<div class="cs-trans--container">
  <div class="cs-trans--img p-right-1" [ngClass]="(payment?.image === 'iconChargeLocal') ? 'cs-trans--img-small' : ''">
    <img src="assets/global/img/{{ payment?.Image ? payment?.Image : payment?.image }}.png" alt="" />
  </div>
  <span class="cs-trans--title color-dark">{{payment?.Name ? payment?.Name : payment?.name}}</span>
</div>
<i item-right class="color-dark codere-icon icon-arrow-right"></i>
<div class="recommended" *ngIf="isRecommended">
  <img src="assets/global/img/recommended.png" alt="" />
  <span>Recomendado</span>
</div>
