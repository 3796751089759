/* eslint-disable */
import { BaseService } from '@providers/BaseService';
import { Platform } from '@ionic/angular';
import { Injectable, NgZone, inject } from '@angular/core';

import { AppsFlyerInitOptions } from '@models/C_AppFlyer';
import { TrackingService } from '@providers/TrackingService';
import {
  AFConstants,
  AFCuid,
  AFEnableTCFDataCollection,
  AFEvent,
  AFInit,
  AFRes,
  AppsFlyer,
  AppsFlyerConsent
} from 'appsflyer-capacitor-plugin';
import { Capacitor } from '@capacitor/core';
import { LicenseTypes } from '@models/MasterData';
import { Router } from '@angular/router';
import { INTERFACE } from '@interfaces/general';
import { environment } from 'apps/codere-ionic/src/environments/environment.config';
import { CapacitorGetPlatform } from '@utils/constants';

@Injectable({ providedIn: 'root' })
export class AppsFlyerTrackingService extends BaseService {
  //DEFINE INTERFACES LOOK FOR @TYPES
  appsKeys: AppsFlyerInitOptions;
  appsFlyer;
  // global: GlobalVarsModel;

  //#region Inject Services
  trackingService = inject(TrackingService); //@Inject(forwardRef(() => TrackingService)) private trackingService: TrackingService,
  router = inject(Router);
  zone = inject(NgZone);
  //#endregion
  constructor() {
    super();
  }

  /**
   * Fires when user closes the cookies modal.
   * hasConsentForDataUsage: boolean - Indicates whether the user has consented to use their data for advertising purposes.
   * hasConsentForAdsPersonalization: boolean - Indicates whether the user has consented to use their data for personalized advertising.
   * forGDPRUser(hasConsentForDataUsage, hasConsentForAdsPersonalization)
   * @param consentOptions {customOK: boolean, analyticsOK: boolean, advertismentOK: boolean}
   */
  async setAppsFlyerConsent(consentOptions: INTERFACE.ConsentOptionsData): Promise<void> {
    try {
      const enableConsent: AFEnableTCFDataCollection = {
        shouldEnableTCFDataCollection: true
      };
      await AppsFlyer.enableTCFDataCollection(enableConsent);
      const gdprConsent = AppsFlyerConsent.forGDPRUser(consentOptions.customOK, consentOptions.advertismentOK);
      await AppsFlyer.setConsentData({ data: gdprConsent });
      await AppsFlyer.startSDK();
    } catch (error) {
      console.error('👎 setAppsFlyerConsent ERROR >>', JSON.stringify(error));
    }
  }

  async startAppsFlyerSdk(): Promise<AFRes> {
    return await AppsFlyer.startSDK();
  }

  async init(): Promise<void> {
    try {
      const afConfig: AFInit = {
        appID: '',
        devKey: this.globalVars.FEATURES.AppsFlyerDevKey || '35oCwDbdGrCHzD9kajJMTk',
        isDebug: environment.GLOBALS.DEBUG_MODE,
        waitForATTUserAuthorization: 20,
        minTimeBetweenSessions: 6, // default 5 sec
        registerOnDeepLink: true,
        registerConversionListener: true,
        registerOnAppOpenAttribution: true,
        deepLinkTimeout: 4000, // default 3000 ms
        useReceiptValidationSandbox: true, // iOS only
        useUninstallSandbox: false, // iOS only
        manualStart: true
      };

      if (Capacitor.getPlatform() === CapacitorGetPlatform.IOS) {
        // this.appsKeys.appId = '917178660';
        // GLOBALS.APPSFLYER_APPID = this.appsKeys.appId;

        if (this.globalVars.licenseType == LicenseTypes.Colombia) {
          afConfig.appID = '1313823849';
          this.globalVars.APPSFLYER_APPID = '1313823849';
        } else if (this.globalVars.licenseType == LicenseTypes.Nacional) {
          afConfig.appID = '917178660';
          this.globalVars.APPSFLYER_APPID = '917178660';
        } else if (this.globalVars.licenseType == LicenseTypes.Panama) {
          afConfig.appID = '1498747318';
          this.globalVars.APPSFLYER_APPID = '1498747318';
        } else {
          afConfig.appID = this.globalVars.FEATURES.AppsFlyerAppId;
          this.globalVars.APPSFLYER_APPID = this.globalVars.FEATURES.AppsFlyerAppId;
        }
      }

      //deeplinks && deferred deeplinks
      this.setOAOA();
      const afResponse: AFRes = await AppsFlyer.initSDK(afConfig);
      const afVersion: AFRes = await AppsFlyer.getSdkVersion();

      console.log('AppsFlyer SDK init: ' + afResponse.res);
      console.log('AppsFlyer SDK version: ' + afVersion.res);
    } catch (err: any) {
      console.log('appsflyer init ERROR->', err);
      this.trackingService.trackEvent(['AppsFlyer', 'initError', '', err, 'event']);
    }
  }

  setOAOA() {
    AppsFlyer.addListener(AFConstants.OAOA_CALLBACK, (res) => {
      if (res.callbackName === AFConstants.onAppOpenAttribution) {
        console.log('AppsFlyer SDK onAppOpenAttribution: ' + res.data);
        this.trackEvent('onAppOpenAttribution', res.data);
        this.router.navigateByUrl(res.data.path);
        // add instructions
      } else {
        console.log(res.errorMessage);
      }
    });
  }

  trackEvent(eventName: string, eventValues: Object) {
    const data: AFEvent = { eventName, eventValue: eventValues };
    console.log('AppsFlyer SDK trackEvent: ' + eventName);
    console.log('AppsFlyer SDK trackEvent - EventValues: ' + eventValues);

    AppsFlyer.logEvent(data)
      .then((r) => {
        console.log(r);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  stopTracking() {
    if (this.appsFlyer.getSdkVersion) {
      //getSdkversion is only available from sdk 6.0.3+
      this.appsFlyer.Stop(true);
    } else {
      this.appsFlyer.stopTracking(true);
    }

    //AppsFlyer provides you a method to opt‐out specific users from AppsFlyer analytics.
    //This method complies with the latest privacy requirements and complies with Facebook data and privacy policies.
    //Default is FALSE, meaning tracking is enabled by default.
    //this.appsFlyer.setDeviceTrackingdisabled(true); --> anonymizeUser
  }

  async setCustomerUserId(cuid: AFCuid) {
    await AppsFlyer.setCustomerUserId(cuid);
  }
}
