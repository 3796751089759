import { CommonModule } from "@angular/common";
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { CUSTOM_ELEMENTS_SCHEMA, Component, EventEmitter, Input, Output, inject } from "@angular/core";
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, ValidatorFn, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { IonicModule } from "@ionic/angular";
import { LicenseTypes } from "@models/MasterData";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { NewBaseService } from "@providers/newBase.service";
import { DeviceService } from "@services/device.service";
@Component({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "cdr-step2-se",
  templateUrl: "./step2-se.html",
  styleUrls: ["./step2-se.scss"],
  standalone: true,
  imports: [CommonModule, IonicModule, TranslateModule, FormsModule, ReactiveFormsModule],
})
export class Step2SeComponent {
  @Input() dateOptions: any;
  @Output() changeStep: EventEmitter<number>;
  @Output() datesEvent: EventEmitter<Date[] | string>;
  @Output() selfExEvent: EventEmitter<{ selfExclusionDate: string; canRevocate: boolean }>;

  text: string;
  textButton0: string;
  textButton1: string;
  timeExclusion = "";
  myForm: FormGroup;
  myFormCo: FormGroup;
  selfEx: any = {
    day: "",
    month: "",
    year: "",
    week: "",
  };
  dayMonth: Array<string>;
  months: Array<{ label: string; data: string }>;
  validDate: boolean;
  checkedDate = true;
  textError = "";
  selfExclusionP2tx03 = "";
  showSelfExclusionP2tx04 = true;
  showSelfExclusionP2tx05 = true;
  isDesktop: boolean = false;
  isMobile: boolean = false;

  globalVars!: GlobalVarsModel;

  translate = inject(TranslateService);
  newBaseService = inject(NewBaseService);
  fb = inject(FormBuilder);
  deviceService = inject(DeviceService);
  router = inject(Router);

  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });

    this.isDesktop = this.deviceService.isDesktop();
    this.isMobile = this.deviceService.isMobile();

    this.changeStep = new EventEmitter();
    this.selfExEvent = new EventEmitter();
    this.datesEvent = new EventEmitter();
    if (this.globalVars.LICENSE_TYPE === LicenseTypes.Colombia) {
      this.timeExclusion = "COSelfexclusion";
      this.myFormCo = this.fb.group({
        day: ["", Validators.compose([Validators.max(31), Validators.min(1)])],
        month: [""],
        year: ["", Validators.min(1)],
        week: ["", Validators.compose([Validators.max(4), Validators.min(1)])],
        toggleDay: [false],
        toggleMonth: [false],
        toggleYear: [false],
        toggleWeek: [false],
      });
      this.myFormCo.get("day").disable();
      this.myFormCo.get("month").disable();
      this.myFormCo.get("year").disable();
      this.myFormCo.get("week").disable();
      if (!this.globalVars.FEATURES.MSO_NewLogicSelfexclusionCo) {
        this.myFormCo.get("month").addValidators(Validators.compose([Validators.max(12), Validators.min(1)]));
      } else {
        this.myFormCo.get("month").addValidators(Validators.min(1));
      }
      this.initListenerFormCo();
    }

    this.myForm = this.fb.group({
      day: ["", Validators.required],
      month: ["", Validators.required],
      year: ["", Validators.required],
    });

    this.dayMonth = [];
    for (let i = 1; i <= 31; i++) {
      this.dayMonth.push(i < 10 ? "0" + i.toString() : i.toString());
    }
    const arrMeses = [
      this.translate.instant("rMEn"),
      this.translate.instant("rMFe"),
      this.translate.instant("rMMa"),
      this.translate.instant("rMAv"),
      this.translate.instant("rMMy"),
      this.translate.instant("rMJn"),
      this.translate.instant("rMJl"),
      this.translate.instant("rMAg"),
      this.translate.instant("rMSe"),
      this.translate.instant("rMOc"),
      this.translate.instant("rMNo"),
      this.translate.instant("rMDi"),
    ];

    this.months = [];

    for (let i = 0; i < 12; i++) {
      this.months.push({
        label: arrMeses[i],
        data: i < 9 ? "0" + (i + 1).toString() : (i + 1).toString(),
      });
    }

    this.myForm.get("day").valueChanges.subscribe((value: string) => {
      this.checkValidDate();
    });
    this.myForm.get("month").valueChanges.subscribe((value: string) => {
      this.checkValidDate();
    });
    this.myForm.get("year").valueChanges.subscribe((value: string) => {
      this.checkValidDate(value);
    });

    switch (this.globalVars.LICENSE_TYPE) {
      case 0:
        this.selfExclusionP2tx03 = this.translate.instant("selfExclusionP2tx03");
        this.showSelfExclusionP2tx04 = true;
        this.showSelfExclusionP2tx05 = true;
        break;
      case 22:
        this.selfExclusionP2tx03 = this.translate.instant("selfExclusionP2tx03PA");
        this.showSelfExclusionP2tx04 = false;
        this.showSelfExclusionP2tx05 = false;
        break;

      default:
        break;
    }
  }

  initListenerFormCo() {
    this.myFormCo.get("toggleDay").valueChanges.subscribe((value) => {
      const control = this.myFormCo.get("day");
      control.clearValidators();
      if (value) {
        control.enable();
        control.setValidators(Validators.compose([Validators.required, Validators.max(31), Validators.min(1)]));
      } else {
        control.disable();
        control.reset("");
        control.setValidators(Validators.compose([Validators.max(31), Validators.min(1)]));
      }
    });
    this.myFormCo.get("toggleMonth").valueChanges.subscribe((value) => {
      const control = this.myFormCo.get("month");
      const validators: ValidatorFn[] = [Validators.min(1)];
      if (!this.globalVars.FEATURES.MSO_NewLogicSelfexclusionCo) {
        validators.push(Validators.max(12));
      }
      control.clearValidators();
      if (value) {
        control.enable();
        validators.push(Validators.required);
        control.setValidators(Validators.compose(validators));
      } else {
        control.disable();
        control.reset("");
        control.setValidators(Validators.compose(validators));
      }
    });
    this.myFormCo.get("toggleYear").valueChanges.subscribe((value) => {
      const control = this.myFormCo.get("year");
      control.clearValidators();
      if (value) {
        control.enable();
        control.setValidators(Validators.compose([Validators.required, Validators.min(1)]));
      } else {
        control.disable();
        control.reset("");
        control.setValidators([Validators.min(1)]);
      }
    });
    this.myFormCo.get("toggleWeek").valueChanges.subscribe((value) => {
      const control = this.myFormCo.get("week");
      control.clearValidators();
      if (value) {
        control.enable();
        control.setValidators(Validators.compose([Validators.required, Validators.max(4), Validators.min(1)]));
      } else {
        control.disable();
        control.reset("");
        control.setValidators(Validators.compose([Validators.max(4), Validators.min(1)]));
      }
    });
  }

  checkValidDate(yearValue?: string) {
    //checks if the date is valid
    let newDate: Date;

    // if (!!this.selfEx.day && !!this.selfEx.month && !!this.selfEx.year) {
    if (this.myForm.get("day").dirty && this.myForm.get("month").dirty && this.myForm.get("year").dirty) {
      //check the date
      const valueDay = parseInt(this.myForm.get("day").value);
      const valueMonth = parseInt(this.myForm.get("month").value) - 1;
      const valueYear = parseInt(yearValue || this.myForm.get("year").value);
      const miDate: Date = new Date(valueYear, valueMonth, valueDay, 0, 0, 0, 0);
      newDate = miDate;
      if (
        miDate.getDate() === valueDay &&
        miDate.getMonth() === valueMonth &&
        miDate.getFullYear() === valueYear
      ) {
        this.validDate = miDate.getTime() > new Date().getTime();
      } else {
        this.validDate = false;
      }
    } else {
      this.validDate = false;
    }
    if (this.validDate) {
      const dateDiff = this.getDateDifference(new Date(), newDate);
      switch (this.globalVars.licenseType) {
        case 22:
          if (dateDiff && dateDiff.years < 2) {
            this.validDate = false;
            this.textError = "selfexclusionminimumPa";
          }
          break;

        default:
          if (dateDiff && dateDiff.years <= 0 && dateDiff.months <= 6) {
            if (dateDiff.months === 6 && dateDiff.days > 0) {
              this.validDate = false;
              this.textError = "selfExclusionmore6months";
            }
          } else {
            this.validDate = false;
            this.textError = "selfExclusionmore6months";
          }
          break;
      }
    } else {
      this.textError = "date_valid";
    }
  }

  selfexclusion_click() {
    let validForm = false;
    let selfExclusionDate = "";
    if (this.timeExclusion === "indefinido") {
      validForm = true;
      // ToDo: Definir valor de tiempo indefinido
      // selfExclusionDate = 1/1/9999;
      selfExclusionDate = "2500-12-31";
    } else {
      if (this.validDate) {
        validForm = true;
        selfExclusionDate = `${this.selfEx.year.toString()}-${this.selfEx.month}-${this.selfEx.day}`;
      }
    }
    if (validForm) {
      this.selfExEvent.emit({ selfExclusionDate, canRevocate: false });
    }
  }

  private dateEnd() {
    if (this.timeExclusion === "indefinido") {
      return "indefinido";
    } else {
      let finalDate: Date = new Date();
      const initialDate: Date = new Date();
      if (this.timeExclusion === "24h") {
        finalDate = this.AddDate(finalDate, 1);
      } else if (this.timeExclusion === "48h") {
        finalDate = this.AddDate(finalDate, 2);
      } else if (this.timeExclusion === "1s" || this.timeExclusion === "7d") {
        finalDate = this.AddDate(finalDate, 7);
      } else if (this.timeExclusion === "intervalodate") {
        const year: number = Number.parseInt(this.myForm.get("year").value);
        const day: number = Number.parseInt(this.myForm.get("day").value);
        const month: number = Number.parseInt(this.myForm.get("month").value) - 1;
        finalDate = new Date(year, month, day);
      } else if (this.timeExclusion === "2y") {
        finalDate = this.AddDate(finalDate, 2, true);
      } else if (this.timeExclusion === "COSelfexclusion") {
        finalDate = this.dateFormCO(finalDate);
      }
      finalDate.setHours(23);
      finalDate.setMinutes(59);
      return [initialDate, finalDate];
    }
  }

  private dateFormCO(date: Date) {
    if (this.selfEx.day.toString().length > 0) {
      date.setDate(date.getDate() + Number.parseInt(this.selfEx.day));
    }
    if (this.selfEx.year.toString().length > 0) {
      date.setFullYear(date.getFullYear() + Number.parseInt(this.selfEx.year));
    }
    if (this.selfEx.month.toString().length > 0) {
      date.setMonth(date.getMonth() + Number.parseInt(this.selfEx.month));
    }
    if (this.selfEx.week.toString().length > 0) {
      date.setDate(date.getDate() + Number.parseInt(this.selfEx.week) * 7);
    }
    return date;
  }

  private AddDate(date: Date, numdias_year: number, year = false): Date {
    if (!year) {
      date.setDate(date.getDate() + numdias_year);
    } else {
      date.setFullYear(date.getFullYear() + numdias_year);
    }
    return date;
  }

  goToStep(n) {
    this.datesEvent.next(this.dateEnd());
    this.changeStep.next(n);
  }

  private getDateDifference(startDate, endDate) {
    if (startDate > endDate) {
      return null;
    }
    const startYear = startDate.getFullYear();
    const startMonth = startDate.getMonth();
    const startDay = startDate.getDate();

    const endYear = endDate.getFullYear();
    const endMonth = endDate.getMonth();
    const endDay = endDate.getDate();

    const february = (endYear % 4 === 0 && endYear % 100 !== 0) || endYear % 400 === 0 ? 29 : 28;
    const daysOfMonth = [31, february, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    const startDateNotPassedInEndYear = endMonth < startMonth || (endMonth === startMonth && endDay < startDay);
    const years = endYear - startYear - (startDateNotPassedInEndYear ? 1 : 0);

    const months = (12 + endMonth - startMonth - (endDay < startDay ? 1 : 0)) % 12;

    const days =
      startDay <= endDay ? endDay - startDay : daysOfMonth[(12 + endMonth - 1) % 12] - startDay + endDay;

    return {
      years: years,
      months: months,
      days: days,
    };
  }
  backToHome() {
    this.router.navigate(["/"], { queryParams: { animate: false } });
  }

  public get isSelfExValid(): boolean {
    return (
      this.selfEx.day.toString().length > 0 ||
      this.selfEx.month.toString().length > 0 ||
      this.selfEx.year.toString().length > 0 ||
      this.selfEx.week.toString().length > 0
    );
  }

  public get isValid(): boolean {
    return (
      this.timeExclusion === "" ||
      (this.timeExclusion === "intervalodate" && (this.myForm.invalid || !this.validDate)) ||
      (this.timeExclusion === "COSelfexclusion" && (this.myFormCo.invalid || !this.isSelfExValid))
    );
  }
}
