import { Component, inject } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
/* eslint-disable eqeqeq */
import { IonicModule, NavController } from "@ionic/angular";
import { LicenseTypes } from "@models/MasterData";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { CustomValidator } from "@providers/CustomValidators";
import { ErrorService } from "@providers/ErrorService";
import { UserService } from "@providers/UserService";
import { Utils } from "@utils/Utils";

import { MSO_PATHS } from "@shared-constants/routes";

import { DeviceService } from "@services/device.service";

import { CommonModule } from "@angular/common";
import { Router } from "@angular/router";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { NewBaseService } from "@providers/newBase.service";
import { CodereFooterComponent } from "@shared-mso-components/footer/codere-footer/codere-footer";
import { UserSectionTitle } from "@shared-mso-components/user-section-title/user-section-title";

@Component({
  standalone: true,
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "my-card-recover-2-page",
  templateUrl: "./my-card-recover-2.html",
  styleUrls: ["./my-card-recover-2.scss"],
  imports: [
    IonicModule,
    CommonModule,
    TranslateModule,
    UserSectionTitle,
    FormsModule,
    ReactiveFormsModule,
    CodereFooterComponent,
  ],
})
export class MyCardRecover2Page {
  steps: string;

  myForm1: FormGroup;
  myForm2: FormGroup;
  myForm3: FormGroup;

  newCardNumber: string;

  globalVars!: GlobalVarsModel;

  isMobile: boolean = false;

  nav = inject(NavController);
  utils = inject(Utils);
  translate = inject(TranslateService);
  newBaseService = inject(NewBaseService);
  fb = inject(FormBuilder);
  userService = inject(UserService);
  errorService = inject(ErrorService);
  device = inject(DeviceService);
  router = inject(Router);

  constructor() {
    this.isMobile = this.device.isMobile();
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
    this.steps = "step1";
    this.myForm1 = this.fb.group({
      password: ["", Validators.required],
    });
    this.myForm2 = this.fb.group({
      cardNumber: ["", this.cardValidator],
    });

    this.myForm3 = this.fb.group({
      newPin: [
        "",
        [
          Validators.required,
          Validators.maxLength(4),
          Validators.minLength(4),
          Validators.pattern("[0-9]*"),
          CustomValidator.pinNotConsecutiveNumbers,
        ],
      ],
      reNewPin: [
        "",
        [
          Validators.required,
          Validators.maxLength(4),
          Validators.minLength(4),
          Validators.pattern("[0-9]*"),
          CustomValidator.pinNotConsecutiveNumbers,
        ],
      ],
    });
  }

  ionViewWillEnter() {
    this.router.navigate([MSO_PATHS.BASE + "/" + MSO_PATHS.MyCardRecover2Page]);
  }

  isValidNumber(number: string) {
    const DELTAS = [0, 1, 2, 3, 4, -4, -3, -2, -1, 0];
    let checksum = 0;
    const chars = number.split("");
    if (chars[0] === "0" && chars[1] === "0") {
      return false;
    }
    for (let i = chars.length - 1; i > -1; i--) {
      const j = parseInt(chars[i]);
      checksum += j;
      if ((i - chars.length) % 2 === 0) {
        checksum += DELTAS[j];
      }
    }
    return checksum % 10 === 0;
  }
  cardValidator = (control: FormControl) => {
    //only 16 numbers
    let cardRegEsp = /^[0-9]{16}$/;
    if (this.globalVars.licenseType === LicenseTypes.Nacional) {
      cardRegEsp = /^[0-9]{16,19}$/;
    }

    const ccNum: string = control.value;
    const charCount: number = ccNum.length;

    if (cardRegEsp.test(control.value)) {
      if (charCount === 19) {
        const nCodere = "31069000";
        const codePhone = "34";
        if (!ccNum.startsWith(nCodere)) {
          return { valid: "notValid" };
        }
        if (ccNum.substring(8, 10) !== codePhone) {
          return { valid: "notValid" };
        }
        const INE = parseInt(ccNum.substring(10, 12));
        const maxINE = 19;
        if (INE < 1 || INE > maxINE) {
          return { valid: "notValid" };
        }

        if (this.isValidNumber(ccNum)) {
          return null;
        }
        return { valid: "notValid" };
      } else {
        const numArr: Array<number> = [];
        let sumTotal = 0;
        for (let i = 0; i < charCount; i++) {
          let digit: number = parseInt(ccNum.charAt(i));
          if (i % 2 === 0) {
            //odd values
            const digitTemp = digit * 2;
            if (digitTemp > 9) {
              digit = parseInt(digitTemp.toString().charAt(0)) + parseInt(digitTemp.toString().charAt(1));
            } else {
              digit = digitTemp;
            }
          }
          numArr.push(digit);
        }
        for (let i = 0; i < numArr.length; i++) {
          sumTotal += numArr[i];
        }
        const diff: number = sumTotal % 10;

        if (diff === 0) {
          //card valid
          return null;
        } else {
          //not valid
          return { valid: "notValid" };
        }
      }
    } else {
      //not valid
      return { valid: false };
    }
  };

  sendCard() {
    if (this.myForm2.valid) {
      this.userService.cardStatus({ cardNumber: this.myForm2.controls["cardNumber"].value }).subscribe(
        (data) => {
          if (data.status === "Notexists") {
            //go nextstep
            this.newCardNumber = this.myForm2.controls["cardNumber"].value;

            this.steps = "step3";
          } else {
            this.utils.showError(this.translate.instant("GetCardnewcardonly"));
          }
        },
        (err) => {
          this.utils.showError(this.translate.instant("m_menu_serverFail"));
        },
      );
    } else {
      this.myForm2.controls["cardNumber"].markAsTouched();
    }
  }

  checkPassword() {
    //
    if (this.myForm1.valid) {
      this.userService
        .validatePasswordAndCard({
          password: this.myForm1.controls["password"].value,
        })
        .subscribe({
          next: (data) => {
            if (data.success) {
              //nextstep
              this.steps = "step2";
            } else {
              if (data.message !== undefined) {
                this.errorService.searchErrorCode(data.message).subscribe({
                  next: (data5) => {
                    this.utils.showError(data5);
                  },
                  error: (err) => {
                    this.utils.showError(data.message);
                  },
                });
              } else {
                this.utils.showError(this.translate.instant("GetCardpasswordKO"));
              }
            }
          },
          error: (err) => {
            ///
            this.utils.showError(this.translate.instant("m_menu_serverFail"));
          },
        });
    } else {
      this.myForm1.controls["password"].markAsTouched();
    }
  }

  setPin() {
    if (this.myForm3.valid) {
      if (this.myForm3.controls["reNewPin"].value === this.myForm3.controls["newPin"].value) {
        this.userService
          .reuseCard({
            newCard: this.newCardNumber,
            pin: this.myForm3.controls["newPin"].value,
          })
          .subscribe({
            next: (data) => {
              //
              if (data.success) {
                this.utils.showMsg(this.translate.instant("GetCarnewlink"), () => {
                  //go back
                  this.router.navigate([MSO_PATHS.BASE + "/" + MSO_PATHS.MyCardPage]);
                });
              } else {
                if (data.message !== undefined) {
                  if (isNaN(data.message)) {
                    this.utils.showError(data.message);
                  } else {
                    this.errorService.searchErrorCode(data.message).subscribe({
                      next: (data3) => {
                        this.utils.showError(data3);
                      },
                      error: () => {
                        this.utils.showError(data.message);
                      },
                    });
                  }
                } else {
                  this.utils.showError(this.translate.instant("GetCardNotMake"));
                }
              }
            },
            error: () => {
              this.utils.showError(this.translate.instant("m_menu_serverFail"));
            },
          });
      } else {
        //not same pin
        this.myForm3.controls["reNewPin"].setErrors({ same: "notValid" });
      }
      //
    } else {
      this.myForm3.controls["newPin"].markAsTouched();
      this.myForm3.controls["reNewPin"].markAsTouched();
    }
  }
}
