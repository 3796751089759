import { C_PendingPayments } from './C_PendingPayments';
import { ListOfCards } from './ListOfCards';

//eslint-disable-next-line
export const enum BasicActionResultStatus {
  Ok = 0,
  Ko = 1,
  error = 2
}

//eslint-disable-next-line
export const enum PaymentMethods {
  Puntos = 100000000,
  Efectivo = 754310000,
  Halcash = 754310001,
  Paypal = 754310002,
  PaySafeCard = 754310003,
  Tarjetanominativa = 754310004,
  Transaccionenpuntodeventa = 754310005,
  Transaccionenweb = 754310006,
  Transferenciabancaria = 754310007,
  Transferenciainterna = 754310008,
  Ukash = 754310009,
  Visa = 754310010,
  Premioapuestaganadora = 754310011,
  Compensacintransaccinerrnea = 754310012,
  Depositoticketpremiado = 754310013,
  Bono = 754310014,
  Teleingreso = 754310015,
  DepositoOxxo = 754310016,
  Skrill = 754310017,
  SorteoCodere = 754310018,
  PromoMXCodere = 754310019,
  BonoSlots = 754310020,
  Neteller = 754310021,
  MuchBetter = 754310022,
  PagueloFacil = 754310023,
  Paynet = 754310024,
  AmericanExpress = 754310025,
  BancoAzteca = 754310026,
  ToditoCash = 754310027,
  SPEI = 754310028,
  Bancomer = 754310029,
  Banamex = 754310030,
  Santander = 754310031,
  Banorte = 754310032,
  FREEbonus = 754310033,
  Nequi = 754310034,
  Daviplata = 754310035,
  GanaGana = 754310036,
  Carulla = 754310037,
  PagaTodo = 754310038,
  ApuestasCucuta = 754310039,
  GanaCercadeTi = 754310040,
  AlmacenesExito = 754310041,
  JER = 754310042,
  Surtimax = 754310043,
  AMEX = 754310044,
  BancoColombia = 754310045,
  OpenPayPaynet = 754310046,
  Tpaga = 754310047,
  Rappi = 754310048,
  PSEDirecta24 = 754310049,
  BalotoDirecta24 = 754310050,
  BbvaMxSms = 754310051,
  MasterCard = 754310052,
  AstropayEPG = 754310053,
  MuchBetterEPG = 754310054,
  Transferenciarapida = 754310055,
  CoinsPaid = 754310056,
  Bizum = 754310057,
  Pagos360 = 754310058,
  Decidir = 754310059,
  Clave = 754310034,
  Cybersource = 754310088
}

export const enum CashierKOSubtitles {
  depositKO = 'depositKO',
  debin_error = 'debin_deposit_error'
}

export class BasicActionResult {
  errCode: number;
  errMessage: string;
  errorParameters: string[];
  status: BasicActionResultStatus;
  success: boolean;
}

export class CancelPaymentActionResult extends BasicActionResult {
  payments: Array<C_PendingPayments>;
}

export class TransactionActionResult extends BasicActionResult {
  amount: number;
  netAmount: number;
  taxAmount: number;
  transactionId: string;
  isFirstDeposit: boolean;
  paymentMethod: number | string;
  MFA_CODE?: string;
}
export class HalcashChargeActionResult extends TransactionActionResult {
  descripción: string;
}
export class PaySafeCardDepositActionResult extends TransactionActionResult {
  dataStorage: string;
  url: string;
}

export class OxxoDepositActionResult extends TransactionActionResult {
  dataStorage: string;
  url: string;
}
export class LotbaDepositActionResult extends TransactionActionResult {
  id: number;
}
export class EpgCardDepositActionResult extends TransactionActionResult {
  dataStorage: string;
  url: string;
  reference: string;
}
export class BBVAMexDepositActionResult extends TransactionActionResult {
  mp_account: number;
  mp_amount: string;
  mp_concept: string;
  mp_currency: string;
  mp_customername: string;
  mp_node: string;
  mp_order: string;
  mp_product: number;
  mp_reference: string;
  mp_signature: string;
  mp_urlfailure: string;
  mp_urlsuccess: string;
  mp_email: string;
  mp_promo: string;
  mp_phone: string;
  url: string;
}

export class PayUDepositActionResult extends TransactionActionResult {
  merchantId: number;
  referenceCode: string;
  description: string;
  override amount: number;
  signature: string;
  accountId: number;
  currency: string;
  buyerFullName: string;
  buyerEmail: string;
  responseUrl: string;
  confirmationUrl: string;
  url: string;
  tax: number;
  taxReturnBase: number;
  paymentMethods: string;
  phoneNumber: string;
  payerDocument: string;
  payerDocumentType: string;
}

export class PaynetActionResult extends TransactionActionResult {
  paynetReference: string;
  barcodeurl: string;
  duedate: string;
}

export class QRDepositActionResult extends TransactionActionResult {
  override transactionId: string;
  provider: string;
  payload: any;
}

export class TpagaDepositActionResult extends QRDepositActionResult {
  url: string;
  token: string;
}

export class CoinspaidActionResult extends QRDepositActionResult {
  address: string;
}

export class BizumActionResult extends TransactionActionResult {
  override success: boolean;
  override errMessage: string;
  override errCode: number;
  url: string;
}

// Redsys implementation
// Bizum
export class RedsysActionResult extends TransactionActionResult {
  override success: boolean;
  override errMessage: string;
  override errCode: number;

  // Redys implementation
  ds_SignatureVersion: string;
  ds_MerchantParameters: string;
  ds_Signature: string;
  url: string;
}

export class TrueLayerActionResult extends TransactionActionResult {
  url: string;
}
// Cards
export class RedsysCardsActionResult extends TransactionActionResult {
  override success: boolean;
  override errMessage: string;
  override errCode: number;

  override amount: number;
  override transactionId: string;
  override isFirstDeposit: boolean;
  override netAmount: number;
  override taxAmount: number;

  hasCards: boolean;
  ListOfCards: ListOfCards[];
}
export class MercadoPagoActionResult extends TransactionActionResult {
  override success: boolean;
  url: string;
  override errMessage: string;
  override errCode: number;
}
export class PagueloFacilDepositActionResult extends TransactionActionResult {
  code: string;
  url: string;
}

export const RISKY_PLAYER_ERROR_CODE = 2214;
