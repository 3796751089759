<ng-container *ngFor="let item of downloadLinks">
  <ng-container *ngIf="!isNative">
    <a
      *ngIf="item.show && !item.event && item.href"
      external-link
      [href]="item.href"
      [target]="item.target"
      [style]="item.style"
      [ngClass]="item.class"
      ><img
        [src]="item.src"
        [style]="item.styleImage"
        [ngClass]="[item.classImg, (globalVars.LICENSE_TYPE === 21 ? ' footerDownloadCo' : ''), (globalVars.LICENSE_TYPE === 21 && item.src.endsWith('footerandroid.png') ? 'androidCo' : '')]"
    /></a>
    <a
      *ngIf="item.show && item.event && item.href"
      [ngClass]="item.class"
      (click)="DownloadApp($event)"
      [style]="item.style"
      ><img
        [src]="item.src"
        [style]="item.styleImage"
        [ngClass]="[item.classImg, (globalVars.LICENSE_TYPE === 21 ? ' footerDownloadCo' : '')]"
    /></a>
  </ng-container>

  <img
    *ngIf="item.show && !item.event && !item.href"
    [src]="item.src"
    [style]="item.styleImage"
    [ngClass]="item.classImg + (globalVars.LICENSE_TYPE === 0 ? ' footerDownloadEs' : '')"
    class="img-store"
  />
</ng-container>
