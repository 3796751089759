<ng-container *ngIf="isDesktop; else mobileView">
  <ion-content class="background-color-muted">
    <!-- user-section-title mobile & desktop -->
    <user-section-title [sectionTitle]="'RECOMPENSAS'"></user-section-title>

    <div class="is-narrow-content">
      <div *ngIf="!loadPending && bonuses.length === 0">
        <div class="alertMessage" *ngIf="bonuses.length === 0">
          <ion-icon
            name="alert-circle-outline"
            role="img"
            class="ion-ios-alert-outline"
            aria-label="alert outline"
          ></ion-icon>
          {{ 'SlotBonusRecomNo' | translate }}
        </div>
        <div class="empty-state center">
          <i class="codere-icon icon-slots big"></i>
        </div>
      </div>

      <div *ngIf="!loadPending && bonuses.length > 0">
        <div *ngFor="let bonus of bonuses">
          <slot-bonus-detail (Refresh)="refresh()" [bonus]="bonus"></slot-bonus-detail>
        </div>
      </div>
    </div>

    <codere-footer></codere-footer>
  </ion-content>
</ng-container>

<ng-template #mobileView>
  <user-section-title [sectionTitle]="'RECOMPENSAS'"></user-section-title>
  <ion-content>
    <!--No hay bonos de Slots-->
    <!-- eslint-disable-next-line -->
    <div class="alert" *ngIf="!loadPending && bonuses.length == 0">
      <div *ngIf="bonuses.length === 0" class="alertMessage">
        <ion-icon
          name="alert-circle-outline"
          role="img"
          class="ion-ios-alert-outline"
          aria-label="alert outline"
        ></ion-icon>
        {{'SlotBonusRecomNo' | translate}}
      </div>
      <div class="empty-state center">
        <i class="codere-icon icon-slots big"></i>
      </div>
    </div>
    <!--  -->
    <div class="background-slots" *ngIf="!loadPending && bonuses.length > 0">
      <div class="marginb15 marginl15">
        <i class="icon-slots"></i>
        <span class="fonttit">{{'SlotBonusAct' | translate}}</span>
      </div>
      <div *ngFor="let bonus of bonuses">
        <slot-bonus-detail (Refresh)="refresh()" [bonus]="bonus"></slot-bonus-detail>
      </div>
    </div>
    <codere-footer></codere-footer>
  </ion-content>
</ng-template>
