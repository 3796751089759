import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'removeTwoPoints' })
export class removeTwoPoints implements PipeTransform {
  transform(value: string): string {
    let str = value;
    if (str[str.length - 1] === ':') {
      str = str.slice(0, str.length - 1);
    } else {
      return str;
    }
    return str;
  }
}
