<div class="titleClass" [ngClass]="description.divTitleClass">
  {{description.preTitle}}{{ description.stringToTranslate | translate | uppercase }}
</div>
<div
  *ngFor="let item of description.information; let i=index"
  #lista
  [ngClass]="
    (globalVars.licenseType === 21 && !isDesktop ? 'mobile-co' : '') +
    (isDesktop ? ' marginl10-mobile' : '') +
    ' ' + description.divAnchorClass +
    (globalVars.LICENSE_TYPE == 0 ? ' footerInformationsEs' : '') +
    (utils.isArgentina() ? ' footerInformationsAr' : '') + 
    (globalVars.LICENSE_TYPE == 22 ? ' footerInformationsPa' : '') + 
    (globalVars.LICENSE_TYPE == 21 ? ' footerInformationsCo' : '')"
>
  <!--isDesktop ? 'marginl10-mobile' :       description.divAnchorClass-->
  <ng-container *ngIf="item.show">
    <div *ngIf="item.beforeText" [ngClass]="item.spancss"><span [innerHTML]="item.beforeText"></span></div>
    <ng-container *ngIf="item.href === 'nolink'; else link">
      <div>{{itemTitlePipe(item)}}</div>
    </ng-container>
    <ng-template #link>
      <a
        link-rel
        [linkdescription]="item"
        *ngIf="item.external && !item.src && item.href !==''"
        external-link
        [href]="sanitize(item.href)"
        [target]="item.target"
        [ngClass]="item.src"
        >{{itemTitlePipe(item)}}</a
      >
      <a
        class=""
        link-rel
        [linkdescription]="item"
        *ngIf="!item.external && !item.event && !item.src && item.href !==''"
        [href]="sanitize(item.href)"
        [target]="item.target"
        [ngClass]="item.class"
        >{{itemTitlePipe(item)}}</a
      >
      <a
        link-rel
        [linkdescription]="item"
        *ngIf="item.event && !item.src"
        [href]="sanitize(item.href)"
        (click)="openEvent($event,item.typeEvent)"
        [ngClass]="item.class"
        >{{itemTitlePipe(item)}}</a
      >
      <a
        link-rel
        [linkdescription]="item"
        *ngIf="item.src"
        [href]="sanitize(item.href)"
        [target]="item.target"
        [ngClass]="item.class"
      >
        <img [ngClass]="item.classImg" [src]="item.src" />{{itemTitlePipe(item)}}</a
      >
    </ng-template>
    <div *ngIf="item.afterText"><span [innerHTML]="item.afterText"></span></div>
  </ng-container>
</div>
