import { CommonModule } from "@angular/common";
/* eslint-disable */
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, inject } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, FormsModule } from "@angular/forms";
import { Browser } from "@capacitor/browser";
import { IonicModule } from "@ionic/angular";
import { LicenseTypes } from "@models/MasterData";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ObfuscateBanksPipe } from "@pipes/obfuscatebanks.pipe";
import { ParseMoney } from "@pipes/parseMoney";
import { EventsService } from "@providers/EventsService";
import { DepositModel, ValidateDepositModel } from "@providers/PaymentMethodService/PaymentMethodBase";
import { TrueLayerMethodBehaviour } from "@providers/PaymentMethodService/TrueLayer";
import { UserService } from "@providers/UserService";
import { UtilsCashierService } from "@providers/UtilsCashierService";
import { NewBaseService } from "@providers/newBase.service";
import { DeviceService } from "@services/device.service";
import { Utils } from "@utils/Utils";
import { Observable } from "rxjs";
import { CsCalculatorComponent } from "../cs-calculator/cs-calculator";

interface account {
  iban: string;
  verified: boolean;
}

interface verifiedAccount {
  iban: string;
  display_name: string;
  ofuscateIban: string;
  verified: boolean;
}

interface account {
  iban: string;
  verified: boolean;
}

interface verifiedAccount {
  iban: string;
  display_name: string;
  ofuscateIban: string;
  verified: boolean;
}

@Component({
  selector: "cs-truelayer",
  templateUrl: "./cs-truelayer.html",
  styleUrls: ["./cs-truelayer.scss"],
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule, TranslateModule, CsCalculatorComponent, ObfuscateBanksPipe],
})
export class CsTrueLayerComponent implements OnInit {
  accountList: account[] = [];
  verifiedAccountList: verifiedAccount[] = [];
  accountSelected: string = "";
  bankList$: Observable<any>;
  errorMessageAmount: string = "";
  isActiveKeyboard: boolean = false;
  paymentForm: FormGroup;
  validAmount = false;
  showVerificarButton = false;
  verifiedAccounts = 0;
  selectedAccount: string = "";
  selectedBank: string = "";
  step: number = 1;
  globalVars!: GlobalVarsModel;

  blockCalculator: boolean = false;

  calculatorConfig: any = {
    counterTitle: "Importe de depósito",
    buttonsTitle: "Importes",
    isDesktop: null,
    license: null,
    currency: "",
  };

  heightContainer: any;
  heightKeyboard: any;

  @ViewChild("containerHeight") containerHeight: ElementRef;
  @ViewChild("keyboardHeight") keyboardHeight: ElementRef;
  @ViewChild("calculatorCont") calculatorCont: ElementRef;
  @Input() paymentSelected;
  @Input() paymentBehaviour: TrueLayerMethodBehaviour;
  @Input() config;
  @Input() stateDepositButton;
  @Output() checkDeposit = new EventEmitter<number>();
  @Output() onKeyboardOpen: EventEmitter<boolean> = new EventEmitter();
  @Output() closeKeyBoardTL: EventEmitter<string> = new EventEmitter();

  @Output() setInputKeyboard: EventEmitter<any> = new EventEmitter();
  @Output() setInputKeyboardtl: EventEmitter<any> = new EventEmitter();

  fb = inject(FormBuilder);
  newBaseService = inject(NewBaseService);
  parseMoney = inject(ParseMoney);
  translate = inject(TranslateService);
  userService = inject(UserService);
  utilsCsService = inject(UtilsCashierService);
  events = inject(EventsService);
  utils = inject(Utils);
  deviceService = inject(DeviceService);

  constructor() {
    this.newBaseService.getVars.subscribe((vars) => {
      this.globalVars = vars;
    });
    this.calculatorConfig.license = this.globalVars.licenseType;
    this.calculatorConfig.isDesktop = this.deviceService.isDesktop();
    this.calculatorConfig.currency = this.globalVars.currencyLiteral;
    this.isActiveKeyboard = this.stateDepositButton;

    this.utilsCsService.$truelayerState.subscribe(() => {
      this.setInitState();
    });

    this.utilsCsService.$closeTrueLayerKeyboard.subscribe(() => {
      this.closeKeyboarButton();
    });
  }

  async ngOnInit() {
    this.closeKeyBoardTL.emit("0");
    this.events.publish("loading:start");
    this.paymentForm = this.fb.group({
      amount: [this.paymentSelected.amount, this.amountValidator],
    });
    this.bankList$ = this.userService.getBankList();
    await this.getAccuntList();
    this.checkVerifiedAccounts();
  }

  closeKeyboarButton() {
    this.isActiveKeyboard = false;
    this.setInputKeyboardtl.emit(this.isActiveKeyboard);
    this.isActiveKeyboard ? this.onKeyboardOpen.emit(true) : this.onKeyboardOpen.emit(false);
  }

  setInitState() {
    this.isActiveKeyboard = this.stateDepositButton;
  }

  async getAccuntList() {
    this.utils.loader();
    this.accountList = await this.userService.getUserAccounts().toPromise();
    this.events.publish("loading:finish");
    this.events.publish("loading:finish");
    this.utils.closeLoader();
  }

  selectAccount(account: any) {
    this.paymentBehaviour.account = account?.detail?.value;
    this.selectedAccount = account?.detail?.value;
    this.showVerificarButton = account?.detail?.value === "newAccount" ? true : false;
  }

  selectBank(bank: string) {
    this.selectedBank = bank;
  }

  amountSelected(amount: number) {
    this.paymentForm.get("amount").setValue(amount);
    this.paymentBehaviour.amount = amount;
    this.validAmount = true;
  }

  setCustomAmount(customOption: string) {
    const currentAmount = this.paymentForm.get("amount").value;
    const newAmount = this.utilsCsService.setCustomAmount(customOption, currentAmount, this.paymentSelected);
    this.paymentForm.get("amount").setValue(newAmount);
    this.paymentBehaviour.amount = newAmount;
  }

  inputAmount(event: number) {
    this.paymentForm.get("amount").setValue(event);
    this.paymentBehaviour.amount = event;

    if (!this.paymentForm.get("amount").valid) {
      this.utilsCsService.showInfoAlert(this.translate.instant("Attention"), this.errorMessageAmount);
      this.setCustomAmount("");
      this.validAmount = false;
    } else {
      this.validAmount = true;
    }
  }

  goToStep(number: number) {
    this.setInputKeyboardtl.emit(false);
    this.closeKeyBoardTL.emit("0");
    this.isActiveKeyboard = false;
    this.closeKeyBoard("e");
    this.step = number;
    this.selectedBank = "";
    if (number === 1) {
      this.selectAccount(this.verifiedAccountList[0].iban);
    }
  }

  async verifyAccount() {
    this.utils.loader();
    const response = await this.userService.verifyAccount().toPromise();
    const resposeStatus = response.status.toLowerCase();

    switch (resposeStatus) {
      case "ok":
        // REVISAR SI ESTE CHECKO DE MOBILE ES OK
        if (this.globalVars.isNative) {
          await Browser.open({ url: response.url });
        } else {
          window.location.href = response.url;
        }
        break;
      case "ko":
        this.utilsCsService.showInfoAlert("Error", response.errMessage);
        break;
      case "pending":
        const alertMessage =
          "Ha habido un problema con su verificación. Para continuar, póngase en contacto con el servicio de soporte";
        this.utilsCsService.showInfoAlert("Error", alertMessage);
        break;
      default:
        break;
    }

    setTimeout(() => {
      this.events.publish("loading:finish");
      this.utils.closeLoader();
    }, 1000);
  }

  async deposit() {
    this.checkDeposit.emit();
  }

  private openBrowser(url, target) {
    const left = screen.width / 2 - 700 / 2;
    const top = screen.height / 2 - 1000 / 2;
    const browser = window.open(
      url,
      target,
      "height=1000,width=700,top=" + top + ",left=" + left + ",toolbar=0,menubar=0,location=0",
    );
    browser.focus();
    this.listenToBrowserClose(browser);
  }

  private listenToBrowserClose(browser) {
    const interval = setInterval(async () => {
      if (browser.closed) {
        this.step = 1;
        await this.ngOnInit();
        clearInterval(interval);
      }
    }, 300);
  }

  private checkVerifiedAccounts() {
    this.verifiedAccountList = [];
    this.accountList.forEach((account, index) => {
      if (account.verified) {
        const splitAccount = account.iban.split("-");
        const verifiedAccount: verifiedAccount = {
          display_name: splitAccount[0],
          iban: account.iban,
          ofuscateIban: splitAccount[1],
          verified: account.verified,
        };
        this.verifiedAccountList.push(verifiedAccount);
        this.verifiedAccounts++;
      }
    });

    if (this.verifiedAccountList.length > 0) {
      const firstAccount = this.verifiedAccountList[0].iban;
      this.selectedAccount = firstAccount;
      this.paymentBehaviour.account = firstAccount;
    }

    this.showVerificarButton = this.verifiedAccountList.length === 0 ? true : false;
  }

  private amountValidator = (control: FormControl) => {
    var depositModel: DepositModel = new DepositModel();
    depositModel.amount = control.value;

    var validateDepositModel: ValidateDepositModel = this.paymentBehaviour.validateAmount(depositModel);
    if (validateDepositModel.isValid) {
      return null;
    } else {
      this.errorMessageAmount = this.translate.instant(validateDepositModel.errorLocalizableId);
      if (!!validateDepositModel.minMaxAmount) {
        this.errorMessageAmount += " " + this.parseMoney.transform(validateDepositModel.minMaxAmount.toString());
      }
      return { valid: false };
    }
  };

  setInputKeyboardTrueLayer() {
    if (this.deviceService.isDesktop()) {
    } else {
      this.isActiveKeyboard = !this.isActiveKeyboard;
      this.setInputKeyboardtl.emit(this.isActiveKeyboard);
      this.isActiveKeyboard ? this.onKeyboardOpen.emit(true) : this.onKeyboardOpen.emit(false);
    }
  }

  setBlockCalculator(e) {
    this.blockCalculator = e;
  }

  closeKeyBoard(event: string) {
    this.setBlockCalculator(false);
    this.isActiveKeyboard = false;
    this.onKeyboardOpen.emit(false);
  }

  pressInputKeyBoard(event) {
    this.setBlockCalculator(event);
    this.paymentForm.get("amount").setValue(parseFloat(parseFloat(event).toFixed(2)));
  }

  public get isSpain() {
    return (
      this.globalVars.licenseType === LicenseTypes.Nacional || this.globalVars.licenseType === LicenseTypes.Madrid
    );
  }
}
