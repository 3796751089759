import { CommonModule } from "@angular/common";
import {
  AfterViewInit,
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  ElementRef,
  Input,
  OnChanges,
  QueryList,
  ViewChild,
  ViewChildren,
  inject,
} from "@angular/core";
import { AlertController } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";
import { Swiper, SwiperOptions } from "swiper";

// Services
import { TrackingService } from "@providers/TrackingService";
import { SportService } from "@sports-services/index";

// Pipes
import { FormatOdd } from "@pipes/formatOdd.pipe";
import { TruncateStringPipe } from "@pipes/truncateString.pipe";
import { SportsExtractDatePipe } from "@sports-pipes/index";

// Stores
import { NewTicketFacade } from "@sports-stores/ticket/index";

// Models
import { BetbuilderItem, BetbuilderItemMethods } from "@sports-models/index";

// Utils
import { Utils } from "@utils/Utils";

@Component({
  selector: "sb-marquee-betbuilder",
  templateUrl: "sb-marquee-betbuilder.html",
  standalone: true,
  imports: [CommonModule, TruncateStringPipe, SportsExtractDatePipe, FormatOdd, TranslateModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  styleUrls: ["./sb-marquee-betbuilder.scss"],
})
export class SbMarqueeBetbuilderComponent implements OnChanges, AfterViewInit {
  @ViewChildren("list") list: QueryList<any>;
  @Input() betbuilderEventsData: BetbuilderItem[];
  @ViewChild("betBuilderSwiper") swiperRef:
    | ElementRef<HTMLElement & { swiper?: Swiper } & { initialize: () => void }>
    | undefined;
  public swiper?: Swiper;
  config: SwiperOptions = {
    loop: false,
    slidesPerView: "auto",
    observer: true,
  };

  betbuilderItems: BetbuilderItem[] = [];

  extractDate = inject(SportsExtractDatePipe);
  trackingService = inject(TrackingService);
  newTicketFacade = inject(NewTicketFacade);
  sportService = inject(SportService);
  alertCtrl = inject(AlertController);

  public utils = inject(Utils);

  ngOnChanges() {
    if (this.betbuilderEventsData.length > 0) this.betbuilderItems = this.betbuilderEventsData;
    if (!this.swiper) return;
  }

  ngAfterViewInit() {
    if (this.swiperRef) {
      const swiperEl = Object.assign(this.swiperRef?.nativeElement, this.config);
      swiperEl.initialize();
      this.swiper = this.swiperRef?.nativeElement.swiper;
    }
  }

  navigate(NodeId: string) {
    this.sportService.getGameByNodeId(NodeId).subscribe((data) => {
      if (!data) {
        this.showDismiss("", "No hay mercados abiertos en este evento");
        return;
      }
      const nodeId = data.NodeId;
      const parentNodeId = data.ParentNodeId;
      this.sportService.deepLinkFromMarquee(parentNodeId, nodeId);
    });
  }

  async showDismiss(title: string, msg: string) {
    const alertOptions = {
      enableBackdropDismiss: false,
      title: title,
      message: msg,
      buttons: [
        {
          role: "cancel",
          text: "OK",
          handler: () => {
            console.log("Alert dismissed");
          },
        },
      ],
    };
    const alert = await this.alertCtrl.create(alertOptions);
    alert.present();
  }

  bet(betbuilderItem: BetbuilderItem) {
    if (this.isSelected(betbuilderItem)) {
      const selectedBetbuilderItem = this.newTicketFacade.ticketView.SmartLineItems.find(
        (item) => item.BetBuilderId === selectedBetbuilderItem.BetBuilderId,
      );
      this.newTicketFacade.removeSmartMarketItem(selectedBetbuilderItem.ItemId);
      return;
    }
    this.utils.loader();
    this.sportService.addBetbuilderItem(betbuilderItem.BetBuilderId).subscribe((resp) => {
      const smartMarketBet = BetbuilderItemMethods.createBetbuilderLineItem(betbuilderItem);
      delete resp["MaxSystem"];
      this.utils.closeLoader();
      this.newTicketFacade.sendAddSmartMarketItem(resp, smartMarketBet);
    });
  }

  isSelected(betbuilderItem: BetbuilderItem) {
    const ticket = this.newTicketFacade.ticketView;
    const betBuilderId = betbuilderItem.BetBuilderId;
    return ticket.SmartLineItems.some((item) => item.BetBuilderId === betBuilderId);
  }
}
