<!-- eslint-disable @angular-eslint/template/eqeqeq -->
<!-- Datos personales -->
<fieldset id="personalData" class="col s12 first-section" [formGroup]="formGrp">
  <legend class="flow-text title-section" *ngIf="!isMobile">{{ titleSection }}</legend>
  <!-- Genero -->
  <cdr-radio [options]="genders" [errorsCol]="errors['gender']" fcName="gender" [fgrp]="formGrp"></cdr-radio>
  <!-- Nombre -->
  <cdr-input
    length="50"
    [label]="labels.name"
    [errorsCol]="errors['name']"
    modal="modalName"
    fcName="name"
    [fgrp]="formGrp"
  ></cdr-input>
  <!-- Primer apellido -->
  <cdr-input
    length="50"
    [label]="labels.name2"
    [errorsCol]="errors['name2']"
    modal="modalName2"
    fcName="name2"
    [fgrp]="formGrp"
  ></cdr-input>
  <!-- Segundo apellido -->
  <cdr-input
    length="50"
    [label]="labels.name3"
    [errorsCol]="errors['name3']"
    modal="modalName3"
    fcName="name3"
    [fgrp]="formGrp"
  ></cdr-input>
  <!--Fecha de nacimiento: container -->
  <cdr-time
    [isMobile]="isMobile"
    [labels]="labels.birthDate"
    [errorsCol]="errors['birthDate']"
    modal="modalBirthdate"
    [title]="titleBirthDate"
    [fgrp]="formGrp"
  ></cdr-time>
  <!-- Tipo de residencia -->
  <cdr-select [options]="residentTypes" [label]="labels.resident" [fgrp]="formGrp" [fcName]="'resident'"></cdr-select>
  <!-- Nacionalidad | Campo para los dos tipos de residentes -->
  <cdr-select
    [options]="nationalities"
    [label]="labels.nationality"
    [errorsCol]="errors['nationality']"
    [fgrp]="formGrp"
    [fcName]="'nationality'"
  ></cdr-select>
  <!-- Campo Profesión-->
  <cdr-select-info
    [options]="jobs"
    [label]="labels.profesion"
    [errorsCol]="errors['profesion']"
    [fgrp]="formGrp"
    [fcName]="'profesion'"
    [modal]="'modalProfesion'"
    [placeHolder]="'selectoccupation'"
  ></cdr-select-info>
  <!-- Campo para NO residentes: Pais, Tipo de documento y  Número de documento -->
  <ng-container *ngIf="countries.length > 0 && formGrp.get('resident').value && formGrp.get('resident').value.id === 1">
    <!-- Pais | Campo para NO residentes -->
    <cdr-select
      [options]="countries"
      [label]="labels.country"
      [errorsCol]="errors['country']"
      [fgrp]="formGrp"
      [fcName]="'country'"
    ></cdr-select>
    <!-- Tipo de documento | Campo para NO residentes -->
    <cdr-select
      [options]="documentTypes"
      *ngIf="formGrp.get('nationality').value.id !== 71"
      [label]="labels.documentType"
      [errorsCol]="errors['documentType']"
      [fgrp]="formGrp"
      [fcName]="'documentType'"
    ></cdr-select>
    <!-- Número de documento | Campo para NO residentes y no NIE-->
    <cdr-input
      length="20"
      [label]="labels.documentNumber"
      [errorsCol]="errors['documentNumber']"
      [modal]="modalDocumenNumber"
      fcName="documentNumber"
      [fgrp]="formGrp"
      *ngIf="nationalities.length > 0 && formGrp.get('nationality').value.id !== 71"
    ></cdr-input>
  </ng-container>
  <!-- DNI | Campo para residentes, españoles no residentes, y extranjeros no residentes con NIE -->
  <ng-container *ngIf="canShowDniInput">
    <cdr-input
      length="9"
      [label]="labels.dni"
      [errorsCol]="errors['dni']"
      [modal]="modalDocumenNumberDNI"
      fcName="dni"
      [fgrp]="formGrp"
    ></cdr-input>
  </ng-container>
  <!-- Número de soporte -->
  <ng-container *ngIf="showSupportNumber">
    <cdr-input
      length="9"
      [label]="labels?.supportNumber"
      [errorsCol]="errors['supportNumber']"
      [modal]="'modalSupportNumber'"
      fcName="supportNumber"
      [fgrp]="formGrp"
    ></cdr-input>
  </ng-container>
</fieldset>
