/* eslint-disable eqeqeq */

import { CommonModule } from "@angular/common";
import { Component, EventEmitter, HostBinding, HostListener, Input, OnInit, Output } from "@angular/core";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";

@Component({
  selector: "cs-transaction-item",
  templateUrl: "./cs-transaction-item.html",
  styleUrls: ["./cs-transaction-item.scss"],
  standalone: true,
  imports: [CommonModule],
})
export class CsTransactionItemComponent implements OnInit {
  @Input() payment: any;
  @Input() paymentSelected: any = null;
  @Output() navigate: EventEmitter<any> = new EventEmitter();

  isRecommended: boolean = false;
  globalVars!: GlobalVarsModel;

  @HostBinding("class.cs-item-selected")
  get isSelected(): boolean {
    if (this.paymentSelected) {
      return this.paymentSelected.name === this.payment.name;
    } else {
      return false;
    }
  }

  @HostListener("click")
  _navigate() {
    this.navigate.emit(this.payment);
  }

  constructor() {}

  ngOnInit(): void {
    this.isRecommended = this.payment.isRecommended;
  }
}
