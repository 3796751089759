/* eslint-disable */
import { Component, Output, EventEmitter, Input, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { ParseStakeMoney } from '@pipes/parseStakeMoney';

import { C_TicketApuestasNew, TicketMethods } from '@models/C_TicketApuestas';
import { NewTicketFacade } from '@models/sports/store/facades/ticket.facade';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'betslip-overask',
  templateUrl: './betslip-overask.component.html',
  styleUrls: ['./betslip-overask.component.scss'],
  standalone: true,
  imports: [CommonModule, IonicModule, FormsModule, ParseStakeMoney, TranslateModule]
})
export class BetslipOveraskComponent {
  @Input() ticket: C_TicketApuestasNew;
  @Input() disabled;
  @Input() btnBetText;
  @Output() clickButton = new EventEmitter();

  newTicketFacade = inject(NewTicketFacade);

  betButton = '';

  constructor() {}

  ngOnChanges() {}

  ngAfterViewInit() {}

  ngOnDestroy() {}

  keepSelections() {
    console.log('keepSelections');
  }
  closeResume() {
    console.log('closeResume');
  }

  clearTicket() {
    console.log('clearTicket');
  }

  placeBet() {
    console.log('placeBet');
  }

  getTicketIsConfirmed(ticket: any): boolean {
    if (ticket != undefined && ticket != null) return TicketMethods.getIsConfirmed(ticket);
    else return false;
  }
}
