/* eslint-disable */
import { Component, Input, Output, EventEmitter, SimpleChanges, HostBinding, ElementRef, inject } from '@angular/core';
import { LineItem } from '../../../models/C_LineItem';
import { TicketSelectionMode } from '../../../models/sports/ticket-selection-mode.enum';
import { C_TicketApuestasNew, TicketMethods } from '../../../models/C_TicketApuestas';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FormatOdd } from '../../../pipes/formatOdd.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { GetNameGamePipe } from '../../../pipes/getNameGame.pipe';
import { ParseStakeMoney } from '../../../pipes/parseStakeMoney';
import { SBBetslipStakeComponent } from '@sports-components/index';
import { IonicModule } from '@ionic/angular';

@Component({
  selector: 'ticket-selection',
  templateUrl: './ticket-selection.html',
  styleUrls: ['./ticket-selection.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    FormatOdd,
    IonicModule,
    TranslateModule,
    GetNameGamePipe,
    ParseStakeMoney,
    SBBetslipStakeComponent
  ]
})
export class TicketSelectionComponent {
  @HostBinding('class.sb-ticket-selection')
  @HostBinding('class.background-color-dark')
  @Input()
  lineItem: LineItem | any;

  @Input() mode: TicketSelectionMode;
  @Input() betType: number;
  @Input() isTicketClosed: boolean = false;
  @Input() isDesktop: boolean;
  @Input() license: number;
  @Input() minBetValue: string;
  @Input() currency: string;
  @Input() keyboard: boolean;
  @Input() ticket: C_TicketApuestasNew;
  @Input() disabled: boolean;

  @Output() updateAmount: EventEmitter<any> = new EventEmitter();
  @Output() select: EventEmitter<any> = new EventEmitter();
  @Output() unselect: EventEmitter<any> = new EventEmitter();
  @Output() delete: EventEmitter<LineItem> = new EventEmitter();
  @Output() scrollTo: EventEmitter<any> = new EventEmitter();
  keyEvent: EventEmitter<any> = new EventEmitter();
  potentialWin: any;
  currencyLeft: boolean = false;

  private _host = inject(ElementRef);

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (!this.lineItem) return;
    this.potentialWin = (this.lineItem.AmountLocal * this.lineItem.Odd).toFixed(2);
  }

  ngAfterViewInit() {
    this.currencyLeft = this.currency === '$';
  }

  public remove() {
    this.delete.emit(this.lineItem);
  }

  public selectAmount() {
    if (this.lineItem.Selected && this.keyboard) return;
    this.select.emit(this.lineItem);
    this.scrollTo.emit(this._host.nativeElement.offsetTop);
  }

  public unselectLineItem() {
    this.unselect.emit(this.lineItem);
  }

  public updateStake(event: any) {
    this.selectAmount();
    this.updateAmount.emit(event);
  }

  get multiSingleActivated() {
    return this.ticket.BetType === 0 && TicketMethods.getNumberOfSelections(this.ticket) > 1 && !this.isTicketClosed;
  }

  @HostBinding('attr.mode')
  get selectionState() {
    let state = null;
    const lineErrors = this.lineItem.LineErrorMessages || [];
    if (lineErrors.length) {
      state = 'selection-error';
    }

    if (this.lineItem.GameLocked) {
      state = 'selection-locked';
    }

    if (this.lineItem['NotAvailable']) {
      state = 'selection-not-available';
    }

    return state;
  }

  getKeyboard() {
    return this.keyboard ? true : null;
  }

  public showOddArrows() {
    return !TicketMethods.getIsConfirmed(this.ticket);
  }

  public getIsProcessing(): boolean {
    return this.ticket.processing;
  }

  getTicketSelectionMode() {
    let state = '';
    const lineErrors = this.lineItem.LineErrorMessages || [];
    if (lineErrors.length) {
      state = 'selection-error';
    }

    if (this.lineItem.GameLocked) {
      state = 'selection-locked';
    }

    if (this.lineItem['NotAvailable']) {
      state = 'selection-not-available';
    }

    return state;
  }
}
