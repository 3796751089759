<!-- Desktop -->
<ng-container *ngIf="isDesktop; else mobileView">
  <div class="cs-withdrawals">
    <div class="cs-withdrawals--payments" [style.height.px]="utils.getModalHeight('ion-modal--height')">
      <div class="cs-withdrawals--section-withdrawals background-color-light">
        <cs-transaction-item *ngFor="let type of withdrawals$ | async" [payment]="type"
          [paymentSelected]="currentWithdrawal" (navigate)="selectOption($event)" class="cs-trans">
        </cs-transaction-item>
      </div>
    </div>

    <div class="cs-withdrawals--content" [style.height.px]="utils.getModalHeight('ion-modal--height')">
      <div class="cs-withdrawals--content-container" #content [ngClass]="currentWithdrawal ? 'default' : 'clicked'">
        <!-- STEP 1 -->
        <div class="cs-withdrawals--section background-color-light p-4">
          <i class="cs-empty-icon codere-icon icon-take-coin color-dark p-bottom-2"></i>
          <ng-container
            *ngIf="globalVars.licenseType === licenseCOL && COLRegCashierTxtActive; else defaultCashierView">
            <h4 class="color-dark">{{'colombiaCashiertxt' | translate}}</h4>
            <h4 class="color-dark">{{'colombiaCashiertxt1' | translate}}</h4>
            <h4 class="color-dark">{{'colombiaCashiertxt2' | translate}}</h4>
          </ng-container>
          <ng-template #defaultCashierView>
            <h4 class="text-center color-dark">{{'cashiercashiertxt' | translate}}</h4>
          </ng-template>
        </div>

        <!-- STEP 2 -->
        <div class="cs-withdrawals--section text-center background-color-light">
          <div class="cs-withdrawals--container w-100 p-2">
            <div class="text-left"
              *ngIf="currentWithdrawal?.className === WMClassName.PAY_PAL && withdrawalsPaypalWarningActive">
              <div class="info-icon icon-info-container">
                <ion-icon class="iconinforeg icon-info" name="information-circle-outline"></ion-icon>
                <span class="color-dark">
                  {{ 'withdrawalsPaypalWarningtxt' | translate }}
                </span>
              </div>
            </div>
            <div class="cs-withdrawals--img py-1" *ngIf="currentWithdrawal?.className !== 'CardSelectionWithdrawal'"
              [ngClass]="(currentWithdrawal?.className === 'LocalWithDrawal') ? 'cs-withdrawals--img-small' : 'cs-withdrawals--img'">
              <img src="assets/global/img/{{ currentWithdrawal?.image }}.png" alt="" />
            </div>
            <div *ngIf="currentWithdrawal?.className === 'CrownCasinoWithdrawal'">
              <cs-crown-casino [isDeposit]="false"></cs-crown-casino>
            </div>

            <div class="cs-withdrawals--address mx-1" *ngIf="currentWithdrawal?.className === 'NequiWithdrawal'">
              <span class="cs-withdrawals--phone-title my-1 w-100 color-score text-center">
                Antes de solicitar un cobro por Nequi, debes estar seguro que posees una cuenta a tu nombre en dicha
                plataforma y que tu teléfono registrado en tu perfil Codere
                <span class="cs-withdrawals--phone-title-bold"> ( {{ (userData$ | async)?.NumMovil }} ) </span> es el
                mimo al registrado en Nequi. Una vez que solicites el cobro, lo recibirás en un plazo máximo de 48h
                laborables. Si por el contrario no cumples con las condiciones arriba indicadas, tu solicitud será
                rechazada en las siguientes 48h laborables.
              </span>
            </div>

            <div class="cs-withdrawals--address mx-1" *ngIf="currentWithdrawal?.className === 'DaviPlataWithdrawal'">
              <span class="cs-withdrawals--phone-title my-1 w-100 color-score text-center">
                Antes de solicitar un cobro por Daviplata, debes estar seguro que posees una cuenta a tu nombre en dicha
                plataforma y que tu teléfono registrado en tu perfil Codere
                <span class="cs-withdrawals--phone-title-bold"> ( {{ (userData$ | async)?.NumMovil }} ) </span> es el
                mimo al registrado en Daviplata. Una vez que solicites el cobro, lo recibirás en un plazo máximo de 48h
                laborables. Si por el contrario no cumples con las condiciones arriba indicadas, tu solicitud será
                rechazada en las siguientes 48h laborables.
              </span>
            </div>

            <div class="cs-withdrawals--transfer-mendoza-content"
              *ngIf="currentWithdrawal?.className === 'BankTransferWithDrawal' && globalVars.licenseType === licenseMDZ">
              <p class="cs-withdrawals--transfer-mendoza-text">{{ 'withdrawalstransfermendozatxt' | translate }}</p>
              <p class="cs-withdrawals--transfer-mendoza-text"
                [innerHTML]="'withdrawalstransfermendozaemail' | translate"></p>
            </div>

            <div class="cs-withdrawals--address mx-1" *ngIf="currentWithdrawal?.className === 'CoinspaidWithDrawal'">
              <span class="cs-withdrawals--address-title my-1 w-100 color-score text-center">Dirección</span>
              <form class="cs-withdrawals--address-amount background-color-white w-100">
                <input class="cs-withdrawals--address-input w-100 text-center m-unset color-dark" type="text"
                  [(ngModel)]="currentAddress" name="currentAddress" #input />
              </form>
            </div>

            <ng-container *ngIf="currentWithdrawal?.className === 'TrueLayerWithdrawal'">
              <!--TODO: revisar texto Spain-->
              <!-- <p class="instant-alert-text" *ngIf="isSpain">
                Debido al tiempo que tardan los bancos en procesar los pagos, no podemos garantizarte el momento exacto
                en que se te abonará el depósito. Tan pronto como el banco nos lo permita, te transferiremos el dinero.
                El tiempo de espera es de menos de 2 minutos con la mayoría de los bancos, pero Revolut y BBVA son aún
                más rápidos. Sin embargo, si tu cuenta es de Caixa o ING, ten en cuenta que tu depósito puede tardar
                unas horas.
              </p> -->
              <div class="cs-withdrawals--address mx-1" *ngIf="trueLayerVerifiedAccountList?.length !== 0">
                <ion-list class="bg-list" *ngIf="trueLayerVerifiedAccountList?.length">
                  <span class="cs-withdrawals--accounts--text"> Selecciona una cuenta bancaria: </span>
                  <ion-item class="item-select">
                    <ion-label class="color-score">Selecciona una cuenta:</ion-label>
                    <ion-select [(ngModel)]="selectedAccount" (ionChange)="selectAccount($event)">
                      <ion-select-option value="newAccount"> Verificar nueva cuenta </ion-select-option>
                      <ion-select-option *ngFor="let account of trueLayerVerifiedAccountList, index as i"
                        value="{{account.iban}}">
                        {{account.iban | obfuscate}}
                      </ion-select-option>
                    </ion-select>
                  </ion-item>
                </ion-list>
              </div>
              <ng-container *ngIf="trueLayerVerifiedAccountList?.length === 0 || showVerificarButton">
                <span class="step1-truelayer--accounts--text">
                  Conecta y verifica tu cuenta bancaria de forma fácil y segura.
                </span>
                <button (click)="verifyAccount()" class="cs-action-button background-color-secondary" tappable>
                  <span class="cs-action-button--text color-light"> {{ 'check' | translate }} </span>
                </button>
              </ng-container>

              <ng-container *ngIf="trueLayerVerifiedAccountList.length !== 0 && !showVerificarButton">
                <cs-calculator [options]="calculatorConfig" [paymentSelected]="currentWithdrawal"
                  [amount]="currentAmount" class="calculator-truelayer" [isActiveKeyboard]="isActiveKeyboard"
                  (amountSelected)="amountSelected($event)" (blockOperation)="handleCalculatorOperationBlocked($event)"
                  (setCustomAmount)="setCustomAmount($event)" (inputAmount)="inputAmount($event)">
                </cs-calculator>

                <button [disabled]="isActiveKeyboard || isOperationBlocked"
                  class="cs-action-button background-color-secondary" tappable (click)="checkStepWithdrawal()"
                  *ngIf="currentWithdrawal?.className === 'TrueLayerWithdrawal'">
                  <span class="cs-action-button--text color-light"> Cobrar </span>
                </button>
              </ng-container>
            </ng-container>

            <cs-calculator *ngIf="showCashier(true)" [options]="calculatorConfig" [paymentSelected]="currentWithdrawal"
              [amount]="currentAmount" [isActiveKeyboard]="isActiveKeyboard" (amountSelected)="amountSelected($event)"
              (blockOperation)="handleCalculatorOperationBlocked($event)" (setCustomAmount)="setCustomAmount($event)"
              (inputAmount)="inputAmount($event)">
            </cs-calculator>

            <cs-tpaga-info *ngIf="currentWithdrawal?.className === 'TPagaWithDrawal'"> </cs-tpaga-info>

            <button [disabled]="isActiveKeyboard || isOperationBlocked"
              class="cs-action-button background-color-secondary" tappable (click)="checkStepWithdrawal()"
              *ngIf="showCashier(true)">
              <span class="cs-action-button--text color-light"> Cobrar </span>
            </button>

            <div *ngIf="currentWithdrawal?.className === 'HalCashWithDrawal'" class="cs-halCash-info" tappable
              (click)="showInfo('halCash')">
              <ion-icon name="help-circle-outline" class="color-score"></ion-icon>
              <span class="color-score cs-halCash-info--title p-left-1"
                [innerHTML]="'v2_infoHalCash' | translate"></span>
            </div>
          </div>
        </div>

        <!-- STEP 3 -->
        <div *ngIf="!!currentWithdrawal && sectionStep === 3" class="cs-withdrawals--section background-color-light">
          <div [ngSwitch]="currentWithdrawal?.className" class="w-100 p-1">
            <cs-halcash-form *ngSwitchCase="'HalCashWithDrawal'" [phoneNumber]="(userData$ | async)?.NumMovil"
              (formHalcashValue)="emitHalcashWithdrawal($event)" (inputInfo)="showInfo($event)">
            </cs-halcash-form>

            <cs-bank-transfer-form *ngSwitchCase="'BankTransferWithDrawal'" [license]="globalVars?.licenseType"
              [userData]="(userData$ | async)" (formTransferValue)="emitTransferWithdrawal($event)"
              (inputInfo)="showInfo($event)">
            </cs-bank-transfer-form>

            <cs-bank-transfer-form *ngSwitchCase="'ItauWithDrawal'" [license]="globalVars?.licenseType"
              [userData]="(userData$ | async)" (formTransferValue)="emitTransferWithdrawal($event)"
              (inputInfo)="showInfo($event)">
            </cs-bank-transfer-form>

            <cs-bank-transfer-form *ngSwitchCase="'ClabeWithDrawal'" [license]="globalVars?.licenseType"
              [banksEnabled]="globalVars.clabeBanksWithDrawalEnabled" [userData]="(userData$ | async)"
              (formTransferValue)="emitAstropayTransferWithdrawal($event)" (inputInfo)="showInfo($event)">
            </cs-bank-transfer-form>

            <cs-local-codere *ngSwitchCase="'LocalWithDrawal'" [license]="globalVars?.licenseType"
              [localConfig]="localConfig" (openCodereLocals)="openCodereLocals()"
              (cancelLocalCode)="cancelLocalCode($event)">
            </cs-local-codere>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<!-- Mobile -->
<ng-template #mobileView>
  <div class="cs-withdrawals" #content>
    <!-- STEP 1 -->
    <div class="cs-withdrawals--section background-color-light">
      <cs-transaction-item *ngFor="let type of withdrawals$ | async" [payment]="type" (navigate)="selectOption($event)">
      </cs-transaction-item>
    </div>

    <!-- STEP 2 -->
    <div class="cs-withdrawals--section text-center background-color-light">
      <div class="text-left"
        *ngIf="currentWithdrawal?.className === WMClassName.PAY_PAL && withdrawalsPaypalWarningActive">
        <div class="info-icon icon-info-container">
          <ion-icon class="iconinforeg icon-info" name="information-circle-outline"></ion-icon>
          <span class="color-dark">
            {{ 'withdrawalsPaypalWarningtxt' | translate }}
          </span>
        </div>
      </div>
      <!--Crown casino-->
      <div class="cs-deposit--container background-color-light w-100 p-1"
        *ngIf="currentWithdrawal?.className === 'CrownCasinoWithdrawal'">
        <cs-crown-casino></cs-crown-casino>
      </div>
      <div class="cs-withdrawals--content w-100 fixed-height">
        <div class="cs-withdrawals--address mx-1" *ngIf="currentWithdrawal?.className === 'NequiWithdrawal'">
          <span class="cs-withdrawals--phone-title my-1 w-100 color-score text-center">
            Antes de solicitar un cobro por Nequi, debes estar seguro que posees una cuenta a tu nombre en dicha
            plataforma y que tu teléfono registrado en tu perfil Codere
            <span class="cs-withdrawals--phone-title-bold"> ( {{ (userData$ | async)?.NumMovil }} ) </span> es el mimo
            al registrado en Nequi. Una vez que solicites el cobro, lo recibirás en un plazo máximo de 48h laborables.
            Si por el contrario no cumples con las condiciones arriba indicadas, tu solicitud será rechazada en las
            siguientes 48h laborables.
          </span>
        </div>

        <div class="cs-withdrawals--address mx-1" *ngIf="currentWithdrawal?.className === 'DaviPlataWithdrawal'">
          <span class="cs-withdrawals--phone-title my-1 w-100 color-score text-center">
            Antes de solicitar un cobro por Daviplata, debes estar seguro que posees una cuenta a tu nombre en dicha
            plataforma y que tu teléfono registrado en tu perfil Codere
            <span class="cs-withdrawals--phone-title-bold"> ( {{ (userData$ | async)?.NumMovil }} ) </span> es el mismo
            al registrado en Daviplata.<br />
            Una vez que solicites el cobro, lo recibirás en un plazo máximo de 48h laborables. Si por el contrario no
            cumples con las condiciones arriba indicadas, tu solicitud será rechazada en las siguientes 48h laborables.
          </span>
        </div>
        <!--TODO: No deberia aparecer-->
        <!-- <div
          class="cs-withdrawals--address mx-1"
          style="height: 100%"
          *ngIf="currentWithdrawal?.className === 'CrownCasinoWithdrawal'"
        >
          <span [innerHTML]="'CrowCasinoWD' | translate"> </span>
        </div> -->

        <div class="cs-withdrawals--transfer-mendoza-content"
          *ngIf="currentWithdrawal?.className === 'BankTransferWithDrawal' && globalVars.licenseType === licenseMDZ">
          <p class="cs-withdrawals--transfer-mendoza-text">{{ 'withdrawalstransfermendozatxt' | translate }}</p>
          <p class="cs-withdrawals--transfer-mendoza-text" [innerHTML]="'withdrawalstransfermendozaemail' | translate">
          </p>
        </div>

        <div class="mx-1 cs-withdrawals--address" *ngIf="currentWithdrawal?.className === 'CoinspaidWithDrawal'">
          <span class="cs-withdrawals--address-title my-1 w-100 color-score text-center">Dirección</span>
          <form class="cs-withdrawals--address-amount background-color-white w-100">
            <input class="cs-withdrawals--address-input mx-1 background-color-white" type="text"
              [(ngModel)]="currentAddress" name="currentAddress" #input />
          </form>
        </div>
        <ng-container *ngIf="currentWithdrawal?.className === 'TrueLayerWithdrawal'">
          <!--TODO: revisar texto Spain-->
          <!-- <p class="instant-alert-text" *ngIf="isSpain">
            Debido al tiempo que tardan los bancos en procesar los pagos, no podemos garantizarte el momento exacto en
            que se te abonará el depósito. Tan pronto como el banco nos lo permita, te transferiremos el dinero. El
            tiempo de espera es de menos de 2 minutos con la mayoría de los bancos, pero Revolut y BBVA son aún más
            rápidos. Sin embargo, si tu cuenta es de Caixa o ING, ten en cuenta que tu depósito puede tardar unas horas.
          </p> -->
          <div class="cs-withdrawals--address mx-1" *ngIf="trueLayerVerifiedAccountList !== 0">
            <span class="cs-withdrawals--accounts--text"> Selecciona una cuenta bancaria: </span>
            <ion-list class="bg-list" *ngIf="trueLayerVerifiedAccountList?.length">
              <ion-item class="item-select">
                <ion-select [(ngModel)]="selectedAccount" (ionChange)="selectAccount($event)"
                  aria-label="Selecciona una cuenta:">
                  <ion-select-option value="newAccount"> Verificar nueva cuenta </ion-select-option>
                  <ion-select-option *ngFor="let account of trueLayerVerifiedAccountList, index as i"
                    value="{{account.iban}}">
                    {{account.iban | obfuscate}}
                  </ion-select-option>
                </ion-select>
              </ion-item>
            </ion-list>
          </div>
          <ng-container *ngIf="trueLayerVerifiedAccountList.length === 0 || showVerificarButton">
            <span class="step1-truelayer--accounts--text">
              Conecta y verifica tu cuenta bancaria de forma fácil y segura.
            </span>
            <button (click)="verifyAccount()" class="cs-action-button background-color-secondary" tappable>
              <span class="cs-action-button--text color-light"> {{ 'check' | translate }} </span>
            </button>
          </ng-container>

          <ng-container *ngIf="trueLayerVerifiedAccountList.length !== 0 && !showVerificarButton">
            <cs-calculator [options]="calculatorConfig" [paymentSelected]="currentWithdrawal" [amount]="currentAmount"
              class="calculator-truelayer" [isActiveKeyboard]="isActiveKeyboard"
              (amountSelected)="amountSelected($event)" (blockOperation)="handleCalculatorOperationBlocked($event)"
              (setCustomAmount)="setCustomAmount($event)" (setInputKeyboard)="setInputKeyboard()">
            </cs-calculator>
            <button [disabled]="isActiveKeyboard || isOperationBlocked"
              class="cs-action-button background-color-secondary" tappable (click)="checkStepWithdrawal()"
              *ngIf="currentWithdrawal?.className === 'TrueLayerWithdrawal'">
              <span class="cs-action-button--text color-light"> Cobrar </span>
            </button>
          </ng-container>
        </ng-container>
        <cs-calculator *ngIf="showCashier(true)" [options]="calculatorConfig" [paymentSelected]="currentWithdrawal"
          [amount]="currentAmount" [isActiveKeyboard]="isActiveKeyboard" (amountSelected)="amountSelected($event)"
          (blockOperation)="handleCalculatorOperationBlocked($event)" class="main-calculator"
          (setCustomAmount)="setCustomAmount($event)" (setInputKeyboard)="setInputKeyboard()">
        </cs-calculator>

        <cs-tpaga-info *ngIf="currentWithdrawal?.className === 'TPagaWithDrawal'"> </cs-tpaga-info>

        <button [disabled]="isActiveKeyboard || isOperationBlocked" class="cs-action-button background-color-secondary"
          tappable (click)="checkStepWithdrawal()" *ngIf="showCashier(true)">
          <span class="cs-action-button--text color-light"> Cobrar </span>
        </button>

        <div *ngIf="currentWithdrawal?.className === 'HalCashWithDrawal'" class="cs-halCash-info" tappable
          (click)="showInfo('halCash')">
          <ion-icon name="help-circle-outline"></ion-icon>
          <span class="cs-halCash-info--title p-left-1" [innerHTML]="'v2_infoHalCash' | translate"></span>
        </div>
      </div>
    </div>

    <!-- STEP 3 -->
    <div *ngIf="!!currentWithdrawal && sectionStep === 3" class="cs-withdrawals--section background-color-light">
      <div [ngSwitch]="currentWithdrawal?.className" class="w-100 p-1">
        <cs-halcash-form *ngSwitchCase="'HalCashWithDrawal'" [phoneNumber]="(userData$ | async)?.NumMovil"
          (formHalcashValue)="emitHalcashWithdrawal($event)" (inputInfo)="showInfo($event)">
        </cs-halcash-form>

        <cs-bank-transfer-form *ngSwitchCase="'BankTransferWithDrawal'" [license]="globalVars?.licenseType"
          [userData]="(userData$ | async)" (formTransferValue)="emitTransferWithdrawal($event)"
          (inputInfo)="showInfo($event)">
        </cs-bank-transfer-form>

        <cs-bank-transfer-form *ngSwitchCase="'ItauWithDrawal'" [license]="globalVars?.licenseType"
          [userData]="(userData$ | async)" (formTransferValue)="emitTransferWithdrawal($event)"
          (inputInfo)="showInfo($event)">
        </cs-bank-transfer-form>

        <cs-bank-transfer-form *ngSwitchCase="'ClabeWithDrawal'" [license]="globalVars?.licenseType"
          [banksEnabled]="globalVars.clabeBanksWithDrawalEnabled" [userData]="(userData$ | async)"
          (formTransferValue)="emitAstropayTransferWithdrawal($event)" (inputInfo)="showInfo($event)">
        </cs-bank-transfer-form>

        <cs-local-codere *ngSwitchCase="'LocalWithDrawal'" [license]="globalVars?.licenseType"
          [localConfig]="localConfig" (openCodereLocals)="openCodereLocals()"
          (cancelLocalCode)="cancelLocalCode($event)">
        </cs-local-codere>
      </div>
    </div>
  </div>
  <custom-keyboard *ngIf="isActiveKeyboard && sectionStep === 2" [active]="isActiveKeyboard" [cashier]="true"
    (closeKeyBoard)="closeKeyBoard($event)" (press)="pressInputKeyBoard($event)"></custom-keyboard>
</ng-template>