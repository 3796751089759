<section class="background-color-dark">
  <!-- BET / WINNINGGS -->
  <ion-grid *ngIf="getTicketIsConfirmed(ticket) || +ticket?.OveraskInfo?.Status === 0">
    <ion-row>
      <ion-col>
        <span class="bs-ticket-title left"> {{ 'uPPExtractTap' | translate }} </span>
      </ion-col>
      <ion-col>
        <span class="bs-ticket-title right"> {{ ticket?.TotalLocal | parseStakeMoney: [1] }}</span>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <span class="bs-ticket-title left"> {{ 'hDetailsGan' | translate }}: </span>
      </ion-col>
      <ion-col>
        <span class="bs-ticket-title right"> {{ ticket?.TotalPotentialWin.toString() | parseStakeMoney: [1] }}</span>
        <span *ngIf="+ticket?.OveraskInfo?.Status === 0" class="bs-ticket-title right">
          {{ ticket?.TotalPotentialWin.toString() | parseStakeMoney: [1] }}
        </span>
      </ion-col>
    </ion-row>
  </ion-grid>

  <!-- TICKET CONFIRMED -->
  <ion-grid *ngIf="ticket?.OveraskInfo?.Status === 'OK' && getTicketIsConfirmed(ticket)">
    <ion-row>
      <ion-col>
        <ion-button class="bs-ticket-button left sportsbook btn-light" (click)="onKeepSelectionsClick()">
          <ion-label class="bs-ticket-button--title font-weight-bolder"> {{ 'keepselections' | translate }} </ion-label>
        </ion-button>
      </ion-col>
      <ion-col>
        <ion-button class="bs-ticket-button right sportsbook btn-primary" (click)="onClearTicketClick()">
          <ion-label class="bs-ticket-button--title font-weight-bolder"> OK </ion-label>
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>

  <!-- TICKET OVERASK WITH STATUS 0 : Aprobación Requerida -->
  <ion-grid *ngIf="+ticket?.OveraskInfo?.Status === 0">
    <ion-row>
      <ion-col>
        <ion-button class="bs-ticket-button left sportsbook btn-light" (click)="onCloseResumeClick()">
          <ion-label class="bs-ticket-button--title font-weight-bolder"> CANCELAR </ion-label>
        </ion-button>
      </ion-col>
      <ion-col>
        <ion-button class="bs-ticket-button right sportsbook btn-transparent" (click)="onClearTicketClick()">
          <ion-label class="bs-ticket-button--title color-primary font-weight-bolder"> Espera por favor </ion-label>
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>

  <!-- TICKET OVERASK WITH STATUS 1 : Apuesta Máxima Posible -->
  <ion-grid *ngIf="+ticket?.OveraskInfo?.Status === 1">
    <ion-row>
      <ion-col>
        <span class="bs-ticket-title left">
          La oferta expira en:
          <span class="color-primary"> {{ newTicketFacade.overaskCountdown$ | async }} segundos </span>
        </span>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-button class="bs-ticket-button left sportsbook btn-light" (click)="onCloseResumeClick()">
          <ion-label class="bs-ticket-button--title font-weight-bolder"> Cancelar </ion-label>
        </ion-button>
      </ion-col>
      <ion-col>
        <ion-button class="bs-ticket-button right sportsbook btn-primary" (click)="onPlaceBetClick()">
          <ion-label class="bs-ticket-button--title">
            <span class="sb-ticket--button-title text-uppercase"> {{ btnBetText }} </span>
            <span class="sb-ticket--button-title text-small">
              {{ 'hDetailsGan' | translate }}: {{ ticket?.TotalPotentialWin.toString() | parseStakeMoney: [1] }}
            </span>
          </ion-label>
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>

  <!-- TICKET OVERASK WITH STATUS 2 / 3 : Rejected / Timeout -->
  <ion-grid *ngIf="+ticket?.OveraskInfo?.Status > 1">
    <ion-row>
      <ion-col>
        <ion-button class="bs-ticket-button left sportsbook btn-light" (click)="onKeepSelectionsClick()">
          <ion-label class="bs-ticket-button--title font-weight-bolder"> {{ 'keepselections' | translate }} </ion-label>
        </ion-button>
      </ion-col>
      <ion-col>
        <ion-button class="bs-ticket-button right sportsbook btn-primary" (click)="onClearTicketClick()">
          <ion-label class="bs-ticket-button--title font-weight-bolder">
            {{ 'v2_eliminarTodo' | translate }}
          </ion-label>
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</section>
