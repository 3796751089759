<ng-container *ngIf="!isMobile; else mobileView">
  <ion-content class="background-color-muted">
    <div class="is-narrow-content">
      <user-section-title [sectionTitle]="'CodereLinkedCard'"></user-section-title>
      <form [formGroup]="myForm">
        <ion-list>
          <ion-list-header class="is-text-left">
            <ion-avatar class="card-pin-avatar"><i class="codere-icon icon-cardPin"></i></ion-avatar>
            <ion-label>{{'v2_intNewPin' | translate}}<!--# INTRODUCE TU NUEVO PIN--></ion-label>
          </ion-list-header>
          <div class="introPinDiv">
            <ion-label class="introPin">
              {{'GetCardenterpintxt' | translate}}<!--Debe de estar formado por cuatro dígitos del 0 al 9.--></ion-label
            >
          </div>
          <div class="myWrapInput">
            <ion-item
              lines="none"
              [class.error]="!myForm.controls['pin'].valid && myForm.controls['pin'].touched"
              class="cdr-item-input"
            >
              <ion-input
                label="{{'GetCardPin' | translate}}*"
                labelPlacement="floating"
                [type]="showPassword['pin'] ? 'text' : 'password'"
                clearOnEdit="false"
                autocomplete="off"
                autoCorrect="off"
                spellcheck="off"
                maxlength="4"
                formControlName="pin"
              >
              </ion-input>
              <ion-icon [name]="showPassword['pin'] ? 'eye-off' : 'eye'" (click)="toggleTypeInput('pin')"></ion-icon>
            </ion-item>
            <div class="labelError" [hidden]="myForm.controls['pin'].valid || !myForm.controls['pin'].touched">
              <span *ngIf="myForm.controls['pin'].hasError('required')">
                {{'v2_pinObl' | translate}}<!--# El pin es obligatorio--><br
              /></span>
              <span *ngIf="myForm.controls['pin'].hasError('pattern')">
                {{'v2_pinNum' | translate}}<!--# El pin debe contener sólo números--><br
              /></span>
              <span *ngIf="myForm.controls['pin'].hasError('maxlength')">
                {{'v2_pinNum1' | translate}}<!--# El pin debe contener 4 números--><br
              /></span>
              <span *ngIf="myForm.controls['pin'].hasError('minlength')">
                {{'v2_pinNum1' | translate}}<!--# El pin debe contener 4 números--><br
              /></span>
            </div>
          </div>

          <div class="myWrapInput">
            <ion-item
              lines="none"
              [class.error]="!myForm.controls['newPin'].valid && myForm.controls['newPin'].touched"
              class="cdr-item-input"
            >
              <ion-input
                label="{{'GetCardPinnew' | translate}}*"
                labelPlacement="floating"
                [type]="showPassword['newPin'] ? 'text' : 'password'"
                clearOnEdit="false"
                autocomplete="off"
                autoCorrect="off"
                spellcheck="off"
                maxlength="4"
                formControlName="newPin"
              >
              </ion-input>
              <ion-icon
                [name]="showPassword['newPin'] ? 'eye-off' : 'eye'"
                (click)="toggleTypeInput('newPin')"
              ></ion-icon>
            </ion-item>
            <div class="labelError" [hidden]="myForm.controls['newPin'].valid || !myForm.controls['newPin'].touched">
              <span *ngIf="myForm.controls['newPin'].hasError('pinNotConsecutiveNumbers')">
                {{'v2_pinRegExNotEqNotConsec' | translate}}<br
              /></span>
              <span *ngIf="myForm.controls['newPin'].hasError('pattern')">
                {{'v2_pinRegExNotEqNotConsec' | translate}}<!--# El pin debe contener sólo números--><br
              /></span>
              <span *ngIf="myForm.controls['newPin'].hasError('required')">
                {{'v2_pinObl' | translate}}<!--# El pin es obligatorio--></span
              >
              <span *ngIf="myForm.controls['newPin'].hasError('maxlength')">
                {{'v2_pinNum1' | translate}}<!--# El pin debe contener 4 números--><br
              /></span>
              <span *ngIf="myForm.controls['newPin'].hasError('minlength')">
                {{'v2_pinNum1' | translate}}<!--# El pin debe contener 4 números--><br
              /></span>
            </div>
          </div>
          <div class="myWrapInput">
            <ion-item
              lines="none"
              [class.error]="!myForm.controls['reNewPin'].valid && myForm.controls['reNewPin'].touched"
              class="cdr-item-input"
            >
              <ion-input
                label="{{'GetCardPinconfirm' | translate}}*"
                labelPlacement="floating"
                [type]="showPassword['reNewPin'] ? 'text' : 'password'"
                clearOnEdit="false"
                autocomplete="off"
                autoCorrect="off"
                spellcheck="off"
                maxlength="4"
                formControlName="reNewPin"
              >
              </ion-input>
              <ion-icon
                [name]="showPassword['reNewPin'] ? 'eye-off' : 'eye'"
                (click)="toggleTypeInput('reNewPin')"
              ></ion-icon>
            </ion-item>
            <div
              class="labelError"
              [hidden]="myForm.controls['reNewPin'].valid || !myForm.controls['reNewPin'].touched"
            >
              <span *ngIf="myForm.controls['reNewPin'].hasError('pinNotConsecutiveNumbers')">
                {{'v2_pinRegExNotEqNotConsec' | translate}}<br
              /></span>
              <span *ngIf="myForm.controls['reNewPin'].hasError('pattern')">
                {{'v2_pinRegExNotEqNotConsec' | translate}}<!--# El pin debe contener sólo números--><br
              /></span>
              <span *ngIf="myForm.controls['reNewPin'].hasError('required')">
                {{'v2_pinObl' | translate}}<!--# El pin es obligatorio--><br
              /></span>
              <span *ngIf="myForm.controls['reNewPin'].hasError('maxlength')">
                {{'v2_pinNum1' | translate}}<!--# El pin debe contener 4 números--><br
              /></span>
              <span *ngIf="myForm.controls['reNewPin'].hasError('minlength')">
                {{'v2_pinNum1' | translate}}<!--# El pin debe contener 4 números--><br
              /></span>
              <span *ngIf="myForm.controls['reNewPin'].hasError('same')">
                {{'v2_errorPin' | translate}}<!--# El pin no coincide--><br
              /></span>
            </div>
          </div>
          <div class="sendForm">
            <ion-button expand="full" (click)="sendPin()"
              >{{'GetCardChangePin' | translate}}<!--Cambiar pin--></ion-button
            >
          </div>
        </ion-list>
      </form>
    </div>
    <codere-footer></codere-footer>
  </ion-content>
</ng-container>

<ng-template #mobileView>
  <!-- eslint-disable @angular-eslint/template/eqeqeq -->
  <ion-header>
    <user-section-title [sectionTitle]="'CodereLinkedCard'"></user-section-title>
  </ion-header>

  <ion-content class="background-color-muted">
    <form [formGroup]="myForm">
      <ion-list>
        <ion-list-header class="is-text-left">
          <ion-avatar class="card-pin-avatar"><i class="codere-icon icon-cardPin"></i></ion-avatar>
          <ion-label
            ><span>{{'v2_intNewPin' | translate}}</span
            ><!--# INTRODUCE TU NUEVO PIN--></ion-label
          >
        </ion-list-header>
        <div class="introPinDiv">
          <ion-label class="introPin">
            {{'GetCardenterpintxt' | translate}}<!--Debe de estar formado por cuatro dígitos del 0 al 9.--></ion-label
          >
        </div>
        <div class="myWrapInput">
          <ion-item
            lines="none"
            [class.error]="!myForm.controls['pin'].valid && myForm.controls['pin'].touched"
            class="cdr-item-input"
          >
            <ion-input
              label="{{'GetCardPin' | translate}}*"
              labelPlacement="floating"
              [type]="showPassword['pin'] ? 'text' : 'password'"
              clearOnEdit="false"
              autocomplete="off"
              autoCorrect="off"
              spellcheck="off"
              maxlength="4"
              formControlName="pin"
            >
            </ion-input>
            <ion-icon [name]="showPassword['pin'] ? 'eye-off' : 'eye'" (click)="toggleTypeInput('pin')"></ion-icon>
          </ion-item>
          <div class="labelError" [hidden]="myForm.controls['pin'].valid || !myForm.controls['pin'].touched">
            <span *ngIf="myForm.controls['pin'].hasError('required')">
              {{'v2_pinObl' | translate}}<!--# El pin es obligatorio--><br
            /></span>
            <span *ngIf="myForm.controls['pin'].hasError('pattern')">
              {{'v2_pinNum' | translate}}<!--# El pin debe contener sólo números--><br
            /></span>
            <span *ngIf="myForm.controls['pin'].hasError('maxlength')">
              {{'v2_pinNum1' | translate}}<!--# El pin debe contener 4 números--><br
            /></span>
            <span *ngIf="myForm.controls['pin'].hasError('minlength')">
              {{'v2_pinNum1' | translate}}<!--# El pin debe contener 4 números--><br
            /></span>
          </div>
        </div>

        <div class="myWrapInput">
          <ion-item
            lines="none"
            [class.error]="!myForm.controls['newPin'].valid && myForm.controls['newPin'].touched"
            class="cdr-item-input"
          >
            <ion-input
              label="{{'GetCardPinnew' | translate}}*"
              labelPlacement="floating"
              [type]="showPassword['newPin'] ? 'text' : 'password'"
              clearOnEdit="false"
              autocomplete="off"
              autoCorrect="off"
              spellcheck="off"
              maxlength="4"
              formControlName="newPin"
            >
            </ion-input>
            <ion-icon
              [name]="showPassword['newPin'] ? 'eye-off' : 'eye'"
              (click)="toggleTypeInput('newPin')"
            ></ion-icon>
          </ion-item>
          <div class="labelError" [hidden]="myForm.controls['newPin'].valid || !myForm.controls['newPin'].touched">
            <span *ngIf="myForm.controls['newPin'].hasError('pinNotConsecutiveNumbers')">
              {{'v2_pinRegExNotEqNotConsec' | translate}}<br
            /></span>
            <span *ngIf="myForm.controls['newPin'].hasError('pattern')">
              {{'v2_pinRegExNotEqNotConsec' | translate}}<!--# El pin debe contener sólo números--><br
            /></span>
            <span *ngIf="myForm.controls['newPin'].hasError('required')">
              {{'v2_pinObl' | translate}}<!--# El pin es obligatorio--></span
            >
            <span *ngIf="myForm.controls['newPin'].hasError('maxlength')">
              {{'v2_pinNum1' | translate}}<!--# El pin debe contener 4 números--><br
            /></span>
            <span *ngIf="myForm.controls['newPin'].hasError('minlength')">
              {{'v2_pinNum1' | translate}}<!--# El pin debe contener 4 números--><br
            /></span>
          </div>
        </div>
        <div class="myWrapInput">
          <ion-item
            lines="none"
            [class.error]="!myForm.controls['reNewPin'].valid && myForm.controls['reNewPin'].touched"
            class="cdr-item-input"
          >
            <ion-input
              label="{{'GetCardPinconfirm' | translate}}*"
              labelPlacement="floating"
              [type]="showPassword['reNewPin'] ? 'text' : 'password'"
              clearOnEdit="false"
              autocomplete="off"
              autoCorrect="off"
              spellcheck="off"
              maxlength="4"
              formControlName="reNewPin"
            >
            </ion-input>
            <ion-icon
              [name]="showPassword['reNewPin'] ? 'eye-off' : 'eye'"
              (click)="toggleTypeInput('reNewPin')"
            ></ion-icon>
          </ion-item>
          <div class="labelError" [hidden]="myForm.controls['reNewPin'].valid || !myForm.controls['reNewPin'].touched">
            <span *ngIf="myForm.controls['reNewPin'].hasError('pinNotConsecutiveNumbers')">
              {{'v2_pinRegExNotEqNotConsec' | translate}}<br
            /></span>
            <span *ngIf="myForm.controls['reNewPin'].hasError('pattern')">
              {{'v2_pinRegExNotEqNotConsec' | translate}}<!--# El pin debe contener sólo números--><br
            /></span>
            <span *ngIf="myForm.controls['reNewPin'].hasError('required')">
              {{'v2_pinObl' | translate}}<!--# El pin es obligatorio--><br
            /></span>
            <span *ngIf="myForm.controls['reNewPin'].hasError('maxlength')">
              {{'v2_pinNum1' | translate}}<!--# El pin debe contener 4 números--><br
            /></span>
            <span *ngIf="myForm.controls['reNewPin'].hasError('minlength')">
              {{'v2_pinNum1' | translate}}<!--# El pin debe contener 4 números--><br
            /></span>
            <span *ngIf="myForm.controls['reNewPin'].hasError('same')">
              {{'v2_errorPin' | translate}}<!--# El pin no coincide--><br
            /></span>
          </div>
        </div>
        <div class="sendForm">
          <ion-button expand="full" (click)="sendPin()"
            >{{'GetCardChangePin' | translate}}<!--Cambiar pin--></ion-button
          >
        </div>
      </ion-list>
    </form>
    <codere-footer></codere-footer>
  </ion-content>
</ng-template>
