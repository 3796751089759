import { Renderer2 } from '@angular/core';

export class CashierMovement {
  step: number;
  container: HTMLElement;
  stepPosition = 0;

  constructor(public _renderer: Renderer2) {}

  setContainer(container: HTMLElement) {
    this.container = container;
  }

  move() {
    const moveTo = (this.step - 1) * -100;
    if (this.container) {
      this._renderer.setAttribute(this.container, 'slide-right', moveTo.toString());
      this._renderer.setStyle(this.container, 'transform', `translateX(${moveTo}%)`);
      this._renderer.setStyle(this.container, 'transition', `transform 300ms ease`);
    }
  }
}
