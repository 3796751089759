/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { BaseService } from '../BaseService';
import { Observable } from 'rxjs';

export interface FundsOrigin {
  id: number;
  name: string;
}

@Injectable({ providedIn: 'root' })
export class BankService extends BaseService {
  getBanks(): Observable<any> {
    const url = this.globalVars.UrlTransactionsApiBase + 'cashierconfiguration/banks';
    return this.myGet(url);
  }

  getBankAccounts(): Observable<any> {
    const url = `${this.globalVars.UrlTransactionsApiBase}${'account/ibanlist'}`;
    return this.myGet(url);
  }

  getFundsOrigin(): Observable<Array<FundsOrigin>> {
    const url = `${this.globalVars.UrlTransactionsApiBase}${'cashierconfiguration/fundsorigin'}`;
    return this.myGet(url);
  }
}
