import { createAction, props } from "@ngrx/store";
import {
  C_Event,
  C_Horses,
  C_League,
  C_LeagueCategory,
  C_TodayLeagues,
  eventDataModel,
  homeDataModel,
  marketDataModel,
  sportCountriesDataModel,
  todayDataModel,
} from "@sports-models/index";

// HOME
export const initHome = createAction("[Home Page] Init");
export const fetchHome = createAction("[Home Page] Fetch", props<{ sport: string }>());
export const setHomeHighlighsSelectedSport = createAction(
  "[Home Page] Set Highlights Selected Sport",
  props<{ sport: string }>(),
);
export const setHome = createAction("[Home Page] Set", props<homeDataModel>());
export const stopHomePagePolling = createAction("[Home Page] Stop Home Polling");
export const clearHome = createAction("[Home Page] Clear Home");

// SPORT COUNTRIES
export const fetchSportCountries = createAction("[SportCountries Page] Fetch SportCountries", props<C_League>());
export const setSportCountries = createAction(
  "[SportCountries Page] Set SportCountries",
  props<sportCountriesDataModel>(),
);

// EVENT
export const fetchEventMainCategories = createAction(
  "[Event Page] Fetch Event League Categories",
  props<eventDataModel>(),
);
export const fetchEventGames = createAction("[Event Page] Fetch Event Games", props<eventDataModel>());
export const fetchEventEarlylMarketGames = createAction(
  "[Event Page] Fetch Event Early Market Games",
  props<eventDataModel>(),
);
export const fetchEventSpecialMarketGames = createAction(
  "[Event Page] Fetch Event Special Market Games",
  props<eventDataModel>(),
);
export const fetchEventMarketsCategories = createAction(
  "[Event Page] Fetch Event Markets Categories",
  props<eventDataModel>(),
);
export const setEventSpecialMarketGames = createAction(
  "[Event Page] Set Event Special Market Games",
  props<eventDataModel>(),
);
export const setEventMainSelectedCategory = createAction(
  "[Event Page] Set Event Main Selected Category",
  props<C_LeagueCategory>(),
);
export const setEventGames = createAction("[Event Page] Set Event Games", props<eventDataModel>());
export const setEventMarketSelectedCategory = createAction(
  "[Event Page] Set Event Market Selected Category",
  props<eventDataModel>(),
);
export const stopEventPagePolling = createAction("[Event Page] Stop Event Polling");
export const clearEvent = createAction("[Event Page] Clear Event Sports");

// MARKET
export const fetchMarketCategories = createAction("[Market Page] Fetch Categories", props<C_Event>());
export const setMarketSelectedCategory = createAction(
  "[Market Page] Set Market Selected Category",
  props<marketDataModel>(),
);
export const fetchMarkets = createAction("[Market Page] Fetch Markes", props<marketDataModel>());
export const fetchMarketBetsense = createAction("[Market Page] Fetch Market Betsense", props<marketDataModel>());
export const setMarkets = createAction("[Market Page] Set", props<marketDataModel>());
export const clearMarkets = createAction("[Market Page] Clear Market Sports");

// TODAY
export const fetchTodayCategories = createAction("[Today Page] Fetch Categories");
export const fetchTodaysDateSportLeagues = createAction(
  "[Today Page] Fetch Date Sport Leagues",
  props<todayDataModel>(),
);
export const fetchTodayLeagueEvents = createAction(
  "[Today Page] Fetch League Sport Events",
  props<C_TodayLeagues>(),
);
export const setTodaySelectedCategory = createAction(
  "[Today Page] Set Today Selected Category",
  props<todayDataModel>(),
);
export const setTodaySelectedDay = createAction(
  "[Today Page] Set Today Selected Day",
  props<{ selectedDay: number }>(),
);
export const setTodaySelectedSport = createAction(
  "[Today Page] Set Today Selected Sport",
  props<C_LeagueCategory>(),
);
export const setToday = createAction("[Today Page] Set", props<todayDataModel>());
export const stopTodayPagePolling = createAction("[Today Page] Stop Today Polling");
export const clearToday = createAction("[Today Page] Clear Today Sports");

// RACE
export const fetchRace = createAction("[Race Page] Fetch Data", props<{ raceType: string; endOfToday: any }>());
export const setRace = createAction("[Race Page] Set Data", props<any>());
export const clearRace = createAction("[Race Page] Clear Race Data");
export const stopRacePagePolling = createAction("[Race Page] Stop Race Polling");

// RACE MARKET
export const initRaceMarket = createAction("[RaceMarket Page] Init Data", props<C_Horses>());
export const fetchRaceMarket = createAction("[RaceMarket Page] Fetch Data", props<any>());
export const setRaceMarketSelectedRace = createAction("[RaceMarket Page] Set Selected Race", props<any>());
export const setRaceMarket = createAction("[RaceMarket Page] Set Data", props<any>());
export const clearRaceMarket = createAction("[RaceMarket Page] Clear RaceMarket Data");
export const stopRaceMarketPagePolling = createAction("[RaceMarket Page] Stop RaceMarket Polling");

// LIVE
export const initLive = createAction("[Live Page] Init Data");
export const setLive = createAction("[Live Page] Set Data", props<any>());
export const setLiveAndFetch = createAction("[Live Page] Set Data And Fetch", props<any>());
export const fetchLive = createAction("[Live Page] Fetch Data", props<any>());
export const setSelectedSportsNav = createAction("[Live Page] Set Selected Sport Nav", props<any>());
export const setLiveSelectedCategory = createAction("[Live Page] Set Selected Category", props<any>());
export const stopLivePagePolling = createAction("[Live Page] Stop Live Polling");
export const clearLive = createAction("[Live Page] Clear Live Sports");

// LIVE MARKET
export const initLiveMarket = createAction("[Live Market Page] Init Data", props<any>());
export const fetchLiveMarket = createAction("[Live Market Page] Fetch Data", props<any>());
export const setLiveMarket = createAction("[Live Market Page] Set Data", props<any>());
export const fetchLiveMarketBetsense = createAction("[Live Market Page] Fetch LiveMarket Betsense", props<any>());
export const setLiveMarketBetsense = createAction("[Live Market Page] Set LiveMarket Betsense", props<any>());
export const setLiveMarketSelectedCategory = createAction(
  "[Live Market Page] Set Selected Category",
  props<any>(),
);
export const clearLiveMarket = createAction("[Live Market Page] Clear LiveMarket Sports");
export const stopLiveMarketPagePolling = createAction("[Live Market Page] Stop Live Market Polling");

// STORE POLLING STOP
export const stopAllStorePollings = createAction("[Stop Store Polligs] Stop pollings");
