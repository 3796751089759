/* eslint-disable eqeqeq */
import { AlertController, ModalController } from '@ionic/angular';
import { Injectable, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { MadridMigrationResult, MigrationActions } from '@models/MadridMigrationResult';
import { LicenseTypes } from '@models/MasterData';
import { DocumentNumberModalOutput } from '@models/DocumentNumberModalOutput';
import { CookieNames } from '@models/CookieNames';

import { BaseService } from '@providers/BaseService';
import { UserService } from '@providers/UserService';
import { UserServiceMSO } from '@providers/UserServiceMSO';
import { LogService } from '@providers/LogService';
import { CookiesService } from '@providers/CookiesService';
import { NativeService } from './NativeService';

import { DocumentNumberModal } from '@shared-payments-pages/document-number-modal/document-number-modal';

/**
 * User migration service
 */
@Injectable({ providedIn: 'root' })
export class UserMigrationService extends BaseService {
  private tAndCAccepted: boolean;

  /**
   * Constructor
   * @param http
   * @param globalVars
   * @param events
   * @param utils
   * @param userService
   * @param logService
   * @param translate
   * @param alertController
   * @param modalController
   */

  //#region Inject Services
  userService = inject(UserService);
  userServiceMSO = inject(UserServiceMSO);
  logService = inject(LogService);
  translate = inject(TranslateService);
  cookiesService = inject(CookiesService);
  alertController = inject(AlertController);
  modalController = inject(ModalController);
  nativeService = inject(NativeService);
  //#endregion
  constructor() {
    super();
    this.tAndCAccepted = false;
  }

  /**
   * Returns if the current logged user should be migrated to the "Nacional" license
   */
  isMadridUserMigrationRequired(): boolean {
    if (!this.globalVars.user.logged) return false;
    const result: boolean =
      this.globalVars.FEATURES.MadridMigrationEnabled &&
      !this.cookiesService.getCookiePerUser(CookieNames.MigrationCompleted) &&
      (!this.cookiesService.getCookiePerUser(CookieNames.MigrationChecked) ||
        // Enum LicenseTypes is not well resolved in AOT compilation
        this.globalVars.licenseType === LicenseTypes.Madrid ||
        (this.globalVars.licenseType === LicenseTypes.Nacional &&
          this.cookiesService.getCookie(CookieNames.CodereUserRegion) === null));
    this.logService.log('isMadridUserMigrationRequired', {
      feature: this.globalVars.FEATURES.MadridMigrationEnabled,
      migrationCheckedCookie: this.cookiesService.getCookiePerUser(CookieNames.MigrationChecked),
      licenseType: this.globalVars.licenseType,
      codereUserRegionCookie: this.cookiesService.getCookie(CookieNames.CodereUserRegion),
      isRequired: result,
      enumNacionalValue: LicenseTypes.Nacional,
      enumMadridValue: LicenseTypes.Madrid
    });

    this.cookiesService.setCookiePerUser(CookieNames.MigrationChecked, 'true', 365 * 24 * 60 * 60 * 1000);
    return result;
  }

  /**
   * Starts migration process for the current logged user
   */
  migrateMadridUser(callBack?: () => void): void {
    this.logService.log('migrateMadridUser');

    const url: string = this.globalVars.DOMAIN + '/checkreceivemailmvc/MadridMigrator/MigrateMadridUser';
    this.myGet(url, this.globalVars.firstLoad).subscribe(
      (data) => {
        this.logService.log('migrateMadridUser result', data);

        const migrationResult: MadridMigrationResult = data;
        if (!migrationResult.success) {
          // Remove migration cookie to force check process
          this.cookiesService.removeCookiePerUser(CookieNames.MigrationChecked);
          switch (migrationResult.action) {
            case MigrationActions.AcceptTandC:
              this.migrateActionAcceptTandC();
              break;
            case MigrationActions.VerifyDocumentation:
              //this.migrateActionAcceptTandC();
              break;
            case MigrationActions.AddDocIdAndType:
              this.migrateActionAddDocIdAndType();
              break;
            default:
              break;
          }
        } else {
          this.cookiesService.setCookiePerUser(CookieNames.MigrationCompleted, 'true', 365 * 24 * 60 * 60 * 1000);

          if (this.globalVars.licenseType == LicenseTypes.Madrid) this.globalVars.licenseType = LicenseTypes.Nacional;
          if (this.globalVars.user.userRegion == LicenseTypes.Madrid)
            this.globalVars.user.userRegion = LicenseTypes.Nacional;
          if (callBack) {
            callBack();
          } else {
            this.events.publish('user:login');
            this.nativeService.checkNotificationNative();
            this.events.publish('navbarpc:refresh');
          }
        }
      },
      (error) => {
        this.logService.error('Error al migrar el usuario de Madrid a Nacional');
        this.logService.error(error);
      }
    );
  }

  // Private methods

  private migrateActionAcceptTandC(): void {
    this.userServiceMSO.getLastTermsAndConditionsUrlbyRegion('Madrid').subscribe((data) => {
      this.showMigrationAcceptTerms(data.Url);
    });
  }

  private async showMigrationAcceptTerms(url: string) {
    const msg: string = this.translate.instant('tfailcondiclegal') + '<br/>' + '<span><br/><br/>';
    const msgCheckbox: string =
      '<span>' +
      this.translate.instant('reg_tyc') +
      '</span>&nbsp;<a external-link id="linkCondicionesReg" target="_blank" href="' +
      url +
      '" class="ui-link"><span>' +
      this.translate.instant('rCond1b') +
      '</span>&nbsp;<span>' +
      this.translate.instant('reg_tyc2') +
      '</span><span>' +
      this.translate.instant('rCond2b') +
      '</span></a>';

    const alert = await this.alertController.create({
      backdropDismiss: false,
      header: 'Importante',
      message: msg + msgCheckbox,
      buttons: [
        {
          text: this.translate.instant('CodereCardconfir'),
          handler: () => {
            this.tAndCAccepted = true;
            this.confirmAcceptTerms();
          }
        }
      ]
    });
    alert.onDidDismiss().then(() => {
      this.logService.log('OnShowMigrationAcceptTermsDismiss');
      if (!this.tAndCAccepted) {
        // Call again to reactivate step, the alert has been closed clicking out
        this.migrateMadridUser();
      }
    });
    await alert.present();
  }

  private confirmAcceptTerms() {
    const url: string = this.globalVars.DOMAIN + '/checkreceivemailmvc/MadridMigrator/AcceptTandC';
    this.myGet(url, this.globalVars.firstLoad).subscribe(
      () => {
        // Call again to activate next step
        this.migrateMadridUser();
      },
      (error) => {
        this.logService.error('Error al confirmar los terminos y condiciones para la migración');
        this.logService.error(error);
        this.migrateMadridUser();
      }
    );
  }

  private async migrateActionAddDocIdAndType() {
    const modal = await this.modalController.create({
      component: DocumentNumberModal,
      backdropDismiss: false
    });
    await modal.present();
    modal.onDidDismiss().then((detail) => {
      const { data } = detail;
      if (!data) {
        // Call again to reactivate step, the modal has been closed clicking out
        this.migrateMadridUser();
      } else {
        this.addDocIdAndType(data);
      }
    });
  }

  private addDocIdAndType(data: DocumentNumberModalOutput) {
    const { documentNumber, documentType } = data;
    const { DOMAIN, firstLoad } = this.globalVars;
    const path = '/checkreceivemailmvc/MadridMigrator/AddDocIdAndType';
    const url = `${DOMAIN}${path}?docNumber=${documentNumber}&docType=${documentType}`;
    this.myGet(url, firstLoad).subscribe({
      next: ({ success }) => {
        if (success) {
          // Call again to activate next step
          this.migrateMadridUser();
        } else {
          this.alertController
            .create({
              backdropDismiss: false,
              header: 'Error',
              message: 'Los datos introducidos no son correctos',
              buttons: [
                {
                  text: this.translate.instant('CodereCardconfir'),
                  handler: () => {
                    // Call again to reactivate step, there was an error
                    this.migrateMadridUser();
                  }
                }
              ]
            })
            .then((alertx) => alertx.present());
        }
      },
      error: (error) => {
        this.logService.error('Error al enviar el tipo y el número de documento');
        this.logService.error(error);
      }
    });
  }
}
