import { EMPTY } from 'rxjs';
import { map } from 'rxjs/operators';
import { WithDrawalBase } from './withdrawal-base';
import { TransactionActionResult } from '@models/C_Transactions';

export class AstropayWithdrawal extends WithDrawalBase {
  constructor(args: any) {
    super(args);
  }

  checkPayment() {
    return EMPTY;
  }

  executePayment() {
    return this.executeRequest().pipe(map((response: TransactionActionResult) => response));
  }

  showErrorCallback() {
    return EMPTY;
  }

  executeOkCallback() {
    return EMPTY;
  }
}
