export class C_UserPanama {
  genero: string;
  name: string;
  lastName: string;
  lastName2: string;
  birthDate: string;
  countryBirth: string;
  nationality: string;
  adress: string;
  postalCode: string;
  email: string;
  cellPhone: string;
  login: string;
  password: string;
  smsAccept: boolean;
  emailAccept: boolean;
  origen: string;
  device: string;
  preferredPayment: string;

  constructor() {
    // ¿Necesita constructor??
    this.genero = '';
    this.name = '';
    this.lastName = '';
    this.lastName2 = '';
    this.birthDate = '';
    this.countryBirth = '';
    this.nationality = '';
    this.adress = '';
    this.postalCode = '';
    this.email = '';
    this.cellPhone = '';
    this.login = '';
    this.password = '';
    this.preferredPayment = '';
  }
}
