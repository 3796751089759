import { Component } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { BaseFilter } from '../base-filter/base-filter';

@Component({
  selector: 'checkbox-filter',
  imports: [IonicModule, CommonModule],
  standalone: true,
  providers: [],
  templateUrl: './checkbox-filters.component.html',
  styleUrls: ['./checkbox-filters.component.scss'],
  host: {
    class: 'checkbox-filters'
  }
})
export class CheckboxFiltersComponent extends BaseFilter {
  constructor() {
    super();
  }
}
