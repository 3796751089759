<img class="cs-doc-icon" src="assets/global/img/{{iconSource}}" />
<ng-container *ngIf="!isMobile; else mobileView">
    <span [ngClass]="statusLabelStyle">{{ statusLabel }}</span>
    <span class="cs-doc-type--text">&nbsp;Recibida el {{ doc?.date}}</span>
</ng-container>
<ng-template #mobileView>
    <div class="status-grid">
        <span [ngClass]="statusLabelStyle">{{ statusLabel }}</span>
        <span class="cs-doc-type--text">&nbsp;{{ doc?.date}}</span>
    </div>
</ng-template>