export class C_LeagueCategory {
  CategoryId: any;
  CategoryName: string;
  IsRelevant: boolean;
  name?: string;
  selected?: boolean;
  index?: number;
  constructor(CategoryId, CategoryName, IsRelevant, Selected?, index?) {
    this.CategoryId = CategoryId;
    this.CategoryName = CategoryName;
    this.IsRelevant = IsRelevant;
    this.name = CategoryName;
    this.selected = Selected;
    this.index = index;
  }
}
