import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { LicenseTypes } from '@models/MasterData';
import { GlobalVarsModel } from '@models/ModelGlobalsVar';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NewBaseService } from '@providers/newBase.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'contact-autoresponse',
  templateUrl: './contact-autoresponse.html',
  styleUrls: ['./contact-autoresponse.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule]
})
export class contactAutoresponse {
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onResponseUseful = new EventEmitter<boolean>();

  @Input() response;
  @Input() subject;
  @Input() texts?;
  @Input() underText?;
  @Input() logged?;

  licenseTypes = LicenseTypes;
  globalVars: GlobalVarsModel;
  newBaseService = inject(NewBaseService);
  hasBeenUseful = null;

  translate = inject(TranslateService);
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
  }

  setHasBeenUseful(value) {
    this.hasBeenUseful = value;
    this.onResponseUseful.emit(value);
  }
}
