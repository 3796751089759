/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable eqeqeq */
import { Observable } from 'rxjs';
import { UserService } from '..//UserService';
import { ListOfCards } from '../../models/ListOfCards';
import { ErrorService } from '../ErrorService';

export class DepositModel {
  amount: any;
  documentNumber: any;
  constructor() {
    this.amount = '';
    this.documentNumber = '';
  }
}

export class ValidateDepositModel {
  errorLocalizableId: string;
  isValid: boolean;
  minMaxAmount: number = null;
}

export class SkipAmountResult {
  skip: boolean;
  constructor(skip = false) {
    this.skip = skip;
  }
}

export class PaymentMethodBehaviourResult {
  constructor() {
    this.success = false;
    this.sendToDocumentVerificationPage = false;
    this.acceptColombiaConditionsRequired = false;
    this.sendToProfesion = false;
  }
  success: boolean;
  sendToDocumentVerificationPage: boolean;
  acceptColombiaConditionsRequired: boolean;
  sendToUserProfileForm: boolean;
  sendToProfesion: boolean;
  erroMessage: Observable<string>;
  errorcode: number;
}

export class IFramePaymentMethodBehaviourResult extends PaymentMethodBehaviourResult {
  iframeType: string;
  iframeArgs: any;
}

export class QRPaymentMethodBehaviourResult extends PaymentMethodBehaviourResult {
  //to identify QR methods
  payload: any; //data to be converted to QR
  provider: string; //name of the provider, neccesary to show the proper text in the CsQrCodeComponent
}

// LOTBA
export class LotbaPaymentMethodBehaviourResult extends PaymentMethodBehaviourResult {
  id: string;
  amount: number;
}

export class RedirectMethodBehaviourResult extends PaymentMethodBehaviourResult {
  url: string;
}

// Redsys implementation
// Bizum
export class RedsysMethodBehaviourResult extends PaymentMethodBehaviourResult {
  url: string;
  ds_SignatureVersion: string;
  ds_MerchantParameters: string;
  ds_Signature: string;
}
// Cards
export class RedsysCardsMethodBehaviourResult extends PaymentMethodBehaviourResult {
  // url: string;
  // ds_SignatureVersion: string;
  // ds_MerchantParameters: string;
  // ds_Signature: string;
  hasCards: boolean;
  ListOfCards: ListOfCards[];
  amount: number;
  transactionId: string;
  isFirstDeposit: boolean;
  netAmount: number;
  status: string;
  taxAmount: number;
}
export class QRMethodBehaviourResult extends PaymentMethodBehaviourResult {
  payload: any; //data to be converted to QR
}

export abstract class PaymentMethodBehaviour {
  acceptColombiaConditions: boolean;
  amount: number;
  isRecommended: boolean;
  documentNumber = '';

  userService: UserService;
  errorService: ErrorService;

  constructor(acceptColombiaConditions = false, userService: UserService, errorService: ErrorService) {
    this.acceptColombiaConditions = acceptColombiaConditions;
    this.userService = userService;
    this.errorService = errorService;
  }

  abstract paymentMetodExecution(): Observable<PaymentMethodBehaviourResult>;
  abstract skipAmountInput(otherParams: any): Observable<SkipAmountResult>;

  private checkExpresion(value: string): boolean {
    const regex = /^\d+(?:\.\d{0,2})$/;
    if (value.indexOf('.') != -1) {
      if (!regex.test(value)) {
        return true;
      }
    }
    return false;
  }

  public validateAmount(input: DepositModel): ValidateDepositModel {
    const result: ValidateDepositModel = new ValidateDepositModel();

    if (this.checkExpresion(input.amount.toString())) {
      result.isValid = false;
      result.errorLocalizableId = 'number_valid';
    } else if (input.amount.length == 0) {
      result.isValid = false;
      result.errorLocalizableId = 'error_tar_cant_r';
    } else if (isNaN(input.amount)) {
      result.isValid = false;
      result.errorLocalizableId = 'error_tar_cant_n';
    } else if (input.amount < this.minDepositAmount) {
      result.isValid = false;
      result.errorLocalizableId = 'minimumAmount';
      result.minMaxAmount = this.minDepositAmount;
    } else if (input.amount > this.maxDepositAmount) {
      result.isValid = false;
      result.errorLocalizableId = 'maximumAmount';
      result.minMaxAmount = this.maxDepositAmount;
    } else {
      result.isValid = true;
    }
    return result;
  }

  public validateFocumentNumber(input: DepositModel): ValidateDepositModel {
    const result: ValidateDepositModel = new ValidateDepositModel();
    const regex = /^[a-zA-Z0-9]{10,15}$/;
    if (this.documentNumberRequired) {
      if (!regex.test(input.documentNumber.toString())) {
        result.errorLocalizableId = 'number_valid';
        result.isValid = false;
        return result;
      }
    }
    result.isValid = true;
    return result;
  }
  public validateFocumentNumberPanama(input: DepositModel): ValidateDepositModel {
    const result: ValidateDepositModel = new ValidateDepositModel();

    const regex = /^[a-zA-Z0-9]{7,10}$/;
    if (this.documentNumberRequired) {
      if (!regex.test(input.documentNumber.toString())) {
        result.errorLocalizableId = 'number_valid';
        result.isValid = false;
        return result;
      }
    }
    result.isValid = true;
    return result;
  }

  abstract name: string;
  abstract image: string;
  abstract track: string;
  abstract isBank: boolean;
  abstract minDepositAmount: number;
  abstract maxDepositAmount: number;
  abstract amountButtons: Array<number>;

  montoLocalizableId = 'tImp';
  documentNumberRequired = false;
}

export interface IQRPaymentMethodBehaviour {
  //only declarative, to identify QR payment family
  qr(): void;
}

export class TrueLayerMethodBehaviourResult extends PaymentMethodBehaviourResult {
  url: string;
}
