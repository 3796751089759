export class C_SlotTournament {
  bonusMoneyExpiryDate: string;
  bonusMoneyExpiryDateSpecified: boolean;
  channels: string | string[];
  endDate: string;
  endDateSpecified: boolean;
  game: any;
  leaderboardEndDate: string;
  leaderboardEndDateSpecified: boolean;
  leaderboardStartDate: string;
  leaderboardStartDateSpecified: boolean;
  maxPlayers: number;
  maxPlayersSpecified: boolean;
  name: string;
  occurenceId: string | number;
  prizeShares: C_PrizeShare[];
  prizegame: C_SlotCompressed;
  startDate: string;
  startDateSpecified: boolean;
  wageringRequirement: number;
  wageringRequirementSpecified: boolean;
  userCanPlay: boolean = false;
  joined: boolean = false;
  userCanJoin?: boolean;
}

export class C_SlotCompressed {
  GameId: string;
  GameName: string;
}

export class C_PrizeShare {
  freeRounds: number;
  placemennt: number;
}
