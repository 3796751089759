import { CommonModule, Location } from "@angular/common";
import { AfterViewInit, Component, Input, ViewChild, inject } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CodereNavbar } from "@components/common/codere-navbar/codere-navbar";
import { CONSTANTS } from "@constants";
import { DeviceType } from "@interfaces/interfaces";
import { IonContent, IonicModule, MenuController, Platform } from "@ionic/angular";
import { ModalController, NavParams } from "@ionic/angular";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { Game } from "@models/lobby/casinoDataModels";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { EventsService } from "@providers/EventsService";
import { NewBaseService } from "@providers/newBase.service";
import { LobbyFacadeService } from "@services/casino/loobyFacade.service";
import { DeviceService } from "@services/device.service";
import { TrackingInfo } from "@shared-pages/base/BasePage";
import { BehaviorSubject } from "rxjs";
import { AbstractLoobyFacadeService } from "src/app/modules/shared/abstracts/abstract-looby-facade-service";
import { CalendarSectionComponent } from "../lobby/calendar-section/calendar-section";

@Component({
  selector: "page-lobby-calendar",
  imports: [IonicModule, CommonModule, CalendarSectionComponent, CodereNavbar, TranslateModule],
  standalone: true,
  providers: [
    NavParams,
    {
      provide: AbstractLoobyFacadeService,
      useClass: LobbyFacadeService,
    },
  ],
  templateUrl: "./lobby-calendar.html",
  styleUrls: ["./lobby-calendar.scss"],
  host: {
    class: "page-lobby-calendar",
  },
})
export class LobbyCalendarPage implements AfterViewInit {
  // #region Inject Services
  events = inject(EventsService);
  modalController = inject(ModalController);
  params = inject(NavParams);
  translate = inject(TranslateService);
  newBaseService = inject(NewBaseService);
  lobbyFacadeService = inject(AbstractLoobyFacadeService);
  platform = inject(Platform);
  route = inject(ActivatedRoute);
  router = inject(Router);
  deviceService = inject(DeviceService);
  menuController = inject(MenuController);
  location = inject(Location);
  // #endregion
  lobbyConstants = CONSTANTS;
  @Input() sections: any;
  finalSections$: BehaviorSubject<any[]> = new BehaviorSubject([]);
  activeGames: any;
  inactiveGames: any;
  @ViewChild(IonContent, { static: false }) content: IonContent;
  globalVars!: GlobalVarsModel;
  isMobile = false;
  calendarActiveSection = {
    endDate: "",
    games: [],
    order: 2000,
    sectionId: "fakeId",
    startDate: "",
    title:
      this.translate.instant("btnPlayNowCalendar") === "btnPlayNowCalendar"
        ? "Juega Ahora"
        : this.translate.instant("btnPlayNowCalendar"),
  };

  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
    this.isMobile = this.deviceService.isMobile();
    this.location.onUrlChange(() => this.closeModal());
  }

  async ngAfterViewInit() {
    if (this.sections) {
      const workingSections = [...this.sections];

      this.activeGames = await this.lobbyFacadeService.getGames();
      this.inactiveGames = await this.lobbyFacadeService.getInactiveGames();
      this.finalSections$.next(this.refactorSections([...workingSections]));
    }
  }

  refactorSections(sections) {
    // All games, active and inactive
    const mixedGames = [];
    // Active games (Juega ahora)
    const sectionActiveGames = [];
    // Inactive games (Proximamente)
    const sectionsInactiveGames = [];

    // Inactive games
    sections.map((section) => {
      mixedGames.push(...section.games);
      section.games = this.setInactiveGames(section);
      sectionsInactiveGames.push(...section.games);
    });

    // Active games
    mixedGames.map((game) => {
      const foundGame = this.activeGames[game.gameId];
      // Set a game as active if the game is in activeGames and not in sectionsInactiveGames
      if (foundGame !== undefined && !sectionsInactiveGames.includes(foundGame)) {
        const newGame = foundGame as Game;
        const size = game?.size;
        newGame.label = this.translate.instant("btnPlayNowCalendar");
        if (newGame.label === "btnPlayNowCalendar") {
          newGame.label = "Juega ahora";
        }
        newGame.active = true;
        newGame.GameId = game.gameId;
        if (this.globalVars.FEATURES.CAS_NewCalendar) {
          newGame.gameTileSize =
            size === this.lobbyConstants.smallsquare
              ? this.lobbyConstants.squareTile
              : this.lobbyConstants.rectangle;
        } else {
          newGame.gameTileSize = this.lobbyConstants.squareTile;
        }
        newGame.imageType = foundGame.DefaultImageType;
        newGame.lobby = "Calendar";
        newGame.product = "Casino";
        newGame.additionalData = new BehaviorSubject<any>({});
        sectionActiveGames.push(newGame);
      }
    });

    this.calendarActiveSection.games = sectionActiveGames;
    sections.push(this.calendarActiveSection);
    return sections;
  }

  /**
   * Filters sections games between active (Juega ahora) and inactive (Proximos Lanzamientos)
   * game or a Juega ahora (active) game
   */
  setInactiveGames(section) {
    const games = [];

    section.games.map((game) => {
      const foundGame = this.inactiveGames[game.gameId];
      if (foundGame) {
        const size = game?.size;
        const newGame = foundGame as Game;
        newGame.label = game.label;
        newGame.textLabel = game.title;
        newGame.active = false;
        newGame.GameId = game.gameId;
        if (this.globalVars.FEATURES.CAS_NewCalendar) {
          newGame.gameTileSize =
            size === this.lobbyConstants.smallsquare
              ? this.lobbyConstants.squareTile
              : this.lobbyConstants.rectangle;
        } else {
          newGame.gameTileSize = this.lobbyConstants.squareTile;
        }
        newGame.imageType = foundGame.DefaultImageType;
        newGame.lobby = "Calendar";
        newGame.product = "Casino";
        newGame.additionalData = new BehaviorSubject<any>({});
        games.push(newGame);
      }
    });
    return games;
  }

  getTrackingInfo(): TrackingInfo {
    return {
      uri: "/Calendar",
      description: "Lobby casino calendar",
      additionalData: null,
    };
  }

  async closeModal() {
    if (this.modalController) {
      await this.modalController.dismiss();
      if (this.isMobile && this.route?.component.name === "LobbyCasinoPage") {
        this.events.publish("returnCasino");
      }
    }
  }

  /**
   * Used to close LobbyCalendarPage, left and right sidebar menus
   */
  async openGame() {
    await this.closeModal();
    await this.menuController.close("left-menu");
    await this.menuController.close("right-menu");
  }

  getImage(img: string) {
    if (this.globalVars.DEVICE_TYPE === DeviceType.NATIVE && this.platform.is("ios")) {
      img.replace(".svg", ".png");
    }
    return img;
  }

  deleteUndefined(oldArray) {
    const newArray = [];
    oldArray.forEach((element) => {
      if (element) {
        newArray.push(element);
      }
    });
    return newArray;
  }

  clickSection(goTo) {
    const inactive = "inactive";
    if (goTo === inactive) {
      if (this.isMobile) {
        this.content.scrollToTop(1500);
      } else {
        const ionContent = document.getElementsByClassName("title-section")[0];
        ionContent.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
      }
    } else {
      const ionContent = document.getElementsByClassName("lastSection")[0];
      ionContent.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    }
  }
}
