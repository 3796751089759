import { EMPTY, Observable } from 'rxjs';
import { CdrInsightsConfig } from './cdr-insights-config';
import { CdrInsightsService } from '../shared/cdr-insights.service';
import { CdrInsightsEvent } from './cdr-insights-event';

export abstract class CdrInsightsBaseModule {
  protected isLoaded: boolean = false;

  constructor(
    protected config: CdrInsightsConfig,
    protected cdrInsightsService: CdrInsightsService
  ) {
    if (this.config.lib.src.length > 0) {
      this.init();
    }
  }

  init() {
    if (this.config.lib.autoLoad && !this.isLoaded) {
      this.load().then((p) => {
        this.build();
      });
    } else this.build();
  }

  get event$(): Observable<CdrInsightsEvent> {
    if (this.config.enabled) {
      return this.cdrInsightsService.event$;
    } else {
      return EMPTY;
    }
    // else return Observable.empty()
  }

  /**
   * load javascript in document head
   */
  load() {
    return new Promise((resolve, reject) => {
      const script: any = document.createElement('script');
      script.type = 'text/javascript';
      script.src = this.config.lib.src;
      if (script.readyState) {
        //IE
        script.onreadystatechange = () => {
          if (script.readyState === 'loaded' || script.readyState === 'complete') {
            script.onreadystatechange = null;
            this.isLoaded = true;
            resolve({ script: script.src, loaded: true, status: 'Loaded' });
          }
        };
      } else {
        //Others
        script.onload = () => {
          this.isLoaded = true;
          resolve({ script: script.src, loaded: true, status: 'Loaded' });
        };
      }
      script.onerror = (error: any) => reject({ script: script.src, loaded: false, status: 'Loaded' });
      document.getElementsByTagName('head')[0].appendChild(script);
    });
  }

  abstract build();
}
