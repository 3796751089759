/* eslint-disable @angular-eslint/component-class-suffix */
/* eslint-disable @angular-eslint/component-selector */
import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'contact-resume',
  templateUrl: './contact-resume-pc.html',
  styleUrls: ['./contact-resume.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule]
})
export class contactResume {
  @Input() subject;
  @Input() question;
  @Input() response;
}
