import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  OnInit,
  ElementRef,
  ViewChild,
  Renderer2,
  inject,
  OnDestroy,
  ChangeDetectorRef
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { EventsService } from '../../../../providers/EventsService';
import { DeviceService } from '../../../../services/device.service';
import { GlobalVarsModel } from '../../../../models/ModelGlobalsVar';
import { NewBaseService } from '../../../../providers/newBase.service';
import { AbstractLoobyFacadeService } from '../../../../abstracts/abstract-looby-facade-service';
import { LobbyFacadeService } from '../../../../services/casino/loobyFacade.service';
import { SlotsService } from '@providers/SlotsService';
import { Subject, filter, takeUntil } from 'rxjs';
import { NavigationStart, Router } from '@angular/router';
import { CASINO_PATHS } from '@shared-constants/routes';

@Component({
  selector: 'lb-oneGameLobby',
  imports: [CommonModule],
  standalone: true,
  templateUrl: './one-game-lobby.html',
  styleUrls: ['./one-game-lobby.scss'],
  providers: [
    {
      provide: AbstractLoobyFacadeService,
      useClass: LobbyFacadeService
    }
  ]
})
export class OneGameLobbyComponent implements OnInit, OnDestroy {
  @Input() oneGameinfo;
  srcGame;
  isStarted = false;
  cont = 0;
  globalVars!: GlobalVarsModel;
  isDesktop = false;
  gameListener;

  @ViewChild('containerGame') containerGame: ElementRef;
  @ViewChild('gameFrame') iframe: ElementRef;

  sanitizer = inject(DomSanitizer);
  newBaseService = inject(NewBaseService);
  facade = inject(AbstractLoobyFacadeService);
  events = inject(EventsService);
  renderer = inject(Renderer2);
  deviceService = inject(DeviceService);
  slotsService = inject(SlotsService);
  router = inject(Router);
  private cd: ChangeDetectorRef = inject(ChangeDetectorRef);
  private destroy$ = new Subject<void>();

  constructor() {
    this.gameListener = this.listenGameSession.bind(this);
  }

  ngOnInit(): void {
    // * GlovalVars
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
    this.subscribeToRouterEvents();
    this.isDesktop = this.deviceService.isDesktop();
    window.addEventListener('message', this.gameListener);
    this.subscriber();
    this.events.publish('initSingleLobby');
    if (this.oneGameinfo) {
      this.playGame();
    }
  }

  playGame() {
    if (!!this.globalVars.slotWindow && !!this.globalVars.slotWindow.window) {
      this.globalVars.slotWindow.window.close();
      this.globalVars.slotWindow = null;
    }
    setTimeout(() => {
      if (this.isDesktop) {
        this.setHeight(window.innerHeight);
        const url = window.location.href.replace(/%20/g, ' ');
        const gameFromLobby = url.includes(this.oneGameinfo.game.lobby);
        if (gameFromLobby) {
          this.facade.playGame(this.oneGameinfo.game);
        }
      }
    }, 200);
  }

  playGameMobile() {
    this.facade.playGame(this.oneGameinfo.game);
  }

  setHeight(height) {
    this.renderer.setStyle(this.containerGame.nativeElement, 'height', height - 60 + 'px');
  }

  private subscribeToRouterEvents() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationStart),
        takeUntil(this.destroy$)
      )
      .subscribe((event) => {
        const hasSingleGame = (event as NavigationStart).url.includes('singleGame');
        const isCasino = (event as NavigationStart).url.includes(CASINO_PATHS.BASE);
        if (isCasino && !hasSingleGame) {
          this.removeListeners();
        } else if (hasSingleGame) {
          this.removeListeners();
          this.cd.detectChanges();
          this.subscriber();
          this.playGame();
        }
      });
  }

  setStyles() {
    if (this.iframe) {
      const gameslot = this.iframe.nativeElement.contentDocument.querySelector('html > body > #mainBox > .gameslot');
      const txtslotsheader = this.iframe.nativeElement.contentDocument.querySelector(
        'html > body > #mainBox > .gameslot > #cabslots > div > .txtslotsheader'
      );
      const logogames = this.iframe.nativeElement.contentDocument.querySelector(
        'html > body > #mainBox > .gameslot > #cabslots > div > .logogames'
      );
      this.renderer.setStyle(gameslot, 'background', 'none');
      this.renderer.setStyle(txtslotsheader, 'color', '#9b9c9e');
      this.renderer.setStyle(logogames, 'display', 'none');
    }
  }

  onResize(event) {
    this.setHeight(window.innerHeight);
  }

  subscriber() {
    setTimeout(() => {
      this.events.subscribe('urlGame:true', (src) => {
        if (this.isDesktop) {
          this.srcGame = this.sanitizer.bypassSecurityTrustResourceUrl(src);
        }
      });
      this.events.subscribe('user:login', () => {});
      this.events.subscribe('user:logout', () => {
        this.srcGame = null;
      });
    }, 200);
  }

  ngOnDestroy() {
    this.removeListeners();
    this.destroy$.next();
    this.destroy$.complete();
  }

  private removeListeners() {
    const iframe = document.getElementById('gameFrame');
    if (iframe) {
      iframe.parentNode.removeChild(iframe);
    }
    this.srcGame = null;
  }

  private listenGameSession(e: any) {
    if (e?.data == 'closeGame') {
      this.srcGame = null;
    }
  }
}
