<ng-container *ngFor="let social of socials">
  <a
    *ngIf="social.external && !social.event"
    external-link
    [href]="social.href"
    [target]="social.target"
    [style]="social.style"
    [ngClass]="social.class + (globalVars.LICENSE_TYPE === 0 ? ' footerSocialsEs' : '') + (globalVars.LICENSE_TYPE === 33 || globalVars.LICENSE_TYPE === 34 ? ' footerSocialsAr' : '')"
  >
    <!-- TODO -->
    <img
      [src]="social.src"
      [ngClass]="social.classImg"
      [alt]="social.altImg"
      [style]="social.styleImage"
      [ngClass]="isDesktop ? 'img-store-desktop': globalVars.licenseType === 21 ? 'img-store-margin0': 'img-store'"
    />
    <!-- <img src="../../../assets/footer/Icons/icon-chat.png" [ngClass]="social.classImg" [alt]="social.altImg" [style]="social.styleImage"> -->
  </a>
  <a
    *ngIf="!social.external && !social.event"
    [href]="social.href"
    [target]="social.target"
    [ngClass]="social.class + (globalVars.LICENSE_TYPE === 0 ? ' footerSocialsEs' : '') + (globalVars.LICENSE_TYPE === 33 || globalVars.LICENSE_TYPE === 34 ? ' footerSocialsAr' : '')"
    [style]="social.style"
  >
    <img
      [src]="social.src"
      [ngClass]="social.classImg"
      [alt]="social.altImg"
      [style]="social.styleImage"
      [ngClass]="isDesktop ? 'img-store-desktop': globalVars.licenseType === 21 ? 'img-store-margin0': 'img-store'"
    />
  </a>
  <a
    *ngIf="social.event"
    [ngClass]="social.class + (globalVars.LICENSE_TYPE === 0 ? ' footerSocialsEs' : '') + (globalVars.LICENSE_TYPE === 33 || globalVars.LICENSE_TYPE === 34 ? ' footerSocialsAr' : '') + (globalVars.LICENSE_TYPE === 22 ? ' footerSocialsPa' : '')  + (globalVars.LICENSE_TYPE === 21 ? ' footerSocialsCo' : '')"
    (click)="openEvent($event,social.typeEvent)"
    [style]="social.style"
  >
    <img
      [src]="social.src"
      [style]="social.styleImage"
      [ngClass]="social.classImg"
      [ngClass]="isDesktop ? globalVars.licenseType === 22 ? 'img-store-desktop-pa' : 'img-store-desktop': globalVars.licenseType === 21 ? 'img-store-margin0': 'img-store'"
    />
  </a>
</ng-container>
