import { NgModule } from '@angular/core';
import { Draggable } from './draggable/draggable';
import { DraggableDirective } from './draggable.directive/draggable.directive';
import { HoverCard } from './hover-card.directive/hover-card.directive';
import { PanHorizontalDirective } from './pan-horizontal/pan-horizontal';
import { SubMenuDirective } from './submenu/submenu';
import { TrackEvent } from './track-event/track-event';
import { ExternalLinkDirective } from './external-link/external-link';
import { DeferLoadDirective } from './defer-load/defer-load.directive';
import { DisableDirective } from './disabled/disabled.directive';
import { LinkRelDirective } from './link-rel/link-rel';
import { OnlyNumbersDirective } from './only-numbers/only-numbers';

@NgModule({
  declarations: [
    Draggable,
    DraggableDirective,
    HoverCard,
    PanHorizontalDirective,
    // SubMenuDirective,
    // TrackEvent,
    ExternalLinkDirective,
    OnlyNumbersDirective,
    DisableDirective
    // LinkRelDirective
  ],
  imports: [DeferLoadDirective],
  exports: [
    Draggable,
    DraggableDirective,
    HoverCard,
    PanHorizontalDirective,
    // SubMenuDirective,
    // TrackEvent,
    ExternalLinkDirective,
    DeferLoadDirective,
    OnlyNumbersDirective,
    DisableDirective
    // LinkRelDirective
  ]
})
export class DirectivesModule {}
