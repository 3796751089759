import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'errorMessagePBS'
})
@Injectable({ providedIn: 'root' })
export class ErrorMessagePBS implements PipeTransform {
  transform(value: string): string {
    if (value.includes('ST_BS_BetSlipPlacementFailure')) {
      value = 'Ninguna de las apuestas ha sido confirmada';
    }
    return value;
  }
}
