import { Injectable, inject } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CombinationMulticastInput } from '../../models/sports/combinationMulticast.model';
import { OddItem } from '../../models/sports/oddsInput.model';
import { SingleItemInput } from '../../models/sports/singleItemInput.model';
import { StakeModel } from '../../models/sports/stake.model';
import { StraightMulticastInput } from '../../models/sports/straightMulticast.model';
import { removeTicket } from '../../models/sports/B_RemoveTicket.model';
import { SmartMarketInput } from '../../models/sports/smartMarket.model';
import { XStateService } from './xstate.service';
import { C_FreeBetVoucher } from '../../models/C_FreeBetTicket';
import { Constants } from '../../utils/constants';
import { BaseServiceExt } from '@providers/BaseServiceExt';

@Injectable({ providedIn: 'root' })
export class BetSlipService extends BaseServiceExt {
  hideLoader = false;
  xStateService = inject(XStateService);
  constructor() {
    super();
  }

  //eslint-disable-next-line
  getLocalStoregeTicket() {}

  //eslint-disable-next-line
  removeLocalStoregeTicket() {}

  addSingleResult(bet: SingleItemInput): Observable<any> {
    const url = this.globalVars.BETSLIPSERVICEBASEURL + Constants.addSingleItemUrl;
    const opt = {
      observe: 'response',
      headers: new HttpHeaders({
        'X-State': this.xStateService.getXState()
      })
    };
    return this.myPost2(url, bet, opt, this.hideLoader);
  }

  addSmartMarketItem(bet: SmartMarketInput): Observable<any> {
    const url = this.globalVars.BETSLIPSERVICEBASEURL + Constants.addSmartMarketItemUrl;
    const opt = {
      observe: 'response',
      headers: new HttpHeaders({
        'X-State': this.xStateService.getXState()
      })
    };
    return this.myPost2(url, bet, opt, this.hideLoader);
  }

  addStraightMulticast(bet: StraightMulticastInput): Observable<any> {
    const url = this.globalVars.BETSLIPSERVICEBASEURL + Constants.straightMulticast;
    const opt = {
      observe: 'response',
      headers: new HttpHeaders({
        'X-State': this.xStateService.getXState()
      })
    };
    return this.myPost2(url, bet, opt, this.hideLoader);
  }

  addCombinationMulticast(bet: CombinationMulticastInput): Observable<any> {
    const url = this.globalVars.BETSLIPSERVICEBASEURL + Constants.combinationMulticast;
    const opt = {
      observe: 'response',
      headers: new HttpHeaders({
        'X-State': this.xStateService.getXState()
      })
    };
    return this.myPost2(url, bet, opt, this.hideLoader);
  }

  removeItem(id: number): Observable<any> {
    const url = this.globalVars.BETSLIPSERVICEBASEURL + Constants.removeItemUrl + id;
    const opt = {
      observe: 'response',
      headers: new HttpHeaders({
        'X-State': this.xStateService.getXState()
      })
    };
    return this.myDelete2(url, opt);
  }

  updateOdds(oddsItems: OddItem[]): Observable<any> {
    const url = this.globalVars.BETSLIPSERVICEBASEURL + Constants.setOdds;
    const dataOdds = { odds: oddsItems };
    const opt = {
      observe: 'response',
      headers: new HttpHeaders({
        'X-State': this.xStateService.getXState()
      })
    };
    return this.myPatch2(url, dataOdds, opt, true);
  }

  stake(stake: StakeModel) {
    const url = this.globalVars.BETSLIPSERVICEBASEURL + Constants.stakeUrl + stake.Group;
    const stakeData = { ...stake };
    const opt = {
      observe: 'response',
      headers: new HttpHeaders({
        'X-State': this.xStateService.getXState()
      })
    };
    delete stakeData.Group;
    if (Object.prototype.hasOwnProperty.call(stakeData, 'LineItems')) delete stakeData.LineItems;
    if (Object.prototype.hasOwnProperty.call(stakeData, 'MultiSingleAmount')) delete stakeData.MultiSingleAmount;
    return this.myPost2(url, stakeData, opt, this.hideLoader);
  }

  removeStake(stakeGroup: string) {
    const url = this.globalVars.BETSLIPSERVICEBASEURL + Constants.stakeUrl + stakeGroup;
    const opt = {
      observe: 'response',
      headers: new HttpHeaders({
        'X-State': this.xStateService.getXState()
      })
    };
    return this.myDelete2(url, opt);
  }

  placeBet(groupKey: string, validateBonus: any = false) {
    const url = this.globalVars.BETSLIPSERVICEBASEURL + Constants.stakeUrl + groupKey + '/tickets';
    if (validateBonus) {
      const opt = {
        observe: 'response',
        headers: new HttpHeaders({
          'X-State': this.xStateService.getXState(),
          'X-Validate-Bonus-Offers': 'true',
          Authorization: this.xStateService.getAuthorization()
        })
      };
      return this.myGet2(url, opt, this.hideLoader);
    } else {
      const opt = {
        observe: 'response',
        headers: new HttpHeaders({
          'X-State': this.xStateService.getXState()
        })
      };
      return this.myGet2(url, opt, this.hideLoader);
    }
  }

  getLicenseSessionHeader() {
    const licenseType = this.globalVars.licenseType;
    // const url = `${environment.baseDomain}BetPlacement/LicenseSessionHeader?licenseType=${licenseType}`;
    const url = `${this.globalVars.DOMAIN}BetPlacement/AuthorizationHeader?licenseType=${licenseType}`;
    return this.myGet(url, false);
  }

  closeBet(groupKey: any) {
    const { AutoAcceptOddUpChanges, AutoAcceptOddDownChanges } = this.globalVars.user.customization;
    const url = this.globalVars.DOMAIN + '/BetPlacement/TicketsAndCloseBet?groupKey=' + groupKey;
    const opt = {
      observe: 'response',
      headers: new HttpHeaders({
        AutoAcceptOddUpChanges: AutoAcceptOddUpChanges.toString(),
        AutoAcceptOddDownChanges: AutoAcceptOddDownChanges.toString(),
        language: this.globalVars.Idioma,
        'X-State': this.xStateService.getXState()
      })
    };
    return this.myGet2(url, opt, this.hideLoader);
  }

  overaskCheckState(OveraskRejectId: any): Observable<any> {
    const url = `${this.globalVars.DOMAIN}BetPlacement/api/Overask?rejectId=${OveraskRejectId}`;
    return this.myGet2(url, false);
  }

  removeTicket(removeT: removeTicket) {
    const url = this.globalVars.BETSLIPSERVICEBASEURL + Constants.removeTicket;
    const opt = {
      observe: 'response',
      headers: new HttpHeaders({
        'X-State': this.xStateService.getXState()
        // 'X-Validate-Bonus-Offers': 'true',
        // 'Authorization': this.xStateService.getAuthorization()
      })
    };
    return this.myPost2(url, removeT, opt);
  }

  addSmartMarket(bet: SingleItemInput): Observable<any> {
    const url = this.globalVars.BETSLIPSERVICEBASEURL + Constants.smartMarketUrl;
    const opt = {
      observe: 'response',
      headers: new HttpHeaders({
        'X-State': this.xStateService.getXState()
        // 'X-Validate-Bonus-Offers': 'true',
        // 'Authorization': this.xStateService.getAuthorization()
      })
    };
    return this.myPost2(url, bet, opt, this.hideLoader);
  }

  freebetAdd(voucher: C_FreeBetVoucher): Observable<any> {
    const url = this.globalVars.BETSLIPSERVICEBASEURL + Constants.freeBetAdd;
    const opt = {
      observe: 'response',
      headers: new HttpHeaders({
        'X-State': this.xStateService.getXState()
        // 'X-Validate-Bonus-Offers': 'true',
        // 'Authorization': this.xStateService.getAuthorization()
      })
    };
    return this.myPost2(url, voucher, opt, false);
  }

  freebetCancel(voucherCode: string): Observable<any> {
    const url = `${this.globalVars.BETSLIPSERVICEBASEURL}${Constants.freeBetCancel}/${voucherCode}`;
    const opt = {
      observe: 'response',
      headers: new HttpHeaders({
        'X-State': this.xStateService.getXState()
        // 'X-Validate-Bonus-Offers': 'true',
        // 'Authorization': this.xStateService.getAuthorization()
      })
    };
    return this.myDelete2(url, opt);
  }

  freebetValidate(): Observable<any> {
    const url = `${this.globalVars.BETSLIPSERVICEBASEURL}${Constants.freeBetValidate}`;
    const opt = {
      observe: 'response',
      headers: new HttpHeaders({
        'X-State': this.xStateService.getXState()
        // 'X-Validate-Bonus-Offers': 'true',
        // 'Authorization': this.xStateService.getAuthorization()
      })
    };
    return this.myGet2(url, opt);
  }
}
