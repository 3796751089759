import { CommonModule } from "@angular/common";
import { Component, Input, OnInit, ViewChild, inject } from "@angular/core";
import { IonContent, ModalController, NavController, NavParams } from "@ionic/angular";
import { CashierKOSubtitles, PaymentMethods } from "@models/C_Transactions";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { TrackingService } from "@providers/TrackingService";
import { UserService } from "@providers/UserService";
import { NewBaseService } from "@providers/newBase.service";
import { Utils } from "@utils/Utils";

@Component({
  selector: "cashier-request-info-ko",
  templateUrl: "./cashier-request-info-ko.html",
  styleUrls: ["./cashier-request-info-ko.scss"],
  host: {
    class: "sb-alert cs-request-info",
  },
  standalone: true,
  imports: [TranslateModule, CommonModule],
})
export class CashierRequestInfoPageKo implements OnInit {
  @ViewChild("content") content: IonContent;

  showVer = false;
  intensivePlayer = false;
  options: any = {};
  decoder: string;
  globalVars!: GlobalVarsModel;
  @Input() paymentArguments?: any;

  nav = inject(NavController);
  translate = inject(TranslateService);
  navParams = inject(NavParams);
  modalCtrl = inject(ModalController);
  newBaseService = inject(NewBaseService);
  userService = inject(UserService);
  utils = inject(Utils);
  private trackingService = inject(TrackingService);

  constructor() {
    this.newBaseService.getVars.subscribe((vars: GlobalVarsModel) => {
      this.globalVars = vars;
    });
  }

  ngOnInit(): void {
    this.paymentArguments
      ? (this.options.paymentArguments = this.paymentArguments)
      : (this.options = this.navParams.data);

    this.decoder = decodeURI(this.getOptionFieldValue("error"));
    this.userService.sendErrors(this.decoder);
    // Nota: Dice "Efectivo" porque viene con el valor 754310000, aunque Debin / Pagos360 tiene el valor 754310058
    if (
      this.getOptionFieldValue("subtitle") === CashierKOSubtitles.depositKO &&
      this.getOptionFieldValue("paymentMethod") === PaymentMethods.Efectivo
    ) {
      this.options.paymentArguments.subtitle = CashierKOSubtitles.debin_error;
    }
    if (this.getOptionFieldValue("error")) {
      if (this.getOptionFieldValue("error").search("174") > -1) {
        this.showVer = true;
      } else if (this.getOptionFieldValue("error").search("2216") > -1) {
        this.intensivePlayer = true;
        this.options.paymentArguments.title = this.translate.instant("playresponsibly").toUpperCase();
      }
    }
  }

  ngOnDestroy() {
    this.utils.unloadContactChat();
  }

  ionViewWillEnter() {
    if (this.globalVars.FEATURES.chatEnabedToFailureDeposit) {
      this.utils.loadContactChat();
      this.initChat();
    }
    if (this.getOptionFieldValue("type") === "withdrawal") {
      this.trackingService.trackEvent(["CashierWithdrawalKo", this.getOptionFieldValue("error"), "", "", "event"]);
    } else {
      this.trackingService.trackEvent(["CashierDepositKO", this.getOptionFieldValue("error"), "", "", "event"]);
    }
  }

  ionViewDidLoad() {
    //eslint-disable-next-line
    if (this.globalVars.browser === "internet") {
      //** TODO */
      // this.content.resize();
    }
  }

  initChat() {
    this.userService.getUserData().subscribe(
      (data) => {
        // window["SnapEngage"].setUserEmail(data.Direccion_Correo, true);
        // window["SnapEngage"].startLink();
        this.utils.openContactChat("normal", data.Direccion_Correo);
      },
      () => {
        // window["SnapEngage"].startLink();
        this.utils.openContactChat();
      },
    );
    this.trackingService.trackEvent(["runChat", "CashierPaymentKo", "", "", "event"]);
  }

  acceptClick() {
    this.modalCtrl.dismiss();
  }

  goVerDoc() {
    this.modalCtrl.dismiss(this.showVer);
  }

  getOptionFieldValue(field: string) {
    return this.options?.paymentArguments[field]
      ? this.options?.paymentArguments[field]
      : this.options[field]
        ? this.options[field]
        : "";
  }
}
