export interface SmartMarketItem {
  itemId: number;
  sport: {
    id: number;
    sportHandle: string;
  };
  location: {
    id: number;
  };
  league: {
    id: number;
  };
  event: {
    id: number;
    hasHandicap: boolean;
  };
  placementSource: string;
  clientData: string;
  // maxSystem: number;
  odds: number;
  taxSettings: string;
  selections: [
    {
      game: {
        id: number;
        gameTypeId: number;
        specialOddsValue: string;
      };
      result: {
        id: number;
        resultTypeId: number;
        odds: number;
        baseOdds: number;
        TaxSettings: string;
      };
    },
  ];
  referenceMarkets: [
    {
      game: {
        id: number;
        gameTypeId: number;
        specialOddsValue: string;
      };
      results: [
        {
          id: number;
          resultTypeId: number;
          odds: number;
          baseOdds: number;
          taxSettings: string;
        },
      ];
    },
  ];
}

export interface SmartMarketInput {
  Sport: {
    Id: number;
    SportHandle: string;
  };
  Location: {
    Id: number;
  };
  League: {
    Id: number;
  };
  Event: {
    Id: number;
    HasHandicap: true;
  };
  PlacementSource: string;
  ClientData: string;
  MaxSystem?: number;
  Odds: number;
  Selections: {
    Game: {
      Id: number;
      GameTypeId: number;
      SpecialOddsValue: string;
    };
    Result: {
      Id: number;
      ResultTypeId: number;
      Odds: number;
      BaseOdds: number;
      TaxSettings: string;
    };
  }[];
  ReferenceMarkets: {
    Game: {
      Id: number;
      GameTypeId: number;
      SpecialOddsValue: string;
    };
    Results: [
      {
        Id: number;
        ResultTypeId: number;
        Odds: number;
        BaseOdds: number;
        TaxSettings: string;
      },
    ];
  }[];
}
