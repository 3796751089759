<div
  *ngIf="isConfirmed()"
  class="sb-ticket--confirmation-header sb-ticket--header background-color-dark"
  (click)="toggleConfirmation()"
>
  <div class="sb-ticket--confirmation-headings">
    <span class="sb-ticket-selection--title color-light">
      {{ 'compapuestaT1' | translate }} {{ 'compapuestaT2' | translate }}
    </span>
    <span *ngIf="isConfirmed() && ticket?.TicketNumber" class="sb-ticket-selection--subtitle color-grey-500">
      {{ 'compapuestaP3' | translate }} #{{ ticket?.TicketNumber }}
    </span>
  </div>
  <i class="codere-icon color-light icon-arrow-bottom" [ngClass]="{ 'rotate-icon-180' : accordionHidden }"></i>
</div>

<div
  *ngIf="+ticket?.OveraskInfo?.Status == 0"
  class="sb-ticket--confirmation-header sb-ticket--header background-color-dark"
>
  <div class="sb-ticket--confirmation-headings">
    <span class="sb-ticket-selection--title color-light"> Aprobación Requerida </span>
    <span class="sb-ticket-selection--subtitle color-grey-500"> Por favor espera </span>
  </div>
</div>
<div
  *ngIf="+ticket?.OveraskInfo?.Status == 1"
  class="sb-ticket--confirmation-header sb-ticket--header background-color-dark"
>
  <div class="sb-ticket--confirmation-headings">
    <span class="sb-ticket-selection--overask color-light">
      LO SENTIMOS, TU APUESTA HA ALCANZADO EL LÍMITE PERMITIDO. APUESTA MAXIMA POSIBLE:
      <span class="color-primary"> {{ ticket?.OveraskInfo?.MaxStake.toString() | parseStakeMoney:[1]}} </span>
    </span>
  </div>
</div>
<div
  *ngIf="+ticket?.OveraskInfo?.Status == 3"
  class="sb-ticket--confirmation-header sb-ticket--header background-color-dark"
>
  <div class="sb-ticket--confirmation-headings">
    <span class="sb-ticket-selection--overask color-light">
      LO SENTIMOS, TU APUESTA HA ALCANZADO EL LÍMITE PERMITIDO Y NO SE HA PODIDO REALIZAR
    </span>
  </div>
</div>
<div
  *ngIf="+ticket?.OveraskInfo?.Status == 2"
  class="sb-ticket--confirmation-header sb-ticket--header background-color-dark"
>
  <div class="sb-ticket--confirmation-headings">
    <span class="sb-ticket-selection--overask color-light">
      LO SENTIMOS, EL TIEMPO DE RESPUESTA SE HA AGOTADO. REALIZA DE NUEVO TU APUESTA
    </span>
  </div>
</div>
<!-- selections ticket  mode="selection-locked"-->
<!--This is swipable-->
<div
  [hidden]="ticket?.OveraskInfo?.Status !== 'OK'"
  class="sb-ticket-collapsed--selections background-color-dark swipe-bar"
  [ngClass]="{ 'rounded-top': !isConfirmed() }"
  #selections
>
  <div id="ticket-selections" [hidden]="accordionHidden">
    <ng-container *ngIf="callGetNumberOfSelections(ticket) < 3">
      <!-- Simple LineItems -->
      <div *ngFor="let lineItem of ticket?.LineItems | slice: 0:2; trackBy: trackBy" class="sb-ticket-selection-group">
        <ticket-selection
          [lineItem]="lineItem"
          [mode]="selectionBetType"
          [isTicketClosed]="isConfirmed()"
          [isDesktop]="isDesktop"
          [ticket]="ticket"
          [license]="license"
          [keyboard]="keyboard$ | async"
          (delete)="removeSelection(lineItem)"
          (updateAmount)="updateStake(lineItem)"
          (select)="selectLineItem(lineItem)"
          (unselect)="unselectLineItem()"
        >
        </ticket-selection>
      </div>

      <!-- StraightMulticast -->
      <div *ngFor="let lineItem of ticket?.StraightMulticast; trackBy: trackBy" class="sb-ticket-selection-group">
        <ticket-selection
          [lineItem]="lineItem"
          [mode]="selectionBetType"
          [ticket]="ticket"
          [isTicketClosed]="!!isConfirmed()"
          [isDesktop]="isDesktop"
          [license]="license"
          [keyboard]="keyboard$ | async"
          [minBetValue]="minBetValue"
          [currency]="currencySign"
          (updateAmount)="updateStake(lineItem)"
          (delete)="removeStraightMulticast(lineItem)"
          (select)="selectLineItem(lineItem)"
          (unselect)="unselectLineItem()"
        >
        </ticket-selection>
      </div>

      <!-- CombinationMulticast -->
      <div *ngFor="let lineItem of ticket?.CombinationMulticast; trackBy: trackBy" class="sb-ticket-selection-group">
        <ticket-selection
          [lineItem]="lineItem"
          [mode]="selectionBetType"
          [ticket]="ticket"
          [isTicketClosed]="!!isConfirmed()"
          [isDesktop]="isDesktop"
          [license]="license"
          [keyboard]="keyboard$ | async"
          [minBetValue]="minBetValue"
          [currency]="currencySign"
          (updateAmount)="updateStake(lineItem)"
          (delete)="removeCombinationItem(lineItem)"
          (select)="selectLineItem(lineItem)"
          (unselect)="unselectLineItem()"
        >
        </ticket-selection>
      </div>

      <!-- Betbuilder SmartLineItems -->
      <div
        *ngFor="let smartLineItem of ticket?.SmartLineItems | slice: 0:2; trackBy: trackBy"
        class="sb-ticket-selection-group"
      >
        <ticket-betbuilder
          [smartLineItem]="smartLineItem"
          [mode]="selectionBetType"
          [ticket]="ticket"
          [isTicketClosed]="!!isConfirmed()"
          [isDesktop]="isDesktop"
          [license]="license"
          [keyboard]="keyboard$ | async"
          [minBetValue]="minBetValue"
          [currency]="currencySign"
          (updateAmount)="updateStake(smartLineItem)"
          (deleteSmartMarket)="removeSmartMarketSelection(smartLineItem)"
          (select)="selectLineItem(smartLineItem)"
          (unselect)="unselectLineItem()"
        >
        </ticket-betbuilder>
      </div>
    </ng-container>
  </div>
</div>
<!-- edit ticket -->
<div
  class="sb-ticket--footer background-color-dark"
  [hidden]="isConfirmed() || ticket?.OveraskInfo?.Status !== 'OK'"
  #ticketFooterBig
>
  <div class="sb-ticket--actions background-color-dark border-color-light box-shadow" #ticketFooter>
    <div class="sb-ticket--row-toggle">
      <div (click)="updateBetType()" class="sb-ticket--toggle">
        <span class="sb-ticket--toggle-title color-light" [hidden]="betTypeToggleHidden()">
          {{ 'tAcu' | translate }}
        </span>

        <ion-toggle
          class="sb-ticket--toggle-icon"
          [ngClass]="{'sb-ticket--toggle-icon-opacity': disableElement}"
          #betTypeToggle
          [disabled]="disableElement"
          [hidden]="betTypeToggleHidden()"
        >
        </ion-toggle>

        <span class="sb-ticket--toggle-title color-light" [hidden]="betTypeToggleHidden()">
          {{ 'tSen' | translate }}
        </span>
      </div>

      <div class="sb-ticket--toggle" (click)="toggleFreebet()" [hidden]="hideFreebetToggle()">
        <ion-toggle class="sb-ticket--toggle-icon" #freebetToggle [disabled]="disableElement"> </ion-toggle>

        <span class="sb-ticket--toggle-title color-light"> {{ 'fb' | translate }} </span>
      </div>
    </div>

    <div class="sb-ticket--row-final">
      <!-- STAKE PLACEMENT AND BET BUTTON -->
      <section class="ticket-button--wrapper" *ngIf="!callBetTicketHasErrors(ticket) && !ticket?.hasChangesInOdds">
        <ion-grid class="ticket-button--grid">
          <ion-row>
            <ion-col size="4" class="ticket-button--col left">
              <ion-button
                class="sports-btn btn-light sportsbook"
                (click)="toggleTotalLocal()"
                [disabled]="disabled$ | async"
              >
                <ion-label>
                  <span class="sb-ticket--button-title" [ngClass]="{ 'text-small' : ticket?.TotalLocal.length > 8 }">
                    {{ ticket?.TotalLocal | parseStakeMoney:[1] }}
                  </span>
                </ion-label>
              </ion-button>
            </ion-col>
            <ion-col class="ticket-button--col right">
              <ion-button class="sports-btn btn-primary sportsbook" (click)="placeBet()" [disabled]="disabled$ | async">
                <ion-label>
                  <span class="sb-ticket--button-title text-uppercase"> {{ btnBetText }} </span>
                  <span class="sb-ticket--button-title text-small">
                    {{'hDetailsGan' | translate}}: {{ ticket?.TotalPotentialWin?.toString() | parseStakeMoney:[1]}}
                  </span>
                </ion-label>
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>
      </section>

      <!-- Error -->
      <button
        *ngIf="callBetTicketHasErrors(ticket) && !ticket?.hasChangesInOdds"
        class="sb-ticket--button-stake color-dark background-color-muted border-color-grey-200"
        mode="locked"
        [class.text-small]="ticket?.TotalLocal.length > 6"
        [attr.mode]="hasFreebet() ? 'locked' : null"
      >
        <span class="sb-ticket--button-title text-uppercase"> &#45; </span>
      </button>

      <button
        id="btn-accept-error-and-continue-1"
        *ngIf="callBetTicketHasErrors(ticket) && !ticket?.hasChangesInOdds"
        class="sb-ticket--button-warning color-light"
        [ngClass]="betButton"
        (click)="acceptErrorAndContinue($event)"
      >
        <ion-icon ios="ios-alert-outline" md="ios-alert-outline"></ion-icon>
        <span class="sb-ticket--button-title text-small"> {{ errorTicket | translate }} </span>
      </button>

      <!-- Odd Acceptance -->
      <button
        id="btn-present-odd-acceptance"
        *ngIf="ticket?.hasChangesInOdds"
        tappable
        (click)="presentOddAcceptance()"
        class="sb-ticket--button-settings color-dark background-color-muted border-color-grey-200"
      >
        <i class="codere-icon icon-preferencias color-dark"></i>
        <span class="sb-ticket--button-title text-left text-small"> {{ 'bettingOptions' | translate }} </span>
      </button>

      <button
        id="btn-accept-error-and-continue-0"
        *ngIf="ticket?.hasChangesInOdds"
        class="sb-ticket--button-bet color-primary background-color-transparent border-color-primary"
        tappable
        (click)="acceptErrorAndContinue(errorTicket)"
      >
        <span class="sb-ticket--button-title text-uppercase"> {{ 'tAcepOdd' | translate }} </span>
      </button>
    </div>
  </div>
</div>

<div
  [hidden]="!isConfirmed() && ticket?.OveraskInfo.Status == 'OK'"
  class="sb-ticket--footer background-color-dark border-color-light box-shadow"
>
  <div class="sb-ticket--actions background-color-dark border-color-light box-shadow">
    <div *ngIf="isConfirmed() || +ticket?.OveraskInfo?.Status == 0" class="sb-ticket--row-confirmation">
      <div class="sb-ticket--confirmation-footer">
        <span class="sb-ticket-selection--title color-grey-200"> {{ 'uPPExtractTap' | translate }} </span>
        <span class="sb-ticket-selection--title color-grey-200"> {{ ticket?.TotalLocal | parseStakeMoney:[1] }} </span>
      </div>

      <div class="sb-ticket--confirmation-footer">
        <span class="sb-ticket-selection--title color-grey-200"> {{ 'hDetailsGan' | translate }}: </span>
        <span *ngIf="isConfirmed()" class="sb-ticket-selection--title color-grey-200">
          {{ ticket?.TotalPotentialWin.toString() | parseStakeMoney:[1] }}
        </span>
        <span *ngIf="+ticket?.OveraskInfo?.Status == 0" class="sb-ticket-selection--title color-grey-200">
          {{ ticket?.TotalPotentialWin.toString() | parseStakeMoney:[1] }}
        </span>
      </div>
    </div>

    <div *ngIf="+ticket?.OveraskInfo?.Status == 1" class="sb-ticket--row-confirmation">
      <div class="sb-ticket--confirmation-footer">
        <span class="sb-ticket-selection--title color-grey-200">
          La oferta expira en:
          <span class="color-primary"> {{ newTicketFacade.overaskCountdown$ | async }} segundos </span>
        </span>
      </div>
    </div>

    <div *ngIf="ticket?.OveraskInfo.Status == 'OK'" class="sb-ticket--row-final">
      <button
        id="keep-selections-ok"
        class="sb-ticket--button-selection color-dark background-color-muted border-color-grey-200"
        tappable
        (click)="keepSelections()"
      >
        <span class="sb-ticket--button-title"> {{ 'keepselections' | translate }} </span>
      </button>
      <button
        id="close-resume-ok"
        class="sb-ticket--button-bet color-light background-color-primary border-color-primary"
        tappable
        [ngClass]="betButton"
        (click)="closeResume()"
      >
        <span class="sb-ticket--button-title text-uppercase"> Ok </span>
      </button>
    </div>
    <div *ngIf="+ticket?.OveraskInfo?.Status == 0" class="sb-ticket--row-final">
      <button
        id="close-resume-0"
        class="sb-ticket--button-selection background-color-muted border-color-grey-200"
        tappable
        (click)="closeResume()"
      >
        <span class="sb-ticket--button-title text-uppercase color-dark"> Cancelar </span>
      </button>
      <button class="sb-ticket--button-bet background-color-dark border-color-dark">
        <span class="sb-ticket--button-title text-uppercase color-primary"> Espera por favor </span>
      </button>
    </div>
    <div *ngIf="+ticket?.OveraskInfo?.Status == 1" class="sb-ticket--row-final">
      <button
        id="clear-ticket"
        class="sb-ticket--button-selection background-color-muted border-color-grey-200"
        tappable
        (click)="clearTicket()"
      >
        <span class="sb-ticket--button-title text-uppercase color-dark"> Cancelar </span>
      </button>

      <button
        id="btn-bet-1"
        class="sb-ticket--button-bet color-light"
        [ngClass]="betButton"
        tappable
        [disabled]="disableElement"
        (click)="placeBet()"
      >
        <span class="sb-ticket--button-title text-uppercase"> {{ btnBetText }} </span>
        <span
          class="sb-ticket--button-title"
          [ngClass]="{ 'text-small' : ticket?.TotalPotentialWin.toString().length > 8 }"
        >
          {{'hDetailsGan' | translate}}: {{ ticket?.TotalPotentialWin.toString() | parseStakeMoney:[1] }}
        </span>
      </button>
    </div>
    <div *ngIf="+ticket?.OveraskInfo?.Status == 3 || +ticket?.OveraskInfo?.Status == 2" class="sb-ticket--row-final">
      <button
        id="keep-selections-3-2"
        class="sb-ticket--button-selection color-dark background-color-muted border-color-grey-200"
        tappable
        (click)="keepSelections()"
      >
        <span class="sb-ticket--button-title"> {{ 'keepselections' | translate }} </span>
      </button>
      <button
        id="clear-ticket-3-2"
        class="sb-ticket--button-bet color-light background-color-primary border-color-primary"
        tappable
        (click)="clearTicket()"
      >
        <span class="sb-ticket--button-title text-uppercase"> Ok </span>
      </button>
    </div>
  </div>
</div>
