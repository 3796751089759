/* eslint-disable */
import { PaynetActionResult } from '@models/C_Transactions';
import { PaymentMethodBehaviour, PaymentMethodBehaviourResult, SkipAmountResult } from './PaymentMethodBase';
import { ErrorService } from '@providers/ErrorService';
import { UserService } from '@providers/UserService';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class PaynetMethodBehaviourResult extends PaymentMethodBehaviourResult {
  transactionReference: string;
  transactionCode: string;
  barCodeUrl: string;
  amount: number;
  date: string;
}

export class PaynetSkipAmountResult extends SkipAmountResult {
  transactionReference: string;
  transactionCode: string;
  barCodeUrl: string;
  amount: number;
  date: string;

  constructor() {
    super(true);
  }
}

export class PaynetPaymentMethodBehaviour extends PaymentMethodBehaviour {
  name = 'Paynet';
  image = 'Paynet';
  track = 'AccessToPaynet';
  isBank = false;
  minDepositAmount: number;
  maxDepositAmount: number;
  amountButtons: number[] = [300, 400, 800, 1000];

  constructor(userService: UserService, errorService: ErrorService) {
    super(false, userService, errorService);
  }

  paymentMetodExecution(): Observable<PaynetMethodBehaviourResult> {
    return this.userService.createOpenPayPaynetDeposit(this.amount, '').pipe(
      map((obj) => {
        return this.map(obj);
      })
    );
  }

  skipAmountInput(): Observable<PaynetSkipAmountResult> {
    const response = this.userService.checkOpenPayPaynetDeposit();
    return response.pipe(map(this.mapSkipAmount));
  }

  private mapSkipAmount(input: PaynetActionResult): PaynetSkipAmountResult {
    const result = new PaynetSkipAmountResult();
    // console.log("############Input Check########")
    if (input.paynetReference != '') {
      result.skip = true;
      result.amount = input.amount;
      result.transactionCode = input.transactionId;
      result.transactionReference = input.paynetReference;
      result.barCodeUrl = input.barcodeurl;
      result.date = input.duedate;
    } else {
      result.skip = false;
    }
    // console.log(input);
    // console.log(result);
    return result;
  }

  private map(input: PaynetActionResult): PaynetMethodBehaviourResult {
    const result = new PaynetMethodBehaviourResult();
    if (input.success) {
      // console.log("############Input########")
      result.success = true;
      result.amount = input.amount;
      result.transactionCode = input.transactionId;
      result.transactionReference = input.paynetReference;
      result.barCodeUrl = input.barcodeurl;
      result.date = input.duedate;
      // console.log(input);
      // console.log(result);
    } else {
      result.success = false;
      result.erroMessage = this.errorService.searchErrorCodeWithParams('error' + input.errCode, input.errorParameters);
      result.errorcode = input.errCode;
      result.sendToDocumentVerificationPage = input.errCode == 174 || input.errCode == 2212;
    }
    return result;
  }
}
