/* eslint-disable */
import { Directive, ElementRef, OnInit, OnChanges, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { Gesture, GestureController, DomController, GestureDetail, GestureConfig } from '@ionic/angular';
@Directive({
  selector: '[panHorizontal]' // Attribute selector
})
export class PanHorizontalDirective implements OnInit, OnChanges {
  gesture: Gesture;

  @Output() onOpened: EventEmitter<any> = new EventEmitter();
  @Output() onClosed: EventEmitter<any> = new EventEmitter();
  @Output() onBackground: EventEmitter<any> = new EventEmitter();
  @Input() closed: boolean;
  @Input() new: boolean = false;

  constructor(
    private element: ElementRef,
    private domCtrl: DomController,
    private gestureCtrl: GestureController
  ) {}

  ngOnInit() {
    // CAMBIOS NUEVA VERSIÓN
    this.gesture = this.gestureCtrl.create({
      el: this.element.nativeElement,
      threshold: 20,
      onStart: (ev) => {
        this.onStart(ev);
      },
      onEnd: (ev) => {
        this.onEnd(ev);
      },
      gestureName: 'test'
    });
    this.gesture.enable();

    // VERSIÓN ANTIGUA
    /*
      this.gesture = new Gesture(this.element.nativeElement, { threshold: 20 });

       this.gesture.listen();

       this.gesture.on('pan', e => {
        //let direction = e.additionalEvent === 'panleft' ? '-' : '';
        this.domCtrl.write(() => {
          let number = Math.min(
            Math.max(e.center.x, 0),
            document.body.clientWidth
          );
          this.element.nativeElement.style.transform =
            'translateX(' + number + 'px)';
          this.element.nativeElement.style.transition = '';
        });
        let opacity = 1 - e.center.x / document.body.clientWidth;
        this.onBackground.emit(opacity.toFixed(2));
        if(e.additionalEvent === 'panright'){
          this.onClosed.emit(true);
        }
      });

      this.gesture.on('panend', e => {
        if (e.center.x <= document.body.clientWidth / 1.5) {
          this.domCtrl.write(() => {
            this.element.nativeElement.style.transform =  this.new ? 'translateX(0)' : 'translateX(0)';
            this.element.nativeElement.style.transition = 'all 300ms ease';
          });

          this.onOpened.emit(true);
          //this.gesture.unlisten();
        } else if (e.center.x > document.body.clientWidth / 1.5) {
          this.domCtrl.write(() => {
            this.element.nativeElement.style.transform = 'translateX(100%)';
            this.element.nativeElement.style.transition = 'all 300ms ease';
          });
          // this.onOpened.emit(false);
          this.onBackground.emit(0);
        }
      }); */
  }

  // NUEVA VERSIÓN: onStart
  onStart(ev) {
    this.domCtrl.write(() => {
      let number = Math.min(Math.max(ev.center.x, 0), document.body.clientWidth);
      this.element.nativeElement.style.transform = 'translateX(' + number + 'px)';
      this.element.nativeElement.style.transition = '';
    });
    let opacity = 1 - ev.center.x / document.body.clientWidth;
    this.onBackground.emit(opacity.toFixed(2));
    if (ev.additionalEvent === 'panright') {
      this.onClosed.emit(true);
    }
  }

  // NUEVA VERSIÓN: onEnd
  onEnd(ev) {
    if (ev.center.x <= document.body.clientWidth / 1.5) {
      this.domCtrl.write(() => {
        this.element.nativeElement.style.transform = this.new ? 'translateX(0)' : 'translateX(0)';
        this.element.nativeElement.style.transition = 'all 300ms ease';
      });

      this.onOpened.emit(true);
      //this.gesture.unlisten();
    } else if (ev.center.x > document.body.clientWidth / 1.5) {
      this.domCtrl.write(() => {
        this.element.nativeElement.style.transform = 'translateX(100%)';
        this.element.nativeElement.style.transition = 'all 300ms ease';
      });
      // this.onOpened.emit(false);
      this.onBackground.emit(0);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.closed) {
      this.element.nativeElement.style.transition = 'all 300ms ease';
      this.element.nativeElement.style.transform = 'translateX(100%)';
      this.onOpened.emit(false);
      this.onBackground.emit(0);
    }
  }

  ngOnDestroy() {
    this.gesture.destroy();
  }
}
