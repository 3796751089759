export const CASINO_PATHS = {
  BASE: 'casino',
  CasinoPage: 'CasinoPage',
  SlotsPage: 'SlotsPage',
  AviatorPage: 'AviatorPage',
  FruitBlastPage: 'FruitBlastPage',
  WonderfulLampPage: 'WonderfulLampPage',
  OmnichannelPage: 'OmnichannelPage',
  SlotsdeSalonPage: 'SlotsdeSalonPage',
  VIPPage: 'VIPPage',
  JackpotPage: 'JackpotPage',
  SlotsBonusPage: 'SlotsBonusPage',
  SlotsHistoryPage: 'SlotsHistoryPage',
  SlotsGamePage: 'SlotsGamePage',
  CasinoEnVivoPage: 'CasinoEnVivoPage',
  LobbyCasinoPage: 'LobbyCasinoPage',
  BlackjackPage: 'BlackjackPage',
  PlaytechIframePage: 'PlaytechIframePage'
};

export const MSO_PATHS = {
  BASE: 'mso',
  InformationPage: 'InformationPage',
  GeolocationPage: 'GeolocationPage',
  InformationPageMobile: 'InformationPageMobile',
  ContactPage: 'ContactPage',
  PersonalInfoPage: 'PersonalInfoPage',
  PromotionsPage: 'PromotionsPage',
  PromotionsTestPage: 'PromotionsTestPage',
  NearestLocalPage: 'NearestLocalPage',
  UserMessagesPage: 'UserMessagesPage',
  DownloadApp: 'DownloadApp',
  CuotaTypePage: 'CuotaTypePage',
  HelpPage: 'HelpPage',
  MyActivityPage: 'MyActivityPage',
  RegistroNewPage: 'RegistroNewPage',
  RegistroARPage: 'RegistroARPage',
  RegistroMZAPage: 'RegistroMZAPage',
  RegistroPBAPage: 'RegistroPBAPage',
  RegistroPAPage: 'RegistroPAPage',
  RegistroCONewPage: 'RegistroCONewPage',
  AvisoPage: 'AvisoPage',
  LanguagePage: 'LanguagePage',
  AddFriendPage: 'AddFriendPage',
  ComunicationPage: 'ComunicationPage',
  SummaryOfMovementsPage: 'SummaryOfMovementsPage',
  CodereCardPage: 'CodereCardPage',
  MyCardPage: 'MyCardPage',
  MyCardPinPage: 'MyCardPinPage',
  MyCardRecoverPage: 'MyCardRecoverPage',
  MyCardRecover2Page: 'MyCardRecover2Page',
  MyCardUnlockPage: 'MyCardUnlockPage',
  MyCardTransferPage: 'MyCardTransferPage',
  CardRequestOkPage: 'CardRequestOkPage',
  CardRequestPage: 'CardRequestPage',
  CardLinkPage: 'CardLinkPage',
  Paso1Page: 'paso1',
  Paso2Page: 'paso2',
  limitsFormulPage: 'limitsFormulPage',
  ResponsibleGamblingTestPage: 'ResponsibleGamblingTestPage',
  SelfExclusionPage: 'SelfExclusionPage',
  QuickExit: 'QuickExit',
  CookiesConsentPage: 'CookiesConsentPage',
  TimeOutPage: 'TimeOut',
  ActivateUserPage: 'ActivateUserPage',
  ChargeHistoryPage: 'ChargeHistoryPage',
  CardRequestFormPage: 'CardRequestFormPage',
  MvpCoderePage: 'MvpCoderePage',
  ChangePasswordPage: 'ChangePasswordPage',
  SimulatorPage: 'SimulatorPage',
  LimitsPage: 'LimitsPage',
  CallMePage: 'CallMePage'
};

export const SPORTS_PATHS = {
  BASE: 'sports',
  BetDetailPage: 'BetDetailPage',
  BetslipUnlockerPage: 'BetslipUnlockerPage',
  CodereFantasy: 'CodereFantasy',
  CreaTuApuestaPage: 'Crea-Tu-Apuesta',
  CuotaChangePage: 'CuotaChangePage',
  DirectosPage: 'DirectosPage',
  EventoPage: 'EventoPage',
  EventoSpecialPage: 'EventoSpecialPage',
  FreeBetsPage: 'FreeBetsPage',
  HorsesMarketPage: 'HorsesMarketPage',
  HorsesPage: 'HorsesPage',
  MercadosPage: 'MercadosPage',
  MisApuestasPage: 'MisApuestasPage',
  MisApuestasPresencialesPage: 'MisApuestasPresencialesPage',
  NowLiveDetailPage: 'NowLiveDetailPage',
  PlayerpropsPage: 'PlayerpropsPage',
  SpecialTeamPage: 'SpecialTeamPage',
  SportCountriesPage: 'SportCountriesPage',
  SportEventsPage: 'SportEventsPage',
  TicketFreebetsPage: 'TicketFreebetsPage',
  TodayPage: 'TodayPage',

  MyBetsPage: 'MyBetsPage'
};

export const PAYMENTS_PATHS = {
  BASE: 'payments',
  PLAYSSTPAGE: 'PlaySstPage',
  CashierPage: 'CashierPage',
  ChargeCoderePage: 'ChargeCoderePage',
  ChargeCancelPage: 'ChargeCancelPage',
  CheckTicketPage: 'CheckTicketPage',
  CheckTicketStep2Page: 'CheckTicketStep2Page'
};

export const PAGES_PATHS = {
  BASE: '',
  HomePage: 'HomePage',
  LoadingPage: 'LoadingPage'
};
