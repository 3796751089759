<ng-container *ngIf="isMobile; then mobileTemplate; else deskTemplate"></ng-container>
<ng-template #deskTemplate>
  <ion-content *ngIf="renderIFrame" class="iframe-container">
    <ion-toolbar class="toolbarSectionName" style="display: none">
      <back-button tappable></back-button>
    </ion-toolbar>
    <iframe class="iframeFullScreen" [src]="scrReg" #iframeReg></iframe>
  </ion-content>

  <ion-footer *ngIf="renderIFrame">
    <button class="btnhelp" (click)="openChat()">{{'tHelpTittle' | translate}}</button>
  </ion-footer>
</ng-template>

<ng-template #mobileTemplate>
  <ion-content *ngIf="renderIFrame" class="iframe-container">
    <iframe [src]="scrReg" class="iframeFullScreen" frameborder="0" #iframeReg></iframe>
  </ion-content>
</ng-template>
