/* eslint-disable @typescript-eslint/no-explicit-any */
import { Observable } from 'rxjs';
import { TransactionActionResult } from '@models/C_Transactions';

export abstract class WithDrawalBase {
  name: string;
  image: string;
  className: string;
  number: number;
  track: string;
  isBank: boolean;
  isRecommended: boolean;
  enabled: boolean;
  amount: number;
  minDepositAmount: number;
  maxDepositAmount: number;
  amountButtons: Array<number>;
  transactionResult: TransactionActionResult;
  checkPaymentRequest: any;
  executeRequest: any;

  constructor({
    name,
    image,
    className,
    number,
    track,
    isBank,
    isRecommended,
    enabled,
    amount,
    minDepositAmount,
    maxDepositAmount,
    amountButtons,
    checkPaymentRequest,
    executeRequest
  }) {
    this.name = name;
    this.image = image;
    this.className = className;
    this.number = number;
    this.track = track;
    this.isBank = isBank;
    this.isRecommended = isRecommended || false;
    this.enabled = enabled;
    this.amount = amount;
    this.minDepositAmount = minDepositAmount;
    this.maxDepositAmount = maxDepositAmount;
    this.amountButtons = amountButtons;
    this.checkPaymentRequest = checkPaymentRequest;
    this.executeRequest = executeRequest;
  }

  abstract checkPayment(): Observable<any>;
  abstract executePayment(): Observable<any>;
  abstract executeOkCallback(): any;
  abstract showErrorCallback(): any;
}
