/* eslint-disable */
import { Component, Input, Output, EventEmitter, Renderer2, ViewChild, ElementRef, inject } from '@angular/core';
import { IonicModule, ModalController } from '@ionic/angular';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ParseMoney } from '../../../pipes/parseMoney';

@Component({
  selector: 'cs-calculator',
  templateUrl: './cs-calculator.html',
  styleUrls: ['./cs-calculator.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, TranslateModule, ReactiveFormsModule, ParseMoney]
})
export class CsCalculatorComponent {
  @ViewChild('amountAddButton')
  amountAddButton: ElementRef;
  @ViewChild('amountSubtractButton')
  amountSubtractButton: ElementRef;
  @ViewChild('input')
  inputCurrentAmount: ElementRef;

  @Input() amount: any;
  @Input() options: any;
  @Input() isActiveKeyboard: any;
  @Input() paymentSelected: any;
  @Input() blockCalculator: boolean = false;
  @Output() amountSelected: EventEmitter<any> = new EventEmitter();
  @Output() setCustomAmount: EventEmitter<any> = new EventEmitter();
  @Output() setInputKeyboardTrueLayer: EventEmitter<any> = new EventEmitter();
  @Output() setInputKeyboard: EventEmitter<any> = new EventEmitter();
  @Output() inputAmount: EventEmitter<any> = new EventEmitter();
  @Output() blockOperation: EventEmitter<boolean> = new EventEmitter();

  setIncrement: any;
  amountForm: FormGroup;
  activeKeyboard: boolean = false;

  fb = inject(FormBuilder);
  modalCtrl = inject(ModalController);
  _renderer = inject(Renderer2);

  constructor() {
    this.amountForm = this.fb.group({
      manualAmount: [20]
    });
  }

  ngOnChanges() {
    if (!!this.amount && !!this.amountForm) {
      this.manualAmount.patchValue(this.amount);
      this.blockCalculator
        ? this.amountForm.get('manualAmount').disable()
        : this.amountForm.get('manualAmount').enable();
    }
  }

  ngAfterViewInit() {
    const actionOn = this.options.isDesktop ? 'click' : 'touchstart';

    this._renderer.listen(this.amountAddButton.nativeElement, actionOn, (event) => {
      if (this.options.isDesktop) {
        this.setCustomAmount.emit('add');
      } else {
        this.setCustomAmount.emit('add');
        this.setIncrement = setInterval(() => {
          this.setCustomAmount.emit('add');
        }, 200);
      }
    });

    this._renderer.listen(this.amountSubtractButton.nativeElement, actionOn, (event) => {
      if (this.options.isDesktop) {
        this.setCustomAmount.emit('subtract');
      } else {
        this.setCustomAmount.emit('subtract');
        this.setIncrement = setInterval(() => {
          this.setCustomAmount.emit('subtract');
        }, 200);
      }
    });

    if (this.options.isDesktop) {
      this.manualAmount.setValue(this.amount);
      this.checkAmountOnDesktop();
    } else {
      this._renderer.listen(this.amountAddButton.nativeElement, 'touchend', (event) => {
        clearInterval(this.setIncrement);
      });
      this._renderer.listen(this.amountSubtractButton.nativeElement, 'touchend', (event) => {
        clearInterval(this.setIncrement);
      });
    }
  }

  get amountButtons(): boolean {
    return this.paymentSelected && this.paymentSelected.amountButtons
      ? this.paymentSelected.amountButtons.length > 0
      : false;
  }

  get disabledAdd(): boolean {
    const amount = this.options.isDesktop ? this.manualAmount.value : this.amount;
    return this.paymentSelected && this.paymentSelected.maxDepositAmount
      ? amount >= this.paymentSelected.maxDepositAmount
      : false;
  }

  get disabledSubstract(): boolean {
    const amount = this.options.isDesktop ? this.manualAmount.value : this.amount;
    return this.paymentSelected && this.paymentSelected.minDepositAmount
      ? amount <= this.paymentSelected.minDepositAmount
      : true;
  }

  get setFont(): any {
    return this.options.license === 4 || this.options.license === 21 || this.options.license === 22
      ? this.setFontSize(window.innerWidth)
      : '';
  }

  get manualAmount() {
    return this.amountForm.get('manualAmount');
  }

  setFontSize(widthDevice: number) {
    return widthDevice > 320 ? { fontSize: '15px' } : { fontSize: '11px' };
  }

  clickInputKeyboard() {
    if (!this.blockCalculator) {
      this.setInputKeyboard.emit();
      this.setInputKeyboardTrueLayer.emit();
    }
  }

  handleAmountChange(ev: InputEvent) {
    const amountInserted = parseInt((<HTMLInputElement>ev?.target).value);
    if (amountInserted > this.paymentSelected.maxDepositAmount) {
      this.blockOperation.emit(true);
    } else {
      this.blockOperation.emit(false);
    }
  }

  checkAmountOnDesktop() {
    this._renderer.listen(this.inputCurrentAmount.nativeElement, 'focusout', (event) => {
      if (this.manualAmount.value == null) {
        this.manualAmount.setValue(0);
        this.blockOperation.emit(false);
      }
      if (parseInt(this.paymentSelected.minDepositAmount) > this.manualAmount.value) {
        this.inputAmount.emit(this.manualAmount.value);
        this.manualAmount.setValue(parseInt(this.paymentSelected.minDepositAmount));
        this.blockOperation.emit(false);
      } else if (parseInt(this.paymentSelected.maxDepositAmount) < this.manualAmount.value) {
        this.inputAmount.emit(this.manualAmount.value);
        this.manualAmount.setValue(parseInt(this.paymentSelected.maxDepositAmount));
        this.blockOperation.emit(false);
      } else {
        this.inputAmount.emit(this.manualAmount.value);
        this.blockOperation.emit(false);
      }
    });
  }
}
