/* eslint-disable */
export enum LicenseTypes {
  Nacional = 0,
  Madrid = 1,
  PaisVasco = 2,
  Extremadura = 3,
  Mexico = 4,
  Colombia = 21,
  Panama = 22,
  ArgentinaCaba = 33,
  ArgentinaPBA = 34,
  ArgentinaCordoba = 35,
  ArgentinaMendoza = 36,
}

/* eslint-disable */
export enum TransactionTypes {
  Deposit = "Deposit",
  Withdrawal = "Withdrawal",
}

export enum Licenses {
  Madrid = "Madrid",
  Nacional = "Nacional",
}

export enum IdentificationDocumentTypes {
  ID = 754310000,
  LD = 754310001,
  PA = 754310002,
  OT = 754310003,
  SS = 754310004,
  DNI = 754310005,
  NIE = 754310006,
}

export enum GameTypes {
  Slots = "slots",
  Casino = "casino",
  VideoBingo = "bingo",
  Jackpot = "jackpot",
}

export enum BannersLibraries {
  SlotsMobile = "bannersslots",
  SlotsPC = "bannersslotsPC",
  CasinoMobile = "bannerscasino",
  CasinoPC = "bannerscasinoPC",
  VideoBingoMobile = "BannersVideoBingo",
  VideoBingoPC = "BannersVideoBingoPC",
}

export enum EventsSortMethods {
  None = 0,
  Default = 1,
}

export enum ValidationPatterns {
  NameSurname = "^[a-zA-ZÀ-ÖØ-öø-ÿ][a-zA-ZÀ-ÖØ-öø-ÿ\\s\\-]*[a-zA-ZÀ-ÖØ-öø-ÿ]$",
  NotStartsOrEndsWithBlank = "^\\S.*\\S$",
}

export enum DeviceType {
  PC = "pc",
  Cordova = "cordova",
  Mobile = "mobile",
}

export enum BankTransferForm {
  TCobIban = "TCobIban",
  TCobTC = "TCobTC",
  swift_bic = "swift_bic",
}

export enum VerificationTypes {
  DNI = "DNI",
  JustificanteBancario = "JustificanteBancario",
  Otros = "otros",
}

export enum VerificationStatus {
  Rejected = 754310000,
  Verified = 754310001,
  Pending = 1,
  Requested = 754310002,
  Inactive = 2,
}

export enum VerificationStringStatus {
  Rejected = "754310000",
  Verified = "754310001",
  Pending = "1",
  Requested = "754310002",
  Inactive = "2",
  Other = "754310003",
}

export enum VerificationTextColorClass {
  Rejected = "rejected",
  Verified = "verified",
  Pending = "pending",
  Requested = "requested",
  Inactive = "inactive",
}

export enum VerificationDocumentType {
  idFront = "idFront",
  idReverse = "idReverse",
  idSelfie = "selfie",
  idBank = "bank",
  idOther = "other",
  titleCardFront = "front",
  titleCardReverse = "reverse",
}
