import { C_LiveData } from './C_LiveData';

export class C_LiveDataSoccer extends C_LiveData {
  override MatchTime: number;
  YellowCardsHome: number;
  RedCardsHome: number;
  YellowCardsAway: number;
  RedCardsAway: number;

  constructor(
    actions: Array<any>,
    matchTime: number,
    ParticipantAway: string,
    participantHome: string,
    period: number,
    periodName: string,
    redCardsAway: number,
    redCardsHome: number,
    resultAway: number,
    resultHome: number,
    time: string,
    yellowCardsAway: number,
    yellowCardsHome: number
  ) {
    super(actions, ParticipantAway, participantHome, period, periodName, resultAway, resultHome, time);
    this.MatchTime = matchTime;
    this.YellowCardsHome = yellowCardsHome;
    this.RedCardsHome = redCardsHome;
    this.YellowCardsAway = yellowCardsHome;
    this.RedCardsAway = redCardsAway;
  }
}
