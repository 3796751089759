<!-- Desktop -->
<ng-container *ngIf="!isMobile; else mobileView">
  <div class="cs-detail--card w-100 p-1 m-auto background-color-white">
    <div class="cs-detail--title w-100 py-1">
      <span class="color-dark">{{ history?.Referencia }}</span>
    </div>
    <div class="cs-detail--row w-100 py-1">
      <span class="color-muted">Transacción:</span>
      <span class="color-dark">{{ history?.Tipo }}</span>
    </div>
    <div *ngIf="!!history?.CodigoTransacion" class="cs-detail--row w-100 py-1">
      <span class="color-muted">Código Transacción:</span>
      <span class="color-dark">{{ history?.CodigoTransacion }}</span>
    </div>
    <div class="cs-detail--row w-100 py-1">
      <span class="color-muted">{{ 'tMet' | translate }}:</span>
      <span class="color-dark">{{ history?.Metodo }}</span>
    </div>
    <div class="cs-detail--row w-100 py-1">
      <span class="color-muted">{{ 'tImp' | translate }}:</span>
      <span class="color-dark">{{ history?.Cantidad | parseMoney }}</span>
    </div>
    <div class="cs-detail--row w-100 py-1" *ngIf="history?.CantidadImpuestos">
      <span class="color-muted">{{ 'tImpTax' | translate }}:</span>
      <span class="color-dark">{{ history?.CantidadImpuestos | parseMoney }}</span>
    </div>
    <div class="cs-detail--row w-100 py-1" *ngIf="history?.CantidadNeta">
      <span class="color-muted">{{ 'tImpNet' | translate }}:</span>
      <span class="color-dark">{{ history?.CantidadNeta | parseMoney }}</span>
    </div>
    <div class="cs-detail--row w-100 py-1" *ngIf="displayRetainedAmount">
      <span class="color-muted">Importe Retenido:</span>
      <span class="color-dark">{{ history?.RetainedAmount | parseMoney }}</span>
    </div>
    <div class="cs-detail--row w-100 py-1">
      <span class="color-muted">{{ 'hDate' | translate }}:</span>
      <span class="color-dark">{{ history?.Fecha | extractDate:[2] }}</span>
    </div>
    <div class="cs-detail--row w-100 py-1">
      <span class="color-muted">Nº Cuenta:</span>
      <span class="color-dark">{{ history?.Cuenta }}</span>
    </div>
    <div class="cs-detail--row w-100 py-1 m-bottom-1">
      <span class="color-muted">{{ 'hStade' | translate }}:</span>
      <span class="color-dark">{{ history?.Estado }}</span>
    </div>
    <div class="cs-detail--button-group w-100">
      <button
        *ngIf="setCancelWithdrawal"
        class="cs-detail--button background-color-secondary"
        tappable
        (click)="cancelPayment()"
      >
        <span class="cs-detail-button--text color-light"> {{ 'tCancel' | translate }} </span>
      </button>
      <button class="cs-detail--button background-color-primary" tappable (click)="close()">
        <span class="cs-detail-button--text color-light"> OK </span>
      </button>
    </div>
  </div>
</ng-container>

<!-- Mobile -->
<ng-template #mobileView>
  <div class="cs-detail--card w-100 p-1 m-auto background-color-white">
    <div class="cs-detail--title w-100 py-1">
      <span class="color-dark">{{ history?.Referencia }}</span>
    </div>
    <div class="cs-detail--row w-100 py-1">
      <span class="color-muted">Transacción:</span>
      <span class="color-dark">{{ history?.Tipo }}</span>
    </div>
    <div *ngIf="!!history?.CodigoTransacion" class="cs-detail--row w-100 py-1">
      <span class="color-muted">Código Transacción:</span>
      <span class="color-dark">{{ history?.CodigoTransacion }}</span>
    </div>
    <div class="cs-detail--row w-100 py-1">
      <span class="color-muted">{{ 'tMet' | translate }}:</span>
      <span class="color-dark">{{ history?.Metodo }}</span>
    </div>
    <div class="cs-detail--row w-100 py-1">
      <span class="color-muted">{{ 'tImp' | translate }}:</span>
      <span class="color-dark">{{ history?.Cantidad | parseMoney }}</span>
    </div>
    <div class="cs-detail--row w-100 py-1" *ngIf="history?.CantidadImpuestos">
      <span class="color-muted">{{ 'tImpTax' | translate }}:</span>
      <span class="color-dark">{{ history?.CantidadImpuestos | parseMoney }}</span>
    </div>
    <div class="cs-detail--row w-100 py-1" *ngIf="history?.CantidadNeta">
      <span class="color-muted">{{ 'tImpNet' | translate }}:</span>
      <span class="color-dark">{{ history?.CantidadNeta | parseMoney }}</span>
    </div>
    <div class="cs-detail--row w-100 py-1" *ngIf="displayRetainedAmount">
      <span class="color-muted">Importe Retenido:</span>
      <span class="color-dark">{{ history?.RetainedAmount | parseMoney }}</span>
    </div>
    <div class="cs-detail--row w-100 py-1">
      <span class="color-muted">{{ 'hDate' | translate }}:</span>
      <span class="color-dark">{{ history?.Fecha | extractDate:[2] }}</span>
    </div>
    <div class="cs-detail--row w-100 py-1">
      <span class="color-muted">Nº Cuenta:</span>
      <span class="color-dark">{{ history?.Cuenta }}</span>
    </div>
    <div class="cs-detail--row w-100 py-1 m-bottom-1">
      <span class="color-muted">{{ 'hStade' | translate }}:</span>
      <span class="color-dark">{{ history?.Estado }}</span>
    </div>
    <div class="cs-detail--button-group w-100">
      <button
        *ngIf="setCancelWithdrawal"
        class="cs-detail--button background-color-secondary"
        tappable
        (click)="cancelPayment()"
      >
        <span class="cs-detail-button--text color-light"> {{ 'tCancel' | translate }} </span>
      </button>
      <button class="cs-detail--button background-color-primary" tappable (click)="close()">
        <span class="cs-detail-button--text color-light"> OK </span>
      </button>
    </div>
  </div>
</ng-template>
