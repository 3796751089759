<div class="sb-prematch-scoreboard--block background-color-dark">
  <div class="sb-prematch-scoreboard--item-left">
    <p class="sb-prematch-scoreboard--title color-light">{{ scoreboard?.home }}</p>
    <p class="sb-prematch-scoreboard--subtitle" *ngIf="scoreboard?.pitcherHome">{{ scoreboard?.pitcherHome }}</p>
  </div>
  <div class="sb-prematch-scoreboard--badge background-color-secondary">
    <p class="sb-prematch-scoreboard--number color-light">{{ scoreboard?.date | extractDate }}</p>
  </div>
  <div class="sb-prematch-scoreboard--item-right">
    <p class="sb-prematch-scoreboard--title color-light">{{ scoreboard?.away }}</p>
    <p class="sb-prematch-scoreboard--subtitle" *ngIf="scoreboard?.pitcherAway">{{ scoreboard?.pitcherAway }}</p>
  </div>
</div>
