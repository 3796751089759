<ng-container *ngIf="isMobile; then mobileTemplate; else desktopTemplate"> </ng-container>
<ng-template #desktopTemplate>
  <ion-content class="has-titleBar background-color-muted">
    <user-section-title [sectionTitle]="'quickexit'"></user-section-title>
    <ion-list class="subDep">
      <div class="masinfo text-center marginb23">
        <span>{{'quickexittxt01' | translate}} </span>
      </div>
      <div class="myWrapInput buttonsLine" [class.has-error]="!validDate">
        <ion-row class="colsForm firstInput" [class.error]="!validDate">
          <ion-col>
            <ion-item>
              <ion-input
                [label]="'quickexit' | translate"
                labelPlacement="floating"
                value="12hr"
                disabled="true"
              ></ion-input>
            </ion-item>

            <!-- <ion-item class="cdr-item-input-floating">
              <ion-label floating class="cdr-label-floating"> {{'quickexit' | translate}} </ion-label>
              <ion-input class="item-input-disabled" value="12hr" disabled="true" type="text"></ion-input>
            </ion-item> -->
          </ion-col>
        </ion-row>
      </div>
      <div class="masinfo text-center marginb23">
        <span> {{'quickexittxt02' | translate}}</span>
        <span (click)="goToSelfExclusion()" class="txthref">'{{'uPJRAut' | translate}}'</span>
      </div>
      <div class="sendForm">
        <button ion-button full (click)="exitClick()">{{'tHelpSend'|translate}}</button>
      </div>
    </ion-list>
    <codere-footer></codere-footer>
  </ion-content>
</ng-template>

<ng-template #mobileTemplate>
  <ion-header>
    <user-section-title [sectionTitle]="'quickexit'"></user-section-title>
  </ion-header>
  <ion-content>
    <div class="myWrapInput" [class.has-error]="!validDate">
      <ion-list class="payment-sublist">
        <div class="masinfo text-center marginb23">
          <span>{{'quickexittxt01' | translate}} </span>
        </div>
        <ion-row class="colsForm firstInput" [class.error]="!validDate">
          <ion-col>
            <ion-item class="static-select">
              <ion-input class="item-input-disabled" value="12hr" disabled="true" type="text"></ion-input>
            </ion-item>
          </ion-col>
        </ion-row>

        <div
          [hidden]="(validDate || !myForm.controls['day'].touched || !myForm.controls['month'].touched || !myForm.controls['year'].touched)"
          class="labelError"
        >
          <span>
            {{'date_valid' | translate}}
            <!--Por favor introduce una fecha válida.--></span
          >
        </div>

        <div class="masinfo text-center marginb23">
          <span> {{'quickexittxt02' | translate}}</span>
          <span (click)="goToSelfExclusion()" class="txthref">'{{'uPJRAut' | translate}}'</span>
        </div>
        <div style="height: 50px"></div>
        <div class="sendForm">
          <button ion-button color="primary" full (click)="exitClick()">{{'tHelpSend'|translate}}</button>
        </div>
      </ion-list>
    </div>
    <codere-footer></codere-footer>
  </ion-content>
</ng-template>
