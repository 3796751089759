import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncateString',
  standalone: true
})
export class TruncateStringPipe implements PipeTransform {
  transform(value: string, length = 20): string {
    if (value.length > length) {
      value = value.substring(0, length).concat('...');
    }
    return value;
  }
}
