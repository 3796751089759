/* eslint-disable */
export enum LicenseTypes {
  Nacional = 0,
  Madrid = 1,
  PaisVasco = 2,
  Extremadura = 3,
  Mexico = 4,
  Colombia = 21,
  Panama = 22,
  ArgentinaCaba = 33,
  ArgentinaPBA = 34,
  ArgentinaCordoba = 35,
  ArgentinaMendoza = 36
}

/* eslint-disable */
export enum TransactionTypes {
  Deposit = 'Deposit',
  Withdrawal = 'Withdrawal'
}

export enum Licenses {
  Madrid = 'Madrid',
  Nacional = 'Nacional'
}

export enum IdentificationDocumentTypes {
  ID = 754310000,
  LD = 754310001,
  PA = 754310002,
  OT = 754310003,
  SS = 754310004,
  DNI = 754310005,
  NIE = 754310006
}

export enum GameTypes {
  Slots = 'slots',
  Casino = 'casino',
  VideoBingo = 'bingo',
  Jackpot = 'jackpot'
}

export enum BannersLibraries {
  SlotsMobile = 'bannersslots',
  SlotsPC = 'bannersslotsPC',
  CasinoMobile = 'bannerscasino',
  CasinoPC = 'bannerscasinoPC',
  VideoBingoMobile = 'BannersVideoBingo',
  VideoBingoPC = 'BannersVideoBingoPC'
}

export enum EventsSortMethods {
  None = 0,
  Default = 1
}

export enum ValidationPatterns {
  NameSurname = '^[a-zA-ZÀ-ÖØ-öø-ÿ][a-zA-ZÀ-ÖØ-öø-ÿ\\s\\-]*[a-zA-ZÀ-ÖØ-öø-ÿ]$',
  NotStartsOrEndsWithBlank = '^\\S.*\\S$'
}

export enum DeviceType {
  PC = 'pc',
  Cordova = 'cordova',
  Mobile = 'mobile'
}
