export interface TargetOptInModel {
  eventName?: string;
  displayBody?: string;
  termsLink?: string;
  keyTerms?: string;
  buttonText?: string;
  daysToRedeem?: number;
  cantidad?: number;
  confirmationText?: string;
  bonusID?: string;
  optinCategory?: string;
}

// eslint-disable-next-line no-shadow
export enum TrackerValue {
  DECLINED = '_declined',
  WINDOWCLOSED = '_windowclosed',
  ASKMELATER = '_askMeLater',
  ACCEPTED = '_accepted'
}
