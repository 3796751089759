import { C_Event } from './C_Event';

export class C_EventMostBet {
  Events: Array<C_Event>;
  Locked: boolean;
  Name: string;
  NodeId: string;
  ParentNodeId: string;
  Priority: number;
  SportHandle: string;
  SportId: string;
  SportOrder: number;
  SportName: string;
  key: any;

  constructor() {}
}
