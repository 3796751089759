<section class="ticket-selection-betbuilder--wrapper">
  <ion-grid class="ticket-selection-betbuilder--grid">
    <ion-row class="ticket-selection-betbuilder--row">
      <ion-col [size]="multiSingleActivated ? '8' : '12'" class="sportsbook ticket-selection-betbuilder--col left">
        <div class="sb-ticket-betbuilder-items-wrap">
          <span
            *ngFor="let item of smartLineItem?.SmartLineItems; first as isFirst; last as isLast;"
            class="sb-ticket-betbuilder--content-left-ball-container"
          >
            <img
              class="sb-ticket-betbuilder--content-left-ball"
              src="assets/global/img/sb-betbuilderItem.svg"
              [ngClass]="{ 'first-element': isFirst, 'last-element': isLast }"
            />
            <p class="sb-ticket-betbuilder--content-left-ball-item">
              <span class="sb-ticket-selection-betbuilder--title color-light">{{ item.Name }} </span>
              <span class="sb-ticket-selection-betbuilder--subtitle color-grey-500"> - {{ item.GameName }} </span>
            </p>
          </span>
        </div>
        <div class="d-flex w-100">
          <span class="sb-ticket-betbuilder--title m-right-1 color-grey-500">
            {{ smartLineItem?.EventName | translate }}
            <span class="m-left-1 sb-ticket-betbuilder--subtitle color-grey-500"
              >{{ smartLineItem?.Odd.toString() | formatOdd }}</span
            >
          </span>
          <i
            class="codere-icon"
            [ngClass]="{
            'sb-button--animation': smartLineItem?.upOdd || smartLineItem?.downOdd,
            'is-down': smartLineItem?.downOdd,
            'is-up': smartLineItem?.upOdd
          }"
          >
          </i>
        </div>
        <span
          class="sb-ticket-betbuilder--subtitle color-grey-500"
          *ngIf="isTicketClosed && smartLineItem?.TicketNumber"
        >
          {{ 'compapuestaP3' | translate }} # {{ smartLineItem?.TicketNumber }}
        </span>
      </ion-col>
      <ion-col size="4" class="sportsbook ticket-selection-betbuilder--col right" *ngIf="multiSingleActivated">
        <ion-button
          class="sports-btn btn-light sportsbook stake-button"
          [ngClass]="{ 'is-selected': smartLineItem?.Selected && keyboard }"
          (click)="selectAmount()"
          *ngIf="!isDesktop"
        >
          <ion-label>
            <span
              class="sb-ticket--button-title text-small"
              [ngClass]="{ 'text-small' : smartLineItem?.AmountLocal.toString().length > 8 }"
            >
              {{ smartLineItem?.AmountLocal.toString() | parseStakeMoney:[1] }}
            </span>
          </ion-label>
        </ion-button>
        <sb-betslip-stake-input
          *ngIf="isDesktop && !getIsProcessing()"
          [minBetValue]="minBetValue"
          [ticket]="ticket"
          [lineItem]="smartLineItem"
          [stakeAmount]="smartLineItem?.Amount?.toString()"
          (update)="updateStake($event)"
          [currency]="currency"
          [mode]="getTicketSelectionMode()"
        ></sb-betslip-stake-input>
        <span class="sb-ticket-betbuilder--subtitle color-primary">
          {{'hDetailsGan' | translate}}: {{ smartLineItem?.TotalPotentialWin.toString() | parseStakeMoney:[1] }}
        </span>
      </ion-col>
      <button
        class="ticket-selection-betbuilder--delete"
        tappable
        (click)="remove()"
        *ngIf="!isTicketClosed"
        [disabled]="keyboard || disabled"
      >
        <i class="codere-icon icon-close color-light"></i>
      </button>
    </ion-row>
  </ion-grid>
</section>
