<div
  class="contModal"
  [hidden]="activeSection!==1"
  [ngClass]="{'backgroundheader': globalVars.FEATURES?.MSO_NewReBranding}"
>
  <button class="closeModal" (click)="closeLogin()">
    <ion-icon name="close-outline"></ion-icon>
  </button>
  <form #loginForm="ngForm" novalidate (submit)="doLogin(loginForm)">
    <ion-list>
      <ion-list-header class="hideTemp">
        <span
          ><ion-icon ios="ios-alert-outline" md="ios-alert-outline"></ion-icon>{{'v2_login' | translate}}<!--# ¡Para poer apostar tienes que iniciar sesión!--></span
        >
      </ion-list-header>

      <ion-item class="cdr-item-input" lines="none">
        <ion-input
          class="ion-input-no-transform-label"
          labelPlacement="floating"
          label="{{'loginUserEmail' | translate}}"
          type="text"
          [(ngModel)]="login.username"
          name="username"
          #username="ngModel"
          required
          autocomplete="off"
          autoCorrect="off"
          spellcheck="off"
          #userInput
        >
        </ion-input>
      </ion-item>

      <ion-item class="cdr-item-input" lines="none">
        <ion-input
          class="ion-input-no-transform-label"
          label="{{'loginPass' | translate}}"
          labelPlacement="floating"
          [type]="typePassword"
          [(ngModel)]="login.password"
          name="password"
          #password="ngModel"
          autocomplete="off"
          autoCorrect="off"
          spellcheck="off"
          required
        ></ion-input>
        <ion-icon name="eye" *ngIf="!showPassword" (click)="onClickShowPassword()"></ion-icon>
        <ion-icon name="eye-off" *ngIf="showPassword" (click)="onClickShowPassword()"></ion-icon>
      </ion-item>
    </ion-list>
    <button ion-button type="button" class="rePass button button-clear" clear (click)="gotoSection(2)">
      {{'tForgotPass' | translate}}<!--¿Olvidaste tu contraseña?-->
    </button>
    <div class="sendForm">
      <button ion-button class="button button-md button-full" type="submit" full id="btnaccess">
        <!-- [track-event]='["attemptLogin", "", login.username, "attemptLogin", "event"]' -->
        {{'access' | translate}}<!--Acceder-->
      </button>

      <ion-item class="keepSession" *ngIf="globalVars.DEVICE_TYPE !== 'web' && !globalVars.FEATURES?.MSO_SkipAutologin">
        <ion-toggle
          labelPlacement="start"
          checked="false"
          [(ngModel)]="login.rememberMe"
          name="rememberMe"
          #rememberMe="ngModel"
          >{{'stayConnec' | translate}}</ion-toggle
        >
      </ion-item>
    </div>
    <hr class="line" />
    <div class="goReg">
      <p>
        {{'v2_registrado' | translate}}
        <!--# ¿Aún no estás registrado?-->
      </p>
      <button ion-button type="button" class="button button-md button-full" (click)="openRegistro()">
        {{'signUp' | translate}}<!--Regístrate-->
      </button>
    </div>
  </form>
</div>

<div class="contModal" [hidden]="activeSection!==2">
  <button ion-button type="button" class="button-close closeModal button" (click)="closeLogin()">
    <ion-icon name="close-outline"></ion-icon>
  </button>
  <form #getPassword="ngForm" novalidate>
    <ion-list>
      <div class="contactTitle">
        {{'tRemPass' | translate}}
        <!--Recordar contraseña-->
      </div>

      <hr />
      <ion-item class="cdr-item-input passwordRecovery" lines="none">
        <ion-input
          class="ion-input-no-transform-label"
          label="{{ 'loginUserEmail' | translate }}"
          labelPlacement="floating"
          class="cdr-input-floating"
          type="text"
          [(ngModel)]="formData.user"
          name="user"
          #user="ngModel"
          required
        ></ion-input>
      </ion-item>

      <div class="myWrapInput">
        <div class="subTitleSub titleCaptcha">
          <span>{{'cTQues' | translate}}<!--¿Cuánto es?--></span>
          <div class="captha">
            <img [src]="captchaUrl" alt="" class="captchaWrap" />
            <i class="icon-reload codere-icon icon-reload" (click)="newCaptcha()"></i>
          </div>
        </div>
        <ion-item class="cdr-item-input passwordRecovery" (input)="onlyNumbers()" lines="none">
          <ion-input
            class="ion-input-no-transform-label"
            label="{{'v2_intrRes' | translate}}"
            labelPlacement="floating"
            class="cdr-input-floating"
            type="text"
            [(ngModel)]="formData.captcha"
            name="captcha"
            #captcha="ngModel"
            required
          ></ion-input>
        </ion-item>
      </div>
    </ion-list>

    <div class="sendForm">
      <ion-row>
        <ion-col col-6>
          <button
            ion-button
            type="button"
            class="button button-md button-clear button-full"
            clear
            full
            (click)="gotoSection(1)"
          >
            {{'v2_volver' | translate}}<!--# Volver-->
          </button>
        </ion-col>
        <ion-col col-6>
          <button
            ion-button
            type="submit"
            class="button button-md button-full"
            full
            (click)="doGetPassword(getPassword)"
          >
            {{'tHelpSend' | translate}}
            <!--Enviar-->
          </button>
        </ion-col>
      </ion-row>
    </div>
  </form>
</div>
