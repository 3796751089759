/* eslint-disable */
import { Injectable, inject } from "@angular/core";
import soliticsconfig from "@config/soliticsconfig";
import { LicenseTypes } from "@models/MasterData";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { NewBaseService } from "@providers/newBase.service";

/**
 *  @author a.e.m
 *  @version 1.0.0
 *  @description
 *    no se usa de momento, se preve para la migracion a angular 9 y
 *    el uso de leazy load de los modulos
 */
declare var $solitics: Solitics;
export abstract class Solitics {
  loginSuccess: Function;
  onLogout: Function;
  onPageEnter: Function;
  emit: Function;
}

@Injectable({ providedIn: "root" })
export class SoliticsService {
  brand: string;
  isLoggedIn: boolean = false;
  popupToken: string = "";
  globalVars: GlobalVarsModel;
  baseService = inject(NewBaseService);
  constructor() {
    this.baseService.getVars.subscribe((data) => {
      this.globalVars = data;
    });
    switch (this.globalVars.licenseType) {
      case LicenseTypes.Colombia:
        this.brand = "codere_colombia";
        break;
      case LicenseTypes.Nacional:
        this.brand = "codere_spain";
        break;
      case LicenseTypes.Panama:
        this.brand = "codere_panama";
        break;
      case LicenseTypes.ArgentinaCaba:
        this.brand = "codere_argentina";
        break;
      case LicenseTypes.ArgentinaPBA:
        this.brand = "codere_argentinapba";
        break;
      default:
        this.brand = "";
    }
    this.popupToken = soliticsconfig[this.globalVars.licenseType].insights.solitics.token;
  }

  /**
   *
   * @param event
   */
  login(event) {
    if (!$solitics) return;
    const adf = JSON.parse(event.additionalData);
    var email = this.globalVars.user.email;
    var id = this.globalVars.user.id;
    var loginObj = {
      // memberId:id,
      keyValue: this.globalVars.user.username,
      keyType: "username",
      email: email,
      brand: this.brand,
      popupToken: this.popupToken,
    };
    console.log("Solitics : loginSuccess");
    console.log(loginObj);
    $solitics.loginSuccess(loginObj);
    var d = this.globalVars;

    if (d) {
      var payload = {
        deviceOperatingSystem: this.globalVars.DEVICE_TYPE,
        email: email,
        memberId: id,
      };
      if (d.user && d.user.balance) {
        payload["userBalance"] = d.user.balance;
      }
      var l = location.href.split("#");
      if (l[1]) payload["eventProductTab"] = l[1];
      console.log("Solitics : event : Login");
      console.log(payload);
      $solitics.emit("LoginComplete", null, JSON.stringify(payload));
    }

    //extra Data
  }
  /**
   *
   */
  logout() {
    if (!$solitics) return;
    $solitics.onLogout();
  }
  onPageEnter(p) {}
  betCompleted(event) {
    const adf = JSON.parse(event.additionalData);
    var payload = {
      betType: event.secondParameter,
      currency: adf.currencyIsoCode,
    };
    if (adf.data) {
      payload["amount"] = adf.data.amount;
      payload["isLive"] = adf.data.isLive;
      payload["products"] = adf.data.products;
      payload["odd"] = adf.data.odd;
    }
    console.log("Solitics : event : betCompleted");
    console.log(payload);
    $solitics.emit("BetCompleted", null, JSON.stringify(payload));
  }

  gameLaunched(event, type) {
    var payload = {
      name: event.description,
      type: type,
    };
    console.log("Solitics : event : gameInit");
    console.log(payload);
    $solitics.emit("GameLaunched", null, JSON.stringify(payload));
  }
}
