<ng-container *ngIf="isDesktop; else mobileView">
  <div class="checkbox-filter-group">
    <div *ngFor="let filter of filterGroup.filters" class="checkbox-filter-item">
      <ion-checkbox (ionChange)="selectFilter(filter)" [checked]="filter.selected" labelPlacement="end">
        {{ filter.text }} ({{ filter.count || 0 }})
      </ion-checkbox>
    </div>
  </div>
</ng-container>

<ng-template #mobileView>
  <div class="checkbox-filter-group">
    <div *ngFor="let filter of filterGroup.filters" class="checkbox-filter-item">
      <ion-checkbox justify="start" (ionChange)="selectFilter(filter)" [checked]="filter.selected" labelPlacement="end">
        <div class="check-content">
          {{ filter.text }}
          <span class="count"> ({{ filter.count || 0 }}) </span>
        </div>
      </ion-checkbox>
    </div>
  </div>
</ng-template>
