import { CUSTOM_ELEMENTS_SCHEMA, Component, Input, NO_ERRORS_SCHEMA, inject } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ControlErrorComponent } from '../control-error/control-error.component';
import { InfoComponent } from '../info/info.component';
import { MaestroDatos, MaestrosService } from '../../../services/registro/maestros.service';
import { InputComponent } from '../input/input.component';

/**
 * Generated class for the TimeComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'cdr-time',
  templateUrl: './time.html',
  standalone: true,
  styleUrls: ['./time.scss'],
  imports: [CommonModule, IonicModule, ReactiveFormsModule, InfoComponent, ControlErrorComponent, InputComponent]
})
export class TimeComponent {
  @Input() title: string;
  @Input() labels: any;
  @Input() errorsCol: any;
  @Input() fgrp: FormGroup;

  @Input() isMobile = false;

  // Arrays de datos
  months: Array<MaestroDatos>;
  years: Array<number>;
  selectOptions: { mode: string; cssClass: string };
  isDayFocused = false;
  isMonthFocused = false;
  isYearFocused = false;
  fControl: FormControl;
  maestros = inject(MaestrosService);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}
  ngOnInit() {
    this.months = this.maestros.getMeses();
    this.years = this.maestros.getYears();
    this.selectOptions = {
      mode: 'md',
      cssClass: 'registro-select'
    };
  }

  onClickSelect() {
    setTimeout(() => {
      const popover = document.querySelector('ion-popover');
      popover.classList.add('popover-registro');
    }, 50);
  }
  handleDayBlur() {
    this.isDayFocused = false;
  }

  handleDayFocus() {
    this.isDayFocused = true;
  }
  handleMonthBlur() {
    this.isMonthFocused = false;
  }

  handleMonthFocus() {
    this.isMonthFocused = true;
  }
  handleYearBlur() {
    this.isYearFocused = false;
  }

  handleYearFocus() {
    this.isYearFocused = true;
  }
}
