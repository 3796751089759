/* eslint-disable eqeqeq */
import { Component, EventEmitter, Input, Output, OnInit, inject, ViewEncapsulation } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TrackingService } from '@providers/TrackingService';
import { LicenseTypes } from '@models/MasterData';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CookieNames } from '@models/CookieNames';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { GlobalVarsModel } from '@models/ModelGlobalsVar';
import { NewBaseService } from '@providers/newBase.service';
import { Utils } from '@utils/Utils';
import { EventsService } from '@providers/EventsService';
import { UserServiceMSO } from '@providers/UserServiceMSO';
import { Router } from '@angular/router';
import { DeviceService } from '@services/device.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'cdr-step3-se',
  templateUrl: './step3-se.html',
  styleUrls: ['./step3-se.scss'],
  standalone: true,
  imports: [CommonModule, IonicModule, TranslateModule, ReactiveFormsModule]
})
export class Step3SeComponent implements OnInit {
  @Output() changeStep: EventEmitter<number>;
  @Input() dates: Date[] | string = [];
  @Input() city = '';
  text: string;
  textButton0: string;
  textButton1: string;
  timenow: Date = new Date();
  myform: FormGroup;
  isDesktop: boolean = false;
  isMobile: boolean = false;

  globalVars!: GlobalVarsModel;

  translate = inject(TranslateService);
  fb = inject(FormBuilder);
  userServiceMSO = inject(UserServiceMSO);
  newBaseService = inject(NewBaseService);
  utils = inject(Utils);
  trackingService = inject(TrackingService);
  formbuilder = inject(FormBuilder);
  events = inject(EventsService);
  deviceService = inject(DeviceService);
  router = inject(Router);

  constructor() {
    this.newBaseService.getVars.subscribe((vars) => {
      this.globalVars = vars;
    });

    this.text = 'AutoExclusión - Step3';
    this.textButton0 = 'Anterior';
    this.textButton1 = 'Siguiente';

    this.isDesktop = this.deviceService.isDesktop();
    this.isMobile = this.deviceService.isMobile();

    this.changeStep = new EventEmitter();
    this.myform = this.formbuilder.group({
      firm1: [false, Validators.compose([Validators.required, Validators.requiredTrue])],
      firm2: [false, Validators.compose([Validators.required, Validators.requiredTrue])]
    });
  }

  ngOnInit(): void {}

  goToStep(n) {
    this.changeStep.next(n);
  }

  onSelfExclusion() {
    let selfExclusionDate = '';
    if (typeof this.dates != 'string') {
      let day = '';
      let month = '';
      if (this.dates[1].getDate() < 10) {
        day = '0' + this.dates[1].getDate();
      } else {
        day = this.dates[1].getDate().toString();
      }
      if (this.dates[1].getMonth() + 1 < 10) {
        month = '0' + (this.dates[1].getMonth() + 1);
      } else {
        month = (this.dates[1].getMonth() + 1).toString();
      }
      selfExclusionDate =
        this.dates[1].getFullYear() +
        '-' +
        month +
        '-' +
        // day;
        day +
        ' ' +
        (this.dates[1].getHours() + ':' + this.dates[1].getMinutes()); // día y hora: 23:59
    } else if (this.dates === 'indefinido') {
      selfExclusionDate = '2500-12-31';
    }
    if (selfExclusionDate === '') {
      return;
    }
    this.userServiceMSO.createAutoExclusion(selfExclusionDate, this.globalVars.renewSelfExclusion).subscribe((data) => {
      if (data == true || data.success || data.flag === 'true') {
        if (this.globalVars.renewSelfExclusion)
          this.trackingService.trackEvent(['RenewSelfExclusionOk', selfExclusionDate, '', '', 'event']);
        else this.trackingService.trackEvent(['CreateSelfExclusionOk', selfExclusionDate, '', '', 'event']);
        this.globalVars.renewSelfExclusion = false;
        this.utils.alert(true, '', this.translate.instant('exclusiontxt01'), 'OK', () => {
          if (this.globalVars.licenseType == LicenseTypes.Nacional) {
            this.events.publish('user:logout');
            this.events.publish('navbarpc:refresh');
          }
          this.router.navigate(['/']);
        });
        this.changeUserCookie();
      } else {
        if (this.globalVars.renewSelfExclusion)
          this.trackingService.trackEvent(['RenewSelfExclusionKo', selfExclusionDate, '', '', 'event']);
        else this.trackingService.trackEvent(['CreateSelfExclusionKo', selfExclusionDate, '', '', 'event']);

        this.globalVars.renewSelfExclusion = false;
        this.utils.alert(true, '', this.translate.instant('exclusiontxt02'), 'OK');
        this.router.navigate(['/']);
      }
    });
  }
  backToHome() {
    this.router.navigate(['/'], { queryParams: { animate: false } });
  }

  get isString() {
    return typeof this.dates === 'string';
  }

  public getMonthName() {
    return new Intl.DateTimeFormat('es-ES', {
      month: 'long'
    }).format(this.timenow);
  }
  public changeUserCookie() {
    const userInfo = JSON.parse(this.globalVars.rootScope.cookiesService.getCookie(CookieNames.UserInfo));
    if (userInfo) {
      const newUserInfo = {
        ...userInfo,
        selfExcluded: true
      };
      this.globalVars.rootScope.cookiesService.setCookie(CookieNames.UserInfo, JSON.stringify(newUserInfo), null, true);
    }
    if (this.globalVars.licenseType == LicenseTypes.Colombia) {
      this.globalVars.extraUserData.isRevocationPage = this.setCanRevocate();
      this.globalVars.rootScope.navService.initRightMenu(true);
    }
  }
  public setCanRevocate() {
    if (typeof this.dates != 'string') {
      const dateNow: Date = new Date();
      dateNow.setHours(dateNow.getHours() + 72);
      return dateNow.getTime() < this.dates[1].getTime();
    } else {
      return false;
    }
  }
}
