/* eslint-disable */
import { Component, Input, OnInit, inject } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ErrorsControls, LabelContactoControls, Secciones } from '@interfaces/interfaces';
import { InputComponent } from '../input/input.component';

@Component({
  standalone: true,
  imports: [CommonModule, IonicModule, ReactiveFormsModule, InputComponent],
  selector: 'cdr-fs-contacto',
  templateUrl: './fs-contacto.component.html',
  styleUrls: ['./fs-contacto.component.scss']
})
export class FsContactoComponent implements OnInit {
  // Objetos del UI
  errors: ErrorsControls;
  labels: LabelContactoControls;
  secciones: Secciones;
  mbPreImg: string;

  // FormControl
  @Input() formGrp: FormGroup;
  @Input('isResident') isResident$: Observable<boolean>;
  formGrpName: string;
  titleSection: string;
  zipType: string;
  flagEnabled: boolean = true;

  translate = inject(TranslateService);

  constructor() {}

  ngOnInit() {
    this.formGrpName = 'contacto';
    this.titleSection = this.translate.instant('v2_registerStep2');
    this.labels = this.setLabels();
    this.errors = this.setErrors();
    this.mbPreImg = 'assets/global/img/esflag.png';
    this.zipType = 'number';
    if (this.isResident$) {
      this.isResident$.subscribe((isResident) => {
        this.zipType = isResident ? 'number' : 'text';
        this.mbPreImg = isResident ? 'assets/global/img/esflag.png' : null;
      });
    }
  }

  private setLabels(): LabelContactoControls {
    return {
      address: this.translate.instant('pIAddress'), //Dirección
      postalCode: this.translate.instant('rZip'), // Código postal
      mobile: this.translate.instant('pIMvl'), // Móvil
      email: this.translate.instant('pIMail') // Email
    };
  }

  private setErrors(): ErrorsControls {
    return {
      address: {
        required: 'Introduce correctamente alguna dirección',
        minlength: 'Introduce una dirección con al menos con 2 caracteres'
      },
      postalCode: {
        required: this.translate.instant('error_cpRule'),
        //	'Debes introducir un código postal válido'
        validFormat: 'El formato del código postal no es correcto'
      },
      mobile: {
        required: this.translate.instant('error_form_mvl_r'),
        //'Introduce tu número de móvil'
        validFormat:
          this.translate.instant('error_mvlRule') !== 'error_mvlRule'
            ? this.translate.instant('error_mvlRule')
            : 'El formato del móvil no es correcto',
        validUnique: 'Este móvil ya existe en Codere'
      },
      email: {
        required: this.translate.instant('v2_emailObl'), //El email es obligatorio
        validFormat: this.translate.instant('error_format'), //'El formato no es válido',
        validUnique: this.translate.instant('error_emailmxreg') //El correo electrónico ya está registrado.
      }
    };
  }
}
