<div class="sb-grid-item--teams">
  <p class="sb-grid-item--title color-dark">
    {{ home | titleCase:event?.CountryCode }}
    <span *ngIf="pitcherHome" class="color-muted">{{ pitcherHome | titleCase }}</span>
  </p>
  <p class="sb-grid-item--title color-dark">
    {{ away | titleCase:event?.CountryCode }}
    <span *ngIf="pitcherAway" class="color-muted">{{ pitcherAway | titleCase }}</span>
  </p>
  <p
    class="sb-grid-item--subtitle"
    [ngClass]="{
    'color-muted': !event?.isLive,
    'color-accent': event?.isLive,
    'has-streaming': isLive
    }"
  >
    {{ date }} <span *ngIf="!date && isLive" style="color: transparent; margin-left: -8px">-</span>
    <span *ngIf="matchTime"> {{ matchTime }}</span>
  </p>
</div>
