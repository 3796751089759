/* eslint-disable */
import { PaymentMethodBehaviour, SkipAmountResult, RedirectMethodBehaviourResult } from './PaymentMethodBase';
import { MercadoPagoActionResult } from '@models/C_Transactions';
import { UserService } from '@providers/UserService';
import { ErrorService } from '@providers/ErrorService';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class MercadoPagoRedirectMethodBehaviour extends PaymentMethodBehaviour {
  name = 'MercadoPagoRedirect';
  image = 'logo_MercadoPago';
  track = 'AccessToMercadoPago';
  isBank = false;
  minDepositAmount = 10;
  maxDepositAmount = 100000;
  amountButtons: number[] = [10, 20, 40, 50];
  override amount = 20;
  cards: boolean = null;

  constructor(userService: UserService, errorService: ErrorService, cards?: boolean) {
    super(false, userService, errorService);
    this.cards = cards;
  }

  skipAmountInput(): Observable<SkipAmountResult> {
    return new Observable<SkipAmountResult>();
  }

  paymentMetodExecution(): Observable<RedirectMethodBehaviourResult> {
    return this.userService.validateMergadoPagoDeposit('', this.amount, this.cards).pipe(
      map((obj) => {
        return this.map(obj);
      })
    );
  }

  private map(input: MercadoPagoActionResult): RedirectMethodBehaviourResult {
    const result = new RedirectMethodBehaviourResult();
    if (input.success) {
      result.url = input.url;
      result.success = true;
    } else {
      result.success = false;
      result.erroMessage = this.errorService.searchErrorCodeWithParams('error' + input.errCode, input.errorParameters);
      result.errorcode = input.errCode;
      result.sendToDocumentVerificationPage = input.errCode == 174 || input.errCode == 2212;
    }
    return result;
  }
}
