<div>
  <div *ngIf="isFAQ && verificationLiterals.questions.FAQ.QA.length > 0" class="FAQ">
    <span [innerHTML]="verificationLiterals.questions.FAQ.title"></span>
  </div>
  <cdk-accordion class="cs-documents-accordion">
    <cdk-accordion-item *ngFor="let accordionQuestion of accordionQuestions; let index = index;"
      #accordionItem="cdkAccordionItem" class="cs-documents-accordion-item" role="button" tabindex="0"
      [attr.id]="'accordion-header-' + index" [attr.aria-expanded]="accordionItem.expanded"
      [attr.aria-controls]="'accordion-body-' + index">
      <div class="cs-documents-accordion-item-header" (click)="accordionItem.toggle()">
        {{ accordionQuestion.question }}
        <img style="width:20px"
          [src]="accordionItem.expanded ? 'assets/global/img/codereIcons/arr-down-li-grey.svg' : 'assets/global/img/codereIcons/arr-up-li-grey.svg'" />
      </div>
      <div class="cs-documents-accordion-item-body" role="region" [style.display]="accordionItem.expanded ? '' : 'none'"
        [attr.id]="'accordion-body-' + index" [attr.aria-labelledby]="'accordion-header-' + index"
        [innerHTML]="accordionQuestion.answer">
      </div>
    </cdk-accordion-item>
  </cdk-accordion>
</div>