<div class="reels-filter-group">
  <div
    *ngFor="let filter of filterGroup.filters; let first = first; let last = last"
    class="reels-filter-item"
    [class.selected]="filter.selected"
  >
    <ion-checkbox (ionChange)="selectFilter(filter)" [checked]="filter.selected" labelPlacement="end">
      {{ filter.text }}
    </ion-checkbox>
    <div class="reels-filter-icon-container">
      <div
        *ngFor="let index of generateRange(filter.id); let first = first; let last = last"
        class="reels-filter-icon-div"
      >
        <ion-icon *ngIf="first" src="assets/global/img/slot-bar-left.svg" class="reels-filter-icon first"></ion-icon>
        <ion-icon
          *ngIf="!first"
          src="assets/global/img/slot-bar-center.svg"
          class="reels-filter-icon middle"
        ></ion-icon>
        <ion-icon src="assets/global/img/reel.svg" class="reels-filter-icon principal"></ion-icon>
        <ion-icon *ngIf="last" src="assets/global/img/slot-bar-right.svg" class="reels-filter-icon last"></ion-icon>
      </div>
    </div>
    <div class="reels-filter-count">({{ filter.count || 0 }})</div>
  </div>
</div>
