<form class="cs-transfer py-1 w-100" [formGroup]="formTransfer">
  <div
    class="cs-transfer--field background-color-white m-bottom-1"
    [class.valid]="formTransfer?.get('name').valid"
    [class.error]="formTransfer?.get('name').invalid && formTransfer?.get('name').touched"
  >
    <input
      class="cs-transfer--input w-100 p-left-1"
      type="text"
      placeholder="{{'TCobTit' | translate}}"
      formControlName="name"
    />
  </div>

  <div
    *ngIf="license === 21 || license === 22"
    class="cs-transfer--field background-color-white m-bottom-1"
    [class.valid]="formTransfer?.get('type').valid"
    [class.error]="formTransfer?.get('type').invalid && formTransfer?.get('type').touched"
    tappable
    (click)="setInput('TCobTC')"
  >
    <span class="cs-transfer--input w-100 p-left-1">{{ typeSelected }}</span>
    <i class="codere-icon icon-arrow-bottom color-dark p-right-1"></i>
  </div>

  <div
    *ngIf="license !== 21"
    class="cs-transfer--field background-color-white m-bottom-1"
    [class.valid]="formTransfer?.get('account').valid"
    [class.error]="formTransfer?.get('account').invalid && formTransfer?.get('account').touched"
  >
    <input
      class="cs-transfer--input w-100 p-left-1"
      type="text"
      placeholder="{{'TCobIban' | translate}}"
      formControlName="account"
    />
    <ion-icon
      *ngIf="license !== 22 && license !== 21"
      class="p-right-1"
      name="information-circle-outline"
      tappable
      (click)="showInputInfo('iban')"
    >
    </ion-icon>
  </div>

  <div
    *ngIf="license === 21"
    class="cs-transfer--field background-color-white m-bottom-1"
    [class.valid]="formTransfer?.get('account').valid"
    [class.error]="formTransfer?.get('account').invalid"
  >
    <ion-select
      [interfaceOptions]="accountSelectOptions"
      placeholder="{{'TCobIban' | translate}}"
      class="cs-transfer--select w-100"
      interface="alert"
      formControlName="account"
    >
      <ion-select-option *ngFor="let account of bankAccounts" [value]="account.iban">
        {{account.iban}}
      </ion-select-option>
    </ion-select>
  </div>

  <div
    *ngIf="license !== 21 && license !== 22"
    class="cs-transfer--field background-color-white m-bottom-1"
    [class.valid]="formTransfer?.get('swiftbic').valid"
    [class.error]="formTransfer?.get('swiftbic').invalid && formTransfer?.get('swiftbic').touched"
  >
    <input
      class="cs-transfer--input w-100 p-left-1"
      type="text"
      placeholder="{{'swift_bic' | translate }}"
      formControlName="swiftbic"
    />
    <ion-icon class="p-right-1" name="information-circle-outline" tappable (click)="showInputInfo('swift')"> </ion-icon>
  </div>

  <div
    *ngIf="license === 21"
    class="cs-transfer--field background-color-white m-bottom-1"
    [class.valid]="formTransfer?.get('swiftbic').valid"
    [class.error]="formTransfer?.get('swiftbic').invalid && formTransfer?.get('swiftbic').touched"
    tappable
    (click)="setInput('swift_bic')"
  >
    <span class="cs-transfer--input w-100 p-left-1">{{ bankSelected }}</span>
    <i class="codere-icon icon-arrow-bottom color-dark p-right-1"></i>
  </div>

  <div
    *ngIf="license === 22"
    class="cs-transfer--field background-color-white m-bottom-1"
    [class.valid]="formTransfer?.get('swiftbic').valid"
    [class.error]="formTransfer?.get('swiftbic').invalid && formTransfer?.get('swiftbic').touched"
    tappable
    (click)="setInput('swift_bic')"
  >
    <span class="cs-transfer--input w-100 p-left-1">{{ bankSelected }}</span>
    <i class="codere-icon icon-arrow-bottom color-dark p-right-1"></i>
  </div>

  <div class="text-center p-bottom-2">
    <button
      class="cs-action-button background-color-secondary"
      tappable
      (click)="emitWithdrawal()"
      [disabled]="!formTransfer?.valid"
    >
      <span class="cs-action-button--text color-light"> {{ 'uPCCob' | translate }} </span>
    </button>
  </div>
</form>
