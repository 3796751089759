import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'my-tab',
  styles: [
    `
      .pane {
        padding: 1em;
      }
    `
  ],
  template: `
    <div [hidden]="!active" class="pane">
      <ng-content></ng-content>
    </div>
  `
})
export class TabComponent {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('tabTitle') title!: string;
  @Input() active = false;
}
