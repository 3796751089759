<div class="amount p-top-1 color-dark">{{'paymentreference' | translate }}</div>
<div class="title p-2 color-dark">{{ payvalidaResult?.reference }}</div>
<div class="amount p-1 color-dark">
  {{'amountpaid' | translate}}: <span>{{payvalidaResult?.amount | parseMoney }}</span>
</div>
<div class="text p-1">{{'paycashbefore' | translate}}: <span class="">{{payvalidaResult?.date}}</span></div>
<div class="img">
  <img alt="company logo" *ngIf="payValidaCashPaymentMethodBehaviour?.type" [src]="getLogo" />
</div>
<span class="mx-3 text-center color-dark">
  Ahora por favor lleva estos datos de tu transacción al punto {{ payValidaCashPaymentMethodBehaviour?.name }} más
  cercano para completar tu depósito
</span>
