/* eslint-disable eqeqeq */
import { CommonModule } from "@angular/common";
import { Component, EventEmitter, HostListener, Input, Output, inject } from "@angular/core";
import { LicenseTypes, VerificationStatus } from "@models/MasterData";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { NewBaseService } from "@providers/newBase.service";
import { FundsOrigin } from "@providers/payments/BankService";
import { DeviceService } from "@services/device.service";
import { CsVerificationItemComponent } from "../cs-verification-item/cs-verification-item";

@Component({
  selector: "cs-document-item",
  templateUrl: "./cs-document-item.html",
  styleUrls: ["./cs-document-item.scss"],
  standalone: true,
  host: {
    class: "cs-document-item background-color-white color-dark",
  },
  imports: [TranslateModule, CommonModule, CsVerificationItemComponent],
})
export class CsDocumentItemComponent {
  @Input() document: any;
  @Input() verificationType = false;
  @Input() openInstrucionsNumber = 0;
  @Input() isOriginFunds?: boolean;
  @Input() originFunds?: string | FundsOrigin;
  @Input() doc?: any;
  @Input() iconName?: string;
  @Output() navigate: EventEmitter<any> = new EventEmitter();
  private globalVars!: GlobalVarsModel;
  private newBaseService = inject(NewBaseService);
  public translate = inject(TranslateService);
  public deviceService = inject(DeviceService);
  isVeridasCOActive = false;
  isMobile = false;
  isDesktop = false;

  constructor() {
    this.isMobile = this.deviceService.isMobile();
    this.isDesktop = this.deviceService.isDesktop();
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
      const { FEATURES } = this.globalVars;
      this.isVeridasCOActive = FEATURES.Payments_VeridasCOActivev3;
    });
  }

  @HostListener("click")
  _navigate() {
    if (this.showButtonByStatus()) {
      this.navigate.emit();
    }
  }

  public get applyClassToDocumentContainer() {
    const defaultClass = "cs-document-item--container";
    return this.isColombia ? `${defaultClass} m-left-1` : defaultClass;
  }

  public get isArgentina() {
    return (
      this.globalVars.licenseType === LicenseTypes.ArgentinaPBA ||
      this.globalVars.licenseType === LicenseTypes.ArgentinaCaba ||
      this.globalVars.licenseType === LicenseTypes.ArgentinaMendoza
    );
  }

  public get isSpain() {
    return (
      this.globalVars.licenseType === LicenseTypes.Nacional ||
      this.globalVars.licenseType === LicenseTypes.Madrid ||
      this.globalVars.licenseType === LicenseTypes.PaisVasco
    );
  }

  public get isColombia() {
    return this.globalVars.licenseType === LicenseTypes.Colombia;
  }

  public showButtonByStatus() {
    if (this.doc) {
      const { status } = this.doc;
      // don't show upload button when verified or pending.
      switch (status) {
        case VerificationStatus.Verified:
          return false;
        case VerificationStatus.Rejected:
          return true;
        case VerificationStatus.Pending:
          return false;
        default:
          return true;
      }
    } else return true;
  }
}
