import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseService } from '@providers/BaseService';
import { Utils } from '@utils/Utils';
import { GlobalVarsModel } from '@models/ModelGlobalsVar';

@Injectable({ providedIn: 'root' })
export class RedirectService extends BaseService {
  override globalVars: GlobalVarsModel;
  override utils: Utils;
  override httpClient: HttpClient;
  constructor() {
    super();
  }

  loginfromJWT(jwt: string) {
    //** TODO */
    // let url: string = `${this.globalVars.UrlBaseLoginServices}/login/authorize`;
    let url: '';
    //
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    return this.myPost(url, { 'x-token': jwt }, { headers: headers });
  }

  generateJWTFromData(data: string) {
    //** TODO */
    // let url: string = `${this.globalVars.UrlUserServiceApiBase}/encrypt`;
    let url: '';
    //
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    return this.myPost(url, { data }, { headers: headers });
  }

  loadDataFromJWT(jwt: string) {
    //** TODO */
    // let url: string = `${this.globalVars.UrlUserServiceApiBase}/decrypt`;
    let url: '';
    //
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    return this.myPost(url, { data: jwt }, { headers: headers });
  }
}
