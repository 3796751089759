import { Component, ViewChild, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavController, ModalController, IonicModule, IonContent } from '@ionic/angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

//Services
import { UserService } from '../../../providers/UserService';
import { TrackingService } from '../../../providers/TrackingService';
import { EventsService } from '../../../providers/EventsService';
import { DeviceService } from '../../../services/device.service';
import { GlobalVarsModel } from '../../../models/ModelGlobalsVar';
import { MSO_PATHS } from '../../../lib/constants/routes';
import { NewBaseService } from '../../../providers/newBase.service';
import { Utils } from '../../../utils/Utils';

import { CodereFooterComponent } from '../../../components/mso/footer/codere-footer/codere-footer';
import { UserSectionTitle } from '../../../components/mso/user-section-title/user-section-title';
import { CodereNavbar } from '../../../components/common/codere-navbar/codere-navbar';

import { AddFriendSharePage } from '../add-friend-share/add-friend-share';

import { Router } from '@angular/router';

@Component({
  standalone: true,
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'add-friend-page',
  templateUrl: './add-friend.html',
  styleUrls: ['./add-friend.scss'],
  imports: [IonicModule, CommonModule, TranslateModule, UserSectionTitle, CodereFooterComponent, CodereNavbar]
})
export class AddFriendPage {
  @ViewChild(IonContent) content: IonContent;
  friendsID: string;
  globalVars!: GlobalVarsModel;
  isMobile: boolean;

  translate = inject(TranslateService);
  utils = inject(Utils);
  nav = inject(NavController);
  trackingService = inject(TrackingService);
  events = inject(EventsService);
  userService = inject(UserService);
  modalCtrl = inject(ModalController);
  newBaseService = inject(NewBaseService);
  route = inject(Router);
  device = inject(DeviceService);

  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });

    this.isMobile = this.device.isMobile();

    this.friendsID = '';
    this.getFriendsID();
  }

  ionViewWillEnter() {
    this.route.navigate([MSO_PATHS.BASE + '/' + MSO_PATHS.AddFriendPage]);
    this.trackingService.trackEvent(['AccessToAddFriends', '', '', 'Acceder a AddFriends', 'event']);
    this.events.publish('footer:refresh', this.content);
  }

  getFriendsID() {
    //llamada a getFriendsID
    this.userService.getFriendsID().subscribe((data) => {
      if (data) {
        this.friendsID = data.friendId;
        this.globalVars.friendsID = this.friendsID;
      }
    });
  }

  async openShareFriend() {
    this.trackingService.trackEvent(['openModalAddFriends', '', '', 'Abrir model AddFriends', 'event']);
    const modal = await this.modalCtrl.create({ component: AddFriendSharePage });
    modal.present();
  }
}
