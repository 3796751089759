import { Directive, Input, inject } from '@angular/core';
import { CasinoFilter, CasinoFilterGroup } from '@interfaces/casinoMoreFiltersInterfaces';
import { DeviceService } from '@services/device.service';

@Directive({
  standalone: true
})
export class BaseFilter {
  deviceService = inject(DeviceService);

  @Input() filterGroup: CasinoFilterGroup;

  isDesktop = false;

  constructor() {
    this.isDesktop = this.deviceService.isDesktop();
  }

  selectFilter(filter: CasinoFilter): void {
    filter.selected = !filter.selected;
  }
}
