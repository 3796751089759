<ng-container *ngIf="!isMobile; else mobileView">
  <ion-content>
    <div class="section-titleclose">
      <h1 class="title" padding>{{'additionalinformation' | translate | uppercase}}</h1>
      <div class="iconclo">
        <button class="closeModal fontwhite" (click)="closeModal()">
          <ion-icon name="close-outline"></ion-icon>
        </button>
      </div>
    </div>

    <ion-list-header class="register-section">
      <span>{{'modalDocumentNumberTitle' | translate}}</span>
    </ion-list-header>

    <div padding>
      <div class="myWrapInput static-select">
        <ion-item class="firstInput">
          <ion-select
            label="{{'NotResidDocumentType' | translate}}"
            labelPlacement="floating"
            placeholder="{{'NotResidDocumentType' | translate}}"
            cancelText="{{'tCancel'|translate}}"
            class="selectInput"
            [(ngModel)]="viewModel.documentType"
            [formControl]="getFormControl('documentType')"
          >
            <ion-select-option
              *ngFor="let documentType of selectorsMasterData.notResidentDocumentTypes"
              [value]="documentType.value"
              >{{documentType.text}}</ion-select-option
            >
          </ion-select>
        </ion-item>
        <div
          [hidden]="validationForm.controls['documentType'].valid || !validationForm.controls['documentType'].touched"
          class="labelError"
        >
          <span *ngIf="validationForm.controls['documentType'].hasError('required')">
            {{'ErrorNotResidDocumentTypeRequired' | translate}}</span
          >
        </div>
      </div>
      <div
        class="myWrapInput static-select"
        [class.has-error]="!validationForm.controls['documentNumber'].valid && validationForm.controls['documentNumber'].touched"
      >
        <ion-item
          [class.error]="!validationForm.controls['documentNumber'].valid && validationForm.controls['documentNumber'].touched"
          class="cdr-item-input-floating"
        >
          <!--Número de documento-->
          <ion-input
            labelPlacement="floating"
            type="text"
            [(ngModel)]="viewModel.documentNumber"
            [formControl]="getFormControl('documentNumber')"
          >
            <div slot="label">
              <ion-text color="medium">{{'NotResidDocumentNumber' | translate}}</ion-text>
            </div>
          </ion-input>
        </ion-item>
        <div
          [hidden]="validationForm.controls['documentNumber'].valid || !validationForm.controls['documentNumber'].touched"
          class="labelError"
        >
          <span *ngIf="validationForm.controls['documentNumber'].hasError('required')">
            {{'ErrorNotResidDocumentNumberRequired' | translate}}</span
          >
          <span *ngIf="validationForm.controls['documentNumber'].hasError('minlength')">
            {{'ErrorNotResidDocumentNumberMinLenght' | translate}}</span
          >
        </div>
      </div>
    </div>

    <ion-footer>
      <div class="sendform" padding>
        <button
          ion-button
          tappable
          (click)="accept()"
          style="background: #0055fe; color: #fff; font-size: 1.5rem; font-weight: 700"
        >
          {{'tAccept' | translate }}
        </button>
      </div>
    </ion-footer>
  </ion-content>
</ng-container>

<ng-template #mobileView>
  <ion-header>
    <codere-navbar titulo=""></codere-navbar>
    <user-section-title [sectionTitle]="'additionalinformation'"></user-section-title>
  </ion-header>
  <ion-content>
    <ion-list-header class="register-section">
      <span>{{'modalDocumentNumberTitle' | translate}}</span>
    </ion-list-header>
    <div padding>
      <div class="myWrapInput static-select">
        <ion-item class="firstInput">
          <ion-select
            label="{{'NotResidDocumentType' | translate}}"
            labelPlacement="floating"
            placeholder="{{'NotResidDocumentType' | translate}}"
            cancelText="{{'tCancel'|translate}}"
            class="selectInput"
            [(ngModel)]="viewModel.documentType"
            [formControl]="getFormControl('documentType')"
          >
            <ion-select-option
              *ngFor="let documentType of selectorsMasterData.notResidentDocumentTypes"
              [value]="documentType.value"
              >{{documentType.text}}</ion-select-option
            >
          </ion-select>
        </ion-item>
        <div
          [hidden]="validationForm.controls['documentType'].valid || !validationForm.controls['documentType'].touched"
          class="labelError"
          style="margin-top: -23px !important"
        >
          <span *ngIf="validationForm.controls['documentType'].hasError('required')">
            {{'ErrorNotResidDocumentTypeRequired' | translate}}</span
          >
        </div>
      </div>
      <div
        class="myWrapInput static-select"
        [class.has-error]="!validationForm.controls['documentNumber'].valid && validationForm.controls['documentNumber'].touched"
      >
        <ion-item
          [class.error]="!validationForm.controls['documentNumber'].valid && validationForm.controls['documentNumber'].touched"
          class="cdr-item-input-floating"
        >
          <!--Número de documento-->
          <ion-input
            label="{{'NotResidDocumentNumber' | translate}}"
            labelPlacement="floating"
            type="text"
            [(ngModel)]="viewModel.documentNumber"
            [formControl]="getFormControl('documentNumber')"
          >
          </ion-input>
        </ion-item>
        <div
          [hidden]="validationForm.controls['documentNumber'].valid || !validationForm.controls['documentNumber'].touched"
          class="labelError"
        >
          <span *ngIf="validationForm.controls['documentNumber'].hasError('required')">
            {{'ErrorNotResidDocumentNumberRequired' | translate}}</span
          >
          <span *ngIf="validationForm.controls['documentNumber'].hasError('minlength')">
            {{'ErrorNotResidDocumentNumberMinLenght' | translate}}</span
          >
        </div>
      </div>
    </div>
  </ion-content>
  <ion-footer>
    <div class="sendform" padding>
      <button ion-button tappable (click)="accept()" style="background: #0055fe; color: #fff">
        {{'tAccept' | translate }}
      </button>
    </div>
  </ion-footer>
</ng-template>
