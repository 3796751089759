import { Component } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { BaseFilter } from '../base-filter/base-filter';

@Component({
  selector: 'reels-filter',
  imports: [IonicModule, CommonModule],
  standalone: true,
  providers: [],
  templateUrl: './reels-filters.component.html',
  styleUrls: ['./reels-filters.component.scss'],
  host: {
    class: 'reels-filters'
  }
})
export class ReelsFiltersComponent extends BaseFilter {
  constructor() {
    super();
  }

  generateRange(id: string): number[] {
    const length = parseInt(id);
    const array = Array(length)
      .fill(0)
      .map((_, index) => index);
    return array;
  }
}
