/* eslint-disable eqeqeq */
import {
  AbstractControl,
  AsyncValidatorFn,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn
} from '@angular/forms';
import { Observable, Subscription, of, from } from 'rxjs';
import { passwErrors } from '@interfaces/interfaces';
import { UserServiceMSO } from '@providers/UserServiceMSO';
import { MaestroDatos } from './services/registro/maestros.service';

type ErrorBirthDate = 'validFormat' | 'notValidDate';

export class RegistroValidators {
  // Validadores Sincronos

  static zipCode =
    (fcResident: AbstractControl): ValidatorFn =>
    (control: AbstractControl): ValidationErrors | null => {
      //if esp => max cp 52999
      //if no residente => only numbers
      const resident: MaestroDatos = fcResident.value;
      const zipRegEsp = /^[0-9]{5}$/;
      const zipRegNR = /^[A-Za-z0-9]{1,15}$/;
      //
      if (control?.value?.length > 0) {
        if (resident) {
          if (resident.id == 0) {
            // residente en España
            if (zipRegEsp.test(control.value) && parseInt(control.value) < 52999) {
              return null;
            } else {
              return { validFormat: 'notValid' };
            }
          } else {
            // No residente
            if (zipRegNR.test(control.value)) {
              return null;
            } else {
              return { validFormat: 'notValid' };
            }
          }
        } else {
          return { validFormat: 'notValid' };
        }
      } else {
        return null;
      }
    };

  static passwd =
    (fgPers: FormGroup, fgUsr: FormGroup): ValidatorFn =>
    (control: AbstractControl): ValidationErrors | null =>
      RegistroValidators.oldPasswordValidator(control.value, { ...fgPers.value, ...fgUsr.value });

  private static oldPasswordValidator = (value: string, register: any): ValidationErrors | null => {
    const control: any = { value: value };
    //passwordValidator
    //^(?=.*[A-Z].*[A-Z])(?=.*[!@#$&*])(?=.*[0-9].*[0-9])(?=.*[a-z].*[a-z].*[a-z]).{8}$
    const minLength = 8;
    const maxLength = 20;
    const mayReg = /[A-ZÑ]/;
    const minReg = /[a-zñ]/;
    const numReg = /\d/;

    let birthDate = '';
    if (register.birthYear) {
      birthDate = register.birthDay + '' + register.birthMonth.id + '' + register.birthYear.toString();
    }
    if (!control.value) {
      return { required: 'required' };
    } else if (!minReg.test(control.value)) {
      return { validPassword: passwErrors.noMin };
    } else if (!mayReg.test(control.value)) {
      return { validPassword: passwErrors.noMay };
    } else if (!numReg.test(control.value)) {
      return { validPassword: passwErrors.noNum };
    } else if (control.value.length < minLength) {
      return { validPassword: passwErrors.tooShort };
    } else if (control.value.length > maxLength) {
      return { validPassword: passwErrors.tooLong };
    } else if (register.name && control.value.toLowerCase().indexOf(register.name.toLowerCase()) != -1) {
      return { validPassword: passwErrors.incName }; //name
    } else if (register.name2 && control.value.toLowerCase().indexOf(register.name2.toLowerCase()) != -1) {
      return { validPassword: passwErrors.incSurname }; //surname
    } else if (register.name3 && control.value.toLowerCase().indexOf(register.name3.toLowerCase()) != -1) {
      return { validPassword: passwErrors.incSurname2 }; //surname2
    } else if (birthDate && control.value.toLowerCase().indexOf(birthDate) != -1) {
      return { validPassword: passwErrors.incBirthday }; //birthDay
    } else if (register.user && control.value.toLowerCase().indexOf(register.user.toLowerCase()) != -1) {
      return { validPassword: passwErrors.incUser }; //user
    } else {
      return null;
    }
  };

  static edad =
    (fcDay: AbstractControl, fcMonth: AbstractControl, fcYear: AbstractControl): ValidatorFn =>
    (control: AbstractControl): ValidationErrors | null => {
      let validDate: boolean;
      let checkedDate = false;
      let errorValid: ErrorBirthDate | null = null;
      if (fcDay.value && fcMonth.value && fcMonth.value.id && fcYear.value) {
        //check the date
        const miDate: Date = new Date(parseInt(fcYear.value), parseInt(fcMonth.value.id) - 1, parseInt(fcDay.value));

        if (
          miDate.getDate() == parseInt(fcDay.value) &&
          miDate.getMonth() == parseInt(fcMonth.value.id) - 1 &&
          miDate.getFullYear() == parseInt(fcYear.value)
        ) {
          errorValid = RegistroValidators.validAge(miDate, 18, 150);
          validDate = !errorValid;
          checkedDate = true;
        } else {
          validDate = false;
        }
      } else {
        validDate = false;
      }
      if (validDate) {
        return null;
      } else if (checkedDate) {
        return { [errorValid]: 'notValid' };
      }
      return { notValidDate: 'notValid' };
    };

  private static validAge(birthDay: Date, minAge: number, maxAge: number): ErrorBirthDate | null {
    const today: Date = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);
    today.setMilliseconds(0);

    const yearsMax = new Date(today.getTime());
    yearsMax.setFullYear(today.getFullYear() - (maxAge + 1));
    const yearsMin = new Date(today.getTime());
    yearsMin.setFullYear(today.getFullYear() - minAge);

    if (birthDay <= yearsMax || birthDay > today) {
      return 'notValidDate';
    }

    if (birthDay >= yearsMin) {
      return 'validFormat';
    }
    return null;
  }

  static oldAddressValidator = (control: FormControl): ValidationErrors | null => {
    if (control.value) {
      const addr: string = control.value.trim();
      if (addr.length >= 2 && addr.length <= 50) {
        return null;
      } else {
        return { validFormat: 'notValid' };
      }
    } else return null;
  };

  static supportNumberValidator = (control: FormControl): ValidationErrors | null => {
    if (control.value) {
      let sp: string = control.value.trim();
      sp = sp.toUpperCase();
      if (sp.length > 0) {
        if (sp.startsWith('E') || sp.startsWith('C')) {
          const splitNum = sp.split(sp[0]);
          if (splitNum.length == 2) {
            const regex = /[0-9]/;
            if (regex.test(splitNum[1])) {
              if (splitNum[1].length == 8) {
                return null;
              } else {
                return { validFormat: 'notValid' };
              }
            } else {
              return { validFormat: 'notValid' };
            }
          } else {
            return { validFormat: 'notValid' };
          }
        } else {
          return { validFormat: 'notValid' };
        }
      } else {
        return { validFormat: 'notValid' };
      }
    } else return null;
  };
  // Validadores Asincronos

  static dniCDR =
    (fcNacionality: AbstractControl, userServiceMSO: UserServiceMSO): AsyncValidatorFn =>
    (control: AbstractControl): Observable<any> => {
      const validChars = 'TRWAGMYFPDXBNJZSQVHLCKET';
      const nifRexp = /^((^[A-Z]{1}[0-9]{7}[A-Z0-9]{1}$|^[T]{1}[A-Z0-9]{8}$)|^[0-9]{8}[A-Z]{1}$)/i;
      const nieRexp = /^((^[A-Z]{1}[0-9]{7}[A-Z0-9]{1}$|^[T]{1}[A-Z0-9]{8}$)|^[0-9]{8}[A-Z]{1}$)/i;

      let str: string = control.value;
      if (control.value) {
        str = str.toUpperCase();
        if (!nifRexp.test(str) && !nieRexp.test(str)) {
          return of({ validFormat: 'notValid' });
        } else {
          let nie = str;
          const letter = str.substr(-1).toUpperCase();
          //Si la nacionalidad es distinta de español cambiamos la primera letra por un número.
          if (fcNacionality.value.id != 71 && !parseInt(str[0])) {
            nie = str.toUpperCase().replace(/^[X]/, '0').replace(/^[Y]/, '1').replace(/^[Z]/, '2');
            const charIndex = parseInt(nie.substr(0, 8)) % 23;
            if (validChars.charAt(charIndex) === letter) {
              return userServiceMSO.checkDataAvailability('NIF', control.value.toUpperCase());
            } else {
              return of({ validFormat: 'notValid' });
            }
          } else if (fcNacionality.value.id == 71 && (!!parseInt(str[0]) || str[0] == '0')) {
            const charIndex = parseInt(nie.substr(0, 8)) % 23;
            if (validChars.charAt(charIndex) === letter) {
              return userServiceMSO.checkDataAvailability('NIF', control.value.toUpperCase());
            } else {
              return of({ validFormat: 'notValid' });
            }
          } else {
            return of({ validFormat: 'notValid' });
          }
        }
      } else {
        //no hay dni
        return of({ required: 'notValid' });
      }
    };

  static otherDocuments =
    (userServiceMSO: UserServiceMSO): AsyncValidatorFn =>
    (control: AbstractControl): Observable<any> => {
      const str: string = control.value;
      if (control.value) {
        return userServiceMSO.checkDataAvailability('OTHERDOC', control.value.toUpperCase());
      } else {
        //no hay document
        return of({ required: 'notValid' });
      }
    };
  static mobileCDR =
    (fcResident: AbstractControl, userServiceMSO: UserServiceMSO): AsyncValidatorFn =>
    (control: AbstractControl): Observable<any> => {
      const regExpPhone = /^[6|7][0-9]{8}$/;
      //eslint-disable-next-line
      const regExpPhoneNR: RegExp = /^[0-9\+\-]*$/;

      if (fcResident.value.id == 0) {
        // residente en España
        if (regExpPhone.test(control.value)) {
          return userServiceMSO.checkDataAvailability('TELEFONO', control.value);
        } else {
          return of({ validFormat: 'notValid' });
        }
      } else {
        //no se comprueba el formato pero sí que sea único
        if (regExpPhoneNR.test(control.value)) {
          return userServiceMSO.checkDataAvailability('TELEFONO', control.value);
        } else {
          return of({ validFormat: 'notValid' });
        }
      }
    };

  static emailCDR =
    (userServiceMSO: UserServiceMSO): AsyncValidatorFn =>
    (control: AbstractControl): Observable<any> => {
      //eslint-disable-next-line
      const regExpEmail: RegExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,63})+$/;
      if (regExpEmail.test(control.value)) {
        return userServiceMSO.checkDataAvailability('CORREO', control.value);
      } else {
        return of({ validFormat: 'notValid' });
      }
    };

  static userCDR =
    (userServiceMSO: UserServiceMSO): AsyncValidatorFn =>
    (control: AbstractControl): Observable<any> =>
      userServiceMSO.checkDataAvailability('USUARIO', control.value);

  static vfSubscription: Subscription;
  static promoCDR =
    (userServiceMSO: UserServiceMSO): AsyncValidatorFn =>
    (control: AbstractControl): Observable<any> => {
      if (control.value) {
        const q = new Promise((resolve, reject) => {
          if (RegistroValidators.vfSubscription) {
            RegistroValidators.vfSubscription.unsubscribe();
          }
          RegistroValidators.vfSubscription = userServiceMSO.verifyCode(control.value).subscribe({
            next: (data) => {
              if (data) {
                if (data.status) {
                  if (data.status.toLowerCase() != 'ko') {
                    resolve(null);
                  } else {
                    resolve({ validFormat: data.errCode });
                  }
                } else {
                  resolve(null);
                }
              } else {
                resolve(null);
              }
            },
            error: (err) => {
              reject(null);
            }
          });
        });
        return from(q);
      } else {
        const q = new Promise((resolve, reject) => {
          resolve(null);
        });
        return from(q);
      }
    };
  static noWhitespaceValidator(control: AbstractControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  static spaceSuffixNames() {
    return (control: AbstractControl) => {
      if (control) {
        const value = control.value;
        if (value) {
          const valueTrim = value.trim();
          if (value !== valueTrim) {
            control.setValue(valueTrim, { emitEvent: false });
            control.updateValueAndValidity({ onlySelf: true, emitEvent: false });
          }
        }
      }
      return null;
    };
  }
}
