export { BetbuilderComponent } from './sb-betbuilder/sb-betbuilder';
export { BetslipOveraskComponent } from './betslip/betslip-overask/betslip-overask.component';
export { BetslipTicketConfirmedComponent } from './betslip/betslip-ticket-confirmed/betslip-ticket-confirmed.component';
export { SBBetslipStakeComponent } from './betslip/betslip-stake-input/betslip-stake-input.component';
export { SbDropdownMenuComponent } from './sb-dropdown-menu/sb-dropdown-menu.component';
export { SbIconBarComponent } from './sb-icon-bar/sb-icon-bar.component';
export { SbMarqueeBetbuilderComponent } from './sb-marquee-betbuilder/sb-marquee-betbuilder';
export { SbMarqueeBetSenseComponent } from './sb-marquee-betsense/sb-marquee-betsense';
export { SbMarqueeComponent } from './sb-marquee/sb-marquee';
export { SbStreamMarketComponent } from './sb-stream-market/sb-stream-market';
export { SbTabsCategoriesComponent } from './sb-tabs-categories/sb-tabs-categories.component';
export { SbTabsComponent } from './sb-tabs/sb-tabs.component';
export { SportsKeyboardComponent } from './sports-keyboard/sports-keyboard.component';
export { SportsPageHeaderComponent } from './sports-page-header/sports-page-header.component';
export { TicketBetbuilderComponent } from './betslip/ticket-betbuilder/ticket-betbuilder';

import { BetbuilderComponent } from './sb-betbuilder/sb-betbuilder';
import { BetslipOveraskComponent } from './betslip/betslip-overask/betslip-overask.component';
import { BetslipTicketConfirmedComponent } from './betslip/betslip-ticket-confirmed/betslip-ticket-confirmed.component';
import { SBBetslipStakeComponent } from './betslip/betslip-stake-input/betslip-stake-input.component';
import { SbDropdownMenuComponent } from './sb-dropdown-menu/sb-dropdown-menu.component';
import { SbIconBarComponent } from './sb-icon-bar/sb-icon-bar.component';
import { SbMarqueeBetbuilderComponent } from './sb-marquee-betbuilder/sb-marquee-betbuilder';
import { SbMarqueeBetSenseComponent } from './sb-marquee-betsense/sb-marquee-betsense';
import { SbMarqueeComponent } from './sb-marquee/sb-marquee';
import { SbStreamMarketComponent } from './sb-stream-market/sb-stream-market';
import { SbTabsCategoriesComponent } from './sb-tabs-categories/sb-tabs-categories.component';
import { SbTabsComponent } from './sb-tabs/sb-tabs.component';
import { SportsKeyboardComponent } from './sports-keyboard/sports-keyboard.component';
import { SportsPageHeaderComponent } from './sports-page-header/sports-page-header.component';
import { TicketBetbuilderComponent } from './betslip/ticket-betbuilder/ticket-betbuilder';

export const Components: any[] = [
  BetbuilderComponent,
  BetslipOveraskComponent,
  BetslipTicketConfirmedComponent,
  SBBetslipStakeComponent,
  SbDropdownMenuComponent,
  SbIconBarComponent,
  SbMarqueeBetbuilderComponent,
  SbMarqueeBetSenseComponent,
  SbMarqueeComponent,
  SbStreamMarketComponent,
  SbTabsCategoriesComponent,
  SbTabsComponent,
  SportsKeyboardComponent,
  SportsPageHeaderComponent,
  TicketBetbuilderComponent
];
