<!-- eslint-disable @angular-eslint/template/eqeqeq -->

<ng-container *ngIf="isDesktop; then desktopTemplate; else mobileTemplate"></ng-container>

<ng-template #desktopTemplate>
  <!-- eslint-disable @angular-eslint/template/eqeqeq -->
  <ion-content class="has-titleBar background-color-muted contentselfautoexclusionar" *ngIf="utils.isArgentina()">
    <!-- TODO user-section-title pc & mobile ⬇️ -->
    <user-section-title [sectionTitle]="'selfExclusiontitle'"></user-section-title>
    <!-- Auto-Exclusion en ArgentinaCaba y PBA -->
    <ng-container
      *ngIf="!this.globalVars.FEATURES.msoNewSelfExclusionAR; then oldSelfExclusionAR else newSelfExclusionAR"
    ></ng-container>
    <ng-template #oldSelfExclusionAR>
      <ion-list class="subDep oldSelfExclusionAR">
        <div class="masinfo text-center marginb23">
          <span
            >{{'selfexclusionsArtx'|translate}}
            <!--Ahora podés iniciar tu trámite de solicitud de autoexclusión-->
          </span>
        </div>

        <div class="sendForm">
          <ion-button expand="full" (click)="gotoExclusionClick()">{{'tHelpSend'|translate}}</ion-button>
        </div>
      </ion-list>
    </ng-template>
    <ng-template #newSelfExclusionAR>
      <div class="conten">
        <ng-container *ngIf="stepARG === 0">
          <div class="contendiv">
            <div class="titlecontact">{{ 'selfexclusion1titlear01' | translate }}</div>
            <div class="titletxt">{{ 'selfexclusion1txtar01' | translate }}</div>
            <div class="titletxt" [innerHTML]="'selfexclusion1txtar02'| translate"></div>
          </div>
          <div class="divcards">
            <ion-card *ngFor="let item of cards">
              <ng-container *ngIf="!isImgCustom(item.img), else nocustom">
                <ion-icon [name]="item.img"></ion-icon>
              </ng-container>
              <ng-template #nocustom>
                <img [src]="item.img" />
              </ng-template>
              <ion-card-header>
                <ion-card-subtitle>{{item.title}}</ion-card-subtitle>
              </ion-card-header>
              <div class="paddinglr16">
                <div [innerHTML]="item.txt" class="font1_25"></div>
                <div [innerHTML]="item.txt2" class="font1_25 margint10"></div>
              </div>
              <ion-card-content>
                <button (click)="onclickCard(item)">{{item.btnmoreinf}}</button>
              </ion-card-content>
            </ion-card>
          </div>
        </ng-container>
        <ng-container *ngIf="stepARG === 1">
          <div class="contendiv divtxt">
            <div *ngIf="globalVars.licenseType === 33">
              <div class="titlecontact txtleft">{{ 'uPJRAut' | translate }}</div>
              <div class="titletxt">{{ 'selfexclusiontartxt01' | translate }}</div>
              <div class="titletxt">{{ 'selfexclusiontartxt02' | translate }}</div>
              <div class="titletxt bold">{{ 'selfexclusiontartxt03' | translate }}</div>
              <div class="titletxt">{{ 'selfexclusiontartxt04' | translate }}</div>
              <div class="titlecontact txtleft margint25">{{ 'selfexclusionfamily' | translate }}</div>
              <div class="titletxt" [innerHTML]="'selfexclusionfamily01'| translate"></div>
              <div class="titletxt" [innerHTML]="'selfexclusionfamily02'| translate"></div>
              <div class="titletxt" [innerHTML]="'selfexclusionfamily03'| translate"></div>
              <div class="titletxt bold" [innerHTML]="'selfexclusionfamily04'| translate"></div>
            </div>
            <div *ngIf="globalVars.licenseType === 36">
              <div class="titlecontact txtleft">{{ 'uPJRAut' | translate }}</div>
              <div class="titletxt marginb15">{{ 'selfexclusiontmzatxt01' | translate }}</div>
              <div class="titletxt marginb15">{{ 'selfexclusiontmzatxt02' | translate }}</div>
              <div class="titletxt marginb15">{{ 'selfexclusiontmzatxt03' | translate }}</div>
              <div class="titletxt marginb15">
                <a
                  external-link
                  href="https://ticketsform.mendoza.gov.ar/ticketsform/servlet/responderformulario?JUEGO_RESPONSABLE"
                  target="_blank"
                >
                  {{ 'selfexclusiontmzatxt0301' | translate }}</a
                >
              </div>
              <div class="titletxt marginb15">{{ 'selfexclusiontmzatxt04' | translate }}</div>
              <div class="titletxt">{{ 'selfexclusiontmzatxt05' | translate }}</div>
            </div>
          </div>
          <div class="btnselfexclusion">
            <ion-button class="btnwhite" (click)="changeStepARG(0)" *ngIf="globalVars.licenseType !== 36"
              >{{ 'btnbackoptions' | translate }}</ion-button
            >
            <ion-button class="btnGreen" (click)="gotoExclusionClick()"
              >{{ (globalVars.licenseType === 36 ? 'requestpreexclusion' : 'btnselfexclusiontwoyears') | translate
              }}</ion-button
            >
          </div>
        </ng-container>
      </div>
    </ng-template>
    <codere-footer></codere-footer>
  </ion-content>

  <ng-container *ngIf="!isRevocationPage; then autoexclusion; else revocation"> </ng-container>
  <ng-template #autoexclusion>
    <ion-content class="has-titleBar" *ngIf="globalVars.licenseType !== 33">
      <user-section-title [sectionTitle]="'selfExclusiontitle'"></user-section-title>
      <!-- Nuevo formulario para ES y PA-->
      <div *ngIf="newSE">
        <!-- todo -> [isMobile]='false' -->
        <cdr-pages-se [step]="startInStep" (selfExEvent)="onSelfExclusion($event)"> </cdr-pages-se>
      </div>
      <!-- Formulario usado anteriormente -->
      <div *ngIf="!newSE">
        <cdr-full-se (selfExEvent)="onSelfExclusion($event)"> </cdr-full-se>
      </div>
      <codere-footer></codere-footer>
    </ion-content>
  </ng-template>
  <ng-template #revocation>
    <ion-content class="has-titleBar">
      <user-section-title [sectionTitle]="'revocation_se_title'"></user-section-title>
      <cdr-step-revocation-se></cdr-step-revocation-se>
    </ion-content>
  </ng-template>
</ng-template>

<ng-template #mobileTemplate>
  <ion-header>
    <!-- <codere-navbar titulo="Preferencias"></codere-navbar> -->
    <user-section-title
      [icon]="icon"
      [sectionTitle]="'selfExclusiontitle'"
      *ngIf="!isRevocationPage"
    ></user-section-title>
    <user-section-title
      [sectionTitle]="'revocation_se_title'"
      *ngIf="isRevocationPage"
      class="titlecenter"
    ></user-section-title>
  </ion-header>
  <ion-content
    *ngIf="utils.isArgentina()"
    [ngClass]="{'contentselfautoexclusionar': this.globalVars.FEATURES.msoNewSelfExclusionAR}"
  >
    <ng-container
      *ngIf="!this.globalVars.FEATURES.msoNewSelfExclusionAR; then oldSelfExclusionAR else newSelfExclusionAR"
    ></ng-container>
    <ng-template #oldSelfExclusionAR>
      <div class="myWrapInput oldSelfExclusionAR" [class.has-error]="!validDate">
        <!-- Auto-Exclusion en Argentina -->
        <ion-list class="payment-sublist">
          <div class="masinfo text-center marginb10">
            <span
              >{{'selfexclusionsArtx'|translate}}<!--Ahora podés iniciar tu trámite de solicitud de autoexclusión-->
            </span>
          </div>

          <div style="height: 50px"></div>
          <div class="sendForm">
            <ion-button color="primary" expand="full" (click)="gotoExclusionClick()"
              >{{'tHelpSend'|translate}}</ion-button
            >
          </div>
        </ion-list>
      </div>
      <div>
        <h3 class="listinfoh3">
          {{'informationabout' | translate | uppercase}} {{'uPJRAut' | translate | uppercase}}<!--# INFORMACIÓN SOBRE-->
        </h3>
        <ul class="listInfo">
          <li><b>•</b>{{'selfexclusionstxt01' | translate}}</li>
          <li *ngIf="globalVars.licenseType !== 0 && globalVars.licenseType !== 1">
            <b>•</b>{{'selfexclusionstxt02' | translate}}
          </li>
        </ul>
      </div>
    </ng-template>
    <ng-template #newSelfExclusionAR>
      <div class="conten">
        <ng-container *ngIf="stepARG === 0">
          <div class="contendiv">
            <div class="titlecontact">{{ 'selfexclusion1titlear01' | translate }}</div>
            <div class="titletxt">{{ 'selfexclusion1txtar01' | translate }}</div>
            <div class="titletxt" [innerHTML]="'selfexclusion1txtar02'| translate"></div>
          </div>
          <div class="divcards">
            <ion-card *ngFor="let item of cards">
              <ng-container *ngIf="!isImgCustom(item.img), else nocustom">
                <ion-icon [name]="item.img"></ion-icon>
              </ng-container>
              <ng-template #nocustom>
                <img [src]="item.img" />
              </ng-template>
              <ion-card-header>
                <ion-card-subtitle>{{item.title}}</ion-card-subtitle>
              </ion-card-header>
              <div class="paddinglr16">
                <div [innerHTML]="item.txt" class="font1_25"></div>
                <div [innerHTML]="item.txt2" class="font1_25 margint10"></div>
              </div>
              <ion-card-content>
                <button (click)="onclickCard(item)">{{item.btnmoreinf}}</button>
              </ion-card-content>
            </ion-card>
          </div>
        </ng-container>
        <ng-container *ngIf="stepARG === 1">
          <div class="contendiv divtxt">
            <div *ngIf="globalVars.licenseType === 33">
              <div class="titlecontact txtleft">{{ 'uPJRAut' | translate }}</div>
              <div class="titletxt">{{ 'selfexclusiontartxt01' | translate }}</div>
              <div class="titletxt">{{ 'selfexclusiontartxt02' | translate }}</div>
              <div class="titletxt bold">{{ 'selfexclusiontartxt03' | translate }}</div>
              <div class="titletxt">{{ 'selfexclusiontartxt04' | translate }}</div>
              <div class="titlecontact txtleft margint25">{{ 'selfexclusionfamily' | translate }}</div>
              <div class="titletxt" [innerHTML]="'selfexclusionfamily01'| translate"></div>
              <div class="titletxt" [innerHTML]="'selfexclusionfamily02'| translate"></div>
              <div class="titletxt" [innerHTML]="'selfexclusionfamily03'| translate"></div>
              <div class="titletxt bold" [innerHTML]="'selfexclusionfamily04'| translate"></div>
            </div>
            <div *ngIf="globalVars.licenseType === 36">
              <div class="titlecontact txtleft">{{ 'uPJRAut' | translate }}</div>
              <div class="titletxt marginb15">{{ 'selfexclusiontmzatxt01' | translate }}</div>
              <div class="titletxt marginb15">{{ 'selfexclusiontmzatxt02' | translate }}</div>
              <div class="titletxt marginb15">{{ 'selfexclusiontmzatxt03' | translate }}</div>
              <div class="titletxt marginb15">
                <a
                  external-link
                  href="https://ticketsform.mendoza.gov.ar/ticketsform/servlet/responderformulario?JUEGO_RESPONSABLE"
                  target="_blank"
                >
                  {{ 'selfexclusiontmzatxt0301' | translate }}</a
                >
              </div>
              <div class="titletxt marginb15">{{ 'selfexclusiontmzatxt04' | translate }}</div>
              <div class="titletxt">{{ 'selfexclusiontmzatxt05' | translate }}</div>
            </div>
          </div>
          <div class="btnselfexclusion">
            <button class="btnwhite" (click)="changeStepARG(0)" *ngIf="globalVars.licenseType !== 36">
              {{ 'btnbackoptions' | translate }}
            </button>
            <button class="btnGreen" (click)="gotoExclusionClick()">
              {{ (globalVars.licenseType === 36 ? 'requestpreexclusion' : 'btnselfexclusiontwoyears') | translate }}
            </button>
          </div>
        </ng-container>
      </div>
    </ng-template>

    <codere-footer></codere-footer>
  </ion-content>

  <ng-container *ngIf="!isRevocationPage; then autoexclusion; else revocation"> </ng-container>

  <ng-template #autoexclusion>
    <ion-content *ngIf="globalVars.licenseType !== 33 && globalVars.licenseType !== 34">
      <div *ngIf="newSE">
        <cdr-pages-se [step]="startInStep" (selfExEvent)="onSelfExclusion($event)"> </cdr-pages-se>
      </div>
      <!-- Formulario usado anteriormente -->
      <div *ngIf="!newSE">
        <cdr-full-se (selfExEvent)="onSelfExclusion($event)"> </cdr-full-se>
      </div>
      <codere-footer></codere-footer>
    </ion-content>
  </ng-template>

  <ng-template #revocation>
    <ion-content class="has-titleBar">
      <cdr-step-revocation-se></cdr-step-revocation-se>
    </ion-content>
  </ng-template>
</ng-template>
