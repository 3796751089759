/* eslint-disable */
import { TpagaDepositActionResult } from '@models/C_Transactions';
import {
  IQRPaymentMethodBehaviour,
  PaymentMethodBehaviour,
  QRPaymentMethodBehaviourResult,
  SkipAmountResult
} from './PaymentMethodBase';
import { ErrorService } from '@providers/ErrorService';
import { UserService } from '@providers/UserService';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class TpagaMethodBehaviourResult extends QRPaymentMethodBehaviourResult {
  transactionId: string;
  amount: number;
  token: string;
  url: string;
  override provider: 'Tpaga'; //important to distinct between qrpayments
}

export class TpagaDepositBehaviour extends PaymentMethodBehaviour implements IQRPaymentMethodBehaviour {
  name = 'TPAGA-DEPOSIT';
  image = 'logo_Tpaga';
  track = 'AccessToTpaga';
  isBank = false;
  minDepositAmount = 10;
  maxDepositAmount = 100000;
  amountButtons: number[] = [10, 20, 40, 50];
  override amount = 20;

  constructor(userService: UserService, errorService: ErrorService) {
    super(false, userService, errorService);
  }

  skipAmountInput(): Observable<SkipAmountResult> {
    return new Observable<SkipAmountResult>();
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  qr() {} //satisfy Interface

  paymentMetodExecution(): Observable<QRPaymentMethodBehaviourResult> {
    return this.userService.validateDepositTpaga('', this.amount).pipe(
      map((obj) => {
        return this.map(obj);
      })
    );
  }

  private map(input: TpagaDepositActionResult): QRPaymentMethodBehaviourResult {
    const result = new TpagaMethodBehaviourResult();
    if (input.success) {
      result.success = true;
      result.transactionId = input.transactionId;
      result.amount = input.amount;
      result.token = input.token;
      result.url = input.url;
      result.payload = input.url;
      result.provider = 'Tpaga';
    } else {
      result.success = false;
      result.erroMessage = this.errorService.searchErrorCodeWithParams('error' + input.errCode, input.errorParameters);
      result.errorcode = input.errCode;
      result.sendToDocumentVerificationPage = input.errCode == 174 || input.errCode == 2212;
    }
    return result;
  }
}
