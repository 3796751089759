export class C_CodereCard {
  active: boolean;
  cardNumber: string;
  balance: number;
  date: string;
  state: string;
  region: string;

  constructor(cardNumber?: string, balance?: number, date?: string, state?: string, region?: string) {
    this.active = false;
    this.cardNumber = cardNumber;
    this.balance = balance;
    this.date = date;
    this.state = state;
    this.region = region;
  }
}
