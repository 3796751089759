<!-- Generated template for the ModalUpdateComponent component -->
<div class="main-chcp">
  <div class="content">
    <div class="logo">
      <svg viewBox="0 0 113.4 31.4">
        <g>
          <path
            class="shadow"
            d="M15.9,21.6c-0.9,1.4-2.1,2.2-3.5,2.2c-2.7,0-4.7-2.1-4.7-5c0-2.5,1.6-5,4.6-5c2.3,0,3.2,1.5,3.6,2.3l0.1,0.1h5
      l-0.1-0.3c-1.2-3.7-4.7-6.3-8.6-6.3c-2.4,0-4.6,0.9-6.3,2.6c-1.8,1.7-2.8,4.1-2.8,6.5c0,5.1,4.1,9.2,9.1,9.2c4,0,7.2-2.3,8.6-6.1
      l0.1-0.4h-5L15.9,21.6z M31.4,9.7c-5,0-9.2,4.1-9.2,9.1s4.1,9.2,9.1,9.2c5.1,0,9.2-4.1,9.2-9.1c0,0,0,0,0,0
      C40.5,13.8,36.5,9.7,31.4,9.7C31.5,9.7,31.4,9.7,31.4,9.7z M31.5,23.6c-2.6-0.1-4.6-2.2-4.5-4.8c0.1-2.5,2-4.4,4.5-4.5l0,0
      c2.6-0.1,4.7,1.9,4.8,4.5c0.1,2.6-1.9,4.7-4.5,4.8C31.6,23.6,31.5,23.6,31.5,23.6z M55.7,11.5c-1.4-1.2-3.1-1.8-4.9-1.9
      c-5,0-8.8,4.1-8.8,9.1c0,5,4.1,9.1,9.1,9.1s9.1-4.1,9.1-9.1V2.6h-4.6V11.5L55.7,11.5z M51.1,23.6c-2.5,0-4.6-2.1-4.6-4.6
      c0-2.5,2.1-4.6,4.6-4.6c2.5,0,4.6,2.1,4.6,4.6C55.7,21.6,53.7,23.6,51.1,23.6L51.1,23.6z M74,23.4c-2.2,1-4.9,0.3-6.4-1.6l12.8-5.4
      l-0.5-1.2c-2-4.7-6.8-7-11.8-4.8c-5,2.1-7.2,7.3-5.2,12s7.6,6.6,12.6,4.5c2.2-0.9,4.1-2.6,5.3-4.8L77,20.2
      C76.5,21.6,75.4,22.8,74,23.4z M69.6,13.9c1.7-0.7,3.7-0.4,5,0.9l-8.1,3.4C66.6,16.3,67.8,14.6,69.6,13.9z M106.5,20.2
      c-0.5,1.4-1.6,2.6-3,3.1c-2.2,1-4.9,0.3-6.4-1.5l12.8-5.4l-0.5-1.2c-2-4.7-6.8-6.9-11.8-4.8c-5,2.1-7.2,7.3-5.2,12
      c2,4.7,7.6,6.6,12.6,4.5c2.2-0.9,4.1-2.6,5.2-4.8L106.5,20.2z M99,13.9c1.7-0.7,3.7-0.4,5,0.9l-8,3.4C96,16.3,97.2,14.6,99,13.9z
       M82.7,17.7v9.8h4.5v-9.2c0-2.8,1.6-4.1,3.8-4.1v-4.1C85.8,10.1,82.7,13.5,82.7,17.7z"
            fill="#334048"
          />
        </g>
      </svg>
    </div>

    <div class="location">
      <svg viewBox="0 0 92 69.9" fill="#fff">
        <g>
          <g>
            <path
              class="rotate-right"
              d="M34.4,27L34.4,27L34.4,27z M30.7,15.8h0.2H31c3.5,0,6.8,1.3,9.7,4c2.9,2.7,4.3,6.3,4.2,10.6
          c0,0.9-0.1,1.7-0.3,2.5c-0.5,1.9-1,3-1.2,3.4c-0.8,1.4-1.7,2.6-2.9,3.8c-0.3,0.3-1.3,1.1-3,2.1c-0.7,0.4-1.4,0.7-2.1,0.9
          c-1.4,0.5-2.9,0.7-4.5,0.7l0,0h-0.1c-1.6,0-3.3-0.3-5-1c-1.9-0.7-3.5-1.9-5-3.3c-2.5-2.5-4.1-5.9-3.9-10.3
          c0.1-2.1,0.5-3.3,0.7-3.7c0.3-0.7,0.5-1.5,0.9-2.2c0.7-1.4,1.7-2.6,2.9-3.8c0.3-0.3,1.3-1.1,3-2.1c0.7-0.4,1.4-0.7,2.1-0.9
          C27.7,16,29.2,15.8,30.7,15.8L30.7,15.8z M31,3.9L31,3.9L31,3.9c-0.1,0-0.2,0-0.4,0h-0.3c-0.2,0-0.3,0-0.5,0l0,0
          c-1.6,0-2.8,0.2-3.6,0.5v4.3c-2.1,0.4-4.5,1.4-7.2,3l-2.9-2.8c0-0.1-0.1-0.2-0.2-0.2h-0.1c-1,0.6-2.2,1.6-3.5,3
          c-1.6,1.6-2.4,2.7-2.5,3.2l3,3c-1.3,1.8-2.3,4.2-3.1,7.4H5.5c-0.2,0-0.3,0.1-0.4,0.2c-0.2,1-0.4,2.4-0.4,4.1
          c0,2.2,0.1,3.8,0.5,4.5l4.4,0.1c0.4,2.1,1.3,4.5,3,7.2l-2.9,2.9c-0.1,0.1-0.1,0.2-0.1,0.4c0.6,0.9,1.5,2,2.9,3.4
          c1.6,1.6,2.8,2.5,3.4,2.7l3-3c1.7,1.2,4,2.2,7.2,3.1v3.8c0,0.1,0.1,0.2,0.2,0.3c1.1,0.3,2.4,0.4,4,0.4c0.1,0,0.3,0,0.5,0l0,0
          c0.1,0,0.2,0,0.2,0c1.7,0,3-0.2,3.9-0.5c0.1-0.1,0.1-0.2,0.1-0.3v-3.7c3.3-0.8,5.8-1.8,7.6-3.1l2.6,2.5c0.1,0.1,0.2,0.1,0.3,0.1
          h0.1c1.2-0.8,2.5-1.9,3.8-3.3c1.4-1.5,2.1-2.5,2.2-2.9L49,41.5c1.7-2.8,2.7-5.2,3.1-7.4h3.5l0,0c0.1,0,0.2-0.1,0.3-0.2
          c0.3-0.7,0.4-2.4,0.4-5c0-2.1-0.2-3.3-0.5-3.6L52,25.2c-0.8-3.3-1.9-5.8-3.1-7.5l2.6-2.6c0.1-0.1,0.1-0.2,0.1-0.4
          c-0.8-1.2-1.9-2.5-3.3-3.8c-1.5-1.4-2.5-2.2-2.9-2.2l0,0c-0.1,0-0.2,0.1-0.2,0.1l-2.6,2.6c-1.7-1.2-4.1-2.2-7.3-3V4.5
          c0-0.1-0.1-0.2-0.2-0.3C33.6,4.1,32.3,3.9,31,3.9L31,3.9z"
            />
          </g>
          <g>
            <path
              class="rotate-left"
              d="M68.8,16.3L68.8,16.3L68.8,16.3z M66.1,12L66.1,12C66.2,12,66.2,12,66.1,12c1.6-0.4,3.1-0.1,4.5,0.7
          c1.5,0.8,2.4,2.2,2.8,4c0.1,0.4,0.1,0.7,0.1,1.1c0,0.9-0.1,1.4-0.2,1.5c-0.2,0.6-0.4,1.3-0.8,1.9c-0.1,0.2-0.4,0.6-1,1.2
          c-0.2,0.2-0.5,0.4-0.8,0.6c-0.5,0.3-1.1,0.6-1.8,0.8l0,0l0,0c-0.7,0.2-1.4,0.2-2.2,0.1c-0.9-0.1-1.7-0.4-2.4-0.9
          c-1.3-0.8-2.3-2.1-2.7-3.9c-0.2-0.9-0.1-1.4-0.1-1.6c0-0.3,0.1-0.7,0.2-1c0.2-0.7,0.4-1.3,0.8-1.9c0.1-0.2,0.4-0.6,1-1.2
          c0.2-0.2,0.5-0.4,0.8-0.6C64.9,12.4,65.5,12.1,66.1,12L66.1,12z M65.1,7L65.1,7L65.1,7C65,7,65,7,65.1,7h-0.2c-0.1,0-0.1,0-0.2,0
          l0,0c-0.7,0.2-1.2,0.3-1.4,0.6l0.2,1.9c-0.8,0.4-1.7,1-2.7,2l-1.5-0.9h-0.1l0,0c-0.4,0.4-0.7,0.9-1.2,1.6
          c-0.5,0.8-0.7,1.3-0.7,1.6l1.5,1c-0.3,0.8-0.5,1.9-0.5,3.3l-1.7,0.4c-0.1,0-0.1,0.1-0.1,0.1c0,0.5,0.1,1,0.3,1.7
          c0.2,0.9,0.4,1.5,0.7,1.8l1.8-0.4c0.4,0.8,1,1.7,2,2.7l-0.9,1.5c0,0,0,0.1,0,0.2c0.3,0.3,0.8,0.7,1.5,1.1c0.8,0.5,1.4,0.8,1.7,0.8
          l1-1.5c0.8,0.3,1.9,0.5,3.3,0.6l0.4,1.6l0.1,0.1c0.5,0,1-0.1,1.7-0.2c0.1,0,0.1,0,0.2,0l0,0h0.1c0.7-0.2,1.2-0.4,1.6-0.6
          c0-0.1,0-0.1,0-0.1l-0.4-1.5c1.3-0.7,2.3-1.3,2.8-2l1.3,0.8h0.1l0,0c0.4-0.5,0.9-1,1.3-1.8s0.6-1.2,0.6-1.4l-1.4-0.9
          c0.4-1.3,0.6-2.5,0.5-3.4l1.5-0.4l0,0l0.1-0.1c0-0.3-0.1-1-0.3-2.1c-0.2-0.9-0.4-1.3-0.6-1.4L75.9,14c-0.7-1.3-1.4-2.2-2.1-2.8
          l0.9-1.5c0,0,0-0.1,0-0.2c-0.5-0.4-1-0.9-1.8-1.3c-0.8-0.4-1.2-0.7-1.4-0.6l0,0c0,0-0.1,0-0.1,0.1l-0.8,1.4
          c-0.8-0.3-1.9-0.5-3.4-0.5L66.8,7l-0.1-0.1C66.1,6.8,65.6,6.9,65.1,7L65.1,7z"
            />
          </g>
          <g>
            <path
              class="rotate-left"
              d="M72,46.4L72,46.4L72,46.4z M67.8,39.6L67.8,39.6c0.1,0,0.1,0,0.2,0c2.3-0.6,4.7-0.2,7,1.1s3.8,3.4,4.4,6.3
          c0.1,0.6,0.2,1.1,0.2,1.7c0,1.4-0.2,2.1-0.3,2.4c-0.3,1-0.7,2-1.3,3c-0.2,0.3-0.7,0.9-1.6,1.8c-0.4,0.4-0.8,0.7-1.2,0.9
          c-0.8,0.5-1.8,0.9-2.9,1.2l0,0c0,0,0,0-0.1,0c-1.1,0.3-2.2,0.3-3.4,0.2c-1.4-0.2-2.6-0.7-3.8-1.4c-2.1-1.2-3.6-3.2-4.2-6.2
          c-0.3-1.4-0.2-2.2-0.1-2.5c0.1-0.5,0.1-1.1,0.2-1.6c0.3-1,0.7-2,1.3-3c0.2-0.3,0.7-0.9,1.6-1.8c0.4-0.4,0.8-0.7,1.2-0.9
          C65.9,40.2,66.8,39.9,67.8,39.6L67.8,39.6z M66.1,31.8L66.1,31.8L66.1,31.8c-0.1,0-0.2,0-0.2,0.1L65.7,32c-0.1,0-0.2,0-0.3,0.1
          l0,0c-1.1,0.3-1.8,0.5-2.3,0.9l0.6,2.8c-1.3,0.6-2.7,1.6-4.2,3.1l-2.3-1.4c-0.1,0-0.1,0-0.2,0c0,0,0,0-0.1,0
          c-0.6,0.6-1.2,1.4-1.9,2.5c-0.8,1.3-1.2,2.1-1.1,2.5l2.4,1.5c-0.5,1.3-0.8,3.1-0.8,5.3L52.8,50c-0.1,0-0.2,0.1-0.2,0.2
          c0,0.7,0.1,1.6,0.4,2.7c0.3,1.5,0.7,2.4,1.1,2.9l2.9-0.7c0.6,1.3,1.6,2.7,3.1,4.3l-1.5,2.3c0,0.1,0,0.2,0,0.3
          c0.4,0.5,1.2,1,2.3,1.7c1.3,0.8,2.2,1.2,2.7,1.2l1.5-2.4c1.3,0.5,3,0.8,5.2,0.9l0.6,2.5c0,0.1,0.1,0.1,0.2,0.2
          c0.8,0,1.6-0.1,2.7-0.4c0.1,0,0.2,0,0.3-0.1l0,0c0.1,0,0.1,0,0.2,0c1.1-0.3,1.9-0.6,2.5-0.9c0-0.1,0.1-0.1,0-0.2l-0.6-2.4
          c2.1-1,3.5-2.1,4.5-3.2l2.1,1.3c0.1,0,0.1,0,0.2,0c0,0,0,0,0.1,0c0.7-0.7,1.3-1.6,2-2.8s1-2,1-2.2l-2.3-1.5c0.7-2.1,1-3.9,0.8-5.3
          l2.3-0.6l0,0c0.1,0,0.1-0.1,0.2-0.2c0.1-0.5-0.1-1.6-0.5-3.4c-0.3-1.4-0.7-2.1-0.9-2.3l-2.5,0.5c-1.1-2-2.2-3.5-3.2-4.4l1.3-2.1
          c0-0.1,0-0.2,0-0.3c-0.7-0.7-1.6-1.4-2.8-2c-1.2-0.7-2-1-2.2-1l0,0c-0.1,0-0.1,0.1-0.1,0.1l-1.3,2.1c-1.3-0.5-3.1-0.8-5.3-0.8
          L69,31.4c0-0.1-0.1-0.1-0.2-0.2C67.8,31.5,67,31.6,66.1,31.8L66.1,31.8z"
            />
          </g>
        </g>
      </svg>
    </div>

    <!-- Mantener esta linea por si deciden volver a usarlo -->
    <!-- <div class="load">
          <span class="loader"></span>
      </div> -->

    <p class="message" [innerHTML]="message"></p>

    <div class="button-content">
      <ion-button class="shadow background-color-primary" (click)="cancelUpdate()">
        Continuar sin actualizar
      </ion-button>
    </div>
  </div>
</div>
