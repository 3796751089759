//eslint-disable-next-line
export enum PostMessageActions {
  Navigate = 'navigate',
  OpenDeposits = 'openDeposits',
  ExecuteJS = 'executeJS',
  GetSessionData = 'GetSessionData',
  GetSessionDataResponse = 'GetSessionDataResponse'
}
export interface PostMesageData {
  action: PostMessageActions;
  data: { [key: string]: any };
}
