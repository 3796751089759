import { Injectable, inject } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { GlobalVarsModel } from '../models/ModelGlobalsVar';
import { NewBaseService } from './newBase.service';
import { LicenseTypes } from '@models/MasterData';

/**
 * Service to get urls based on features, parameters, ...
 */
@Injectable({ providedIn: 'root' })
export class UrlsService {
  playerPropsUrl: string = '';
  public globalVars!: GlobalVarsModel;
  private newBaseService = inject(NewBaseService);
  constructor(public sanitizer: DomSanitizer) {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
    if (this.globalVars.FEATURES.playerPropsEventUrl) {
      this.playerPropsUrl = this.globalVars.FEATURES.playerPropsEventUrl + '{0}';
    }
  }

  /**
   * Returns the betsense safe url for an event node identifier
   * @param nodeId The event node identifier
   * @returns The safe url
   */
  public getBetsenseUrl(nodeId: string): SafeResourceUrl {
    let countryPid = 7;
    switch (this.globalVars.licenseType) {
      case LicenseTypes.Colombia:
        countryPid = 17;
        break;
      default:
        countryPid = 7;
    }

    let url: string = `https://coderewidget2.betsense.io/?eventid=${nodeId}&pid=${countryPid}&lang=es&engine=codere&style=web`;
    if (!url) return null;

    url = url.replace('{0}', nodeId);
    url = url.replace('{1}', this.globalVars.Idioma);

    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  public getPlayerPropsWidgetUrl(nodeId: string, widgetId: string): SafeResourceUrl {
    const url = this.playerPropsUrl.replace('{0}', nodeId);
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  public getVeridasIframeUrl(baseUrl: string, token: string): SafeResourceUrl {
    const url = `${baseUrl}?access_token=${token}`;
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
