<div class="sb-stream-market background-color-regular border-color-regular">
  <a *ngIf="!video" (click)="toggleVideo()" class="sb-stream-market--button sb-stream-market--title color-secondary"
    >{{ buttonText }}</a
  >
  <div *ngIf="video" class="sb-stream-market--video">
    <i (click)="toggleVideo()" class="sb-stream-market--close background-color-dark">
      <svg width="10" height="10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10.485 10.485">
        <path
          id="Union_1"
          data-name="Union 1"
          d="M-1512.808-789.394l-3.536-3.536-3.536,3.536a1,1,0,0,1-1.414,0,1,1,0,0,1,0-1.414l3.536-3.536-3.536-3.536a1,1,0,0,1,0-1.414,1,1,0,0,1,1.414,0l3.536,3.536,3.536-3.536a1,1,0,0,1,1.414,0,1,1,0,0,1,0,1.414l-3.536,3.536,3.536,3.536a1,1,0,0,1,0,1.414,1,1,0,0,1-.707.293A1,1,0,0,1-1512.808-789.394Z"
          transform="translate(1521.586 799.586)"
          fill="#fff"
        />
      </svg>
    </i>
    <iframe class="sb-stream-market--iframe" [src]="streamingSource"></iframe>
  </div>
</div>
