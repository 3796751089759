/* eslint-disable no-shadow */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { IonContent, IonicModule, NavController, IonCol } from '@ionic/angular';
import { ViewChild, Component, inject, Renderer2, OnInit, CUSTOM_ELEMENTS_SCHEMA, AfterViewInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
  ValidatorFn,
  AbstractControl,
  ValidationErrors,
  ReactiveFormsModule,
  FormsModule
} from '@angular/forms';
import { Router } from '@angular/router';
import { ExtractDatePipe } from '../../../pipes/extractDate.pipe';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { UserService } from '../../../providers/UserService';
import { LicenseTypes } from '../../../models/MasterData';
import { TrackingService } from '../../../providers/TrackingService';
import { Observable, catchError, map, of } from 'rxjs';
import { Utils } from '../../../utils/Utils';
import { CommonModule } from '@angular/common';
import { CodereFooterComponent } from '../../../components/mso/footer/codere-footer/codere-footer';
import { UserSectionTitle } from '../../../components/mso/user-section-title/user-section-title';
import { EventsService } from '../../../providers/EventsService';
import { GlobalVarsModel } from '../../../models/ModelGlobalsVar';
import { MSO_PATHS } from '../../../lib/constants/routes';
import { NewBaseService } from '../../../providers/newBase.service';
import { ErrorService } from '../../../providers/ErrorService';
import { UserServiceMSO } from '../../../providers/UserServiceMSO';
import { DeviceService } from '../../../services/device.service';
import { RegistroValidators } from '../../../../../mso/src/lib/registro.validators';

@Component({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'personal-info-page',
  templateUrl: './personal-info.html',
  styleUrls: ['./personal-info.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    CodereFooterComponent,
    ExtractDatePipe,
    UserSectionTitle
  ]
})
export class PersonalInfoPage implements OnInit, AfterViewInit {
  @ViewChild(IonContent) content: IonContent;
  @ViewChild('fromDate') fromDate: IonCol;

  showContent = true;
  public ticketTarget: any;
  showother = false;
  myForm: FormGroup;

  register: any;
  originalData: any;
  legalTerms: { url: string; date: string };
  policyTerms: { url: string; date: string };

  yearForm: FormGroup;
  model: any = { year: '' };
  actualYear: number;
  years: Array<number>;
  profesionInfo: Array<string>;
  profesionInfoSelect: Array<{ label: string; value: string }>;
  profesionAlertOptions: any = { header: 'SELECCIONE UNA OPCIÓN', cssClass: 'alertProfesion' };
  pepSelectTitular!: Array<{ label: string; value: string }>;
  estCiviles!: Array<{ id: number; name: string }>;
  nationalities!: Array<string>;
  typesDocument!: Array<{ id: number; name: string; label: string }>;
  UpdateInProgress = false;
  showDisabledMailInfo = false;
  showDisabledMobileInfo = false;
  showDisabledAddressInfo = false;
  listTypeTaxIdentification: Array<string> = ['CUIT', 'CUIL', 'CDI'];
  auxTypeIdentification = '';
  public trueFormControl = new FormControl(false); // **+** Cambiamos a public
  public falseFormControl = new FormControl(false); // **+** Cambiamos a public

  fromExtractAr!: FormGroup;

  toDateMax!: string;
  toDateMin!: string;
  fromDateMax!: string;
  fromDateMin!: string;
  pickerOptions!: any;
  dateRegisterUser!: string;
  globalVars!: GlobalVarsModel;
  isMobile = false;

  extractDatePipe = inject(ExtractDatePipe);
  nav = inject(NavController);
  newBaseService = inject(NewBaseService);
  utils = inject(Utils);
  userService = inject(UserService);
  userServiceMSO = inject(UserServiceMSO);
  translate = inject(TranslateService);
  events = inject(EventsService);
  renderer = inject(Renderer2);
  renderer2 = inject(Renderer2);
  errorService = inject(ErrorService);
  trackingService = inject(TrackingService);
  fb = inject(FormBuilder);
  device = inject(DeviceService);
  route = inject(Router);

  constructor() {
    this.isMobile = this.device.isMobile();
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });

    onresize = () => {
      this.resizeExtractDateOptions();
    };

    this.myForm = this.fb.group({
      user: [{ value: '', disabled: true }, Validators.maxLength(255)],
      email: [{ value: '', disabled: true }, [Validators.required, Validators.maxLength(255)], this.emailValidator],
      address: [
        { value: '', disabled: this.globalVars.licenseType == 33 },
        [Validators.required, Validators.maxLength(255), RegistroValidators.noWhitespaceValidator]
      ],
      zipCode: [{ value: '', disabled: true }, Validators.maxLength(10)],
      Profesion: ['', null],
      PersonPrp: [{ value: '', disabled: false }, null],
      Otra: ['', null]
    });
    if (this.globalVars.licenseType == LicenseTypes.Mexico || this.globalVars.licenseType == LicenseTypes.Panama) {
      this.myForm.addControl(
        'phone',
        new FormControl(
          { value: '', disabled: true },
          Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(10)])
        )
      );
      this.myForm.addControl('PersonPrpCargo', new FormControl({ value: '', disabled: true }));
      // En MX y CO -> PRP
    } else {
      // Para otros tipos de licencia
      const phoneControl = new FormControl(
        { value: '', disabled: true },
        Validators.compose([Validators.required, Validators.maxLength(50)])
      );

      // Añade el validador de teléfono a la lista de validadores del control
      phoneControl.setValidators(this.phoneValidator);

      this.myForm.addControl('phone', phoneControl);
    }
    if (this.utils.isArgentina()) {
      this.pickerOptions = {
        cssClass: 'datetimepersonalinfo'
      };
      this.fromExtractAr = this.fb.group({
        fromDate: [{ value: null }, Validators.required],
        toDate: [{ value: null, disabled: true }, Validators.required]
      });

      const dateAux = new Date('2021-12-01');
      const dayAux = dateAux.getDate() >= 10 ? dateAux.getDate() : '0' + dateAux.getDate().toString();
      const monthAux =
        dateAux.getMonth() + 1 >= 10 ? dateAux.getMonth() + 1 : '0' + (dateAux.getMonth() + 1).toString();
      this.dateRegisterUser = `${dateAux.getFullYear().toString()}-${monthAux.toString()}-${dayAux.toString()}`;
      this.fromExtractAr.get('fromDate').valueChanges.subscribe((value) => {
        const dateNow = new Date();
        const dayAuxNow = dateNow.getDate() >= 10 ? dateNow.getDate() : '0' + dateNow.getDate().toString();
        const monthAuxNow =
          dateNow.getMonth() + 1 >= 10 ? dateNow.getMonth() + 1 : '0' + (dateNow.getMonth() + 1).toString();
        this.toDateMax = `${dateNow.getFullYear().toString()}-${monthAuxNow.toString()}-${dayAuxNow.toString()}`;
        const minDate = new Date(value);
        const dayAuxMin = minDate.getDate() >= 10 ? minDate.getDate() : '0' + minDate.getDate().toString();
        const monthAuxMin =
          minDate.getMonth() + 1 >= 10 ? minDate.getMonth() + 1 : '0' + (minDate.getMonth() + 1).toString();
        this.toDateMin = `${minDate.getFullYear().toString()}-${monthAuxMin.toString()}-${dayAuxMin.toString()}`;
        this.fromExtractAr.get('toDate').reset();
        this.fromExtractAr.get('toDate').enable();
        this.fromExtractAr.updateValueAndValidity();
      });

      this.fromDateMin = this.dateRegisterUser;
      const dateNo = new Date();
      const dayAuxNow = dateNo.getDate() >= 10 ? dateNo.getDate() : '0' + dateNo.getDate().toString();
      const monthAuxNow =
        dateNo.getMonth() + 1 >= 10 ? dateNo.getMonth() + 1 : '0' + (dateNo.getMonth() + 1).toString();
      this.fromDateMax = `${dateNo.getFullYear().toString()}-${monthAuxNow.toString()}-${dayAuxNow.toString()}`;

      // campos parciales para la dirección
      this.myForm.addControl('num', new FormControl({ value: '', disabled: true }));
      this.myForm.addControl('piso', new FormControl({ value: '', disabled: true }));
      this.myForm.addControl('dpto', new FormControl({ value: '', disabled: true }));
      // PEP
      this.myForm.addControl('PersonPrpCargo', new FormControl({ value: '', disabled: false }));
      this.myForm.addControl('PepTipo', new FormControl({ value: '', disabled: false }));
      this.myForm.addControl('PepCargo', new FormControl({ value: '', disabled: false }));
      this.myForm.addControl('PepVinculo', new FormControl({ value: '', disabled: false }));
      this.myForm.addControl('PepTitular', new FormControl({ value: '', disabled: false }));
      this.pepSelectTitular = [
        { label: this.translate.instant('peptitular'), value: '0' },
        { label: this.translate.instant('pepAffinity'), value: '1' }
      ];
      // Estado civil y Cónyuge
      this.nationalities = [];
      this.typesDocument = [
        { id: 0, name: 'DNI', label: 'DNI Argentino' },
        { id: 1, name: 'Libreta de Enrolamiento', label: 'Libreta de Enrolamiento' },
        { id: 2, name: 'Libreta Cívica', label: 'Libreta Cívica' },
        { id: 3, name: 'Cédula Mercosur', label: 'Cédula Mercosur' },
        { id: 4, name: 'Pasaporte', label: 'Pasaporte Argentino' },
        { id: 5, name: 'Pasaporte EXT', label: 'Pasaporte Extranjero' },
        { id: 6, name: 'Documento EXT', label: 'Documento Extranjero' }
        // {{'NotResidDocumentType2' | translate}}/{{'NotResidDocumentType3' | translate}}
      ];
      this.myForm.addControl('estCivil', new FormControl('', [Validators.required]));
      this.myForm.addControl('cygNombre', new FormControl(''));
      this.myForm.addControl('cygApellidos', new FormControl(''));
      this.myForm.addControl('cygNacionalidad', new FormControl(''));
      this.myForm.addControl('cygTipoDoc', new FormControl(0));
      this.myForm.addControl('cygNumDoc', new FormControl(''));
      this.myForm.addControl('typeTaxIdentification', new FormControl(''));
      this.myForm.addControl('taxIdentifier', new FormControl(''));
      // opciones de estado civil
      this.estCiviles = this.utils.getCivilStatesAR();
    }
    if (this.GetValidLicense()) {
      this.register = {
        NombreUsuario: '',
        Direccion_Correo: '',
        NumMovil: '',
        Direccion: '',
        CodigoPostal: '',
        Profesion: '',
        Otra: '',
        PersonPrp: false,
        PersonPrpCargo: ''
      };
      if (this.utils.isArgentina()) {
        this.register = {
          ...this.register,
          // Campos parciales de la direccion
          AddressNumberStreet: '',
          AddressFloor: '',
          AddressDepartment: '',
          // Nuevos campos PET
          confirmPepTipo: '',
          confirmPepTitular: '',
          confirmPepVinculo: '',
          // Nuevos campos conyuge
          cygNombre: '',
          cygApellidos: '',
          cygNacionalidad: '',
          cygTipoDoc: '',
          cygNumDoc: '',
          typeTaxIdentification: '',
          taxIdentifier: ''
        };
      }
    } else {
      this.register = {
        NombreUsuario: '',
        Direccion_Correo: '',
        NumMovil: '',
        Direccion: '',
        CodigoPostal: ''
      };
    }

    this.originalData = {
      email: '',
      phone: ''
    };

    this.UpdateInProgress = false;

    this.actualYear =
      this.globalVars.licenseType == LicenseTypes.Nacional ? new Date().getFullYear() - 1 : new Date().getFullYear();

    this.yearForm = this.fb.group({
      year: [this.actualYear, null]
    });

    this.years = [];

    const minYear =
      this.globalVars.licenseType == LicenseTypes.ArgentinaCaba
        ? 2021
        : this.globalVars.licenseType == LicenseTypes.ArgentinaPBA
          ? 2023
          : this.globalVars.licenseType == LicenseTypes.ArgentinaMendoza
            ? 2024
            : 2014;
    for (let i = this.actualYear; i >= minYear; i--) {
      this.years.push(i);
    }
    this.legalTerms = { url: '', date: '' };
    this.policyTerms = { url: '', date: '' };

    if (this.globalVars.licenseType == LicenseTypes.Colombia) {
      this.loadTermsAndPolitics();
    }

    this.profesionInfo = this.globalVars.FEATURES.ListOfProfesions.split(';', 200);

    this.profesionInfoSelect = [];
    for (let i = 0; i < this.profesionInfo.length; i++) {
      this.profesionInfoSelect.push({
        label: this.profesionInfo[i],
        value: i.toString()
      });
    }

    this.loadUserData();
    this.ProfesionEvents();
  }

  ngAfterViewInit(): void {
    this.resizeExtractDateOptions();
  }

  resizeExtractDateOptions = () => {
    const fromDateEle = document.getElementById('fromDate');
    try {
      if (window.innerWidth > 360) {
        this.fromDate.size = null;
        fromDateEle.classList.add('adjust-grid-element');
        fromDateEle.style.marginLeft = '0';
      } else {
        this.fromDate.size = '12';
        fromDateEle.classList.remove('adjust-grid-element');
        fromDateEle.style.marginLeft = '0.5rem';
      }
    } catch (error) {
      console.log(error);
    }
  };

  ngOnInit(): void {
    if (this.utils.isArgentina()) {
      this.myForm.controls['PepTipo'].valueChanges.subscribe((value) => {
        if (value == '0') {
          this.myForm.controls['PepVinculo'].clearValidators();
          this.myForm.controls['PepTitular'].clearValidators();
          this.myForm.controls['PepVinculo'].reset();
          this.myForm.controls['PepTitular'].reset();
        } else if (value == '1') {
          this.myForm.controls['PepVinculo'].setValidators([Validators.required, Validators.minLength(4)]);
          this.myForm.controls['PepTitular'].setValidators([Validators.required, Validators.minLength(4)]);
        }
      });
      this.myForm.get('estCivil').valueChanges.subscribe((value) => {
        console.info('Estado civil', value);
        if (
          this.myForm.get('estCivil').value == 'Casado/a' ||
          this.myForm.get('estCivil').value == 'Unión Civil' ||
          this.myForm.get('estCivil').value == 'Unión de Hecho'
        ) {
          this.myForm.get('cygNombre').setValidators(Validators.required);
          this.myForm.get('cygApellidos').setValidators(Validators.required);
          this.myForm.get('cygNacionalidad').setValidators(Validators.required);
          this.myForm.get('cygTipoDoc').setValidators(Validators.required);
          this.myForm
            .get('cygNumDoc')
            .setValidators([Validators.required, this.validaNumDoc(this.myForm.get('cygTipoDoc'))]);
          this.myForm.get('typeTaxIdentification').setValidators(Validators.required);
          // this.myForm.get('taxIdentifier').setValidators([Validators.required,this.cuiTLValidator])
        } else {
          this.myForm.get('cygNombre').clearValidators();
          this.myForm.get('cygApellidos').clearValidators();
          this.myForm.get('cygNacionalidad').clearValidators();
          this.myForm.get('cygTipoDoc').clearValidators();
          this.myForm.get('cygNumDoc').clearValidators();
          this.myForm.get('typeTaxIdentification').clearValidators();
          this.myForm.get('taxIdentifier').clearValidators();
        }
        this.myForm.get('cygNombre').updateValueAndValidity();
        this.myForm.get('cygApellidos').updateValueAndValidity();
        this.myForm.get('cygNacionalidad').updateValueAndValidity();
        this.myForm.get('cygTipoDoc').updateValueAndValidity();
        this.myForm.get('cygNumDoc').updateValueAndValidity();
        this.myForm.get('typeTaxIdentification').updateValueAndValidity();
        this.myForm.get('taxIdentifier').updateValueAndValidity();
      });
      this.myForm.get('typeTaxIdentification').valueChanges.subscribe((value) => {
        if (
          this.myForm.get('estCivil').value == 'Casado/a' ||
          this.myForm.get('estCivil').value == 'Unión Civil' ||
          this.myForm.get('estCivil').value == 'Unión de Hecho'
        ) {
          if (value != this.auxTypeIdentification) {
            this.auxTypeIdentification = value;
            this.myForm.get('taxIdentifier').clearValidators();
            // this.myForm.get('taxIdentifier').reset();
            if (value === 'CDI') {
              this.myForm
                .get('taxIdentifier')
                .setValidators([Validators.required, Validators.maxLength(11), Validators.minLength(11)]);
            } else {
              this.myForm
                .get('taxIdentifier')
                .setValidators([Validators.required, Validators.pattern('^[0-9-]+$'), this.cuiTLValidator]);
            }
          }
        } else {
          this.myForm.get('taxIdentifier').clearValidators();
        }
      });
    }
  }

  resetValidatorsInfoPersonPep() {
    this.myForm.controls['PepTipo'].clearValidators();
    this.myForm.controls['PepCargo'].clearValidators();
    this.myForm.controls['PepVinculo'].clearValidators();
    this.myForm.controls['PepTitular'].clearValidators();
    this.myForm.controls['PersonPrp'].setValue(false);
    this.myForm.controls['PepTitular'].reset();
    this.myForm.controls['PepTipo'].reset();
    this.myForm.controls['PepCargo'].reset();
    this.myForm.controls['PepVinculo'].reset();
    this.register.PersonPrp = false;
    this.myForm.get('PersonPrp').setValue(false);
    this.trueFormControl.setValue(false);
    this.falseFormControl.setValue(true);
  }
  resetValidatorsInfoPersonPepOK() {
    this.myForm.controls['PepTipo'].setValidators(Validators.required);
    this.register.PersonPrp = true;
    this.myForm.get('PersonPrp').setValue(true);
    this.myForm.controls['PepCargo'].setValidators([Validators.required, Validators.minLength(4)]);
    this.falseFormControl.setValue(false);
    this.trueFormControl.setValue(true);
  }
  /**
   * Diferencia licencias de Colombia - México - Panama -> devuelve false
   * frente a otras: España y Argentina -> Devuelve true
   * @returns
   */
  GetValidLicense(): boolean {
    if (
      this.globalVars.licenseType != LicenseTypes.Colombia &&
      this.globalVars.licenseType != LicenseTypes.Mexico &&
      this.globalVars.licenseType != LicenseTypes.Panama
    ) {
      return true;
    } else {
      return false;
    }
  }

  ProfesionEvents() {
    if (this.GetValidLicense()) {
      this.myForm.controls['Otra'].valueChanges.subscribe((value: string) => {
        if (this.register != null) {
          if (this.register.Otra != '') {
            this.ticketTarget = document.getElementById('select-profesion');
            /* se comenta para evitar que el campo profesión esté deshabilitado cuendo viene el campo Otra */
            // this.renderer2.setProperty(this.ticketTarget, 'disabled', 'true');

            this.ticketTarget = document.getElementById('Input-otra');
            /* se comenta porque esta clase ya no se le pone*/
            // this.renderer2.removeClass(this.ticketTarget, 'item-input-disabled');
            this.UpdateInProgress = false;
          }
        }
      });

      this.myForm.controls['Profesion'].valueChanges.subscribe((value: string) => {
        if (!this.UpdateInProgress) {
          if (this.register != null) {
            if (
              this.register.Profesion != '' //&&
              //this.register.Profesion != "Seleccione una opcion"
            ) {
              this.ticketTarget = document.getElementById('select-profesion');
              /* se comenta esta línea pq el campo profesion ya no está nunca deshabilitado*/
              // this.renderer2.setProperty(this.ticketTarget, 'disabled', 'false');
              this.ticketTarget = document.getElementById('Input-otra');
              // this.renderer.setElementClass(
              //   this.ticketTarget,
              //   "item-input-disabled",
              //   true
              // );
              // if (this.ticketTarget) {
              //   this.renderer2.addClass(this.ticketTarget, 'item-input-disabled');
              // }
              this.register.Otra = '';
              this.showother = false;
              this.UpdateInProgress = false;
            }
          }
        }
      });
    }
  }

  changeFocus() {
    this.ticketTarget = document.getElementById('Input-otra');
    this.renderer2.removeClass(this.ticketTarget, 'item-input-disabled');
  }

  phoneValidator = (control: AbstractControl): Observable<ValidationErrors | null> => {
    let regExpPhone = /^[6|7|8][0-9]{8}$/;
    if (this.globalVars.licenseType == LicenseTypes.Colombia) {
      regExpPhone = /^[3][0-9]{9}$/;
    }

    if (control.value != this.originalData.phone) {
      if (regExpPhone.test(control.value)) {
        return this.userServiceMSO.checkDataAvailability('TELEFONO', control.value).pipe(
          map((isAvailable) => (isAvailable ? null : { dataNotAvailable: true })),
          catchError(() => of({ serverError: true }))
        );
      } else {
        return of({ validFormat: 'notValid' });
      }
    } else {
      return of(null);
    }
  };
  //errores cónyuge en AR
  errorCodeSpouse(code = '') {
    const error = this.myForm.get(code).errors;
    let errorCode = '';
    if (error) {
      if (error['required']) {
        errorCode = this.translate.instant('error_form_requiered');
        //error['validFormat']
      } else {
        errorCode = this.translate.instant('error_format');
      }
    }
    return errorCode;
  }

  cuiTLValidator(control: AbstractControl): ValidationErrors | null {
    if (!control.value) return null;
    const cuil = control.value.split('-').join('').split(' ').join('');
    if (cuil.length !== 11) {
      return { validFormat: 'formatnoValid' };
    }

    const [checkDigit, ...rest] = cuil.split('').map(Number).reverse();

    const total = rest.reduce((acc, cur, index) => acc + cur * (2 + (index % 6)), 0);

    const mod11 = 11 - (total % 11);

    if (mod11 === 11) {
      const result = checkDigit === 0 ? null : { validFormat: 'formatnoValid' };
      return result;
    }

    if (mod11 === 10) {
      return { validFormat: 'formatnoValid' };
    }

    return checkDigit === mod11 ? null : { validFormat: 'formatnoValid' };
  }

  emailValidator = (control: FormControl) => {
    const regExpEmail =
      // eslint-disable-next-line no-useless-escape
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (control.value != this.originalData.email) {
      if (regExpEmail.test(control.value)) {
        return this.userServiceMSO.checkDataAvailability('CORREO', control.value);
      } else {
        return of({ validFormat: 'notValid' });
      }
    } else {
      return of(null);
    }
  };

  ionViewWillEnter() {
    this.route.navigate([MSO_PATHS.BASE + '/' + MSO_PATHS.PersonalInfoPage]);
    this.trackingService.trackEvent(['AccessToPersonalInf', '', '', 'Acceder info personal', 'event']);
    //refresh footer
    this.events.publish('footer:refresh', this.content);
    //load nationalities (only Argentina)
    if (this.utils.isArgentina()) {
      this.userService.getTable('CDR_OL_NACIONALIDAD').subscribe(
        (data) => {
          // ;
          this.nationalities = [];
          for (const key in data) {
            this.nationalities.push(data[key]);
          }
          //Ordena el array por orden alfabético
          this.nationalities.sort();
        },
        (err) => console.error(err),
        () => console.info('completed')
      );
    }
  }

  ionViewDidEnter() {
    //refresh footer
    this.events.publish('footer:refresh', this.content);
  }

  /**
   * Manejador de eventos del boton "Cambiar" (Contraseña)
   */
  changePass() {
    //goto change pass
    this.route.navigate([MSO_PATHS.BASE + '/' + MSO_PATHS.ChangePasswordPage]), { animate: this.isMobile };
  }

  async download() {
    this.utils.loader();
    const selectedYear = this.yearForm.controls['year'].value;
    const datesToSend = this.formatFinalToDates(selectedYear);
    if (this.globalVars.isNative) {
      const token = await this.userServiceMSO.getTokenFromCookieNatives().toPromise();
      this.userServiceMSO.newUserSummaryNatives(datesToSend[0], datesToSend[1], token.data);
    } else {
      this.userServiceMSO.newUserSummary(datesToSend[0], datesToSend[1]).subscribe({
        next: (resp: any) => {
          if (!resp.type.includes('text')) {
            const name = `CodereApuestas_${datesToSend[0].split('-')[2]}_${this.register.NIF}`;
            this.utils.saveBlob(resp, name);
          } else {
            this.utils.showError(this.translate.instant('generate_summmaryPlayer'));
          }
        },
        error: () => {
          this.utils.showError(this.translate.instant('generate_summmaryPlayer_Error'));
        }
      });
    }
  }

  async getExtractoAR() {
    if (this.fromExtractAr.valid) {
      this.utils.loader();
      const dateFrom = this.formatDate(this.fromExtractAr.get('fromDate').value);
      const dateTo = this.formatDate(this.fromExtractAr.get('toDate').value);
      if (this.globalVars.isNative) {
        const token = await this.userServiceMSO.getTokenFromCookieNatives().toPromise();
        this.userServiceMSO.newUserExtractNatives(dateFrom, dateTo, token.data);
      } else {
        this.userServiceMSO.newUserExtract(dateFrom, dateTo).subscribe(
          (resp: any) => {
            if (!resp.type.includes('text')) {
              const name = `CodereApuestas_${dateFrom.split('-')[2]}_${this.register.NIF}`;
              this.utils.saveBlob(resp, name);
            } else {
              this.utils.showError(this.translate.instant('generate_summmaryPlayer'));
            }
          },
          () => {
            this.utils.showError(this.translate.instant('generate_summmaryPlayer_Error'));
          }
        );
      }
    }
  }
  formatDate(date: string) {
    const dateArr = date.split('-');
    return `${dateArr[2]}-${dateArr[1]}-${dateArr[0]}`;
  }

  formatFinalToDates(year: string) {
    let toDate: string;
    let fromDate: string;
    if (year == '') {
      const lastYear = this.globalVars.licenseType == 0 ? new Date().getFullYear() - 1 : new Date().getFullYear();
      const fromYear = this.globalVars.licenseType == 33 ? 2021 : 2014;
      fromDate = `01-01-${fromYear}`;
      toDate = `31-12-${lastYear}`;
    } else {
      fromDate = `01-01-${year}`;
      toDate = `31-12-${year}`;
    }
    return [fromDate, toDate];
  }

  downloadTerms() {
    window.open(this.legalTerms.url, '_blank');
  }

  /**
   * Descargar política de privacidad (Solo Colombia)
   */
  downloadPolitic() {
    window.open(this.policyTerms.url, '_blank');
  }

  /**
   * Descargar términos y condiciones (Solo Colombia)
   */
  loadTermsAndPolitics() {
    this.userServiceMSO.getLastTermsAndConditionsUrlbyRegion('Colombia').subscribe(
      (data) => {
        this.legalTerms.date = this.extractDatePipe.transform(data.Date, [1]);
        this.legalTerms.url = data.Url;
      },
      (err) => {
        //
        console.error(err);
      }
    );
    this.userServiceMSO.getLastPrivacyPoliticsUrlbyRegion('Colombia').subscribe(
      (data) => {
        this.policyTerms.date = this.extractDatePipe.transform(data.Date, [1]);
        this.policyTerms.url = data.Url;
      },
      (err) => {
        //
        console.error(err);
      }
    );
  }

  /**
   * Manejador del evento submit del formulario (boton "Actualizar")
   */
  updateData() {
    let validForm = false;

    if (
      (this.myForm.get('address').valid || this.myForm.get('address').disable) &&
      (this.myForm.get('zipCode').valid || this.myForm.get('zipCode').disable) &&
      (this.myForm.get('phone').valid || this.myForm.get('phone').disable) &&
      (this.myForm.get('email').valid || this.myForm.get('email').disable)
    ) {
      validForm = true;
      // eslint-disable-next-line no-empty
    } else {
    }

    if (this.globalVars.licenseType == LicenseTypes.Nacional) {
      this.register.Profesion != '' || this.register.Otra != '' ? (validForm = true) : (validForm = false);
    }
    if (this.utils.isArgentina()) {
      validForm = this.myForm.valid;
    }

    if (validForm) {
      this.register.NombreUsuario = this.myForm.get('user').value;
      this.register.Direccion_Correo = this.myForm.get('email').value;
      this.register.NumMovil = this.myForm.get('phone').value;
      this.register.Direccion = this.myForm.get('address').value;
      this.register.CodigoPostal = this.myForm.get('zipCode').value;
      // Only for spain,
      if (this.GetValidLicense()) {
        if (this.register.Otra != '' && this.register.Profesion == '') {
          this.UpdateInProgress = true;
          this.register.Profesion = this.register.Otra;
        }
      }
      if (this.utils.isArgentina()) {
        this.updateDataAr();
      }
      this.userServiceMSO.updateData(this.register).subscribe(
        (data) => {
          if (data.status == 'ok') {
            this.utils.showMsg(
              this.translate.instant('m_reg_updOk'),
              null,
              this.utils.isArgentina() ? this.translate.instant('updateddates').toUpperCase() : 'INFO',
              'updateDataAlert'
            );
            this.ChangeProfesionInfo();
            if (this.globalVars.FEATURES.MSO_CheckProfesion) {
              this.globalVars.extraUserData.haveProfesion = true;
              this.events.publish('badges:refresh');
            }
          } else {
            this.ChangeProfesionInfo();
            if (data.errMessage != undefined) {
              this.errorService.searchErrorCode(data.errMessage).subscribe({
                next: (data) => {
                  this.utils.showError(data);
                },
                error: (err) => {
                  this.utils.showError(data.errMessage);
                }
              });
            }
          }
        },
        (err) => {
          this.utils.showError(this.translate.instant('m_menu_serverFail'));
        }
      );
    } else {
      //this.myForm.markAsTouched();
      for (const x in this.myForm.controls) {
        this.myForm.controls[x].markAsTouched();
      }
    }
  }

  updateDataAr() {
    this.register.AddressNumberStreet = this.myForm.get('num').value;
    this.register.AddressFloor = this.myForm.get('piso').value;
    this.register.AddressDepartment = this.myForm.get('dpto').value;
    this.register.DeclaracionPep = this.myForm.get('PersonPrp').value;
    // PEP
    /// PersonPrp : bolean
    this.register.ConfirmPepTipo =
      this.myForm.get('PepTipo').value && this.myForm.get('PepTipo').value == '0'
        ? 'Titular'
        : this.myForm.get('PepTipo').value && this.myForm.get('PepTipo').value == '1'
          ? 'Afinidad/Cercanía'
          : '';
    this.register.PersonPrpCargo = this.myForm.get('PepCargo').value;
    this.register.ConfirmPepVinculo = this.myForm.get('PepVinculo').value;
    this.register.ConfirmPepTitular = this.myForm.get('PepTitular').value;
    // Estado civil y Cónyuge
    this.register.Estado = this.myForm.get('estCivil').value;
    // opciones de estado civil
    // this.estCiviles = [
    //   { id: 1, name: 'Casado/a' },
    //   { id: 2, name: 'Divorciado/a' },
    //   { id: 3, name: 'Soltero/a' },
    //   { id: 4, name: 'Unión Civil' },
    //   { id: 5, name: 'Unión de Hecho' },
    //   { id: 6, name: 'Viudo/a' }
    // ]
    this.register.PersoncloselyrelatedwithplayerName = this.myForm.get('cygNombre').value;
    this.register.PersoncloselyrelatedwithplayerLastname = this.myForm.get('cygApellidos').value;
    this.register.PersoncloselyrelatedwithplayerNationality = this.myForm.get('cygNacionalidad').value;
    const tipeDoc = this.typesDocument.find((item) => item.id == this.myForm.get('cygTipoDoc').value);
    this.register.PersoncloselyrelatedwithplayerTipodocumento = tipeDoc ? tipeDoc.name : tipeDoc;
    this.register.PersoncloselyrelatedwithplayerNif = this.myForm.get('cygNumDoc').value;
    // if ( this.myForm.get('estCivil').value != 'Casado/a' &&
    //           this.myForm.get('estCivil').value != 'Unión Civil' &&
    //           this.myForm.get('estCivil').value != 'Unión de Hecho'){
    //             this.myForm.get('typeTaxIdentification').reset();
    //             this.myForm.get('taxIdentifier').reset();
    //           }
    this.register.PersoncloselyrelatedwithplayerTipoidentificaciontributaria = this.myForm.get('typeTaxIdentification')
      .value
      ? this.myForm.get('typeTaxIdentification').value
      : null;
    this.register.PersoncloselyrelatedwithplayerIdentificadortatributario = this.myForm.get('taxIdentifier').value
      ? this.myForm.get('taxIdentifier').value.split('-').join('').split(' ').join('')
      : null;
  }

  ChangeProfesionInfo() {
    if (this.GetValidLicense()) {
      this.UpdateInProgress = false;
    }
  }

  /**
   * Carga de datos del usuario
   * Utiliza el servicio getUserData()
   */
  loadUserData() {
    this.userService.getUserData().subscribe(
      (data) => {
        this.register = data;

        this.globalVars.user.name = data.Nombre;
        this.globalVars.user.surname = data.PrimerApellido;
        this.globalVars.user.surname2 = data.SegundoApellido;
        this.globalVars.user.birthDay = this.extractDatePipe.transform(new Date(data.FechaNacimiento), [1]);

        this.originalData.email = this.register.Direccion_Correo;
        this.originalData.phone = this.register.NumMovil;
        // Only for nacional the profesion is retrieved
        this.CheckLoadOfProfesion();

        this.myForm.patchValue({
          user: data.NombreUsuario,
          email: data.Direccion_Correo,
          phone: data.NumMovil,
          address: data.Direccion,
          zipCode: data.CodigoPostal
        });

        if (this.utils.isArgentina()) {
          this.loadUserDataAR();
        }
      },
      (err) => {
        // this.utils.showError(err);
      }
    );
  }

  /**
   * Continuación de la carga de datos específica en AR
   */
  loadUserDataAR() {
    this.myForm.patchValue({ num: this.register.AddressNumberStreet });
    this.myForm.patchValue({ piso: this.register.AddressFloor });
    this.myForm.patchValue({ dpto: this.register.AddressDepartment });
    // PEP
    this.myForm.patchValue({ PersonPrp: this.register.DeclaracionPep }); //bolean
    // eslint-disable-next-line no-nested-ternary
    const tipopep = this.register.ConfirmPepTipo ? (this.register.ConfirmPepTipo == 'Titular' ? '0' : '1') : null;
    this.myForm.patchValue({ PepTipo: tipopep });
    this.register.DeclaracionPep ? this.trueFormControl.setValue(true) : this.falseFormControl.setValue(true);
    this.myForm.patchValue({ PepCargo: this.register.PersonPrpCargo });
    this.myForm.patchValue({ PepVinculo: this.register.ConfirmPepVinculo });
    this.myForm.patchValue({ PepTitular: this.register.ConfirmPepTitular });
    // Estado civil y Cónyuge
    const estadoCivil = this.estCiviles.filter((item) => item.name.toUpperCase() == this.register.Estado.toUpperCase());
    //   { id: 1, name: 'Casado/a' },
    //   { id: 2, name: 'Divorciado/a' },
    //   { id: 3, name: 'Soltero/a' },
    //   { id: 4, name: 'Unión Civil' },
    //   { id: 5, name: 'Unión de Hecho' },
    //   { id: 6, name: 'Viudo/a' }
    // ]
    this.myForm.patchValue({ estCivil: estadoCivil[0].name });
    this.myForm.patchValue({
      cygNombre:
        this.register.PersoncloselyrelatedwithplayerName === 'null'
          ? ''
          : this.register.PersoncloselyrelatedwithplayerName
    });
    this.myForm.patchValue({
      cygApellidos:
        this.register.PersoncloselyrelatedwithplayerLastname === 'null'
          ? ''
          : this.register.PersoncloselyrelatedwithplayerLastname
    });
    this.myForm.patchValue({ cygNacionalidad: this.register.PersoncloselyrelatedwithplayerNationality });
    let tipoDocumento;
    if (this.register.PersoncloselyrelatedwithplayerTipodocumento) {
      tipoDocumento = this.typesDocument.find(
        (d) => d.name.toUpperCase() === this.register.PersoncloselyrelatedwithplayerTipodocumento.toUpperCase()
      ).id;
    }
    this.myForm.patchValue({ cygTipoDoc: tipoDocumento });
    this.myForm.patchValue({
      cygNumDoc:
        this.register.PersoncloselyrelatedwithplayerNif === 'null'
          ? ''
          : this.register.PersoncloselyrelatedwithplayerNif
    });
    this.myForm.patchValue({
      typeTaxIdentification:
        this.register.PersoncloselyrelatedwithplayerTipoidentificaciontributaria === null
          ? ''
          : this.register.PersoncloselyrelatedwithplayerTipoidentificaciontributaria
    });
    this.myForm.patchValue({
      taxIdentifier:
        this.register.PersoncloselyrelatedwithplayerIdentificadortatributario === null
          ? ''
          : this.register.PersoncloselyrelatedwithplayerIdentificadortatributario
    });
  }

  CheckLoadOfProfesion() {
    if (this.GetValidLicense() && this.register.Profesion) {
      if (this.register.Profesion != '' && this.register.Profesion == 'Seleccione una opcion') {
        this.register.Profesion = '';
      }
      if (this.register.Profesion != '') {
        let dataToCheck = false;

        for (let index = 0; index < this.profesionInfoSelect.length; index++) {
          if (this.profesionInfoSelect[index].label == this.register.Profesion) {
            dataToCheck = true;
            break;
          }
        }
        if (!dataToCheck) {
          this.UpdateInProgress = true;
          this.showother = true;
          this.register.Otra = this.register.Profesion;
          //this.register.Profesion = "Seleccione una opción";
        }
      } else {
        this.showother = false;
      }
    }
  }

  doSubscription() {
    //
    this.userService.setReceive().subscribe((data) => {
      this.globalVars.user.pendingSMSEmail = false;
      this.events.publish('badges:refresh');
    });
  }

  validaNumDoc(tipoDocControl: AbstractControl): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!tipoDocControl.valid || !control.value) {
        return null;
      }
      const tipoDoc: number = tipoDocControl.value;
      // 0 -> 'DNI' 1 -> Pasaporte/Otros
      const numDoc: string = control.value;
      const docRegExp = tipoDoc == 0 ? /^[0-9]{6,12}$/ : /^[0-9a-zA-Z]{6,12}$/;

      if (docRegExp.test(numDoc)) {
        // valid
        return null;
      } else {
        // devuelve error
        return { validFormat: 'Formato no válido' };
      }
    };
  }
  setfalseshowDisabledAddressInfo() {
    if (this.globalVars.licenseType == 33) {
      this.showDisabledAddressInfo = true;
    }
  }
}
