import { IonContent, IonicModule, NavController, NavParams } from '@ionic/angular';
import { ViewChild, Component, inject } from '@angular/core';
import { CodereFooterComponent } from '@shared-mso-components/footer/codere-footer/codere-footer';
import { CodereNavbar } from '@shared-common-components/codere-navbar/codere-navbar';
import { DeviceService } from '@services/device.service';
import { EventsService } from '@providers/EventsService';
import { GlobalVarsModel } from '@models/ModelGlobalsVar';
import { NewBaseService } from '@providers/newBase.service';
import { ParseMoney } from '@pipes/parseMoney';
import { SlotsService } from '@providers/SlotsService';
import { UserSectionTitle } from '@shared-mso-components/user-section-title/user-section-title';
import { Utils } from '@utils/Utils';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { EmptyState } from '@components/payments/empty-state/empty-state';
import { FormsModule } from '@angular/forms';
import { ExtractDatePipe } from '@pipes/extractDate.pipe';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'slots-history-page',
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    CodereNavbar,
    UserSectionTitle,
    CodereFooterComponent,
    EmptyState,
    TranslateModule,
    ExtractDatePipe,
    ParseMoney
  ],
  standalone: true,
  templateUrl: 'slots-history.html',
  styleUrls: ['./slots-history.scss']
})
export class SlotsHistoryPage {
  @ViewChild(IonContent) content: IonContent;
  form: { date: string };
  arrHistory: Array<any>;
  imageSource: string;
  globalVars!: GlobalVarsModel;
  isMobile = false;
  queryParamsSubscription: Subscription;

  nav = inject(NavController);
  navParams = inject(NavParams);
  events = inject(EventsService);
  newBaseService = inject(NewBaseService);
  slotsService = inject(SlotsService);
  utils = inject(Utils);
  deviceService = inject(DeviceService);
  router = inject(Router);
  route = inject(ActivatedRoute);

  constructor() {
    // * GlovalVars
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });

    this.isMobile = this.deviceService.isMobile();

    this.form = {
      date: '2'
    };

    if (this.navParams.data && this.navParams.data['item']) {
      this.form = { date: this.navParams.data['item'].type };
    }
    this.arrHistory = [];
    this.updateHistory();
    this.imageSource = 'assets/global/img/slotsGreen.svg';
  }

  ngOnInit() {
    this.queryParamsSubscription = this.route.queryParams.subscribe((params) => {
      if (Object.keys(params).length > 0) {
        this.updateHistory(params['type']);
      } else {
        this.updateHistory('2');
        this.form.date = '2';
      }
      this.events.publish('footer:refresh', this.content);
    });
  }

  ngAfterViewInit() {
    this.events.publish('footer:refresh', this.content);
  }

  updateHistory(datatype?: string) {
    let datasearch;
    if (datatype) {
      datasearch = datatype;
      this.form.date = datatype;
    } else {
      datasearch = this.form.date;
    }

    if (datasearch == 'lastmonth') {
      datasearch = '31';
    }

    this.slotsService.getSlotHistory(datasearch).subscribe(
      (data) => {
        this.arrHistory = data;
      },
      (err) => {
        this.utils.showError(err);
      }
    );
  }
}
