<div class="sb-marquee-swiper swiper-container" [class.is-switch]="switch" #swiper>
  <div
    class="swiper-wrapper"
    [class.is-switch]="switch"
    #list
    [ngStyle]="{'justify-content': isMobile==true ?  marquees.length==1 ? 'center': 'left' : marquees.length<3 ? 'center':'left'}"
  >
    <div
      *ngFor="let marquee of marquees; let index = index"
      class="sb-marquee--box swiper-slide sb-marquee-item background-color-light"
      tappable
    >
      <div class="sb-marquee--table" (click)="navigate(marquee?.NodeId, marquee?.ParentNodeId)">
        <div class="sb-marquee--table-item sb-marquee-item--subtitle text-left">
          <i [ngClass]="'icon-' + marquee?.SportHandle" class="sb-grid-header--sport-icon codere-icon color-dark"></i>
          {{ marquee?.LeagueName | truncateString: 27 }}
        </div>
        <div
          class="sb-marquee--table-item sb-marquee-item--subtitle color-accent text-right"
          [ngClass]="{'marquee-has-streaming': marquee?.HasStreaming}"
        >
          <span *ngIf="marquee?.liveData; else preGameDate">
            {{ getPeriodAndMatchTime(marquee?.liveData, marquee?.SportHandle) }}
          </span>

          <ng-template #preGameDate>
            <span> {{ marquee?.StartDate | extractDate:[6] }} </span>
          </ng-template>
        </div>
      </div>
      <div
        *ngIf="!isLiveTennis(marquee?.SportHandle, marquee?.IsLive); else tennisLive"
        (click)="navigate(marquee?.NodeId, marquee?.ParentNodeId)"
        class="sb-marquee--teams"
      >
        <div class="sb-marquee--teams-item sb-marquee-item--title flex-justify-content-left color-dark">
          {{ marquee?.ParticipantHome | truncateString: 13 }}
        </div>
        <div class="sb-marquee--teams-item-score">
          <div *ngIf="marquee?.ParticipantHomeJerseyUrl">
            <img class="iconnew" src="{{marquee?.ParticipantHomeJerseyUrl}}" alt="" />
          </div>
          <span class="color-accent sb-marquee-item--title">
            <span *ngIf="marquee?.liveData; else preGameResult">
              <span
                *ngIf="isLiveTennis(marquee?.SportHandle, marquee?.IsLive)"
                class="sb-marquee-item--subtitle color-accent"
              >
                ({{ marquee?.liveData?.SetsHome }})
              </span>
              {{ marquee?.liveData.ResultHome }} - {{ marquee?.liveData.ResultAway }}
              <span
                *ngIf="isLiveTennis(marquee?.SportHandle, marquee?.IsLive)"
                class="sb-marquee-item--subtitle color-accent"
              >
                ({{ marquee?.liveData?.SetsAway }})
              </span>
            </span>

            <ng-template #preGameResult>
              <span>-</span>
            </ng-template>
          </span>
          <div *ngIf="marquee?.ParticipantAwayJerseyUrl">
            <img class="iconnew" src="{{marquee?.ParticipantAwayJerseyUrl}}" alt="" />
          </div>
        </div>
        <div class="sb-marquee--teams-item sb-marquee-item--title flex-justify-content-right color-dark">
          {{ marquee?.ParticipantAway | truncateString: 13 }}
        </div>
      </div>

      <ng-template #tennisLive>
        <div class="sb-marquee--tennis" (click)="navigate(marquee?.NodeId, marquee?.ParentNodeId)">
          <div class="sb-marquee--tennis-live-right">
            <div class="sb-marquee--tennis-live-right-home">
              <img
                *ngIf="marquee?.ParticipantHomeJerseyUrl"
                class="iconnew"
                src="{{marquee?.ParticipantHomeJerseyUrl}}"
                alt="Participant Home Jersey"
              />
              <span class="sb-marquee-item--title color-dark">{{ marquee?.ParticipantHome }}</span>
            </div>
            <div class="sb-marquee--tennis-live-right-away">
              <img
                *ngIf="marquee?.ParticipantAwayJerseyUrl"
                class="iconnew"
                src="{{marquee?.ParticipantAwayJerseyUrl}}"
                alt="Participant Away Jersey"
              />
              <span class="sb-marquee-item--title color-dark">{{ marquee?.ParticipantAway }}</span>
            </div>
          </div>
          <div class="sb-marquee--tennis-live-left">
            <sb-overview-score-sets
              [sportHandle]="marquee?.SportHandle"
              [scoreboard]="marquee?.liveData"
              [isMarquee]="true"
              scoreboard
            >
            </sb-overview-score-sets>
          </div>
        </div>
      </ng-template>

      <div class="sb-marquee--button-wrapper" *ngIf="marquee?.Game?.Results; else noBets">
        <sb-button
          class="sb-button background-color-button"
          *ngFor="let result of marquee?.Game?.Results; let index = index"
          [ngClass]="marquee?.Game?.Results.length > 2 ? 'three-buttons' : 'two-buttons'"
          [type]="marquee?.Game?.GameType"
          [typeName]="marquee?.Game?.DisplayTypeName"
          [game]="result"
          [results]="marquee?.Game?.Results"
          [index]="index"
          (bet)="bet($event, marquee, marquee?.Game?.Name, '1')"
          [locked]="result?.Locked"
          [home]="marquee?.ParticipantHome"
          [away]="marquee?.ParticipantAway"
          [selected]="newTicketFacade?.hasResult(result.NodeId.toString())"
          [sport]="marquee?.SportHandle"
        >
        </sb-button>
      </div>

      <ng-template #noBets>
        <div class="sb-marquee--button-wrapper">
          <button
            class="sb-button--bet sb-button--bet-text background-color-button color-dark"
            tappable
            (click)="navigate(marquee?.NodeId, marquee?.ParentNodeId)"
          >
            Apuesta ya
            <i class="codere-icon icon-arrow-right"></i>
          </button>
        </div>
      </ng-template>
    </div>
  </div>
</div>
