import { BaseService } from '@providers/BaseService';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { C_Message } from '@models/C_Message';
// import 'rxjs/add/operator/debounceTime';

@Injectable({ providedIn: 'root' })
export class MessageService extends BaseService {
  messageCount: BehaviorSubject<number>;
  UrlUserServiceApiBaseMessage: string;

  constructor() {
    super();
    this.messageCount = new BehaviorSubject(0);
    this.UrlUserServiceApiBaseMessage = '/Codere.Dynamics.CSS.API/api/v1/';
  }

  getMessages(): Observable<C_Message[]> {
    const url = this.globalVars.DOMAIN + this.UrlUserServiceApiBaseMessage + 'messages';
    return this.myGet(url);
  }

  getMessageCount(): Observable<number> {
    return this.myGet(`${this.globalVars.DOMAIN}${this.UrlUserServiceApiBaseMessage}messages/unread/count`);
  }

  updateMessageCount(mesageCounter: number) {
    this.messageCount.next(mesageCounter);
  }

  setReadMessage(idMessage: string): Observable<any> {
    return this.myPut(
      `${this.globalVars.DOMAIN}${this.UrlUserServiceApiBaseMessage}messages/updateread?id=${idMessage}&isread=true`,
      null
    );
  }
}
