<!-- eslint-disable @angular-eslint/template/eqeqeq -->
<div class="modalAr">
  <button type="button" class="closeModal" (click)="closeModal()">
    <ion-icon class="ion-icon-close" name="close-circle-outline"></ion-icon>
  </button>
  <div class="modal-title">{{title}}</div>
  <div class="modal-main" *ngIf="!fiveLogins">
    <ion-row *ngIf="type==1">
      <ion-col size="7">
        <div class="title01">{{'infoselfexclusion' | translate}}</div>
      </ion-col>
      <ion-col size="5">
        <button type="submit" full (click)="goToSelfExclusion()" class="btnAr">
          <div>hacé click acá</div>
        </button>
      </ion-col>
    </ion-row>
  </div>
  <div [ngClass]="{'modal-footer': true, 'modal5logins': fiveLogins}">
    <ion-row *ngIf="fiveLogins">
      <ion-col size="4" class="center">
        <a (click)="gotoMisApuestas()">
          <img src="assets/global/img/argentina/logosalida.png" alt="Autoexcluirte" class="invertimg" />
        </a>
      </ion-col>
      <ion-col size="4" class="center">
        <a external-link="" target="_blank" href="https://saberjugar.gob.ar/?page=linea-orientacion">
          <img src="assets/global/img/argentina/logobLotba.svg" alt="Logo Loteria de la Ciudad" />
        </a>
      </ion-col>
      <ion-col size="4">
        <a external-link="" target="_blank" href="https://saberjugar.gob.ar/?page=linea-orientacion" class="">
          <img src="assets/global/img/argentina/logobySJ.svg" alt="Logo Saber Jugar" class="bordery" />
        </a>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="!fiveLogins">
      <ion-col size="6" class="center">
        <a external-link="" target="_blank" href="https://www.saberjugar.gob.ar/">
          <img src="assets/global/img/argentina/logobLotba.svg" alt="Logo Loteria de la Ciudad" />
        </a>
      </ion-col>
      <ion-col size="6">
        <a external-link="" target="_blank" href="https://saberjugar.gob.ar/" class="">
          <img src="assets/global/img/argentina/logobySJ.svg" alt="Logo Saber Jugar" class="bordery" />
        </a>
      </ion-col>
    </ion-row>
  </div>
</div>
