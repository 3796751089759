import { IonicModule, NavController } from '@ionic/angular';
import { Component, inject } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { UserService } from '@providers/UserService';
import { Utils } from '@utils/Utils';
import { CommonModule } from '@angular/common';
import { GlobalVarsModel } from '@models/ModelGlobalsVar';
import { NewBaseService } from '@providers/newBase.service';
import { DeviceService } from '@services/device.service';
import { CodereNavbar } from '@shared-common-components/codere-navbar/codere-navbar';
import { MSO_PATHS } from '@shared-constants/routes';
import { CodereFooterComponent } from '@shared-mso-components/footer/codere-footer/codere-footer';
import { UserSectionTitle } from '@shared-mso-components/user-section-title/user-section-title';
import { Router } from '@angular/router';
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'card-request-ok-page',
  templateUrl: './card-request-ok.html',
  styleUrls: ['./card-request-ok.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, TranslateModule, CodereFooterComponent, UserSectionTitle, CodereNavbar]
})
export class CardRequestOkPage {
  isMobile: boolean;
  globalVars!: GlobalVarsModel;
  nav = inject(NavController);
  newBaseService = inject(NewBaseService);
  userService = inject(UserService);
  utils = inject(Utils);
  translate = inject(TranslateService);
  router = inject(Router);
  device = inject(DeviceService);
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });

    this.isMobile = this.device.isMobile();
  }

  openMiTarjeta() {
    this.router.navigate([`/${MSO_PATHS.BASE}/${MSO_PATHS.MyCardPage}`]);
  }
}
