import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, map, switchMap, tap, withLatestFrom, catchError, takeWhile } from 'rxjs';

// Store
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as SportsbookActions from '../actions/sportsbook.actions';
import { SportsbookFacade } from '../facades/sportsbook.facade';
import { SportsbookState } from '../reducers/sportsbook.reducers';

// Services
import { BufferFacade } from '../../../../providers/facades';
import { NewBaseService } from '../../../../providers/newBase.service';
import { SportService } from '../../../../providers/SportService';
import { SportRaceService } from '../../../../../../sports/src/lib/services/sports.race.service';
import { SportLiveService } from '../../../../../../sports/src/lib/services/sports.live.service';
import { SportLiveMarketService } from '../../../../../../sports/src/lib/services/sports.live-market.service';

import { DeviceService } from '@services/device.service';

// Models
import { GlobalVarsModel } from '../../../../models/ModelGlobalsVar';
import * as models from '../../../../models/index';

// Utils
import { SportsbookUtils } from '../storeUtils/sportsbook.utils';
import { RacePageUtils } from '../storeUtils/racePageUtils.utils';
import { SportBookService } from '../../../../providers/SportBookService';
import { livePageUtils } from '../storeUtils/livePageUtils.utils';
import { FLOW_STATUS } from '../../../../../../../libs/sports/src/lib/utils/sports.constants';

@Injectable({ providedIn: 'root' })
export class SportsbookEffects {
  // Services
  newBaseService = inject(NewBaseService);
  bufferFacade = inject(BufferFacade);
  sportService = inject(SportService);
  sportRaceService = inject(SportRaceService);
  sportLiveService = inject(SportLiveService);
  sportLiveMarketService = inject(SportLiveMarketService);

  // Utils
  sportBookService = inject(SportBookService);
  sportBookUtils = inject(SportsbookUtils);
  racePageUtils = inject(RacePageUtils);
  livePageUtils = inject(livePageUtils);
  deviceService = inject(DeviceService);
  store = inject(Store<SportsbookState>);
  sportsbookFacade = inject(SportsbookFacade);

  globalVars!: GlobalVarsModel;

  isDesktop: boolean;
  isMobile: boolean;

  homePolling$ = new BehaviorSubject(true);
  eventPolling$ = new BehaviorSubject(true);
  todayPolling$ = new BehaviorSubject(true);
  marketPolling$ = new BehaviorSubject(true);
  raceMarketPolling$ = new BehaviorSubject(true);
  livePolling$ = new BehaviorSubject(true);
  liveMarketPolling$ = new BehaviorSubject(true);

  pollings$ = [
    this.eventPolling$,
    this.homePolling$,
    this.livePolling$,
    this.liveMarketPolling$,
    this.marketPolling$,
    this.raceMarketPolling$,
    this.todayPolling$
  ];

  timer = {
    live: 'live',
    regular: 'regular',
    highlights: 'highlights'
  };

  constructor(private actions$: Actions) {
    this.isDesktop = this.deviceService.isDesktop();
    this.isMobile = this.deviceService.isMobile();
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
  }

  // HOME
  initHome$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SportsbookActions.initHome),
      map(() => SportsbookActions.setHomeHighlighsSelectedSport({ sport: 'soccer' }))
    )
  );

  setHomeHighlighsSelectedSport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SportsbookActions.setHomeHighlighsSelectedSport),
      map((selectedSport) => SportsbookActions.fetchHome(selectedSport))
    )
  );

  fetchHome$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SportsbookActions.fetchHome),
      switchMap((selectedSport) => {
        this.stopAllPollings();
        this.startHomePagePolling();
        const params = {
          countHomeLiveEvents: 5,
          gameTypesHomeLiveEvents: this.globalVars.gameTypes.serialize(this.globalVars.gameTypes.getAll()),
          sportHandle: selectedSport.sport,
          countHighlightsEvents: 5,
          gameTypesHighlightsEvents: this.globalVars.gameTypes.serialize(
            this.globalVars.gameTypes.getSport(selectedSport.sport)
          )
        };
        return this.bufferFacade
          .newAuxBuffer(this.sportService, 'getHomeData', params, this.timer.regular, this.homePolling$)
          .pipe(
            takeWhile((_) => this.homePolling$.getValue()),
            map(([prevHomeData, currentHomeData]) => {
              const updatedCurrentHomeData = this.sportBookService.setHomeData(
                prevHomeData,
                currentHomeData,
                this.isMobile,
                selectedSport.sport
              );
              return SportsbookActions.setHome(updatedCurrentHomeData);
            })
          );
      })
    )
  );

  stopHomePagePolling$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SportsbookActions.stopHomePagePolling),
        tap(() => {
          this.stopPolling(this.homePolling$);
        })
      ),
    { functional: true, dispatch: false }
  );

  // SPORTS COUNTRIES PAGE
  fetchSportCountries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SportsbookActions.fetchSportCountries),
      switchMap((sport: any) =>
        this.sportService.getPaisLigaInfo(sport.NodeId).pipe(
          map((data) => {
            this.stopAllPollings();
            return SportsbookActions.setSportCountries({ ...data, parentId: sport.NodeId });
          })
        )
      )
    )
  );

  // EVENT PAGE
  fetchEventMainCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SportsbookActions.fetchEventMainCategories),
      switchMap((league: any) => {
        const initialEventMainCategories = this.sportBookService.setEventMainCategories(league);
        this.stopAllPollings();
        return this.sportService
          .GetCategoriesByLeague({
            idLeague: league.NodeId,
            sportHandle: league.SportHandle,
            countryCode: league.CountryCode
          })
          .pipe(
            map((eventMarkesCategories: any) => {
              const hasSixPack = models.SixPack.validate(league.SportHandle, league.CountryCode);
              eventMarkesCategories = eventMarkesCategories.map(
                (cat) => new models.C_LeagueCategory(cat.CategoryId, cat.CategoryName, cat.IsRelevant)
              );
              const gameTypes = this.globalVars.gameTypes.getSport(league.SportHandle);

              if (hasSixPack) {
                eventMarkesCategories.unshift(new models.C_LeagueCategory(-1, 'Principal', false));
                eventMarkesCategories.filter((category) => gameTypes.indexOf(parseInt(category.CategoryId)) === -1);
              }
              if (!this.isMobile && !hasSixPack) {
                eventMarkesCategories.unshift(new models.C_LeagueCategory(-1, 'Principal', false));
              }

              if (eventMarkesCategories.length <= 0) {
                eventMarkesCategories.unshift(new models.C_LeagueCategory(-1, 'Principal', false));
              }

              return SportsbookActions.fetchEventGames({
                eventMainCategories: initialEventMainCategories,
                eventMarkesCategories: eventMarkesCategories,
                games: [],
                specialMarkets: null,
                leagueName: league.Name,
                event: new models.C_EventPageLeague(league),
                parentId: league.NodeId,
                selectedEventMainCategory: initialEventMainCategories[0],
                selectedMarketsCategory: eventMarkesCategories[0]
              });
            })
          );
      })
    )
  );

  setEventMarketSelectedCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SportsbookActions.setEventMarketSelectedCategory),
      withLatestFrom(this.store.select('sportsbook')),
      map(([selectedMarketsCategory, sportsbook]) => {
        const eventPageData = sportsbook['eventData'];
        return SportsbookActions.fetchEventGames({
          eventMainCategories: eventPageData.eventMainCategories,
          eventMarkesCategories: eventPageData.eventMarkesCategories,
          games: eventPageData.games,
          specialMarkets: eventPageData.specialMarkets,
          leagueName: eventPageData.leagueName,
          event: eventPageData.event,
          parentId: eventPageData.parentId,
          selectedEventMainCategory: eventPageData.selectedEventMainCategory,
          selectedMarketsCategory
        });
      })
    )
  );

  setEventMainSelectedCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SportsbookActions.setEventMainSelectedCategory),
      withLatestFrom(this.store.select('sportsbook')),
      map(([selectedEventMainCategory, sportsbook]) => {
        const eventPageData = sportsbook['eventData'];
        if (selectedEventMainCategory.index == 1) {
          return SportsbookActions.fetchEventEarlylMarketGames({
            eventMainCategories: eventPageData.eventMainCategories,
            eventMarkesCategories: eventPageData.eventMarkesCategories,
            games: [],
            specialMarkets: null,
            leagueName: eventPageData.leagueName,
            event: eventPageData.event,
            parentId: eventPageData.parentId,
            selectedEventMainCategory,
            selectedMarketsCategory: eventPageData.selectedMarketsCategory
          });
        }
        if (selectedEventMainCategory.index == 2) {
          return SportsbookActions.fetchEventSpecialMarketGames({
            eventMainCategories: eventPageData.eventMainCategories,
            eventMarkesCategories: eventPageData.eventMarkesCategories,
            games: [],
            specialMarkets: null,
            leagueName: eventPageData.leagueName,
            event: eventPageData.event,
            parentId: eventPageData.parentId,
            selectedEventMainCategory,
            selectedMarketsCategory: eventPageData.selectedMarketsCategory
          });
        }
        return SportsbookActions.fetchEventMarketsCategories({
          eventMainCategories: eventPageData.eventMainCategories,
          eventMarkesCategories: eventPageData.eventMarkesCategories,
          games: [],
          specialMarkets: null,
          leagueName: eventPageData.leagueName,
          event: eventPageData.event,
          parentId: eventPageData.parentId,
          selectedEventMainCategory,
          selectedMarketsCategory: eventPageData.selectedMarketsCategory
        });
      })
    )
  );

  fetchEventMarketsCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SportsbookActions.fetchEventMarketsCategories),
      switchMap((eventPageData: any) =>
        this.sportService
          .GetCategoriesByLeague({
            idLeague: eventPageData.event.NodeId,
            sportHandle: eventPageData.event.SportHandle,
            countryCode: eventPageData.event.CountryCode
          })
          .pipe(
            map((eventMarkesCategories: any) => {
              const hasSixPack = models.SixPack.validate(
                eventPageData.event.SportHandle,
                eventPageData.event.CountryCode
              );
              eventMarkesCategories = eventMarkesCategories.map(
                (cat) => new models.C_LeagueCategory(cat.CategoryId, cat.CategoryName, cat.IsRelevant)
              );
              const gameTypes = this.globalVars.gameTypes.getSport(eventPageData.event.SportHandle);

              if (hasSixPack) {
                eventMarkesCategories.unshift(new models.C_LeagueCategory(-1, 'Principal', false));
                eventMarkesCategories.filter((category) => gameTypes.indexOf(parseInt(category.CategoryId)) === -1);
              }
              if (!this.isMobile && !hasSixPack) {
                eventMarkesCategories.unshift(new models.C_LeagueCategory(-1, 'Principal', false));
              }

              if (eventMarkesCategories.length <= 0) {
                eventMarkesCategories.unshift(new models.C_LeagueCategory(-1, 'Principal', false));
              }

              return SportsbookActions.fetchEventGames({
                eventMainCategories: eventPageData.eventMainCategories,
                eventMarkesCategories: eventMarkesCategories,
                games: [],
                specialMarkets: null,
                leagueName: eventPageData.leagueName,
                event: new models.C_EventPageLeague(eventPageData.event),
                parentId: eventPageData.parentId,
                selectedEventMainCategory: eventPageData.selectedEventMainCategory,
                selectedMarketsCategory: eventMarkesCategories[0]
              });
            })
          )
      )
    )
  );

  fetchEventSpecialMarketGames$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SportsbookActions.fetchEventSpecialMarketGames),
      switchMap((eventPageData: any) => {
        const params = {
          parentId: eventPageData.selectedEventMainCategory.nodeId,
          sportHandle: eventPageData.event.SportHandle
        };
        this.stopAllPollings();
        return this.sportService.getEvents(params).pipe(
          map((specialMarketEvents) =>
            SportsbookActions.setEventGames({
              eventMainCategories: eventPageData.eventMainCategories,
              eventMarkesCategories: [],
              games: specialMarketEvents,
              specialMarkets: null,
              leagueName: eventPageData.leagueName,
              event: eventPageData.event,
              parentId: eventPageData.parentId,
              selectedEventMainCategory: eventPageData.selectedEventMainCategory,
              selectedMarketsCategory: eventPageData.selectedMarketsCategory
            })
          )
        );
      })
    )
  );

  fetchEventEarlylMarketGames$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SportsbookActions.fetchEventEarlylMarketGames),
      switchMap((eventPageData: any) => {
        const params = {
          parentId: eventPageData.selectedEventMainCategory.nodeId,
          sportHandle: eventPageData.event.SportHandle
        };
        this.stopAllPollings();
        return this.sportService.getEvents(params).pipe(
          map((earlyMarketEvents) =>
            SportsbookActions.setEventGames({
              eventMainCategories: eventPageData.eventMainCategories,
              eventMarkesCategories: [],
              games: [],
              specialMarkets: earlyMarketEvents.length > 0 ? earlyMarketEvents[0] : null,
              leagueName: eventPageData.leagueName,
              event: eventPageData.event,
              parentId: eventPageData.parentId,
              selectedEventMainCategory: eventPageData.selectedEventMainCategory,
              selectedMarketsCategory: eventPageData.selectedMarketsCategory
            })
          )
        );
      })
    )
  );

  fetchEventGames$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SportsbookActions.fetchEventGames),
      switchMap((eventPageData: any) => {
        const params = {
          parentId: eventPageData.parentId,
          category: eventPageData.selectedMarketsCategory.CategoryId,
          sportHandle: eventPageData.event.SportHandle
        };
        this.startEventPagePolling();
        return this.bufferFacade
          .newAuxBuffer(this.sportService, 'getEventsCountryBuffer', params, this.timer.regular, this.eventPolling$)
          .pipe(
            map(([prev, current]) => {
              const gamesData = this.sportBookService.mapEvents(
                prev,
                current,
                eventPageData.selectedMarketsCategory.CategoryId,
                eventPageData.event
              );
              return SportsbookActions.setEventGames({
                eventMainCategories: eventPageData.eventMainCategories,
                eventMarkesCategories: eventPageData.eventMarkesCategories,
                games: gamesData.leagueMarkets,
                specialMarkets: eventPageData.specialMarkets,
                leagueName: eventPageData.leagueName,
                event: { ...eventPageData.event, marketNames: gamesData.marketNames },
                parentId: eventPageData.parentId,
                selectedEventMainCategory: eventPageData.selectedEventMainCategory,
                selectedMarketsCategory: eventPageData.selectedMarketsCategory
              });
            })
          );
      })
    )
  );

  // MARKET PAGE
  fetchMarketCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SportsbookActions.fetchMarketCategories),
      switchMap((marketData: any) =>
        this.sportService.getMarketCategories(marketData.NodeId).pipe(
          map((categoriesData: any) => {
            const categories = this.sportBookService.setCategories(categoriesData);
            this.stopAllPollings();
            return SportsbookActions.fetchMarkets({
              parentId: marketData.NodeId,
              leagueName: marketData.LeagueName,
              market: marketData,
              categories: categories,
              selectedCategory: categories[0],
              marketBets: [],
              betsenseData: null
            });
          })
        )
      )
    )
  );

  setMarketSelectedCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SportsbookActions.setMarketSelectedCategory),
      withLatestFrom(this.store.select('sportsbook')),
      map(([selectedCategory, sportsbook]) => {
        const marketPageData = sportsbook['marketData'];
        return SportsbookActions.fetchMarkets({
          parentId: marketPageData.parentId,
          leagueName: marketPageData.leagueName,
          market: marketPageData.market,
          categories: marketPageData.categories,
          marketBets: marketPageData.marketBets,
          selectedCategory,
          betsenseData: null
        });
      })
    )
  );

  fetchMarkets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SportsbookActions.fetchMarkets),
      switchMap((marketPageData: any) =>
        this.sportService
          .getMarketGamesByCategory(
            marketPageData.parentId,
            marketPageData.selectedCategory.CategoryId,
            marketPageData.leagueName
          )
          .pipe(
            map((marketBetsData: any) => {
              const marketBets = this.sportBookService.setMarketBets(marketBetsData, marketPageData);
              const currentMarket = {
                ...marketPageData,
                marketBets,
                betsenseData: null
              };
              this.sportsbookFacade.fetchMarketBetsense(currentMarket);
              return SportsbookActions.setMarkets({
                categories: marketPageData.categories,
                parentId: marketPageData.parentId,
                leagueName: marketPageData.leagueName,
                market: marketPageData.market,
                selectedCategory: marketPageData.selectedCategory,
                marketBets,
                betsenseData: null
              });
            })
          )
      )
    )
  );

  fetchMarketBetsense$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SportsbookActions.fetchMarketBetsense),
      switchMap((currentMarket) => {
        const marketPageDataNodeId = currentMarket?.market?.NodeId;
        this.startMarketPagePolling();
        return this.bufferFacade
          .newAuxBuffer(
            this.sportService,
            'getBetsenseData',
            marketPageDataNodeId,
            this.timer.regular,
            this.marketPolling$
          )
          .pipe(
            map(([prev, current]) =>
              SportsbookActions.setMarkets({
                ...currentMarket,
                betsenseData: current.response.results
              })
            )
          );
      })
    )
  );

  // TODAY PAGE
  fetchTodayCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SportsbookActions.fetchTodayCategories),
      withLatestFrom(this.store.select('sportsbook')),
      map(([data, sportsbook]) => {
        const todayData = sportsbook['todayData'];
        const nextFiveDays = this.sportBookService.getNextFiveDays();
        this.stopAllPollings();
        return SportsbookActions.fetchTodaysDateSportLeagues({
          ...todayData,
          fiveDays: nextFiveDays,
          selectedDay: 0,
          sportsEvents: []
        });
      })
    )
  );

  fetchTodaysDateSportLeagues$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SportsbookActions.fetchTodaysDateSportLeagues),
      switchMap((todayPageData) =>
        this.sportService.getCountriesByDate(todayPageData.selectedSport.CategoryId, todayPageData.selectedDay).pipe(
          map((countriesByDateData: any) => {
            const sportsEvents: models.C_TodayLeagues[] = countriesByDateData.flatMap((country) =>
              country.Leagues.map((league) => ({
                ...league,
                CountryCode: country.CountryCode
              }))
            );
            return SportsbookActions.setToday({
              fiveDays: todayPageData.fiveDays,
              selectedDay: todayPageData.selectedDay,
              selectedSport: todayPageData.selectedSport,
              sportsFilters: todayPageData.sportsFilters,
              sportsEvents
            });
          })
        )
      )
    )
  );

  fetchTodayLeagueEvents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SportsbookActions.fetchTodayLeagueEvents),
      withLatestFrom(this.store.select('sportsbook')),
      switchMap(([selectedLeague, sportsbook]) => {
        const todayPageData = sportsbook['todayData'];
        const params = {
          parentId: selectedLeague.NodeId,
          dayDifference: todayPageData.selectedDay,
          sportHandle: todayPageData.selectedSport.CategoryId
        };
        this.startTodayPagePolling();
        return this.bufferFacade
          .newAuxBuffer(this.sportService, 'getEventsByDate', params, this.timer.regular, this.todayPolling$)
          .pipe(
            map(([prev, current]) => {
              const sportsEvents = this.sportBookService.setTodayLeagueEvents(
                prev,
                current,
                selectedLeague,
                todayPageData.sportsEvents
              );
              return SportsbookActions.setToday({
                fiveDays: todayPageData.fiveDays,
                selectedDay: todayPageData.selectedDay,
                selectedSport: todayPageData.selectedSport,
                sportsFilters: todayPageData.sportsFilters,
                sportsEvents
              });
            })
          );
      })
    )
  );

  setTodaySelectedDay$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SportsbookActions.setTodaySelectedDay),
      withLatestFrom(this.store.select('sportsbook')),
      map(([selectedDay, sportsbook]) => {
        const todayPageData = sportsbook['todayData'];
        this.stopAllPollings();
        return SportsbookActions.fetchTodaysDateSportLeagues({
          fiveDays: todayPageData.fiveDays,
          selectedDay: selectedDay.selectedDay,
          selectedSport: todayPageData.selectedSport,
          sportsFilters: todayPageData.sportsFilters,
          sportsEvents: []
        });
      })
    )
  );

  setTodaySelectedSport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SportsbookActions.setTodaySelectedSport),
      withLatestFrom(this.store.select('sportsbook')),
      map(([selectedSport, sportsbook]) => {
        const todayPageData = sportsbook['todayData'];
        this.stopAllPollings();
        return SportsbookActions.fetchTodaysDateSportLeagues({
          fiveDays: todayPageData.fiveDays,
          selectedDay: todayPageData.selectedDay,
          selectedSport,
          sportsFilters: todayPageData.sportsFilters,
          sportsEvents: []
        });
      })
    )
  );

  // RACE
  fetchRace$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SportsbookActions.fetchRace),
      withLatestFrom(this.store.select('sportsbook')),
      switchMap(([raceData, sportsbook]) => {
        const racePageData = sportsbook['raceData'];
        const endOfToday = raceData.endOfToday;
        let raceSportHandle = raceData.raceType;
        if (raceSportHandle == '') raceSportHandle = 'horse_racing';
        this.stopAllPollings();
        return this.sportService.getRacesInfo(raceSportHandle).pipe(
          map((data) => {
            const nextRaces = data.nextRaces.map(
              (race: models.C_Horses) =>
                (race = new models.C_Horses(
                  race.Name,
                  race.NodeId,
                  race.ParentNodeId,
                  race.EventNodeTypeId,
                  race.Priority,
                  race.SportHandle,
                  race.ChildrenCount,
                  race.CountryCode,
                  race.IsActive,
                  race.OddsAvailability,
                  race.PaddockId,
                  race.StartDate,
                  race.GameTypeId,
                  race.Races,
                  race.Locked,
                  race.StreamingEnabled
                ))
            );
            const { today, tomorrow } = this.racePageUtils.setPaddocksByDate(data.racesbyPaddock, endOfToday);
            return SportsbookActions.setRace({
              filters: racePageData.filters,
              selectedFilter: racePageData.selectedFilter ? racePageData.selectedFilter : racePageData.filters[0],
              next: nextRaces,
              today,
              tomorrow
            });
          })
        );
      })
    )
  );

  // RACE MARKET
  initRaceMarket$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SportsbookActions.initRaceMarket),
      withLatestFrom(this.store.select('sportsbook')),
      switchMap(([raceMarketParms, sportsbook]) => {
        const raceMarketPageData = sportsbook['raceMarketData'];
        this.stopAllPollings();
        return this.sportRaceService.getNextRaces(raceMarketParms.SportHandle).pipe(
          map((data) => {
            const dropListEventSelected = data.find((i) => i.NodeId == raceMarketParms.NodeId);
            return SportsbookActions.fetchRaceMarket({
              filters: raceMarketPageData.filters,
              selectedFilter: raceMarketPageData.selectedFilter
                ? raceMarketPageData.selectedFilter
                : raceMarketPageData.filters[0],
              dropListEvents: data,
              dropListEventSelected: dropListEventSelected ? dropListEventSelected : raceMarketParms,
              participants: []
            });
          })
        );
      })
    )
  );

  setRaceMarketSelectedRace$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SportsbookActions.setRaceMarketSelectedRace),
      withLatestFrom(this.store.select('sportsbook')),
      map(([raceMarketParms, sportsbook]) => {
        const raceMarketPageData = sportsbook['raceMarketData'];
        const dropListEventSelected = raceMarketPageData.dropListEvents.find((i) => i.NodeId == raceMarketParms.NodeId);
        return SportsbookActions.fetchRaceMarket({
          filters: raceMarketPageData.filters,
          selectedFilter: null,
          dropListEvents: raceMarketPageData.dropListEvents,
          dropListEventSelected,
          participants: []
        });
      })
    )
  );

  fetchRaceMarket$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SportsbookActions.fetchRaceMarket),
      switchMap((raceMarketData) => {
        this.startRaceMarketPagePolling();
        const raceMarketParams = {
          paddockId: raceMarketData.dropListEventSelected.PaddockId,
          raceId: raceMarketData.dropListEventSelected.NodeId,
          sport: raceMarketData.dropListEventSelected.SportHandle
        };
        return this.bufferFacade
          .newAuxBuffer(
            this.sportRaceService,
            'getRaceMarketData',
            raceMarketParams,
            this.timer.live,
            this.raceMarketPolling$
          )
          .pipe(
            map(([prev, current]) => {
              const dropListEventSelected = current.dropListEvents.find((i) => i.NodeId == raceMarketParams.raceId);
              if (!current.participants) this.stopAllPollings();
              return SportsbookActions.setRaceMarket({
                filters: raceMarketData.filters,
                selectedFilter: raceMarketData.selectedFilter,
                dropListEvents: raceMarketData.dropListEvents,
                dropListEventSelected,
                participants: current.participants
              });
            })
          );
      })
    )
  );

  // LIVE
  initLive$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SportsbookActions.initLive),
      switchMap(() => {
        this.stopAllPollings();
        return this.sportLiveService.getLiveEventsBySportHandleUrl().pipe(
          map((data) => {
            const navSports = this.livePageUtils.setSportsNav(data);
            const categories = this.livePageUtils.getCategories(navSports);
            const sport = this.livePageUtils.getEvents(navSports.selectedSportsNav, null, categories);
            const isFinish = !categories.categories.length ? FLOW_STATUS.FINISH_STATUS : FLOW_STATUS.POLLING_STATUS;
            return SportsbookActions.setLiveAndFetch({
              sportsNav: navSports.sportsNav,
              selectedSportsNav: navSports.selectedSportsNav,
              categories: categories.categories,
              selectedCategory: categories.selectedCategory,
              sport,
              liveState: isFinish
            });
          })
        );
      })
    )
  );

  setLiveAndFetch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SportsbookActions.setLiveAndFetch),
      map((livePageData) =>
        SportsbookActions.fetchLive({
          sportsNav: livePageData.sportsNav,
          selectedSportsNav: livePageData.selectedSportsNav,
          categories: livePageData.categories,
          selectedCategory: livePageData.selectedCategory,
          sport: livePageData.sport
        })
      )
    )
  );

  setSelectedSportsNav$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SportsbookActions.setSelectedSportsNav),
      withLatestFrom(this.store.select('sportsbook')),
      map(([newSelectedSportsNav, sportsbook]) => {
        const livePageData = sportsbook['liveData'];
        const selectedSportsNav = livePageData.sportsNav.find((i) => i.SportHandle == newSelectedSportsNav.SportHandle);
        return SportsbookActions.fetchLive({
          ...livePageData,
          selectedSportsNav
        });
      })
    )
  );

  fetchLive$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SportsbookActions.fetchLive),
      switchMap(({ type, ...payload }) => {
        this.stopAllPollings();
        this.startLivePagePolling();
        return this.bufferFacade
          .newAuxBuffer(
            this.sportLiveService,
            'getLiveEventsBySportHandleUrl',
            payload.selectedSportsNav.SportHandle,
            this.timer.live,
            this.livePolling$
          )
          .pipe(
            withLatestFrom(this.store.select('sportsbook')),
            takeWhile((_) => this.livePolling$.getValue()),
            map(([[prev, current], sportsbook]) => {
              const lastLivePageData = sportsbook['liveData'];
              const navSports = this.livePageUtils.setSportsNav(current, lastLivePageData.selectedSportsNav);
              const categories = this.livePageUtils.getCategories(navSports);
              const sport = this.livePageUtils.getEvents(
                navSports.selectedSportsNav,
                lastLivePageData.selectedSportsNav,
                categories
              );
              const isFinish = !categories.categories.length ? FLOW_STATUS.FINISH_STATUS : FLOW_STATUS.POLLING_STATUS;
              return SportsbookActions.setLive({
                sportsNav: navSports.sportsNav,
                selectedSportsNav: navSports.selectedSportsNav,
                categories: categories.categories,
                selectedCategory: categories.selectedCategory,
                liveState: isFinish,
                sport
              });
            })
          );
      })
    )
  );

  stopLivePagePolling$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SportsbookActions.stopLivePagePolling),
        tap(() => {
          this.stopPolling(this.livePolling$);
        })
      ),
    { functional: true, dispatch: false }
  );

  // LIVE MARKET
  initLiveMarket$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SportsbookActions.initLiveMarket),
      map((payload) => {
        this.stopAllPollings();
        this.startLiveMarketPagePolling();
        const { market, isBetsenseEnable } = payload;
        return SportsbookActions.fetchLiveMarket({
          parentId: market.NodeId,
          categoryInfoId: '99',
          leagueName: market.LeagueName,
          market,
          isBetsenseEnable
        });
      })
    )
  );

  fetchLiveMarket$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SportsbookActions.fetchLiveMarket),
      switchMap(({ type, ...payload }) => {
        const { isBetsenseEnable, ...fetchMarketLiveParams } = payload;
        const params = {
          parentid: fetchMarketLiveParams.parentId,
          categoryInfoId: fetchMarketLiveParams.categoryInfoId,
          leagueName: fetchMarketLiveParams.leagueName
        };
        if (isBetsenseEnable) this.sportsbookFacade.fetchLiveMarketBetsense({ fetchMarketLiveParams });
        return this.bufferFacade
          .newAuxBuffer(
            this.sportLiveMarketService,
            'getGamesLiveEvent2',
            params,
            this.timer.live,
            this.liveMarketPolling$
          )
          .pipe(
            withLatestFrom(this.store.select('sportsbook')),
            takeWhile((_) => this.liveMarketPolling$.getValue()),
            map(([[prevLiveMarketData, currentLiveMarketData], sportsbook]) => {
              const liveMarketPageData = sportsbook['liveMarketData'];
              const { categories, selectedCategory, processedCurrentLiveMarketData } =
                this.sportBookUtils.processLiveMarketDataResponse(
                  currentLiveMarketData,
                  prevLiveMarketData,
                  fetchMarketLiveParams,
                  liveMarketPageData
                );
              return SportsbookActions.setLiveMarket({
                marketLiveState: FLOW_STATUS.POLLING_STATUS,
                market: fetchMarketLiveParams.market,
                prevLiveMarketData: prevLiveMarketData,
                currentLiveMarketData: processedCurrentLiveMarketData,
                categories: categories,
                selectedCategory
              });
            }),
            catchError((e) => {
              this.sportsbookFacade.setLiveMarket({
                marketLiveState: FLOW_STATUS.FINISH_STATUS
              });
              return [];
            })
          );
      })
    )
  );

  fetchLiveMarketBetsense$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SportsbookActions.fetchLiveMarketBetsense),
      switchMap(({ type, fetchMarketLiveParams }) => {
        const { market } = fetchMarketLiveParams;
        const marketLivePageDataNodeId = market?.NodeId;
        return this.bufferFacade
          .newAuxBuffer(
            this.sportLiveMarketService,
            'getBetsenseData',
            marketLivePageDataNodeId,
            this.timer.regular,
            this.liveMarketPolling$
          )
          .pipe(
            switchMap(([prev, currentBetsenseData]) => {
              const nodeIdList = this.sportBookUtils.getNodeIdList(currentBetsenseData.response.results);
              const params = {
                parentid: fetchMarketLiveParams.parentId,
                categoryInfoId: fetchMarketLiveParams.categoryInfoId,
                leagueName: fetchMarketLiveParams.leagueName,
                nodeIdList
              };
              return this.sportLiveMarketService.getGamesLiveEvent2(params).pipe(
                takeWhile((_) => this.liveMarketPolling$.getValue()),
                map((currentLiveMarketData) => {
                  const { resultOddNodes } = currentLiveMarketData;
                  const updatedBetsenseData = this.sportBookUtils.updateBetsenseData(
                    resultOddNodes,
                    currentBetsenseData.response.results
                  );
                  return SportsbookActions.setLiveMarketBetsense({
                    betsenseData: updatedBetsenseData
                  });
                })
              );
            })
          );
      })
    )
  );

  setLiveMarketSelectedCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SportsbookActions.setLiveMarketSelectedCategory),
      withLatestFrom(this.store.select('sportsbook')),
      map(([{ type, ...payload }, sportsbook]) => {
        const liveMarketPageData = sportsbook['liveMarketData'];
        return SportsbookActions.fetchLiveMarket({
          parentId: liveMarketPageData.market.NodeId,
          categoryInfoId: payload.selectedCategory.CategoryId,
          leagueName: liveMarketPageData.market.LeagueName,
          market: liveMarketPageData.market
        });
      })
    )
  );

  stopLiveMarketPagePolling$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SportsbookActions.stopLiveMarketPagePolling),
        tap(() => {
          this.stopPolling(this.liveMarketPolling$);
        })
      ),
    { functional: true, dispatch: false }
  );

  stopAllPollings$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SportsbookActions.stopAllStorePollings),
        tap(() => this.stopAllPollings())
      ),
    { functional: true, dispatch: false }
  );

  // POLLING METHODS
  startHomePagePolling() {
    this.stopAllPollings();
    this.homePolling$.next(true);
  }

  startEventPagePolling() {
    this.stopAllPollings();
    this.eventPolling$.next(true);
  }

  startMarketPagePolling() {
    this.stopAllPollings();
    this.marketPolling$.next(true);
  }

  startTodayPagePolling() {
    this.stopAllPollings();
    this.todayPolling$.next(true);
  }

  startRaceMarketPagePolling() {
    this.stopAllPollings();
    this.raceMarketPolling$.next(true);
  }

  startLivePagePolling() {
    this.stopAllPollings();
    this.livePolling$.next(true);
  }

  startLiveMarketPagePolling() {
    this.stopAllPollings();
    this.liveMarketPolling$.next(true);
  }

  stopAllPollings() {
    this.pollings$.forEach((polling) => this.stopPolling(polling));
  }

  stopPolling(pollingSubject: BehaviorSubject<boolean>) {
    pollingSubject.next(false);
    pollingSubject.complete();
  }
}
