import { Directive, ElementRef, Renderer2, Input, inject, AfterViewInit } from '@angular/core';
import { DisplayType } from '@models/sportbook/display-tipe.enum';
import { C_Result } from '@models/C_Result';

@Directive({
  standalone: true,
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[bet-display]'
})
export class BetDisplayDirective implements AfterViewInit {
  @Input() desktop: boolean;
  @Input() betType: string;
  @Input() game: any;

  _element = inject(ElementRef);
  _renderer = inject(Renderer2);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}

  ngAfterViewInit() {
    this.setDisplay();
  }

  private setDisplay() {
    if (this.isFullWidth()) {
      return;
    }
    let css: string[] = [];
    switch (this.betType) {
      case DisplayType.FOURBUTTONLIST:
        css = ['is-wrap', 'has-four-buttons'];
        break;
      case DisplayType.GOALSCORER:
      case DisplayType.THREEBUTTONLINE:
      case DisplayType.THREEWAY:
      case DisplayType.SCORE:
      case DisplayType.THREEBUTTONLIST:
      case DisplayType.SIXBUTTONLIST:
        css = ['is-wrap', 'has-three-buttons'];
        break;
      case DisplayType.OUTRIGHTLIST:
        const buttons = this.desktop ? 'has-four-buttons' : 'has-two-buttons';
        css = ['is-wrap', buttons];
        break;
      case DisplayType.TWOWAY:
      case DisplayType.TWOBUTTONLINE:
      case DisplayType.TWOBUTTONLIST:
      case DisplayType.SCORETWINCAST:
      default:
        css = ['is-wrap', 'has-two-buttons'];
        break;
      case DisplayType.DESKTOPMARKETS:
        css = ['is-wrap'];
        break;
    }

    css.forEach((_class) => this._renderer.addClass(this._element.nativeElement, _class));
    this._renderer.setAttribute(this._element.nativeElement, 'data-type', this.betType);
  }

  private isFullWidth() {
    if (!this.game) {
      return false;
    }
    const result = this.game.hasOwnProperty.call('Results');
    const grouped = this.game.hasOwnProperty.call('GroupResults');

    if (result && this.game.Results) {
      return this.specialCoutesEvent(this.game) === 1;
    }

    if (grouped) {
      return false;
    }
  }

  private specialCoutesEvent(game) {
    const auxGame = { ...game };
    if (auxGame.Results.length === 1) return auxGame.Results.length;
    auxGame.Results.map((result: C_Result, index: number) => {
      if (!result.ParentNodeId) {
        auxGame.Results.splice(index, 1);
      }
    });
    return auxGame.Results.length;
  }
}
