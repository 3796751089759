import { Directive, HostListener, ElementRef } from '@angular/core';
// import { InAppBrowser, InAppBrowserOptions } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { Browser } from '@capacitor/browser';
import { Platform } from '@ionic/angular';

/**
 * Generated class for the ExternalLinkDirective directive.
 *
 * See https://angular.io/api/core/Directive for more info on Angular
 * Directives.
 */
@Directive({
  selector: '[external-link]' // Attribute selector
})
export class ExternalLinkDirective {
  @HostListener('click', ['$event.target']) onClick($event) {
    const element = this.element.nativeElement;

    if (this.platform.is('cordova')) {
      this.openLinkInInAppBrowser(element.href);
      return false;
    } else {
      //does not modify the behaviour
      return true;
    }
  }

  constructor(
    private element: ElementRef,
    // private iab: InAppBrowser,
    private platform: Platform
  ) {}

  //#region
  // openLinkInInAppBrowser(link) {
  //   let target: string = "_system";
  //   const options: InAppBrowserOptions = {
  //     location: "yes",
  //     hidden: "no",
  //     closebuttoncaption: "Cerrar",
  //     closebuttoncolor: "#ffffff",
  //     footer: "no",
  //     footercolor: "#000000",
  //     hardwareback: "yes",
  //     hidenavigationbuttons: "no",
  //     hideurlbar: "yes",
  //     navigationbuttoncolor: "#ffffff",
  //     toolbarcolor: "#000000",
  //     zoom: "yes",
  //     mediaPlaybackRequiresUserAction: "no",
  //     shouldPauseOnSuspend: "yes",
  //     useWideViewPort: "yes",

  //     toolbar: "yes",
  //     toolbartranslucent: "yes",
  //     enableViewportScale: "yes",
  //     allowInlineMediaPlayback: "yes",
  //     keyboardDisplayRequiresUserAction: "no",
  //     suppressesIncrementalRendering: "no",
  //     presentationstyle: "fullscreen", //pagesheet, formsheet, fullscreen
  //     transitionstyle: "coververtical", //fliphorizontal, crossdissolve, coververtical
  //     toolbarposition: "bottom", //top, bottom
  //     hidespinner: "no"

  //   }
  //   if(link.includes('.pdf')) {
  //     target = '_system';
  //   }

  //   this.iab.create(encodeURI(link), target, options);
  // }
  //#endregion

  public async openLinkInInAppBrowser(link: string) {
    let target: string = '_system';
    const options = {
      toolbarColor: '#000000',
      windowColor: '#000000',
      backButtonCanClose: true,
      backButtonStyle: 'close',
      navigationBarColor: '#000000',
      showTitle: true
    };

    if (link.includes('.pdf')) {
      target = '_system';
    }

    try {
      await Browser.open({ url: encodeURI(link), ...options });
    } catch (error) {
      console.error('Error opening browser:', error);
    }
  }
}
