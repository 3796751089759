/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable */
import { Injectable, inject } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { UtilsCashierService } from '../UtilsCashierService';
import { map, catchError } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { CancelPaymentActionResult } from '../../models/C_Transactions';
import { EventsService } from '../EventsService';
import { GlobalVarsModel } from '../../models/ModelGlobalsVar';
import { NewBaseService } from '../newBase.service';
import { UserService } from '../UserService';
import { BalancesService } from '../BalancesService';
import { Utils } from '../../utils/Utils';
import { ErrorService } from '../ErrorService';

@Injectable({ providedIn: 'root' })
export class PaymentsCashierService {
  private message = new BehaviorSubject<string>('close modal');
  public customMessage = this.message.asObservable();

  swormAcepted = false;
  globalVars!: GlobalVarsModel;

  errorService = inject(ErrorService);
  newBaseService = inject(NewBaseService);
  alertCtrl = inject(AlertController);
  translate = inject(TranslateService);
  userService = inject(UserService);
  utilsCsService = inject(UtilsCashierService);
  balancesService = inject(BalancesService);
  utils = inject(Utils);
  events = inject(EventsService);

  constructor() {
    this.newBaseService.getVars.subscribe((data) => {
      this.globalVars = data;
    });
  }

  public changeMessage(msg: string): void {
    this.message.next(msg);
  }

  cancelPendingPayment(payment: any) {
    this.utils.loader();
    const { Tipo, Cantidad, Referencia } = payment;

    return this.userService.cancelPendingPayments(this.globalVars.user.username, Referencia, false).pipe(
      map(
        (data) => {
          const paymentType = Tipo ? Tipo : 'Cobro';
          if (!!this.globalVars.UrlTransactionsApiBase && this.globalVars.TRANSAPI_CRUDoverTransactions) {
            const { success, errCode, errorParameters, errMessage } = <CancelPaymentActionResult>data;
            if (success) {
              this.utils.closeLoader();
              this.events.publish('badges:refresh');
              this.balancesService
                .refreshBalance(true)
                .subscribe(() => this.utils.showAlertCancelPayment(Cantidad, paymentType, null));
            } else {
              this.utils.closeLoader();
              this.errorService.searchErrorCodeWithParams(errCode.toString(), errorParameters).subscribe(
                (data2) => {
                  this.utils.showError(data2, null);
                },
                (err) => {
                  this.utils.showError(errMessage, null);
                }
              );
            }
          } else {
            if (data.status == 'ko') {
              this.utils.closeLoader();
              this.errorService.searchErrorCode('error' + data.errCode).subscribe(
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                (data) => {
                  this.utilsCsService.showInfoAlert(
                    this.translate.instant('tFail'),
                    this.translate.instant('m_menu_noLater')
                  );
                },
                (err) => {
                  this.utilsCsService.showInfoAlert(
                    this.translate.instant('tFail'),
                    this.translate.instant('m_knk_canCobKo')
                  );
                }
              );
            } else {
              this.utils.closeLoader();
              this.events.publish('badges:refresh');
              this.balancesService
                .refreshBalance(true)
                .subscribe(() => this.utils.showAlertCancelPayment(payment.Cantidad, paymentType, null));
            }
          }
          return data;
        },
        (err) => {
          this.utils.closeLoader();
          this.utils.showError(err);
        }
      ),
      catchError((err) => {
        this.utils.closeLoader();
        this.utils.showError(err);
        return err;
      })
    );
  }

  cavaSwormAcepted() {
    this.swormAcepted = true;
  }

  cavaSwormCancel() {
    this.swormAcepted = false;
  }

  checkSworm() {
    return this.swormAcepted;
  }
}
