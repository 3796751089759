import { ModalController, IonicModule } from '@ionic/angular';
import { Component, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Subscription, of, switchMap } from 'rxjs';
import { Store } from '@ngrx/store';
import { TranslateService, TranslateModule } from '@ngx-translate/core';

// services
import { TicketService } from '../../../providers/TicketService';
import { TrackingService } from '../../../providers/TrackingService';

// models
import { C_FreeBetTicket } from '../../../models/C_FreeBetTicket';
import { GlobalVarsModel } from '../../../models/ModelGlobalsVar';
import { LicenseTypes } from '../../../models/MasterData';

import { ticketState } from '../../../models/sports/store/reducers/ticket.reducers';

// pipes
import { ParseMoney } from '../../../pipes/parseMoney';
import { FormatOdd } from '../../../pipes/formatOdd.pipe';

// components
import { CodereStyleMoneyComponet } from '../../../components/sports/codere-style-money/codere-style-money';

// other
import { Utils } from '../../../utils/Utils';
import { NewBaseService } from '../../../providers/newBase.service';
import { NewTicketFacade } from '@models/sports/store/facades/ticket.facade';

@Component({
  selector: 'ticket-freebets-page',
  templateUrl: './ticket-freebets.html',
  standalone: true,
  imports: [CommonModule, FormsModule, IonicModule, TranslateModule, CodereStyleMoneyComponet, ParseMoney, FormatOdd],
  styleUrls: ['./ticket-freebets.scss']
})
export class TicketFreebetsPage implements OnInit {
  lstFreebets: Array<C_FreeBetTicket>;
  availableDepositFbs: any;
  inputFB: string;
  ticketFreebetSubscription: Subscription;
  ticketFreebet$: Subscription;

  globalvars: GlobalVarsModel;
  LicenseTypes = LicenseTypes;
  trackingService = inject(TrackingService);
  ticketService = inject(TicketService);
  translate = inject(TranslateService);
  newTicketFacade: any;
  store = inject(Store<ticketState>);
  modalCtrl = inject(ModalController);
  utils = inject(Utils);
  newBaseService = inject(NewBaseService);

  constructor() {
    this.newBaseService.getVars
      .pipe(
        switchMap((data: GlobalVarsModel) => {
          this.globalvars = data;
          return of(null);
        })
      )
      .subscribe();
    this.newTicketFacade = new NewTicketFacade();
  }

  ngOnInit(): void {
    this.bindFreebetsActive();
  }

  sendInputFB() {
    if (this.inputFB) {
      this.freeBetsAdd(new C_FreeBetTicket(null, null, this.inputFB, 0, '', true, '', false), true);
    }
  }

  close(isXClosed = true) {
    this.ticketFreebetSubscription.unsubscribe();
    this.modalCtrl.dismiss();
  }

  bindFreebetsActive() {
    this.newTicketFacade.updateFreeBets();
    this.ticketFreebetSubscription = this.store.select('ticket').subscribe((ticketSta: any) => {
      this.lstFreebets = ticketSta.freeBets;
      if (ticketSta.ticket.hasFreebet) {
        this.trackingService.trackEvent(['freeBetsAddsTicket', '', '', 'Ticket Freebet a\u00F1adido', 'event']);
        this.close(false);
      }
    });
  }

  freeBetsAdd(freebet: C_FreeBetTicket, personalized: boolean) {
    this.newTicketFacade.freeBetsAdd(freebet, personalized);
    this.close(false);
  }

  isAR() {
    return (
      LicenseTypes.ArgentinaCaba ||
      LicenseTypes.ArgentinaCordoba ||
      LicenseTypes.ArgentinaMendoza ||
      LicenseTypes.ArgentinaPBA
    );
  }
}
