import { Component, EventEmitter, Output, Input, HostBinding, OnChanges, SimpleChanges, inject } from '@angular/core';
import { Device } from '@capacitor/device';
import { CommonModule } from '@angular/common';

// PIPES
import { ParseStakeMoney } from '@pipes/parseStakeMoney';

// SERVICES
import { UtilsCashierService } from '@providers/UtilsCashierService';
import { NewBaseService } from '@providers/newBase.service';

// MODELS
import { GlobalVarsModel } from '@models/ModelGlobalsVar';

@Component({
  selector: 'sports-keyboard',
  templateUrl: './sports-keyboard.component.html',
  styleUrls: ['./sports-keyboard.component.scss'],
  standalone: true,
  imports: [ParseStakeMoney, CommonModule]
})
export class SportsKeyboardComponent implements OnChanges {
  @Input() active: boolean;
  @Input() disabledDot: boolean;
  @Input() buttons: any[];
  @Input() amountAux = '';
  @Input() stakeLimit: number;
  @Output() closeKeyBoard: EventEmitter<any> = new EventEmitter();
  @Output() press: EventEmitter<any> = new EventEmitter();

  minImport: number;
  previousAmount;
  importsPredefined = [];
  iphoneDevices: string[] = [];

  utilsCashierService = inject(UtilsCashierService);
  globalVars: GlobalVarsModel;
  newBaseService = inject(NewBaseService);

  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });

    this.iphoneDevices = [
      'iPhone10,3',
      'iPhone10,6',
      'iPhone11,8',
      'iPhone11,2',
      'iPhone11,6',
      'iPhone11,4',
      'iPhone12,1',
      'iPhone12,3',
      'iPhone12,5',
      'iPhone12,8',
      'iPhone13,1',
      'iPhone13,2',
      'iPhone13,3',
      'iPhone13,4',
      'iPhone14,2',
      'iPhone14,3',
      'iPhone14,4',
      'iPhone14,5'
    ];
  }

  ionViewWillEnter() {
    this.amountAux = '';
  }

  @HostBinding('class.custom-keyboard--iphone-new-generation')
  async isPhoneNewGeneration() {
    const info = await Device.getInfo();
    return this.globalVars.isNative && this.active && this.iphoneDevices.indexOf(info.model) > -1;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.active) {
      this.amountAux = '';
    }
  }

  btCancel_Click(amount?: number) {
    this.amountAux = '';
    this.close();
  }
  close(amount?) {
    this.utilsCashierService.closeTrueLauerKeyboard();
    this.closeKeyBoard.emit();
  }
  btOk_Click() {
    let cont: number = 0;
    for (let i: number = 0; i < this.amountAux.length; i++) {
      if (this.amountAux[i] == '.') cont++;
    }

    if (isNaN(parseFloat(this.amountAux)) || cont > 1) {
      this.close();
    } else {
      this.closeKeyBoard.emit(this.amountAux);
      this.amountAux = '';
    }
  }

  btKeyBoard_Click(key: string, custom: boolean = false) {
    this.amountAux = custom ? '' : this.amountAux;
    if (+this.amountAux > this.stakeLimit) {
      if (key == 'del') this.amountAux = this.amountAux.substr(0, this.amountAux.length - 1);
    } else {
      switch (key) {
        case ',':
          if (this.amountAux.length != 0 && this.amountAux.length != 6 && this.amountAux.indexOf(',') == -1)
            this.amountAux = this.amountAux + ',';
          if (this.amountAux.length == 0) this.amountAux = '0' + key;
          break;
        case '.':
          if (this.amountAux.length != 0 && this.amountAux.length != 6 && this.amountAux.indexOf('.') == -1)
            this.amountAux = this.amountAux + '.';
          if (this.amountAux.length == 0) this.amountAux = '0' + key;
          break;
        case 'del':
          if (this.amountAux.length > 0) {
            this.amountAux = this.amountAux.substr(0, this.amountAux.length - 1);
          }
          break;
        default:
          const commaUse: boolean = this.amountAux.indexOf(',') != -1;
          const dotUse: boolean = this.amountAux.indexOf('.') != -1;
          if (!commaUse && !dotUse) {
            this.amountAux = this.amountAux + key;
          } else {
            let decimalSimbol: string = ',';
            if (dotUse) {
              decimalSimbol = '.';
            }
            if (this.amountAux.length - (this.amountAux.indexOf(decimalSimbol) + 1) < 2) {
              this.amountAux = this.amountAux + key;
            }
          }
          this.amountAux = +this.amountAux > this.stakeLimit ? this.stakeLimit.toString() : this.amountAux;
          break;
      }
    }
    if (this.amountAux.length === 0) {
      this.amountAux = '0';
    }
    this.press.emit(this.amountAux);
  }
}
