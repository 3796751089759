/* eslint-disable */
import { IFramePaymentMethodBehaviourResult, PaymentMethodBehaviour, SkipAmountResult } from './PaymentMethodBase';
import { OxxoDepositActionResult } from '@models/C_Transactions';
import { UserService } from '@providers/UserService';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ErrorService } from '@providers/ErrorService';

export class OxxoCashPaymentMethodBehaviour extends PaymentMethodBehaviour {
  constructor(userService: UserService, errorService: ErrorService) {
    super(false, userService, errorService);
    this.documentNumberRequired = true;
  }

  name = 'OXXO';
  image = 'logo-oxxo2';
  track = 'AccessToOxxo';
  isBank = false;
  amountButtons: number[] = [300, 400, 800, 1000];
  minDepositAmount = 100;
  maxDepositAmount = 999999999;
  override amount = 400;

  skipAmountInput(): Observable<SkipAmountResult> {
    return new Observable<SkipAmountResult>();
  }

  paymentMetodExecution(): Observable<IFramePaymentMethodBehaviourResult> {
    return this.userService.validateDepositOxxo('', this.amount, this.documentNumber).pipe(
      map((obj) => {
        return this.map(obj);
      })
    );
  }

  private map(input: OxxoDepositActionResult): IFramePaymentMethodBehaviourResult {
    const result = new IFramePaymentMethodBehaviourResult();
    if (input.success) {
      result.success = true;
      result.iframeType = 'oxxo';
      result.iframeArgs = { type: 'oxxo', url: encodeURI(input.url), msg: false };
    } else {
      result.success = false;
      result.erroMessage = this.errorService.searchErrorCodeWithParams('error' + input.errCode, input.errorParameters);
      result.errorcode = input.errCode;
      result.sendToDocumentVerificationPage = input.errCode == 174 || input.errCode == 2212;
    }
    return result;
  }
}
