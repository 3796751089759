import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IdentificationDocumentTypes } from './MasterData';

export class SelectorItem {
  text: string;
  value: number;
}

// Masterdata for selectors
@Injectable({ providedIn: 'root' })
export class SelectorsMasterData {
  constructor(public translate: TranslateService) {}

  public notResidentDocumentTypes: SelectorItem[] = [
    {
      text: this.translate.instant('NotResidDocumentType0'),
      value: IdentificationDocumentTypes.ID
    },
    {
      text: 'NIE',
      value: IdentificationDocumentTypes.NIE
    },
    {
      text: this.translate.instant('NotResidDocumentType1'),
      value: IdentificationDocumentTypes.LD
    },
    {
      text: this.translate.instant('NotResidDocumentType2'),
      value: IdentificationDocumentTypes.PA
    },
    {
      text: this.translate.instant('NotResidDocumentType4'),
      value: IdentificationDocumentTypes.SS
    }
  ];
}
