/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input, inject } from '@angular/core';
import { AnchorLink } from '../../../../models/footer/anchor-link';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { DeviceService } from '../../../../services/device.service';
import { GlobalVarsModel } from '../../../../models/ModelGlobalsVar';
import { MSO_PATHS } from '../../../../lib/constants/routes';
import { NewBaseService } from '../../../../providers/newBase.service';

@Component({
  standalone: true,
  imports: [CommonModule],
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'codere-footer-socials',
  templateUrl: './codere-footer-socials.html',
  styleUrls: ['./codere-footer-socials.scss']
})
export class CodereFooterSocials {
  @Input() socials: AnchorLink[] = [];
  isDesktop: boolean = false;
  globalVars!: GlobalVarsModel;

  router = inject(Router);
  deviceService = inject(DeviceService);
  newBaseService = inject(NewBaseService);

  constructor() {
    this.isDesktop = this.deviceService.isDesktop();
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
  }

  openEvent($event: any, typeEvent?: string) {
    switch (typeEvent) {
      case 'contactPage':
        this.openContactPage($event);
        break;
    }
  }

  private openContactPage($event: any) {
    this.router.navigate([`/${MSO_PATHS.BASE}/${MSO_PATHS.ContactPage}`], {
      queryParams: { animate: !this.isDesktop }
    });
    $event.preventDefault();
  }
}
