import { Injectable } from '@angular/core';
import { C_Country } from '@models/C_Country';
import { C_Sport } from '@models/C_Sport';
import { SidebarUtils } from '@models/sports/store/storeUtils/sidebar.utils';

@Injectable({ providedIn: 'root' })
export class MenuSportService {
  highlightSports: any;
  sports: C_Sport[] = [];
  mainSidebar: any = {};
  mainSidebarActualNode!: string;

  //última posición del scroll
  lastScrollPosition!: number;

  constructor() {}

  setSports(sports: any[]) {
    this.sports = [];
    sports?.forEach((s) => {
      this.sports.push(
        new C_Sport(
          s.isnew,
          s.Locked,
          s.Name,
          s.NodeId,
          s.ParentNodeId,
          s.Priority,
          s.SportHandle,
          SidebarUtils.showDropdownIcon(s.SportHandle)
        )
      );
    });
    this.setMainSidebar(this.sports);
  }

  setCountries(sportNodeId: string, countries: any[]) {
    const sport = this.sports.find((s) => s.NodeId === sportNodeId);
    if (sport) {
      const countriesAux: any[] = [];
      countries.forEach((c) => {
        countriesAux.push(
          new C_Country(
            c.Races ? c.Races : c.Leagues,
            c.CountryCode,
            c.Name,
            c.NodeId,
            c.ParentNodeId,
            c.Priority,
            c.SportHandle,
            c.ChildrenCount,
            false
          )
        );
      });

      sport.countries.data = [...countriesAux];
    }
  }

  setMainSidebar(sports: any) {
    const sportName: string[] = sports.map((sport: any) => sport.SportHandle);

    sportName.forEach((sportKey) => {
      this.mainSidebar[sportKey] = true;
    });
  }

  toggleSidebarItem(sportHandle: string) {
    this.mainSidebar[sportHandle] = !this.mainSidebar[sportHandle];
  }
}
