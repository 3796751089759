export class RaceBetSlip {
  Id: number;
  OpenPrice: boolean;
  Place: number;
  Type: number;
  SportHandle: string;
  GreyhoundHorses: boolean;

  constructor(id: number, openPrice: boolean, place: number, type: number, sportHandle: string, greyhound: boolean) {
    this.Id = id;
    this.OpenPrice = openPrice;
    this.Place = place;
    this.Type = type;
    this.SportHandle = sportHandle;
    this.GreyhoundHorses = greyhound;
  }
}
