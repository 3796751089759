import * as SidebarActions from "./sidebar.actions";
import { sidebarState } from "./sidebar.reducers";

import { Injectable, inject } from "@angular/core";
import { Store } from "@ngrx/store";
import { C_SportSidebar } from "@sports-models/index";
import { Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class SidebarFacade {
  store = inject(Store<sidebarState>);

  sidebar$: Observable<any>;
  sports: any[];
  highlights: any[];
  highlightsConfig: any[];

  selectedSport: C_SportSidebar = null;

  constructor() {
    this.sidebar$ = this.store.select((state) => state.sidebar);
    this.sidebar$.subscribe((sidebar: sidebarState) => {
      this.sports = sidebar.sports;
      this.highlights = sidebar.highlights;
      this.highlightsConfig = sidebar.highlightsConfig;
      if (this.selectedSport)
        this.selectedSport = sidebar.sports.find((i) => i.SportHandle === this.selectedSport.SportHandle);
    });
  }

  fetchCountries(sport) {
    this.store.dispatch(SidebarActions.fetchCountries(sport));
  }

  toggleSection(sport) {
    this.store.dispatch(SidebarActions.toggleSection({ sport }));
  }

  setSelectedSport(sport) {
    this.selectedSport = sport;
  }
}
