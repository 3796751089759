/* eslint-disable @typescript-eslint/no-explicit-any */
import { MenuController, NavParams, IonicModule } from '@ionic/angular';
import { Component, inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { LicenseTypes } from '@models/MasterData';
import { GlobalVarsModel } from '@models/ModelGlobalsVar';
import { NewBaseService } from '@providers/newBase.service';
import { TranslateModule } from '@ngx-translate/core';
import { Utils } from '@utils/Utils';
import { CodereFooterComponent } from '@shared-mso-components/footer/codere-footer/codere-footer';
import { BackButton } from '@shared-common-components/back-button/back-button';
import { Router } from '@angular/router';
import { DeviceService } from '@services/device.service';
import { TrackEvent } from '@directives/track-event/track-event';
import { CommonModule } from '@angular/common';

/*
  Generated class for the HelpPage page.

  See http://ionicframework.com/docs/v2/components/#navigation for more info on
  Ionic pages and navigation.
*/
@Component({
  standalone: true,
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'download-native-page',
  templateUrl: './download-native.html',
  styleUrls: ['./download-native.scss'],
  imports: [CommonModule, IonicModule, CodereFooterComponent, TranslateModule, BackButton, TrackEvent],
  providers: [NavParams]
})
export class DownloadApp {
  section: any;
  scrHelp: any;
  viewAndroid: boolean;
  globalVars!: GlobalVarsModel;
  isMobile: boolean = false;

  utils = inject(Utils);
  newBaseService = inject(NewBaseService);
  menu = inject(MenuController);
  navParams = inject(NavParams);
  sanitizer = inject(DomSanitizer);
  router = inject(Router);
  deviceService = inject(DeviceService);

  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });

    this.isMobile = this.deviceService.isMobile();

    switch (this.globalVars.licenseType) {
      case LicenseTypes.Mexico:
        this.scrHelp = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.codere.mx/descarga-la-app');
        break;
      case LicenseTypes.Colombia:
        this.scrHelp = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.codere.com.co/descarga-la-app');
        break;
      default:
        this.scrHelp = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.codere.es/apuesta-en-tu-m%C3%B3vil');
        break;
    }

    this.viewAndroid = false;
  }

  ionViewWillLeave(event) {
    this.utils.hideFormAccessoryBar(true);
  }

  ionViewWillEnter(event) {
    this.utils.hideFormAccessoryBar(false);
  }

  openModalUser() {
    this.menu.open('right');
  }

  backToHome() {
    this.router.navigate(['/']);
  }

  click_AndroidButton() {
    if (this.viewAndroid) this.viewAndroid = false;
    else this.viewAndroid = true;
  }
}
