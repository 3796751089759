/* eslint-disable eqeqeq */
import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { I_NewCarousel } from '@models/I_NewCarousel';
import { FormatOdd } from '@pipes/formatOdd.pipe';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'codere-slider-container',
  templateUrl: './codere-slider-container.html',
  styleUrls: ['./codere-slider-container.scss'],
  standalone: true,
  imports: [CommonModule, IonicModule, FormatOdd]
})
export class CodereSliderContainer implements OnInit {
  @Input() slide: I_NewCarousel;
  @Output() clickedSlide: EventEmitter<{ slide: I_NewCarousel; type: string }> = new EventEmitter();

  rigthTeamAmount = 0;
  drawAmount = 0;
  leftTeamAmount = 0;
  nowAmount = 0;
  isDesktop = false;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}

  ngOnInit(): void {
    if (this.slide) {
      if (this.slide.typeOfTemplate === 'One Odd') {
        this.nowAmount = this.slide.oneOddAmount;
      } else {
        this.leftTeamAmount = this.slide.leftTeamAmount;
        if (this.slide.typeOfTemplate == 'Two teams with draw') {
          this.drawAmount = this.slide.drawAmount;
        }
        this.rigthTeamAmount = this.slide.rigthTeamAmount;
      }
    }
  }
  btnClickSlide(slide: I_NewCarousel, event = '') {
    this.clickedSlide.emit({ slide, type: event });
  }
  isTeamMach(slide: I_NewCarousel) {
    return (
      slide.typeOfTemplate === 'Two teams without draw' ||
      slide.typeOfTemplate === 'Two teams with draw' ||
      slide.typeOfTemplate === 'Tennis Match'
    );
  }
}
