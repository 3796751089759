<div class="birthdate-container">
  <ion-row id="fecnacpc" [formGroup]="fgrp" class="titlebirthdate">
    <ion-col size="12">
      <ion-item-divider mode="md" class="item-divider">
        <ion-label class="time-label">
          <span
            class="default-span"
            [ngClass]="{'green-text': 
                        fgrp.get('birthYear').valid 
                        && fgrp.get('birthYear').value}"
          >
            {{title}}
          </span>
          <cdr-info
            class="time-label-img"
            tabindex="-1"
            [target]="'modalBirthdate'"
            [fcName]="'birthYear'"
            [fgrp]="fgrp"
          ></cdr-info>
        </ion-label>
      </ion-item-divider>
      <div class="line"></div>
    </ion-col>
  </ion-row>
</div>
<ng-container [formGroup]="fgrp">
  <ion-row class="mainbirthdate">
    <!--Fecha de nacimiento: día -->
    <ion-col size="4" class="time-col">
      <div id="birthDay">
        <select formControlName="birthDay" (focus)="handleDayFocus()">
          <option *ngFor="let day of [].constructor(31); index as i" [value]="i+1">{{i+1}}</option>
        </select>
        <label
          [ngClass]="{'floating': fgrp.get('birthDay').dirty || isMobile ||isDayFocused ,
                    'inplace': fgrp.get('birthDay').pristine || !isMobile || !isDayFocused }"
        >
          {{labels.day}}
        </label>
      </div>
    </ion-col>
    <!--Fecha de nacimiento: mes -->
    <ion-col size="4" class="time-col">
      <div id="birthMonth">
        <select [formControlName]="'birthMonth'" (focus)="handleMonthFocus()" (ngModel)="months">
          <option *ngFor="let option of months" [ngValue]="option">{{option.name}}</option>
        </select>
        <label
          [ngClass]="{'floating': fgrp.get('birthMonth').dirty || isMobile || isMonthFocused ,
                        'inplace': fgrp.get('birthMonth').pristine || !isMobile || !isMonthFocused }"
          >{{labels.month}}</label
        >
      </div>
    </ion-col>
    <!--Fecha de nacimiento: año -->
    <ion-col size="4" class="time-col">
      <cdr-input fcName="birthYear" [fgrp]="fgrp" [label]="labels.year" length="4"></cdr-input>
    </ion-col>
    <cdr-control-error *ngIf="errorsCol" [fControl]="$any(fgrp).get('birthYear')" [errorsCol]="errorsCol">
    </cdr-control-error>
  </ion-row>
</ng-container>
