import { Route } from '@angular/router';
import { MsoComponent } from './mso/mso.component';
import { inject } from '@angular/core';
import { MSO_PATHS } from '@shared-constants/routes';
import { SMALL_VIEWPORT } from '@shared-constants/injection-tokens';

import { Login } from '@shared-mso-pages/login/login';
import { PersonalInfoPage } from '@shared-mso-pages/personal-info/personal-info';
import { AuthGuard } from '@guards/auth.guard';
import { LicenseGuard } from '@guards/license.guard';
import { ModalGuard } from '@guards/modal.guard';
import { CuotaTypePage } from '@shared-mso-pages/cuota-type/cuota-type';
import { LanguagePage } from '@shared-mso-pages/language/language';
import { MyActivityPage } from '@shared-mso-pages/my-activity/my-activity';
import { AddFriendPage } from '@shared-mso-pages/add-friend/add-friend';
import { CodereCardPage } from './pages/codere-card/codere-card';
import { MyCardPage } from '@shared-mso-pages/my-card/my-card';
import { MyCardRecoverPage } from '@shared-mso-pages/my-card-recover/my-card-recover';
import { MyCardUnlockPage } from '@shared-mso-pages/my-card-unlock/my-card-unlock';
import { MyCardRecover2Page } from '@shared-mso-pages/my-card-recover-2/my-card-recover-2';
import { ChargeHistoryPage } from '@shared-mso-pages/charge-history/charge-history';
import { MyCardPinPage } from '@shared-mso-pages/my-card-pin/my-card-pin';
import { LimitsPage } from '@shared-mso-pages/limits/limits';
import { MyCardTransferPage } from '@shared-mso-pages/my-card-transfer/my-card-transfer';

import { LicenseTypes } from '@models/MasterData';
import { ContactPage } from './pages/contact/contact/contact';
import { PromotionsPage } from './pages/promociones/promociones';
import { NearestLocalPage } from '@shared-mso-pages/nearest-local/nearest-local';
import { RegistroNewPage } from './pages/registroESNew/registro/registro';
import { UserMessagesPage } from './pages/user-messages/user-messages';
import { InformationPage } from './pages/information-page/information-page';
import { InformationPageMobile } from '@mso-pages/information-page-mobile/information-page-mobile';
import { DownloadApp } from './pages/download-native/download-native';
import { HelpPage } from './pages/ayuda/ayuda';
import { AvisoPage } from './pages/aviso/aviso';
import { ComunicationPage } from './pages/comunication/comunication';
import { CardRequestOkPage } from './pages/card-request-ok/card-request-ok';
import { CardRequestPage } from './pages/card-request/card-request';
import { Paso1Page, Paso2Page } from './pages/registroESNew';
import { SummaryOfMovementsPage } from './pages/summary-of-movements/summary-of-movements';
import { limitsFormulPage } from './pages/limits-formul/limits-formul';
import { RegistroARPage } from './pages/registroAR/registroAR';
import { RegistroPAPage } from './pages/registroPA/registroPA';
import { RegistroCONewPage } from './pages/registroCONew/registroCONew';
import { RegistroPBAPage } from './pages/registroPBA/registroPBA';
import { ResponsibleGamblingTestPage } from './pages/responsible-gambling-test/responsible-gambling-test';
import { QuickExit } from './pages/quickExit/quickExit';
import { RegisterGuard } from '@guards/register.guard';

import { PlaytechIframePage } from '@shared-pages/playtech-iframe/playtech-iframe';
import { CookiesConsentPage } from './pages/cookies-consent/cookies-consent';
import { SelfExclusionPage } from './pages/selfExclusion/selfExclusion';
import { ActivateUserPage } from './pages/activate-user/activate-user-page';
import { CardLinkPage } from '@shared-mso-pages/card-link/card-link';
import { CardRequestFormPage } from '@mso-pages/card-request-form/card-request-form';
import { TimeOutPage } from './pages/timeOut/timeOut';
import { MvpCoderePage } from '@mso-pages/mvpcodere/mvpcodere';
import { ChangePasswordPage } from './pages/change-password/change-password';
import { SimulatorPage } from '@mso-pages/simulator/simulator-page';
import { RegistroMZAPage } from './pages/registroMZA/registroMZA';
import { GeolocationPage } from '@mso-pages/geolocation-page/geolocation-page/geolocation-page-mobile';

export const MSO_ROUTES: Route[] = [
  { path: '', pathMatch: 'full', component: MsoComponent },
  { path: 'login', component: Login },
  { path: MSO_PATHS.ContactPage, component: ContactPage, data: { section: 'user' } },
  {
    path: MSO_PATHS.PersonalInfoPage,
    component: PersonalInfoPage,
    canActivate: [AuthGuard],
    data: { section: 'user' }
  },
  { path: MSO_PATHS.PromotionsPage, component: PromotionsPage, data: { promotionsTest: false } },
  { path: MSO_PATHS.SummaryOfMovementsPage, component: SummaryOfMovementsPage, data: { section: 'user' } },

  { path: MSO_PATHS.PromotionsTestPage, component: PromotionsPage, data: { promotionsTest: true } },
  { path: MSO_PATHS.NearestLocalPage, component: NearestLocalPage },
  {
    path: MSO_PATHS.RegistroNewPage,
    component: RegistroNewPage,
    canActivate: [LicenseGuard, RegisterGuard],
    data: {
      requiredLicenseType: [
        LicenseTypes.Nacional,
        LicenseTypes.Extremadura,
        LicenseTypes.Madrid,
        LicenseTypes.PaisVasco
      ],
      skipModal: true
    },
    children: [
      {
        path: MSO_PATHS.Paso1Page,
        component: Paso1Page,
        canActivate: [LicenseGuard],
        data: {
          requiredLicenseType: [
            LicenseTypes.Nacional,
            LicenseTypes.Extremadura,
            LicenseTypes.Madrid,
            LicenseTypes.PaisVasco
          ]
        }
      },
      {
        path: MSO_PATHS.Paso2Page,
        component: Paso2Page,
        canActivate: [LicenseGuard],
        data: {
          requiredLicenseType: [
            LicenseTypes.Nacional,
            LicenseTypes.Extremadura,
            LicenseTypes.Madrid,
            LicenseTypes.PaisVasco
          ]
        }
      }
    ]
  },
  {
    path: MSO_PATHS.RegistroARPage,
    component: RegistroARPage,
    canActivate: [LicenseGuard, RegisterGuard],
    data: {
      requiredLicenseType: [LicenseTypes.ArgentinaCaba, LicenseTypes.ArgentinaCordoba]
    }
  },
  {
    path: MSO_PATHS.RegistroMZAPage,
    component: RegistroMZAPage,
    canActivate: [LicenseGuard, RegisterGuard],
    data: {
      requiredLicenseType: [LicenseTypes.ArgentinaMendoza]
    }
  },
  {
    path: MSO_PATHS.RegistroPBAPage,
    component: RegistroPBAPage,
    canActivate: [LicenseGuard, RegisterGuard],
    data: {
      requiredLicenseType: [LicenseTypes.ArgentinaPBA]
    }
  },
  {
    path: MSO_PATHS.RegistroPAPage,
    component: RegistroPAPage,
    canActivate: [LicenseGuard, RegisterGuard],
    data: {
      requiredLicenseType: [LicenseTypes.Panama]
    }
  },
  {
    path: MSO_PATHS.RegistroCONewPage,
    component: RegistroCONewPage,
    canActivate: [LicenseGuard, RegisterGuard],
    data: {
      requiredLicenseType: [LicenseTypes.Colombia]
    }
  },
  {
    path: MSO_PATHS.UserMessagesPage,
    component: UserMessagesPage,
    canActivate: [AuthGuard],
    data: { section: 'user' }
  },
  { path: MSO_PATHS.InformationPage, component: InformationPage },
  { path: MSO_PATHS.InformationPageMobile, component: InformationPageMobile },
  { path: MSO_PATHS.DownloadApp, component: DownloadApp },
  { path: MSO_PATHS.CuotaTypePage, component: CuotaTypePage, data: { section: 'user' } },
  { path: MSO_PATHS.HelpPage, component: HelpPage },
  { path: MSO_PATHS.LanguagePage, component: LanguagePage, data: { section: 'user' } },
  { path: MSO_PATHS.MyActivityPage, component: MyActivityPage, canActivate: [AuthGuard], data: { section: 'user' } },
  { path: MSO_PATHS.AddFriendPage, component: AddFriendPage },
  { path: MSO_PATHS.AvisoPage, component: AvisoPage, canActivate: [AuthGuard] },
  { path: MSO_PATHS.MyCardPage, component: MyCardPage, canActivate: [AuthGuard], data: { section: 'user' } },
  { path: MSO_PATHS.MyCardPinPage, component: MyCardPinPage, data: { section: 'user' } },
  {
    path: MSO_PATHS.MyCardTransferPage,
    component: MyCardTransferPage,
    canActivate: [AuthGuard],
    data: { section: 'user' }
  },
  {
    path: MSO_PATHS.MyCardRecoverPage,
    component: MyCardRecoverPage,
    canActivate: [AuthGuard],
    data: { section: 'user' }
  },
  {
    path: MSO_PATHS.MyCardRecover2Page,
    component: MyCardRecover2Page,
    data: { section: 'user' }
  },
  { path: MSO_PATHS.MyCardUnlockPage, component: MyCardUnlockPage, data: { section: 'user' } },
  { path: MSO_PATHS.CardLinkPage, component: CardLinkPage, data: { section: 'user' } },
  { path: MSO_PATHS.CardRequestPage, component: CardRequestPage, canActivate: [AuthGuard], data: { section: 'user' } },
  { path: MSO_PATHS.ComunicationPage, component: ComunicationPage, data: { section: 'user' } },
  {
    path: MSO_PATHS.CardRequestOkPage,
    component: CardRequestOkPage,
    canActivate: [AuthGuard],
    data: { section: 'user' }
  },
  {
    path: MSO_PATHS.CodereCardPage,
    component: CodereCardPage,
    canActivate: [LicenseGuard],
    data: { requiredLicenseType: [LicenseTypes.Nacional, LicenseTypes.Colombia] }
  },
  {
    path: MSO_PATHS.limitsFormulPage,
    component: limitsFormulPage,
    canActivate: [AuthGuard],
    data: { section: 'user' }
  },
  {
    path: MSO_PATHS.QuickExit,
    component: QuickExit,
    canActivate: [AuthGuard, LicenseGuard],
    data: {
      section: 'user',
      requiredLicenseType: [LicenseTypes.ArgentinaCaba, LicenseTypes.ArgentinaMendoza, LicenseTypes.ArgentinaPBA]
    }
  },
  { path: MSO_PATHS.NearestLocalPage, component: NearestLocalPage },
  {
    path: MSO_PATHS.ChargeHistoryPage,
    component: ChargeHistoryPage,
    data: { section: 'user' }
  },
  {
    path: 'PlaytechIframePage',
    component: PlaytechIframePage,
    data: {
      section: 'sportbook'
    }
  },
  {
    path: MSO_PATHS.ResponsibleGamblingTestPage,
    component: ResponsibleGamblingTestPage,
    canActivate: [AuthGuard, LicenseGuard],
    data: {
      section: 'user',
      requiredLicenseType: [LicenseTypes.Colombia]
    }
  },
  {
    path: MSO_PATHS.CookiesConsentPage,
    component: CookiesConsentPage,
    canActivate: [LicenseGuard, ModalGuard],
    data: { requiredLicenseType: [LicenseTypes.Nacional], pcCssClass: 'modalh800 modalcookieconsent' }
  },
  {
    path: MSO_PATHS.SelfExclusionPage,
    component: SelfExclusionPage,
    canActivate: [AuthGuard],
    data: { section: 'user' }
  },
  {
    path: MSO_PATHS.ActivateUserPage,
    component: ActivateUserPage
  },
  {
    path: MSO_PATHS.SimulatorPage,
    component: SimulatorPage
  },
  {
    path: MSO_PATHS.CardRequestFormPage,
    component: CardRequestFormPage,
    canActivate: [AuthGuard],
    data: { section: 'user' }
  },
  {
    path: MSO_PATHS.LimitsPage,
    component: LimitsPage,
    canActivate: [AuthGuard],
    data: { section: 'user' }
  },
  {
    path: MSO_PATHS.TimeOutPage,
    component: TimeOutPage,
    canActivate: [AuthGuard, LicenseGuard],
    data: {
      requiredLicenseType: [LicenseTypes.ArgentinaCaba, LicenseTypes.ArgentinaPBA, LicenseTypes.ArgentinaMendoza],
      section: 'user'
    }
  },
  {
    path: MSO_PATHS.MvpCoderePage,
    component: MvpCoderePage
  },
  {
    path: MSO_PATHS.ChangePasswordPage,
    component: ChangePasswordPage,
    canActivate: [AuthGuard],
    data: {
      section: 'user'
    }
  },
  {
    path: 'welcome',
    canMatch: [() => inject(SMALL_VIEWPORT)],
    component: MsoComponent
  },
  {
    path: MSO_PATHS.GeolocationPage,
    component: GeolocationPage
  }
];

/* ToDo: Paginas que necesitan authGuard
ChangePasswordPage
ChargeCancelPage
ChargeCoderePage
ChargeHistoryPage
ChargeOnlineMoreInfoPage
ChargeOnlinePage
CuotaChangePage
DepositCoderePage
DepositHistoryPage
DepositOnlinePage
DepositTicketPage
DocumentVerificationPage
DocumentVerificationStep2Page
FreeBetsPage
LimitsPage
MyCardRecover2Page
MyCardTransferPage
SlotsHistoryPage
CheckTicketStep2Page
BetDetailPage
DepositPaymentKoPage
DepositPaymentOkPage
timeOut
SelfExclusion
quickExit
SlotsBonusPage
PlaySstPage
AddFriends
CashierPage
ChargePaymentOkPage
ChargePaymentKoPage
PlaytechIframePage
CalendarPage or LobbyCalendarPage
ResponsibleGamblingTestPage
SummaryOfMovementsPage */
