<ion-header>
  <div class="alert-head">
    <h2 class="alert-title" id="">
      {{'v2_seleccionaTu' | translate}}
      <!--# Selecciona tu-->
      <img src="assets/global/img/FREEBETlogoPop.png" alt="" />
    </h2>
    <button class="closeModal" (click)="close()">
      <ion-icon name="close-outline"></ion-icon>
    </button>
  </div>
</ion-header>
<ion-content [class.no-freebets]="!!lstFreebets && lstFreebets?.length === 0">
  <div class="contModal pop-wrapper">
    <div class="alert-message">
      <ion-list class="freebets-mx-input" *ngIf="globalvars.licenseType!==0">
        <ion-list-header class="freebet-header"> Introduce tu código: </ion-list-header>
        <ion-item class="freebet-input-item">
          <ion-label> Código FB </ion-label>
          <ion-input type="text" max-length="255" [(ngModel)]="inputFB"></ion-input>
        </ion-item>
        <button ion-button full class="is-ticket-button" (click)="sendInputFB()">OK</button>
      </ion-list>

      <ion-list>
        <ion-item
          style="align-items: baseline"
          *ngFor="let itemFB of lstFreebets"
          [hidden]="!lstFreebets || lstFreebets?.length==0"
          (click)="freeBetsAdd(itemFB, false)"
        >
          <ion-avatar item-left class="freebetBullet" [ngClass]="{ 'long-number': isAR() }">
            <codere-style-money [amount]="itemFB.Amount.toString()" [type]="0"></codere-style-money>
            <p>
              {{'fb' | translate | uppercase}}
              <!--freebet-->
            </p>
          </ion-avatar>
          <h2>{{itemFB.Name | translate}}</h2>
          <h3>
            <!--Importe-->{{'importSlots' | translate}}
            <b>{{itemFB.Amount | parseMoney}}</b>
          </h3>
          <div *ngFor="let itemConditions of itemFB?.Conditions">
            <!--<h3>probando</h3>-->
            <h3 *ngIf="itemConditions.ConditionValueDecimal>0">
              {{'fb_txt_active6' | translate}}
              <b>{{itemConditions.ConditionValueDecimal.toString() | formatOdd}}</b>
            </h3>
            <h3 *ngIf="itemConditions.ConditionValueInt>0">
              {{'fb_txt_active8' | translate}}
              <b>{{itemConditions.ConditionValueInt}}</b>
            </h3>
          </div>
          <h3 class="lastCol" *ngIf="itemFB.IsRedeemable">
            {{'v2_ganaNetas' | translate}}
            <!--# Solo ganancias netas-->
          </h3>
          <i class="codere-icon arrow icon-arrow-right" item-right=""></i>
        </ion-item>
      </ion-list>

      <div
        class="empty-state text-center"
        *ngIf="globalvars?.licenseType !== LicenseTypes.Mexico"
        [hidden]="!lstFreebets || lstFreebets?.length!=0"
      >
        <b>{{'v2_noFreeBet' | translate}}<!--# ¡No tienes ningún FreeBet!--></b>
        <span> </span>
      </div>
    </div>
  </div>
</ion-content>
