<div *ngIf="title" class="color-light lb-counter-txt" [ngClass]="{'lb-counter-txt--small': titleSize}">{{title}}</div>
<div *ngIf="setAmount?.length > 0" class="lb-counter--content">
  <ng-container *ngIf="!isSpain">
    <svg
      class="lb-counter-item--currency color-light"
      [ngClass]="{'lb-counter-item--currency--small': titleSize}"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 9 17"
    >
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g transform="translate(-1176.000000, -7367.000000)">
          <path
            d="M1181.23047,7383.75146 L1181.23047,7382.13281 C1182.23763,7382.02767 1183.03174,7381.6901 1183.61279,7381.12012 C1184.19385,7380.55013 1184.48438,7379.78646 1184.48438,7378.8291 C1184.48438,7377.86621 1184.19938,7377.10807 1183.62939,7376.55469 C1183.05941,7376.0013 1182.19059,7375.51432 1181.02295,7375.09375 C1180.25374,7374.82259 1179.72111,7374.5376 1179.42505,7374.23877 C1179.12899,7373.93994 1178.98096,7373.55257 1178.98096,7373.07666 C1178.98096,7372.56755 1179.12207,7372.16219 1179.4043,7371.8606 C1179.68652,7371.559 1180.10986,7371.4082 1180.67432,7371.4082 C1181.21663,7371.4082 1181.64274,7371.61019 1181.95264,7372.01416 C1182.26253,7372.41813 1182.41748,7372.96598 1182.41748,7373.65771 L1182.41748,7373.65771 L1184.42627,7373.65771 C1184.42627,7372.55648 1184.15926,7371.67244 1183.62524,7371.00562 C1183.09123,7370.33879 1182.35107,7369.93343 1181.40479,7369.78955 L1181.40479,7369.78955 L1181.40479,7367.96338 L1180.07666,7367.96338 L1180.07666,7369.77295 C1179.1193,7369.88363 1178.36117,7370.23503 1177.80225,7370.82715 C1177.24333,7371.41927 1176.96387,7372.17464 1176.96387,7373.09326 C1176.96387,7374.06169 1177.25578,7374.82674 1177.8396,7375.38843 C1178.42342,7375.95011 1179.29915,7376.43018 1180.4668,7376.82861 C1181.1862,7377.08317 1181.69946,7377.3654 1182.00659,7377.67529 C1182.31372,7377.98519 1182.46729,7378.37533 1182.46729,7378.8457 C1182.46729,7379.36035 1182.2985,7379.76709 1181.96094,7380.06592 C1181.62337,7380.36475 1181.14746,7380.51416 1180.5332,7380.51416 C1179.95768,7380.51416 1179.47762,7380.34538 1179.09302,7380.00781 C1178.70841,7379.67025 1178.51611,7379.10856 1178.51611,7378.32275 L1178.51611,7378.32275 L1176.55713,7378.32275 L1176.54053,7378.36426 C1176.51839,7379.55404 1176.8269,7380.45467 1177.46606,7381.06616 C1178.10522,7381.67765 1178.92008,7382.03597 1179.91064,7382.14111 L1179.91064,7382.14111 L1179.91064,7383.75146 L1181.23047,7383.75146 Z"
            fill="#FFFFFF"
            fill-rule="nonzero"
          ></path>
        </g>
      </g>
    </svg>
  </ng-container>

  <!-- [randomMath]="false" -->
  <lb-counter-item
    *ngFor="let character of setAmount; let idx = index"
    [class.Small]="titleSize"
    [class.lb-counter--separator]="!character.isNumber && !titleSize"
    [class.lb-counter--separator--small]="!character.isNumber && titleSize"
    [currentItem]="character"
    [minSize]="titleSize"
    [position]="idx"
  >
  </lb-counter-item>

  <ng-container *ngIf="isSpain">
    <div class="color-light euroCoin" [ngClass]="{'euroCoin--small': titleSize}">€</div>
  </ng-container>
</div>
