/* eslint-disable */
import { TransactionActionResult } from "@models/C_Transactions";
import { ErrorService } from "@providers/ErrorService";
import { UserService } from "@providers/UserService";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { IFramePaymentMethodBehaviourResult, PaymentMethodBehaviour, SkipAmountResult } from "./PaymentMethodBase";

export abstract class TelePayPaymentMethodBehaviour extends PaymentMethodBehaviour {
  name = "VISA";
  image = "Visa_Payment_icon";
  track = "AccessToCreditCardDepositTelepay";
  isBank = false;

  constructor(userService: UserService, acceptColombiaConditions = false, errorService: ErrorService) {
    super(acceptColombiaConditions, userService, errorService);
  }
  skipAmountInput(): Observable<SkipAmountResult> {
    return new Observable<SkipAmountResult>();
  }

  paymentMetodExecution(): Observable<IFramePaymentMethodBehaviourResult> {
    return this.userService.validateDepositVISA("", this.amount).pipe(
      map((obj) => {
        return this.map(obj);
      }),
    );
  }

  private map(input: TransactionActionResult): IFramePaymentMethodBehaviourResult {
    const result = new IFramePaymentMethodBehaviourResult();
    if (input.success) {
      result.success = true;
      result.iframeType = "visa";
      result.iframeArgs = { type: "visa", amount: input.amount, acceptColombiaConditions: false };
    } else {
      result.success = false;
      result.erroMessage = this.errorService.searchErrorCodeWithParams(
        "error" + input.errCode,
        input.errorParameters,
      );
      result.errorcode = input.errCode;
      result.sendToDocumentVerificationPage = input.errCode === 174 || input.errCode === 2212;
      result.acceptColombiaConditionsRequired = input.errCode === 4577;
    }
    return result;
  }
}

export class TelePayPaymentMethodBehaviourES extends TelePayPaymentMethodBehaviour {
  amount = 20;
  minDepositAmount = 10;
  maxDepositAmount = 100000;
  amountButtons: number[] = [10, 20, 40, 50];
}

export class TelePayPaymentMethodBehaviourCO extends TelePayPaymentMethodBehaviour {
  amount = 3000;
  minDepositAmount = 3000;
  maxDepositAmount = 9999999;
  amountButtons: number[] = [30000, 100000, 500000, 1000000];
}
