<ng-container *ngIf="!isMobile; else mobileView">
  <ion-header>
    <user-section-title [sectionTitle]="'CodereCardTransfer'"></user-section-title>
  </ion-header>
  <ion-content class="background-color-muted">
    <div class="is-narrow-content">
      <!--SALDO ACTUAL-->
      <h2 class="list-preheader text-uppercase has-x-margin-mobile">{{'uPBalance' | translate | uppercase}}</h2>
      <ion-list class="card-list">
        <ion-item class="card-item">
          <i class="codere-icon icon-mobileCash" item-start=""></i>{{'CodereCardBalanceOnline' | translate}}:
          <!--Saldo Online:-->
          <b><span style="margin-left: 5px">{{globalVars.user.balance | parseMoney:[1]}}</span></b>
          <ion-buttons slot="end">
            <button class="reload-button" item-end="" (click)="updateBalance()">
              <i class="reload-icon codere-icon icon-reCharge"></i>
            </button>
          </ion-buttons>
        </ion-item>
        <ion-item class="card-item" lines="none">
          <i class="codere-icon icon-cardSaldo" item-start=""></i>
          {{'CodereCardBalance0' | translate}}:
          <!--Saldo Tarjeta:-->
          <b><span style="margin-left: 5px">{{globalVars.user.codereCard?.balance | parseMoney:[1]}}</span></b>
          <ion-buttons slot="end">
            <button class="reload-button" item-end="" (click)="updateBalanceCard()">
              <i class="reload-icon codere-icon icon-reCharge"></i>
            </button>
          </ion-buttons>
        </ion-item>
      </ion-list>
      <h2 class="list-preheader text-uppercase">
        {{'CodereCardtransferbal' | translate | uppercase}}
        <!--TRANSFERIR SALDO-->
      </h2>
      <ion-list class="card-list has-no-padding">
        <ion-row class="subHeaderSelects">
          <ion-col>
            <ion-list>
              <ion-item class="ion-item-select" lines="none">
                <ion-label
                  >{{'From' | translate | uppercase}}:
                  <!--De:-->
                </ion-label>
                <ion-select
                  cancelText="{{'tCancel' | translate}}"
                  [(ngModel)]="form.from"
                  (ionChange)="checkValidOption($event, 1)"
                  placeholder="{{'CodereCardBalanceOnline'|translate}}"
                >
                  <ion-select-option value="online" checked="true"
                    >{{'CodereCardBalanceOnline' | translate}}
                    <!--Saldo online--></ion-select-option
                  >
                  <ion-select-option value="tarjeta"
                    >{{'CodereCardBalance0' | translate}}
                    <!--Saldo tarjeta--></ion-select-option
                  >
                </ion-select>
                <ion-icon ios="ios-arrow-dropdown" md="md-arrow-dropdown"></ion-icon>
              </ion-item>
            </ion-list>
          </ion-col>
          <ion-col>
            <ion-list>
              <ion-item class="ion-item-select" lines="none">
                <ion-label
                  >{{'To' | translate | uppercase}}:
                  <!--A:-->
                </ion-label>
                <ion-select
                  cancelText="{{'tCancel' | translate}}"
                  [(ngModel)]="form.to"
                  (ionChange)="checkValidOption($event,2)"
                  placeholder="{{'CodereCardBalance0'|translate}}"
                >
                  <ion-select-option value="online"
                    >{{'CodereCardBalanceOnline'|translate}}
                    <!--Saldo online--></ion-select-option
                  >
                  <ion-select-option value="tarjeta" checked="true"
                    >{{'CodereCardBalance0'|translate}}
                    <!--Saldo tarjeta--></ion-select-option
                  >
                </ion-select>
                <ion-icon ios="ios-arrow-dropdown" md="md-arrow-dropdown"></ion-icon>
              </ion-item>
            </ion-list>
          </ion-col>
        </ion-row>
        <div class="user-input-container" [formGroup]="myForm1">
          <p class="input-prelabel">
            {{'CodereCardintimport'|translate}}
            <!--Introducir importe-->
          </p>
          <div class="myWrapInput">
            <ion-row class="inputButtonGrid">
              <ion-col width-80>
                <ion-item
                  lines="none"
                  [class.error]="!myForm1.controls['amount'].valid && myForm1.controls['amount'].touched"
                  class="cdr-item-input"
                >
                  <ion-input
                    labelPlacement="floating"
                    label="{{'tImp' | translate}}"
                    type="number"
                    formControlName="amount"
                  ></ion-input>
                </ion-item>
                <div
                  class="labelError"
                  [hidden]="myForm1.controls['amount'].valid && !limitAmount || !myForm1.controls['amount'].touched"
                >
                  <span *ngIf="myForm1.controls['amount'].hasError('required')  && !limitAmount">
                    {{'error_form_requiered' | translate}}</span
                  >
                  <span *ngIf="myForm1.controls['amount'].hasError('amount') && !limitAmount">
                    {{'v2_errorSaldo' | translate}}</span
                  >
                  <span *ngIf="limitAmount"> La cantidad máxima es {{ maxAmount }}</span>
                </div>
              </ion-col>
              <ion-col (click)="checkOk()" tappable>
                <button class="btGreen">OK</button>
              </ion-col>
            </ion-row>
          </div>
        </div>

        <div class="user-input-container no-border" *ngIf="myForm1.controls['amount'].valid && checkedOk">
          <p>
            {{'v2_introPin' | translate}}<!--# Introduce el Pin de la tarjeta por seguridad--><span
              class="required-text"
              >*</span
            >
          </p>
          <div class="myWrapInput" [formGroup]="myForm1">
            <ion-item
              lines="none"
              [class.error]="!myForm1.controls['pin'].valid && myForm1.controls['pin'].touched"
              class="cdr-item-input"
            >
              <ion-input
                labelPlacement="floating"
                label="PIN"
                type="password"
                maxlength="4"
                formControlName="pin"
                autocomplete="off"
              ></ion-input>
            </ion-item>
            <div class="labelError" [hidden]="myForm1.controls['pin'].valid || !myForm1.controls['pin'].touched">
              <span *ngIf="myForm1.controls['pin'].hasError('required')"> {{'v2_pinObl' | translate}}</span>
              <span *ngIf="myForm1.controls['pin'].hasError('pattern')">
                {{'v2_pinNum' | translate}}<!--# El pin debe contener sólo números--><br
              /></span>
              <span *ngIf="myForm1.controls['pin'].hasError('minlength')"> {{'v2_pinNum1' | translate}}<br /></span>
              <span *ngIf="myForm1.controls['pin'].hasError('maxlength')"> {{'v2_pinNum1' | translate}}<br /></span>
            </div>
          </div>
        </div>
        <div class="sendForm has-x-padding-mobile" *ngIf="myForm1.controls['amount'].valid && checkedOk">
          <ion-button expand="full" (click)="sendTransfer()">
            {{'CodereCardconfir' | translate | uppercase}}
            <!--CONFIRMAR-->
          </ion-button>
        </div>
      </ion-list>
    </div>
    <codere-footer></codere-footer>
  </ion-content>
</ng-container>

<ng-template #mobileView>
  <ion-header>
    <user-section-title [sectionTitle]="'CodereCardTransfer'"></user-section-title>
  </ion-header>
  <ion-content class="background-color-muted">
    <div class="padding">
      <!--SALDO ACTUAL-->
      <h2 class="list-preheader text-uppercase has-x-margin-mobile">{{'uPBalance' | translate | uppercase}}</h2>
      <ion-list class="card-list">
        <ion-item class="card-item">
          <i class="codere-icon icon-mobileCash" item-start=""></i>{{'CodereCardBalanceOnline' | translate}}:
          <!--Saldo Online:-->
          <b><span style="margin-left: 5px">{{globalVars.user.balance | parseMoney:[1]}}</span></b>
          <ion-buttons slot="end">
            <button class="reload-button" item-end="" (click)="updateBalance()">
              <i class="reload-icon codere-icon icon-reCharge"></i>
            </button>
          </ion-buttons>
        </ion-item>
        <ion-item class="card-item" lines="none">
          <i class="codere-icon icon-cardSaldo" item-start=""></i>
          {{'CodereCardBalance0' | translate}}:
          <!--Saldo Tarjeta:-->
          <b><span style="margin-left: 5px">{{globalVars.user.codereCard?.balance | parseMoney:[1]}}</span></b>
          <ion-buttons slot="end">
            <button class="reload-button" item-end="" (click)="updateBalanceCard()">
              <i class="reload-icon codere-icon icon-reCharge"></i>
            </button>
          </ion-buttons>
        </ion-item>
      </ion-list>
      <h2 class="list-preheader text-uppercase">
        {{'CodereCardtransferbal' | translate | uppercase}}
        <!--TRANSFERIR SALDO-->
      </h2>
      <ion-list class="card-list has-no-padding">
        <ion-row class="subHeaderSelects">
          <ion-col size="6">
            <ion-list>
              <ion-item class="ion-item-select" lines="none">
                <ion-label
                  >{{'From' | translate | uppercase}}:
                  <!--De:-->
                </ion-label>
                <ion-select
                  cancelText="{{'tCancel' | translate}}"
                  [(ngModel)]="form.from"
                  (ionChange)="checkValidOption($event, 1)"
                  placeholder="{{'CodereCardBalanceOnline'|translate}}"
                  toggleIcon="chevron-down-outline"
                >
                  <ion-select-option value="online" checked="true"
                    >{{'CodereCardBalanceOnline' | translate}}
                    <!--Saldo online--></ion-select-option
                  >
                  <ion-select-option value="tarjeta"
                    >{{'CodereCardBalance0' | translate}}
                    <!--Saldo tarjeta--></ion-select-option
                  >
                </ion-select>
              </ion-item>
            </ion-list>
          </ion-col>
          <ion-col size="6">
            <ion-list>
              <ion-item class="ion-item-select" lines="none">
                <ion-label
                  >{{'To' | translate | uppercase}}:
                  <!--A:-->
                </ion-label>
                <ion-select
                  cancelText="{{'tCancel' | translate}}"
                  [(ngModel)]="form.to"
                  (ionChange)="checkValidOption($event,2)"
                  placeholder="{{'CodereCardBalance0'|translate}}"
                  toggleIcon="chevron-down-outline"
                >
                  <ion-select-option value="online"
                    >{{'CodereCardBalanceOnline'|translate}}
                    <!--Saldo online--></ion-select-option
                  >
                  <ion-select-option value="tarjeta" checked="true"
                    >{{'CodereCardBalance0'|translate}}
                    <!--Saldo tarjeta--></ion-select-option
                  >
                </ion-select>
              </ion-item>
            </ion-list>
          </ion-col>
        </ion-row>
        <div class="user-input-container" [formGroup]="myForm1">
          <p class="input-prelabel">
            {{'CodereCardintimport'|translate}}
            <!--Introducir importe-->
          </p>
          <div class="myWrapInput">
            <ion-row class="inputButtonGrid">
              <ion-col width-80>
                <ion-item
                  lines="none"
                  [class.error]="!myForm1.controls['amount'].valid && myForm1.controls['amount'].touched"
                  class="cdr-item-input"
                >
                  <ion-input
                    labelPlacement="floating"
                    label="{{'tImp' | translate}}"
                    type="number"
                    formControlName="amount"
                  ></ion-input>
                </ion-item>
                <div
                  class="labelError"
                  [hidden]="myForm1.controls['amount'].valid && !limitAmount || !myForm1.controls['amount'].touched"
                >
                  <span *ngIf="myForm1.controls['amount'].hasError('required')  && !limitAmount">
                    {{'error_form_requiered' | translate}}</span
                  >
                  <span *ngIf="myForm1.controls['amount'].hasError('amount') && !limitAmount">
                    {{'v2_errorSaldo' | translate}}</span
                  >
                  <span *ngIf="limitAmount"> La cantidad máxima es {{ maxAmount }}</span>
                </div>
              </ion-col>
              <ion-col (click)="checkOk()" tappable>
                <button class="btGreen">OK</button>
              </ion-col>
            </ion-row>
          </div>
        </div>

        <div class="user-input-container no-border" *ngIf="myForm1.controls['amount'].valid && checkedOk">
          <p>
            {{'v2_introPin' | translate}}<!--# Introduce el Pin de la tarjeta por seguridad--><span
              class="required-text"
              >*</span
            >
          </p>
          <div class="myWrapInput" [formGroup]="myForm1">
            <ion-item
              lines="none"
              [class.error]="!myForm1.controls['pin'].valid && myForm1.controls['pin'].touched"
              class="cdr-item-input"
            >
              <ion-input
                labelPlacement="floating"
                label="PIN"
                type="password"
                maxlength="4"
                formControlName="pin"
                autocomplete="off"
              ></ion-input>
            </ion-item>
            <div class="labelError" [hidden]="myForm1.controls['pin'].valid || !myForm1.controls['pin'].touched">
              <span *ngIf="myForm1.controls['pin'].hasError('required')"> {{'v2_pinObl' | translate}}</span>
              <span *ngIf="myForm1.controls['pin'].hasError('pattern')">
                {{'v2_pinNum' | translate}}<!--# El pin debe contener sólo números--><br
              /></span>
              <span *ngIf="myForm1.controls['pin'].hasError('minlength')"> {{'v2_pinNum1' | translate}}<br /></span>
              <span *ngIf="myForm1.controls['pin'].hasError('maxlength')"> {{'v2_pinNum1' | translate}}<br /></span>
            </div>
          </div>
        </div>
      </ion-list>
      <div class="sendForm has-x-padding-mobile" *ngIf="myForm1.controls['amount'].valid && checkedOk">
        <ion-button expand="full" (click)="sendTransfer()">
          {{'CodereCardconfir' | translate | uppercase}}
          <!--CONFIRMAR-->
        </ion-button>
      </div>
    </div>
    <codere-footer></codere-footer>
  </ion-content>
</ng-template>
