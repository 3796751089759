import { IonicModule, ModalController } from '@ionic/angular';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { CUSTOM_ELEMENTS_SCHEMA, Component, NO_ERRORS_SCHEMA, inject } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { UserServiceMSO } from '@providers/UserServiceMSO';
import { Utils } from '@utils/Utils';
import { CommonModule } from '@angular/common';
import { DeviceService } from '@services/device.service';
import { GlobalVarsModel } from '@models/ModelGlobalsVar';
import { NewBaseService } from '@providers/newBase.service';
import { UserSectionTitle } from '@shared-mso-components/user-section-title/user-section-title';
import { Router } from '@angular/router';

@Component({
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'responsible-gambling-test',
  templateUrl: './responsible-gambling-test.html',
  standalone: true,
  styleUrls: ['./responsible-gambling-test.scss'],
  imports: [IonicModule, CommonModule, FormsModule, TranslateModule, ReactiveFormsModule, UserSectionTitle]
})
export class ResponsibleGamblingTestPage {
  questions: Array<string>;
  formQuestions: FormGroup;
  questionNow: string;
  indexQuestionNow = 0;
  globalVars!: GlobalVarsModel;
  isDesktop = false;

  fb = inject(FormBuilder);
  utils = inject(Utils);
  userServiceMSO = inject(UserServiceMSO);
  translate = inject(TranslateService);
  modalCtrl = inject(ModalController);
  formBuilder = inject(FormBuilder);
  router = inject(Router);
  newBaseService = inject(NewBaseService);
  deviceService = inject(DeviceService);
  viewCtrl = inject(ModalController);

  constructor() {
    this.newBaseService.getVars.subscribe((global) => (this.globalVars = global));
    this.isDesktop = this.deviceService.isDesktop();
    this.questions = [
      this.translate.instant('responsibleGamblingTest01'),
      this.translate.instant('responsibleGamblingTest02'),
      this.translate.instant('responsibleGamblingTest03'),
      this.translate.instant('responsibleGamblingTest04'),
      this.translate.instant('responsibleGamblingTest05'),
      this.translate.instant('responsibleGamblingTest06'),
      this.translate.instant('responsibleGamblingTest07'),
      this.translate.instant('responsibleGamblingTest08'),
      this.translate.instant('responsibleGamblingTest09'),
      this.translate.instant('responsibleGamblingTest010')
    ];

    this.questionNow = this.questions[this.indexQuestionNow];
    this.formQuestions = this.formBuilder.group({});
    this.questions.forEach((q, index) => {
      this.formQuestions.addControl(
        `question${index}`,
        new FormControl(false, [Validators.required, Validators.requiredTrue])
      );
    });
  }

  nextQuestion(control: string, value: boolean) {
    this.formQuestions.controls[control].setValue(value);
    if (this.indexQuestionNow < this.questions.length - 1) {
      this.indexQuestionNow++;
      this.questionNow = this.questions[this.indexQuestionNow];
    } else {
      this.validaQuestion();
    }
  }

  prevQuestion() {
    if (this.indexQuestionNow > 0) {
      this.indexQuestionNow--;
      this.questionNow = this.questions[this.indexQuestionNow];
    }
  }

  backToHome() {
    this.router.navigate(['/']);
  }
  validaQuestion() {
    if (this.formQuestions.valid) {
      this.showpopupJuegResponTestCo(
        'assets/global/img/icon-check-green.png',
        true,
        this.questions.length,
        'responsibleGamblingTestOk'
      );
    } else {
      this.showpopupJuegResponTestCo(
        'assets/global/img/icon-close-red.png',
        false,
        this.countFaildResponse(),
        'responsibleGamblingTestKo'
      );
    }
  }
  async showpopupJuegResponTestCo(srcimg: string, approved: boolean, rdos: number, txt: string) {
    const msg = `<div><img src="${srcimg}" alt="OK"></div><div class="${
      approved ? 'txtgreen' : 'txtred'
    } font2_2">${this.translate
      .instant(approved ? 'approved1' : 'Failed1')
      .toUpperCase()}</div><div class="font2">${this.translate.instant(txt)} ${
      !approved
        ? `<a href="https://www.codere.com.co/Paginas/juego-responsable.aspx" external-link target="_blank">${this.translate.instant(
            'here'
          )}</a>`
        : ''
    }</div>`;
    const errorAlert = await this.utils.alert(
      false,
      this.translate.instant('responsibleGamblingTest') + ` (${rdos}/${this.questions.length})`,
      msg,
      this.translate.instant('confirm'),
      () => {
        if (this.isDesktop) {
          this.backToHome();
        } else {
          if (this.viewCtrl) {
            this.viewCtrl.dismiss();
          }
        }
      },
      'alertTest'
    );
    const nodeAlert = errorAlert.querySelector('.alert-wrapper');
    nodeAlert.classList.add('alertsportsgeocaba');
    nodeAlert.classList.add('testjresponco');
    nodeAlert.classList.add('responsible-test-result');
    nodeAlert.querySelector('a').setAttribute('target', '_blank');
  }
  countFaildResponse() {
    let count = 0;
    if (this.formQuestions.valid) {
      count = this.questions.length;
    } else {
      this.questions.forEach((q, index) => {
        if (this.formQuestions.controls['question' + index].valid) {
          count++;
        }
      });
    }
    return count;
  }
}
