<div class="min-bet-filter">
  <div
    *ngFor="let filter of filterGroup.filters; let i = index"
    class="min-bet-filter-item"
    (click)="selectFilter(filter)"
  >
    <ion-icon
      *ngIf="!filter.selected; else selected"
      src="assets/global/img/{{ filter.icon }}.svg"
      class="icon"
    ></ion-icon>
    <ng-template #selected>
      <ion-icon src="assets/global/img/{{ filter.icon }}_purple.svg" class="icon"></ion-icon>
    </ng-template>
    <div class="min-bet-filter-count">({{ filter.count || 0 }})</div>
    <div *ngIf="i" class="min-bet-filter-text">
      {{ filter.text | translate }}
    </div>
  </div>
</div>
