<!-- <div *ngIf="options.Referencia" class="cs-history-item--content item-type item-start">
  <span class="cs-history-item--text text-wrap">{{ history?.Referencia}}</span>
</div> -->
<div class="cs-history-item--content item-icon item-start">
  <i class="codere-icon type-icon" [ngClass]="isDeposit ? 'icon-insert-coin' : 'icon-take-coin'"></i>
  <span *ngIf="isDesktop" class="cs-history-item--text pd-left-4">{{ isDeposit ? 'Depósito' : 'Cobro' }}</span>
</div>
<div class="cs-history-item--content item-date item-start">
  <span class="cs-history-item--text">{{ getDisplayDate(history) }}</span>
</div>
<div class="cs-history-item--content item-type item-start">
  <span class="cs-history-item--text text-wrap">{{ history?.Metodo}}</span>
</div>
<div class="cs-history-item--content item-amount item-end">
  <span class="cs-history-item--text">{{ history?.Cantidad | parseMoney }}</span>
</div>
<!-- <div *ngIf="options.RetainedAmount" class="cs-history-item--content item-type item-start">
  <span class="cs-history-item--text text-wrap">{{ history?.RetainedAmount | parseMoney}}</span>
</div> -->
<!-- <div *ngIf="options.Cuenta" class="cs-history-item--content item-type item-start">
  <span class="cs-history-item--text text-wrap">{{ history?.Cuenta}}</span>
</div> -->
<div class="cs-history-item--content item-state item-start">
  <span class="codere-icon type-icon--circle" [ngClass]="setIconColor(history?.StateCode)"></span>
  <span *ngIf="!setCancelWithdrawal" class="cs-history-item--text">{{ history?.Estado}}</span>
  <span
    *ngIf="setCancelWithdrawal"
    class="cs-history-item--text color-light background-color-secondary cancel-option"
    tappable
    (click)="cancelPendingPayment()"
    >{{ 'tCancel' | translate }}</span
  >
</div>

<div class="cs-history-item--content item-icon item-end" tappable (click)="navigate?.emit(history)">
  <i item-right class="color-dark codere-icon icon-arrow-right"></i>
</div>
