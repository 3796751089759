import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "obfuscatebanks",
  standalone: true,
})
export class ObfuscateBanksPipe implements PipeTransform {
  transform(value: string): string {
    let result = "";
    let obfuscatedNumber = "";
    //eslint-disable-next-line
    if (value !== "" && value) {
      obfuscatedNumber = value.substr(value.length - 4);
    }
    result = `****${obfuscatedNumber}`;
    return result;
  }
}
