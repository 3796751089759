/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';

interface Styles {
  name: string;
  src: string;
}

export const StyleStore: Styles[] = [
  { name: 'betradarliveplayer', src: 'assets/global/liveplayer/style/styles.css' },
  // { name: 'betradarliveplayer', src: 'https://avplayer-cdn.akamaized.net/dist/latest/avvpl-player.css' },
  { name: 'sportradar', src: 'assets/js/sportradar/css/theme.css' }
];

declare const document: any;

@Injectable({ providedIn: 'root' })
export class StyleService {
  private styles: any = {};

  constructor() {
    StyleStore.forEach((style: any) => {
      this.styles[style.name] = {
        loaded: false,
        src: style.src
      };
    });
  }

  load(...styles: string[]) {
    const promises: any[] = [];
    styles.forEach((style) => promises.push(this.loadStyle(style)));
    return Promise.all(promises);
  }

  loadStyle(name: string) {
    return new Promise((resolve, reject) => {
      //resolve if already loaded
      if (this.styles[name].loaded) {
        resolve({ style: name, loaded: true, status: 'Already Loaded' });
      } else {
        //load style
        const style = document.createElement('link');
        style.rel = 'stylesheet';
        style.type = 'text/css';
        style.href = this.styles[name].src;
        if (style.readyState) {
          //IE
          style.onreadystatechange = () => {
            if (style.readyState === 'loaded' || style.readyState === 'complete') {
              style.onreadystatechange = null;
              this.styles[name].loaded = true;
              resolve({ style: name, loaded: true, status: 'Loaded' });
            }
          };
        } else {
          //Others
          style.onload = () => {
            this.styles[name].loaded = true;
            resolve({ style: name, loaded: true, status: 'Loaded' });
          };
        }
        style.onerror = (error: any) => reject({ style: name, loaded: false, status: 'Loaded' });
        document.getElementsByTagName('head')[0].appendChild(style);
      }
    });
  }
}
