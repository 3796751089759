<!-- eslint-disable -->
<ion-content class="codere-sidebar-menu leftMenuOpen" [class.leftMenuOpen]="globalVars?.leftMenuOpen">
  <ion-list class="menuUserList">
    <ion-list-header class="text-uppercase" (click)="openMisApuestas()">
      <span class="tittle-text">
        <i class="codere-icon icon-apuestas-83"></i>
        <ion-label>{{title}}</ion-label>
      </span>
    </ion-list-header>
    <ion-item-group *ngFor="let menu of navService.itemsMenu$ | async">
      <ion-item-divider class="catMenuUser" (click)="categoryMenuClick(menu)">
        <ion-label class="text-uppercase">
          <i [ngClass]="menu.icon" *ngIf="menu.icon.startsWith('codere')"></i>
          <img [src]="menu.icon" class="iconmyactivity" *ngIf="!menu.icon.startsWith('codere')" />
          <span class="is-bold">{{menu?.title}}</span>
          <i
            *ngIf="menu?.items?.length != 0"
            class="codere-icon arrow icon-arrow-bottom"
            [class.icon-arrow-top]="menu?.collapsed"
            [class.icon-arrow-bottom]="!menu?.collapsed"
            item-left
          ></i>
        </ion-label>
        <ion-badge *ngIf="menu.badgeCount>0" slot="end" class="ion-badge-counter" [class.alert-badge]="menu.isAlert"
          >{{menu?.badgeCount}}</ion-badge
        >
      </ion-item-divider>

      <div class="menu-items" *ngIf="menu.collapsed" tappable>
        <ion-item
          *ngFor="let int of menu?.items"
          (click)="itemMenuClick(int)"
          [ngClass]="{selected: currentPage === int.label}"
          lines="none"
        >
          <ion-label>{{int?.label}}</ion-label>
          <ion-badge slot="end" *ngIf="int.badgeCount>0" [class.alert-badge]="menu.isAlert">
            {{int.badgeCount}}
          </ion-badge>
        </ion-item>
      </div>
    </ion-item-group>

    <div class="contactButton" (click)="gotoContact()">
      <button ion-button full><i class="codere-icon icon-contact iconwhite"></i>{{contact}}</button>
    </div>
  </ion-list>
</ion-content>
