<!-- eslint-disable @angular-eslint/template/eqeqeq -->
<ng-container *ngIf="isMobile; then mobileTemplate; else deskTemplate"></ng-container>
<ng-template #deskTemplate>
  <ion-content>
    <ion-toolbar class="toolbarSectionName"> {{'downloadApp' | translate}} </ion-toolbar>
    <iframe class="iframeFullScreen" [src]="scrHelp"></iframe>
    <codere-footer></codere-footer>
  </ion-content>
</ng-template>
<ng-template #mobileTemplate>
  <ion-header>
    <ion-toolbar class="toolbarSectionName">
      <back-button tappable></back-button>
      {{'downloadApp' | translate}}
    </ion-toolbar>
  </ion-header>
  <ion-content>
    <ion-list *ngIf="globalVars.licenseType != 4 && globalVars.licenseType != 21" class="downloadandroid">
      <div class="boxDeposit">
        <div class="downloadapp">
          <a
            class="mandroidco"
            href="https://play.google.com/store/apps/details?id=spain.codere.apuestas"
            [track-event]="['downloadApp', 'Android', '', '', 'event']"
          ></a>
        </div>

        <div class="downloadapp" *ngIf="!viewAndroid">
          <a
            href="https://itunes.apple.com/es/app/codere-appuestas/id917178660?mt=8"
            [track-event]="['downloadApp', 'IOS', '', '', 'event']"
            class="mios"
          ></a>
        </div>
      </div>
      <div *ngIf="viewAndroid">
        <div class="txt1instal">Descarga la APP de Codere Apuestas para Android.</div>
        <div class="downloadback">
          <div class="instalapktitstep1 fontw700">Sigue estos sencillos pasos:</div>
          <div class="stepsdiv">
            <div>
              <span class="numstep">1- </span><span>Accede a <strong>Ajustes</strong></span>
            </div>
            <div>
              <span class="numstep">2- </span
              ><span
                >Selecciona <strong>Seguridad</strong> (dependiendo de tu versi&oacute;n de Android lo
                encontrar&aacute;s en M&aacute;s).</span
              >
            </div>
            <div>
              <span class="numstep">3- </span
              ><span
                >Marca la casilla <strong>de "Fuentes desconocidas"</strong> u
                <strong>"Or&iacute;genes desconocidos"</strong>.</span
              >
            </div>
            <div>
              <span class="numstep">4- </span><span>Pulsa <strong>Aceptar</strong> para confirmarlo.</span>
            </div>
          </div>
          <div class="sendForm">
            <a
              *ngIf="globalVars.FEATURES?.linkNativeDownload =='' "
              [track-event]="['downloadApp', 'Android', '', '', 'event']"
              class="disable-hover button button-md button-default button-default-md button-full button-full-md"
              href="https://m.apuestas.codere.es/android/codereAPPuestas.apk"
              target="_blank"
              external-link
            >
              <img src="assets/global/img/icon_android.png" alt="Android" /> Descarga tu app
            </a>
            <a
              *ngIf="globalVars.FEATURES?.linkNativeDownload !='' "
              [track-event]="['downloadApp', 'Android', '', '', 'event']"
              class="disable-hover button button-md button-default button-default-md button-full button-full-md"
              href="{{globalVars.FEATURES?.linkNativeDownload}}"
              target="_blank"
              external-link
            >
              <img src="assets/global/img/icon_android.png" alt="Android" /> Descarga tu app
            </a>
          </div>
        </div>
        <div class="txt1instal1">
          Esta es la app oficial para Android de Codere Apuestas. La instalaci&oacute;n de esta app NO supondr&aacute;
          ning&uacute;n da&ntilde;o para tu dispositivo a pesar del mensaje de adventencia que aparece tras pulsar el
          bot&oacute;n de "Descargar".
        </div>
      </div>
    </ion-list>
    <ion-list *ngIf="globalVars.licenseType == 4" class="downloadandroid">
      <div class="boxDeposit">
        <div class="downloadapp" (click)="click_AndroidButton()">
          <span class="mandroid mandroides"></span>
        </div>
        <div class="downloadapp" *ngIf="!viewAndroid">
          <a
            href="https://itunes.apple.com/mx/app/codere-appuestas-mx/id1185542560?mt=8"
            [track-event]="['downloadApp', 'IOS', '', '', 'event']"
            class="mios"
          ></a>
        </div>
      </div>
      <div *ngIf="viewAndroid">
        <div class="txt1instal">Descarga la APP de Codere para Android.</div>
        <div class="downloadback">
          <div class="instalapktitstep1 fontw700">Sigue estos sencillos pasos:</div>
          <div class="stepsdiv">
            <div>
              <span class="numstep">1- </span><span>Accede a <strong>Ajustes</strong></span>
            </div>
            <div>
              <span class="numstep">2- </span
              ><span
                >Selecciona <strong>Seguridad</strong> (dependiendo de tu versi&oacute;n de Android lo
                encontrar&aacute;s en M&aacute;s).</span
              >
            </div>
            <div>
              <span class="numstep">3- </span
              ><span
                >Marca la casilla <strong>de "Fuentes desconocidas"</strong> u
                <strong>"Or&iacute;genes desconocidos"</strong>.</span
              >
            </div>
            <div>
              <span class="numstep">4- </span><span>Pulsa <strong>Aceptar</strong> para confirmarlo.</span>
            </div>
          </div>
          <div class="sendForm">
            <a
              class="disable-hover button button-md button-default button-default-md button-full button-full-md"
              [track-event]="['downloadApp', 'Android', '', '', 'event']"
              href="https://m.codere.mx/android/codereAPPuestasMX.apk"
              target="_blank"
              external-link
            >
              <img src="assets/global/img/icon_android.png" alt="Android" /> Descarga tu app
            </a>
          </div>
        </div>
        <div class="txt1instal1">
          Esta es la app oficial para Android de Codere Apuestas. La instalaci&oacute;n de esta app NO supondr&aacute;
          ning&uacute;n da&ntilde;o para tu dispositivo a pesar del mensaje de adventencia que aparece tras pulsar el
          bot&oacute;n de "Descargar".
        </div>
      </div>
    </ion-list>
    <ion-list *ngIf="globalVars.licenseType == 21" class="downloadandroid">
      <div class="boxDeposit">
        <div class="downloadapp">
          <a
            class="mandroidco"
            href="https://play.google.com/store/apps/details?id=co.codere.apuestas"
            [track-event]="['downloadApp', 'Android', '', '', 'event']"
          ></a>
        </div>
        <div class="downloadapp" *ngIf="!viewAndroid">
          <a
            href="https://itunes.apple.com/co/app/id1313823849"
            [track-event]="['downloadApp', 'IOS', '', '', 'event']"
            class="mios"
          ></a>
        </div>
      </div>
    </ion-list>

    <codere-footer></codere-footer>
  </ion-content>
</ng-template>
