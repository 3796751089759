/* eslint-disable */
import { inject } from '@angular/core';
import { BBVAMexDepositActionResult } from '../../models/C_Transactions';
import { PaymentMethodBehaviour, RedirectMethodBehaviourResult, SkipAmountResult } from './PaymentMethodBase';
import { UserService } from '../UserService';
import { ErrorService } from '../ErrorService';
import { GlobalVarsModel } from '../../models/ModelGlobalsVar';
import { NewBaseService } from '../newBase.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class VisaBBVAMexPaymentMethodBehaviour extends PaymentMethodBehaviour {
  name = 'BANCOMER';
  image = 'bancomer0';
  track = 'AccessToBancomer';
  isBank = false;
  minDepositAmount = 100;
  maxDepositAmount = 999999999;
  amountButtons: number[] = [300, 400, 800, 100];
  override amount = 400;

  deviceType: string;
  globalVars!: GlobalVarsModel;
  newBaseService = inject(NewBaseService);

  constructor(userService: UserService, errorService: ErrorService) {
    super(false, userService, errorService);
    this.newBaseService.getVars.subscribe((data) => {
      this.globalVars = data;
    });
  }

  skipAmountInput(): Observable<SkipAmountResult> {
    return new Observable<SkipAmountResult>();
  }

  paymentMetodExecution(): Observable<RedirectMethodBehaviourResult> {
    return this.userService
      .validateBancomerDep(this.amount, this.deviceType)
      .pipe(map((x) => this.map(x, this.globalVars)));
  }

  private map(input: BBVAMexDepositActionResult, globalVars: GlobalVarsModel): RedirectMethodBehaviourResult {
    const result = new RedirectMethodBehaviourResult();
    if (input.success) {
      result.success = true;
      result.url =
        globalVars.UrlTransactionsApiBase +
        'Payments/GetBBVAMexDepositFormRequest' +
        '?' +
        'mp_account=' +
        input.mp_account +
        '&mp_amount=' +
        input.mp_amount +
        '&mp_concept=' +
        input.mp_concept +
        '&mp_currency=' +
        input.mp_currency +
        '&mp_customername=' +
        input.mp_customername +
        '&mp_node=' +
        input.mp_node +
        '&mp_order=' +
        input.mp_order +
        '&mp_product=' +
        input.mp_product +
        '&mp_reference=' +
        input.mp_reference +
        '&mp_signature=' +
        input.mp_signature +
        '&mp_urlfailure=' +
        input.mp_urlfailure +
        '&mp_urlsuccess=' +
        input.mp_urlsuccess +
        '&mp_email=' +
        input.mp_email +
        '&mp_phone=' +
        input.mp_phone +
        '&mp_promo=' +
        input.mp_promo +
        '&mp_url=' +
        input.url;
    } else {
      result.success = false;
      result.erroMessage = this.errorService.searchErrorCodeWithParams('error' + input.errCode, input.errorParameters);
      result.errorcode = input.errCode;
      result.sendToDocumentVerificationPage = input.errCode == 174 || input.errCode == 2212;
    }
    return result;
  }
}
