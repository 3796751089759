import { CommonModule } from "@angular/common";
import { Component, ViewChild, inject } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { Router } from "@angular/router";
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AlertController, IonContent, IonicModule } from "@ionic/angular";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { EventsService } from "@providers/EventsService";
import { TrackingService } from "@providers/TrackingService";
import { UserServiceMSO } from "@providers/UserServiceMSO";
import { DeviceService } from "@services/device.service";
import { MSO_PATHS } from "@shared-constants/routes";
import { CodereFooterComponent } from "@shared-mso-components/footer/codere-footer/codere-footer";
import { UserSectionTitle } from "@shared-mso-components/user-section-title/user-section-title";
import { Utils } from "@utils/Utils";

@Component({
  standalone: true,
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "quickExit-page",
  templateUrl: "./quickExit.html",
  styleUrls: ["./quickExit.scss"],
  imports: [
    IonicModule,
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    UserSectionTitle,
    CodereFooterComponent,
  ],
})
export class QuickExit {
  @ViewChild(IonContent) content: IonContent;
  myForm: FormGroup;
  selfEx: any;
  dayMonth: Array<string>;
  months: Array<{ label: string; data: string }>;
  years: Array<number>;
  validDate: boolean;
  public trackEvent: TrackEvent;
  alertConfirm: HTMLIonAlertElement;

  globalVars!: GlobalVarsModel;
  isMobile: boolean;

  utils = inject(Utils);
  translate = inject(TranslateService);
  events = inject(EventsService);
  trackingService = inject(TrackingService);
  fb = inject(FormBuilder);
  alertController = inject(AlertController);
  device = inject(DeviceService);
  userServiceMSO = inject(UserServiceMSO);
  router = inject(Router);

  constructor() {
    this.isMobile = this.device.isMobile();
    this.selfEx = {
      day: "",
      month: "",
      year: "",
    };
    this.myForm = this.fb.group({
      day: ["", Validators.required],
      month: ["", Validators.required],
      year: ["", Validators.required],
    });

    this.dayMonth = [];
    for (let i = 1; i <= 31; i++) {
      this.dayMonth.push(i < 10 ? "0" + i.toString() : i.toString());
    }
    const arrMeses = [
      this.translate.instant("rMEn"),
      this.translate.instant("rMFe"),
      this.translate.instant("rMMa"),
      this.translate.instant("rMAv"),
      this.translate.instant("rMMy"),
      this.translate.instant("rMJn"),
      this.translate.instant("rMJl"),
      this.translate.instant("rMAg"),
      this.translate.instant("rMSe"),
      this.translate.instant("rMOc"),
      this.translate.instant("rMNo"),
      this.translate.instant("rMDi"),
    ];

    this.months = [];

    for (let i = 0; i < 12; i++) {
      this.months.push({ label: arrMeses[i], data: i < 9 ? "0" + (i + 1).toString() : (i + 1).toString() });
    }

    this.years = [];
    const nowYearDD: number = new Date().getFullYear();
    const maxYearDD = nowYearDD + 100;
    for (let i = nowYearDD; i < maxYearDD; i++) {
      this.years.push(i);
    }

    this.myForm.controls["day"].valueChanges.subscribe((value: string) => {
      this.checkValidDate();
    });
    this.myForm.controls["month"].valueChanges.subscribe((value: string) => {
      this.checkValidDate();
    });
    this.myForm.controls["year"].valueChanges.subscribe((value: string) => {
      this.checkValidDate();
    });
    this.createAlertConfirm();
  }

  async createAlertConfirm() {
    this.alertConfirm = await this.alertController.create({
      header: this.translate.instant("CodereCardconfir") + " " + this.translate.instant("quickexit"),
      // 'Confirmar' 'Autoexclusión',
      //
      message: this.translate.instant("quickexitquestion"),
      // '¿Esta seguro de que desea ejecutar la salida rapida?'
      buttons: [
        {
          text: "NO",
          handler: () => this.createAlertConfirm(),
          role: "cancel",
        },
        {
          text: "Sí",
          handler: () => this.finalSalidaRapida(),
        },
      ],
    });
  }

  ionViewWillEnter() {
    this.router.navigate([`${MSO_PATHS.BASE}/${MSO_PATHS.QuickExit}`]);
    this.trackingService.trackEvent(["AccessToSelfExclusion", "", "", "", "event"]);
    this.events.publish("footer:refresh", this.content);
  }

  goToSelfExclusion() {
    this.router.navigate([`${MSO_PATHS.BASE}/${MSO_PATHS.SelfExclusionPage}`]);
  }

  exitClick() {
    console.info("Confirmar Salida rápida");
    this.alertConfirm.present();
  }

  finalSalidaRapida() {
    console.info("Salida rápida");
    this.userServiceMSO.createExitQuitAR().subscribe((resp) => {
      this.onQuitExit(resp);
    });
  }

  onQuitExit(data) {
    if (data.success) {
      this.utils.alert(true, "", this.translate.instant("exclusiontxt011"), "OK");
      this.events.publish("logout");
      console.info("Salida Rápida ejecutada correctamente");
    } else {
      this.utils.alert(true, "", this.translate.instant("exclusiontxt021"), "OK");
      console.info("Salida Rápida fallida");
    }
    this.router.navigate(["/"]);
  }

  checkValidDate() {
    //checks if the date is valid
    if (!!this.selfEx.day && !!this.selfEx.month && !!this.selfEx.year) {
      //check the date
      const miDate: Date = new Date(
        parseInt(this.selfEx.year),
        parseInt(this.selfEx.month) - 1,
        parseInt(this.selfEx.day),
        0,
        0,
        0,
        0,
      );

      if (
        miDate.getDate() === parseInt(this.selfEx.day) &&
        miDate.getMonth() === parseInt(this.selfEx.month) - 1 &&
        miDate.getFullYear() === parseInt(this.selfEx.year)
      ) {
        this.validDate = miDate > new Date();
      } else {
        this.validDate = false;
      }
    } else {
      this.validDate = false;
    }
  }
}
