import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getNameGame',
  standalone: true
})
export class GetNameGamePipe implements PipeTransform {
  transform(value: string, args: string[]): string {
    if (!args[0]) return value;
    const place = args[0].toString();

    switch (place) {
      case '0':
        return value;
      case '1':
        return 'Ganador';
      case '2':
      default:
        if (parseInt(args[1]) !== 4) return 'GC';
        else return 'W/P';
    }
  }
}
