/* eslint-disable @typescript-eslint/no-explicit-any */
import { AfterViewChecked, Component, OnInit, ViewChild, inject } from '@angular/core';
import { FormControlStatus, FormGroup } from '@angular/forms';
import { IonContent, IonRouterOutlet, IonicModule, NavController } from '@ionic/angular';
import { BehaviorSubject, combineLatest, filter, map, Observable } from 'rxjs';
import { Buttons, DEV } from '@interfaces/interfaces';
import { CommonModule } from '@angular/common';
import { ValidationService } from '../../../services/registro/validation.service';
import { EventsService } from '@providers/EventsService';
import { MSO_PATHS } from '@shared-constants/routes';
import { Router, Params, NavigationExtras } from '@angular/router';
import { InfoTopComponent } from '../../../components/registro/info-top/info-top.component';
import { FsPersonalComponent } from '../../../components/registro/fs-personal/fs-personal.component';
import { FsContactoComponent } from '../../../components/registro/fs-contacto/fs-contacto.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'cdr-paso1',
  templateUrl: './paso1.html',
  standalone: true,
  styleUrls: ['./paso1.scss'],
  imports: [CommonModule, IonicModule, InfoTopComponent, FsPersonalComponent, FsContactoComponent]
})
export class Paso1Page implements OnInit, AfterViewChecked {
  @ViewChild(IonContent) content: IonContent;
  buttons: Buttons;
  isResident$: Observable<boolean>; //ionic param
  fgroup: FormGroup; // ionic param
  formPers: FormGroup;
  formCont: FormGroup;
  canShowSuportNumber: BehaviorSubject<boolean>;
  params: Params;

  vals = inject(ValidationService);
  ionRouterOutlet = inject(IonRouterOutlet);
  router = inject(Router);
  events = inject(EventsService);
  navCtrl = inject(NavController);

  constructor() {
    const navigation = this.router.getCurrentNavigation();
    this.params = navigation.extras.queryParams;
    this.fgroup = this.params['formReg'];
    this.formPers = this.fgroup?.get('personal') as FormGroup;
    this.formCont = this.fgroup?.get('contacto') as FormGroup;
    this.isResident$ = this.params['isResident'];
    this.canShowSuportNumber = this.params['canShowSuportNumber'];
    this.events.subscribe('doScrollToTop', () => {
      this.content.scrollToTop();
    });
  }
  ngOnInit() {
    this.buttons = {
      next: 'Siguiente',
      back: 'Volver',
      endRegister: ''
    };
  }

  ngAfterViewChecked(): void {
    this.resizeContent();
  }
  ionViewWillLeave() {
    if (DEV) {
      // en dev no se valida para cambiar de paso
      return;
    }
    if (!this.formPers.valid || !this.formCont.valid) {
      this.vals.validateAllFormFields(this.formPers); // personal
      this.vals.validateAllFormFields(this.formCont); // contacto
      throw new Error('Form validation error!');
    }
  }

  /**
   * Manejador del evento click del botón "siguiente"
   */
  onClickNext() {
    this.vals.validateAllFormFields(this.formPers); // personal
    this.vals.validateAllFormFields(this.formCont); // contacto
    this.formPers.get('profesion').markAsDirty();

    const STATUS_TO_CHECK: FormControlStatus = 'PENDING';

    if (this.formCont.status === STATUS_TO_CHECK || this.formPers.status === STATUS_TO_CHECK) {
      const observablesForm: Observable<FormControlStatus>[] = [];

      if (this.formCont.status === STATUS_TO_CHECK) {
        observablesForm.push(this.formCont.statusChanges);
      }

      if (this.formPers.status === STATUS_TO_CHECK) {
        observablesForm.push(this.formPers.statusChanges);
      }

      const $formContSubscription = combineLatest(observablesForm)
        .pipe(
          map((values) => values.includes(STATUS_TO_CHECK)),
          filter((res) => !res)
        )
        .subscribe((_) => {
          if (this.formCont.valid && this.formPers.valid) {
            this.navigateToPaso2();
          }

          $formContSubscription.unsubscribe();
        });
    } else if (this.formCont.valid && this.formPers.valid) {
      this.navigateToPaso2();
    }
  }
  navigateToPaso2() {
    const navigationExtras: NavigationExtras = {
      skipLocationChange: true,
      queryParams: {
        formReg: this.fgroup
      }
    };

    this.navCtrl.navigateForward(
      [`/${MSO_PATHS.BASE}/${MSO_PATHS.RegistroNewPage}/${MSO_PATHS.Paso2Page}`],
      navigationExtras
    );
  }
  resizeContent() {
    const fixedDiv = document.querySelector('.info-section') as HTMLElement;
    const responsiveDiv = document.querySelector('.content-section') as HTMLElement;
    const fixedDivHeight = fixedDiv.offsetHeight;
    responsiveDiv.style.top = fixedDivHeight + 2 + 'px';
  }
}
