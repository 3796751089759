export class C_NodeTicket {
  DayOrderNr: number;
  betHistoryInfo: any[];
  TicketsDatetime: string;
  BetsPropsBets: Array<any>;
  Amount: number = null;
  Status: string = null;
  StatusCode: number = null;
}

export class C_Ticket {
  betEvents: C_BetEvent[];
  Amount: any;
  TicketOrderNr: number;
  TicketDatetime: string;
  BetsNumber: number;
  TicketStatus: string;
  TicketStatusNumber: string;
  BankCount: number;
  BetTypeNumber: string;
  BetType: string;
  VoucherCode: string;
  CashOutStatus: number;
  TicketNumber: string;
  CashOutAmount: number;
  PotentialWinnings: number;
  PotentialWinningsOriginal: number;
  X: number;
  Y: number;
  CancelationReason: string;
  Stake: number;
  AccumulatorOpened: boolean;
  accumulatorOpened: boolean;
  TicketDetails: any;

  constructor() {
    this.betEvents = new Array<C_BetEvent>();
    this.AccumulatorOpened = false;
  }
}

export class C_BetEvent {
  MarketName: string;
  ResultName: string;
  ClearingStatusNumber: string;
  IsBank: boolean;
  CountryName: string;
  LeagueName: string;
  EventDatetime: string;
  SportName: string;
  EventName: string;
  ClearingStatus: string;
  Odd: number;
  ResultsNr: number;
  SmartMarketItems?: {
    Name: string;
    GameName: string;
  }[];
}

export class C_DetailTicket {
  BetHistoryInfo: C_Ticket;
  Amount: number;
  Status: string;
  StatusCode: number;

  constructor() {
    this.BetHistoryInfo = new C_Ticket();
    this.Amount = 0;
    this.Status = null;
    this.StatusCode = 0;
  }
}
