/* eslint-disable */

import { CUSTOM_ELEMENTS_SCHEMA, Component, ViewChild, inject } from "@angular/core";
import { IonicModule, MenuController, Platform } from "@ionic/angular";

import { CommonModule } from "@angular/common";
import { FormBuilder, type FormControl, type FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { LicenseTypes } from "@models/MasterData";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ContactService } from "@providers/ContactService";
import { FooterService } from "@providers/FooterService";
import { TrackingService } from "@providers/TrackingService";
import { UserService } from "@providers/UserService";
import { NewBaseService } from "@providers/newBase.service";
import { DeviceService } from "@services/device.service";
import { MSO_PATHS } from "@shared-constants/routes";
import { CodereFooterComponent } from "@shared-mso-components/footer/codere-footer/codere-footer";
import { Utils } from "@utils/Utils";
import { CustomSelect } from "../../../components/custom-select/custom-select";
import { contactAutoresponse } from "../contact-autoresponse/contact-autoresponse";
import { contactFormCredentials } from "../contact-form-credentials/contact-form-credentials";
import { contactForm } from "../contact-form/contact-form";
import { contactResume } from "../contact-resume/contact-resume";
import { contactWithUs } from "../contact-with-us/contact-with-us";

@Component({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  selector: "contact-page",
  templateUrl: "./contact.html",
  styleUrls: ["./contact.scss"],
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    CodereFooterComponent,
    CustomSelect,
    contactAutoresponse,
    contactForm,
    contactFormCredentials,
    contactResume,
    contactWithUs,
  ],
})
export class ContactPage {
  @ViewChild("selectCategories") selectCategories;
  @ViewChild("selectSubjects") selectSubjects;
  myForm: FormGroup;
  captchaUrl: string;

  userData: any;
  nombreApellido: string;
  chatSrc: string;

  categories: any = [];
  subjects: any = [];
  responses = null;
  subjectSelected = "";
  isAutoResponse = null;
  loadingSubjects = false;
  categoryChanged = false;
  loadingService = true;
  errService = false;
  showForm = false;
  responseUseful = null;
  subjectSelectedId = null;
  showLastPage = false;
  lastPage = null;
  showFormCredentials = false;
  messageDescription = null;
  isDesktop = false;
  isMobile: boolean;
  globalVars!: GlobalVarsModel;

  fb = inject(FormBuilder);
  newBaseService = inject(NewBaseService);
  utils = inject(Utils);
  userService = inject(UserService);
  menu = inject(MenuController);
  contactService = inject(ContactService);
  trackingService = inject(TrackingService);
  translate = inject(TranslateService);
  footerService = inject(FooterService);
  router = inject(Router);
  platform = inject(Platform);
  deviceService = inject(DeviceService);
  device = inject(DeviceService);

  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
    this.isDesktop = this.deviceService.isDesktop();
    this.isMobile = this.device.isMobile();

    this.resetForm();

    this.userData = {};
    this.nombreApellido = "";

    this.chatSrc = "assets/global/img/ico-chat-online-CSS.png";

    // this.app = app;

    this.newCaptcha();

    if (this.globalVars.user.logged) {
      this.loadUserData();
    }
  }

  ngOnInit() {
    if (this.globalVars.FEATURES.contactServiceEnabled) {
      this.fetchCategories();
    }
    this.footerService.loadFooter().then((data) => {});
  }

  resetSubjects() {
    this.selectSubjects.selectedValue = null;
  }

  handleResponseUseful(hasBeenUsefull) {
    this.responseUseful = hasBeenUsefull;
    if (!hasBeenUsefull) {
      this.showForm = true;
    } else {
      this.showFormCredentials = true;
      if (this.globalVars.user.logged) {
        this.handleSubmitCredentialsLogged();
      }
    }
  }

  resetAll() {
    this.fetchCategories();
    this.subjects = [];
    this.messageDescription = null;
    this.responses = null;
    this.subjectSelected = "";
    this.isAutoResponse = null;
    this.errService = false;
    this.showForm = false;
    this.showLastPage = false;
    this.showFormCredentials = false;
    this.responseUseful = null;
    this.lastPage = null;
  }

  handleCategoryChanged(category) {
    this.subjects = null;
    this.selectSubjects.selectedValue = null;
    this.messageDescription = null;
    this.isAutoResponse = null;
    this.showForm = false;
    this.contactService
      .getSubjects(category.id)
      .toPromise()
      .then((values) => {
        this.subjects = values;
      })
      .catch(() => {
        this.errService = true;
      });
  }

  handleSubmit(textarea) {
    this.loadingService = true;
    const callModel = {
      playerName: this.globalVars.user.username,
      subjectId: this.subjectSelectedId,
      requiresMoreInfo: this.responseUseful,
      customerMessage: textarea,
    };

    const lastPage = {
      subject: this.subjectSelected,
      response: this.messageDescription,
      question: textarea,
      underText: this.translate.instant("tContactRepresent"),
    };

    this.contactService
      .postContactForm(callModel)
      .toPromise()
      .then(() => {
        this.showLastPage = true;
        this.lastPage = lastPage;
        this.loadingService = false;
      })
      .catch(() => {
        this.errService = true;
        this.loadingService = false;
      });
  }

  handleSubmitCredentials({ username, email }) {
    this.loadingService = true;
    const callModel = {
      firstName: username,
      email: email,
      subjectId: this.subjectSelectedId,
      requiresMoreInfo: this.responseUseful,
      customerMessage: "",
    };

    const lastPage = {
      subject: this.subjectSelected,
      response: this.messageDescription,
      underText: this.translate.instant("tContactRepresent"),
    };

    this.contactService
      .postContactForm(callModel)
      .toPromise()
      .then(() => {
        this.showLastPage = true;
        this.lastPage = lastPage;
        this.loadingService = false;
      })
      .catch(() => {
        this.errService = true;
        this.loadingService = false;
      });
  }

  handleSubmitCredentialsLogged() {
    this.loadingService = true;
    const callModel = {
      playerName: this.globalVars.user.username,
      subjectId: this.subjectSelectedId,
      requiresMoreInfo: this.responseUseful,
      customerMessage: "",
    };

    const lastPage = {
      subject: this.subjectSelected,
      response: this.messageDescription,
      underText: this.translate.instant("tContactRepresent"),
    };

    this.contactService
      .postContactForm(callModel)
      .toPromise()
      .then(() => {
        this.showLastPage = true;
        this.lastPage = lastPage;
        this.loadingService = false;
      })
      .catch(() => {
        this.errService = true;
        this.loadingService = false;
      });
  }

  handleSubmitWithCredentials({ textarea, username, email }) {
    const callModel = {
      firstName: username,
      email: email,
      subjectId: this.subjectSelectedId,
      requiresMoreInfo: this.responseUseful,
      customerMessage: textarea,
    };

    const lastPage = {
      subject: this.subjectSelected,
      question: textarea,
      response: this.messageDescription,
      underText: this.translate.instant("tContactRepresent"),
    };
    this.loadingService = true;
    this.contactService
      .postContactForm(callModel)
      .toPromise()
      .then(() => {
        this.showLastPage = true;
        this.lastPage = lastPage;
        this.loadingService = false;
      })
      .catch(() => {
        this.errService = true;
        this.loadingService = false;
      });
  }

  handleSubjectSelected(subject) {
    this.loadingService = true;
    this.loadingSubjects = true;
    this.responseUseful = null;
    this.messageDescription = null;
    this.responseUseful = null;
    this.isAutoResponse = null;
    this.contactService
      .getSubject(subject.id)
      .toPromise()
      .then((values) => {
        this.responses = values;
        this.messageDescription = values.autoResponseText;
        this.loadingService = false;
        this.loadingSubjects = false;
      })
      .catch(() => {
        this.errService = true;
        this.loadingService = false;
        this.loadingSubjects = false;
      });
    this.isAutoResponse = subject.isAutoResponse;
    this.showForm = !subject.isAutoResponse;
    // this.responses = [{ id: '0', name: 'lorem', description: 'description' }, { id: '1', name: 'ipsum', description: 'description1' }, { id: '2', name: 'mock', description: 'description2' }]
    this.subjectSelectedId = subject.id;
    this.subjectSelected = subject.name;
  }

  fetchCategories() {
    // this.loadingService = false
    // this.categories = [{ id: '0', name: 'lorem' }, { id: '1', name: 'ipsum' }, { id: '2', name: 'mock' }]
    this.loadingService = true;
    this.contactService
      .getCategories()
      .toPromise()
      .then((values) => {
        this.categories = values;
        this.loadingService = false;
      })
      .catch(() => {
        this.errService = true;
        this.loadingService = false;
      });
  }

  openContact() {
    this.router.navigate([MSO_PATHS.BASE + "/" + MSO_PATHS.ContactPage]), { animate: false };
  }

  ionViewDidEnter() {
    if (this.globalVars.FEATURES.contactServiceEnabled) {
      this.utils.loadContactChat(true);
    } else {
      this.loadingService = false;
      this.utils.loadContactChat(false);
    }

    if (this.globalVars.LICENSE_TYPE === LicenseTypes.Nacional) {
      document.getElementById("spacerFooter").style.backgroundColor = "#e8e8e8";
    }
  }

  ionViewWillLeave(event) {
    this.utils.unloadContactChat();
  }

  loadUserData() {
    this.userService.getUserData().subscribe(
      (data) => {
        this.userData = data;
        this.nombreApellido = data.Nombre + " " + data.PrimerApellido;
      },
      (err) => {},
    );
  }

  openChat() {
    this.trackingService.trackEvent(["runChat", "contact", "", "", "event"]);
    this.utils.openContactChat();
  }

  resetForm() {
    if (this.globalVars.licenseType === LicenseTypes.Mexico) {
      this.myForm = this.fb.group({
        nombre: ["", Validators.required],
        email: ["", [Validators.required, this.emailValidator]],
        movil: ["", Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(10)])],
        comentarios: ["", Validators.required],
        privacidad: [false, Validators.required],
        captcha: ["", Validators.required],
      });
    } else {
      this.myForm = this.fb.group({
        nombre: ["", Validators.required],
        email: ["", [Validators.required, this.emailValidator]],
        movil: ["", this.phoneValidator],
        comentarios: ["", Validators.required],
        privacidad: [false, Validators.required],
        captcha: ["", Validators.required],
      });
    }
  }

  phoneValidator = (control: FormControl) => {
    const regExpPhone: RegExp = /^[6|7|8][0-9]{8}$/;

    if (control.value) {
      if (control.value.length > 0) {
        if (regExpPhone.test(control.value)) {
          return null;
        } else {
          return { validFormat: "notValid" };
        }
      } else {
        return null;
      }
    } else {
      return null;
    }
  };
  emailValidator = (control: FormControl) => {
    const regExpEmail: RegExp =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (regExpEmail.test(control.value)) {
      return null;
    } else {
      return { validFormat: "notValid" };
    }
  };
  newCaptcha() {
    this.captchaUrl = this.globalVars.URLBASE + "/ViewTicket/CaptchaImage?_=" + Math.floor(Math.random() * 99999);
  }

  unloadChat() {
    //TODO BAU// ¿hay una forma mejor de hacer esto?
    const element = document.getElementById("snapengage-button");
    element.parentNode.removeChild(element);

    const element2 = document.getElementById("snapengage-mobile");
    element.parentNode.removeChild(element2);

    const element3 = document.getElementById("SnapABug_Button");
    element.parentNode.removeChild(element3);

    //$("body").removeClass("snapengage-noscroll");
  }

  doSendForm() {
    let validForm = false;
    if (
      this.myForm.controls["nombre"].valid &&
      this.myForm.controls["email"].valid &&
      this.myForm.controls["comentarios"].valid &&
      this.myForm.controls["captcha"].valid &&
      this.myForm.controls["privacidad"].value
    ) {
      validForm = true;
    }

    if (validForm) {
      //
      this.userService
        .sendHelpMail(
          this.myForm.controls["nombre"].value,
          this.myForm.controls["email"].value,
          this.myForm.controls["movil"].value,
          this.myForm.controls["comentarios"].value,
          this.myForm.controls["captcha"].value,
        )
        .subscribe(
          (data) => {
            if (data.status === "ok") {
              this.utils.showMsg(this.translate.instant("m_menu_mailOk"), () => {
                //clear form
                this.resetForm();
              });
            } else {
              if (data.errCode === "CaptchaFailure") {
                this.utils.showError(this.translate.instant("captchaFail"));
              } else {
                this.utils.showError(this.translate.instant("m_menu_serverFail"));
              }
            }
          },
          () => {
            this.utils.showError(this.translate.instant("m_menu_serverFail"));
          },
        );
    } else {
      for (const x in this.myForm.controls) {
        this.myForm.controls[x].markAsTouched();
      }
    }
  }

  openCallMe() {
    //go to callMe
    this.router.navigate([MSO_PATHS.BASE + "/" + MSO_PATHS.CallMePage]), { animate: !this.isDesktop };
  }

  openPoliticaPrivacidad($event) {
    this.router.navigate([`/${MSO_PATHS.BASE}/${MSO_PATHS.InformationPage}`], {
      queryParams: { type: "politicapriv", animate: !this.isDesktop },
    });
    $event.preventDefault();
  }
}
