<!-- 
    1 - info : untouched
    2 - bad : touched + no valid + no focus + 
    3 - good: valid + no focus
 -->

<ng-container *ngIf="fgrp && fcName; else alone">
  <ng-container [formGroup]="fgrp">
    <ion-icon
      name="information-circle-outline"
      *ngIf="fgrp.get(fcName).pristine || (fgrp.get(fcName).valid && !fgrp.get(fcName).value)"
      tabindex="-1"
      [href]="target"
      size="large"
      class="modal-trigger"
      class="transparent tooltip-icon grey-text"
      (click)="presentPopover(target)"
    >
    </ion-icon>
    <ion-icon
      name="close-circle-outline"
      class="iconred"
      size="large"
      *ngIf="fgrp.get(fcName).dirty && fgrp.get(fcName).invalid"
      class="transparent tooltip-icon red-text"
      (click)="deleteValue()"
    >
    </ion-icon>
    <ion-icon
      name="checkmark-circle-outline"
      size="large"
      *ngIf="fgrp.get(fcName).touched && fgrp.get(fcName).valid && fgrp.get(fcName).value"
      class="transparent tooltip-icon green-text"
    >
    </ion-icon>
  </ng-container>
</ng-container>
<ng-template #alone>
  <ion-icon
    name="information-circle-outline"
    tabindex="-1"
    [href]="target"
    class="modal-trigger"
    class="transparent tooltip-icon grey-text"
    size="large"
    (click)="presentPopover(target)"
  >
  </ion-icon>
</ng-template>
