<ng-container *ngIf="globalVars.FEATURES.CAS_NewCalendar">
  <ng-container *ngIf="!isMobile; else mobileView">
    <ion-header>
      <div class="header">
        <h4 class="header-title">{{ 'releasecalendar' | translate }}</h4>
        <i item-right class="codere-icon icon-close header-close" (click)="closeModal()"></i>
      </div>
      <div class="header-rings"><img [src]="getImage('assets/global/img/CalendarRings_Desktop.svg')" /></div>
    </ion-header>
    <ion-tab-bar slot="fixed" class="buttons_container">
      <button class="buttons_container__button" (click)="clickSection('inactive')">
        <span class="color-light">{{ 'viewupcomingreleases' | translate }}</span>
      </button>
      <button class="buttons_container__button" (click)="clickSection('active')">
        <span class="color-light">{{ 'seegamesavailable' | translate }}</span>
      </button>
    </ion-tab-bar>
    <ion-content class="background-color-dark" [scrollEvents]="true">
      <div class="container">
        <p class="title-section">{{ 'upcomingreleases' | translate }}</p>
        <ng-container *ngFor="let item of finalSections$ | async; let isLast=last">
          <p class="title-section lastSection" *ngIf="isLast">{{ 'gamesalreadyavailable' | translate }}</p>
          <lb-calendar-section
            *ngIf="item.games.length > 0"
            [section]="item"
            (turnPlay)="openGame()"
          ></lb-calendar-section>
        </ng-container>
      </div>
    </ion-content>
  </ng-container>

  <ng-template #mobileView>
    <div class="header-rings"><img [src]="getImage('assets/global/img/CalendarRings_Mobile.svg')" /></div>
    <div class="header">
      <h5 class="header-title">{{ 'releasecalendar' | translate }}</h5>
      <i item-right class="codere-icon icon-close header-close" (click)="closeModal()"></i>
    </div>
    <ion-tab-bar slot="fixed" color="black" class="buttons_container">
      <button class="buttons_container__button" (click)="clickSection('inactive')">
        <span class="color-light">{{ 'viewupcomingreleases' | translate }}</span>
      </button>
      <button class="buttons_container__button" (click)="clickSection('active')">
        <span class="color-light">{{ 'seegamesavailable' | translate }}</span>
      </button>
    </ion-tab-bar>
    <ion-content class="container-calendar background-color-dark" [scrollEvents]="true">
      <div class="t-header">
        <p class="title-section">{{ 'upcomingreleases' | translate }}</p>
        <ng-container *ngFor="let item of finalSections$ | async; let isLast=last">
          <p class="title-section lastSection" *ngIf="isLast">{{ 'gamesalreadyavailable' | translate }}</p>
          <lb-calendar-section
            *ngIf="item.games.length > 0"
            [section]="item"
            (turnPlay)="openGame()"
          ></lb-calendar-section>
        </ng-container>
      </div>
    </ion-content>
  </ng-template>
</ng-container>

<ng-container *ngIf="!globalVars.FEATURES.CAS_NewCalendar">
  <ng-container *ngIf="!isMobile; else mobileView">
    <ion-header>
      <div class="cs-header p-1 header-green" [ngClass]="{'rebranding': globalVars.FEATURES?.MSO_NewReBranding}">
        <h4 class="m-0">{{globalVars?.FEATURES?.casinoCalendarName}}</h4>
        <i item-right class="codere-icon icon-close" (click)="closeModal()"></i>
      </div>
    </ion-header>
    <ion-content class="background-color-dark">
      <ng-container *ngFor="let item of finalSections$ | async">
        <lb-calendar-section
          *ngIf="item.games.length > 0"
          [section]="item"
          (turnPlay)="openGame()"
        ></lb-calendar-section>
      </ng-container>
    </ion-content>
  </ng-container>

  <ng-template #mobileView>
    <ion-header>
      <codere-navbar></codere-navbar>
    </ion-header>
    <ion-content class="background-color-dark">
      <div class="t-header">
        <div class="cs-header p-1 header-green" [ngClass]="{'rebranding': globalVars.FEATURES?.MSO_NewReBranding}">
          <!-- <i class="sb-navbar-item--icon codere-icon icon-calendar"></i> -->
          <img class="calendarImg" [src]="getImage('assets/global/img/calendarNew.svg')" />
          <h5 class="m-0">{{globalVars?.FEATURES?.casinoCalendarName}}</h5>
          <i item-right class="codere-icon icon-close" (click)="closeModal()"></i>
        </div>
        <ng-container *ngFor="let item of finalSections$ | async">
          <lb-calendar-section
            *ngIf="item.games.length > 0"
            [section]="item"
            (turnPlay)="openGame()"
          ></lb-calendar-section>
        </ng-container>
      </div>
    </ion-content>
  </ng-template>
</ng-container>
