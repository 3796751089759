import { Directive, Input, inject } from "@angular/core";
import { CasinoFilter, CasinoFilterGroup } from "@interfaces/casinoMoreFiltersInterfaces";
import { DeviceService } from "@services/device.service";

@Directive({
  standalone: true,
})
export class BaseFilter {
  deviceService = inject(DeviceService);

  @Input() filterGroup: CasinoFilterGroup;

  isDesktop = false;

  constructor() {
    this.isDesktop = this.deviceService.isDesktop();
  }

  selectFilter(filter: CasinoFilter): void {
    filter.selected = !filter.selected;
  }

  generateRange(id: string | number): number[] {
    const length = typeof id === "string" ? parseInt(id) : id;
    const array = new Array(length).fill(0).map((_, index) => index);
    return array;
  }
}
