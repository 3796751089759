<div
  class="results-submenu"
  [ngStyle]="{'left': searchService?.inputWidth}"
  *ngIf="!searchSubMenuClosed"
  #resultsSearch
>
  <ion-list *ngIf="searchResultsLoading">
    <div class="result-content">
      <ion-spinner padding></ion-spinner>
    </div>
  </ion-list>
  <ion-list *ngIf="viewList()">
    <!-- <p padding-left class="title">{{'noResults'|translate}}</p> -->
    <p class="title ion-padding-start">{{'noResults'|translate}}</p>
    <!--No se han encontrado resultados-->
  </ion-list>
  <ion-list *ngIf="viewResults()" no-lines>
    <ion-item
      lines="none"
      class="result-item item-md"
      *ngFor="let result of searchResults"
      (click)="btItemSearch_Click($event,result)"
    >
      <i class="icon" [ngClass]="'icon-'+result.SportHandle+'-o'"></i>
      <div class="result-content">
        <p class="title">{{result?.Name}}</p>
        <p class="subtitle">{{result.League}}</p>
      </div>
    </ion-item>
  </ion-list>
</div>
