<ng-container *ngIf="isDesktop; then deskTemplate; else mobileTemplate"></ng-container>
<ng-template #deskTemplate>
  <ion-content class="background-color-muted">
    <user-section-title [sectionTitle]="'responsibleGamblingTest'"></user-section-title>
    <div>
      <ion-row
        class="row questiontxt"
        *ngFor="let question of questions; let i=index;"
        [ngClass]="{'margint5r': i == 0}"
      >
        <ion-col size="10">
          <span> {{i+1}}. {{ question | translate}} </span>
        </ion-col>
        <ion-col size="2" class="divtoggle">
          <div>{{'yes' | translate}}</div>
          <ion-toggle [formControl]="$any(formQuestions.controls[('question' + i)])"></ion-toggle>
          <div>No</div>
        </ion-col>
      </ion-row>
      <div class="txtcenter margint3r">
        <button ion-button class="btnorange" (click)="backToHome()">
          {{'tCancel' | translate}} <span class="codere-icon icon-arrow-right"></span>
        </button>
        <button ion-button class="btnwhite" [class.padding2070]="isDesktop" (click)="validaQuestion()">
          {{'tCont' | translate }}
        </button>
      </div>
    </div>
  </ion-content>
</ng-template>
<ng-template #mobileTemplate>
  <div class="contModal ismobilepopup">
    <div class="row titlepopup">
      <div (click)="prevQuestion()" class="borderr1">
        <i class="codere-icon arrow icon-arrow-left fontsize1_7" item-left=""></i>
      </div>
      <div>
        <div class="titletxt">
          {{ 'responsibleGamblingTest' | translate | uppercase}} ( {{(this.indexQuestionNow +
          1)}}/{{this.questions.length}})
        </div>
      </div>
    </div>
    <div>
      <div class="row">
        <div class="limitTitalertMessage">
          <div class="limitAlert-message col col-80">
            <span class="alertMessageCustom color61"> {{ this.questionNow | translate }} </span>
          </div>
        </div>
      </div>

      <ion-row class="buttonsLine limitcentertxt">
        <ion-col (click)="nextQuestion(('question' + this.indexQuestionNow),true)">
          <button
            ion-button=""
            class="btnyesno disable-hover button button-ios button-outline-ios limitbutton-outline"
            tappable=""
          >
            <span class="button-inner"> No</span>
            <div class="button-effect"></div>
          </button>
        </ion-col>
        <ion-col (click)="nextQuestion(('question' + this.indexQuestionNow),false)">
          <button
            ion-button=""
            class="btnyesno disable-hover button button-ios button-outline-ios limitbutton-outline"
            tappable=""
          >
            <span class="button-inner"> S&iacute;</span>
            <div class="button-effect"></div>
          </button>
        </ion-col>
      </ion-row>
    </div>
  </div>
</ng-template>
