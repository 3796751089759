/* eslint-disable */
import {
  QRPaymentMethodBehaviourResult,
  PaymentMethodBehaviour,
  SkipAmountResult,
  IQRPaymentMethodBehaviour
} from './PaymentMethodBase';
import { CoinspaidActionResult } from '@models/C_Transactions';
import { UserService } from '@providers/UserService';
import { ErrorService } from '@providers/ErrorService';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class CoinspaidMethodBehaviourResult extends QRPaymentMethodBehaviourResult {
  transactionId: string;
  amount: number;
  address: string;
  override provider: 'Coinspaid'; //important to distinct between qrpayments
}

export class CoinspaidDepositBehaviour extends PaymentMethodBehaviour implements IQRPaymentMethodBehaviour {
  name = 'Coinspaid';
  image = 'Coinspaid';
  track = 'AccessToCoinspaid';
  isBank = false;
  minDepositAmount = 10;
  maxDepositAmount = 100000;
  amountButtons: number[] = [10, 20, 40, 50];
  override amount = 20;

  constructor(userService: UserService, errorService: ErrorService) {
    super(false, userService, errorService);
  }

  skipAmountInput(): Observable<SkipAmountResult> {
    return new Observable<SkipAmountResult>();
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  qr() {} //satisfy Interface

  paymentMetodExecution(): Observable<QRPaymentMethodBehaviourResult> {
    return this.userService.validateDepositCoinspaid('', this.amount).pipe(
      map((obj) => {
        return this.map(obj);
      })
    );
  }

  private map(input: CoinspaidActionResult): QRPaymentMethodBehaviourResult {
    const result = new CoinspaidMethodBehaviourResult();
    if (input.success) {
      result.success = true;
      result.transactionId = input.transactionId;
      result.amount = input.amount;
      result.address = input.address;
      result.payload = input.address;
      result.provider = 'Coinspaid';
    } else {
      result.success = false;
      result.erroMessage = this.errorService.searchErrorCodeWithParams('error' + input.errCode, input.errorParameters);
      result.errorcode = input.errCode;
      result.sendToDocumentVerificationPage = input.errCode == 174 || input.errCode == 2212;
    }
    return result;
  }
}
