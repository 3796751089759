/* eslint-disable eqeqeq */
import { Component, inject } from "@angular/core";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { EventsService } from "@providers/EventsService";
import { NewBaseService } from "@providers/newBase.service";
import { Utils } from "@utils/Utils";

import { CommonModule } from "@angular/common";
import { IonicModule, ModalController, NavController } from "@ionic/angular";
import { UserService } from "@providers/UserService";

@Component({
  standalone: true,
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "teleingreso-moreinfo",
  templateUrl: "./limits-formul.html",
  imports: [IonicModule, CommonModule, TranslateModule],
  styleUrls: ["./limits-formul.scss"],
})
export class limitsFormulPage {
  pageInt: number;
  resposeForm: Array<boolean>;
  responseText: string;
  questions: Array<string>;
  globalVars!: GlobalVarsModel;

  utils = inject(Utils);
  nav = inject(NavController);
  newBaseService = inject(NewBaseService);
  viewCtrl = inject(ModalController);
  userService = inject(UserService);
  translate = inject(TranslateService);
  events = inject(EventsService);

  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
    this.pageInt = 0;
    this.resposeForm = [false, false, false, false, false, false, false, false, false, false];
    this.questions = [
      this.translate.instant("surveylimits01"),
      this.translate.instant("surveylimits02"),
      this.translate.instant("surveylimits03"),
      this.translate.instant("surveylimits04"),
      this.translate.instant("surveylimits05"),
      this.translate.instant("surveylimits06"),
      this.translate.instant("surveylimits07"),
      this.translate.instant("surveylimits08"),
      this.translate.instant("surveylimits09"),
      this.translate.instant("surveylimits10"),
    ];
  }

  btCancel_Click() {
    this.viewCtrl.dismiss();
  }

  nextPage() {
    this.pageInt++;
  }
  prevPage() {
    this.pageInt--;
  }
  nextQuestions(opc: boolean) {
    this.resposeForm[this.pageInt - 2] = opc;
    this.pageInt++;
  }
  finallyQuestion(opc: boolean) {
    let passForm = true;
    this.responseText = "";
    this.resposeForm[this.pageInt - 2] = opc;
    for (let i = 0; i < this.resposeForm.length; i++) {
      const resposeQ = this.resposeForm[i] ? "Si" : "No";
      this.responseText += this.questions[i] + "\r\n" + resposeQ + "\r\n";
      if (this.resposeForm[i]) {
        passForm = false;
      }
    }
    this.sendLimits(passForm, this.responseText);
  }
  sendLimits(form: boolean, text: string): void {
    if (this.globalVars.FEATURES.MSO_DecouplingLimits) {
      this.viewCtrl.dismiss({ approbed: form });
      return;
    }
    const day: number = this.globalVars.limits.day;
    const week: number = this.globalVars.limits.week;
    const month: number = this.globalVars.limits.month;
    this.userService.changeLimitswithTest(text, form, day, week, month).subscribe(
      (data) => {
        if (data !== null) {
          if (data.limitsChangeRequestStatus === "PENDING") {
            const msg = this.globalVars.user.username + " " + this.translate.instant("tlimitampliados");
            this.utils.showMsg(msg);
          } else if (data.limitsChangeRequestStatus === "SURVEY_REJECTED") {
            this.utils.showError(this.globalVars.user.username + " " + this.translate.instant("gametestko"));
          } else if (data.limitsChangeRequestStatus === "LESS3MONTH") {
            this.utils.showError(this.translate.instant("surveylimitserror")); //La solicitud de aumento de límites no puede realizarse.
          } else if (data.limitsChangeRequestStatus === "NOT_AVAILABLE") {
            this.utils.showError(this.translate.instant("errorMoreLimits"));
          }
        } else {
          this.utils.showError(this.globalVars.user.username + " " + this.translate.instant("tfaillimitno"));
        }
        this.viewCtrl.dismiss();
      },
      (err) => {
        this.utils.showError(err);
        this.viewCtrl.dismiss();
      },
    );
  }
  sendLimitsFailureTest(form: boolean, text: string): void {
    const day: number = this.globalVars.limits.day;
    const week: number = this.globalVars.limits.week;
    const month: number = this.globalVars.limits.month;
    this.userService.changeLimitswithTest(text, form, day, week, month).subscribe(
      (data) => {
        if (data !== null) {
          if (data.StorageResponseStatus === 0) {
            this.utils.showError(this.translate.instant("gametestko"));
          } else {
            this.utils.showError(this.globalVars.user.username + " " + this.translate.instant("tfaillimitno"));
          }
        } else {
          this.utils.showError(this.globalVars.user.username + " " + this.translate.instant("tfaillimitno"));
        }
        this.viewCtrl.dismiss();
      },
      (err) => {
        //
        this.utils.showError(err);
        this.viewCtrl.dismiss();
      },
    );
  }
}
