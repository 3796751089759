import { CommonModule } from "@angular/common";
/* eslint-disable */
import { Component, EventEmitter, Input, Output, inject } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";

import { ParseStakeMoney } from "@pipes/parseStakeMoney";

import { TranslateModule } from "@ngx-translate/core";
import { C_TicketApuestasNew } from "@sports-models/index";
import { NewTicketFacade } from "@sports-stores/ticket/index";
import { TicketMethods } from "@sports-utils/index";

@Component({
  selector: "betslip-ticket-confirmed",
  templateUrl: "./betslip-ticket-confirmed.component.html",
  styleUrls: ["./betslip-ticket-confirmed.component.scss"],
  standalone: true,
  imports: [CommonModule, IonicModule, FormsModule, ParseStakeMoney, TranslateModule],
})
export class BetslipTicketConfirmedComponent {
  @Input() ticket: C_TicketApuestasNew;
  @Input() disabled;
  @Input() btnBetText;
  @Input() status: string;
  @Output() keepSelections = new EventEmitter();
  @Output() closeResume = new EventEmitter();
  @Output() clearTicket = new EventEmitter();
  @Output() placeBet = new EventEmitter();

  newTicketFacade = inject(NewTicketFacade);

  betButton = "";

  onKeepSelectionsClick() {
    console.log("keepSelections");
    this.keepSelections.emit();
  }
  onCloseResumeClick() {
    console.log("closeResume");
    this.closeResume.emit();
  }

  onClearTicketClick() {
    console.log("clearTicket");
    this.clearTicket.emit();
  }

  onPlaceBetClick() {
    console.log("placeBet");
    this.placeBet.emit();
  }

  getTicketIsConfirmed(ticket: any): boolean {
    if (ticket !== undefined && ticket !== null) return TicketMethods.getIsConfirmed(ticket);
    else return false;
  }
}
