import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'obfuscate', standalone: true })
export class ObfuscatePipe implements PipeTransform {
  transform(value: string): string {
    let result = '';
    //eslint-disable-next-line
    if (value !== '' && value !== null) {
      for (let i = 0; i < value.length; i++) {
        result += i > 3 && i < value.length - 4 ? '*' : value.charAt(i);
      }
    }
    return result;
  }
}
