import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild
} from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { Swiper, SwiperOptions } from 'swiper';
import { SportsConstants } from '../../utils/sports.constants';
import { TitleCasePipe } from '@pipes/titlecase/title-case.pipe';

@Component({
  selector: 'sb-tabs-categories',
  templateUrl: './sb-tabs-categories.component.html',
  styleUrls: ['./sb-tabs-categories.component.scss'],
  standalone: true,
  imports: [CommonModule, IonicModule, TranslateModule, TitleCasePipe],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SbTabsCategoriesComponent implements OnChanges, AfterViewInit {
  /**
   * scrollable segment
   * @input categories[]
   * @type Array
   * */
  @Input() scrollable: any = false;
  /**
   * categories Array
   * @input categories[]
   * @type Array
   * */
  @Input() categories: any;
  /**
   * Selected filter
   * @input selected
   * @type {category}
   */
  @Input() selectedTab: any;
  /**
   * Event emmited when the user selects a tab
   * @output category
   * @type {category}
   */
  @Output() selectTab: EventEmitter<any> = new EventEmitter();
  /**
   * color mode: 'regular', 'dark'
   * @input color
   * @type string
   */
  @Input() color: string = 'regular';
  /**
   * Small font-size for the buttons
   * @input smallButton
   * @type boolean
   */
  @Input() smallButton: boolean = false;
  /**
   * ion-segment-button layout for mobile
   * @input isMobile
   * @type boolean
   */
  @Input() isMobile: boolean = false;
  @ViewChild('tabsCategoriesSwiper') tabsCategoriesSwiper:
    | ElementRef<HTMLElement & { swiper?: Swiper } & { initialize: () => void }>
    | undefined;

  public swiper?: Swiper;
  config: SwiperOptions = {
    centeredSlides: false,
    loop: false,
    slidesPerView: 'auto',
    observer: true
  };
  SpConstants = SportsConstants;
  mode = 'regular';

  swiperElements = [];

  constructor() {}

  ngOnChanges() {
    if (this.tabsCategoriesSwiper?.nativeElement && !this.existsSwiper()) {
      this.setSwiperConfig();
    }
    if (this.existsSwiper() && this.categories?.length > 0) {
      this.swiperElements = this.categories;
      this.tabsCategoriesSwiper.nativeElement.swiper.update();
    }
  }

  ngAfterViewInit() {
    if (this.tabsCategoriesSwiper?.nativeElement && !this.existsSwiper()) {
      this.setSwiperConfig();
      this.ngOnChanges();
    }
  }

  setSwiperConfig() {
    const swiperEl = Object.assign(this.tabsCategoriesSwiper?.nativeElement, this.config);
    swiperEl.initialize();
    this.swiper = this.tabsCategoriesSwiper?.nativeElement?.swiper;
  }

  existsSwiper = () => this.swiper && !this.swiper.destroyed;

  /**
   * Selects an emmit the category selection
   * @param cat
   * @param index
   */
  onClick(cat, index) {
    this.selectTab.emit({ ...cat, index });
  }
}
