/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable, inject } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { BaseService } from '@providers/BaseService';
import { firstValueFrom } from 'rxjs';
// import { SplashScreen } from '@capacitor/splash-screen';
import { Capacitor } from '@capacitor/core';
import { BundleInfo, CapacitorUpdater, DelayCondition } from '@capgo/capacitor-updater';
import { ModalUpdateComponent } from '@components/common/modal-update/modal-update';
import { AlertController } from '@ionic/angular';
import { LogService } from '@providers/LogService';
import { TrackingService } from '@providers/TrackingService';

/**
 * Updates service
 * Contain methods related with the application updates
 */
@Injectable({ providedIn: 'root' })
export class UpdatesService extends BaseService {
  bindEventsInitialized = false;
  // TODO ELIMINAR **+**: private hotCodePush: HotCodePush

  private updater: { bundle: BundleInfo } = null;

  logService = inject(LogService);
  trackingService = inject(TrackingService);
  modalController = inject(ModalController);
  alertController = inject(AlertController);

  constructor() {
    super();
    CapacitorUpdater.notifyAppReady().then((updaterInfo) => (this.updater = updaterInfo));
  }

  public async checkForNewVersion(
    currentVersion?: string
  ): Promise<{ isNewerVersionAvailable: boolean; releaseUrl: string; latestVersion: string }> {
    try {
      const { latestVersion, releaseUrl } = await firstValueFrom(this.httpClient.get<any>(this.globalVars.HCP_URL));

      return {
        isNewerVersionAvailable: !!(currentVersion === 'builtin' || currentVersion < latestVersion),
        latestVersion,
        releaseUrl
      };
    } catch (error) {
      console.error('HCP: Error al verificar la versión', error);
      return {
        isNewerVersionAvailable: false,
        latestVersion: null,
        releaseUrl: null
      };
    }
  }

  /**
   * Manages how many times the app has started
   * @returns number of times the app has started
   */
  launchNumber(): number {
    const savedLaunches = localStorage.getItem('launches');
    if (!savedLaunches) {
      localStorage.setItem('launches', '0');
    } else {
      let count = parseInt(savedLaunches);
      if (count != null) {
        count++;
        localStorage.setItem('launches', count.toString());
      }
    }

    return parseInt(localStorage.getItem('launches'));
  }

  async updateIfRequiredTest(): Promise<void> {
    this.updater = await CapacitorUpdater.notifyAppReady();

    const LAUNCH_TIMES = 1; // we can change when to open the update modal

    const savedLaunches = this.launchNumber();

    if (savedLaunches >= LAUNCH_TIMES) {
      this.globalVars.strVersion = this.updater.bundle.version;

      if (Capacitor.getPlatform() !== 'web') {
        const response = await this.checkForNewVersion(this.updater.bundle.version);

        if (response.isNewerVersionAvailable) {
          const alert = await this.alertController.create({
            header: 'Nueva versión disponible',
            message: 'Hay una nueva versión disponible. ¿Desea actualizar?',
            buttons: [
              {
                text: 'Cancelar',
                role: 'cancel',
                handler: async () => {
                  console.log('Actualización cancelada');
                  await CapacitorUpdater.setMultiDelay({
                    delayConditions: [
                      {
                        kind: 'kill'
                      }
                    ]
                  });
                }
              },
              {
                text: 'Actualizar',
                handler: async () => {
                  await alert.dismiss();
                  const modal = await this.modalController.create({
                    component: ModalUpdateComponent
                  });
                  await modal.present();

                  try {
                    const version = await CapacitorUpdater.download({
                      url: response.releaseUrl,
                      version: response.latestVersion
                    });
                    this.globalVars.strVersion = version.version;
                    await CapacitorUpdater.set(version);
                  } catch (error) {
                    console.error('Falló la actualización', error);
                  } finally {
                    await modal.dismiss();
                  }
                }
              }
            ]
          });

          await alert.present();
        }
      }
    }
  }
}
