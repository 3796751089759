<ng-container *ngIf="!isMobile; else mobileView">
  <ion-content class="has-titleBar">
    <!-- user-section-title mobile & desktop -->
    <user-section-title [sectionTitle]="'cTSubT'"></user-section-title>
    <div class="is-narrow-content">
      <form [formGroup]="formDepTicket">
        <ion-list class="hidden">
          <div class="boxDeposit">
            <p>
              {{'scancode' | translate | uppercase}}
              <!--ESCANÉA TU CÓDIGO-->
            </p>
            <button full>
              <i class="icon-scan2"></i> {{'scan' | translate}}
              <!--Escanear -->
            </button>
          </div>
        </ion-list>
        <ion-list class="form-list">
          <p>
            {{'tDepTicknumero' | translate | uppercase}}
            <!--INTRODUCE EL NÚMERO DE TICKET-->
          </p>
          <div
            class="myWrapInput"
            [class.has-error]="(!formDepTicket.controls['numTicket'].valid && formDepTicket.controls['numTicket'].touched) || !numticketvalido"
          >
            <ion-item
              lines="none"
              class="boxDeposit cdr-item-input"
              [class.error]="(!formDepTicket.controls['numTicket'].valid && formDepTicket.controls['numTicket'].touched) || !numticketvalido"
            >
              <ion-input
                label="{{'compapuestaP3' | translate}}"
                labelPlacement="floating"
                type="number"
                [formControl]="$any(formDepTicket.controls['numTicket'])"
              ></ion-input>
            </ion-item>
            <div
              [hidden]="( (formDepTicket.controls['numTicket'].valid && numticketvalido) || !formDepTicket.controls['numTicket'].touched)"
              class="labelError"
            >
              <span
                *ngIf="formDepTicket.controls['numTicket'].hasError('required') || formDepTicket.controls['numTicket'].hasError('minlength')"
              >
                {{'error_ticket_num' | translate}} <br />
              </span>
              <span *ngIf="!numticketvalido && !formDepTicket.controls['numTicket'].hasError('required')">
                {{'consulticketnum' | translate}}
                <!--No hay ticket con esta numeración.-->
              </span>
            </div>
          </div>
          <div class="exampleInput">
            {{'ejem' | translate}}
            <!--Ejemplo:-->
            B<span>999999999999</span>-99999
            <button class="buttonInfo" (click)="openHelpNumTicket()" tabindex="-1">
              <ion-icon name="help-circle-outline"></ion-icon>
            </button>
          </div>
          <div
            class="myWrapInput"
            [class.has-error]="(!formDepTicket.controls['captcha'].valid || !validCaptcha) && formDepTicket.controls['captcha'].touched"
          >
            <div class="subTitleSub titleCaptcha">
              <span
                >{{'cTQues' | translate}}
                <!--¿Cuánto es?--></span
              >
              <div class="captcha">
                <img [src]="captchaUrl" alt="" class="captchaWrap" />
                <ion-icon (click)="newCaptcha()" name="sync-outline"></ion-icon>
              </div>
            </div>

            <ion-item
              lines="none"
              class="cdr-item-input"
              [class.error]="(!formDepTicket.controls['captcha'].valid || !validCaptcha) && formDepTicket.controls['captcha'].touched"
            >
              <ion-input
                label="{{'v2_intrRes' | translate}}"
                labelPlacement="floating"
                type="number"
                [formControl]="$any(formDepTicket.controls['captcha'])"
                [value]="formDepTicket.controls['captcha'].value"
              ></ion-input>
            </ion-item>
            <div
              [hidden]="(formDepTicket.controls['captcha'].valid && validCaptcha) || !formDepTicket.controls['captcha'].touched"
              class="labelError"
            >
              <span *ngIf="formDepTicket.controls['captcha'].hasError('required')">
                {{'error_form_requiered' | translate}}
              </span>
              <span *ngIf="!validCaptcha && !formDepTicket.controls['captcha'].hasError('required')">
                {{messageErrorCaptcha}}
              </span>
            </div>
          </div>
          <div
            class="myWrapInput"
            *ngIf="globalVars.licenseType !==4 && globalVars.licenseType !==21 && globalVars.licenseType !==22"
          >
            <ion-item *ngIf="globalVars.browser !=='internet'" class="cdr-item-input" lines="none">
              <ion-select
                placeholder="{{'ccaa' | translate}}"
                cancelText="{{'tCancel'|translate}}"
                class="selectInput"
                [formControl]="$any(formDepTicket.controls['ccaa'])"
                toggleIcon="chevron-down-outline"
                expandedIcon="chevron-up-outline"
                [value]="formDepTicket.controls['ccaa'].value"
              >
                <ion-select-option value="Andalucia_1">Andaluc&iacute;a</ion-select-option>
                <ion-select-option value="Aragon_2">Arag&oacute;n</ion-select-option>
                <ion-select-option value="Asturias_1">Asturias</ion-select-option>
                <ion-select-option value="Cantabria_1">Cantabria</ion-select-option>
                <ion-select-option value="Catalunya_1">Catalunya</ion-select-option>
                <ion-select-option value="CastillaLaMancha_1">C. la Mancha</ion-select-option>
                <ion-select-option value="CastillaYLeon_1">Castilla y Le&oacute;n</ion-select-option>
                <ion-select-option value="Ceuta_1">Ceuta</ion-select-option>
                <ion-select-option value="Extremadura_1">Extremadura</ion-select-option>
                <ion-select-option value="Galicia_3">Galicia</ion-select-option>
                <ion-select-option value="LaRioja_3">La Rioja</ion-select-option>
                <ion-select-option value="Madrid_1">Madrid</ion-select-option>
                <ion-select-option value="Melilla_1">Melilla</ion-select-option>
                <ion-select-option value="Murcia_3">Murcia</ion-select-option>
                <ion-select-option value="Navarra_2">Navarra</ion-select-option>
                <ion-select-option value="Valencia_3">C.Valenciana</ion-select-option>
                <ion-select-option value="PaisVasco_2">Pa&iacute;s Vasco</ion-select-option>
              </ion-select>
            </ion-item>
            <select
              class="select-explorer"
              *ngIf="globalVars.browser ==='internet'"
              [formControl]="$any(formDepTicket.controls['ccaa'])"
            >
              <option value="Andalucia_1">Andaluc&iacute;a</option>
              <option value="Aragon_2">Arag&oacute;n</option>
              <option value="Asturias_1">Asturias</option>
              <option value="Cantabria_1">Cantabria</option>
              <option value="Catalunya_1">Catalunya</option>
              <option value="CastillaLaMancha_1">C. la Mancha</option>
              <option value="CastillaYLeon_1">Castilla y Le&oacute;n</option>
              <option value="Ceuta_1">Ceuta</option>
              <option value="Extremadura_1">Extremadura</option>
              <option value="Galicia_3">Galicia</option>
              <option value="LaRioja_3">La Rioja</option>
              <option value="Madrid_1">Madrid</option>
              <option value="Melilla_1">Melilla</option>
              <option value="Murcia_3">Murcia</option>
              <option value="Navarra_2">Navarra</option>
              <option value="Valencia_3">C.Valenciana</option>
              <option value="PaisVasco_2">Pa&iacute;s Vasco</option>
            </select>
            <div
              [hidden]="formDepTicket.controls['ccaa'].valid || !formDepTicket.controls['ccaa'].touched"
              class="labelError"
            >
              <span *ngIf="formDepTicket.controls['ccaa'].hasError('required')">
                {{'error_form_requiered' | translate}}
              </span>
            </div>
          </div>

          <div class="sendForm">
            <ion-button expand="full" class="button-full" (click)="sendTicket()">
              {{'cTBut' | translate}}
              <!--Comprobar-->
            </ion-button>
          </div>
        </ion-list>
      </form>
    </div>
    <div
      class="myWrapInput"
      [class.has-error]="(!formDepTicket.controls['captcha'].valid || !validCaptcha) && formDepTicket.controls['captcha'].touched"
    >
      <div class="subTitleSub titleCaptcha">
        <span
          >{{'cTQues' | translate}}
          <!--¿Cuánto es?--></span
        >
        <div class="captcha">
          <img [src]="captchaUrl" alt="" class="captchaWrap" />
          <ion-icon (click)="newCaptcha()" name="sync-outline"></ion-icon>
        </div>
      </div>
      <div class="chapta-item-content">
        <ion-item
          [class.error]="(!formDepTicket.controls['captcha'].valid || !validCaptcha) && formDepTicket.controls['captcha'].touched"
          class="cdr-item-input"
          lines="none"
        >
          <ion-input
            label="{{'v2_intrRes' | translate}}"
            labelPlacement="floating"
            type="number"
            [formControl]="$any(formDepTicket.controls['captcha'])"
            [value]="formDepTicket.controls['captcha'].value"
          ></ion-input>
        </ion-item>
      </div>
    </div>
  </ion-content>
</ng-container>

<ng-template #mobileView>
  <ion-header>
    <user-section-title [sectionTitle]="'cTSubT'"></user-section-title>
  </ion-header>
  <ion-content>
    <div class="padding">
      <div class="hidden introReg" [innerHTML]="'seeresult' | translate"></div>

      <ion-list class="hidden ticket-list">
        <div class="boxDeposit">
          <p>{{ 'scancode' | translate | uppercase }}x</p>
          <button ion-button full (click)="scanTicket()">
            <i class="icon-scan2"></i>
            {{ 'scan' | translate }}
          </button>
        </div>
      </ion-list>
      <form [formGroup]="formDepTicket">
        <ion-list class="ticket-list">
          <ion-list-header class="ticket-list-header has-no-background">
            {{ 'tDepTicknumero' | translate | uppercase }}
            <!--INTRODUCE EL NUMERO DE TICKET-->
          </ion-list-header>

          <div
            class="myWrapInput class depTicket-number"
            [class.has-error]="
            (!formDepTicket.controls['numTicket'].valid &&
              formDepTicket.controls['numTicket'].touched) ||
            !numticketvalido
          "
          >
            <ion-item
              lines="none"
              class="boxDeposit cdr-item-input"
              [class.error]="
              (!formDepTicket.controls['numTicket'].valid &&
                formDepTicket.controls['numTicket'].touched) ||
              !numticketvalido
            "
            >
              <!--Num. de ticket-->
              <ion-input
                label="{{'compapuestaP3' | translate}}"
                labelPlacement="floating"
                type="number"
                [formControl]="$any(formDepTicket.controls['numTicket'])"
              ></ion-input>
            </ion-item>
            <div
              [hidden]="( (formDepTicket.controls['numTicket'].valid && numticketvalido) || !formDepTicket.controls['numTicket'].touched)"
              class="labelError"
            >
              <span
                *ngIf="formDepTicket.controls['numTicket'].hasError('required') || formDepTicket.controls['numTicket'].hasError('minlength')"
              >
                {{'error_ticket_num' | translate}} <br />
              </span>
              <span *ngIf="!numticketvalido && !formDepTicket.controls['numTicket'].hasError('required')">
                {{'consulticketnum' | translate}}
                <!--No hay ticket con esta numeración.-->
              </span>
            </div>
          </div>
          <div class="exampleInput">
            {{ 'ejem' | translate }}
            <!--Ejemplo:-->B<span>999999999999</span>-99999
            <button class="buttonInfo" (click)="openHelpNumTicket()" tabindex="-1">
              <ion-icon name="help-circle-outline"></ion-icon>
            </button>
          </div>
          <div
            class="myWrapInput"
            [class.has-error]="
            (!formDepTicket.controls['captcha'].valid || !validCaptcha) &&
            formDepTicket.controls['captcha'].touched
          "
          >
            <div class="subTitleSub titleCaptcha">
              <span
                >{{ 'cTQues' | translate }}
                <!--Cuanto es?--></span
              >
              <div class="captcha">
                <img [src]="captchaUrl" alt="" class="captchaWrap" />
                <ion-icon (click)="newCaptcha()" name="sync-outline"></ion-icon>
              </div>
            </div>
            <ion-item
              class="cdr-item-input"
              [class.error]="
              (!formDepTicket.controls['captcha'].valid || !validCaptcha) &&
              formDepTicket.controls['captcha'].touched
            "
              lines="none"
            >
              <!--# Introduce el resultado-->
              <ion-input
                label="{{'v2_intrRes' | translate}}"
                labelPlacement="floating"
                type="number"
                [formControl]="$any(formDepTicket.controls['captcha'])"
                [value]="formDepTicket.controls['captcha'].value"
              ></ion-input>
            </ion-item>
            <div
              [hidden]="
              (formDepTicket.controls['captcha'].valid && validCaptcha) ||
              !formDepTicket.controls['captcha'].touched
            "
              class="labelError"
            >
              <span *ngIf="formDepTicket.controls['captcha'].hasError('required')">
                {{ 'error_form_requiered' | translate }}
              </span>
              <span
                *ngIf="
                !validCaptcha &&
                !formDepTicket.controls['captcha'].hasError('required')
              "
              >
                {{ messageErrorCaptcha }}
              </span>
            </div>
          </div>
          <div
            class="myWrapInput select-communities"
            *ngIf="
            globalVars.licenseType !==4 &&
            globalVars.licenseType !==21 &&
            globalVars.licenseType !==22
          "
          >
            <ion-item class="cdr-item-input" lines="none">
              <ion-select
                class="selectInput"
                placeholder="{{'ccaa' | translate}}"
                cancelText="{{'tCancel'|translate}}"
                [formControl]="$any(formDepTicket.controls['ccaa'])"
                toggleIcon="chevron-down-outline"
                expandedIcon="chevron-up-outline"
              >
                <ion-select-option value="Andalucia_1">Andaluc&iacute;a</ion-select-option>
                <ion-select-option value="Aragon_2">Arag&oacute;n</ion-select-option>
                <ion-select-option value="Asturias_1">Asturias</ion-select-option>
                <ion-select-option value="Cantabria_1">Cantabria</ion-select-option>
                <ion-select-option value="Catalunya_1">Catalunya</ion-select-option>
                <ion-select-option value="CastillaLaMancha_1">C. la Mancha</ion-select-option>
                <ion-select-option value="CastillaYLeon_1">Castilla y Le&oacute;n</ion-select-option>
                <ion-select-option value="Ceuta_1">Ceuta</ion-select-option>
                <ion-select-option value="Extremadura_1">Extremadura</ion-select-option>
                <ion-select-option value="Galicia_3">Galicia</ion-select-option>
                <ion-select-option value="LaRioja_3">La Rioja</ion-select-option>
                <ion-select-option value="Madrid_1">Madrid</ion-select-option>
                <ion-select-option value="Melilla_1">Melilla</ion-select-option>
                <ion-select-option value="Murcia_3">Murcia</ion-select-option>
                <ion-select-option value="Navarra_2">Navarra</ion-select-option>
                <ion-select-option value="Valencia_3">C.Valenciana</ion-select-option>
                <ion-select-option value="PaisVasco_2">Pa&iacute;s Vasco</ion-select-option>
              </ion-select>
            </ion-item>
            <div
              [hidden]="
              formDepTicket.controls['ccaa'].valid ||
              !formDepTicket.controls['ccaa'].touched
            "
              class="labelError"
            >
              <span *ngIf="formDepTicket.controls['ccaa'].hasError('required')">
                {{ 'error_form_requiered' | translate }}
              </span>
            </div>
          </div>

          <div class="sendForm">
            <!--Comprobar-->
            <ion-button mode="md" expand="full" class="button-full" (click)="sendTicket()">
              {{'cTBut' | translate}}
            </ion-button>
          </div>
          <div class="hidden sendForm" tappable (click)="tryActivateAdmin()">
            <!--Comprobar testing-->
            <ion-button mode="md" expand="full" class="button-full">Comprobar</ion-button>
          </div>
        </ion-list>
      </form>
    </div>
    <codere-footer></codere-footer>
  </ion-content>
</ng-template>
