<ion-toolbar class="toolbarSectionName">
  <back-button tappable></back-button>
  <span>{{ texto | translate }}</span>
</ion-toolbar>

<ion-content class="ion-margin">
  <ion-grid class="content">
    <ion-row class="ion-text-center">
      <ion-col *ngFor="let data of defaultLogData" size="6">
        <ion-button
          class="action-btn"
          [color]="selectedContent === data.id ? 'warning' : 'tertiary'"
          (click)="buttonOnClick(data.id)"
        >
          <ion-text color="light" class="ion-margin"> {{ data.name }} </ion-text>
        </ion-button>
      </ion-col>
    </ion-row>

    <ion-row class="data-row">
      <form [formGroup]="form">
        <ion-col size="12" *ngIf="selectedContent === eventNames.LOGIN">
          <ion-item lines="none" formGroupName="af_login">
            <ion-input label="Username:" type="text" formControlName="username"></ion-input>
          </ion-item>
        </ion-col>

        <ion-col size="12" *ngIf="selectedContent === eventNames.REGISTER">
          <div formGroupName="af_complete_registration">
            <ion-item lines="none">
              <ion-input label="Username:" type="text" formControlName="username"></ion-input>
            </ion-item>
            <ion-item lines="none">
              <ion-input label="Email:" type="email" formControlName="email"></ion-input>
            </ion-item>
          </div>
        </ion-col>

        <ion-col size="12" *ngIf="selectedContent === eventNames.FIRST_DEPOSIT">
          <div formGroupName="af_purchase">
            <ion-item lines="none">
              <ion-input label="Username:" type="text" formControlName="username"></ion-input>
            </ion-item>
            <ion-item lines="none">
              <ion-select
                label="Currency:"
                formControlName="currency"
                [interfaceOptions]="customOptions"
                interface="alert"
                placeholder="Currencies List"
              >
                <ion-select-option *ngFor="let currency of currencyList" [value]="currency.id">
                  {{ currency.id }}
                </ion-select-option>
              </ion-select>
            </ion-item>
            <ion-item lines="none">
              <ion-input label="Amount:" type="number" formControlName="amount"></ion-input>
            </ion-item>
          </div>
        </ion-col>

        <ion-col size="12" *ngIf="selectedContent === eventNames.SUB_DEPOSIT">
          <div formGroupName="sdeposit">
            <ion-item lines="none">
              <ion-input label="Username:" type="text" formControlName="username"></ion-input>
            </ion-item>
            <ion-item lines="none">
              <ion-select
                label="Currency:"
                formControlName="currency"
                [interfaceOptions]="customOptions"
                interface="alert"
                placeholder="Currencies List"
              >
                <ion-select-option *ngFor="let currency of currencyList" [value]="currency.id">
                  {{ currency.id }}
                </ion-select-option>
              </ion-select>
            </ion-item>
            <ion-item lines="none">
              <ion-input label="Amount:" type="number" formControlName="amount"></ion-input>
            </ion-item>
          </div>
        </ion-col>

        <ion-col class="result-col" size="12" *ngIf="resultText !== null">
          Results:
          <ion-text color="dark" *ngFor="let result of resultText">
            <p>{{ result }}</p>
          </ion-text>
        </ion-col>

        <ion-col class="bottom-col" size="12">
          <ion-button
            color="tertiary"
            (click)="submit(form, selectedContent)"
            expand="block"
            fill="solid"
            shape="round"
          >
            Lauch Event
          </ion-button>
        </ion-col>
      </form>
    </ion-row>
  </ion-grid>
  <codere-footer></codere-footer>
</ion-content>
