<ng-container *ngIf="!isMobile; else mobileView">
  <!-- <ion-header>
    <user-section-title [sectionTitle]="'GetCardorder'"></user-section-title>
  </ion-header> -->
  <ion-content class="has-titleBar">
    <h1 class="single-title-bar">
      {{'GetCardorder' | translate | uppercase}}
      <!--SOLICITAR TARJETA-->
    </h1>
    <div class="is-narrow-content">
      <ion-list class="firstList">
        <ion-list-header>
          <ion-avatar item-left><i class="codere-icon icon-checkmark"></i></ion-avatar>
          <span
            >{{'GetCardCreatedOK' | translate | uppercase}}
            <!--TARJETA CREADA CON ÉXITO--></span
          >
        </ion-list-header>
      </ion-list>
      <ion-list class="subDep">
        <ion-item lines="none">
          <ol>
            <li>
              <span [innerHTML]="'GetCardCreated01' | translate">El número de tu tarjeta es</span>
              {{globalVars.user.codereCard.cardNumber}}{{'GetCardCreated011' | translate}}
              <!--, en breve la recibirás en tu domicilio.-->
            </li>
            <li>
              <span [innerHTML]="'GetCardCreated02' | translate"
                >Comienza a usarla hoy mismo en los terminales de tu local Codere introduciendo manualmente el número de
                la tarjeta.</span
              >
            </li>
            <li>
              <span [innerHTML]="'GetCardCreated03' | translate"
                >Puedes consultar el número siempre que lo necesites en la sección “Mi tarjeta”.</span
              >
            </li>
          </ol>
        </ion-item>
        <div class="sendForm">
          <ion-button expand="full" full color="primary" (click)="openMiTarjeta()">
            {{'CodereLinkedCard' | translate}}
            <!--Mi tarjeta-->
          </ion-button>
        </div>
      </ion-list>
      <div class="myWrapLegal bulletList">
        <h1>
          {{'GetCarduse' | translate}}
          <!--Cómo usar la tarjeta antes de que llegue-->
        </h1>
        <ion-row>
          <ion-col col-6>
            <ion-item>
              <ion-label>
                <div class="bullet">
                  <p>1</p>
                </div>
                <span [innerHTML]="'GetCarduse01' | translate">
                  <!--Pulsa <b>“Inicia sesión”</b> en el terminal de apuestas--></span
                >
              </ion-label>
            </ion-item>
            <ion-item>
              <ion-label>
                <div class="bullet">
                  <p>2</p>
                </div>
                {{'GetCarduse02' | translate}}
                <!--Introduce los <b>16 dígitos</b> de tu tarjeta-->
              </ion-label>
            </ion-item>
          </ion-col>
          <ion-col col-6>
            <ion-item>
              <ion-label class="label-bullet-3">
                <div class="bullet">
                  <p>3</p>
                </div>
                <span [innerHTML]="'v2_introPin1' | translate"> <!--# Introduce tu <b>PIN</b>--></span>
              </ion-label>
            </ion-item>
            <ion-item>
              <ion-label>
                <div class="bullet">
                  <p>4</p>
                </div>
                <span [innerHTML]="'GetCarduse04' | translate">
                  <!--Pulsa en el botón <b>“Login”</b> del teclado--></span
                >
              </ion-label>
            </ion-item>
          </ion-col>
        </ion-row>
      </div>
      <div class="noteEnd">
        <ion-icon mode="ios" name="alert-circle-outline"></ion-icon>
        <span [innerHTML]="'GetCarduse05' | translate">
          <!--¡No olvides cerrar sesión cuando termines de realizar tu apuestas!--></span
        >
      </div>
    </div>
    <codere-footer></codere-footer>
  </ion-content>
</ng-container>

<ng-template #mobileView>
  <ion-header>
    <user-section-title [sectionTitle]="'GetCardorder'"></user-section-title>
  </ion-header>

  <ion-content>
    <ion-list class="firstList">
      <ion-list-header>
        <ion-avatar item-left><i class="codere-icon icon-checkmark"></i></ion-avatar>
        <ion-label>
          <span
            >{{'GetCardCreatedOK' | translate | uppercase}}
            <!--TARJETA CREADA CON ÉXITO--></span
          >
        </ion-label>
      </ion-list-header>
    </ion-list>
    <ion-list class="subDep">
      <ion-item lines="none">
        <ol>
          <li>
            <span [innerHTML]="'GetCardCreated01' | translate">El número de tu tarjeta es</span>
            <!-- TODO Revisar -->
            {{globalVars.user.codereCard.cardNumber}}{{'GetCardCreated011' | translate}}
            <!--, en breve la recibirás en tu domicilio.-->
          </li>
          <li>
            <span [innerHTML]="'GetCardCreated02' | translate"
              >Comienza a usarla hoy mismo en los terminales de tu local Codere introduciendo manualmente el número de
              la tarjeta.</span
            >
          </li>
          <li>
            <span [innerHTML]="'GetCardCreated03' | translate"
              >Puedes consultar el número siempre que lo necesites en la sección “Mi tarjeta”.</span
            >
          </li>
        </ol>
      </ion-item>
      <div class="sendForm">
        <ion-button class="ion-button" expand="full" (click)="openMiTarjeta()">
          {{'CodereLinkedCard' | translate}}
          <!--Mi tarjeta-->
        </ion-button>
      </div>
    </ion-list>
    <div class="myWrapLegal bulletList">
      <ion-list-header> {{'GetCarduse' | translate}}<!--Cómo usar la tarjeta antes de que llegue--> </ion-list-header>
      <ion-item lines="none">
        <ion-label>
          <div class="bullet">
            <p>1</p>
          </div>
          <span [innerHTML]="'GetCarduse01' | translate"></span>
        </ion-label>
      </ion-item>
      <ion-item lines="none">
        <ion-label>
          <div class="bullet">
            <p>2</p>
          </div>
          {{'GetCarduse02' | translate}}<!--Introduce los <b>16 dígitos</b> de tu tarjeta-->
        </ion-label>
      </ion-item>
      <ion-item lines="none">
        <ion-label>
          <div class="bullet">
            <p>3</p>
          </div>
          <span [innerHTML]="'v2_introPin1' | translate"><!--# Introduce tu <b>PIN</b>--></span>
        </ion-label>
      </ion-item>
      <ion-item lines="none">
        <ion-label>
          <div class="bullet">
            <p>4</p>
          </div>

          <span [innerHTML]="'GetCarduse04' | translate"><!--Pulsa en el botón <b>“Login”</b> del teclado--></span>
        </ion-label>
      </ion-item>
    </div>
    <div class="noteEnd">
      <ion-icon mode="ios" name="alert-circle-outline"></ion-icon>
      <span [innerHTML]="'GetCarduse05' | translate"
        ><!--¡No olvides cerrar sesión cuando termines de realizar tu apuestas!--></span
      >
    </div>

    <codere-footer></codere-footer>
  </ion-content>
</ng-template>
