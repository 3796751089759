/* eslint-disable @typescript-eslint/no-explicit-any */
import { EMPTY } from 'rxjs';
import { map } from 'rxjs/operators';
import { TransactionActionResult } from '@models/C_Transactions';
import { WithDrawalBase } from './withdrawal-base';

export class TrueLayerWithdrawal extends WithDrawalBase {
  userName: string;
  incumbent: string;
  account: string;
  details: string;
  typeaccount = '';

  constructor(args: any) {
    super(args);
  }

  checkPayment() {
    return this.checkPaymentRequest().pipe(map((response: TransactionActionResult) => response));
  }

  executePayment() {
    return this.executeRequest().pipe(map((response: TransactionActionResult) => response));
  }

  showErrorCallback() {
    return EMPTY;
  }
  executeOkCallback() {
    return EMPTY;
  }
}
