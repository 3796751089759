/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { C_Message } from '@models/C_Message';
import { NavController, ModalController, IonicModule } from '@ionic/angular';
import { ViewChild, Component, ElementRef, QueryList, OnDestroy, inject } from '@angular/core';
import { MessageModalPage } from '../message-modal/message-modal';
import { MessageService } from '@providers/MessageService';
import { mergeMap } from 'rxjs/operators';
import { Utils } from '@utils/Utils';
import { CommonModule } from '@angular/common';
import { CodereFooterComponent } from '@shared-mso-components/footer/codere-footer/codere-footer';
import { TranslateModule } from '@ngx-translate/core';
import { IabService } from '@providers/iabService';
import { EventsService } from '@providers/EventsService';
import { MSO_PATHS } from '@shared-constants/routes';
import { GlobalVarsModel } from '@models/ModelGlobalsVar';
import { NewBaseService } from '@providers/newBase.service';
import { DeviceService } from '@services/device.service';
import { SanitizerHtml } from '@pipes/sanitizerHtml';
import { AccordionList } from '../../components/accordion-list/accordion-list';
import { Router } from '@angular/router';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'user-messages-page',
  templateUrl: './user-messages.html',
  standalone: true,
  styleUrls: ['./user-messages.scss'],
  imports: [CommonModule, IonicModule, TranslateModule, CodereFooterComponent, AccordionList, SanitizerHtml]
})
export class UserMessagesPage implements OnDestroy {
  @ViewChild('messageces') messageces!: QueryList<ElementRef>;
  messageCount: number;
  messages!: Array<C_Message>;
  error = false;
  loading = true;
  indexActive: number | null = null;
  listLinksActives: NodeList = null;
  globalVars!: GlobalVarsModel;
  isMobile: boolean = false;

  events = inject(EventsService);
  nav = inject(NavController);
  messageService = inject(MessageService);
  utils = inject(Utils);
  modalController = inject(ModalController);
  iabService = inject(IabService);
  newBaseService = inject(NewBaseService);
  device = inject(DeviceService);
  router = inject(Router);

  constructor() {
    this.isMobile = this.device.isMobile();
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
  }

  ngOnDestroy(): void {
    this.removeListeners();
  }

  ngOnInit() {
    if (!this.globalVars.FEATURES.messageServiceEnabled) {
      this.router.navigate([MSO_PATHS.BASE + '/' + MSO_PATHS.ContactPage]), { animate: false };
    }
    this.getUserMessages();
    this.messageService.messageCount.subscribe((data) => {
      this.messageCount = data;
    });
  }

  ionViewWillEnter() {
    this.router.navigate([MSO_PATHS.BASE + '/' + MSO_PATHS.UserMessagesPage]), { animate: false };
  }

  getHTMLContent(i) {}

  expandItem(event: MouseEvent, message: C_Message, index: number): void {
    if (this.indexActive == null || this.indexActive !== index) {
      this.indexActive = index;
      this.removeListeners();
      this.listLinksActives = event.currentTarget['querySelectorAll']('a');
      this.addListeners();
    }
    this.messages.map((listItem) => {
      if (message.id === listItem.id) {
        listItem.expanded = !listItem.expanded;
      } else {
        listItem.expanded = false;
      }
      return listItem;
    });
    if (!message.read) {
      this.setReadMessage(message);
    }
  }

  removeListeners() {
    if (this.listLinksActives && this.listLinksActives.length > 0) {
      Array.from(this.listLinksActives).forEach((a) => {
        a.removeEventListener('click', this.listendEvent, true);
      });
    }
  }

  addListeners() {
    if (this.listLinksActives && this.listLinksActives.length > 0) {
      Array.from(this.listLinksActives).forEach((a) => {
        a.addEventListener('click', this.listendEvent.bind(this));
      });
    }
  }

  listendEvent($event) {
    $event.preventDefault();
    const id = $event.currentTarget.id;
    switch (id) {
      case 'goToReactivate':
        this.router.navigate([`${MSO_PATHS.BASE}/${MSO_PATHS.MyActivityPage}`], { queryParams: { fromLink: true } });
        return;
    }

    const href: string = $event.currentTarget.href;

    //Added code for users of the old Front with the message already obtained
    if (href.endsWith('MyActivityPage')) {
      this.router.navigateByUrl(`/${MSO_PATHS.BASE}/${MSO_PATHS.MyActivityPage}`);
      return;
    }

    const urlLink = new URL(href.replace('#', ''));

    if (urlLink.pathname.length > 0) {
      const page = urlLink.pathname;

      const route: any = this.globalVars.rootScope.getComponentForRoute(page);

      if (route) {
        const params = {};

        Array.from(urlLink.searchParams.entries()).forEach(([key, value]) => {
          params[key] = value;
        });

        this.router.navigate([page], { queryParams: params });
        return;
      }
    }

    if (this.globalVars.isNative) {
      this.iabService.create(href);
    } else {
      window.open(href, '_blank');
    }
  }

  getUserMessages() {
    this.loading = true;
    return this.messageService.getMessages().subscribe(
      (data) => {
        this.messages = data;
        if (this.messages) {
          const unreadMessages = this.messages.filter((message) => !message.read);
          if (unreadMessages) {
            this.messageService.updateMessageCount(unreadMessages.length);
          }
        }
      },
      (error) => {
        this.error = true;
        console.error(error);
      },
      () => {
        this.messages.map((message) => ({ ...message, expanded: false }));
        this.loading = false;
      }
    );
  }

  async openModalMsg(message: C_Message) {
    const modal = await this.modalController.create({
      component: MessageModalPage,
      componentProps: { message: message },
      cssClass: 'auto-height'
    });
    await modal.present();
  }

  openContact() {
    this.router.navigate([MSO_PATHS.BASE + '/' + MSO_PATHS.ContactPage]), { animate: false };
  }

  setReadMessage(message: C_Message) {
    this.messageService
      .setReadMessage(message.id)
      // .flatMap((data: any) => this.messageService.getMessageCount())
      .pipe(mergeMap((data: any) => this.messageService.getMessageCount()))
      .subscribe({
        next: (data: any) => {
          const index = this.messages.findIndex((myMessage) => myMessage.id === message.id);
          this.messages[index].read = true;
          this.messageService.updateMessageCount(data);
        },
        error: (error: any) => {
          this.error = true;
        }
      });
  }
}
