<div class="sb-grid-item--score is-regular">
  <div
    *ngIf="scoreboard?.HomeAttacking && scoreboard.SportHandle !== 'american_football' "
    class="sb-grid-item--score-column"
  >
    <i
      class="sb-scoreboard-dot"
      [ngClass]="{
        'is-active': scoreboard?.HomeAttacking,
        'sb-button--animation': scoreboard?.HomeAttacking
      }"
    ></i>
    <i
      class="sb-scoreboard-dot"
      [ngClass]="{
        'is-active': !scoreboard?.HomeAttacking,
        'sb-button--animation': !scoreboard?.HomeAttacking
      }"
    ></i>
  </div>

  <div class="sb-grid-item--score-column">
    <p class="sb-grid-item--number color-accent">{{ resultHome }}</p>
    <p class="sb-grid-item--number color-accent">{{ resultAway }}</p>
  </div>
</div>
