/* eslint-disable eqeqeq */
import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
  inject
} from '@angular/core';
import { IonContent, NavParams, ModalController, IonicModule } from '@ionic/angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Observable, Subject, Subscription, takeUntil } from 'rxjs';

import { Game } from '../../../models/lobby/casinoDataModels';
import { EmptyState } from '../../../components/payments/empty-state/empty-state';

import { C_Banners } from '../../../models/C_banners';
import { CONSTANTS } from '../../../constants/lobbyConstants';

import { CookieNames } from '../../../models/CookieNames';
import { CookiesService } from '../../../providers/CookiesService';

import { EventsService } from '../../../providers/EventsService';
import { LobbyHeaderComponent } from '../../../components/casino/lobby/lobby-header/lobby-header';
import { CommonModule } from '@angular/common';
import { CasinoCategorySliderNewComponent } from '../../../components/casino/lobby/casino-category-slider-new/casino-category-slider-new.component';
import { CasinoCardNewComponent } from '../../../components/casino/casino-card-new/casino-card-new.component';
import { CasinoBannerCard } from '../../../components/casino/lobby/casino-banner-card/casino-banner-card.component';
import { CasinoLiveCardNewComponent } from '../../../components/casino/lobby/casino-live-card-new/casino-live-card-new';
import { Utils } from '../../../utils/Utils';
import { ParseMoney } from '../../../pipes/parseMoney';
import { JackpotHeaderComponent } from '../../../components/casino/lobby/jackpot-header/jackpot-header';
import { OneGameLobbyComponent } from '../../../components/casino/lobby/one-game-lobby/one-game-lobby';
import { LobbySliderComponent } from '../../../components/casino/lobby/lobby-slider/lobby-slider';
import { SlotsService } from '../../../providers/SlotsService';
import { AbstractLoobyFacadeService } from '../../../abstracts/abstract-looby-facade-service';
import { C_Jackpot } from '../../../models/casino/lobby/C_Jackpot';
import { Login } from '../../mso/login/login';
import { CodereNavbar } from '../../../components/common/codere-navbar/codere-navbar';
import { DeviceService } from '../../../services/device.service';
import { GlobalVarsModel } from '../../../models/ModelGlobalsVar';
import { NewBaseService } from '../../../providers/newBase.service';
import { BasePage } from '../../base/BasePage';
import { SbNavbarComponent } from '../../../components/common/sb-navbar/sb-navbar';
import { LobbySearchComponent } from '../../../components/casino/lobby-search/lobby-search';
import { SbNavbarItemComponent } from '../../../components/common/sb-navbar-item/sb-navbar-item';
import { CodereSliderComponent } from '../../../components/mso/slider/codere-slider/codere-slider';
import { CodereSliderCsvComponent } from '../../../components/mso/slider/codere-slider-csv/codere-slider-csv';
import { CodereFooterComponent } from '../../../components/mso/footer/codere-footer/codere-footer';
import { ActivatedRoute, Router } from '@angular/router';
import { omnichanelLobbyComponent } from '../../../components/casino/lobby/omnichanel-lobby/omnichanel-lobby';
import { JackpotCardComponent } from '../../../components/casino/lobby/jackpot-card/jackpot-card';
import { LobbyCalendarPage } from '../../../components/casino/lobby-calendar/lobby-calendar';
import { CASINO_PATHS, MSO_PATHS } from '@shared-constants/routes';
import { LobbyFacadeService } from '@services/casino/loobyFacade.service';
import { NavService } from '@providers/NavService';
import { MoreFiltersPage } from '@components/casino/more-filters/more-filters.component';
import { CasinoFilter } from '@interfaces/casinoMoreFiltersInterfaces';
import { EventTypes } from '@models/TrackingEvents';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'page-lobby-casino',
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    LobbyHeaderComponent,
    CasinoCategorySliderNewComponent,
    CasinoCardNewComponent,
    CasinoBannerCard,
    CasinoLiveCardNewComponent,
    SbNavbarComponent,
    LobbySearchComponent,
    SbNavbarItemComponent,
    CodereSliderComponent,
    CodereSliderCsvComponent,
    EmptyState,
    CodereFooterComponent,
    ParseMoney,
    JackpotHeaderComponent,
    OneGameLobbyComponent,
    LobbySliderComponent,
    CodereNavbar,
    omnichanelLobbyComponent,
    JackpotCardComponent
  ],
  standalone: true,
  providers: [
    NavParams,
    {
      provide: AbstractLoobyFacadeService,
      useClass: LobbyFacadeService
    }
  ],
  templateUrl: './lobby-casino.html',
  styleUrls: ['./lobby-casino.scss']
})
export class LobbyCasinoPage extends BasePage implements OnInit, OnDestroy, AfterViewChecked {
  @ViewChild(IonContent, { static: false }) content: IonContent;
  // #region Inject Service
  events = inject(EventsService);
  navParams = inject(NavParams);
  newBaseService = inject(NewBaseService);
  slotsService = inject(SlotsService);
  cookiesService = inject(CookiesService);
  modalController = inject(ModalController);
  lobbyFacadeService = inject(AbstractLoobyFacadeService);
  utils = inject(Utils);
  route = inject(ActivatedRoute);
  deviceService = inject(DeviceService);
  router = inject(Router);
  navService = inject(NavService);
  translate = inject(TranslateService);
  // #endregion

  activeFilterIndex: number;
  activeFilterName: string;
  filters: any[];
  gridLengthOptions: any;
  isScrolled = false;
  lobby$: Observable<any>;
  moreFiltersApplied$: Observable<any>;
  lobbyName: string;
  singleGame = false;
  omnichannel = false;

  resizeObservable$: Observable<Event>;
  resizeSubscription$: Subscription;
  widthGridLengthA: number;
  widthGridLengthB: number;
  sliderVisible = true;
  subscriptions: Subscription[];
  navCategory: string;
  navFilter: string;
  gameToPlay: string;
  gamecheck = false;
  lobbyConstants = CONSTANTS;
  sectionPage: string;
  showBanner = true;
  showCarousel = true;
  globalVars!: GlobalVarsModel;
  isDesktop = false;
  isMobile = false;
  redirect = false;
  private destroy$ = new Subject<void>();

  private categoryName = '';

  constructor(private cd: ChangeDetectorRef) {
    super();

    // * GlovalVars
    this.newBaseService.getVars.pipe(takeUntil(this.destroy$)).subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });

    this.isDesktop = this.deviceService.isDesktop();
    this.isMobile = this.deviceService.isMobile();

    this.stopEvents();

    this.events.subscribe('gameLoginSuccesful', () => {
      const game: Game = this.globalVars.afterLoginParamsUrl;
      // If game exists and user is logged play game
      if (game && this.globalVars.user.logged) {
        this.lobbyFacadeService.playGame(game);
        this.globalVars.afterLoginParamsUrl = '';
      } else {
        this.gameToPlay = null;
        this.globalVars.afterLoginParamsUrl = '';
      }
    });
    this.events.subscribe('user:login', () => {
      this.showBanner = true;
      this.lobbyFacadeService.forceRPG('fromLobby');
    });
    this.globalVars.gameFromQuickLaunch = false;
  }

  // Runs after all view has been checked
  ngAfterViewChecked(): void {
    // Sets the category only if not in jackpot lobby and if category parameters exists
    if (this.navCategory && this.lobbyName !== 'jackpot') {
      this.setCategory(this.navCategory);
      this.navCategory = undefined;
    }
    if (this.navFilter && this.lobbyName !== 'jackpot') {
      this.setFilter(this.navFilter);
      this.navFilter = undefined;
    }
    this.lobbyFacadeService.filterName$.subscribe((filterName) => {
      if (this.activeFilterName === this.translate.instant('casinoMoreFilters') && filterName === CONSTANTS.all) {
        this.selectActiveFilter(filterName);
      }
    });
  }

  override async ngOnInit() {
    this.startPage();
    await this.lobbyFacadeService.setLobbyData(this.lobbyName);
    this.lobby$ = this.lobbyFacadeService.lobbyState;
    this.moreFiltersApplied$ = this.lobbyFacadeService.moreFiltersApplied;
    this.filters = this.lobbyFacadeService.filtersList;
    setTimeout(() => {
      this.activeFilterIndex = 0;
      this.events.publish('finishLobbies:casino');
    });
    this.lobbyFacadeService.checkBonusCasino();
  }

  startPage() {
    this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      this.singleGame = false;
      // Resets category filter
      this.navCategory = undefined;
      this.navFilter = undefined;

      this.lobbyName = params['lobby'];
      this.singleGame = params['singleGame'] || false;
      if (this.lobbyName === 'Omnichannel') {
        this.omnichannel = params['lobby'];
      }

      if (params['playgame']) {
        let game = params['playgame'];
        // The param comes from app.component openLobby() replace - with empty space
        if (game.indexOf('-') !== -1) {
          game = game.replace(/-/g, ' ');
        }
        if (game) {
          this.gameToPlay = game;
          this.initGameUrl();
        }
      }
      if (params['filter']) {
        let newFilt = params['filter'];
        // The param comes from app.component openLobby() replace - with empty space
        if (newFilt.indexOf('-') !== -1) {
          newFilt = newFilt.replace(/-/g, ' ');
        }
        this.navFilter = this.capitalizefirstLetter(newFilt);
      }

      if (params['category']) {
        let category = params['category'];
        if (category.indexOf('-') !== -1) {
          category = category.replace(/-/g, ' ');
        }
        this.navCategory = category;
      } else {
        // Resets lobby if already exists when changing between lobby pages
        if (this.lobby$) {
          this.lobbyFacadeService.setLobbyData(this.lobbyName).then(() => {
            this.lobby$ = this.lobbyFacadeService.lobbyState;
            this.filters = this.lobbyFacadeService.filtersList;
          });
        }

        // If no params provided, e.g.: page refresh loses queryparams
        if (!Object.keys(params).length && !this.isMobile) {
          this.navService.lobbies.forEach((lobby) => {
            if (this.router.url.toUpperCase().includes(lobby.lobbyUrl.toUpperCase())) {
              this.router
                .navigate([`casino/${lobby.lobbyUrl}Page`], {
                  queryParams: { lobby: lobby.LobbyName, singleGame: lobby.SingleGameLobby }
                })
                .then((hasNavigated) => {
                  if (hasNavigated) {
                    this.lobbyFacadeService.setLobbyData(lobby.LobbyName).then(() => {
                      this.lobby$ = this.lobbyFacadeService.lobbyState;
                      this.filters = this.lobbyFacadeService.filtersList;
                    });
                  }
                });
            }
          });
        }
      }
    });

    if (!this.lobbyName) {
      this.lobbyName = this.lobbyFacadeService.getLobbyFromUrl();
    }
    if (!this.singleGame) {
      this.singleGame = this.lobbyFacadeService.getIsSingleLobby(this.lobbyName);
    }

    if (!this.omnichannel) {
      this.omnichannel = this.lobbyFacadeService.getIsOmnichannel(this.lobbyName);
    }

    if (this.navParams.get('codepromotion')) {
      this.globalVars.codePromotion = this.navParams.get('codepromotion');
      this.cookiesService.setCookie(
        CookieNames.CoderePromotionCode,
        this.globalVars.codePromotion,
        3 * 24 * 60 * 60 * 1000,
        true
      );
    }
    this.cd.detectChanges();
  }

  ngOnDestroy() {
    this.singleGame = false;
    if (this.resizeSubscription$) {
      this.resizeSubscription$.unsubscribe();
    }
  }

  stopEvents() {
    this.events.destroy('gameLoginSuccesful');
    this.events.destroy('finishLobbies:casino');
  }

  getTrackingInfo() {
    return {
      uri: this.sectionPage,
      description: 'lobby casino section page',
      additionalData: null
    };
  }

  capitalizefirstLetter(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  /**
   * Selects sb-navbar selected item
   */
  setFilter(name: string) {
    if (this.redirect) {
      this.router.navigate([`/${CASINO_PATHS.BASE}/${CASINO_PATHS.CasinoPage}`]);
      this.redirect = false;
    }

    const newFilter = this.filters?.find((filter) => filter.label.toLowerCase() === name.toLowerCase());
    if (newFilter) {
      setTimeout(() => {
        if (name === this.globalVars.FEATURES.casinoCalendarName) {
          this.lobbyFacadeService.setFilter(name, LobbyCalendarPage);
          this.selectActiveFilter('Todos');
        } else if (name === this.translate.instant('casinoMoreFilters')) {
          this.lobbyFacadeService.setFilter(name, MoreFiltersPage);
          this.selectActiveFilter(name);
        } else {
          this.lobbyFacadeService.setFilter(name, LobbyCasinoPage);
          this.selectActiveFilter(name);
        }
      }, 10);
    }
  }

  setCategory(category) {
    this.lobbyFacadeService.unapplyMoreFilters();
    if (this.redirect) {
      this.router.navigate([`/${CASINO_PATHS.BASE}/${CASINO_PATHS.CasinoPage}`]);
      this.redirect = false;
    }

    let filter = undefined;
    if (category !== undefined) {
      if (typeof category === 'string') {
        if (category === CONSTANTS.allEng) {
          this.activeFilterIndex = 0;
          category = CONSTANTS.all;
          filter = CONSTANTS.all;
        } else {
          this.activeFilterIndex = -1;
        }
      } else {
        if (category.type === CONSTANTS.allEng) {
          this.activeFilterIndex = 0;
          category = CONSTANTS.all;
          filter = CONSTANTS.all;
        } else {
          this.activeFilterIndex = -1;
          category = category.name;

          if (this.isDesktop) {
            window.scrollY = window.innerHeight;
            const ionContentArray = Array.from(document.getElementsByClassName('background-color-dark'));
            ionContentArray.forEach((ionContent) => {
              ionContent.scrollBy({
                top: -ionContent.scrollHeight,
                left: 0,
                behavior: 'smooth'
              });
            });
          } else {
            this.content.scrollToTop(1500);
          }
        }
      }
      setTimeout(() => {
        this.selectActiveFilter(filter);
        this.lobbyFacadeService.setCategory(category);
      }, 0);
    }

    this.categoryName = category.name;

    if (category.name) {
      this.trackingService.track({
        eventType: EventTypes.CasinoHomeViewAll,
        description: `"Ver todos" home category selected: ${category.name}`,
        additionalData: {
          category: category.name,
          lobby: this.lobbyName,
          platform: this.lobbyFacadeService.getPlatform()
        }
      });
    } else {
      this.trackingService.track({
        eventType: EventTypes.CasinoHomeHidde,
        description: `"Ocultar" home category selected: ${category.name}`,
        additionalData: {
          category: category.name,
          lobby: this.lobbyName,
          platform: this.lobbyFacadeService.getPlatform()
        }
      });
    }
  }

  selectSearch(word: string) {
    this.trackingService.track({
      eventType: EventTypes.CasinoSearch,
      description: `Search with text: ${word}`,
      additionalData: {
        text: word,
        lobby: this.lobbyName,
        platform: this.lobbyFacadeService.getPlatform()
      }
    });
    this.lobbyFacadeService.search(word);
    this.activeFilterIndex = -1;
    this.activeFilterName = undefined;
    this.selectActiveFilter(undefined);
  }

  scrolling(event: any) {
    this.isScrolled = true;
    this.onScroll(event);
  }

  playGame(pGame) {
    pGame.game.lobby = this.lobbyFacadeService.getCurrentLobby();
    pGame.game.product = 'Casino';
    this.lobbyFacadeService.playGame(pGame);
  }

  hideBanner() {
    if (this.showBanner) {
      this.showBanner = false;
    } else {
      this.showBanner = true;
    }
  }

  async clickBanner(theBanner: C_Banners) {
    if (theBanner.Target.toLowerCase() == '_opengame') {
      const gameB = await this.lobbyFacadeService.loadGameByURL(theBanner.Url);
      if (gameB != null) {
        const bGame = {
          demo: false,
          game: gameB
        };
        bGame.game.lobby = 'PromoBanner';
        this.lobbyFacadeService.playGame(bGame);
      }
    } else if (theBanner.Target == '_self') {
      if (theBanner.Url.search('http') != -1) {
        if (theBanner.Url.search(this.globalVars.DOMAIN) != -1) {
          if (theBanner.Url.search('#') != -1) {
            const num = theBanner.Url.search('#');
            const url = theBanner.Url.substring(num + 2);
            this.globalVars.rootScope.extTryToGoPage(url);
          }
        }
      } else {
        if (theBanner.Url.search('#') != -1) {
          const num = theBanner.Url.search('#');
          const url = theBanner.Url.substring(num + 2);
          this.globalVars.rootScope.extTryToGoPage(url);
        }
      }
    } else {
      window.open(theBanner.Url);
    }
  }

  private selectActiveFilter(filter) {
    this.filters = this.filters?.map((f, index) => {
      f.selected = f.label === filter;
      if (f.selected) {
        this.activeFilterIndex = index;
        this.activeFilterName = filter;
      }
      return f;
    });
    this.trackingService.track({
      eventType: EventTypes.CasinoLobbyFilterApplied,
      description: `Lobby filter applied: ${this.activeFilterName}`,
      additionalData: {
        filter: filter,
        lobby: this.lobbyName,
        platform: this.lobbyFacadeService.getPlatform()
      }
    });
  }

  openInfo(jackpot: C_Jackpot) {
    const url = jackpot.uiInfo.termsAndConditionsUrl;
    this.router.navigate([`/${MSO_PATHS.BASE}/${MSO_PATHS.PromotionsPage}`], {
      queryParams: { url: url }
    });
  }

  async openLogin(jackpot: C_Jackpot) {
    if (!this.globalVars.user.logged) {
      const login = await this.modalController.create({
        component: Login
      });
      login.present();
      return;
    }
  }

  async initGameUrl() {
    const GAMES = await this.lobbyFacadeService.getGames();
    let lobbyGame;
    Object.keys(GAMES).forEach((id) => {
      if (GAMES[id].Name.toLowerCase() === this.gameToPlay.toLowerCase()) {
        lobbyGame = Object.assign(GAMES[id]);
        lobbyGame.GameId = id;
      }
    });
    if (lobbyGame) {
      // eslint-disable-next-line no-shadow
      const Game = {
        demo: false,
        game: lobbyGame
      };
      Game.game.lobby = 'PromoBanner';
      this.gameToPlay = null;
      this.lobbyFacadeService.playGame(Game);
    }
  }

  unapplyMoreFilters(): void {
    this.lobbyFacadeService.unapplyMoreFilters();
  }

  unapplyMoreFilter(casinoFilter: CasinoFilter): void {
    this.lobbyFacadeService.unapplyMoreFilter(casinoFilter);
  }

  onScroll(event: any): void {
    if (event?.detail?.currentY > event?.detail?.startY) {
      if (this.categoryName) {
        this.trackingService.track({
          eventType: EventTypes.CasinoHomeScrollDown,
          description: `Home category scroll down: ${this.categoryName}`,
          additionalData: {
            category: this.categoryName,
            lobby: this.lobbyName,
            platform: this.lobbyFacadeService.getPlatform()
          }
        });
      } else if (this.activeFilterName) {
        this.trackingService.track({
          eventType: EventTypes.CasinoLobbyFilterScrollDown,
          description: `Lobby filter scroll down: ${this.activeFilterName}`,
          additionalData: {
            filter: this.activeFilterName,
            lobby: this.lobbyName,
            platform: this.lobbyFacadeService.getPlatform()
          }
        });
      }
    } else {
      if (this.categoryName) {
        this.trackingService.track({
          eventType: EventTypes.CasinoHomeScrollUp,
          description: `Home category scroll up: ${this.categoryName}`,
          additionalData: {
            category: this.categoryName,
            lobby: this.lobbyName,
            platform: this.lobbyFacadeService.getPlatform()
          }
        });
      } else if (this.activeFilterName) {
        this.trackingService.track({
          eventType: EventTypes.CasinoLobbyFilterScrollUp,
          description: `Lobby filter scroll up: ${this.activeFilterName}`,
          additionalData: {
            filter: this.activeFilterName,
            lobby: this.lobbyName,
            platform: this.lobbyFacadeService.getPlatform()
          }
        });
      }
    }
  }
}
