<div class="container container-indications-camera">
  <button (click)="dismiss()" class="background-color-transparent btn close-button">
    <i class="color-light codere-icon icon-close" item-right=""></i>
  </button>
  <div class="camera-content" *ngIf="!pictureImage">
    <video #cameraFeed autoplay></video>
  </div>
  <div class="image-content" *ngIf="pictureImage">
    <img [src]="pictureImage" alt="picture image" />
  </div>
  <div *ngIf="pictureImage" class="color-light review-pic text-center">
    <div class="text-picture-alert">
      <p class="font-weight-bold title">Revisa la foto</p>
      <p class="first-comment">¿Has comprobado que se pueden leer los detalles y la identificación completa?</p>
      <p class="second-comment">¿La foto está nítida sin sombras o flashes que dificulten el reconocimiento?</p>
      <div class="review-pic-buttons">
        <button (click)="retakePic()" class="repeat">Repetir</button>
        <button class="btn btn-green-codere confirm" (click)="confirmPic()">Confirmar</button>
      </div>
    </div>
  </div>
  <div class="bottom-bar-mobile" (click)="takePicture()" *ngIf="!pictureImage">
    <div class="outside-circle">
      <div class="inside-circle"></div>
    </div>
    <span class="cancel-text" (click)="dismiss()">Cancel</span>
  </div>
  <div class="bottom-bar-desktop" *ngIf="!pictureImage">
    <button class="bottom-button" (click)="takePicture()" *ngIf="!pictureImage">Haz CLIC AQUÍ para capturar</button>
  </div>
</div>
