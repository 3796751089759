import { Component, Input, OnInit, inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { ErrorsControls, LabelUsuarioControls } from '@interfaces/interfaces';
import { ExpansionComponent } from '../../registro/expansion/expansion.component';
import { InputComponent } from '../../registro/input/input.component';
import { IonicModule } from '@ionic/angular';

@Component({
  standalone: true,
  imports: [CommonModule, IonicModule, InputComponent, ExpansionComponent],
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'cdr-fs-usuario',
  templateUrl: './fs-usuario.component.html',
  styleUrls: ['./fs-usuario.component.scss']
})
export class FsUsuarioComponent implements OnInit {
  // Objetos del UI
  errors: ErrorsControls;
  labels: LabelUsuarioControls;

  // FormControl
  @Input() formGrp: FormGroup;
  formGrpName: string;
  titleSection: string;

  translate = inject(TranslateService);

  constructor() {}

  ngOnInit() {
    this.formGrpName = 'usuario';
    this.titleSection = this.translate.instant('v2_registerStep3'); // 'Datos de usuario'
    this.labels = this.setLabels();
    this.errors = this.setErrors();
  }

  private setLabels(): LabelUsuarioControls {
    return {
      user: this.translate.instant('loginUser'),
      password: this.translate.instant('loginPass'), //	Contraseña
      promoCode: 'Código promocional (opcional)',
      friendsID: 'Código amigo (opcional)'
    };
  }

  private setErrors(): ErrorsControls {
    return {
      user: {
        required: 'Introduce el nombre de usuario',
        minlength: this.translate.instant('error_form_min4'), //'El usuario debe tener al menos 4 caracteres',
        pattern: 'Elimine en el usario los caracteres no válidos',
        validUnique: this.translate.instant('error_checkUsername') //	El usuario introducido ya existe.
      },
      password: {
        required: this.translate.instant('v2_contraObl'),
        validPassword: ''
      },
      promoCode: {
        required: '', // No required
        validFormat: this.translate.instant('v2_errorCodigoPromo') //'El código promocional no es válido'
      }
    };
  }
}
