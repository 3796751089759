/* eslint-disable @typescript-eslint/no-explicit-any */
import { BaseServiceExt } from '@providers/BaseServiceExt';
import { Injectable, inject } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { Constants } from 'libs/shared/src/utils/constants';

import { C_Event } from '@models/C_Event';
import { C_SearchResult } from '@models/C_SearchResult';
import { C_Horses } from '@models/C_Horses';
import { C_EventLive } from '@models/C_EventLive';
import { Router } from '@angular/router';
import { SPORTS_PATHS } from '@shared-constants/routes';
import { DeviceService } from '@services/device.service';

@Injectable({ providedIn: 'root' })
export class SearchService extends BaseServiceExt {
  searchTerm: Subject<any> = new Subject();
  inputWidth: any;
  router = inject(Router);
  deviceService = inject(DeviceService);
  constructor() {
    super();
  }

  getResultSearch(text: string): Observable<any> {
    const url = this.globalVars.NAVIGATIONSERVICEBASEURL + Constants.getSearchUrl;
    const params = { text };
    return this.myGet(url, false, { params }).pipe(
      map((results) =>
        results.map((result) => {
          result = C_Event.parse(result);
          result.setHomeAndAway();
          return result;
        })
      )
    );
  }

  getSpecialTeamSearch(texto: string): Observable<C_Event[]> {
    let url = this.globalVars.NAVIGATIONSERVICEBASEURL + Constants.getCustomQueryUrl;
    url += '?queryType=' + texto;
    return this.myGet(url).pipe(
      map((results) =>
        results.map((result) => {
          result = C_Event.parse(result);
          result.setHomeAndAway();
          return result;
        })
      )
    );
  }

  getCustomQuerySearch(queryType: string): Observable<C_Event[]> {
    const url = this.globalVars.NAVIGATIONSERVICEBASEURL + Constants.getCustomQueryUrl;
    const params = { queryType };

    return this.myGet(url, false, { params }).pipe(
      map((results) =>
        results.map((result) => {
          result = C_Event.parse(result);
          result.setHomeAndAway();
          return result;
        })
      )
    );
  }

  getResultTownSearch(texto: string): Observable<any> {
    const url = this.globalVars.URLBASE + 'Colombia/GetTownList?name=' + texto;
    return this.myGet(url);
  }

  navigateToSearchItemPageMobile(event, item: C_SearchResult, isMobile) {
    console.log({ page: 'navigateToSearchItemPageMobile', event, item });
    this.globalVars.SportSelected = '';
    this.setTypeOfSportHandle(item, item.isLive, isMobile);
  }

  setTypeOfSportHandle(item, isLive, isMobile) {
    if (item.SportHandle == 'greyhound_racing' || item.SportHandle == 'horse_racing') {
      const raceEvent: C_Horses = new C_Horses(
        item.Name,
        item.NodeId,
        item.ParentNodeId,
        item.EventNodeTypeId,
        item.Priority,
        item.SportHandle,
        item.ChildrenCount,
        item.Country,
        false,
        0,
        item.PaddockId,
        item.StartDate
      );

      this.navigateMercadosPage(raceEvent, item.SportHandle, isMobile);
    } else {
      //si es live vamos a evento live
      if (item.isLive) {
        const event = C_EventLive.parse(item);
        const eventGameTypes = this.globalVars.gameTypes.getOne(event.SportHandle, event.CountryCode, event.isLive);
        event.setGamesByPlatform(eventGameTypes, isMobile);

        this.navigateNowLiveDetailPage(event, item.SportHandle, isMobile);
      } else {
        const event = C_Event.parse(item);
        const eventGameTypes = this.globalVars.gameTypes.getOne(event.SportHandle, event.CountryCode, event.isLive);
        event.hasSpecialGame(this.globalVars.gameTypes.apuestas_especiales);
        event.setGamesByPlatform(eventGameTypes, isMobile);

        this.navigateMercadosPage(event, item.SportHandle, isMobile);
      }
    }
  }

  navigateNowLiveDetailPage(item, SportHandle, isMobile) {
    this.router.navigate([SPORTS_PATHS.NowLiveDetailPage], { state: { params: item, animate: isMobile } });
  }

  navigateMercadosPage(item, SportHandle, isMobile) {
    if (SportHandle == 'greyhound_racing' || SportHandle == 'horse_racing') {
      this.router.navigate([SPORTS_PATHS.HorsesMarketPage], { state: { params: item, animate: isMobile } });
    } else {
      this.router.navigate([SPORTS_PATHS.MercadosPage], { state: { params: item, animate: isMobile } });
    }
  }
}
