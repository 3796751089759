<div [ngClass]="[cssDiv, (globalVars.LICENSE_TYPE == 33 || globalVars.LICENSE_TYPE == 34 ? 'extraImgAR' : '')]">
  <div *ngFor="let item of extraImgs" [ngClass]="item.divContainerCss">
    <img *ngIf="item.show && !isImageType(item)" [src]="item.src" [ngClass]="item.class" [alt]="item.altImg" />
    <a
      *ngIf="item.show && isImageType(item) && item.src && !item.event && item.external"
      external-link
      [href]="item.href"
      [target]="item.target"
      [ngClass]="item.class"
      ><img [src]="item.src" [alt]="item.altImg" [ngClass]="item.classImg"
    /></a>
    <a
      *ngIf="item.show && isImageType(item) && !item.src && !item.event"
      external-link
      [href]="item.href"
      [target]="item.target"
      [ngClass]="item.class"
      >{{item.title | translate}}</a
    >
    <a
      *ngIf="item.show && isImageType(item) && item.src && item.event "
      (click)="this.openEvent($event, item.typeEvent)"
      [ngClass]="item.class"
      ><img [src]="item.src" [alt]="item.altImg" [ngClass]="item.classImg"
    /></a>
    <a
      *ngIf="item.show && isImageType(item) && item.src && !item.event && !item.external"
      [href]="item.href"
      [ngClass]="item.class"
      ><img [src]="item.src" [alt]="item.altImg" [ngClass]="item.classImg"
    /></a>
  </div>
</div>
