import { CommonModule } from "@angular/common";
import { Component, OnInit, inject } from "@angular/core";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { CodereNavbar } from "@components/common/codere-navbar/codere-navbar";
import { CodereFooterComponent } from "@components/mso/footer/codere-footer/codere-footer";
import { UserSectionTitle } from "@components/mso/user-section-title/user-section-title";
import { IonicModule } from "@ionic/angular";
import { I_MyActivity, I_ReactivateAccount } from "@models/I_MyActivity";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ParseMoney } from "@pipes/parseMoney";
import { EventsService } from "@providers/EventsService";
import { UserService } from "@providers/UserService";
import { NewBaseService } from "@providers/newBase.service";
import { DeviceService } from "@services/device.service";
import { CASINO_PATHS, MSO_PATHS, PAYMENTS_PATHS, SPORTS_PATHS } from "@shared-constants/routes";
import { Utils } from "@utils/Utils";

@Component({
  standalone: true,
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "my-activity-page",
  styleUrls: ["./my-activity.scss"],
  templateUrl: "./my-activity.html",
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    ParseMoney,
    CodereNavbar,
    UserSectionTitle,
    CodereFooterComponent,
    ReactiveFormsModule,
  ],
})
export class MyActivityPage implements OnInit {
  selectList: Array<{ value: string; name: string }>;
  selectedDate = new FormControl({ value: "", disabled: true });
  startDate: Date;
  endDate: Date;
  myActivity: I_MyActivity;
  reactivateAccountData: I_ReactivateAccount;
  isReactivateAccount = false;
  globalVars!: GlobalVarsModel;
  isMobile = false;
  auxDate = "";

  newBaseService = inject(NewBaseService);
  translate = inject(TranslateService);
  userservice = inject(UserService);
  events = inject(EventsService);
  utils = inject(Utils);
  activeRoute = inject(ActivatedRoute);
  route = inject(Router);
  deviceService = inject(DeviceService);

  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });

    this.isMobile = this.deviceService.isMobile();

    this.selectList = [
      //para  history casino y payments
      {
        value: "2",
        name: this.translate.instant("histapu2d"),
      },
      {
        value: "7",
        name: this.translate.instant("histapu7d"),
      },
      {
        value: "15",
        name: this.translate.instant("histapu15d"),
      },
      {
        value: "30",
        name: this.translate.instant("last30days"),
      },
      {
        value: "lastmonth",
        name: this.translate.instant("lastmonth"),
      },
      {
        value: "90",
        name: this.translate.instant("histapu90d"),
      },
    ];
  }

  ngOnInit(): void {
    this.selectedDate.valueChanges.subscribe((data) => {
      if (this.isReactivateAccount) {
        this.getReactivateAccountData();
      }
      if (data === this.auxDate) return;
      this.auxDate = data;
      this.selectDates(data);
    });

    this.activeRoute.queryParams.subscribe((params) => {
      if (params["fromLink"] && params["fromLink"] === "true") {
        this.isReactivateAccount = true;
        this.selectedDate.setValue("90");
      } else {
        this.selectedDate.setValue("30");
      }
    });

    this.selectedDate.enable();
  }

  ionViewDidLeave() {
    this.auxDate = "";
  }

  selectDates(value: string) {
    switch (value) {
      case "2":
        this.startDate = new Date();
        this.startDate.setDate(this.startDate.getDate() - 2);
        this.endDate = new Date();
        break;
      case "7":
        this.startDate = new Date();
        this.startDate.setDate(this.startDate.getDate() - 7);
        this.endDate = new Date();
        break;
      case "15":
        this.startDate = new Date();
        this.startDate.setDate(this.startDate.getDate() - 15);
        this.endDate = new Date();
        break;
      case "30":
        this.startDate = new Date();
        this.startDate.setDate(this.startDate.getDate() - 30);
        this.endDate = new Date();
        break;
      case "lastmonth":
        this.startDate = new Date();
        this.startDate.setDate(1);
        this.startDate.setMonth(this.startDate.getMonth() - 1);
        this.endDate = new Date(this.startDate.getFullYear(), this.startDate.getMonth() + 1, 0);
        break;
      case "90":
        this.startDate = new Date();
        this.startDate.setDate(this.startDate.getDate() - 90);
        this.endDate = new Date();
        break;
    }
    if (!this.isReactivateAccount) {
      this.getMyActivity();
    }
  }
  getMyActivity() {
    if (this.startDate && this.endDate) {
      this.utils.loader();
      this.userservice.getUserActivity(this.formatDate(this.startDate), this.formatDate(this.endDate)).subscribe({
        next: (data) => {
          data.depositLimits.dailyLimit.changes = data.depositLimits.dailyLimit.changes.sort(this.dateFilter);
          data.depositLimits.weeklyLimit.changes = data.depositLimits.weeklyLimit.changes.sort(this.dateFilter);
          data.depositLimits.monthlyLimit.changes = data.depositLimits.monthlyLimit.changes.sort(this.dateFilter);
          this.myActivity = data;
          this.utils.closeLoader();
        },
        error: () => {
          this.utils.closeLoader();
        },
      });
    }
  }
  getReactivateAccountData() {
    this.utils.loader();
    this.userservice.getReactivateAccountData().subscribe({
      next: (data) => {
        this.reactivateAccountData = data;
        this.utils.closeLoader();
      },
      error: () => {
        this.utils.closeLoader();
      },
    });
  }
  dateFilter(a: { dateChange: Date; amount: number }, b: { dateChange: Date; amount: number }) {
    const dateA = new Date(a.dateChange);
    const dateB = new Date(b.dateChange);
    if (dateA.getTime() > dateB.getTime()) {
      return -1;
    }
    if (dateA.getTime() < dateB.getTime()) {
      return 1;
    }
    return 0;
  }
  getMonthString(index: number) {
    const months = [
      this.translate.instant("rMEn"),
      this.translate.instant("rMFe"),
      this.translate.instant("rMMa"),
      this.translate.instant("rMAv"),
      this.translate.instant("rMMy"),
      this.translate.instant("rMJn"),
      this.translate.instant("rMJl"),
      this.translate.instant("rMAg"),
      this.translate.instant("rMSe"),
      this.translate.instant("rMOc"),
      this.translate.instant("rMNo"),
      this.translate.instant("rMDi"),
    ];
    return months[index];
  }
  gotoLimits() {
    this.route.navigate([`/${MSO_PATHS.BASE}/${MSO_PATHS.LimitsPage}`]);
  }

  goToCashier() {
    const filters = {
      type: this.selectedDate.value === "lastmonth" ? "0" : this.selectedDate.value,
      start: this.startDate,
      end: this.endDate,
    };

    this.globalVars.rootScope.openModalOrPage(
      `${PAYMENTS_PATHS.BASE}/${PAYMENTS_PATHS.CashierPage}`,
      { section: "history", filterActivity: filters },
      true,
      "modalCashier is-modal",
    );
  }

  goToSlotsHistory() {
    const filters = {
      type: this.selectedDate.value,
      start: this.startDate,
      end: this.endDate,
    };

    this.route.navigate([`/${CASINO_PATHS.BASE}/${CASINO_PATHS.SlotsHistoryPage}`], {
      queryParams: { type: filters.type, start: filters.start.getTime(), end: filters.end.getTime() },
    });
  }

  setFormatValue(value: string) {
    // para history sports
    let type = 0;
    switch (value) {
      case "2":
        type = 0;
        break;
      case "7":
        type = 1;
        break;
      case "15":
        type = 2;
        break;
      case "30":
        type = 3;
        break;
      case "lastmonth":
        type = 4;
        break;
      case "90":
        type = 5;
        break;
    }
    return type;
  }

  gotoMyBets() {
    const filters = {
      type: this.selectedDate.value,
      start: this.startDate,
      end: this.endDate,
    };

    const misApuestasParams = {
      dateValue: this.setFormatValue(filters.type),
      start: filters.start.getTime(),
      end: filters.end.getTime(),
      typeValue: 0,
    };

    this.route.navigate([`/${SPORTS_PATHS.BASE}/${SPORTS_PATHS.MisApuestasPage}`], {
      state: { params: misApuestasParams },
    });
  }

  goToSumary() {
    const filters = {
      type: this.selectedDate.value,
      start: this.startDate,
      end: this.endDate,
    };
    this.route.navigate([`/${MSO_PATHS.BASE}/${MSO_PATHS.SummaryOfMovementsPage}`], {
      state: { type: filters.type, start: filters.start.getTime(), end: filters.end.getTime() },
    });
  }

  formatDate(datetoFormat: Date) {
    let day = "";
    if (datetoFormat.getDate() < 10) {
      day = "0" + datetoFormat.getDate().toString();
    } else {
      day = datetoFormat.getDate().toString();
    }
    let month = "";
    if (datetoFormat.getMonth() + 1 < 10) {
      month = "0" + (datetoFormat.getMonth() + 1).toString();
    } else {
      month = (datetoFormat.getMonth() + 1).toString();
    }
    return `${day}-${month}-${datetoFormat.getFullYear()}`;
  }

  getFormatLimits(limitValue: number, type: "day" | "week" | "month") {
    let newValue = 0;
    switch (type) {
      case "day":
        newValue = limitValue > 0 ? limitValue : parseInt(this.globalVars.FEATURES.depositDayLimit);
        break;
      case "week":
        newValue = limitValue > 0 ? limitValue : parseInt(this.globalVars.FEATURES.depositWeekLimit);
        break;
      case "month":
        newValue = limitValue > 0 ? limitValue : parseInt(this.globalVars.FEATURES.depositMonthLimit);
        break;
    }
    return newValue;
  }
  getNetBalance(item: I_ReactivateAccount) {
    if (item && item.summaryData) {
      return (
        item.summaryData.openingBalance +
        item.summaryData.deposits -
        item.summaryData.withdrawals -
        item.summaryData.finalBalance
      );
    } else {
      return 0;
    }
  }
  getCprTxt(item: I_ReactivateAccount) {
    let txt = "";
    switch (item.cprCase) {
      case 1:
        txt = "cprintensiverisky";
        break;
      case 2:
        txt = "cprrisky";
        break;
      case 3:
        txt = "cprintensive";
        break;
      case 0:
        return "";
    }
    return this.translate.instant(txt);
  }
}
