<!-- eslint-disable @angular-eslint/template/eqeqeq -->
<ion-content>
  <div class="contModal">
    <div *ngIf="pageInt==0">
      <div>
        <h2 class="limitTitalert">AUMENTO DE L&Iacute;MITES DE DEP&Oacute;SITO</h2>
      </div>
      <div class="limitTitalertMessage">
        <span class="alertMessageCustom color61"
          >Para eliminar o aumentar los l&iacute;mites de dep&oacute;sito predeterminados, es necesario que realices un
          test de juego responsable.</span
        >
      </div>
      <ion-row class="buttonsLine limitcentertxt">
        <ion-col (click)="btCancel_Click()">
          <ion-button class="disable-hover button button-ios button-default button-default-ios" tappable="">
            <span class="button-inner"> Cancelar</span>
            <div class="button-effect"></div>
          </ion-button>
        </ion-col>
        <ion-col (click)="nextPage()">
          <ion-button class="disable-hover button button-ios button-default button-default-ios" tappable="">
            <span class="button-inner"> Continuar</span>
            <div class="button-effect"></div>
          </ion-button>
        </ion-col>
      </ion-row>
    </div>
    <div *ngIf="pageInt==1">
      <div>
        <h2 class="limitTitalert">AUMENTO DE L&Iacute;MITES DE DEP&Oacute;SITO</h2>
      </div>
      <div class="limitTitalertMessage">
        <div *ngIf="globalVars.licenseType == 0">
          <div class="limitAlert-message">
            <span class="alertMessageCustom txtley color61">
              En cumplimiento de la Ley 15/1999, de 13 de Diciembre, de Protecci&oacute;n de Datos de Car&aacute;cter
              Personal, los datos de car&aacute;cter personal que se recogen en este documento se incluir&aacute;n en el
              fichero de datos personales con la finalidad de prestar el servicio solicitado, dar cumplimiento a la
              relaci&oacute;n contractual establecida con el jugador, gestionar la participaci&oacute;n en los juegos y
              apuestas, as&iacute; como para llevar a cabo la gesti&oacute;n operativa y comercial de los juegos y
              apuestas, y tambi&eacute;n para cumplir con las obligaciones legales derivadas de la actividad, siendo
              responsable del tratamiento CODERE ONLINE, S.A.U.
              <br />El suscriptor del presente documento podr&aacute; ejercitar los derechos de acceso,
              rectificaci&oacute;n, cancelaci&oacute;n y oposici&oacute;n, respecto de sus datos personales,
              dirigi&eacute;ndose por escrito al domicilio sito en Alcobendas (Madrid 28108), Avenida de Bruselas 26,
              indicando la referencia PROTECCI&Oacute;N DE DATOS.
            </span>
          </div>
        </div>
        <div *ngIf="globalVars.licenseType == 1">
          <div class="limitAlert-message">
            <span class="alertMessageCustom txtley color61">
              En cumplimiento de la Ley 15/1999, de 13 de Diciembre, de Protecci&oacute;n de Datos de Car&aacute;cter
              Personal, los datos de car&aacute;cter personal que se recogen en este documento se incluir&aacute;n en el
              fichero de datos personales con la finalidad de prestar el servicio solicitado, dar cumplimiento a la
              relaci&oacute;n contractual establecida con el jugador, gestionar la participaci&oacute;n en los juegos y
              apuestas, as&iacute; como para llevar a cabo la gesti&oacute;n operativa y comercial de los juegos y
              apuestas, y tambi&eacute;n para cumplir con las obligaciones legales derivadas de la actividad, siendo
              responsable del tratamiento CODERE APUESTAS, S.A.U.
              <br />El suscriptor del presente documento podr&aacute; ejercitar los derechos de acceso,
              rectificaci&oacute;n, cancelaci&oacute;n y oposici&oacute;n, respecto de sus datos personales,
              dirigi&eacute;ndose por escrito al domicilio sito en Alcobendas (Madrid 28108), Avenida de Bruselas 26,
              indicando la referencia PROTECCI&Oacute;N DE DATOS.
            </span>
          </div>
        </div>
      </div>
      <ion-row class="buttonsLine limitcentertxt">
        <ion-col (click)="btCancel_Click()">
          <ion-button class="disable-hover button button-ios button-default button-default-ios" tappable="">
            <span class="button-inner"> Cancelar</span>
            <div class="button-effect"></div>
          </ion-button>
        </ion-col>
        <ion-col (click)="nextPage()">
          <ion-button class="disable-hover button button-ios button-default button-default-ios" tappable="">
            <span class="button-inner"> Comenzar test</span>
            <div class="button-effect"></div>
          </ion-button>
        </ion-col>
      </ion-row>
    </div>
    <div *ngIf="pageInt==2">
      <div>
        <h2 class="limitTitalert">TEST DE JUEGO RESPONSABLE</h2>
        <div class="limitsubtitle limitcentertxt">Pregunta 1 de 10</div>
      </div>
      <div class="row">
        <div (click)="prevPage()" class="iconlimit col col-20">
          <i class="codere-icon arrow icon-arrow-left fontsize2" item-left=""></i>
        </div>
        <div class="limitTitalertMessage">
          <div class="limitAlert-message col col-80">
            <span class="alertMessageCustom color61"> 1. {{ 'surveylimits01' | translate }} </span>
          </div>
        </div>
      </div>

      <ion-row class="buttonsLine limitcentertxt">
        <ion-col (click)="nextQuestions(true)">
          <ion-button class="disable-hover button button-ios button-outline-ios limitbutton-outline" tappable="">
            <span class="button-inner"> S&iacute;</span>
            <div class="button-effect"></div>
          </ion-button>
        </ion-col>
        <ion-col (click)="nextQuestions(false)">
          <ion-button class="disable-hover button button-ios button-outline-ios limitbutton-outline" tappable="">
            <span class="button-inner"> No</span>
            <div class="button-effect"></div>
          </ion-button>
        </ion-col>
      </ion-row>
    </div>
    <div *ngIf="pageInt==3">
      <div>
        <h2 class="limitTitalert">TEST DE JUEGO RESPONSABLE</h2>
        <div class="limitsubtitle limitcentertxt">Pregunta 2 de 10</div>
      </div>
      <div class="row">
        <div (click)="prevPage()" class="iconlimit col col-20">
          <i class="codere-icon arrow icon-arrow-left fontsize2" item-left=""></i>
        </div>
        <div class="limitTitalertMessage col col-80">
          <div class="limitAlert-message">
            <span class="alertMessageCustom color61"> 2. {{ 'surveylimits02' | translate }} </span>
          </div>
        </div>
      </div>

      <ion-row class="buttonsLine limitcentertxt">
        <ion-col (click)="nextQuestions(true)">
          <ion-button class="disable-hover button button-ios button-outline-ios limitbutton-outline" tappable="">
            <span class="button-inner"> S&iacute;</span>
            <div class="button-effect"></div>
          </ion-button>
        </ion-col>
        <ion-col (click)="nextQuestions(false)">
          <ion-button class="disable-hover button button-ios button-outline-ios limitbutton-outline" tappable="">
            <span class="button-inner"> No</span>
            <div class="button-effect"></div>
          </ion-button>
        </ion-col>
      </ion-row>
    </div>
    <div *ngIf="pageInt==4">
      <div>
        <h2 class="limitTitalert">TEST DE JUEGO RESPONSABLE</h2>
        <div class="limitsubtitle limitcentertxt">Pregunta 3 de 10</div>
      </div>
      <div class="row">
        <div (click)="prevPage()" class="iconlimit col col-20">
          <i class="codere-icon arrow icon-arrow-left fontsize2" item-left=""></i>
        </div>
        <div class="limitTitalertMessage col col-80">
          <div class="limitAlert-message">
            <span class="alertMessageCustom color61"> 3. {{ 'surveylimits03' | translate }} </span>
          </div>
        </div>
      </div>
      <ion-row class="buttonsLine limitcentertxt">
        <ion-col (click)="nextQuestions(true)">
          <ion-button class="disable-hover button button-ios button-outline-ios limitbutton-outline" tappable="">
            <span class="button-inner"> S&iacute;</span>
            <div class="button-effect"></div>
          </ion-button>
        </ion-col>
        <ion-col (click)="nextQuestions(false)">
          <ion-button class="disable-hover button button-ios button-outline-ios limitbutton-outline" tappable="">
            <span class="button-inner"> No</span>
            <div class="button-effect"></div>
          </ion-button>
        </ion-col>
      </ion-row>
    </div>
    <div *ngIf="pageInt==5">
      <div>
        <h2 class="limitTitalert">TEST DE JUEGO RESPONSABLE</h2>
        <div class="limitsubtitle limitcentertxt">Pregunta 4 de 10</div>
      </div>
      <div class="row">
        <div (click)="prevPage()" class="iconlimit col col-20">
          <i class="codere-icon arrow icon-arrow-left fontsize2" item-left=""></i>
        </div>
        <div class="limitTitalertMessage col col-80">
          <div class="limitAlert-message">
            <span class="alertMessageCustom color61"> 4. {{ 'surveylimits04' | translate }} </span>
          </div>
        </div>
      </div>
      <ion-row class="buttonsLine limitcentertxt">
        <ion-col (click)="nextQuestions(true)">
          <ion-button class="disable-hover button button-ios button-outline-ios limitbutton-outline" tappable="">
            <span class="button-inner"> S&iacute;</span>
            <div class="button-effect"></div>
          </ion-button>
        </ion-col>
        <ion-col (click)="nextQuestions(false)">
          <ion-button class="disable-hover button button-ios button-outline-ios limitbutton-outline" tappable="">
            <span class="button-inner"> No</span>
            <div class="button-effect"></div>
          </ion-button>
        </ion-col>
      </ion-row>
    </div>
    <div *ngIf="pageInt==6">
      <div>
        <h2 class="limitTitalert">TEST DE JUEGO RESPONSABLE</h2>
        <div class="limitsubtitle limitcentertxt">Pregunta 5 de 10</div>
      </div>
      <div class="row">
        <div (click)="prevPage()" class="iconlimit col col-20">
          <i class="codere-icon arrow icon-arrow-left fontsize2" item-left=""></i>
        </div>
        <div class="limitTitalertMessage col col-80">
          <div class="limitAlert-message">
            <span class="alertMessageCustom color61"> 5. {{ 'surveylimits05' | translate }} </span>
          </div>
        </div>
      </div>
      <ion-row class="buttonsLine limitcentertxt">
        <ion-col (click)="nextQuestions(true)">
          <ion-button class="disable-hover button button-ios button-outline-ios limitbutton-outline" tappable="">
            <span class="button-inner"> S&iacute;</span>
            <div class="button-effect"></div>
          </ion-button>
        </ion-col>
        <ion-col (click)="nextQuestions(false)">
          <ion-button class="disable-hover button button-ios button-outline-ios limitbutton-outline" tappable="">
            <span class="button-inner"> No</span>
            <div class="button-effect"></div>
          </ion-button>
        </ion-col>
      </ion-row>
    </div>
    <div *ngIf="pageInt==7">
      <div>
        <h2 class="limitTitalert">TEST DE JUEGO RESPONSABLE</h2>
        <div class="limitsubtitle limitcentertxt">Pregunta 6 de 10</div>
      </div>
      <div class="row">
        <div (click)="prevPage()" class="iconlimit col col-20">
          <i class="codere-icon arrow icon-arrow-left fontsize2" item-left=""></i>
        </div>
        <div class="limitTitalertMessage col col-80">
          <div class="limitAlert-message">
            <span class="alertMessageCustom color61"> 6. {{ 'surveylimits06' | translate }} </span>
          </div>
        </div>
      </div>
      <ion-row class="buttonsLine limitcentertxt">
        <ion-col (click)="nextQuestions(true)">
          <ion-button class="disable-hover button button-ios button-outline-ios limitbutton-outline" tappable="">
            <span class="button-inner"> S&iacute;</span>
            <div class="button-effect"></div>
          </ion-button>
        </ion-col>
        <ion-col (click)="nextQuestions(false)">
          <ion-button class="disable-hover button button-ios button-outline-ios limitbutton-outline" tappable="">
            <span class="button-inner"> No</span>
            <div class="button-effect"></div>
          </ion-button>
        </ion-col>
      </ion-row>
    </div>
    <div *ngIf="pageInt==8">
      <div>
        <h2 class="limitTitalert">TEST DE JUEGO RESPONSABLE</h2>
        <div class="limitsubtitle limitcentertxt">Pregunta 7 de 10</div>
      </div>
      <div class="row">
        <div (click)="prevPage()" class="iconlimit col col-20">
          <i class="codere-icon arrow icon-arrow-left fontsize2" item-left=""></i>
        </div>
        <div class="limitTitalertMessage col col-80">
          <div class="limitAlert-message">
            <span class="alertMessageCustom color61"> 7. {{ 'surveylimits07' | translate }} </span>
          </div>
        </div>
      </div>
      <ion-row class="buttonsLine limitcentertxt">
        <ion-col (click)="nextQuestions(true)">
          <ion-button class="disable-hover button button-ios button-outline-ios limitbutton-outline" tappable="">
            <span class="button-inner"> S&iacute;</span>
            <div class="button-effect"></div>
          </ion-button>
        </ion-col>
        <ion-col (click)="nextQuestions(false)">
          <ion-button class="disable-hover button button-ios button-outline-ios limitbutton-outline" tappable="">
            <span class="button-inner"> No</span>
            <div class="button-effect"></div>
          </ion-button>
        </ion-col>
      </ion-row>
    </div>
    <div *ngIf="pageInt==9">
      <div>
        <h2 class="limitTitalert">TEST DE JUEGO RESPONSABLE</h2>
        <div class="limitsubtitle limitcentertxt">Pregunta 8 de 10</div>
      </div>
      <div class="row">
        <div (click)="prevPage()" class="iconlimit col col-20">
          <i class="codere-icon arrow icon-arrow-left fontsize2" item-left=""></i>
        </div>
        <div class="limitTitalertMessage col col-80">
          <div class="limitAlert-message">
            <span class="alertMessageCustom color61"> 8. {{ 'surveylimits08' | translate }} </span>
          </div>
        </div>
      </div>
      <ion-row class="buttonsLine limitcentertxt">
        <ion-col (click)="nextQuestions(true)">
          <ion-button class="disable-hover button button-ios button-outline-ios limitbutton-outline" tappable="">
            <span class="button-inner"> S&iacute;</span>
            <div class="button-effect"></div>
          </ion-button>
        </ion-col>
        <ion-col (click)="nextQuestions(false)">
          <ion-button class="disable-hover button button-ios button-outline-ios limitbutton-outline" tappable="">
            <span class="button-inner"> No</span>
            <div class="button-effect"></div>
          </ion-button>
        </ion-col>
      </ion-row>
    </div>
    <div *ngIf="pageInt==10">
      <div>
        <h2 class="limitTitalert">TEST DE JUEGO RESPONSABLE</h2>
        <div class="limitsubtitle limitcentertxt">Pregunta 9 de 10</div>
      </div>
      <div class="row">
        <div (click)="prevPage()" class="iconlimit col col-20">
          <i class="codere-icon arrow icon-arrow-left fontsize2" item-left=""></i>
        </div>
        <div class="limitTitalertMessage col col-80">
          <div class="limitAlert-message">
            <span class="alertMessageCustom color61"> 9. {{ 'surveylimits09' | translate }} </span>
          </div>
        </div>
      </div>
      <ion-row class="buttonsLine limitcentertxt">
        <ion-col (click)="nextQuestions(true)">
          <ion-button class="disable-hover button button-ios button-outline-ios limitbutton-outline" tappable="">
            <span class="button-inner"> S&iacute;</span>
            <div class="button-effect"></div>
          </ion-button>
        </ion-col>
        <ion-col (click)="nextQuestions(false)">
          <ion-button class="disable-hover button button-ios button-outline-ios limitbutton-outline" tappable="">
            <span class="button-inner"> No</span>
            <div class="button-effect"></div>
          </ion-button>
        </ion-col>
      </ion-row>
    </div>
    <div *ngIf="pageInt==11">
      <div>
        <h2 class="limitTitalert">TEST DE JUEGO RESPONSABLE</h2>
        <div class="limitsubtitle limitcentertxt">Pregunta 10 de 10</div>
      </div>
      <div class="row">
        <div (click)="prevPage()" class="iconlimit col col-20">
          <i class="codere-icon arrow icon-arrow-left fontsize2" item-left=""></i>
        </div>
        <div class="limitTitalertMessage col col-80">
          <div class="limitAlert-message">
            <span class="alertMessageCustom color61"> 10. {{ 'surveylimits10' | translate }} </span>
          </div>
        </div>
      </div>
      <ion-row class="buttonsLine limitcentertxt">
        <ion-col (click)="finallyQuestion(true)">
          <ion-button class="disable-hover button button-ios button-outline-ios limitbutton-outline" tappable="">
            <span class="button-inner"> S&iacute;</span>
            <div class="button-effect"></div>
          </ion-button>
        </ion-col>
        <ion-col (click)="finallyQuestion(false)">
          <ion-button class="disable-hover button button-ios button-outline-ios limitbutton-outline" tappable="">
            <span class="button-inner"> No</span>
            <div class="button-effect"></div>
          </ion-button>
        </ion-col>
      </ion-row>
    </div>
  </div>
</ion-content>
