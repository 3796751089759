/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable eqeqeq */
import { IonSelect, IonicModule, ModalController, NavController, Platform } from '@ionic/angular';
import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup, FormsModule, FormControl, FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { UserService } from '../../../providers/UserService';
import { BalancesService } from '../../../providers/BalancesService';
import { TrackingService } from '../../../providers/TrackingService';
import { NewBaseService } from '../../../providers/newBase.service';
import { ErrorService } from '../../../providers/ErrorService';

import { DeviceService } from '../../../services/device.service';

import { LicenseTypes } from '../../../models/MasterData';
import { GlobalVarsModel } from '../../../models/ModelGlobalsVar';
import { TransactionActionResult } from '../../../models/C_Transactions';

import { Utils } from '../../../utils/Utils';

import { ParseMoney } from '../../../pipes/parseMoney';

import { MSO_PATHS } from '../../../lib/constants/routes';

import { UserSectionTitle } from '@shared-mso-components/user-section-title/user-section-title';
import { CodereFooterComponent } from '@shared-mso-components/footer/codere-footer/codere-footer';

import { Router } from '@angular/router';

@Component({
  standalone: true,
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'my-card-transfer-page',
  templateUrl: './my-card-transfer.html',
  styleUrls: ['./my-card-transfer.scss'],
  imports: [
    IonicModule,
    CommonModule,
    UserSectionTitle,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    CodereFooterComponent,
    ParseMoney
  ]
})
export class MyCardTransferPage {
  form: { from: string; to: string };
  myForm1: FormGroup;
  checkedOk = false;
  limitAmount = false;
  maxAmount = 1000000;
  isDesktop = false;
  globalVars!: GlobalVarsModel;

  isMobile: boolean = false;

  modalCtrl = inject(ModalController);
  nav = inject(NavController);
  utils = inject(Utils);
  translate = inject(TranslateService);
  newBaseService = inject(NewBaseService);
  fb = inject(FormBuilder);
  userService = inject(UserService);
  platform = inject(Platform);
  parseMoney = inject(ParseMoney);
  trackingService = inject(TrackingService);
  balancesService = inject(BalancesService);
  errorService = inject(ErrorService);
  device = inject(DeviceService);
  router = inject(Router);

  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });

    this.isMobile = this.device.isMobile();

    this.form = {
      from: 'online',
      to: 'tarjeta'
    };

    this.updateBalanceCard();
    this.resetForm();
  }

  linkOutOfModalPage(link: any, paymentArguments: any) {
    this.router.navigate(link, {
      queryParams: { paymentArguments: paymentArguments, animate: this.isMobile }
    });
  }

  resetForm() {
    this.myForm1 = this.fb.group({
      amount: ['', Validators.required],
      pin: [
        '',
        Validators.compose([
          Validators.required,
          Validators.maxLength(4),
          Validators.minLength(4),
          Validators.pattern('[0-9]*')
        ])
      ]
    });
  }

  ionViewWillEnter() {
    this.router.navigate([MSO_PATHS.BASE + '/' + MSO_PATHS.MyCardTransferPage]);
    this.trackingService.trackEvent(['accessToCodereCardTrans', '', '', 'Transferir Fondos Tarjeta Codere', 'event']);
  }

  checkValidOption(event: any, queSel: number) {
    const val = event.detail.value as string;

    if (queSel == 1) {
      if (val == 'online') {
        this.form.to = 'tarjeta';
      } else {
        this.form.to = 'online';
      }
    } else if (queSel == 2) {
      if (val == 'online') {
        this.form.from = 'tarjeta';
      } else {
        this.form.from = 'online';
      }
    }
  }

  updateBalance() {
    this.balancesService.refreshBalance();
  }

  updateBalanceCard() {
    this.balancesService.getClientCardBalance().subscribe(
      (data) => {
        if (data.success) {
          this.globalVars.user.codereCard.balance = data.cardBalance;
        } else {
          this.utils.showError(this.translate.instant('GetCardAccessActiveKO'));
        }
      },
      () => {
        //error
        this.utils.showError(this.translate.instant('GetCardAccessActiveKO'));
      }
    );
  }

  validAmount(): boolean {
    this.maxAmount = +this.globalVars.FEATURES.MaxTarjetaDepositAmount;
    if (+this.myForm1.controls['amount'].value > this.maxAmount) {
      this.limitAmount = true;
    } else {
      this.limitAmount = false;
    }
    //check enough amount
    let maxBalance = 0;
    if (this.form.from == 'online') {
      maxBalance = this.globalVars.user.balance;
    } else {
      maxBalance = this.globalVars.user.codereCard.balance;
    }
    const val = this.myForm1.controls['amount'].value;
    //
    if (isNaN(val) || this.limitAmount) {
      return false;
    } else {
      return parseFloat(val) <= maxBalance;
    }
  }

  doSendTransfer() {
    const pin: string = this.myForm1.controls['pin'].value;
    const amount: string = this.myForm1.controls['amount'].value;
    //comentado hasta subida a Mexico de TransactionAPi
    if (this.globalVars.licenseType == LicenseTypes.Mexico) {
      this.checkSendTransfer(pin, amount);
    } else {
      this.doConfirmSendTransfer(pin, amount);
    }
  }

  checkSendTransfer(pin: string, amount: string) {
    let service: Observable<any>;

    if (this.form.from == 'online') {
      service = this.balancesService.checkAddingBalanceToCard(pin, amount);
    } else if (this.form.from == 'tarjeta') {
      service = this.balancesService.checkAddingBalanceFromCard(pin, amount);
    }

    service.subscribe({
      next: (data) => {
        const transactionResult = <TransactionActionResult>data;
        if (transactionResult.success) {
          if (transactionResult.taxAmount == 0) {
            this.doConfirmSendTransfer(pin, amount);
          } else {
            this.utils.showAlertTransferWithTaxes(
              transactionResult.netAmount,
              transactionResult.taxAmount,
              () => {
                this.doFinalSendTransfer(pin, amount);
              },
              'Retención de impuestos',
              this.translate.instant('tAccept'),
              this.translate.instant('tCancel')
            );
          }
        } else {
          this.errorService
            .searchErrorCodeWithParams(transactionResult.errCode.toString(), transactionResult.errorParameters)
            .subscribe({
              next: (data3) => {
                this.trackingService.trackEvent([
                  'TransactionActionResultError',
                  data3,
                  transactionResult.errCode.toString(),
                  '',
                  'event'
                ]);

                if (transactionResult.errCode == 174 || transactionResult.errCode == 2212) {
                  this.utils.confirm(
                    false,
                    this.translate.instant('tFail'),
                    data3,
                    this.translate.instant('tCancel'),
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    () => {},
                    this.translate.instant('check')
                  );
                } else {
                  this.utils.showError(data3);
                }
              },
              error: () => {
                this.utils.showError(transactionResult.errMessage);
              }
            });
        }
      },
      error: () => {
        this.utils.showError(this.translate.instant('m_menu_serverFail'));
      }
    });
  }

  doConfirmSendTransfer(pin: string, amount: string) {
    const title: string = this.translate.instant('CodereCardconfir');
    let msg = '';

    if (this.form.from == 'online') {
      msg =
        this.translate.instant('CodereCardtransfer00') +
        '&nbsp;' +
        this.parseMoney.transform(amount) +
        ' ' +
        this.translate.instant('CodereCardtransfer03');
    } else {
      msg =
        this.translate.instant('CodereCardtransfer00') +
        '&nbsp;' +
        this.parseMoney.transform(amount) +
        ' ' +
        this.translate.instant('CodereCardtransfer04');
    }

    this.utils.confirm(
      false,
      title,
      msg,
      this.translate.instant('tCancel'),
      null,
      this.translate.instant('tAccept'),
      () => {
        //hack. problema 2 ventanas modales. hay que añadir delay en ionic.beta9
        setTimeout(() => {
          this.doFinalSendTransfer(pin, amount);
        }, 1000);
      }
    );
  }

  doFinalSendTransfer(pin: string, amount: string) {
    let service: Observable<any>;

    if (this.form.from == 'online') {
      service = this.balancesService.addBalanceToCard(pin, amount);
    } else if (this.form.from == 'tarjeta') {
      service = this.balancesService.addBalanceFromCard(pin, amount);
    }

    service.subscribe({
      next: (data) => {
        if (data.success) {
          //
          let msg: string;
          const title: string = this.translate.instant('CodereCardtransfer01');
          if (this.form.from == 'online') {
            msg =
              this.translate.instant('CodereCardtransfer02') +
              ' ' +
              this.parseMoney.transform(amount) +
              ' ' +
              this.translate.instant('CodereCardtransfer03');
          } else {
            this.trackingService.trackEvent([
              'DepositPaymentOK',
              'N/A',
              '',
              this.parseMoney.transform(amount),
              'event'
            ]);
            msg =
              this.translate.instant('CodereCardtransfer02') +
              ' ' +
              this.parseMoney.transform(amount) +
              ' ' +
              this.translate.instant('CodereCardtransfer04');
          }
          this.utils.alert(false, title, msg, 'OK', () => {
            //clear form and stay
            this.resetForm();
            this.checkedOk = false;
            this.updateBalance();
            this.updateBalanceCard();
          });
        } else {
          if (data.errMessage) {
            if (isNaN(data.errMessage)) {
              //test
              this.utils.showError(data.errMessage);
            } else {
              //
              if (data.errMessage == '174') {
                //not verified user
                this.utils.confirm(
                  false,
                  this.translate.instant('tFail'),
                  'Para realizar esta transacción es necesario que hayas completado la verificación documental',
                  this.translate.instant('tCancel'),
                  () => {
                    //
                  },
                  this.translate.instant('check')
                );
              } else {
                this.errorService.searchErrorCode(data.errMessage).subscribe(
                  (data3) => {
                    this.utils.showError(data3);
                  },
                  () => {
                    this.utils.showError(data.errMessage);
                  }
                );
              }
            }
          } else {
            this.utils.showError(this.translate.instant('GetCardtransatarKO'));
          }
        }
      },
      error: () => {
        this.utils.showError(this.translate.instant('m_menu_serverFail'));
      }
    });
  }

  checkOk() {
    if (this.validAmount()) {
      this.checkedOk = true;
    } else {
      //no saldo
      if (parseFloat(this.myForm1.controls['amount'].value)) {
        this.myForm1.controls['amount'].setErrors({ amount: 'notValid' });
      }
      this.myForm1.controls['amount'].markAsTouched();
      this.checkedOk = false;
    }
  }

  sendTransfer() {
    if (this.myForm1.valid) {
      if (this.validAmount()) {
        this.doSendTransfer();
      } else {
        //no saldo
        this.myForm1.controls['amount'].setErrors({ amount: false });
      }
    } else {
      this.myForm1.controls['amount'].markAsTouched();
      this.myForm1.controls['pin'].markAsTouched();
    }
  }
}
