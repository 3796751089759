import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegistroESNewPages } from '../pages/registroESNew';
import { RegistroCONewPage } from '../pages/registroCONew/registroCONew';
import { RegistroPAPage } from '../pages/registroPA/registroPA';
import { RegistroARPage } from '../pages/registroAR/registroAR';
import { SelfExclusionPage } from '../pages/selfExclusion/selfExclusion';
import { ContactPage } from '../pages/contact/contact/contact';
import { HelpPage } from '../pages/ayuda/ayuda';
import { PersonalInfoPage } from '@shared-mso-pages/personal-info/personal-info';
import { LanguagePage } from '@shared-mso-pages/language/language';
import { MyCardPage } from '@shared-mso-pages/my-card/my-card';
import { CookiesConsentPage } from '../pages/cookies-consent/cookies-consent';

@Component({
  selector: 'codere-ionic-mso',
  standalone: true,
  imports: [
    CommonModule,
    RegistroESNewPages,
    RegistroCONewPage,
    RegistroPAPage,
    RegistroARPage,
    SelfExclusionPage,
    ContactPage,
    HelpPage,
    PersonalInfoPage,
    LanguagePage,
    MyCardPage,
    CookiesConsentPage
  ],
  templateUrl: './mso.component.html',
  styleUrls: ['./mso.component.scss']
})
export class MsoComponent {}
