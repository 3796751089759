<ng-container *ngIf="isMobile; then mobileTemplate; else desktopTemplate"> </ng-container>
<ng-template #mobileTemplate>
  <ion-content *ngIf="scrHelp">
    <iframe #iframe data-tap-disabled="true" class="iframeFullScreen" [src]="scrHelp"></iframe>
    <codere-footer></codere-footer>
  </ion-content>
</ng-template>
<ng-template #desktopTemplate>
  <ion-content>
    <ng-container *ngIf="scrHelp">
      <iframe #iframe class="iframeFullScreen" [src]="scrHelp"></iframe>
    </ng-container>
    <codere-footer></codere-footer>
  </ion-content>
</ng-template>
