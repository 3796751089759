import { Component, Input, ViewChild, ElementRef, inject } from '@angular/core';
import { ScriptService } from '@providers/ScriptService';
import { StyleService } from '@providers/StyleService';

declare const SIR;

@Component({
  selector: 'sportradar-widget',
  templateUrl: './sportradar-widget.html',
  standalone: true
})
export class SportradarWidgetComponent {
  @ViewChild('standardWidgetSIR') standardWidgetSIR: ElementRef;
  @Input() statisticsId: string;
  @Input() isFormula1: boolean;
  @Input() SPO_LMTFormula1: boolean;

  scriptService = inject(ScriptService);
  styleService = inject(StyleService);

  constructor() {}

  ngAfterViewInit() {
    if (!this.isFormula1) {
      Promise.all([this.scriptService.load('sportradar'), this.styleService.load('sportradar')]).then(() => {
        SIR('addWidget', '.sr-widget-1', 'match.lmtPlus', {
          layout: 'single',
          scoreboard: 'disable',
          detailedScoreboard: 'disable',
          tabsPosition: 'bottom',
          pitchLogo: './assets/global/img/codere-apuestas-mx.svg',
          goalBannerImage: './assets/global/img/codere_green.svg',
          logo: ['./assets/global/img/codere_grey.svg'],
          matchId: this.statisticsId
        });
      });
    } else if (this.SPO_LMTFormula1) {
      Promise.all([this.scriptService.load('sportradarF1'), this.styleService.load('sportradar')]).then(() => {
        SIR('addWidget', '.sr-widget-1', 'match.lmtPlus', {
          stageId: this.statisticsId
        });
      });
    }
  }

  ngOnDestroy() {
    SIR('removeWidget', document.querySelector('.sr-widget-1'));
  }
}
