import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ticketState } from '../reducers/ticket.reducers';

export const getTicketState = createFeatureSelector<ticketState>('ticket');

export const getTicket = createSelector(getTicketState, (state: ticketState) => state.ticket);

export const getFilters = createSelector(getTicketState, (state: ticketState) => state.filterSelected);

export const getSelectionsList = createSelector(getTicketState, (state: ticketState) => state.ticket.LineItems);

export const getBetbuilderSelection = createSelector(getTicketState, (state: ticketState) => state.betbuilderSelection);

export const getFreeBets = createSelector(getTicketState, (state: ticketState) => state.freeBets);

export const getApplicableAccaBonus = createSelector(
  getTicketState,
  (state: ticketState) => state.ticket.ApplicableAccaBonus
);

export const getOverask = createSelector(getTicketState, (state: ticketState) => state.ticket.OveraskInfo);
