import { Directive, ElementRef, EventEmitter, OnDestroy, OnInit, Output, inject } from "@angular/core";
import { fromEvent } from "rxjs";
import { delay, tap } from "rxjs/operators";

@Directive({
  selector: "[submenu]",
  standalone: true,
})
export class SubMenuDirective implements OnInit, OnDestroy {
  private listening: boolean;
  private globalClick: any;

  @Output() clickOutside: EventEmitter<object>;

  _elRef = inject(ElementRef);
  constructor() {
    this.listening = false;
    this.clickOutside = new EventEmitter();
  }

  ngOnInit() {
    //Equipo migracion: Se quita pipe por error en la compilacion
    //esto provoca incompatibilidad de tipos  -> '.pipe(tap(()'
    // añadimos ' if (event instanceof MouseEvent)' para asegurarnos de que solo se llama
    // a this.onGlobalClick cuando tenemos un evento de tipo MouseEvent
    this.globalClick = fromEvent(document, "click")
      .pipe(
        delay(1),
        tap(() => {
          this.listening = true;
        }),
      )
      .subscribe((event: Event) => {
        if (event instanceof MouseEvent) {
          this.onGlobalClick(event);
        }
      });
  }

  ngOnDestroy() {
    if (this.globalClick) {
      this.globalClick.unsubscribe();
    }
  }

  onGlobalClick(event: MouseEvent) {
    if (event instanceof MouseEvent && this.listening === true) {
      if (this.isDescendant(this._elRef.nativeElement, event.target) === true) {
        this.clickOutside.emit({
          target: event.target || null,
          value: false,
        });
      } else {
        this.clickOutside.emit({
          target: event.target || null,
          value: true,
        });
      }
    }
  }

  isDescendant(parent, child) {
    let node = child;
    while (node) {
      if (node === parent) {
        return true;
      } else {
        node = node.parentNode;
      }
    }
    return false;
  }
}
