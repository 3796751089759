import { Injectable, inject } from '@angular/core';
import { Observable, from } from 'rxjs';
import { UserService } from './UserService';
import { TranslateLoader } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class LanguageService implements TranslateLoader {
  userService = inject(UserService);

  constructor() {}
  getTranslation(lang: string): Observable<any> {
    const q = new Promise((resolve, reject) => {
      this.userService.getLanguage(lang).subscribe({
        next: (data) => {
          if (data.success) {
            //failcodes
            this.userService.errorList = [];
            for (const key in data.failCodes) {
              this.userService.errorList.push({ code: key, label: data.failCodes[key] + '(' + key + ')' });
            }
            //resources
            resolve(data.resources);
          } else {
            reject('');
          }
        },
        error: (err) => reject(err)
      });
    });
    return from(q);
  }
}
