import { CommonModule } from "@angular/common";
/* eslint-disable */
import { Component, ElementRef, EventEmitter, Input, Output, Renderer2, ViewChild, inject } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { Router } from "@angular/router";
import { AlertController, ModalController, NavController, NavParams, Platform } from "@ionic/angular";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, Subject, takeUntil } from "rxjs";

import { GlobalVarsModel } from "../../../models/ModelGlobalsVar";
import { NativeService } from "../../../providers/NativeService";
import { TrackingService } from "../../../providers/TrackingService";
import { UserService } from "../../../providers/UserService";
import { UtilsCashierService } from "../../../providers/UtilsCashierService";
import { NewBaseService } from "../../../providers/newBase.service";
import { DeviceService } from "../../../services/device.service";
//Services
import { Utils } from "../../../utils/Utils";

import {
  LicenseTypes,
  VerificationDocumentType,
  VerificationStatus,
  VerificationTypes,
} from "../../../models/MasterData";
//Models
import { EventTypes } from "../../../models/TrackingEvents";
import { CashierVeridasIframe } from "../cashier-veridas-iframe/cashier-veridas-iframe";

//Pipes
import { ExtractDatePipe } from "../../../pipes/extractDate.pipe";

import { CsVeridasPolicyAcceptModal } from "@components/payments/cs-veridas-policy-accept-modal/cs-veridas-policy-accept-modal";
import { BankService, FundsOrigin } from "@providers/payments/BankService";
import { MSO_PATHS } from "@shared-constants/routes";
import { CashierWithdrawalsSworm } from "@shared-payments-pages/cashier-withdrawals-sworm/cashier-withdrawals-sworm";
import { CsDocumentHeaderComponent } from "../../../components/payments/cs-document-header/cs-document-header";
import { CsDocumentItemComponent } from "../../../components/payments/cs-document-item/cs-document-item";
import { CsVerificationItemComponent } from "../../../components/payments/cs-verification-item/cs-verification-item";
//Others
import { DOCUMENT_SIDES, DOCUMENT_TYPES, VERIFICATION_STATUS } from "../../../models/VeridasCodes";
import { CameraAccessInterface, InputConfigInterface } from "../../../models/payments/I_VerificationUploadConfig";

import { Camera } from "@capacitor/camera";
import { VerificationLiterals } from "@models/payments/VerificationLiterals";
import { VerificationOption } from "@models/payments/VerificationOption";
import { CsDocumentsAccordionComponent } from "src/app/modules/payments/components/cs-documents-accordion/cs-documents-accordion";
interface C_StatusData {
  name: string;
  status: string;
  face: string;
  type: string;
  reason: string;
  date: string;
  date_pure: any;
}

interface ModalResult {
  originSelected: { name: string };
  swornAccepted: boolean;
}

@Component({
  selector: "page-cashier-documents",
  templateUrl: "./cashier-documents.html",
  styleUrls: ["./cashier-documents.scss"],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    CsVerificationItemComponent,
    CsDocumentItemComponent,
    CsDocumentHeaderComponent,
    CsDocumentsAccordionComponent,
  ],
})
export class CashierDocumentsPage {
  // THESE ARE THE MANUAL UPLOAD FIELDS
  @ViewChild("inputOne") inputOne: ElementRef;
  @ViewChild("inputTwo") inputTwo: ElementRef;
  @ViewChild("inputThree") inputThree: ElementRef;
  @ViewChild("inputFour") inputFour: ElementRef;
  @ViewChild("inputFive") inputFive: ElementRef;
  @ViewChild("inputSix") inputSix: ElementRef;
  @ViewChild("inputSeven") inputSeven: ElementRef;

  @Output() trackingTrack: EventEmitter<any> = new EventEmitter();
  @Input() userFromIosApp: boolean = false;

  identFrontData$: BehaviorSubject<C_StatusData[]>;
  identReverseData$: BehaviorSubject<C_StatusData[]>;
  bankDocData$: BehaviorSubject<C_StatusData[]>;
  otherDocData$: BehaviorSubject<C_StatusData[]>;
  veridasData$: BehaviorSubject<C_StatusData[]>;
  verificationListData$: BehaviorSubject<C_StatusData[]>;
  selfieData$: BehaviorSubject<C_StatusData[]>;
  bankCardData$: BehaviorSubject<C_StatusData[]>;
  paypalData$: BehaviorSubject<C_StatusData[]>;

  isNative: boolean;
  isProfessionFilled: boolean;
  userNIF: string;
  userProfession: string;
  userTipoDocumento: string;
  isVerificationCompleted: boolean = true;
  counterVeridasFailStatus: number = 0;
  maxNumberOfVeridasFailCashier: number = 3;
  fundOriginSelected: string;
  originFunds?: string | FundsOrigin = null;
  showOriginOfFunds: boolean;

  inputsConfig: InputConfigInterface[];
  infoAlertShowed: boolean = false;
  qrDataToShow: string;
  veridasInfo: string;
  alertOpened = false;

  // Literales
  selfieLiteral: string = "";
  // TEMP

  isVeridasCOActive = false;

  documentVerificationAvailable = true;
  verifiableDocumentTypes = ["ResidenteDNI", "ResidenteNIE"];
  allowedVeridasLicenses = [LicenseTypes.Nacional, LicenseTypes.Colombia];
  allowedPersonalDataPolicyLicenses = [LicenseTypes.Nacional, LicenseTypes.Colombia];

  DOCUMENT_SIDE_FRONT = "front";
  DOCUMENT_SIDE_FRONT_LABEL = "Anverso";
  DOCUMENT_SIDE_BACK_LABEL = "Reverso";

  USER_DOCUMENT_VERIFICATION_STATUS_COMPLETED = "Completada";
  isDesktop = false;
  isMobile = false;
  public globalVars!: GlobalVarsModel;
  private newBaseService = inject(NewBaseService);
  public navCtrl = inject(NavController);
  public alertCtrl = inject(AlertController);
  public viewCtrl = inject(ModalController); //** ViewController deprecado */
  public modalCtrl = inject(ModalController);
  public extractDate = inject(ExtractDatePipe);
  public navParams = inject(NavParams);
  public translate = inject(TranslateService);
  public utilsCsService = inject(UtilsCashierService);
  private _renderer = inject(Renderer2);
  private platform = inject(Platform);
  public nativeService = inject(NativeService);
  public userService = inject(UserService);
  public deviceService = inject(DeviceService);
  public utils = inject(Utils);
  private trackingService = inject(TrackingService);
  public router = inject(Router);
  public bankService = inject(BankService);
  private destroy$ = new Subject<void>();
  showAutoVerification: boolean;
  public verificationLiterals: VerificationLiterals;
  literalQuestions: any = {};

  XpressIDPrevAttempts: number = 0;
  verificationType = VerificationTypes;
  verificationOptionSelected: VerificationOption;
  verificationOptions: VerificationOption[] = [];
  verDocType = VerificationDocumentType;
  licenseType = LicenseTypes;
  playerIDForVeridasFinish: string;

  constructor() {
    if (!this.verificationOptionSelected)
      this.verificationOptionSelected = {
        type: VerificationTypes.DNI,
        text: this.translate.instant("verification_text_button_DNI"),
        selected: true,
      };
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
      this.isVeridasCOActive = this.globalVars.FEATURES.Payments_VeridasCOActivev3;
      this.showAutoVerification = this.globalVars.FEATURES.VeridasAutoVerificationEnabledv3;
    });

    this.getVerificationLiterals();

    this.isDesktop = this.deviceService.isDesktop();
    this.isMobile = this.deviceService.isMobile();
    this.isNative = this.platform.is("cordova");
    this.isProfessionFilled = true;
    this.userNIF = "";
    this.identFrontData$ = new BehaviorSubject([]);
    this.identReverseData$ = new BehaviorSubject([]);
    this.bankDocData$ = new BehaviorSubject([]);
    this.otherDocData$ = new BehaviorSubject([]);
    this.veridasData$ = new BehaviorSubject([]);
    this.verificationListData$ = new BehaviorSubject([]);
    this.selfieData$ = new BehaviorSubject([]);
    this.bankCardData$ = new BehaviorSubject([]);
    this.paypalData$ = new BehaviorSubject([]);
    this.showOriginOfFunds = this.globalVars.FEATURES.showOriginOfFunds;
    this.maxNumberOfVeridasFailCashier = Number(this.globalVars.FEATURES.maxNumberOfVeridasfail);
  }

  async checkIsIosRealDevice() {
    const isIos = await this.deviceService.isRealIOSDevice();
    if (isIos) {
      this.showAutoVerification = false;
    }
  }

  async ngAfterViewInit() {
    this.inputsConfig = [
      {
        type: "754310000",
        face: "754310000",
        input: this.inputOne,
        id: "input1",
        name: "inputOne",
      },
      {
        type: "754310000",
        face: "754310001",
        input: this.inputTwo,
        id: "input2",
        name: "inputTwo",
      },
      {
        type: "754310001",
        face: "754310000",
        input: this.inputThree,
        id: "input3",
        name: "inputThree",
      },
      {
        type: "754310006",
        face: "754310000",
        input: this.inputFour,
        id: "input4",
        name: "inputFour",
      },
      {
        type: "754310005",
        face: "754310000",
        input: this.inputFive,
        id: "input5",
        name: "inputFive",
      },
      {
        type: "754310007",
        face: "754310000",
        input: this.inputSix,
        id: "input6",
        name: "inputSix",
      },
      {
        type: "754310002",
        face: "754310000",
        input: this.inputSeven,
        id: "input7",
        name: "inputSeven",
      },
    ];

    var veridasEnabledAndNative =
      "Veridas=" +
      this.globalVars.FEATURES.VeridasXpressIdVerificationEnabled.toString() +
      "&Native=" +
      this.isNative.toString();
    this.trackingTrack.emit({
      eventType: EventTypes.Veridas,
      secondParameter: "Loading DocumentVerificationPage",
      description: veridasEnabledAndNative,
    });

    this.LoadUserData();
    if (!!this.userProfession) {
      this.isProfessionFilled = true;
    } else {
      this.isProfessionFilled = false;
    }

    if (this.userFromIosApp && !this.checkIosChrome()) {
      await this.goToCheckPermissionsBeforeVeridas();
    }

    await this.checkIsIosRealDevice();
  }

  ngOnInit() {
    this.updateStatus();
    this.setVerificationOptions();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get canShowVeridas_NotES_NotCO() {
    if (!this.globalVars.FEATURES.VeridasXpressIdVerificationEnabled) {
      return false;
    } else if (this.checkIosChrome()) {
      return false;
    } else if (this.counterVeridasFailStatus > this.maxNumberOfVeridasFailCashier) {
      return false;
    } else {
      return true;
    }
  }

  checkIosChrome() {
    const ios = /iPhone|iP[oa]d/.test(navigator.userAgent);
    const chrome1 = /Chrome\W\d/.test(navigator.userAgent);
    const chrome2 = /CriOS\W\d/.test(navigator.userAgent);
    const isIosChrome = ios && (chrome1 || chrome2);
    return isIosChrome;
  }

  setExternalTriggerClick(e: string) {
    const send = (this[e] as ElementRef).nativeElement;
    this.triggerClick(send);
  }

  setCamera(e: CameraAccessInterface) {
    const { file, sendStep } = e;
    const input = this.inputsConfig.find((input) => input.name === sendStep);
    const myImage = new Image();
    const reader = new FileReader();
    reader.onload = () => {
      myImage.src = reader.result.toString();
      myImage.onload = () => {
        const resized = this.resize(myImage);
        this.uploadImage(input.type, input.face, resized, file.name);
      };
    };

    reader.readAsDataURL(file);
  }

  /**
   * Void function to unlisten the
   * _renderer.listen method on the onChange call.
   */
  private unlistener: () => void;

  /**
   * Called when need to clean the input value
   */
  cleanLocalInput(input): void {
    return (input.value = null);
  }

  triggerClick(input: HTMLElement) {
    if (this.globalVars.FEATURES.enableVerificationMessage) {
      this.utils.alertHistory(
        this.translate.instant("verificationMessage2"),
        this.translate.instant("verificationMessage"),
        this.translate.instant("close"),
        "alertbig",
      );
    } else {
      const { type, face } = this.inputsConfig.find((config) => config.id === input.id);

      this.unlistener = this._renderer.listen(input, "change", (e) => {
        this.onChange(e, type, face);
        this.unlistener();
      });
      input.click();
      this.cleanLocalInput(input);
    }
  }

  async openModalSworm() {
    const fundsOrigin: Array<FundsOrigin> = [];

    this.utils.loader();

    try {
      const origin = await this.bankService.getFundsOrigin().toPromise();
      fundsOrigin.push(...origin);

      const modalMoreInfo = await this.modalCtrl.create({
        component: CashierWithdrawalsSworm,
        componentProps: {
          fundsOrigin: fundsOrigin,
          originFundsSelected: this.originFunds,
          swormTitle: this.translate.instant("origenDeFondosDocumentacion"),
        },
      });

      await modalMoreInfo.present();

      const { data } = await modalMoreInfo.onDidDismiss<ModalResult>();

      if (data) {
        this.fundOriginSelected = data.originSelected.name;
        if (data.swornAccepted) {
          this.originFunds = this.fundOriginSelected;
          this.userService
            .updateOriginOfFunds(this.fundOriginSelected)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
              next: async () => {},
              error: (err) => {
                console.error(err);
              },
            });
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      this.utils.closeLoader();
    }
  }

  checkCameraStatus() {
    this.nativeService.checkEnableCameraDevice((status) => {
      if (!status) {
        this.utils.showMsg(this.translate.instant("cameraApp"), null, this.translate.instant("cameraApp2"));
      }
    });
  }

  GotoPersonaInfoPage(params?: any) {
    if (this.isDesktop) {
      this.viewCtrl.dismiss(["null", "null", { animate: false }]).then(() => {
        this.router.navigate([`/${MSO_PATHS.BASE}/${MSO_PATHS.PersonalInfoPage}`, params], {
          queryParams: { animate: !this.isDesktop },
        });
      });
    } else {
      this.router.navigate([`/${MSO_PATHS.BASE}/${MSO_PATHS.PersonalInfoPage}`], {
        queryParams: { animate: !this.isDesktop },
      });
    }
  }

  async updateStatus() {
    await this.utils.loader();
    this.counterVeridasFailStatus = 0;

    this.trackingService.track({
      eventType: EventTypes.PaymentsVeridasVerificationsRequested,
      secondParameter: "veridas_verifications_requested",
      description: "Requested previous verifications from the backend",
    });

    this.userService.GetDocVerifications().subscribe({
      next: async (data) => {
        if (data.success) {
          const identFrontData = [];
          const identReverseData = [];
          const bankDocData = [];
          const otherDocData = [];
          const veridasData = [];
          const verificationListData = [];
          const selfieData = [];
          const bankCardData = [];
          const paypalData = [];

          data.records.forEach((record) => {
            const face = !!record.cdr_docface ? record.cdr_docface.Value : null;
            const reason = !!record.cdr_reason ? record.cdr_reason : "";
            const date = this.extractDate.transform(record.ModifiedOn, [1]);
            const recordData: C_StatusData = {
              name: record.cdr_name,
              status: record.statuscode.Value,
              type: record.cdr_type ? record.cdr_type.Value : "",
              face: face,
              reason: reason,
              date: date,
              date_pure: record.ModifiedOn,
            };

            if (record.cdr_type) {
              switch (record.cdr_type.Value) {
                case DOCUMENT_TYPES.dni:
                  face === DOCUMENT_SIDES.front
                    ? identFrontData.push(recordData)
                    : identReverseData.push(recordData);
                  break;
                case DOCUMENT_TYPES.bankReceipt:
                  bankDocData.push(recordData);
                  break;
                case DOCUMENT_TYPES.others:
                  otherDocData.push(recordData);
                  break;
                case DOCUMENT_TYPES.veridas:
                  veridasData.push(recordData);
                  if (record.statuscode.Value === VERIFICATION_STATUS.rejected.toString()) {
                    ++this.counterVeridasFailStatus;
                  }
                  break;
                case DOCUMENT_TYPES.verificationList:
                  verificationListData.push(recordData);
                  break;
                case DOCUMENT_TYPES.selfie:
                  selfieData.push(recordData);
                  break;
                case DOCUMENT_TYPES.bankCard:
                  bankCardData.push(recordData);
                  break;
                case DOCUMENT_TYPES.paypal:
                  paypalData.push(recordData);
                  break;
              }
            }
          });

          this.identFrontData$.next(identFrontData);
          this.identReverseData$.next(identReverseData);
          this.bankDocData$.next(bankDocData);
          this.otherDocData$.next(otherDocData);
          this.veridasData$.next(veridasData);
          this.verificationListData$.next(verificationListData);
          this.selfieData$.next(selfieData);
          this.bankCardData$.next(bankCardData);
          this.paypalData$.next(paypalData);
          if (this.utils.isColombia() || this.utils.isNacional()) {
            this.documentVerificationAvailable = true;
          } else {
            this.documentVerificationAvailable = false;
          }
        } else {
          this.trackingService.track({
            eventType: EventTypes.PaymentsVeridasVerificationsRequestedFailure,
            secondParameter: "veridas_verifications_requested_failure",
            description: "Failure in obtaining previous verifications from the backend",
            additionalData: data,
          });
          this.emptyDocData();
        }
        this.setVerificationOptionsStatuses();
        await this.utils.closeLoader();
      },
      error: async (_) => {
        this.emptyDocData();
        await this.utils.closeLoader();
      },
    });
  }

  emptyDocData() {
    this.identFrontData$.next([]);
    this.identReverseData$.next([]);
    this.bankDocData$.next([]);
    this.otherDocData$.next([]);
    this.veridasData$.next([]);
    this.verificationListData$.next([]);
    this.selfieData$.next([]);
    this.bankCardData$.next([]);
    this.paypalData$.next([]);
  }

  async showAlertInfo(titleToShow: string, messageToShow: string, textButton: string, continueDoc = false) {
    if ((!this.alertOpened && continueDoc) || (!this.alertOpened && this.checkVeridasCanActivated())) {
      if (this.globalVars.licenseType !== LicenseTypes.Nacional) {
        this.showAlertVeri(titleToShow, messageToShow, textButton, continueDoc);
      } else {
        this.showAlertVeri(titleToShow, messageToShow, textButton, continueDoc);
      }
    }
  }

  async showAlertVeri(titleToShow: string, messageToShow: string, textButton: string, continueDoc = false) {
    this.alertOpened = true;
    const infoAlert = await this.alertCtrl.create({
      header: this.utils.isArgentina() ? "" : "Alert",
      subHeader: this.utils.isArgentina()
        ? "Como hacer la verificación corrrectamente"
        : "Recomendaciones para verificar",
      message: messageToShow,
      cssClass: this.utils.isArgentina() ? "alertbig imgDniDocs" : "verification-guide",
      backdropDismiss: true,
      buttons: [
        {
          text: textButton,
          handler: () => {
            this.alertOpened = false;
            if (
              !continueDoc &&
              this.globalVars.FEATURES.VeridasXpressIdVerificationEnabled &&
              !this.checkIosChrome() &&
              this.isDocumentVerifiable(this.userTipoDocumento)
            ) {
              this.checkJobTitle();
            }
          },
        },
      ],
    });
    infoAlert.onDidDismiss().then(() => {
      this.alertOpened = false;
    });
    infoAlert.present();
  }

  showInfoAlert(event: Event = null) {
    if (!!event) {
      event.preventDefault();
    }

    let titleToShow: string;
    let messageToShow: string;
    let textButton: string;
    // the title for all alerts it the same;
    titleToShow = this.translate.instant("documentVerificationtxt02");
    textButton = this.translate.instant("tCont");

    if (!this.veridas_NotES_NotCO_Active || !this.canShowVeridas_NotES_NotCO) {
      messageToShow = this.getMessageToShow(messageToShow);
      this.showAlertInfo(titleToShow, messageToShow, "OK");
    } else if (this.isDocumentVerifiable(this.userTipoDocumento)) {
      if (this.isDesktop) {
        if (
          !this.veridas_NotES_NotCO_Active ||
          this.counterVeridasFailStatus > this.maxNumberOfVeridasFailCashier
        ) {
          messageToShow = this.getMessageToShow(messageToShow);
          this.showAlertInfo(titleToShow, messageToShow, "OK");
        }
      } else {
        if (this.isMobile) {
          if (
            !this.veridas_NotES_NotCO_Active ||
            this.counterVeridasFailStatus > this.maxNumberOfVeridasFailCashier
          ) {
            messageToShow = this.getMessageToShow(messageToShow);
            textButton = "OK";
          } else if (!this.isNative) {
            messageToShow = this.translate.instant("documentVerificationtxt03");
          } else {
            messageToShow = this.translate.instant("documentVerificationtxt04");
          }
        }
        this.showAlertInfo(titleToShow, messageToShow, textButton);
      }
    } else {
      messageToShow = this.translate.instant("documentVerificationtxt05");
      titleToShow = this.translate.instant("documentVerificationtxt06");
      this.showAlertInfo(titleToShow, messageToShow, textButton, false);
    }
  }

  onChange(event, type: string, face: string) {
    const tgt = event.target,
      files = tgt.files;
    if (FileReader && files && files.length) {
      var reader = new FileReader();
      reader.onload = () => {
        const myImage = new Image();
        myImage.src = reader.result.toString();
        myImage.onload = async () => {
          const resized = this.resize(myImage);
          const imgAlert = await this.alertCtrl.create({
            header: this.translate.instant("CodereCardconfir"),
            message: "<img src='" + resized + "'/>",
            cssClass: "sb-alert",
            backdropDismiss: false,
            buttons: [
              {
                text: this.translate.instant("tCancel"),
                role: "cancel",
              },
              {
                text: this.translate.instant("tAccept"),
                handler: () => {
                  this.uploadImage(type, face, resized, files[0].name);
                },
                role: "",
              },
            ],
          });
          const footer = await this.utils.createARFooter();
          await imgAlert.present();
          if (this.utils.isArgentina()) {
            // write in the alert in Argentina
            const ionAlert = await this.alertCtrl.getTop();
            if (ionAlert) {
              const alertElement = ionAlert.querySelector(".alert-wrapper");
              if (alertElement) {
                alertElement.appendChild(footer);
              }
            }
          }
        };
      };
      reader.readAsDataURL(files[0]);
    }
  }

  uploadImage(type: string, face: string, image: string, docName: string) {
    //strip image declaration from image base64
    const doc = encodeURIComponent(image.substring(image.indexOf(",") + 1));
    const verificationData = { type, face, doc, docName };

    this.userService.AddDocVerification(verificationData).subscribe({
      next: (data) => {
        if (data.success === true) {
          this.utils.showMsg(
            this.translate.instant("DocReceivedOK"),
            async () => {
              await this.updateStatus();
            },
            null,
          );
        }
      },
      error: (err) => {},
    });
  }

  resize(img, MAX_WIDTH: number = 900, MAX_HEIGHT: number = 900): string {
    var canvas = document.createElement("canvas");
    var width = img.width;
    var height = img.height;

    if (width > height) {
      if (width > MAX_WIDTH) {
        height *= MAX_WIDTH / width;
        width = MAX_WIDTH;
      }
    } else {
      if (height > MAX_HEIGHT) {
        width *= MAX_HEIGHT / height;
        height = MAX_HEIGHT;
      }
    }
    canvas.width = width;
    canvas.height = height;
    var ctx = canvas.getContext("2d");

    ctx.drawImage(img, 0, 0, width, height);

    var dataUrl: string = canvas.toDataURL("image/jpeg");
    return dataUrl;
  }

  GetProfession(): any {
    if (this.GetValidLicense()) {
      this.userService.getJobTitle().subscribe({
        next: (data) => {
          if (data.success) {
            return data.jobTitle;
          }
        },
        error: (err) => {
          this.utils.showError(this.translate.instant("m_menu_serverFail"));
        },
      });
    }
  }

  GetValidLicense(): boolean {
    if (
      this.globalVars.licenseType !== LicenseTypes.Mexico &&
      this.globalVars.licenseType !== LicenseTypes.Panama
    ) {
      return true;
    } else {
      return false;
    }
  }

  checkVeridasCanActivated() {
    let message;
    if (this.veridasData$.getValue().find((item) => item.status === VERIFICATION_STATUS.pending.toString())) {
      message = this.translate.instant("Tienes una verificación pendiente. Por favor espera a una resolución");
    } else if (this.checkVeridasApprovedDate() || this.isVerificationCompleted) {
      message = this.translate.instant("Tu documentación ya ha sido aprobada");
    }

    if (message) {
      this.utils.alert(false, this.translate.instant("Attention"), message, this.translate.instant("tCont"));
      return false;
    } else {
      return true;
    }
  }

  checkVeridasApprovedDate() {
    let recentApproval = false;
    this.veridasData$.getValue().map((item: C_StatusData) => {
      const setItemDate = new Date(parseInt(item.date_pure.slice(6, item.date_pure.length - 2)));
      if (item.status === VERIFICATION_STATUS.approved.toString()) {
        recentApproval = true;
      }
    });
    return recentApproval;
  }

  diff_minutes(dt2, dt1) {
    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60;
    var minutes = Math.abs(Math.round(diff));
    return minutes;
  }

  async checkJobTitle() {
    if (this.GetValidLicense()) {
      const data = await this.userService.getJobTitle().toPromise();
      if (data.success && data.jobTitle === "false") {
        this.utils.confirm(
          false,
          this.translate.instant("Attention"),
          this.translate.instant("V2_profesionRequest"),
          this.translate.instant("tCancel"),
          null,
          this.translate.instant("tCont"),
          () => {
            this.GotoPersonaInfoPage();
          },
          true,
        );
      } else {
        await this.goToCheckPermissionsBeforeVeridas();
      }
    } else {
      await this.goToCheckPermissionsBeforeVeridas();
    }
  }

  async goToCheckPermissionsBeforeVeridas() {
    if (this.isNative) {
      const permissions = await Camera.checkPermissions();

      if (permissions.camera === "denied") {
        this.utils.showError(
          "Es necesario que aceptes los permisos de acceso a la cámara para poder tomar la fotografía de tu documentación",
        );
        return;
      }

      if (permissions.camera === "prompt") {
        Camera.requestPermissions({
          permissions: ["camera"],
        })
          .then((res) => {
            console.log(res);
            if (res.camera === "granted") {
              this.gotoVerification();
            }
          })
          .catch((err) => {
            if (permissions.camera === "granted") {
              this.gotoVerification();
            } else {
              this.utils.showError(
                "Es necesario que aceptes los permisos de acceso a la cámara para poder tomar la fotografía de tu documentación",
              );
            }
          });
      } else {
        this.gotoVerification();
      }
    } else {
      if (this.isGetUserMediaSupported()) {
        this.gotoVerification();
      } else {
        this.utilsCsService.showInfoAlert(
          this.translate.instant("tFail"),
          "Tu navegador no permite la captura de documentos, por favor utiliza otro distinto",
        );
      }
    }
  }

  async gotoVerification() {
    const cssClass: string = "modalVeridasIframe";
    const modal = await this.modalCtrl.create({
      component: CashierVeridasIframe,
      backdropDismiss: false,
      cssClass: cssClass,
    });
    modal.onDidDismiss().then(async ({ data }) => {
      if (data?.VALIDATION_ID) {
        this.userService
          .setFinishVeridasVerification(data?.VALIDATION_ID, this.playerIDForVeridasFinish)
          .subscribe(async (res) => {
            if (res.Success) {
              this.trackingService.track({
                eventType: EventTypes.Veridas,
                secondParameter: "iframe_process_verified",
                description: data?.VALIDATION_ID,
              });
              await this.updateStatus();
            } else {
              this.trackingService.track({
                eventType: EventTypes.Veridas,
                secondParameter: "iframe_error_process_verified",
                description: data?.VALIDATION_ID,
              });
              await this.updateStatus();
              this.utils.showError(
                "Ha habido un error de verificación en el proceso. Inténtalo de nuevo",
                () => {},
              );
            }
          });
      } else {
        await this.updateStatus();
      }
    });
    await modal.present();
  }

  LoadUserData(): any {
    if (this.globalVars.licenseType !== LicenseTypes.Mexico) {
      this.userService.getUserData().subscribe({
        next: (data) => {
          this.utils.isArgentina() ? (this.originFunds = data.OrigenFondos) : null;
          this.playerIDForVeridasFinish = data.IdJugador;
          this.userProfession = data.Profesion;
          this.userTipoDocumento = data.TipoDocumento;
          this.userNIF = data.NIF;
          this.isVerificationCompleted =
            data.VerifiDocumental === this.USER_DOCUMENT_VERIFICATION_STATUS_COMPLETED;
          if (!this.isVerificationCompleted) {
            this.userService.getVeridasPreviousAttempts(data.IdJugador).subscribe((prevAttempts) => {
              prevAttempts.forEach((attempt) => {
                if (attempt?.status === "REJECTED" || attempt?.status === "PENDING") {
                  this.XpressIDPrevAttempts++;
                }
              });
              if (this.XpressIDPrevAttempts > 0 && this.XpressIDPrevAttempts < 3) {
                this.utils.showMsg(`${this.XpressIDPrevAttempts} / 3 intentos de verificación realizados`);
              }
            });
          }
        },
        error: (err) => {
          this.utils.showError(this.translate.instant("m_menu_serverFail"));
        },
      });
    }
  }

  isGetUserMediaSupported(): boolean {
    var isGetUserMediaSupported = false;
    if (window.navigator["getUserMedia"]) {
      isGetUserMediaSupported = true;
    } else if (window.navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      isGetUserMediaSupported = true;
    }
    return isGetUserMediaSupported;
  }

  get veridas_NotES_NotCO_Active() {
    return (
      this.globalVars.FEATURES.VeridasXpressIdVerificationEnabled &&
      this.isDocumentVerifiable(this.userTipoDocumento)
    );
  }

  get isNativeIos() {
    return this.isNative && this.platform.is("ios") ? true : false;
  }

  titleIDCard(documentSide?: string) {
    const sideOption = {
      front: this.DOCUMENT_SIDE_FRONT_LABEL,
      reverse: this.DOCUMENT_SIDE_BACK_LABEL,
    };

    const defaultTitleFormat = (documentSide?: string) => {
      const residentDocumentTitle = this.translate.instant("payments_default_DNI_NIE_txt");
      const foreignDocumentTitle = this.translate.instant("payments_default_DNI_passport_txt");

      if (this.verifiableDocumentTypes.some((type) => type === this.userTipoDocumento)) {
        return documentSide
          ? `${residentDocumentTitle} ${sideOption[documentSide]}`
          : `${residentDocumentTitle} ${this.userNIF}`;
      } else {
        return documentSide
          ? `${foreignDocumentTitle} ${sideOption[documentSide]}`
          : `${foreignDocumentTitle} ${this.userNIF}`;
      }
    };

    switch (true) {
      case this.utils.isMexico(): {
        const defaultDocument = "IFE";
        return documentSide ? `${defaultDocument} ${sideOption[documentSide]}` : defaultDocument;
      }
      case this.utils.isColombia(): {
        const defaultDocument = this.translate.instant("payments_cedula_extranjeria_CO_txt");
        sideOption.front = "Frontal";
        return documentSide ? `${defaultDocument} ${sideOption[documentSide]}` : defaultDocument;
      }
      case this.utils.isPanama(): {
        const defaultDocument = this.translate.instant("payments_cedula_pasaporte_PA_txt");
        sideOption.front = "Frontal";
        sideOption.reverse = "Trasero";
        return documentSide ? `${defaultDocument} ${sideOption[documentSide]}` : defaultDocument;
      }
      case this.utils.isArgentina(): {
        const defaultDocument = `DNI ${this.userNIF}`;
        return documentSide ? `${defaultDocument} ${sideOption[documentSide]}` : defaultDocument;
      }
      default:
        return defaultTitleFormat(documentSide);
    }
  }

  bankDataInfo() {
    return this.translate.instant("payments_info_Bancaria_txt");
  }

  otherDocument() {
    return this.translate.instant("payments_otros_documentos_txt");
  }

  originFundsCheck() {
    return this.translate.instant("origenDeFondos");
  }

  outLinkIos() {
    const section = "documents";
    const conf = "menubar=yes,resizable=yes,scrollbars=yes,status=yes,location=no',toolbar=no";
    this.userService
      .getTokenForRedirectNativeIos()
      .then((data) => {
        const userInfo = data["_body"];
        const windowObjectReference = window.open(
          this.getUrl() + "?section=" + section + "&userInfo=" + userInfo,
          "_system",
          conf,
        );
        if (windowObjectReference.closed) {
          console.log("ventana nativa cerrada!!");
        }
      })
      .catch((err) => console.log("getToken", err));
  }

  getUrl() {
    return "https://m.apuestas.codere.es/deportes/#/CashierPage";
  }

  setSelfieLiteral() {
    const defaultSelfieDocumentTitle = this.translate.instant("selfieTxt");
    if (this.utils.isColombia() || this.utils.isPanama()) {
      return `${defaultSelfieDocumentTitle} ${this.translate.instant("conCedulaTxt")}`;
    } else if (this.utils.isArgentina()) {
      return `${defaultSelfieDocumentTitle} ${this.translate.instant("conDNITxt")}`;
    } else {
      return defaultSelfieDocumentTitle;
    }
  }

  public get isArgentina() {
    return (
      this.globalVars.licenseType === LicenseTypes.ArgentinaPBA ||
      this.globalVars.licenseType === LicenseTypes.ArgentinaCaba ||
      this.globalVars.licenseType === LicenseTypes.ArgentinaMendoza
    );
  }

  public get isSpain() {
    return (
      this.globalVars.licenseType === LicenseTypes.Nacional ||
      this.globalVars.licenseType === LicenseTypes.Madrid ||
      this.globalVars.licenseType === LicenseTypes.PaisVasco
    );
  }

  public get showUploadDocuments() {
    return this.counterVeridasFailStatus < 3 || this.isVerificationCompleted;
  }

  public get getVerificationRequests() {
    let verificationRequests: C_StatusData[] = [];
    verificationRequests = [...this.veridasData$.getValue()];

    return verificationRequests.reverse();
  }

  public get lastDocumentUploadRequests() {
    const idFrontReq = [...this.identFrontData$.getValue()].reverse();
    const idReverseReq = [...this.identReverseData$.getValue()].reverse();
    const bankReq = [...this.bankDocData$.getValue()].reverse();
    const otherDocReq = [...this.otherDocData$.getValue()].reverse();
    const selfieReq = [...this.selfieData$.getValue()].reverse();

    return {
      [this.verDocType.idFront]: idFrontReq[0],
      [this.verDocType.idReverse]: idReverseReq[0],
      [this.verDocType.idBank]: bankReq[0],
      [this.verDocType.idSelfie]: selfieReq[0],
      [this.verDocType.idOther]: otherDocReq[0],
    };
  }

  public get getDocumentUploadRequests() {
    const idFrontReq = [...this.identFrontData$.getValue()].reverse();
    const idReverseReq = [...this.identReverseData$.getValue()].reverse();
    const bankReq = [...this.bankDocData$.getValue()].reverse();
    const otherDocReq = [...this.otherDocData$.getValue()].reverse();
    const selfieReq = [...this.selfieData$.getValue()].reverse();

    return {
      [this.verDocType.idFront]: idFrontReq,
      [this.verDocType.idReverse]: idReverseReq,
      [this.verDocType.idBank]: bankReq,
      [this.verDocType.idSelfie]: selfieReq,
      [this.verDocType.idOther]: otherDocReq,
    };
  }

  public getLastDocumentUploadRequests(documentType: string) {
    let documents: any[];
    switch (documentType) {
      case this.verDocType.idFront:
        documents = [...this.identFrontData$.getValue()];
        break;
      case this.verDocType.idReverse:
        documents = [...this.identReverseData$.getValue()];
        break;
      case this.verDocType.idSelfie:
        documents = [...this.selfieData$.getValue()];
        break;
      case this.verDocType.idBank:
        documents = [...this.bankDocData$.getValue()];
        break;
      case this.verDocType.idOther:
        documents = [...this.otherDocData$.getValue()];
        break;
    }
    return documents[documents.length - 1];
  }

  private isCurrentLicense(license: LicenseTypes) {
    return license === this.globalVars.licenseType;
  }

  public isLicenseAvailableToVeridas() {
    return this.allowedVeridasLicenses.some(this.isCurrentLicense);
  }

  public isLicenseAvailableToPersonalDataPolicy() {
    return this.allowedPersonalDataPolicyLicenses.some(this.isCurrentLicense);
  }

  public isDocumentVerifiable(documentType: string) {
    return this.verifiableDocumentTypes.some((type) => type === documentType);
  }

  public isVerificationFlowEnabled() {
    const verificationRequests = this.getVerificationRequests;
    const length = this.getVerificationRequests.length;
    if (length === 0) {
      return true;
    }

    const lastVerificationRequest = verificationRequests[0];

    return length <= 3 && lastVerificationRequest.status !== "754310001";
  }

  public isVerificationApproved() {
    const verificationRequests = this.getVerificationRequests;
    const length = this.getVerificationRequests.length;
    if (length === 0) {
      return false;
    }

    const { status } = verificationRequests[0];

    return status === VERIFICATION_STATUS.approved.toString();
  }

  public isVerificationPending() {
    const length = this.getVerificationRequests.length;
    if (length === 0) {
      return false;
    }

    const { status } = this.getVerificationRequests[0];

    return length <= 3 && status === VERIFICATION_STATUS.pending.toString();
  }

  public isMaxFailuresNumberReached() {
    const length = this.getVerificationRequests.length;
    const verificationRequests = this.getVerificationRequests;
    return (
      length === 3 &&
      verificationRequests.every(
        (request) => request.status === VERIFICATION_STATUS.rejected.toString() && !this.isManualUploadEnabled(),
      )
    );
  }

  // CONTROLS IF LIST OF DOCUMENTS APPEARS, ALONG WITH MANY OTHER THINGS
  public isManualUploadEnabled() {
    if (this.utils.isSpain()) {
      if (this.showAutoVerification) {
        // FF ES ON
        if (this.isVerificationCompleted) {
          return true;
        } else {
          if (this.XpressIDPrevAttempts < 3) {
            return false;
          } else {
            return true;
          }
        }
      } else {
        return true;
      }
    }
    if (this.utils.isColombia()) {
      if (this.isVeridasCOActive) {
        // FF CO ON
        if (this.isVerificationCompleted) {
          return true;
        } else {
          if (this.XpressIDPrevAttempts < 3) {
            return false;
          } else {
            return true;
          }
        }
      } else {
        return true;
      }
    }
    // REST LICENSES ALWAYS ON
    if (!this.utils.isSpain() && !this.utils.isColombia()) {
      return true;
    }
  }

  public async handleAcceptedPolicy(policyAccepted: boolean) {
    if (policyAccepted) {
      this.checkJobTitle();
    }
  }

  public async showInstructionsToVerify(event: Event = null) {
    if (!!event) {
      event.preventDefault();
    }

    const instructionsToVerify = this.translate.instant("documentVerificationtxt01");
    const buttonLabel = this.isDesktop
      ? this.translate.instant("No gracias, continuaré con mi ordenador")
      : this.translate.instant("tCont");
    const buttonHandler = () => {
      this.utils.events.destroy("qr:available");
    };

    const alertOptions = {
      header: "Alerta",
      subHeader: "Recomendaciones para verificar",
      cssClass: "verification-guide",
      backdropDismiss: true,
      buttons: [
        {
          text: "x",
          cssClass: "corner-close-icon",
          handler: buttonHandler,
        },
        {
          text: buttonLabel,
          handler: buttonHandler,
        },
      ],
    };

    const displayAlert = async () => {
      this.utils.closeLoader();
      const options = {
        message: this.isDesktop ? instructionsToVerify : "<div class='mobile'>" + instructionsToVerify + "</div>",
        ...alertOptions,
      };

      const alert = await this.alertCtrl.create(options);
      alert.present();
    };
    displayAlert();
  }

  public async initVerification(event: Event = null) {
    const modalConfirm = await this.modalCtrl.create({
      component: CsVeridasPolicyAcceptModal,
      componentProps: {},
      cssClass: "cs-request-info is-modal",
    });
    await modalConfirm.present();
    const { data } = await modalConfirm.onDidDismiss();

    if (data?.accepted) {
      this.handleAcceptedPolicy(true);
    }
  }

  public applyClassToDocumentList() {
    const defaultClass = "cs-documents--list";
    return this.utils.isColombia() ? `${defaultClass} display-flex` : defaultClass;
  }

  public applyClassToManualUploadTitle() {
    const defaultClass = "cs-document-item--container manual-upload-title justify-center";
    return this.utils.isColombia() ? defaultClass : `${defaultClass} p-1 m-bottom-36`;
  }

  public applyClassToVerificationInfoHeader() {
    const defaultClass = "verification-info-header text-center";
    return this.utils.isColombia() ? `${defaultClass} display-flex` : defaultClass;
  }

  public applyClassToVerificationInfoParagraph() {
    const defaultClass = "m-bottom-2 font-size-2";
    return this.utils.isColombia() ? `font-size-1` : defaultClass;
  }

  public applyClassToVerificationInfoLink() {
    const defaultClass = "verification-info-link color-secondary";
    return this.utils.isColombia() ? `${defaultClass} font-size-1` : `${defaultClass} m-bottom-2 p-1`;
  }

  public getMessageToShow(messageToShow: string) {
    if (this.utils.isArgentina()) {
      messageToShow = this.translate.instant("documentVerificationtxt01NoVeridasARG");
      //(debajo) div para mostrar (por scss, class: reverso-dni) la imagen en background del reverso del DNI.
      messageToShow += "<div class='reverso-dni'></div>";
    } else {
      messageToShow = this.translate.instant("documentVerificationtxt01NoVeridas");
    }
    return messageToShow;
  }

  public showOriginFunds() {
    return this.isArgentina && this.showOriginOfFunds;
  }

  public getVerificationOptionSelected(verificationOptionSelected: VerificationOption) {
    this.verificationOptionSelected = verificationOptionSelected;
  }

  getVerificationLiterals() {
    const header = {
      title: this.translate.instant("verification_text_title"),
      verified: this.translate.instant("verification_text_verified"),
      not_verified: this.translate.instant("verification_text_not_verified"),
      pending: this.translate.instant("verification_text_pending"),
      required: this.translate.instant("verification_text_required"),
      account: this.translate.instant("verification_text_account"),
      DNI: this.translate.instant("verification_text_DNI"),
      justificante: null,
    };

    if (this.translate.instant("verification_text_justificante") !== "verification_text_justificante") {
      header.justificante = this.translate.instant("verification_text_justificante");
    }

    const buttons = {
      DNI: this.translate.instant("verification_text_button_DNI"),
      justificante: this.translate.instant("verification_text_button_justificante"),
      otros: this.translate.instant("verification_text_button_otros"),
    };

    this.setQuestions("FAQ");
    this.setQuestions("DNI");
    this.setQuestions("justificante");
    this.setQuestions("otros");

    this.verificationLiterals = new VerificationLiterals(header, buttons, this.literalQuestions);
  }

  setQuestions(type: string): any {
    this.literalQuestions[type] = { QA: [] };
    const prefix = "verification_accordion_" + type;
    if (type === "FAQ") {
      this.literalQuestions[type].title = this.translate.instant("verification_FAQ_text_title");
    }
    for (let i = 1; i <= 10; i++) {
      const questionLiteral = prefix + "_question_0" + i;
      const answerLiteral = prefix + "_answer_0" + i;

      const question = this.translate.instant(questionLiteral);
      const answer = this.translate.instant(answerLiteral);

      if (question !== "" && answer !== "") {
        if (question !== questionLiteral && answer !== answerLiteral) {
          this.literalQuestions[type].QA.push({ question: question, answer: answer });
        }
      }
    }
  }

  iconSource(doc?: any, statusNumber?: number) {
    switch (doc?.status || statusNumber) {
      //rejected
      case 754310000:
        return "icon-verification-button-rejected.svg";
      //approved
      case 754310001:
        return "icon-verification-button-completed.svg";
      //solicited
      case 754310002:
        return "icon-verification-button-requested.svg";
      //processing
      case 1:
        return "icon-verification-button-pending.svg";
      //inactive
      case 2:
        return null;
    }
  }

  setVerificationOptionsStatuses() {
    const docBank = this.getLastDocumentUploadRequests("bank");
    const docOther = this.getLastDocumentUploadRequests("other");

    if (docBank && docOther) {
      this.verificationOptions.find((option: VerificationOption) => option.type === VerificationTypes.DNI).status =
        this.iconSource(null, this.getGlobalDNIStatus())
          ? `assets/global/img/${this.iconSource(null, this.getGlobalDNIStatus())}`
          : null;
      this.verificationOptions.find(
        (option: VerificationOption) => option.type === VerificationTypes.JustificanteBancario,
      ).status = `assets/global/img/${this.iconSource(docBank)}`;
      this.verificationOptions.find(
        (option: VerificationOption) => option.type === VerificationTypes.Otros,
      ).status = `assets/global/img/${this.iconSource(docOther)}`;
    }
  }

  getGlobalDNIStatus(): number {
    const docDniFront = this.getLastDocumentUploadRequests(this.verDocType.idFront);
    const docDniReverse = this.getLastDocumentUploadRequests(this.verDocType.idReverse);
    const docSelfie = this.getLastDocumentUploadRequests(this.verDocType.idSelfie);
    if (docDniFront && docDniReverse && docSelfie) {
      if (
        docDniFront.status === VerificationStatus.Rejected ||
        docDniReverse.status === VerificationStatus.Rejected ||
        docSelfie.status === VerificationStatus.Rejected
      )
        return VerificationStatus.Rejected; //"icon-verification-button-rejected.svg"
      else if (
        docDniFront.status === VerificationStatus.Pending ||
        docDniReverse.status === VerificationStatus.Pending ||
        docSelfie.status === VerificationStatus.Pending
      )
        return VerificationStatus.Pending; //"icon-verification-pending.svg"
      else if (
        docDniFront.status === VerificationStatus.Rejected ||
        docDniReverse.status === VerificationStatus.Rejected ||
        docSelfie.status === VerificationStatus.Rejected
      )
        return VerificationStatus.Rejected; //"icon-verification-requested.svg"
      else if (
        docDniFront.status === VerificationStatus.Verified ||
        docDniReverse.status === VerificationStatus.Verified ||
        docSelfie.status === VerificationStatus.Verified
      )
        return VerificationStatus.Verified; //"icon-verification-button-completed.svg"
    } else return null;
  }

  setVerificationOptions() {
    const DNIVerification: VerificationOption = {
      type: VerificationTypes.DNI,
      text: this.verificationLiterals.buttons.DNI, //"DNE/DNI, Selfie" //verification_text_button_DNI
      selected: true,
    };
    const justificanteBancarioVerification: VerificationOption = {
      type: VerificationTypes.JustificanteBancario,
      text: this.verificationLiterals.buttons.justificante, //"Justificante Bancario" //verification_text_button_justificante
      selected: false,
    };
    const otrosVerification: VerificationOption = {
      type: VerificationTypes.Otros,
      text: this.verificationLiterals.buttons.otros, //"Otros" //verification_text_button_otros
      selected: false,
    };
    this.verificationOptions.push(DNIVerification);
    this.verificationOptions.push(justificanteBancarioVerification);
    this.verificationOptions.push(otrosVerification);
  }
}
