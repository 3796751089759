<!-- eslint-disable -->
<div
  id="swipableArea"
  #swipableArea
  (touchend)="onTouchEnd($event)"
  [ngStyle]="{
    height: swipableAreaStyleHeight,
    transition: swipableAreaStyleTransition,
  }"
>
  <div #swiperPullBar class="swiper-pull-bar--wrapper" *ngIf="!isTicketFullOpen()">
    <div [hidden]="confirmedTicket" class="swiper-pull-bar"></div>
  </div>

  <ticket-collapsed
    [ngStyle]="{
      display: hiddenTicketCollapsed(),
      height: ticketCollapsedIsOpen ? 'auto' : '79px'
    }"
    #ticketCollapsed
  >
  </ticket-collapsed>
</div>

<div class="reveal" (click)="toggleFullTicket(false); $event.stopPropagation()" *ngIf="ticketFullModalIsOpen"></div>

<div
  *ngIf="ticketFullModalIsOpen"
  tappable
  [hidden]="confirmedTicket"
  class="sb-ticket--header border-color-light background-color-dark"
  #header
>
  <ion-toolbar class="sportsbook">
    <p class="sb-tm-small-footer--title text-uppercase color-light">{{ 'tTitle' | translate }}</p>
    <ion-buttons slot="primary">
      <ion-button
        class="sports-btn btn-transparent with-border sportsbook margin-right-14"
        (click)="toggleFullTicket(false); $event.stopPropagation()"
        [disabled]="keyboard$ | async"
        fill="solid"
      >
        <ion-label>
          <span class="sb-ticket--button-title text-uppercase">{{ 'close' | translate }}</span>
        </ion-label>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</div>

<ticket
  [ngClass]="{
    'openKeyboard': openKeyboardOnTicketFull(),
    'auto-height': confirmedTicket,
    'hide-ticket-full': !isTicketFullOpen()
  }"
  [ngStyle]="{
    display: hiddenFullTicket()
  }"
  [ticketState]="ticketState"
  (ticketOddAcceptance)="newTicketFacade?.presentOddAcceptance()"
  #ticketFull
></ticket>

<sports-keyboard
  *ngIf="keyboard$ | async"
  [buttons]="newTicketFacade?.defaultAmounts"
  [active]="keyboard$ | async"
  [disabledDot]="mobileBehavior?.disabledDot$ | async"
  [stakeLimit]="stakeLimit"
  (closeKeyBoard)="closeKeyboard($event)"
  (press)="updateAmount($event)"
>
</sports-keyboard>

<div
  #smallFooter
  [ngStyle]="{
    display: smallFooterSetDisplay,
    opacity: smallFooterOpacity
  }"
  class="sb-tm-small-footer--resume background-color-dark"
  tappable
  (touchend)="onTouchEndSmallFooter($event)"
  (panup)="toggle()"
  (click)="toggle()"
>
  <div class="sb-tm-small-footer--content-left">
    <span
      class="sb-tm-small-footer--resume-bullet"
      [ngClass]="{
      'background-color-primary': !hasFreebet(),
      'color-dark': !hasFreebet(),
      'background-color-secondary': hasFreebet(),
      'color-light': hasFreebet()}"
    >
      {{ numberOfSelections }}
    </span>
    <span class="sb-tm-small-footer--resume-title color-light"> {{'v2_selec' | translate }} </span>
  </div>

  <div class="sb-tm-small-footer--content-right" *ngIf="ticket?.BetType != 0">
    <span class="sb-tm-small-footer--resume-title color-light"> {{ 'hOdd' | translate }} {{ cuota | formatOdd }} </span>
  </div>
</div>
