<!-- eslint-disable @angular-eslint/template/eqeqeq -->
<ng-container *ngIf="!isMobile; else mobileView">
  <ion-content
    [ngSwitch]="steps"
    class="is-narrow-content has-titleBar"
    class="ion-padding"
    *ngIf="globalVars.licenseType !== 4"
  >
    <user-section-title [sectionTitle]="'CodereCardToCheck'"></user-section-title>
    <ion-card class="codere-card">
      <ion-card-header>
        <span>
          {{'CodereCardc' | translate | uppercase }}
          <!--TARJETA-->
        </span>
        <img *ngIf="globalVars.licenseType !== 4" src="assets/global/img/logoGreen.svg" alt="" />
        <img *ngIf="globalVars.licenseType === 4" src="assets/global/img/logo_cirpremia.png" alt="" />
      </ion-card-header>
      <!--NUMERO DE TARJETA-->
      <ion-item class="card-item" lines="none">
        <ion-label>
          <p>
            <span>{{'CodereCardToChecknumber' | translate | uppercase }}</span>
            <span class="result">0000000000000000</span>
          </p>
        </ion-label>
      </ion-item>
      <!--SALDO DE TARJETA-->
      <ion-item class="card-item" lines="none">
        <ion-label>
          <p>
            <span>{{'CodereCardBalance' | translate | uppercase }}</span>
            <span class="result"><b>0€</b></span>
          </p>
        </ion-label>
      </ion-item>
      <!--FECHA DE VINCULACION-->
      <ion-item class="card-item" lines="none">
        <ion-label>
          <p>
            <span>{{'CodereCardDate' | translate | uppercase }}</span>
            <span class="result">01 / 01 / 2016 </span>
          </p>
        </ion-label>
      </ion-item>
    </ion-card>

    <ion-toolbar class="subHeaderSteps">
      <ion-segment>
        <ion-segment-button
          [ngClass]="{enabledButton: steps=='step1', disabledButton: steps!='step1'}"
          disabled
          tappable="false"
          value="step1"
        >
          <ion-avatar>
            <span>1</span>
          </ion-avatar>
          <div class="squareArr"><i></i></div>
        </ion-segment-button>

        <ion-segment-button
          [ngClass]="{enabledButton: steps!='step1', disabledButton: steps=='step1'}"
          disabled
          tappable="false"
          value="step2"
        >
          <ion-avatar>
            <span>2</span>
          </ion-avatar>
          <div class="squareArr"><i></i></div>
        </ion-segment-button>
      </ion-segment>
    </ion-toolbar>

    <!-- step1 -->
    <form class="card-link-form" [formGroup]="myForm1" scroll="true" *ngSwitchCase="'step1'">
      <ion-list>
        <div
          class="myWrapInput first"
          [class.has-error]="!myForm1.controls['numTarjeta'].valid && myForm1.controls['numTarjeta'].touched"
        >
          <ion-item
            class="cdr-item-input"
            lines="none"
            [class.error]="!myForm1.controls['numTarjeta'].valid && myForm1.controls['numTarjeta'].touched"
          >
            <ion-input
              labelPlacement="floating"
              label="{{'CodereCardToChecknumber' | translate}}"
              type="tel"
              [counter]="false"
              [maxlength]="this.globalVars.licenseType === 0 ? 19 : 16"
              [class.link-error]="!myForm1.controls['numTarjeta'].valid && myForm1.controls['numTarjeta'].touched"
              formControlName="numTarjeta"
            ></ion-input>
          </ion-item>

          <div
            class="labelError"
            [hidden]="myForm1.controls['numTarjeta'].valid || !myForm1.controls['numTarjeta'].touched"
          >
            <span *ngIf="myForm1.controls['numTarjeta'].hasError('required')"
              >{{'error_form_requiered' | translate}}</span
            >
            <span *ngIf="myForm1.controls['numTarjeta'].hasError('valid')">{{'GetCardChecknumKO' | translate}}</span>
          </div>
        </div>
        <div class="sendForm">
          <ion-button expand="full" (click)="sendTarjeta()">
            {{'tCont' | translate}}
            <!--Continuar-->
          </ion-button>
        </div>
      </ion-list>
    </form>
    <!-- end step1 -->

    <!-- step2 -->
    <form class="card-link-form" [formGroup]="myForm2" scroll="true" *ngSwitchCase="'step2'">
      <ion-list>
        <p>
          {{'GetCardLink' | translate}}
          <!--Tarjeta vinculada-->
        </p>
        <div class="myWrapInput">
          <div class="completeInput">{{cardNumber}}</div>
        </div>
        <p>
          {{'CoderecardPin' | translate}} <span class="required-text">*</span>
          <!--Inserta el Pin para tu nueva tarjeta*-->
        </p>
        <div class="myWrapInput">
          <ion-item
            [class.error]="!myForm2.controls['pin1'].valid && myForm2.controls['pin1'].touched"
            class="cdr-item-input"
            lines="none"
          >
            <ion-input
              labelPlacement="floating"
              label="Pin"
              type="password"
              [counter]="false"
              [maxlength]="4"
              [class.link-error]="!myForm1.controls['numTarjeta'].valid && myForm1.controls['numTarjeta'].touched"
              formControlName="pin1"
            ></ion-input>
          </ion-item>

          <div class="labelError" [hidden]="myForm2.controls['pin1'].valid || !myForm2.controls['pin1'].touched">
            <span *ngIf="myForm2.controls['pin1'].hasError('required')"
              >{{'v2_pinObl' | translate}}
              <!--# El pin es obligatorio--></span
            >
            <span *ngIf="myForm2.controls['pin1'].hasError('pattern')"
              >{{'v2_pinNum' | translate}}
              <!--# El pin debe contener sólo números--><br
            /></span>
            <span *ngIf="myForm2.controls['pin1'].hasError('maxlength')"
              >{{'v2_pinNum1' | translate}}
              <!--# El pin debe contener 4 números--></span
            >
            <span *ngIf="myForm2.controls['pin1'].hasError('minlength')"
              >{{'v2_pinNum1' | translate}}
              <!--# El pin debe contener 4 números--></span
            >
          </div>
        </div>
        <div class="myWrapInput">
          <ion-item
            [class.error]="!myForm2.controls['repin1'].valid && myForm2.controls['repin1'].touched"
            class="cdr-item-input"
            lines="none"
          >
            <ion-input
              labelPlacement="floating"
              label="{{'GetCardRepeatPin' | translate}}"
              class="cdr-input-floating"
              type="password"
              [counter]="false"
              [maxlength]="4"
              formControlName="repin1"
            ></ion-input>
          </ion-item>

          <div class="labelError" [hidden]="myForm2.controls['repin1'].valid || !myForm2.controls['repin1'].touched">
            <span *ngIf="myForm2.controls['repin1'].hasError('required')"
              >{{'v2_pinObl' | translate}}
              <!--# El pin es obligatorio--></span
            >
            <span *ngIf="myForm2.controls['repin1'].hasError('pattern')"
              >{{'v2_pinNum' | translate}}
              <!--# El pin debe contener sólo números--><br
            /></span>
            <span *ngIf="myForm2.controls['repin1'].hasError('maxlength')"
              >{{'v2_pinNum1' | translate}}
              <!--# El pin debe contener 4 números--></span
            >
            <span *ngIf="myForm2.controls['repin1'].hasError('minlength')"
              >{{'v2_pinNum1' | translate}}
              <!--# El pin debe contener 4 números--></span
            >
            <span *ngIf="myForm2.controls['repin1'].hasError('same')">
              {{'v2_errorPin' | translate}}
              <!--# El PIN no coincide--></span
            >
          </div>
        </div>
        <div
          class="myWrapLegal"
          [class.error]="!myForm2.controls['legal1'].value && myForm2.controls['legal1'].touched"
        >
          <ion-item lines="none" class="paddingl16">
            <ion-checkbox labelPlacement="end" formControlName="legal1">.</ion-checkbox>
            <!--[innerHTML]="'v2_acpTerm' | translate"-->
            <ion-label>
              Es necesario aceptar los
              <a [href]="urlCondiciones" target="_blank">términos y condiciones</a>
            </ion-label>
          </ion-item>
          <div class="labelError" [hidden]="myForm2.controls['legal1'].value || !myForm2.controls['legal1'].touched">
            {{'error_form_check_ct' | translate}}
          </div>
        </div>
        <div class="sendForm">
          <ion-button expand="full" (click)="sendPin1()">
            {{'CodereCardToCheck' | translate}}
            <!--Vincular tarjeta-->
          </ion-button>
        </div>
      </ion-list>
    </form>
    <!-- end step2 -->

    <!-- step3 -->
    <form class="card-link-form" [formGroup]="myForm3" scroll="true" *ngSwitchCase="'step3'">
      <ion-list>
        <p>
          {{'GetCardLink' | translate}}
          <!--Tarjeta vinculada-->
        </p>
        <div class="myWrapInput">
          <div class="completeInput">{{cardNumber}}</div>
        </div>
        <p>
          {{'CoderecardPin' | translate}}<span class="required-text">*</span>
          <!--Inserta el Pin para tu nueva tarjeta*-->
        </p>
        <div class="myWrapInput">
          <ion-item
            [class.error]="!myForm3.controls['pin2'].valid && myForm3.controls['pin2'].touched"
            class="cdr-item-input"
            lines="none"
          >
            <ion-input
              labelPlacement="floating"
              label="Pin"
              type="password"
              [counter]="false"
              [maxlength]="4"
              formControlName="pin2"
            ></ion-input>
          </ion-item>

          <div class="labelError" [hidden]="myForm3.controls['pin2'].valid || !myForm3.controls['pin2'].touched">
            <span *ngIf="myForm3.controls['pin2'].hasError('required')"
              >{{'v2_pinObl' | translate}}
              <!--# El pin es obligatorio--></span
            >
            <span *ngIf="myForm3.controls['pin2'].hasError('pattern')"
              >{{'v2_pinNum' | translate}}
              <!--# El pin debe contener sólo números--><br
            /></span>
            <span *ngIf="myForm3.controls['pin2'].hasError('maxlength')"
              >{{'v2_pinNum1' | translate}}
              <!--# El pin debe contener 4 números--></span
            >
            <span *ngIf="myForm3.controls['pin2'].hasError('minlength')"
              >{{'v2_pinNum1' | translate}}
              <!--# El pin debe contener 4 números--></span
            >
          </div>
        </div>

        <div
          class="myWrapLegal"
          [class.error]="!myForm3.controls['legal2'].value && myForm3.controls['legal2'].touched"
        >
          <ion-item lines="none">
            <!--[innerHTML]="'v2_acpTerm' | translate"-->
            <ion-checkbox labelPlacement="end" formControlName="legal2">.</ion-checkbox>
            <ion-label>
              Es necesario aceptar los
              <a [href]="urlCondiciones" target="_blank">términos y condiciones</a>
            </ion-label>
          </ion-item>
          <div class="labelError" [hidden]="myForm3.controls['legal2'].value || !myForm3.controls['legal2'].touched">
            {{'error_form_check_ct' | translate}}
          </div>
        </div>

        <div class="sendForm">
          <ion-button expand="full" (click)="sendPin2()">
            {{'CodereCardToCheck' | translate}}
            <!--Vincular tarjeta-->
          </ion-button>
        </div>
      </ion-list>
    </form>
    <!-- end step3 -->

    <ion-list class="legal-list card-link-conditions">
      <ion-list-header>
        <ion-label>
          {{'CoderecardAdvantages' | translate | uppercase }}
          <!--VENTAJAS TARJETA CODERE-->
          <hr />
        </ion-label>
      </ion-list-header>

      <ion-item class="legal-item card-link-item" lines="none">
        <ion-label>
          <p>{{'CoderecardAdvantages01'|translate}}</p>
          <p>{{'CoderecardAdvantages02'|translate}}</p>
        </ion-label>
      </ion-item>
    </ion-list>
    <codere-footer></codere-footer>
  </ion-content>

  <!-- MEXICO -->
  <ion-content *ngIf="globalVars.licenseType === 4">
    <ion-card class="codere-card">
      <ion-card-header>
        <span>
          {{'CodereCardc' | translate | uppercase }}
          <!--TARJETA-->
        </span>
        <img src="assets/global/img/logo_cirpremia.png" alt="" />
      </ion-card-header>
    </ion-card>
    <form class="card-link-form" [formGroup]="myForm4" scroll="true">
      <ion-list>
        <div
          class="myWrapInput first"
          [class.has-error]="!myForm4.controls['mexicoNumTarjeta'].valid && myForm4.controls['mexicoNumTarjeta'].touched"
        >
          <ion-item
            [class.error]="!myForm4.controls['mexicoNumTarjeta'].valid && myForm4.controls['mexicoNumTarjeta'].touched"
            class="cdr-item-input"
            lines="none"
          >
            <ion-input
              labelPlacement="floating"
              label="{{'CodereCardToChecknumber' | translate}}"
              type="tel"
              [counter]="false"
              [maxlength]="20"
              formControlName="mexicoNumTarjeta"
            ></ion-input>
          </ion-item>
          <div
            class="labelError"
            [hidden]="myForm4.controls['mexicoNumTarjeta'].valid || !myForm4.controls['mexicoNumTarjeta'].touched"
          >
            <span *ngIf="myForm4.controls['mexicoNumTarjeta'].hasError('required')"
              >{{'error_form_requiered' | translate}}</span
            >
            <span *ngIf="myForm4.controls['mexicoNumTarjeta'].hasError('valid')"
              >{{'GetCardChecknumKO' | translate}}</span
            >
          </div>
        </div>
        <div class="sendForm">
          <ion-button expand="full" (click)="sendTarjetaMexico()">
            {{'tCont' | translate}}
            <!--Continuar-->
          </ion-button>
        </div>
      </ion-list>
    </form>
  </ion-content>
</ng-container>

<ng-template #mobileView>
  <ion-header>
    <user-section-title [sectionTitle]="'CodereCardToCheck'"></user-section-title>
  </ion-header>

  <ion-content [ngSwitch]="steps" *ngIf="globalVars.licenseType !== 4">
    <ion-card class="codere-card">
      <ion-card-header>
        <span>
          {{'CodereCardc' | translate | uppercase }}
          <!--TARJETA-->
        </span>
        <img src="assets/global/img/logoGreen.svg" alt="" />
      </ion-card-header>
      <!--NUMERO DE TARJETA-->
      <ion-item class="card-item" lines="none">
        <ion-label>
          <p>
            <span>{{'CodereCardToChecknumber' | translate | uppercase }}</span>
            <span class="result">0000000000000000</span>
          </p>
        </ion-label>
      </ion-item>
      <!--SALDO DE TARJETA-->
      <ion-item class="card-item" lines="none">
        <ion-label>
          <p>
            <span>{{'CodereCardBalance' | translate | uppercase }}</span>
            <span class="result"><b>0€</b></span>
          </p>
        </ion-label>
      </ion-item>
      <!--FECHA DE VINCULACION-->
      <ion-item class="card-item" lines="none">
        <ion-label>
          <p>
            <span>{{'CodereCardDate' | translate | uppercase }}</span>
            <span class="result">01 / 01 / 2016 </span>
          </p>
        </ion-label>
      </ion-item>
    </ion-card>

    <ion-toolbar class="subHeaderSteps">
      <ion-segment lines="none">
        <ion-segment-button
          [ngClass]="{enabledButton: steps=='step1', disabledButton: steps!='step1'}"
          disabled
          tappable="false"
          value="step1"
          mode="md"
        >
          <ion-avatar>
            <span>1</span>
          </ion-avatar>
          <div class="squareArr"><i></i></div>
        </ion-segment-button>

        <ion-segment-button
          [ngClass]="{enabledButton: steps!='step1', disabledButton: steps=='step1'}"
          disabled
          tappable="false"
          value="step2"
          mode="md"
        >
          <ion-avatar>
            <span>2</span>
          </ion-avatar>
          <div class="squareArr"><i></i></div>
        </ion-segment-button>
      </ion-segment>
    </ion-toolbar>

    <!-- step1 -->
    <form class="card-link-form" [formGroup]="myForm1" scroll="true" *ngSwitchCase="'step1'">
      <ion-list>
        <div
          class="myWrapInput first"
          [class.has-error]="!myForm1.controls['numTarjeta'].valid && myForm1.controls['numTarjeta'].touched"
        >
          <ion-item
            class="cdr-item-input"
            lines="none"
            [class.error]="!myForm1.controls['numTarjeta'].valid && myForm1.controls['numTarjeta'].touched"
          >
            <ion-input
              labelPlacement="floating"
              label="{{'CodereCardToChecknumber' | translate}}"
              type="tel"
              [counter]="false"
              [maxlength]="this.globalVars.licenseType === 0 ? 19 : 16"
              [class.link-error]="!myForm1.controls['numTarjeta'].valid && myForm1.controls['numTarjeta'].touched"
              formControlName="numTarjeta"
            ></ion-input>
          </ion-item>

          <div
            class="labelError"
            [hidden]="myForm1.controls['numTarjeta'].valid || !myForm1.controls['numTarjeta'].touched"
          >
            <span *ngIf="myForm1.controls['numTarjeta'].hasError('required')"
              >{{'error_form_requiered' | translate}}</span
            >
            <span *ngIf="myForm1.controls['numTarjeta'].hasError('valid')">{{'GetCardChecknumKO' | translate}}</span>
          </div>
        </div>
        <div class="sendForm">
          <ion-button expand="full" (click)="sendTarjeta()">
            {{'tCont' | translate}}
            <!--Continuar-->
          </ion-button>
        </div>
      </ion-list>
    </form>
    <!-- end step1 -->

    <!-- step2 -->
    <form class="card-link-form" [formGroup]="myForm2" scroll="true" *ngSwitchCase="'step2'">
      <ion-list>
        <p>
          {{'GetCardLink' | translate}}
          <!--Tarjeta vinculada-->
        </p>
        <div class="myWrapInput">
          <div class="completeInput">{{cardNumber}}</div>
        </div>
        <p>
          {{'CoderecardPin' | translate}} <span class="required-text">*</span>
          <!--Inserta el Pin para tu nueva tarjeta*-->
        </p>
        <div class="myWrapInput">
          <ion-item
            [class.error]="!myForm2.controls['pin1'].valid && myForm2.controls['pin1'].touched"
            class="cdr-item-input"
            lines="none"
          >
            <ion-input
              labelPlacement="floating"
              label="Pin"
              type="password"
              [counter]="false"
              [maxlength]="4"
              [class.link-error]="!myForm1.controls['numTarjeta'].valid && myForm1.controls['numTarjeta'].touched"
              formControlName="pin1"
            ></ion-input>
          </ion-item>

          <div class="labelError" [hidden]="myForm2.controls['pin1'].valid || !myForm2.controls['pin1'].touched">
            <span *ngIf="myForm2.controls['pin1'].hasError('required')"
              >{{'v2_pinObl' | translate}}
              <!--# El pin es obligatorio--></span
            >
            <span *ngIf="myForm2.controls['pin1'].hasError('pattern')"
              >{{'v2_pinNum' | translate}}
              <!--# El pin debe contener sólo números--><br
            /></span>
            <span *ngIf="myForm2.controls['pin1'].hasError('maxlength')"
              >{{'v2_pinNum1' | translate}}
              <!--# El pin debe contener 4 números--></span
            >
            <span *ngIf="myForm2.controls['pin1'].hasError('minlength')"
              >{{'v2_pinNum1' | translate}}
              <!--# El pin debe contener 4 números--></span
            >
          </div>
        </div>
        <div class="myWrapInput">
          <ion-item
            [class.error]="!myForm2.controls['repin1'].valid && myForm2.controls['repin1'].touched"
            class="cdr-item-input"
            lines="none"
          >
            <ion-input
              labelPlacement="floating"
              label="{{'GetCardRepeatPin' | translate}}"
              class="cdr-input-floating"
              type="password"
              [counter]="false"
              [maxlength]="4"
              formControlName="repin1"
            ></ion-input>
          </ion-item>

          <div class="labelError" [hidden]="myForm2.controls['repin1'].valid || !myForm2.controls['repin1'].touched">
            <span *ngIf="myForm2.controls['repin1'].hasError('required')"
              >{{'v2_pinObl' | translate}}
              <!--# El pin es obligatorio--></span
            >
            <span *ngIf="myForm2.controls['repin1'].hasError('pattern')"
              >{{'v2_pinNum' | translate}}
              <!--# El pin debe contener sólo números--><br
            /></span>
            <span *ngIf="myForm2.controls['repin1'].hasError('maxlength')"
              >{{'v2_pinNum1' | translate}}
              <!--# El pin debe contener 4 números--></span
            >
            <span *ngIf="myForm2.controls['repin1'].hasError('minlength')"
              >{{'v2_pinNum1' | translate}}
              <!--# El pin debe contener 4 números--></span
            >
            <span *ngIf="myForm2.controls['repin1'].hasError('same')">
              {{'v2_errorPin' | translate}}
              <!--# El PIN no coincide--></span
            >
          </div>
        </div>
        <div
          class="myWrapLegal"
          [class.error]="!myForm2.controls['legal1'].value && myForm2.controls['legal1'].touched"
        >
          <ion-item lines="none" class="paddingl16">
            <ion-checkbox labelPlacement="end" formControlName="legal1">.</ion-checkbox>
            <!--[innerHTML]="'v2_acpTerm' | translate"-->
            <ion-label>
              Es necesario aceptar los
              <a [href]="urlCondiciones" target="_blank">términos y condiciones</a>
            </ion-label>
          </ion-item>
          <div class="labelError" [hidden]="myForm2.controls['legal1'].value || !myForm2.controls['legal1'].touched">
            {{'error_form_check_ct' | translate}}
          </div>
        </div>
        <div class="sendForm">
          <ion-button expand="full" (click)="sendPin1()">
            {{'CodereCardToCheck' | translate}}
            <!--Vincular tarjeta-->
          </ion-button>
        </div>
      </ion-list>
    </form>
    <!-- end step2 -->

    <!-- step3 -->
    <form class="card-link-form" [formGroup]="myForm3" scroll="true" *ngSwitchCase="'step3'">
      <ion-list>
        <p>
          {{'GetCardLink' | translate}}
          <!--Tarjeta vinculada-->
        </p>
        <div class="myWrapInput">
          <div class="completeInput">{{cardNumber}}</div>
        </div>
        <p>
          {{'CoderecardPin' | translate}}<span class="required-text">*</span>
          <!--Inserta el Pin para tu nueva tarjeta*-->
        </p>
        <div class="myWrapInput">
          <ion-item
            [class.error]="!myForm3.controls['pin2'].valid && myForm3.controls['pin2'].touched"
            class="cdr-item-input"
            lines="none"
          >
            <ion-input
              labelPlacement="floating"
              label="Pin"
              type="password"
              [counter]="false"
              [maxlength]="4"
              formControlName="pin2"
            ></ion-input>
          </ion-item>

          <div class="labelError" [hidden]="myForm3.controls['pin2'].valid || !myForm3.controls['pin2'].touched">
            <span *ngIf="myForm3.controls['pin2'].hasError('required')"
              >{{'v2_pinObl' | translate}}
              <!--# El pin es obligatorio--></span
            >
            <span *ngIf="myForm3.controls['pin2'].hasError('pattern')"
              >{{'v2_pinNum' | translate}}
              <!--# El pin debe contener sólo números--><br
            /></span>
            <span *ngIf="myForm3.controls['pin2'].hasError('maxlength')"
              >{{'v2_pinNum1' | translate}}
              <!--# El pin debe contener 4 números--></span
            >
            <span *ngIf="myForm3.controls['pin2'].hasError('minlength')"
              >{{'v2_pinNum1' | translate}}
              <!--# El pin debe contener 4 números--></span
            >
          </div>
        </div>

        <div
          class="myWrapLegal"
          [class.error]="!myForm3.controls['legal2'].value && myForm3.controls['legal2'].touched"
        >
          <ion-item lines="none">
            <!--[innerHTML]="'v2_acpTerm' | translate"-->
            <ion-checkbox labelPlacement="end" formControlName="legal2">.</ion-checkbox>
            <ion-label>
              Es necesario aceptar los
              <a [href]="urlCondiciones" target="_blank">términos y condiciones</a>
            </ion-label>
          </ion-item>
          <div class="labelError" [hidden]="myForm3.controls['legal2'].value || !myForm3.controls['legal2'].touched">
            {{'error_form_check_ct' | translate}}
          </div>
        </div>

        <div class="sendForm">
          <ion-button expand="full" (click)="sendPin2()">
            {{'CodereCardToCheck' | translate}}
            <!--Vincular tarjeta-->
          </ion-button>
        </div>
      </ion-list>
    </form>
    <!-- end step3 -->

    <ion-list class="legal-list card-link-conditions">
      <ion-list-header>
        <ion-label>
          {{'CoderecardAdvantages' | translate | uppercase }}
          <!--VENTAJAS TARJETA CODERE-->
          <hr />
        </ion-label>
      </ion-list-header>

      <ion-item class="legal-item card-link-item" lines="none">
        <ion-label>
          <p>{{'CoderecardAdvantages01'|translate}}</p>
          <p>{{'CoderecardAdvantages02'|translate}}</p>
        </ion-label>
      </ion-item>
    </ion-list>
    <codere-footer></codere-footer>
  </ion-content>

  <!-- MEXICO -->
  <ion-content *ngIf="globalVars.licenseType === 4">
    <ion-card class="codere-card">
      <ion-card-header>
        <span>
          {{'CodereCardc' | translate | uppercase }}
          <!--TARJETA-->
        </span>
        <img src="assets/global/img/logo_cirpremia.png" alt="" />
      </ion-card-header>
    </ion-card>
    <form class="card-link-form" [formGroup]="myForm4" scroll="true">
      <ion-list>
        <div
          class="myWrapInput first"
          [class.has-error]="!myForm4.controls['mexicoNumTarjeta'].valid && myForm4.controls['mexicoNumTarjeta'].touched"
        >
          <ion-item
            [class.error]="!myForm4.controls['mexicoNumTarjeta'].valid && myForm4.controls['mexicoNumTarjeta'].touched"
            class="cdr-item-input"
            lines="none"
          >
            <ion-input
              labelPlacement="floating"
              label="{{'CodereCardToChecknumber' | translate}}"
              type="tel"
              [counter]="false"
              [maxlength]="20"
              formControlName="mexicoNumTarjeta"
            ></ion-input>
          </ion-item>
          <div
            class="labelError"
            [hidden]="myForm4.controls['mexicoNumTarjeta'].valid || !myForm4.controls['mexicoNumTarjeta'].touched"
          >
            <span *ngIf="myForm4.controls['mexicoNumTarjeta'].hasError('required')"
              >{{'error_form_requiered' | translate}}</span
            >
            <span *ngIf="myForm4.controls['mexicoNumTarjeta'].hasError('valid')"
              >{{'GetCardChecknumKO' | translate}}</span
            >
          </div>
        </div>
        <div class="sendForm">
          <ion-button expand="full" (click)="sendTarjetaMexico()">
            {{'tCont' | translate}}
            <!--Continuar-->
          </ion-button>
        </div>
      </ion-list>
    </form>
  </ion-content>
</ng-template>
