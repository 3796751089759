import { Injectable, inject } from "@angular/core";
import { AlertController, LoadingController } from "@ionic/angular";
import { C_NodeTicket } from "@models/C_ResponseTickets";
// import { C_MyBetsFilterOption } from '@models/C_MyBetsFilterOption';
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { TranslateService } from "@ngx-translate/core";
import { ParseMoney } from "@pipes/parseMoney";
import { BalancesService } from "@providers/BalancesService";
import { NewUserService } from "@providers/NewUserService";
import { TrackingService } from "@providers/TrackingService";
import { UserService } from "@providers/UserService";
import { NewBaseService } from "@providers/newBase.service";
import { DeviceService } from "@services/device.service";
import { SportService } from "@sports-services/sports.service";
import { Utils } from "@utils/Utils";
import { BehaviorSubject } from "rxjs";

@Injectable({ providedIn: "root" })
export class UtilsMyBetsService {
  arrTickets: Array<C_NodeTicket>;
  auxArrTickets: BehaviorSubject<C_NodeTicket[]>;

  filterDateSelected$: BehaviorSubject<any>;
  filterTypeSelected$: BehaviorSubject<any>;

  filtersDate$: BehaviorSubject<any[]>;
  filtersType$: BehaviorSubject<any[]>;

  filterDateCollapsed$: BehaviorSubject<boolean>;
  filterTypeCollapsed$: BehaviorSubject<boolean>;

  filtersDate: any[];
  filtersType: any[];

  openedTickets: string[] = [];
  token: string;
  showEmptyState = false;

  getLoadDetails$: BehaviorSubject<boolean>;
  isSectionMisApuestasPage$: BehaviorSubject<boolean>; // MisApuestasPage true
  counterTicketBets$: BehaviorSubject<number>;

  pushWinnings = false;

  globalVars!: GlobalVarsModel;

  alertCtrl = inject(AlertController);
  translate = inject(TranslateService);
  sportService = inject(SportService);
  userService = inject(UserService);
  utils = inject(Utils);
  balancesService = inject(BalancesService);
  trackingService = inject(TrackingService);
  myParseMoney = inject(ParseMoney);
  newUserService = inject(NewUserService);
  newBaseService = inject(NewBaseService);
  deviceService = inject(DeviceService);

  loadingControler = inject(LoadingController);
  loading: HTMLIonLoadingElement;
  spinnerIsOpen = false;

  isMobile = false;
  isDesktop = false;

  constructor() {
    this.isDesktop = this.deviceService.isDesktop();
    this.isMobile = this.deviceService.isMobile();
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
    this.arrTickets = [];

    setTimeout(() => {
      this.filtersDate = [
        { value: "2", text: this.translate.instant("histapu2d"), selected: false },
        { value: "7", text: this.translate.instant("histapu7d"), selected: true },
        { value: "15", text: this.translate.instant("histapu15d"), selected: false },
        { value: "30", text: this.translate.instant("last30days"), selected: false },
        { value: "lastmonth", text: this.translate.instant("lastmonth"), selected: false },
        { value: "90", text: this.translate.instant("histapu90d"), selected: false },
        { value: "365", text: this.translate.instant("histaptodas"), selected: false },
      ];

      this.filtersType = [
        { value: "All", text: this.translate.instant("histaptodas"), selected: false },
        { value: "4", text: this.translate.instant("histapabiertas"), selected: true },
        { value: "1", text: this.translate.instant("histapganadas"), selected: false },
        { value: "3", text: this.translate.instant("histapperdidas"), selected: false },
        { value: "5", text: this.translate.instant("histapcobrada"), selected: false },
        { value: "0", text: this.translate.instant("histapotras"), selected: false },
      ];

      this.isSectionMisApuestasPage$ = new BehaviorSubject(false);

      this.filtersDate$ = new BehaviorSubject(this.filtersDate);
      this.filtersType$ = new BehaviorSubject(this.filtersType);

      this.filterDateSelected$ = new BehaviorSubject(this.filtersDate[4]);
      this.filterTypeSelected$ = new BehaviorSubject(this.filtersType[1]);

      this.filterDateCollapsed$ = new BehaviorSubject(true);
      this.filterTypeCollapsed$ = new BehaviorSubject(true);

      this.getLoadDetails$ = new BehaviorSubject(true);
      this.counterTicketBets$ = new BehaviorSubject(0);
    }, 100);
  }

  setFiltersValues(dateValue: number, typeValue: number) {
    this.filterDateSelected$.next(this.filtersDate[dateValue]);
    this.filterTypeSelected$.next(this.filtersType[typeValue]);
    this.getMyBets();
  }

  setBetSlipFilters(value) {
    // value 0 is Asentado
    // value 1 is Abiertas

    let canLoadDetails: boolean;
    let filterType: any;
    let dateType: any;
    switch (value) {
      case 0:
        canLoadDetails = false;
        filterType = this.filtersType[0];
        dateType = this.filtersDate[0];

        break;
      case 1:
        canLoadDetails = true;
        filterType = this.filtersType[1];
        dateType = this.filtersDate[0];
        break;
    }

    this.getLoadDetails$.next(canLoadDetails);
    this.filterTypeSelected$.next(filterType);
    this.filterDateSelected$.next(dateType);
    this.arrTickets = [];
    this.getMyBets();
  }

  changeFilter(option: any, filterType: string) {
    setTimeout(() => {
      switch (filterType) {
        case "date":
          this.filterDateSelected$.next(option);
          break;
        case "type":
          this.filterTypeSelected$.next(option);
          break;
      }
      this.filterDateCollapsed$ = new BehaviorSubject(true);
      this.filterTypeCollapsed$ = new BehaviorSubject(true);
      this.showEmptyState = false;
      this.getMyBets();
    }, 10);
  }

  getMyBets() {
    this.showHideAutoSpinner();
    this.openedTickets = [];
    let date;
    let endDate;
    if (this.filterDateSelected$.value.value === "lastmonth") {
      date = new Date();
      date.setDate(1);
      date.setMonth(date.getMonth() - 1);
      endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      date = date.toISOString();
      endDate = endDate.toISOString();
    } else {
      date = new Date();
      date.setDate(date.getDate() - parseInt(this.filterDateSelected$.value.value));
      date = date.toISOString();
    }
    this.getMyBetsAzure(this.filterTypeSelected$.value.value, date, endDate);
  }

  getMyBetsAzure(type: string, date: string, endDate: string) {
    try {
      this.newUserService.getAuthToken().subscribe(
        (authData) => {
          if (this.globalVars.FEATURES.SPO_MisApuestasNewModelFeatureFlagEnabled) {
            // New Model
            this.token = authData.token;
            this.newUserService.getTicketsByDateOnAzureNewModel(type, date, endDate, this.token).subscribe(
              (data) => {
                this.setBetsData(data);
              },
              (err) => {
                this.hideSpinner();
                this.utils.showError(this.translate.instant("m_menu_serverFail"));
              },
              () => {},
            );
          } else {
            // Old Model
            this.token = authData.token;
            this.newUserService.getTicketsByDateOnAzure(type, date, endDate, this.token).subscribe(
              (data) => {
                this.setBetsData(data);
              },
              (err) => {
                this.hideSpinner();
                this.utils.showError(this.translate.instant("m_menu_serverFail"));
              },
              () => {},
            );
          }
        },
        (err) => {
          this.hideSpinner();
          this.utils.showError(this.translate.instant("m_menu_serverFail"));
        },
      );
    } catch (e) {
      this.hideSpinner();
      this.utils.showError(this.translate.instant("m_menu_serverFail"));
    }
  }

  setBetsData(data) {
    this.hideSpinner();
    this.showEmptyState = true;
    if (this.isSectionMisApuestasPage$.value) {
      if (this.globalVars.FEATURES.SPO_MisApuestasNewModelFeatureFlagEnabled) {
        // New Model
        const mappedDataArr: any[] = [];
        data.forEach((ticket) => {
          const mappedData = {
            DayOrderNr: ticket.DayOrderNr,
            TicketsDatetime: new Date(ticket.TicketsDatetime),
            betHistoryInfo: ticket.Tickets,
          };
          mappedDataArr.push(mappedData);
        });

        this.arrTickets = [...mappedDataArr];
      } else {
        // Old Model
        this.arrTickets = [...data];
      }
    } else if (this.isDesktop) {
      this.counterTicketBets$.next(0);
      let betsCounter = 0;
      const auxArray: any[] = [];
      data.map((ticket, index) => {
        if (betsCounter <= 7) {
          auxArray[index] = {
            DayOrderNr: ticket.DayOrderNr,
            TicketsDatetime: this.globalVars.FEATURES.SPO_MisApuestasNewModelFeatureFlagEnabled
              ? new Date(ticket.TicketsDatetime)
              : ticket.TicketsDatetime,
            betHistoryInfo: [],
          };
        }
        if (this.globalVars.FEATURES.SPO_MisApuestasNewModelFeatureFlagEnabled) {
          // New Model
          ticket.Tickets.map((betTile) => {
            // must cast date
            betTile.TicketDatetime = new Date(betTile.TicketDatetime);
            if (
              betsCounter <= 7 &&
              this.globalVars.rootScope.myBetsSelectedFilterType$.value !== undefined &&
              this.globalVars.rootScope.myBetsSelectedFilterType$.value !== null &&
              (this.globalVars.rootScope.myBetsSelectedFilterType$.value.value === 1 ||
                (this.globalVars.rootScope.myBetsSelectedFilterType$.value.value === 0 &&
                  betTile.TicketStatusNumber !== "4"))
            ) {
              auxArray[index].betHistoryInfo.push(betTile);
              ++betsCounter;
            }
          });
        } else {
          ticket.betHistoryInfo.map((betTile) => {
            if (
              betsCounter <= 7 &&
              (this.globalVars.rootScope.myBetsSelectedFilterType$.value.value === 1 ||
                (this.globalVars.rootScope.myBetsSelectedFilterType$.value.value === 0 &&
                  betTile.TicketStatusNumber !== "4"))
            ) {
              auxArray[index].betHistoryInfo.push(betTile);
              ++betsCounter;
            }
          });
        }
      });
      this.arrTickets = auxArray;
      const setCounter = betsCounter === 0 ? -1 : betsCounter;
      this.counterTicketBets$.next(setCounter);
    }
  }

  payOutTicket(params) {
    const ticketNr = params.ticketNumber;
    const amount = params.result.TicketDetails.Amount;
    const msg =
      this.translate.instant("CloseBet1") +
      " " +
      this.myParseMoney.transform((parseInt(amount) / 100).toString()) +
      " " +
      this.translate.instant("CloseBet2") +
      "<br />" +
      this.translate.instant("compapuestaP3") +
      ":" +
      ticketNr;
    this.trackingService.trackEvent([
      "EarlyCashOutCall",
      "",
      "",
      "Llamada a cerrar apuesta (EarlyCashOut)",
      "event",
    ]);
    this.utils.confirm(
      true,
      this.translate.instant("CloseBet"),
      msg,
      this.translate.instant("tCancel"),
      null,
      this.translate.instant("tAccept"),
      async () => {
        await this.utils.loader();
        this.newUserService.payoutBetTicket(ticketNr, parseInt(amount), false).subscribe(
          async (data) => {
            if (data.success) {
              this.getMyBets();
              this.globalVars.ticketStatusChanged = true;

              this.balancesService.refreshBalance();
              this.trackingService.trackEvent([
                "EarlyCashOutComplete",
                ticketNr,
                amount,
                "Cerrar apuesta completado  (EarlyCashOut)",
                "event",
              ]);
              await this.utils.closeLoader();
            } else {
              const texto = data.status + " (" + data.statuscode + ")";
              this.utils.showError(texto, () => {
                this.getMyBets();
              });
              this.trackingService.trackEvent([
                "EarlyCashOutFailure",
                ticketNr,
                amount,
                "Cerrar apuesta fallido (EarlyCashOut)",
                "event",
              ]);
              await this.utils.closeLoader();
            }
          },
          async (err) => {
            await this.utils.closeLoader();
            this.utils.showError("", async () => {
              this.getMyBets();
            });
          },
        );
      },
      false,
      "cashout-confirmation-alert",
    );
  }

  // Devuelve fechas a partir de las 00:00
  getDateToCompare(value: string) {
    const miDate = new Date(parseInt(value.slice(6, value.length - 2)));
    return new Date(miDate.getFullYear(), miDate.getMonth(), miDate.getDate()).getTime();
  }

  getTicket(dateCard: any): Promise<any> {
    let optionCall =
      this.globalVars.FEATURES.checkTicketFromAzureEnabled && !!this.token
        ? "getTicketInfoFromAzure"
        : "getTicketInfo";
    if (this.globalVars.FEATURES.SPO_MisApuestasNewModelFeatureFlagEnabled) {
      switch (optionCall.toLowerCase()) {
        case "getticketinfofromazure":
          optionCall = "getTicketInfoFromAzureNewModel";
          break;
        default:
          optionCall = "getTicketInfoNewModel";
          break;
      }
    }

    const ticket = dateCard.ticketNumber;
    const dateTicket = dateCard.dateTicket;
    if (this.openedTickets.find((ticketNumberOpened) => ticketNumberOpened === ticket)) return null;

    return this.newUserService[optionCall](ticket, this.token, true).subscribe(
      (data) => {
        if (data) {
          const currentDateTicket = this.arrTickets.findIndex((d) => d === dateTicket);
          if (currentDateTicket < 0) return;
          const selectedTicket: any = this.arrTickets[currentDateTicket].betHistoryInfo.find(
            (t) => t.TicketNumber === ticket,
          );
          if (!selectedTicket.TicketDetails && selectedTicket.TicketStatusNumber === "4") {
            selectedTicket.TicketDetails = {
              betEvents: this.globalVars.FEATURES.SPO_MisApuestasNewModelFeatureFlagEnabled
                ? data.BetEvent
                : data.BetHistoryInfo.betEvents,
              Status: data.Status,
              StatusCode: data.StatusCode,
              Amount: this.globalVars.FEATURES.SPO_MisApuestasNewModelFeatureFlagEnabled
                ? data.CashOutAmount
                : data.Amount,
            };
          }

          if (!this.globalVars.FEATURES.SPO_MisApuestasNewModelFeatureFlagEnabled) {
            // Old Model
            selectedTicket.betEvents = data.BetHistoryInfo.betEvents;
          }
          this.openedTickets.push(selectedTicket.TicketNumber);
          const newArrTickets = Object.assign([], this.arrTickets);
          this.arrTickets = newArrTickets;
          if (this.globalVars.FEATURES.SPO_MisApuestasNewModelFeatureFlagEnabled) {
            // New Model
            if (data.CashOutAmount !== 0 && data.TicketStatusNumber === "4") {
              this.trackingService.trackEvent([
                "EarlyCashOutAbility",
                "",
                "",
                "Cerrar apuesta disponible (EarlyCashOut)",
                "event",
              ]);
            } else if (data.CashOutAmount === 0 && data.TicketStatusNumber === "4") {
              this.trackingService.trackEvent([
                "EarlyCashOutNoAbility",
                "",
                "",
                "Cerrar apuesta no disponible (EarlyCashOut)",
                "event",
              ]);
            }
          } else {
            // Old Model
            if (data.BetHistoryInfo.CashOutAmount !== 0 && data.BetHistoryInfo.TicketStatusNumber === "4") {
              this.trackingService.trackEvent([
                "EarlyCashOutAbility",
                "",
                "",
                "Cerrar apuesta disponible (EarlyCashOut)",
                "event",
              ]);
            } else if (data.BetHistoryInfo.CashOutAmount === 0 && data.BetHistoryInfo.TicketStatusNumber === "4") {
              this.trackingService.trackEvent([
                "EarlyCashOutNoAbility",
                "",
                "",
                "Cerrar apuesta no disponible (EarlyCashOut)",
                "event",
              ]);
            }
          }
        }
        return;
      },
      (err) => {
        console.error("Error obtener ticket");
        return;
      },
    );
  }

  tryToGoToEvent(selection) {
    this.sportService.getGameByNodeId(selection.ResultsNr).subscribe((data) => {
      if (!data) {
        this.showDismiss("", "No hay mercados abiertos en este evento");
        return;
      }
      this.sportService.deepLinkToEvent(data);
    });
  }

  async showDismiss(title: string, msg: string) {
    const alertOptions = {
      enableBackdropDismiss: false,
      title: title,
      message: msg,
      buttons: [
        {
          role: "cancel",
          text: "OK",
          handler: () => {
            //nothing, this is just a dismiss
          },
        },
      ],
    };
    const alert = await this.alertCtrl.create(alertOptions);
    alert.present();
  }

  // Loading control
  async showHideAutoSpinner() {
    if (!this.spinnerIsOpen) {
      this.loading = await this.loadingControler.create();
      this.loading.present();
      this.spinnerIsOpen = true;
    }
  }

  async hideSpinner() {
    if (this.spinnerIsOpen) {
      await this.loadingControler.dismiss();
      this.spinnerIsOpen = false;
    }
  }
}
