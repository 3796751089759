import { inject, Injectable } from '@angular/core';
import { BaseServiceExt } from '@providers/BaseServiceExt';
import { DeviceService } from '@services/device.service';
import { map, Observable } from 'rxjs';
import { C_SportsEventLive } from '../models';
import { SportsConstants } from '../utils/sports.constants';
import { SportCommonService } from './sports.common.service';
import { SportHandleList } from '@utils/sportHandleList';
import { FastBetProvider } from '@providers/fast-bet.service';
import { C_CategoryInfo, C_GameGroupByName, C_Result, C_EventLive } from '@models/index';

@Injectable({ providedIn: 'root' })
export class SportLiveMarketService extends BaseServiceExt {
  deviceService = inject(DeviceService);
  sportsCommonService = inject(SportCommonService);
  fastBetService = inject(FastBetProvider);

  isDesktop: boolean;
  isMobile: boolean;

  //GameType property used for identify "Resultados Finales"
  public gametypes: number[] = [284, 114, 37, 82, 153, 192];
  public listOfGameTypesInCombinedMarket = [
    4, 7, 39, 148, 155, 175, 988, 990, 991, 993, 1064, 1065,
    // 1300, // This is a three axis market manually done.
    1330, 1391, 1392, 1393, 1394, 1606, 1647, 1648, 1649, 1650, 1667, 1668, 1669, 1804, 1805, 1806, 1807, 1808, 1809,
    1853
  ];
  regexHandicap = /[+-.0-9]+/g;
  regexTitles = /[a-zA-Z]+/;
  regexValues = /[0-9-.+-]+/;
  regexValuesLongResult = /[0-9-+.]+\d/g;

  constructor() {
    super();
    this.isDesktop = this.deviceService.isDesktop();
    this.isMobile = this.deviceService.isMobile();
  }

  getGamesLiveEvent2(args): Observable<any> {
    const url = `${this.globalVars.NAVIGATIONSERVICEBASEURL}${SportsConstants.getGamesLiveByCategoryInfoUrl}`;
    const headers = { language: this.globalVars.Idioma };
    const params: any = {};
    params.parentid = args.parentid;
    params.categoryInfoId = args.categoryInfoId;

    const resultIdsParams = args.nodeIdList?.length && args.nodeIdList.join(';');
    if (resultIdsParams) params.resultIds = resultIdsParams;
    return this.myGet(url, true, { headers, params }).pipe(
      map((data) => {
        if (!data || !data.Event || !data.CategoryInfos?.length) {
          throw Error('El evento no está activo en estos momentos.');
        }
        data.Event = C_SportsEventLive.parse(data.Event);
        data.Event.setHomeAndAway();
        data.Event.setDetailScoreboardType(data.Event);
        data.Event.Games.forEach((game) => {
          this.sportsCommonService.getGameResultsOrdered(game);
          this.sportsCommonService.setLockedResults(data.Event);
        });

        let groupedMarkets = this.sportsCommonService.groupMarkets(data.Event.Games, args.leagueName);

        const alternatives = ['nba', 'nfl'];
        if (args.leagueName && alternatives.indexOf(args.leagueName.toLowerCase()) !== -1) {
          groupedMarkets = this.sportsCommonService.doAlternatives(data.Event.Games, groupedMarkets);
        }

        groupedMarkets.forEach((grouped) => {
          grouped.sort();
        });

        data.Event.Games = groupedMarkets.sort((a, b) => b.Priority - a.Priority);
        return data;
      })
    );
  }

  getBetsenseData(NodeId) {
    const url = `https://ctrl2.betsense.io/api/1/7/1/delivery/events/es/${NodeId}`;
    return this.myGet2(url);
  }

  updateOddChanges(prev: any, current: any, field: string = 'Results') {
    if (!prev || !current) {
      return;
    }
    field = current[field] ? field : 'GroupResults';
    for (const i in current[field]) {
      if (!prev[field] || !prev[field][i] || !current[field][i]) {
        return;
      }
      current[field][i].upOdd = current[field][i].Odd > prev[field][i].Odd;
      current[field][i].downOdd = current[field][i].Odd < prev[field][i].Odd;
    }
    return current;
  }

  setEvents(event, filter, market) {
    //Order soccer "Resultado Final" in three sub-arrays
    event.Games = this.orderThreeColumns(event.Games);
    if (!filter || (Boolean(filter) && filter.CategoryId === SportsConstants.NineHundredNinetyNineCategoryId)) {
      return;
    }
    /* combined markets */
    //Fastbet
    if (this.isTennisEvent(event) && filter.CategoryId == SportsConstants.FastbetCategoryId) {
      event.Games = this.getFastBets(event, market);
      return event;
    }

    const filteredMarkets = event.Games.filter((game) => {
      switch (true) {
        case !game.CategoryInfos:
          return false;
        case game.CategoryInfos.length > 0 && filter.CategoryId != SportsConstants.BetbuilderCategoryId:
          return game.CategoryInfos.find((category) => category.CategoryId === filter.CategoryId);
        case filter.CategoryId == SportsConstants.BetbuilderCategoryId:
          return game.SmartMarketAvailable;
        default:
          return filter.CategoryId === SportsConstants.PrincipalesCategoryId;
      }
    });

    event.Games.forEach((game, index) => {
      game = {
        ...game,
        teamHome: market.teamHome,
        teamAway: market.teamAway,
        nameLeague: market.LeagueName
      };
      game.GroupResults.forEach((r) => {
        r.Spov = game.Spov;
      });

      this.getAllSpovesInMarket(filteredMarkets, game);
    });
    this.getCombinedMarkets(event.Games, market, true);

    event.Games = filteredMarkets;
    if (event.SportHandle === 'tennis') {
      event.Games = event.Games.filter((game) => !game.Locked);
    }
    return event;
  }

  public getCombinedMarkets(markets, actualMarket, skipCall = false) {
    if (this.globalVars.FEATURES.ListOfGameTypesInCombinedMarket) {
      this.listOfGameTypesInCombinedMarket = JSON.parse(this.globalVars.FEATURES.ListOfGameTypesInCombinedMarket);
    }
    const updatedMarkets = markets.map((market) => {
      if (!market.GroupResults) return;
      const idCombinedMarket = this.listOfGameTypesInCombinedMarket.find((idMarket) => idMarket === market.GameType);
      market = {
        ...market,
        teamAway: market.teamAway ? market.teamAway : actualMarket.teamAway,
        teamHome: market.teamHome ? market.teamHome : actualMarket.teamHome
      };
      if (idCombinedMarket) {
        market = {
          ...market,
          isCombined: true,
          displayMode: 'single',
          headerClass: 'sb-grid-header--single',
          DisplayTypeName: '3buttonlist',
          Locked: actualMarket.Locked ? actualMarket.Locked : market.Locked
        };

        this.getCombinedMarketMatrix(idCombinedMarket, market, actualMarket, skipCall);
      } else {
        market.isCombined = false;
        market.displayMode = 'expanded';
      }
      return market;
    });
    return updatedMarkets;
  }

  //Order Resultados Finales in three colummns: local, tie and visitant
  private orderThreeColumns(markets) {
    markets.forEach((item) => {
      const isFinal = this.gametypes.indexOf(item.GameType) != -1;
      if (isFinal) {
        const local = [];
        const tie = [];
        const visitant = [];
        let a;
        let b;
        let tmp = '';
        item.GroupResults.forEach((it) => {
          tmp = it.Name.split(':');
          a = parseInt(tmp[0]);
          b = parseInt(tmp[1]);
          if (a > b) local.push(it);
          else if (b > a) visitant.push(it);
          else tie.push(it);
        });
        item.GroupResults = [local, tie, visitant];
      }
    });
    return markets;
  }

  // LIVE MARKET CREATION
  private getCombinedMarketMatrix(idCombinedMarket, market, actualMarket, skipCall: boolean = false) {
    let matrixGrid: number[] = [];
    switch (idCombinedMarket) {
      case 39:
      case 175:
      case 148:
      case 1065:
      case 991:
      case 1667:
      case 1668:
      case 1669:
      case 1805:
      case 1806:
      case 1804:
      case 1394:
        matrixGrid = [0, 2, 4, 1, 3, 5];
        this.getCombinedMarketMatchResultAndTotalOverUnder(market, matrixGrid, skipCall);
        break;
      case 7:
        matrixGrid = [0, 1, 2, 3, 4, 5, 7, 6, 8];
        this.getCombinedMarketMatchResultAndFirstTeamScore(market, matrixGrid);
        break;
      case 988:
      case 990:
        matrixGrid = [0, 2, 4, 1, 3, 5];
        this.getCombinedMarketMatchResultAndFirstTeamScore(market, matrixGrid);
        break;
      case 1064:
        matrixGrid = [0, 2, 4, 1, 3, 5];
        this.getCombinedMarketMatchResultAndFirstTeamScore(market, matrixGrid);
        break;
      case 1330:
      case 1809:
      case 1807:
      case 1808:
        matrixGrid = [0, 2, 1, 3];
        this.getCombinedMarketLineMatchAndTotal(market, matrixGrid, actualMarket);
        break;
      case 1391:
      case 1392:
      case 1393:
        matrixGrid = [0, 1, 2, 3];
        this.getCombinedMarketLineMatchAndTotal(market, matrixGrid, actualMarket);
        break;
      case 1647:
      case 1648:
      case 1649:
      case 1650:
      case 1606 /* especial basket case */:
        /*handicap*/
        matrixGrid = [0, 2, 1, 3];
        this.getCombinedMarketLineMatchAndTotal(market, matrixGrid, actualMarket);
        break;
      case 993:
        matrixGrid = [0, 2, 1, 3];
        this.getCombinedMarketHandicap(market, matrixGrid, actualMarket);
        break;
      case 4:
      case 155:
        matrixGrid = [0, 1, 2, 3];
        this.getCombinedMarketOneXTwoHandicap(market, matrixGrid, actualMarket);
        break;
      case 1853:
        matrixGrid = [0, 2, 1, 3];
        this.getCombinedMarketHandicapBasket(market, matrixGrid, actualMarket);
        break;
      default:
        return;
    }
  }

  private getCombinedMarketHandicapBasket(market, matrix: number[], actualMarket) {
    market.isHardCombined = false;
    market.isSoftCombined = true;
    market.titleCenter = '';
    market.DisplayTypeName = '2buttonline';
    market.teamAway = actualMarket.teamAway;
    market.teamHome = actualMarket.teamHome;
    this.getTotalsAndHandicaps(market);
    this.getGridWithFourPositions(market, matrix);
  }

  private getCombinedMarketOneXTwoHandicap(market: any, matrix: number[], actualMarket: any) {
    market.isHardCombined = false;
    market.isCombined = false;
    market.isSoftCombined = false;
    market.isHandicap = true;
    market.titleCenter = `X(${this.getShortName(market.teamAway)})`;
    market.DisplayTypeName = '3buttonlist';
    market.displayMode = 'expanded';
    market.headerClass = 'sb-grid-header';
    market.teamHome = this.getShortName(market.teamHome);
    market.teamAway = this.getShortName(market.teamAway);
    market.GroupResults.forEach((result: any) => {
      if (!result.Name || !result.Name.match(this.regexValuesLongResult)) {
        return;
      }
      const [namesOne, nameTwo] = result.Name.match(this.regexValuesLongResult);
      result.Name = nameTwo ? nameTwo : namesOne;
    });
  }

  private getCombinedMarketHandicap(market: any, matrix: number[], actualMarket: any) {
    market.isHardCombined = false;
    market.isSoftCombined = true;
    market.titleCenter = '';
    market.DisplayTypeName = '2buttonline';
    market.teamAway = actualMarket.teamAway;
    market.teamHome = actualMarket.teamHome;
    this.getTotalsAndHandicaps(market);
    this.getGridWithFourPositions(market, matrix);
  }

  private getCombinedMarketMatchResultAndTotalOverUnder(market: any, matrix?: number[], skipCall: boolean = false) {
    market.titleCenter = 'X';
    market.teamHome = '1';
    market.teamAway = '2';
    market.isHardCombined = true;
    market.isSoftCombined = false;
    //eslint-disable-next-line
    market.Locked = market.Locked;
    if (market.GameType === 1065) {
      market.titleCenter = '1/2';
      market.teamHome = '1/X';
      market.teamAway = '2/X';
    }
    if (!skipCall) {
      this.getTotalSpovsList(market);
      this.getGridWithSixPositions(market, matrix);
    }
  }

  private getCombinedMarketLineMatchAndTotal(market, matrix: number[], actualMarket) {
    market.isHardCombined = false;
    market.isSoftCombined = true;
    market.titleCenter = '';
    market.DisplayTypeName = '2buttonline';
    // eslint-disable-next-line no-self-assign
    market.Locked = market.Locked;

    if (market.GameType === 1330 || market.GameType === 1853) {
      this.getTotalSpovsList(market);
      market.teamHome = `${this.getShortName(actualMarket.teamHome)}`;
      market.teamAway = `${this.getShortName(actualMarket.teamAway)}`;
    } else {
      this.getTotalsAndHandicaps(market);
    }
    this.getGridWithFourPositions(market, matrix);
  }

  private getCombinedMarketMatchResultAndFirstTeamScore(market: any, matrix: number[]) {
    market.titleCenter = '1/2';
    market.teamHome = '1/X';
    market.teamAway = '2/X';
    //eslint-disable-next-line
    market.Locked = market.Locked;
    if (market.GameType === 7 || market.GameType === 988 || market.GameType === 990) {
      market.titleCenter = 'X';
      market.teamHome = '1';
      market.teamAway = '2';
      market.DisplayTypeName = '3buttonlist';
    }
    market.isHardCombined = false;
    market.isSoftCombined = true;
    market.AllSpov = this.getAllSpovsWithOutSpovs(market);
    this.getGridWithSevenPositions(market, matrix);
  }

  private getShortName(name: string) {
    return name.replace(/\s/g, '').slice(0, 3).toUpperCase();
  }

  private getTotalsAndHandicaps(market) {
    const keysObject = {};
    const newArraySpovs = [];
    market.spovListOfKeys = {};

    const otherSpoves = market.Spov.split('|');
    otherSpoves.forEach((key) => {
      if (key === '') {
        return;
      }
      keysObject[key.match(this.regexTitles)[0]] = key.match(this.regexValues)[0];
    });
    market.spovListOfKeys = keysObject;
    market.spovListOfKeys.realHcap = market.spovListOfKeys.Hcap;
    if (Math.sign(parseFloat(market.spovListOfKeys.Hcap)) === -1) {
      market.spovListOfKeys.Hcap = parseFloat(market.spovListOfKeys.Hcap) * -1;
    }

    if (
      market.GameType === 993 ||
      market.GameType === 1606 ||
      market.GameType === 1648 ||
      market.GameType === 1649 ||
      market.GameType === 1650 ||
      market.GameType === 1853
    ) {
      if (market.GameType == 1853) {
        market.teamHome = `${this.getShortName(market.teamHome)} `;
        market.teamAway = `${this.getShortName(market.teamAway)} `;
        newArraySpovs.push(`Más ${market.spovListOfKeys.Spov}`);
        newArraySpovs.push(`Menos ${market.spovListOfKeys.Spov}`);
      } else {
        market.teamHome = `${this.getShortName(market.teamHome)} ${this.getSymbol(
          parseFloat(market.spovListOfKeys.realHcap)
        )} ${parseFloat(market.spovListOfKeys.Hcap)}`;
        market.teamAway = `${this.getShortName(market.teamAway)} ${this.getSymbol(
          -1 * parseFloat(market.spovListOfKeys.realHcap)
        )} ${parseFloat(market.spovListOfKeys.Hcap)}`;
        newArraySpovs.push(`Más ${market.spovListOfKeys.Totals}`);
        newArraySpovs.push(`Menos ${market.spovListOfKeys.Totals}`);
      }
    } else if (market.GameType === 1647) {
      newArraySpovs.push(
        `${this.getShortName(market.teamHome)} ${this.getSymbol(
          parseFloat(market.spovListOfKeys.realHcap)
        )} ${parseFloat(market.spovListOfKeys.Hcap)}`
      );
      newArraySpovs.push(
        `${this.getShortName(market.teamAway)} ${this.getSymbol(
          -1 * parseFloat(market.spovListOfKeys.realHcap)
        )} ${parseFloat(market.spovListOfKeys.Hcap)}`
      );
    } else {
      newArraySpovs.push(`Más ${parseFloat(market.spovListOfKeys.Hcap)}`);
      newArraySpovs.push(`Menos ${parseFloat(market.spovListOfKeys.Hcap)}`);
    }

    market.AllSpov = newArraySpovs;
  }

  private getGridWithFourPositions(market: any, matrix: any) {
    market.combinedResults = [];
    market.AllSpov.forEach((spov: any, indexSpov: any) => {
      const row: any = [];
      matrix.forEach((matrixOrder: any, indexMatix: any) => {
        let current = market.GroupResults.find((m: any) => m.SortOrder === matrixOrder);
        if (!current) {
          current = this.declareNewResultMissing();
        }
        if (row.length < 2 && indexSpov === 0 && indexMatix < 2) {
          row.push(current);
        }
        if (row.length < 2 && indexSpov === 1 && indexMatix < 4 && indexMatix >= 2) {
          row.push(current);
        }
      });
      if (row.length > 0) market.combinedResults.push({ results: row, spov: spov });
    });
  }

  private getTotalSpovsList(market: any) {
    const newArraySpovs: any = [];
    market.AllSpov = [];
    let actualSpov: any;
    market.GroupResults.forEach((result: any) => {
      if (actualSpov != result.Spov) {
        market.AllSpov.push(result.Spov);
        actualSpov = result.Spov;
      }
    });

    market.AllSpov.forEach((spov: any) => {
      const otherSpoves = spov.split('|');
      otherSpoves.forEach((key: any) => {
        if (market.GameType === 1330) {
          newArraySpovs.push(`Más ${key.match(this.regexValues)[0]}`);
          newArraySpovs.push(`Menos ${key.match(this.regexValues)[0]}`);
        } else {
          newArraySpovs.push(key.match(this.regexValues)[0]);
        }
      });
    });
    market.AllSpov = newArraySpovs;
  }

  private getGridWithSixPositions(market: any, matrix: Array<number>) {
    market.combinedResults = [];

    market.AllSpov.forEach((spov: any) => {
      const filteredResults = market.GroupResults.filter(
        (result: any) => result.Spov.substr(result.Spov.indexOf('>') + 1) == Math.abs(parseFloat(spov)).toString()
      );

      const rowInFirstPositionGrid: any = [];
      const rowInSecondPositionGrid: any = [];
      matrix.forEach((matrixOrder) => {
        let current = filteredResults.find((m: any) => m.SortOrder === matrixOrder);
        if (!current) {
          current = this.declareNewResultMissing();
        }
        //current.title = spov;
        if (matrixOrder % 2 == 0) {
          rowInFirstPositionGrid.push(current);
        } else {
          rowInSecondPositionGrid.push(current);
        }
      });
      const arrOfRows = [rowInFirstPositionGrid, rowInSecondPositionGrid];
      market.combinedResults.push({
        results: arrOfRows,
        spov: this.getFinalSpovsByMarketName(market.Name, spov, market.GameType)
      });
    });
  }

  private getAllSpovsWithOutSpovs(market: any) {
    if (market.GameType === 988 || market.GameType === 990 || market.GameType === 1064) {
      return ['Si', 'No'];
    }
    //Todo : this crash
    try {
      return [market.GroupResults[2].Name.split(' y ')[0], market.GroupResults[2].Name.split(' y ')[1], 'Sin Goles'];
    } catch {
      return ['1', '2', 'Sin Goles'];
    }
  }

  private getGridWithSevenPositions(market: any, matrix: any) {
    market.combinedResults = [];
    market.AllSpov.forEach((spov: any, indexSpov: any) => {
      const row: any = [];
      this.getRowsOfGridInSevenPositions(matrix, market, row, indexSpov);
      if (row.length > 0) market.combinedResults.push({ results: row, spov: spov });
    });
  }

  private getRowsOfGridInSevenPositions(matrix: any, market: any, row: any[], indexSpov: any) {
    matrix.forEach((matrixOrder: any, indexMatix: any) => {
      let current = market.GroupResults.find((m: any) => m.SortOrder === matrixOrder);
      if (!current) {
        current = this.declareNewResultMissing();
      }
      if (row.length < 3 && indexSpov === 0 && indexMatix < 3) {
        row.push(current);
      }
      if (row.length < 3 && indexSpov === 1 && indexMatix < 6 && indexMatix >= 3) {
        row.push(current);
      }
      if (row.length < 3 && indexSpov === 2 && indexMatix >= 6 && matrix.length > 7) {
        row.push(current);
      }
    });
  }

  public declareNewResultMissing(): C_Result {
    return new C_Result('-', null, null, null, null, null, null, null, null, null, null, null);
  }

  isTennisEvent(liveMarket) {
    return liveMarket.SportHandle == SportHandleList.tennis;
  }

  // Fast bets methods
  private getFastBets(liveMarket: C_EventLive, actualMarket) {
    let fastBet;
    if (this.isTennisEvent(liveMarket)) {
      const ev = this.fastBetService.parseLiveDataGames(liveMarket);
      if (!ev) {
        return;
      }
      const fastGames = [];
      this.fastBetService.fastBetMarkets(ev, actualMarket).forEach((bet) => {
        if (bet.Games.length < 1) {
          return;
        }
        bet.headerClass = 'sb-grid-header--single';
        const titles = this.fastBetService.getGridTitleHeader(bet);
        const fastBetsGames = [];
        let orderZero: C_Result[];
        let orderOne: C_Result[];
        let isSoftCombined = false;
        if (bet.Games.length > 1) {
          [orderZero, orderOne] = this.getResultsFastBet(bet.Games);
          fastBetsGames.push(orderZero);
          fastBetsGames.push(orderOne);
        } else {
          isSoftCombined = true;
          [orderZero, orderOne] = this.getResultsFastBet(bet.Games);
          fastBetsGames.push(orderZero);
          fastBetsGames.push(orderOne);
        }
        const namesOfTeams = this.getTeamsName(bet);
        fastBet = this.getFastBetTennis(bet, fastBetsGames, namesOfTeams, titles, liveMarket);
        if (this.checkFastBetMarketIsValid(fastBet)) {
          this.lockInvalidFastBetGameTypeIds(fastBet);
          fastGames.push(fastBet);
          return fastGames;
        }
      });
    }
  }

  private lockInvalidFastBetGameTypeIds(fastBet) {
    fastBet.GroupResults.forEach((group) => {
      if (group.GameTypeId !== 1636 && group.GameTypeId !== 1972) {
        group.Locked = true;
      }
    });

    fastBet.combinedResults.forEach((group) => {
      if (group.GameTypeId !== 1636 && group.GameTypeId !== 1972) {
        group.Locked = true;
      }
    });
  }

  private getTeamsName(bet: any): string[] {
    const teamNames = [bet.Name.split(' - ')[0], bet.Name.split(' - ')[1]];
    return teamNames;
  }

  private getFastBetTennisName(bet: any): string {
    let name = `Mercados Rapidos`;

    // Name "Ganador del Punto 7 en el Juego 7 del Set 1"
    // "Ganador del juego (Juego 7 / Set 1)"

    if (bet.Games && bet.Games[0] && (bet.Games[0].GameType === 1636 || bet.Games[0].GameType === 1972)) {
      const setIndex = bet.Games[0].Name.indexOf('Set');
      const setString = bet.Games[0].Name.substring(setIndex, setIndex + 5);

      name += ` ${setString}`;
    }

    return name;
  }

  private getFastBetTennis(bet: any, fastBetsGames: any[], namesOfTeams: string[], titles: any, ev: any) {
    const name = this.getFastBetTennisName(bet);

    return C_GameGroupByName.parse({
      AllSpov: titles,
      Name: name,
      nameLeague: bet.Games[0].nameLeague,
      Priority: bet.Games[0].Priority,
      CategoryInfos: [
        {
          CategoryId: '0',
          CategoryName: 'MERCADOS RAPIDOS',
          IsRelevant: false
        }
      ],
      CategoryInfo: {
        CategoryId: '0',
        CategoryName: 'MERCADOS RAPIDOS',
        IsRelevant: false
      },
      DisplayTypeName: '2buttonline',
      teamAway: namesOfTeams[1],
      teamHome: titles.tileCenter,
      Locked: this.getEventIsLocked(ev, bet),
      titleTeams: 'Ganador',
      titleCenter: namesOfTeams[0],
      isSoftCombined: false,
      isHardCombined: false,
      isSpecialMarket: false,
      isCombined: false,
      isFastGamesHidden: false,
      headerClass: 'sb-grid-header--single',
      displayMode: 'expanded',
      GroupResults: fastBetsGames[0],
      combinedResults: fastBetsGames[1]
    });
  }

  private getEventIsLocked(ev: any, bet: any): boolean {
    let isEventLocked: boolean = false;
    if (ev.Locked) {
      return ev.Locked;
    }
    bet.Games.forEach((game) => {
      if (game.Locked) {
        isEventLocked = game.Locked;
      }
    });

    return isEventLocked;
  }

  private checkFastBetMarketIsValid(fastBet): boolean {
    let isValid = false;
    fastBet.GroupResults.forEach((r) => {
      if (r.SortOrder) {
        isValid = true;
      }
    });

    fastBet.combinedResults.forEach((r) => {
      if (r.SortOrder) {
        isValid = true;
      }
    });
    return isValid;
  }

  getResultsFastBet(Games: any): [C_Result[], C_Result[]] {
    const orderZero: C_Result[] = [];
    const orderOne: C_Result[] = [];
    Games.forEach((game) => {
      if (!game?.GroupResults || !game?.GroupResults.length) {
        orderZero.push(this.declareNewResultMissing());
        orderOne.push(this.declareNewResultMissing());
        return;
      }
      game?.GroupResults.forEach((res) => {
        if (res.SortOrder === 0) {
          orderZero.push(res);
          return;
        }
        orderOne.push(res);
      });
    });
    return [orderZero, orderOne];
  }

  private getFinalSpovsByMarketName(name: any, spov: any, gameType: number): string[] {
    const expressionPrefix = /([A-Za-z/á]+[/]+[A-Za-z]+)/g;
    let spovPrefixList = name.match(expressionPrefix) ? name.match(expressionPrefix)[0].split('/') : '';
    if (gameType == 39 && name == '1X2 y Total de Goles') {
      spovPrefixList = ['Más', 'Menos'];
    }
    return [
      `${spovPrefixList[0]} ${spov.substr(spov.indexOf('>') + 1)}`,
      `${spovPrefixList[1]}  ${spov.substr(spov.indexOf('>') + 1)}`
    ];
  }

  public getAllSpovesInMarket(groupedMarkets: any[], market: any) {
    if (!groupedMarkets[groupedMarkets.length - 1]) return;
    groupedMarkets[groupedMarkets.length - 1].AllSpov.push(market.Spov);
  }

  getSymbol(hCap: number) {
    return Math.sign(hCap) > 0 ? '+' : '-';
  }

  public getMarketGoalScorer(
    groupedMarkets: C_GameGroupByName[],
    actualMarket: C_GameGroupByName,
    skipCall = false
  ): C_GameGroupByName[] {
    if (actualMarket.SportHandle !== 'soccer' || skipCall) {
      return groupedMarkets;
    }
    const listOfIdsGoalMarkets = [74, 75, 76];
    const listOfMarketsAvailable = [];
    let listOfScorerMarkets: any = [];
    listOfIdsGoalMarkets.forEach((idMarket) => {
      const scorerMarket = groupedMarkets.find((market) => market.GameType === idMarket);
      if (scorerMarket) {
        //this.getGoalScorersOrder(idMarket, scorerMarket);
        scorerMarket.position = idMarket;
        listOfScorerMarkets.push(scorerMarket);
        listOfMarketsAvailable.push(idMarket);
      } else {
        const aux = C_GameGroupByName.parse({ GameType: idMarket });
        aux.position = idMarket;
        listOfScorerMarkets.push(aux);
      }
    });

    if (!listOfMarketsAvailable.length) {
      return groupedMarkets;
    }

    if (listOfMarketsAvailable.length < 3) {
      listOfScorerMarkets = this.getMissingScoreMarkets(listOfScorerMarkets);
    }

    groupedMarkets = this.deleteMarketsUseless(groupedMarkets, listOfIdsGoalMarkets);

    const newMarketScore: C_GameGroupByName = this.getGoalScoreMarket(listOfScorerMarkets, actualMarket);
    this.getNewScoreMarket(groupedMarkets, newMarketScore);

    return groupedMarkets;
  }
  private getNewScoreMarket(groupedMarkets: C_GameGroupByName[], newMarketScore: C_GameGroupByName) {
    let positionHatTrickMarket;
    groupedMarkets.forEach((market, index) => {
      if (market.GameType === 149) {
        positionHatTrickMarket = index;
      }
    });
    if (positionHatTrickMarket) {
      groupedMarkets.splice(positionHatTrickMarket, 0, newMarketScore);
    } else {
      groupedMarkets.push(newMarketScore);
    }
  }
  private getMissingScoreMarkets(listOfScorerMarkets: C_GameGroupByName[]) {
    const ghostMarket = C_GameGroupByName.parse({
      Name: 'Goleadores',
      GroupResults: []
    });
    const newMarket: any = [];

    listOfScorerMarkets.map((market) => {
      if (market === null) {
        newMarket.push(ghostMarket);
      } else {
        newMarket.push(market);
      }
    });
    return newMarket;
  }
  private deleteMarketsUseless(
    groupedMarkets: C_GameGroupByName[],
    listOfIdsGoalMarkets: number[]
  ): C_GameGroupByName[] {
    const listOfNoScorerMarkets: C_GameGroupByName[] = [];
    groupedMarkets.forEach((market) => {
      if (
        market.GameType !== listOfIdsGoalMarkets[0] &&
        market.GameType !== listOfIdsGoalMarkets[1] &&
        market.GameType !== listOfIdsGoalMarkets[2]
      ) {
        listOfNoScorerMarkets.push(market);
      }
    });
    return listOfNoScorerMarkets;
  }
  private getGoalScoreMarket(listOfScorerMarkets: any[], actualMarket: any): C_GameGroupByName {
    const listOfPlayerNames = this.getListOfPlayers(listOfScorerMarkets);
    const ListOfPlayersWithResults = this.getGoalScorersData(listOfPlayerNames, listOfScorerMarkets);
    const { categoryInfoScoreMarket, groupResults } = this.getCategoryAndGroupResults(listOfScorerMarkets);
    return this.prepareGoalScoreMarket(ListOfPlayersWithResults, actualMarket, categoryInfoScoreMarket, groupResults);
  }
  private prepareGoalScoreMarket(
    playerWithResultsList: any[],
    actualMarket: any,
    categoryInfoScoreMarket: any,
    groupResults: any[]
  ): C_GameGroupByName {
    return C_GameGroupByName.parse({
      Name: 'Goleadores',
      nameLeague: actualMarket.LeagueName,
      Priority: actualMarket.Priority,
      CategoryInfos: categoryInfoScoreMarket.CategoryInfos,
      CategoryInfo: categoryInfoScoreMarket.CategoryInfo,
      DisplayTypeName: '3buttonlist',
      teamAway: 'Ultimo',
      teamHome: 'Primer',
      titleCenter: 'Durante',
      isSoftCombined: true,
      isHardCombined: false,
      isSpecialMarket: false,
      isCombined: true,
      headerClass: 'sb-grid-header--single',
      displayMode: 'single',
      GroupResults: groupResults,
      combinedResults: playerWithResultsList
    });
  }
  private getCategoryAndGroupResults(listOfScorerMarkets: any[]) {
    const groupResults = [
      ...listOfScorerMarkets[0].GroupResults,
      ...listOfScorerMarkets[1].GroupResults,
      ...listOfScorerMarkets[2].GroupResults
    ];
    const categoryInfoScoreMarket = {
      CategoryInfos: listOfScorerMarkets[0].CategoryInfos,
      CategoryInfo: listOfScorerMarkets[0].CategoryInfo
    };
    return { categoryInfoScoreMarket, groupResults };
  }
  /* buscamos todos los nombres de los jugadores en los tres mercados,
   * quitamos lo valores repetidos y devolvemos la lista */
  private getListOfPlayers(listOfScorerMarkets: any[]) {
    const ListOfPlayersInMarkets: any = [];
    listOfScorerMarkets.forEach((game) => {
      game.GroupResults.forEach((result: any) => {
        ListOfPlayersInMarkets.push(result.Name);
      });
    });

    const listOfPlayerNames = Array.from(new Set(ListOfPlayersInMarkets));
    return listOfPlayerNames;
  }
  /*recorremos la lista de jugadores y la cruzamos con la lista de mercados y extraemos los resultados cuando coincidan con el nombre del jugador,
   * construimos el objeto que vamos autilizar en la vista y lo añadimos a la lista que vamos a devolver con los datos del mercado,
   * devolvemos el la lista con los jugadores y sus resultados asociados */
  /* si el resultado no ha sido encontrado declaramos uno vacio y le asignamos la posicion en funcion del gameType*/
  private getGoalScorersData(listOfPlayerNames: any[], listOfScorerMarkets: any[]) {
    const listOfPlayersWithResults: any = [];
    listOfPlayerNames.forEach((player: string) => {
      const resultsListByPlayerName: any = [];
      listOfScorerMarkets.forEach((market) => {
        const gameType = market.GameType;
        const resultByPlayerName = market.GroupResults.filter((result: any) => result.Name === player);
        if (!resultByPlayerName.length) {
          resultsListByPlayerName.push(this.getMissingResultInGoalScorers(gameType));
          return;
        }
        resultsListByPlayerName.push(resultByPlayerName[0]);
      });

      const newPlayerWithResultsList = {
        spov: player,
        results: resultsListByPlayerName
      };
      newPlayerWithResultsList.results = this.getOrderResultsInGoalScorers(newPlayerWithResultsList);
      listOfPlayersWithResults.push(newPlayerWithResultsList);
    });

    return listOfPlayersWithResults;
  }

  /* devolvemos el resultado ausente y le asignamos una posicion en funcion del id */
  private getMissingResultInGoalScorers(gameType: any): any {
    return { ...this.declareNewResultMissing(), ...{ position: this.getResultPositionInGoalScorer(gameType) } };
  }
  /*asignamos una posicion al mercado ausente*/
  private getResultPositionInGoalScorer(gameType): number {
    if (gameType === 74) {
      return 0;
      // eslint-disable-next-line no-dupe-else-if
    } else if (gameType === 74) {
      return 1;
    }
    return 2;
  }
  private getOrderResultsInGoalScorers(newPlayerWithResultsList: { spov: string; results: any[] }): any[] {
    /* ordered the results*/
    return newPlayerWithResultsList.results.sort((a, b) => a.position - b.position);
  }

  public isBetbuilderSoccerEvent(market) {
    const betbuilderGames = [];
    market.Games.forEach((i) => {
      if (i.SmartMarketAvailable) betbuilderGames.push(i);
    });
    return market.SportHandle === SportHandleList.soccer && betbuilderGames.length > 0;
  }

  public setLiveMarketCategories(data: any, market: any) {
    let cats = this.arrangeCategories(data.CategoryInfos, []);
    if (
      this.isBetbuilderSoccerEvent(data.Event) &&
      cats.length > 0 &&
      this.globalVars.FEATURES.SPO_LiveBetbuilderEnabled
    ) {
      cats = this.createBetbuilderFilterSoccer(cats);
    }
    return cats;
  }

  public createBetbuilderFilterSoccer(categories: C_CategoryInfo[]) {
    let newCategoriesArr: C_CategoryInfo[] = Array.from(categories);
    const principalesMarketsCat = categories.find((i) => i.CategoryId == SportsConstants.principalesCat);
    const betbuilderBetsCat = categories.find((i) => i.CategoryId == SportsConstants.betbuilderCatId);
    // const betbuilderBetsCat = new C_CategoryInfo(Constants.betbuilderCatId, false, Constants.betbuilderCatName);
    newCategoriesArr = newCategoriesArr.filter(
      (i) => i.CategoryId !== SportsConstants.principalesCat && i.CategoryId !== SportsConstants.betbuilderCatId
    );
    if (betbuilderBetsCat) newCategoriesArr.unshift(betbuilderBetsCat);
    if (principalesMarketsCat) newCategoriesArr.unshift(principalesMarketsCat);
    return newCategoriesArr;
  }
  arrangeCategories(categories: any[], previousCategories: C_CategoryInfo[]): C_CategoryInfo[] {
    previousCategories = categories.map((i) => {
      const newCat: C_CategoryInfo = new C_CategoryInfo(i.CategoryId, i.IsRelevant, i.CategoryName);
      newCat.numGames = 1;
      previousCategories.push(newCat);
      return newCat;
    });
    return previousCategories;
  }
}
