export class CResponseSession {
  CanPlay: boolean;
  Reason: string;
  LastSessionDate: string;
  Less60: boolean;
  DailyLimit: boolean;
  AutoExclusion: boolean;
  Title: string;
  SessionType: string;
  ElapsedTime: number;
}
