import { IonicModule, ModalController } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { Component, inject } from '@angular/core';
import { TrackingService } from '../../../providers/TrackingService';
import { GlobalVarsModel } from '../../../models/ModelGlobalsVar';
import { NewBaseService } from '../../../providers/newBase.service';
import { DeviceService } from '../../../services/device.service';
import { Capacitor } from '@capacitor/core';
import { CommonModule } from '@angular/common';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'add-friend-share-page',
  templateUrl: './add-friend-share.html',
  standalone: true,
  styleUrls: ['./add-friend-share.scss'],
  imports: [IonicModule, CommonModule, TranslateModule]
})
export class AddFriendSharePage {
  url: string;
  friendsID: string;
  textmsgTwitter: string;
  textmsgWhatsapp: string;
  textmsgMail: string;
  globalVars!: GlobalVarsModel;
  isMobile: boolean = false;
  isDesktop: boolean = Capacitor.getPlatform() === 'web';

  trackingService = inject(TrackingService);
  modalControl = inject(ModalController);
  newBaseService = inject(NewBaseService);
  device = inject(DeviceService);

  constructor() {
    this.isMobile = this.device.isMobile();

    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });

    this.friendsID = this.globalVars.friendsID;
    this.url = this.globalVars.FEATURES.urlRegisterLite;
    this.textmsgTwitter = encodeURIComponent(
      'Reg\u00EDstrate en Codere con mi c\u00F3digo ' +
        this.friendsID +
        ' Nos REGALAN 10EUR a cada uno! Reg\u00EDstrate desde aqu\u00ED ->  ' +
        this.url +
        '?friendsID=' +
        this.friendsID
    );
    this.textmsgWhatsapp = encodeURIComponent(
      'Que pasa t\u00EDo? Utiliza mi c\u00F3digo ' +
        this.friendsID +
        ' para registrarte en Codere, que nos REGALAN 10EUR a cada uno! Te paso el link: ' +
        this.url +
        '?friendsID=' +
        this.friendsID
    );
    this.textmsgMail =
      'Amigo! Los de Codere acaban de darme este c\u00F3digo ' +
      this.friendsID +
      ' Si lo pones al registrarte nos dan 10 pavos a cada uno... Ya no tienes excusa! Anda, pincha en el link y reg\u00EDstrate, que ganamos los dos -> ' +
      this.url +
      '?friendsID=' +
      this.friendsID;
    this.textmsgMail = encodeURIComponent(this.textmsgMail);
  }

  btClose_Click() {
    this.modalControl.dismiss();
  }

  shareOnWhatsapp() {
    this.trackingService.trackEvent(['ShareOnWhatsapp', '', '', 'Compartir AddFriends por whatsapp', 'event']);
    if (this.isDesktop) window.open('https://api.whatsapp.com/send?text=' + this.textmsgWhatsapp);
    else {
      if (this.globalVars.DEVICE_TYPE === 'cordova') {
        //
        window.open('whatsapp://send?text=' + this.textmsgWhatsapp, '_system');
      } else {
        if (this.globalVars.DEVICE_TYPE !== 'ANDROID')
          location.href = 'https://api.whatsapp.com/send?text=' + this.textmsgWhatsapp;
        else window.open('whatsapp://send?text=' + this.textmsgWhatsapp);
      }
    }
  }
  shareOnTwitter() {
    this.trackingService.trackEvent(['ShareOnTwitter', '', '', 'Compartir AddFriends por Twitter', 'event']);
    if (this.isDesktop) window.open('https://twitter.com/intent/tweet?text=' + this.textmsgTwitter);
    else {
      if (this.globalVars.DEVICE_TYPE === 'cordova') {
        //
        window.open('twitter://post?message=' + this.textmsgTwitter, '_system');
      } else {
        if (this.globalVars.DEVICE_TYPE !== 'ANDROID')
          location.href = 'https://twitter.com/intent/tweet?text=' + this.textmsgTwitter;
        else window.open('twitter://post?message=' + this.textmsgTwitter);
      }
    }
  }
  shareOnFacebook() {
    this.trackingService.trackEvent(['ShareOnFacebook', '', '', 'Compartir AddFriends por Facebook', 'event']);
    if (this.isDesktop) {
      window.open(
        encodeURI(
          'https://www.facebook.com/sharer/sharer.php?u=' +
            this.url +
            '?friendsID=' +
            this.friendsID +
            '&summary=Invitar Amigo&title=Codere Apuestas'
        ),
        'Añadir amigo'
      );
    } else {
      if (this.globalVars.DEVICE_TYPE === 'cordova') {
        //
        window.open(
          'fb-messenger://share?link=' + this.url + '?friendsID=' + this.friendsID + '&app_id=123456789',
          '_system'
        );
      } else {
        if (this.globalVars.DEVICE_TYPE !== 'ANDROID')
          location.href = encodeURI(
            'https://www.facebook.com/sharer/sharer.php?u=' +
              this.url +
              '?friendsID=' +
              this.friendsID +
              '&summary=Invitar Amigo&title=Codere Apuestas'
          );
        else
          window.open('fb-messenger://share?link=' + this.url + '?friendsID=' + this.friendsID + '&app_id=123456789');
      }
    }
  }
  shareOnMail() {
    this.trackingService.trackEvent(['ShareOnMail', '', '', 'Compartir AddFriends por Mail', 'event']);
    let emailstring;
    emailstring = 'mailto:?';
    emailstring = emailstring + '&subject=Invitacion a Codere';
    emailstring = emailstring + '&body=' + this.textmsgMail;
    if (this.globalVars.DEVICE_TYPE === 'cordova') {
      //
      window.open(emailstring, '_system');
    } else {
      location.href = emailstring;
    }
  }
}
