export interface CombinationMulticastInput {
  Sport: {
    Id: number;
    SportHandle: string;
  };
  Location: {
    Id: number;
  };
  League: {
    Id: number;
  };
  Event: {
    Id: number;
    HasHandicap: boolean;
  };
  PlacementSource: string;
  ClientData: string;
  MaxSystem: number;
  ResultIds: number[];
  Magnitude: number;
  TaxSettings: string;
  Game: {
    Id: number;
    GameTypeId: number;
    SpecialOddsValue: string;
    IsInPlay: boolean;
    BetTypeAvailability: number;
    NumberOfStarters: number;
    PlaceOddsDivisor: number;
    IncludedPlaces: number;
  };
}

export interface CombinationMulticastItem {
  Sport: {
    Id: number;
    SportHandle: string;
  };
  Location: {
    Id: number;
  };
  League: {
    Id: number;
  };
  Event: {
    Id: number;
    HasHandicap: true;
  };
  PlacementSource: string;
  ClientData: string;
  MaxSystem: number;
  ResultIds: number[];
  Magnitude: number;
  TaxSettings: string;
  Game: {
    Id: number;
    GameTypeId: number;
    SpecialOddsValue: string;
    IsInPlay: boolean;
    BetTypeAvailability: number;
    NumberOfStarters: number;
    PlaceOddsDivisor: number;
    IncludedPlaces: number;
  };
  // CombinationMulticastOdds: [
  //   {
  //     ResultIds: number[];
  //     Odds: number;
  //   }
  // ];
  ItemId: number;
  IsBanker: boolean;
  CanBeBanker: boolean;
  NumberOfBetWays: number;
}

export const mapCombinationMulticastResult = (combinationData, resultIds): CombinationMulticastInput => ({
  Sport: combinationData.Sport,
  Location: combinationData.Location,
  League: combinationData.League,
  Event: combinationData.Event,
  PlacementSource: '',
  ClientData: '',
  MaxSystem: combinationData.Magnitude == 2 ? 3 : 1, // defaults: Forecast=3, Tricast=1, Others=No Limit
  ResultIds: resultIds,
  Magnitude: combinationData.Magnitude,
  TaxSettings: '',
  Game: {
    Id: combinationData.Game.Id,
    GameTypeId: combinationData.Game.GameTypeId,
    SpecialOddsValue: combinationData.Game.SpecialOddsValue,
    IsInPlay: false,
    BetTypeAvailability: 0,
    NumberOfStarters: combinationData.Game.NumberOfStarters,
    PlaceOddsDivisor: 0,
    IncludedPlaces: 0
  }
  // CombinationMulticastOdds: [
  //   {
  //     ResultIds: resultIds,
  //     Odds: resultIds.length,
  //   },
  // ],
});
