import { NgZone, inject } from '@angular/core';
import { Injectable } from '@angular/core';
import { AlertController, ModalController, Platform } from '@ionic/angular';
import { Capacitor } from '@capacitor/core';
import { DeviceService } from '../services/device.service';

import { TrackingService } from './TrackingService';
import { EventsService } from './EventsService';
import { UserService } from './UserService';
import { GlobalVarsModel } from '../models/ModelGlobalsVar';
import { NewBaseService } from './newBase.service';
import { App } from '@capacitor/app';
import { Preferences } from '@capacitor/preferences';

import { Login } from '../pages/mso/login/login';
import { BiometricService } from './BiometricService';

import { AppVersionPopup } from '../pages/mso/app-version-popup/app-version-popup';
import { Message, RegisterOptions, XtremePush } from '@codere-plugins/xtremepush-capacitor';
import { Router } from '@angular/router';
import { Camera } from '@capacitor/camera';
import { CASINO_PATHS, MSO_PATHS, PAGES_PATHS } from '@shared-constants/routes';
import { Utils } from '@utils/Utils';
import { environment } from 'apps/codere-ionic/src/environments/environment.config';
// TODO ELIMINAR Comprobar importación
// import { AppVersion } from '@ionic-native/app-version';
// import { AppVersionPopup, HomePage, LobbyCasinoPage } from '../pages/index';
// import { Login } from '../pages/login/login';

@Injectable({ providedIn: 'root' })
export class NativeService {
  codigoScaneado: string;

  appNativeDevice: string;
  globalVars!: GlobalVarsModel;

  isIos: boolean;
  isAndroid: boolean;

  //#region injects
  zone = inject(NgZone);
  alertController = inject(AlertController);
  modalController = inject(ModalController);
  userService = inject(UserService);
  platform = inject(Platform);
  events = inject(EventsService);
  trackingService = inject(TrackingService);
  nbs = inject(NewBaseService);
  biometricService = inject(BiometricService);
  deviceService = inject(DeviceService);
  router = inject(Router);
  private utils = inject(Utils);
  //#endregion

  // #region javascript DOM global functions
  CodereNativeAppAndroid: any;
  CodereNativeAppIOS: any;
  // #endregion

  constructor() {
    this.isIos = Capacitor.getPlatform() === 'ios';
    this.isAndroid = Capacitor.getPlatform() === 'android';

    // private appVersion: AppVersion
    this.nbs.getVars.subscribe((data) => {
      this.globalVars = data;
    });
    this.codigoScaneado = '';
    if (!Capacitor.isNativePlatform()) {
      this.initPolling();
    }
  }

  initPolling() {
    //comprueba durante un tiempo si la app es xamarin o no porque hay una demora en el proceso
    const timeLimit = 5 * 1000;
    const curTime: number = new Date().getTime();
    const int = setInterval(() => {
      // TODO
      // updateNative();
      //**+** Revisar this.globalVars.DEVICE_TYPE = GLOBALS.DEVICE_TYPE;

      const now: number = new Date().getTime();
      if (now - curTime > timeLimit) {
        clearInterval(int);
        //ya ha terminado el poling
      }
    }, 500);
  }

  activateUrlChangerClick() {
    /*
      This function is setup by main.js from libs\shared\src\assets\js\buildMobile\main.js
      by modifying the prototype
    */
    window['activateChangerClick']();
  }

  scanTicket(callback): void {
    //
    let code = '';
    window['ReadBarCode'] = (barCode) => {
      if (isNaN(barCode.charAt(0))) {
        code = barCode.substr(1);
      } else {
        code = barCode;
      }
      this.zone.run(() => {
        callback(code);
      });
    };

    if (this.isAndroid) {
      try {
        window['CodereNativeAppAndroid'].scanCode('x28', 'ReadBarCode');
      } catch (e) {
        console.error(e);
      }
    } else if (this.isIos) {
      try {
        window.location.href = 'js2ios://scanCode?an=x28&cb=ReadBarCode';
        window['webkit'].messageHandlers.js2ios.postMessage('scanCode?an=x28&cb=ReadBarCode');
      } catch (e) {
        console.error(e);
      }
    }
  }

  scanTicketPlaySST(callback): void {
    window['ReadBarCodeSST'] = (barCode) => {
      this.zone.run(() => {
        callback(barCode);
      });
    };

    if (this.isAndroid) {
      window['CodereNativeAppAndroid'].scanCode('x28', 'ReadBarCodeSST');
    } else if (this.isIos) {
      window.location.href = 'js2ios://scanCode?an=x28&cb=ReadBarCodeSST';
      window['webkit'].messageHandlers.js2ios.postMessage('scanCode?an=x28&cb=ReadBarCodeSST');
    }
  }

  setLoggedUser(user: string, pass: string, bgtId: number): void {
    window['cbSetLoggedUser'] = (tag) => {
      if (tag.search('<') !== -1) {
        tag = tag.replace('<', '').replace('>', '');
        const tagA = tag.split(' ');
        tag = '';
        for (let i = 0; i < tagA.length; i++) {
          tag += tagA[i];
        }
      }
      this.zone.run(() => {
        this.saveDeviceId(tag);
      });
    };

    if (this.isAndroid) {
      try {
        window['CodereNativeAppAndroid'].setLoggedUser('x33', user, pass, bgtId, 'cbSetLoggedUser');
      } catch (e) {
        console.error(e);
      }
    } else if (this.isIos) {
      try {
        window.location.href =
          'js2ios://setLoggedUser?an=x33&q=' + user + '&pass=' + pass + '&bgtId=' + bgtId + '&cb=cbSetLoggedUser';
        window['webkit'].messageHandlers.js2ios.postMessage(
          'setLoggedUser?an=x33&q=' + user + '&pass=' + pass + '&bgtId=' + bgtId + '&cb=cbSetLoggedUser'
        );
      } catch (e) {
        console.error(e);
      }
    }
  }

  saveDeviceId(tag: string) {
    this.globalVars.rootScope.saveDeviceId(tag);
  }

  async checkTouchId(callbackAfterLogin?: (data: any, role: string) => void) {
    if (Capacitor.isNativePlatform() && this.biometricService.isBiometricAvailable) {
      try {
        const biometric = await this.biometricService.handleBiometricLogin();
        this.events.publish('user:cordovaTouchIdLogin', { user: biometric.username, password: biometric.password });
        if (callbackAfterLogin) callbackAfterLogin({}, '');
      } catch (error) {
        this.openLogin(callbackAfterLogin);
      }
    } else if (
      this.globalVars.FEATURES.IosTouchIdEnabled &&
      (this.isAndroid || this.isIos) &&
      this.globalVars.nativeIOSTouchIdAvailable
    ) {
      this.checkTouchIdCall();
    } else {
      this.openLogin(callbackAfterLogin);
    }
  }

  checkTouchIdCall(callbackAfterLogin?: (data: any, role: string) => void) {
    if (this.isAndroid) {
      try {
        window['CodereNativeAppAndroid'].checkTouchId('x28', 'checkTouchIdCallback');
      } catch (e) {
        this.openLogin(callbackAfterLogin);
      }
    } else if (this.isIos) {
      try {
        window.location.href = 'js2ios://checkTouchId?an=x28&cb=checkTouchIdCallback';
        try {
          window['webkit'].messageHandlers.js2ios.postMessage('checkTouchId?an=x28&cb=checkTouchIdCallback');
        } catch (e) {
          console.error(e);
        }
      } catch (e) {
        this.openLogin(callbackAfterLogin);
      }
    }
  }

  private openLogin = async (callbackAfterLogin?: (data: any, role: string) => void) => {
    const modal = await this.modalController.create({ component: Login, cssClass: 'is-modal' });
    if (callbackAfterLogin) modal.onDidDismiss().then(({ data, role }) => callbackAfterLogin(data, role));
    if (!this.globalVars.user.logged) await modal.present();
  };

  async reOpenLogin() {
    const modal = await this.modalController.create({ component: Login });
    modal.present();
  }

  async checkEnableCameraDevice(callback): Promise<void> {
    const permissions = await Camera.checkPermissions();
    callback(permissions.camera === 'granted');
  }

  registerXtremePush() {
    if (XtremePush) {
      XtremePush.deviceInfo().then((info: any) => {
        //window['XtremePush'].deviceInfo((info) => {
        console.info(JSON.stringify(info) + '**************** correctamente');

        // [Log] {externalID: "ID20630", XPushDeviceID: "264851583", deviceID: "8A12E1CC-6486-4BE8-81C8-FA16964F7F57", deviceToken: "f560e3bde593d5e784056a699b751216eef03b76a49cf23468c30841e3002ee7"} (cordova.js, line 1732)
        const plataforma: string = this.isIos ? 'IOS' : 'ANDROID';

        //si el token llega vacío es porque el usuario no ha aceptado permisos aún...
        if (info.deviceToken) {
          this.userService.saveDeviceId(info.deviceToken, plataforma).subscribe(
            () => {
              console.info(info.deviceToken + ':::registrado correctamente');
            },
            (err) => {
              console.info('err al registrar ID');
            }
          );
        }

        this.trackingService.trackEvent([
          'RegisterExtremePush',
          info.deviceToken,
          this.globalVars.XTREME_PUSH_APPKEY,
          '',
          'event'
        ]);
      });
    }
  }

  /* push notifications logic */
  public async initExtremePush() {
    if (window && XtremePush) {
      this.extremePushListeners();
      //enable inbox && inappmessaging via feature
      const inappMessagingEnabled: boolean = this.globalVars.FEATURES.XPMessagingEnabled
        ? this.globalVars.FEATURES.XPMessagingEnabled
        : false;
      const inboxEnabled: boolean = this.globalVars.FEATURES.XPInboxEnabled
        ? this.globalVars.FEATURES.XPInboxEnabled
        : false;
      const isDebugMode: boolean = environment.GLOBALS.DEBUG_MODE ? environment.GLOBALS.DEBUG_MODE : false;
      const xpOptions: RegisterOptions = {
        appKey: this.globalVars.XTREME_PUSH_APPKEY,
        debugLogs: isDebugMode,
        debugMode: isDebugMode,
        requestNotificationPermission: true,
        requestPermissions: true,
        enableInApp: inappMessagingEnabled,
        inboxEnabled: inboxEnabled,
        deeplinkCallback: 'onDeeplinkReceived',
        messageResponseCallback: 'onPushOpened',
        inboxBadgeCallback: 'onInboxBadgeUpdate',
        ios: {
          pushPermissionsRequest: false,
          locationsEnabled: false,
          locationsPermissionsRequest: false
        },
        android: {
          gcmProjectNumber: this.globalVars.ANDROID_SENDER_ID
        }
      };

      XtremePush.register({ value: xpOptions }).then((r) => {
        XtremePush.requestPushPermissions();
        const savedLaunches = parseInt(localStorage.getItem('launches'));
        if (savedLaunches === 0) {
          this.xtremeHitEvent('App_install', 'App Install Event');
        }
      });
    }
  }

  async extremePushListeners(): Promise<void> {
    await XtremePush.addListener('onPushOpened', async (pnContent: Message) => {
      if (pnContent) {
        this.handlerDeepLink(pnContent);
      }
    });
    await XtremePush.addListener('onDeeplinkReceived', (onDeeplinkReceived) => {
      console.log('onDeeplinkReceived', onDeeplinkReceived);
    });
    await XtremePush.addListener('onInboxBadgeUpdate', (count) => {
      console.log('onInboxBadgeUpdate', count);
    });
  }

  private handlerDeepLink(pnContent: Message) {
    const actionPayload = pnContent['data']['action'] ? pnContent['data']['action'] : null;
    console.log('Push Content>>', pnContent);
    if (pnContent.url) {
      switch (pnContent.url.substring(pnContent.url.lastIndexOf('/') + 1)) {
        case MSO_PATHS.RegistroNewPage:
          const pathRoute = `${MSO_PATHS.BASE}/${MSO_PATHS.RegistroNewPage}`;
          this.navigateToPage(pathRoute, actionPayload);
          break;
        case MSO_PATHS.PromotionsPage:
          const pathRoutePromotion = `${MSO_PATHS.BASE}/${MSO_PATHS.PromotionsPage}`;
          this.navigateToPage(pathRoutePromotion, actionPayload);
          break;
        case PAGES_PATHS.HomePage:
          const pathRoutePAGES_PATHS = `${PAGES_PATHS.HomePage}`;
          this.navigateToPage(pathRoutePAGES_PATHS, actionPayload);
          break;
        case CASINO_PATHS.CasinoPage:
          const pathRouteCASINO_PATHS = `${CASINO_PATHS.BASE}/${CASINO_PATHS.CasinoPage}`;
          this.navigateToPage(pathRouteCASINO_PATHS, actionPayload);
          break;
        default:
          console.log('Route URL not found');
          break;
      }
    } else {
      console.log('There is no url on the pnContent');
    }
  }

  navigateToPage(routeUrl: string, actionPayload?: string) {
    if (actionPayload) {
      this.router.navigate([routeUrl], { queryParams: { actionPayload } });
    } else {
      this.router.navigateByUrl(routeUrl);
    }
  }

  xtremeHitEvent(title: string, message: string) {
    XtremePush.hitEvent({ title, message });
  }

  requestXtremePushPermissions(idBGT: number) {
    this.setExternalId(idBGT);
    this.trackIdBGT(idBGT);
  }

  addTicketBetFromHome(itemsString: string) {
    const items = itemsString.split('-');

    console.error({ addTicketBetFromHome: 'A\u00F1adir via push notification apuesta por id' });

    this.trackingService.trackEvent([
      'AddBetExt',
      itemsString,
      '',
      'A\u00F1adir via push notification apuesta por id',
      'event'
    ]);
  }

  private trackIdBGT(idBGT: number) {
    if (idBGT) {
      this.trackingService.trackEvent([
        'InitExtremePush',
        idBGT.toString(),
        this.globalVars.XTREME_PUSH_APPKEY,
        '',
        'event'
      ]);
    }
  }

  private setExternalId(idBGT?: number) {
    setTimeout(() => {
      if (idBGT) {
        XtremePush.setExternalId({ id: idBGT.toString() + '_3' });
      }
    }, 2000);
    this.registerXtremePush();
  }

  checkNotificationNative() {
    window['notificationAndroid'] = (page) => {
      this.zone.run(() => {
        this.globalVars.rootScope.tryToGoPage(page);
      });
    };

    if (this.isIos) {
      try {
        window.location.href = 'js2ios://getNotification';
        window['webkit'].messageHandlers.js2ios.postMessage('getNotification');
      } catch (e) {
        console.error(e);
      }
    }

    if (this.isAndroid) {
      try {
        window['CodereNativeAppAndroid'].getNotification('x30', 'notificationAndroid');
      } catch (e) {
        console.error(e);
      }
    }
  }

  setNativeForCasinoTrack() {
    if (this.isIos) {
      this.globalVars.nativeDevice = 'iOS';
    } else if (this.isAndroid) {
      this.globalVars.nativeDevice = 'Android';
    } else {
      this.globalVars.nativeDevice = 'Native';
    }
  }

  async setNativeStorage() {
    // let AppNativeDevice: string;
    if (this.isIos) {
      this.appNativeDevice = 'ios';
      localStorage.setItem('AppNativeDevice', 'ios');
      this.globalVars.nativeDevice = 'iOS';
    } else if (this.isAndroid) {
      this.appNativeDevice = 'android';
      localStorage.setItem('AppNativeDevice', 'android');
      this.globalVars.nativeDevice = 'Android';
    }

    const minVersion = 4;
    const maxCounter = 3;

    let currentCounter = 0;

    if (localStorage.getItem('oldVersionPopUp')) {
      currentCounter = parseInt(localStorage.getItem('oldVersionPopUp'));
    }

    const appVersion = await App.getInfo();
    const appNativeData = await Preferences.get({ key: 'AppNativeData' });

    if (!appVersion.version) {
      const nativeData = JSON.parse(appNativeData.value);

      if (
        this.appNativeDevice === 'android' &&
        parseInt(nativeData.AppVersion) < minVersion &&
        currentCounter < maxCounter
      ) {
        this.newVersionPopoUp();
      } else if (this.appNativeDevice === 'android' && currentCounter < maxCounter) {
        this.newVersionPopoUp();
      }
    } else {
      console.error('No se encontraron datos para la clave "AppNativeData".');
    }
  }

  async setAppVersion(): Promise<void> {
    try {
      const info = await App.getInfo();
      const appPackageName = info.id;
      const appVersion = info.version;

      const nativeData = {
        AppNativeDevice: this.setAppNativeDevice(),
        AppPackageName: appPackageName,
        AppVersion: appVersion
      };

      await Preferences.set({
        key: 'AppNativeData',
        value: JSON.stringify(nativeData)
      });

      // GLOBALS.PACKAGE_DEVICE_NAME = appPackageName;

      this.setMainPage(appPackageName);
    } catch (error) {
      console.error('Error al establecer la versión de la aplicación:', error);
    }
  }

  //** TODO */
  // setAppVersion(): Promise<string | void>{
  //   let promise: Promise<string | void> = Promise.all([
  //     this.appVersion.getPackageName(),
  //     this.appVersion.getVersionCode(),
  //     this.appVersion.getVersionNumber()
  //   ]).then(([PackageName, VersionCode, VersionNumber]) => {
  //       let nativeData = {
  //         AppNativeDevice: this.setAppNativeDevice(),
  //         AppPackageName: PackageName,
  //         AppVersionCode: VersionCode,
  //         AppVersionNumber: VersionNumber
  //       }
  //       localStorage.setItem('AppNativeData', JSON.stringify(nativeData));
  //       GLOBALS.PACKAGE_DEVICE_NAME = PackageName;
  //       this.setMainPage(PackageName);
  //     });

  //   return promise;
  // }

  private setMainPage(packageName: string) {
    if (packageName.indexOf('.casino') !== -1) {
      //**+** REVISAR ESTA VARIABLE
      // this.router.navigate(['casino'], {
      //   //   queryParams: {
      //   //   lobby: 'Playtech'
      //   // }
      // })
      // this.globalVars.mainPageApp = 'LobbyCasinoPage';

      //** TODO */
      // this.globalVars.mainPageComponent = LobbyCasinoPage;
      this.setNativeForCasinoTrack();
    } else {
      //**+** REVISAR ESTA VARIABLE
      // this.globalVars.mainPageApp = 'HomePage';
      // this.globalVars.mainPageComponent = HomePage;
      if (this.globalVars.FEATURES.AppVersionPopup) this.setNativeStorage();
    }
  }

  setAppNativeDevice() {
    if (this.isIos) {
      this.appNativeDevice = 'ios';
      localStorage.setItem('AppNativeDevice', 'ios');
      this.globalVars.nativeDevice = 'iOS';
    } else if (this.isAndroid) {
      this.appNativeDevice = 'android';
      localStorage.setItem('AppNativeDevice', 'android');
      this.globalVars.nativeDevice = 'Android';
    }
    return this.appNativeDevice;
  }

  async newVersionPopoUp() {
    const modalConfig: any = {
      messageAlert: '',
      storeUrl: '',
      imgPath: 'assets/global/img/googlePlayStore.png'
    };

    if (localStorage.getItem('androidDownloaded')) {
      modalConfig.messageAlert =
        'Tenga en cuenta que Codere ya no admite esta versión de la aplicación, si ya ha descargado la nueva aplicación de PlayStore, desinstale esta versión o haga clic en el enlace de abajo para abrir la nueva aplicación';
      modalConfig.storeUrl = 'https://codere.onelink.me/3k3c/18ef989b';
    } else {
      modalConfig.messageAlert =
        'La nueva aplicación de Codere ya está disponible en Google Play Store, descargue la nueva aplicación para obtener las últimas actualizaciones';
      modalConfig.storeUrl = 'https://play.google.com/store/apps/details?id=spain.codere.apuestas';
    }

    this.modalController.create({ component: Login });

    const modal = await this.modalController.create({
      component: AppVersionPopup,
      componentProps: modalConfig,
      cssClass: 'is-modal'
    });

    await modal.present();

    modal.onDidDismiss().then((clickToStore) => {
      let popUpCounter: number;

      if (clickToStore) {
        localStorage.setItem('androidDownloaded', 'yes');

        if (localStorage.getItem('oldVersionPopUp')) {
          popUpCounter = parseInt(localStorage.getItem('oldVersionPopUp')) + 1;
        } else {
          popUpCounter = 1;
        }
        localStorage.setItem('oldVersionPopUp', popUpCounter.toString());
      }
    });
  }
}
