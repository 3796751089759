export class C_GameTypes {
  alternativeHandicaps: number[];
  alternativeTotals: number[];
  american_football: number[];
  anticipadas: number[];
  apuestas_especiales: number[];
  artes_marciales: number[];
  australian_football: number[];
  badminton: number[];
  baseball_jp: number[];
  baseball_kr: number[];
  baseball_us: number[];
  baseball: number[];
  basketball_us: number[];
  basketball: number[];
  beach_soccer: number[];
  beach_volleyball: number[];
  boxeo: number[];
  curling: number[];
  darts: number[];
  ebasket: number[];
  edgeCases: any;
  efootball: number[];
  eSports_sports: number[];
  esports: number[];
  field_hockey: number[];
  futsal: number[];
  gameTypesData: any;
  handball: number[];
  ice_hockey_nous_live: number[];
  ice_hockey_us: number[];
  ice_hockey: number[];
  motorsport: number[];
  padel: number[];
  pelota: number[];
  rugby_league: number[];
  rugby: number[];
  snooker: number[];
  soccer: number[];
  squash: number[];
  table_tennis: number[];
  tennis: number[];
  videolive: number[];
  virtual_soccer: number[];
  volleyball: number[];
  waterpolo: number[];
  constructor(gameTypesConfigData?: any) {
    if (gameTypesConfigData) {
      this.gameTypesData = gameTypesConfigData;
      this.setGameTypesConfigData(gameTypesConfigData);
    } else {
      /* this.baseball = [158, 259, 317]; */
      // this.baseball_jp = [158, 259, 317]; //OLD 16-08-21
      // this.baseball_kr = [184, 874, 959]; //OLD 16-08-21
      // this.basketball = [184, 259, 393]; //OLD 29-03-21
      // this.basketball_us = [97, 259, 393]; //OLD 29-03-21
      // this.handball = [1, 18]; //OLD 29-03-21
      // this.ice_hockey = [184, 159, 18];
      // this.ice_hockey_us = [97, 259, 18];
      // this.snooker = [97, 794]; //OLD 29-03-21
      // this.squash = [97, 393];
      // this.table_tennis = [97, 18]; //OLD 29-03-21
      // this.volleyball = [97, 393]; //OLD 29-03-21
      //this.baseball = [158, 874, 959, 158];
      //this.baseball_us = [184, 874, 959, 158];
      //this.basketball = [184, 259, 393];
      //this.basketball_us = [97, 159, 393];
      this.alternativeHandicaps = [159, 259];
      this.alternativeTotals = [393, 18];
      this.american_football = [97, 159, 393];
      this.anticipadas = [97, 1828];
      this.apuestas_especiales = [911, 303];
      this.artes_marciales = [97];
      this.australian_football = [97];
      this.badminton = [97, 393];
      this.baseball = [97, 159, 959];
      this.baseball_jp = [97, 159, 959];
      this.baseball_kr = [97, 159, 959];
      this.baseball_us = [97, 159, 959];
      this.basketball = [184, 259, 393];
      this.basketball_us = [97, 159, 393];
      this.beach_soccer = [1, 18];
      this.beach_volleyball = [97, 18];
      this.boxeo = [97];
      this.curling = [97];
      this.darts = [97];
      this.ebasket = [97, 159, 393];
      this.efootball = [1, 18];
      this.esports = [97];
      this.eSports_sports = [1, 18];
      this.field_hockey = [1, 18];
      this.futsal = [1, 18];
      this.handball = [1, 18];
      this.ice_hockey = [97, 159, 317];
      this.ice_hockey_nous_live = [97, 159, 317]; // Todo: this should be without nous as we are not having US_LIVE;
      this.ice_hockey_us = [97, 159, 317];
      this.motorsport = [313];
      this.padel = [97, 1828];
      this.pelota = [97];
      this.rugby = [1, 259];
      this.rugby_league = [1, 259];
      this.snooker = [97, 459];
      this.soccer = [1, 18];
      this.squash = [97, 195];
      this.table_tennis = [97, 393];
      this.tennis = [97, 195];
      this.virtual_soccer = [1, 18];
      this.volleyball = [97, 393];
      this.waterpolo = [1, 18];

      this.setVideoLive();

      // PreMatch
      //eSports Battle - Champions League - 2708675739
      //eSports Battle - Champions League B - 2708613567
      //eSports Battle - Europa League - 2708629910
      //eSports Battle - Premier League - 2708492960
      //eSports Battle - Nigth Erupoa League - 2730907869
      //PES - 2737211206

      //In Play

      //eSports Battle - Champions League - 2708672000
      //eSports Battle - Champions League B - 2708623627
      //eSports Battle - Europa League - 2708657621
      //eSports Battle - Premier League - 2708664352
      // PES - 2737211206
      const e = [
        2708675739, 2708613567, 2708629910, 2708492960, 2737211206, 2730907869, 2708672000, 2708623627, 2708657621,
        2708664352, 2737211206
      ];
      this.edgeCases = {
        esports: [[[2697455296, 1066367978, 2713705662, 2718327269, 2717363118, 2708675739, ...e], [1]]],
        basketball: [[[2706784563], [159, 2083]]],
        ice_hockey: [
          [
            [2698825872, 2700567854],
            [184, 259, 18]
          ]
        ],
        // ice_hockey: [[[2698825872, 2700567854, 2819844606], [1, 317]]], 2819844553, 2819844557, 2819844560
        baseball: [
          [[2013504952], [158, 259, 317]],
          [[2813504524], [97, 159, 959]]
        ]
        // american_football: [[[1065937060, 1025083770], [159, 393]]]
      };
    }
  }

  private setVideoLive() {
    this.videolive = Object.keys(this).reduce((acc, key) => {
      acc.push(...this[key]);
      const set = new Set(acc);
      return Array.from(set);
    }, []);
  }

  getAll(): number[] {
    return [...this.videolive, ...this.getAllEdgeCases()];
  }

  //-> EventoPage --> Outputs: getOne(ice_hockey,nous,true) -> // [1, 18]
  getOne(
    sportHandle: string,
    countryCode: string,
    isLive?: boolean,
    league?: string,
    auxGameTypes?: number[]
  ): number[] {
    //-> si country Code
    //-> si es live;
    // si no, sporthandle;
    if (this.isEdgeCase(sportHandle, league)) {
      return this.getEdgeCase(sportHandle, league);
    }

    let gametype = sportHandle;
    const gameTypes = new C_GameTypes(this.gameTypesData);
    if (countryCode) {
      //ice_hockey_us
      countryCode = sportHandle === 'ice_hockey' && isLive ? 'nous' : countryCode; // Todo: needs refactor.
      gametype = gametype + '_' + countryCode.toLowerCase(); //?
    }

    if (isLive && Object.prototype.hasOwnProperty.call(gameTypes, gametype + '_live')) {
      gametype = gametype + '_live';
    }

    if (Object.prototype.hasOwnProperty.call(gameTypes, 'gametype') && sportHandle !== 'basketball') {
      // if (gameTypes.hasOwnProperty(gametype) && sportHandle !== 'basketball') {
      return gameTypes[gametype];
      // } else if ((sportHandle === 'basketball' || sportHandle === "ice_hockey") && !!auxGameTypes && auxGameTypes.length >= 3) {
    } else if (sportHandle === 'basketball' && !!auxGameTypes && auxGameTypes.length >= 1) {
      const setGametypes = this.setBasketballGametypes(auxGameTypes, gameTypes, sportHandle);
      return setGametypes;
    } else {
      return gameTypes[sportHandle];
    }
  }

  setBasketballGametypes(auxGameTypes, gameTypes, sportHandle: string) {
    if (auxGameTypes.length >= 3) {
      return auxGameTypes.slice(0, 3);
    } else {
      let customMatrix: number[] = [];
      if (gameTypes[sportHandle]) {
        customMatrix = gameTypes[sportHandle];
        auxGameTypes?.map((gameType) => {
          if (gameType === 97 || gameType === 184) {
            customMatrix[0] = gameType;
          } else if (gameType === 159 || gameType === 259) {
            customMatrix[1] = gameType;
          } else if (gameType === 18 || gameType === 393 || gameType === 2083) {
            customMatrix[2] = gameType;
          }
        });
      }
      return customMatrix;
    }
  }

  //DirectosPage, TodayPage?, SportEventsPage and rest of Overviews
  getSport(sportHandle: string): number[] {
    const sport = Object.keys(this).reduce((acc, key) => {
      if (key !== 'edgeCases' && key.includes(sportHandle)) {
        acc.push(...this[key]);
      }
      return acc;
    }, []);

    let edgeCases = [];
    if (this.edgeCases[sportHandle]) {
      edgeCases = this.edgeCases[sportHandle].reduce((acc, [_, games]) => [...acc, ...games], []);
    }

    return Array.from(new Set([...sport, ...edgeCases]));
  }

  serialize(gameTypes: number[]) {
    gameTypes = Array.from(new Set(gameTypes));
    return gameTypes.reduce((acc, type) => (acc += type + ';'), '').slice(0, -1);
  }

  isSpecial(gameType: number): boolean {
    return this.apuestas_especiales.indexOf(gameType) !== -1;
  }

  getAllEdgeCases() {
    const edgeCases = [];

    Object.keys(this.edgeCases).forEach((key) => {
      const sport = this.edgeCases[key].reduce((acc, [_, games]) => [...acc, ...games], []);
      edgeCases.push(...sport);
    });

    return edgeCases;
  }

  getEdgeCase(sportHandle: string, league: string): number[] {
    const tuple = this.edgeCases[sportHandle].find((element) => element[0].indexOf(parseInt(league)) !== -1);

    return tuple[1];
  }

  isEdgeCase(sportHandle: string, league: string): boolean {
    if (!this.edgeCases[sportHandle] || !league) {
      return false;
    }

    return this.edgeCases[sportHandle].some(([leagues]) => leagues.indexOf(parseInt(league)) !== -1);
  }

  setGameTypesConfigData(gameTypesConfigData: any) {
    this.alternativeHandicaps = gameTypesConfigData.gametypes.alternativeHandicaps;
    this.alternativeTotals = gameTypesConfigData.gametypes.alternativeTotals;
    this.american_football = gameTypesConfigData.gametypes.american_football;
    this.anticipadas = gameTypesConfigData.gametypes.anticipadas;
    this.apuestas_especiales = gameTypesConfigData.gametypes.apuestas_especiales;
    this.artes_marciales = gameTypesConfigData.gametypes.artes_marciales;
    this.australian_football = gameTypesConfigData.gametypes.australian_football;
    this.badminton = gameTypesConfigData.gametypes.badminton;
    this.baseball = gameTypesConfigData.gametypes.baseball;
    this.baseball_jp = gameTypesConfigData.gametypes.baseball_jp;
    this.baseball_kr = gameTypesConfigData.gametypes.baseball_kr;
    this.baseball_us = gameTypesConfigData.gametypes.baseball_us;
    this.basketball = gameTypesConfigData.gametypes.basketball;
    this.basketball_us = gameTypesConfigData.gametypes.basketball_us;
    this.beach_soccer = gameTypesConfigData.gametypes.beach_soccer;
    this.beach_volleyball = gameTypesConfigData.gametypes.beach_volleyball;
    this.boxeo = gameTypesConfigData.gametypes.boxeo;
    this.curling = gameTypesConfigData.gametypes.curling;
    this.darts = gameTypesConfigData.gametypes.darts;
    this.ebasket = gameTypesConfigData.gametypes.ebasket;
    this.efootball = gameTypesConfigData.gametypes.efootball;
    this.esports = gameTypesConfigData.gametypes.esports;
    this.eSports_sports = gameTypesConfigData.gametypes.eSports_sports;
    this.field_hockey = gameTypesConfigData.gametypes.field_hockey;
    this.futsal = gameTypesConfigData.gametypes.futsal;
    this.handball = gameTypesConfigData.gametypes.handball;
    this.ice_hockey = gameTypesConfigData.gametypes.ice_hockey;
    this.ice_hockey_nous_live = gameTypesConfigData.gametypes.ice_hockey_nous_live;
    this.ice_hockey_us = gameTypesConfigData.gametypes.ice_hockey_us;
    this.motorsport = gameTypesConfigData.gametypes.motorsport;
    this.padel = gameTypesConfigData.gametypes.padel;
    this.pelota = gameTypesConfigData.gametypes.pelota;
    this.rugby = gameTypesConfigData.gametypes.rugby;
    this.rugby_league = gameTypesConfigData.gametypes.rugby_league;
    this.snooker = gameTypesConfigData.gametypes.snooker;
    this.soccer = gameTypesConfigData.gametypes.soccer;
    this.squash = gameTypesConfigData.gametypes.squash;
    this.table_tennis = gameTypesConfigData.gametypes.table_tennis;
    this.tennis = gameTypesConfigData.gametypes.tennis;
    this.virtual_soccer = gameTypesConfigData.gametypes.virtual_soccer;
    this.volleyball = gameTypesConfigData.gametypes.volleyball;
    this.waterpolo = gameTypesConfigData.gametypes.waterpolo;

    this.setVideoLive();

    this.edgeCases = gameTypesConfigData.edgeCases;
  }
}
