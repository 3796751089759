<!-- eslint-disable -->
<ng-container *ngFor="let row of rowsTexts">
  <ng-container *ngIf="row.type === 'array'">
    <ion-row
      *ngFor="let row2 of row.anchors_images"
      [ngClass]="[stylesIonRow(row.class), (globalVars.licenseType === 33 || globalVars.licenseType === 34 ? 'copyFooterAr' : '') + (globalVars.licenseType === 21 ? 'copyFooterCo' : '')]"
    >
      <ng-container *ngFor="let item of row2">
        <img *ngIf="!isImageType(item)" [src]="item.src" [ngClass]="item.class" [alt]="item.altImg" />
        <a
          *ngIf="isImageType(item) && item.src && !item.event"
          [target]="item.target"
          [ngClass]="item.class"
          (click)="openBrowser(item.href)"
        >
          <img [src]="item.src" [alt]="item.altImg" [ngClass]="item.classImg" />
        </a>
        <a
          *ngIf="isImageType(item) && !item.src && !item.event"
          (click)="openBrowser(item.href)"
          [target]="item.target"
          [ngClass]="item.class"
        >
          {{item.title | translate}}
        </a>
        <a
          *ngIf="isImageType(item) && item.src && item.event "
          (click)="openEvent($event, item.typeEvent)"
          [ngClass]="item.class"
        >
          <img [src]="item.src" [alt]="item.altImg" [ngClass]="item.classImg" />
        </a>
      </ng-container>
    </ion-row>
    <div class="timer-footer">
      <span class="timeFooter" *ngIf="timer && time !== null">{{time}}</span>
    </div>
  </ng-container>
  <ion-row *ngIf="row.type === 'noSpan'" [ngClass]="row.class">{{row.titleToTranslate | translate}} </ion-row>
  <ion-row
    *ngIf="row.type === 'span'"
    [ngClass]="['padding10 ion-align-items-center ' + stylesIonRow(row.class), (globalVars.licenseType === 33 || globalVars.licenseType === 34 ? 'copyFooterAr' : ''), (globalVars.licenseType === 22 ? 'copyFooterPa' : ''), (globalVars.licenseType === 21 ? 'copyFooterCo' : '') ]"
  >
    <ion-col size="11" [ngClass]="'copyFooter txtlegal border0 '+ stylesIonCol()">
      <span *ngIf="row.innerHTML !== ''" [innerHTML]="row.innerHTML | translate"></span>
      <span *ngIf="row.titleToTranslate || row.preTitle" [ngClass]="row.spanClass">
        {{row.preTitle}}{{row.titleToTranslate | translate}}
      </span>
    </ion-col>
    <ion-col class="aenor-col" [class.co]="globalVars.licenseType === 21" size="1">
      <a *ngIf="aenor" external-link [href]="aenor.href" target="_blank">
        <img alt="AENOR Seguridad Información" [src]="aenor.src" class="aenor" />
      </a>
    </ion-col>
  </ion-row>
</ng-container>
