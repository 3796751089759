<!-- eslint-disable @angular-eslint/template/eqeqeq -->

<ion-content [class.coderecardco]="globalVars.LICENSE_TYPE === licenseTypes.Colombia">
  <user-section-title [sectionTitle]="'circleLoyaltyCard' | translate" class="titlecursor"></user-section-title>
  <ion-grid>
    <ion-row class="ion-justify-content-center noborder bannercard" (click)="bannerCard()">
      <ion-col size="12" size-md="9" class="banner">
        <img [src]="bannerimg" class="bannercard" />
      </ion-col>
    </ion-row>
    <ion-row class="cardexpl ion-justify-content-center">
      <div class="divgrap">
        <ion-col [size]="globalVars.LICENSE_TYPE === licenseTypes.Colombia && isMobile ? 11 : 12" size-md="3">
          <ion-card>
            <ion-card-header>
              <ion-card-title class="center">
                <img [src]="iconimg.card" />
              </ion-card-title>
              <ion-card-subtitle>{{ 'cardcoderetx01' | translate }}</ion-card-subtitle>
            </ion-card-header>
            <ion-card-content><p [innerHTML]="'cardcoderetx02' | translate"></p></ion-card-content>
          </ion-card>
        </ion-col>
        <ion-col [size]="globalVars.LICENSE_TYPE === licenseTypes.Colombia && isMobile ? 11 : 12" size-md="3">
          <ion-card>
            <ion-card-header>
              <ion-card-title class="center">
                <img [src]="iconimg.benefits" />
              </ion-card-title>
              <ion-card-subtitle>{{ 'cardcoderetx03' | translate }}</ion-card-subtitle>
            </ion-card-header>
            <ion-card-content><p [innerHTML]="'cardcoderetx04' | translate"></p></ion-card-content>
          </ion-card>
        </ion-col>
        <ion-col [size]="globalVars.LICENSE_TYPE === licenseTypes.Colombia && isMobile ? 11 : 12" size-md="3">
          <ion-card>
            <ion-card-header>
              <ion-card-title class="center">
                <img [src]="iconimg.payments" />
              </ion-card-title>
              <ion-card-subtitle>{{ 'cardcoderetx05' | translate }}</ion-card-subtitle>
            </ion-card-header>
            <ion-card-content><p [innerHTML]="'cardcoderetx06' | translate"></p></ion-card-content>
          </ion-card>
        </ion-col>
      </div>
    </ion-row>
    <ion-row class="ion-justify-content-center solicitycoderecard bannersolicity">
      <img [src]="bannersolicity" class="imgsolicity" *ngIf="globalVars.LICENSE_TYPE === licenseTypes.Colombia" />
      <ion-col size="11" [sizeMd]="globalVars.LICENSE_TYPE === licenseTypes.Nacional ? 4 : 6" class="coltext">
        <article>
          <div class="titlegreen">{{ 'cardcoderetx07' | translate }}</div>
          <div [innerHTML]="'cardcoderetx08' | translate" class="txtleft"></div>
        </article>
      </ion-col>
      <ion-col
        [size]="globalVars.LICENSE_TYPE === licenseTypes.Colombia && isMobile ? 11 : 12"
        [sizeMd]=" globalVars.LICENSE_TYPE === licenseTypes.Nacional ? 5 : 12"
        class="displayb"
      >
        <ion-row class="noborder displayb">
          <div class="displayf">
            <ion-col size="12" size-md="6">
              <ion-card class="displaycard txtblack" (click)="openMapLocals()">
                <ion-card-header>
                  <ion-card-title>
                    <img src="assets/global/img/coderecard/icon-card-solicitar.svg" />
                  </ion-card-title>
                </ion-card-header>
                <ion-card-content>
                  <h2>{{ 'cardcoderetx09' | translate }}</h2>
                  <p [innerHTML]="'cardcoderetx10' | translate"></p>
                </ion-card-content>
                <div><ion-icon name="chevron-forward-outline"></ion-icon></div>
              </ion-card>
            </ion-col>
            <ion-col size="12" size-md="6" *ngIf="globalVars.LICENSE_TYPE === licenseTypes.Nacional">
              <ion-card class="displaycard txtblack" (click)="openCardCodereCP()">
                <ion-card-header>
                  <ion-card-title>
                    <img src="assets/global/img/coderecard/icon-card-cp.svg" />
                  </ion-card-title>
                </ion-card-header>
                <ion-card-content>
                  <h2>{{ 'cardcoderetx11' | translate }}</h2>
                  <p [innerHTML]="'cardcoderetx12' | translate"></p>
                </ion-card-content>
                <div><ion-icon name="chevron-forward-outline"></ion-icon></div>
              </ion-card>
            </ion-col>
          </div>
        </ion-row>
      </ion-col>
    </ion-row>
    <ion-row class="ion-justify-content-center bannercardassociate solicitycoderecard">
      <img [src]="bannerassociate" class="imgsr" *ngIf="globalVars.LICENSE_TYPE === licenseTypes.Colombia" />
      <ion-col size="11" [sizeMd]="globalVars.LICENSE_TYPE === licenseTypes.Nacional ? 4 : 6" class="coltext displaya2">
        <article>
          <div class="titlegreen">{{ 'cardcoderetx13' | translate }}</div>
          <div [innerHTML]="'cardcoderetx14' | translate" class="txtleft"></div>
        </article>
      </ion-col>
      <ion-col
        [size]="globalVars.LICENSE_TYPE === licenseTypes.Colombia && isMobile ? 11 : 12"
        [sizeMd]="globalVars.LICENSE_TYPE === licenseTypes.Nacional ? 5 : 6"
        class="displaya"
      >
        <ion-card class="displaycard txtblack width100" (click)="associatePage()">
          <ion-card-header>
            <ion-card-title>
              <img src="assets/global/img/coderecard/icon-card-asociar.svg" />
            </ion-card-title>
          </ion-card-header>
          <ion-card-content>
            <h2>{{ 'cardcoderetx15' | translate }}</h2>
            <p [innerHTML]="'cardcoderetx16' | translate"></p>
          </ion-card-content>
          <div><ion-icon name="chevron-forward-outline"></ion-icon></div>
        </ion-card>
      </ion-col>
    </ion-row>
    <ion-row class="ion-justify-content-center question noborder">
      <ion-col size="11" size-md="9" class="pregcol">
        <div [innerHTML]="'contact_frequentlyquestions' | translate" class="titlegreen"></div>
      </ion-col>
      <ng-container *ngFor="let question of questions | maxElements:maxQuestions; let i = index">
        <ion-col size="12" size-md="9">
          <ion-card>
            <ion-card-header (click)="toggleQuestion(i)">
              <ion-card-title>
                <span>{{ question.request | translate }}</span>
                <ion-icon name="chevron-down-outline" [ngClass]="{'rotate': isOpen[i]}"></ion-icon>
              </ion-card-title>
            </ion-card-header>
            <ion-card-content [@expandCollapse]="isOpen[i] ? 'expanded' : 'collapsed'">
              <div [innerHTML]="question.response | translate"></div>
            </ion-card-content>
          </ion-card>
        </ion-col>
      </ng-container>
      <ion-col size="11" size-md="9" *ngIf="!(this.maxQuestions >= this.questions.length)" class="btnseemore">
        <ion-button (click)="addQuestions()">{{'seeMore' | translate }}</ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
  <codere-footer></codere-footer>
</ion-content>
