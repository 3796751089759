<!-- eslint-disable @angular-eslint/template/eqeqeq -->
<div class="iconwarning" *ngIf="globalVars.LICENSE_TYPE !==33" [class.marginb0]="isMobile">
  <div><img src="assets/global/img/icon-warning-red.svg" alt="warning red" /></div>
  <div class="masinfo text-center redtxt">{{'selfExclusionP3tx01' | translate}}</div>
  <div class="margint10 txtblack">
    <ng-container *ngIf="dates && !isString; else indefinido">
      <div><span>{{'selfExclusionP3tx02' | translate}}</span> <span>{{ dates[0] | date:'dd/MM/yyyy HH:mm'}}</span></div>
      <div>
        <span>{{'selfExclusionP3tx03' | translate}}</span> <span> {{ dates[1] | date:'dd/MM/yyyy HH:mm'}}</span>
      </div>
    </ng-container>
    <ng-template #indefinido>
      <div><span>{{'selfExclusionP3tx02' | translate}}</span><span>{{ timenow | date: 'dd/MM/yyyy HH:mm'}}</span></div>
      <div><span>{{'selfExclusionP3tx03' | translate}}</span> <span>{{'indefinitetime' | translate }}</span></div>
    </ng-template>
  </div>
</div>

<div class="containerP">
  <div class="texto page1">
    <div>
      <div><span class="is-bold">{{'selfExclusionP3title1' | translate | uppercase}}</span></div>
      <div class="mt15"><span [innerHTML]="'selfExclusionP3tx04' | translate"></span></div>
      <div class="mt15"><span class="is-bold">{{'selfExclusionP3title2' | translate | uppercase}}</span></div>
      <div *ngIf="globalVars.LICENSE_TYPE ===0" class="mt15">
        <span [innerHTML]="'selfExclusionP3tx05' | translate"></span>
      </div>
      <div><span [innerHTML]="'selfExclusionP3tx06' | translate"></span></div>
      <div *ngIf="globalVars.LICENSE_TYPE ===21 || globalVars.licenseType ===22">
        <div class="mt15"><span class="is-bold">{{'selfExclusionP3title3' | translate | uppercase}}</span></div>
        <div class="margint10"><span [innerHTML]="'selfExclusionP3tx07' | translate"></span></div>
        <div class="borderdotted" *ngIf="globalVars.licenseType ===21">
          <div class="toggle formbtn margint10">
            <ion-toggle class="button paddingt12" [formControl]="$any(myform).controls['firm1']"> </ion-toggle>
            <ion-label>
              {{'selfExclusionP3tx08' | translate }} {{city}} {{'selfExclusionP3tx09' | translate }}
              {{this.timenow.getDate()}} {{'selfExclusionP3tx10' | translate }} {{getMonthName() | titlecase}} {{'of' |
              translate }} {{this.timenow.getFullYear()}}
            </ion-label>
          </div>

          <div class="toggle formbtn">
            <ion-toggle class="button paddingt12" [formControl]="$any(myform).controls['firm2']"> </ion-toggle>
            <ion-label> {{'selfExclusionP3tx11' | translate }}</ion-label>
          </div>
        </div>
      </div>
      <div class="padding30RL">
        <div class="btnContainer">
          <ion-button
            (click)="onSelfExclusion()"
            class="btnRed"
            [disabled]="this.globalVars.LICENSE_TYPE ===21 && !myform.valid"
            shape="round"
          >
            {{'selfExclusionP3btn' | translate | uppercase}}
          </ion-button>
          <ion-button
            (click)="backToHome()"
            class="btnwhite"
            [class.padding70]="isDesktop"
            [class.padding115]="isMobile"
            shape="round"
          >
            {{'tCancel' | translate}}
          </ion-button>
        </div>
      </div>
    </div>
  </div>
</div>
