import { Component } from '@angular/core';
import { AlertController } from '@ionic/angular';
interface iAalert {
  title?: string;
  message?: string;
  cssClass?: string | string[];
  inputs?: any;
  buttons?: any;
}

@Component({
  selector: 'codere-ionic-alert-modal',
  standalone: true,
  template: ''
})
export class AlertModalComponent {
  constructor(private alertController: AlertController) {}

  async innerHTML(alert: iAalert) {
    const modal = await this.alertController.create({
      message: alert.message,
      inputs: alert.inputs,
      buttons: alert.buttons,
      cssClass: alert.cssClass
    });
    if (alert.title) {
      const doc = document.getElementsByClassName('customModal');
      if (doc[0]?.childNodes[2]) {
        const value = doc[0].childNodes[2].firstChild as Element;
        value.innerHTML = '<h6 class="sb-alert--title color-secondary">' + alert.title + '</h6>';
      }
    }
    await modal.present();
  }
}
