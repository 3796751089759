import { C_Event, C_Game } from '../../../../shared/src/models';

export class C_SportsEventLive extends C_Event {
  ResultInfo: string;
  override liveData: any;
  SportId: string;
  DefaultGame: any;
  NodeStatus: number;
  NodeStatusChangeTime: string;
  IsActive: boolean;
  CountryName: string;
  override teamHome: string;
  override teamAway: string;
  RealName: string;
  override SportName?: string;
  override Locked?: boolean;
  constructor(
    resultInfo?: string,
    liveData?: any,
    sportId?: string,
    defaultGame?: any,
    nodeStatus?: number,
    nodeStatusChangeTime?: string,
    isActive?: boolean,
    games?: Array<C_Game>,
    leagueName?: string,
    startDate?: string,
    islive?: boolean,
    statisticsId?: string,
    streamingEnabled?: string,
    name?: string,
    nodeId?: string,
    parentNodeId?: string,
    eventNodeTypeId?: number,
    priority?: number,
    sporthandle?: string,
    childrenCount?: number,
    teamHome?: string,
    teamAway?: string,
    RealName?: string,
    SportName?: string,
    Locked?: boolean
  ) {
    super(
      games,
      leagueName,
      startDate,
      islive,
      statisticsId,
      streamingEnabled,
      name,
      nodeId,
      parentNodeId,
      eventNodeTypeId,
      // TODO priority,
      sporthandle,
      childrenCount
    );
    this.ResultInfo = resultInfo;
    this.liveData = liveData;
    this.SportId = sportId;
    this.DefaultGame = defaultGame;
    this.NodeStatus = nodeStatus;
    this.NodeStatusChangeTime = nodeStatusChangeTime;
    this.IsActive = isActive;
  }

  static override parse(obj: any): C_SportsEventLive {
    const event = Object.assign(new C_SportsEventLive(), obj);
    event.Games = event.Games.filter(Boolean);
    event.setHomeAndAway();
    event.setScoreboardType();
    event.setDetailScoreboardType();
    event.setLockedResults();
    event.setMode();
    return event;
  }
}
