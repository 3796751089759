<!-- eslint-disable @angular-eslint/template/eqeqeq -->
<ion-list class="greyBackground">
  <div class="iconwarning" *ngIf="showTopWarningMessage">
    <div><img src="assets/global/img/icon-warning-red.svg" alt="warning red" /></div>
    <div class="masinfo text-center">
      <div *ngIf="globalVars.licenseType == 0">
        {{'selfexclusionstxt01' | translate}}
        <div>{{'selfexclusionstxt02' | translate}}</div>
      </div>

      <div *ngIf="globalVars.licenseType == 21 || globalVars.licenseType == 22">
        {{'selfexclusionstxt01PA'| translate}}
      </div>
    </div>
  </div>

  <cdr-step1-se *ngIf="step==1" (changeStep)="onStepChange($event)"> </cdr-step1-se>
  <cdr-step2-se
    [dateOptions]="dateOptions"
    *ngIf="step==2"
    (changeStep)="onStepChange($event)"
    (datesEvent)="onDatesEvent($event)"
    (selfExEvent)="onSelfExclusion($event, 2)"
  >
  </cdr-step2-se>
  <cdr-step3-se *ngIf="step==3" (changeStep)="onStepChange($event)" [dates]="dates" [city]="city"> </cdr-step3-se>
  <cdr-step-form-se *ngIf="this.step==4" (changeStep)="onStepChange($event)" (cityEmit)="cityChange($event)">
  </cdr-step-form-se>
</ion-list>
