/* eslint-disable @typescript-eslint/no-explicit-any */
import { EventEmitter, Injectable, inject } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { Utils } from '../utils/Utils';
import { GlobalVarsModel } from '../models/ModelGlobalsVar';
import { NewBaseService } from './newBase.service';
import { UserService } from './UserService';
import { LicenseTypes } from '@models/MasterData';

@Injectable({ providedIn: 'root' })
export class UtilsCashierService {
  colombiaPromo = true;
  $truelayerState = new EventEmitter();
  $closeTrueLayerKeyboard = new EventEmitter();
  private initialPayment = new BehaviorSubject('');
  currentPayment = this.initialPayment.asObservable();

  globalVars!: GlobalVarsModel;

  newBaseService = inject(NewBaseService);
  alertCtrl = inject(AlertController);
  utils = inject(Utils);
  userService = inject(UserService);

  constructor() {
    this.newBaseService.getVars.subscribe((data) => {
      this.globalVars = data;
    });
  }

  setCustomAmount(customOption: string, currentAmount: any, paymentSelected) {
    let newAmount;
    const customAmount = this.setAmountButtons(currentAmount, customOption);

    switch (customOption) {
      case 'add':
        newAmount = currentAmount + customAmount;
        if (paymentSelected.maxDepositAmount && newAmount > paymentSelected.maxDepositAmount) {
          return paymentSelected.maxDepositAmount;
        } else {
          return newAmount;
        }

      case 'subtract':
        newAmount = currentAmount - customAmount;
        if (paymentSelected.minDepositAmount && paymentSelected.minDepositAmount <= newAmount) {
          return newAmount;
        }
        return paymentSelected.minDepositAmount;

      default:
        if (currentAmount > paymentSelected.maxDepositAmount) {
          return paymentSelected.maxDepositAmount;
        } else if (currentAmount < paymentSelected.minDepositAmount) {
          return paymentSelected.minDepositAmount;
        } else {
          return currentAmount;
        }
    }
  }

  setAmountButtons(currentAmount: number, customOption: string) {
    switch (this.globalVars.licenseType) {
      case 0:
        return this.setNacionalAmountButtons(currentAmount, customOption);
      case 4:
        return this.setMexAmountButtons(currentAmount, customOption);
      case 21:
        return 10000;
      case 22:
        return this.setMexAmountButtons(currentAmount, customOption);

      default:
        return this.setNacionalAmountButtons(currentAmount, customOption);
    }
  }

  setNacionalAmountButtons(currentAmount: number, customOption: string) {
    if (customOption === 'add') {
      switch (true) {
        case currentAmount < 100:
          return 5;
        case currentAmount >= 100:
          return 10;
      }
    } else {
      switch (true) {
        case currentAmount <= 100:
          return 5;
        case currentAmount > 100:
          return 10;
      }
    }
  }

  setMexAmountButtons(currentAmount: number, customOption: string) {
    if (customOption === 'add') {
      switch (true) {
        case currentAmount < 500:
          return 100;
        case currentAmount >= 500 && currentAmount < 2000:
          return 500;
        case currentAmount >= 2000 && currentAmount < 10000:
          return 1000;
        case currentAmount >= 10000:
          return 50000;
      }
    } else {
      switch (true) {
        case currentAmount <= 500:
          return 100;
        case currentAmount > 500 && currentAmount <= 2000:
          return 500;
        case currentAmount > 2000 && currentAmount <= 10000:
          return 1000;
        case currentAmount > 10000:
          return 5000;
      }
    }
  }

  async showInfoAlert(title: string, message: string) {
    if (this.globalVars.FEATURES.SendFrontErrors) {
      this.userService.sendErrors(message);
    }
    const buttons = [
      {
        text: 'OK'
      }
    ];
    this.showAlert(title, message, buttons);
  }

  private async showAlert(title: string, message: string, buttons: any) {
    const footer = await this.utils.createARFooter();
    const alert = await this.alertCtrl.create({
      header: title,
      message: message,
      buttons,
      backdropDismiss: true,
      cssClass: 'sb-alert--documentation alertcashier'
    });

    await alert.present();
    if (this.globalVars.licenseType === LicenseTypes.ArgentinaCaba) {
      // Agregar el pie de página después de que la alerta se haya presentado
      const ionAlert = await this.alertCtrl.getTop();
      if (ionAlert) {
        const alertElement = ionAlert.querySelector('.alert-wrapper');
        if (alertElement) {
          alertElement.appendChild(footer);
        }
      }
    }
    return alert;
  }

  offColombiaPromo() {
    this.colombiaPromo = false;
  }

  checkColombiaPromo() {
    return this.colombiaPromo;
  }

  setInitTruelayer() {
    this.$truelayerState.emit();
  }

  closeTrueLauerKeyboard() {
    this.$closeTrueLayerKeyboard.emit();
  }
}
