import { Component, Input, inject } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GlobalVarsModel } from '../../../models/ModelGlobalsVar';
import { NewBaseService } from '../../../providers/newBase.service';
import { ParseMoney } from '../../../pipes/parseMoney';
import { NewUserService } from '../../../providers/NewUserService';
import { BalancesService } from '../../../providers/BalancesService';
import { TrackingService } from '../../../providers/TrackingService';
import { C_DetailTicket } from '../../../models/C_ResponseTickets';
import { MSO_PATHS } from '../../../lib/constants/routes';
import { Router } from '@angular/router';
import { Utils } from '../../../utils/Utils';
import { ExtractDatePipe } from '../../../pipes/extractDate.pipe';
import { FormatTitleEventPipe } from '../../../pipes/formatTitleEvent.pipe';
import { FormatOdd } from '../../../pipes/formatOdd.pipe';

@Component({
  selector: 'detail-ticket',
  templateUrl: 'detail-ticket.html',
  styleUrls: ['./detail-ticket.scss'],
  standalone: true,
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    ParseMoney,
    ReactiveFormsModule,
    TranslateModule,
    ExtractDatePipe,
    FormatTitleEventPipe,
    FormatOdd
  ]
})
export class DetailTicket {
  @Input() betDetails: C_DetailTicket;
  @Input() ticketPresencial: any;
  @Input() loadDetails: any;

  globalVars: GlobalVarsModel;
  componentPage: string;

  newBaseService = inject(NewBaseService);
  parseMoney = inject(ParseMoney);
  translateService = inject(TranslateService);
  utils = inject(Utils);
  balanceService = inject(BalancesService);
  trackingService = inject(TrackingService);
  newUserService = inject(NewUserService);
  router = inject(Router);

  constructor() {
    this.newBaseService.getVars.subscribe((vars) => {
      this.globalVars = vars;
    });
  }

  ngOnInit() {
    this.componentPage = this.globalVars.currentPage.name;
  }

  openCondiciones(event) {
    this.router.navigate([`/${MSO_PATHS.BASE}/${MSO_PATHS.InformationPage}`], {
      queryParams: { type: 'webdataprotection', animate: true },
      queryParamsHandling: 'merge'
    });
    event.preventDefault();
  }

  payOutTicket(ticketNr: string, amount: string) {
    const msg =
      this.translateService.instant('CloseBet1') +
      ' ' +
      this.parseMoney.transform((parseInt(amount) / 100).toString()) +
      ' ' +
      this.translateService.instant('CloseBet2') +
      '<br />' +
      this.translateService.instant('compapuestaP3') +
      ':' +
      ticketNr;

    if (this.ticketPresencial) {
      this.trackingService.trackEvent([
        'EarlyCashOutCallCodereCard',
        '',
        '',
        'Llamada a cerrar apuesta (EarlyCashOut)'
      ]);
    } else {
      this.trackingService.trackEvent(['EarlyCashOutCall', '', '', 'Llamada a cerrar apuesta (EarlyCashOut)', 'event']);
    }
    this.utils.confirm(
      true,
      this.translateService.instant('CloseBet'),
      msg,
      this.translateService.instant('tCancel'),
      null,
      this.translateService.instant('tAccept'),
      () => {
        this.newUserService.payoutBetTicket(ticketNr, parseInt(amount), this.ticketPresencial).subscribe(
          (data) => {
            if (data.success) {
              const msg2 =
                this.translateService.instant('BetClose1') +
                ' ' +
                this.parseMoney.transform((parseInt(amount) / 100).toString()) +
                ' ' +
                this.translateService.instant('BetClose2');
              const title = this.translateService.instant('CloseBet');
              this.utils.showMsg(
                msg2,
                () => {
                  this.loadDetails();
                  this.globalVars.ticketStatusChanged = true;
                },
                title
              );
              this.balanceService.refreshBalance();

              if (this.ticketPresencial) {
                this.trackingService.trackEvent([
                  'EarlyCashOutCompleteCodereCard',
                  ticketNr,
                  amount,
                  'Cerrar apuesta completado  (EarlyCashOut)',
                  'event'
                ]);
              } else {
                this.trackingService.trackEvent([
                  'EarlyCashOutComplete',
                  ticketNr,
                  amount,
                  'Cerrar apuesta completado  (EarlyCashOut)',
                  'event'
                ]);
              }
            } else {
              const texto = data.status + ' (' + data.statuscode + ')';
              this.utils.showError(texto, () => {
                this.loadDetails();
              });
              if (this.ticketPresencial) {
                this.trackingService.trackEvent([
                  'EarlyCashOutFailureCodereCard',
                  ticketNr,
                  amount,
                  'Cerrar apuesta fallido (EarlyCashOut)',
                  'event'
                ]);
              } else {
                this.trackingService.trackEvent([
                  'EarlyCashOutFailure',
                  ticketNr,
                  amount,
                  'Cerrar apuesta fallido (EarlyCashOut)',
                  'event'
                ]);
              }
            }
          },
          (error) => {
            this.utils.showError('', () => {
              this.loadDetails();
            });
          }
        );
      }
    );
  }

  currentPage(name: string) {
    return this.componentPage == name ? true : false;
  }
}
