<ion-content>
  <div class="contModal contModalCash">
    <button class="closeModal" (click)="btClose_Click()">
      <ion-icon name="close-outline"></ion-icon>
    </button>
    <div class="myWrapLegal bulletList">
      <ion-item class="introPopUp">
        <ion-label class="ion-text-wrap" [innerHTML]="'v2_tHalCahsCond1' | translate"> </ion-label>
      </ion-item>
      <ion-item>
        <ion-label class="ion-text-wrap">
          <div class="bullet">
            <p>1</p>
          </div>
          {{'v2_tHalCahsCond2' | translate}}
        </ion-label>
      </ion-item>
      <ion-item>
        <ion-label class="ion-text-wrap">
          <div class="bullet">
            <p>2</p>
          </div>
          <span [innerHTML]="'v2_tHalCahsCond3' | translate"></span>
        </ion-label>
      </ion-item>
      <ion-item>
        <ion-label class="ion-text-wrap">
          <div class="bullet">
            <p>3</p>
          </div>
          <span [innerHTML]="'v2_tHalCahsCond4' | translate"></span>
        </ion-label>
      </ion-item>
      <ion-item>
        <ion-label class="ion-text-wrap">
          <div class="bullet">
            <p>4</p>
          </div>
          {{'v2_tHalCahsCond5' | translate}}
        </ion-label>
      </ion-item>
      <ion-item class="itemLast">
        <ion-label class="w-50-px ion-text-wrap">
          <div class="bullet">
            <p>5</p>
          </div>
          <span class="ion-text-wrap" [innerHTML]="'v2_tHalCahsCond6' | translate"></span>
        </ion-label>
      </ion-item>
    </div>
  </div>
</ion-content>
