/* eslint-disable */
import { IFramePaymentMethodBehaviourResult, PaymentMethodBehaviour, SkipAmountResult } from './PaymentMethodBase';
import { OxxoDepositActionResult } from '@models/C_Transactions';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ErrorService } from '@providers/ErrorService';
import { UserService } from '@providers/UserService';

export abstract class OxxoBankPaymentMethodBehaviour extends PaymentMethodBehaviour {
  type: string;

  constructor(
    public override userService: UserService,
    type: string,
    public override errorService: ErrorService
  ) {
    super(false, userService, errorService);
    this.type = type;
    this.documentNumberRequired = true;
  }

  isBank = true;
  amountButtons: number[] = [300, 400, 800, 1000];
  minDepositAmount = 100;
  maxDepositAmount = 999999999;
  track = 'AccessToOxxoBank';
  override amount = 400;

  skipAmountInput(): Observable<SkipAmountResult> {
    return new Observable<SkipAmountResult>();
  }

  paymentMetodExecution(): Observable<IFramePaymentMethodBehaviourResult> {
    return this.userService.validateDepositOxxoBank('', this.amount, this.documentNumber, this.type).pipe(
      map((obj) => {
        return this.map(obj);
      })
    );
  }

  private map(input: OxxoDepositActionResult): IFramePaymentMethodBehaviourResult {
    const result = new IFramePaymentMethodBehaviourResult();
    if (input.success) {
      result.success = true;
      result.iframeType = 'oxxo';
      result.iframeArgs = { type: 'oxxo', url: encodeURI(input.url), msg: false };
    } else {
      result.success = false;
      result.erroMessage = this.errorService.searchErrorCodeWithParams('error' + input.errCode, input.errorParameters);
      result.errorcode = input.errCode;
      result.sendToDocumentVerificationPage = input.errCode == 174 || input.errCode == 2212;
    }
    return result;
  }
}

export class BancomerOxxoBankPaymentMethodBehaviour extends OxxoBankPaymentMethodBehaviour {
  constructor(
    public override userService: UserService,
    public override errorService: ErrorService
  ) {
    super(userService, 'BV', errorService);
  }

  name = 'Bancomer';
  image = 'Bancomer';
}

export class BanamexOxxoBankPaymentMethodBehaviour extends OxxoBankPaymentMethodBehaviour {
  constructor(
    public override userService: UserService,
    public override errorService: ErrorService
  ) {
    super(userService, 'BM', errorService);
  }

  name: string = 'Banamex';
  image: string = 'Banamex';
}

export class SantanderOxxoBankPaymentMethodBehaviour extends OxxoBankPaymentMethodBehaviour {
  constructor(
    public override userService: UserService,
    public override errorService: ErrorService
  ) {
    super(userService, 'SM', errorService);
  }

  name: string = 'Santander';
  image: string = 'Santander';
}

export class AmericanExpressOxxoBankPaymentMethodBehaviour extends OxxoBankPaymentMethodBehaviour {
  constructor(
    public override userService: UserService,
    public override errorService: ErrorService
  ) {
    super(userService, 'AE', errorService);
  }
  override isBank = false;
  name: string = 'OxxoBank-AmericanExpress';
  image: string = 'AmericanExpress';
  override track: string = 'AccessToOxxoBankAmericanExpress';
}

export class AztecaBankOxxoBankPaymentMethodBehaviour extends OxxoBankPaymentMethodBehaviour {
  constructor(
    public override userService: UserService,
    public override errorService: ErrorService
  ) {
    super(userService, 'AZ', errorService);
  }
  name: string = 'OxxoBank-AztecaBank';
  image: string = 'BancoAzteca';
  override track: string = 'AccessToOxxoAztecaBank';
}

export class BanorteBankPaymentMethodBehaviour extends OxxoBankPaymentMethodBehaviour {
  constructor(
    public override userService: UserService,
    public override errorService: ErrorService
  ) {
    super(userService, 'BQ', errorService);
  }

  name: string = 'Banorte';
  image: string = 'Banorte';
}

export class SpeiBankPaymentMethodBehaviour extends OxxoBankPaymentMethodBehaviour {
  constructor(
    public override userService: UserService,
    public override errorService: ErrorService
  ) {
    super(userService, 'SE', errorService);
  }
  override isBank = false;
  name: string = 'ASTROSPI';
  image: string = 'logo-spei';
  override track: string = 'AccessToSpei';
}
export class PaynetOxxoBankPaymentMethodBehaviour extends OxxoBankPaymentMethodBehaviour {
  constructor(
    public override userService: UserService,
    public override errorService: ErrorService
  ) {
    super(userService, 'PN', errorService);
  }
  override isBank = false;
  name: string = 'PayNetOxxo';
  image: string = 'PayNetOxxo';
}

export class ToditoOxxoBankPaymentMethodBehaviour extends OxxoBankPaymentMethodBehaviour {
  constructor(
    public override userService: UserService,
    public override errorService: ErrorService
  ) {
    super(userService, 'TC', errorService);
  }
  override isBank = false;
  name: string = 'Todito';
  image: string = 'Todito';
}

export class CarullaOxxoBankPaymentMethodBehaviour extends OxxoBankPaymentMethodBehaviour {
  constructor(
    public override userService: UserService,
    public override errorService: ErrorService
  ) {
    super(userService, 'CR', errorService);
  }
  override isBank = false;
  name: string = 'Carulla';
  image: string = 'logo_carulla';
  override documentNumberRequired = false;
}
export class GanaBankOxxoBankPaymentMethodBehaviour extends OxxoBankPaymentMethodBehaviour {
  constructor(
    public override userService: UserService,
    public override errorService: ErrorService
  ) {
    super(userService, 'GG', errorService);
  }
  name: string = 'OxxoBank-GanaBank';
  image: string = 'ganagana';
  override track: string = 'AccessToOxxoBankGana';
  override documentNumberRequired = false;
}

export class AlmacenesExitoOxxoBankPaymentMethodBehaviour extends OxxoBankPaymentMethodBehaviour {
  constructor(
    public override userService: UserService,
    public override errorService: ErrorService
  ) {
    super(userService, 'EX', errorService);
  }
  name: string = 'OxxoBank-AlamacenesExitoBank';
  image: string = 'almacenesExito';
  override track: string = 'AccessToOxxoBankAlmacenesExito';
  override documentNumberRequired = false;
}
export class PagoTodoOxxoBankPaymentMethodBehaviour extends OxxoBankPaymentMethodBehaviour {
  constructor(
    public override userService: UserService,
    public override errorService: ErrorService
  ) {
    super(userService, 'TP', errorService);
  }
  override isBank = false;
  name: string = 'PagoTodo';
  image: string = 'logo_pagoTodo';
  override documentNumberRequired = false;
}
export class CucutaOxxoBankPaymentMethodBehaviour extends OxxoBankPaymentMethodBehaviour {
  constructor(
    public override userService: UserService,
    public override errorService: ErrorService
  ) {
    super(userService, 'TA', errorService);
  }
  override isBank = false;
  name: string = 'Cucuta';
  image: string = 'logoto_cucuta';
  override documentNumberRequired = false;
}
export class GanaCercaBankOxxoBankPaymentMethodBehaviour extends OxxoBankPaymentMethodBehaviour {
  constructor(
    public override userService: UserService,
    public override errorService: ErrorService
  ) {
    super(userService, 'GC', errorService);
  }
  name: string = 'OxxoBank-GanaCercaBank';
  image: string = 'ganaCerca';
  override track: string = 'AccessToOxxoBankGanaCerca';
  override documentNumberRequired = false;
}
export class BancolombiaBankOxxoBankPaymentMethodBehaviour extends OxxoBankPaymentMethodBehaviour {
  constructor(
    public override userService: UserService,
    public override errorService: ErrorService
  ) {
    super(userService, 'GG', errorService);
  }
  name: string = 'OxxoBank-BanColombia';
  image: string = 'banColombia';
  override track: string = 'AccessToOxxoBankColombia';
  override documentNumberRequired = false;
}
export class VisaOxxoBankPaymentMethodBehaviour extends OxxoBankPaymentMethodBehaviour {
  constructor(
    public override userService: UserService,
    public override errorService: ErrorService
  ) {
    super(userService, 'VI', errorService);
  }
  name: string = 'OxxoBank-Visa';
  image: string = 'Visa_epg';
  override track: string = 'AccessToOxxoVisa';
  override documentNumberRequired = true;
}
export class MasterCardOxxoBankPaymentMethodBehaviour extends OxxoBankPaymentMethodBehaviour {
  constructor(
    public override userService: UserService,
    public override errorService: ErrorService
  ) {
    super(userService, 'MC', errorService);
  }
  name: string = 'OxxoBank-MasterCard';
  image: string = 'Logo_MasterCard';
  override track: string = 'AccessToOxxoMasterCard';
  override documentNumberRequired = true;
}
export class BalotoOxxoBankPaymentMethodBehaviour extends OxxoBankPaymentMethodBehaviour {
  constructor(
    public override userService: UserService,
    public override errorService: ErrorService
  ) {
    super(userService, 'BU', errorService);
  }
  name: string = 'OxxoBank-Baloto';
  image: string = 'logo-viabaloto';
  override track: string = 'AccessToOxxoBaloto';
  override documentNumberRequired = false;
}
/*export class PSEOxxoBankPaymentMethodBehaviour extends OxxoBankPaymentMethodBehaviour{
    constructor (public userService: UserService){
        super(userService,"PC")
    }
    name: string = "OxxoBank-PSE";
    image: string = "Spe";
    track:string ="AccessToOxxoPSE";
    documentNumberRequired=false;
}^*/
