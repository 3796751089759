import { HttpClient, type HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { type Observable, type ObservableInput, throwError } from "rxjs";

import { Platform } from "@ionic/angular";
import { catchError, finalize, map } from "rxjs/operators";
import { ExceptionInterceptorNoShowError } from "../constants/exception-interceptor-no-show-error.constant";
import { CookieNames } from "../models/CookieNames";
import { GlobalVarsModel } from "../models/ModelGlobalsVar";
import { EventsService } from "../providers/EventsService";
import { LoaderService } from "../services/loader-feature/loader.service";
import { Utils } from "../utils/Utils";
import { NewBaseService } from "./newBase.service";

@Injectable({ providedIn: "root" })
export class BaseService {
  loaderService: LoaderService;
  globalVars!: GlobalVarsModel;
  events = inject(EventsService);
  utils = inject(Utils);
  httpClient = inject(HttpClient);
  newBaseService = inject(NewBaseService);
  platform = inject(Platform);
  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
    this.loaderService = new LoaderService();
  }

  protected extractData(res: any) {
    if (res.status < 200 || res.status >= 300) {
      if (this.events) {
        this.events.publish("user:serverfail");
      }
      return {};
    } else {
      if (!res?.text()) {
        return null;
      } else {
        return res.json() || {};
      }
    }
  }

  //handleError = (error: any, caught: any): Observable<any> => {
  handleError = (error: any, caught: any): any => {
    setTimeout(() => {
      this.sendErrors(error);
    }, 500);
    if (this.events) {
      this.events.publish("loading:finish");
    }
    if (this.checkUrl(error.url)) {
      switch (error.status) {
        case 200:
          // en ocasiones ha entrado por aquí un error y había dado el status=200. Rarisimo, pero ha entrado. Ha sido al hacer login, en uno de todos lo métodos que desencadena.
          //en este caso no hacer nada.
          break;
        case 401:
          this.events.publish("user:logout:soft");
          break;
        case 403:
          this.events.publish("user:logout:soft");
          break;
        case 404:
          console.error("NOT Found");
          break;
        case 410:
          this.events.publish("user:serverfailmaintenace", error);
          break;
        default:
          this.events.publish("user:serverfail", error);
          break;
      }
    }
  };

  checkUrl(url: string): boolean {
    let result = true;
    if (url) {
      const lista = ExceptionInterceptorNoShowError;
      for (let index = 0; index < lista.length; index++) {
        const element = lista[index];
        if (url.toLowerCase().includes(element.toLowerCase())) {
          result = false;
          break;
        }
      }
    }
    return result;
  }

  sendErrors(error: any) {
    if (this.globalVars.FEATURES.SendFrontErrors && this.globalVars.walletActive) {
      const errorDate = new Date().toISOString();
      const paymentMethod: string =
        // eslint-disable-next-line no-nested-ternary
        typeof this.globalVars.paymentMethod === "string"
          ? this.globalVars.paymentMethod
          : (this.globalVars.paymentMethod as any).name
            ? (this.globalVars.paymentMethod as any).name
            : "";
      const objectError = {
        date: errorDate,
        deviceType: this.globalVars.PLATFORM,
        license: this.globalVars.licenseType,
        loginName: this.globalVars.user.idBGT,
        errorType: error,
        viewName: this.globalVars.user.username,
        paymentMethod: paymentMethod,
        paymentProvider: "",
      };
      const url = `${this.globalVars.UrlTransactionsApiBase}monitoring/trackfronterror`;
      if (error.status === 0) {
        localStorage.setItem("savedError", JSON.stringify(objectError));
      } else {
        this.httpClient.post(url, objectError);
        return this.myPost(url, objectError, null, false, (err: any, caught: Observable<any>): any => {
          console.error(err);
        }).subscribe((response) => console.error("error response", response));
      }
    }
  }

  handleError$(error: HttpErrorResponse) {
    this.sendErrors(error);

    if (this.events) {
      this.events.publish("loading:finish");
    }

    switch (error.status) {
      case 200:
        // en ocasiones ha entrado por aquí un error y había dado el status=200. Rarisimo, pero ha entrado. Ha sido al hacer login, en uno de todos lo métodos que desencadena.
        //en este caso no hacer nada.
        break;
      case 401:
        this.events.publish("user:logout:soft");
        break;
      case 403:
        this.events.publish("user:logout:soft");
        break;
      case 404:
        console.error("NOT Found");
        break;
      case 410:
        this.events.publish("user:serverfailmaintenace");
        break;
      default:
        this.events.publish("user:serverfail");
        break;
    }

    const errMsg = "Error " + error.status;
    return throwError(() => new Error(errMsg));
  }

  // FIX FOR PTP IN LOCAL
  private sanitizeURLForPTPLocal(url: string) {
    const { DOMAIN } = this.globalVars;
    if (DOMAIN.includes("localhost")) {
      url = url.replace("https://dm.apuestas.codere.es", DOMAIN);
      // For future PTP if required
      url = url.replace("https://qm.codere.com.ar/", DOMAIN);
      url = url.replace("https://q.mx.bet.cdrsbg.com/", DOMAIN);
    }
    return url;
  }

  public myGet(
    url: string,
    hideLoader = false,
    errorHandler?: (err: any, caught: Observable<any>) => ObservableInput<{}>,
    responsive = null,
  ): Observable<any> {
    url = this.sanitizeURLForPTPLocal(url);
    if (!hideLoader) {
      this.events.publish("loading:start");
    }
    const options: any = this.setImpCookieClient();
    /*Esta condicional es para agregar el responseType de la peticion,
     normalmente es JSON pero en algunos devuelve html o texto esto provoca que ingrese
     en error.
     responseType: 'arraybuffer'|'blob'|'json'|'text'
    */
    if (responsive) options.responseType = responsive;
    if (this.globalVars.isNative && url.includes("assets") && this.platform.is("ios")) {
      const baseHref = window.location.origin;
      url = baseHref + "/" + url;
    }
    return this.httpClient.get(url, options).pipe(
      map((response) => response),
      catchError(errorHandler ? errorHandler : this.handleError),
      finalize(() => {
        if (!hideLoader) {
          this.events.publish("loading:finish");
        }
      }),
    );
  }

  protected myGetOld(
    url: string,
    hideLoader = false,
    errorHandler?: (err: any, caught: Observable<any>) => ObservableInput<{}>,
  ): Observable<any> {
    if (!hideLoader) {
      this.events.publish("loading:start");
    }

    return this.httpClient.get<any>(url, this.setImpCookie()).pipe(
      map((response: any) => this.extractData(response)),
      catchError((error: any) => (errorHandler ? errorHandler("Error", error) : this.handleError$(error))),
      finalize(() => {
        if (!hideLoader) {
          this.events.publish("loading:finish");
        }
      }),
    );
  }

  handleErrorImg = (error: any, caught: any): any => {
    this.sendErrors(error);

    if (this.events) {
      this.events.publish("loading:finish");
    }

    switch (error.status) {
      case 200:
        // en ocasiones ha entrado por aquí un error y había dado el status=200. Rarisimo, pero ha entrado. Ha sido al hacer login, en uno de todos lo métodos que desencadena.
        //en este caso no hacer nada.
        break;
      case 403:
        this.events.publish("imgStreamingFail");
        break;
      default:
        this.events.publish("user:serverfail");
        break;
    }
  };

  protected imgGetOld(url: string, hideLoader = false): Observable<any> {
    // eslint-disable-next-line no-empty
    if (hideLoader) {
    } else {
      this.events.publish("loading:start");
    }

    return this.httpClient.get<any>(url, this.setImpCookie()).pipe(
      map((response: any) => this.extractData(response)),
      catchError((error: any) => this.handleErrorImg("Error", error)),
      finalize(() => {
        if (hideLoader) {
          this.events.publish("loading:finish");
        }
      }),
    );
  }

  protected imgGet(url: string, hideLoader?: boolean): Observable<any> {
    // eslint-disable-next-line prefer-const
    const options: any = this.setImpCookieClient(false);
    return this.httpClient.get(url, options).pipe(
      // eslint-disable-next-line arrow-body-style
      map((response) => {
        return response;
      }),
      catchError(this.handleErrorImg),
    );
  }

  protected myPost(
    url: string,
    data: any,
    options?: any,
    hideLoader = false,
    errorHandler?: (err: any, caught: Observable<any>) => ObservableInput<{}>,
  ): Observable<any> {
    url = this.sanitizeURLForPTPLocal(url);
    if (!hideLoader) {
      this.events.publish("loading:start");
    }
    if (options) {
      if (!options.headers) {
        options.headers = new HttpHeaders();
      }
    } else {
      options = this.setImpCookieClient();
    }
    return this.httpClient.post(url, data, options).pipe(
      map((response) => response),
      catchError(errorHandler ? errorHandler : this.handleError),
      finalize(() => {
        if (!hideLoader) {
          this.events.publish("loading:finish");
        }
      }),
    );
  }

  protected myPostOld(
    url: string,
    data: any,
    options?: any,
    hideLoader = false,
    errorHandler?: (err: any, caught: Observable<any>) => ObservableInput<{}>,
  ): Observable<any> {
    if (!hideLoader) {
      this.events.publish("loading:start");
    }

    if (!!options && !!options.headers) {
      if (this.globalVars.isNative && !!localStorage.getItem("reese84")) {
        options.headers.append("X-D-Token", JSON.parse(localStorage.getItem("reese84")).token);
      }
      if (
        this.globalVars.FEATURES.csrfControlFront &&
        this.globalVars.user.logged &&
        this.globalVars.extraUserData.csrfControl
      ) {
        options.heend(CookieNames.CsrfControl, this.globalVars.extraUserData.csrfControl);
      }
    } else {
      options = this.setImpCookie();
    }

    return this.httpClient.post(url, data, options).pipe(
      map(this.extractData),
      catchError(errorHandler ? errorHandler : this.handleError$),
      finalize(() => {
        console.error("inside post", url);
        console.error(errorHandler);
        console.error(this.handleError);
        console.error(this.handleError$);
        if (!hideLoader) {
          this.events.publish("loading:finish");
        }
      }),
    );
  }

  protected myPut(
    url: string,
    data: any,
    options?: any,
    hideLoader = false,
    errorHandler?: (err: any, caught: Observable<any>) => ObservableInput<{}>,
  ): Observable<any> {
    if (!hideLoader) {
      this.events.publish("loading:start");
    }
    if (options) {
      if (!options.headers) {
        options.headers = new HttpHeaders();
      }
    } else {
      options = this.setImpCookieClient();
    }

    return this.httpClient.put(url, data, options).pipe(
      map((response) => response),
      catchError(errorHandler ? errorHandler : this.handleError),
      finalize(() => {
        if (!hideLoader) {
          this.events.publish("loading:finish");
        }
      }),
    );
  }

  protected myPutOld(
    url: string,
    data: any,
    options?: any,
    hideLoader = false,
    errorHandler?: (err: any, caught: Observable<any>) => ObservableInput<{}>,
  ): Observable<any> {
    if (!hideLoader) {
      this.events.publish("loading:start");
    }

    if (!options) {
      if (!options.headers) {
        options.headers = new HttpHeaders();
      }
    } else {
      options = this.setImpCookie();
    }

    return this.httpClient.put(url, data, options).pipe(
      map(this.extractData),
      catchError(errorHandler ? errorHandler : this.handleError$),
      finalize(() => {
        if (!hideLoader) {
          this.events.publish("loading:finish");
        }
      }),
    );
  }

  protected myDelete(
    url: string,
    hideLoader = false,
    errorHandler?: (err: any, caught: Observable<any>) => ObservableInput<{}>,
  ): Observable<any> {
    if (!hideLoader) {
      this.events.publish("loading:start");
    }
    const options: any = this.setImpCookieClient();
    return this.httpClient.delete(url, options).pipe(
      map((response) => response),
      catchError(errorHandler ? errorHandler : this.handleError),
      finalize(() => {
        if (!hideLoader) {
          this.events.publish("loading:finish");
        }
      }),
    );
  }

  protected myDeleteOld(
    url: string,
    hideLoader = false,
    errorHandler?: (err: any, caught: Observable<any>) => ObservableInput<{}>,
  ): Observable<any> {
    if (!hideLoader) {
      this.events.publish("loading:start");
    }

    return this.httpClient.delete(url, this.setImpCookie()).pipe(
      map((response: any) => this.extractData(response)),
      catchError((error: any) => (errorHandler ? errorHandler("Error", error) : this.handleError$(error))),
      finalize(() => {
        if (!hideLoader) {
          this.events.publish("loading:finish");
        }
      }),
    );
  }

  setImpCookie() {
    //Add Imperva cookie token in Native Apps
    const headers = new HttpHeaders();
    const returnOk = false;

    const options = { headers: headers };

    return returnOk ? options : null;
  }
  setImpCookieClient(takeIntoAccountCSRF = true) {
    //Add Imperva cookie token in Native Apps
    let returnOk = false;
    const options = {
      headers: new HttpHeaders(),
    };
    if (this.globalVars.isNative && !!localStorage.getItem("reese84")) {
      returnOk = true;
      options.headers = options.headers.append("X-D-Token", JSON.parse(localStorage.getItem("reese84")).token);
    }
    if (
      takeIntoAccountCSRF &&
      this.globalVars.FEATURES.csrfControlFront &&
      this.globalVars.user.logged &&
      this.globalVars.extraUserData.csrfControl
    ) {
      returnOk = true;
      options.headers = options.headers.append(CookieNames.CsrfControl, this.globalVars.extraUserData.csrfControl);
    }
    return returnOk ? options : {};
  }
}
