<!-- <div class="swiper-container" #swiper>
  <div
    *ngIf="!controls"
    class="lb-slider--overlay is-left fade-in"
    [ngClass]="{'is-left':isGradientLeftAvailable}"
  ></div>
  <div class="swiper-wrapper" color="dark">
    <ng-content></ng-content>
  </div>
  <div *ngIf="!controls && isGradientRightAvailable" class="lb-slider--overlay is-right fade-in"></div>

  <div *ngIf="controls" class="swiper-button-prev slide-control prev" tappable (click)="changeSlide('prev')">
    <ion-icon
      class="icon icon-md icon-md-black ion-ios-arrow-back"
      color="black"
      md="ios-arrow-back"
      ios="ios-arrow-back"
    ></ion-icon>
  </div>

  <div *ngIf="controls" class="swiper-button-next slide-control next" tappable (click)="changeSlide('next')">
    <ion-icon
      class="icon icon-md icon-md-black ion-ios-arrow-forward"
      color="black"
      md="ios-arrow-forward"
      ios="ios-arrow-forward"
    ></ion-icon>
  </div>
</div> -->

<swiper-container init="false" #swiper>
  <!-- gradient left -->
  <div *ngIf="!controls && isGradientLeftAvailable" class="casino-category-slider--overlay is-left fade-in"></div>

  <!-- content -->

  <swiper-slide
    [ngStyle]="{ height: '100%', maxWidth: getSlideSize(game.gameTileSize) }"
    *ngFor="let game of jackpot?.jackpotInfo?.games"
  >
    <casino-card-new
      tappable
      class="swiper-slide"
      [game]="game"
      [isJpLobby]="true"
      [showGameTicker]="false"
      (turnPlay)="playGame(game)"
    >
    </casino-card-new>
  </swiper-slide>
  <!-- gradient right -->
  <div *ngIf="isGradientRightAvailable" class="casino-category-slider--overlay is-right fade-in"></div>

  <!-- controls -->
</swiper-container>
<ng-container *ngIf="controls">
  <button class="swiper-button-prev slide-control prev" [class]="'swiper-button-prev-' + id" tappable ion-button>
    <ion-icon name="chevron-back-outline"></ion-icon>
  </button>
  <button
    *ngIf="controls"
    class="swiper-button-next slide-control next"
    [class]="'swiper-button-next-' + id"
    tappable
    ion-button
  >
    <ion-icon name="chevron-forward-outline"></ion-icon>
  </button>
</ng-container>
