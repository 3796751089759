/* eslint-disable */
import { Component, Output, EventEmitter, Input, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { ParseStakeMoney } from '@pipes/parseStakeMoney';

import { C_TicketApuestasNew, TicketMethods } from '@models/C_TicketApuestas';
import { NewTicketFacade } from '@models/sports/store/facades/ticket.facade';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'betslip-ticket-confirmed',
  templateUrl: './betslip-ticket-confirmed.component.html',
  styleUrls: ['./betslip-ticket-confirmed.component.scss'],
  standalone: true,
  imports: [CommonModule, IonicModule, FormsModule, ParseStakeMoney, TranslateModule]
})
export class BetslipTicketConfirmedComponent {
  @Input() ticket: C_TicketApuestasNew;
  @Input() disabled;
  @Input() btnBetText;
  @Input() status: string;
  @Output() keepSelections = new EventEmitter();
  @Output() closeResume = new EventEmitter();
  @Output() clearTicket = new EventEmitter();
  @Output() placeBet = new EventEmitter();

  newTicketFacade = inject(NewTicketFacade);

  betButton = '';

  constructor() {}

  ngOnChanges() {}

  ngAfterViewInit() {}

  ngOnDestroy() {}

  onKeepSelectionsClick() {
    console.log('keepSelections');
    this.keepSelections.emit();
  }
  onCloseResumeClick() {
    console.log('closeResume');
    this.closeResume.emit();
  }

  onClearTicketClick() {
    console.log('clearTicket');
    this.clearTicket.emit();
  }

  onPlaceBetClick() {
    console.log('placeBet');
    this.placeBet.emit();
  }

  getTicketIsConfirmed(ticket: any): boolean {
    if (ticket != undefined && ticket != null) return TicketMethods.getIsConfirmed(ticket);
    else return false;
  }
}
