<div class="cs-document-header">
  <div class="cs-document-header--container p-1 justify-center verification-manual-title">
    <span [innerHTML]="verificationLiterals.header.title"></span>
  </div>
  <!-- Warning -->
  <div class="verification-info-header">
    <p class="warning" [ngClass]="getStatus()">
      <img [src]="getIcon()" />{{ getWarningMessage() }}
    </p>
    <p *ngIf="getStatus() != verStatus.verified" [innerHTML]="verificationLiterals.header.required"></p>
  </div>
  <!-- Instructions -->
  <div class="verification-info-instructions">
    <table>
      <tr>
        <td class="left-icon">
          <img src="assets/global/img/info/verification_account.svg" />
        </td>
        <td class="right-text">
          <div [innerHTML]="verificationLiterals.header.account"></div>
        </td>
      </tr>
      <tr class="empty-row"></tr>
      <tr>
        <td class="left-icon">
          <img src="assets/global/img/info/verification_dni.svg" />
        </td>
        <td class="right-text">
          <div>
            <p>{{ verificationLiterals.header.DNI }}</p>
            <p *ngIf="verificationLiterals.header.justificante && verificationLiterals.header.justificante !== ''">
              {{ verificationLiterals.header.justificante }}</p>
          </div>
        </td>
      </tr>
    </table>
  </div>
  <!-- Buttons -->
  <div class="verification-info-buttons">
    <button *ngFor="let verificationOption of verificationOptions" class="btn-verification"
      [ngClass]="{'selected': verificationOption.selected, 'icon': verificationOption.status && !isMobile}"
      (click)="verificationOptionClicked(verificationOption)">
      <div class="cs-doc-button">
        <img *ngIf="verificationOption.status" class="cs-doc-button--icon" [src]="verificationOption.status">
        <span>{{verificationOption.text }}</span>
      </div>
    </button>
  </div>
</div>