/* eslint-disable eqeqeq */
/* eslint-disable no-var */
/* eslint-disable no-empty */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable-next-line no-var */
import { Injectable, Injector, forwardRef, inject } from '@angular/core';
import { Subject } from 'rxjs';

import { EventTypes, TrackingEvent } from '../models/TrackingEvents';
import { PaymentMethods } from '../models/C_Transactions';
import { CookieNames } from '../models/CookieNames';

import { LogService } from '../providers/LogService';
import { CookiesService } from '../providers/CookiesService';
import { ICodereEventHandler } from '../providers/eventHandlers/ICodereEventHandler';
import { CdrInsightsEvent } from '../providers/tracking/cdr-core/cdr-insights/model/cdr-insights-event';
import { Utils } from '../utils/Utils';
import { GlobalVarsModel } from '../models/ModelGlobalsVar';
import { Platform } from '@ionic/angular';
import { Capacitor } from '@capacitor/core';
import { NewBaseService } from './newBase.service';
import { ITrackingService } from '../interfaces/ITrackingService';

declare var customInsights: any, customInsights;
declare var codereSharedJS: any, codereSharedJS;
declare var appInsights: any, appInsights;

@Injectable({ providedIn: 'root' })
export class TrackingService implements ITrackingService {
  public static event$: Subject<CdrInsightsEvent> = new Subject<CdrInsightsEvent>();
  countEvent: number;
  readonly eventHandlers: ICodereEventHandler[];
  lastTimeTrackedInMs!: number;
  globalVars!: GlobalVarsModel;
  isDesktop: boolean = Capacitor.getPlatform() === 'web';
  //#region Inject Services
  logService = inject(LogService);
  utils = inject(Utils);
  cookiesService = inject(CookiesService);
  newBaseService = inject(NewBaseService);
  platform = inject(Platform);

  //#endregion
  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
    this.countEvent = 0;
    this.resetTime();
  }
  event$: Subject<CdrInsightsEvent>;

  public track = (event: TrackingEvent): void => {
    this.internalTrackEvent(
      'event',
      event.eventType,
      event.id,
      event.secondParameter,
      event.description,
      event.eventType,
      event.additionalData
    );
  };

  public trackEvent = (event: Array<string>): void => {
    this.internalTrackEvent(event[4], event[0], event[1], event[2], event[3]);
  };

  private internalTrackEvent = (
    trackingType: string,
    key: number | string,
    id: string,
    secondParameter: string,
    description?: string,
    eventType?: EventTypes,
    additionalData?: { [key: string]: any }
  ): void => {
    if (this.globalVars.user.logged && this.globalVars.LICENSE_TYPE === 4) {
      // TODO **+** ERROR codereSharedJS
      // codereSharedJS?.mexos.getMexosInternalCookieInfo((mexosInternalCookieInfo) => {
      //   this.internalTrackEventCallback(
      //     trackingType,
      //     key,
      //     id,
      //     secondParameter,
      //     description,
      //     eventType,
      //     additionalData,
      //     mexosInternalCookieInfo
      //   );
      // });
    } else {
      this.internalTrackEventCallback(trackingType, key, id, secondParameter, description, eventType, additionalData);
    }
  };

  private internalTrackEventCallback = (
    trackingType: string,
    key: number | string,
    id: string,
    secondParameter: string,
    description?: string,
    eventType?: EventTypes,
    additionalData?: { [key: string]: any },
    mexosInternalCookieInfo?: any
  ): void => {
    const bannerClickCookie: string = this.cookiesService.getCookie(CookieNames.MexosBannerClick);
    // TODO **+** ERROR codereSharedJS
    // const mexosCookieInfo: any = codereSharedJS.mexos.getMexosCookieInfoFromCookieValue(bannerClickCookie);
    const keyDesc: string = typeof key === 'string' ? key : EventTypes[key];

    const configData: any = {
      App: this.isDesktop ? 'WebAppV2PC' : 'WebAppV2',
      id: id,
      key: keyDesc,
      secondParameter: secondParameter,
      description: description,
      additionalData: JSON.stringify(additionalData),
      ApplicationState: this.ApplicationStateToString(),
      AppVersion: this.globalVars.APP_VERSION,
      // TODO **+** ERROR codereSharedJS
      //clickSource: mexosCookieInfo && mexosCookieInfo.advertiser ? mexosCookieInfo.advertiser : 'Organic',
      sourceCDR:
        mexosInternalCookieInfo && mexosInternalCookieInfo.advertiser
          ? mexosInternalCookieInfo.advertiser
          : bannerClickCookie
            ? 'none'
            : 'Organic'
    };

    configData.additionalData = JSON.stringify(additionalData);

    this.logService.log('Tracking event', configData);
    if (this.globalVars.FEATURES.EnabledSolitics) {
      TrackingService.event$.next({ ...configData, eventType }); // solitics
    }

    //diferenciar pagina de evento
    try {
      if (appInsights) {
        if (trackingType == 'event') {
          appInsights.trackEvent(configData.key, configData);
          //PredictBet Call
          this.callToPredict();
          this.resetTime();
        } else {
          //PAGE
          const previousTime: number = this.lastTimeTrackedInMs;
          this.resetTime();
          appInsights.trackPageView(
            configData.description,
            configData.key + '/' + configData.secondParameter + '/' + encodeURI(configData.description),
            configData,
            undefined,
            this.lastTimeTrackedInMs - previousTime
          );
        }
      }
    } catch (err) {
      this.logService.error('Error tracking event in appInsights', err);
    }

    // Global event handlers
    try {
      this.eventHandlers.forEach((eventHandler) => {
        eventHandler.handleEvent(key as EventTypes, id, secondParameter, description, 'event', additionalData);
      });
    } catch (err) {
      this.logService.error('Error tracking to global event handlers', err);
    }

    try {
      // External tracking lib
      customInsights.trackEvent(eventType ? eventType : key, id, secondParameter, description, 'event', additionalData);
    } catch (err) {
      this.logService.error('Error tracking to customInsights', err);
    }
  };

  customTrackEvent(event: Array<any>) {
    try {
      customInsights.trackEvent(
        !isNaN(event[0]) ? parseInt(event[0]) : event[0],
        event[1] ? event[1] : undefined,
        event[2] ? event[2] : undefined,
        event[3] ? event[3] : undefined,
        event[4] ? event[4] : undefined,
        event[5] ? event[5] : undefined
      );
    } catch (err) {}
  }

  setAuthenticatedUserContext(username: string) {
    try {
      appInsights.setAuthenticatedUserContext(username);
    } catch (err) {}
  }

  callToPredict() {
    if (this.countEvent == this.globalVars.predictNumberEvents) {
      this.countEvent++;
    } else {
      if (this.countEvent < this.globalVars.predictNumberEvents) this.countEvent++;
    }
  }

  mustTrackEvent(paymentMethod: number): boolean {
    return (
      paymentMethod == PaymentMethods.PaySafeCard ||
      paymentMethod == PaymentMethods.Paypal ||
      paymentMethod == PaymentMethods.Visa ||
      paymentMethod == PaymentMethods.Nequi ||
      paymentMethod == PaymentMethods.PagueloFacil ||
      !paymentMethod
    );
  }

  trackPageView(uri: string, description: string, additionalData: any) {
    try {
      if (appInsights) {
        //PAGE
        const previousTime: number = this.lastTimeTrackedInMs;
        this.resetTime();
        appInsights.trackPageView(description, uri, additionalData, undefined, this.lastTimeTrackedInMs - previousTime);
      }
    } catch (err) {}

    try {
      //external Insights lib
      customInsights.trackEvent(uri, undefined, undefined, description, 'pageView');
    } catch (err) {}
  }

  public getTrackingDevice = (): string => (this.isDesktop ? 'pc' : 'mobile');

  private resetTime() {
    this.lastTimeTrackedInMs = new Date().getMilliseconds();
  }

  ApplicationStateToString(): string {
    //reads all the parameters ending with "On" and retuns the string
    let stateStr = '';
    let index = 0;
    for (const propName in this.globalVars.ApplicationState) {
      if (propName.indexOf('On') != -1) {
        if (index > 0) {
          stateStr += '&';
        }
        if (this.globalVars.ApplicationState[propName]) {
          stateStr += propName;
        } else {
          stateStr += propName.split('On')[0] + 'Off';
        }
        index++;
      }
    }

    return stateStr;
  }
}
