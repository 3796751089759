<div
  ion-button
  class="user-submenu-button"
  [ngClass]="{'backgroundheader': globalVars.FEATURES?.MSO_NewReBranding}"
  [class.selected]="!userSubMenuClosed"
  submenu
  (clickOutside)="toggleSubMenu('user',$event)"
  tappable
>
  <!--<div class="avatar">
        <ion-icon name="person"></ion-icon>
    </div>
    <ion-icon class="avatar-settings-icon" name="md-cog" color="background"></ion-icon>-->
  <i class="codere-icon icon-icono_avatar"></i>
</div>

<div class="user-submenu" *ngIf="!userSubMenuClosed" submenu (clickOutside)="toggleSubMenu('user',$event)">
  <div class="user-submenu-item is-button background-color-dark color-light" (click)="navigateCashier()">
    {{'IcoDeposit'|translate | uppercase}}
    <!--DEPOSITAR-->
  </div>
  <div class="user-submenu-item is-button background-color-base color-light" value="false" (click)="refreshBalance()">
    <i class="codere-icon icon-reload" value="false"></i>
    <span>{{globalVars.user.balance.toString() | parseMoney:[4]}}</span>
  </div>
  <div class="user-submenu-item" (click)="navigate('MisApuestasPage')">
    <p>{{'IcoMybets'|translate}}</p>
    <!--Mis Apuestas-->
  </div>
  <div class="user-submenu-item" (click)="navigateCashier()">
    <p>{{'uPCajero'|translate | titlecase}}</p>
    <!--Cashier-->
  </div>
  <div class="user-submenu-item" *ngIf="globalVars?.FEATURES?.DocVerification" (click)="navigateCashier('documents')">
    <p>{{'veridoc'|translate}}</p>
    <!--Verificación Documental-->
    <ion-badge
      class="alert-badge"
      item-end
      *ngIf="globalVars?.FEATURES?.MSO_CheckDNIExpired && globalVars?.extraUserData?.dniExpired"
      >1</ion-badge
    >
  </div>
  <div class="user-submenu-item" (click)="navigate('FreeBetsPage')" *ngIf="globalVars?.FEATURES?.FreeBetEnabled">
    <p>Freebets</p>
  </div>
  <div
    class="user-submenu-item"
    (click)="navigate('SlotsBonusPage')"
    *ngIf="globalVars?.FEATURES?.BonusSlotsMenuEnabled"
  >
    <p>{{'CasinoBonus'|translate}}</p>
    <!--Bonos de Casino-->
  </div>
  <div class="user-submenu-item" (click)="navigate('MyActivityPage')" *ngIf="globalVars?.FEATURES?.myactivity">
    <p>{{'myactivity'|translate}}</p>
    <!--My actividad-->
  </div>
  <div
    class="user-submenu-item"
    (click)="navigateCodereCard()"
    *ngIf="(globalVars?.FEATURES?.TarjetaCodereEnabled && globalVars?.licenseType !== LicenseTypes.Mexico) || (globalVars?.licenseType === LicenseTypes.Mexico && globalVars?.user?.codereCard.active) || (globalVars?.licenseType === LicenseTypes.Mexico && globalVars?.withoutWinlineCard)"
  >
    <p>{{'CodereCard'|translate}}</p>
    <!--Tarjeta Codere-->
  </div>
  <div
    class="user-submenu-item"
    (click)="navigate('PersonalInfoPage')"
    *ngIf="globalVars?.FEATURES?.MSO_PersonalInfoPageEnabled"
  >
    <p>{{'uPPrefer'|translate}}</p>
    <!--Ajustes de cuenta-->
  </div>
  <div class="user-submenu-item" (click)="navigate('CuotaTypePage')">
    <!--Opciones de apuesta-->
    <p>{{ 'bettingOptions' | translate }}</p>
  </div>
  <div class="user-submenu-item" (click)="navigate('QuickExit')" *ngIf="globalVars?.FEATURES?.quickExitPageEnabled">
    <p>{{'quickexit'|translate}}</p>
    <!--Salida rápida-->
  </div>
  <div
    class="user-submenu-item"
    *ngIf="globalVars?.FEATURES?.CommunicationsOptions"
    (click)="navigate('ComunicationPage')"
  >
    <!--Opciones de comunicación -->
    <p>{{'communicationoptions'|translate}}</p>
  </div>

  <div class="user-submenu-item" (click)="doLogout()">
    <p>{{'tLogOut'|translate}}</p>
    <ion-icon item-right name="exit-outline" class="exit-icon"></ion-icon>
  </div>
</div>
