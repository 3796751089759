import { CommonModule } from "@angular/common";
/* eslint-disable eqeqeq*/
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
  inject,
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { DeferLoadDirective } from "@directives/defer-load/defer-load.directive";
import { IonImg, IonicModule } from "@ionic/angular";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { PragmaticInfo } from "@models/index";
import { Game } from "@models/lobby/casinoDataModels";
import { SlotName } from "@pipes/casino/lobby/slot-name.pipe";
import { ParseMoney } from "@pipes/parseMoney";
import { NewBaseService } from "@providers/newBase.service";
import { JackpotService } from "@services/casino/jackpot.service";
import { PragmaticService } from "@services/casino/pragmatic.service";
import { DeviceService } from "@services/device.service";
import Hls from "hls.js";
import { BehaviorSubject } from "rxjs";

@Component({
  selector: "casino-card-new",
  imports: [DeferLoadDirective, CommonModule, ParseMoney, SlotName, IonicModule],
  standalone: true,
  templateUrl: "./casino-card-new.component.html",
  styleUrls: ["./casino-card-new.component.scss"],
})
export class CasinoCardNewComponent implements OnInit, AfterViewInit, OnDestroy {
  image: string;
  defaultImg: string;
  baseImage: string;
  badges;
  loaded = false;
  isJackpotCounter = false;
  jackpots$: BehaviorSubject<number> = new BehaviorSubject(0);
  inited = false;
  numberRank = 0;
  isDesktop = false;
  @Input() game: Game;
  @Input() isJpLobby;
  @Input() outstanding = false;
  @Input() grid: boolean;
  @Input() showGameTicker = true;
  @Input() active = true;
  @Input() setStreaming = false;
  @Input() category: any;
  @Input() theme: string;
  @Input() isVertical = false;
  @Input() isRounded = false;
  @Input() isCalendar = false;
  classStreaming = false;
  hls: Hls;
  pragmaticInfo: PragmaticInfo;

  @Output() turnPlay: EventEmitter<any> = new EventEmitter();
  @Output() height: EventEmitter<number> = new EventEmitter();

  @ViewChild("videoPlayer") videoPlayerElRef: ElementRef;

  private ref: HTMLElement;
  get refElement() {
    return this.ref;
  }
  subscription: any;
  globalVars!: GlobalVarsModel;

  jackpotService = inject(JackpotService);
  element = inject(ElementRef);
  renderer = inject(Renderer2);
  newBaseService = inject(NewBaseService);
  cd = inject(ChangeDetectorRef);
  sanitizer = inject(DomSanitizer);
  deviceService = inject(DeviceService);
  pragmaticService = inject(PragmaticService);

  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });

    this.isDesktop = this.deviceService.isDesktop();

    if (this.globalVars) {
      this.baseImage = this.globalVars.LOBBY_IMAGES;
    }
  }

  ngOnInit() {
    if (this.game && !this.inited) {
      this.setImage();
      if (this.game.lobby !== "QuickLaunch") {
        this.setBadges();
      }
      this.subscription = this.game.additionalData?.subscribe((data) => this.changed(data));
      if (this.game.numberRanking) {
        this.numberRank = this.game.numberRanking;
      }
      this.inited = true;
    }
  }

  async ngAfterViewInit() {
    if (!this.game) return;
    this.ref = this.element.nativeElement;
    this.setSize();
  }

  @HostBinding("attr.priority")
  get priority() {
    return;
  }

  @HostListener("mouseover")
  onMouseOver() {
    if (!this.ref) return;
    this.renderer.addClass(this.ref, "hovered");
  }

  @HostListener("mouseleave")
  onMouseLeave() {
    if (!this.ref) return;
    this.renderer.removeClass(this.ref, "hovered");
  }

  changed(data) {
    if (!this.game) return;
    if (data && data.jp && data.timer) {
      this.isJackpotCounter = true;
      this.jackpots$ = this.jackpotService.getTicker(data.jp, data.timer, this.game.GameId);
    }
  }

  errorImageHandler(ionImgElement: IonImg) {
    ionImgElement.src = this.defaultImg;
  }

  setSize() {
    if (this.outstanding) return;

    const sizeClass = this.grid ? "in-grid" : this.game.gameTileSize.toLowerCase();

    this.renderer.addClass(this.ref, sizeClass);
  }

  setImage() {
    let shape = this.game.gameTileSize.toLowerCase().includes("square") ? "Square" : "Rectangle";
    const imageType = this.game.imageType || "jpg";

    if (this.grid && !this.isCalendar) {
      shape = "Square";
    }
    if (this.isVertical) {
      shape = "Vertical";
    }
    if (this.isRounded) {
      shape = "Round";
    }

    const image = this.game.Name.replace(/ /g, "") + "_" + shape + `.${imageType}`;
    this.game.Image = this.baseImage + image;
    this.defaultImg = this.baseImage + "default_" + shape + ".jpg";
  }

  playGame(demo?: boolean) {
    if (this.active) {
      this.turnPlay.emit({ demo: demo, game: this.game });
    }
  }

  isLoaded() {
    this.loaded = true;
    this.cd.detectChanges();
    this.getPragmaticGameInfo();
  }

  isLoaded2() {
    this.loaded = true;
    this.cd.detectChanges();
    setTimeout(() => {
      this.playVideo();
    }, 100);
  }

  setBadges() {
    if (!this.game.Badges) return;
    this.badges = this.game.Badges.map((badge) => badge.toLowerCase());
  }

  infoGame(game) {
    alert("info game: " + game.Name + " Next functionality to add");
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.hls) {
      this.hls.stopLoad();
      this.hls.destroy();
    }
    // if (this.totalSeatedPlayers) {
    //   this.pragmaticService.disconnect();
    // }
  }

  getsafeUrl(url: any) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  playVideo() {
    const videoElement = this.videoPlayerElRef.nativeElement;
    if (this.game.gameTileSize === "Smallsquare") {
      this.classStreaming = true;
    }
    if (Hls.isSupported() && this.game.streamUrl) {
      this.hls = new Hls();
      this.hls.loadSource(this.game.streamUrl);
      this.hls.attachMedia(videoElement);
      this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
        videoElement.muted = true;
        videoElement.volume = 0;
        videoElement.play();
      });
      //error this.hls
      this.hls.on(Hls.Events.ERROR, (_event, data) => {
        if (data.fatal) {
          switch (data.type) {
            case Hls.ErrorTypes.NETWORK_ERROR:
              this.hls.startLoad();
              break;
            case Hls.ErrorTypes.MEDIA_ERROR:
              this.hls.recoverMediaError();
              break;
            default:
              this.setStreaming = false;
              this.classStreaming = false;
              this.hls.stopLoad();
              this.hls.destroy();
              break;
          }
        } else if (data.type === "mediaError") {
          this.hls.recoverMediaError();
        } else if (
          data.details === "levelLoadTimeOut" ||
          data.details === "fragLoadError" ||
          data.details === "levelLoadError"
        ) {
          this.hls.stopLoad();
          this.hls.destroy();
          this.setStreaming = false;
          this.classStreaming = false;
        }
      });
    } else if (videoElement.canPlayType("application/vnd.apple.mpegurl") && this.game.streamUrl) {
      videoElement.src = this.game.streamUrl;
      videoElement.muted = true;
      videoElement.volume = 0;
      videoElement.play();
      //error native elemet
      videoElement.addEventListener("error", (_event) => {
        this.setStreaming = false;
        this.classStreaming = false;
      });
      videoElement.addEventListener("pause", (_event) => {
        videoElement.play();
      });
    } else {
      this.setStreaming = false;
      this.classStreaming = false;
    }
  }

  private getPragmaticGameInfo() {
    if (
      this.globalVars?.FEATURES?.CAS_PragmaticGameInfo &&
      this.game?.ProviderGameId === this.globalVars?.FEATURES?.CAS_PragmaticGameInfo
    ) {
      this.pragmaticService?.getMessages(this.game?.ProviderGameId).subscribe({
        next: (data: PragmaticInfo) => {
          this.pragmaticInfo = data;
        },
        error: (error) => {
          console.error("Error:", error);
        },
      });
    }
  }
}
