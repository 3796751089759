<div class="modal-container">
  <div class="modal-content">
    <h1 class="single-title-bar">
      <!--Configurar cookies-->
      Importante
    </h1>
    <div>
      <div>
        <p>{{ modalConfig?.messageAlert }}</p>
      </div>
      <div>
        <ion-img [src]="modalConfig?.imgPath" alt="logo android" />
      </div>
      <div>
        <ion-buttons class="modal-buttons">
          <ion-button (click)="closeModal()">Cerrar</ion-button>
          <ion-button (click)="goToAppLink()">Descagar</ion-button>
        </ion-buttons>
      </div>
    </div>
  </div>
</div>
