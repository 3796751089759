/* eslint-disable */
/**
 * CookieNames
 * Cookie names to be used inside the app
 */
export enum CookieNames {
  CodereUser = 'usr',
  CodereUserRegion = 'codere_userregion',
  CodereCookie = 'codere_cookie', // Indica si se ha aceptado la política de cookies
  CoderePromotionCode = 'codere_codepromotion',
  Idioma = 'idioma',
  Cuota = 'cuota', // Tipo de cuota
  NativeDeviceType = 'NativeDeviceType',
  AdviceSlots = 'adviceSlots',
  BonusSignupRequired = 'BonusSignupRequired',
  MigrationChecked = 'MigrationChecked',
  MigrationCompleted = 'MigrationCompleted',
  MexosBannerClick = 'banner_click',
  DefaultDepositOption = 'defaultDepositOptionSelected',
  DefaultChargeOption = 'defaultChargeOptionSelected',
  WarningCoderePassword = 'warningPassCodere',
  ForgotPassword = 'forgotPasswordCodere',
  IforiumPromo = 'ifr_bns',
  VipStats = 'vipstats',
  DyId = '_dyid',
  DyServer = '_dyid_server',
  DySession = '_dyjsession',
  IsPromotable = 'ispromotablevip',
  LoggedInApplicationSite = 'LoggedInApplicationSite',
  UserInfo = 'userInfo',
  CsrfControl = 'csrfControl',
  ConsentStatusCookies = 'consentStatus'
}
