<section class="sb-grid-item" [class]="'sb-grid-content--' + mode">
  <div class="sb-grid-item--row background-color-light">
    <div
      class="sb-grid-item--content"
      [ngClass]="{'content-hidden': itemHasContent()}"
      tappable
      (click)="navigate?.emit(event)"
      #content
    >
      <ng-content select="[content]"></ng-content>
      <ng-content #scoreboard select="[scoreboard]"></ng-content>
    </div>
    <div class="sb-grid-item--bets" [ngClass]="displayCss === 'block' ? 'bets-custom':''" #bets *ngIf="hasBets">
      <ng-content select="[race-button]"></ng-content>
      <ng-content select="[sb-markets]"></ng-content>
    </div>
    <div class="sb-grid-item--bets sb-button-custom-container" *ngIf="!hasBets" #noBets>
      <button
        class="sb-button--bet sb-button--bet-text background-color-button color-dark sb-button-custom"
        tappable
        (click)="navigate?.emit(event)"
      >
        Apuesta ya
        <i class="codere-icon icon-arrow-right"></i>
      </button>
    </div>
    <ng-content select="[counter-button]"></ng-content>
  </div>
</section>
