/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable, inject } from '@angular/core';
import { Observable, of, BehaviorSubject, concat } from 'rxjs';
import { takeWhile, concatMap, startWith, pairwise, tap, delay, skip } from 'rxjs/operators';
import { GlobalVarsModel } from '../../models/ModelGlobalsVar';
import { NewBaseService } from '../newBase.service';
import { Platform } from '@ionic/angular';
@Injectable({ providedIn: 'root' })
export class BufferFacade {
  // refresh time
  ticket: number;
  live: number;
  regular: number;
  load$ = new BehaviorSubject<string>('');
  headers$ = new BehaviorSubject<string>('');
  highlights: number;
  veridasPolling: number;
  globalVars!: GlobalVarsModel;
  isDesktop = false;

  newBaseService = inject(NewBaseService);
  platform = inject(Platform);
  constructor() {
    this.isDesktop = this.platform.is('desktop');

    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
    if (this.isDesktop) {
      this.ticket = this.globalVars.TK_DESK_RT;
    } else {
      this.ticket = this.globalVars.TK_RT;
    }

    this.live = this.globalVars.LIVE_RT;
    this.regular = this.globalVars.HOME_RT;
    this.highlights = this.globalVars.HIGHLIGHTS_RT;
    this.veridasPolling = this.globalVars.VERIDAS_POLLING_RT;
  }

  newAuxBuffer(
    service: any,
    apiCall: string,
    params: any,
    timer: string,
    polling$: BehaviorSubject<boolean>
  ): Observable<any> {
    const whenToRefresh$ = of('').pipe(
      delay(this[timer]),
      tap((_) => {
        this.load$.next('');

        this.load$.closed = false;
      }),
      skip(1)
    );

    const polled$ = this.load$.pipe(
      takeWhile((_) => polling$.getValue()),
      concatMap((_) => concat(service[apiCall](params), whenToRefresh$)),
      startWith(null),
      pairwise()
    );
    return polled$;
  }
}
