<ion-content>
  <ion-grid class="body" *ngIf="type === 'internetFail'">
    <ion-row>
      <ion-col size="12">
        <header>
          <div class="logo">
            <svg viewBox="0 0 113.4 31.4">
              <g>
                <path
                  class="st0"
                  d="M15.9,21.6c-0.9,1.4-2.1,2.2-3.5,2.2c-2.7,0-4.7-2.1-4.7-5c0-2.5,1.6-5,4.6-5c2.3,0,3.2,1.5,3.6,2.3l0.1,0.1h5
    l-0.1-0.3c-1.2-3.7-4.7-6.3-8.6-6.3c-2.4,0-4.6,0.9-6.3,2.6c-1.8,1.7-2.8,4.1-2.8,6.5c0,5.1,4.1,9.2,9.1,9.2c4,0,7.2-2.3,8.6-6.1
    l0.1-0.4h-5L15.9,21.6z M31.4,9.7c-5,0-9.2,4.1-9.2,9.1s4.1,9.2,9.1,9.2c5.1,0,9.2-4.1,9.2-9.1c0,0,0,0,0,0
    C40.5,13.8,36.5,9.7,31.4,9.7C31.5,9.7,31.4,9.7,31.4,9.7z M31.5,23.6c-2.6-0.1-4.6-2.2-4.5-4.8c0.1-2.5,2-4.4,4.5-4.5l0,0
    c2.6-0.1,4.7,1.9,4.8,4.5c0.1,2.6-1.9,4.7-4.5,4.8C31.6,23.6,31.5,23.6,31.5,23.6z M55.7,11.5c-1.4-1.2-3.1-1.8-4.9-1.9
    c-5,0-8.8,4.1-8.8,9.1c0,5,4.1,9.1,9.1,9.1s9.1-4.1,9.1-9.1V2.6h-4.6V11.5L55.7,11.5z M51.1,23.6c-2.5,0-4.6-2.1-4.6-4.6
    c0-2.5,2.1-4.6,4.6-4.6c2.5,0,4.6,2.1,4.6,4.6C55.7,21.6,53.7,23.6,51.1,23.6L51.1,23.6z M74,23.4c-2.2,1-4.9,0.3-6.4-1.6l12.8-5.4
    l-0.5-1.2c-2-4.7-6.8-7-11.8-4.8c-5,2.1-7.2,7.3-5.2,12s7.6,6.6,12.6,4.5c2.2-0.9,4.1-2.6,5.3-4.8L77,20.2
    C76.5,21.6,75.4,22.8,74,23.4z M69.6,13.9c1.7-0.7,3.7-0.4,5,0.9l-8.1,3.4C66.6,16.3,67.8,14.6,69.6,13.9z M106.5,20.2
    c-0.5,1.4-1.6,2.6-3,3.1c-2.2,1-4.9,0.3-6.4-1.5l12.8-5.4l-0.5-1.2c-2-4.7-6.8-6.9-11.8-4.8c-5,2.1-7.2,7.3-5.2,12
    c2,4.7,7.6,6.6,12.6,4.5c2.2-0.9,4.1-2.6,5.2-4.8L106.5,20.2z M99,13.9c1.7-0.7,3.7-0.4,5,0.9l-8,3.4C96,16.3,97.2,14.6,99,13.9z
     M82.7,17.7v9.8h4.5v-9.2c0-2.8,1.6-4.1,3.8-4.1v-4.1C85.8,10.1,82.7,13.5,82.7,17.7z"
                  fill="#334048"
                />
              </g>
            </svg>
          </div>
        </header>

        <main>
          <div class="conexion">
            <svg viewBox="0 0 631 548.9">
              <g>
                <g>
                  <g id="Wi-Fi">
                    <g>
                      <path
                        d="M315.5,393c-27.6,0-50,22.4-50,50s22.4,50,50,50c27.6,0,50-22.4,50-50C365.5,415.4,343.1,393,315.5,393z
           M614.9,195.8C540.2,106.8,431,55.8,315.5,55.8s-224.7,51-299.4,139.9c-9.9,11.8-8.4,29.4,3.4,39.4c5.2,4.4,11.6,6.6,17.9,6.6
          c8,0,15.9-3.4,21.4-10c64.1-76.2,157.6-120,256.7-120s192.6,43.7,256.7,120c5.5,6.6,13.5,10,21.4,10c6.4,0,12.7-2.1,18-6.6
          C623.3,225.2,624.9,207.6,614.9,195.8z M315.5,167.6c-82.5,0-160.5,36.4-213.9,99.9c-9.9,11.8-8.4,29.4,3.4,39.4
          c5.2,4.4,11.6,6.6,18,6.6c8,0,15.9-3.4,21.4-10c42.7-50.8,105.1-80,171.1-80s128.4,29.2,171.1,80c5.5,6.6,13.5,10,21.4,10
          c6.4,0,12.7-2.1,18-6.6c11.8-9.9,13.4-27.6,3.4-39.4C476,204,398,167.6,315.5,167.6z M315.5,279.4c-49.6,0-96.3,21.9-128.3,59.9
          c-9.9,11.8-8.4,29.4,3.4,39.4c5.2,4.4,11.6,6.6,18,6.6c8,0,15.9-3.4,21.4-10c21.4-25.4,52.6-40,85.5-40s64.2,14.6,85.5,40
          c5.5,6.6,13.5,10,21.4,10c6.4,0,12.7-2.1,18-6.6c11.8-9.9,13.4-27.6,3.4-39.4C411.8,301.2,365,279.4,315.5,279.4z"
                        fill="#334048"
                      />
                    </g>
                  </g>
                </g>
                <path
                  d="M469.2,343.4c-33.1,0-60,26.9-60,60c0,33.1,26.9,60,60,60c33.1,0,60-26.9,60-60
    C529.2,370.2,502.3,343.4,469.2,343.4z M491.3,426.2l-8.8,0l-13.3-15.9l-13.3,15.9l-8.9,0c-0.6,0-1.1-0.5-1.1-1.1
    c0-0.3,0.1-0.5,0.3-0.7l17.4-20.8l-17.4-20.7c-0.2-0.2-0.3-0.4-0.3-0.7c0-0.6,0.5-1.1,1.1-1.1l8.9,0l13.3,15.9l13.3-15.9l8.8,0
    c0.6,0,1.1,0.5,1.1,1.1c0,0.3-0.1,0.5-0.3,0.7l-17.4,20.7l17.4,20.8c0.2,0.2,0.3,0.4,0.3,0.7C492.4,425.7,491.9,426.2,491.3,426.2z
    "
                  fill="#F5222D"
                />
              </g>
            </svg>
          </div>
          <p>
            Parece que no dispones de una conexión a internet. Por favor pulsa el botón para intentar establecer una
            conexión.
          </p>
        </main>
      </ion-col>
    </ion-row>
  </ion-grid>

  <ion-grid class="body" *ngIf="type === 'geolocation'">
    <ion-row>
      <ion-col size="12">
        <div class="logo">
          <svg viewBox="0 0 113.4 31.4">
            <g>
              <path
                class="st0"
                d="M15.9,21.6c-0.9,1.4-2.1,2.2-3.5,2.2c-2.7,0-4.7-2.1-4.7-5c0-2.5,1.6-5,4.6-5c2.3,0,3.2,1.5,3.6,2.3l0.1,0.1h5
l-0.1-0.3c-1.2-3.7-4.7-6.3-8.6-6.3c-2.4,0-4.6,0.9-6.3,2.6c-1.8,1.7-2.8,4.1-2.8,6.5c0,5.1,4.1,9.2,9.1,9.2c4,0,7.2-2.3,8.6-6.1
l0.1-0.4h-5L15.9,21.6z M31.4,9.7c-5,0-9.2,4.1-9.2,9.1s4.1,9.2,9.1,9.2c5.1,0,9.2-4.1,9.2-9.1c0,0,0,0,0,0
C40.5,13.8,36.5,9.7,31.4,9.7C31.5,9.7,31.4,9.7,31.4,9.7z M31.5,23.6c-2.6-0.1-4.6-2.2-4.5-4.8c0.1-2.5,2-4.4,4.5-4.5l0,0
c2.6-0.1,4.7,1.9,4.8,4.5c0.1,2.6-1.9,4.7-4.5,4.8C31.6,23.6,31.5,23.6,31.5,23.6z M55.7,11.5c-1.4-1.2-3.1-1.8-4.9-1.9
c-5,0-8.8,4.1-8.8,9.1c0,5,4.1,9.1,9.1,9.1s9.1-4.1,9.1-9.1V2.6h-4.6V11.5L55.7,11.5z M51.1,23.6c-2.5,0-4.6-2.1-4.6-4.6
c0-2.5,2.1-4.6,4.6-4.6c2.5,0,4.6,2.1,4.6,4.6C55.7,21.6,53.7,23.6,51.1,23.6L51.1,23.6z M74,23.4c-2.2,1-4.9,0.3-6.4-1.6l12.8-5.4
l-0.5-1.2c-2-4.7-6.8-7-11.8-4.8c-5,2.1-7.2,7.3-5.2,12s7.6,6.6,12.6,4.5c2.2-0.9,4.1-2.6,5.3-4.8L77,20.2
C76.5,21.6,75.4,22.8,74,23.4z M69.6,13.9c1.7-0.7,3.7-0.4,5,0.9l-8.1,3.4C66.6,16.3,67.8,14.6,69.6,13.9z M106.5,20.2
c-0.5,1.4-1.6,2.6-3,3.1c-2.2,1-4.9,0.3-6.4-1.5l12.8-5.4l-0.5-1.2c-2-4.7-6.8-6.9-11.8-4.8c-5,2.1-7.2,7.3-5.2,12
c2,4.7,7.6,6.6,12.6,4.5c2.2-0.9,4.1-2.6,5.2-4.8L106.5,20.2z M99,13.9c1.7-0.7,3.7-0.4,5,0.9l-8,3.4C96,16.3,97.2,14.6,99,13.9z
 M82.7,17.7v9.8h4.5v-9.2c0-2.8,1.6-4.1,3.8-4.1v-4.1C85.8,10.1,82.7,13.5,82.7,17.7z"
                fill="#334048"
              />
            </g>
          </svg>
        </div>
      </ion-col>

      <ion-col size="12">
        <div class="location">
          <svg viewBox="0 0 500 419.3">
            <g>
              <g>
                <path
                  class="st0"
                  d="M447.8,108.1l-17,30.7l24.1,11V344l-73.1-36.2v-76.6h-22.2v77l-95.3,49V163.1l45.4-22.2l-18.1-32.7
                        l-39.7,20.3L133.5,67.7L13.2,128.4v273.1l120.2-58.7l118.3,58.7L370.4,342l116.3,59.5V128.4L447.8,108.1z M123.4,310.1L50.2,344
                        V149.8l73.1-35.8V310.1z M240.9,357.2l-95.3-46.7V114l95.3,49V357.2z"
                  fill="#334048"
                />
              </g>
              <g>
                <path
                  class="st0"
                  d="M370.1,13.8c-34.2,0-62,29.2-62,65c0,10.8,2.6,21.4,7.4,30.9l50.2,95.2c1.8,3.5,6.9,3.5,8.8,0l50.2-95.3
                        c4.8-9.4,7.4-20.1,7.4-30.8C432.1,42.9,404.3,13.8,370.1,13.8z M370.4,103.4c-16.7,0-30.2-13.6-30.2-30.2s13.6-30.2,30.2-30.2
                        s30.2,13.6,30.2,30.2C400.6,89.8,387,103.4,370.4,103.4z"
                  fill="#334048"
                />
              </g>
            </g>
          </svg>
        </div>

        <p>Discúlpanos, pero no estás autorizado en utilizar esta aplicación en tu zona geográfica</p>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
