<ng-container *ngIf="isDesktop; else mobileView">
  <div class="volatility-filter">
    <ion-card
      *ngFor="let filter of filterGroup.filters"
      class="volatility-filter-card"
      (click)="selectFilter(filter)"
      [class.selected]="filter.selected"
    >
      <ion-card-header>
        <ion-card-subtitle
          ><ion-icon src="assets/global/img/{{ filter.icon }}.svg" class="icon"></ion-icon
        ></ion-card-subtitle>
        <ion-card-title class="title">{{ filter.text | translate }} ({{ filter.count || 0 }})</ion-card-title>
      </ion-card-header>

      <ion-card-content class="volatility-filter-content">
        {{ filter.subtext | translate }}
      </ion-card-content>
    </ion-card>
  </div>
</ng-container>

<ng-template #mobileView>
  <div class="volatility-filter">
    <ion-card
      *ngFor="let filter of filterGroup.filters"
      class="volatility-filter-card"
      (click)="selectFilter(filter)"
      [class.selected]="filter.selected"
    >
      <ion-card-content class="volatility-filter-content">
        <ion-icon src="assets/global/img/{{ filter.icon }}.svg" class="icon"></ion-icon>
        <div class="volatility-filter-text-content">
          <div class="title">{{ filter.text | translate }} ({{ filter.count || 0 }})</div>
          <div class="text">{{ filter.subtext | translate }}</div>
        </div>
      </ion-card-content>
    </ion-card>
  </div>
</ng-template>
