<ng-container *ngIf="!isMobile; else mobileView">
  <ion-header class="has-titleBar">
    <user-section-title [sectionTitle]="'bettingOptions'"></user-section-title>
  </ion-header>

  <ion-content class="background-color-muted">
    <div class="is-narrow-content">
      <!--Tipo de cuota-->
      <div class="subtype is-text-uppercase">{{ 'oSubT' | translate }}</div>
      <ion-list class="user-list" radio-group (ionChange)="changeOddsType()">
        <ion-radio-group [(ngModel)]="selectedOddType">
          <div class="user-input-container">
            <ion-item class="ion-item-radio">
              <!--Decimal-->
              <ion-radio value="dec" name="cuota-dec">{{ 'tOddDec' | translate }}</ion-radio>
            </ion-item>
          </div>
          <div class="user-input-container">
            <ion-item class="ion-item-radio">
              <!--Fraccional-->
              <ion-radio value="fra" name="cuota-fra">{{ 'tOddFra' | translate }}</ion-radio>
            </ion-item>
          </div>
          <div class="user-input-container">
            <ion-item class="ion-item-radio">
              <!--Americana-->
              <ion-radio value="ame" name="cuota-ame">{{ 'tOddAme' | translate }}</ion-radio>
            </ion-item>
          </div>
        </ion-radio-group>
      </ion-list>
      <!--Tipo de cuota-->
      <div class="subtype is-text-uppercase">{{ 'bettingOptions' | translate }}</div>
      <ion-list class="user-list" radio-group (ionChange)="changeCuotaAccept()">
        <ion-radio-group [(ngModel)]="selectedCuotaChange">
          <div class="user-input-container">
            <ion-item class="ion-item-radio">
              <!--Aceptar cualquier cambio de cuota-->
              <ion-radio value="0" name="cuota-0">{{ 'cuotachange0' | translate }}</ion-radio>
            </ion-item>
          </div>
          <div class="user-input-container">
            <ion-item class="ion-item-radio">
              <!--Aceptar cuotas m&aacute;s altas-->
              <ion-radio value="1" name="cuota-1">{{ 'cuotachange1' | translate }}</ion-radio>
            </ion-item>
          </div>
          <div class="user-input-container">
            <ion-item class="ion-item-radio">
              <!--No aceptar cambio de cuotas-->
              <ion-radio value="2" name="cuota-2">{{ 'cuotachange2' | translate }}</ion-radio>
            </ion-item>
          </div>
        </ion-radio-group>
      </ion-list>
    </div>

    <codere-footer></codere-footer>
  </ion-content>
</ng-container>

<ng-template #mobileView>
  <ion-header class="has-titleBar">
    <user-section-title [sectionTitle]="'bettingOptions'"></user-section-title>
  </ion-header>

  <ion-content class="background-color-muted">
    <!--Tipo de cuota-->
    <div class="subtype is-text-uppercase">{{ 'oSubT' | translate }}</div>
    <ion-list class="user-list m-bottom-0" radio-group (ionChange)="changeOddsType()">
      <ion-radio-group [(ngModel)]="selectedOddType">
        <div class="user-input-container">
          <ion-item class="ion-item-radio">
            <!--Decimal-->
            <ion-radio value="dec" name="cuota-dec" mode="md">{{ 'tOddDec' | translate }}</ion-radio>
          </ion-item>
        </div>
        <div class="user-input-container">
          <ion-item class="ion-item-radio">
            <!--Fraccional-->
            <ion-radio value="fra" name="cuota-fra" mode="md">{{ 'tOddFra' | translate }}</ion-radio>
          </ion-item>
        </div>
        <div class="user-input-container">
          <ion-item class="ion-item-radio">
            <!--Americana-->
            <ion-radio value="ame" name="cuota-ame" mode="md">{{ 'tOddAme' | translate }}</ion-radio>
          </ion-item>
        </div>
      </ion-radio-group>
    </ion-list>
    <!--Tipo de cuota-->
    <div class="subtype is-text-uppercase">{{ 'bettingOptions' | translate }}</div>
    <ion-list class="user-list m-bottom-0" radio-group (ionChange)="changeCuotaAccept()">
      <ion-radio-group [(ngModel)]="selectedCuotaChange">
        <div class="user-input-container">
          <ion-item class="ion-item-radio">
            <!--Aceptar cualquier cambio de cuota-->
            <ion-radio value="0" name="cuota-0" mode="md">{{ 'cuotachange0' | translate }}</ion-radio>
          </ion-item>
        </div>
        <div class="user-input-container">
          <ion-item class="ion-item-radio">
            <!--Aceptar cuotas m&aacute;s altas-->
            <ion-radio value="1" name="cuota-1" mode="md">{{ 'cuotachange1' | translate }}</ion-radio>
          </ion-item>
        </div>
        <div class="user-input-container">
          <ion-item class="ion-item-radio">
            <!--No aceptar cambio de cuotas-->
            <ion-radio value="2" name="cuota-2" mode="md">{{ 'cuotachange2' | translate }}</ion-radio>
          </ion-item>
        </div>
      </ion-radio-group>
    </ion-list>

    <div class="sendForm">
      <ion-button expand="full" (click)="update()">{{'tInPUpd' | translate}}<!--Actualizar--></ion-button>
    </div>

    <codere-footer></codere-footer>
  </ion-content>
</ng-template>
