import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CdrInsightsService } from '@providers/tracking/cdr-core/cdr-insights/shared/cdr-insights.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  exports: [],
  providers: []
})
export class CdrInsightsModule {}
