<div class="contact-response-wrapper">
  <div class="contact-response">
    <h1>{{'tContactSubject' | translate}}:</h1>
    <p>{{subject}}</p>
  </div>
  <div *ngIf="response" class="contact-response">
    <h1>{{'tContactAnswer' | translate}}:</h1>
    <p [innerHtml]="response"></p>
  </div>
  <div *ngIf="question" class="contact-response">
    <h1>{{'tContactQuestion' | translate}}:</h1>
    <p>{{ question }}</p>
  </div>
</div>
