import { Pipe, PipeTransform, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'betTypeName' })
export class BetTypeNamePipe implements PipeTransform {
  public translate = inject(TranslateService);

  transform(value: number): string {
    switch (value) {
      case 0:
        return this.translate.instant('tSen'); //"SENCILLAS";

      case 1:
        return this.translate.instant('tAcu'); //"ACUMULADAS";

      case 2:
        return this.translate.instant('tMul'); //"MULTIPLES";

      case 3:
        return this.translate.instant('tMWay'); //"MULTI-SELECCION";

      default:
        return value.toString();
    }
  }
}
