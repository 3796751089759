import { CommonModule } from '@angular/common';
import { Component, Input, Output, EventEmitter, inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'sb-stream-market',
  templateUrl: './sb-stream-market.html',
  styleUrls: ['./sb-stream-market.scss'],
  standalone: true,
  imports: [CommonModule]
})
export class SbStreamMarketComponent {
  @Input() streamingSource: any;
  @Output() stream: EventEmitter<any> = new EventEmitter();
  video: boolean;

  public buttonText: string;
  private _sanitizer = inject(DomSanitizer);

  constructor() {
    this.buttonText = 'Watch Streaming';
  }

  toggleVideo() {
    this.video = !this.video;
    this.stream.emit();
  }
}
