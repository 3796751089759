<!-- eslint-disable -->
<div *ngIf="allHistory$ | async as allHistory" class="cs-history background-color-dark w-100">
  <div class="cs-history--header--filters">
    <div class="cs-history--filters w-100 filter-labels">
      <div class="cs-history--filters-button p-0">
        <span>{{ 'Historial_period' | translate }}</span>
      </div>
      <div class="cs-history--filters-button p-0">
        <span>{{ 'Historial_status' | translate }}</span>
      </div>
      <div class="cs-history--filters-button p-0">
        <span>{{ 'Historial_type' | translate }}</span>
      </div>
    </div>
    <div class="cs-history--filters w-100 p-1">
      <div
        *ngIf="days$ | async as days"
        class="cs-history--filters-button background-color-2024"
        tappable
        (click)="setFilter(filtersDates)"
      >
        <span>{{ days?.label }}</span>
        <i class="codere-icon icon-arrow-bottom color-white"></i>
      </div>
      <div
        *ngIf="state$ | async as state"
        class="cs-history--filters-button background-color-2024"
        tappable
        (click)="setFilter(filtersStates)"
      >
        <span>{{ state?.label }}</span>
        <i class="codere-icon icon-arrow-bottom color-white"></i>
      </div>
      <div
        *ngIf="type$ | async as type"
        class="cs-history--filters-button background-color-2024"
        tappable
        (click)="setFilter(filtersTypes)"
      >
        <span>{{ type?.label }}</span>
        <i class="codere-icon icon-arrow-bottom color-white"></i>
      </div>
    </div>
  </div>
  <div *ngIf="allHistory?.length > 0" class="cs-history--container background-color-light w-100">
    <div class="cs-history--header background-color-2024">
      <!-- <div *ngIf="historyFields.Referencia" class="cs-history--content item-type item-start">
        <span class="cs-history--text text-center">{{ 'tRef' | translate }}</span>
      </div> -->

      <div class="cs-history--content item-icon item-start">
        <span class="cs-history--text">{{ 'uPPExtractTip' | translate }}</span>
      </div>

      <div class="cs-history--content item-date item-start">
        <span class="cs-history--text">{{ 'uPPExtractFec' | translate }}</span>
      </div>
      <div class="cs-history--content item-type item-start">
        <span class="cs-history--text">{{ 'tMet' | translate }}</span>
      </div>

      <div class="cs-history--content item-amount item-end">
        <span class="cs-history--text">{{ 'uPPExtractCan' | translate }}</span>
      </div>

      <!-- <div *ngIf="historyFields.RetainedAmount" class="cs-history--content item-retained item-start">
        <span class="cs-history--text">Importe Retenido</span>
      </div> -->
      <!-- <div *ngIf="historyFields.Cuenta" class="cs-history--content item-type item-start">
        <span class="cs-history--text text-center">{{ 'tCue' | translate }}</span>
      </div> -->

      <div class="cs-history--content item-state item-start">
        <span class="cs-history--text">{{ 'CodereCardState' | translate }}</span>
      </div>

      <div class="cs-history--content item-icon item-end">
        <i item-right class="codere-icon p-left-3"></i>
      </div>
    </div>
    <div class="cs-history--list" [style.height.px]="utils.getModalHeight('ion-modal--height', 129)">
      <!-- 129px is the fixed size of headers(35px), filters(86px), paddings(8px) -->
      <!-- todo: revisar [isDesktop]="isDesktop" -->
      <cs-history-item
        *ngFor="let item of allHistory; let odd = odd"
        [class]="'hovering'"
        [history]="item"
        [options]="historyFields"
        [mode]="'white'"
        (navigate)="navigate($event)"
        (cancelPayment)="showCancelPendingAlert($event)"
      >
      </cs-history-item>
    </div>
  </div>
  <div *ngIf="allHistory?.length == 0 && loaded" class="cs-history--container background-color-light w-100">
    <p class="text-center color-dark py-2">No existe historial con estos filtros</p>
  </div>
</div>
