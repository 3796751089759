import { sidebarState } from '../reducers/sidebar.reducers';
import * as SidebarActions from '../actions/sidebar.actions';

import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { C_SportSidebar } from '@models/C_Sport';

@Injectable({ providedIn: 'root' })
export class SidebarFacade {
  store = inject(Store<sidebarState>);

  sidebar$: Observable<any>;
  sports: any[];
  highlights: any[];
  highlightsConfig: any[];

  selectedSport: C_SportSidebar = null;

  constructor() {
    this.sidebar$ = this.store.select((state) => state.sidebar);
    this.sidebar$.subscribe((sidebar: sidebarState) => {
      this.sports = sidebar.sports;
      this.highlights = sidebar.highlights;
      this.highlightsConfig = sidebar.highlightsConfig;
      if (this.selectedSport)
        this.selectedSport = sidebar.sports.find((i) => i.SportHandle == this.selectedSport.SportHandle);
    });
  }

  fetchCountries(sport) {
    this.store.dispatch(SidebarActions.fetchCountries(sport));
  }

  toggleSection(sport) {
    this.store.dispatch(SidebarActions.toggleSection({ sport }));
  }

  setSelectedSport(sport) {
    this.selectedSport = sport;
  }
}
