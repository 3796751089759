import { HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { LoadingController } from "@ionic/angular";
import { Observable, finalize, from, map, of, switchMap, throwError } from "rxjs";

import { DecouplingInfoPlayer } from "@models/I_DecouplingInfoPlayer";
import {
  CasinoSessionsLimits,
  DecoplingLimits,
  DepositsSessionsLimits,
  LimitsChangeResponse,
} from "@models/I_DecouplingLimits";
import { TypeLogout } from "@models/I_DecouplingLogout";
import { I_SelfExclusionRequest } from "@models/I_SelfExclusionDecoupling";
import {
  I_PreValidateSignupArg,
  I_PreValidateSignupResponseArg,
  I_SignupArgResponse,
  I_SignupFormArg,
} from "@models/I_SignupArgentinaDecoupling";
import { I_SignupPaForm, I_SignupPaResponse } from "@models/I_SignupPADecoupling";
import { LicenseTypes } from "@models/MasterData";
import { BaseService } from "./BaseService";
import { I_ComplianceResponseCO, I_SignupCoForm, I_SignupCoResponse } from "./I_SignupCODecoupling";

import { I_DecouplingTrackersCodereID } from "@models/I_DecouplingTrackersCodereID";
import { TrackersCodereId } from "@models/TrackingEvents";

const URL_SIGNUP = "services.accounts.signup";
const URL_LOGIN = "services.accounts.login";
const URL_PROFILE = "services.players.profile";
export interface DecouplingResponse<T> {
  value: T | null;
  isSuccess: boolean;
  isFailure: boolean;
  error: ErrorDecoupling;
}

export interface ErrorDecoupling {
  code: string;
  description: string;
  type: number;
}
//eslint-disable-next-line
export enum ErrorCodes {
  FORBIDDEN = 403,
  UNAUTHORIZED = 401,
  MAINTENACE = 410,
  CONFLICT = 409,
  LOTBA = 19,
}

@Injectable({
  providedIn: "root",
})
export class DecouplingUserServiceMSO extends BaseService {
  loadingCtrl = inject(LoadingController);
  loading: HTMLIonLoadingElement;
  loadingCounter = 0;

  constructor() {
    super();
  }

  public signupPa(data: I_SignupPaForm): Observable<I_SignupPaResponse> {
    return this.customPost<I_SignupPaResponse>(
      `${this.globalVars.DOMAIN}${URL_SIGNUP}${this.utils.isQA ? ".pa" : ""}/signup`,
      data,
      {},
      true,
    );
  }

  public signupCo(data: I_SignupCoForm): Observable<I_SignupCoResponse> {
    return this.customPost<I_SignupCoResponse>(`${this.globalVars.DOMAIN}/${URL_SIGNUP}/signup`, data, {}, true);
  }

  public signupArg(data: I_SignupFormArg): Observable<I_SignupPaResponse> {
    return this.customPost<I_SignupArgResponse>(`${this.globalVars.DOMAIN}/${URL_SIGNUP}/signup`, data, {}, true);
  }

  public preValidateSignupArg(data: I_PreValidateSignupArg): Observable<I_PreValidateSignupResponseArg> {
    return this.customPost<I_PreValidateSignupResponseArg>(
      `${this.globalVars.DOMAIN}/${URL_SIGNUP}/prevalidatesignup`,
      data,
      {},
      true,
    );
  }

  public logoutDecoupling(userID: string, type: TypeLogout = TypeLogout.AUTOMATICO) {
    let finalpath = URL_LOGIN;
    if (this.utils.isQA) {
      if (this.globalVars.licenseType === LicenseTypes.Nacional) {
        finalpath += ".es";
      }
      if (this.globalVars.licenseType === LicenseTypes.Panama) {
        finalpath += ".pa";
      }
    }
    const url = `${this.globalVars.DOMAIN}/${finalpath}/logout`;
    const headers = new HttpHeaders().append("X-Codere-Key", "a9bf68d9-7042-40af-aa28-83a0ea7a9c4f");
    return this.customPost<null>(url, { typeLogout: type, playerIdEncrypted: userID }, { headers }, true, [
      ErrorCodes.UNAUTHORIZED,
      ErrorCodes.CONFLICT,
    ]);
  }

  public refreshCookie() {
    let finalpath = URL_LOGIN;
    if (this.utils.isQA) {
      if (this.globalVars.licenseType === LicenseTypes.Nacional) {
        finalpath += ".es";
      }
      if (this.globalVars.licenseType === LicenseTypes.Panama) {
        finalpath += ".pa";
      }
    }
    const url = `${this.globalVars.DOMAIN}/${finalpath}/login/refreshcookie`;
    return this.customPost<null>(url, {}, {}, false, [ErrorCodes.UNAUTHORIZED]);
  }

  public decouplingApplyRequestChangeLimits(accepted: boolean) {
    let finalpath = URL_PROFILE;
    if (this.utils.isQA) {
      if (this.globalVars.licenseType === LicenseTypes.Nacional) {
        finalpath += ".es";
      }
      if (this.globalVars.licenseType === LicenseTypes.Panama) {
        finalpath += ".pa";
      }
    }
    const url = `${this.globalVars.DOMAIN}/${finalpath}/limits/applyrequestchange`;
    return this.customPost<null>(url, { accepted }, {}, true);
  }

  public decouplingCasinoRequestChangeLimits(limits: CasinoSessionsLimits) {
    let finalpath = URL_PROFILE;
    if (this.utils.isQA) {
      if (this.globalVars.licenseType === LicenseTypes.Nacional) {
        finalpath += ".es";
      }
      if (this.globalVars.licenseType === LicenseTypes.Panama) {
        finalpath += ".pa";
      }
    }
    const url = `${this.globalVars.DOMAIN}/${finalpath}/limits/casinosessions/requestchange`;
    return this.customPost<LimitsChangeResponse>(url, limits, {}, true);
  }

  public decouplingDepositsRequestChangeLimits(limits: DepositsSessionsLimits) {
    let finalpath = URL_PROFILE;
    if (this.utils.isQA) {
      if (this.globalVars.licenseType === LicenseTypes.Nacional) {
        finalpath += ".es";
      }
      if (this.globalVars.licenseType === LicenseTypes.Panama) {
        finalpath += ".pa";
      }
    }
    const url = `${this.globalVars.DOMAIN}/${finalpath}/limits/deposits/requestchange`;
    return this.customPost<LimitsChangeResponse>(url, limits, {}, true);
  }

  public decouplingGetLimits() {
    let finalpath = URL_PROFILE;
    if (this.utils.isQA) {
      if (this.globalVars.licenseType === LicenseTypes.Nacional) {
        finalpath += ".es";
      }
      if (this.globalVars.licenseType === LicenseTypes.Panama) {
        finalpath += ".pa";
      }
    }
    const url = `${this.globalVars.DOMAIN}/${finalpath}/profile/limits`;
    return this.customGet<DecoplingLimits>(url, true);
  }

  public decouplingDepositsRequestChangeLimitsWithForm(limits: DepositsSessionsLimits, approbed: boolean) {
    let finalpath = URL_PROFILE;
    if (this.utils.isQA) {
      if (this.globalVars.licenseType === LicenseTypes.Nacional) {
        finalpath += ".es";
      }
      if (this.globalVars.licenseType === LicenseTypes.Panama) {
        finalpath += ".pa";
      }
    }
    const url = `${this.globalVars.DOMAIN}/${finalpath}/limits/applysurvey`;
    return this.customPost<LimitsChangeResponse>(url, { ...limits, approbed }, {}, true);
  }
  public getInfoPlayer() {
    let finalpath = URL_PROFILE;
    if (this.utils.isQA) {
      if (this.globalVars.licenseType === LicenseTypes.Nacional) {
        finalpath += ".es";
      }
      if (this.globalVars.licenseType === LicenseTypes.Panama) {
        finalpath += ".pa";
      }
    }
    const url = `${this.globalVars.DOMAIN}/${finalpath}/profile/infoplayer`;
    return this.customGet<DecouplingInfoPlayer>(url, true);
  }

  public selfExclusionDecoupling(data: I_SelfExclusionRequest = {}) {
    let finalpath = URL_PROFILE;
    if (this.utils.isQA) {
      if (this.globalVars.licenseType === LicenseTypes.Nacional) {
        finalpath += ".es";
      }
      if (this.globalVars.licenseType === LicenseTypes.Panama) {
        finalpath += ".pa";
      }
    }
    const url = `${this.globalVars.DOMAIN}/${finalpath}/profile/autoexclusion`;
    return this.customPost<null | { url: string }>(url, data, {}, true);
  }
  public decouplingRegisterCOComplianceRecords() {
    return this.customPost<I_ComplianceResponseCO>(
      `${this.globalVars.DOMAIN}/${URL_PROFILE}/compliance/data/all`,
      {},
      {},
      false,
    );
  }

  public getTrackersCodereID(): Observable<TrackersCodereId[]> {
    let finalpath = URL_PROFILE;
    if (this.utils.isQA) {
      if (this.globalVars.licenseType === LicenseTypes.Nacional) {
        finalpath += ".es";
      }
      if (this.globalVars.licenseType === LicenseTypes.Panama) {
        finalpath += ".pa";
      }
    }
    const url = `${this.globalVars.DOMAIN}/${finalpath}/profile/trackers`;
    return this.customGet<I_DecouplingTrackersCodereID[]>(url, false).pipe(
      map((allTrackers) =>
        allTrackers.map((tracker) => ({
          cantidadapuesta: tracker.betAmount,
          categoriaevento: tracker.category,
          cuota: tracker.quota,
          deporte: tracker.sport,
          evento: tracker.event,
          fechaapuesta: tracker.betDate,
          idapuesta: tracker.betId,
          inplay: tracker.inPlay,
          mercado: tracker.market,
          trackType: tracker.trackerType,
          validUntil: tracker.validUntil,
          value: tracker.code,
        })),
      ),
    );
  }

  public createTrackersCodereID(newTrackers: I_DecouplingTrackersCodereID) {
    let finalpath = URL_PROFILE;
    if (this.utils.isQA) {
      if (this.globalVars.licenseType === LicenseTypes.Nacional) {
        finalpath += ".es";
      }
      if (this.globalVars.licenseType === LicenseTypes.Panama) {
        finalpath += ".pa";
      }
    }
    const url = `${this.globalVars.DOMAIN}/${finalpath}/profile/trackers`;
    return this.customPost<null>(url, newTrackers, {}, false);
  }

  public updateTrackersCodereID(newTrackers: I_DecouplingTrackersCodereID) {
    let finalpath = URL_PROFILE;
    if (this.utils.isQA) {
      if (this.globalVars.licenseType === LicenseTypes.Nacional) {
        finalpath += ".es";
      }
      if (this.globalVars.licenseType === LicenseTypes.Panama) {
        finalpath += ".pa";
      }
    }
    const url = `${this.globalVars.DOMAIN}/${finalpath}/profile/trackers`;
    return this.customPut<null>(url, newTrackers, {}, false);
  }

  private customHandleError(skiptDefaultError: ErrorCodes[] = []) {
    return (errorResponse: HttpErrorResponse): Observable<ErrorDecoupling | undefined> =>
      throwError(() => {
        const { status, error } = errorResponse;

        if (!skiptDefaultError.includes(status)) {
          switch (status) {
            case ErrorCodes.UNAUTHORIZED:
            case ErrorCodes.FORBIDDEN:
              this.events.publish("user:logout:soft");
              return undefined;
            case ErrorCodes.MAINTENACE:
              this.events.publish("user:serverfailmaintenace", error);
              return undefined;
          }
        }

        const newErr: ErrorDecoupling = {
          code: error.error?.code,
          type: error.error?.type || status,
          description: error.error?.description,
        };
        return newErr;
      });
  }

  private customPipe<T>(observable: Observable<T>, loading = false): Observable<T> {
    const loaderObservable = loading ? from(this.showLoader()) : of(null);

    return loaderObservable.pipe(
      switchMap(() =>
        observable.pipe(
          map((response) => {
            const data = response as DecouplingResponse<T>;

            if (data.isSuccess === false) {
              throwError(() => data.error);
            } else {
              return data.value || null;
            }
          }),
          finalize(() => {
            if (loading) {
              this.hideLoader();
            }
          }),
        ),
      ),
    );
  }

  private customGet<T>(url: string, loading = false, skiptDefaultError: ErrorCodes[] = []): Observable<T> {
    return this.customPipe<T>(this.myGet(url, false, this.customHandleError(skiptDefaultError)), loading);
  }
  private customPost<T>(
    url: string,
    data: any,
    options: any,
    loading = false,
    skiptDefaultError: ErrorCodes[] = [],
  ): Observable<T> {
    return this.customPipe<T>(
      this.myPost(url, data, options, false, this.customHandleError(skiptDefaultError)),
      loading,
    );
  }
  private customPut<T>(
    url: string,
    data: any,
    options: any,
    loading = false,
    skiptDefaultError: ErrorCodes[] = [],
  ): Observable<T> {
    return this.customPipe<T>(
      this.myPut(url, data, options, false, this.customHandleError(skiptDefaultError)),
      loading,
    );
  }
  private customDelete<T>(url: string, loading = false, skiptDefaultError: ErrorCodes[] = []): Observable<T> {
    return this.customPipe<T>(this.myDelete(url, false, this.customHandleError(skiptDefaultError)), loading);
  }

  private async showLoader() {
    if (this.loadingCounter === 0) {
      this.loadingCounter++;
      this.loading = await this.loadingCtrl.create({
        spinner: "crescent",
        backdropDismiss: false,
        cssClass: "is-loader",
        translucent: true,
      });
      await this.loading.present();
    } else {
      this.loadingCounter++;
    }
  }

  private async hideLoader() {
    this.loadingCounter--;
    if (this.loadingCounter <= 0) {
      this.loadingCounter = 0;
      await this.loading.dismiss();
    }
  }
}
