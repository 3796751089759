/* eslint-disable eqeqeq */
import { Component, ElementRef, EventEmitter, Input, Output, Renderer2, ViewChild, inject } from '@angular/core';
import { Bonus } from '@models/csbgonlinecasinobonuses/Bonus';
import { SlotsBonusesService } from '@providers/SlotsBonusesService';
import { SafeResourceUrl } from '@angular/platform-browser';
import { DeviceService } from '@services/device.service';
import { GlobalVarsModel } from '@models/ModelGlobalsVar';
import { NewBaseService } from '@providers/newBase.service';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { LobbyCasinoPage } from '@shared-casino-pages/lobby-casino/lobby-casino';
import { ContactPage } from '@mso-pages/contact/contact/contact';
import { LicenseTypes } from '@models/MasterData';
import { TrackingService } from '@providers/TrackingService';
import { Utils } from '@utils/Utils';
import { EventTypes } from '@models/TrackingEvents';
import { CASINO_PATHS } from '@shared-constants/routes';

@Component({
  selector: 'slot-bonus-detail',
  templateUrl: './slot-bonus-detail.html',
  styleUrls: ['./slot-bonus-detail.scss'],
  standalone: true,
  imports: [IonicModule, TranslateModule]
})

//eslint-disable-next-line
export class SlotBonusDetail {
  @Input() bonus: Bonus;
  iframeUrl: SafeResourceUrl;
  globalVars!: GlobalVarsModel;
  @ViewChild('iframe') iframe: ElementRef;
  cont = 0;
  eventClick = null;
  @Output() Refresh: EventEmitter<any> = new EventEmitter();
  isMobile = false;

  newBaseService = inject(NewBaseService);
  slotsBonusesService = inject(SlotsBonusesService);
  router = inject(Router);
  trackingService = inject(TrackingService);
  utils = inject(Utils);
  renderer = inject(Renderer2);
  deviceService = inject(DeviceService);

  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });

    this.isMobile = this.deviceService.isMobile();
  }

  ngOnInit() {
    this.iframeUrl = this.slotsBonusesService.getUserBonusDetailIframeUrl(this.bonus);
  }

  ngAfterViewInit() {
    this.iframe = this.renderer.selectRootElement('#iframe');
  }

  setCancelBtn() {
    const container = this.iframe.nativeElement.contentDocument.querySelector(
      'html > body > .wrapper > .left > .divtit'
    );

    if (container) {
      const div: HTMLDivElement = this.renderer.createElement('div');
      div.innerHTML =
        '<button #eventclick class="btnBonusCancel" style="all: unset; color: #FB3737 !important; text-decoration: underline 2px #FB3737;padding-top: 0.4rem;font-weight: 400;font-size: 0.9rem;cursor: pointer;"><span>Cancelar bono</span></button>';
      this.renderer.appendChild(container, div);
      const btn = this.iframe.nativeElement.contentDocument.querySelector(
        'html > body > .wrapper > .left > .divtit > div > .btnBonusCancel'
      );
      setTimeout(() => {
        this.eventClick = this.renderer.listen(btn, 'click', (ev) => {
          this.cancelBonus(ev);
        });
      }, 10);
    }
  }

  async cancelBonus(event) {
    const msn =
      'Si cancelas perderás de tu saldo la cantidad correspondiente al bono que está compuesta por el Importe actual de bono y las Ganancias del bono.';
    const alertOptions = {
      enableBackdropDismiss: false,
      title: 'BONO - ' + this.bonus.MarketingName,
      cssClass: 'bonusCancelCasino',
      message: msn,
      buttons: [
        {
          role: 'eliminar',
          text: 'Cancelar bono',
          handler: () => {
            this.cancelbonusService();
          }
        },
        {
          role: 'cancel',
          text: 'Quiero mi bono',
          handler: () => {}
        }
      ]
    };
    const alert = await this.utils.alertController.create(alertOptions);
    alert.present();
  }

  cancelbonusService() {
    const PT = '/playtech';
    const provider = this.bonus.RemoveUrl ? this.bonus.RemoveUrl : PT;
    this.slotsBonusesService
      .cancelBonusCasino(this.bonus.BonusInstanceCode, this.globalVars.user.username, provider)
      .subscribe(async (result) => {
        if (result.Error === '0') {
          this.trackingService.track({
            eventType: EventTypes.CasinoBonusCancelActiveBonus,
            description: `Result of cancelCasinoBonus, invoked by accepting ${this.bonus.BonusInstanceCode} of user ${this.globalVars.user.username}`,
            additionalData: {
              result: result
            }
          });
          this.Refresh.emit(true);
        } else {
          //"Error": "UMS-67" Sesión de juego abierta
          //"Error":"UMS-105" No se a encontrado el bono que borrar
          let msn = '';
          if (result.Error == 'UMS-67') {
            msn = 'Lo sentimos no se ha podido cancelar tu bono por tener una sesión de juego abierta';
            const alertOptions = {
              enableBackdropDismiss: false,
              title: 'Error',
              message: msn,
              buttons: [
                {
                  role: 'cancel',
                  text: 'Ok',
                  handler: () => {}
                }
              ]
            };
            const alert = await this.utils.alertController.create(alertOptions);
            alert.present();
          } else {
            msn =
              'Lo sentimos ha habido un problema al cancelar tu bono, por favor ponte en contacto con nuestro servicio de atención al cliente';
            const alertOptions = {
              enableBackdropDismiss: false,
              title: 'Error',
              message: msn,
              buttons: [
                {
                  role: 'ok',
                  text: 'Contacto',
                  handler: () => {
                    this.openContact();
                  }
                },
                {
                  role: 'cancel',
                  text: 'Ok',
                  handler: () => {}
                }
              ]
            };
            const alert = await this.utils.alertController.create(alertOptions);
            alert.present();
          }
        }
      });
  }

  openContact() {
    this.router.navigate(['/' + ContactPage]);
  }

  playBonus() {
    let params = undefined;
    if (
      this.globalVars.FEATURES.CAS_BonusBalanceBDRedirectMOB !== undefined &&
      this.globalVars.FEATURES.CAS_BonusBalanceBDRedirectPC !== undefined
    ) {
      params = this.isMobile
        ? JSON.parse(this.globalVars.FEATURES.CAS_BonusBalanceBDRedirectMOB)
        : JSON.parse(this.globalVars.FEATURES.CAS_BonusBalanceBDRedirectPC);
    }
    let lobby = this.globalVars.FEATURES.Lobbies.find((lb) => lb.LobbyName.toLowerCase().indexOf('playtech') < 0);

    lobby === undefined ? (lobby = this.globalVars.FEATURES.Lobbies[0].LobbyName) : (lobby = lobby.LobbyName);

    if (params !== undefined && params !== '') {
      this.router.navigate([`/${CASINO_PATHS.BASE}/${CASINO_PATHS.CasinoPage}`], { queryParams: params });
    } else {
      if (!!this.bonus.SelectedGames && this.bonus.SelectedGames.length == 1) {
        // Navigate to casino lobby and play game
        this.router.navigate([`/${CASINO_PATHS.BASE}/${CASINO_PATHS.CasinoPage}`], {
          queryParams: { lobby: lobby, playgame: this.bonus.SelectedGames[0] }
        });
      } else {
        // Navigate to casino lobby
        this.router.navigate([`/${CASINO_PATHS.BASE}/${CASINO_PATHS.CasinoPage}`], { queryParams: { lobby: lobby } });
      }
    }
  }
}
