<!-- eslint-disable -->
<ion-content
  [ngClass]="{'backgroundheader': globalVars.FEATURES?.MSO_NewReBranding, 'colornewcas' : globalVars.FEATURES?.MSO_NewReBranding && isCasinoPage}"
>
  <div class="loading-container" [ngClass]="{'platform-core': isDesktop, 'platform-mobile': !isDesktop}">
    <img
      alt=""
      class="logo"
      [src]="'assets/global/img/' + (!globalVars.FEATURES?.MSO_NewReBranding ? 'logo' : (isCasinoPage ? 'logo-purple' : 'logo-green')) + '.svg'"
      *ngIf="globalVars.licenseType != 4"
    />
    <img alt="" class="logo" src="assets/global/img/codere-apuestas-mx.svg" *ngIf="globalVars.licenseType == 4" />
    <img
      alt=""
      class="loadingSplash"
      [ngClass]="{'anim': globalVars.FEATURES?.MSO_NewReBranding}"
      [src]="'assets/global/img/'+ (globalVars.FEATURES?.MSO_NewReBranding ? 'newring': 'ring') + '.svg'"
    />

    <h3 *ngIf="globalVars.FEATURES?.MSO_NewReBranding">loading...</h3>
  </div>
</ion-content>
