<section>
  <ng-container *ngIf="!!slide?.superTitle">
    <div [ngClass]="{'superText': (!isTeamMach(slide)),'superText2': (isTeamMach(slide))}" class="superText">
      {{slide?.superTitle}}
    </div>
  </ng-container>

  <ng-container *ngIf="slide?.textLines?.length > 0">
    <div *ngFor="let txt of slide?.textLines; let i = index" class="txt">{{txt}}</div>
  </ng-container>

  <ng-container *ngIf="slide?.typeOfTemplate === 'One Odd'">
    <div class="txt">antes {{slide?.oddBet.toString() | formatOdd}} Ahora {{nowAmount.toString() | formatOdd}}</div>
  </ng-container>

  <ng-container *ngIf="!!slide?.buttonText && !isTeamMach(slide)">
    <button class="btnWrapper">{{slide?.buttonText}}</button>
  </ng-container>

  <ng-container *ngIf="(isTeamMach(slide))">
    <div
      class="teamsTable"
      [ngClass]="{'threeteams': slide?.typeOfTemplate === 'Two teams with draw', 'twoteams': slide?.typeOfTemplate !== 'Two teams with draw'}"
    >
      <div class="teamsTableCol cursor-pointer" (click)="btnClickSlide(slide,'leftTeam')">
        <div class="txt2">{{slide?.leftTeam}}</div>
        <div class="txt">{{leftTeamAmount.toString() | formatOdd}}</div>
      </div>
      <div
        *ngIf="slide?.typeOfTemplate === 'Two teams with draw'"
        class="teamsTableCol cursor-pointer"
        (click)="btnClickSlide(slide,'draw')"
      >
        <div class="txt2">Empate</div>
        <div class="txt">{{drawAmount.toString() | formatOdd}}</div>
      </div>
      <div class="teamsTableCol cursor-pointer" (click)="btnClickSlide(slide,'rightTeam')">
        <div class="txt2">{{slide?.rightTeamName}}</div>
        <div class="txt">{{rigthTeamAmount.toString() | formatOdd}}</div>
      </div>
    </div>
    <button class="btnWrapper" (click)="btnClickSlide(slide,'goTo')">{{slide?.buttonText}}</button>
    <ng-container *ngIf="isTeamMach(slide)">
      <img *ngIf="slide?.leftShirtImage" class="leftImg" [src]="slide?.leftShirtImage" alt="" />
      <img *ngIf="slide?.rightShirtImage" class="rightImg" [src]="slide?.rightShirtImage" alt="" />
    </ng-container>
  </ng-container>
</section>
