<!-- Confirmación -->
<fieldset id="confirmData" class="box col s12 m12 l12" [formGroup]="formGrp">
  <legend class="flow-text title-section">{{ titleSection }}</legend>
  <!-- Términos y condiciones -->
  <ion-row class="row">
    <ion-col size="2">
      <ion-item lines="none">
        <ion-checkbox id="confirm" item-start formControlName="confirm"></ion-checkbox>
      </ion-item>
    </ion-col>
    <ion-col size="10">
      <span [innerHTML]="safeConfirmText"></span>
    </ion-col>
  </ion-row>
  <cdr-control-error *ngIf="errors" [fControl]="$any(formGrp).get('confirm')" [errorsCol]="errors['confirm']" />
  <!-- Aceptación de comunicaciones -->
  <ion-row class="row">
    <ion-col size="2">
      <ion-item lines="none">
        <ion-checkbox id="comunica" item-start formControlName="comunica"></ion-checkbox>
      </ion-item>
    </ion-col>
    <ion-col size="10" size-md="10">
      <span [innerHTML]="safeComunicsText"></span>
    </ion-col>
  </ion-row>
  <!-- Persona con Responsabilidad Pública (PRP) -->
  <ion-row class="row rowtoggle">
    <div>
      {{ 'prp' | translate }}
    </div>
    <div class="toggleprp">
      <div class="toggle-button-cover">
        <div class="button" id="btnprp">
          <input
            type="checkbox"
            class="checkbox"
            [checked]="!this.formGrp.get('PRP').value"
            (change)="onChangePrp($event)"
          />
          <div class="knobs">
            <span></span>
          </div>
          <div class="layer"></div>
        </div>
      </div>
    </div>
    <div><cdr-info tabindex="-1" [target]="'modalPRP'"></cdr-info></div>
  </ion-row>
  <!-- Hash de control class="col s12 grey lighten-4 sha"-->
  <ion-item id="sha" class="sha lighten-4 grey" *ngIf="!isMobile" lines="none">
    <div>
      <a class="grey-text text-darken-1">
        <details>
          <summary><b>SHA-1:</b></summary>
          <span>
            <!-- 8B76DB20514470CB4010583E2E0DAB2AB98CD8D9 -->
            {{ legalTerms.hash }}
          </span>
        </details>
      </a>
    </div>
  </ion-item>
</fieldset>
