<div class="pd-text">
  <p>{{'amountWithdraw' | translate}}</p>
  <p>{{'moneyTPaga' | translate}}</p>
  <ul>
    <li>{{'withdrawCash' | translate}}</li>
    <li>{{'paymentSP' | translate}}</li>
    <li>{{'buyStores' | translate}}</li>
  </ul>
  <p>{{'maxvaluewithdrawn' | translate}} {{ maxAmount | parseMoney }}</p>
  <p>{{'phoneCodere' | translate}}</p>
</div>
