<ng-container *ngIf="!isMobile; else mobileView">
  <ion-toolbar class="toolbarSectionName">
    <back-button tappable></back-button>
    <span style="color: white">{{'ftHelp' | translate}}</span>
  </ion-toolbar>

  <ion-content>
    <iframe
      data-tap-disabled="true"
      class="iframeFullScreen nodisplay"
      (load)="helpageload($event)"
      [src]="scrHelp"
    ></iframe>
    <codere-footer></codere-footer>
  </ion-content>
</ng-container>

<ng-template #mobileView>
  <ion-toolbar class="toolbarSectionName">
    <span style="color: white">{{'ftHelp' | translate}}</span>
  </ion-toolbar>

  <ion-content>
    <iframe
      data-tap-disabled="true"
      class="iframeFullScreen nodisplay"
      (load)="helpageload($event)"
      [src]="scrHelp"
    ></iframe>
    <codere-footer></codere-footer>
  </ion-content>
</ng-template>
