/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable eqeqeq */
import { IonicModule, ModalController, NavController } from '@ionic/angular';
import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { GlobalVarsModel } from '@models/ModelGlobalsVar';
import { EventsService } from '@providers/EventsService';
import { UserService } from '@providers/UserService';
import { TrackingService } from '@providers/TrackingService';
import { NewBaseService } from '@providers/newBase.service';
import { PAYMENTS_PATHS } from '@shared-constants/routes';
import { Utils } from '@utils/Utils';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'select-promotion',
  templateUrl: './select-promotion.html',
  standalone: true,
  styleUrls: ['./select-promotion.scss'],
  imports: [CommonModule, IonicModule, FormsModule, TranslateModule]
})
export class SelectPromotion {
  bonos;
  public trackEvent!: TrackEvent;
  globalVars!: GlobalVarsModel;

  events = inject(EventsService);
  nav = inject(NavController);
  utils = inject(Utils);
  userService = inject(UserService);
  trackingService = inject(TrackingService);
  newBaseService = inject(NewBaseService);
  modalCtrl = inject(ModalController);
  viewCtrl = inject(ModalController);
  router = inject(Router);

  constructor() {
    this.newBaseService.getVars.subscribe((data) => {
      this.globalVars = data;
    });
    this.bonos = '200';
  }

  promotion_click(promotion: boolean) {
    this.globalVars.defaultPromotion = promotion;
  }

  goToHome() {
    this.loginAndGoto('/');
  }
  goToDeposits() {
    this.loginAndGoto(`/${PAYMENTS_PATHS.BASE}/${PAYMENTS_PATHS.CashierPage}`);
  }

  loginAndGoto(dest: any) {
    this.viewCtrl.dismiss();
    this.updatePromotions();
    this.loadPixel();
    //checks if there is a current user connected
    this.userService.getCurrentUser().subscribe({
      next: (data) => {
        if (data.sessionOut) {
          this.router.navigate(['/']);
        } else {
          if (data.success) {
            if (data.idBGT) {
              this.globalVars.user.idBGT = data.idBGT;
            }
            //connected
            this.globalVars.user.username = data.username;
            this.globalVars.user.balance = data.balance;
            this.globalVars.user.logged = true;
            const tempRegion = this.globalVars.LICENSE_TYPE;
            if (tempRegion) {
              this.globalVars.user.userRegion = tempRegion;
              this.globalVars.licenseType = tempRegion;
            }

            this.globalVars.afterLoginUrl = dest;
            this.events.publish('user:login');
          } else {
            //not connected
            this.router.navigate(['/']);
          }
        }
      },
      error: () => {
        //no action
        this.router.navigate(['/']);
      }
    });
  }

  updatePromotions() {
    if (!this.globalVars.defaultPromotion) {
      //send new promotions changePromotion
      this.userService.changePromotion().subscribe((data) => {
        if (data.success) console.info('promocion cambiada con exito');
        else console.info('promocion no cambiada');
      });
    }
  }

  loadPixel() {
    this.trackingService.trackEvent(['PixelPromotion', 'pixel', '', 'Pixel de promocion', 'event']);
  }
}
