<ng-container *ngIf="isDesktop; else mobileView">
  <ion-content class="is-narrow-content">
    <!-- todo -->
    <!-- <ion-toolbar
      class="toolbarSectionName tablet-content section-title"
      [class.enter]="viewEntering"
      [class.leave]="viewLeaving"
      [class.leftMenuOpen]="globalVars.leftMenuOpen"
    > -->
    <ion-toolbar class="toolbarSectionName tablet-content section-title" [class.leftMenuOpen]="globalVars.leftMenuOpen">
      <back-button tappable></back-button>
      DETALLES DEL BONO
    </ion-toolbar>

    <ion-list class="list slotsbonbac">
      <ion-item class="card-item">
        <div>
          <!-- TODO Tipo bono: {{bono.BonusName}} -->
          <span class="fontgreen">{{bono}}</span>
        </div>
      </ion-item>
      <ion-item class="card-item">
        <div>
          <span class="fontcolorg">{{'hStade' | translate}}</span>
          <span class="result"
            ><span class="fontsize16r">
              <!-- TODO  -->
              <!-- <b>{{bono.State=='active'?'Activo':bono.State}}</b> -->
            </span>
          </span>
        </div>
      </ion-item>
      <ion-item class="card-item">
        <div>
          <span class="fontcolorg">{{'SlotBonusStarDate' | translate}}</span>
          <span class="result"
            ><span class="fontsize16r">
              <!-- TODO Tipo bono: {{bono.StartDate | extractDate:[1]} -->
              <b>{{ bono }}</b>
            </span></span
          >
        </div>
      </ion-item>
      <ion-item class="card-item">
        <div>
          <span class="fontcolorg">{{'ExpiryDate' | translate}}</span>
          <span class="result"
            >:
            <span class="fontsize16r">
              <!-- TODO BONO -->
              <!-- <b>{{ bono.ExpirationDate | extractDate:[1]}}</b> -->
            </span></span
          >
        </div>
      </ion-item>
    </ion-list>
    <!-- TODO Bono: *ngIf="!!bono.SelectedGames"  -->
    <div class="marginb15">
      <span class="fonttit">{{'SlotBonusgamesv' | translate}}</span>
    </div>

    <!-- TODO Bono: *ngIf="!!bono.SelectedGames" -->
    <div>
      <!-- 
        TODO bono:
        <ion-col
        col-4=""
        *ngFor="let game of bono.SelectedGames"
        (click)="goToSlots(bono.SectionType)"
      > -->
      <ion-col>
        <!-- <img
          *ngIf="!globalVars.isMobile"
          src="{{globalVars.UrlBase}}AppWeb/images/slots/{{game | strNoSpaces}}.png"
          class="imgWidth100"
        />
        <img
          *ngIf="globalVars.isMobile"
          src="{{globalVars.UrlBase}}AppWeb/images/slots/mobile/{{game | strNoSpaces}}.png"
          class="imgWidth100"
        /> -->
      </ion-col>
    </div>

    <codere-footer></codere-footer>
  </ion-content>
</ng-container>

<ng-template #mobileView>
  <!-- <ion-header>
    <codere-navbar titulo="DETALLES DEL BONO"></codere-navbar>
    <user-section-title></user-section-title>
  </ion-header> -->

  <ion-content>
    <!--<div class="marginb15 marginl15">
           <i class="icon-slots"></i> <span class="fonttit">Detalles del bono</span>
       </div>-->
    <div>
      <ion-list class="list slotsbonbac">
        <ion-item class="card-item">
          <div>
            <!-- <span class="fontgreen">{{bono.BonusName}}</span> -->
          </div>
        </ion-item>
        <ion-item class="card-item">
          <div>
            <span class="fontcolorg">{{'hStade' | translate}}</span>
            <span class="result"
              ><span class="fontsize16r">
                <!-- <b>{{bono.State=='active'?'Activo':bono.State}}</b> -->
              </span></span
            >
          </div>
        </ion-item>
        <ion-item class="card-item">
          <div>
            <span class="fontcolorg">{{'SlotBonusStarDate' | translate}}</span>
            <span class="result"
              ><span class="fontsize16r">
                <!-- <b>{{bono.StartDate | extractDate:[1]}}</b> -->
              </span></span
            >
          </div>
        </ion-item>
        <ion-item class="card-item">
          <div>
            <span class="fontcolorg">{{'ExpiryDate' | translate}}</span>
            <span class="result"
              >:
              <span class="fontsize16r">
                <!-- <b>{{bono.ExpirationDate | extractDate:[1]}}</b> -->
              </span></span
            >
          </div>
        </ion-item>
      </ion-list>
    </div>
    <!-- <div class="marginb15 marginl15" *ngIf="!!bono.SelectedGames">
      <span class="fonttit">{{'SlotBonusgamesv' | translate}}</span>
    </div> -->
    <!-- <div *ngIf="!!bono.SelectedGames">
      <ion-col
        col-4=""
        *ngFor="let game of bono.SelectedGames"
        (click)="goToSlots(bono.SectionType)"
      >
        <img
          *ngIf="!globalVars.isMobile"
          src="{{globalVars.UrlBase}}AppWeb/images/slots/{{game | strNoSpaces}}.png"
          class="imgWidth100"
        />
        <img
          *ngIf="globalVars.isMobile"
          src="{{globalVars.UrlBase}}AppWeb/images/slots/mobile/{{game | strNoSpaces}}.png"
          class="imgWidth100"
        />
      </ion-col>
    </div> -->
    <codere-footer></codere-footer>
  </ion-content>
</ng-template>
