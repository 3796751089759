import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ILoaderState } from '@models/loader-feature/iloader-state.model';

@Injectable({ providedIn: 'root' })
export class LoaderService {
  private loaderSubject = new Subject<ILoaderState>();
  loaderState = this.loaderSubject.asObservable();

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}
  show() {
    this.loaderSubject.next(<ILoaderState>{ show: true });
  }
  hide() {
    this.loaderSubject.next(<ILoaderState>{ show: false });
  }
}
