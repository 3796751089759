import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { MSO_PATHS, SPORTS_PATHS } from '../lib/constants/routes';
import { DeviceService } from './device.service';
import { GlobalVarsModel } from '../models/ModelGlobalsVar';
import { TrackingService } from '../providers/TrackingService';
import { NewBaseService } from '../providers/newBase.service';

@Injectable({ providedIn: 'root' })
export class CodereRouterService {
  globalVars!: GlobalVarsModel;

  private router: Router = inject(Router);
  private newBaseService = inject(NewBaseService);
  private trackingService = inject(TrackingService);
  deviceService = inject(DeviceService);
  isDesktop: boolean;

  constructor() {
    this.isDesktop = this.deviceService.isDesktop();
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
  }

  openMvpCodere(): void {
    this.router.navigate([`${MSO_PATHS.BASE}/${MSO_PATHS.MvpCoderePage}`]);
  }

  openToday(): void {
    this.router.navigate([SPORTS_PATHS.TodayPage]);
    this.trackingService.trackEvent(['AccessToHoy', '', 'sportmenu', 'Hoy', 'event']);
  }

  openWorldCupCalendar() {
    this.router.navigate([MSO_PATHS.InformationPage, { type: 'politicapriv' }, { animate: !this.isDesktop }]);
  }

  navigateToRacePage(race) {
    this.router.navigate([SPORTS_PATHS.HorsesPage], {
      state: { params: race },
      queryParams: { animate: !this.globalVars.isNative }
    });
  }

  navigateToTopSportCountriesPage(sport) {
    this.router.navigate([SPORTS_PATHS.SportCountriesPage], {
      state: { params: { Name: sport.Name, NodeId: sport.NodeId, SportHandle: sport.SportHandle } }
    });
  }
}
