interface Action {
  Period: number;
  PeriodName: string;
  Time: number;
  ActionType: number;
  ActionTypeName: string;
  Participant: string;
  AffectedParticipant: string;
  IsHomeTeam: boolean;
}

interface LiveData {
  Sets: number[][];
  HomeService: boolean;
  SetsHome: number;
  SetsAway: number;
  Period: number;
  PeriodName: string;
  Actions: Action[];
  ResultHome: number;
  ResultAway: number;
  Time: string;
  MatchTime: number;
  RemainingPeriodTime: string;
  ParticipantHome: string;
  ParticipantAway: string;
}

interface AdditionalValues {
  KeyValues: [];
  LocalizedValues: { Key: string; Value: string }[];
  ReferenceId: number;
}

interface LocalizedNames {
  LocalizedValues: { CountryCode: string; LanguageCode: string; Value: string }[];
  ReferenceId: number;
}

interface Participant {
  AdditionalValues: AdditionalValues;
  Id: number;
  IsHome: boolean;
  LocalizedNames: LocalizedNames;
  LocalizedShortNames: LocalizedNames;
}

interface Result {
  Odd: number;
  SortOrder: number;
  IsLive: boolean;
  upOdd: boolean;
  downOdd: boolean;
  IsNonRunner: boolean;
  SportId: string;
  LocationId: string;
  LeagueId: string;
  EventId: string;
  EventHasHandicap: boolean;
  GameId: string;
  GameTypeId: number;
  GameSpecialOddsValue: string;
  GameBetTypeAvailability: number;
  GameNumberOfStarters: number;
  Name: string;
  NodeId: string;
  ParentNodeId: string;
  Priority: number;
  SportHandle: string;
  Locked: boolean;
}

interface CategoryInfo {
  CategoryId: string;
  CategoryName: string;
  IsRelevant: boolean;
}

interface Game {
  Results: Result[];
  DisplayTypeName: string;
  CategoryInfo: CategoryInfo;
  CategoryInfos: CategoryInfo[];
  GameType: number;
  SmartMarketAvailable: boolean;
  Spov: string;
  Name: string;
  NodeId: string;
  ParentNodeId: string;
  Priority: number;
  SportHandle: string;
  Locked: boolean;
}

export interface I_GetEventsService {
  ResultInfo: string;
  liveData: LiveData;
  StartDate: string;
  StarDate: string;
  isLive: boolean;
  LeagueName: string;
  ChildrenCount: number;
  SportName: string;
  CountryCode: string;
  CountryName: string;
  Participants: Participant[];
  LiveHistory: null;
  SmartMarketReferenceGameTypeIds: string;
  StatisticsId: string;
  SportId: string;
  Games: Game[];
  StreamingId: string;
  StreamingEnabled: string;
  LTMEnabled: boolean;
  LeagueKlothoPriority: number;
  HighlightPriority: number;
  ParticipantHome: string;
  ParticipantAway: string;
  ParticipantHomeId: number;
  ParticipantAwayId: number;
  Name: string;
  NodeId: string;
  ParentNodeId: string;
  Priority: number;
  SportHandle: string;
  Locked: boolean;
}
