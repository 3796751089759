import { CUSTOM_ELEMENTS_SCHEMA, Component, OnDestroy, inject } from '@angular/core';
import { ModalController } from '@ionic/angular';
/* MODELS */
import { LicenseTypes } from '../../../models/MasterData';
import { GlobalVarsModel } from '../../../models/ModelGlobalsVar';
/* PROVIDERS */
import { NewBaseService } from '../../../providers/newBase.service';
/* RXJS */
import { Subscription } from 'rxjs/internal/Subscription';

/**
 * Generated class for the ModalUpdateComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'modal-update',
  templateUrl: './modal-update.html',
  styleUrls: ['./modal-update.scss'],
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ModalUpdateComponent implements OnDestroy {
  private subscription: Subscription;
  public message: any;
  viewCtrl = inject(ModalController);
  newBaseService = inject(NewBaseService);
  globalVars!: GlobalVarsModel;

  constructor() {
    this.subscription = this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.checkCountry();
  }

  cancelUpdate() {
    this.viewCtrl.dismiss();
  }

  checkCountry(): void {
    if (this.globalVars.licenseType == LicenseTypes.Nacional) {
      this.message = `Para disfrutar de la experiencia de juego más completa, mantén actualizada tu app constantemente y disfruta de todos los beneficios que estamos implementando.
      <br>
      Gracias por tu paciencia.`;
    }

    if (this.globalVars.licenseType == LicenseTypes.Colombia) {
      this.message = `Para disfrutar plenamente de nuestros contenidos y funcionalidades en la aplicación, espera a que se complete esta actualización. Omitirla, significaría perderla.
      <br>
      Gracias por tu paciencia.`;
    }

    if (this.globalVars.licenseType == LicenseTypes.Panama) {
      this.message = `Para disfrutar plenamente del contenido y las funciones más recientes de la aplicación, espere a que se complete esta actualización. Omitirla puede significar perderla.
      <br>
      Gracias por tu paciencia.`;
    }
  }
}
