import { CommonModule } from "@angular/common";
import { Component, inject } from "@angular/core";
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Capacitor } from "@capacitor/core";
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable eqeqeq */
import { IonicModule, NavController, Platform } from "@ionic/angular";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ErrorService } from "@providers/ErrorService";
import { TrackingService } from "@providers/TrackingService";
import { UserService } from "@providers/UserService";
import { Utils } from "@utils/Utils";

import { GlobalVarsModel } from "@models/ModelGlobalsVar";

import { CodereFooterComponent } from "@components/mso/footer/codere-footer/codere-footer";
import { UserSectionTitle } from "@components/mso/user-section-title/user-section-title";
import { NewBaseService } from "@providers/newBase.service";
import { DeviceService } from "@services/device.service";

import { MSO_PATHS } from "@shared-constants/routes";

@Component({
  standalone: true,
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "my-card-unlock-page",
  templateUrl: "./my-card-unlock.html",
  styleUrls: ["./my-card-unlock.scss"],
  imports: [
    IonicModule,
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    UserSectionTitle,
    CodereFooterComponent,
  ],
})
export class MyCardUnlockPage {
  steps: string;
  myForm1: FormGroup;
  myForm2: FormGroup;
  isDesktop = false;
  isMobile: boolean = false;

  globalVars!: GlobalVarsModel;

  showPassword: { [key: string]: boolean } = {
    password: false,
    newPin: false,
    reNewPin: false,
  };

  nav = inject(NavController);
  utils = inject(Utils);
  translate = inject(TranslateService);
  fb = inject(FormBuilder);
  userService = inject(UserService);
  errorService = inject(ErrorService);
  trackingService = inject(TrackingService);
  newBaseService = inject(NewBaseService);
  platform = inject(Platform);
  device = inject(DeviceService);
  router = inject(Router);

  constructor() {
    this.isDesktop = Capacitor.getPlatform() === "web" || this.platform.is("desktop");
    this.isMobile = this.device.isMobile();
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
    this.steps = "step1";
    this.myForm1 = this.fb.group({
      password: ["", Validators.required],
    });
    this.myForm2 = this.fb.group({
      newPin: [
        "",
        Validators.compose([
          Validators.required,
          Validators.maxLength(4),
          Validators.minLength(4),
          Validators.pattern("[0-9]*"),
        ]),
      ],
      reNewPin: [
        "",
        Validators.compose([
          Validators.required,
          Validators.maxLength(4),
          Validators.minLength(4),
          Validators.pattern("[0-9]*"),
        ]),
      ],
    });
  }

  ionViewWillEnter() {
    this.router.navigate([MSO_PATHS.BASE + "/" + MSO_PATHS.MyCardUnlockPage]);
    this.trackingService.trackEvent(["AccessToCodereCardActive", "", "", "Acceso Tarjeta Codere", "event"]);
  }
  accessContact() {
    //access to help
    this.router.navigate([MSO_PATHS.BASE + "/" + MSO_PATHS.ContactPage]), { animate: !this.device.isDesktop() };
  }

  toggleInput(myInput: HTMLInputElement) {
    if (myInput.type === "password") {
      myInput.type = "text";
    } else {
      myInput.type = "password";
    }
  }

  setPin() {
    if (this.myForm2.valid) {
      if (this.myForm2.controls["reNewPin"].value === this.myForm2.controls["newPin"].value) {
        this.userService.unlockedCard({ pin: this.myForm2.controls["newPin"].value }).subscribe({
          next: (data) => {
            //
            if (data.success) {
              this.utils.showMsg(this.translate.instant("GetCardUnblocked"), () => {
                this.router.navigate([MSO_PATHS.BASE + "/" + MSO_PATHS.MyCardPage]);
              });
            } else {
              this.utils.showError(this.translate.instant("GetCardError"));
            }
          },
          error: (err) => {
            //
            this.utils.showError(this.translate.instant("m_menu_serverFail"));
          },
        });
      } else {
        //not same pin
        this.myForm2.controls["reNewPin"].setErrors({ same: "notValid" });
      }
      //
    } else {
      this.myForm2.controls["newPin"].markAsTouched();
      this.myForm2.controls["reNewPin"].markAsTouched();
    }
  }

  checkPassword() {
    //
    if (this.myForm1.valid) {
      this.userService.validatePassword({ password: this.myForm1.controls["password"].value }).subscribe(
        (data) => {
          //
          if (data.success) {
            this.userService.checkCardLocked().subscribe(
              (data2) => {
                //

                if (data2.success) {
                  //Step2
                  this.steps = "step2";
                } else {
                  if (data2.locked) {
                    //show pop-up
                    const msg: string =
                      this.translate.instant("GetCardNotUnlock") + "<br/>" + this.translate.instant("GetCardhelp");
                    this.utils.showError(msg, () => {
                      setTimeout(() => {
                        this.accessContact();
                      }, 800);
                    });
                  } else {
                    if (data2.message !== undefined) {
                      if (isNaN(data2.message)) {
                        this.utils.showError(data2.message);
                      } else {
                        this.errorService.searchErrorCode(data2.message).subscribe(
                          (data3) => {
                            this.utils.showError(data3);
                          },
                          (err) => {
                            this.utils.showError(data2.message);
                          },
                        );
                      }
                    } else {
                      this.utils.showError(this.translate.instant("GetCardnotunblocked"));
                    }
                  }
                }
              },
              (err) => {
                //
                this.utils.showError(this.translate.instant("m_menu_serverFail"));
              },
            );
          } else {
            if (data.message !== undefined) {
              this.errorService.searchErrorCode(data.message).subscribe(
                (data5) => {
                  this.utils.showError(data5);
                },
                (err) => {
                  this.utils.showError(data.message);
                },
              );
            } else {
              this.utils.showError(this.translate.instant("GetCardpasswordKO"));
            }
          }
        },
        (err) => {
          ///
          this.utils.showError(this.translate.instant("m_menu_serverFail"));
        },
      );
    } else {
      this.myForm1.controls["password"].markAsTouched();
    }
  }

  toggleTypeInput(field: string) {
    this.showPassword[field] = !this.showPassword[field];
  }
}
