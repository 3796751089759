<ion-header class="betslip-unlocker--header">
  <img src="assets/global/img/logo.svg" class="logo logoES logocodere" />
</ion-header>
<ion-content [ngClass]="{'is-mobile': isMobile}">
  <div class="content betslip-unlocker">
    <ion-row class="button-row">
      <ion-icon name="alert-circle-outline" size="large"></ion-icon>
      <p *ngFor="let message of mainMessage" class="betslip-unlocker--title-big">{{ message }}</p>
    </ion-row>
    <ion-row class="button-row">
      <div class="center-button">
        <button
          class="betslip-unlocker--button color-light background-color-primary border-color-primary"
          (click)="clearAll()"
        >
          {{ 'SPO_BetslipUnlockerButtonText' | translate }}
        </button>
      </div>
    </ion-row>
  </div>
</ion-content>
