import { C_Event } from './C_Event';

export class C_League {
  Events: any;
  Name: string;
  NodeId: string;
  ParentNodeId: string;
  // EventNodeTypeId: number;
  Priority: number;
  SportHandle: string;
  Locked: boolean;
  // ChildrenCount: number;
  Collapsed: boolean;
  SportName?: string;
  isUsaEvent: string;
  CountryCode: string;
  SportsHandle: any;
  isSixPackEvent: boolean;
  marketNames?: string[];
  constructor(
    events?: Array<C_Event>,
    name?: string,
    nodeId?: string,
    parentNodeId?: string,
    // eventNodeTypeId?: number,
    priority?: number,
    sporthandle?: string,
    locked?: boolean,
    collapsed: boolean = true
  ) {
    this.Events = events;
    this.Name = name;
    this.NodeId = nodeId;
    this.ParentNodeId = parentNodeId;
    // this.EventNodeTypeId = eventNodeTypeId;
    this.Priority = priority;
    this.SportHandle = sporthandle;
    this.Locked = locked;
    // this.ChildrenCount = childrenCount;
    this.Collapsed = collapsed;
  }

  static parse(obj: any): C_League {
    return Object.assign(new C_League(), obj);
  }

  //Todo: sort array marketNames by gameTypes;
  static getMarketNames(events: C_Event[], gameTypes: number[]): string[] {
    const marketNames = [];
    events.forEach((event) =>
      event.Games.forEach((game) => {
        const marketName = {
          order: gameTypes.indexOf(game.GameType),
          name: game.Name
        };
        marketNames.push(marketName);
      })
    );
    return Array.from(new Set(marketNames.map((market) => JSON.stringify(market)))).map((market) => {
      const m = JSON.parse(market);
      return m.name;
    });
  }
}

//export = C_League
