/* eslint-disable eqeqeq */
import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';

import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { GlobalVarsModel } from '@models/ModelGlobalsVar';
import { NewBaseService } from '@providers/newBase.service';
import { CodereFooterComponent } from '@shared-mso-components/footer/codere-footer/codere-footer';
import { UserSectionTitle } from '@shared-mso-components/user-section-title/user-section-title';
import { UserServiceMSO } from '@providers/UserServiceMSO';
import { Utils } from '@utils/Utils';
import { ErrorService } from '@providers/ErrorService';
import { DeviceService } from '@services/device.service';
import { LegalTerms, LegalTermsServiceService } from '../../services/registro/legalTerms.service';
import { RegistroValidators } from '../../registro.validators';

interface UserActivateInfo {
  userName: string;
  password: string;
  pinCode: string;
  acceptInfo: boolean;
  userToken: string;
  userPhone: string;
  deviceType: string;
  dayLimit: number;
  weekLimit: number;
  monthLimit: number;
}

interface UsuarioData {
  IdJugador: string;
  Genero: string;
  NIF: string;
  AceptaEmailPromo: boolean;
  AceptaEmailPromoSpecified: boolean;
  AceptaSMSPromo: boolean;
  AceptaSMSPromoSpecified: boolean;
  Saldo: number;
  SaldoSpecified: boolean;
  Ciudad: string | null;
  CodigoPostal: string;
  Direccion: string;
  EstadoCta: string;
  FechaNacimiento: string;
  FechaNacimientoFormat: Date;
  FechaNacimientoSpecified: boolean;
  NombreUsuario: string;
  Pais: string;
  Provincia: null;
  SegundoApellido: string;
  IdUsuarioBGT: string | null | number;
  VerifiDocumental: string;
  NumMovil: string;
  Nacionalidad: string | null;
  Direccion_Correo: string;
  Nombre: string;
  PrimerApellido: string;
  Afiliado: string | null;
  VersionTyCAceptada: string | null;
  DispositivoRegistro: string | null;
  TipoDocumento: string | null;
  NumeroTarjeta: string;
}

// eslint-disable-next-line no-shadow
enum PasswordError {
  NOMIN,
  NOMAYUS,
  NONUM,
  TOSHORT,
  TOLONG,
  INCLUSER,
  INCLUBIRTHDAT,
  INCLUNAME,
  INCLUSURNAME,
  INCLUSURNAME2
}

@Component({
  standalone: true,
  templateUrl: './activate-user-page.html',
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'activate-user-page',
  styleUrls: ['./activate-user-page.scss'],
  imports: [IonicModule, CommonModule, TranslateModule, CodereFooterComponent, UserSectionTitle, ReactiveFormsModule]
})
export class ActivateUserPage implements OnInit, OnDestroy {
  dataUser: UserActivateInfo = {
    userName: '',
    password: '',
    pinCode: '',
    acceptInfo: false,
    dayLimit: 0,
    monthLimit: 0,
    userToken: '',
    weekLimit: 0,
    deviceType: 'web',
    userPhone: ''
  };

  userForm: FormGroup;
  termsConditions: LegalTerms;
  $tycSubscription: Subscription;
  allUsuarioData: UsuarioData;

  showPassword: { [key: string]: boolean } = {
    pass1: false,
    pass2: false,
    pin1: false,
    pin2: false
  };

  TyCCajaCodere = 'http://condiciones.apuestas.codere.es/Madrid/Condiciones_madrid.html';

  globalVars!: GlobalVarsModel;
  params: Params;
  formBuilder = inject(FormBuilder);
  userServiceMSO = inject(UserServiceMSO);
  legalTS = inject(LegalTermsServiceService);
  utils = inject(Utils);
  translate = inject(TranslateService);
  errorService = inject(ErrorService);
  newBaseService = inject(NewBaseService);
  router = inject(Router);
  activatedRouter = inject(ActivatedRoute);
  deviceService = inject(DeviceService);

  isMobile = this.deviceService.isMobile();
  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
    if (this.isMobile) {
      const codereNavBarElement = document.querySelector('codere-navbar') as HTMLElement;
      if (codereNavBarElement) {
        codereNavBarElement.style.display = 'none';
      }
    }
    this.initialiceForm();
  }

  ngOnInit(): void {
    this.activatedRouter.queryParams.subscribe((params) => {
      this.params = params;

      if (!this.getDataParam()) {
        this.router.navigate(['/']);
        return;
      }

      this.getUserByToken();
      this.getLastTermsAndCondition();
    });
  }
  initialiceForm() {
    this.userForm = this.formBuilder.group({
      userName: new FormControl('', {
        validators: [
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(15),
          Validators.pattern('[A-Za-z0-9]*')
        ],
        asyncValidators: [RegistroValidators.userCDR(this.userServiceMSO)],
        updateOn: 'blur'
      }),
      password1: new FormControl('', {
        updateOn: 'blur'
      }),
      password2: new FormControl('', {
        updateOn: 'blur'
      }),
      pin1: new FormControl('', {
        updateOn: 'blur',
        validators: [Validators.required, Validators.pattern('^[0-9]+$')]
      }),
      pin2: new FormControl('', {
        updateOn: 'blur'
      }),
      ageConfirm: new FormControl(false, {
        validators: [Validators.required, Validators.requiredTrue]
      }),
      termsAndConditionConfirm: new FormControl(false, {
        validators: [Validators.required, Validators.requiredTrue]
      }),
      confirmComunications: new FormControl(false, {
        validators: [Validators.required, Validators.requiredTrue]
      })
    });

    this.userForm
      .get('password1')
      .setValidators([Validators.required, this.vereficarPassword(this.userForm.controls['userName'])]);
    this.userForm
      .get('password2')
      .setValidators([
        Validators.required,
        this.verificarCoincidencia('password1'),
        this.vereficarPassword(this.userForm.controls['userName'])
      ]);
    this.userForm
      .get('pin2')
      .setValidators([Validators.required, Validators.pattern('^[0-9]+$'), this.verificarCoincidencia('pin1')]);
    this.userForm.get('pin1').setValidators([Validators.required, Validators.pattern('^[0-9]+$')]);

    this.setFormEvents();
  }

  submitForm() {
    this.setAllControllsTouchedDirty(this.userForm);
    if (!this.userForm.valid) {
      return;
    }

    this.dataUser.acceptInfo = this.userForm.value['confirmComunications'];
    this.dataUser.password = this.userForm.value['password1'];
    this.dataUser.pinCode = this.userForm.value['pin1'];
    this.dataUser.userName = this.userForm.value['userName'];

    this.userServiceMSO.activateUser(this.dataUser).subscribe({
      next: (resp) => {
        if (resp.status === 'ok') {
          this.utils.loginAndGoto('/', null, false).then((isOk) => {
            this.globalVars.rootScope.tryToGoPage('/');
          });
        } else {
          if (resp.errCode != undefined) {
            if (isNaN(resp.errCode)) {
              this.utils.showError(resp.errCode);
            } else {
              this.errorService.searchErrorCode(resp.errCode).subscribe({
                next: (data3) => {
                  this.utils.showError(data3);
                },
                error: () => {
                  this.utils.showError(this.translate.instant('tfailactiva'));
                }
              });
            }
          } else {
            this.utils.showError(this.translate.instant('tfailactiva'));
          }
        }
      },
      error: () => {
        this.utils.showError(this.translate.instant('tfailactiva'));
      }
    });
  }

  getDataParam(): boolean {
    let isDataLoad = false;

    if (this.params['mobilephone'] && this.params['token']) {
      this.dataUser.userPhone = this.params['mobilephone'];
      this.dataUser.userToken = this.params['token'];
      isDataLoad = true;
    }

    return isDataLoad;
  }

  getLastTermsAndCondition() {
    this.legalTS.updateConditions();
    this.$tycSubscription = this.legalTS.$legalTerms.subscribe((data) => {
      this.termsConditions = data;
    });
  }

  getUserByToken() {
    this.userServiceMSO.getUserDataByPinCode(this.dataUser.userToken).subscribe({
      next: (response: UsuarioData) => {
        if (response) {
          this.allUsuarioData = response;
        } else {
          this.globalVars.rootScope.tryToGoPage('/');
        }
      },
      error: (_) => {
        this.globalVars.rootScope.tryToGoPage('/');
      }
    });
  }

  verificarCoincidencia(field: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control || !this.userForm || !this.userForm.controls[field]) {
        return null;
      }
      const fristValue = control.value;
      const confirmarContraseña = this.userForm.controls[field].value;
      return fristValue === confirmarContraseña ? null : { camposNoCoinciden: true };
    };
  }

  vereficarPassword(userControl: AbstractControl): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const minLength = 8;
      const maxLength = 20;
      const mayReg = /[A-ZÑ]/;
      const minReg = /[a-zñ]/;
      const numReg = /\d/;

      if (!control || !userControl) {
        return null;
      }

      const value = control.value;
      const valueUserName = userControl.value;
      if (!value) {
        return { required: 'required' };
      }

      if (!minReg.test(value)) {
        return { validPassword: PasswordError.NOMIN };
      }

      if (!mayReg.test(value)) {
        return { validPassword: PasswordError.NOMAYUS };
      }

      if (!numReg.test(value)) {
        return { validPassword: PasswordError.NONUM };
      }
      if (value.length < minLength) {
        return { validPassword: PasswordError.TOSHORT };
      }
      if (value.length > maxLength) {
        return { validPassword: PasswordError.TOLONG };
      }

      if (valueUserName && value.toLowerCase().indexOf(valueUserName.toLowerCase()) != -1) {
        return { validPassword: PasswordError.INCLUSER };
      }

      if (!this.allUsuarioData) {
        return null;
      }

      if (this.allUsuarioData.Nombre && value.toLowerCase().indexOf(this.allUsuarioData.Nombre.toLowerCase()) != -1) {
        return { validPassword: PasswordError.INCLUNAME };
      }
      if (
        this.allUsuarioData.PrimerApellido &&
        value.toLowerCase().indexOf(this.allUsuarioData.PrimerApellido.toLowerCase()) != -1
      ) {
        return { validPassword: PasswordError.INCLUSURNAME };
      }
      if (
        this.allUsuarioData.SegundoApellido &&
        value.toLowerCase().indexOf(this.allUsuarioData.SegundoApellido.toLowerCase()) != -1
      ) {
        return { validPassword: PasswordError.INCLUSURNAME2 };
      }

      let birthDate = '';
      if (this.allUsuarioData.FechaNacimiento) {
        const dateFormat = new Date(
          parseInt(this.allUsuarioData.FechaNacimiento.slice(6, this.allUsuarioData.FechaNacimiento.length - 2))
        );
        const day = dateFormat.getDate();
        const month = dateFormat.getMonth() + 1;
        const year = dateFormat.getFullYear();
        birthDate = `${day.toString()}${month.toString()}${year.toString()}`;
      }

      if (birthDate && control.value.toLowerCase().indexOf(birthDate) != -1) {
        return { validPassword: PasswordError.INCLUBIRTHDAT };
      }

      return null;
    };
  }

  setAllControllsTouchedDirty(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const value = formGroup.get(field);
      value.markAsTouched();
      value.markAsDirty();
    });
  }

  toggleTypeInput(field: string) {
    this.showPassword[field] = !this.showPassword[field];
  }

  setFormEvents() {
    this.userForm.get('pin1').valueChanges.subscribe((data) => {
      this.userForm.controls['pin2'].updateValueAndValidity({ emitEvent: false });
    });

    this.userForm.get('pin2').valueChanges.subscribe((data) => {
      this.userForm.controls['pin1'].updateValueAndValidity({ emitEvent: false });
    });

    this.userForm.get('password2').valueChanges.subscribe((data) => {
      this.userForm.controls['password1'].updateValueAndValidity({
        emitEvent: false
      });
    });
    this.userForm.get('password1').valueChanges.subscribe((data) => {
      this.userForm.controls['password2'].updateValueAndValidity({
        emitEvent: false
      });
    });
  }

  ngOnDestroy(): void {
    if (this.$tycSubscription) {
      this.$tycSubscription.unsubscribe();
    }
  }
}
