import { Injectable } from '@angular/core';
import { GenderOptions } from '@interfaces/interfaces';

export interface MaestroDatos {
  id: number;
  name: string;
}

@Injectable({ providedIn: 'root' })
export class MaestrosService {
  private months: Array<MaestroDatos> = [
    { id: 1, name: 'Enero' },
    { id: 2, name: 'Febrero' },
    { id: 3, name: 'Marzo' },
    { id: 4, name: 'Abril' },
    { id: 5, name: 'Mayo' },
    { id: 6, name: 'Junio' },
    { id: 7, name: 'Julio' },
    { id: 8, name: 'Agosto' },
    { id: 9, name: 'Septiembre' },
    { id: 10, name: 'Octubre' },
    { id: 11, name: 'Noviembre' },
    { id: 12, name: 'Diciembre' }
  ];

  private residentTypes: Array<MaestroDatos> = [
    { id: 0, name: 'Residente en España' },
    { id: 1, name: 'No residente en España' }
  ];

  private documentTypes: Array<MaestroDatos> = [
    { id: 754310000, name: 'Id Extranjero' },
    { id: 754310006, name: 'NIE' },
    { id: 754310001, name: 'Carnet de conducir' },
    { id: 754310002, name: 'Pasaporte' },
    { id: 754310004, name: 'Seguridad Social' }
  ];

  private genders: Array<MaestroDatos> = [
    { id: 1, name: GenderOptions.Hombre },
    { id: 2, name: GenderOptions.Mujer }
    //{id: 3, name: 'Otros'}
  ];
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}

  getMeses(): Array<MaestroDatos> {
    return this.months;
  }

  getYears(min = 18, max = 100): Array<number> {
    const yearsForm = [];
    const nowYear = new Date().getFullYear();
    const maxYear = nowYear - min;
    const minYear = nowYear - max;
    for (let i = maxYear; i > minYear; i--) {
      yearsForm.push(i);
    }
    return yearsForm;
  }

  getResidentTypes(): Array<MaestroDatos> {
    return this.residentTypes;
  }

  getDocumentTypes(): Array<MaestroDatos> {
    return this.documentTypes;
  }

  getGender(): Array<MaestroDatos> {
    return this.genders;
  }

  createUser() {
    return {
      Genero_Hombre_Mujer: null,
      NIF: null,
      AceptaEmailPromo_Y_N: false,
      AceptaEmailPromo_Y_NSpecified: false,
      AceptaSMSPromo_Y_N: false,
      AceptaSMSPromo_Y_NSpecified: false,
      Ciudad: null,
      CodigoPostalExt: null,
      CodigoPostal: '',
      Direccion: '',
      EstadoCta: null,
      FechaNacimiento_YYYYMMDD: new Date(1397484309733),
      FechaNacimiento_YYYYMMDDSpecified: false,
      NombreUsuario: null,
      Pais: null,
      Password: null,
      Provincia: null,
      SegundoApellido: null,
      VerifiDocumental: 'Pendiente',
      NumMovilExt: null,
      NumMovil: null,
      Nacionalidad: null,
      Direccion_Correo: null,
      Nombre: null,
      PrimerApellido: null,
      Afiliado: null,
      VersionTyCAceptada: null,
      DispositivoRegistro: null,
      CodigoAgente: null,
      CodigoPartner: null,
      OrigenWebLocal: 'Web',
      TipoDocumento_NIF_NIE: 'NIF/NIE',
      CodigoBono: null,
      NodoSalaId: null
    };
  }
}
