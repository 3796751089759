export class C_CategoryInfo {
  CategoryId: number | string;
  IsRelevant: boolean;
  CategoryName: string;
  numGames: number;
  image?: string;
  term?: string;
  type?: string;
  priority?: number;
  name?: string;
  selected?: boolean;
  constructor(
    catId: number | string,
    isRelevant: boolean,
    catName: string,
    image?: string,
    term?: string,
    type?: string,
    priority?: number,
    name?: string,
    selected?: boolean,
  ) {
    this.CategoryId = catId;
    this.IsRelevant = isRelevant;
    this.CategoryName = catName;
    this.numGames = 1;
    this.term = term;
    this.type = type;
    this.priority = priority;
    this.name = catName;
    this.selected = selected;
  }

  static setCategories(categories: any[], node: string, previousCategories?: any[]): C_CategoryInfo[] {
    const categoriesAux: any[] = previousCategories || [];
    let indexTemp;

    for (let i: number = 0; i < categories.length; i++) {
      switch (node) {
        case "Name":
          if (categories[i].CategoryInfo) {
            indexTemp = categoriesAux.findIndex(
              (myItem) => myItem.CategoryId === categories[i].CategoryInfo.CategoryId,
            );
          } else {
            indexTemp = categoriesAux.findIndex((item) => item.CategoryId === 999);
          }
          break;
        case "LeagueName":
          indexTemp = categoriesAux.findIndex((myItem) => myItem.CategoryName === categories[i][node]);
          break;
      }

      if (categories[i][node]) {
        if (indexTemp === -1) {
          //nueva cat

          const newCat: C_CategoryInfo = new C_CategoryInfo(
            parseInt(categories[i].CategoryInfo ? categories[i].CategoryInfo.CategoryId : categories[i].NodeId),
            categories[i].IsActive,
            categories[i].CategoryInfo ? categories[i].CategoryInfo.CategoryName : categories[i][node],
          );
          newCat.numGames = 1;

          categoriesAux.push(newCat);
        } else {
          //ya existe
          categoriesAux[indexTemp].numGames++;
        }
      } else {
        //no hay CategoryName
      }
    }
    return categoriesAux;
  }
}
