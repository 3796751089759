export class SportNavItem {
  Name: string;
  NodeId: string;
  SportHandle: string;
  Selected: boolean;
  Data: any;
  SportId: string;

  constructor(Name: string, NodeId: string, SportHandle: string, Selected: boolean, Data: any, SportId: string) {
    this.Name = Name;
    this.NodeId = NodeId;
    this.SportHandle = SportHandle;
    this.Selected = Selected;
    this.Data = Data;
    this.SportId = SportId;
  }
}

export interface SportNav {
  selectedSportsNav: SportNavItem;
  sportsNav: SportNavItem[];
}
