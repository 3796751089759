/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { IonContent, IonicModule, NavController, NavParams } from '@ionic/angular';
import { Component, ViewChild, inject } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { UserService } from '../../../providers/UserService';
import { CookiesService } from '../../../providers/CookiesService';
import { TrackingService } from '../../../providers/TrackingService';
import { CookieNames } from '../../../models/CookieNames';
import { Utils } from '../../../utils/Utils';
import { DeviceService } from '../../../services/device.service';
import { EventsService } from '../../../providers/EventsService';
import { MSO_PATHS, PAGES_PATHS } from '../../../lib/constants/routes';
import { GlobalVarsModel } from '../../../models/ModelGlobalsVar';
import { NewBaseService } from '../../../providers/newBase.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CodereFooterComponent } from '../../../components/mso/footer/codere-footer/codere-footer';
import { UserSectionTitle } from '../../../components/mso/user-section-title/user-section-title';
import { Router } from '@angular/router';

@Component({
  standalone: true,
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'cuota-type-page',
  templateUrl: './cuota-type.html',
  styleUrls: ['./cuota-type.scss'],
  imports: [CommonModule, IonicModule, FormsModule, TranslateModule, CodereFooterComponent, UserSectionTitle],
  providers: [NavParams]
})
export class CuotaTypePage {
  @ViewChild(IonContent) content: IonContent;
  selectedOddType: string;
  selectedCuotaChange: any;
  firstEnter = true;
  globalVars!: GlobalVarsModel;
  isMobile: boolean;

  events = inject(EventsService);
  nav = inject(NavController);
  navParams = inject(NavParams);
  trackingService = inject(TrackingService);
  newBaseService = inject(NewBaseService);
  utils = inject(Utils);
  translate = inject(TranslateService);
  userService = inject(UserService);
  cookiesService = inject(CookiesService);
  router = inject(Router);
  device = inject(DeviceService);

  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });

    this.selectedCuotaChange = this.globalVars.cuotaAccept;
    this.selectedOddType = this.globalVars.oddType;
    this.isMobile = this.device.isMobile();
  }
  ionViewWillEnter() {
    this.router.navigate([MSO_PATHS.BASE + '/' + MSO_PATHS.CuotaTypePage]);
    this.trackingService.trackEvent(['AccessToOddType', '', '', 'Acceder tipo de cuota', 'event']);
    this.events.publish('footer:refresh', this.content);
  }

  changeOddsType() {
    if (this.globalVars.oddType != this.selectedOddType) {
      this.globalVars.oddType = this.selectedOddType;
      this.cookiesService.setCookie(CookieNames.Cuota, this.globalVars.oddType, 365 * 24 * 60 * 60 * 1000);
    }
  }

  changeCuotaAccept() {
    this.utils.setCuotaAccept(this.selectedCuotaChange);
    if (this.globalVars.user.logged) {
      this.userService.updateCustomizations(this.globalVars.user.customization).subscribe(
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {},
        (err) => {
          console.error(err);
        }
      );
    }
    this.firstEnter = false;
  }

  update() {
    this.router.navigate([PAGES_PATHS.BASE + '/' + PAGES_PATHS.HomePage]);
  }
}
