/* eslint-disable @typescript-eslint/no-explicit-any */
import { Paso1Page } from './paso1/paso1';
export { Paso1Page } from './paso1/paso1';

import { Paso2Page } from './paso2/paso2';
export { Paso2Page } from './paso2/paso2';

import { RegistroNewPage } from './registro/registro';
export { RegistroNewPage } from './registro/registro';

export const RegistroESNewPages: any[] = [Paso1Page, Paso2Page, RegistroNewPage];
