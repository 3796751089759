/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable */
import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, map } from 'rxjs';
import { BaseService } from '@providers/BaseService';
import { C_DetailTicket, C_NodeTicket } from '../models/C_ResponseTickets';
import { Utils } from '@utils/Utils';
import { LogService } from './LogService';
import { NewBaseService } from './newBase.service';
import { GlobalVarsModel } from '@models/ModelGlobalsVar';
import { Constants } from '../constants';

@Injectable({ providedIn: 'root' })
export class NewUserService extends BaseService {
  errorList: Array<{ code: string; label: string }>;

  override globalVars!: GlobalVarsModel;
  override newBaseService = inject(NewBaseService);
  override utils = inject(Utils);
  override httpClient = inject(HttpClient);
  logService = inject(LogService);

  constructor() {
    super();
    this.errorList = [];
    this.newBaseService.getVars.subscribe((vars) => {
      this.globalVars = vars;
    });
  }

  getAuthToken(): Observable<any> {
    const url = `${this.globalVars.DOMAIN}/SportsMisc/api/Account/GetAuthToken`;
    return this.myGet(url, true);
  }

  payoutBetTicket(
    ticketId: string,
    amount: number,
    esPresencial = false
  ): Observable<{ success: string; status: string; statuscode?: string }> {
    let url: string;
    if (esPresencial) {
      url = `${this.globalVars.SportsMiscUrl}${Constants.OrderFormPayOutBetTicketCard}?ticketid=${ticketId}&amount=${amount}&cardNumber=${this.globalVars.user.codereCard.cardNumber}`;
      if (!this.globalVars.FEATURES.SPO_CodereCardPayout_SportsMisc) {
        url = `${this.globalVars.URLBASE}orderform/payoutbetticketCard?ticketid=${ticketId}&amount=${amount}&cardNumber=${this.globalVars.user.codereCard.cardNumber}`;
      }
      return this.myPost(url, null);
    } else {
      if (this.globalVars.licenseTicketType != '') {
        url = `${this.globalVars.URLBASE}orderform/PayOutBetTicketExternal?ticketid=${ticketId}&amount=${amount}&licenseTikect=${this.globalVars.licenseTicketType}`;
        this.globalVars.licenseTicketType = '';
        return this.myGet(url);
      } else {
        url = `${this.globalVars.SportsMiscUrl}${Constants.OrderFormPayOutBetTicketTicket}?ticketid=${ticketId}&amount=${amount}`;
        return this.myPost(url, null);
      }
    }
  }

  getTicketsByDateOnAzureNewModel(
    betType: string,
    date: string,
    endDate: string,
    token: string
  ): Observable<C_NodeTicket[]> {
    // TODO : this.globalVars.GetTicketsUrl
    // let url = this.globalVars.GetTicketsUrl + 'getTicketsByDate?typeBetH=' + betType + '&startDate=' + date;
    let url = '';
    if (endDate !== undefined && endDate !== null) {
      url = url + '&endDate=' + endDate;
    }
    url = url + '&authtoken=' + token;
    return this.myGet(url).pipe(map((data) => data));
  }

  getTicketsByDateOnAzure(betType: string, date: string, endDate: string, token: string): Observable<C_NodeTicket[]> {
    const url = `${this.globalVars.UrlBaseBettingServiceHub}/home/getticketsbydate?language=${this.globalVars.Idioma}&typeBetH=${betType}&startDate=${date}&endDate=${endDate}&authtoken=${token}`;
    return this.myGet(url).pipe(map((data) => data.data));
  }

  getTicketInfoFromAzureNewModel(ticketId: string, token: string, hideLoader = false): Observable<C_DetailTicket> {
    // TODO : this.globalVars.GetTicketsUrl
    //const url = this.globalVars.GetTicketsUrl + 'ViewTicket?ticketId=' + ticketId + '&authtoken=' + token;
    const url = '';
    return this.myGet(url, hideLoader);
  }

  getTicketInfoFromAzure(ticketId: string, token: string, hideLoader = false): Observable<C_DetailTicket> {
    const url = `${this.globalVars.UrlBaseBettingServiceHub}/home/ViewTicket?language=${this.globalVars.Idioma}&ticketid=${ticketId}&authtoken=${token}`;
    return this.myGet(url, hideLoader);
  }

  getTicketsByDate(betType: string, date: string): Observable<C_NodeTicket[]> {
    const url = this.globalVars.URLBASE + 'orderform/getticketsbydate?typeBetH=' + betType + '&startDate=' + date;
    return this.myGet(url).pipe(map((data) => data.data));
  }

  getTicketsByDateFromPlayerProps(betType: string, date: string): Observable<any> {
    const url =
      this.globalVars.DOMAIN +
      '/checkreceivemailmvc/Orderform/GetPlayerPropBets?startDate=' +
      date +
      '&typeBetH=' +
      betType;
    return this.myGet(url).pipe(map((data) => data.bets));
  }

  getTicketInfoNewModel(ticketId: string, token: string = '', hideLoader: boolean = false): Observable<C_DetailTicket> {
    var url = this.globalVars.GET_TICKETS_URL + 'ViewTicket?ticketId=' + ticketId;
    if (token != '') {
      url = url + '&authToken=' + token;
    }
    return this.myGet(url, hideLoader);
  }

  getTicketInfo(ticketId: string, hideLoader = false): Observable<C_DetailTicket> {
    let url: string = `${this.globalVars.URLBASE}${Constants.getTicketInfoMonolith}?ticketid=${ticketId}`;
    return this.myGet(url, hideLoader);
  }
  getTicketInfoCard(ticketId: string): Observable<C_DetailTicket> {
    let url = `${this.globalVars.SportsMiscUrl}${Constants.OrderFormGetTicketInfoCard}?ticketid=${ticketId}&cardnumber=${this.globalVars.user.codereCard.cardNumber}`;
    return this.myGet(url);
  }

  getCardHistory(type: string, date: string): Observable<any> {
    const url: string = `${this.globalVars.SportsMiscUrl}${Constants.CodereCardGetCardHistory}?typeBetH=${type}&startDate=${date}`;
    return this.myGet(url);
  }

  activateUser(data: any) {
    const url = this.globalVars.URLBASE_USERSERVICE + 'AddNickNamePwd';
    return this.myPost(url, data);
  }
  getUserDataByPinCode(token: string) {
    const url = this.globalVars.URLBASE + 'account/GetUserDataByPinCode?pincode=' + token;
    return this.myGet(url);
  }

  getSessionInitDate(): Observable<any> {
    let url: string = `${this.globalVars.SportsMiscUrl}${Constants.AccountGetCurrentSessionStart}`;
    return this.myGet(url, true);
  }

  getEarlyCashoutEnabled(): boolean {
    return this.globalVars.FEATURES.EarlyCashoutEnabled;
  }

  getGlobalVars(): GlobalVarsModel {
    return this.globalVars;
  }
}
