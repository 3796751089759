import { Injectable, Pipe, PipeTransform, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'CodereFormatDate', standalone: true })
@Injectable({ providedIn: 'root' })
export class CodereFormatDatePipe implements PipeTransform {
  public translate = inject(TranslateService);
  transform(value: string, args: any[] = []): string {
    if (value == undefined || value == null) return '';

    let format: number;
    if (args.length > 0) {
      format = parseInt(args[0]);
    } else {
      format = 0;
    }

    switch (format) {
      case 0:
        // Format the date into 24 Oct 11:15
        return this.formatTicketDate(value);
      case 1:
        // Format the date into DD/MM/YYYY
        return this.convertIsoToDdmmyyyy(value);
      default:
        // Format the date into 24 Oct 11:15
        return this.formatTicketDate(value);
    }
  }

  private getMonthName(mes: number): string {
    //devuelve el nombre del mes en formato MMM
    const meses = [
      this.translate.instant('month1'),
      this.translate.instant('month2'),
      this.translate.instant('month3'),
      this.translate.instant('month4'),
      this.translate.instant('month5'),
      this.translate.instant('month6'),
      this.translate.instant('month7'),
      this.translate.instant('month8'),
      this.translate.instant('month9'),
      this.translate.instant('month10'),
      this.translate.instant('month11'),
      this.translate.instant('month12')
    ];
    return meses[mes];
  }

  private formatoNumber(numero, digitos): string {
    //devuelve el string del número con n dígitos
    let numberStr = numero.toString();
    while (numberStr.length < digitos) {
      numberStr = '0' + numberStr;
    }
    return numberStr;
  }

  private formatTicketDate(isoDateStr: string): string {
    // Parse the ISO 8601 date string
    const dateObj = new Date(isoDateStr);
    // Extract day, month, hours, and minutes
    const day = String(dateObj.getUTCDate()).padStart(2, '0');
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const month = monthNames[dateObj.getUTCMonth()];
    const hours = String(dateObj.getUTCHours()).padStart(2, '0');
    const minutes = String(dateObj.getUTCMinutes()).padStart(2, '0');
    // Format the date into 24 Oct 11:15
    return `${day} ${month} ${hours}:${minutes}`;
  }

  private convertIsoToDdmmyyyy(isoDateStr: string): string {
    // Parse the ISO 8601 date string
    const dateObj = new Date(isoDateStr);
    // Extract day, month, and year
    const day = String(dateObj.getUTCDate()).padStart(2, '0');
    const month = String(dateObj.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = dateObj.getUTCFullYear();
    // Format the date into dd/MM/YYYY
    return `${day}/${month}/${year}`;
  }
}
