import { Component } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { BaseFilter } from '../base-filter/base-filter';

@Component({
  selector: 'image-filter',
  imports: [IonicModule, CommonModule],
  standalone: true,
  providers: [],
  templateUrl: './image-filter.component.html',
  styleUrls: ['./image-filter.component.scss'],
  host: {
    class: 'image-filters'
  }
})
export class ImageFilterComponent extends BaseFilter {
  constructor() {
    super();
  }
}
