/* eslint-disable eqeqeq */
import { Pipe, PipeTransform } from '@angular/core';
import { GlobalVarsModel } from '@models/ModelGlobalsVar';

@Pipe({ name: 'formatMejora' })
export class FormatMejora implements PipeTransform {
  globalVars!: GlobalVarsModel;

  transform(t?: number): string {
    if (t == undefined || t == null) return '-';
    return ((t - 1) * 100).toFixed(2);
  }
}
