/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { NavController, MenuController, NavParams, IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';
import { GlobalVarsModel } from '@models/ModelGlobalsVar';
import { PostMesageData, PostMessageActions } from '@models/PostMessageData';
import { LicenseTypes } from '@models/MasterData';
import { NewBaseService } from '@providers/newBase.service';
import { DeviceService } from '@services/device.service';
import { CodereFooterComponent } from '@shared-mso-components/footer/codere-footer/codere-footer';
import { BackButton } from '@shared-common-components/back-button/back-button';
import { UserSectionTitle } from '@shared-mso-components/user-section-title/user-section-title';

import { Utils } from '@utils/Utils';
import { CashierPage } from '../../../../../shared/src/pages/payments/cashier/cashier';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ayuda-page',
  templateUrl: './ayuda.html',
  styleUrls: ['./ayuda.scss'],
  standalone: true,
  imports: [CommonModule, IonicModule, CodereFooterComponent, TranslateModule, BackButton, UserSectionTitle],
  providers: [NavParams]
})
export class HelpPage {
  section: any;
  scrHelp: any;
  eventListener: any;
  globalVars!: GlobalVarsModel;
  isMobile: boolean;

  nav = inject(NavController);
  menu = inject(MenuController);
  navParams = inject(NavParams);
  router = inject(Router);
  sanitizer = inject(DomSanitizer);
  newBaseService = inject(NewBaseService);
  utils = inject(Utils);
  route = inject(Router);
  device = inject(DeviceService);

  constructor() {
    this.isMobile = this.device.isMobile();
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });

    this.section = this.navParams.get('section');
    switch (this.section) {
      case 'verificacion':
        this.scrHelp = this.sanitizer.bypassSecurityTrustResourceUrl(
          'https://www.codere.es/mi-cuenta/verificaci%C3%B3n-documental'
        );
        break;
      default:
        this.scrHelp = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.codere.es/ayuda');
    }

    if (this.globalVars.licenseType == LicenseTypes.Mexico) {
      this.scrHelp = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.codere.mx/ayuda');
    }
    if (this.globalVars.licenseType == LicenseTypes.Colombia) {
      this.scrHelp = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.codere.com.co/ayuda');
    }
    if (this.globalVars.licenseType == LicenseTypes.Panama) {
      this.scrHelp = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.codere.pa/ayuda');
    }
    if (this.globalVars.licenseType == LicenseTypes.ArgentinaCaba) {
      this.scrHelp = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.codere.bet.ar/ayuda');
    }
    if (this.globalVars.licenseType == LicenseTypes.ArgentinaMendoza) {
      this.scrHelp = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.mdz.codere.bet.ar/ayuda');
    }
    if (this.globalVars.licenseType == LicenseTypes.ArgentinaPBA) {
      this.scrHelp = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.codere.bet.ar/ayuda');
    }
    // Add post messages event listener
    this.eventListener = this.listenEvents.bind(this);
  }
  helpageload(event) {
    event.target.classList.remove('nodisplay');
  }

  ionViewWillEnter(event) {
    this.utils.hideFormAccessoryBar(false);

    window.addEventListener('message', this.eventListener);
  }

  ionViewWillLeave(event) {
    this.utils.hideFormAccessoryBar(true);
  }

  ionViewDidLeave() {
    window.removeEventListener('message', this.eventListener);
  }

  openModalUser() {
    this.menu.open('right');
  }

  backToHome() {
    this.router.navigate(['/']);
  }

  /**
   * Event listener method for page post messages
   * @param event Event data
   */
  listenEvents(event: any) {
    console.info(event.data);
    const postMessageData: PostMesageData = event.data;
    switch (postMessageData.action) {
      case PostMessageActions.Navigate:
        window.location.href = event.data.url;
        break;
      case PostMessageActions.OpenDeposits:
        this.globalVars.forceToOpenLogin = true;
        this.globalVars.rootScope.openModalOrPage(CashierPage, postMessageData.data, true, 'modalCashier');
        break;
      default:
        break;
    }
  }
}
