<ng-container *ngIf="isDesktop && oneGameinfo">
  <div #containerGame (window:resize)="onResize($event)">
    <div *ngIf="srcGame" class="h100">
      <iframe id="gameFrame" #gameFrame [src]="srcGame" width="100%" height="100%" (load)="setStyles()"> </iframe>
    </div>
    <div *ngIf="!srcGame && oneGameinfo?.game?.srcImg" class="h100">
      <a class="noneA" style="opacity: 1" (click)="playGame()"
        ><img class="imgM" [src]="oneGameinfo?.game?.srcImg"
      /></a>
    </div>
    <div
      *ngIf="!srcGame && oneGameinfo?.game?.srcImg === null || !srcGame && oneGameinfo?.game?.srcImg === undefined  || !srcGame && oneGameinfo?.game?.srcImg === ''"
      class="h100"
    >
      <a class="noneA" style="opacity: 1" (click)="playGame()"
        ><img class="imgM" src="assets/global/img/fondo_app_web.jpg" />
        <div class="cont-g">
          <div class="cont-button">
            <button class="btnPlayGame">JUEGA AHORA</button>
          </div>
        </div>
      </a>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!isDesktop && oneGameinfo">
  <div class="cont-iframe">
    <a class="noneA" (click)="playGameMobile()">
      <img *ngIf="oneGameinfo?.game?.srcImg" class="imgM" [src]="oneGameinfo?.game?.srcImg" />
      <img
        *ngIf="oneGameinfo?.game?.srcImg === null || oneGameinfo?.game?.srcImg === undefined || oneGameinfo?.game?.srcImg === ''"
        class="imgM"
        src="assets/global/img/bgTicket.png"
      />
      <div
        *ngIf="oneGameinfo?.game?.srcImg === null || oneGameinfo?.game?.srcImg === undefined || oneGameinfo?.game?.srcImg === ''"
        class="cont-g"
      >
        <div class="cont-button">
          <button class="btnPlayGame">JUEGA AHORA</button>
        </div>
      </div>
    </a>
  </div>
</ng-container>
