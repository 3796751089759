/* eslint-disable @typescript-eslint/no-explicit-any */
import { CommonModule } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  ViewChild,
  ContentChildren,
  QueryList,
  ViewContainerRef,
  HostBinding,
  OnChanges,
  AfterContentInit,
  inject,
  ChangeDetectorRef
} from '@angular/core';
import { BetDisplayDirective } from '../../../directives/bet-display/bet-display';
import { TranslateModule } from '@ngx-translate/core';
import { DeviceService } from '../../../services/device.service';
import { Router } from '@angular/router';

@Component({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sb-grid-item',
  templateUrl: './sb-grid-item.html',
  standalone: true,
  imports: [CommonModule, TranslateModule, BetDisplayDirective],
  styleUrls: ['./sb-grid-item.scss']
})
export class SbGridItemComponent implements OnChanges, AfterContentInit {
  @ViewChild('content') content: ElementRef;
  @ViewChild('bets', { read: ViewContainerRef }) bets: ViewContainerRef;
  @ContentChildren(BetDisplayDirective) betsContainer: QueryList<any>;
  @Input() mode = 'teams';
  @Input() event;
  @Input() betType: string;
  @Input() special: boolean;
  @Input() hasBets = true;
  @Input() currentPage: string;
  @Output() navigate: EventEmitter<any> = new EventEmitter();
  @HostBinding('class.has-special-bets')
  hasContent: boolean;
  isDesktop = false;
  displayCss = 'flex';

  deviceService = inject(DeviceService);
  private router = inject(Router);

  constructor(private cd: ChangeDetectorRef) {
    this.isDesktop = this.deviceService.isDesktop();
  }

  ngOnChanges() {
    this.canBet();
    this.cd.detectChanges();
    if (this.router.routerState.snapshot.url.indexOf('SpecialTeam') > 0) {
      this.displayCss = 'block';
    }
  }

  ngAfterContentInit() {
    this._hasNoContent();
  }

  private _hasNoContent() {
    this.hasContent = Boolean(this.content?.nativeElement?.childElementCount);
    this.cd.detectChanges();
  }

  itemHasContent() {
    return !this.content?.nativeElement?.childElementCount || false;
  }

  private getResults(obj) {
    // eslint-disable-next-line no-prototype-builtins
    const results = obj?.hasOwnProperty('Results') ? 'Results' : 'GroupResults';
    return obj[results].length;
  }

  private canBet() {
    if (this.event) {
      if (this.event.isSixPack || this.event.isCombined) {
        return;
      }
      const results = this.event.Games
        ? this.event.Games.reduce((acc, game) => (acc += this.getResults(game)), 0)
        : this.getResults(this.event);
      this.hasBets = Boolean(results);
    }
  }
}
