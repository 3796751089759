<ng-container [formGroup]="fgrp">
  <div class="col s12 select-element">
    <select
      [id]="'select-' + fcName"
      item-content
      [formControlName]="fcName"
      (focus)="handleFocus($event)"
      (change)="handleChange($event)"
      (blur)="handleBlur($event)"
    >
      <option *ngFor="let option of options" [ngValue]="option">
        {{ option.name }}
      </option>
    </select>
    <label
      [for]="'select-' + fcName"
      [id]="'label-' + fcName"
      class="labelselect"
      [ngClass]="{
        floating: fgrp.get(fcName).dirty || fgrp.get(fcName).valid,
        'green-text': fgrp.get(fcName).valid
      }"
    >
      <span #labelSpanRef>{{ label }}</span>
      <ion-icon *ngIf="fgrp.get(fcName).valid" size="large" name="checkmark-circle-outline" class="green-text" />
      <ion-icon *ngIf="fgrp.get(fcName).invalid" size="large" name="checkmark-circle-outline" class="invisible" />
    </label>
  </div>
</ng-container>

<!-- Mensajes de error -->
<cdr-control-error style="margin-bottom: 15px" *ngIf="errorsCol" [fControl]="fControl" [errorsCol]="errorsCol">
</cdr-control-error>
