import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { GlobalVarsModel } from '../models/ModelGlobalsVar';
import { NewBaseService } from './newBase.service';

@Injectable({ providedIn: 'root' })
export class JackpotsService {
  _globals!: GlobalVarsModel;

  //#region Inject Service
  httpClient = inject(HttpClient);
  newBaseService = inject(NewBaseService);
  //#endregion

  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this._globals = data;
    });
  }

  getApiClientConfig() {
    const url = this._globals.LOBBY_JACKPOTS_TICKERS + 'GetClientApiConfiguration';
    return this.httpClient.get<any>(url).pipe(map((res) => res.json()));
  }

  getJackpots(): Observable<any[]> {
    const url = this._globals.LOBBY_JACKPOTS_TICKERS + 'GetLobbyJackpots';
    return this.httpClient.get<any>(url).pipe(map((res) => res.json()));
  }

  getJackpotGames() {
    const url = this._globals.LOBBY_JACKPOTS_TICKERS + 'GetAllJackpotGames';
    return this.httpClient.get<any>(url).pipe(map((res) => res.json()));
  }
}
