import { Injectable, inject } from '@angular/core';

import { Store } from '@ngrx/store';

import { I_CashoutParams, I_FetchMyBetsParams } from '../../models';

import { MyBetsState } from './my-bets.reducers';
import * as MyBetsActions from './my-bets.actions';

@Injectable({ providedIn: 'root' })
export class MyBetsFacade {
  store = inject(Store<MyBetsState>);

  lastParams: I_FetchMyBetsParams;

  constructor() {}

  fetchMyBets(params: I_FetchMyBetsParams) {
    this.lastParams = params;
    this.store.dispatch(MyBetsActions.FetchMyBets(params));
  }

  refreshMyBets() {
    this.store.dispatch(MyBetsActions.FetchMyBets(this.lastParams));
  }

  getCashoutByTicket(CashoutParams: I_CashoutParams) {
    this.store.dispatch(MyBetsActions.GetCashoutByTicket({ CashoutParams }));
  }
}
