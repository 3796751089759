<ion-header>
  <user-section-title [sectionTitle]=" !isReactivateAccount?'myactivity':'accountreactivation'"></user-section-title>
  <div class="btnlist" *ngIf="!isReactivateAccount">
    <ion-item class="ion-item-activity" lines="none">
      <ion-select
        class="custom-icon-select"
        justify="start"
        [formControl]="selectedDate"
        cancelText="{{'tCancel'|translate}}"
        placeholder="{{'histapu2d'|translate}}"
      >
        <div slot="label" class="ion-select-label">{{'histapfecha'|translate}}</div>
        <ion-select-option *ngFor="let item of selectList" [value]="item.value">{{item.name}}</ion-select-option>
      </ion-select>
      <ion-icon name="chevron-down-outline" class="custom-chevron-icon"></ion-icon>
    </ion-item>
  </div>
</ion-header>

<ion-content class="background-color-muted">
  <div class="widthcontent" padding>
    <div *ngIf="!isReactivateAccount">{{'activityinfo01'|translate}}</div>
    <div class="textbold" *ngIf="!isReactivateAccount">
      {{'activityinfo02'|translate}} {{startDate.getDate()}} {{'of'|translate}} {{getMonthString(startDate.getMonth())}}
      {{'activityinfo03'|translate}} {{endDate.getDate()}} {{'of'|translate}} {{getMonthString(endDate.getMonth())}}.
    </div>
    <div class="textbold" *ngIf="isReactivateAccount">{{'informationgamblingactivity'|translate}}</div>
    <div>
      <div class="title">{{'accessplatform'|translate}}</div>
      <!--Actividad de uso -->
      <div class="contentall" padding>
        <div class="date" *ngIf="!isReactivateAccount">
          {{startDate|date:'dd/MM/yyyy'}} - {{endDate|date:'dd/MM/yyyy'}}
        </div>
        <div class="date" *ngIf="isReactivateAccount">{{reactivateAccountData?.dateRGIAJ}}</div>
        <div>
          <ion-row *ngIf="!isReactivateAccount">
            <ion-col col-6>{{'accessplatform'|translate}}</ion-col
            ><!--Acceso a la plataforma -->
            <ion-col class="textaligne textbold">
              <span class="access">{{myActivity?.accessToPlatform}}</span>
              <!-- !!!!! -->
              <span>{{'accesses'|translate}}</span> </ion-col
            ><!--Accesos -->
          </ion-row>
          <ion-row *ngIf="isReactivateAccount">
            <ion-col col-7>{{'loginsperday'|translate}}</ion-col
            ><!--Número medio de logins por día -->
            <ion-col class="textaligne textbold">
              <span>{{reactivateAccountData?.accessToPlatform}}</span>
              <span>{{'accesses'|translate}}</span></ion-col
            ><!--Accesos -->
          </ion-row>
          <ion-row *ngIf="isReactivateAccount">
            <ion-col col-7>{{'casinosessionduration'|translate}}</ion-col
            ><!--Duración media de sesión de casino -->
            <ion-col class="textaligne textbold"><span>{{reactivateAccountData?.accessToCasino}}</span> </ion-col
            ><!--tiempo de sesión casino -->
          </ion-row>
        </div>
      </div>
    </div>
    <div *ngIf="!isReactivateAccount">
      <div class="title">{{'depositsandmethods'|translate}}</div>
      <!--Depósitos y métodos de pago -->
      <div class="contentall" padding>
        <div class="date">{{startDate|date:'dd/MM/yyyy'}} - {{endDate|date:'dd/MM/yyyy'}}</div>
        <div>
          <ion-row>
            <ion-col>{{'numberdeposits'|translate}}</ion-col>
            <ion-col class="textaligne textbold"
              ><span>{{myActivity?.depositsPaymentMethods.numberOfDeposits}}</span></ion-col
            >
          </ion-row>
          <ion-row class="paddingb15">
            <ion-col>{{'totaldeposits'|translate}}</ion-col>
            <ion-col class="textaligne textbold"
              ><span>{{myActivity?.depositsPaymentMethods.totalDeposits | parseMoney:[1]}}</span></ion-col
            >
          </ion-row>
          <ion-row class="bordert rowmethods">{{'paymentmethods'|translate}}</ion-row
          ><!--Métodos de pago -->
          <ng-container *ngIf="myActivity">
            <ion-row *ngFor="let item of myActivity.depositsPaymentMethods.paymentMethods" class="textbold">
              <ion-col>{{item.methodName}}</ion-col>
              <ion-col class="textaligne"><span>{{item.quantity | parseMoney:[1]}}</span></ion-col>
            </ion-row>
            <ion-row class="link">
              <div class="divlink" (click)="goToCashier()">
                <span class="textbold">{{'seedetail'|translate}}</span>
                <span class="codere-icon icon-arrow-right"></span
                ><!--Ver detalle -->
              </div>
            </ion-row>
          </ng-container>
        </div>
      </div>
      <div class="paddingt10">{{'activityinfosummarydeposit'|translate}}</div>
    </div>
    <div>
      <div class="title">{{(!isReactivateAccount?'summarymovements':'netbalance')|translate}}</div>
      <!-- Resumen de los movimientos -->
      <div class="contentall" padding>
        <div class="date" *ngIf="!isReactivateAccount">
          {{startDate|date:'dd/MM/yyyy'}} - {{endDate|date:'dd/MM/yyyy'}}
        </div>
        <div class="date" *ngIf="isReactivateAccount">{{reactivateAccountData?.dateRGIAJ}}</div>
        <div>
          <ion-row class="textbold paddingb15" *ngIf="!isReactivateAccount">
            <ion-col>{{'openingbalance' | translate}}</ion-col
            ><!--Saldo inicial -->
            <ion-col class="textaligne textbold"
              ><span>{{myActivity?.summaryOfMovements.openingBalance | parseMoney:[1]}}</span></ion-col
            >
          </ion-row>
          <ion-row class="textbold paddingb15" *ngIf="isReactivateAccount">
            <ion-col>{{'openingbalance' | translate}}</ion-col
            ><!--Saldo inicial -->
            <ion-col class="textaligne textbold"
              ><span>{{reactivateAccountData?.summaryData.openingBalance | parseMoney:[1]}}</span></ion-col
            >
          </ion-row>
          <ion-row class="bordert paddingt15" *ngIf="!isReactivateAccount">
            <ion-col>{{'tDepBig' | translate}}</ion-col
            ><!--Depósitos -->
            <ion-col class="textaligne"
              ><span>{{myActivity?.summaryOfMovements.summaryData.deposits | parseMoney:[1]}}</span></ion-col
            >
          </ion-row>
          <ion-row class="bordert paddingt15" *ngIf="isReactivateAccount">
            <ion-col>{{'tDepBig' | translate}}</ion-col
            ><!--Depósitos -->
            <ion-col class="textaligne"
              ><span>{{reactivateAccountData?.summaryData.deposits | parseMoney:[1]}}</span></ion-col
            >
          </ion-row>
          <ion-row *ngIf="!isReactivateAccount">
            <ion-col>{{'withdrawals' | translate}}</ion-col
            ><!--Retiros -->
            <ion-col class="textaligne"
              ><span>{{myActivity?.summaryOfMovements.summaryData.withdrawals | parseMoney:[1]}}</span></ion-col
            >
          </ion-row>
          <ion-row *ngIf="isReactivateAccount">
            <ion-col>{{'withdrawals' | translate}}</ion-col
            ><!--Retiros -->
            <ion-col class="textaligne"
              ><span>{{reactivateAccountData?.summaryData.withdrawals | parseMoney:[1]}}</span></ion-col
            >
          </ion-row>
          <ion-row *ngIf="!isReactivateAccount">
            <ion-col>{{'participations' | translate}}</ion-col
            ><!--Participaciones -->
            <ion-col class="textaligne"
              ><span>{{myActivity?.summaryOfMovements.summaryData.participations | parseMoney:[1]}}</span></ion-col
            >
          </ion-row>
          <ion-row *ngIf="!isReactivateAccount">
            <ion-col>{{'prizes' | translate}}</ion-col
            ><!--Premios -->
            <ion-col class="textaligne"
              ><span>{{myActivity?.summaryOfMovements.summaryData.awards | parseMoney:[1]}}</span></ion-col
            >
          </ion-row>
          <ion-row *ngIf="!isReactivateAccount">
            <ion-col class="paddingb15">{{'uPPBonus' | translate}}</ion-col
            ><!--Bonos -->
            <ion-col class="textaligne"
              ><span>{{myActivity?.summaryOfMovements.summaryData.bonuses | parseMoney:[1]}}</span></ion-col
            >
          </ion-row>
          <ion-row class="bordert textbold paddingt15" *ngIf="!isReactivateAccount">
            <ion-col>{{'endingbalance' | translate}}</ion-col>
            <!--Saldo Final -->
            <ion-col class="textaligne"
              ><span>{{myActivity?.summaryOfMovements.finalBalance | parseMoney:[1]}}</span></ion-col
            >
          </ion-row>
          <ion-row class="bordert textbold paddingt15" *ngIf="isReactivateAccount">
            <ion-col>{{'endingbalance' | translate}}</ion-col>
            <!--Saldo Final -->
            <ion-col class="textaligne"
              ><span>{{reactivateAccountData?.summaryData.finalBalance | parseMoney:[1]}}</span></ion-col
            >
          </ion-row>
          <ion-row class="bordert textbold paddingt15" *ngIf="isReactivateAccount">
            <ion-col>{{'netbalance' | translate}}</ion-col>
            <!--Saldo Neto -->
            <ion-col class="textaligne" *ngIf="reactivateAccountData"
              ><span>{{getNetBalance(reactivateAccountData) | parseMoney:[1]}}</span></ion-col
            >
          </ion-row>
          <ion-row class="link" *ngIf="!isReactivateAccount">
            <div class="divlink" (click)="goToSumary()">
              <span class="textbold">Ver extracto</span> <span class="codere-icon icon-arrow-right"></span>
            </div>
          </ion-row>
        </div>
      </div>
      <div *ngIf="isReactivateAccount && reactivateAccountData" class="textbold margint25">
        {{getCprTxt(reactivateAccountData)}}
      </div>
      <div class="paddingt10" *ngIf="!isReactivateAccount">{{'activityinfomovement' | translate}}</div>
    </div>
    <div *ngIf="!isReactivateAccount">
      <div class="title">{{'expenditurebalancehistory' | translate}}</div>
      <!--Histórico del balance de gasto -->
      <div class="contentall" padding>
        <div class="date">{{startDate|date:'dd/MM/yyyy'}} - {{endDate|date:'dd/MM/yyyy'}}</div>
        <div>
          <ion-row class="paddingb15">
            <ion-col>{{'casinohistory' | translate}}</ion-col
            ><!--Historial de Casino -->
            <ion-col class="textaligne textbold colorblue">
              <div (click)="goToSlotsHistory()" class="pointer">
                <span class="textbold">{{'seedetail' | translate}}</span>
                <span class="codere-icon icon-arrow-right"></span>
              </div>
            </ion-col>
          </ion-row>
          <ion-row class="bordert paddingt15">
            <ion-col>{{'bettinghistory' | translate}}</ion-col>
            <!--Historial de Apuestas -->
            <ion-col class="textaligne textbold colorblue">
              <div (click)="gotoMyBets()" class="pointer">
                <span class="textbold">{{'seedetail' | translate}}</span>
                <span class="codere-icon icon-arrow-right"></span>
              </div>
            </ion-col>
          </ion-row>
        </div>
      </div>
      <div class="paddingt10">{{'activityinfohistory' | translate}}</div>
    </div>
    <div *ngIf="!isReactivateAccount">
      <div class="title">{{'depositlimit' | translate}}</div>
      <!--Límite de depósito -->
      <div class="contentall" padding>
        <div class="date">{{startDate|date:'dd/MM/yyyy'}} - {{endDate|date:'dd/MM/yyyy'}}</div>
        <div>
          <ion-row class="textbold">
            <ion-col>{{'uPPLimitsDay' | translate}}</ion-col
            ><!--Límite diario -->
            <ion-col class="textaligne textbold"
              ><span
                >{{getFormatLimits(myActivity?.depositLimits.dailyLimit.current, 'day') | parseMoney:[1]}}</span
              ></ion-col
            >
          </ion-row>
          <ng-container *ngIf="myActivity">
            <ion-row *ngFor="let item of myActivity.depositLimits.dailyLimit.changes; last as lastchildren">
              <ion-col>{{item.dateChange|date:'dd/MM/yyyy'}}</ion-col>
              <ion-col class="textaligne textbold" [ngClass]="{'paddingb15': lastchildren}"
                ><span>{{item.amount | parseMoney:[1]}}</span></ion-col
              >
            </ion-row>
          </ng-container>
          <ion-row class="textbold bordert paddingt15">
            <ion-col>{{'uPPLimitsWeek' | translate}}</ion-col
            ><!--Límite semanal -->
            <ion-col class="textaligne textbold"
              ><span
                >{{getFormatLimits(myActivity?.depositLimits.weeklyLimit.current, 'week') | parseMoney:[1]}}</span
              ></ion-col
            >
          </ion-row>
          <ng-container *ngIf="myActivity">
            <ion-row *ngFor="let item of myActivity.depositLimits.weeklyLimit.changes last as lastchildren">
              <ion-col>{{item.dateChange|date:'dd/MM/yyyy'}}</ion-col>
              <ion-col class="textaligne textbold" [ngClass]="{'paddingb15': lastchildren}"
                ><span>{{item.amount | parseMoney:[1]}}</span></ion-col
              >
            </ion-row>
          </ng-container>

          <ion-row class="textbold bordert paddingt15">
            <ion-col>{{'uPPLimitsMounth' | translate}}</ion-col
            ><!--Límite mensual	 -->
            <ion-col class="textaligne textbold"
              ><span
                >{{getFormatLimits(myActivity?.depositLimits.monthlyLimit.current, 'month') | parseMoney:[1]}}</span
              ></ion-col
            >
          </ion-row>
          <ng-container *ngIf="myActivity">
            <ion-row *ngFor="let item of myActivity.depositLimits.monthlyLimit.changes">
              <ion-col>{{item.dateChange|date:'dd/MM/yyyy'}}</ion-col>
              <ion-col class="textaligne textbold"><span>{{item.amount | parseMoney:[1]}}</span></ion-col>
            </ion-row>
          </ng-container>
          <ion-row class="link"
            ><div class="divlink textbold paddingt15" (click)="gotoLimits()">
              {{'changeLimits' | translate}} <span class="codere-icon icon-arrow-right"></span></div
          ></ion-row>
        </div>
      </div>
      <div class="paddingt10">{{'activityinfodepositlimit' | translate}}</div>
    </div>
  </div>
  <codere-footer></codere-footer>
</ion-content>
