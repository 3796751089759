<ion-content class="is-modal">
  <div class="contModal">
    <img src="assets/global/img/logo.svg" style="width: 25%" />
    <h1>ATENCIÓN:</h1>

    <div>
      <p>
        La presente página web es de propiedad de Codere y para uso exclusivo de su personal para la realización de
        pruebas operativas. Prohibido el acceso o utilización de la misma sin autorización de Codere. No implica oferta
        de servicios oferta de juegos de azar. Imágenes a mero título ilustrativo, no contractuales.
      </p>
      <p>Advertencia de uso indebido:</p>
      <ul>
        <li>
          Codere no se responsabiliza por los daños y perjuicios que se pudieren ocasionar a los ingresantes a la página
          web en violación a lo aquí establecido.
        </li>
        <li>
          El ingresante perderá cualquier monto de dinero que deposite o transfiera a una cuenta de Codere o de un
          tercero.
        </li>
        <li>
          Codere se reserva el derecho de reclamar por los daños y perjuicios ocasionados por un acceso en violación de
          la presente y realizar las denuncias correspondientes
        </li>
      </ul>
      <div class="sendForm">
        <button class="ion-button" expand="full" type="button" (click)="closeModal()">Aceptar</button>
      </div>
    </div>
  </div>
</ion-content>
