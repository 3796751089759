<!-- eslint-disable @angular-eslint/template/eqeqeq -->
<div
  class="section-title"
  [class.section-titlepa]="!isDesktop && (globalVars.licenseType === 21 || globalVars.licenseType === 22)  "
>
  <i
    *ngIf="icon"
    class="sb-back-button color-light codere-icon icon-arrow-left"
    tappable=""
    (click)="onClickIcon()"
  ></i>
  <h1 class="title text-uppercase">{{ sectionTitle | translate}}</h1>
</div>
