import { EMPTY } from 'rxjs';
import { map } from 'rxjs/operators';
import { TransactionActionResult } from '@models/C_Transactions';
import { WithDrawalBase } from './withdrawal-base';

export class HalCashWithDrawal extends WithDrawalBase {
  tlf: string;
  pincode: string;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(args: any) {
    super(args);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  getForm() {}

  checkPayment() {
    return EMPTY;
  }

  executePayment() {
    return this.executeRequest().pipe(map((response: TransactionActionResult) => response));
  }

  showErrorCallback() {
    return EMPTY;
  }
  executeOkCallback() {
    return EMPTY;
  }
}
