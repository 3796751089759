<!-- eslint-disable -->
<!--Status-->
<!--Muesca de ticket-->
<div class="ticketTop"></div>
<ion-list clear class="ticketReceipt ticketOpened">
  <ion-list-header [ngClass]="'type_'+betDetails?.BetHistoryInfo?.TicketStatusNumber">
    {{betDetails?.BetHistoryInfo?.TicketStatus}}
    <ion-note item-right=""
      ><img src="assets/global/img/logobue.png" alt="" *ngIf="!!betDetails?.BetHistoryInfo?.VoucherCode"
    /></ion-note>
  </ion-list-header>

  <ion-item-group>
    <ion-item
      *ngIf="globalVars.licenseType != 4 && betDetails?.BetHistoryInfo?.PotentialWinningsOriginal != betDetails?.BetHistoryInfo?.PotentialWinnings && !betDetails?.BetHistoryInfo?.VoucherCode"
    >
      {{'v2_mejTuPremSinmej' | translate}}
      <ion-label item-right="">{{betDetails?.BetHistoryInfo?.PotentialWinningsOriginal | parseMoney}}</ion-label>
    </ion-item>
    <ion-item>
      <!--{{'hDetailsGan'|translate}}-->
      <span
        *ngIf="betDetails?.BetHistoryInfo?.TicketStatusNumber == '1' || betDetails?.BetHistoryInfo?.TicketStatusNumber == '5'"
        >{{'hDetailsGan' | translate}}</span
      >
      <span
        *ngIf="(betDetails?.BetHistoryInfo?.TicketStatusNumber != '1' && betDetails?.BetHistoryInfo?.TicketStatusNumber != '5') && (betDetails?.BetHistoryInfo?.PotentialWinningsOriginal != betDetails?.BetHistoryInfo?.PotentialWinnings && !betDetails?.BetHistoryInfo?.VoucherCode && globalVars.licenseType != 4)"
        >{{'v2_mejTuPremConmej' | translate}}</span
      >
      <span
        *ngIf="(betDetails?.BetHistoryInfo?.TicketStatusNumber != '1' && betDetails?.BetHistoryInfo?.TicketStatusNumber != '5') && (betDetails?.BetHistoryInfo?.PotentialWinningsOriginal == betDetails?.BetHistoryInfo?.PotentialWinnings || (betDetails?.BetHistoryInfo?.PotentialWinningsOriginal != betDetails?.BetHistoryInfo?.PotentialWinnings && !!betDetails?.BetHistoryInfo?.VoucherCode) || (betDetails?.BetHistoryInfo?.PotentialWinningsOriginal != betDetails?.BetHistoryInfo?.PotentialWinnings && globalVars.licenseType == 4))"
        >{{'compapuestaP2' | translate}}</span
      >
      <ion-label item-right="">{{betDetails?.BetHistoryInfo?.PotentialWinnings | parseMoney}}</ion-label>
    </ion-item>
    <ion-item>
      {{'compapuestaP1'|translate}}
      <ion-label item-right="">{{betDetails?.BetHistoryInfo?.Stake | parseMoney}}</ion-label>
    </ion-item>
    <ion-item>
      {{'hDetailsApu'|translate}}
      <ion-label item-right="">{{betDetails?.BetHistoryInfo?.TicketDatetime | extractDate:[2]}}</ion-label>
    </ion-item>
    <ion-item [class.noborder]="(betDetails?.BetHistoryInfo?.CancelationReason==null)">
      {{'compapuestaP3'|translate}}
      <ion-label item-right="">{{betDetails?.BetHistoryInfo?.TicketNumber}}</ion-label>
    </ion-item>
    <ion-item [hidden]="(betDetails?.BetHistoryInfo?.CancelationReason==null)">
      {{'v2_motCancel' | translate}}<!--# Motivo de cancelaci�n-->
      <ion-label item-right="">{{betDetails?.BetHistoryInfo?.CancelationReason}}</ion-label>
    </ion-item>
  </ion-item-group>

  <div class="sendForm">
    <!-- TODO revisar boton -->
    <!-- <button
      ion-button
      full
      class="blueBt"
      (click)="payOutTicket(betDetails?.BetHistoryInfo?.TicketNumber, betDetails?.Amount.toString())"
      *ngIf="((currentPage('CheckTicketStep2Page') || currentPage('CheckTicketPage')) && globalVars.user.logged && betDetails?.BetHistoryInfo?.TicketStatusNumber == '4' && globalVars.licenseType !=0 && betDetails?.StatusCode == 0 && betDetails?.BetHistoryInfo?.VoucherCode == null) || (!currentPage('CheckTicketStep2Page') && !currentPage('CheckTicketPage') && globalVars.FEATURES?.EarlyCashoutEnabled && globalVars.user.logged && betDetails?.BetHistoryInfo?.TicketStatusNumber == '4' && betDetails?.StatusCode == 0 && betDetails?.BetHistoryInfo?.VoucherCode == null)"
    >
      {{'Cobraryabtn' | translate}} {{ betDetails?.Amount/100 | parseMoney}}!
    </button> -->

    <div class="legal" *ngIf="globalVars.licenseType == 1">
      <span [innerHTML]="'textHistLicen'| translate"></span><br />
      <!--Apuesta de contrapartida. Codere Online S.A.U. - CIF: A-80346038 - Inscripci�n: AP-000001-->
      <span [innerHTML]="'testBootomTicket' | translate"></span>
      <!--Las normas de organizaci�n y funcionamiento que rigen la apuesta se encuentran a su disposici�n en la p�gina <a href="http://www.codere.es" tarjet="_blank">http://www.codere.es</a>-->
    </div>

    <div class="legal" *ngIf="globalVars.licenseType == 0">
      <span [innerHTML]="'textBetLicenNac' | translate"></span>
      <!--Apuesta de contrapartida. Codere Online S.A.U. - CIF: A-80346038 - Inscripci�n: AP-000001-->
      <span>
        - CIF: A-80346038 - con las siguientes licencias: 225-11/GA/A86346038/SGR, AOC/2014/002, ADC/2014/001,
        AHC/2014/002</span
      ><br />
      <span [innerHTML]="'testBootomTicket' | translate"></span>
      <!--Las normas de organizaci�n y funcionamiento que rigen la apuesta se encuentran a su disposici�n en la p�gina <a href="http://www.codere.es" tarjet="_blank">http://www.codere.es</a>-->
    </div>
  </div>
</ion-list>

<!--Resultado-->
<ion-list class="cardsTitleSep">
  <ion-list-header> {{'tMacthRes' | translate}} </ion-list-header>
</ion-list>

<!--Muesca de ticket-->
<div class="ticketTop2"></div>

<!--Resultado-->
<ion-list clear class="ticketReceipt ticketResultado" *ngFor="let event of betDetails?.BetHistoryInfo?.betEvents">
  <ion-list-header> {{event.EventName | formatTitleEvent}} </ion-list-header>
  <ion-item>
    {{'uPPExtractFec' | translate}}<!--Fecha-->
    <ion-label item-right=""> {{event.EventDatetime | extractDate:[2]}}</ion-label>
  </ion-item>
  <ion-item class="typeBet"> {{event.MarketName}} </ion-item>
  <ion-item>
    {{'hDetailsPro'|translate}}
    <ion-label item-right=""><b [ngClass]="'status_'+event.ClearingStatusNumber"> {{event.ResultName}}</b></ion-label>
  </ion-item>
  <ion-item>
    {{'bolC'|translate}}
    <ion-label item-right=""> {{event.Odd.toString() | formatOdd}}</ion-label>
  </ion-item>
  <ion-item [hidden]="!event.IsBank">
    <ion-label item-right="">{{'isBank'|translate}} </ion-label>
  </ion-item>
</ion-list>
