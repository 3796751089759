<div class="alert-wrapper background-color-white">
  <span *ngIf="showTitle && !isDebinPending" class="sb-alert--title text-center color-dark"
    >{{ options?.title | translate}}</span
  >
  <span *ngIf="showTitle && isDebinPending" class="sb-alert--title text-center codere-green"
    >{{ options?.title | translate}}</span
  >

  <div class="cs-request-info--row py-1">
    <span *ngIf="!isPending && !isDebinPending" class="sb-alert--text color-muted">
      <span>{{ options?.subtitle | translate}}</span>
    </span>
    <span *ngIf="isPending && !isDebinPending" class="sb-alert--text color-muted"
      >Estamos procesando tu <span>{{ paymentType }}</span></span
    >
    <span *ngIf="isDebinPending" class="sb-alert--text color-muted">
      <span [innerHTML]="'tDepPending' | translate "></span>
    </span>
  </div>
  <div [hidden]="amount <= 0 || isDebinPending" class="cs-request-info--row py-1">
    <span class="sb-alert--text color-muted">
      {{'dCCount' | translate}}
      <span class="color-dark">{{ options?.amount | parseMoney }}</span>
    </span>
  </div>
  <div *ngIf="checkCodeValue(options?.idTransaction) && !mfaActive" class="cs-request-info--row py-1">
    <span class="sb-alert--text color-muted">
      {{'tIDTran' | translate}}
      <span class="color-dark">{{ options?.idTransaction }}</span>
    </span>
  </div>
  <div *ngIf="checkCodeValue(options?.mfaCode) && mfaActive" class="cs-request-info--row py-1">
    <span class="sb-alert--text color-muted">
      {{'authCodeTxt' | translate}}
      <span class="color-dark">{{ options?.mfaCode }}</span>
    </span>
  </div>
  <div class="cs-request-info--row py-1">
    <span>{{'tContac' | translate}}:</span>
    <a href="mailto:apuestas@codere.com">apuestas@codere.com</a>
  </div>
  <div class="alert-button--column-group payments">
    <button class="sb-alert--button" tappable (click)="acceptClick()">{{'tAccept' | translate}}</button>
  </div>
</div>
