import { Injectable, inject } from "@angular/core";
import { BaseServiceExt } from "@providers/BaseServiceExt";
import { DeviceService } from "@services/device.service";
import { SportsConstants } from "@sports-utils/index";
import { Observable, forkJoin, map } from "rxjs";
import { C_SportsHorses, C_SportsRace, RaceMarketPage } from "../models";

@Injectable({ providedIn: "root" })
export class SportRaceService extends BaseServiceExt {
  deviceService = inject(DeviceService);
  isDesktop: boolean;
  isMobile: boolean;

  constructor() {
    super();
    this.isDesktop = this.deviceService.isDesktop();
    this.isMobile = this.deviceService.isMobile();
  }

  GetCompleteRace(params: any): Observable<C_SportsRace> {
    const url = `${this.globalVars.NAVIGATIONSERVICEBASEURL}${SportsConstants.getCompleteRaceUrl}`;
    const headers = { language: this.globalVars.Idioma };
    return this.myGet(url, true, { headers, params });
  }

  getNextRaces(sport: any): Observable<C_SportsHorses[]> {
    /*        falta limite de nodes         */
    const url = `${this.globalVars.NAVIGATIONSERVICEBASEURL}${SportsConstants.getNextRacesUrl}?sporthandle=${sport}&languageCode=${this.globalVars.Idioma}`;
    return this.myGet(url, true).pipe(
      map((races) => {
        const next = [];
        races.forEach((race: C_SportsHorses) => {
          const r = new C_SportsHorses(
            race.Name,
            race.NodeId,
            race.ParentNodeId,
            race.EventNodeTypeId,
            race.Priority,
            race.SportHandle,
            race.ChildrenCount,
            race.CountryCode,
            race.IsActive,
            race.OddsAvailability,
            race.PaddockId,
            race.StartDate,
            race.GameTypeId,
            race.Races,
            race.Locked,
            race.StreamingEnabled,
          );
          next.push(r);
        });
        return next;
      }),
    );
  }

  getRaceMarketData(raceMarketParams): Observable<RaceMarketPage> {
    return forkJoin([
      this.GetCompleteRace({ paddockId: raceMarketParams.paddockId, raceId: raceMarketParams.raceId }),
      this.getNextRaces(raceMarketParams.sport),
    ]).pipe(map((res) => new RaceMarketPage(res[0], res[1])));
  }
}
