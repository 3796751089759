/* eslint-disable */
import { Injectable, inject } from "@angular/core";
import { InsightsService } from "@services/insights.service";
import { GlobalVarsModel } from "../models/ModelGlobalsVar";
import { NewBaseService } from "./newBase.service";

/** IMPORTANT NOTE: when using SeverityLevel from another module, apart from importing LogService,
 * Severitylevel must also be explicitly imported. On the contrary, it will compile, but there will
 * be runtime errors. For example:
 * import { ..., LogService, TraceCategory, SeverityLevel } from '../../providers';
 * If SeverityLevel ins not included it will compile (because of the appInsights.d.ts declaration)
 * but it will fial at runtime (SeverityLevel undefined error)
 */

// declare var appInsights: any, appInsights;
@Injectable({ providedIn: "root" })
export class LogService {
  appInsights = inject(InsightsService);
  globalVars!: GlobalVarsModel;

  newBaseService = inject(NewBaseService);

  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
  }

  public log(trace: string, ...optionalParams: any[]) {
    if (this.globalVars.DEBUG_MODE) {
      // console.log(trace, optionalParams);
    } else {
      const msg: string = !!optionalParams
        ? `Client trace: ${trace} - ${JSON.stringify(optionalParams)}`
        : `Client trace: ${trace}`;
      /* appInsights.trackTrace(msg, {}, SeverityLevel.Information); */
      // this.appInsights.trackTrace(msg, {}, SeverityLevel.Information);
    }
  }

  public trace(category: TraceCategory, level: SeverityLevel, message: string, additionalData?: {}) {
    var traceMessage = `${category} [${SeverityLevel[level]}] ${message}`; // `

    if (this.globalVars.DEBUG_MODE) {
      // console.log(traceMessage, additionalData);
    } else {
      var customProperties = <any>(additionalData || {});
      customProperties.category = category;
      // todo
      // this.appInsights.trackTrace(traceMessage, customProperties, level);
    }
  }

  public error(trace: string, ...optionalParams: any[]) {
    if (this.globalVars.DEBUG_MODE) {
      // console.error(trace, optionalParams);
    } else {
      const msg: string = !!optionalParams
        ? `Client error: ${trace} - ${JSON.stringify(optionalParams)}`
        : `Client error: ${trace}`;
      // todo
      // this.appInsights.trackTrace(msg, {}, SeverityLevel.Error);
    }
  }
}

export enum TraceCategory {
  WaitingMessages = "WaitingMessages",
}

export enum SeverityLevel {
  Verbose = 0,
  Information = 1,
  Warning = 2,
  Error = 3,
  Critical = 4,
}
