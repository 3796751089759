<div
  [ngClass]="!isDesktop ? 'mobile' : globalVars.licenseType === 33 ? 'desktop-ar': '' + 'desktop' + (globalVars.LICENSE_TYPE == 22 ? ' containerFooterPa' : '') + (globalVars.LICENSE_TYPE == 21 ? ' containerFooterCo' : '')"
  class="container"
  [class.es]="globalVars.licenseType === 0"
>
  <ng-container *ngFor="let item of bodyInformation">
    <ng-container *ngIf="item.information.length > 0">
      <ng-container *ngIf="isDesktop">
        <div [ngClass]="item.divContainerClass" class="row">
          <codere-footer-information [description]="item"></codere-footer-information>
        </div>
      </ng-container>
      <ng-container *ngIf="!isDesktop">
        <codere-footer-information
          class="txtwhite marginl10 footer-info-container row"
          [description]="item"
          [ngClass]="{'footerBodyEs': globalVars.LICENSE_TYPE == 0, 'footerBodyAr': utils.isArgentina(), 'footerBodyPa': globalVars.LICENSE_TYPE == 22, 'footerBodyCo': globalVars.LICENSE_TYPE == 21}
          "
        ></codere-footer-information>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
