export class PreRegisterUser {
  constructor(
    public id: string = '',
    public subject: string = '',
    public name: string = '',
    public surname: string = '',
    public bgtCardId: string = '',
    public email: string = '',
    public birthDate: string = '',
    public gender: string = '',
    public city: string = '',
    public addressLine1: string = '',
    public state: string = '',
    public postalCode: string = '',
    public country: string = '',
    public mobile: string = ''
  ) {}
}
