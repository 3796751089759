import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'formatTitleEvent',
  standalone: true
})
export class FormatTitleEventPipe implements PipeTransform {
  transform(value: string): string {
    if (value !== null) {
      if (value.search('Loteria Nacional') !== -1) value = value.replace(/-/g, '<br>');
    }
    return this.deleteST(value);
  }

  private capitalizeEachWord(str: string): string {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  private deleteST(n: string): string {
    if (!n) {
      return '';
    }
    return n.replace('<st> ', '');
  }
}
