<section class="ticket-selection--wrapper" [ngClass]="getTicketSelectionMode()">
  <ion-grid class="ticket-selection--grid">
    <ion-row class="ticket-selection--row">
      <ion-button
        class="sportsbook btn-transparent icon-only ticket-selection--delete"
        (click)="remove()"
        *ngIf="!isTicketClosed"
        [disabled]="keyboard || disabled"
      >
        <ion-icon class="ticket-selection--icon-delete" name="close-outline" slot="icon-only"></ion-icon>
      </ion-button>
      <ion-button
        class="sportsbook btn-transparent icon-only ticket-selection--blocked"
        (click)="remove()"
        *ngIf="(!isTicketClosed && lineItem?.GameLocked) || (!isTicketClosed && lineItem?.NotAvailable)"
        [disabled]="keyboard || disabled"
      >
        <ion-icon class="ticket-selection--icon-blocked" name="lock-closed-outline" slot="icon-only"></ion-icon>
      </ion-button>
      <ion-col [size]="multiSingleActivated ? '8' : '12'" class="sportsbook ticket-selection--col left">
        <div class="d-flex w-100">
          <span class="sb-ticket-selection--title m-right-1 color-light"> {{ lineItem?.ResultName }}</span>
          <!-- added +"" to convert the value to string -->
          <span class="m-h-1 sb-ticket-selection--subtitle-profit color-grey-500"
            >{{ lineItem?.Odd+"" | formatOdd }}</span
          >
          <ion-icon
            *ngIf="showOddArrows() && lineItem?.downOdd"
            name="caret-down-outline"
            class="caret-down-outline"
            color="danger"
            [ngClass]="{'sb-button--animation': lineItem?.upOdd || lineItem?.downOdd}"
          >
          </ion-icon>
          <ion-icon
            *ngIf="showOddArrows() && lineItem?.upOdd"
            name="caret-up-outline"
            class="caret-up-outline"
            color="success"
            [ngClass]="{'sb-button--animation': lineItem?.upOdd || lineItem?.downOdd}"
          >
          </ion-icon>
        </div>
        <span class="sb-ticket-selection--subtitle color-grey-500">
          {{ lineItem?.GameName | getNameGame:[lineItem?.Place, license] | translate }}
        </span>
        <span class="sb-ticket-selection--subtitle color-grey-500"> {{ lineItem?.EventName | translate }} </span>
        <span class="sb-ticket-selection--subtitle color-grey-500" *ngIf="isTicketClosed && lineItem?.TicketNumber">
          {{ 'compapuestaP3' | translate }} # {{ lineItem?.TicketNumber }}
        </span>
      </ion-col>
      <ion-col size="4" class="sportsbook ticket-selection--col right" *ngIf="multiSingleActivated">
        <ion-button
          class="sports-btn btn-light sportsbook stake-button"
          [ngClass]="{ 'is-selected': lineItem?.Selected && keyboard }"
          (click)="selectAmount()"
          [disabled]="keyboard || disabled"
          *ngIf="!isDesktop"
        >
          <ion-label>
            <span *ngIf="false" class="sb-ticket--button-title text-small">
              {{ 'total' | translate }}: {{ ticket?.TotalPotentialWin.toString() | parseStakeMoney:[1] }}
            </span>
            <span
              class="sb-ticket--button-title text-small"
              [ngClass]="{ 'text-small' : lineItem?.AmountLocal.length > 8 }"
            >
              {{ lineItem?.AmountLocal | parseStakeMoney:[1] }}
            </span>
          </ion-label>
        </ion-button>
        <sb-betslip-stake-input
          *ngIf="isDesktop && !getIsProcessing()"
          [minBetValue]="minBetValue"
          [ticket]="ticket"
          [lineItem]="lineItem"
          [stakeAmount]="lineItem?.Amount?.toString()"
          (update)="updateStake($event)"
          [currency]="currency"
          [disabled]="disabled"
          [mode]="getTicketSelectionMode()"
        ></sb-betslip-stake-input>
        <span class="sb-ticket-selection--subtitle color-primary" *ngIf="!getIsProcessing()">
          {{'hDetailsGan' | translate}}: {{ lineItem?.TotalPotentialWin | parseStakeMoney:[1] }}
        </span>
      </ion-col>
    </ion-row>
  </ion-grid>
</section>
