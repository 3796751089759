<ion-content>
  <main>
    <user-section-title [sectionTitle]="'Activar Usuario'"></user-section-title>
    <section>
      <form (ngSubmit)="submitForm()" [formGroup]="userForm">
        <legend>DATOS CUENTA ONLINE</legend>

        <div
          class="myWrapInput"
          [class.has-error]="!userForm.get('userName').valid 
                && userForm.get('userName').enabled && userForm.get('userName').touched && userForm.get('userName').dirty"
        >
          <ion-item
            class="cdr-item-input"
            lines="none"
            [class.error]="!userForm.get('userName').valid 
          && userForm.get('userName').enabled && userForm.get('userName').touched && userForm.get('userName').dirty"
          >
            <ion-input
              type="text"
              label="Usuario"
              formControlName="userName"
              label-placement="floating"
              autocomplete="off"
              autoCorrect="off"
              spellcheck="off"
              [class.ion-input-error]="!userForm.get('userName').valid 
              && userForm.get('userName').enabled && userForm.get('userName').touched && userForm.get('userName').dirty"
            >
            </ion-input>
          </ion-item>
          <div
            [hidden]="userForm.get('userName').disabled || userForm.get('userName').valid 
                    || userForm.get('userName').untouched || !userForm.get('userName').dirty"
            class="labelError"
          >
            <span *ngIf="userForm.get('userName').hasError('required')"> {{'error_form_requiered' | translate}} </span>
            <span *ngIf="userForm.get('userName').hasError('minlength')"> {{'error_form_min4' | translate}} </span>
            <span *ngIf="userForm.get('userName').hasError('pattern')">
              'Elimine en el usario los caracteres no válidos'
            </span>

            <span *ngIf="userForm.get('userName').hasError('validUnique')">
              {{'error_checkUsername' | translate}}
            </span>
          </div>

          <div
            class="myWrapInput"
            [class.has-error]="!userForm.get('password1').valid 
                && userForm.get('password1').enabled && userForm.get('password1').touched && userForm.get('password1').dirty"
          >
            <ion-item
              lines="none"
              class="cdr-item-input"
              [class.error]="!userForm.get('password1').valid 
              && userForm.get('password1').enabled && userForm.get('password1').touched && userForm.get('password1').dirty"
            >
              <ion-input
                label="Contraseña"
                labelPlacement="floating"
                formControlName="password1"
                [type]="showPassword['pass1'] ? 'text' : 'password'"
                autocomplete="off"
                autoCorrect="off"
                spellcheck="off"
                [class.ion-input-error]="!userForm.get('password1').valid 
                && userForm.get('password1').enabled && userForm.get('password1').touched && userForm.get('password1').dirty"
              ></ion-input>
              <ion-icon
                [name]="showPassword['pass1'] ? 'eye-off' : 'eye'"
                (click)="toggleTypeInput('pass1')"
              ></ion-icon>
            </ion-item>
            <div
              [hidden]="userForm.get('password1').disabled || userForm.get('password1').valid 
                    || userForm.get('password1').untouched || !userForm.get('password1').dirty"
              class="labelError"
            >
              <span *ngIf="userForm.get('password1').hasError('required')">
                {{'error_form_requiered' | translate}}
              </span>
              <span *ngIf="userForm.get('password1').hasError('validPassword')"> El formato no es correcto. </span>
              <span *ngIf="userForm.get('password1').hasError('camposNoCoinciden')">
                {{'v2_errorContra5' | translate}}
              </span>
            </div>
          </div>
        </div>

        <div
          class="myWrapInput"
          [class.has-error]="!userForm.get('password2').valid 
                && userForm.get('password2').enabled && userForm.get('password2').touched && userForm.get('password2').dirty"
        >
          <ion-item
            lines="none"
            class="cdr-item-input"
            [class.error]="!userForm.get('password2').valid 
                    && userForm.get('password2').enabled && userForm.get('password2').touched && userForm.get('password2').dirty"
          >
            <ion-input
              label="Repetir contraseña"
              labelPlacement="floating"
              [type]="showPassword['pass2'] ? 'text' : 'password'"
              formControlName="password2"
              autocomplete="off"
              autoCorrect="off"
              spellcheck="off"
              [class.ion-input-error]="!userForm.get('password2').valid 
              && userForm.get('password2').enabled && userForm.get('password2').touched && userForm.get('password2').dirty"
            ></ion-input>
            <ion-icon [name]="showPassword['pass2'] ? 'eye-off' : 'eye'" (click)="toggleTypeInput('pass2')"></ion-icon>
          </ion-item>
          <div
            [hidden]="userForm.get('password2').disabled || userForm.get('password2').valid 
                    || userForm.get('password2').untouched || !userForm.get('password2').dirty"
            class="labelError"
          >
            <span *ngIf="userForm.get('password2').hasError('required')"> {{'error_form_requiered' | translate}} </span>
            <span *ngIf="userForm.get('password2').hasError('camposNoCoinciden')"> Las contraseñas no coinciden </span>
          </div>
        </div>

        <legend>DATOS TARJETA CODERE</legend>

        <div
          class="myWrapInput"
          [class.has-error]="!userForm.get('pin1').valid 
                && userForm.get('pin1').enabled && userForm.get('pin1').touched && userForm.get('pin1').dirty"
        >
          <ion-item
            lines="none"
            class="cdr-item-input"
            [class.error]="!userForm.get('pin1').valid 
                    && userForm.get('pin1').enabled && userForm.get('pin1').touched && userForm.get('pin1').dirty"
          >
            <ion-input
              label="Pin"
              labelPlacement="floating"
              [type]="showPassword['pin1'] ? 'text' : 'password'"
              formControlName="pin1"
              autocomplete="off"
              autoCorrect="off"
              spellcheck="off"
              [class.ion-input-error]="!userForm.get('pin1').valid 
              && userForm.get('pin1').enabled && userForm.get('pin1').touched && userForm.get('pin1').dirty"
            ></ion-input>
            <ion-icon [name]="showPassword['pin1'] ? 'eye-off' : 'eye'" (click)="toggleTypeInput('pin1')"></ion-icon>
          </ion-item>
          <div
            [hidden]="userForm.get('pin1').disabled || userForm.get('pin1').valid 
                    || userForm.get('pin1').untouched || !userForm.get('pin1').dirty"
            class="labelError"
          >
            <span *ngIf="userForm.get('pin1').hasError('required')"> {{'error_form_requiered' | translate}} </span>
            <span *ngIf="userForm.get('pin1').hasError('pattern')"> Sólo se admiten números </span>
            <span *ngIf="userForm.get('pin1').hasError('camposNoCoinciden')"> Los pins no coinciden </span>
          </div>
        </div>

        <div
          class="myWrapInput"
          [class.has-error]="!userForm.get('pin2').valid 
                && userForm.get('pin2').enabled && userForm.get('pin2').touched && userForm.get('pin2').dirty"
        >
          <ion-item
            lines="none"
            class="cdr-item-input"
            [class.error]="!userForm.get('pin2').valid 
                    && userForm.get('pin2').enabled && userForm.get('pin2').touched && userForm.get('pin2').dirty"
          >
            <ion-input
              label="Repetir Pin"
              labelPlacement="floating"
              [type]="showPassword['pin2'] ? 'text' : 'password'"
              formControlName="pin2"
              autocomplete="off"
              autoCorrect="off"
              spellcheck="off"
              [class.ion-input-error]="!userForm.get('pin2').valid 
              && userForm.get('pin2').enabled && userForm.get('pin2').touched && userForm.get('pin2').dirty"
            ></ion-input>
            <ion-icon [name]="showPassword['pin2'] ? 'eye-off' : 'eye'" (click)="toggleTypeInput('pin2')"></ion-icon>
          </ion-item>
          <div
            [hidden]="userForm.get('pin2').disabled || userForm.get('pin2').valid 
                    || userForm.get('pin2').untouched || !userForm.get('pin2').dirty"
            class="labelError"
          >
            <span *ngIf="userForm.get('pin2').hasError('required')"> {{'error_form_requiered' | translate}} </span>
            <span *ngIf="userForm.get('pin2').hasError('camposNoCoinciden')"> Los pins no coinciden </span>
            <span *ngIf="userForm.get('pin1').hasError('pattern')"> Sólo se admiten números </span>
          </div>
        </div>
        <ion-row>
          <ion-col size="12">
            <div class="checkactivate">
              <ion-checkbox
                mode="md"
                class="greencheckbox"
                item-start
                slot="start"
                formControlName="ageConfirm"
              ></ion-checkbox>
              <p>
                {{'reg_tyc' | translate}}&nbsp;<a
                  *ngIf="termsConditions"
                  [href]="termsConditions.url"
                  external-link
                  target="_blank"
                  >{{'rCond1b' | translate}}&nbsp;{{'reg_tyc2' | translate}}&nbsp;{{'ftPolitica' | translate}}</a
                >
              </p>
            </div>
          </ion-col>
          <ion-col
            size="12"
            *ngIf="!userForm.get('ageConfirm').disabled && !userForm.get('ageConfirm').valid 
            && !userForm.get('ageConfirm').untouched && userForm.get('ageConfirm').dirty"
          >
            <div class="labelError">
              <span> {{'error_form_requiered' | translate}} </span>
            </div>
          </ion-col>
        </ion-row>

        <ion-row *ngIf="termsConditions">
          <div>
            <p>SHA-1: <span>{{termsConditions.hash}}</span></p>
          </div>
        </ion-row>
        <ion-row>
          <ion-col size="12">
            <div class="checkactivate">
              <ion-checkbox
                mode="md"
                class="greencheckbox"
                item-start
                formControlName="termsAndConditionConfirm"
                slot="start"
              ></ion-checkbox>
              <p>
                {{'GetCardtyc01' | translate}}
                <a external-link target="_blank" [href]="TyCCajaCodere">{{'GetCardtyc02' | translate}}</a>
                &nbsp;{{'GetCardtyc03' | translate}}
              </p>
            </div>
          </ion-col>
          <ion-col
            size="12"
            *ngIf="!userForm.get('termsAndConditionConfirm').disabled && !userForm.get('termsAndConditionConfirm').valid 
            && !userForm.get('termsAndConditionConfirm').untouched && userForm.get('termsAndConditionConfirm').dirty"
          >
            <div class="labelError">
              <span> {{'error_form_requiered' | translate}} </span>
            </div>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col size="12">
            <div class="checkactivate">
              <ion-checkbox
                mode="md"
                class="greencheckbox"
                item-start
                formControlName="confirmComunications"
                slot="start"
              ></ion-checkbox>
              <p>{{'reg_tycemailNew' | translate}}</p>
            </div>
          </ion-col>
          <ion-col
            size="12"
            *ngIf="!userForm.get('confirmComunications').disabled && !userForm.get('confirmComunications').valid 
            && !userForm.get('confirmComunications').untouched && userForm.get('confirmComunications').dirty"
          >
            <div class="labelError">
              <span> {{'error_form_requiered' | translate}} </span>
            </div>
          </ion-col>
        </ion-row>
        <button type="submit" class="validatetbn">Validar</button>
      </form>
    </section>
  </main>
  <codere-footer></codere-footer>
</ion-content>
