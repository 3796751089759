<div class="cs-document-item--container">
  <ng-container *ngIf="iconName; else defaultIcon">
    <img class="cs-document-item--container--icon" src="assets/global/img/info/{{ iconName }}.svg" />
  </ng-container>
  <ng-template #defaultIcon>
    <img class="cs-document-item--container--icon" src="assets/global/img/info/verification_type_default.svg" />
  </ng-template>
  <span class="cs-document-item--text">{{ document }}</span>
</div>

<div class="cs-document-item--status">
  <cs-verification-item *ngIf="doc" [doc]="doc"></cs-verification-item>
  <ng-container *ngIf="showButtonByStatus()">
    <div *ngIf="!isArgentina && !verificationType || (isColombia && !isVeridasCOActive)"
      class="cs-document-item--container--button">
      <span *ngIf="isDesktop" class="cs-document-item--text">{{'uploadFile' | translate}}</span>
      <img *ngIf="isMobile" src="./assets/global/img/upload-white-icon.png" />
    </div>
  </ng-container>
</div>
<ng-container *ngIf="isArgentina">
  <ng-container *ngIf="!isOriginFunds; else originFundsTemplate">
    <ng-container *ngIf="showButtonByStatus()">
      <div class="cs-document-item--container--button" *ngIf="!verificationType">
        <span *ngIf="isDesktop" class="cs-document-item--text color-light">{{'uploadFile' | translate}}</span>
        <img *ngIf="isMobile" src="./assets/global/img/upload-white-icon.png" />
      </div>
    </ng-container>
  </ng-container>
  <ng-template #originFundsTemplate>
    <span *ngIf="!isMobile" class="cs-document-item--text">{{ originFunds ? originFunds : '' }}</span>
    <div class="cs-document-item--container--button">
      <span *ngIf="!isMobile" class="cs-document-item--text color-light">{{ translate.instant('origenDeFondosSeleccion')
        }}</span>
      <span *ngIf="isMobile" class="cs-document-item--text color-light">{{ originFunds ? originFunds :
        translate.instant('origenDeFondosSeleccion') }}</span>
    </div>
  </ng-template>
</ng-container>