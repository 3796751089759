import { Component, EventEmitter, Input, Output, inject } from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { AlertController, AlertOptions, IonicModule, ModalController } from "@ionic/angular";
import { TranslateModule, TranslateService } from "@ngx-translate/core";

import { CommonModule } from "@angular/common";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { UserService } from "@providers/UserService";
import { NewBaseService } from "@providers/newBase.service";
import { Utils } from "@utils/Utils";

import { BankTransferForm, LicenseTypes } from "@models/MasterData";
import { BankService } from "@providers/payments/BankService";
import { CsItemSelectorModal } from "../cs-item-selector-modal/cs-item-selector-modal";

@Component({
  standalone: true,
  imports: [IonicModule, CommonModule, TranslateModule, ReactiveFormsModule],
  selector: "cs-bank-transfer-form",
  templateUrl: "./cs-bank-transfer-form.html",
  styleUrls: ["./cs-bank-transfer-form.scss"],
})
export class CsBankTransferFormComponent {
  @Input() userData: any;
  @Input() license: any;
  @Input() banksEnabled: boolean;
  @Output() formTransferValue: EventEmitter<any> = new EventEmitter();
  @Output() inputInfo: EventEmitter<any> = new EventEmitter();

  typeArray: any[];
  banksArray: Array<{ label: string; value: string; type: string; checked: boolean }> = [];
  accountsArray: Array<{ label: string; value: string; type: string; checked: boolean }> = [];
  bankSelected: string = "Banco";
  typeSelected: string = "Ahorros";
  accountSelected: string;
  selectedBanks: string[] = [];

  formTransfer: FormGroup;
  private ACCOUNT_TYPE_INPUT_KEY = BankTransferForm.TCobTC;
  private BANK_SWIFT_INPUT_KEY = BankTransferForm.swift_bic;
  bankAccounts = [];
  readonly bankTransferForm = BankTransferForm;

  accountSelectOptions: AlertOptions;
  globalVars!: GlobalVarsModel;

  fb = inject(FormBuilder);
  translate = inject(TranslateService);
  alertCtrl = inject(AlertController);
  modalCtrl = inject(ModalController);
  utils = inject(Utils);
  userService = inject(UserService);
  bankService = inject(BankService);
  newBaseService = inject(NewBaseService);

  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });

    this.formTransfer = this.fb.group({
      name: ["", this.ValidateNameTranfer],
      account: ["", Validators.required],
      swiftbic: ["", Validators.required],
      type: [""],
    });
    this.accountSelected = this.translate.instant("TCobIban");
    this.banksEnabled = false;
  }

  async onAccountChange(event) {
    const alert = await this.alertCtrl.create({
      header: this.translate.instant("Bankaccount"),
      cssClass: "sb-alert",
    });
    await alert.present();
  }

  ngOnChanges() {
    if (
      this.userData &&
      (this.utils.isPaisVasco() ||
        this.utils.isColombia() ||
        this.utils.isPanama() ||
        this.utils.isArgentinaCABA())
    ) {
      this.setUserName();
    }
    if (this.userData && this.utils.isArgentinaCABA()) {
      this.setCuitCuil();
    }
  }

  ngOnInit() {
    switch (this.license) {
      case LicenseTypes.Nacional:
        this.formTransfer.get("account").setValidators(this.ValidateAccountTranfer);
        this.formTransfer.get("swiftbic").setValidators(this.ValidateSwiftbicTranfer);
        break;
      case LicenseTypes.PaisVasco:
        this.formTransfer.get("account").setValidators(this.ValidateAccountTranfer);
        this.formTransfer.get("swiftbic").setValidators(this.ValidateSwiftbicTranfer);
        break;
      case LicenseTypes.Mexico:
        if (this.banksEnabled) {
          this.getListBankClabe();
          this.formTransfer.get("swiftbic").setValidators(Validators.required);
        } else {
          this.formTransfer.get("swiftbic").patchValue("Mexico");
        }
        this.formTransfer.get("account").setValidators(this.ValidateCLABE);
        break;
      case LicenseTypes.Colombia:
        this.typeArray = [
          { label: this.translate.instant("TCobTC01"), value: "ahorros", type: "radio", checked: true },
          { label: this.translate.instant("TCobTC02"), value: "corriente", type: "radio", checked: false },
        ];
        this.loadBankList();
        this.loadBankAccounts();
        this.formTransfer.get("type").patchValue("ahorros");
        this.formTransfer.get("type").setValidators(Validators.required);
        this.formTransfer.get("swiftbic").setValidators(Validators.required);
        this.formTransfer.get("account").setValidators(this.ValidateAccountTranferCol);
        break;
      case LicenseTypes.Panama:
        this.typeArray = [
          { label: this.translate.instant("TCobTC01"), value: "ahorros", type: "radio", checked: true },
          { label: this.translate.instant("TCobTC02"), value: "corriente", type: "radio", checked: false },
        ];
        this.loadBankList();
        this.formTransfer.get("type").patchValue("ahorros");
        this.formTransfer.get("type").setValidators(Validators.required);
        this.formTransfer.get("swiftbic").setValidators(Validators.required);
        this.formTransfer.get("account").setValidators(this.ValidateAccountTranferCol);
        break;
      case LicenseTypes.ArgentinaCaba:
        this.formTransfer.get("account").setValidators(this.ValidateAccountTranferArg);
        break;
    }
  }

  setUserName() {
    let userName = this.userData.Nombre + " " + this.userData.PrimerApellido;
    if (this.userData.SegundoApellido) userName += " " + this.userData.SegundoApellido;
    this.formTransfer.get("name").patchValue(userName);
    this.formTransfer.get("name").disable();
  }

  setCuitCuil() {
    let cuitCuil = "";
    if (this.userData.CuitCuil) cuitCuil += " " + this.userData.CuitCuil;
    this.formTransfer.get("swiftbic").patchValue(cuitCuil);
    this.formTransfer.get("swiftbic").disable();
  }

  async setInput(inputType: string) {
    const footer = await this.utils.createARFooter();
    const data = {
      title: this.translate.instant(inputType),
      items: this.banksArray,
      selectedItems: this.selectedBanks,
    };
    const modalOptions = {
      cssClass: "cs-item-selector-modal is-modal",
    };
    if (inputType === this.ACCOUNT_TYPE_INPUT_KEY) {
      const selectAlert = await this.alertCtrl.create({
        header: this.translate.instant(inputType),
        cssClass: "sb-alert payments",
        backdropDismiss: true,
        buttons: [
          {
            text: this.translate.instant("tCancel"),
            role: "cancel",
          },
          {
            text: this.translate.instant("ifrFdbMinAmountAcceptBtn"),
            handler: (option) => {
              this.handleInputTypeOptionSelection(inputType, option);
            },
          },
        ],
        inputs: this.getSelectOptions(inputType),
      });
      await selectAlert.present();
      if (this.globalVars.licenseType === this.license) {
        // Agregar el pie de página después de que la alerta se haya presentado
        const ionAlert = await this.alertCtrl.getTop();
        if (ionAlert) {
          const alertElement = ionAlert.querySelector(".alert-wrapper");
          if (alertElement) {
            alertElement.appendChild(footer);
          }
        }
      }
    } else if (inputType === BankTransferForm.TCobIban) {
      const selectAlert = await this.alertCtrl.create({
        header: this.translate.instant(inputType),
        cssClass: "sb-alert payments",
        backdropDismiss: true,
        buttons: [
          {
            text: this.translate.instant("tCancel"),
            role: "cancel",
          },
          {
            text: this.translate.instant("ifrFdbMinAmountAcceptBtn"),
            handler: (option) => {
              this.handleInputTypeOptionSelection(inputType, option);
            },
          },
        ],
        inputs: this.getSelectOptions(inputType),
      });
      await selectAlert.present();
      if (this.globalVars.licenseType === this.license) {
        // Agregar el pie de página después de que la alerta se haya presentado
        const ionAlert = await this.alertCtrl.getTop();
        if (ionAlert) {
          const alertElement = ionAlert.querySelector(".alert-wrapper");
          if (alertElement) {
            alertElement.appendChild(footer);
          }
        }
      }
    } else {
      const bankSelectorModal = await this.modalCtrl.create({
        component: CsItemSelectorModal,
        componentProps: {
          items: data.items,
          title: data.title,
        },
        ...modalOptions,
      });

      await bankSelectorModal.present();
      const { data: modalData } = await bankSelectorModal.onDidDismiss();
      if (modalData) {
        this.formTransfer.get("swiftbic").patchValue(modalData.value);
        this.bankSelected = modalData.label;
      }
    }
  }

  handleInputTypeOptionSelection(inputType: string, option: any) {
    switch (inputType) {
      case BankTransferForm.TCobTC:
        this.formTransfer.get("type").patchValue(option);
        const setType = this.typeArray.find((type) => type.value === option);
        if (setType) {
          this.typeArray.forEach((type) => (type.checked = type === setType));
          this.typeSelected = setType.label;
        }
        break;
      case BankTransferForm.TCobIban:
        this.formTransfer.get("account").patchValue(option);
        const setAccount = this.accountsArray.find((account) => account.value === option);
        if (setAccount) {
          this.accountsArray.forEach((account) => (account.checked = account === setAccount));
          this.accountSelected = setAccount.label;
        }
        break;
      case BankTransferForm.swift_bic:
        this.formTransfer.get("swiftbic").patchValue(option);
        const setBank = this.banksArray.find((bank) => bank.value === option);
        if (setBank) {
          this.banksArray.forEach((bank) => (bank.checked = bank === setBank));
          this.bankSelected = setBank.label;
        }
        break;
    }
  }

  getSelectOptions(inputType) {
    switch (inputType) {
      case BankTransferForm.TCobTC:
        return this.typeArray;
      case BankTransferForm.TCobIban:
        return this.accountsArray;
      case BankTransferForm.swift_bic:
        return this.banksArray;
    }
  }

  loadBankList() {
    this.bankService.getBanks().subscribe(
      (banklist) => {
        if (banklist.success) {
          this.banksArray = banklist.items;
        }
      },
      (error) => {
        console.error("Error fetching banks:", error);
      },
    );
  }

  loadBankAccounts() {
    const bankAccountObserver = {
      next: (data) => {
        this.bankAccounts = [...data];
        this.bankAccounts.forEach((account) => {
          (account.checked = false),
            (account.type = "radio"),
            (account.label = account.iban),
            (account.value = account.iban);
        });
        this.accountsArray = this.bankAccounts;
      },
      error: (err) => {
        console.error("Bank Accounts Err ", err);
      },
    };
    this.bankService.getBankAccounts().subscribe(bankAccountObserver);
  }

  getListBankClabe() {
    this.banksArray = [];
    this.userService.GetListOfBanksDirect24().subscribe((data) => {
      for (let index = 0; index < data.length; index++) {
        this.banksArray.push({
          label: data[index].label,
          value: data[index].value,
          type: "radio",
          checked: false,
        });
      }
    });
  }

  showInputInfo(option) {
    this.inputInfo.emit(option);
  }

  emitWithdrawal() {
    if (this.formTransfer.get("name").disabled) this.formTransfer.get("name").enable();
    this.formTransferValue.emit(this.formTransfer.value);
  }

  ValidateNameTranfer = (control: AbstractControl) => {
    const nameRegEsp: RegExp = /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/;
    if (control.value.length === 0 || control.value.length < 5 || !nameRegEsp.test(control.value)) {
      return { valid: false };
    }
    return null;
  };

  ValidateSwiftbicTranfer = (control: AbstractControl) => {
    if (control.value.length === 0) {
      return { valid: false };
    }
    return null;
  };
  ValidateBankNameTranfer = (control: AbstractControl) => {
    if (control.value.length === 0) {
      return { valid: false };
    }
    return null;
  };

  ValidateCLABE = (control: AbstractControl) => {
    if (control.value.length === 0 || control.value.length !== 18) {
      return { valid: false };
    }
    return null;
  };

  private _ValidateAccountTranfer = (control: AbstractControl) => {
    const accountRegEsp1: RegExp =
      /^((NO)[0-9A-Z]{2}[0-9A-Z]{4}[0-9A-Z]{4}[0-9A-Z]{3}|(NO)[0-9A-Z]{15}|(BE)[0-9A-Z]{2}[0-9A-Z]{4}[0-9A-Z]{4}[0-9A-Z]{4}|(BE)[0-9A-Z]{16}|(DK|FO|FI|GL|NL)[0-9A-Z]{2}[0-9A-Z]{4}[0-9A-Z]{4}[0-9A-Z]{4}[0-9A-Z]{2}|(DK|FO|FI|GL|NL)[0-9A-Z]{18}|(MK|SI)[0-9A-Z]{2}[0-9A-Z]{4}[0-9A-Z]{4}[0-9A-Z]{4}[0-9A-Z]{3}|(MK|SI)[0-9A-Z]{19}|(BA|EE|KZ|LT|LU|AT)[0-9A-Z]{2}[0-9A-Z]{4}[0-9A-Z]{4}[0-9A-Z]{4}[0-9A-Z]{4}|(BA|EE|KZ|LT|LU|AT)[0-9A-Z]{20}|(HR|LI|LV|CH)[0-9A-Z]{2}[0-9A-Z]{4}[0-9A-Z]{4}[0-9A-Z]{4}[0-9A-Z]{4}[0-9A-Z]{1}|(HR|LI|LV|CH)[0-9A-Z]{21}|(BG|DE|IE|ME|RS|GB)[0-9A-Z]{2}[0-9A-Z]{4}[0-9A-Z]{4}[0-9A-Z]{4}[0-9A-Z]{4}[0-9A-Z]{2}|(BG|DE|IE|ME|RS|GB)[0-9A-Z]{22}|(GI|IL)[0-9A-Z]{2}[0-9A-Z]{4}[0-9A-Z]{4}[0-9A-Z]{4}[0-9A-Z]{4}[0-9A-Z]{3}|(GI|IL)[0-9A-Z]{23}|(AD|CZ|SA|RO|SK|ES|SE|TN)[0-9A-Z]{2}[0-9A-Z]{4}[0-9A-Z]{4}[0-9A-Z]{4}[0-9A-Z]{4}[0-9A-Z]{4}|(AD|CZ|SA|RO|SK|ES|SE|TN)[0-9A-Z]{24}|(PT)[0-9A-Z]{2}[0-9A-Z]{4}[0-9A-Z]{4}[0-9A-Z]{4}[0-9A-Z]{4}[0-9A-Z]{4}[0-9A-Z]{1}|(PT)[0-9A-Z]{25}|(IS|TR)[0-9A-Z]{2}[0-9A-Z]{4}[0-9A-Z]{4}[0-9A-Z]{4}[0-9A-Z]{4}[0-9A-Z]{4}[0-9A-Z]{2}|(IS|TR)[0-9A-Z]{26}|(FR|GR|IT|MC|SM)[0-9A-Z]{2}[0-9A-Z]{4}[0-9A-Z]{4}[0-9A-Z]{4}[0-9A-Z]{4}[0-9A-Z]{4}[0-9A-Z]{3}|(FR|GR|IT|MC|SM)[0-9A-Z]{27}|(AL|CY|HU|LB|PL)[0-9A-Z]{2}[0-9A-Z]{4}[0-9A-Z]{4}[0-9A-Z]{4}[0-9A-Z]{4}[0-9A-Z]{4}[0-9A-Z]{4}|(AL|CY|HU|LB|PL)[0-9A-Z]{28}|(MU)[0-9A-Z]{2}[0-9A-Z]{4}[0-9A-Z]{4}[0-9A-Z]{4}[0-9A-Z]{4}[0-9A-Z]{4}[0-9A-Z]{4}[0-9A-Z]{2}|(MU)[0-9A-Z]{30}|(MT)[0-9A-Z]{2}[0-9A-Z]{4}[0-9A-Z]{4}[0-9A-Z]{4}[0-9A-Z]{4}[0-9A-Z]{4}[0-9A-Z]{4}[0-9A-Z]{3}|(MT)[0-9A-Z]{31})$/;
    const accountRegEsp2: RegExp =
      /^((NO)[0-9A-Z]{2}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{3}|(NO)[0-9A-Z]{15}|(BE)[0-9A-Z]{2}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}|(BE)[0-9A-Z]{16}|(DK|FO|FI|GL|NL)[0-9A-Z]{2}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{2}|(DK|FO|FI|GL|NL)[0-9A-Z]{18}|(MK|SI)[0-9A-Z]{2}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{3}|(MK|SI)[0-9A-Z]{19}|(BA|EE|KZ|LT|LU|AT)[0-9A-Z]{2}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}|(BA|EE|KZ|LT|LU|AT)[0-9A-Z]{20}|(HR|LI|LV|CH)[0-9A-Z]{2}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{1}|(HR|LI|LV|CH)[0-9A-Z]{21}|(BG|DE|IE|ME|RS|GB)[0-9A-Z]{2}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{2}|(BG|DE|IE|ME|RS|GB)[0-9A-Z]{22}|(GI|IL)[0-9A-Z]{2}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{3}|(GI|IL)[0-9A-Z]{23}|(AD|CZ|SA|RO|SK|ES|SE|TN)[0-9A-Z]{2}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}|(AD|CZ|SA|RO|SK|ES|SE|TN)[0-9A-Z]{24}|(PT)[0-9A-Z]{2}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{1}|(PT)[0-9A-Z]{25}|(IS|TR)[0-9A-Z]{2}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{2}|(IS|TR)[0-9A-Z]{26}|(FR|GR|IT|MC|SM)[0-9A-Z]{2}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{3}|(FR|GR|IT|MC|SM)[0-9A-Z]{27}|(AL|CY|HU|LB|PL)[0-9A-Z]{2}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}|(AL|CY|HU|LB|PL)[0-9A-Z]{28}|(MU)[0-9A-Z]{2}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{2}|(MU)[0-9A-Z]{30}|(MT)[0-9A-Z]{2}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{4}[ ][0-9A-Z]{3}|(MT)[0-9A-Z]{31})$/;
    const iban: string = control.value.replace(/\s+/g, "");
    if (control.value.length === 0 || (!accountRegEsp1.test(iban) && !accountRegEsp2.test(iban))) {
      return { valid: false };
    }
    return null;
  };
  public get ValidateAccountTranfer() {
    return this._ValidateAccountTranfer;
  }
  public set ValidateAccountTranfer(value) {
    this._ValidateAccountTranfer = value;
  }
  ValidateAccountTranferCol = (control: AbstractControl) => {
    const accountRegEsp1: RegExp = /^[0-9\s-]*$/;
    if (control.value.length === 0 || control.value.length <= 5 || !accountRegEsp1.test(control.value)) {
      return { valid: false };
    }
    return null;
  };
  ValidateAccountTranferArg = (control: AbstractControl) => {
    const accountRegEsp: RegExp = /^[0-9]{22}$/;
    const iban: string = control.value.replace(/\s+/g, "");
    if (control.value.length === 0 || !accountRegEsp.test(iban)) {
      return { valid: false };
    }
    return null;
  };

  validarCodigoBancoArg(codigo) {
    if (codigo.length !== 8) {
      return false;
    }
    const banco = codigo.substr(0, 3);
    const sucursal = codigo.substr(3, 4);
    const digitoVerificador2 = codigo[7];

    const suma =
      banco[0] * 7 +
      banco[1] * 1 +
      banco[2] * 3 +
      sucursal[0] * 9 +
      sucursal[1] * 7 +
      sucursal[2] * 1 +
      sucursal[3] * 3;
    const diferencia = 10 - (suma % 10);

    if (digitoVerificador2 === 0) {
      return diferencia === 10;
    } else {
      return diferencia === digitoVerificador2;
    }
  }

  validarCuentaArg(cuenta) {
    if (cuenta.length !== 14) {
      return false;
    }
    const digitoVerificador = cuenta[13];
    const suma =
      cuenta[0] * 3 +
      cuenta[1] * 9 +
      cuenta[2] * 7 +
      cuenta[3] * 1 +
      cuenta[4] * 3 +
      cuenta[5] * 9 +
      cuenta[6] * 7 +
      cuenta[7] * 1 +
      cuenta[8] * 3 +
      cuenta[9] * 9 +
      cuenta[10] * 7 +
      cuenta[11] * 1 +
      cuenta[12] * 3;
    const diferencia = 10 - (suma % 10);
    if (digitoVerificador === 0 && diferencia === 10) {
      return true;
    }
    return diferencia === digitoVerificador;
  }
}
