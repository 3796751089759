<ng-container *ngIf="!isMobile; else mobileView">
  <ion-content>
    <div class="contModal contModalCash teleingresomodal">
      <button class="closeModal" (click)="btClose_Click()">
        <ion-icon ios="ios-close" md="ios-close"></ion-icon>
      </button>
      <div class="myWrapLegal">
        <div class="introPopUp fonttit">{{'addfriendsharetxt01' | translate}}:<!--Comparte con tus amigos:--></div>
        <ul class="allshare">
          <li class="shareicon shareicont">
            <div class="padding5" (click)="shareOnTwitter()">
              <a class="">
                <span><img src="assets/global/img/icon-twitter.png" class="imgredsoc" /></span>
                <span class="shareicontxtt">Twitter</span>
              </a>
            </div>
          </li>
          <li class="shareicon shareiconf">
            <div class="padding5" (click)="shareOnFacebook()">
              <a class="">
                <span><img src="assets/global/img/icon-facebook.png" class="imgredsoc" /></span>
                <span class="shareicontxtf">Facebook</span>
              </a>
            </div>
          </li>
          <li class="shareicon shareiconc">
            <div class="padding5">
              <a class="" href="mailto:&amp;subject=Invitacion%20a%20Codere&amp;body={{textmsgMail}}">
                <span><img src="assets/global/img/icon-mail.png" class="imgredsoc" /></span>
                <span class="shareicontxtc">Correo</span>
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </ion-content>
</ng-container>

<ng-template #mobileView>
  <div class="contModal contModalCash teleingresomodal">
    <button class="closeModal" (click)="btClose_Click()">
      <ion-icon ios="ios-close" md="ios-close"></ion-icon>
    </button>
    <div class="myWrapLegal">
      <div class="introPopUp fonttit txtcenter">
        {{ 'addfriendsharetxt01' | translate }}<!--Comparte con tus amigos:-->
      </div>
      <ul class="allshare">
        <li class="shareicon shareiconw">
          <div class="padding5" (click)="shareOnWhatsapp()">
            <a class="">
              <span class="spanicon"><img src="assets/global/img/icon-whatsapp.png" class="imgredsoc" /></span>
              <span class="shareicontxtw">Whatsapp</span>
            </a>
          </div>
        </li>
        <li class="shareicon shareicont">
          <div class="padding5" (click)="shareOnTwitter()">
            <a class="">
              <span class="spanicon"><img src="assets/global/img/icon-twitter.png" class="imgredsoc" /></span>
              <span class="shareicontxtt">Twitter</span>
            </a>
          </div>
        </li>
        <li class="shareicon shareiconf">
          <div class="padding5" (click)="shareOnFacebook()">
            <a class="">
              <span class="spanicon"><img src="assets/global/img/icon-facebook.png" class="imgredsoc" /></span>
              <span class="shareicontxtf">Facebook</span>
            </a>
          </div>
        </li>
        <li class="shareicon shareiconc">
          <div class="padding5" (click)="shareOnMail()">
            <a class="">
              <span class="spanicon"><img src="assets/global/img/icon-mail.png" class="imgredsoc" /></span>
              <span class="shareicontxtc">{{ 'email' | translate }}<!--Correo--></span>
            </a>
          </div>
        </li>
      </ul>
    </div>
  </div>
</ng-template>
