/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable eqeqeq */
import {
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  EventEmitter,
  Input,
  NO_ERRORS_SCHEMA,
  Output,
  inject
} from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FormGroup, FormBuilder, Validators, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { GlobalVarsModel } from '@models/ModelGlobalsVar';
import { NewBaseService } from '@providers/newBase.service';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { DeviceService } from '@services/device.service';

@Component({
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'cdr-full-se',
  templateUrl: './full-se.html',
  styleUrls: ['./full-se.scss'],
  standalone: true,
  imports: [CommonModule, IonicModule, TranslateModule, FormsModule, ReactiveFormsModule]
})
export class FullSeComponent {
  isMobile: boolean = false;
  myForm: FormGroup;
  selfEx: any;
  dayMonth: Array<string>;
  months: Array<{ label: string; data: string }>;
  years: Array<number>;
  validDate: boolean;
  @Output() selfExEvent: EventEmitter<string>;
  checkedDate = true;
  globalVars!: GlobalVarsModel;

  translate = inject(TranslateService);
  fb = inject(FormBuilder);
  newBaseService = inject(NewBaseService);
  deviceService = inject(DeviceService);

  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });

    this.isMobile = this.deviceService.isMobile();
    this.selfExEvent = new EventEmitter();
    this.selfEx = {
      day: '',
      month: '',
      year: ''
    };
    this.myForm = this.fb.group({
      day: ['', Validators.required],
      month: ['', Validators.required],
      year: ['', Validators.required]
    });

    this.dayMonth = [];
    for (let i = 1; i <= 31; i++) {
      this.dayMonth.push(i < 10 ? '0' + i.toString() : i.toString());
    }
    const arrMeses = [
      this.translate.instant('rMEn'),
      this.translate.instant('rMFe'),
      this.translate.instant('rMMa'),
      this.translate.instant('rMAv'),
      this.translate.instant('rMMy'),
      this.translate.instant('rMJn'),
      this.translate.instant('rMJl'),
      this.translate.instant('rMAg'),
      this.translate.instant('rMSe'),
      this.translate.instant('rMOc'),
      this.translate.instant('rMNo'),
      this.translate.instant('rMDi')
    ];

    this.months = [];

    for (let i = 0; i < 12; i++) {
      this.months.push({
        label: arrMeses[i],
        data: i < 9 ? '0' + (i + 1).toString() : (i + 1).toString()
      });
    }

    this.years = [];
    const nowYearDD: number = new Date().getFullYear();
    const maxYearDD = nowYearDD + 100;
    for (let i = nowYearDD; i < maxYearDD; i++) {
      this.years.push(i);
    }

    this.myForm.get('day').valueChanges.subscribe((value: string) => {
      this.checkValidDate();
    });
    this.myForm.get('month').valueChanges.subscribe((value: string) => {
      this.checkValidDate();
    });
    this.myForm.get('year').valueChanges.subscribe((value: string) => {
      this.checkValidDate();
    });
  }

  checkValidDate() {
    //checks if the date is valid

    if (!!this.selfEx.day && !!this.selfEx.month && !!this.selfEx.year) {
      //check the date
      const miDate: Date = new Date(
        parseInt(this.selfEx.year),
        parseInt(this.selfEx.month) - 1,
        parseInt(this.selfEx.day),
        0,
        0,
        0,
        0
      );

      if (
        miDate.getDate() == parseInt(this.selfEx.day) &&
        miDate.getMonth() == parseInt(this.selfEx.month) - 1 &&
        miDate.getFullYear() == parseInt(this.selfEx.year)
      ) {
        this.validDate = miDate > new Date();
      } else {
        this.validDate = false;
      }
    } else {
      this.validDate = false;
    }
  }

  selfexclusion_click() {
    let validForm = false;
    if (this.validDate) {
      validForm = true;
    }
    if (validForm) {
      const selfExclusionDate: string = this.selfEx.year.toString() + '-' + this.selfEx.month + '-' + this.selfEx.day;
      this.selfExEvent.next(selfExclusionDate);
    }
  }
}
