import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, Component, Input, NO_ERRORS_SCHEMA, OnInit, inject } from "@angular/core";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";
import { PopoverController } from "@ionic/angular";
import { IonicModule } from "@ionic/angular";
import { InfoOverComponent } from "../info-over/info-over.component";

@Component({
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  standalone: true,
  imports: [CommonModule, IonicModule, ReactiveFormsModule],
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "cdr-info",
  templateUrl: "./info.component.html",
  styleUrls: ["./info.component.scss"],
})
export class InfoComponent implements OnInit {
  @Input() target: string;
  @Input() fgrp: FormGroup;
  @Input() fcName: string;
  isPristine: boolean;

  popoverController = inject(PopoverController);

  constructor() {}

  ngOnInit(): void {
    if (this.fgrp && this.fcName) {
      this.isPristine = this.fgrp?.get(this.fcName).untouched;
    }
  }

  deleteValue() {
    this.fgrp?.get(this.fcName).reset();
  }

  async presentPopover(ev: any) {
    //**Codigo con error, se comenta para dejarlo de ejemplo*
    // const popover = await this.popoverController.create(
    //   InfoOverComponent,
    //   {target: this.target},
    //   {cssClass: this.target ==='modalPRP' && 'modalprp'}
    // );
    // return await popover.present();
    //** Codigo refactorizado */
    const popover = await this.popoverController.create({
      component: InfoOverComponent,
      mode: "md",
      componentProps: { target: this.target },
      cssClass: this.target === "modalPRP" ? "modal-prp" : "modal-default",
    });

    return popover.present();
  }
}
