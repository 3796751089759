<div class="cs-qr-code--img py-1"></div>

<div class="cs-qr-code--text text-center p-2" *ngIf="depositResult?.provider==='Tpaga'">
  <span class="m-bottom-2">Para utilizar <b>Tpaga</b>, debes seguir los siguientes pasos:</span>
  <ol class="text-left">
    <li class="m-bottom-1">Descarga el app <b>Billetera Tpaga</b> gratis desde AppGallery, AppStore o PlayStore</li>
    <li class="m-bottom-1">Regístrate con tus datos personales</li>
    <li class="m-bottom-1">
      Agrega tus tarjetas o recarga tu Billetera mediante transferencia bancaria o en los puntos físicos
    </li>
    <li class="m-bottom-1">Escanea el código QR que generamos</li>
    <li class="m-bottom-1">Confirma el pago</li>
  </ol>
</div>

<div class="cs-qr-code--text text-center p-2" *ngIf="depositResult?.provider==='Coinspaid'">
  <span class="m-bottom-2"><b>Coinspaid</b></span
  ><br />
  <ol class="text-left">
    <li class="m-bottom-1">Esta es tu dirección privada de depósito</li>
    <li class="m-bottom-1">
      Cualquier transacción que se haga en esta dirección aparecerá inmediatamente en tu balance.
      <p>&nbsp;</p>
      <p>Tu dirección es<br /></p>
      <div class="cs-transfer--slice border-color-secondary">
        <b>{{depositResult?.payload}}</b>
        <button
          class="cs-transfer--slice-button cs-trans--text color-secondary background-color-white border-color-secondary"
          tappable
          (click)="copyButton(depositResult?.payload)"
        >
          Copiar
        </button>
      </div>
    </li>
  </ol>
</div>

<section class="p-1" id="qrCode"></section>
<p>--</p>
