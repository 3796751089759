<!--Signup bonus-->
<ion-grid class="margin0">
  <ion-row class="is-row-grid">
    <iframe #iframe [src]="iframeUrl" class="iframe" scrolling="no" (load)="setCancelBtn()"></iframe>
  </ion-row>
  <ion-row class="is-row-grid containerBtns">
    <ion-col col-12 class="ion-text-center">
      <button class="btnPlay" (click)="playBonus()"><span>{{'btnPlayNow' | translate}}</span></button>
    </ion-col>
  </ion-row>
</ion-grid>
