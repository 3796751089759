<ng-container *ngIf="!isMobile; else mobileView">
  <ion-toolbar class="toolbarSectionName">
    <back-button tappable></back-button>
    <span style="color: white">{{ texto | translate }}</span>
  </ion-toolbar>
  <ion-content>
    <ng-container *ngIf="scrHelp">
      <iframe #iframe class="iframeFullScreen nodisplay" (load)="helpageload($event)" [src]="scrHelp"></iframe>
    </ng-container>
    <codere-footer></codere-footer>
  </ion-content>
</ng-container>

<ng-template #mobileView>
  <ion-toolbar class="toolbarSectionName">
    <back-button tappable></back-button>
    <span style="color: white">{{ texto | translate }}</span>
  </ion-toolbar>
  <ion-content>
    <ng-container *ngIf="scrHelp">
      <iframe
        #iframe
        data-tap-disabled="true"
        class="iframeFullScreen nodisplay"
        (load)="helpageload($event)"
        [src]="scrHelp"
      ></iframe>
    </ng-container>
    <codere-footer></codere-footer>
  </ion-content>
</ng-template>
