<!-- eslint-disable @angular-eslint/template/eqeqeq -->
<div
  class="freebet-item"
  *ngIf="freeBet.Type=='depsitoonline' || freeBet.Type=='entregainmediata' || freeBet.Type=='movimientotarjetaCodere'"
>
  <ion-list>
    <p>
      {{'fb_text_pend1' | translate}}<!--Realiza apuestas por valor de-->
      <span class="colorText">{{freeBet.TotalBets | parseMoney}}</span>
      {{'fb_text_pend2' | translate}}<!-- a una cuota mínima de @-->
      <span *ngIf="globalVars.LICENSE_TYPE != 4" class="colorText">{{freeBet.minOdd.toString() | formatOdd}}</span>
      <span *ngIf="globalVars.LICENSE_TYPE == 4" class="colorText">+150</span> {{'fb_text_pend3' | translate}}<!--para liberar tu freebet.-->
    </p>

    <ion-item class="main-box">
      <ion-avatar item-left class="freebetBullet">
        <span
          >{{freeBet.AmountFb | parseMoney}}
          <!--<sub>€</sub--></span
        >
        <p>freebet</p>
      </ion-avatar>
      <div class="titulos">
        <h2>{{freeBet.Name}}</h2>
        <h3>Importe <b>{{freeBet.TotalBets | parseMoney}}</b></h3>
        <h3>
          Cuota mínima <b *ngIf="globalVars.LICENSE_TYPE != 4">{{freeBet.minOdd.toString() | formatOdd}}</b
          ><b *ngIf="globalVars.LICENSE_TYPE == 4">+150</b>
        </h3>
      </div>
    </ion-item>

    <div class="progressBar-container">
      <div class="progress-label align-left">
        <p class="label">APOSTADO</p>
        <p class="value">{{freeBet.AmountBets | parseMoney:[1]}}</p>
      </div>

      <div class="progress-label align-right">
        <p class="label">IMPORTE TOTAL</p>
        <p class="value">{{freeBet.TotalBets | parseMoney}}</p>
      </div>

      <div class="progressBar" [ngStyle]="{'witdh':progressBarWidth}">
        <div class="progress transitioned"></div>
      </div>
      <div class="progressBar-container">
        <div class="tooltip-progress-invisible" [ngStyle]="{'witdh':toolTippWidth}"></div>
        <div class="tooltip-progress transitioned">FALTAN {{freeBet.pendingAmount | parseMoney}}</div>
      </div>
    </div>

    <p>
      {{'fbpendate01' | translate }}<!--Tienes hasta el-->&nbsp;<span class="colorText"
        >{{freeBet.date | extractDate:[1]}}</span
      ><!--dd/mm/aa-->&nbsp;{{'fbpendate02' | translate}}<!--para completarlo.-->
    </p>

    <a href="{{freeBet.Url}}" target="_blank" class="masinfo">{{'fbpenmasinfo' | translate}}<!--Más información--></a>
  </ion-list>
</div>

<div class="freebet-item" *ngIf="freeBet.Type=='cantidadapostadatarjeta'">
  <ion-list>
    <p>&nbsp;</p>
    <h2>
      {{freeBet.Name}}. Devolución&nbsp;<span>{{freeBet.PercentDev}}<!--50-->%&nbsp;</span><span>pérdidas</span>
    </h2>

    <p>
      {{'fb_promoeuro_text01' | translate}}><!--Con esta promoción,-->&nbsp;{{'fb_promo_text04' | translate}}
      <!--te devolveremos en freebets Presenciales el-->&nbsp;{{freeBet.PercentDev}}<!--50-->%&nbsp;{{'fb_promo_text05'}}
      de las pérdidas netas de tus apuestas realizadas con tu Tarjeta Codere&nbsp;{{'fb_promo_text03'}} en el
      periodo&nbsp;{{freeBet.dateStart | extractDate:[1]}} - {{freeBet.date | extractDate:[1]}}
    </p>
    <p class="paddingb1em"><span>Freebets obtenidas en el periodo</span>:&nbsp;{{freeBet.GrossWin}}€</p>

    <a href="{{freeBet.Url}}" target="_blank" class="masinfo">{{'fbpenmasinfo' | translate}}<!--Más información--></a>
  </ion-list>
</div>

<div class="freebet-item" *ngIf="freeBet.Type=='cashbackonline'">
  <ion-list>
    <p>&nbsp;</p>
    <h2>
      {{freeBet.Name}}. {{'fbpromosubtitledev' | translate }}<!--Devolución-->&nbsp;{{freeBet.PercentDev}}<!--50-->%&nbsp;{{'fbpromosubtitleperd'
      | translate}}<!--pérdidas-->
    </h2>

    <p>
      {{'fb_promoeuro_text01' | translate}}<!--Con esta promoción-->&nbsp;{{'fb_promoeuro_text02' | translate}}
      <!--ganarás un-->{{freeBet.PercentDev}}<!--50-->% {{'fb_promoeuro_text03' | translate}}
      <!--de tus pérdidas netas en apuestas-->
      {{'fb_promoeuro_text04' | translate}}
      <!--el-->{{freeBet.dateStart | extractDate:[1]}}
    </p>
    <p>{{'hStade' | translate}}&nbsp;<!--Estado-->{{freeBet.active}}<!--Activa--></p>
    <p><span>Freebets obtenidas en el periodo</span>:&nbsp;{{calcFreebetEur(freeBet.GrossWin)}}€</p>
    <div *ngIf="freeBet.dateGW != 0">
      <p>
        {{'fb_promoeuro_text07' | translate}}><!--Cálculo realizado el-->&nbsp;{{freeBet.dateGW.toString() |
        extractDate:[2]}}><!--dd/mm/aa</span>&nbsp;<span>12:54</span></span>-->
      </p>
    </div>
    <a href="{{freeBet.Url}}" target="_blank" class="masinfo">{{'fbpenmasinfo' | translate}}<!--Más información--></a>
  </ion-list>
</div>

<div class="freebet-item" *ngIf="freeBet.Type=='cashbackpresencial'">
  <ion-list>
    <p>&nbsp;</p>
    <h2>
      {{freeBet.Name}}. {{'fbpromosubtitledev' | translate}}<!--Devolución-->&nbsp;{{freeBet.PercentDev}}<!--50-->%&nbsp;{{'fbpromosubtitleperd'
      | translate}}<!--pérdidas-->
    </h2>

    <p class="paddingb1em">
      {{'fb_promoeuro_text01' | translate }}<!--Con esta promoción,-->&nbsp;{{'fb_promoeuroP_text01' | translate}}<!--te devolveremos en freebets Presenciales el-->
      {{freeBet.PercentDev}}<!--50-->% {{'fb_promoeuroP_text02' | translate}}<!--de tus pérdidas netas en apuestas de la Eurocopa-->{{'fb_promoeuro_text04'
      | translate }}
      <!--desde el-->{{freeBet.dateStart | extractDate:[1]}} {{'fb_promoeuro_text05' | translate}}
      <!--hasta el-->{{freeBet.date | extractDate:[1]}}
    </p>
    <p>{{'Stade' | translate}}:&nbsp;<!--Estado-->{{freeBet.active}}<!--Activa--></p>

    <p>Freebets obtenidas en el periodo:&nbsp;{{calcFreebetEur(freeBet.GrossWin)}}€</p>
    <div *ngIf="freeBet.dateGW != 0">
      <p>
        {{'fb_promoeuro_text07' | translate}}<!--Cálculo realizado el-->&nbsp;{{freeBet.dateGW.toString() |
        extractDate:[2]}}<!--dd/mm/aa</span>&nbsp;<span>12:54</span></span>-->
      </p>
    </div>
    <a href="{{freeBet.Url}}" target="_blank" class="masinfo">{{'fbpenmasinfo' | translate}}<!--Más información--></a>
  </ion-list>
</div>
