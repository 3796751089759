import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'extractHourServer' })
export class ExtractHourServerPipe implements PipeTransform {
  transform(value: string, date: string): string {
    if (value === undefined || value === null) return '';
    const miDate = new Date(parseInt(date.slice(6, date.length - 2)));
    const hour = this.formatoNumber(miDate.getHours(), 2) + ':' + this.formatoNumber(miDate.getMinutes(), 2);

    return value.replace(/\d{2}:\d{2}/, hour);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formatoNumber(numero: any, digitos: number): string {
    //devuelve el string del número con n dígitos
    let numberStr: string = numero.toString();
    while (numberStr.length < digitos) {
      numberStr = '0' + numberStr;
    }
    return numberStr;
  }
}
