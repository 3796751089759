export class SportCarrouselItem {
  Name: string;
  img: string;
  actionLink: any;
  actionData: any;

  constructor(Name: string, img: string, actionLink: any, actionData: any) {
    this.Name = Name;
    this.img = img;
    this.actionLink = actionLink;
    this.actionData = actionData;
  }
}
