import { HttpHeaders } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { Observable } from "rxjs";

import {
  C_FreeBetVoucher,
  CombinationMulticastInput,
  OddItem,
  SingleItemInput,
  SmartMarketInput,
  StakeModel,
  StraightMulticastInput,
  removeTicket,
} from "@sports-models/index";

import { XStateService } from "./xstate.service";

import { BaseServiceExt } from "@providers/BaseServiceExt";

import { BetslipState } from "@sports-models/index";
import { SportsConstants } from "@sports-utils/index";

@Injectable({ providedIn: "root" })
export class BetSlipService extends BaseServiceExt {
  hideLoader = false;
  xStateService = inject(XStateService);

  addSingleResult(bet: SingleItemInput): Observable<any> {
    const url = this.globalVars.BETSLIPSERVICEBASEURL + SportsConstants.addSingleItemUrl;
    // const url = `${SportsConstants.TICKET_PROXY}/TicketProxy/AddSingleItem`;
    const opt = {
      observe: "response",
      headers: new HttpHeaders({
        "X-State": this.xStateService.getXState(),
      }),
    };
    return this.myPost2(url, bet, opt, this.hideLoader);
  }

  // TICKET PROXY
  addSingleResult_TICKET_PROXY(betslipState: BetslipState): Observable<any> {
    const url = `${SportsConstants.TICKET_PROXY}/TicketProxy/AddSingleItem`;
    const opt = {
      observe: "response",
      headers: new HttpHeaders({
        "X-State": this.xStateService.getXState(),
      }),
    };
    return this.myPost2(url, betslipState, opt, this.hideLoader);
  }

  addSmartMarketItem(bet: SmartMarketInput): Observable<any> {
    const url = this.globalVars.BETSLIPSERVICEBASEURL + SportsConstants.addSmartMarketItemUrl;
    const opt = {
      observe: "response",
      headers: new HttpHeaders({
        "X-State": this.xStateService.getXState(),
      }),
    };
    return this.myPost2(url, bet, opt, this.hideLoader);
  }

  addStraightMulticast(bet: StraightMulticastInput): Observable<any> {
    const url = this.globalVars.BETSLIPSERVICEBASEURL + SportsConstants.straightMulticast;
    const opt = {
      observe: "response",
      headers: new HttpHeaders({
        "X-State": this.xStateService.getXState(),
      }),
    };
    return this.myPost2(url, bet, opt, this.hideLoader);
  }

  addCombinationMulticast(bet: CombinationMulticastInput): Observable<any> {
    const url = this.globalVars.BETSLIPSERVICEBASEURL + SportsConstants.combinationMulticast;
    const opt = {
      observe: "response",
      headers: new HttpHeaders({
        "X-State": this.xStateService.getXState(),
      }),
    };
    return this.myPost2(url, bet, opt, this.hideLoader);
  }

  removeItem(id: number): Observable<any> {
    const url = this.globalVars.BETSLIPSERVICEBASEURL + SportsConstants.removeItemUrl + id;
    const opt = {
      observe: "response",
      headers: new HttpHeaders({
        "X-State": this.xStateService.getXState(),
      }),
    };
    return this.myDelete2(url, opt);
  }

  updateOdds(oddsItems: OddItem[]): Observable<any> {
    const url = this.globalVars.BETSLIPSERVICEBASEURL + SportsConstants.setOdds;
    const dataOdds = { odds: oddsItems };
    const opt = {
      observe: "response",
      headers: new HttpHeaders({
        "X-State": this.xStateService.getXState(),
      }),
    };
    return this.myPatch2(url, dataOdds, opt, true);
  }

  stake(stk: StakeModel) {
    const url = this.globalVars.BETSLIPSERVICEBASEURL + SportsConstants.stakeUrl + stk.Group;
    const stakeData = { ...stk };
    const opt = {
      observe: "response",
      headers: new HttpHeaders({
        "X-State": this.xStateService.getXState(),
      }),
    };
    delete stakeData.Group;
    if (Object.prototype.hasOwnProperty.call(stakeData, "LineItems")) delete stakeData.LineItems;
    if (Object.prototype.hasOwnProperty.call(stakeData, "MultiSingleAmount")) delete stakeData.MultiSingleAmount;
    return this.myPost2(url, stakeData, opt, this.hideLoader);
  }

  removeStake(stakeGroup: string) {
    const url = this.globalVars.BETSLIPSERVICEBASEURL + SportsConstants.stakeUrl + stakeGroup;
    const opt = {
      observe: "response",
      headers: new HttpHeaders({
        "X-State": this.xStateService.getXState(),
      }),
    };
    return this.myDelete2(url, opt);
  }

  placeBet(groupKey: string, validateBonus: any = false) {
    const url = this.globalVars.BETSLIPSERVICEBASEURL + SportsConstants.stakeUrl + groupKey + "/tickets";
    if (validateBonus) {
      const opt = {
        observe: "response",
        headers: new HttpHeaders({
          "X-State": this.xStateService.getXState(),
          "X-Validate-Bonus-Offers": "true",
          Authorization: this.xStateService.getAuthorization(),
        }),
      };
      return this.myGet2(url, opt, this.hideLoader);
    } else {
      const opt = {
        observe: "response",
        headers: new HttpHeaders({
          "X-State": this.xStateService.getXState(),
        }),
      };
      return this.myGet2(url, opt, this.hideLoader);
    }
  }

  getLicenseSessionHeader() {
    const licenseType = this.globalVars.licenseType;
    // const url = `${environment.baseDomain}BetPlacement/LicenseSessionHeader?licenseType=${licenseType}`;
    const url = `${this.globalVars.DOMAIN}BetPlacement/AuthorizationHeader?licenseType=${licenseType}`;
    return this.myGet(url, false);
  }

  closeBet(groupKey: any) {
    const { AutoAcceptOddUpChanges, AutoAcceptOddDownChanges } = this.globalVars.user.customization;
    const url = this.globalVars.DOMAIN + "/BetPlacement/TicketsAndCloseBet?groupKey=" + groupKey;
    const opt = {
      observe: "response",
      headers: new HttpHeaders({
        AutoAcceptOddUpChanges: AutoAcceptOddUpChanges.toString(),
        AutoAcceptOddDownChanges: AutoAcceptOddDownChanges.toString(),
        language: this.globalVars.Idioma,
        "X-State": this.xStateService.getXState(),
      }),
    };
    return this.myGet2(url, opt, this.hideLoader);
  }

  overaskCheckState(OveraskRejectId: any): Observable<any> {
    const url = `${this.globalVars.DOMAIN}BetPlacement/api/Overask?rejectId=${OveraskRejectId}`;
    return this.myGet2(url, false);
  }

  removeTicket(removeT: removeTicket) {
    const url = this.globalVars.BETSLIPSERVICEBASEURL + SportsConstants.removeTicket;
    const opt = {
      observe: "response",
      headers: new HttpHeaders({
        "X-State": this.xStateService.getXState(),
        // 'X-Validate-Bonus-Offers': 'true',
        // 'Authorization': this.xStateService.getAuthorization()
      }),
    };
    return this.myPost2(url, removeT, opt);
  }

  addSmartMarket(bet: SingleItemInput): Observable<any> {
    const url = this.globalVars.BETSLIPSERVICEBASEURL + SportsConstants.smartMarketUrl;
    const opt = {
      observe: "response",
      headers: new HttpHeaders({
        "X-State": this.xStateService.getXState(),
        // 'X-Validate-Bonus-Offers': 'true',
        // 'Authorization': this.xStateService.getAuthorization()
      }),
    };
    return this.myPost2(url, bet, opt, this.hideLoader);
  }

  freebetAdd(voucher: C_FreeBetVoucher): Observable<any> {
    const url = this.globalVars.BETSLIPSERVICEBASEURL + SportsConstants.freeBetAdd;
    const opt = {
      observe: "response",
      headers: new HttpHeaders({
        "X-State": this.xStateService.getXState(),
        // 'X-Validate-Bonus-Offers': 'true',
        // 'Authorization': this.xStateService.getAuthorization()
      }),
    };
    return this.myPost2(url, voucher, opt, false);
  }

  freebetCancel(voucherCode: string): Observable<any> {
    const url = `${this.globalVars.BETSLIPSERVICEBASEURL}${SportsConstants.freeBetCancel}/${voucherCode}`;
    const opt = {
      observe: "response",
      headers: new HttpHeaders({
        "X-State": this.xStateService.getXState(),
        // 'X-Validate-Bonus-Offers': 'true',
        // 'Authorization': this.xStateService.getAuthorization()
      }),
    };
    return this.myDelete2(url, opt);
  }

  freebetValidate(): Observable<any> {
    const url = `${this.globalVars.BETSLIPSERVICEBASEURL}${SportsConstants.freeBetValidate}`;
    const opt = {
      observe: "response",
      headers: new HttpHeaders({
        "X-State": this.xStateService.getXState(),
        // 'X-Validate-Bonus-Offers': 'true',
        // 'Authorization': this.xStateService.getAuthorization()
      }),
    };
    return this.myGet2(url, opt);
  }
}
