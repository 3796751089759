// PLACE BET

export interface placeBetSlipRequest {
  AdditionalInfoCulture: any;
  BetTickets: BetTicketsReq[];
  ClientIP: any;
  ClientVersion: any;
  CurrencyIsoCode: string;
  IncludeAdditionalInfos: boolean;
  IncludeBetWays: boolean;
  NodeNr: number;
  PlaceBetTransactionType: any;
  PlaceMode: number;
}

export interface BetTicketsReq {
  Bets: BetsReq[];
  BonusOfferIds: any[];
}

export interface BetsReq {
  BetDefinitions: BetDefinitionsReq[];
  BetLegs: BetLegsReq[];
  BonusVoucherCode: any;
  StakeCt: number;
  X_Value: number;
}

export interface BetDefinitionsReq {
  StakeCt: number;
  X_Value: number;
}

export interface BetLegsReq {
  IsBankLeg: boolean;
  TypeLegs: TypeLegsReq[];
}

export interface TypeLegsReq {
  OddCalculation: any;
  Odds: number;
  StakeCt: number;
  Type: number;
  ValueLegs: ValueLegsReq[];
}

export interface ValueLegsReq {
  BetResults: BetResultsReq[];
  GameId: number;
  NumberOfStarters: number;
  Place: number;
}

export interface BetResultsReq {
  aseOdds: any;
  CustomId: number;
  Odds: number;
  ResultsNr: number;
  SpecialOddsValue: string;
  StakeCt: number;
}

// CLOSE BET
export interface CloseBet {
  GeneralError: boolean;
  SingleTicketItem: SingleTicketItem;
}

export interface SingleTicketItem {
  ErrorCode: string;
  ReusltId: string;
  Stake: 100;
  TicketId: string;
}
