<section>
  <swiper-container #betBuilderSwiper init="false" class="sb-marquee-betbuilder-swiper swiper-container">
    <swiper-slide
      *ngFor="let betbuilderItem of betbuilderItems | slice:0:10; let index = index"
      class="sb-marquee-betbuilder--box swiper-slide sb-marquee-betbuilder-item background-color-light"
    >
      <div class="sb-marquee-betbuilder--table">
        <div
          class="sb-marquee-betbuilder--table-item sb-marquee-betbuilder-item--subtitle color-dark text-left"
          (click)="navigate(betbuilderItem.EventId)"
        >
          <i
            [ngClass]="'icon-' + betbuilderItem?.SportHandle"
            class="sb-grid-header--sport-icon codere-icon color-dark"
          ></i>
          {{ betbuilderItem.EventName | truncateString: 27 }}
        </div>
        <div class="sb-marquee-betbuilder--table-item sb-marquee-betbuilder-item--subtitle color-accent text-right">
          <span>{{ betbuilderItem?.StartDate | extractDate:[6] }}</span>
        </div>
      </div>
      <div class="sb-bet-selection-betbuilder--items-wrap" (click)="navigate(betbuilderItem.EventId)">
        <span
          *ngFor="let item of betbuilderItem.MarketInfoList; first as isFirst; last as isLast;"
          class="sb-bet-selection-betbuilder--content-left-ball-container"
        >
          <div
            class="sb-bet-selection-betbuilder--content-left-ball-wrapper"
            [ngClass]="{ 'first-element': isFirst, 'last-element': isLast }"
          >
            <img
              class="sb-bet-selection-betbuilder--content-left-ball"
              src="assets/global/img/sb-betbuilderItemDark.svg"
            />
          </div>
          <div class="sb-bet-selection-betbuilder--content-left-ball-item">
            <span class="sb-bet-selection--title color-dark"> {{ item.ResultName }}&nbsp; </span>
            <span class="sb-bet-selection--text color-dark"> - {{ item.GameName }} </span>
          </div>
        </span>
      </div>
      <button
        *ngIf="betbuilderItem.Odd"
        class="sb-marquee-betbuilder--button"
        (click)="bet(betbuilderItem)"
        [ngClass]="{'is-selected': isSelected(betbuilderItem)}"
      >
        <span *ngIf="!isSelected(betbuilderItem)" class="selected-text-color color-muted">
          {{ 'tAddTick' | translate }}
        </span>
        <span *ngIf="isSelected(betbuilderItem)" class="selected-text-color color-muted"
          >{{ 'tAdded' | translate }}</span
        >
        <span class="selected-text-color text-16">
          {{ 'bolC' | translate }}: {{ betbuilderItem.Odd.toString() | formatOdd }}
        </span>
      </button>
    </swiper-slide>
  </swiper-container>
</section>
