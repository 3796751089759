import { Component, inject } from '@angular/core';
import { NavController, NavParams, ModalController } from '@ionic/angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

//Services
import { Utils } from '../../../utils/Utils';
import { TrackingService } from '../../../providers/TrackingService';
import { UserService } from '../../../providers/UserService';
import { EventsService } from '../../../providers/EventsService';

import { GlobalVarsModel } from '../../../models/ModelGlobalsVar';
import { ParseMoney } from '../../../pipes/parseMoney';
import { TrackingInfo } from '../../base/BasePage';
import { PaymentsCashierService } from '../../../providers/payments/PaymentsCashierService';
import { CommonModule } from '@angular/common';
import { ExtractDatePipe } from '../../../pipes/extractDate.pipe';
//import relativo por dependencias ciclicas
import { BasePage } from '../../base/BasePage';
import { CsPendingPayments } from '@models/payments/cs-pending-payments';

@Component({
  selector: 'page-cashier-pending-payments',
  templateUrl: './cashier-pending-payments.html',
  styleUrls: ['./cashier-pending-payments.scss'],
  standalone: true,
  imports: [TranslateModule, CommonModule, ExtractDatePipe, ParseMoney],
  host: {
    class: 'sb-alert cs-pending-payments'
  }
})
export class CashierPendingPaymentsPage extends BasePage {
  pendingPayments: any;
  public globalVars!: GlobalVarsModel;

  public navCtrl = inject(NavController);
  public navParams = inject(NavParams);
  public events = inject(EventsService);
  public viewCtrl = inject(ModalController);
  public translate = inject(TranslateService);
  public paymentsService = inject(PaymentsCashierService);
  public modalCtrl = inject(ModalController);
  public userService = inject(UserService);
  public utils = inject(Utils);
  override trackingService = inject(TrackingService);

  constructor() {
    super();
    this.pendingPayments = this.navParams.data;
  }

  getTrackingInfo(): TrackingInfo {
    return {
      uri: encodeURI(`/CashierPendingPayments`),
      description: `CashierPendingPayments`,
      additionalData: {
        sportHandle: null
      }
    };
  }

  close(data: CsPendingPayments = null) {
    this.viewCtrl.dismiss(data);
  }

  cancelPayment(payment) {
    this.paymentsService.cancelPendingPayment(payment).subscribe({
      next: (_data) => {
        this.getPendingOnlinePayments();
      },
      error: (_error) => {
        this.close();
      }
    });
  }

  public getPendingOnlinePayments() {
    this.userService.getPendingOnlinePayments(this.globalVars.user.username).subscribe({
      next: (data) => {
        this.events.publish('loader:dismiss', null);
        if (!data || data.length === 0) {
          this.pendingPayments = [];
          this.close(this.pendingPayments);
          return;
        }
        const offlinePayments = ['Hal-cash', this.translate.instant('typeextractotarnom'), 'Tarjeta nominativa'];
        this.pendingPayments = data.filter((payment) => offlinePayments.indexOf(payment.Metodo) === -1);
      },
      error: (err) => {
        this.utils.showError(err);
      }
    });
  }
}
