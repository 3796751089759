/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Pipe, PipeTransform } from '@angular/core';
import { C_LobbyGame } from '@models/lobby/C_Lobby';

@Pipe({ name: 'lobbyType' })
export class LobbyFilter implements PipeTransform {
  transform(lobby: C_LobbyGame[], args: any[]): any {
    let lobbyFiltered;

    if (!lobby) {
      return;
    }

    const category = args[0],
      lobbyId = args[1],
      featured = args[2];

    if (lobbyId.CategoryId === '99') {
      lobbyFiltered = lobby.reduce((unique, o) => {
        if (!unique.some((obj) => obj.GameId === o.GameId)) {
          unique.push(o);
        }
        return unique;
      }, []);

      return this.sort(lobbyFiltered);
    }

    if (lobbyId.CategoryId === '98') {
      lobbyFiltered = lobby
        .filter((game) => game.GameName.toLowerCase().includes(lobbyId.term.toLowerCase()))
        .reduce((unique, o) => {
          if (!unique.some((obj) => obj.GameId === o.GameId)) {
            unique.push(o);
          }
          return unique;
        }, []);
      return this.sort(lobbyFiltered);
    }

    lobbyFiltered = lobby.filter(
      (game) => game.GameLobbyGroupType === category && game.GameLobbyGroupId == lobbyId.CategoryId
    );

    lobbyFiltered = this.sort(lobbyFiltered);

    return featured ? lobbyFiltered.filter((l, i) => i <= 10) : lobbyFiltered;
  }

  sort(lobby) {
    return lobby.sort((a, b) => a.GamePriority - b.GamePriority);
  }
}
