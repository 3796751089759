<ion-nav
  hideBackButton
  class="codere-navbar-pc"
  [class.has-submenu]="globalVars?.user?.logged"
  [ngClass]="{'backgroundheader': globalVars.FEATURES?.MSO_NewReBranding, 
  'colornewlogo': globalVars.FEATURES?.MSO_NewReBranding && !isCasinoUrl, 'colornewlogocas' : globalVars.FEATURES?.MSO_NewReBranding && isCasinoUrl}"
>
  <div class="toolbar-content toolbar-content-md">
    <!-- Logo de Codere -->
    <div class="nav left logoCodereNavbar">
      <ion-buttons>
        <button
          (click)="goHomePage()"
          class="logo-button-align bar-button bar-button-md bar-button-default bar-button-default-md"
        >
          <img
            alt=""
            [class]="globalVars.FEATURES?.MSO_NewReBranding ? isCasinoUrl ? headerImg.imgClass.casino : headerImg.imgClass.sport : headerImg.imgClass.common"
            [src]="globalVars.FEATURES?.MSO_NewReBranding ? isCasinoUrl ? headerImg.imgSrcCas : headerImg.imgSrcSport : headerImg.imgSrc"
          />
        </button>
      </ion-buttons>
    </div>
    <!-- Menú principal -->
    <div class="nav left navSectionsNavbar">
      <ion-buttons class="bar-buttons bar-buttons-md">
        <ng-container *ngFor="let component of navService?.navSectionsLeft$ | async">
          <button
            ion-button
            *ngIf="getFeature(component)"
            class="nav-item bar-button bar-button-md bar-button-default bar-button-default-md"
            (click)="navigate(component)"
            [class.is-active]="isActive(component)"
            [ngClass]="{ 'nav-item-custom': component?.icon }"
          >
            <div>
              <img *ngIf="component?.icon" class="nav-icon" [src]="component?.icon" />
            </div>
            <span>{{component?.translate | translate | uppercase}}</span>
          </button>
        </ng-container>
        <button
          class="nav-item bar-button bar-button-md bar-button-default bar-button-default-md"
          ion-button=""
          (click)="ourSalas()"
          *ngIf="globalVars.licenseType === licenseType.ArgentinaCaba"
        >
          <span class="button-inner">
            <span>Nuestras Salas</span>
          </span>
          <div class="button-effect"></div>
        </button>
        <button
          *ngIf="globalVars?.FEATURES?.MSO_SesionTimer || globalVars?.FEATURES?.MSO_LocalDateTimer"
          class="nav-item bar-button bar-button-md bar-button-default bar-button-default-md"
          ion-button=""
        >
          <span class="button-inner flexCol">
            <span *ngIf="globalVars?.FEATURES?.MSO_SesionTimer && globalVars?.user?.logged" class="timeheader margint19"
              >{{timeColombia}}</span
            >
            <span *ngIf="globalVars?.FEATURES?.MSO_LocalDateTimer" class="timeheader margint19">{{localTime}}</span>
          </span>
          <div class="button-effect"></div>
        </button>
      </ion-buttons>
    </div>
    <!-- Search -->
    <div
      *ngIf="showSearchInput && !hideHeaderSearchFunctionality"
      class="nav right searchNavbar marginr45"
      [class.explorer-nav-buttons]="globalVars?.browser === 'internet'"
      [class.focused]="searchFocus"
    >
      <ion-icon name="search" size="small" color="light" class="icon icon-md icon-md-light ion-md-search"></ion-icon>
      <input
        class="navbar-search-input"
        submenu
        (clickOutside)="toggleSubMenu('search',$event)"
        [(ngModel)]="searchInputModel"
        (ngModelChange)="changed($event)"
        [class.expanded]="searchFocusActive"
        (focus)="toggleInputWidth(searchInput,true)"
        (blur)="toggleInputWidth(searchInput)"
        type="text"
        placeholder="{{'phSearch'|translate}} ..."
        #searchInput
      />
    </div>

    <!-- Botones -->
    <div class="nav right userNavbar" [class.not-logged]="!globalVars?.user?.logged">
      <!-- LOGGED -->
      <ion-buttons class="loginOps align-right user-actions">
        <div>
          <button
            ion-button
            light
            class="nav-item"
            (click)="openMisApuestas()"
            [hidden]="!globalVars?.user?.logged"
            *ngIf="globalVars?.FEATURES?.notificationEnabled"
          >
            <span class="button-inner">
              <ion-icon name="mail" color="light"></ion-icon>
            </span>
          </button>
          <button
            ion-button
            light
            class="nav-item bar-button bar-button-md bar-button-default bar-button-default-md"
            [hidden]="!globalVars?.user?.logged"
          >
            <span class="button-inner"> {{ globalVars?.user?.username }} </span>
          </button>
        </div>
        <div>
          <!-- Mensajes -->
          <button
            *ngIf="globalVars?.user?.logged && globalVars.FEATURES.messageServiceEnabled"
            ion-button
            light
            class="nav-item bar-button-clear-md btnnotification"
            (click)="goUserMessagesPage()"
            tappable
            clear
          >
            <span class="button-inner">
              <span [ngClass]="messageCount && messageCount > 0 ? 'icon-badge-container' : null" slot="start">
                <ion-icon name="notifications-outline"> </ion-icon>
                <ion-badge
                  style="font-family: RobotoCondensed-Regular"
                  *ngIf="messageCount && messageCount > 0"
                  class="notifications-badge"
                  >{{ messageCount }}</ion-badge
                >
              </span>
            </span>
          </button>
          <button
            ion-button
            light
            class="nav-item contact-navbar-btn bar-button bar-button-md bar-button-default bar-button-default-md"
            (click)="goContactPage()"
            [hidden]="!globalVars?.user?.logged"
            tappable
            *ngIf="!globalVars.FEATURES.messageServiceEnabled"
          >
            <span class="contactSpan">
              <span><ion-icon name="mail-outline"></ion-icon></span> {{'tContac'| translate }}
            </span>
          </button>
          <button
            ion-button
            light
            class="nav-item contact-navbar-btn bar-button bar-button-md bar-button-default bar-button-default-md"
            (click)="navigateDeposits()"
            [hidden]="!globalVars.user?.logged"
            tappable
          >
            <span class="button-inner">
              | <span><i class="icon-depositoon"></i> </span> {{'IcoDeposit' | translate}} |</span
            >
            <!--Depositar-->
          </button>
          <button
            ion-button
            class="nav-item bar-button bar-button-md bar-button-default bar-button-default-md"
            (click)="refreshBalance()"
            [hidden]="!globalVars?.user?.logged"
          >
            <span class="button-inner">
              <i class="codere-icon icon-reload"></i>
            </span>
          </button>
          <button
            ion-button
            class="nav-item bar-button bar-button-md bar-button-default bar-button-default-md"
            (click)="showBalanceDetails($event)"
            [hidden]="!globalVars?.user?.logged"
          >
            <span class="button-inner">
              {{globalVars?.user?.balance | parseMoney:[4]}}

              <ion-icon style="margin-left: 2px" *ngIf="balancePopoverAvailable()" [name]="popoverName"> </ion-icon>
            </span>
          </button>
        </div>
      </ion-buttons>
      <!-- NOT LOGGED -->
      <ion-buttons
        class="loginOps align-right bar-button-default bar-button-default-md"
        *ngIf="!globalVars?.user?.logged || isRegistro"
      >
        <button
          ion-button
          tappable="true"
          class="btAccess bar-button bar-button-md bar-button-default bar-button-default-md"
          (click)="openModalLogin()"
          [ngClass]="{'disable-hover': globalVars.FEATURES?.MSO_NewReBranding}"
        >
          <span>{{'access' | translate}} </span>
        </button>
        <button
          ion-button
          *ngIf="getUrl() === 'RegistroNewPage'"
          tappable="true"
          class="btAccess"
          (click)="openModalLogin()"
        >
          <span>{{'access' | translate}}</span>
        </button>
        <button
          ion-button
          tappable="true"
          class="btSignUp bar-button bar-button-md bar-button-default bar-button-default-md"
          (click)="openRegistro()"
          [hidden]="isRegistro"
        >
          <span>{{'signUp' | translate}}</span>
        </button>
      </ion-buttons>

      <ion-buttons class="loginOps align-right" right *ngIf="isRegistro">
        <button class="btCallUs" (click)="navigate('CallMePage')" *ngIf="globalVars?.FEATURES?.callmebackEnabled">
          <span [innerHTML]="'tRegTel4' | translate">{{'callMe' | translate}}</span>
          <i class="icon-callUs"></i>
        </button>
      </ion-buttons>
    </div>
  </div>
</ion-nav>
