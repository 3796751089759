import { CommonModule, Location } from "@angular/common";
import { AfterViewInit, Component, ElementRef, Renderer2, ViewChild, inject } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { ScreenOrientation } from "@capacitor/screen-orientation";
import { DomController, IonicModule } from "@ionic/angular";
import { WindowRef } from "@providers/window";
import { forkJoin, map } from "rxjs";

// Models
import { GlobalVarsModel } from "@models/ModelGlobalsVar";

// Services
import { EventsService } from "@providers/EventsService";
import { TrackingService } from "@providers/TrackingService";
import { NewBaseService } from "@providers/newBase.service";
import { DeviceService } from "@services/device.service";
import { SportRaceService, SportService } from "@sports-services/index";

// Routes
import { PAGES_PATHS, SPORTS_PATHS } from "@shared-constants/routes";

// Utils
import { Utils } from "@shared-utils/Utils";
import { SportHandleList, SportsConstants, VIDEOPLAYER_ACTIONS } from "@sports-utils/index";

declare let avvpl;

@Component({
  selector: "video-player",
  templateUrl: "./video-player.html",
  styleUrls: ["./video-player.scss"],
  standalone: true,
  imports: [IonicModule, CommonModule],
})
export class VideoPlayerComponent implements AfterViewInit {
  public ticketTarget;
  public offsetX = 0;
  public offsetY = 0;
  public isFree = false;
  public hammer;
  public eventListClass = "";

  public event;
  public streamingEvents = [];
  public videotag: string;
  public lastEvent;
  public windowMove = false;

  private avvpl;

  zoom = {
    active: false,
    width: 0,
    height: 0,
    left: 0,
    boxHeight: 0,
    boxStartHeight: 0,
  };

  error = false;

  globalVars!: GlobalVarsModel;
  isDesktop = false;
  isMobile = false;

  events = inject(EventsService);
  element = inject(ElementRef);
  renderer = inject(Renderer2);
  domCtrl = inject(DomController);
  windowRef = inject(WindowRef);
  sportService = inject(SportService);
  sanitizer = inject(DomSanitizer);
  router = inject(Router);
  newBaseService = inject(NewBaseService);
  deviceService = inject(DeviceService);
  location = inject(Location);
  sportRaceService = inject(SportRaceService);
  trackingService = inject(TrackingService);
  utils = inject(Utils);

  @ViewChild("videoPlayerContainer") container: ElementRef;
  @ViewChild("videoPlayerTopBar") topbar: ElementRef;

  constructor() {
    this.newBaseService.getVars.subscribe((data) => {
      this.globalVars = data;
    });
    this.isDesktop = this.deviceService.isDesktop();
    this.isMobile = this.deviceService.isMobile();

    this.location.onUrlChange((url) => {
      if (
        [
          PAGES_PATHS.HomePage,
          SPORTS_PATHS.DirectosPage,
          SPORTS_PATHS.EventoPage,
          "EventoMejoraPage",
          SPORTS_PATHS.HorsesPage,
          SPORTS_PATHS.HorsesMarketPage,
          SPORTS_PATHS.MercadosPage,
          SPORTS_PATHS.NowLiveDetailPage,
          SPORTS_PATHS.SportCountriesPage,
          "RealMadridPage",
          "RiverPlatePage",
          SPORTS_PATHS.SpecialTeamPage,
          SPORTS_PATHS.TodayPage,
          SPORTS_PATHS.SportEventsPage,
          SPORTS_PATHS.EventoSpecialPage,
        ].some((str) => url.includes(str))
      ) {
        this.globalVars.hideBetFooterBar = false;
        this.globalVars.hasTicket = true;
        if (!this.globalVars.ApplicationState.StreamingOn) {
          return;
        }
        if (this.isMobile) {
          // if (![SPORTS_PATHS.HorsesMarketPage, "NowLiveDetailPage"].some((str) => url.includes(str))) {
          //   this.events.publish(VIDEOPLAYER_ACTIONS.CLOSE);
          // }
          // //  else this.goToTarget(false);
          // else {
          //   setTimeout(() => {
          //     const content = document.getElementById("video-container");
          //     this.goToTarget(false, content);
          //   }, 200);
          this.events.publish(VIDEOPLAYER_ACTIONS.CLOSE);
        } else {
          this.events.publish(VIDEOPLAYER_ACTIONS.TO_TICKET);
          this.sportService.showSportradar$.next(false);
        }
        return;
      }

      this.globalVars.hideBetFooterBar = true;
      this.globalVars.hasTicket = false;
      if (this.globalVars.ApplicationState.StreamingOn) {
        this.events.publish(VIDEOPLAYER_ACTIONS.FREE);
      }
    });

    this.events.subscribe(VIDEOPLAYER_ACTIONS.PLAY, (args: { src; event; provider; height }) => {
      const { src, event, provider, height } = args;
      console.log({ provider });

      this.closeConnections();
      setTimeout(() => {
        this.event = event;
        this.lastEvent = event;

        this.ticketTarget = document.getElementById(this.getContainerTarget());

        this.videotag = src;
        this.addVideoTag(src);

        this.isFree = false;
        this.goToTarget(false);
        if (this.isDesktop) {
          this.zoom.boxStartHeight = height;
          const box = document.getElementsByClassName("sb-grid-layout")[0];
          this.renderer.setStyle(box, "height", box.getBoundingClientRect().height + "px");
        }

        this.renderer.setStyle(this.element.nativeElement, "display", "block");
        this.renderer.setStyle(this.element.nativeElement, "position", "absolute");
      }, 800);
      this.globalVars.videoPlayerOnTicket = false;
      this.globalVars.videoPlayerWindow = false;
    });

    this.events.subscribe(VIDEOPLAYER_ACTIONS.PLAY_IFRAME, ({ src, event, provider, height }) => {
      console.log({ provider, height });

      this.closeConnections();
      setTimeout(() => {
        this.event = event;
        this.ticketTarget = document.getElementById(this.getContainerTarget());
        this.addIFrameTag(src);

        this.isFree = false;
        this.goToTarget(false);
        this.renderer.setStyle(this.element.nativeElement, "display", "block");
        this.renderer.setStyle(this.element.nativeElement, "position", "absolute");
      }, 500);
      this.globalVars.videoPlayerOnTicket = false;
      this.globalVars.videoPlayerWindow = false;
    });

    this.events.subscribe(VIDEOPLAYER_ACTIONS.CLOSE, (payload) => {
      if (payload?.error && !this.error) {
        this.error = true;
        this.utils.sportsAlert(
          true,
          "",
          payload.event?.SportHandle === SportHandleList.greyhound_racing ||
            payload.event?.SportHandle === SportHandleList.horse_racing
            ? SportsConstants.VideoNoComenzadoLiteral
            : SportsConstants.VideoNoDisponibleLiteral,
          SportsConstants.AceptarLiteral,
          () => {
            this.error = false;
          },
        );
      }
      this.container.nativeElement.innerHTML = "";
      this.isFree = false;
      this.removeHammer();
      this.globalVars.videoPlayerWindow = false;
      this.globalVars.videoPlayerOnTicket = false;
      this.globalVars.ApplicationState.StreamingOn = false;
      this.zoom.active = false;

      if (this.isDesktop) {
        const box = document.getElementsByClassName("sb-grid-layout")[0];
        if (box) {
          this.renderer.setStyle(box, "height", this.zoom.boxStartHeight + "px");
          setTimeout(() => {
            this.renderer.setStyle(box, "height", "auto");
          }, 500);
        }
      }
      this.renderer.setStyle(this.element.nativeElement, "display", "none");
      this.renderer.setStyle(this.element.nativeElement, "position", "absolute");

      this.closeConnections();
    });

    this.events.subscribe(VIDEOPLAYER_ACTIONS.FREE, () => {
      if (this.isFree) {
        return;
      }

      if (!this.globalVars.ApplicationState.StreamingOn) {
        return;
      }

      if (!this.isDesktop) {
        this.domCtrl.write(() => {
          this.renderer.setStyle(this.element.nativeElement, "width", "58vw");
          this.renderer.setStyle(this.element.nativeElement, "height", "20vh");
        });
      }

      if (this.isDesktop && this.zoom.active) {
        this.handleZoomDesktop();
      }

      setTimeout(() => {
        this.globalVars.videoPlayerOnTicket = false;
        this.renderer.setStyle(this.element.nativeElement, "position", "absolute");

        this.handlePan({ center: { x: 0, y: window.innerHeight } }, true);
        this.isFree = true;

        this.hammer = new window["Hammer"](this.element.nativeElement);

        this.hammer.get("pan").set({ direction: window["Hammer"].DIRECTION_ALL });

        this.hammer.on("pan", (ev) => {
          this.handlePan(ev, false);
        });

        this.hammer.on("panend", (ev) => {
          this.handlePan(ev, true);
          const myNodeList = document.getElementsByClassName("scroll-content") as HTMLCollectionOf<HTMLElement>;
          for (let i = 0; i < myNodeList.length; i++) {
            myNodeList[i].style.overflowY = "scroll";
          }
        });

        this.hammer.on("panstart", (ev) => {
          const myNodeList = document.getElementsByClassName("scroll-content") as HTMLCollectionOf<HTMLElement>;
          for (let i = 0; i < myNodeList.length; i++) {
            myNodeList[i].style.overflowY = "hidden";
          }

          this.renderer.setStyle(this.element.nativeElement, "transition", "unset");

          const bbox = this.element.nativeElement.getBoundingClientRect();
          this.offsetX = ev.center.x - bbox.left;
          this.offsetY = ev.center.y - bbox.top;
        });
      }, 500);
    });

    this.events.subscribe(VIDEOPLAYER_ACTIONS.TO_TICKET, (payload) => {
      if (payload?.from === "resize" && this.isFree && this.isMobile) {
        return;
      }
      if (!this.globalVars.ApplicationState.StreamingOn) {
        return;
      }

      if (!this.isDesktop) {
        if (this.isFree) {
          return;
        }
        this.events.publish(VIDEOPLAYER_ACTIONS.FREE);
        return;
      }

      this.globalVars.videoPlayerWindow = true;
      this.goToTicket();
      this.isFree = false;
      this.removeHammer();
    });

    this.events.subscribe(VIDEOPLAYER_ACTIONS.RUN, (runFromPageData) => {
      this.loadEvent(runFromPageData.event, runFromPageData);
    });
  }

  getContainerTarget() {
    const raceContainerElement = document.getElementById("video-container-race-market");
    const liveContainerElement = document.getElementById("video-container-live-market");
    let target = "";
    if (!raceContainerElement && liveContainerElement) {
      target = "video-container-live-market";
    } else if (raceContainerElement && !liveContainerElement) {
      target = "video-container-race-market";
    } else if (raceContainerElement && liveContainerElement) {
      if (
        raceContainerElement.getBoundingClientRect().width > liveContainerElement.getBoundingClientRect().width
      ) {
        target = "video-container-race-market";
      } else target = "video-container-live-market";
    }
    return target;
  }

  addVideoTag(src) {
    this.container.nativeElement.innerHTML = "";
    const videoTag = `<div class="video-player" id="video-player">
      <video class="live-iframe" preload="none" width="100%" height="100%">
        <source src="${src}" type="application/x-mpegURL" />
        <source src="${src}" type="application/smil" />
        <source src="${src}" type="video/webm" />
        <source src="${src}" type="video/mp4" />
      </video>
    </div>`;
    this.container.nativeElement.innerHTML = videoTag;
    this.globalVars.ApplicationState.StreamingOn = true;
    this.closeConnections();
    setTimeout(() => this.initStream(), 500);
  }

  addIFrameTag(src) {
    this.closeConnections();
    this.container.nativeElement.innerHTML = "";
    const frameTag = `<div class="video-player" id="video-player">
      <iframe class="live-iframe" width="100%" height="100%" src="${src}" frameBorder="0"${this.isMobile ? " allowfullscreen" : ""}>
      </iframe>
    </div>`;
    this.container.nativeElement.innerHTML = frameTag;
    this.globalVars.ApplicationState.StreamingOn = true;
  }

  close() {
    if (this.streamingEvents.length !== 0) {
      this.streamingEvents = [];
    } else {
      this.events.publish(VIDEOPLAYER_ACTIONS.CLOSE);
    }
  }

  showMaximize(): boolean {
    const box = document.getElementsByClassName("has-two-items")?.[0];
    const showMaximize =
      box !== undefined &&
      !this.router.url.includes(SPORTS_PATHS.HorsesMarketPage) &&
      !this.globalVars.videoPlayerWindow;
    return showMaximize;
  }

  handleZoomDesktop() {
    const box = document.getElementsByClassName("has-two-items")[0];
    if (!this.globalVars.videoPlayerWindow) {
      if (!this.zoom.active) {
        this.zoom.active = true;
        const boxPosition = box.getBoundingClientRect();
        const elementPosition = this.element.nativeElement.getBoundingClientRect();
        this.zoom.left = elementPosition.left;
        this.zoom.width = elementPosition.width;
        this.zoom.height = elementPosition.height;
        this.zoom.boxHeight = boxPosition.height;
        this.renderer.setStyle(box, "transition", ".3s ease-out");
        this.renderer.setStyle(this.element.nativeElement, "transition", ".3s ease-out");
        this.renderer.setStyle(this.element.nativeElement, "left", boxPosition.left + "px");
        this.renderer.setStyle(this.element.nativeElement, "width", boxPosition.width + "px");
        this.renderer.setStyle(this.element.nativeElement, "height", "32.4vw");
        this.renderer.setStyle(box, "height", "32.4vw");
      } else {
        this.zoom.active = false;
        if (box) {
          this.renderer.setStyle(box, "transition", ".3s ease-out");
          this.renderer.setStyle(box, "height", this.zoom.boxHeight + "px");
        }
        this.renderer.setStyle(this.element.nativeElement, "transition", ".3s ease-out");
        this.renderer.setStyle(this.element.nativeElement, "left", this.zoom.left + "px");
        this.renderer.setStyle(this.element.nativeElement, "width", this.zoom.width + "px");
        this.renderer.setStyle(this.element.nativeElement, "height", this.zoom.height + "px");
      }
    }
  }

  private handleZoomMobile() {
    if (!this.zoom.active) {
      this.zoom.active = true;
      ScreenOrientation.lock({ orientation: "landscape-primary" });
    } else {
      this.zoom.active = false;
      ScreenOrientation.lock({ orientation: "portrait" });
    }
  }

  closeConnections() {
    if (this.windowRef.nativeWindow.lpInstance) {
      this.windowRef.nativeWindow.lpInstance.stop();
      if (this.windowRef.nativeWindow.lpInstance.activeVideoHandler.hls) {
        this.windowRef.nativeWindow.lpInstance.activeVideoHandler.hls.stopLoad();
      }
    }
    if (this.avvpl) {
      this.avvpl.remove();
    }
  }

  goToEvent() {
    if (
      this.event.SportHandle !== SportHandleList.greyhound_racing &&
      this.event.SportHandle !== SportHandleList.horse_racing
    ) {
      this.router.navigate(["NowLiveDetailPage"], {
        state: { params: { ...this.event, runStreaming: true } },
      });
    } else {
      this.router.navigate([SPORTS_PATHS.HorsesMarketPage], {
        state: { params: { ...this.event, runStreaming: true } },
        queryParams: { animate: !this.globalVars.isNative },
      });
    }
    this.events.publish(VIDEOPLAYER_ACTIONS.TO_TICKET);
  }

  loadLiveEvents() {
    forkJoin([
      this.sportService.GetLiveEventsBySportHandle(),
      this.sportRaceService.getNextRaces(SportHandleList.horse_racing),
      this.sportRaceService.getNextRaces(SportHandleList.greyhound_racing),
    ])
      .pipe(
        map(([eventsBySportHandle, horseRacingNextRaces, greyhoundRacingNextRaces]) => {
          let filteredEventsBySportHandle = [];
          for (const x in eventsBySportHandle) {
            filteredEventsBySportHandle = filteredEventsBySportHandle.concat(
              eventsBySportHandle[x].Events.filter(
                (event) => !!event.StreamingEnabled && event.StreamingEnabled !== "",
              ),
            );
          }
          const filteredHorseRacingNextRaces = horseRacingNextRaces.filter(
            (event) => !!event.StreamingEnabled && event.StreamingEnabled !== "",
          );
          const filteredGreyhoundRacingNextRaces = greyhoundRacingNextRaces.filter(
            (event) => !!event.StreamingEnabled && event.StreamingEnabled !== "",
          );
          return [
            ...filteredEventsBySportHandle,
            ...filteredHorseRacingNextRaces,
            ...filteredGreyhoundRacingNextRaces,
          ];
        }),
      )
      .subscribe((streamingEvents) => {
        this.streamingEvents = streamingEvents;
      });
  }

  loadEvent(event, runFromPageData?) {
    this.streamingEvents = []; // Empty array to close the streaming list on topbar when load a new event.
    if (!runFromPageData) {
      setTimeout(() => {
        this.event = event;
      }, 500);
    }
    console.log(
      `Trying to process streaming of provider ${event.StreamingEnabled}. ${event.StreamingId ? "" : " With empty StreamingId."}${event.StreamingId ? "" : " With empty StreamingId."}`,
    );
    switch (event.StreamingEnabled) {
      case "betradar":
        this.getBetRadarVideoStreaming(event, runFromPageData);
        break;
      case "img":
        this.getIMGVideoStreaming(event, runFromPageData);
        break;
      case "sis":
        this.getSisVideoStreaming(event, runFromPageData);
        break;
      case "genius":
        this.getBetGeniusViedoStreaming(event, runFromPageData);
        break;
      case "beter":
        this.viewBeterStreamingIframe(event, runFromPageData);
        break;
      case "watchbet":
        this.getWatchBetVideoStreaming(event, runFromPageData);
        break;
      case "sms":
        this.getSMSStreamingUrl(event, runFromPageData);
        break;
      case "xbnet":
        this.getXBNETStreamingUrl(event, runFromPageData);
        break;
    }
  }

  getSMSStreamingUrl(event, runFromPageData?) {
    this.sportService.getSMSStreamingUrl(event.StreamingId).subscribe(
      (data) => {
        if (data !== "" && data.url != null && data.isLive) {
          if (runFromPageData) {
            this.events.publish(VIDEOPLAYER_ACTIONS.PLAY, {
              src: data.url,
              event: event,
              provider: "sms",
            });
          } else {
            this.videotag = data.url;
            this.addVideoTag(data.url);
          }
          this.trackingService.trackEvent([
            "ActiveStreamingSMS",
            event.NodeId,
            event.SportHandle,
            "Streaming activo",
            "event",
          ]);
        } else {
          this.events.publish(VIDEOPLAYER_ACTIONS.CLOSE, {
            error: "getSMSStreamingUrl error: Empty data or url",
            event,
          });
        }
      },
      (error) => {
        this.events.publish(VIDEOPLAYER_ACTIONS.CLOSE, { event, error: `getSMSStreamingUrl error: ${error}` });
      },
    );
  }

  getXBNETStreamingUrl(event, runFromPageData?) {
    this.sportService.getStreamingXbnet(event.StatisticsId).subscribe(
      (data) => {
        if (data.success) {
          if (runFromPageData) {
            this.events.publish(VIDEOPLAYER_ACTIONS.PLAY_IFRAME, {
              src: data.url,
              event: event,
              provider: "xbnet",
            });
          } else {
            this.addIFrameTag(data.url);
          }
          this.trackingService.trackEvent([
            "ActiveStreamingXBNETS",
            event.NodeId,
            event.SportHandle,
            "Streaming activo",
            "event",
          ]);
        } else {
          this.events.publish(VIDEOPLAYER_ACTIONS.CLOSE, { event, error: `getXBNETStreamingUrl not succes` });
        }
      },
      (error) => {
        this.events.publish(VIDEOPLAYER_ACTIONS.CLOSE, { event, error: `getXBNETStreamingUrl error: ${error}` });
      },
    );
  }

  getBetRadarVideoStreaming(event, runFromPageData?) {
    this.sportService.getStreamingBetRadarUrl(event.StreamingId).subscribe(
      (data) => {
        if (!!data && data.isLive) {
          if (runFromPageData) {
            this.events.publish(VIDEOPLAYER_ACTIONS.PLAY, {
              src: data.url,
              event: event,
              provider: "betRadar",
              height: runFromPageData.height,
            });
          } else {
            this.videotag = data.url;
            this.addVideoTag(data.url);
          }
          this.trackingService.trackEvent([
            "GetVideoStreaming",
            event.StreamingId,
            event.Name,
            event.SportHandle,
            "event",
          ]);
        } else {
          this.events.publish(VIDEOPLAYER_ACTIONS.CLOSE, {
            event,
            error: "getBetRadarVideoStreaming error: Empty data or url",
          });
        }
      },
      (error) => {
        this.events.publish(VIDEOPLAYER_ACTIONS.CLOSE, {
          event,
          error: `getBetRadarVideoStreaming error: ${error}`,
        });
      },
    );
  }

  getIMGVideoStreaming(event, runFromPageData?) {
    this.sportService.getIMGVideoStreamingURL(event.StreamingId).subscribe(
      (data) => {
        if (data.success) {
          const token = data.token;
          const timestamp = data.timestamp;
          const id = data.id;
          this.sportService.getStreamingImgUrl(token, timestamp, id).subscribe(
            (streamingdata) => {
              const urlobject = streamingdata;
              if (urlobject.hlsUrl !== "") {
                if (runFromPageData) {
                  this.events.publish(VIDEOPLAYER_ACTIONS.PLAY, {
                    src: urlobject.hlsUrl,
                    event: event,
                    provider: "img",
                  });
                } else {
                  this.videotag = urlobject.hlsUrl;
                  this.addVideoTag(urlobject.hlsUrl);
                }
                this.trackingService.trackEvent([
                  "GetVideoStreaming",
                  event.StreamingId,
                  event.Name,
                  event.SportHandle,
                  "event",
                ]);
              } else {
                this.events.publish(VIDEOPLAYER_ACTIONS.CLOSE, {
                  event,
                  error: "getIMGVideoStreaming error: Empty data or url",
                });
              }
            },
            (error) => {
              this.events.publish(VIDEOPLAYER_ACTIONS.CLOSE, {
                event,
                error: `getStreamingImgUrl error: ${error}`,
              });
            },
          );
        } else {
          this.events.publish(VIDEOPLAYER_ACTIONS.CLOSE, {
            event,
            error: "getIMGVideoStreaming error: data not success",
          });
        }
      },
      (error) => {
        this.events.publish(VIDEOPLAYER_ACTIONS.CLOSE, {
          event,
          error: `getIMGVideoStreamingURL error: ${error}`,
        });
      },
    );
  }

  getSisVideoStreaming(event, runFromPageData?) {
    this.sportService.getNewSisStreamingUrl(event.StreamingId).subscribe(
      (data) => {
        let videoFormat = "";
        if (data.format !== "" && data.format === "phenixEmbed") {
          videoFormat = VIDEOPLAYER_ACTIONS.PLAY_IFRAME;
        } else if (data.format !== "" && data.format === "hls") {
          videoFormat = VIDEOPLAYER_ACTIONS.PLAY;
        }
        if (videoFormat !== "") {
          if (runFromPageData) {
            this.events.publish(videoFormat, {
              src: data.url,
              event: event,
              provider: event.StreamingEnabled,
            });
          } else {
            if (videoFormat === VIDEOPLAYER_ACTIONS.PLAY_IFRAME) {
              this.addIFrameTag(data.url);
            } else if (videoFormat === VIDEOPLAYER_ACTIONS.PLAY) {
              this.videotag = data.url;
              this.addVideoTag(data.url);
            }
          }
          this.trackingService.trackEvent([
            "ActiveStreamingsis",
            event.NodeId,
            event.SportHandle,
            "Streaming activo",
            "event",
          ]);
        } else {
          this.events.publish(VIDEOPLAYER_ACTIONS.CLOSE, {
            event,
            error: "getSisVideoStreaming error: data format",
          });
        }
      },
      (error) => {
        this.events.publish(VIDEOPLAYER_ACTIONS.CLOSE, { event, error: `getNewSisStreamingUrl error: ${error}` });
      },
    );
  }

  getBetGeniusViedoStreaming(event, runFromPageData) {
    this.sportService.getBetGeniusStreamingUrl(event.StreamingId).subscribe(
      (data) => {
        if (data.success) {
          if (data.url !== "") {
            if (runFromPageData) {
              this.events.publish(VIDEOPLAYER_ACTIONS.PLAY, {
                src: `${data.url}?${data.token}`,
                event: event,
                provider: "genius",
              });
            } else {
              this.videotag = `${data.url}?${data.token}`;
              this.addVideoTag(`${data.url}?${data.token}`);
            }
            this.trackingService.trackEvent([
              "ActiveStreamingGenius",
              event.StreamingId,
              event.Name,
              event.SportHandle,
              "Streaming activo",
              "event",
            ]);
          } else {
            this.events.publish(VIDEOPLAYER_ACTIONS.CLOSE, {
              event,
              error: "getBetGeniusViedoStreaming error: Empty data or url",
            });
          }
        } else {
          this.events.publish(VIDEOPLAYER_ACTIONS.CLOSE, {
            event,
            error: "getBetGeniusViedoStreaming error: not success",
          });
        }
      },
      (error) => {
        this.events.publish(VIDEOPLAYER_ACTIONS.CLOSE, {
          event,
          error: `getBetGeniusStreamingUrl error: ${error}`,
        });
      },
    );
  }

  viewBeterStreamingIframe(event, runFromPageData?) {
    const videoFormat = VIDEOPLAYER_ACTIONS.PLAY_IFRAME;
    const src = `${SportsConstants.BeterStreamingUrl}${event.StreamingId}`;
    if (runFromPageData) {
      this.events.publish(videoFormat, {
        src,
        event: event,
        provider: event.StreamingEnabled,
      });
    } else {
      this.addIFrameTag(src);
    }
    this.trackingService.trackEvent([
      "ActiveStreamingsis",
      event.NodeId,
      event.SportHandle,
      "Streaming activo",
      "event",
    ]);
  }

  getWatchBetVideoStreaming(event, runFromPageData?) {
    let urlVideoStreaming = "";
    this.sportService.getWatchBetVideoStreamingURL(event.StreamingId).subscribe(
      (data) => {
        if (data.success) {
          const parameter = data.url;
          const eventId = parameter.split("@")[0];
          const md5 = encodeURIComponent(parameter.split("@")[1]);
          const userMd5 = this.md5code(this.globalVars.user.username.toLowerCase());
          this.sportService.getStreamingWatchBetUrl(eventId, userMd5, md5).subscribe(
            (streamingdata) => {
              if (streamingdata) {
                try {
                  const urlobject = streamingdata;
                  let indexUrl = 0;
                  let urlVideo = "";
                  if (Array.isArray(urlobject.eventInfo.availableMediaFormats.mediaFormat)) {
                    if (urlobject.eventInfo.availableMediaFormats.mediaFormat[0].playerAlias === "iPhonewabsec") {
                      urlVideo = this._extractData(
                        urlobject.eventInfo.availableMediaFormats.mediaFormat[0].stream.streamLaunchCode,
                      );
                      indexUrl = 1;
                    } else {
                      urlVideo = this._extractData(
                        urlobject.eventInfo.availableMediaFormats.mediaFormat[1].stream.streamLaunchCode,
                      );
                      indexUrl = 0;
                    }
                    if (location.protocol === "http:")
                      urlVideoStreaming = this._extractData(
                        urlobject.eventInfo.availableMediaFormats.mediaFormat[indexUrl].stream.streamLaunchCode,
                      );
                    else {
                      urlVideoStreaming = urlVideo;
                    }
                  } else {
                    urlVideoStreaming = this._extractData(
                      urlobject.eventInfo.availableMediaFormats.mediaFormat.stream.streamLaunchCode,
                    );
                  }
                  if (runFromPageData) {
                    this.events.publish(VIDEOPLAYER_ACTIONS.PLAY, {
                      src: urlVideoStreaming,
                      event: event,
                      provider: "watchbet",
                    });
                  } else {
                    this.videotag = urlVideoStreaming;
                    this.addVideoTag(urlVideoStreaming);
                  }
                  this.trackingService.trackEvent([
                    "GetVideoStreaming",
                    event.StreamingId,
                    event.Name,
                    event.SportHandle,
                    "event",
                  ]);
                } catch (error) {
                  console.log(error);
                  this.events.publish(VIDEOPLAYER_ACTIONS.CLOSE, {
                    event,
                    error: "getWatchBetVideoStreaming: Error proccessing streamingdata",
                  });
                }
              } else {
                this.events.publish(VIDEOPLAYER_ACTIONS.CLOSE, {
                  event,
                  error: "getWatchBetVideoStreaming: Empty streamingdata",
                });
              }
            },
            (error) => {
              this.events.publish(VIDEOPLAYER_ACTIONS.CLOSE, {
                event,
                error: `getStreamingWatchBetUrl error: ${error}`,
              });
            },
          );
        } else {
          this.events.publish(VIDEOPLAYER_ACTIONS.CLOSE, {
            event,
            error: "getWatchBetVideoStreamingURL: not success",
          });
        }
      },
      (error) => {
        this.events.publish(VIDEOPLAYER_ACTIONS.CLOSE, {
          event,
          error: `getWatchBetVideoStreamingURL error: ${error}`,
        });
      },
    );
  }

  removeHammer() {
    if (!this.hammer) {
      return;
    }

    this.hammer.off("pan");
    this.hammer.off("panend");
    this.hammer.off("panstart");
    this.hammer.destroy();
  }

  ngAfterViewInit() {
    this.renderer.setStyle(this.element.nativeElement, "position", "absolute");
    this.renderer.setStyle(this.element.nativeElement, "left", "0px");
    this.renderer.setStyle(this.element.nativeElement, "top", "0px");
  }

  goToTarget(transition = true, content = null) {
    this.domCtrl.write(() => {
      if (!this.ticketTarget) {
        return;
      }

      const bbox = this.ticketTarget.getBoundingClientRect();
      let scrollTop = 0;
      if (content) {
        scrollTop = content.scrollTop;
      }
      const newLeft = bbox.left;
      const newTop = bbox.top + scrollTop;
      const newWidth = bbox.width;

      this.renderer.setStyle(this.element.nativeElement, "transition", transition ? ".3s ease-out" : "unset");
      this.renderer.setStyle(this.element.nativeElement, "width", newWidth + "px");
      this.getHeightVideo();

      if (document.body.getBoundingClientRect().width > 1024 || this.isMobile) {
        this.renderer.setStyle(this.element.nativeElement, "left", newLeft + "px");
      } else {
        this.renderer.setStyle(this.element.nativeElement, "left", "initial");
      }

      this.renderer.setStyle(this.element.nativeElement, "right", "25.7%");
      this.renderer.setStyle(this.element.nativeElement, "top", newTop + "px");
    });
  }

  private getHeightVideo() {
    let height;
    if (this.isMobile) {
      height = "56.4vw";
    } else {
      height =
        this.event.SportHandle !== SportHandleList.greyhound_racing &&
        this.event.SportHandle !== SportHandleList.horse_racing
          ? "10vw"
          : "32.4vw";

      this.event.SportHandle !== SportHandleList.greyhound_racing &&
      this.event.SportHandle !== SportHandleList.horse_racing
        ? this.renderer.removeClass(this.element.nativeElement, "races-streaming")
        : this.renderer.removeClass(this.element.nativeElement, "regular-streaming");

      this.event.SportHandle !== SportHandleList.greyhound_racing &&
      this.event.SportHandle !== SportHandleList.horse_racing
        ? this.renderer.addClass(this.element.nativeElement, "regular-streaming")
        : this.renderer.addClass(this.element.nativeElement, "races-streaming");
    }
    this.renderer.setStyle(this.element.nativeElement, "height", height);
  }

  goToTicket() {
    this.domCtrl.write(() => {
      const box = document.getElementsByClassName("sb-grid-layout")[0];
      if (box !== undefined) this.renderer.removeStyle(box, "height");
      this.zoom.active = false;

      this.renderer.setStyle(this.element.nativeElement, "transition", ".3s ease-out");
      this.renderer.setStyle(this.element.nativeElement, "width", "calc(25vw - 7px)");
      this.renderer.setStyle(this.element.nativeElement, "height", "10vw");

      this.renderer.setStyle(this.element.nativeElement, "left", "75vw");
      this.renderer.setStyle(this.element.nativeElement, "top", "60px");

      this.renderer.removeClass(this.element.nativeElement, "races-streaming");
      this.renderer.addClass(this.element.nativeElement, "regular-streaming");

      setTimeout(() => {
        this.globalVars.videoPlayerOnTicket = true;
      }, 500);
    });
  }

  handlePan(ev, end) {
    let newLeft = ev.center.x - this.offsetX;
    let newTop = ev.center.y - this.offsetY;

    const bbox = this.element.nativeElement.getBoundingClientRect();
    const margin = 20;

    this.domCtrl.write(() => {
      if (end) {
        this.windowMove = true;
        this.globalVars.videoPlayerWindow = true;
        const midWidth = window.innerWidth / 2;
        const midHeight = window.innerHeight / 2;

        this.renderer.setStyle(this.element.nativeElement, "transition", ".3s ease-out");

        newLeft = ev.center.x < midWidth ? margin : window.innerWidth - bbox.width - margin;
        newTop = ev.center.y < midHeight ? margin : window.innerHeight - bbox.height - margin;

        this.eventListClass = ev.center.y < midHeight ? "list-down" : "list-up";
      }

      this.renderer.setStyle(this.element.nativeElement, "left", newLeft + "px");
      this.renderer.setStyle(this.element.nativeElement, "top", newTop + "px");
    });
  }

  initStream() {
    const config = {
      id: "video-player",
      allowFullScreen: this.isMobile,
      mute: false,
      skin: {
        colors: {
          "icon-button-hover": "#79bf00",
          "label-active": "#79bf00",
          "toggle-button-active": "#79bf00",
          "toggle-button-text-active": "#000000",
          slider: "#79bf00",
        },
      },
    };

    this.avvpl = new avvpl.setupPlayer(config);

    if (this.isMobile) {
      this.avvpl.fullscreenChange.subscribe(() => {
        this.handleZoomMobile();
      });
    }
  }

  private _extractData(data) {
    let str = JSON.stringify(data);
    str = str.replace("#cdata", "url");
    const obt = JSON.parse(str);
    return obt.url;
  }

  private md5code(s) {
    function L(k, d) {
      return (k << d) | (k >>> (32 - d));
    }
    function K(G, k) {
      let I = 0;
      let d = 0;
      let F = 0;
      let H = 0;
      let x = 0;
      F = G & 2147483648;
      H = k & 2147483648;
      I = G & 1073741824;
      d = k & 1073741824;
      x = (G & 1073741823) + (k & 1073741823);
      if (I & d) {
        return x ^ 2147483648 ^ F ^ H;
      }
      if (I | d) {
        if (x & 1073741824) {
          return x ^ 3221225472 ^ F ^ H;
        } else {
          return x ^ 1073741824 ^ F ^ H;
        }
      } else {
        return x ^ F ^ H;
      }
    }
    function r(d, F, k) {
      return (d & F) | (~d & k);
    }
    function q(d, F, k) {
      return (d & k) | (F & ~k);
    }
    function p(d, F, k) {
      return d ^ F ^ k;
    }
    function n(d, F, k) {
      return F ^ (d | ~k);
    }
    function u(G, F, aa, Z, k, H, I) {
      G = K(G, K(K(r(F, aa, Z), k), I));
      return K(L(G, H), F);
    }
    function f(G, F, aa, Z, k, H, I) {
      G = K(G, K(K(q(F, aa, Z), k), I));
      return K(L(G, H), F);
    }
    function D(G, F, aa, Z, k, H, I) {
      G = K(G, K(K(p(F, aa, Z), k), I));
      return K(L(G, H), F);
    }
    function t(G, F, aa, Z, k, H, I) {
      G = K(G, K(K(n(F, aa, Z), k), I));
      return K(L(G, H), F);
    }
    function e(G) {
      let Z;
      const F = G.length;
      const x = F + 8;
      const k = (x - (x % 64)) / 64;
      const I = (k + 1) * 16;
      const aa = new Array(I - 1);
      let d = 0;
      let H = 0;
      while (H < F) {
        Z = (H - (H % 4)) / 4;
        d = (H % 4) * 8;
        aa[Z] = aa[Z] | (G.charCodeAt(H) << d);
        H++;
      }
      Z = (H - (H % 4)) / 4;
      d = (H % 4) * 8;
      aa[Z] = aa[Z] | (128 << d);
      aa[I - 2] = F << 3;
      aa[I - 1] = F >>> 29;
      return aa;
    }
    function B(x) {
      let k = "";
      let F = "";
      let G;
      let d;
      for (d = 0; d <= 3; d++) {
        G = (x >>> (d * 8)) & 255;
        F = "0" + G.toString(16);
        k = k + F.substr(F.length - 2, 2);
      }
      return k;
    }
    function J(k) {
      k = k.replace(/rn/g, "n");
      let d = "";
      for (let F = 0; F < k.length; F++) {
        const x = k.charCodeAt(F);
        if (x < 128) {
          d += String.fromCharCode(x);
        } else {
          if (x > 127 && x < 2048) {
            d += String.fromCharCode((x >> 6) | 192);
            d += String.fromCharCode((x & 63) | 128);
          } else {
            d += String.fromCharCode((x >> 12) | 224);
            d += String.fromCharCode(((x >> 6) & 63) | 128);
            d += String.fromCharCode((x & 63) | 128);
          }
        }
      }
      return d;
    }
    let C = [];
    let P;
    let h;
    let E;
    let v;
    let g;
    let Y;
    let X;
    let W;
    let V;
    const S = 7;
    const Q = 12;
    const N = 17;
    const M = 22;
    const A = 5;
    const z = 9;
    const y = 14;
    const w = 20;
    const o = 4;
    const m = 11;
    const l = 16;
    const j = 23;
    const U = 6;
    const T = 10;
    const R = 15;
    const O = 21;
    s = J(s);
    C = e(s);
    Y = 1732584193;
    X = 4023233417;
    W = 2562383102;
    V = 271733878;
    for (P = 0; P < C.length; P += 16) {
      h = Y;
      E = X;
      v = W;
      g = V;
      Y = u(Y, X, W, V, C[P + 0], S, 3614090360);
      V = u(V, Y, X, W, C[P + 1], Q, 3905402710);
      W = u(W, V, Y, X, C[P + 2], N, 606105819);
      X = u(X, W, V, Y, C[P + 3], M, 3250441966);
      Y = u(Y, X, W, V, C[P + 4], S, 4118548399);
      V = u(V, Y, X, W, C[P + 5], Q, 1200080426);
      W = u(W, V, Y, X, C[P + 6], N, 2821735955);
      X = u(X, W, V, Y, C[P + 7], M, 4249261313);
      Y = u(Y, X, W, V, C[P + 8], S, 1770035416);
      V = u(V, Y, X, W, C[P + 9], Q, 2336552879);
      W = u(W, V, Y, X, C[P + 10], N, 4294925233);
      X = u(X, W, V, Y, C[P + 11], M, 2304563134);
      Y = u(Y, X, W, V, C[P + 12], S, 1804603682);
      V = u(V, Y, X, W, C[P + 13], Q, 4254626195);
      W = u(W, V, Y, X, C[P + 14], N, 2792965006);
      X = u(X, W, V, Y, C[P + 15], M, 1236535329);
      Y = f(Y, X, W, V, C[P + 1], A, 4129170786);
      V = f(V, Y, X, W, C[P + 6], z, 3225465664);
      W = f(W, V, Y, X, C[P + 11], y, 643717713);
      X = f(X, W, V, Y, C[P + 0], w, 3921069994);
      Y = f(Y, X, W, V, C[P + 5], A, 3593408605);
      V = f(V, Y, X, W, C[P + 10], z, 38016083);
      W = f(W, V, Y, X, C[P + 15], y, 3634488961);
      X = f(X, W, V, Y, C[P + 4], w, 3889429448);
      Y = f(Y, X, W, V, C[P + 9], A, 568446438);
      V = f(V, Y, X, W, C[P + 14], z, 3275163606);
      W = f(W, V, Y, X, C[P + 3], y, 4107603335);
      X = f(X, W, V, Y, C[P + 8], w, 1163531501);
      Y = f(Y, X, W, V, C[P + 13], A, 2850285829);
      V = f(V, Y, X, W, C[P + 2], z, 4243563512);
      W = f(W, V, Y, X, C[P + 7], y, 1735328473);
      X = f(X, W, V, Y, C[P + 12], w, 2368359562);
      Y = D(Y, X, W, V, C[P + 5], o, 4294588738);
      V = D(V, Y, X, W, C[P + 8], m, 2272392833);
      W = D(W, V, Y, X, C[P + 11], l, 1839030562);
      X = D(X, W, V, Y, C[P + 14], j, 4259657740);
      Y = D(Y, X, W, V, C[P + 1], o, 2763975236);
      V = D(V, Y, X, W, C[P + 4], m, 1272893353);
      W = D(W, V, Y, X, C[P + 7], l, 4139469664);
      X = D(X, W, V, Y, C[P + 10], j, 3200236656);
      Y = D(Y, X, W, V, C[P + 13], o, 681279174);
      V = D(V, Y, X, W, C[P + 0], m, 3936430074);
      W = D(W, V, Y, X, C[P + 3], l, 3572445317);
      X = D(X, W, V, Y, C[P + 6], j, 76029189);
      Y = D(Y, X, W, V, C[P + 9], o, 3654602809);
      V = D(V, Y, X, W, C[P + 12], m, 3873151461);
      W = D(W, V, Y, X, C[P + 15], l, 530742520);
      X = D(X, W, V, Y, C[P + 2], j, 3299628645);
      Y = t(Y, X, W, V, C[P + 0], U, 4096336452);
      V = t(V, Y, X, W, C[P + 7], T, 1126891415);
      W = t(W, V, Y, X, C[P + 14], R, 2878612391);
      X = t(X, W, V, Y, C[P + 5], O, 4237533241);
      Y = t(Y, X, W, V, C[P + 12], U, 1700485571);
      V = t(V, Y, X, W, C[P + 3], T, 2399980690);
      W = t(W, V, Y, X, C[P + 10], R, 4293915773);
      X = t(X, W, V, Y, C[P + 1], O, 2240044497);
      Y = t(Y, X, W, V, C[P + 8], U, 1873313359);
      V = t(V, Y, X, W, C[P + 15], T, 4264355552);
      W = t(W, V, Y, X, C[P + 6], R, 2734768916);
      X = t(X, W, V, Y, C[P + 13], O, 1309151649);
      Y = t(Y, X, W, V, C[P + 4], U, 4149444226);
      V = t(V, Y, X, W, C[P + 11], T, 3174756917);
      W = t(W, V, Y, X, C[P + 2], R, 718787259);
      X = t(X, W, V, Y, C[P + 9], O, 3951481745);
      Y = K(Y, h);
      X = K(X, E);
      W = K(W, v);
      V = K(V, g);
    }
    const i = B(Y) + B(X) + B(W) + B(V);
    return i.toLowerCase();
  }
}
