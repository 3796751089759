import { createAction, props } from '@ngrx/store';
import { I_CashoutParams, I_MyBetsModel } from '../../models';

export const FetchMyBets = createAction(
  '[MyBets Page] Fetch MyBets',
  props<{ betType: string; date: string; endDate: string }>()
);
export const GetCashoutByTicket = createAction(
  '[MyBets Page] Get Cashout By Ticket',
  props<{ CashoutParams: I_CashoutParams }>()
);
export const SetMyBets = createAction('[MyBets Page] Set MyBets', props<{ myBetsTickets: I_MyBetsModel[] }>());
