import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';

@Injectable({ providedIn: 'root' })
export class CustomValidator {
  static pinNotConsecutiveNumbers(control: FormControl) {
    const pin = control.value;
    let result = { pinNotConsecutiveNumbers: { valid: false } };

    if (Math.abs(pin[0] - pin[1]) !== 1 || Math.abs(pin[1] - pin[2]) !== 1 || Math.abs(pin[2] - pin[3]) !== 1) {
      result = null;
    }
    return result;
  }
}
