<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start" *ngIf="hasBackButton">
      <ion-button class="sportsbook button-back" shape="round" (click)="navigate()"
        ><ion-icon slot="icon-only" name="chevron-back"></ion-icon
      ></ion-button>
    </ion-buttons>
    <ion-title>{{ title }}</ion-title>
    <ion-buttons slot="end">
      <ion-button fill="solid" class="sportsbook button-close" *ngIf="isMobile" (click)="close()">{{
        'close' | translate
      }}</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
