/* eslint-disable no-return-await */
import { CasinoService } from './casino.service';
import { Injectable, inject } from '@angular/core';
import { LobbyTrackingService, Params } from './lobbytracking.service';
import { Category, Filter, Game } from '../../models/lobby/casinoDataModels';
import { GlobalVarsModel } from '../../models/ModelGlobalsVar';
import { NewBaseService } from '../../providers/newBase.service';
import { BalancesService } from '../../providers/BalancesService';
import { Router } from '@angular/router';
import { EventsService } from '../../providers/EventsService';
import { AbstractLoobyFacadeService } from '../../abstracts/abstract-looby-facade-service';
import { GameService } from './game.service';
import { Observable } from 'rxjs';
import { CasinoFilter } from '@interfaces/casinoMoreFiltersInterfaces';
import { CASINO_PATHS } from '../../lib/constants/routes';

@Injectable({ providedIn: 'root' })
export class LobbyFacadeService implements AbstractLoobyFacadeService {
  saveOutGames;
  globalVars: GlobalVarsModel;

  casinoService = inject(CasinoService);
  gameService = inject(GameService);
  lobbyTrackingService = inject(LobbyTrackingService);
  events = inject(EventsService);
  balancesService = inject(BalancesService);
  newBaseService = inject(NewBaseService);
  router = inject(Router);

  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
  }

  async setLobbyData(lobby: string): Promise<void> {
    await this.casinoService.setLobbyData(lobby);
  }

  async setFilter(name: string, component: any): Promise<void> {
    this.casinoService.setFilter(name, component);
  }

  setCategory(name: string): void {
    this.casinoService.setCategory(name);
  }

  search(word: string): void {
    this.casinoService.search(word);
  }

  get filtersList() {
    return this.casinoService.filtersList;
  }

  get lobbyState(): Observable<Category | Category[] | Filter> {
    return this.casinoService.lobbyState;
  }

  get filterName$(): Observable<string> {
    return this.casinoService.filterName$;
  }

  getlobbyName(lobby) {
    return this.casinoService.getlobbyName(lobby);
  }

  getLobbyFromUrl(): any {
    return this.casinoService.getLobbyFromUrl();
  }

  getIsSingleLobby(lobby): boolean {
    return this.casinoService.getIsSingleLobby(lobby);
  }

  getIsOmnichannel(lobby): boolean {
    return this.casinoService.getIsOmnichannel(lobby);
  }

  async loadGameByURL(name: string): Promise<any> {
    return await this.casinoService.loadGameByURL(name);
  }

  async playGame(game): Promise<void> {
    this.gameService.playGame(game);
  }

  track(event: Game | string, params?: Params): void {
    this.lobbyTrackingService.track(event, params);
  }

  get slotsBanners(): any[] {
    return this.casinoService.slotsBanners;
  }

  checkBonusCasino(): void {
    this.casinoService.checkBalance();
  }

  getGames(): Promise<any> {
    return this.casinoService.getGames();
  }

  getInactiveGames(): Promise<any> {
    return this.casinoService.getInactiveGames();
  }

  getCurrentLobby(): string {
    return this.casinoService.getCurrentLobby();
  }

  getGamesFromOutCasino() {
    if (this.saveOutGames) {
      return this.saveOutGames;
    } else {
      const returnGames = this.casinoService.getGamesFromOutCasino();
      this.saveOutGames = returnGames;
      return this.saveOutGames;
    }
  }

  async playGameOutCasino(game): Promise<void> {
    this.subscribeEvents();
    this.playGame(game);
  }

  subscribeEvents() {
    //in case show game without login user
    this.events.subscribe('gameLoginSuccesful', () => {
      if (this.balancesService.inLobbyCasinoPage() === false) {
        const game: Game = this.globalVars.afterLoginParamsUrl;
        if (game) {
          this.playGame(game);
          this.globalVars.afterLoginParamsUrl = '';
        } else {
          this.globalVars.afterLoginParamsUrl = '';
        }
      }
    });
  }

  forceRPG(lobby): void {
    if (this.router.url.includes(CASINO_PATHS.BASE)) {
      this.casinoService.getRpg(lobby, false);
    }
  }

  get moreFiltersApplied(): Observable<CasinoFilter[]> {
    return this.casinoService.moreFiltersApplied;
  }

  public unapplyMoreFilters(): void {
    this.casinoService.unapplyMoreFilters(true);
  }

  public unapplyMoreFilter(casinoFilter: CasinoFilter) {
    this.casinoService.unapplyMoreFilter(casinoFilter);
  }

  public getPlatform(): string {
    return this.casinoService.getPlatform();
  }
}
