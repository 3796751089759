/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { NavController, MenuController, NavParams, IonicModule } from '@ionic/angular';
import { Router } from '@angular/router';
import { Component, ViewChild, inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { SlotsBonusesService } from '@providers/SlotsBonusesService';
import { LicenseTypes } from '@models/MasterData';
import { GlobalVarsModel } from '@models/ModelGlobalsVar';
import { UserService } from '@providers/UserService';
import { CodereFooterComponent } from '@shared-mso-components/footer/codere-footer/codere-footer';
import { TrackingInfo, BasePage } from '@shared-pages/base/BasePage';
import { Utils } from '@utils/Utils';
import { DeviceService } from '@services/device.service';

//eslint-disable-next-line
enum PostMessageActions {
  AvailableBonusRequest = 'AvailableBonusRequest',
  AvailableBonusResponse = 'AvailableBonusResponse',
  PromotionsOptIn = 'PromotionsOptIn',
  PromotionsOptOut = 'PromotionsOptOut'
}

@Component({
  standalone: true,
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'mvpcodere-page',
  templateUrl: './mvpcodere.html',
  styleUrls: ['./mvpcodere.scss'],
  imports: [CommonModule, FormsModule, IonicModule, CodereFooterComponent]
})
export class MvpCoderePage extends BasePage {
  url: any = null;
  scrHelp: any;
  promoListener: any;
  trackEvent: TrackEvent;
  @ViewChild('iframe') iframe;
  globalVars!: GlobalVarsModel;
  isMobile: boolean = false;

  utils = inject(Utils);
  nav = inject(NavController);
  router = inject(Router);
  menu = inject(MenuController);
  navParams = inject(NavParams);
  userService = inject(UserService);
  sanitizer = inject(DomSanitizer);
  slotsBonusesService = inject(SlotsBonusesService);
  deviceService = inject(DeviceService);

  constructor() {
    super();
    this.isMobile = this.deviceService.isMobile();
    this.globalVars.partner = 'mvp';
    if (this.navParams.get('url')) this.url = this.navParams.get('url');
    if (this.navParams.get('item')) {
      if (this.navParams.get('item').url) this.url = this.navParams.get('item').url;
    }
    if (this.url) {
      this.scrHelp = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
    } else if (this.globalVars.licenseType == LicenseTypes.Colombia) {
      this.updateMvpInfo();
    }
    this.promoListener = this.listenEvents.bind(this);
  }

  updateMvpInfo(): void {
    if (!this.globalVars.user.logged) {
      this.connectToMvp();
      return;
    }
    const token$ = this.userService.getMvpCodereToken();
    // in logged users, parameters will be ddefined
    token$.subscribe((result) => {
      this.globalVars.mvpData.token = result.token;
      this.connectToMvp();
    });
  }

  private connectToMvp(): void {
    let url = this.globalVars.FEATURES.MVPCodere_IframeURL; //"http://copa.qa.codere.s3-website-eu-west-1.amazonaws.com/"
    url += `?token=${this.globalVars.mvpData.token}&login=${this.globalVars.mvpData.login}&register=${this.globalVars.mvpData.register}&deposit=${this.globalVars.mvpData.deposit}`;
    console.info('Iframe decode url', decodeURI(url));
    this.scrHelp = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    // this.globalVars.partner = "";
    this.globalVars.mvpData = {
      token: '0',
      login: '0',
      register: '0',
      deposit: '0'
    };
  }

  getTrackingInfo(): TrackingInfo {
    return {
      uri: '/mvpcodere',
      description: 'MVP Codere page',
      additionalData: null
    };
  }

  ionViewWillEnter() {
    window.addEventListener('message', this.promoListener);

    this.utils.changePage('/', MvpCoderePage);
    this.utils.hideFormAccessoryBar(false);
  }

  ionViewDidLeave() {
    window.removeEventListener('message', this.promoListener);
    this.utils.hideFormAccessoryBar(true);
    this.slotsBonusesService.storeIforiumPromoCookie(); // #67436
  }

  openModalUser() {
    this.menu.open('right');
  }

  backToHome() {
    this.router.navigate(['/']);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  listenEvents() {}
}
