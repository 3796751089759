import { RedsysActionResult } from "@models/C_Transactions";
import { ErrorService } from "@providers/ErrorService";
import { UserService } from "@providers/UserService";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
/* eslint-disable */
import { PaymentMethodBehaviour, RedsysMethodBehaviourResult, SkipAmountResult } from "./PaymentMethodBase";

export class RedsysMethodBehaviour extends PaymentMethodBehaviour {
  name = "Bizum";
  image = "bizum_trans";
  track = "AccessToBizum";
  isBank = false;
  minDepositAmount = 10;
  maxDepositAmount = 100000;
  amountButtons: number[] = [10, 20, 40, 50];
  amount = 20;
  form = true;

  constructor(userService: UserService, errorService: ErrorService) {
    super(false, userService, errorService);
  }

  skipAmountInput(): Observable<SkipAmountResult> {
    return new Observable<SkipAmountResult>();
  }

  paymentMetodExecution(): Observable<RedsysMethodBehaviourResult> {
    return this.userService.validateDepositRedsysBizum("", this.amount, this.form, { form: this.form }).pipe(
      map((obj) => {
        return this.map(obj);
      }),
    );
  }

  private map(input: RedsysActionResult): RedsysMethodBehaviourResult {
    const result = new RedsysMethodBehaviourResult();
    if (input.success) {
      result.success = true;
      result.url = input.url; //'https://sis-t.redsys.es:25443/sis/realizarPago'
      result.ds_SignatureVersion = input.ds_SignatureVersion; //'HMAC_SHA256_V1';
      result.ds_MerchantParameters = input.ds_MerchantParameters; //'ewoJIkRTX01FUkNIQU5UX0FNT1VOVCI6IjE0NSIsCgkiRFNfTUVSQ0hBTlRfT1JERVIiOiIxNDQ2MDY4NTgxIiwKCSJEU19NRVJDSEFOVF9NRVJDSEFOVENPREUiOiI5OTkwMDg4ODEiLAoJIkRTX01FUkNIQU5UX0NVUlJFTkNZIjoiOTc4IiwKCSJEU19NRVJDSEFOVF9UUkFOU0FDVElPTlRZUEUiOiIwIiwKCSJEU19NRVJDSEFOVF9URVJNSU5BTCI6IjEiLAoJIkRTX01FUkNIQU5UX01FUkNIQU5UVVJMIjoiaHR0cDpcL1wvd3d3LnBydWViYS5jb21cL3VybE5vdGlmaWNhY2lvbi5waHAiLAoJIkRTX01FUkNIQU5UX1VSTE9LIjoiaHR0cDpcL1wvd3d3LnBydWViYS5jb21cL3VybE9LLnBocCIsCgkiRFNfTUVSQ0hBTlRfVVJMS08iOiJodHRwOlwvXC93d3cucHJ1ZWJhLmNvbVwvdXJsS08ucGhwIgp9IA=='
      result.ds_Signature = input.ds_Signature; //'PqV2+SF6asdasMjXasKJRTh3UIYya1hmU/igHkzhC+R='
    } else {
      result.success = false;
      result.erroMessage = this.errorService.searchErrorCodeWithParams(
        "error" + input.errCode,
        input.errorParameters,
      );
      result.errorcode = input.errCode;
      result.sendToDocumentVerificationPage = input.errCode === 174 || input.errCode === 2212;
      result.sendToProfesion = input.errCode === 2412;
    }
    return result;
  }
}
