/* eslint-disable eqeqeq */
import { CommonModule } from '@angular/common';
import { Component, OnChanges, Input, Output, EventEmitter, HostBinding, HostListener, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { filterItem } from '../../../models/sports/B_Filters.model';
import { ApplicableAccaBonus } from '../../../models/C_TicketApuestas';
import { ticketState } from '../../../models/sports/store/reducers/ticket.reducers';
import { NewTicketFacade } from '../../../models/sports/store/facades/ticket.facade';
import * as ticketSelector from '../../../models/sports/store/selectors/ticket.selectors';

@Component({
  selector: 'ticket-bonus',
  templateUrl: './ticket-bonus.html',
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule, TranslateModule],
  styleUrls: ['./ticket-bonus.scss']
})
export class TicketBonusComponent {
  bonusSub: Subscription;
  filterSub: Subscription;

  selectedFilter: filterItem;
  currentPercentage: number;

  newTicketFacade = inject(NewTicketFacade);
  store = inject(Store<ticketState>);

  constructor() {
    this.bonusSub = this.store
      .select(ticketSelector.getApplicableAccaBonus)
      .subscribe((applicableAccaBonus: ApplicableAccaBonus) => {
        this.currentPercentage =
          applicableAccaBonus.maxBonusRate > 0 ? Math.round(applicableAccaBonus.maxBonusRate * 100 - 100) : 0;
      });

    this.filterSub = this.store.select(ticketSelector.getFilters).subscribe((filters) => {
      this.selectedFilter = filters;
    });
  }

  @HostBinding('style.display')
  get display(): string {
    const mustShow = this.currentPercentage > 0 && this.selectedFilter.value == 1;
    return mustShow ? 'block' : 'none';
  }

  openBonus() {
    this.newTicketFacade.openBonus();
  }

  ngOnDestroy() {
    this.bonusSub.unsubscribe();
    this.filterSub.unsubscribe();
  }
}
