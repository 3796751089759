<ng-container *ngIf="globalVars.FEATURES.CAS_NewCalendar">
  <ng-container *ngIf="isDesktop; else mobileView">
    <ion-grid class="calendarNew-container">
      <ion-row class="calendarNew-card" deferLoad>
        <ion-col
          *ngFor="let game of section.games"
          class="calendarNew-card--container"
          [size]="game.gameTileSize === 'Rectangle' ? '6' : '3'"
        >
          <div class="calendarNew-card--gameCard" (click)="playGame(game)">
            <p class="calendarNew-card--gameCard--labelDate" *ngIf="!game.active">{{ game.label }}</p>
            <p class="calendarNew-card--gameCard--labelPlay" *ngIf="game.active">{{ game.label }}</p>
            <div class="calendarNew-card--gameCard--img">
              <ion-img
                class="casino-card--image"
                [src]="this.getImage(game)"
                [alt]="game?.Name"
                #cardNewImg
                (ionError)="errorImageHandler(cardNewImg, game)"
              />
            </div>
            <div class="calendarNew-card--gameCard--info" *ngIf="typeGame === lobbyConstants.slotsLobbyName">
              <p class="calendarNew-card--gameCard--info-name">{{ game?.Name | slotName }}</p>
              <ion-row
                class="calendarNew-card--gameCard--info-icons"
                [ngClass]="{ iconsMin: game.gameTileSize === 'Rectangle' }"
              >
                <ion-col>
                  <img class="codere-icon" src="assets/global/img/Lines.svg" />
                  <p class="calendarNew-card--gameCard--info-icons--txt">Layout</p>
                  <p class="calendarNew-card--gameCard--info-icons--txt2">
                    {{game.NumReels}} x {{game.NumRows}}
                  </p></ion-col
                >
                <ion-col>
                  <img class="codere-icon" src="assets/global/img/Volatility.svg" />
                  <p class="calendarNew-card--gameCard--info-icons--txt">{{ 'volatility' | translate }}</p>
                  <p class="calendarNew-card--gameCard--info-icons--txt2">{{game.Volatility}}</p></ion-col
                >
                <ion-col>
                  <img class="codere-icon" src="assets/global/img/MinimumBet.svg" />
                  <p class="calendarNew-card--gameCard--info-icons--txt">{{ 'minimumbet' | translate }}</p>
                  <p class="calendarNew-card--gameCard--info-icons--txt2">{{game.MinBet | parseMoney: [4]}}</p></ion-col
                >
              </ion-row>
            </div>
            <div class="calendarNew-card--gameCard--info" *ngIf="typeGame === lobbyConstants.casinoLobbyName">
              <p class="calendarNew-card--gameCard--info-name">{{ game?.Name | slotName }}</p>
              <ion-row
                class="calendarNew-card--gameCard--info-icons"
                [ngClass]="{ iconsMin: game.gameTileSize === 'Rectangle' }"
              >
                <ion-col
                  ><img class="codere-icon casinoType" [src]="'assets/global/img/casinoTypes/' + typeString +'.svg'" />
                  <p class="calendarNew-card--gameCard--info-icons--txt">Tipo</p>
                  <p class="calendarNew-card--gameCard--info-icons--txt2">{{typeString}}</p></ion-col
                >
                <ion-col
                  >
                  <img *ngIf="game.live" class="codere-icon live" src="assets/global/img/casinoTypes/LiveDealer.svg" />
                  <img *ngIf="!game.live" class="codere-icon live" src="assets/global/img/casinoTypes/RNG.svg" />
                  <p class="calendarNew-card--gameCard--info-icons--txt2">{{casinoType}}</p></ion-col
                >
                <ion-col>
                  <img class="codere-icon" src="assets/global/img/MinimumBet.svg" />
                  <p class="calendarNew-card--gameCard--info-icons--txt">{{ 'minimumbet' | translate }}</p>
                  <p class="calendarNew-card--gameCard--info-icons--txt2">{{game.MinBet | parseMoney: [4]}}</p></ion-col
                >
              </ion-row>
            </div>
            <div class="calendarNew-card--gameCard--hover" *ngIf="game.active">
              <h6 class="calendarNew-card--gameCard--hover-title color-light">
                {{ game?.Name | slotName | uppercase }}
              </h6>
              <div class="calendarNew-card--gameCard--hover-play-container">
                <img
                  class="calendarNew-card--gameCard--hover-play-container-image"
                  [src]="'assets/global/img/slots/icono_Play.svg'"
                />
              </div>
            </div>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ng-container>
  <ng-template #mobileView>
    <ion-grid class="calendarNew-container">
      <ion-row class="calendarNew-card" deferLoad>
        <ion-col
          *ngFor="let game of section.games"
          class="calendarNew-card--container"
          [size]="game.gameTileSize === 'Rectangle' ? '12' : '6'"
        >
          <div class="calendarNew-card--gameCard" (click)="playGame(game)">
            <p class="calendarNew-card--gameCard--labelDate" *ngIf="!game.active">{{ game.label }}</p>
            <p class="calendarNew-card--gameCard--labelPlay" *ngIf="game.active">{{ game.label }}</p>
            <div class="calendarNew-card--gameCard--img">
              <ion-img
                class="casino-card--image"
                [src]="this.getImage(game)"
                [alt]="game?.Name"
                #cardNewImg
                (ionError)="errorImageHandler(cardNewImg, game)"
              />
            </div>
            <div class="calendarNew-card--gameCard--info" *ngIf="typeGame === lobbyConstants.slotsLobbyName">
              <p class="calendarNew-card--gameCard--info-name">{{ game?.Name | slotName }}</p>
              <ion-row
                class="calendarNew-card--gameCard--info-icons"
                [ngClass]="{ iconsMin: game.gameTileSize === 'Rectangle' }"
              >
                <ion-col>
                  <img class="codere-icon" src="assets/global/img/Lines.svg" />
                  <p class="calendarNew-card--gameCard--info-icons--txt">Layout</p>
                  <p class="calendarNew-card--gameCard--info-icons--txt2">
                    {{game.NumReels}} x {{game.NumRows}}
                  </p></ion-col
                >
                <ion-col>
                  <img class="codere-icon" src="assets/global/img/Volatility.svg" />
                  <p class="calendarNew-card--gameCard--info-icons--txt">{{ 'volatility' | translate }}</p>
                  <p class="calendarNew-card--gameCard--info-icons--txt2">{{game.Volatility}}</p></ion-col
                >
                <ion-col>
                  <img class="codere-icon" src="assets/global/img/MinimumBet.svg" />
                  <p class="calendarNew-card--gameCard--info-icons--txt">{{ 'minimumbet' | translate }}</p>
                  <p class="calendarNew-card--gameCard--info-icons--txt2">{{game.MinBet | parseMoney: [4]}}</p></ion-col
                >
              </ion-row>
            </div>
            <div class="calendarNew-card--gameCard--info" *ngIf="typeGame === lobbyConstants.casinoLobbyName">
              <p class="calendarNew-card--gameCard--info-name">{{ game?.Name | slotName }}</p>
              <ion-row
                class="calendarNew-card--gameCard--info-icons"
                [ngClass]="{ iconsMin: game.gameTileSize === 'Rectangle' }"
              >
                <ion-col
                  >
                  <img class="codere-icon casinoType" [src]="'assets/global/img/casinoTypes/' + typeString +'.svg'" />
                  <p class="calendarNew-card--gameCard--info-icons--txt">Tipo</p>
                  <p class="calendarNew-card--gameCard--info-icons--txt2">{{typeString}}</p></ion-col
                >
                <ion-col
                  >
                  <img *ngIf="game.live" class="codere-icon live" src="assets/global/img/casinoTypes/LiveDealer.svg" />
                  <img *ngIf="!game.live" class="codere-icon live" src="assets/global/img/casinoTypes/RNG.svg" />
                  <p class="calendarNew-card--gameCard--info-icons--txt2">{{casinoType}}</p></ion-col
                >
                <ion-col>
                  <img class="codere-icon" src="assets/global/img/MinimumBet.svg" />
                  <p class="calendarNew-card--gameCard--info-icons--txt">{{ 'minimumbet' | translate }}</p>
                  <p class="calendarNew-card--gameCard--info-icons--txt2">{{game.MinBet | parseMoney: [4]}}</p></ion-col
                >
              </ion-row>
            </div>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ng-template>
</ng-container>

<ng-container *ngIf="!globalVars.FEATURES.CAS_NewCalendar">
  <ng-container *ngIf="isDesktop; else mobileView">
    <div class="calendar-container">
      <div class="calendar-title">
        <h6>{{section.title}}</h6>
      </div>
      <div class="calendar-card">
        <div *ngFor="let game of section.games" class="calendar-card--container">
          <casino-card-new
            tappable
            theme="calendar-card"
            class="calendar-card--img swiper-slide"
            [ngClass]="game.active ? 'activeImg' : 'inactive'"
            [game]="game"
            [grid]="true"
            [active]="game.active"
            [showGameTicker]="false"
            [isCalendar]="true"
            (turnPlay)="playGame(game)"
          >
          </casino-card-new>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #mobileView>
    <div class="calendarM-container">
      <div class="calendarM-title">
        <h6>{{section.title}}</h6>
      </div>
      <div class="calendarM-card">
        <div *ngFor="let game of section.games" class="calendarM-card--container">
          <casino-card-new
            tappable
            class="calendarM-card--img swiper-slide custom-item-mobile"
            [ngClass]="game.active ? 'activeImg' : 'inactive'"
            [game]="game"
            [grid]="true"
            [active]="game.active"
            [showGameTicker]="false"
            [isCalendar]="true"
            (turnPlay)="playGame(game)"
          >
          </casino-card-new>
        </div>
      </div>
    </div>
  </ng-template>
</ng-container>
