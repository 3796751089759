import { CommonModule } from "@angular/common";
/* eslint-disable eqeqeq */
import { Component, Input, type OnDestroy, type OnInit, inject } from "@angular/core";
import { LicenseTypes } from "@models/MasterData";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { NewBaseService } from "@providers/newBase.service";
import { Subject } from "rxjs";
import { Observable, Subscription } from "rxjs";
import { JackpotCounterItemComponent } from "./jackpot-counter-item/jackpot-counter-item";

@Component({
  selector: "lb-counter",
  imports: [CommonModule, JackpotCounterItemComponent],
  standalone: true,
  templateUrl: "./jackpot-counter.html",
  styleUrls: ["./jackpot-counter.scss"],
})
export class JackpotCounterComponent implements OnInit, OnDestroy {
  private mainAnimation = 0;

  globalVars!: GlobalVarsModel;
  @Input() curre: Observable<number>;
  @Input() title: string;
  @Input() titleSize = false;
  @Input() currentAmount$: Observable<number>;

  public currentNumber = 0;
  private _counterSub$ = new Subject();
  private first = true;
  public currentTicker = 0;
  public subscription: Subscription;
  isSpain = true;
  setAmount: any[] = [];

  newBaseService = inject(NewBaseService);
  constructor() {
    // * GlovalVars
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
  }

  ngOnInit(): void {
    this.subscription = this.currentAmount$.subscribe((number) => {
      if (this.first) {
        //this is the base for all animations
        this.currentNumber = number;
      }
      this.first = false;
      this.updateAmount(number);
    });
    switch (this.globalVars.licenseType) {
      case LicenseTypes.Mexico:
      case LicenseTypes.Colombia:
      case LicenseTypes.Panama:
        this.isSpain = false;
        break;
      default:
        this.isSpain = true;
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  //updateAmount(newTicker, currentTicker) {
  updateAmount(newTicker) {
    //Uncoment next line to not stop previous animation and wait until previous animation is finished
    //if(this.mainAnimation > 0) return;
    const timeTotal = 5000;
    const countStep = 500;
    const speed = timeTotal / countStep;
    const increment = newTicker - this.currentNumber;
    const initial = this.currentNumber;
    const step = increment / countStep;
    const sumCounter = 0;

    // Current Ticker 1.500,10
    const initInt = Math.floor(this.currentNumber); // => 1.500
    let initDec = (this.currentNumber - initInt) * 100; // => 10
    // New Ticker 1.750,90
    const finalInt = Math.floor(newTicker); // => 1.750
    const finalDec = (newTicker - finalInt) * 100; // => 90
    if (finalDec < initDec) {
      initDec = 0;
    }
    this.currentNumber = newTicker;

    let counter = 0;
    const step2 = 1 / 500;

    const updateCount = () => {
      counter += step2;
      const currentInt = initInt + (finalInt - initInt) * this.easeOutQuart(counter);
      const currentDec = initDec + (finalDec - initDec) * this.easeOutQuart(counter);
      this.setAmountFunction(currentInt + currentDec / 100);
      if (counter < 1) {
        clearTimeout(this.mainAnimation);
        setTimeout(() => {
          this.mainAnimation = window.setTimeout(updateCount, speed);
        }, 0);
      } else this.mainAnimation = 0;
    };
    updateCount();
  }

  easeOutQuart(x: number): number {
    const easeOutQuartLog = 1 - Math.pow(1 - x, 4);
    return easeOutQuartLog;
  }

  setAmountFunction(currentNumber) {
    this.setAmount = [];
    const toLocaleStringMx: string = currentNumber.toLocaleString("es-MX", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    const stringAmount: string[] = toLocaleStringMx.split("");
    if (toLocaleStringMx.length === 7) stringAmount.splice(1, 0, ",");

    stringAmount.map((el) => {
      if (el.indexOf(".") !== -1 || el.indexOf(",") !== -1) {
        this.setAmount.push({ item: el, isNumber: false });
      } else {
        this.setAmount.push({ item: el, isNumber: true });
      }
    });
  }
}
