import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';

import { NewBaseService } from './newBase.service';
import { GlobalVarsModel } from '@models/ModelGlobalsVar';
declare const BlueRibbon;

@Injectable({ providedIn: 'root' })
export class BlueRibbonService {
  NAMESPACE = '/JackpotServices.AppService/api/BlueRibbonJackpot/';
  SDK: any;
  potChanged$ = new BehaviorSubject({ jackpotState: null });
  stateChange$ = new BehaviorSubject(null);
  changeStateActive: boolean = false;
  _globals!: GlobalVarsModel;

  //#region Inject Service
  httpClient = inject(HttpClient);
  newBaseService = inject(NewBaseService);
  //#endregion
  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this._globals = data;
    });
  }

  getApiClientConfig() {
    const url = this._globals.DOMAIN + this.NAMESPACE + 'GetClientApiConfiguration';
    return this.httpClient.get<any>(url).pipe(map((res) => res.json()));
  }

  getPlayerJackpots() {
    if (!this._globals.user.username) {
      return of({ jackpotsIds: [] });
    }
    const url = this._globals.DOMAIN + this.NAMESPACE + 'GetPlayerJackpots?playerLogin=' + this._globals.user.username;

    return this.httpClient.get<any>(url).pipe(map((res) => res.json()));
  }

  optIn(user: string, gameId: string) {
    const url =
      this._globals.DOMAIN + this.NAMESPACE + `OptInPlayer?playerLogin=${user}&representativeGameId=${gameId}`;
    return this.httpClient.post<any>(url, null).pipe(map((res) => res.json()));
  }

  optOut(user: string, gameId: string) {
    const url =
      this._globals.DOMAIN + this.NAMESPACE + `OptOutPlayer?playerLogin=${user}&representativeGameId=${gameId}`;
    return this.httpClient.post<any>(url, null).pipe(map((res) => res.json()));
  }

  async getUserToken() {
    const url = this._globals.DOMAIN + this.NAMESPACE + `GetUserToken?playerLogin=${this._globals.user.username}`;
    const token = await lastValueFrom(this.httpClient.get<any>(url).pipe(map((res) => res.json())));
    return token;
  }

  async getGuestToken() {
    const url = this._globals.DOMAIN + this.NAMESPACE + 'GetAnonymousToken';
    const token = await lastValueFrom(this.httpClient.get<any>(url).pipe(map((res) => res.json())));
    return token;
  }

  initSDK() {
    if (this.SDK) return;

    this.getApiClientConfig().subscribe((response) => {
      const blueRibbonConfigWithOverlay = {
        operatorId: response.operatorId, //required
        loginAnonymousPlayer: () => this.getGuestToken(), //required
        loginAuthenticatedPlayer: () => this.getUserToken(), //required
        baseServiceUrl: response.baseServiceUrl //required
      };

      // Create new sdk instance
      this.SDK = new BlueRibbon.SdkCoreManager(blueRibbonConfigWithOverlay);
    });
  }

  connectSDK(games: any) {
    const gamesConfiguration = {
      games: games,
      gamesMode: BlueRibbon.constants.gamesMode.LOBBY // IN_GAME || LOBBY
    };

    const connectConfiguration = {
      playerId: this._globals.user.logged ? this._globals.user.username : undefined,
      currency: 'MXN'
    };

    this.SDK.connect(connectConfiguration)
      .then(() =>
        // Start BlueRibbon sdk
        this.SDK.startGamesFeed(gamesConfiguration)
          .then((result) => {})
          .then(() => {
            this.listenSDKEvents();
          })
      )
      .catch((err) => {
        // console.log('ERROR: non overlay sdk failed initialized');
        // console.error('ERROR: non overlay sdk failed initialized', err);
      });
  }

  disconnectSDK() {
    if (!this.SDK) return;
    // this.SDK
  }

  listenSDKEvents() {
    // Listen to events using sdk instance
    this.SDK.events.on(BlueRibbon.constants.events.WIN_EVENT, (data) => {
      // console.log('WIN_EVENT: ' + JSON.stringify(data));
      // Win occurred, do something with the winning details
    });

    this.SDK.events.on(BlueRibbon.constants.events.POT_STATE_CHANGED_EVENT, (data) => {
      this.potChanged$.next(data);
      // Jackpot pot state changed, do something with the ticker amount
    });
    this.SDK.events.on(BlueRibbon.constants.events.OPT_IN_EVENT, (data) => {
      this.changeStateActive = true;
      const response = {
        gameId: data.playerOptState.gameId,
        gameName: data.playerOptState.gameName,
        playerId: data.playerOptState.playerId,
        potState: true
      };
      // console.log('GAME WIDGET OPT_IN_EVENT: ' + JSON.stringify(data));
      this.stateChange$.next(response);
    });
    this.SDK.events.on(BlueRibbon.constants.events.OPT_OUT_EVENT, (data) => {
      this.changeStateActive = true;
      const response = {
        gameId: data.playerOptState.gameId,
        gameName: data.playerOptState.gameName,
        playerId: data.playerOptState.playerId,
        potState: false
      };
      // console.log('GAME WIDGET OPT_OUT_EVENT: ' + JSON.stringify(data));
      this.stateChange$.next(response);
    });
  }
}
