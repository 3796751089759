/** From the model with the same name in the backend
 * Reference: https://codere.visualstudio.com/cSBGOnline/_workitems/edit/62039
 */
export class SportsBalanceDetails {
  /** Currency code text (€,$...)*/
  CurrencyCode: string;

  /** (1) Saldo Sports: player total money available for Sports (ring fence + free bets */
  TotalBalance: number;

  /** (2) Withdrawable = external real balance. Translation: Retirable */
  WithdrawableBalance: number;

  /** (3) SportsBalance: player total money available for Sports
   * = (ring-fenced + bonus + pending winnings) = (ring-fenced + SaldoBonos)
   * Transalation: "Saldo casino"
   * */
  Freebets: number;
  SportsBalance: number;
}
