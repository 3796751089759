<section class="scroll-content" [class.registro-ios]="isIos">
  <cdr-info-top></cdr-info-top>
  <form class="form-registro" [formGroup]="formReg" (ngSubmit)="enviar()">
    <ng-container *ngIf="isMobile; then mobileTemplate; else deskTemplate"></ng-container>
  </form>
  <!-- Escritorio -->

  <ng-template #deskTemplate>
    <div>
      <!-- Taboola Pixel Code -->
      <div id="taboola"></div>
      <!-- End of Taboola Pixel Code -->
      <ion-row class="newrow">
        <ion-col col-4 class="border-right">
          <!--  Datos personales -->
          <cdr-fs-personal [formGrp]="formPers" [canShowSuportNumber]="canShowSuportNumber"></cdr-fs-personal>
        </ion-col>
        <ion-col col-4 class="border-right">
          <!--  Datos de contacto -->
          <cdr-fs-contacto [formGrp]="formCont" [isResident]="isResident$.asObservable()" />
          <!-- Datos de usuario -->
          <cdr-fs-usuario [formGrp]="formUsr"></cdr-fs-usuario>
        </ion-col>
        <ion-col col-4>
          <!-- Datos de confirmación -->
          <cdr-fs-confirmar [formGrp]="formConf"></cdr-fs-confirmar>
          <!-- Boton de envío -->
          <ion-button class="btn-register" type="submit" id="endRegister" expand="block">
            {{buttons.endRegister}}
          </ion-button>
        </ion-col>
      </ion-row>
    </div>
  </ng-template>
  <!-- Mobile -->
  <ng-template #mobileTemplate>
    <ion-tabs mode="md" (ionTabsDidChange)="onTabChange($event)">
      <ion-tab-bar slot="top">
        <ion-tab-button (click)="navigateToPaso('paso1')" [class.selected]="selectedTab === 'paso1'">
          <ion-label class="tab-label-text" [class.selected]="selectedTab === 'paso1'">PASO 1</ion-label>
          <ion-label class="tab-label-desc">Datos personales</ion-label>
        </ion-tab-button>
        <ion-tab-button
          [disabled]="!step1Valid"
          (click)="navigateToPaso('paso2')"
          [ngClass]="{'selected':selectedTab === 'paso2','disabled':!step1Valid}"
        >
          <ion-label class="tab-label-text" [ngClass]="{'selected':selectedTab ==='paso2','disabled':!step1Valid}"
            >PASO 2</ion-label
          >
          <ion-label class="tab-label-desc" [class.disabled]="!step1Valid">Crear cuenta</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ng-template>
</section>
