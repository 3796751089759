/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable, Pipe, PipeTransform, inject } from "@angular/core";
import { LicenseTypes } from "../models/MasterData";
import { GlobalVarsModel } from "../models/ModelGlobalsVar";
import { NewBaseService } from "../providers/newBase.service";

/**
 * pipe para parsear la moneda dependiendo del pais,
 * se usa de una manera global en el proyecto
 */
@Pipe({ name: "parseMoney", standalone: true })
@Injectable({ providedIn: "root" })
export class ParseMoney implements PipeTransform {
  globalVars!: GlobalVarsModel;
  newBaseService = inject(NewBaseService);
  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
  }

  /**
   *
   * @param t : la cantidad en formato string
   * @param args : formato
   */
  transform(t?: string | number, args: any[] = []): string {
    t = t + "";
    let format: number;
    if (args.length > 0) {
      format = parseInt(args[0]);
    } else {
      format = 0;
    }

    //format 0 -> devuelve N/A para el ticket cuando es 0 o negativo
    //format 1 -> devuelve el número formateado con la divisa, sea positivo o negativo
    //format 4 -> para slot de casino
    if (t === undefined || t === "undefined" || t === null) return "-";
    let intT: number = parseFloat(t.toString().replace(",", "."));

    if (format === 0) {
      if (intT <= 0) return "N/A";
    } else if (format === 1) {
      //money en slot puede ser negativo
    } else if (format === 4 || format === 5) {
      //money en slot puede ser negativo
      const minimumFractionDigits = format === 4 ? 2 : 0;
      let miresult: string;
      switch (this.globalVars.LICENSE_TYPE) {
        case LicenseTypes.Mexico:
        case LicenseTypes.Panama:
        case LicenseTypes.ArgentinaCaba:
        case LicenseTypes.ArgentinaMendoza:
          miresult = intT.toLocaleString("en", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: minimumFractionDigits,
          });
          break;
        case LicenseTypes.Colombia:
          miresult = intT.toLocaleString("es-CO", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 0,
          });
          miresult = miresult.replace("US", "").split(",")[0];
          break;
        default:
          miresult = intT.toLocaleString("de", {
            style: "currency",
            currency: "EUR",
            minimumFractionDigits: minimumFractionDigits,
          });
          break;
      }
      return miresult;
    }

    let result: string;

    //intT = Math.floor(intT * 100) / 100;
    intT = Math.floor(parseFloat((intT * 100).toFixed(2))) / 100;
    if ((intT * 100) % 100 === 0) {
      //si es resultado redondo, quitamos la parte flotante
      result = intT.toFixed();
    } else {
      if (this.globalVars.LICENSE_TYPE === LicenseTypes.Mexico) {
        result = intT.toFixed(2).replace(",", ".");
      } else if (
        this.globalVars.LICENSE_TYPE === LicenseTypes.ArgentinaCaba ||
        this.globalVars.LICENSE_TYPE === LicenseTypes.ArgentinaPBA
      ) {
        result = intT.toLocaleString("de-DE", { minimumFractionDigits: 2 });
      } else {
        result = intT.toFixed(2).replace(".", ",");
      }
    }
    if (this.globalVars.LICENSE_TYPE === LicenseTypes.Colombia) {
      let num = Math.floor(intT).toString();
      const car = num.length;
      if (num.length > 9) {
        num =
          num.substring(0, car - 9) +
          "." +
          num.substring(car - 9, car - 9 + 3) +
          "." +
          num.substring(car - 9 + 3, car - 9 + 3 + 3) +
          "." +
          num.substring(car - 9 + 3 + 3, car);
      } else if (num.length > 6) {
        num =
          num.substring(0, car - 6) +
          "." +
          num.substring(car - 6, car - 6 + 3) +
          "." +
          num.substring(car - 6 + 3, car);
      } else if (num.length > 3) {
        num = num.substring(0, car - 3) + "." + num.substring(car - 3, car);
      }
      result = num;
    }

    //adds the currency symbol
    if (result !== "NaN") {
      switch (this.globalVars.LICENSE_TYPE) {
        case LicenseTypes.ArgentinaCaba:
        case LicenseTypes.ArgentinaPBA:
        case LicenseTypes.ArgentinaMendoza:
        case LicenseTypes.Mexico:
          return "$" + result;
        case LicenseTypes.Colombia:
        case LicenseTypes.Panama:
          return result + "$";
        default:
          return result + "€";
      }
    }
    return "";
  }
}
