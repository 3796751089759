import { CommonModule, Location, ViewportScroller } from "@angular/common";
import {
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  ElementRef,
  Inject,
  Input,
  OnChanges,
  QueryList,
  ViewChild,
  ViewChildren,
  forwardRef,
  inject,
} from "@angular/core";
import { Router, RouterModule } from "@angular/router";
import { IonAccordionGroup, IonInput, IonicModule } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";

import { EventsService } from "@providers/EventsService";
import { SearchService } from "@providers/SearchService";
import { TrackingService } from "@providers/TrackingService";
import { NewBaseService } from "@providers/newBase.service";
import { CodereRouterService } from "@services/codere-router.service";
// Services
import { SportService } from "@sports-services/index";

import { GlobalVarsModel } from "@models/ModelGlobalsVar";

// Pipes
import { countryMarkerPipe } from "@sports-pipes/sports.country-marker.pipe";

// Routes
import { SPORTS_PATHS } from "@shared-constants/routes";

// Store

import { DeviceService } from "@services/device.service";
import { C_Event } from "@sports-models/index";
import { C_EventLive } from "@sports-models/index";
import { C_HighLight } from "@sports-models/index";
import { C_Horses } from "@sports-models/index";
import { C_League } from "@sports-models/index";
import { C_SearchResult } from "@sports-models/index";
import { C_SpecialSports } from "@sports-models/index";
import { C_Sport, C_SportSidebar } from "@sports-models/index";
import { SidebarFacade } from "@sports-stores/sidebar/index";
// Utils
import { SportHandleList } from "@sports-utils/index";
import { debounceTime, distinctUntilChanged } from "rxjs";

@Component({
  standalone: true,
  imports: [CommonModule, IonicModule, RouterModule, TranslateModule, countryMarkerPipe],
  selector: "codere-sidebar-pc",
  templateUrl: "./codere-sidebar-pc.html",
  styleUrls: ["./codere-sidebar-pc.scss"],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CodereSidebarPcComponent implements OnChanges {
  @ViewChild("SportsSidebar") SportsSidebar: IonAccordionGroup;
  @ViewChild("searchInput", { static: false }) searchInput: IonInput;
  //es necesario hacer input de las variables y métodos de la app.
  @Input() highlights: Array<C_HighLight>;
  @Input() highlightsConfig: Array<C_SpecialSports> = [];
  @Input() sports: any;
  league: any;
  highLightSports: any = [];

  globalMenuAnimated: boolean = false;
  specialGames: Array<any> = [];

  searchTermItems: any[] = [];
  searchTerm: any;

  sportsItems: C_SportSidebar[] = [];

  @ViewChildren("subMenu") arrSubMenu: QueryList<ElementRef>;

  globalVars!: GlobalVarsModel;

  isMobile: boolean;

  public router: Router = inject(Router);
  public sportService: SportService = inject(SportService);
  public events: EventsService = inject(EventsService);
  private newBaseService: NewBaseService = inject(NewBaseService);
  private codereRouterService: CodereRouterService = inject(CodereRouterService);
  private eventService = inject(EventsService);
  private location = inject(Location);
  public sidebarFacade = inject(SidebarFacade);
  public searchService = inject(SearchService);
  deviceService = inject(DeviceService);
  public scroller = inject(ViewportScroller);

  constructor(@Inject(forwardRef(() => TrackingService)) private trackingService: TrackingService) {
    this.isMobile = this.deviceService.isMobile();
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
    this.location.onUrlChange((url) => {
      const namePageUrl = url.split("?")[0].substring(2);
      if (!SPORTS_PATHS[namePageUrl]) {
        this.globalVars.LeagueSelected = "";
        this.globalVars.SportHandleSelected = "";
        this.sidebarFacade.selectedSport = null;
        this.globalVars.SportSelected = "";
      }
    });

    this.searchService.searchTerm
      .pipe(debounceTime(800), distinctUntilChanged())
      .subscribe((search) => this.search(search));
  }

  ngOnChanges() {
    this.sportsItems = this.sports;
    this.highLightSports = this.highlights;
    if (this.sidebarFacade.selectedSport) this.navigateToSport(this.sidebarFacade.selectedSport);
  }

  openMvpCodere() {
    this.codereRouterService.openMvpCodere();
  }

  openToday() {
    this.codereRouterService.openToday();
  }

  openWorldCupCalendar() {
    this.codereRouterService.openWorldCupCalendar();
  }

  toggleSport(sport: any) {
    let collapsed = true;
    if (sport.isCollapsible) {
      collapsed = this.isCollapsed(sport);
      if (!collapsed) return (this.sidebarFacade.selectedSport = null);
    } else {
      collapsed = true;
    }
    this.toggleSportTrackingService(sport, collapsed);
    this.globalVars.SportHandleSelected = sport.SportHandle;
    this.globalVars.SportSelected = sport.Name;
    this.sidebarFacade.selectedSport = sport;

    if (!sport.isCollapsible) {
      this.navigateToNoDropdownSports(sport);
    } else {
      this.getSportItems(sport);
    }
  }

  isCollapsed(sport) {
    switch (true) {
      case this.sidebarFacade.selectedSport === null:
      case this.sidebarFacade.selectedSport === undefined:
        return true;
      case sport.SportHandle === this.sidebarFacade.selectedSport.SportHandle:
        return false;
      case sport.SportHandle !== this.sidebarFacade.selectedSport.SportHandle:
        return true;
    }
    return;
  }

  getSportItems(sport) {
    this.sidebarFacade.fetchCountries(sport);
  }

  navigateToSport(sport) {
    if (sport.highlight.length > 0) {
      this.league = sport.highlight[0];
      this.router.navigate(["SportEventsPage"], {
        state: { params: sport },
        queryParams: { animate: !this.globalVars.isNative },
      });
    } else {
      if (sport.countries.data.length > 0) {
        if (sport.countries.data[0].Leagues.length > 0) {
          this.league = sport.countries.data[0].Leagues[0];
          this.league = {
            ...this.league,
            SportName: sport.Name,
          };
          this.navigate(this.league);

          this.trackingService.trackEvent([
            "AccesstoLeagueFromCountryItemsportMenu",
            this.league.Name,
            this.league.SportHandle,
            this.league.NodeId,
            "event",
          ]);
        }
      }
    }
    setTimeout(() => {
      this.scroller.scrollToAnchor(sport.SportHandle);
    }, 500);
  }

  navigateToNoDropdownSports(sport) {
    this.globalVars.SportSelected = sport.Name;
    switch (sport.SportHandle) {
      case SportHandleList.horse_racing:
      case SportHandleList.greyhound_racing:
        this.codereRouterService.navigateToRacePage(sport);
        break;
      default:
        this.codereRouterService.navigateToTopSportCountriesPage(sport);
        break;
    }
  }

  toggleSportTrackingService(sport, collapsed) {
    this.trackingService.trackEvent([
      collapsed ? "OpenItemSportMenu" : "CollapsedItemSportMenu",
      sport.SportHandle,
      this.globalVars.currentPage.name,
      "",
      "event",
    ]);
  }

  toggleSection(sport: C_Sport) {
    this.sidebarFacade.toggleSection(sport);
    this.trackingService.trackEvent([
      "ViewMoreItemSports",
      sport.countries.collapsed,
      this.globalVars.currentPage.name,
      "",
      "event",
    ]);
  }

  itemLeagueTapped(event: Event, league: C_League, sport: C_Sport, isHL: boolean = false): void {
    this.globalVars.SportSelected = sport.Name;
    this.globalVars.LeagueSelected = league.NodeId;
    this.league = league;

    if (this.league.SportHandle !== "greyhound_racing" && this.league.SportHandle !== "horse_racing") {
      this.navigate(this.league);
    } else {
      this.codereRouterService.navigateToRacePage(sport);
    }
    this.trackingService.trackEvent([
      isHL ? "AccesstoLeagueFromHLItemsportMenu" : "AccesstoLeagueFromCountryItemsportMenu",
      this.league.Name,
      this.league.SportHandle,
      this.league.NodeId,
      "event",
    ]);
  }

  async navigate(league: any) {
    let componentName: string = SPORTS_PATHS.EventoPage;
    let params = league;

    const leagueName = league.Name.toLowerCase();

    if (leagueName.search("anticipadas") >= 0 || leagueName.search("especiales") >= 0) {
      const data = await this.sportService.getEventsCountry(league.NodeId).toPromise();
      if (data.length === 1) {
        componentName = SPORTS_PATHS.MercadosPage;
        params = data[0];
      }
    }
    const hasBackButton: string = this.router.routerState.snapshot.url;
    this.eventService.sendCanGoBack(hasBackButton === "/HomePage" ? true : false);
    switch (componentName) {
      case SPORTS_PATHS.EventoPage:
        this.router.navigate([componentName], { state: { params: params, isLeftMenuAZ: true } });
        break;
      case SPORTS_PATHS.MercadosPage:
        this.router.navigate([componentName], { state: { params: params } });
        break;
    }
  }

  showSportHandleContent(sportHandle) {
    let showContent = true;

    switch (sportHandle) {
      case "soccer":
      case "tennis":
      case "basketball":
      case "olimpiadas":
      case "greyhound_racing":
      case "horse_racing":
        showContent = false;
        break;
    }
    return showContent;
  }

  getLeagueValue(sport) {
    return sport.highlight.length > 0 ? null : sport.countries.data[0];
  }

  // SEARCH
  searchOutOfFocus(ev) {
    this.searchIonInput(ev);
  }

  searchIonInput(ev) {
    if (ev?.detail?.value) {
      this.searchService.searchTerm.next(ev?.detail?.value);
    } else {
      this.searchService.searchTerm.next(ev?.detail?.value);
      this.clearSearchTerms();
    }
  }

  clearSearchTerms() {
    this.searchTermItems = [];
  }

  search(term: string) {
    if (term) {
      this.searchService.getResultSearch(term).subscribe((results: any) => {
        this.searchTermItems = results;
      });
    }
  }

  btItemSearch_Click($DOMevent, item: C_SearchResult) {
    this.clearSearchTerms();

    if (item.SportHandle === "greyhound_racing" || item.SportHandle === "horse_racing") {
      const objHorsesAux: C_Horses = new C_Horses(
        item.Name,
        item.NodeId,
        item.ParentNodeId,
        item.EventNodeTypeId,
        item.Priority,
        item.SportHandle,
        item.ChildrenCount,
        item.Country,
        false,
        0,
        item.PaddockId,
        item.StartDate,
      );
      this.router.navigate(["HorsesMarketPage"], { state: { params: objHorsesAux } });
    } else {
      if (item.isLive) {
        const event = C_EventLive.parse(item);
        const eventGameTypes = this.globalVars.gameTypes.getOne(
          event.SportHandle,
          event.CountryCode,
          event.isLive,
        );
        event.setGamesByPlatform(eventGameTypes, this.isMobile);
        this.router.navigate(["NowLiveDetailPage"], { state: { params: event } });
      } else {
        const event = C_Event.parse(item);
        const eventGameTypes = this.globalVars.gameTypes.getOne(
          event.SportHandle,
          event.CountryCode,
          event.isLive,
        );
        event.hasSpecialGame(this.globalVars.gameTypes.apuestas_especiales);
        event.setGamesByPlatform(eventGameTypes, this.isMobile);
        this.router.navigate(["MercadosPage"], { state: { params: event, isLeftMenuAZ: true } });
      }
    }
  }
}
