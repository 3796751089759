import { Component, Input, inject } from '@angular/core';
import { AnchorLink } from '../../../../models/footer/anchor-link';
import { FooterImage } from '../../../../models/footer/imagen';
import { CommonModule } from '@angular/common';
import { CodereFooterDownloadLinks } from '../codere-footer-downloadlinks/codere-footer-downloadlinks';
import { CodereFooterSocials } from '../codere-footer-socials/codere-footer-socials';
import { IonicModule } from '@ionic/angular';
import { DeviceService } from '../../../../services/device.service';
import { NewBaseService } from '../../../../providers/newBase.service';
import { GlobalVarsModel } from '../../../../models/ModelGlobalsVar';

@Component({
  standalone: true,
  imports: [CommonModule, CodereFooterDownloadLinks, CodereFooterSocials, IonicModule],
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'codere-footer-header',
  templateUrl: './codere-footer-header.html',
  styleUrls: ['./codere-footer-header.scss']
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class CodereFooterHeader {
  globalVars!: GlobalVarsModel;
  isDesktop: boolean = false;
  @Input() socialsLinks: AnchorLink[] = [];
  @Input() downloadLinks: AnchorLink[] = [];
  @Input() downloadLinksDivCss = '';
  @Input() headerImages: FooterImage[] = [];
  @Input() addViewMobile = false;
  @Input() divSocilasCss = 'iconreds';
  @Input() colombiaImagesHeader: FooterImage[] = [];
  @Input() colombiaImagesHeaderMobile: FooterImage[] = [];
  @Input() downloadLinksMobileColombia: AnchorLink[] = [];
  @Input() socialsLinksMobileColombia: AnchorLink[] = [];
  @Input() socialMobile = true;
  @Input() headersImgMobile = false;

  newBaseService = inject(NewBaseService);
  deviceService = inject(DeviceService);

  headerImage(imgAlt: string, imgClass: string) {
    if (this.globalVars.licenseType === 22) {
      if (!this.isDesktop) {
        if (imgAlt === 'RealMadrid') {
          return 'real-madrid-pa';
        } else {
          return '';
        }
      }
    }
    return imgClass;
  }

  constructor() {
    this.isDesktop = this.deviceService.isDesktop();
    this.newBaseService.getVars.subscribe((data) => {
      this.globalVars = data;
    });
  }
}
