import { C_LiveData } from './C_LiveData';

export class C_LiveDataBasketball extends C_LiveData {
  Quarters: Array<number>;
  override MatchTime: number;

  constructor(
    actions: Array<any>,
    matchTime: number,
    ParticipantAway: string,
    participantHome: string,
    period: number,
    periodName: string,
    quarters: Array<number>,
    resultAway: number,
    resultHome: number,
    time: string
  ) {
    super(actions, ParticipantAway, participantHome, period, periodName, resultAway, resultHome, time);
    this.Quarters = quarters;
    this.MatchTime = matchTime;
  }
}

//export =  C_LiveDataBasketball
