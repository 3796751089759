export class C_MyBetsFilterOption {
  value: string;
  text: string;
  selected?: boolean;
  constructor(text: string, val: string, selected?: boolean) {
    this.value = val;
    this.text = text;
    this.selected = selected;
  }
}
