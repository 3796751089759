<ion-row
  class="real-madrid-row"
  [class.co]="globalVars?.licenseType === 21"
  *ngIf="isDesktop || globalVars?.licenseType === 22"
  [ngClass]="{
    'footerHeaderRMPa' : globalVars.LICENSE_TYPE == 22,
    'footerHeaderRMAr': globalVars.LICENSE_TYPE == 33 || globalVars.LICENSE_TYPE == 34,
    'footerHeaderRMCo': globalVars.LICENSE_TYPE == 21
    }"
>
  <div *ngIf="downloadLinks.length > 0" [ngClass]="downloadLinksDivCss">
    <codere-footer-downloadlinks [downloadLinks]="downloadLinks"></codere-footer-downloadlinks>
  </div>
  <img *ngFor="let img of headerImages" [src]="img.src" [alt]="img.alt" [ngClass]="headerImage(img.alt, img.class)" />
  <div [ngClass]="divSocilasCss" *ngIf="socialsLinks.length > 0 && globalVars?.licenseType !== 22">
    <codere-footer-socials [socials]="socialsLinks"></codere-footer-socials>
  </div>
  <ng-container *ngIf="globalVars?.licenseType === 21">
    <ion-col size="12" class="displayf">
      <div
        *ngFor="let img of colombiaImagesHeader"
        [ngClass]="[img.divContainerCss, (globalVars.LICENSE_TYPE == 21 ? 'imgHeaderCo' : '')]"
      >
        <img
          [alt]="img.alt"
          [ngClass]="[(!isDesktop ? img.class: img.class.includes('crown') ? 'crown': ''), (img.alt.includes('Fantasia') ? 'fantasiaCo' : ''), (img.alt.includes('Mundo Casino') ? 'mundoCo' : '')]"
          [src]="img.src"
        />
      </div>
    </ion-col>
  </ng-container>
</ion-row>

<ion-row
  *ngIf="globalVars?.licenseType === 22"
  class="redsocial"
  [ngClass]="{
  'footerHeaderEs': globalVars.LICENSE_TYPE == 0,
  'footerHeaderCo': globalVars.LICENSE_TYPE == 21,
  'footerHeaderAr': globalVars.LICENSE_TYPE == 33 || globalVars.LICENSE_TYPE == 34,
  'footerHeaderredSocialPa': globalVars.LICENSE_TYPE == 22,
}"
>
  <codere-footer-socials [socials]="socialsLinks"></codere-footer-socials>
</ion-row>

<ng-container *ngIf="addViewMobile && !isDesktop">
  <ion-row class="download" *ngIf="globalVars?.licenseType === 0">
    <codere-footer-downloadlinks [downloadLinks]="downloadLinks" class="download"></codere-footer-downloadlinks>
  </ion-row>
  <ion-row class="redsocial" *ngIf="socialsLinks.length > 0 && globalVars?.licenseType !== 21 && this.socialMobile">
    <codere-footer-socials [socials]="socialsLinks"></codere-footer-socials>
  </ion-row>
  <ion-row
    *ngIf=" headerImages.length > 0 && globalVars?.licenseType !== 21 && this.headersImgMobile"
    class="real-madrid-row"
    [ngClass]="[globalVars.licenseType === 33 || globalVars.licenseType === 34 ? 'img-ar footerHeaderAr' : '']"
  >
    <img
      *ngFor="let img of headerImages"
      [src]="img.src"
      [alt]="img.alt"
      [ngClass]="globalVars.licenseType === 33 ? !isDesktop ? 'maxh84-mobile' : img.class : img.class"
    />
  </ion-row>
  <ng-container *ngIf="globalVars?.licenseType === 21">
    <ion-row>
      <div class="footericondeposit" [ngClass]="globalVars.licenseType === 21 ? 'depositCo' : ''">
        <div class="footericondeposit--container">
          <codere-footer-downloadlinks [downloadLinks]="downloadLinksMobileColombia"></codere-footer-downloadlinks>
          <codere-footer-socials [socials]="socialsLinksMobileColombia"></codere-footer-socials>
        </div>
        <div
          *ngFor="let img of colombiaImagesHeaderMobile"
          [ngClass]="[img.divContainerCss, (globalVars.licenseType === 21 ? 'img-ar footerHeaderCo' : '')]"
        >
          <img [alt]="img.alt" [ngClass]="img.class" class="img-container" [src]="img.src" [style]="img.styImg" />
        </div>
      </div>
    </ion-row>
  </ng-container>
</ng-container>
