/* eslint-disable */
import { Component, EventEmitter, OnInit, Output, inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { IonicModule, Platform } from '@ionic/angular';
import { GlobalVarsModel } from '@models/ModelGlobalsVar';
import { UserService } from '@providers/UserService';
import { UserServiceMSO } from '@providers/UserServiceMSO';
import { NewBaseService } from '@providers/newBase.service';
import { DeviceService } from '@services/device.service';
import { Router } from '@angular/router';
import { LicenseTypes } from '@models/MasterData';

@Component({
  selector: 'cdr-step-form-se',
  templateUrl: './step-form-se.html',
  styleUrls: ['./step-form-se.scss'],
  standalone: true,
  imports: [CommonModule, IonicModule, TranslateModule, ReactiveFormsModule]
})
export class StepFormSeComponent implements OnInit {
  @Output() changeStep: EventEmitter<number>;
  @Output() cityEmit: EventEmitter<string>;
  text: string;
  textButton0: string;
  textButton1: string;
  myForm: FormGroup;
  myFormbtn: FormGroup;
  city: string = '';
  resType: Array<{ label: string; value: number }>;
  departaments: Array<string>;
  datetime: Date;
  globalVars!: GlobalVarsModel;
  isDesktop: boolean = false;

  userService = inject(UserService);
  userServiceMSO = inject(UserServiceMSO);
  formbuilder = inject(FormBuilder);
  translate = inject(TranslateService);
  newBaseService = inject(NewBaseService);
  deviceService = inject(DeviceService);
  router = inject(Router);

  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
    this.isDesktop = this.deviceService.isDesktop();
    this.datetime = new Date();
    this.text = 'AutoExclusión - Step1';
    this.textButton0 = 'Anterior';
    this.textButton1 = 'Siguiente';
    this.myForm = this.formbuilder.group({
      name: [{ value: '', disabled: true }, Validators.required],
      surname: [{ value: '', disabled: true }, Validators.required],
      documentTypeCO: [{ value: null, disabled: true }, Validators.required],
      NumberDocument: [{ value: '', disabled: true }, Validators.required],
      address: [{ value: '', disabled: true }, Validators.required],
      Municipality: [{ value: null, disabled: true }],
      phone: [{ value: '', disabled: true }, Validators.required],
      email: [{ value: '', disabled: true }, Validators.required],
      Province: [{ value: null, disabled: true }]
    });

    if (this.globalVars.licenseType === LicenseTypes.Colombia) {
      this.myForm.get('Municipality').setValidators([Validators.required]);
    }
    if (this.globalVars.licenseType === LicenseTypes.Panama) {
      this.myForm.get('Province').setValidators([Validators.required]);
    }

    this.myFormbtn = this.formbuilder.group({
      toggleGamesInternet: [false, Validators.compose([Validators.required, Validators.requiredTrue])]
    });
    this.resType = [
      { label: this.translate.instant('rCoDocumentTypeCC'), value: 0 },
      { label: this.translate.instant('rCoDocumentTypeCE'), value: 1 }
    ];
    this.changeStep = new EventEmitter();
    this.cityEmit = new EventEmitter();
  }

  ngOnInit(): void {
    this.myFormbtn.get('toggleGamesInternet').valueChanges.subscribe((value) => {});
    this.userServiceMSO.getDepartamentsColombia().subscribe((data) => {
      this.departaments = [];
      if (this.globalVars.browser == 'internet') this.departaments.push(' ');
      for (var key in data.departement) {
        this.departaments.push(data.departement[key]);
      }
      //Ordena el array por orden alfabético
      this.departaments.sort();
      this.userService.getUserData().subscribe((data) => {
        this.myForm.get('name').setValue(data.Nombre);
        this.myForm.get('surname').setValue(data.PrimerApellido + ' ' + data.SegundoApellido || '');
        this.myForm
          .get('documentTypeCO')
          .setValue(
            data.TipoDocumento == 'ResidenteDNI'
              ? this.translate.instant('rCoDocumentTypeCC')
              : this.translate.instant('rCoDocumentTypeCE')
          );
        this.myForm.get('NumberDocument').setValue(data.NIF);
        this.myForm.get('address').setValue(data.Direccion);
        this.myForm.get('phone').setValue(data.NumMovil);
        this.myForm.get('email').setValue(data.Direccion_Correo);
        if (this.globalVars.licenseType === LicenseTypes.Colombia) {
          this.city = data.Municipio;
          this.myForm.get('Municipality').setValue(data.Municipio);
        }
        if (this.globalVars.licenseType === LicenseTypes.Panama) {
          this.city = data.Provincia;
          this.myForm.get('Province').setValue(data.Provincia);
        }
        this.cityEmit.emit(this.city);
      });
    });
  }

  goToStep(n) {
    this.changeStep.next(n);
  }
  backToHome() {
    this.router.navigate(['/'], { queryParams: { animate: false } });
  }
}
