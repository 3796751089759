/* eslint-disable eqeqeq */
import { IonicModule, NavController } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { UserService } from '../../../providers/UserService';
import { ErrorService } from '../../../providers/ErrorService';
import { CustomValidator } from '../../../providers/CustomValidators';

import { GlobalVarsModel } from '../../../models/ModelGlobalsVar';

import { MSO_PATHS } from '../../../lib/constants/routes';

import { NewBaseService } from '../../../providers/newBase.service';
import { DeviceService } from '../../../services/device.service';
import { UserSectionTitle } from '../../../components/mso/user-section-title/user-section-title';
import { CodereFooterComponent } from '../../../components/mso/footer/codere-footer/codere-footer';

import { Router } from '@angular/router';
import { Utils } from '../../../utils/Utils';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'my-card-pin-page',
  templateUrl: './my-card-pin.html',
  styleUrls: ['./my-card-pin.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule, IonicModule, CodereFooterComponent, UserSectionTitle, ReactiveFormsModule]
})
export class MyCardPinPage {
  myForm: FormGroup;

  globalVars!: GlobalVarsModel;
  isMobile: boolean;

  showPassword: { [key: string]: boolean } = {
    pin: false,
    newPin: false,
    reNewPin: false
  };

  nav = inject(NavController);
  utils = inject(Utils);
  translate = inject(TranslateService);
  newBaseService = inject(NewBaseService);
  fb = inject(FormBuilder);
  userService = inject(UserService);
  errorService = inject(ErrorService);
  device = inject(DeviceService);
  router = inject(Router);

  constructor() {
    this.isMobile = this.device.isMobile();
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });

    this.myForm = this.fb.group({
      pin: [
        '',
        Validators.compose([
          Validators.required,
          Validators.maxLength(4),
          Validators.minLength(4),
          Validators.pattern('[0-9]*')
        ])
      ],
      newPin: [
        '',
        [
          Validators.required,
          Validators.maxLength(4),
          Validators.minLength(4),
          Validators.pattern(/^(\d)(?!\1+$)\d{3}$/),
          CustomValidator.pinNotConsecutiveNumbers
        ]
      ],
      reNewPin: [
        '',
        [
          Validators.required,
          Validators.maxLength(4),
          Validators.minLength(4),
          Validators.pattern(/^(\d)(?!\1+$)\d{3}$/),
          CustomValidator.pinNotConsecutiveNumbers
        ]
      ]
    });
  }

  toggleTypeInput(field: string) {
    this.showPassword[field] = !this.showPassword[field];
  }

  sameNewPin(): boolean {
    if (this.myForm.controls['reNewPin'].value == this.myForm.controls['newPin'].value) {
      return true;
    } else {
      return false;
    }
  }

  sendPin() {
    if (this.myForm.valid) {
      if (this.sameNewPin()) {
        this.changePin(this.myForm.controls['pin'].value, this.myForm.controls['newPin'].value);
      } else {
        this.myForm.controls['reNewPin'].setErrors({ same: 'notValid' });
      }
    } else {
      for (const x in this.myForm.controls) {
        this.myForm.controls[x].markAsTouched();
      }
    }
  }

  changePin(oldPin: string, newPin: string) {
    const creed = { oldPin: oldPin, newPin: newPin };
    this.userService.setNewPin(creed).subscribe(
      (data) => {
        if (data.success) {
          this.utils.showMsg(this.translate.instant('GetCardPinOK'), () => {
            //redirect
            setTimeout(() => {
              this.nav.pop();
            }, 800);
          });
        } else {
          this.errorService.searchErrorCode(data.message).subscribe({
            next: (data2) => {
              const msgCode: string = data2;

              this.utils.showError(msgCode);
            },
            error: () => {
              this.utils.showError(this.translate.instant('GetCardtryingPinKO'));
            }
          });
        }
      },
      () => {
        this.utils.showError(this.translate.instant('m_menu_serverFail'));
      }
    );
  }

  ionViewWillEnter() {
    this.router.navigate([MSO_PATHS.BASE + '/' + MSO_PATHS.MyCardPinPage]);
  }
}
