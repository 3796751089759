/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input, OnInit, inject } from '@angular/core';
import { AnchorLink } from '../../../../models/footer/anchor-link';
import { CommonModule } from '@angular/common';
import { Capacitor } from '@capacitor/core';
import { Router } from '@angular/router';
import { DeviceService } from '../../../../services/device.service';
import { MSO_PATHS } from '../../../../lib/constants/routes';
import { GlobalVarsModel } from '../../../../models/ModelGlobalsVar';
import { NewBaseService } from '../../../../providers/newBase.service';

@Component({
  standalone: true,
  imports: [CommonModule],
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'codere-footer-downloadlinks',
  templateUrl: './codere-footer-downloadlinks.html',
  styleUrls: ['./codere-footer-downloadlinks.scss']
})
export class CodereFooterDownloadLinks implements OnInit {
  isNative: boolean;

  @Input()
  downloadLinks: AnchorLink[] = [];
  isDesktop = false;
  globalVars!: GlobalVarsModel;

  router = inject(Router);
  deviceService = inject(DeviceService);
  newBaseService = inject(NewBaseService);

  constructor() {
    this.isDesktop = this.deviceService.isDesktop();
    this.isNative = Capacitor.isNativePlatform();
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
  }
  ngOnInit(): void {
    const market = this.downloadLinks;
    for (const m of market) {
      if (m.class === 'marginr15 ios') {
        m.src = 'assets/global/img/FooterDescargaIos.png';
      }
      if (m.class === 'android-download') {
        m.src = 'assets/global/img/FooterDescargaAndroid.png';
      }
    }
  }

  DownloadApp($event: any) {
    this.router.navigate([`/${MSO_PATHS.BASE}/${MSO_PATHS.DownloadApp}`]);
    $event.preventDefault();
  }
}
