<section *ngIf="isMobile">
  <ion-segment
    [scrollable]="scrollable"
    class="sb-tabs-categories-segment"
    value="{{ selectedTab?.CategoryId }}"
    [ngClass]="color"
    select-on-focus="true"
    swipe-gesture="true"
  >
    <ion-segment-button
      class="sb-tabs-categories-segment--button"
      [ngClass]="{ 'short-button': smallButton, 'segment-reverse-flex': isMobile && categories.length > 3 }"
      *ngFor="let cat of categories; let index = index"
      [value]="cat.CategoryId"
      (click)="onClick(cat, index)"
    >
      <ion-label
        class="sb-tabs-categories-segment--label"
        *ngIf="cat?.CategoryId !== SpConstants.betbuilderCatId"
        [ngClass]="{ 'label-small-text': smallButton }"
      >
        {{ cat.name | translate | lowercase }}
      </ion-label>
      <ion-label
        class="sb-tabs-categories-segment--label betbuilder-capsule"
        *ngIf="cat?.CategoryId === SpConstants.betbuilderCatId"
        [ngClass]="{ 'label-small-text': smallButton }"
      >
        Crea tu apuesta
      </ion-label>
    </ion-segment-button>
  </ion-segment>
</section>

<section *ngIf="!isMobile" class="sb-tabs-categories-swiper">
  <swiper-container class="sb-tabs-categories-swiper--container" init="false" #tabsCategoriesSwiper>
    <swiper-slide
      class="sb-tabs-categories-swiper--slide"
      *ngFor="let swiperCat of swiperElements; let index = index"
      (click)="onClick(swiperCat, index)"
    >
      <ion-button
        class="sb-tabs-categories-swiper--button sportsbook"
        [ngClass]="{ selected: swiperCat?.CategoryId === selectedTab?.CategoryId }"
      >
        <ion-label
          class="sb-tabs-categories-swiper--label"
          *ngIf="swiperCat?.CategoryId !== SpConstants.betbuilderCatId"
          [ngClass]="{ 'label-small-text': smallButton }"
        >
          {{ swiperCat?.name | translate | lowercase }}
        </ion-label>
        <ion-label
          class="sb-tabs-categories-swiper--label betbuilder-capsule"
          *ngIf="swiperCat?.CategoryId === SpConstants.betbuilderCatId"
          [ngClass]="{ 'label-small-text': smallButton }"
        >
          {{ 'SPO_MatchaccaCategoryName' | translate | uppercase }}
        </ion-label>
      </ion-button>
    </swiper-slide>
  </swiper-container>
</section>
