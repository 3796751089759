/* eslint-disable eqeqeq */
import { Injectable } from '@angular/core';
import { C_Fastbet } from '@models/C_Fastbet';
import { C_EventLive } from '@models/C_EventLive';
import { C_GameGroupByName } from '@models/C_GameGroupByName';

@Injectable({ providedIn: 'root' })
export class FastBetProvider {
  fastBetGame: any;
  constructor() {}

  fastBetMarkets(event: C_EventLive, actualMarket: any): any[] {
    this.fastBetGame = {
      Point: this.getFastBetPoint(event),
      Game: this.getFastBetGame(event),
      Set: event.liveData.Sets.length
    };

    const fastBets = [];
    const fastGames = [this.setFastBetGame(event, this.fastBetGame), this.setFastbetPoint(event, this.fastBetGame)];
    fastGames.forEach((game, index) => {
      let fb: any;
      if (index % 2 === 0) {
        fb = new C_Fastbet(event.Name, event.NodeId, event.SportHandle, event.SportId, []);
        fastBets.push(fb);
      }
      const i = index <= 1 ? 0 : 1;
      if (!game) {
        return;
      }
      fastBets[i].Games.push(game);
    });
    return fastBets;
  }

  parseLiveDataGames(event: any) {
    event.Games.forEach((game: any) => {
      if (!game.Spov) {
        game.Fastbet = {};
        return;
      }
      const spov = game.Spov.split('|');
      game.Fastbet = {};
      game.name = 'FastBet';
      spov.forEach((s: any) => {
        const key = s.match(/[a-zA-Z]+/g);
        const result = s.match(/[0-9]+/g);
        game.Fastbet[key] = parseFloat(result[0]);
      });
    });
    return event;
  }

  getGridTitleHeader(bet: any) {
    const allSpovs = {
      tileCenter: '',
      titleRight: ''
    };
    bet.Games.forEach((game: any, index: any) => {
      if (index === 0) {
        allSpovs.tileCenter = game.AllSpov;
        return;
      }
      if (game.GameType !== 1636 && game.GameType !== 1972) {
        allSpovs.titleRight = '-';
      } else {
        allSpovs.titleRight = game.AllSpov;
      }
    });
    return allSpovs;
  }

  private setFastBetGame(event: any, fastbet: any) {
    let game = event.Games.find(
      (g: any) =>
        ((g.Fastbet.Set >= fastbet.Set && g.Fastbet.Game >= fastbet.Game) || g.Fastbet.Set > fastbet.Set) &&
        g.GameType === 1636 &&
        !g.Fastbet.Point &&
        g.GroupResults.length <= 2 &&
        !g.Locked
    );
    if (!game || !game.GroupResults.length) {
      game = this.getGameMissing(game);
      game.AllSpov = `-`;
      return game;
    }
    const { setTurn, gameTurn } = this.getMatchTurn(game);
    // game.AllSpov = `${gameTurn} ${setTurn}`;
    game.AllSpov = `${setTurn}`;
    return game;
  }

  private setFastbetPoint(event: any, fastbet: any) {
    let point = event.Games.find(
      (g: any) =>
        ((g.Fastbet.Set >= fastbet.Set && g.Fastbet.Game >= fastbet.Game) || g.Fastbet.Set > fastbet.Set) &&
        g.GameType === 1972 &&
        g.Fastbet.Point >= fastbet.Point &&
        g.GroupResults.length <= 2 &&
        !g.Locked
    );
    if (!point || !point.GroupResults.length) {
      point = this.getGameMissing(point);
      point.AllSpov = `-`;
      return point;
    }

    const { setTurn, gameTurn, pointTurn } = this.getMatchTurn(point);
    point.AllSpov = `${setTurn} ${gameTurn}`;
    return point;
  }
  private getMatchTurn(event: any) {
    if (!event.Name) {
      const setTurn: string = `Set ${this.fastBetGame.Set}`;
      const gameTurn: string = `Juego ${this.fastBetGame.Game}`;
      const pointTurn: string = `Punto ${this.fastBetGame.event}`;
      return { setTurn, gameTurn, pointTurn };
    }
    const rgNumTurns: RegExp = /[A-z]+ [0-9]+/gm;
    const turnsList: Array<any> = event.Name.match(rgNumTurns).map((l_: any) =>
      l_.replace(/\b\w/gm, (l: any) => l.toUpperCase())
    );
    const setTurn: string = turnsList[0];
    const gameTurn: string = turnsList[1];
    const pointTurn: string = turnsList[2];
    return { setTurn, gameTurn, pointTurn };
  }

  private getGameMissing(game: any) {
    game = C_GameGroupByName.parse({
      AllSpov: [],
      Name: '',
      nameLeague: '',
      Priority: '',
      CategoryInfos: {},
      CategoryInfo: {},
      DisplayTypeName: '3way',
      teamAway: 'Ganador',
      teamHome: 'Ganador',
      titleCenter: '',
      Locked: true,
      isSoftCombined: false,
      isHardCombined: false,
      isSpecialMarket: false,
      isCombined: true,
      headerClass: 'sb-grid-header--single',
      displayMode: 'single',
      GroupResults: [],
      combinedResults: null
    });
    return game;
  }
  private getFastBetGame(event: any): number {
    const currentSet = event.liveData.Sets.length;
    const game = event.liveData.Sets[currentSet - 1].reduce((prev: number, curr: number) => prev + curr);
    return game + 1; //TODO: accumulator should be 1 instead of add 1 on return ?
  }

  private getFastBetPoint(event: any) {
    const points = event.liveData.ResultAway + event.liveData.ResultHome;
    let point: number;
    switch (points) {
      case 0:
        point = 1;
        break;
      case 15:
        point = 2;
        break;
      case 30:
        point = 3;
        break;
      case 40:
      case 45:
        point = 4;
        break;
      case 55:
      case 60:
        point = 5;
        break;
      case 70:
        point = 6;
        break;
      case 80:
        point = 7;
        break;
      default:
        point = 0;
        break;
    }

    return point;
  }
}
