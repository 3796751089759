<ng-container [formGroup]="fgrp">
  <ion-item
    lines="none"
    [id]="fcName"
    [ngClass]="{
      'ion-item-focused': isFocused,
      'ion-item-green': !isFocused && fgrp.get(fcName).valid && fgrp.get(fcName).value,
      'ion-item-error': !isFocused && fControl?.invalid && fControl?.dirty
    }"
  >
    <div item-left *ngIf="preImg" class="div-flag"><img [src]="preImg" alt="Imagen" class="img-flag" /></div>
    <div item-left *ngIf="!preImg && placeholder.length > 0 && fcName === 'mobile'" class="div-flag">
      <div class="no-flag-c"></div>
    </div>
    <ion-input
      [placeholder]="placeholder"
      type="{{ isPasswd ? (showPassword ? 'text' : 'password') : type }}"
      [attr.data-length]="length"
      [maxlength]="length"
      [formControlName]="fcName"
      labelPlacement="{{ preImg || (placeholder.length > 0 && fcName === 'mobile') ? 'stacked' : 'floating' }}"
      label="{{ label }}"
      autocomplete="off"
      autoCorrect="off"
      spellcheck="off"
      class="input-element"
      (ionBlur)="onBlur()"
      (ionFocus)="onFocus()"
      [ngClass]="{
        'input-element-error': fControl?.invalid && fControl?.dirty,
        'input-label-flag': preImg || (!preImg && placeholder.length > 0 && fcName === 'mobile')
      }"
    >
    </ion-input>
    <ion-icon
      class="input-icon-eye"
      *ngIf="isPasswd"
      size="large"
      [name]="showPassword ? 'eye-off' : 'eye'"
      (click)="togglePassword()"
    />
    <cdr-info *ngIf="modal" class="input-icon" tabindex="-1" [target]="modal" [fcName]="fcName" [fgrp]="fgrp" />
  </ion-item>
</ng-container>
<cdr-control-error *ngIf="errorsCol" [fControl]="fControl" [errorsCol]="errorsCol" />
