<div class="cs-crown-casino p-1 crown-casino-bg">
  <div>
    <div class="header">Crown Casino</div>

    <div class="info">
      Para hacer un <span>{{transactionTypeLabel}}</span> mediante <strong>Casinos Crown</strong>, solo tienes que
      dirigirte a una de las siguientes sucursales que te facilitamos en este listado y solicitarlo de manera
      presencial.
    </div>
    <div class="subsidiary-list">
      <div class="subsidiary-address" *ngFor="let subsidiary of subsidiaries">
        <div class="address-header">
          <div class="subsidiary-name">{{subsidiary.name}}</div>
          <a href="{{subsidiary.map}}" target="_blank">Como llegar</a>
        </div>
        <div class="address">{{subsidiary.address}}</div>
      </div>
    </div>

    <div class="footer">
      Siguiendo las disposiciones de la Ley, tus retiros de premios por medio de los
      <strong>Crown Casinos</strong> acarrean una <strong>deducción del 5.5% de impuestos</strong>.
    </div>
  </div>
</div>
