import { Component } from '@angular/core';
import { IonicModule, ModalController } from '@ionic/angular';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'page-aviso',
  templateUrl: './aviso.html',
  standalone: true,
  styleUrls: ['./aviso.scss'],
  imports: [IonicModule]
})
export class AvisoPage {
  constructor(private modalControl: ModalController) {}

  closeModal() {
    this.modalControl.dismiss();
  }
}
