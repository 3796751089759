import { NgModule } from '@angular/core';
import { BetTypeNamePipe } from './betTypeName.pipe';
import { Bonus } from './bonus.pipe';
import { CategoryFilter } from './category-filter.pipe';
import { CountFromStartDatePipe } from './countFromStartDate.pipe';
import { DocStatusFilter } from './docStatus.pipe';
import { DocTypeFilter } from './docType.pipe';
import { ErrorMessagePBS } from './errorMessagePBS.pipe';
import { ExtractHourServerPipe } from './extractHourServer.pipe';
import { FilterTournamentPipe } from './filterTournament.pipe';
import { FormatMejora } from './formatMejora.pipe';
import { FormatNameResultPipe } from './formatNameResult.pipe';
import { FormatTax } from './formatTax.pipe';
import { LobbyFilter } from './lobby-filter';
import { countDownStartDate } from './countDownStartDate.pipe';
import { genericObjectFilter } from './genericObjectFilter.pipe';
import { removeTwoPoints } from './removeTwoPoints/removeTwoPoints.pipe';
import { ParseMoney } from './parseMoney';
import { ParseStakeMoney } from './parseStakeMoney';
import { ParseTransactionMoney } from './parseTransactionMoney.pipe';

@NgModule({
  declarations: [
    BetTypeNamePipe,
    Bonus,
    CategoryFilter,
    countDownStartDate,
    CountFromStartDatePipe,
    DocTypeFilter,
    ExtractHourServerPipe,
    FilterTournamentPipe,
    FormatMejora,
    FormatNameResultPipe,
    FormatTax,
    genericObjectFilter,
    DocStatusFilter,
    LobbyFilter,
    removeTwoPoints,
    ErrorMessagePBS
  ],
  imports: [],
  exports: [
    BetTypeNamePipe,
    Bonus,
    CategoryFilter,
    countDownStartDate,
    CountFromStartDatePipe,
    DocTypeFilter,
    ExtractHourServerPipe,
    FilterTournamentPipe,
    FormatMejora,
    FormatNameResultPipe,
    FormatTax,
    genericObjectFilter,
    DocStatusFilter,
    LobbyFilter,
    removeTwoPoints,
    ErrorMessagePBS
  ]
})
export class PipesModule {}
