import { Renderer2, QueryList } from '@angular/core';
import { Observable } from 'rxjs';

export class FilterBar {
  elements: any;
  filterWidth: number;
  container: HTMLElement;
  listContainer: QueryList<any>;
  line: HTMLElement;
  items: any[];

  constructor(
    private _renderer: Renderer2,
    line: HTMLElement
  ) {
    this.elements = {};
    this.line = line;
  }

  moveTo(index: number) {
    const item = this.elements[index];
    if (!item) return;

    this._renderer.setStyle(this.line, 'transform', `translateX(${item.element.offsetLeft}px)`);

    this._renderer.setStyle(this.line, 'width', item.rect.width + 'px');
  }

  setElements(list: HTMLElement) {
    if (!list) return;

    for (let i = 0; i < list.children.length; i++) {
      this.elements[i] = {
        element: list.children[i],
        rect: list.children[i].getBoundingClientRect()
      };
    }
  }

  get listChanges$(): Observable<any> {
    if (!this.listContainer) return;
    return this.listContainer.changes;
  }
}
