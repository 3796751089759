// eslint-disable-next-line @typescript-eslint/no-empty-function

import { Injectable, inject } from "@angular/core";
import { Capacitor } from "@capacitor/core";
import { Device } from "@capacitor/device";
import { Platform } from "@ionic/angular";

@Injectable({ providedIn: "root" })
export class DeviceService {
  platform = inject(Platform);
  toMatchMobile = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];
  toMatchIos = [/iPhone/i, /iPad/i, /iPod/i];

  isDesktop(): boolean {
    return !this.isMobile();
  }

  isMobile(): boolean {
    return this.toMatchMobile.some((toMatchItem) => navigator.userAgent.match(toMatchItem));
  }

  // BAUPAY-4434: Los dejamos aquí por si pueden ser útiles en algún momento del futuro.
  isIos(): boolean {
    return this.toMatchIos.some((toMatchApplePlatform) => navigator.userAgent.match(toMatchApplePlatform));
  }

  isCapacitorIos(): boolean {
    const plat = Capacitor.getPlatform();
    if (plat === "ios") {
      return true;
    } else {
      return false;
    }
  }

  // BAUPAY-4434: Este es el método bueno para saber si estamos en un ios
  async isRealIOSDevice(): Promise<boolean> {
    const info = await Device.getInfo();
    console.log(info);

    if (info.platform === "ios") {
      console.log("Running on iOS");
      return true;
    } else {
      console.log("Not running on iOS");
      return false;
    }
  }

  getDevicePlatform = (): string => (Capacitor.getPlatform() === "web" ? "Desktop" : "Mobile");
}
