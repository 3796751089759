<div class="alert-wrapper background-color-white">
  <div class="text-left margin-20 title">
    <span>{{ title }}</span>
  </div>
  <div class="text-center margin-top-10 subtitle">
    <span>{{ subtitle }}</span>
  </div>

  <div class="btn-row">
    <div>
      <button class="btn-cancel" (click)="cancel()">{{ cancelButtonText }}</button>
    </div>
    <div>
      <button class="btn-verify" (click)="accept()">{{ acceptButtonText }}</button>
    </div>
  </div>
</div>
