import { C_Sport } from './C_Sport';
import { C_EventLive } from './C_EventLive';
import { SidebarUtils } from './sports/store/storeUtils/sidebar.utils';

export class C_SportsLive extends C_Sport {
  Events: Array<C_EventLive>;
  SportOrder: number;
  SportId: string;
  override Name: string;
  override NodeId: string;
  override ParentNodeId: string;
  override Priority: number;
  override SportHandle: string;
  override Locked: boolean;
  Collapsed: boolean;
  scoreboardType?: string;
  RealName: string;
  marketNames: string[];

  constructor(
    events?: Array<any>,
    sportOrder?: number,
    sportId?: string,
    name?: string,
    nodeId?: string,
    parentNodeId?: string,
    priority?: number,
    sporthandle?: string,
    locked?: boolean,
    realName?: string,
    marketNames?: string[]
  ) {
    super(false, locked, name, nodeId, parentNodeId, priority, sporthandle, SidebarUtils.showDropdownIcon(sporthandle));
    this.Events = events;
    this.SportOrder = sportOrder;
    this.SportId = sportId;
    this.Collapsed = true;
    this.scoreboardType = '';
    this.RealName = realName;
    this.marketNames = marketNames;
  }

  static parse(obj: any): C_SportsLive {
    return Object.assign(new C_SportsLive(), obj);
  }

  public setNameOfLiveEvents(events) {
    events.map((event) => {
      [event.teamHome, event.teamAway] = event.Name.split(' - ');
    });
  }

  public setScoreboardType(isLive: boolean) {
    if (!isLive) {
      return '';
    }

    switch (this.SportHandle) {
      case 'soccer':
      case 'handball':
      case 'snooker':
      case 'esports':
      case 'baseball':
      case 'basketball':
      case 'ice_hockey':
        this.scoreboardType = 'regular';
        break;
      case 'beach_volleyball':
      case 'volleyball':
      case 'tennis':
      case 'badminton':
      case 'table_tennis':
        this.scoreboardType = 'sets';
        break;
      default:
        this.scoreboardType = '';
        break;
    }
  }
}
