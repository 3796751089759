import { BSfreeBetVouchers, C_FreeBetTicket, FreebetInstance } from "@sports-models/index";

export const newFreebetInstance = (): FreebetInstance => ({
  Amount: 0,
  BonusOfferId: 0,
  Conditions: [],
  ExpirationDate: "",
  IsRedeemable: false,
  VoucherCode: "",
  Name: "",
  Validation: {
    systems: true,
    singles: true,
  },
});

export const mapFreebetInstance = (FBData: C_FreeBetTicket, betSlipFB: BSfreeBetVouchers): FreebetInstance => ({
  Amount: FBData.Amount,
  BonusOfferId: FBData.BonusOfferId,
  Conditions: [],
  ExpirationDate: FBData.ExpirationDate,
  IsRedeemable: FBData.IsRedeemable,
  Name: FBData.Name,
  VoucherCode: FBData.VoucherCode,
  Validation: {
    singles: betSlipFB.validation.singles[0].vouchers[0].validationCode !== "NotYetValidated",
    systems:
      betSlipFB.validation.systems[betSlipFB.validation.systems.length - 1].vouchers[0].validationCode !==
      "NotYetValidated",
  },
});
