import { C_OveraskData, C_TicketEnumMode, FreeBetCondition, LineItem, SmartLineItem } from "../index";

/*
enum enum_PipelineExecutionResult { Success = 0, Warning = 1, Failure = 2 }
enum enum_BetType { Simple = 0, Accumulated = 1, Multiple = 2, MultipleChoice = 3 }
enum enum_cdr_bettingactivity_cdr_tickettype { Caballos = 754310000, Deportes = 754310001, Galgos = 754310002 }
enum enum_cdr_bonus_cdr_bonustype { BonusAmount = 754310000, DepositRatio = 754310001, BetRatio = 754310002 }
enum enum_FreebetConditionType { MaxOdds = 0, MinOdds = 1, MinLegs = 2 }
enum enum_Place { AnyOrder = -1, NotDefined = 0, Place1 = 1, Place2 = 2, Place3 = 3 }
enum enum_LineItemStatus { Ok = 0, PendingRemove = 1 }
enum enum_GreyhoundHorseBetStatus { Ok = 0, PendingRemove = 1 }
enum enum_GreyhoundHorseExecutionResult { Success = 0, Warning = 1, Failure = 2 }
*/

export interface ApplicableBonus {
  BonusId: string; //guid
  /// <summary>
  /// Id of the relation between the bonus and the user, when the bonus can be applied to the user
  /// </summary>
  PlayerBonusId: string; //guid
  Applied: boolean;
  BonusDescription: string;
  AppliedAmount: number; //decimal
  PendingAmount: number; //decimal
  //this is the id of the transaction to be used to rollback/confirm bonus transaction when bet is failed/completed
  BonusTransactionId: string; //es un guid
  Priority: number;
  BonusType: number; //guid
}

export interface GreyhoundHorseBet {
  Amount?: number;
  AmountLocal?: number;
  BetType?: any;
  Date?: string; //
  downOdd: boolean;
  GameId?: string;
  GameLocked?: boolean;
  GreyhoundHorseBetType?: number; //es un enum bettype
  Handicap?: boolean;
  Id?: string;
  Index?: number;
  IsBet?: boolean;
  IsSelected?: boolean;
  ItemId?: number;
  LineErrorMessages?: string[];
  LineErrors: string; // corregir TODO
  NotAvailable?: boolean;
  Ordered?: boolean;
  Odd: number; //decimal
  Paddock?: string;
  Participants?: GreyhoundParticipant[];
  PendingCheck?: boolean;
  PipelineExecutionResult?: number; // enum_GreyhoundHorseExecutionResult
  ResultsNr: string; // corregir TODO 'codigo BAU'
  Selected: boolean;
  SportHandle?: string;
  Status?: number; //enum_GreyhoundHorseBetStatus { get; set; }
  Tax?: number;
  TicketNumber?: string;
  TotalPotentialWin?: any;
  upOdd: boolean;
}

export interface FreebetInstance {
  Amount: number; //decimal
  BonusOfferId: number; //decimal { get; set; }
  Conditions: FreeBetCondition[];
  ExpirationDate: any;
  IsRedeemable: boolean;
  VoucherCode: string;
  Name: string;
  Validation: {
    systems: boolean;
    singles: boolean;
  };
}

export interface GreyhoundParticipant {
  OpenPrice: boolean;
  Id: string;
  Place: number; //es un enum
  Name: string;
}

export interface ApplicableAccaBonus {
  BonusId: number;
  maxBonusRate: number;
  BonusAmount: number;
}

export interface StakeGroups {
  Singles: {
    totalAmount?: number;
    totalPotentialWin?: number;
  };
  Accumulator: {
    totalAmount?: number;
    totalPotentialWin?: number;
    systemId?: number;
    bonus?: any;
  };
  System: {
    totalAmount?: number;
    totalPotentialWin?: number;
    systemId?: number;
    possibleSystems?: systemData[];
    bonus?: any;
    selectedGroup?: string;
  };
}

export interface systemData {
  canBeEachWay: boolean;
  numberOfBetWays: number;
  numberOfEachWayBetWays: number;
  systemId: number;
  systemType: string;
  text?: string;
  groupName: string;
}

export interface C_TicketApuestasNew {
  // MultipleChoice: boolean;
  //NBN - new betslip needed
  //TicketTree: SBSBetTicketTO; //siempre viene a null y hacer la estructura de clases que lo componen es muy laborioso. De momento no lo voy a usar a ver si depsues hace falta.
  ApplicableAccaBonus: ApplicableAccaBonus;
  BetType: number; //NBN - new betslip needed
  BonusChecked: any; //es un datetime { get; set; }  //NBN - new betslip needed
  Bonuses?: Array<ApplicableBonus>;
  CombiCount?: number;
  CombinationMulticast: GreyhoundHorseBet[]; // LineItem | GreyhoundHorseBet  //NBN - new betslip needed
  Combinations: number; //NBN - new betslip needed
  Created: any; //es un datetime { get; set; }  //NBN - new betslip needed
  Default: boolean;
  FreebetInstance?: FreebetInstance;
  FreeBetVoucherId?: string;
  GamesEvent?: boolean;
  hasChangesInOdds: boolean;
  HasPrice: boolean;
  isAcceptBettingOdds?: boolean;
  isPlacingbet?: boolean; //NBN - new betslip needed
  keepSelection: boolean; //NBN - new betslip needed
  LanguageCode: string;
  LineItems: Array<LineItem>; //NBN - new betslip needed
  MaxCombinations?: number;
  MaxMult?: number; //es decimal { get; set; }
  mode: C_TicketEnumMode; //NBN - new betslip needed
  MultisingleError?: string;
  NumberOfBanks?: number;
  NumberOfResults?: number;
  Odd: number;
  Opened: boolean; //NBN - new betslip needed
  OrderBonusMessages: Array<string>; //NBN - new betslip needed
  OrderErrorMessages: Array<string>; //NBN - new betslip needed
  OrderErrors: any;
  OrderFormId?: string; //es un GUID
  OveraskInfo?: C_OveraskData;
  OveraskCloseBet?: any;
  PipelineExecutionResult?: number;
  processing?: boolean; //NBN - new betslip needed
  SmartLineItems: Array<SmartLineItem>; //NBN - new betslip needed
  StakeGroups: StakeGroups; //NBN - new betslip needed
  StraightMulticast: GreyhoundHorseBet[]; // LineItem | GreyhoundHorseBet  //NBN - new betslip needed
  SystemSelected: systemData; //NBN - new betslip needed
  SystemType: number; //NBN - new betslip needed
  Tax: number;
  TicketNumber: string; //NBN - new betslip needed
  TicketType?: number;
  Total: number; //es un decimal  //NBN - new betslip needed
  TotalBets: number;
  TotalLocal: string;
  TotalPotentialWin?: number; //es decimal  //NBN - new betslip needed
  TotalPotentialWinWithoutBoost?: number; // es decimal
  SingleStakeLimits?: number;
  SystemStakeLimits?: number;
}
