<!-- eslint-disable @angular-eslint/template/eqeqeq -->
<div class="container-selfie-caba">
  <div class="bg-transparent" (click)="closeModal()"></div>
  <cs-document-indications-camera
    (onPhotoPicked)="cameraPhotoPicked($event)"
    (onWebCamPickerDismissed)="webCamPickerDismissed()"
    *ngIf="activeCamera"
  ></cs-document-indications-camera>
  <ng-container *ngIf="!activeCamera">
    <div class="content">
      <!-- Argentina (CABA + PBA + Mendoza) -->
      <ng-container *ngIf="this.utils.isArgentina(); else otherRegion">
        <h2>{{ textTitle }}<span class="text-bold">{{ textTitle02 }}</span></h2>
        <p [className]="stepInstructions === 3 ? 'with-picture' : 'without-picture'"></p>
        <p>{{ textParagraph }}<span class="text-bold">{{ textParagraph02 }}</span></p>
      </ng-container>
      <ng-template #otherRegion>
        <h2>{{ textTitle }}</h2>
        <p [className]="stepInstructions === 3 ? 'with-picture' : 'without-picture'">{{ textParagraph }}</p>
        -->
      </ng-template>
      <div class="buttons-photo">
        <button
          class="cs-action-button background-color-primary pictures-caba-buttons"
          tappable
          (click)="accessFiles()"
        >
          <img src="./assets/global/img/upload-white-icon.png" />
          Subir Archivo
        </button>
        <button
          class="cs-action-button background-color-primary pictures-caba-buttons"
          tappable
          (click)="accessCamera()"
        >
          <img src="./assets/global/img/camera-caba.png" />
          Hacer Foto
        </button>
      </div>
    </div>
  </ng-container>
</div>
