import { Component, Input } from '@angular/core';

@Component({
  selector: 'lb-jackpot-header',
  templateUrl: './jackpot-header.html',
  standalone: true,
  styleUrls: ['./jackpot-header.scss'],
  host: {
    class: 'lb-jackpot-header'
  }
})
export class JackpotHeaderComponent {
  @Input() headerImage: string;
}
