import { PopoverController } from '@ionic/angular';
import { inject, Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';

import { BaseService } from './BaseService';
import { SlotsBonusesService } from './SlotsBonusesService';
import { UserService } from './UserService';

import { CasinoBalanceDetails } from '../models/csbgonlinecasinobonuses/Balance';
import { LicenseTypes } from '../models/MasterData';

import { BalanceDetail } from '../components/common/balance-detail/balance-detail';
import { Router } from '@angular/router';
import { SportsBalanceDetails } from '../models/C_SportsBalance';

import { CASINO_PATHS } from '../lib/constants/routes';
import { Constants } from '../constants';

@Injectable({ providedIn: 'root' })
export class BalancesService extends BaseService {
  details$ = new BehaviorSubject<null | CasinoBalanceDetails | SportsBalanceDetails>(null);
  errorList: Array<{ code: string; label: string }>;
  private popoverName = new BehaviorSubject<string>('caret-down-outline');
  //#region Inject Services
  slotsBonusesService = inject(SlotsBonusesService);
  userService = inject(UserService);
  router = inject(Router);
  //#endregion
  constructor(public popoverController: PopoverController) {
    super();
    this.errorList = [];
  }

  inCasino(): boolean {
    const single = 'singleGame=true';
    const isSingle = this.router.url.includes(single);
    const isCasino =
      this.router.url.includes(CASINO_PATHS.CasinoPage) &&
      !isSingle &&
      (this.globalVars.licenseType === LicenseTypes.Nacional ||
        this.globalVars.licenseType === LicenseTypes.Colombia ||
        this.globalVars.licenseType === LicenseTypes.Mexico ||
        this.globalVars.licenseType === LicenseTypes.ArgentinaCaba);
    return isCasino;
  }

  inJackpot(): boolean {
    const isJackpot =
      this.router.url.includes(CASINO_PATHS.JackpotPage) &&
      (this.globalVars.licenseType === LicenseTypes.Nacional ||
        this.globalVars.licenseType === LicenseTypes.Colombia ||
        this.globalVars.licenseType === LicenseTypes.Mexico);
    return isJackpot;
  }

  inLobbyCasinoPage(): boolean {
    const arrayPaths = [CASINO_PATHS.CasinoPage];
    return arrayPaths.some((ele) => this.router.url.includes(ele));
  }

  inSlotsBonus(): boolean {
    const inSlotsBonus =
      this.router.url.includes(CASINO_PATHS.SlotsBonusPage) &&
      (this.globalVars.licenseType === LicenseTypes.Nacional ||
        this.globalVars.licenseType === LicenseTypes.Colombia ||
        this.globalVars.licenseType === LicenseTypes.Mexico ||
        this.globalVars.licenseType === LicenseTypes.Panama);
    return inSlotsBonus;
  }

  inCasinoPanama(): boolean {
    const inCasinoPanama =
      this.router.url.includes(CASINO_PATHS.CasinoPage) && this.globalVars.licenseType === LicenseTypes.Panama;
    return inCasinoPanama;
  }

  getIsSingleLobby(lobby) {
    const lobbyC = this.globalVars.FEATURES.Lobbies.find((lb) => lb.lobbyUrl + 'Page' === lobby);
    return lobbyC === undefined ? false : lobbyC.SingleGameLobby ? true : false;
  }

  getPopoverName(): Observable<string> {
    return this.popoverName.asObservable();
  }

  /**
   * Show balance details on screen, near the header total balance, only if the user is in Casino
   * @param event includes the position where the user clicked, which is used to set
   * the popup position
   */

  async showBalanceDetails(event: any, popoverController: PopoverController) {
    let isCasino = false;
    let isPanama = false;
    let balanceDetails$;
    this.popoverController = popoverController;

    if (this.inCasino() || this.inJackpot() || this.inSlotsBonus()) {
      isCasino = true;
      balanceDetails$ = this.slotsBonusesService?.getCasinoBalanceDetails();
    } else if (this.inCasinoPanama()) {
      isCasino = true;
      isPanama = true;
      balanceDetails$ = this.slotsBonusesService?.getCasinoBalanceDetails();
    } else {
      balanceDetails$ = this.userService?.getGenericBalanceDetails();
    }

    const popover = await this.popoverController?.create({
      component: BalanceDetail,
      componentProps: {
        details$: this.details$,
        isCasino: isCasino,
        isPanama: isPanama
      },
      backdropDismiss: true,
      showBackdrop: false,
      dismissOnSelect: true,
      arrow: true,
      mode: 'ios'
    });

    popover.shadowRoot.lastChild.firstChild['style'].cssText = 'z-index: 99; margin-top: 4px;';
    popover.shadowRoot.lastChild.lastChild['style'].cssText =
      'box-shadow: 0 3px 12px 2px rgba(0,0,0,.3); border: 3px; margin-left: -1%; width: 250px;';
    this.popoverName.next('caret-up-sharp');
    await popover.present(event);

    balanceDetails$?.subscribe((details: CasinoBalanceDetails | SportsBalanceDetails) => {
      if (this.globalVars.FEATURES.Casino_EnabledMultiProviderBonuses) {
        const parseMutidetails = this.setDataMulti(details);
        this.details$.next(parseMutidetails);
      } else {
        this.details$.next(details);
      }
    });
    popover.onDidDismiss().then(() => {
      this.popoverName.next('caret-down-sharp');
      this.details$.next(null);
    });
  }

  setDataMulti(details) {
    const Playtech = 'PT';
    const Habanero = 'HAB';
    const parseDetails: CasinoBalanceDetails = details[0];
    parseDetails.CurrencyCode = details[0].Data.CurrencyCode;
    parseDetails.WithdrawableBalance = details[0].Data.WithdrawableBalance;
    parseDetails.TotalBalance = details[0].Data.TotalBalance;
    parseDetails.CasinoBalance = details[0].Data.CasinoBalance;
    parseDetails.BonusAndWinningsBalance = details[0].Data.BonusAndWinningsBalance;
    parseDetails.RingfencedBalance = details[0].Data.RingfencedBalance;
    parseDetails.bonusHab =
      details[0].Data.Provider === Habanero ? details[0].Data.CasinoBalance : details[1].Data.CasinoBalance;
    parseDetails.bonusPT =
      details[1].Data.Provider === Playtech ? details[1].Data.CasinoBalance : details[0].Data.CasinoBalance;
    return parseDetails;
  }

  hideBalanceDetails() {
    let result = false;
    if (this.popoverController) {
      this.popoverController.dismiss();
      this.popoverController = null;
      result = true;
    }
    this.popoverName.next('caret-down-sharp');
    return result;
  }
  /**
   * If the user is logged, refreshes the balance (globalvars.user.balance) which is an observable
   * shown in the header: CodereNavbar/ CodereNavbarPc.
   * If the user is in Casino, the amount is calculated in a different way
   */
  refreshBalance(hideLoader?: boolean): Observable<void> {
    // Observable which emits an event when the operation ends
    const subject = new Subject<void>();
    // This only happens if the user is logged
    if (this.globalVars.user.logged) {
      if (this.inCasino() || this.inJackpot() || this.inSlotsBonus()) {
        this.slotsBonusesService.getCasinoBalanceDetails().subscribe(
          (balances: CasinoBalanceDetails) => {
            this.globalVars.user.balance = balances.WithdrawableBalance;
            subject.next();
          },
          () => {
            subject.next();
          }
        );
      } else if (this.inCasinoPanama()) {
        this.slotsBonusesService.getCasinoBalanceDetails().subscribe(
          (balances: CasinoBalanceDetails) => {
            if (!this.globalVars.FEATURES.Casino_EnabledMultiProviderBonuses) {
              this.globalVars.user.balance = balances.WithdrawableBalance;
            } else {
              this.globalVars.user.balance = balances[0].Data.WithdrawableBalance;
            }
            subject.next();
          },
          () => {
            subject.next();
          }
        );
      } else {
        const url = this.globalVars.SportsMiscUrl + 'Account/RefreshBalance';
        this.myGet(url, hideLoader).subscribe(
          (data) => {
            if (data.success) {
              this.globalVars.user.username = data.username;
              this.globalVars.user.balance = data.balance;
              this.globalVars.user.logged = true;
            }
            subject.next();
          },
          () => {
            subject.next();
          }
        );
      }
    } else {
      // If the user wasn't logged, the refreshed finished automatically
      subject.next();
    }
    return subject;
  }
  addWonTicketToBalance(ticketId: string): Observable<any> {
    const url = this.globalVars.URLBASE + 'Payments/AddWonTicketToBalance?&ticketid=' + ticketId;
    return this.myGet(url);
  }

  //codere card
  getClientCardBalance(): Observable<any> {
    const url = `${this.globalVars.SportsMiscUrl}${Constants.CodereCardGetClientCardBalance}`;
    return this.myGet(url);
  }

  addBalanceToCard(pin: string, amount: string): Observable<any> {
    let url = this.globalVars.URLBASE + 'CodereCard/AddBalanceToCard?pin=' + pin + '&amount=' + amount;
    if (!!this.globalVars.UrlTransactionsApiBase && this.globalVars.TRANSAPI_BgtCardPayment) {
      url = this.globalVars.UrlTransactionsApiBase + 'CodereCard/AddBalanceToCard?pin=' + pin + '&amount=' + amount;
    }
    return this.myPost(url, null);
  }

  addBalanceFromCard(pin: string, amount: string): Observable<any> {
    let url = this.globalVars.URLBASE + 'CodereCard/AddBalanceFromCard?pin=' + pin + '&amount=' + amount;
    if (!!this.globalVars.UrlTransactionsApiBase && this.globalVars.TRANSAPI_BgtCardDeposit) {
      url = this.globalVars.UrlTransactionsApiBase + 'CodereCard/AddBalanceFromCard?pin=' + pin + '&amount=' + amount;
    }
    return this.myPost(url, null);
  }

  checkAddingBalanceToCard(pin: string, amount: string): Observable<any> {
    const url =
      this.globalVars.UrlTransactionsApiBase + 'CodereCard/CheckAddingBalanceToCard?pin=' + pin + '&amount=' + amount;
    return this.myPost(url, null);
  }

  checkAddingBalanceFromCard(pin: string, amount: string): Observable<any> {
    const url =
      this.globalVars.UrlTransactionsApiBase + 'CodereCard/CheckAddingBalanceFromCard?pin=' + pin + '&amount=' + amount;
    return this.myPost(url, null);
  }
}
