/* eslint-disable eqeqeq */
import { LogService } from './LogService';
import { BaseService } from './BaseService';
import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, from } from 'rxjs';

import { Utils } from '../utils/Utils';
import { GlobalVarsModel } from '../models/ModelGlobalsVar';
import { EventsService } from './EventsService';
import { NewBaseService } from './newBase.service';

@Injectable({ providedIn: 'root' })
export class ErrorService extends BaseService {
  errorList: Array<{ code: string; label: string }>;
  override globalVars!: GlobalVarsModel;

  override newBaseService = inject(NewBaseService);
  override events = inject(EventsService);
  override utils = inject(Utils);
  override httpClient = inject(HttpClient);
  logService = inject(LogService);

  constructor() {
    super();
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
    this.errorList = [];
  }

  searchErrorCode(code: string): Observable<any> {
    const list: Array<string> = [];
    return this.searchErrorCodeWithParams(code, list);
  }

  searchErrorCodeWithParams(code: string, errorParams: Array<string>): Observable<any> {
    //searchs the error code in the list
    //code can be number (XXX) or (errorXXX)
    if (code.indexOf('error') == -1) {
      code = 'error' + code;
    }

    // console.log("search->", code);
    const isTheCode = (element, index, array): any => {
      if (code == element.code) {
        return true;
      } else {
        return false;
      }
    };

    const getCode = (elem?: any): string => {
      if (elem) {
        return elem.label;
      } else {
        return '';
      }
    };

    if (this.errorList.length > 0) {
      //cached list
      const q = new Promise((resolve, reject) => {
        const elem = this.errorList.find(isTheCode);
        const label = getCode(elem);
        if (label != '') {
          const composedString = this.utils.stringFormat(label, errorParams);
          this.logService.error(composedString, { code: code });
          resolve(composedString);
        } else {
          reject('not found');
        }
      });
      // return Observable.fromPromise(q) -> from;
      return from(q);
    } else {
      //loads list
      const q = new Promise((resolve, reject) => {
        this.loadErrorCodes().subscribe(
          (data) => {
            this.errorList = [];
            for (const key in data) {
              this.errorList.push({ code: key, label: data[key] + '(' + key + ')' });
            }
            const elem = this.errorList.find(isTheCode);
            const label = getCode(elem);
            if (label != '') {
              const composedString = this.utils.stringFormat(label, errorParams);
              this.logService.error(composedString, { code: code });
              resolve(composedString);
            } else {
              reject('not found');
            }
          },
          (err) => {
            // console.log("error find", err);
            reject('not found');
          }
        );
      });
      // return Observable.fromPromise(q) -> from;
      return from(q);
    }
  }

  loadErrorCodes(): Observable<any> {
    let url: string = this.globalVars.URLBASE + 'home/GetFailCodes?languageCode=' + this.globalVars.Idioma;
    if (this.globalVars.UrlCommonApiBase) {
      url = this.globalVars.UrlCommonApiBase + 'Resources/GetFailCodes?languageCode=' + this.globalVars.Idioma;
    }
    return this.myGet(url, true);
  }
}
