<!-- Datos de Usuario-->
<fieldset id="userData" class="col s12">
  <p class="title-section">
    {{ titleSection }}
  </p>
  <!-- Usuario -->
  <cdr-input
    length="20"
    [label]="labels.user"
    [errorsCol]="errors['user']"
    modal="modalUser"
    fcName="user"
    [fgrp]="formGrp"
  ></cdr-input>
  <!-- Contraseña -->
  <cdr-input
    length="20"
    type="password"
    [label]="labels.password"
    [errorsCol]="errors['password']"
    modal="modalPassword"
    fcName="password"
    [fgrp]="formGrp"
  ></cdr-input>
  <!-- Promociones | Elemento colapsable  -->
  <cdr-expansion
    length="30"
    id="licodpromo"
    [label]="'¿Tienes código de promoción o de local (opcional)?'"
    [errorsCol]="errors['promoCode']"
    modal="modalPromoCode"
    fcName="promoCode"
    [fgrp]="formGrp"
  >
  </cdr-expansion>
</fieldset>
