/* eslint-disable eqeqeq */
import { BaseService } from './BaseService';
import { SlotsBonusesService } from './SlotsBonusesService';
import { Injectable, inject } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { Observable, lastValueFrom, BehaviorSubject, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { C_ItemsMenu } from '../models/C_ItemsMenu';
import { LicenseTypes } from '../models/MasterData';
import { C_ItemMenu } from '../models/C_ItemMenu';
import { CookieNames } from '../models/CookieNames';
import { TitleCasePipe } from '../pipes/titlecase/title-case.pipe';
import { UserService } from './UserService';
import { PersonalInfoPage } from '../pages/mso/personal-info/personal-info';
import { AlertModalComponent } from '../components/common/alert-modal/alert-modal.component';
import { AddFriendPage } from '../pages/mso/add-friend/add-friend';
import { CashierDocumentsPage } from '@shared-payments-pages/cashier-documents/cashier-documents';
import { NavigationEnd, Router } from '@angular/router';
import { BalancesService } from './BalancesService';
import { DeviceService } from '../services/device.service';
import { FreeBetsPage } from '../../src/pages/sports/free-bets/free-bets';
import { SPORTS_PATHS } from '@shared-constants/routes';
import { CASINO_PATHS } from '@shared-constants/routes';

@Injectable({ providedIn: 'root' })
export class NavService extends BaseService {
  lobbies: any[] = [];
  navSectionsLeft: any[] = []; //needs interface;
  navSectionsLeft$: Observable<any>;
  // navSectionsLeft$: BehaviorSubject<any[]> = new BehaviorSubject([]);
  navUserSection: any[];
  itemsMenu: Array<C_ItemsMenu>;
  itemsMenu$: BehaviorSubject<C_ItemsMenu[]> = new BehaviorSubject([]);
  firstLoad = true;
  //#region Inject Services
  slotsBonusesService = inject(SlotsBonusesService); //  @Inject(forwardRef(() => SlotsBonusesService)) public slotsBonusesService: SlotsBonusesService,
  userService = inject(UserService); //@Inject(forwardRef(() => UserService)) public userService: UserService,
  alertCtrl = inject(AlertController);
  translate = inject(TranslateService);
  router = inject(Router);
  balancesService = inject(BalancesService);
  deviceService = inject(DeviceService);
  isDesktop = false;
  titleCasePipe = inject(TitleCasePipe);

  // TODO newTicketFacade
  // newTicketFacade = inject(NewTicketFacade);
  //#endregion

  isMobile = this.deviceService.isMobile();

  constructor() {
    super();
    this.init(); //**+** ESTE ELEMENTO SE MOVIO DEL IF PARA EMPEZARLO */
    this.isDesktop = this.deviceService.isDesktop();

    this.events.subscribe('navbarpc:refresh', () => {
      if (this.firstLoad) {
        // this.init();
        this.firstLoad = false;
      } else {
        this.updateNavLeft();
      }
    });
  }

  init() {
    this.getSectionsLeftItems().then((items) => {
      this.navSectionsLeft = items;
      this.updateNavLeft();
    });
  }

  updateNavLeft(updateItem?: any) {
    this.navSectionsLeft$ = of(
      this.navSectionsLeft.map((item) => {
        if (item.componentName.toLowerCase() === SPORTS_PATHS.MisApuestasPage.toLowerCase()) {
          this.globalVars.user?.logged ? (item.feature = true) : (item.feature = false);
        }
        if (updateItem && item.componentName === updateItem) {
          item.active = true;
        } else {
          item.active = false;
        }
        if (item.componentName == 'eurocup2024') {
          item.feature = this.globalVars.FEATURES.euroCopaWidgetEnable;
        }
        return item;
      })
    );
  }

  // web right menu
  initRightMenu(refreshRequest = true) {
    this.itemsMenu = [];
    let i = 0;
    const titlecase = new TitleCasePipe();
    this.itemsMenu.push(
      new C_ItemsMenu(
        titlecase.transform(this.translate.instant('uPCajero')),
        'codere-icon icon-apuestas-84',
        '',
        '',
        [],
        false,
        0,
        true
      )
    );

    const arrSubMenuCashier: Array<C_ItemMenu> = [];
    if (this.globalVars.FEATURES.DepOnlineEnabled) {
      //** TODO */
      arrSubMenuCashier.push(
        new C_ItemMenu(this.translate.instant('tDepBig'), 'CashierPage', 'CashierDepositPage.name', true, 0, false, {
          section: 'deposit'
        })
      );
      arrSubMenuCashier.push(
        new C_ItemMenu(this.translate.instant('tCob'), 'CashierPage', 'CashierWithdrawalsPage.name', true, 0, false, {
          section: 'withdrawals'
        })
      );
      arrSubMenuCashier.push(
        new C_ItemMenu(this.translate.instant('hTitle'), 'CashierPage', 'CashierHistoryPage.name', true, 0, false, {
          section: 'history'
        })
      );
      arrSubMenuCashier.push(
        new C_ItemMenu(this.translate.instant('veridoc'), 'CashierPage', 'CashierDocumentsPage.name', true, 0, true, {
          section: 'documents'
        })
      );
    }
    this.itemsMenu[i++].items = arrSubMenuCashier;

    if (this.globalVars.licenseType == LicenseTypes.Panama || this.isMobile) {
      this.itemsMenu.push(
        new C_ItemsMenu(
          this.titleCasePipe.transform(this.translate.instant('veridoc')),
          'assets/global/img/icono_verificacion_menu.svg',
          'CashierPage',
          'CashierDocumentsPage.name',
          [],
          true,
          0,
          false,
          { section: 'documents' }
        )
      );
      i++;
    }

    //** TODO */
    if (this.globalVars.FEATURES.AddFriends) {
      this.itemsMenu.push(
        new C_ItemsMenu(
          this.translate.instant('InviteFriend'),
          'codere-icon icon-addfriend',
          AddFriendPage,
          AddFriendPage.name,
          [],
          true
        )
      );
      i++;
    }

    if (
      (this.globalVars.FEATURES.TarjetaCodereEnabled && this.globalVars.licenseType != LicenseTypes.Mexico) ||
      (this.globalVars.licenseType == LicenseTypes.Mexico && this.globalVars.user.codereCard.active) ||
      (this.globalVars.licenseType == LicenseTypes.Mexico && this.globalVars.withoutWinlineCard)
    ) {
      this.itemsMenu.push(
        new C_ItemsMenu(this.translate.instant('CodereCard'), 'codere-icon icon-tarjeta', '', '', [])
      );
      const arrSubMenuCard: Array<C_ItemMenu> = [];

      //** TODO */

      if (
        this.globalVars.licenseType == LicenseTypes.Mexico &&
        !this.globalVars.user.codereCard.active &&
        this.globalVars.FEATURES.CardAssociationAllowed
      ) {
        arrSubMenuCard.push(
          new C_ItemMenu(this.translate.instant('CodereCardToCheck'), 'CardLinkPage', 'CardLinkPage.name')
        );
      } else if (
        this.globalVars.user.codereCard == null ||
        (!this.globalVars.user.codereCard.active && this.globalVars.FEATURES.CardAssociationAllowed)
      ) {
        arrSubMenuCard.push(
          new C_ItemMenu(this.translate.instant('GetCardorder'), 'CardRequestPage', 'CardRequestPage.name')
        );
        arrSubMenuCard.push(
          new C_ItemMenu(this.translate.instant('CodereCardToCheck'), 'CardLinkPage', 'CardLinkPage.name')
        );
      }

      if (this.globalVars.user.codereCard.active) {
        arrSubMenuCard.push(
          new C_ItemMenu(
            this.translate.instant('CodereCardMybetsLocal'),
            'MisApuestasPresencialesPage',
            'MisApuestasPresencialesPage.name'
          )
        );
        arrSubMenuCard.push(
          new C_ItemMenu(this.translate.instant('CodereLinkedCard'), 'MyCardPage', 'MyCardPage.name')
        );
        arrSubMenuCard.push(
          new C_ItemMenu(this.translate.instant('CodereCardTransfer'), 'MyCardTransferPage', 'MyCardTransferPage.name')
        );
      }

      this.itemsMenu[i++].items = arrSubMenuCard;
    }

    if (this.globalVars.licenseType != LicenseTypes.ArgentinaMendoza)
      this.itemsMenu.push(new C_ItemsMenu(this.translate.instant('uPPBonus'), 'codere-icon icon-mejora', '', '', []));
    const arrSubMenuBonos: Array<C_ItemMenu> = [];
    if (this.globalVars.FEATURES.FreeBetEnabled) {
      arrSubMenuBonos.push(new C_ItemMenu(this.translate.instant('fb'), 'FreeBetsPage', ' FreeBetsPage.name', true));
    }
    if (this.globalVars.FEATURES.BonusSlotsMenuEnabled) {
      arrSubMenuBonos.push(new C_ItemMenu('Casino', 'SlotsBonusPage', 'SlotsBonusPage.name'));
    }
    if (this.globalVars.licenseType != LicenseTypes.ArgentinaMendoza) this.itemsMenu[i++].items = arrSubMenuBonos;

    if (this.globalVars.FEATURES.myactivity) {
      this.itemsMenu.push(
        new C_ItemsMenu(this.translate.instant('myactivity'), 'assets/global/img/icon-my-activity.svg', '', '', [])
      );
      const arrSubMenuActivity: Array<C_ItemMenu> = [];
      // TODO
      arrSubMenuActivity.push(
        new C_ItemMenu(this.translate.instant('monthlysummary'), 'MyActivityPage', 'MyActivityPage.name', true)
      );
      this.itemsMenu[i++].items = arrSubMenuActivity;
    }

    if (this.globalVars.FEATURES.slotReport) {
      this.itemsMenu.push(
        new C_ItemsMenu(this.translate.instant('v2_activCasino'), 'codere-icon icon-casino', '', '', [])
      );
      const arrSubMenuSlots: Array<C_ItemMenu> = [];
      arrSubMenuSlots.push(
        new C_ItemMenu(this.translate.instant('uPGames'), 'SlotsHistoryPage', 'SlotsHistoryPage.name', true)
      );
      this.itemsMenu[i++].items = arrSubMenuSlots;
    }

    if (this.isMobile && this.globalVars.FEATURES.playsstenabled) {
      this.itemsMenu.push(
        new C_ItemsMenu('Juega en tu Local', 'codere-icon icon-pin', 'PlaySstPage', 'PlaySstPage.name', [], true)
      );
      i++;
    }
    if (this.globalVars.FEATURES.checkTicket) {
      this.itemsMenu.push(
        new C_ItemsMenu(
          this.translate.instant('uPTicket'),
          'codere-icon icon-ticket',
          'CheckTicketPage',
          'CheckTicketPage.name',
          []
        )
      );
      i++;
    }
    if (this.globalVars.FEATURES.NearestLocals) {
      const iconlocal =
        this.globalVars.licenseType != LicenseTypes.Panama
          ? 'assets/global/img/icon-your-nearest-store.svg'
          : 'codere-icon icon-pin';
      this.itemsMenu.push(
        new C_ItemsMenu(this.translate.instant('uPLocal'), iconlocal, 'NearestLocalPage', 'NearestLocalPage.name', [])
      );
      i++;
    }

    this.itemsMenu.push(
      new C_ItemsMenu(this.translate.instant('uPPrefer'), 'codere-icon icon-preferencias', '', '', [], false, 0, true)
    );
    const arrSubMenuPreferencias: Array<C_ItemMenu> = [];
    if (this.globalVars.FEATURES.MSO_PersonalInfoPageEnabled)
      arrSubMenuPreferencias.push(
        new C_ItemMenu(this.translate.instant('uPPPInfo'), 'PersonalInfoPage', 'PersonalInfoPage.name', true, 0, true)
      );
    if (this.globalVars.FEATURES.timeOutPageEnabled)
      arrSubMenuPreferencias.push(new C_ItemMenu(this.translate.instant('timeout'), 'TimeOut', 'TimeOut.name', true));
    if (this.globalVars.FEATURES.selfExclusionPageEnabled && this.utils.isArgentina())
      arrSubMenuPreferencias.push(
        new C_ItemMenu(this.translate.instant('uPJRAut'), 'SelfExclusionPage', 'SelfExclusionPage.name', true)
      );
    if (this.globalVars.FEATURES.MSO_CommunicationsOptionsLeft) {
      arrSubMenuPreferencias.push(
        new C_ItemMenu(
          this.translate.instant('communicationoptions'),
          'ComunicationPage',
          'ComunicationPage.name',
          true
        )
      );
    }
    if (this.globalVars.licenseType != LicenseTypes.Colombia)
      // todo
      arrSubMenuPreferencias.push(
        new C_ItemMenu(this.translate.instant('bettingOptions'), 'CuotaTypePage', 'CuotaTypePage.name', false)
      );
    if (this.globalVars.FEATURES.nationalLimits) {
      arrSubMenuPreferencias.push(
        new C_ItemMenu(this.translate.instant('uPPLimits'), 'LimitsPage', 'LimitsPage.name', true)
      );
    }
    if (this.globalVars.licenseType == LicenseTypes.Colombia)
      arrSubMenuPreferencias.push(
        new C_ItemMenu(this.translate.instant('bettingOptions'), 'CuotaTypePage', 'CuotaTypePage.name', false)
      );
    if (this.globalVars.FEATURES.selfExclusionPageEnabled && !this.utils.isArgentina() && !this.canShowRevocationCO())
      arrSubMenuPreferencias.push(
        new C_ItemMenu(this.translate.instant('uPJRAut'), 'SelfExclusionPage', 'SelfExclusionPage.name', true)
      );
    if (this.canShowRevocationCO()) {
      arrSubMenuPreferencias.push(
        new C_ItemMenu(
          this.translate.instant('revocation'),
          'SelfExclusionPage',
          'SelfExclusionPage.name',
          true,
          0,
          false,
          {
            revocation: true
          }
        )
      );
    }
    if (this.globalVars.FEATURES.EnabledSelfDiagnosisTest)
      arrSubMenuPreferencias.push(
        new C_ItemMenu(
          this.translate.instant('responsibleGamblingTest'),
          'ResponsibleGamblingTestPage',
          'ResponsibleGamblingTestPage.name',
          true
        )
      );
    if (!this.globalVars.FEATURES.MSO_DisabledLanguage) {
      arrSubMenuPreferencias.push(
        new C_ItemMenu(this.translate.instant('iSubT'), 'LanguagePage', 'LanguagePage.name', false)
      );
    }
    this.itemsMenu[i++].items = arrSubMenuPreferencias;

    if (this.globalVars.FEATURES.quickExitPageEnabled) {
      this.itemsMenu.push(
        new C_ItemsMenu(
          this.translate.instant('quickexit'),
          'codere-icon icon-wrong',
          'QuickExit',
          'QuickExit.name',
          [],
          true,
          0,
          false
        )
      );
    }

    this.itemsMenu$.next(this.itemsMenu);

    this.bindBadges(refreshRequest);
  }

  private canShowSelfexclusionCO(): boolean {
    if (this.globalVars.licenseType != LicenseTypes.Colombia) {
      return true;
    } else {
      const userInfo = JSON.parse(this.globalVars.rootScope.cookiesService.getCookie(CookieNames.UserInfo));
      if (userInfo) {
        return !userInfo.selfExcluded;
      } else {
        return true;
      }
    }
  }
  private canShowRevocationCO(): boolean {
    if (this.globalVars.licenseType != LicenseTypes.Colombia) {
      return false;
    } else {
      return this.globalVars.extraUserData.isRevocationPage;
    }
  }
  static freebetPopupShown = false;
  bindBadges(refreshRequest = true) {
    if (this.globalVars.user.logged) {
      this.setBadgeMenu(PersonalInfoPage, this.globalVars.user.pendingSMSEmail ? 1 : 0);

      // cuando falta información personal por completar.
      let countPersonalInfo = 0;
      if (this.globalVars.user.pendingSMSEmail) {
        countPersonalInfo++;
      }
      if (this.globalVars.FEATURES.MSO_CheckProfesion && !this.globalVars.extraUserData.haveProfesion) {
        countPersonalInfo++;
      }
      //add badges dni expired nacional
      if (this.globalVars.FEATURES.MSO_CheckDNIExpired && this.globalVars.extraUserData.dniExpired) {
        this.setBadgeMenu(CashierDocumentsPage, 1);
      }

      this.setBadgeMenu(PersonalInfoPage, countPersonalInfo);

      let countBonusSlots = 0;
      if (refreshRequest) {
        // cuando tengo freebets activas.
        this.userService.loadFreeBets().subscribe(
          async (vouchers) => {
            // todo newTicketFacade
            // this.newTicketFacade.setFreeBetsVouchers(vouchers.data);
            const preFb = vouchers.availableDepositFbs ? vouchers.availableDepositFbs.length : 0;
            const fb = vouchers.data ? vouchers.data.length : 0;

            if (preFb || fb) {
              const sum = preFb + fb;
              this.setBadgeMenu(FreeBetsPage, sum);
              this.globalVars.user.pendingCountFreebets = sum;
            } else {
              this.setBadgeMenu(FreeBetsPage, 0);
            }
            //freebet overlay
            if (fb == 0) {
              return;
            }
            let message;
            let amount = 0;
            for (const b of vouchers.data) {
              if (b.IsTerminalVoucher) continue;
              amount += b.Amount;
            }
            if (amount == 0 || NavService.freebetPopupShown) return;

            if (
              this.globalVars.licenseType == LicenseTypes.Mexico ||
              this.globalVars.licenseType == LicenseTypes.Colombia ||
              this.globalVars.licenseType == LicenseTypes.Panama ||
              this.utils.isArgentina()
            ) {
              message = '$' + amount + ' Freebets Disponibles';
            } else {
              message = amount + 'EUR Freebets Disponibles';
            }
            //TODO ELIMINAR argentina
            const alertModal = new AlertModalComponent(this.alertCtrl);
            await alertModal.innerHTML({
              title: '<h6 class="sb-alert--title color-secondary">' + message + '</h6>',
              message: `<p class="sb-alert--text color-dark">${this.translate.instant(
                'fbdisponible'
              )}</br>${this.translate.instant('goodluck')}</p>`,
              buttons: ['OK'],
              cssClass: 'customModal'
            });
            NavService.freebetPopupShown = true;
          },
          (err) => {
            //** TODO */
            // this.setBadgeMenu(FreeBetsPage, 0);
          }
        );
        this.globalVars.user.cobPend = 0;
        //** TODO */
        // this.setBadgeMenu(ChargeCancelPage, this.globalVars.user.cobPend); //Miestras tanto

        //comprobar bonos slots
        if (this.globalVars.FEATURES.BonusSlotsMenuEnabled) {
          this.slotsBonusesService
            .getUserBonusCount(this.globalVars.user.username, this.isDesktop)
            .subscribe((data) => {
              if (data) {
                countBonusSlots = data;
                this.setBadgeMenu(CASINO_PATHS.SlotsBonusPage, countBonusSlots);
              }
            });
        }
      }

      //** TODO */
      // this.setBadgeMenu(SlotsBonusPage, countBonusSlots);
      this.setBadgeMenu(FreeBetsPage, this.globalVars.user.pendingCountFreebets);
      //** TODO */
      // this.setBadgeMenu(ChargeCancelPage, this.globalVars.user.cobPend);
    } else {
      //quitar todos los badges
      //** TODO */
      this.setBadgeMenu(PersonalInfoPage, 0);
      // this.setBadgeMenu(FreeBetsPage, 0);
      // this.setBadgeMenu(ChargeCancelPage, 0);
      // this.setBadgeMenu(SlotsBonusPage, 0);
      if (this.globalVars.FEATURES.MSO_CheckDNIExpired) {
        this.setBadgeMenu(CashierDocumentsPage, 0);
      }
    }
  }
  setBadgeMenu(link: any, badgeCount: number) {
    //sets the badge in right menu
    const im: C_ItemsMenu[] = this.itemsMenu;
    for (let i = 0; i < im.length; i++) {
      for (let j = 0; j < im[i].items.length; j++) {
        if (im[i].items[j].link == link || im[i].items[j].section.includes(link.name + '.name')) {
          im[i].items[j].badgeCount = badgeCount;
        }
      }
      if (im[i].link == link) {
        im[i].badgeCount = badgeCount;
      }
    }

    //recalculamos los badges de todos los items padre.
    let badgeCountAcumulado: number;
    for (let i = 0; i < im.length; i++) {
      badgeCountAcumulado = 0;
      if (im[i].items.length > 0) {
        //es para los items que tienen hijos
        for (let j = 0; j < im[i].items.length; j++) {
          badgeCountAcumulado = badgeCountAcumulado + im[i].items[j].badgeCount;
        }
      }
      im[i].badgeCount = badgeCountAcumulado;
    }
  }

  async getSectionsLeftItems() {
    let items = await lastValueFrom(this.myGet('assets/navItems/navSectionLeftItems.json'));
    if (!Array.isArray(items)) {
      return [];
    }
    items = items.map((item) => {
      if (item.componentName === 'misApuestasPage') {
        item.feature = this.globalVars.user?.logged;
      } else {
        item.feature = typeof item.feature === 'string' ? this.globalVars.FEATURES[item.feature] : item.feature;
      }
      return item;
    });
    if (this.globalVars.FEATURES.Lobbies) {
      this.lobbies = this.globalVars.FEATURES.Lobbies;

      if (Array.isArray(this.lobbies) && this.lobbies.length > 0) {
        Array.from(this.lobbies).forEach((ele) => {
          if (!ele.lobbyUrl) {
            ele.lobbyUrl = ele.LobbyTitle.replace(/ /g, '');
          }
        });
        let index = items.findIndex((item) => item.translate === 'liveEventAd');
        Array.from(this.lobbies).forEach((lobby) => {
          index++;
          items.splice(index, 0, {
            translate: lobby.LobbyTitle,
            url: lobby.lobbyUrl,
            path: `casino/${lobby.lobbyUrl}Page`,
            componentName: 'LobbyPage',
            params: {
              lobby: lobby.LobbyName,
              singleGame: lobby.SingleGameLobby
            },
            feature: true,
            active: true
          });
        });

        //let mundial = items.findIndex(item => item.translate === "Mundial");
        const mvpPosition = items.findIndex((item) => item.translate === 'MVP de Codere');
        const liveEventAd = items.findIndex((item) => item.translate === 'liveEventAd');
        const swap = (val1, val2, arr) => {
          if (!arr.includes(val1) || !arr.includes(val2)) return;
          arr.splice(mvpPosition, 1, val2);
          arr.splice(liveEventAd, 1, val1);
        };
        swap(items[mvpPosition], items[liveEventAd], items);
      }
    }

    return items;
  }
}
