<!-- eslint-disable -->
<ng-container *ngIf="!isMobile; else mobileView">
  <div class="cs-deposit" *ngIf="paymentsAllowed" [ngClass]="checkCurrentDepositAndLicense()">
    <div class="cs-deposit--payments border-color-dark">
      <div class="cs-deposit--section-payments background-color-light">
        <cs-transaction-item
          *ngFor="let type of paymentOptions"
          [payment]="type"
          [paymentSelected]="paymentSelected"
          (navigate)="selectOption($event)"
          class="cs-trans px-1 py-2"
        >
        </cs-transaction-item>
      </div>
    </div>

    <div class="cs-deposit--content">
      <div class="cs-deposit--content-container" #content>
        <!-- STEP 1 -->
        <div class="cs-deposit--section background-color-light p-4">
          <i class="cs-empty-icon codere-icon icon-insert-coin color-dark p-bottom-2"></i>
          <h4 class="text-center color-dark">{{'cashierdeposittxt' | translate}}</h4>
          <h4 *ngIf="globalVars?.licenseType === 33" class="text-center color-dark">
            {{'cashierdeposittxt02' | translate}}
          </h4>
        </div>

        <!-- STEP 2 -->
        <div
          class="cs-deposit--section text-center"
          [ngClass]="currentPayment === 'transferencia bancaria' ? 'background-light-section': ''"
        >
          <!-- <div class="cs-deposit--container background-color-dark w-100 p-2" *ngIf="!bankAccountMethodBehaviour && !localCodereMethodBehaviour && !tpagaDepositBehaviour"> -->
          <div
            class="cs-deposit--container background-color-light w-100 p-2"
            *ngIf="!bankAccountMethodBehaviour && !localCodereMethodBehaviour && !puntoPagoBehaviour && !redsysCardsMethodBehaviour && !trueLayerBehaviour && !CrownCasinoBehaviour && !BankcvuMethodBehaviour"
          >
            <div
              class="text-left cs-card-mx-advice"
              *ngIf="globalVars?.licenseType === LicenseTypes.Mexico && paymentSelected && paymentSelected?.name === 'VISA/Mastercard' "
            >
              <p>
                *¡Importante! Sólo se permite el uso de tarjetas cuyo titular coincida con el titular de la cuenta de
                codere.mx.
              </p>
            </div>
            <div class="cs-deposit--img py-1">
              <img src="assets/global/img/{{ paymentSelected?.image }}.png" alt="" />
            </div>

            <cs-calculator
              [options]="calculatorConfig"
              [paymentSelected]="paymentSelected"
              [amount]="paymentForm?.get('amount')?.value"
              [isActiveKeyboard]="isActiveKeyboard"
              (amountSelected)="amountSelected($event)"
              (blockOperation)="handleCalculatorOperationBlocked($event)"
              (setCustomAmount)="setCustomAmount($event)"
              (inputAmount)="inputAmount($event)"
              [blockCalculator]="blockCalculator"
            >
            </cs-calculator>

            <!-- Paypal -->
            <ng-template [ngIf]="!hidePaypalButton && isPaypalMethod">
              <button
                [disabled]="isActiveKeyboard || isOperationBlocked || !paymentForm?.valid"
                class="cs-action-button background-color-secondary"
                tappable
                (click)="goToPaypalDeposit()"
              >
                <span class="cs-action-button--text color-light"> {{ 'uPCDep' | translate }} </span>
              </button>
            </ng-template>

            <!-- LOTBA CODE INPUT-->
            <form
              class="cs-lotba-form p-1 w-100"
              *ngIf="step === 2 && globalVars?.licenseType === 33 && currentPaymentMethodBehaviour.name === 'Lotba'"
            >
              <div class="cs-lotba-form--field background-color-white m-bottom-1">
                <input
                  class="w-100 p-left-1"
                  type="text"
                  placeholder="Introduzca su código"
                  [formControl]="lotbaCodeNumber"
                />
              </div>
            </form>
            <!-- END LOTBA CODE INPUT-->

            <ng-container
              *ngIf="(step === 2 && (globalVars?.licenseType === LicenseTypes.Mexico || globalVars?.licenseType === 22) && currentPaymentMethodBehaviour?.documentNumberRequired) else withoutForm"
            >
              <cs-oxxobank-form
                [paymentForm]="paymentForm"
                [license]="globalVars?.licenseType"
                (checkDeposit)="checkDeposit()"
              >
              </cs-oxxobank-form>

              <ng-template
                [ngIf]="(!paypalPaymentMethodBehaviour || showDepositarButton) && (globalVars?.licenseType === LicenseTypes.Mexico ||  globalVars?.licenseType === 22)"
              >
                <button
                  [disabled]="isActiveKeyboard || isOperationBlocked || !paymentForm?.valid"
                  class="cs-action-button background-color-secondary"
                  tappable
                  (click)="checkOxxoDeposit()"
                >
                  <span class="cs-action-button--text color-light"> {{ 'uPCDep' | translate }} </span>
                </button>
              </ng-template>
            </ng-container>
          </div>

          <!-- Bank Account -->
          <div
            class="cs-deposit--container background-color-light w-100 p-2 cs-auto-heigth"
            *ngIf="bankAccountMethodBehaviour"
          >
            <div class="cs-deposit--img py-1">
              <img src="assets/global/img/{{ paymentSelected?.image }}.png" alt="" />
            </div>
            <cs-transfer-deposit [transferConfig]="transferConfig"></cs-transfer-deposit>
          </div>
          <div
            class="cs-deposit--container background-color-light w-100 p-2 cs-auto-heigth"
            *ngIf="BankcvuMethodBehaviour"
          >
            <div class="cs-deposit--img py-1">
              <img src="assets/global/img/{{ paymentSelected?.image }}.png" alt="" />
            </div>
          </div>

          <!-- Local Codere -->
          <div class="cs-deposit--container background-color-light w-100 p-1" *ngIf="localCodereMethodBehaviour">
            <div class="cs-deposit--img cs-deposit--img-small py-1">
              <img src="assets/global/img/{{ paymentSelected?.image }}.png" alt="" />
            </div>
            <cs-local-codere
              [license]="globalVars?.licenseType"
              [localConfig]="localConfig"
              (openCodereLocals)="openCodereLocals()"
            >
            </cs-local-codere>
          </div>

          <!-- Punto Pago -->
          <div class="cs-deposit--container background-color-light w-100 p-1" *ngIf="puntoPagoBehaviour">
            <div class="cs-deposit--img cs-deposit--img-small py-1">
              <img src="assets/global/img/{{ paymentSelected?.image }}.png" alt="" />
            </div>
            <cs-punto-pago></cs-punto-pago>
          </div>

          <!--Crown casino-->
          <div class="cs-deposit--container background-color-light w-100 p-1" *ngIf="CrownCasinoBehaviour">
            <div class="cs-deposit--img cs-deposit--img-small py-1">
              <img src="assets/global/img/{{ paymentSelected?.image }}.png" alt="" />
            </div>
            <cs-crown-casino></cs-crown-casino>
          </div>

          <div class="cs-deposit--container background-color-light w-100 p-2" *ngIf="trueLayerBehaviour">
            <div class="cs-deposit--img cs-deposit--img py-1">
              <img src="assets/global/img/{{ paymentSelected?.image }}.png" alt="" />
            </div>
            <cs-truelayer
              [paymentSelected]="paymentSelected"
              [config]="calculatorConfig"
              (checkDeposit)="checkDeposit(true)"
              [paymentBehaviour]="trueLayerBehaviour"
              class="w-100"
            >
            </cs-truelayer>
          </div>
        </div>

        <ng-template #withoutForm>
          <div class="deposit-consent-container">
            <span *ngIf="displayDepositDeclaration" class="deposit-consent"> {{ depositDeclaration }} </span>
            <!-- todo: revisar [disabled]="checkLotbaDepositisActiveKeyboard" -->
            <ng-template [ngIf]="showDepositarButton">
              <button
                class="cs-action-button background-color-secondary"
                tappable
                [disabled]="isOperationBlocked"
                (click)="checkDeposit()"
              >
                <span class="cs-action-button--text color-light"> {{ 'uPCDep' | translate }} </span>
              </button>
            </ng-template>
          </div>
        </ng-template>

        <!-- STEP 3 -->
        <div *ngIf="step === 3" class="cs-deposit--section background-color-light">
          <cs-payment-iframe
            class="cs-deposit--section-iframe"
            *ngIf="iFramePaymentMethodBehaviourResult"
            [options]="iFramePaymentMethodBehaviourResult?.iframeArgs"
            [payment]="paymentSelected"
            [depositAmount]="paymentForm?.controls['amount']?.value"
            (requestInfo)="requestIframe($event)"
          >
          </cs-payment-iframe>

          <cs-payvalida-ok
            *ngIf="payValidaCashPaymentMethodBehaviour || dimonexPayValidaCashPaymentMethodBehaviour || efectyPaymentMethodBehaviour"
            [payValidaCashPaymentMethodBehaviour]="selectPayValidaCashPaymentMethod"
            [payvalidaResult]="payValidaCashMethodBehaviourResult"
          >
          </cs-payvalida-ok>

          <cs-qr-code
            *ngIf="qrDepositBehaviour"
            [depositBehaviour]="qrDepositBehaviour"
            [depositResult]="qrMethodBehaviourResult"
          >
          </cs-qr-code>

          <div *ngIf="paypalPaymentMethodBehaviour" class="paypalstep">
            <div class="cs-deposit--img py-1">
              <img src="assets/global/img/{{ paymentSelected?.image }}.png" alt="" />
            </div>
            <h4>Está a punto de ingresar {{ payPalAmount }} {{ calculatorConfig?.currency }}s</h4>
            <h4>Ir a PayPal</h4>
            <div class="cs-action-button">
              <div id="paypal-button"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="cs-deposit no-payment-allowed" *ngIf="!paymentsAllowed">
    <div class="container-not-paymet">
      <div class="content-not-payment geo-error">
        {{paragraphNoPayment}}
        <a href="apuestas@codere.com" class="color-accent">apuestas&#64;codere.com</a>
        o
        <div>
          <img style="width: 20px; height: 20px" src="assets/global/img/whats.png" alt="Codere whatsapp" class="" />
          +5491136822558
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #mobileView>
  <div class="cs-deposit" #content *ngIf="paymentsAllowed">
    <!-- STEP 1 -->
    <div class="cs-deposit--section background-color-light">
      <cs-transaction-item *ngFor="let type of paymentOptions" [payment]="type" (navigate)="selectOption($event)">
      </cs-transaction-item>
    </div>

    <!-- STEP 2 -->
    <div class="cs-deposit--section text-center">
      <!--Crown casino-->
      <div class="cs-deposit--container background-color-light w-100 p-1" *ngIf="CrownCasinoBehaviour">
        <cs-crown-casino></cs-crown-casino>
      </div>
      <div
        class="cs-deposit--container background-color-light w-100 p-2"
        *ngIf="!bankAccountMethodBehaviour && !localCodereMethodBehaviour && !puntoPagoBehaviour && !redsysCardsMethodBehaviour && !trueLayerBehaviour  && !CrownCasinoBehaviour && !BankcvuMethodBehaviour"
      >
        <div
          class="text-left cs-card-mx-advice"
          *ngIf="globalVars?.licenseType === LicenseTypes.Mexico && paymentSelected && paymentSelected?.name === 'VISA/Mastercard' "
        >
          <p>
            *¡Importante! Sólo se permite el uso de tarjetas cuyo titular coincida con el titular de la cuenta de
            codere.mx
          </p>
        </div>

        <div *ngIf="!CrownCasinoBehaviour">
          <cs-calculator
            [options]="calculatorConfig"
            [paymentSelected]="paymentSelected"
            [amount]="paymentForm?.get('amount')?.value"
            [isActiveKeyboard]="isActiveKeyboard"
            (amountSelected)="amountSelected($event)"
            (blockOperation)="handleCalculatorOperationBlocked($event)"
            (setCustomAmount)="setCustomAmount($event)"
            (setInputKeyboard)="setInputKeyboard()"
            [blockCalculator]="blockCalculator"
          >
          </cs-calculator>
        </div>
        <!-- Paypal -->
        <ng-template [ngIf]="!hidePaypalButton && isPaypalMethod">
          <button
            [disabled]="isActiveKeyboard || isOperationBlocked || !paymentForm?.valid"
            class="cs-action-button background-color-secondary"
            tappable
            (click)="goToPaypalDeposit()"
          >
            <span class="cs-action-button--text color-light"> {{ 'uPCDep' | translate }} </span>
          </button>
        </ng-template>
        <!-- LOTBA CODE INPUT-->
        <form
          class="cs-lotba-form p-1 w-100"
          *ngIf="step === 2 && globalVars?.licenseType === 33 && currentPaymentMethodBehaviour.name === 'Lotba'"
        >
          <div class="cs-lotba-form--field background-color-white m-bottom-1">
            <input class="w-100 p-left-1" type="text" [formControl]="lotbaCodeNumber" />
          </div>
        </form>
        <!-- END LOTBA CODE INPUT-->

        <ng-container
          *ngIf="(step === 2 && (globalVars?.licenseType === LicenseTypes.Mexico || globalVars?.licenseType === 22) && currentPaymentMethodBehaviour?.documentNumberRequired) else withoutForm"
        >
          <cs-oxxobank-form
            [paymentForm]="paymentForm"
            [license]="globalVars?.licenseType"
            (checkDeposit)="checkDeposit()"
          >
          </cs-oxxobank-form>

          <!-- Deposit Button -->
          <ng-template
            [ngIf]="(!paypalPaymentMethodBehaviour || showDepositarButton) && (globalVars?.licenseType === LicenseTypes.Mexico ||  globalVars?.licenseType === 22)"
          >
            <button
              [disabled]="isActiveKeyboard || isOperationBlocked || !paymentForm?.valid"
              class="cs-action-button background-color-secondary"
              tappable
              (click)="checkOxxoDeposit()"
            >
              <span class="cs-action-button--text color-light"> {{ 'uPCDep' | translate }} </span>
            </button>
          </ng-template>
        </ng-container>

        <!-- PayPal Button -->
        <!-- <div [hidden]="!paypalPaymentMethodBehaviour" class="cs-action-button">
          <div id="paypal-button"></div>
        </div> -->
      </div>

      <!-- BankTransfer -->
      <div class="cs-deposit--container background-color-light w-100 p-2" *ngIf="bankAccountMethodBehaviour">
        <cs-transfer-deposit [transferConfig]="transferConfig"> </cs-transfer-deposit>
      </div>

      <!-- Local Codere -->
      <div class="cs-deposit--container background-color-light w-100 p-1" *ngIf="localCodereMethodBehaviour">
        <cs-local-codere
          [license]="globalVars?.licenseType"
          [localConfig]="localConfig"
          (openCodereLocals)="openCodereLocals()"
        ></cs-local-codere>
      </div>

      <!-- Punto Pago -->
      <div class="cs-deposit--container background-color-light w-100 p-1" *ngIf="puntoPagoBehaviour">
        <cs-punto-pago></cs-punto-pago>
      </div>

      <!-- TrueLayer -->
      <div
        class="cs-deposit--container background-color-light w-100 p-1 truelayer-container"
        *ngIf="trueLayerBehaviour"
      >
        <cs-truelayer
          #truelayer
          (closeKeyBoardTL)="closeKeyBoard('0')"
          [paymentSelected]="paymentSelected"
          [paymentBehaviour]="trueLayerBehaviour"
          (setInputKeyboardtl)="setInputKeyboardtl($event)"
          [config]="calculatorConfig"
          (checkDeposit)="checkDeposit(true)"
          [stateDepositButton]="stateDepositButton"
          class="w-100"
        >
        </cs-truelayer>
      </div>
    </div>

    <ng-template #withoutForm>
      <div class="deposit-consent-container">
        <span *ngIf="displayDepositDeclaration" class="deposit-consent"> {{ depositDeclaration }} </span>
        <ng-template [ngIf]="showDepositarButton">
          <button
            [disabled]="isActiveKeyboard || isOperationBlocked"
            [ngClass]="{'cs-calculator--disabled-form': isActiveKeyboard || isOperationBlocked}"
            class="cs-action-button background-color-secondary"
            tappable
            (click)="checkDeposit()"
          >
            <span class="cs-action-button--text color-light"> {{ 'uPCDep' | translate }} </span>
          </button>
        </ng-template>
      </div>
    </ng-template>

    <!-- STEP 3 -->
    <div *ngIf="step === 3" class="cs-deposit--section background-color-light">
      <cs-payment-iframe
        class="cs-deposit--section-iframe"
        *ngIf="iFramePaymentMethodBehaviourResult"
        [options]="iFramePaymentMethodBehaviourResult?.iframeArgs"
        [payment]="paymentSelected"
        [depositAmount]="paymentForm?.controls['amount']?.value"
        (requestInfo)="requestIframe($event)"
      >
      </cs-payment-iframe>

      <cs-payvalida-ok
        *ngIf="payValidaCashPaymentMethodBehaviour || dimonexPayValidaCashPaymentMethodBehaviour || efectyPaymentMethodBehaviour"
        [payValidaCashPaymentMethodBehaviour]="selectPayValidaCashPaymentMethod"
        [payvalidaResult]="payValidaCashMethodBehaviourResult"
      >
      </cs-payvalida-ok>

      <div *ngIf="paypalPaymentMethodBehaviour" class="paypalstep">
        <div class="cs-deposit--img py-1">
          <img src="assets/global/img/{{ paymentSelected?.image }}.png" alt="" />
        </div>
        <h4>Está a punto de ingresar {{ payPalAmount }} {{ calculatorConfig?.currency }}s</h4>
        <h4>Ir a PayPal</h4>
        <div class="cs-action-button">
          <div id="paypal-button"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="cs-deposit no-payment-allowed" *ngIf="!paymentsAllowed">
    <div class="container-not-paymet">
      <div class="content-not-payment geo-error">
        {{paragraphNoPayment}}
        <a href="apuestas@codere.com" class="color-accent">apuestas&#64;codere.com</a>
        o
        <div>
          <img style="width: 20px; height: 20px" src="assets/global/img/whats.png" alt="Codere whatsapp" class="" />
          +5491136822558
        </div>
      </div>
    </div>
  </div>
  <custom-keyboard
    *ngIf="isActiveKeyboard && sectionStep === 2"
    [active]="isActiveKeyboard"
    [cashier]="true"
    (closeKeyBoard)="closeKeyBoard($event)"
    (press)="pressInputKeyBoard($event)"
  ></custom-keyboard>
</ng-template>
