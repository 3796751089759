<!-- eslint-disable -->
<div class="ticket-filter-swiper swiper-container" [class.is-switch]="switch" #swiper [elementDisabled]="keyboard">
  <div class="swiper-wrapper" [class.is-switch]="switch" #list>
    <div
      class="swiper-slide ticket-filter-item"
      *ngFor="let filter of filters; let index = index"
      [class.is-hidden]="filter?.hidden"
      [class.is-selected]="!filter?.hidden && filter?.selected"
      [class.is-disabled]="!filter?.hidden && filter?.disabled"
      (click)="select(filter, index)"
      [elementDisabled]="keyboard"
    >
      <div class="ticket-filter-item--image" *ngIf="filter?.image">x</div>
      <span
        class="ticket-filter--title color-muted"
        [ngClass]="{'line-through' : (filter?.disabled && mode === 'grey')}"
      >
        {{ filter?.name | translate | titleCase }}
      </span>

      <img class="ticket-filter-item--icon" *ngIf="filter?.icon && !filter?.name" [src]="filter.icon" />
      <img
        class="ticket-filter-item--iconBetbuilder"
        *ngIf="filter?.icon && (!filter?.name )"
        [src]="filter.icon"
      />
      <ion-icon name="lock" color="dark" *ngIf="filter?.blocked"></ion-icon>
    </div>
    <div class="ticket-filter-line" #line></div>
  </div>
</div>
