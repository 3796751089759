import { Injectable, inject } from '@angular/core';
// import { InAppBrowser, InAppBrowserOptions } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { Browser } from '@capacitor/browser';

@Injectable({ providedIn: 'root' })
export class IabService {
  // options: InAppBrowserOptions = {
  //   location: "yes",
  //   hidden: "no",
  //   closebuttoncaption: "Cerrar",
  //   closebuttoncolor: "#ffffff",
  //   footer: "no",
  //   footercolor: "#000000",
  //   hardwareback: "yes",
  //   hidenavigationbuttons: "no",
  //   hideurlbar: "yes",
  //   navigationbuttoncolor: "#ffffff",
  //   toolbarcolor: "#000000",
  //   zoom: "yes",
  //   mediaPlaybackRequiresUserAction: "no",
  //   shouldPauseOnSuspend: "yes",
  //   useWideViewPort: "yes",

  //   toolbar: "yes",
  //   toolbartranslucent: "yes",
  //   enableViewportScale: "yes",
  //   allowInlineMediaPlayback: "yes",
  //   keyboardDisplayRequiresUserAction: "no",
  //   suppressesIncrementalRendering: "no",
  //   presentationstyle: "fullscreen", //pagesheet, formsheet, fullscreen
  //   transitionstyle: "coververtical", //fliphorizontal, crossdissolve, coververtical
  //   toolbarposition: "bottom", //top, bottom
  //   hidespinner: "no"

  // }

  // iab = inject(InAppBrowser);
  constructor() {}

  // create(url: string, target = "_blank") {
  //   return this.iab.create(encodeURI(url), target, this.options);
  // }

  public create(url: string, target: string = '_blank') {
    const options = {
      toolbarColor: '#000000',
      windowColor: '#000000',
      backButtonCanClose: true,
      backButtonStyle: 'close',
      navigationBarColor: '#000000',
      showTitle: true
    };

    try {
      return Browser.open({ url: encodeURI(url), ...options });
    } catch (error) {
      console.error('Error opening browser:', error);
    }
  }
}
