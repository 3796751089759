/* eslint-disable */
import { IFramePaymentMethodBehaviourResult, PaymentMethodBehaviour, SkipAmountResult } from './PaymentMethodBase';
import { PaySafeCardDepositActionResult } from '@models/C_Transactions';
import { UserService } from '@providers/UserService';
import { ErrorService } from '@providers/ErrorService';
import { Observable, map } from 'rxjs';

export class MercadoPagoMethodBehaviour extends PaymentMethodBehaviour {
  name = 'MercadoPago';
  image = 'logo_MercadoPago';
  track = 'AccessToMercadoPago';
  isBank = false;
  minDepositAmount = 10;
  maxDepositAmount = 100000;
  amountButtons: number[] = [10, 20, 40, 50];
  override amount = 20;

  constructor(userService: UserService, errorService: ErrorService) {
    super(false, userService, errorService);
  }

  skipAmountInput(): Observable<SkipAmountResult> {
    return new Observable<SkipAmountResult>();
  }

  paymentMetodExecution(): Observable<IFramePaymentMethodBehaviourResult> {
    return this.userService.validateMergadoPagoDeposit('', this.amount).pipe(
      map((obj) => {
        return this.map(obj);
      })
    );
  }

  private map(input: PaySafeCardDepositActionResult): IFramePaymentMethodBehaviourResult {
    const result = new IFramePaymentMethodBehaviourResult();
    if (input.success) {
      result.success = true;
      result.iframeType = 'MercadoPago';
      result.iframeArgs = { type: 'MercadoPago', url: encodeURI(input.url) };
    } else {
      result.success = false;
      result.erroMessage = this.errorService.searchErrorCodeWithParams('error' + input.errCode, input.errorParameters);
      result.errorcode = input.errCode;
      result.sendToDocumentVerificationPage = input.errCode == 174 || input.errCode == 2212;
    }
    return result;
  }
}
