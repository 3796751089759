import { C_LiveData } from './C_LiveData';

export class C_LiveDataVolleybeach extends C_LiveData {
  Sets: Array<number>;
  HomeService: boolean;
  SetsHome: number;
  SetsAway: number;

  constructor(
    actions: Array<any>,
    homeService: boolean,
    ParticipantAway: string,
    participantHome: string,
    period: number,
    periodName: string,
    resultAway: number,
    resultHome: number,
    sets: Array<number>,
    setsAway: number,
    setsHome: number,
    time: string
  ) {
    super(actions, ParticipantAway, participantHome, period, periodName, resultAway, resultHome, time);
    this.Sets = sets;
    this.HomeService = homeService;
    this.SetsHome = setsHome;
    this.SetsAway = setsAway;
  }
}
