/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable, inject } from '@angular/core';
import { UserServiceMSO } from '@providers/UserServiceMSO';
import { Subject, BehaviorSubject } from 'rxjs';

export interface LegalTerms {
  url: string;
  hash: string;
  code: string;
}

@Injectable({ providedIn: 'root' })
export class LegalTermsServiceService {
  private legalTerms: LegalTerms;
  public $legalTerms: BehaviorSubject<LegalTerms>;
  private license: string;
  public $licence: Subject<string>;
  private gtSubscription: any;
  private vfSubscription: any;

  userServiceMSO = inject(UserServiceMSO);
  constructor() {
    this.legalTerms = { url: '', hash: '', code: '' };
    this.license = '0';
    this.$legalTerms = new BehaviorSubject(this.legalTerms);
    this.$licence = new Subject();
  }

  /**
   * Actualizalos datos de terminos y condiciones
   * utilizando un servicio que hace una llamada al back
   */
  updateConditions(): void {
    if (this.gtSubscription) {
      this.gtSubscription.unsubscribe();
    }
    this.userServiceMSO.getLastTermsAndConditionsUrlbyRegion('Nacional').subscribe({
      next: (data) => {
        this.legalTerms.hash = data.Hash;
        this.legalTerms.url = data.Url;
        this.legalTerms.code = data.Code;
        this.license = '0';
        this.$licence.next(this.license);
        this.$legalTerms.next(this.legalTerms);
      },
      error: (error) => {
        console.error(error);
      }
    });
  }

  public getLicense(): string {
    return this.license;
  }

  public getLegalTerms(): LegalTerms {
    return this.legalTerms;
  }
}
