export interface I_MarqueeItem {
  StartDate: string;
  HasStreaming: boolean;
  IsLive: boolean;
  LeagueName: string;
  ParticipantHome: string;
  ParticipantAway: string;
  ParticipantHomeJerseyUrl: string;
  ParticipantAwayJerseyUrl: string;
  NodeId: string;
  SportHandle: string;
  ParentNodeId: any;
  Game: {
    Results: MarqueeItemGame[];
    DisplayTypeName: string;
    CategoryInfo: {
      CategoryId: number;
      CategoryName: string;
      IsRelevant: boolean;
    };
    CategoryInfos: {
      CategoryId: number;
      CategoryName: string;
      IsRelevant: boolean;
    }[];
    GameType: number;
    SmartMarketAvailable: boolean;
    Spov: string;
    Name: string;
    NodeId: number;
    ParentNodeId: number;
    Priority: number;
    SportHandle: string;
    Locked: boolean;
  };
  liveData: {
    YellowCardsHome: number;
    RedCardsHome: number;
    YellowCardsAway: number;
    RedCardsAway: number;
    Period: number;
    PeriodName: string;
    Actions: MarqueeItemLiveDataActions[];
    ResultHome: number;
    ResultAway: number;
    Time: number;
    MatchTime: number;
    RemainingPeriodTime: string;
    ParticipantHome: string;
    ParticipantAway: string;
    SetsAway: string;
    SetsHome: string;
  };
}

interface MarqueeItemGame {
  Odd: number;
  SortOrder: number;
  IsLive: boolean;
  upOdd: boolean;
  downOdd: boolean;
  IsNonRunner: boolean;
  SportId: number;
  LocationId: number;
  LeagueId: number;
  EventId: number;
  EventHasHandicap: boolean;
  GameId: number;
  GameTypeId: number;
  GameSpecialOddsValue: string;
  GameBetTypeAvailability: number;
  GameNumberOfStarters: number;
  Name: string;
  NodeId: number;
  ParentNodeId: number;
  Priority: number;
  SportHandle: string;
  Locked: boolean;
}

interface MarqueeItemLiveDataActions {
  Period: number;
  PeriodName: string;
  Time: number;
  ActionType: number;
  ActionTypeName: null;
  Participant: string;
  AffectedParticipant: string;
  IsHomeTeam: boolean;
}
