/**
 * SPORT URLS
 */
// CATEGORY URLS
export const getCategoriesByLeagueUrl = "Category/GetCategoriesByLeague";
export const getCategoryNoLiveInfosUrl = "Category/GetCategoryNoLiveInfos";

// CONFIG URLS
export const betsConfigData = "Configfile/GetBetsConfigData";

// EVENTS URLS
export const getEventsByDateUrl = "Event/GetEventsByDate";
export const getEventsUrl = "Event/GetEvents";
export const getLiveEventsBySportHandleUrl = "Event/GetLiveEventsBySportHandle";
export const getLiveEventsAndSportsBySportHandleUrl = "Event/GetLiveEventsAndSportsBySportHandle";
// HIGHLIGHTS URLS
export const getHiglightEventsBySportHandleUrl = "Highlights/GetHiglightEventsBySportHandle";

// HOME URLS
export const getCountriesByDateUrl = "Home/GetCountriesByDate";
export const getCountriesUrl = "Home/GetCountries";
export const getHomeInfoUrl = "Home/GetHomeInfo";
export const getHomeLiveEvents = "Home/GetHomeLiveEvents";
export const getLiveEventsAndSportsBySportHandle = "Home/GetLiveEventsAndSportsBySportHandle";
export const getRacesByPaddockUrl = "Home/GetRacesbyPaddock";
export const getSportBySportHandle = "Home/GetSportBySportHandle";
export const getSportUrl = "Home/GetSports";
export const updateOddsUrl = "Home/Update";

// RACING URLS
export const getCompleteRaceUrl = "Racing/GetCompleteRace";
export const getNextRacesUrl = "Racing/GetNextRaces";
export const getRaceInfo = "Racing/GetRaceInfo";
export const silksURL = "https://silks.termct.com/RASSilks/";

// LEFT MENU URLS
export const getMenuLeftUrl = "LeftMenu/GetMenuLeft";
export const getSubmenuLeftUrl = "LeftMenu/GetSubmenuLeft";

// MATCHACCA URLS
export const betbuilderAddItemUrl = "MatchAcca/GetMatchAccaResponse?";
export const getBetbuilderResposeByBetBuilderIdUrl = "MatchAcca/GetMatchaccaResposeByBetBuilderId?betBuilderId=";

// PAGES URL - PAIS LIGA
export const getPaisLigaInfoUrl = "LeftMenu/GetCountriesAndHighlights";

// GAME URL
export const getGamesNoLiveByCategoryInfoUrl = "Game/GetGamesNoLiveByCategoryInfo";
export const getGamesNoLiveAndCategoryInfosUrl = "Game/GetGamesNoLiveAndCategoryInfos";
export const getGamesNoLiveUrl = "Game/GetGamesNoLive";
export const JGetNode = "home/JGetNode";
export const getGamesLiveAndCategoryInfosUrl = "Game/GetGamesLiveAndCategoryInfos";
export const getGamesLiveUrl = "Game/GetGamesLive";
export const getGamesLiveByCategoryInfoUrl = "Game/GetGamesLiveByCategoryInfo";

// SEARCH URLS
export const getSearchUrl = "Search/FreeTextSearch";
export const getCustomQueryUrl = "Home/CustomQuerySearch";

// BETSLIP URLS
export const addSingleItemUrl = "item/singleResult";
export const addSmartMarketItemUrl = "item/smartMarket";
export const stakeUrl = "staking/";
export const removeItemUrl = "item/";
export const removeTicket = "betslip/clear";
export const setOdds = "item/singleResult/odds";
export const straightMulticast = "item/straightMulticast";
export const combinationMulticast = "item/combinationMulticast";
export const smartMarketUrl = "item/smartMarket";
export const freeBetAdd = "bonus/voucher";
export const freeBetGetInfo = "FreebetBonusEs/GetVoucherInfo";
export const freeBetCancel = "bonus/voucher";
export const freeBetValidate = "bonus/validate";

// MY BETS
export const OrderFormPayOutBetTicketCard = "Card/PayOutBetTicket";
export const OrderFormPayOutBetTicketTicket = "Ticket/PayOutBetTicket";
export const GetCashOutByTicket = "/Home/GetCashOutByTicket";

// STREAMING URLS
export const getStreamingURL = "api/Streaming/GetStreamingURL";
export const getXbNetStreamingURL = "api/Streaming/GetXbNetStreamingURL";
export const getBetRadarStreamingInfo = "api/Streaming/GetBetRadarStreamingInfo";
export const getSMSStreamingURL = "api/Streaming/GetSMSStreamingURL";
export const getIMGStreamingURL = "api/Streaming/GetIMGStreamingURL";
export const getStreamingIdWatchBetToken = "api/Streaming/GetStreamingIdWatchBetToken";
export const getSISStreamingURL = "api/Streaming/GetSISStreamingURL";
export const getGeniusStreamingURL = "api/Streaming/GetGeniusStreamingURL";
export const IMGStreamUrl = "/api/v2/streaming/events/";
export const BeterStreamingUrl = "https://fbstreambro.cc/embed.php/?match_id=";

// SportHandle

// Category Id
export const priorityForCat = "Priority";
export const goleadoresCat = "81";
export const specialBetCat = "90";
export const principalesCat = "99";
export const matchaccaCatId = "111";
export const matchaccaCatName = "Matchacca";
export const betbuilderCatId = "111";
export const betbuilderCatName = "Matchacca";
export const betbuilderName = "betbuilder";

// Tournaments IDs
export const UEFAEuroNodeId = "8338719747";
export const CopaAmericaNodeId = "8374076327";
export const MundialNodeId = "5108021769";

// Tournaments literals
export const UEFAEuroGamesLiteral = "Partidos - UEFA Euro 2024";
export const UEFAEuroPageLiteral = "Pagina de eventos de Eurocopa 2024";
export const AccessToUEFAEuroEventPageLiteral = "AccessToEuroCupEventPage";
export const CopaAmerica2021Literal = "Copa América 2021";
export const PaginaDeEventosDeCopaAmericaLiteral = "Pagina de eventos de Copa America";
export const AccessToCopaAmericaEventPageLiteral = "AccessToCopaAmericaEventPage";
export const tournamentLiteral = "tournament";

// Unique Tournaments IDs
export const EurocopaUniqueTournamentId = 1;
export const CopaAmericaUniqueTournamentId = 133;
export const MundialUniqueTournamentId = 16;

// Sport Navigation
export const CreaTuApuestaPageLink = "Crea-Tu-Apuesta";

// Sport categories
export const OtrasApuestasCategoryId = 0;
export const FastbetCategoryId = 2;
export const PrincipalesCategoryId = 99;
export const NineHundredNinetyNineCategoryId = 999;
export const BetbuilderCategoryId = 111;
export const CombinadosCategoryId = 83;
export const GolesCategoryId = 82;
export const ResultadoFinalCategoryId = 75;
export const PrimeraYSegundaParteCategoryId = 60;
export const HandicapCategoryId = 50;
export const EquiposCategoryId = 40;
export const ProximosMinutosCategoryId = 35;
export const ApuestasCombinadasCategoryId = 90;
export const PrimerEquipoEnMarcarXPuntosCategoryId = 85;
export const ApuestasPrimeraMitadCategoryId = 80;
export const ApuestasPrimerCuartoCategoryId = 75;
export const GanadorSinEmpateCategoryId = 97;
export const TotalDeAsaltosCategoryId = 448;

// Codere Fantasy
export const getCodereFantasyCode = "/HeroPicks/GetUserCode";

/**
 * Literals
 */
export const BETSENSE = "betSense";
export const today = "today";
export const tomorrow = "tomorrow";
export const SPO_BetbuilderMessage2 = "Necesitas al menos 2 selecciones.";
export const SPO_BetbuilderMessage1 = "Añadir al menos 1 o más selecciones.";
export const SPO_BetbuilderMessage = "Apostar o añadir más selecciones";
export const SPO_BetbuilderMessageError =
  "Combinación no válida. Algunas de tus selecciones no son combinables. Por favor, cámbialas para continuar.";
export const ACTIVO = "Activo";
export const INACTIVO = "Inactivo";
export const AceptarLiteral = "Aceptar";
export const VideoNoComenzadoLiteral =
  "El vídeo de la carrera todavía no ha comenzado, inténtelo de nuevo a la hora programada";
export const VideoNoDisponibleLiteral = "Vídeo no disponible";

/**
 * Component Color modes
 */
export const compoModeGrey = "grey";

/**
 * flow status
 */
export const FLOW_STATUS = {
  INITIAL_STATUS: "initialStatus",
  POLLING_STATUS: "pollingStatus",
  FINISH_STATUS: "finishStatus",
};

export const TICKET_PROXY = "https://ticketproxyqa.azurewebsites.net";

export const LIVE_MARKET_FILTER_TYPES = {
  SPORTRADAR: "sportradar",
  SCOREBOARD: "scoreboard",
  VIDEO_STREAMING: "videoStreaming",
};

export const VIDEOPLAYER_ACTIONS = {
  CLOSE: "videoplayer:close",
  TO_TICKET: "videoplayer:toticket",
  FREE: "videoplayer:free",
  PLAY: "videoplayer:play",
  PLAY_IFRAME: "videoplayer:playIframe",
  RUN: "videoplayer:run",
};
// ! NOT IN USE
// export const getGetBetBuilderEvents = "Marquee/GetBetBuilderEvents";
// export const getHighlightsConfig = "HighlightsLeftMenu/GetHighlightsConfig";
// export const getMarquee = "Marquee/GetMarquee";
// export const getDgaFantasyDev = "https://codere.dgafantasy-dev.com/league?operatorKey=";
// export const getDgaFantasy = "https://codere.dgafantasy.com/league?operatorKey=";

// NO SESSION TIMEOUT URLS
// export const geHomeMainLeague = "NoSessionTimeout/GeHomeMainLeague";

// PAGES URL
// export const getCountriesInfo = "Pages/GetCountriesInfo";

// HIGHLIGHTS URLS
// export const getHighlightsEventsUrl = "Highlights/GetHighlightsEvents";
// export const getHighlightsUrl = "Highlights/Gethighlights";
