import { createReducer, on } from '@ngrx/store';
import * as MyBetsActions from './my-bets.actions';
import { I_MyBetsModel } from '../../models';

// const MOCK_RESPONSE: I_MyBetsModel[] = [
//   {
//     DayOrderNr: 0,
//     TicketsDatetime: '2024-10-23T15:34:04.89Z',
//     Tickets: [
//       {
//         TicketOrderNr: 10,
//         TicketNumber: 327185398,
//         TicketDatetime: '2024-10-23T15:38:51.583Z',
//         TicketStatusNumber: 3,
//         TicketStatus: 'Perdida',
//         IsPushWinning: false,
//         Stake: 1,
//         BetType: 'Acumulada BetBuilder',
//         BetTypeNumber: '24',
//         X: 3,
//         Y: 3,
//         PotentialWinnings: 51.16,
//         PotentialWinningsOriginal: 51.16,
//         CancelationReason: null,
//         CashOutAmount: 0,
//         CashOutStatus: 0,
//         VoucherCode: null,
//         BetsNumber: 100,
//         StatusCode: 0,
//         Pin: '4388',
//         BetEvent: [
//           {
//             IsBetbuilder: true,
//             SportName: 'Fútbol',
//             CountryName: 'España',
//             LeagueName: 'Primera División',
//             EventName: 'Real Madrid - Barcelona',
//             EventId: 9997546112,
//             MarketName: 'Marca Gol Durante el Partido',
//             ResultName: 'Kylian Mbappé',
//             ClearingStatusNumber: '3',
//             ClearingStatus: 'Perdida',
//             EventDatetime: '2024-10-26T19:00:00Z',
//             Odd: 2.05,
//             ResultsNr: 10070114976,
//             IsBank: false,
//             BetBuilderItems: [
//               {
//                 SportName: 'Fútbol',
//                 CountryName: 'España',
//                 LeagueName: 'Primera División',
//                 EventName: 'Real Madrid - Barcelona',
//                 EventId: 9997546112,
//                 MarketName: 'Marca Gol Durante el Partido',
//                 ResultName: 'Kylian Mbappé',
//                 ClearingStatusNumber: '3',
//                 ClearingStatus: 'Perdida',
//                 EventDatetime: '2024-10-26T19:00:00Z',
//                 Odd: 2.05,
//                 ResultsNr: 10070114976,
//                 IsBank: false
//               },
//               {
//                 SportName: 'Fútbol',
//                 CountryName: 'España',
//                 LeagueName: 'Primera División',
//                 EventName: 'Real Madrid - Barcelona',
//                 EventId: 9997546112,
//                 MarketName: 'Marcan Ambos Equipos',
//                 ResultName: 'Sí',
//                 ClearingStatusNumber: '3',
//                 ClearingStatus: 'Perdida',
//                 EventDatetime: '2024-10-26T19:00:00Z',
//                 Odd: 1.43,
//                 ResultsNr: 9997546424,
//                 IsBank: false
//               },
//               {
//                 SportName: 'Fútbol',
//                 CountryName: 'España',
//                 LeagueName: 'Primera División',
//                 EventName: 'Real Madrid - Barcelona',
//                 EventId: 9997546112,
//                 MarketName: 'Primer Equipo en Marcar',
//                 ResultName: 'Real Madrid',
//                 ClearingStatusNumber: '3',
//                 ClearingStatus: 'Perdida',
//                 EventDatetime: '2024-10-26T19:00:00Z',
//                 Odd: 1.7,
//                 ResultsNr: 9997546600,
//                 IsBank: false
//               },
//               {
//                 SportName: 'Fútbol',
//                 CountryName: 'España',
//                 LeagueName: 'Primera División',
//                 EventName: 'Real Madrid - Barcelona',
//                 EventId: 9997546112,
//                 MarketName: '1X2',
//                 ResultName: 'Real Madrid',
//                 ClearingStatusNumber: '3',
//                 ClearingStatus: 'Perdida',
//                 EventDatetime: '2024-10-26T19:00:00Z',
//                 Odd: 1.88,
//                 ResultsNr: 9997546635,
//                 IsBank: false
//               }
//             ]
//           },
//           {
//             IsBetbuilder: false,
//             SportName: 'Fútbol',
//             CountryName: 'España',
//             LeagueName: 'Primera División',
//             EventName: 'Espanyol - Sevilla',
//             EventId: 9997494406,
//             MarketName: '1X2',
//             ResultName: 'Espanyol',
//             ClearingStatusNumber: '3',
//             ClearingStatus: 'Perdida',
//             EventDatetime: '2024-10-25T19:00:00Z',
//             Odd: 2.85,
//             ResultsNr: 9997494844,
//             IsBank: false
//           },
//           {
//             IsBetbuilder: false,
//             SportName: 'Fútbol',
//             CountryName: 'España',
//             LeagueName: 'Primera División',
//             EventName: 'Rayo - Alavés',
//             EventId: 9997509234,
//             MarketName: '1X2',
//             ResultName: 'X',
//             ClearingStatusNumber: '3',
//             ClearingStatus: 'Perdida',
//             EventDatetime: '2024-10-26T14:15:00Z',
//             Odd: 3,
//             ResultsNr: 9997510013,
//             IsBank: false
//           }
//         ],
//         HasBetbuilder: true
//       },
//       {
//         TicketOrderNr: 9,
//         TicketNumber: 327185397,
//         TicketDatetime: '2024-10-23T15:38:27.747Z',
//         TicketStatusNumber: 3,
//         TicketStatus: 'Perdida',
//         IsPushWinning: false,
//         Stake: 10,
//         BetType: 'Sencilla BetBuilder',
//         BetTypeNumber: '14',
//         X: 1,
//         Y: 1,
//         PotentialWinnings: 59.83,
//         PotentialWinningsOriginal: 59.83,
//         CancelationReason: null,
//         CashOutAmount: 0,
//         CashOutStatus: 0,
//         VoucherCode: null,
//         BetsNumber: 1000,
//         StatusCode: 0,
//         Pin: '1915',
//         BetEvent: [
//           {
//             IsBetbuilder: true,
//             SportName: 'Fútbol',
//             CountryName: 'España',
//             LeagueName: 'Primera División',
//             EventName: 'Real Madrid - Barcelona',
//             EventId: 9997546112,
//             MarketName: 'Marca Gol Durante el Partido',
//             ResultName: 'Kylian Mbappé',
//             ClearingStatusNumber: '3',
//             ClearingStatus: 'Perdida',
//             EventDatetime: '2024-10-26T19:00:00Z',
//             Odd: 2.05,
//             ResultsNr: 10070114976,
//             IsBank: false,
//             BetBuilderItems: [
//               {
//                 SportName: 'Fútbol',
//                 CountryName: 'España',
//                 LeagueName: 'Primera División',
//                 EventName: 'Real Madrid - Barcelona',
//                 EventId: 9997546112,
//                 MarketName: 'Marca Gol Durante el Partido',
//                 ResultName: 'Kylian Mbappé',
//                 ClearingStatusNumber: '3',
//                 ClearingStatus: 'Perdida',
//                 EventDatetime: '2024-10-26T19:00:00Z',
//                 Odd: 2.05,
//                 ResultsNr: 10070114976,
//                 IsBank: false
//               },
//               {
//                 SportName: 'Fútbol',
//                 CountryName: 'España',
//                 LeagueName: 'Primera División',
//                 EventName: 'Real Madrid - Barcelona',
//                 EventId: 9997546112,
//                 MarketName: 'Marcan Ambos Equipos',
//                 ResultName: 'Sí',
//                 ClearingStatusNumber: '3',
//                 ClearingStatus: 'Perdida',
//                 EventDatetime: '2024-10-26T19:00:00Z',
//                 Odd: 1.43,
//                 ResultsNr: 9997546424,
//                 IsBank: false
//               },
//               {
//                 SportName: 'Fútbol',
//                 CountryName: 'España',
//                 LeagueName: 'Primera División',
//                 EventName: 'Real Madrid - Barcelona',
//                 EventId: 9997546112,
//                 MarketName: 'Primer Equipo en Marcar',
//                 ResultName: 'Real Madrid',
//                 ClearingStatusNumber: '3',
//                 ClearingStatus: 'Perdida',
//                 EventDatetime: '2024-10-26T19:00:00Z',
//                 Odd: 1.7,
//                 ResultsNr: 9997546600,
//                 IsBank: false
//               },
//               {
//                 SportName: 'Fútbol',
//                 CountryName: 'España',
//                 LeagueName: 'Primera División',
//                 EventName: 'Real Madrid - Barcelona',
//                 EventId: 9997546112,
//                 MarketName: '1X2',
//                 ResultName: 'Real Madrid',
//                 ClearingStatusNumber: '3',
//                 ClearingStatus: 'Perdida',
//                 EventDatetime: '2024-10-26T19:00:00Z',
//                 Odd: 1.88,
//                 ResultsNr: 9997546635,
//                 IsBank: false
//               }
//             ]
//           }
//         ],
//         HasBetbuilder: true
//       },
//       {
//         TicketOrderNr: 8,
//         TicketNumber: 327185396,
//         TicketDatetime: '2024-10-23T15:36:26.113Z',
//         TicketStatusNumber: 1,
//         TicketStatus: 'Ganada',
//         IsPushWinning: false,
//         Stake: 2,
//         BetType: 'Múltiple',
//         BetTypeNumber: '3',
//         X: 3,
//         Y: 5,
//         PotentialWinnings: 3.47,
//         PotentialWinningsOriginal: 41.18,
//         CancelationReason: null,
//         CashOutAmount: 0,
//         CashOutStatus: 0,
//         VoucherCode: null,
//         BetsNumber: 200,
//         StatusCode: 0,
//         Pin: '8086',
//         BetEvent: [
//           {
//             IsBetbuilder: false,
//             SportName: 'Fútbol',
//             CountryName: 'España',
//             LeagueName: 'Primera División',
//             EventName: 'Espanyol - Sevilla',
//             EventId: 9997494406,
//             MarketName: '1X2',
//             ResultName: 'Espanyol',
//             ClearingStatusNumber: '3',
//             ClearingStatus: 'Perdida',
//             EventDatetime: '2024-10-25T19:00:00Z',
//             Odd: 2.85,
//             ResultsNr: 9997494844,
//             IsBank: false
//           },
//           {
//             IsBetbuilder: false,
//             SportName: 'Fútbol',
//             CountryName: 'España',
//             LeagueName: 'Primera División',
//             EventName: 'Rayo - Alavés',
//             EventId: 9997509234,
//             MarketName: '1X2',
//             ResultName: 'X',
//             ClearingStatusNumber: '3',
//             ClearingStatus: 'Perdida',
//             EventDatetime: '2024-10-26T14:15:00Z',
//             Odd: 3,
//             ResultsNr: 9997510013,
//             IsBank: false
//           },
//           {
//             IsBetbuilder: false,
//             SportName: 'Fútbol',
//             CountryName: 'España',
//             LeagueName: 'Primera División',
//             EventName: 'Real Valladolid - Villarreal',
//             EventId: 9997488867,
//             MarketName: '1X2',
//             ResultName: 'Villarreal',
//             ClearingStatusNumber: '1',
//             ClearingStatus: 'Ganada',
//             EventDatetime: '2024-10-26T12:00:00Z',
//             Odd: 1.9,
//             ResultsNr: 9997489529,
//             IsBank: false
//           },
//           {
//             IsBetbuilder: false,
//             SportName: 'Fútbol',
//             CountryName: 'España',
//             LeagueName: 'Primera División',
//             EventName: 'Real Madrid - Barcelona',
//             EventId: 9997546112,
//             MarketName: '1X2',
//             ResultName: 'Barcelona',
//             ClearingStatusNumber: '1',
//             ClearingStatus: 'Ganada',
//             EventDatetime: '2024-10-26T19:00:00Z',
//             Odd: 3.2,
//             ResultsNr: 9997546633,
//             IsBank: false
//           },
//           {
//             IsBetbuilder: false,
//             SportName: 'Fútbol',
//             CountryName: 'España',
//             LeagueName: 'Primera División',
//             EventName: 'Las Palmas - Girona',
//             EventId: 9997513792,
//             MarketName: '1X2',
//             ResultName: 'Las Palmas',
//             ClearingStatusNumber: '1',
//             ClearingStatus: 'Ganada',
//             EventDatetime: '2024-10-26T16:30:00Z',
//             Odd: 2.85,
//             ResultsNr: 9997514416,
//             IsBank: false
//           }
//         ],
//         HasBetbuilder: false
//       },
//       {
//         TicketOrderNr: 3,
//         TicketNumber: 327185391,
//         TicketDatetime: '2024-10-23T15:35:46.303Z',
//         TicketStatusNumber: 3,
//         TicketStatus: 'Perdida',
//         IsPushWinning: false,
//         Stake: 1,
//         BetType: 'Sencilla',
//         BetTypeNumber: '1',
//         X: 1,
//         Y: 1,
//         PotentialWinnings: 3.6,
//         PotentialWinningsOriginal: 3.6,
//         CancelationReason: null,
//         CashOutAmount: 0,
//         CashOutStatus: 0,
//         VoucherCode: null,
//         BetsNumber: 100,
//         StatusCode: 0,
//         Pin: '3761',
//         BetEvent: [
//           {
//             IsBetbuilder: false,
//             SportName: 'Fútbol',
//             CountryName: 'España',
//             LeagueName: 'Primera División',
//             EventName: 'Real Madrid - Barcelona',
//             EventId: 9997546112,
//             MarketName: '1X2',
//             ResultName: 'X',
//             ClearingStatusNumber: '3',
//             ClearingStatus: 'Perdida',
//             EventDatetime: '2024-10-26T19:00:00Z',
//             Odd: 3.6,
//             ResultsNr: 9997546634,
//             IsBank: false
//           }
//         ],
//         HasBetbuilder: false
//       },
//       {
//         TicketOrderNr: 4,
//         TicketNumber: 327185392,
//         TicketDatetime: '2024-10-23T15:35:46.303Z',
//         TicketStatusNumber: 3,
//         TicketStatus: 'Perdida',
//         IsPushWinning: false,
//         Stake: 1,
//         BetType: 'Sencilla',
//         BetTypeNumber: '1',
//         X: 1,
//         Y: 1,
//         PotentialWinnings: 2.25,
//         PotentialWinningsOriginal: 2.25,
//         CancelationReason: null,
//         CashOutAmount: 0,
//         CashOutStatus: 0,
//         VoucherCode: null,
//         BetsNumber: 100,
//         StatusCode: 0,
//         Pin: '6876',
//         BetEvent: [
//           {
//             IsBetbuilder: false,
//             SportName: 'Fútbol',
//             CountryName: 'España',
//             LeagueName: 'Primera División',
//             EventName: 'Real Betis - Atlético',
//             EventId: 10029459998,
//             MarketName: '1X2',
//             ResultName: 'Atlético',
//             ClearingStatusNumber: '3',
//             ClearingStatus: 'Perdida',
//             EventDatetime: '2024-10-27T17:30:00Z',
//             Odd: 2.25,
//             ResultsNr: 10029460532,
//             IsBank: false
//           }
//         ],
//         HasBetbuilder: false
//       },
//       {
//         TicketOrderNr: 5,
//         TicketNumber: 327185393,
//         TicketDatetime: '2024-10-23T15:35:46.303Z',
//         TicketStatusNumber: 3,
//         TicketStatus: 'Perdida',
//         IsPushWinning: false,
//         Stake: 1,
//         BetType: 'Sencilla',
//         BetTypeNumber: '1',
//         X: 1,
//         Y: 1,
//         PotentialWinnings: 2.85,
//         PotentialWinningsOriginal: 2.85,
//         CancelationReason: null,
//         CashOutAmount: 0,
//         CashOutStatus: 0,
//         VoucherCode: null,
//         BetsNumber: 100,
//         StatusCode: 0,
//         Pin: '9147',
//         BetEvent: [
//           {
//             IsBetbuilder: false,
//             SportName: 'Fútbol',
//             CountryName: 'España',
//             LeagueName: 'Primera División',
//             EventName: 'Espanyol - Sevilla',
//             EventId: 9997494406,
//             MarketName: '1X2',
//             ResultName: 'Espanyol',
//             ClearingStatusNumber: '3',
//             ClearingStatus: 'Perdida',
//             EventDatetime: '2024-10-25T19:00:00Z',
//             Odd: 2.85,
//             ResultsNr: 9997494844,
//             IsBank: false
//           }
//         ],
//         HasBetbuilder: false
//       },
//       {
//         TicketOrderNr: 6,
//         TicketNumber: 327185394,
//         TicketDatetime: '2024-10-23T15:35:46.303Z',
//         TicketStatusNumber: 3,
//         TicketStatus: 'Perdida',
//         IsPushWinning: false,
//         Stake: 1,
//         BetType: 'Sencilla',
//         BetTypeNumber: '1',
//         X: 1,
//         Y: 1,
//         PotentialWinnings: 3.5,
//         PotentialWinningsOriginal: 3.5,
//         CancelationReason: null,
//         CashOutAmount: 0,
//         CashOutStatus: 0,
//         VoucherCode: null,
//         BetsNumber: 100,
//         StatusCode: 0,
//         Pin: '9403',
//         BetEvent: [
//           {
//             IsBetbuilder: false,
//             SportName: 'Fútbol',
//             CountryName: 'España',
//             LeagueName: 'Primera División',
//             EventName: 'Rayo - Alavés',
//             EventId: 9997509234,
//             MarketName: '1X2',
//             ResultName: 'Alavés',
//             ClearingStatusNumber: '3',
//             ClearingStatus: 'Perdida',
//             EventDatetime: '2024-10-26T14:15:00Z',
//             Odd: 3.5,
//             ResultsNr: 9997510012,
//             IsBank: false
//           }
//         ],
//         HasBetbuilder: false
//       },
//       {
//         TicketOrderNr: 7,
//         TicketNumber: 327185395,
//         TicketDatetime: '2024-10-23T15:35:46.303Z',
//         TicketStatusNumber: 3,
//         TicketStatus: 'Perdida',
//         IsPushWinning: false,
//         Stake: 1,
//         BetType: 'Sencilla',
//         BetTypeNumber: '1',
//         X: 1,
//         Y: 1,
//         PotentialWinnings: 2.85,
//         PotentialWinningsOriginal: 2.85,
//         CancelationReason: null,
//         CashOutAmount: 0,
//         CashOutStatus: 0,
//         VoucherCode: null,
//         BetsNumber: 100,
//         StatusCode: 0,
//         Pin: '4108',
//         BetEvent: [
//           {
//             IsBetbuilder: false,
//             SportName: 'Fútbol',
//             CountryName: 'España',
//             LeagueName: 'Primera División',
//             EventName: 'Mallorca - Athletic',
//             EventId: 10029463073,
//             MarketName: '1X2',
//             ResultName: 'Mallorca',
//             ClearingStatusNumber: '3',
//             ClearingStatus: 'Perdida',
//             EventDatetime: '2024-10-28T20:00:00Z',
//             Odd: 2.85,
//             ResultsNr: 10029463613,
//             IsBank: false
//           }
//         ],
//         HasBetbuilder: false
//       },
//       {
//         TicketOrderNr: 2,
//         TicketNumber: 327185390,
//         TicketDatetime: '2024-10-23T15:35:18.667Z',
//         TicketStatusNumber: 3,
//         TicketStatus: 'Perdida',
//         IsPushWinning: false,
//         Stake: 1,
//         BetType: 'Acumulada',
//         BetTypeNumber: '2',
//         X: 4,
//         Y: 4,
//         PotentialWinnings: 123.89,
//         PotentialWinningsOriginal: 123.89,
//         CancelationReason: null,
//         CashOutAmount: 0,
//         CashOutStatus: 0,
//         VoucherCode: null,
//         BetsNumber: 100,
//         StatusCode: 0,
//         Pin: '7571',
//         BetEvent: [
//           {
//             IsBetbuilder: false,
//             SportName: 'Fútbol',
//             CountryName: 'España',
//             LeagueName: 'Primera División',
//             EventName: 'Espanyol - Sevilla',
//             EventId: 9997494406,
//             MarketName: '1X2',
//             ResultName: 'Espanyol',
//             ClearingStatusNumber: '3',
//             ClearingStatus: 'Perdida',
//             EventDatetime: '2024-10-25T19:00:00Z',
//             Odd: 2.85,
//             ResultsNr: 9997494844,
//             IsBank: false
//           },
//           {
//             IsBetbuilder: false,
//             SportName: 'Fútbol',
//             CountryName: 'España',
//             LeagueName: 'Primera División',
//             EventName: 'Real Valladolid - Villarreal',
//             EventId: 9997488867,
//             MarketName: '1X2',
//             ResultName: 'X',
//             ClearingStatusNumber: '3',
//             ClearingStatus: 'Perdida',
//             EventDatetime: '2024-10-26T12:00:00Z',
//             Odd: 3.45,
//             ResultsNr: 9997489530,
//             IsBank: false
//           },
//           {
//             IsBetbuilder: false,
//             SportName: 'Fútbol',
//             CountryName: 'España',
//             LeagueName: 'Primera División',
//             EventName: 'Rayo - Alavés',
//             EventId: 9997509234,
//             MarketName: '1X2',
//             ResultName: 'Alavés',
//             ClearingStatusNumber: '3',
//             ClearingStatus: 'Perdida',
//             EventDatetime: '2024-10-26T14:15:00Z',
//             Odd: 3.5,
//             ResultsNr: 9997510012,
//             IsBank: false
//           },
//           {
//             IsBetbuilder: false,
//             SportName: 'Fútbol',
//             CountryName: 'España',
//             LeagueName: 'Primera División',
//             EventName: 'Real Madrid - Barcelona',
//             EventId: 9997546112,
//             MarketName: '1X2',
//             ResultName: 'X',
//             ClearingStatusNumber: '3',
//             ClearingStatus: 'Perdida',
//             EventDatetime: '2024-10-26T19:00:00Z',
//             Odd: 3.6,
//             ResultsNr: 9997546634,
//             IsBank: false
//           }
//         ],
//         HasBetbuilder: false
//       },
//       {
//         TicketOrderNr: 1,
//         TicketNumber: 327185389,
//         TicketDatetime: '2024-10-23T15:34:04.89Z',
//         TicketStatusNumber: 3,
//         TicketStatus: 'Perdida',
//         IsPushWinning: false,
//         Stake: 1,
//         BetType: 'Sencilla',
//         BetTypeNumber: '1',
//         X: 1,
//         Y: 1,
//         PotentialWinnings: 1.18,
//         PotentialWinningsOriginal: 1.18,
//         CancelationReason: null,
//         CashOutAmount: 0,
//         CashOutStatus: 0,
//         VoucherCode: null,
//         BetsNumber: 100,
//         StatusCode: 0,
//         Pin: '8274',
//         BetEvent: [
//           {
//             IsBetbuilder: false,
//             SportName: 'Fútbol',
//             CountryName: 'México',
//             LeagueName: 'Liga S23 MX',
//             EventName: 'Cruz Azul S23 - FC Juarez S23',
//             EventId: 10072217554,
//             MarketName: '1X2',
//             ResultName: 'Cruz Azul S23',
//             ClearingStatusNumber: '3',
//             ClearingStatus: 'Perdida',
//             EventDatetime: '2024-10-23T15:00:00Z',
//             Odd: 1.18,
//             ResultsNr: 10076706781,
//             IsBank: false
//           }
//         ],
//         HasBetbuilder: false
//       }
//     ]
//   },
//   {
//     DayOrderNr: 1,
//     TicketsDatetime: '2024-10-24T09:13:07.27Z',
//     Tickets: [
//       {
//         TicketOrderNr: 15,
//         TicketNumber: 327185430,
//         TicketDatetime: '2024-10-24T09:15:17.637Z',
//         TicketStatusNumber: 1,
//         TicketStatus: 'Ganada',
//         IsPushWinning: false,
//         Stake: 2,
//         BetType: 'Múltiple',
//         BetTypeNumber: '3',
//         X: 2,
//         Y: 5,
//         PotentialWinnings: 1.74,
//         PotentialWinningsOriginal: 16.75,
//         CancelationReason: null,
//         CashOutAmount: 0,
//         CashOutStatus: 0,
//         VoucherCode: null,
//         BetsNumber: 200,
//         StatusCode: 0,
//         Pin: '1087',
//         BetEvent: [
//           {
//             IsBetbuilder: false,
//             SportName: 'Fútbol',
//             CountryName: 'España',
//             LeagueName: 'Primera División',
//             EventName: 'Getafe - Valencia',
//             EventId: 10029458478,
//             MarketName: '1X2',
//             ResultName: 'X',
//             ClearingStatusNumber: '1',
//             ClearingStatus: 'Ganada',
//             EventDatetime: '2024-10-27T15:15:00Z',
//             Odd: 2.85,
//             ResultsNr: 10029458924,
//             IsBank: false
//           },
//           {
//             IsBetbuilder: false,
//             SportName: 'Fútbol',
//             CountryName: 'España',
//             LeagueName: 'Primera División',
//             EventName: 'Real Betis - Atlético',
//             EventId: 10029459998,
//             MarketName: '1X2',
//             ResultName: 'Atlético',
//             ClearingStatusNumber: '3',
//             ClearingStatus: 'Perdida',
//             EventDatetime: '2024-10-27T17:30:00Z',
//             Odd: 2.25,
//             ResultsNr: 10029460532,
//             IsBank: false
//           },
//           {
//             IsBetbuilder: false,
//             SportName: 'Fútbol',
//             CountryName: 'España',
//             LeagueName: 'Primera División',
//             EventName: 'Real Sociedad - Osasuna',
//             EventId: 10029461378,
//             MarketName: '1X2',
//             ResultName: 'X',
//             ClearingStatusNumber: '3',
//             ClearingStatus: 'Perdida',
//             EventDatetime: '2024-10-27T20:00:00Z',
//             Odd: 3.35,
//             ResultsNr: 10029461852,
//             IsBank: false
//           },
//           {
//             IsBetbuilder: false,
//             SportName: 'Fútbol',
//             CountryName: 'España',
//             LeagueName: 'Primera División',
//             EventName: 'Real Madrid - Barcelona',
//             EventId: 9997546112,
//             MarketName: '1X2',
//             ResultName: 'Barcelona',
//             ClearingStatusNumber: '1',
//             ClearingStatus: 'Ganada',
//             EventDatetime: '2024-10-26T19:00:00Z',
//             Odd: 3.05,
//             ResultsNr: 9997546633,
//             IsBank: false
//           },
//           {
//             IsBetbuilder: false,
//             SportName: 'Fútbol',
//             CountryName: 'España',
//             LeagueName: 'Primera División',
//             EventName: 'Rayo - Alavés',
//             EventId: 9997509234,
//             MarketName: '1X2',
//             ResultName: 'X',
//             ClearingStatusNumber: '3',
//             ClearingStatus: 'Perdida',
//             EventDatetime: '2024-10-26T14:15:00Z',
//             Odd: 3,
//             ResultsNr: 9997510013,
//             IsBank: false
//           }
//         ],
//         HasBetbuilder: false
//       },
//       {
//         TicketOrderNr: 13,
//         TicketNumber: 327185428,
//         TicketDatetime: '2024-10-24T09:13:49.977Z',
//         TicketStatusNumber: 3,
//         TicketStatus: 'Perdida',
//         IsPushWinning: false,
//         Stake: 1,
//         BetType: 'Sencilla',
//         BetTypeNumber: '1',
//         X: 1,
//         Y: 1,
//         PotentialWinnings: 2.35,
//         PotentialWinningsOriginal: 2.35,
//         CancelationReason: null,
//         CashOutAmount: 0,
//         CashOutStatus: 0,
//         VoucherCode: null,
//         BetsNumber: 100,
//         StatusCode: 0,
//         Pin: '1569',
//         BetEvent: [
//           {
//             IsBetbuilder: false,
//             SportName: 'Fútbol',
//             CountryName: 'España',
//             LeagueName: 'Primera División',
//             EventName: 'Mallorca - Athletic',
//             EventId: 10029463073,
//             MarketName: '1X2',
//             ResultName: 'Athletic',
//             ClearingStatusNumber: '3',
//             ClearingStatus: 'Perdida',
//             EventDatetime: '2024-10-28T20:00:00Z',
//             Odd: 2.35,
//             ResultsNr: 10029463611,
//             IsBank: false
//           }
//         ],
//         HasBetbuilder: false
//       },
//       {
//         TicketOrderNr: 14,
//         TicketNumber: 327185429,
//         TicketDatetime: '2024-10-24T09:13:49.977Z',
//         TicketStatusNumber: 3,
//         TicketStatus: 'Perdida',
//         IsPushWinning: false,
//         Stake: 2,
//         BetType: 'Sencilla',
//         BetTypeNumber: '1',
//         X: 1,
//         Y: 1,
//         PotentialWinnings: 6.7,
//         PotentialWinningsOriginal: 6.7,
//         CancelationReason: null,
//         CashOutAmount: 0,
//         CashOutStatus: 0,
//         VoucherCode: null,
//         BetsNumber: 200,
//         StatusCode: 0,
//         Pin: '7038',
//         BetEvent: [
//           {
//             IsBetbuilder: false,
//             SportName: 'Fútbol',
//             CountryName: 'España',
//             LeagueName: 'Primera División',
//             EventName: 'Real Sociedad - Osasuna',
//             EventId: 10029461378,
//             MarketName: '1X2',
//             ResultName: 'X',
//             ClearingStatusNumber: '3',
//             ClearingStatus: 'Perdida',
//             EventDatetime: '2024-10-27T20:00:00Z',
//             Odd: 3.35,
//             ResultsNr: 10029461852,
//             IsBank: false
//           }
//         ],
//         HasBetbuilder: false
//       },
//       {
//         TicketOrderNr: 12,
//         TicketNumber: 327185427,
//         TicketDatetime: '2024-10-24T09:13:24.387Z',
//         TicketStatusNumber: 3,
//         TicketStatus: 'Perdida',
//         IsPushWinning: false,
//         Stake: 1,
//         BetType: 'Acumulada',
//         BetTypeNumber: '2',
//         X: 4,
//         Y: 4,
//         PotentialWinnings: 101.28,
//         PotentialWinningsOriginal: 101.28,
//         CancelationReason: null,
//         CashOutAmount: 0,
//         CashOutStatus: 0,
//         VoucherCode: null,
//         BetsNumber: 100,
//         StatusCode: 0,
//         Pin: '9140',
//         BetEvent: [
//           {
//             IsBetbuilder: false,
//             SportName: 'Fútbol',
//             CountryName: 'España',
//             LeagueName: 'Primera División',
//             EventName: 'Rayo - Alavés',
//             EventId: 9997509234,
//             MarketName: '1X2',
//             ResultName: 'Alavés',
//             ClearingStatusNumber: '3',
//             ClearingStatus: 'Perdida',
//             EventDatetime: '2024-10-26T14:15:00Z',
//             Odd: 3.5,
//             ResultsNr: 9997510012,
//             IsBank: false
//           },
//           {
//             IsBetbuilder: false,
//             SportName: 'Fútbol',
//             CountryName: 'España',
//             LeagueName: 'Primera División',
//             EventName: 'Real Valladolid - Villarreal',
//             EventId: 9997488867,
//             MarketName: '1X2',
//             ResultName: 'X',
//             ClearingStatusNumber: '3',
//             ClearingStatus: 'Perdida',
//             EventDatetime: '2024-10-26T12:00:00Z',
//             Odd: 3.45,
//             ResultsNr: 9997489530,
//             IsBank: false
//           },
//           {
//             IsBetbuilder: false,
//             SportName: 'Fútbol',
//             CountryName: 'España',
//             LeagueName: 'Primera División',
//             EventName: 'Las Palmas - Girona',
//             EventId: 9997513792,
//             MarketName: '1X2',
//             ResultName: 'Las Palmas',
//             ClearingStatusNumber: '1',
//             ClearingStatus: 'Ganada',
//             EventDatetime: '2024-10-26T16:30:00Z',
//             Odd: 2.75,
//             ResultsNr: 9997514416,
//             IsBank: false
//           },
//           {
//             IsBetbuilder: false,
//             SportName: 'Fútbol',
//             CountryName: 'España',
//             LeagueName: 'Primera División',
//             EventName: 'Leganés - Celta',
//             EventId: 10029457142,
//             MarketName: '1X2',
//             ResultName: 'X',
//             ClearingStatusNumber: '3',
//             ClearingStatus: 'Perdida',
//             EventDatetime: '2024-10-27T13:00:00Z',
//             Odd: 3.05,
//             ResultsNr: 10029457599,
//             IsBank: false
//           }
//         ],
//         HasBetbuilder: false
//       },
//       {
//         TicketOrderNr: 11,
//         TicketNumber: 327185425,
//         TicketDatetime: '2024-10-24T09:13:07.27Z',
//         TicketStatusNumber: 3,
//         TicketStatus: 'Perdida',
//         IsPushWinning: false,
//         Stake: 1,
//         BetType: 'Sencilla',
//         BetTypeNumber: '1',
//         X: 1,
//         Y: 1,
//         PotentialWinnings: 2.85,
//         PotentialWinningsOriginal: 2.85,
//         CancelationReason: null,
//         CashOutAmount: 0,
//         CashOutStatus: 0,
//         VoucherCode: null,
//         BetsNumber: 100,
//         StatusCode: 0,
//         Pin: '3935',
//         BetEvent: [
//           {
//             IsBetbuilder: false,
//             SportName: 'Fútbol',
//             CountryName: 'España',
//             LeagueName: 'Primera División',
//             EventName: 'Espanyol - Sevilla',
//             EventId: 9997494406,
//             MarketName: '1X2',
//             ResultName: 'Espanyol',
//             ClearingStatusNumber: '3',
//             ClearingStatus: 'Perdida',
//             EventDatetime: '2024-10-25T19:00:00Z',
//             Odd: 2.85,
//             ResultsNr: 9997494844,
//             IsBank: false
//           }
//         ],
//         HasBetbuilder: false
//       }
//     ]
//   },
//   {
//     DayOrderNr: 2,
//     TicketsDatetime: '2024-10-28T10:16:56.803Z',
//     Tickets: [
//       {
//         TicketOrderNr: 17,
//         TicketNumber: 327185572,
//         TicketDatetime: '2024-10-28T10:17:45.56Z',
//         TicketStatusNumber: 3,
//         TicketStatus: 'Perdida',
//         IsPushWinning: false,
//         Stake: 10,
//         BetType: 'Acumulada BetBuilder',
//         BetTypeNumber: '24',
//         X: 5,
//         Y: 5,
//         PotentialWinnings: 10776.56,
//         PotentialWinningsOriginal: 10776.56,
//         CancelationReason: null,
//         CashOutAmount: 0,
//         CashOutStatus: 0,
//         VoucherCode: null,
//         BetsNumber: 1000,
//         StatusCode: 0,
//         Pin: '5781',
//         BetEvent: [
//           {
//             IsBetbuilder: true,
//             SportName: 'Fútbol',
//             CountryName: 'España',
//             LeagueName: 'Primera División',
//             EventName: 'Valencia - Real Madrid',
//             EventId: 10067579678,
//             MarketName: 'Marcan Ambos Equipos',
//             ResultName: 'Sí',
//             ClearingStatusNumber: '4',
//             ClearingStatus: 'Abierta',
//             EventDatetime: '2024-11-02T20:00:00Z',
//             Odd: 1.9,
//             ResultsNr: 10067579917,
//             IsBank: false,
//             BetBuilderItems: [
//               {
//                 SportName: 'Fútbol',
//                 CountryName: 'España',
//                 LeagueName: 'Primera División',
//                 EventName: 'Valencia - Real Madrid',
//                 EventId: 10067579678,
//                 MarketName: 'Marcan Ambos Equipos',
//                 ResultName: 'Sí',
//                 ClearingStatusNumber: '4',
//                 ClearingStatus: 'Abierta',
//                 EventDatetime: '2024-11-02T20:00:00Z',
//                 Odd: 1.9,
//                 ResultsNr: 10067579917,
//                 IsBank: false
//               },
//               {
//                 SportName: 'Fútbol',
//                 CountryName: 'España',
//                 LeagueName: 'Primera División',
//                 EventName: 'Valencia - Real Madrid',
//                 EventId: 10067579678,
//                 MarketName: 'Resultado Final',
//                 ResultName: '2:1',
//                 ClearingStatusNumber: '4',
//                 ClearingStatus: 'Abierta',
//                 EventDatetime: '2024-11-02T20:00:00Z',
//                 Odd: 19,
//                 ResultsNr: 10067579951,
//                 IsBank: false
//               },
//               {
//                 SportName: 'Fútbol',
//                 CountryName: 'España',
//                 LeagueName: 'Primera División',
//                 EventName: 'Valencia - Real Madrid',
//                 EventId: 10067579678,
//                 MarketName: '1X2',
//                 ResultName: 'Valencia',
//                 ClearingStatusNumber: '4',
//                 ClearingStatus: 'Abierta',
//                 EventDatetime: '2024-11-02T20:00:00Z',
//                 Odd: 6.25,
//                 ResultsNr: 10067580128,
//                 IsBank: false
//               }
//             ]
//           },
//           {
//             IsBetbuilder: false,
//             SportName: 'Fútbol',
//             CountryName: 'España',
//             LeagueName: 'Primera División',
//             EventName: 'Mallorca - Athletic',
//             EventId: 10029463073,
//             MarketName: '1X2',
//             ResultName: 'Mallorca',
//             ClearingStatusNumber: '3',
//             ClearingStatus: 'Perdida',
//             EventDatetime: '2024-10-28T20:00:00Z',
//             Odd: 2.75,
//             ResultsNr: 10029463613,
//             IsBank: false
//           },
//           {
//             IsBetbuilder: false,
//             SportName: 'Fútbol',
//             CountryName: 'España',
//             LeagueName: 'Primera División',
//             EventName: 'Alavés - Mallorca',
//             EventId: 10067624039,
//             MarketName: '1X2',
//             ResultName: 'X',
//             ClearingStatusNumber: '4',
//             ClearingStatus: 'Abierta',
//             EventDatetime: '2024-11-01T20:00:00Z',
//             Odd: 2.75,
//             ResultsNr: 10067624484,
//             IsBank: false
//           },
//           {
//             IsBetbuilder: false,
//             SportName: 'Fútbol',
//             CountryName: 'España',
//             LeagueName: 'Primera División',
//             EventName: 'Girona - Leganés',
//             EventId: 10067882336,
//             MarketName: '1X2',
//             ResultName: 'Girona',
//             ClearingStatusNumber: '4',
//             ClearingStatus: 'Abierta',
//             EventDatetime: '2024-11-02T15:15:00Z',
//             Odd: 1.5,
//             ResultsNr: 10067882788,
//             IsBank: false
//           },
//           {
//             IsBetbuilder: false,
//             SportName: 'Fútbol',
//             CountryName: 'España',
//             LeagueName: 'Primera División',
//             EventName: 'Atlético - Las Palmas',
//             EventId: 10068877009,
//             MarketName: '1X2',
//             ResultName: 'X',
//             ClearingStatusNumber: '4',
//             ClearingStatus: 'Abierta',
//             EventDatetime: '2024-11-03T13:00:00Z',
//             Odd: 5,
//             ResultsNr: 10068877459,
//             IsBank: false
//           }
//         ],
//         HasBetbuilder: true
//       },
//       {
//         TicketOrderNr: 16,
//         TicketNumber: 327185571,
//         TicketDatetime: '2024-10-28T10:16:56.803Z',
//         TicketStatusNumber: 4,
//         TicketStatus: 'Abierta',
//         IsPushWinning: false,
//         Stake: 10,
//         BetType: 'Múltiple BetBuilder',
//         BetTypeNumber: '34',
//         X: 2,
//         Y: 5,
//         PotentialWinnings: 134.49,
//         PotentialWinningsOriginal: 134.49,
//         CancelationReason: null,
//         CashOutAmount: 0,
//         CashOutStatus: -180001,
//         VoucherCode: null,
//         BetsNumber: 1000,
//         StatusCode: 0,
//         Pin: '6438',
//         BetEvent: [
//           {
//             IsBetbuilder: true,
//             SportName: 'Fútbol',
//             CountryName: 'España',
//             LeagueName: 'Primera División',
//             EventName: 'Mallorca - Athletic',
//             EventId: 10029463073,
//             MarketName: 'Marcan Ambos Equipos',
//             ResultName: 'Sí',
//             ClearingStatusNumber: '3',
//             ClearingStatus: 'Perdida',
//             EventDatetime: '2024-10-28T20:00:00Z',
//             Odd: 2,
//             ResultsNr: 10029463402,
//             IsBank: false,
//             BetBuilderItems: [
//               {
//                 SportName: 'Fútbol',
//                 CountryName: 'España',
//                 LeagueName: 'Primera División',
//                 EventName: 'Mallorca - Athletic',
//                 EventId: 10029463073,
//                 MarketName: 'Marcan Ambos Equipos',
//                 ResultName: 'Sí',
//                 ClearingStatusNumber: '3',
//                 ClearingStatus: 'Perdida',
//                 EventDatetime: '2024-10-28T20:00:00Z',
//                 Odd: 2,
//                 ResultsNr: 10029463402,
//                 IsBank: false
//               },
//               {
//                 SportName: 'Fútbol',
//                 CountryName: 'España',
//                 LeagueName: 'Primera División',
//                 EventName: 'Mallorca - Athletic',
//                 EventId: 10029463073,
//                 MarketName: 'Primer Equipo en Marcar',
//                 ResultName: 'Mallorca',
//                 ClearingStatusNumber: '3',
//                 ClearingStatus: 'Perdida',
//                 EventDatetime: '2024-10-28T20:00:00Z',
//                 Odd: 2.15,
//                 ResultsNr: 10029463578,
//                 IsBank: false
//               },
//               {
//                 SportName: 'Fútbol',
//                 CountryName: 'España',
//                 LeagueName: 'Primera División',
//                 EventName: 'Mallorca - Athletic',
//                 EventId: 10029463073,
//                 MarketName: '1X2',
//                 ResultName: 'Mallorca',
//                 ClearingStatusNumber: '3',
//                 ClearingStatus: 'Perdida',
//                 EventDatetime: '2024-10-28T20:00:00Z',
//                 Odd: 2.75,
//                 ResultsNr: 10029463613,
//                 IsBank: false
//               }
//             ]
//           },
//           {
//             IsBetbuilder: false,
//             SportName: 'Fútbol',
//             CountryName: 'España',
//             LeagueName: 'Primera División',
//             EventName: 'Villarreal - Rayo',
//             EventId: 10069461930,
//             MarketName: '1X2',
//             ResultName: 'X',
//             ClearingStatusNumber: '4',
//             ClearingStatus: 'Abierta',
//             EventDatetime: '2024-11-02T17:30:00Z',
//             Odd: 3.4,
//             ResultsNr: 10069462358,
//             IsBank: false
//           },
//           {
//             IsBetbuilder: false,
//             SportName: 'Fútbol',
//             CountryName: 'España',
//             LeagueName: 'Primera División',
//             EventName: 'Sevilla - Real Sociedad',
//             EventId: 10067622550,
//             MarketName: '1X2',
//             ResultName: 'Sevilla',
//             ClearingStatusNumber: '4',
//             ClearingStatus: 'Abierta',
//             EventDatetime: '2024-11-03T17:30:00Z',
//             Odd: 2.7,
//             ResultsNr: 10067622983,
//             IsBank: false
//           },
//           {
//             IsBetbuilder: false,
//             SportName: 'Fútbol',
//             CountryName: 'España',
//             LeagueName: 'Primera División',
//             EventName: 'Alavés - Mallorca',
//             EventId: 10067624039,
//             MarketName: '1X2',
//             ResultName: 'X',
//             ClearingStatusNumber: '4',
//             ClearingStatus: 'Abierta',
//             EventDatetime: '2024-11-01T20:00:00Z',
//             Odd: 2.75,
//             ResultsNr: 10067624484,
//             IsBank: false
//           },
//           {
//             IsBetbuilder: false,
//             SportName: 'Fútbol',
//             CountryName: 'España',
//             LeagueName: 'Primera División',
//             EventName: 'Osasuna - Real Valladolid',
//             EventId: 10067618162,
//             MarketName: '1X2',
//             ResultName: 'Osasuna',
//             ClearingStatusNumber: '4',
//             ClearingStatus: 'Abierta',
//             EventDatetime: '2024-11-02T13:00:00Z',
//             Odd: 1.62,
//             ResultsNr: 10067618841,
//             IsBank: false
//           }
//         ],
//         HasBetbuilder: true
//       }
//     ]
//   }
// ];

export interface MyBetsState {
  myBetsTickets: I_MyBetsModel[];
  initialStatus: boolean;
}

export const initialState: MyBetsState = {
  myBetsTickets: [],
  initialStatus: true
};

export const myBetsReducer = createReducer(
  initialState,
  on(MyBetsActions.SetMyBets, (state, myBetsTickets) => ({
    ...state,
    initialStatus: false,
    myBetsTickets: myBetsTickets.myBetsTickets
  }))
);
