export class C_ActionsLiveData {
  ParticipantHome: string;
  ParticipantAway: string;
  MatchTime: number;
  YellowCardsHome: number;
  RedCardsHome: number;
  YellowCardsAway: number;
  RedCardsAway: number;
  Period: number;
  PeriodName: string;
  Actions: Array<any>;
  ResultHome: number;
  ResultAway: number;
  Time: string;
}
