// SPORTS MISC
export const AccountGetCurrentSessionStart = "Account/GetCurrentSessionStart";
export const CodereCardAddClientCard = "Card/AddClientCard";
export const CodereCardAddNewCardToClient = "Card/AddNewCardToClient";
export const CodereCardCardRequestExists = "Card/CardRequestExists";
export const CodereCardCardStatus = "Card/CardStatus";
export const CodereCardCheckCardLocked = "Card/CheckCardLocked";
export const CodereCardCreateCardRequest = "Card/CreateCardRequest";
export const CodereCardDocAndResidentNotAllowed = "Card/DocAndResidentNotAllowed";
export const CodereCardGetAllowedRegions = "Card/GetAllowedRegions";
export const CodereCardGetCardHistory = "Card/GetCardHistory";
export const CodereCardGetClientCardBalance = "Card/GetClientCardBalance";
export const CodereCardGetUserCardNumber = "Card/GetUserCardNumber";
export const CodereCardHasCardNif = "Card/HasCardNif";
export const CodereCardReuseCard = "Card/ReuseCard";
export const CodereCardSetNewPin = "Card/SetNewPin";
export const CodereCardUnlockedCard = "Card/UnlockedCard";
export const CodereCardValidatePasswordAndCard = "Card/ValidatePasswordAndCard";
export const CustomizationAddParameter = "Customization/SetAutoAcceptOddConfig";
export const CustomizationGetParameters = "Customization/GetParameters";
export const getTicketInfoMonolith = "orderform/GetTicketInfo";
export const OrderFormGetTicketInfoCard = "Card/GetTicketInfo";
export const OrderFormPayOutBetTicketCard = "Card/PayOutBetTicket";
export const OrderFormPayOutBetTicketTicket = "Ticket/PayOutBetTicket";

// SPORTS MISC TO MONOLITH
// export const GetCurrentSessionStartMonolith = "/account/GetCurrentSessionStart";
// NOTE: In monolith we make two calls to endpoint AddParameter. In SportsMisc, only one call to endpoint will set both parameters
// //! Not in use
// export const AccountCheckUserLogged = "Account/CheckUserLogged";
// //! Not in use
// //! Not in use
// export const AccountGetCurrentUser = "Account/GetCurrentUser";
// //! Not in use
// export const CodereCardUpdateCard = "Card/UpdateCard";

// License Meta tags language
export const licenseEspañaLanguage = "es";
export const licenseMexicoLanguage = "es-mx";
export const licenseColombiaLanguage = "es-co";
export const licensePanamaLanguage = "es-pa";
export const licenseArgentinaCabaLanguage = "es-ar";
export const licenseArgentinaPBALanguage = "es-ar";
export const licenseArgentinaCordobaLanguage = "es-ar";
export const licenseArgentinaMendozaLanguage = "es-ar";

export const getCodereFantasyCode = "/HeroPicks/GetUserCode";
export const getDgaFantasyDev = "https://codere.dgafantasy-dev.com/league?operatorKey=";
export const getDgaFantasy = "https://codere.dgafantasy.com/league?operatorKey=";

/**
 * Literals
 */
export const today = "today";
export const tomorrow = "tomorrow";
export const SPO_BetbuilderMessage2 = "Necesitas al menos 2 selecciones.";
export const SPO_BetbuilderMessage1 = "Añadir al menos 1 o más selecciones.";
export const SPO_BetbuilderMessage = "Apostar o añadir más selecciones";
export const SPO_BetbuilderMessageError =
  "Combinación no válida. Algunas de tus selecciones no son combinables. Por favor, cámbialas para continuar.";

/**
 * Component Color modes
 */
export const compoModeGrey = "grey";

/**
 * MSO
 */

//Icons new Rebranding
export const MSO_Page_Icons = {
  sport: "assets/icon/icon.png",
  casino: "assets/icon/icon-casino.png",
};

export const Max_GraceTime = 7;
export const UserLoggedSS = "isUserLogged";
export const PagesActivesLS = "pagesActives";

export const VipEstado = {
  YES: "yes",
  NO: "no",
  HIGHTROLLER: "highroller",
};

export const qaServers = [
  "qm.codere.com.ar",
  "qm.apuestas.codere.es",
  "dm.apuestas.codere.es",
  "q.mx.bet.cdrsbg.com",
  "qm.codere.pa",
  "dm.apuestas.codere.es",
  "localhost",
  "localhost:4200",
  "qpba.codere.com.ar",
  "qm.mdz.codere.bet.ar",
];

//eslint-disable-next-line
export enum CapacitorGetPlatform {
  IOS = "ios",
  ANDROID = "android",
  WEB = "web",
}
//eslint-disable-next-line
export enum SourcesCDR {
  Callmeback = 754310003,
  NativeApp = 754310001,
  Till = 754310002,
  WebApp = 754310000,
}
