import { CommonModule } from '@angular/common';
import { Component, Input, AfterViewInit } from '@angular/core';
import {
  IQRPaymentMethodBehaviour,
  QRPaymentMethodBehaviourResult
} from '../../../providers/PaymentMethodService/PaymentMethodBase';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const QrCreator: any;

@Component({
  selector: 'cs-qr-code',
  templateUrl: './cs-qr-code.html',
  styleUrls: ['./cs-qr-code.scss'],
  standalone: true,
  imports: [CommonModule]
})
export class CsQrCodeComponent implements AfterViewInit {
  @Input() depositResult?: QRPaymentMethodBehaviourResult;
  @Input() depositBehaviour?: IQRPaymentMethodBehaviour;

  constructor() {}

  ngAfterViewInit() {
    const qrCreator = new QrCreator();
    const qrData = document.querySelector('#qrCode');
    const qrSizeInPixels = 250;
    const config = {
      text: this.depositResult?.payload,
      radius: 0,
      ecLevel: 'H',
      fill: '#000000',
      background: null,
      size: qrSizeInPixels
    };
    QrCreator.render(config, qrData);
  }

  copyButton(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }
}
