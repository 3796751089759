import { Injectable, inject } from "@angular/core";
import { BaseServiceExt } from "@providers/BaseServiceExt";
import { DeviceService } from "@services/device.service";
import * as models from "@sports-models/index";
import { SportsConstants } from "@sports-utils/index";
import { orderBy } from "@utils/utils.functions";
import { Observable, map } from "rxjs";
import { SportsCommonService } from "./sports.common.service";

@Injectable({ providedIn: "root" })
export class SportLiveService extends BaseServiceExt {
  deviceService = inject(DeviceService);
  SportsCommonService = inject(SportsCommonService);

  isDesktop: boolean;
  isMobile: boolean;

  constructor() {
    super();
    this.isDesktop = this.deviceService.isDesktop();
    this.isMobile = this.deviceService.isMobile();
  }

  getLiveEventsAndSportsBySportHandle(sportHandler?: string): Observable<models.C_SportsLive> {
    const url = `${this.globalVars.NAVIGATIONSERVICEBASEURL}${SportsConstants.getLiveEventsAndSportsBySportHandleUrl}`;
    const headers = { language: this.globalVars.Idioma };
    const params = {
      ...(sportHandler && { sportHandle: sportHandler }),
      gametypes: this.globalVars.gameTypes.serialize(this.globalVars.gameTypes.getSport(sportHandler)),
    };

    return this.myGet(url, true, { headers, params }).pipe(
      map((data) => {
        const response = models.C_SportsLive.parse(data);
        response.Events = orderBy(response.Events, "").map((event) => {
          const ev = models.C_EventLive.parse(event);
          ev.hasSpecialGame(this.globalVars.gameTypes.apuestas_especiales);
          const eventGameTypes = this.SportsCommonService.getEventGameTypes(ev);
          ev.setGamesByPlatform(eventGameTypes, this.isMobile);
          return ev;
        });

        return response;
      }),
    );
  }
}
