<div class="sb-dropdown--header background-color-regular color-dark" (click)="_toggle()">
  <div class="sb-dropdown--badge" *ngIf="image">
    <img [src]="image" [alt]="title" />
  </div>
  <p class="sb-dropdown--title" no-margin>{{ title }}</p>
  <span *ngIf="hasStreaming" class="sb-dropdown--streaming has-streaming"></span>
  <i class="sb-dropdown--arrow codere-icon" [class.icon-arrow-bottom]="open" [class.icon-arrow-right]="!open"></i>
</div>

<div class="sb-dropdown--content" #content [hidden]="!open">
  <ng-content></ng-content>
</div>
