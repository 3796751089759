export class C_PendingPayments {
  Fecha: string;
  Operador: string;
  Referencia: string;
  Cantidad: string;
  CantidadNeta: string;
  CantidadBruta: string;
  CantidadImpuestos: string;
  Estado: string;
  Metodo: string;
  Cuenta: string;

  constructor(
    fecha: string,
    operador: string,
    referencia: string,
    cantidad: string,
    estado: string,
    metodo: string,
    cuenta: string
  ) {
    this.Fecha = fecha;
    this.Operador = operador;
    this.Referencia = referencia;
    this.Cantidad = cantidad;
    this.Estado = estado;
    this.Metodo = metodo;
    this.Cuenta = cuenta;
  }
}
