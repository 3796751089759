export interface I_JGetNode {
  ParentNodeId: string;
  DisplayTypeName: string;
  SpecialOddsValue: string;
  CategoryInfo: CategoryInfo;
  CategoryInfos: CategoryInfo[];
  LocalizedCategoryInfos: { [key: string]: CategoryInfo[] };
  StartDate: string;
  GameTypeId: number;
  SmartMarketAvailable: boolean;
  InplayType: number;
  LockedBasedInIsDisplayed: boolean;
  Locked: boolean;
  LastUpdated: string;
  IsRaceNode: boolean;
  NodeId: string;
  Parent: ParentNode;
  Type: string;
  TypeId: number;
  IsActive: boolean;
  IsDeleteable: boolean;
  IsDisplayed: boolean;
  SportHandle: string;
  BookMakerId: number;
  Status: number;
  LocalizedName: { [key: string]: string };
  NodeName: string;
  Priority: number;
  KlothoPriority: number | null;
  LiveDeactivationDelayS: number;
  MergePriority: number;
}

interface CategoryInfo {
  CategoryId: string;
  CategoryName: string;
  IsRelevant: boolean;
}

interface ParentNode {
  IsLive: boolean;
  StartDate: string;
  IsCombinable: boolean;
  StatisticsId: string;
  SmartMarketReferenceGameTypeIds: string;
  ChildrenCount: number;
  StreamingMapping: { [key: string]: string };
  StreamingId: string | null;
  StreamingProvider: string | null;
  LMTEnabled: boolean;
  HighlightPriority: number;
  ParticipantHome: string;
  ParticipantAway: string;
  Participants: Participant[];
  LiveHistory: any;
  ParentNodeId: string;
  TypeOfEvent: number;
  LockedBasedInIsDisplayed: boolean;
  Locked: boolean;
  LastUpdated: string;
  IsRaceNode: boolean;
  NodeId: string;
  Parent: ParentNode | null;
  Type: string;
  TypeId: number;
  IsActive: boolean;
  IsDeleteable: boolean;
  IsDisplayed: boolean;
  SportHandle: string;
  BookMakerId: number;
  Status: number;
  LocalizedName: { [key: string]: string };
  NodeName: string;
  Priority: number;
  KlothoPriority: number | null;
  LiveDeactivationDelayS: number;
  MergePriority: number;
}

interface Participant {
  AdditionalValues: AdditionalValues;
  Id: number;
  IsHome: boolean;
  LocalizedNames: LocalizedValues;
  LocalizedShortNames: LocalizedValues;
}

interface AdditionalValues {
  KeyValues: any[];
  LocalizedValues: LocalizedValue[];
  ReferenceId: number;
}

interface LocalizedValues {
  LocalizedValues: LocalizedValue[];
  ReferenceId: number;
}

interface LocalizedValue {
  Key: string;
  Value: string;
  CountryCode?: string;
  LanguageCode?: string;
}
