/* eslint-disable */
import { CommonModule, Location } from '@angular/common';
import {
  Component,
  ViewChild,
  OnInit,
  OnDestroy,
  inject,
  Input,
  ContentChild,
  Renderer2,
  ElementRef
} from '@angular/core';
import { IonicModule, NavController, ModalController, AlertController, NavParams, IonContent } from '@ionic/angular';

import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Observable, BehaviorSubject, Subscription, of } from 'rxjs';
import { tap, map, catchError } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import {
  PaypalPaymentMethodBehaviour,
  PaypalPaymentMethodBehaviourResult
} from '../../../providers/PaymentMethodService/Paypal';
import { BasePage } from '../../../pages/base/BasePage';
import { PersonalInfoPage } from '../../../pages/mso/personal-info/personal-info';
import { PaymentMethodBehaviour } from '../../../providers/PaymentMethodService/PaymentMethodBase';
import { DeviceService } from '../../../services/device.service';
import { PaymentMethodFactory } from '../../../providers/PaymentMethodService/PaymentMethodFactory';
import { TrackingInfo } from '../../../pages/base/BasePage';
import { CodereNavbar } from '../../../components/common/codere-navbar/codere-navbar';
import { CASINO_PATHS, MSO_PATHS } from '../../../lib/constants/routes';
import { SbNavbarComponent } from '../../../components/common/sb-navbar/sb-navbar';
import { CodereFooterComponent } from '../../../components/mso/footer/codere-footer/codere-footer';
import { paymentArguments } from '../../../models/paymentArguments';

//Services
import { Utils } from '../../../utils/Utils';
import { NewBaseService } from '../../../providers/newBase.service';
import { ScriptService } from '../../../providers/ScriptService';
import { EventsService } from '../../../providers/EventsService';
import { ErrorService } from '../../../providers/ErrorService';
import { SbFilterComponent } from '../../../components/sports/sb-filter/sb-filter';

//Models
import { GlobalVarsModel } from '../../../models/ModelGlobalsVar';
import { LicenseTypes } from '../../../models/MasterData';
import { TransactionActionResult, PaymentMethods, RISKY_PLAYER_ERROR_CODE } from '../../../models/C_Transactions';
import { DOCUMENT_VERIFICATION_STATES } from '../../../models/payments/DocumentVerificationStates';

//Others
import { CashierRequestInfoPageOk } from '../cashier-request-info-ok/cashier-request-info-ok';
import { CsNavbar } from '../../../models/payments/cs-navbar';
import { CsPendingPayments } from '../../../models/payments/cs-pending-payments';
import { PaymentsCashierService } from '../../../providers/payments/PaymentsCashierService';
import { EventTypes } from '../../../models/TrackingEvents';
import { CameraAccessInterface } from '../../../models/payments/I_VerificationUploadConfig';
import { CashierDocumentsPage } from '../cashier-documents/cashier-documents';
import { CashierHistoryPage } from '../cashier-history/cashier-history';
import { CashierDepositPage } from '../cashier-deposit/cashier-deposit';
import { CashierWithdrawalsPage } from '../cashier-withdrawals/cashier-withdrawals';
import { CsDocumentIndicationsComponent } from '../../../components/payments/cs-document-indications/cs-document-indications';
import { UserServiceMSO } from '../../../providers/UserServiceMSO';
import { CsVerificationAlertModal } from '../../../components/payments/cs-verification-alert-modal/cs-verification-alert-modal';
import { AppsFlyerTrackingService } from '../../../providers/AppsFlyerTrackingService';
import { UtilsCashierService } from '@providers/UtilsCashierService';
import { TicketMethods } from '@models/C_TicketApuestas';
import { NativeService } from '@providers/NativeService';
import { CashierRequestInfoPageKo } from '@shared-payments-pages/cashier-request-info-ko/cashier-request-info-ko';
import { Capacitor } from '@capacitor/core';
import { CapacitorGetPlatform } from '@constants';
import * as MSO_PAYMENTCONST from '@utils/paymentsMethodsConstants';
import { GTMDataManagerService, TrackStatus } from '@providers/GTMDataManager.service';

export enum GraceUserStatus {
  BEFORE_GRACE = 0,
  IN_GRACE = 1,
  PAST_GRACE = 2
}

@Component({
  selector: 'page-cashier',
  templateUrl: './cashier.html',
  styleUrls: ['./cashier.scss'],
  standalone: true,
  imports: [
    IonicModule,
    CommonModule,
    SbFilterComponent,
    CashierDepositPage,
    CashierWithdrawalsPage,
    CashierHistoryPage,
    CodereNavbar,
    SbNavbarComponent,
    CodereFooterComponent,
    CashierDocumentsPage,
    CsDocumentIndicationsComponent,
    TranslateModule
  ]
})
export class CashierPage extends BasePage implements OnInit, OnDestroy {
  @ViewChild(IonContent) content: IonContent;
  @ViewChild('content') contentRef: ElementRef;
  @Input() section: string;
  @ViewChild('cashierDocuments') cashierDocuments: CashierDocumentsPage;
  CASINO_LABEL = 'Casino';
  filters$: Observable<any>;
  stateModal$: Observable<any>;
  currentFilter$: BehaviorSubject<any> = new BehaviorSubject(null);
  currentSection$: Observable<string>;
  paymentOptions$: Observable<PaymentMethodBehaviour[]>;
  paymentSelected: any;
  currentStep$: BehaviorSubject<any> = new BehaviorSubject(1);
  isLocalTicket = false;

  pendingPayments: CsPendingPayments[];
  pendingPayments$: Observable<CsPendingPayments[]>;

  paymentMethodFactory: PaymentMethodFactory;

  filters: CsNavbar[];
  filterParam: string = 'deposit';
  userFromIos: boolean = false;
  currentSection: string = 'deposit';
  headerSection: string = 'Depósitos';

  userData: any;
  documentPending: boolean;
  blockFilters: boolean = false;

  closeExtModal: boolean = false;

  message: string;
  editMessage: Observable<any>;

  stateSubscription: Subscription;
  myActivityFilter: any = null;

  cabaInstructions: boolean = false;

  stepInstructions: number = 1;

  globalVars!: GlobalVarsModel;

  isMobile: boolean;
  isUserVerified: boolean;
  scroll: boolean = true;
  isEmpty = false;

  // User Flows
  isBlockedByGracePeriod = false;
  userGraceStatus: GraceUserStatus = GraceUserStatus.BEFORE_GRACE;

  public userService = inject(UserServiceMSO);
  public utils = inject(Utils);
  public scriptService = inject(ScriptService);
  public events = inject(EventsService);
  public navCtrl = inject(NavController);
  public navParams = inject(NavParams);
  public alertCtrl = inject(AlertController);
  public translate = inject(TranslateService);
  public viewCtrl = inject(ModalController);
  private modalCtrl = inject(ModalController);
  private newBaseService = inject(NewBaseService);
  public router = inject(Router);
  public ErrorService = inject(ErrorService);
  public deviceService = inject(DeviceService);
  public paymentsCashierService = inject(PaymentsCashierService);
  public utilsCssService = inject(UtilsCashierService);
  public route = inject(ActivatedRoute);
  public location = inject(Location);
  private _renderer = inject(Renderer2);
  private appsflyerTrackingService = inject(AppsFlyerTrackingService);
  private nativeService = inject(NativeService);
  private GTMDataManagerService = inject(GTMDataManagerService);

  constructor() {
    super();
    this.location.onUrlChange((url) => {
      if (url.indexOf('payments') >= 0) {
        this.setSectionStep(1);
      }
    });
    // For mobile
    this.isMobile = this.deviceService.isMobile();

    this.newBaseService.getVars.subscribe((vars: GlobalVarsModel) => {
      this.globalVars = vars;
    });

    const navSectionParam = this.navParams.get('section');
    const isEmptyParam = this.navParams.get('cashierEmpty');
    if (isEmptyParam) {
      this.isEmpty = true;
    }

    this.events.subscribe('cashier:endEmpty', (data = {}) => {
      this.isEmpty = false;
    });

    this.route.queryParams.subscribe((params) => {
      if (params['section']) {
        this.filterParam = params['section'];
      } else if (navSectionParam) {
        this.filterParam = this.navParams.get('section');
      } else {
        this.filterParam = 'deposit';
      }
      this.setFilters();
      //if navParams.data is empty, get params from route.queryParams
      Object.keys(this.navParams.data).length !== 0
        ? this.checkRequestInfoModal(this.navParams.data)
        : this.checkRequestInfoModal(params);
    });

    if (this.navParams.get('filterActivity')) {
      this.myActivityFilter = this.navParams.get('filterActivity');
    }
    if (this.navParams.get('userInfo')) this.userFromIos = true;

    // load QR script, necesary for a few payments/withdrawal methods
    this.scriptService.loadScript('qrcode');

    // Necesary for Redsys payment methods
    this.scriptService.loadScript('redsys', this.globalVars.FEATURES.BBVAIframeUrl);

    if (this.globalVars.licenseType == LicenseTypes.Colombia) {
      this.events.subscribe('closeWelcomeBonus', () => {
        if (this.deviceService.isDesktop()) {
          this.closeModal();
        } else {
          //this.backNavbar();
        }
      });
    }

    const paypalShowErrorCallback = (paymentMethodBehaviourResult: PaypalPaymentMethodBehaviourResult) => {
      paymentMethodBehaviourResult.erroMessage.subscribe(
        (error: string) => {
          if (paymentMethodBehaviourResult.sendToDocumentVerificationPage) {
            this.utils.confirm(
              false,
              this.translate.instant('tFail'),
              error,
              this.translate.instant('tCancel'),
              () => {},
              this.translate.instant('check'),
              () => {
                setTimeout(() => {
                  this.setFilter(this.filters[3]);
                }, 800);
              }
            );
          } else if (paymentMethodBehaviourResult.sendToUserProfileForm) {
            this.utils.confirm(
              false,
              this.translate.instant('tFail'),
              error,
              this.translate.instant('tCancel'),
              () => {},
              'Cambiar Teléfono',
              () => {
                setTimeout(() => {
                  this.linkOutOfModalPage(PersonalInfoPage, {});
                }, 800);
              }
            );
          } else if (paymentMethodBehaviourResult.sendToProfesion) {
            this.utils.confirm(
              false,
              this.translate.instant('Attention'),
              this.translate.instant('V2_profesionRequest'),
              this.translate.instant('tCancel'),
              null,
              this.translate.instant('tCont'),
              () => {
                this.GotoPersonaInfoPage();
              },
              true
            );
          } else {
            this.utils.showError(error, null);
          }
        },
        () => {
          if (paymentMethodBehaviourResult.errorcode === RISKY_PLAYER_ERROR_CODE) {
            this.utils.showError(
              'Por la política de “Juego más seguro” el cajero se ha modificado en función de tu perfil'
            );
          } else {
            this.utils.showError(this.translate.instant('errorDeposit'));
          }
        }
      );
    };
    const paypalExecuteOkCallback = (transaction: TransactionActionResult) => {
      var paymentMethod: number = PaymentMethods.Paypal;
      const payload = transaction.isFirstDeposit ? 'af_purchase' : 'SDeposit';
      this.trackingService.trackEvent([
        'DepositPaymentOK',
        transaction.transactionId,
        paymentMethod.toString(),
        transaction.amount.toString(),
        'event'
      ]);

      if (this.trackingService.mustTrackEvent(paymentMethod)) {
        var eventType = transaction.isFirstDeposit ? EventTypes.FirstDeposit : EventTypes.SubsequentDeposit;
        this.trackingService.track({
          eventType: eventType,
          additionalData: {
            userId: this.globalVars.user.username,
            currencyIsoCode: this.globalVars.currencyISOCode,
            data: transaction,
            //MSO trackers GTM
            ...(this.utils.isNacional()
              ? {
                  paymentMethod: MSO_PAYMENTCONST.PAYMENTMETHODSTRACK[paymentMethod],
                  signupDate: this.globalVars.user.registratedDate.toISOString(),
                  lastLoginDate: this.globalVars.user.lastLoginDate.toISOString(),
                  lastDepositDate: new Date().toISOString(),
                  contactId: this.globalVars.user.idBGT,
                  codereIdTrackers: this.globalVars.user.codereIdTracker
                }
              : {})
          }
        });

        if (this.globalVars.licenseType === LicenseTypes.Nacional) {
          this.GTMDataManagerService.addItem(
            {
              status: TrackStatus.OK,
              userId: this.globalVars.user.idBGT,
              signupDate: this.globalVars.user.registratedDate.toISOString(),
              lastLoginDate: this.globalVars.user.lastLoginDate.toISOString(),
              paymentMethod: MSO_PAYMENTCONST.PAYMENTMETHODSTRACK[paymentMethod],
              lastDepositDate: new Date().toISOString(),
              amount: transaction.amount,
              advertiser: this.globalVars.user.codereIdTracker.Mexos_Advertiser || '',
              profile: this.globalVars.user.codereIdTracker.Mexos_ProfileID || '',
              acqSource: this.globalVars.user.codereIdTracker.Mexos_Aquisition_Source || '',
              ID2: this.globalVars.user.codereIdTracker.Mexos_ID2 || '',
              ID3: this.globalVars.user.codereIdTracker.Mexos_ID3 || ''
            },
            transaction.isFirstDeposit ? 'FTD' : 'SDeposits'
          );
        } //End MSO trackers GTM

        if (Capacitor.getPlatform() !== CapacitorGetPlatform.WEB) {
          this.appsflyerTrackingService.trackEvent(payload, {
            af_content_id: this.globalVars.user.username,
            af_revenue: transaction.amount,
            af_currency: this.globalVars.currencyISOCode
          });

          // Xtreme First deposit and Second deposit event
          this.nativeService.xtremeHitEvent(payload, `${payload} Event`);
        }
      }

      let paymentArguments = {
        type: 'deposit',
        title: 'depositOK',
        subtitle: 'tDepOk',
        idTransaction: transaction.transactionId,
        amount: transaction.amount,
        isFirstDeposit: transaction.isFirstDeposit,
        paymentMethod: transaction.amount
      };

      this.showRequestOkInfoModal(paymentArguments);
    };

    this.paymentMethodFactory = new PaymentMethodFactory();
    this.paymentMethodFactory.initialize(paypalShowErrorCallback, paypalExecuteOkCallback);
    this.paymentSelected = null;

    this.pendingPayments = [];
    this.getPaymentOptions();
    this.setFooter();
  }

  override ngOnInit() {
    this.globalVars.walletActive = true;
    this.stateSubscription = this.paymentsCashierService.customMessage.subscribe((value) => {
      // TODO: revisar if, cuando entra tira un error si el item del localStorage esta seteado
      // if (localStorage.getItem('savedError')) {
      //   let savedError = JSON.parse(localStorage.getItem('savedError'));
      //   this.userService.sendErrors(savedError);
      // }
      this.ionViewDidLoad();
      setTimeout(() => {
        if (value == 'close') {
          this.paymentsCashierService.changeMessage('open');
          this.closeModal();
        }
      }, 200);
    });
  }

  ngOnDestroy(): void {
    this.globalVars.walletActive = false;
    this.stateSubscription.unsubscribe();
    if (this.globalVars.licenseType == LicenseTypes.Panama) {
      let paramsFromStore = JSON.parse(localStorage.getItem('paramsPaAfterReg'));
      if (paramsFromStore && !!paramsFromStore.comeFromReg) {
        localStorage.removeItem('paramsPaAfterReg');
        this.events.publish('welcome-panama');
      }
    }
  }

  emitAccessFiles(e: string) {
    this.cashierDocuments.setExternalTriggerClick(e);
  }

  emitAccessCamera(e: CameraAccessInterface) {
    this.cashierDocuments.setCamera(e);
  }

  GotoPersonaInfoPage(params?: any) {
    if (this.deviceService.isDesktop) {
      this.viewCtrl.dismiss(null, null, { animate: false } as any).then(() => {
        this.router.navigate(
          [`/${MSO_PATHS.BASE}/${MSO_PATHS.PersonalInfoPage}`, { animate: this.deviceService.isMobile() }],
          {}
        );
      });
      this.viewCtrl.dismiss();
    } else {
      this.router.navigate([`/${MSO_PATHS.BASE}/${MSO_PATHS.PersonalInfoPage}`, params], {
        queryParams: { animate: !this.deviceService.isDesktop }
      });
      this.router.navigate(
        [`/${MSO_PATHS.BASE}/${MSO_PATHS.PersonalInfoPage}`, { animate: this.deviceService.isMobile() }],
        {}
      );
    }
  }

  ionViewWillEnter() {
    this.checkAcceptanceComunication();
    if (this.deviceService.isMobile()) {
      this.events.publish('footer:refresh');
    }
  }

  private checkRequestInfoModal(paramsData: any) {
    if (!!paramsData['result'] && paramsData['result'].toLowerCase() === 'success') {
      let paymentArguments: paymentArguments = {
        type: 'deposit',
        title: 'depositOK',
        subtitle: 'tDepOk',
        idTransaction: paramsData['idTransaction'],
        amount: paramsData['amount'],
        isFirstDeposit: JSON.parse(paramsData['isFirstDeposit']),
        paymentMethod: paramsData['paymentMethod']
      };
      if (paymentArguments.isFirstDeposit) {
        const sessionData = JSON.parse(sessionStorage.getItem('partnercodere'));
        if (sessionData) {
          this.globalVars.partner = sessionData.partner;
          this.globalVars.mvpData.deposit = '1';
          this.globalVars.mvpData.token = sessionData.mvpData.token;
          this.globalVars.mvpData.login = sessionData.mvpData.login;
          this.globalVars.mvpData.register = sessionData.mvpData.register;
          sessionStorage.removeItem('partnercodere');
        }
      }
      this.showRequestOkInfoModal(paymentArguments);
    } else if (!!paramsData['result'] && paramsData['result'].toLowerCase() === 'error') {
      let paymentArguments: paymentArguments = {
        type: 'deposit',
        title: 'depositKOtitle',
        subtitle: 'depositKO',
        error: paramsData['error']
      };
      this.showRequestKoInfoModal(paymentArguments);
    }
  }

  private isRegisteredAfterGracePeriod(date_1: Date, date_2: Date): boolean {
    const instantRegister = date_1.getTime();
    const instantGrace = date_2.getTime();
    return instantRegister > instantGrace ? true : false;
  }

  private daysPassedSincePeriod(dateRegister: Date): number {
    const timeDifference = new Date().getTime() - dateRegister.getTime();
    const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));
    return daysDifference;
  }

  private handleGraceStatus(
    daysPassed: number,
    daysLimit: number,
    depositsTotal: number,
    depositLimit: number
  ): GraceUserStatus {
    if (daysPassed > daysLimit) {
      return GraceUserStatus.PAST_GRACE;
    } else {
      return GraceUserStatus.IN_GRACE;
    }
  }

  ionViewDidLoad() {
    this.userService.getUserData().subscribe((res) => {
      this.isUserVerified = res.VerifiDocumental && res.VerifiDocumental == DOCUMENT_VERIFICATION_STATES.COMPLETED;
      const userRegistrationDate = new Date(res.RegistrationDate);
      const graceStartDate = new Date(this.globalVars.FEATURES.Payments_GracePeriodStart);
      const isRegisteredAfter = this.isRegisteredAfterGracePeriod(userRegistrationDate, graceStartDate);

      if (
        (this.globalVars.licenseType == LicenseTypes.Panama || this.utils.isArgentina()) &&
        !this.isUserVerified &&
        isRegisteredAfter
      ) {
        const daysPassed = this.daysPassedSincePeriod(userRegistrationDate);
        this.userGraceStatus = this.handleGraceStatus(
          daysPassed,
          this.globalVars.FEATURES.Payments_GracePeriodDays,
          res.Saldo,
          this.globalVars.FEATURES.Payments_GracePeriodDepositLimit
        );
      } else if ((this.utils.isPanama() || this.utils.isArgentina()) && !isRegisteredAfter) {
        this.userGraceStatus = GraceUserStatus.BEFORE_GRACE;
      }

      if (this.globalVars.FEATURES.Payments_GracePeriodActive === false) {
        // Desactivamos user flows, van por flujo normal
        this.userGraceStatus = GraceUserStatus.BEFORE_GRACE;
      }

      if (this.globalVars.licenseType == LicenseTypes.Panama || this.globalVars.FEATURES.MSO_GraceTimeLogicEnabled) {
        if (this.userGraceStatus === GraceUserStatus.BEFORE_GRACE) {
          this.documentPending =
            !res.VerifiDocumental || res.VerifiDocumental == DOCUMENT_VERIFICATION_STATES.PENDING ? true : false;
          if (this.documentPending && !this.globalVars.extraUserData.graceTime) {
            this.filterParam = 'documents';
            this.blockFilters = true;
          } else {
            this.blockFilters = false;
          }
        } else if (this.userGraceStatus === GraceUserStatus.IN_GRACE) {
          this.blockFilters = false;
          this.isBlockedByGracePeriod = false;
        } else if (this.userGraceStatus === GraceUserStatus.PAST_GRACE) {
          this.utilsCssService.showInfoAlert(
            '¡CODERISTA!',
            `Han pasado ${this.globalVars.FEATURES.Payments_GracePeriodDays} días y no has completado tu verificación documental. Recuerda cargar tu documentación para desbloquear la cuenta y seguir jugando`
          );
          this.filterParam = 'documents';
          this.isBlockedByGracePeriod = true;
        }
      } else if (this.utils.isColombia()) {
        if (
          (!res.VerifiDocumental || res.VerifiDocumental === 'Pendiente') &&
          res.TipoDocumento === 'PermisoProteccinTemporalPPT'
        ) {
          this.filterParam = 'documents';
          this.blockFilters = true;
          this.documentPending = true;
        }
      } else if (this.utils.isArgentina() && this.globalVars.FEATURES.MSO_DocumentationVerificationStatus) {
        if (this.userGraceStatus === GraceUserStatus.BEFORE_GRACE) {
          if (
            this.globalVars.FEATURES.MSO_dateRegisterVerificationStatus &&
            this.globalVars.FEATURES.MSO_dateRegisterVerificationStatus.length > 0
          ) {
            try {
              const dateregisterverificationstatus = new Date(
                this.globalVars.FEATURES.MSO_dateRegisterVerificationStatus
              );
              const dateregisteruser = new Date(res.RegistrationDate);
              if (dateregisteruser.getTime() >= dateregisterverificationstatus.getTime()) {
                this.documentPending =
                  !res.VerifiDocumental || res.VerifiDocumental == DOCUMENT_VERIFICATION_STATES.PENDING ? true : false;
                if (this.documentPending) {
                  this.filterParam = 'documents';
                  this.blockFilters = true;
                  this.documentPending = true;
                }
              }
            } catch (error) {
              console.log(error);
            }
          } else {
            this.documentPending =
              !res.VerifiDocumental || res.VerifiDocumental == DOCUMENT_VERIFICATION_STATES.PENDING ? true : false;
            if (this.documentPending) {
              this.filterParam = 'documents';
              this.blockFilters = true;
              this.documentPending = true;
            }
          }
        } else if (this.userGraceStatus === GraceUserStatus.IN_GRACE) {
          this.blockFilters = false;
          this.isBlockedByGracePeriod = false;
        } else if (this.userGraceStatus === GraceUserStatus.PAST_GRACE) {
          this.utilsCssService.showInfoAlert(
            '¡CODERISTA!',
            `Han pasado ${this.globalVars.FEATURES.Payments_GracePeriodDays} días y no has completado tu verificación documental. Recuerda cargar tu documentación para desbloquear la cuenta y seguir jugando`
          );
          this.filterParam = 'documents';
          this.isBlockedByGracePeriod = true;
        }
      }
      this.setFilters();
    });
  }

  async setFilters() {
    if (this.globalVars.licenseType == LicenseTypes.Panama && this.globalVars.isProhibited) {
      this.filterParam = 'documents';
    }
    this.filters = [
      {
        name: this.translate.instant('tDepBig'),
        section: 'deposit',
        disabled: this.globalVars.isProhibited || this.blockFilters || this.isBlockedByGracePeriod,
        blocked: this.globalVars.isProhibited || this.blockFilters || this.isBlockedByGracePeriod
      },
      {
        name: this.translate.instant('tCob'),
        section: 'withdrawals',
        disabled: this.documentPending || this.isBlockedByGracePeriod,
        blocked: this.documentPending || this.isBlockedByGracePeriod
      },
      {
        name: this.translate.instant('hTitle'),
        section: 'history',
        disabled: this.globalVars.isProhibited || this.blockFilters || this.isBlockedByGracePeriod,
        blocked: this.globalVars.isProhibited || this.blockFilters || this.isBlockedByGracePeriod
      },
      { name: this.translate.instant('cashierdocuments'), section: 'documents', disabled: false, blocked: false }
    ];

    this.currentSection$ = this.currentFilter$.asObservable().pipe(
      map((obj) => {
        return obj.section;
      })
    );

    this.currentFilter$.asObservable().subscribe((filter) => {
      if (filter && filter?.name) {
        this.headerSection = filter.name;
      }
      if (filter?.section === 'documents' && this.deviceService.isDesktop()) {
        this.scroll = true;
      } else {
        this.scroll = false;
      }
    });

    this.filters$ = of(this.filters).pipe(
      tap((filters) => {
        let initFilter = filters.find((filter) => filter.section === this.filterParam);
        this.currentFilter$.next(initFilter);
        this.changeFilter(initFilter);
      })
    );

    // En Argentina se comprueba si el usuario acaba de registrarse
    // con el consiguiente login desde el backend
    // y se ha detectado su autoexclusion parcial
    if (this.globalVars.licenseType == LicenseTypes.ArgentinaCaba) {
      let autoExclusion = JSON.parse(localStorage.getItem('alertSelfExclusionLotba'));
      if (autoExclusion) {
        localStorage.removeItem('alertSelfExclusionLotba');
        this.utils.selfExclusionModal(1, this.viewCtrl);
      }
    }
  }

  getTrackingInfo(): TrackingInfo {
    return {
      uri: encodeURI(`/cashier`),

      description: `Cashier`,

      additionalData: {
        sportHandle: null
      }
    };
  }

  setTrackingEvent(event: any[]) {
    this.trackingService.trackEvent(event);
  }

  setTrackingTrack(event: any) {
    this.trackingService.track(event);
  }

  async showRequestOkInfoModal(paymentArguments: any) {
    if (this.viewCtrl) {
      this.viewCtrl.dismiss();
    }
    const modal = await this.modalCtrl.create({
      component: CashierRequestInfoPageOk,
      componentProps: {
        paymentArguments: paymentArguments
      }
    });
    modal.present();
    modal.onDidDismiss().then(() => {
      if (paymentArguments.isFirstDeposit && this.globalVars.licenseType == LicenseTypes.Panama) {
        let paramsFromStore = JSON.parse(localStorage.getItem('paramsPaAfterReg'));
        if (paramsFromStore) {
          localStorage.removeItem('paramsPaAfterReg');
        }
        this.events.publish('welcome-panama');
      }
      if (this.globalVars.partner == 'mvp') {
        this.globalVars.rootScope.tryToGoPage('MvpCoderePage');
      } else if (this.globalVars.FEATURES.ModalBonusSportCasino && paymentArguments.isFirstDeposit) {
        this.goTo(paymentArguments);
      } else {
        this.globalVars.rootScope.tryToGoPage(this.globalVars.mainPageApp);
      }
      sessionStorage.removeItem('modalWelcomeCO');
      if (this.viewCtrl) {
        this.viewCtrl.dismiss();
      }
    });
  }

  goTo(paymentArguments: any) {
    let page: any = this.globalVars.mainPageApp;
    let bono = sessionStorage.getItem('modalWelcomeCO');
    if (bono && bono === this.CASINO_LABEL) {
      page = CASINO_PATHS.CasinoPage;
    } else if (bono && bono != this.CASINO_LABEL) {
      if (Number(paymentArguments.amount) >= 20000) {
        page = 'FreeBetsPage';
      }
    }
    setTimeout(() => {
      if (!this.deviceService.isMobile()) {
        this.globalVars.rootScope.tryToGoPage(page);
      } else {
        this.navCtrl.navigateRoot(page);
      }
    }, 1500);
  }

  async showRequestKoInfoModal(paymentArguments: any) {
    if (this.viewCtrl) {
      this.viewCtrl.dismiss();
    }
    const modal = await this.modalCtrl.create({
      component: CashierRequestInfoPageKo,
      componentProps: {
        paymentArguments: paymentArguments
      }
    });
    modal.present();
    modal.onDidDismiss().then((docSection) => {
      if (docSection?.data) {
        setTimeout(() => {
          this.setTrackingEvent(['NotDocumentationVerifiedOnCharge', paymentArguments.error, '', '', 'event']);
          this.setFilter(this.filters[3]);
        }, 800);
      }
      if (this.viewCtrl) {
        this.viewCtrl.dismiss();
      }
    });
  }

  getPaymentOptions() {
    if (
      this.globalVars.licenseType == LicenseTypes.Mexico ||
      this.globalVars.licenseType == LicenseTypes.Colombia ||
      this.globalVars.licenseType == LicenseTypes.Panama
    ) {
      let currentLicense = 'Mexico';
      switch (this.globalVars.licenseType) {
        case LicenseTypes.Colombia:
          currentLicense = 'Colombia';
          break;
        case LicenseTypes.Panama:
          currentLicense = 'Panama';
          break;
      }
      let amount$ = new Observable((observer) => {
        let amount = Number(this.navParams.get('amount'));
        // amount parameter used only in Colombia Registration
        if (amount && amount > 0 && this.globalVars.licenseType == LicenseTypes.Colombia) {
          this.userService.getLimits().subscribe((data) => {
            const limitDay = Number(data.day);
            if (amount > limitDay) {
              amount = limitDay;
            } else if (amount <= 20000 && limitDay <= 20000) {
              amount = 20000;
            }
            observer.next(amount);
          });
        } else {
          observer.next(null);
        }
      });
      amount$.subscribe((value: unknown) => {
        const amount = value as number;
        this.userService.getAllAstropayMethods(currentLicense).subscribe(
          (astropayMethods: any[]) => {
            this.paymentOptions$ = this.paymentMethodFactory.payments(astropayMethods).pipe(
              map((paymentMethods: PaymentMethodBehaviour[]) => {
                paymentMethods = paymentMethods.filter((x) => x != null);
                paymentMethods = this.fillAmountButtons(paymentMethods);
                if (this.navParams.get('name')) {
                  const deposit = paymentMethods.find(
                    (payment) => payment.name.toLowerCase() === this.navParams.get('name').toLowerCase()
                  );
                  if (deposit) {
                    // amount parameter used only in Colombia Registration
                    if (amount) {
                      deposit.amount = amount;
                    }
                    // Se ha encontrado un método de pago con el nombre recibido como parámetro
                    this.setPayment(deposit);
                  }
                }
                return paymentMethods;
              }),
              catchError((_) => {
                this.utils.showError(this.translate.instant('errorDeposit'));
                return [];
              })
            );
          },
          (_) => {
            this.paymentOptions$ = this.paymentMethodFactory.payments([]).pipe(
              map((paymentMethods: PaymentMethodBehaviour[]) => {
                paymentMethods = paymentMethods.filter((x) => x != null);
                paymentMethods = this.fillAmountButtons(paymentMethods);
                return paymentMethods;
              }),
              catchError((_) => {
                this.utils.showError(this.translate.instant('errorDeposit'));
                return [];
              })
            );
          }
        );
      });
    } else {
      this.paymentOptions$ = this.paymentMethodFactory.payments([]).pipe(
        map((paymentMethods: PaymentMethodBehaviour[]) => {
          paymentMethods = this.fillAmountButtons(paymentMethods);
          if (this.navParams.get('name')) {
            const deposit = paymentMethods.find(
              (payment) => payment.name.toLowerCase() === this.navParams.get('name').toLowerCase()
            );
            this.setPayment(deposit);
          }
          this.utils.closeLoader();
          return paymentMethods;
        }),
        catchError((_) => {
          this.utils.showError(this.translate.instant('errorDeposit'));
          return [];
        })
      );
    }
  }

  fillAmountButtons(paymentMethods: PaymentMethodBehaviour[]) {
    paymentMethods.map((method: PaymentMethodBehaviour) => {
      if (!!method.amountButtons) {
        return method;
      } else {
        let amountButtons = this.globalVars.FEATURES.amountDepositsValues.split(';').map((button) => {
          return parseInt(button);
        });
        if (method instanceof PaypalPaymentMethodBehaviour && amountButtons[0] == 10) {
          amountButtons[0] = 15;
        }
        method.amountButtons = amountButtons;
        return method;
      }
    });
    return paymentMethods;
  }

  linkOutOfModalPage(link: any, paymentArguments: any) {
    if (this.deviceService.isMobile()) {
      this.router.navigate(['/' + link, paymentArguments], {
        queryParams: { animate: !this.deviceService.isDesktop }
      });
    } else {
      this.viewCtrl.dismiss();
    }
  }

  changeFilter(filterItem: CsNavbar): void {
    this.globalVars.paymentMethod = '';
    if (filterItem.section === this.currentFilter$.getValue().section) return;
    this.setFilter(filterItem);
  }

  setFilter(filterItem: CsNavbar) {
    this.currentStep$.next(1);
    this.currentFilter$.next(filterItem);
    this.paymentSelected = null;
    this.setFooter();
    this.scrollToTop();
  }

  setFooter() {
    if (this.deviceService.isMobile()) {
      this.events.publish('footer:refresh');
    }
  }

  setPayment(payment) {
    this.paymentSelected = payment;
    this.scrollToTop();
    // Si es un valor inicial establecido desde fuera
    if (
      this.globalVars.afterLoginParams &&
      this.globalVars.afterLoginParams.name &&
      this.globalVars.afterLoginParams.name.toLowerCase() == payment.name.toLowerCase()
    ) {
      // Actualizar el servicio
      //this.utilsCashier.setInitialPayment(payment);
      this.setSectionStep(2);
    }
  }

  scrollToTop() {
    this.content.scrollToTop();
  }

  setSectionStep(step: number) {
    if (step === 1) {
      this.setPayment(null);
      this.globalVars.paymentMethod = '';
    }
    this.currentStep$.next(step);
    this.scrollToTop();
  }

  setLocalTicket(isLocalTicket) {
    // TODO: no borrar, falta por terminar
    //this.isLocalTicket = isLocalTicket;
  }

  keyboardOpen(isOpen: any) {
    // TODO: la funcionalidad keyboard no esta terminada
    if (isOpen) {
      this.scroll = false;
      this.scrollToTop();
    } else {
      this.scroll = true;
    }
  }

  closeModal() {
    this.globalVars.paymentMethod = '';
    if (!!this.viewCtrl) {
      if (this.globalVars.licenseType == LicenseTypes.Panama) this.globalVars.openFromRegister = false;
      if (this.globalVars.partner == 'mvp') {
        this.globalVars.rootScope.tryToGoPage('MvpCoderePage');
      } else if (this.globalVars.licenseType == LicenseTypes.Colombia) {
        let bono = sessionStorage.getItem('modalWelcomeCO');
        sessionStorage.removeItem('modalWelcomeCO');
        if (bono && bono === this.CASINO_LABEL) {
          // TODO: no borrar, dependencias ciclicas, revisar con Angel
          // this.globalVars.rootScope.tryToGoPage(LobbyCasinoPage);
        }
      }
      this.viewCtrl.dismiss();
    }
  }

  backNavbar() {
    let virtualStep = this.currentStep$.getValue();
    this.globalVars.openFromRegister = false;
    if (this.isLocalTicket && virtualStep > 1) {
      this.currentStep$.next(1);
    } else if (virtualStep > 1) {
      this.currentStep$.next(virtualStep - 1);
      if (virtualStep === 2) this.setPayment(null);
    } else {
      if (this.globalVars.partner == 'mvp') {
        this.navCtrl.navigateRoot('MvpCoderePage');
      } else if (this.globalVars.licenseType == LicenseTypes.Colombia) {
        let bono = sessionStorage.getItem('modalWelcomeCO');
        let page;
        if (bono && bono === this.CASINO_LABEL) {
          page = CASINO_PATHS.CasinoPage;
        } else {
          page = this.globalVars.mainPageComponent;
        }
        sessionStorage.removeItem('modalWelcomeCO');
        this.navCtrl.navigateRoot(page);
      } else {
        this.navCtrl.navigateRoot(this.globalVars.mainPageComponent);
      }
      this.setFooter();
    }
    this.scrollToTop();
  }

  openInstructionsModal(e) {
    this.stepInstructions = e;
    this.cabaInstructions = true;
  }

  closeSelfieModal() {
    this.stepInstructions = 1;
    this.cabaInstructions = false;
  }

  //TODO cashierDocuments revisar
  // emitAccessFiles(e: string) {
  //   this.cashierDocuments.setExternalTriggerClick(e);
  // }

  // emitAccessCamera(e: CameraAccessInterface) {
  //   this.cashierDocuments.setCamera(e);
  // }

  checkAcceptanceComunication() {
    if (this.globalVars.extraUserData.acceptanceComunication) {
      if (this.deviceService.isDesktop) {
        this.closeModal();
        this.globalVars.rootScope.updateshowAlertComunicationsPrefences();
      } else {
        this.backNavbar();
      }
    }
  }

  async checkUserVerification() {
    if (!this.isUserVerified) {
      const verificationModal = await this.modalCtrl.create({
        component: CsVerificationAlertModal,
        cssClass: 'cs-verification-alert-modal'
      });
      verificationModal.present();
      verificationModal.onDidDismiss().then((verificationAccepted) => {
        if (verificationAccepted) {
          this.setFilter(this.filters.find((filer) => filer.section == 'documents'));
        }
      });
    }
  }
}
