import { SafeResourceUrl } from '@angular/platform-browser';

import { TicketService } from '../../providers/TicketService';
import { TrackingService } from '../../providers/TrackingService';
import { EventsService } from '../../providers/EventsService';

import { NewTicketFacade } from './store/facades/ticket.facade';
import { GlobalVarsModel } from '../../models/ModelGlobalsVar';

export class BetSenseData {
  eventId: string;
  iframeEnabled: boolean;
  iframeVisible: boolean;
  iframeUrl: SafeResourceUrl;
  ticketService: TicketService;
  newTicketFacade: NewTicketFacade;
  globals: GlobalVarsModel;
  events: EventsService;
  trackingService: TrackingService;
}
