<ng-container *ngIf="!isMobile; else mobileView">
  <ion-content class="tipoApuesta is-narrow-content has-titleBar">
    <!-- todo: revisar -->
    <!-- [class.enter]="viewEntering"
    [class.leave]="viewLeaving" -->
    <ion-toolbar class="toolbarSectionName tablet-content section-title">
      <back-button tappable></back-button>
      {{'uPHBet'|translate}}
    </ion-toolbar>

    <detail-ticket [betDetails]="betDetails"> </detail-ticket>

    <!--Buttons-->
    <div class="sendForm is-row-grid">
      <!-- todo: reviar -->
      <button
        ion-button
        full
        (click)="btDepositTicket()"
        [hidden]="betDetails?.BetHistoryInfo == null || (betDetails?.BetHistoryInfo.TicketStatusNumber != '1' || !globalVars.FEATURES?.loadTicket)"
      >
        {{'tDepTickPrem' | translate}}
        <!--Depositar ticket premiado-->
      </button>
      <button ion-button full (click)="btBackCheckTicket()">
        {{'consulticketother' | translate}}
        <!--Consultar otro ticket-->
      </button>
    </div>

    <codere-footer></codere-footer>
  </ion-content>
</ng-container>

<ng-template #mobileView>
  <ion-content class="tipoApuesta">
    <detail-ticket [betDetails]="betDetails"></detail-ticket>

    <div class="sendForm">
      <button
        ion-button
        full
        (click)="btDepositTicket()"
        [hidden]="betDetails?.BetHistoryInfo.TicketStatusNumber != '1' || !globalVars.FEATURES?.loadTicket"
      >
        {{'tDepTickPrem' | translate}}<!--Depositar ticket premiado-->
      </button>
      <button ion-button full (click)="btBackCheckTicket()">
        {{'consulticketother' | translate}}<!--Consultar otro ticket-->
      </button>
    </div>

    <codere-footer></codere-footer>
  </ion-content>
</ng-template>
