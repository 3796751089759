<!-- eslint-disable @angular-eslint/template/eqeqeq -->

<!-- Desktop -->
<ng-container *ngIf="!isMobile; else mobileView">
  <ion-content class="background-color-muted desktop-version">
    <ion-row class="map-and-list-container">
      <ion-col class="map-container" [class.leftMenuOpen]="globalVars.leftMenuOpen">
        <label class="item item-input searchInput">
          <ion-icon class="search-icon" name="search-outline"></ion-icon>
          <input
            class=""
            id="txtSearch"
            type="search"
            placeholder="{{ 'nearestlocal' | translate }} ..."
            max-length="255"
            [(ngModel)]="inputSearch"
          />
        </label>
        <button class="localizeMe" (click)="updateGPS()"></button>
        <div id="map"></div>
      </ion-col>
      <ion-col class="list-container">
        <ion-list class="codere-places">
          <h1>Cerca de ti</h1>
          <ion-item *ngIf="!poisActivos?.length"> {{ 'v2_errorLocalesMap' | translate }} </ion-item>
          <ion-item class="local-item" *ngFor="let poi of poisActivos" (click)="clickPoi(poi)">
            <ion-thumbnail item-left>
              <img *ngIf="!poi.isOnline" [src]="'assets/global/img/poi.svg'" />
              <img *ngIf="poi.isOnline" [src]="'assets/global/img/poiOnline.svg'" />
            </ion-thumbnail>
            <p class="distance" item-left [hidden]="poi.distance < 0">{{ 'To' | translate }} {{ poi.distance }} Km</p>
            <div class="info-container">
              <h2>{{ poi.nomb }}</h2>
              <p>{{ poi.dir }} - {{ poi.pobl }}</p>
            </div>
          </ion-item>
        </ion-list>
      </ion-col>
    </ion-row>
    <ion-row style="height: auto" *ngIf="globalVars.licenseType == 22 && isPanamaRegister">
      <div class="NearestLocalPagePa" style="padding: 20px">
        <ion-icon
          aria-label="alert outline"
          class="ion-ios-alert-outline icon icon-md"
          ios="ios-alert-outline"
          md="ios-alert-outline"
          role="img"
        ></ion-icon>
        <span [innerHTML]="'nearestLocaltxtreg' | translate"></span>
      </div>
    </ion-row>
  </ion-content>
</ng-container>

<!-- Mobile -->
<ng-template #mobileView>
  <ion-header>
    <user-section-title [sectionTitle]="'uPLocal'"></user-section-title>
  </ion-header>

  <ion-content class="NearestLocalPage background-color-muted">
    <div
      class="NearestLocalPagePa"
      style="height: auto; padding: 20px"
      *ngIf="globalVars.licenseType == 22 && isPanamaRegister"
    >
      <ion-icon
        aria-label="alert outline"
        class="ion-ios-alert-outline icon icon-md"
        ios="ios-alert-outline"
        md="ios-alert-outline"
        role="img"
      ></ion-icon>
      <span [innerHTML]="'nearestLocaltxtreg' | translate"
        ><!--Para <b>registrarte</b> dirígete a uno de nuestros locales.--></span
      >
    </div>

    <ion-toolbar class="subHeaderTabs">
      <label class="item item-input searchInput">
        <ion-icon class="search-icon" name="search-outline"></ion-icon>
        <input
          class=""
          id="txtSearch"
          type="search"
          placeholder="{{ 'nearestlocal' | translate }} ..."
          max-length="255"
          [(ngModel)]="inputSearch"
        />
        <button class="localizeMe" (click)="updateGPS()"></button>
      </label>

      <div id="map"></div>
    </ion-toolbar>

    <ion-list>
      <ion-item *ngIf="!poisActivos?.length"> {{ 'v2_errorLocalesMap' | translate }} </ion-item>
      <ion-item *ngFor="let poi of poisActivos" (click)="clickPoi(poi)">
        <div class="icon-container" item-left>
          <ion-thumbnail item-left>
            <img *ngIf="!poi.isOnline" [src]="'assets/global/img/poi.svg'" />
            <img *ngIf="poi.isOnline" [src]="'assets/global/img/poiOnline.svg'" />
          </ion-thumbnail>
        </div>
        <div class="text-container">
          <h2>{{ poi.nomb }}</h2>
          <p>{{ poi.dir }} - {{ poi.pobl }}</p>
        </div>
        <span class="distance-container" item-right [hidden]="poi.distance < 0">
          {{ 'To' | translate }} {{ poi.distance }} Km
        </span>
      </ion-item>
    </ion-list>

    <codere-footer></codere-footer>
  </ion-content>
</ng-template>
