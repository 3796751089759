<ng-container *ngIf="!isMobile; else mobileView">
  <ion-grid class="tablet-common-grid">
    <ion-row>
      <ion-col [class.leftMenuOpen]="globalVars.leftMenuOpen">
        <user-section-title [sectionTitle]="sectionTitle"> </user-section-title>
        <ion-grid class="tablet-common-grid">
          <ion-row>
            <ion-col [class.leftMenuOpen]="globalVars.leftMenuOpen">
              <ion-content class="has-titleBar background-color-muted">
                <div class="is-narrow-content">
                  <!-- Mensaje inicial -->
                  <div class="subtype is-text-uppercase">
                    <p>{{ texts.canales }}</p>
                    <p>{{ texts.momento }}</p>
                  </div>

                  <!-- Aceptar todas -->
                  <ion-list class="user-list">
                    <div class="user-input-container">
                      <ion-item lines="none">
                        <ion-checkbox
                          labelPlacement="end"
                          justify="start"
                          class="cdr-checkbox"
                          [(ngModel)]="allOptions"
                          (ionChange)="setOptions()"
                          [disabled]="allOptions"
                          >{{ texts.selectAll }}</ion-checkbox
                        >
                      </ion-item>
                    </div>
                  </ion-list>
                  <!-- Seleccionar opciones -->
                  <ion-list class="user-list">
                    <!-- Llamada telefónica-->
                    <div class="user-input-container">
                      <ion-item lines="none">
                        <!-- Llamada telefónica-->
                        <ion-checkbox
                          labelPlacement="end"
                          justify="start"
                          class="cdr-checkbox"
                          [(ngModel)]="opciones.phone"
                          (ionChange)="setAllOptions()"
                          >{{texts.phonecall}}</ion-checkbox
                        >
                      </ion-item>
                    </div>
                    <!--SMS-->
                    <div class="user-input-container">
                      <ion-item lines="none">
                        <ion-checkbox
                          labelPlacement="end"
                          justify="start"
                          class="cdr-checkbox"
                          [(ngModel)]="opciones.sms"
                          (ionChange)="setAllOptions()"
                          >SMS</ion-checkbox
                        >
                      </ion-item>
                    </div>
                    <!--E-mails-->
                    <div class="user-input-container">
                      <ion-item lines="none">
                        <ion-checkbox
                          labelPlacement="end"
                          justify="start"
                          class="cdr-checkbox"
                          [(ngModel)]="opciones.mail"
                          (ionChange)="setAllOptions()"
                          >E-mail</ion-checkbox
                        >
                      </ion-item>
                    </div>

                    <!-- Alertas de Aplicación -->
                    <div class="user-input-container">
                      <ion-item lines="none">
                        <ion-checkbox
                          labelPlacement="end"
                          justify="start"
                          class="cdr-checkbox"
                          [(ngModel)]="opciones.alerts"
                          (ionChange)="setAllOptions()"
                        >
                          {{texts.applicationalerts}}</ion-checkbox
                        >
                      </ion-item>
                    </div>
                  </ion-list>
                  <div class="sendForm">
                    <ion-button expand="full" (click)="update()">{{'tInPUpd' | translate}}<!--Actualizar--></ion-button>
                  </div>
                  <!-- Mensaje informativo -->
                  <div class="subtype is-text-uppercase">
                    <p>{{ texts.elige }}</p>
                    <!-- Elige las opciones de comunicacion  -->
                    <p>{{ texts.cambios }}</p>
                    <!-- Demora en los cambios  -->
                  </div>
                  <!-- Mensaje legal -->
                  <div class="legal">
                    {{texts.legal}}
                    <!-- Te informamos ... -->
                  </div>
                </div>
                <codere-footer></codere-footer>
              </ion-content>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-col>
    </ion-row>
  </ion-grid>
</ng-container>

<!-- Mobile -->
<ng-template #mobileView>
  <ion-header>
    <user-section-title [sectionTitle]="sectionTitle"> </user-section-title>
  </ion-header>

  <ion-content class="background-color-muted">
    <!-- Mensaje inicial -->
    <div class="subtype is-text-uppercase">
      <p>{{ texts.canales }}</p>
      <p>{{ texts.momento }}</p>
    </div>
    <!-- Aceptar todas -->
    <ion-list class="user-list m-bottom-3">
      <div class="user-input-container">
        <ion-item lines="none">
          <ion-checkbox
            labelPlacement="end"
            justify="start"
            class="cdr-checkbox"
            [(ngModel)]="allOptions"
            (ionChange)="setOptions()"
            [disabled]="allOptions"
            >{{ texts.selectAll }}</ion-checkbox
          >
        </ion-item>
      </div>
    </ion-list>
    <!-- Seleccionar opciones -->
    <ion-list class="user-list">
      <!-- Llamada telefónica-->
      <div class="user-input-container">
        <ion-item lines="none">
          <ion-checkbox
            labelPlacement="end"
            justify="start"
            class="cdr-checkbox"
            [(ngModel)]="opciones.phone"
            (ionChange)="setAllOptions()"
          >
            {{texts.phonecall}}</ion-checkbox
          >
        </ion-item>
      </div>
      <!--Enviar SMS-->
      <div class="user-input-container">
        <ion-item lines="none">
          <ion-checkbox
            labelPlacement="end"
            justify="start"
            class="cdr-checkbox"
            [(ngModel)]="opciones.sms"
            (ionChange)="setAllOptions()"
            >SMS</ion-checkbox
          >
        </ion-item>
      </div>
      <!--Enviar E-mails-->
      <div class="user-input-container">
        <ion-item lines="none">
          <ion-checkbox
            labelPlacement="end"
            justify="start"
            class="cdr-checkbox"
            [(ngModel)]="opciones.mail"
            (ionChange)="setAllOptions()"
            >E-mails</ion-checkbox
          >
        </ion-item>
      </div>
      <!-- Alertas de Aplicación -->
      <div class="user-input-container">
        <ion-item lines="none">
          <ion-checkbox
            labelPlacement="end"
            justify="start"
            class="cdr-checkbox"
            [(ngModel)]="opciones.alerts"
            (ionChange)="setAllOptions()"
          >
            {{texts.applicationalerts}}</ion-checkbox
          >
        </ion-item>
      </div>
    </ion-list>
    <div class="sendForm">
      <ion-button expand="full" (click)="update()">{{'tInPUpd' | translate}}<!--Actualizar--></ion-button>
    </div>
    <!-- Mensaje informativo -->
    <div class="subtype is-text-uppercase">
      <p>{{ texts.elige }}</p>
      <!-- Elige las opciones de comunicacion  -->
      <p>{{ texts.cambios }}</p>
      <!-- Demora en los cambios  -->
    </div>
    <!-- Mensaje legal -->
    <div class="legal">
      {{texts.legal}}
      <!-- Te informamos ... -->
    </div>
    <codere-footer></codere-footer>
  </ion-content>
</ng-template>
