<ion-accordion-group class="sb-dropdown-menu--accordion-group" #DropdownMenu>
  <ion-accordion class="sb-dropdown-menu--accordion">
    <ion-item slot="header" class="sb-dropdown-menu--accordion-item" lines="none">
      <ion-label class="sb-dropdown-menu--item-label">
        <p class="sb-dropdown-menu--item-label-p">{{ selectedItem.text }}</p>
      </ion-label>
      <ion-icon class="ion-accordion-toggle-icon" name="chevron-down" slot="end"></ion-icon>
    </ion-item>
    <div class="sb-dropdown-menu--content" slot="content">
      <ion-list class="sb-dropdown-menu--content-list"
        ><ion-item
          class="sb-dropdown-menu--content-item"
          lines="none"
          *ngFor="let item of dropdownItems; let i = index"
          (click)="onClick(item)"
        >
          <ion-label class="sb-dropdown-menu--content-label">
            <p class="sb-dropdown-menu--content-label-p">{{ item.text }}</p>
          </ion-label>
        </ion-item>
      </ion-list>
    </div>
  </ion-accordion>
</ion-accordion-group>
