/* eslint-disable eqeqeq*/
import { Injectable } from "@angular/core";
import { C_Message } from "@models/C_Message";
import { LicenseTypes } from "@models/MasterData";
import { BaseService } from "@providers/BaseService";
import { BehaviorSubject, Observable } from "rxjs";
@Injectable({ providedIn: "root" })
export class ContactService extends BaseService {
  messageCount: BehaviorSubject<number>;
  UrlUserServiceApiBaseMessage: string;
  constructor() {
    super();
    this.messageCount = new BehaviorSubject(0);
    this.UrlUserServiceApiBaseMessage = this.globalVars.DOMAIN + "/Codere.Dynamics.CSS.API/api/v1/";
  }

  getCategories(): Observable<C_Message[]> {
    let url = this.UrlUserServiceApiBaseMessage + "contactForm/categories";
    if (this.globalVars.licenseType === LicenseTypes.Colombia) {
      url += "?license=Colombia Online";
    }
    return this.myGet(url);
  }
  getSubjects(id): Observable<C_Message[]> {
    let url = this.UrlUserServiceApiBaseMessage + "contactForm/categories/" + id + "/subjects";
    if (this.globalVars.licenseType === LicenseTypes.Colombia) {
      url += "?license=Colombia Online";
    }
    return this.myGet(url);
  }
  getSubject(id): Observable<C_Message> {
    const url = this.UrlUserServiceApiBaseMessage + "contactForm/subjects/" + id;
    return this.myGet(url);
  }

  postContactForm(data): Observable<any> {
    return this.myPost(`${this.UrlUserServiceApiBaseMessage}contactForm`, data);
  }
}
