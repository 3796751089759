<!-- eslint-disable @angular-eslint/template/eqeqeq -->

<ion-content padding [ngClass]="{ 'platform-core': isDesktop, 'platform-mobile': !isDesktop }">
  <ion-header>
    <user-section-title [sectionTitle]="'v2_obTarj'"></user-section-title>
  </ion-header>
  <h1 class="single-title-bar text-center">
    {{'v2_obTarj' | translate | uppercase}}
    <!--# CÓMO OBTENER TU TARJETA-->
  </h1>
  <div [ngClass]="{'is-narrow-content':isDesktop}">
    <div
      [ngClass]="{ 'text-center': isDesktop, 'has-x-margin-mobile': !isDesktop }"
      class="introReg"
      [innerHTML]="'GetCardordertx00' | translate"
    >
      <!--Puedes <b>obtener tu Tarjeta Codere</b> de las siguientes formas:-->
    </div>

    <ion-list class="double-option">
      <ng-container *ngIf="isDesktop; else mobileCard">
        <ion-item tappable="true" (click)="openMap()">
          <ion-avatar item-left>
            <span>1</span>
          </ion-avatar>
          <div class="stacked-text">
            <h2>
              {{'GetCardordertx01' | translate}}
              <!--Consíguela en cualquier local Codere-->
            </h2>
            <span class="card-extra-info">
              <span>{{'GetCardordertx011' | translate}}</span>
            </span>
          </div>
          <div class="arrow-container">
            <i class="codere-icon arrow icon-arrow-right"></i>
          </div>
        </ion-item>

        <ion-item
          tappable="true"
          (click)="openForm2()"
          *ngIf="globalVars.LICENSE_TYPE == 0 || globalVars.LICENSE_TYPE == 1"
        >
          <ion-avatar item-left>
            <span>2</span>
          </ion-avatar>
          <div class="stacked-text">
            <h2>
              {{'GetCardordertx02' | translate}}
              <!--Solicitando que te la enviemos por correo postal-->
            </h2>
            <span class="card-extra-info">
              <ion-icon name="alert-circle-outline"></ion-icon>
              <span>{{'GetCardordertx022' | translate}}</span>
              <!--Comprueba que los datos de envío son correctos -->
            </span>
          </div>
          <div class="arrow-container">
            <i class="codere-icon arrow icon-arrow-right"></i>
          </div>
        </ion-item>
      </ng-container>

      <ng-template #mobileCard>
        <ion-item tappable="true" (click)="openMap()">
          <ion-avatar item-left>
            <span>1</span>
          </ion-avatar>
          <div class="stacked-text">
            <h2>
              {{'GetCardordertx01' | translate}}
              <!--Consíguela en cualquier local Codere-->
            </h2>
          </div>
        </ion-item>
        <div class="arrow-container">
          <i class="codere-icon arrow icon-arrow-right"></i>
        </div>
        <ion-label class="lblRequest">{{'GetCardordertx011' | translate}}</ion-label>
        <ng-container *ngIf="globalVars.LICENSE_TYPE == 0 || globalVars.LICENSE_TYPE == 1">
          <ion-item tappable="true" (click)="openForm2()">
            <ion-avatar item-left>
              <span>2</span>
            </ion-avatar>
            <div class="stacked-text">
              <h2>
                {{'GetCardordertx02' | translate}}
                <!--Solicitando que te la enviemos por correo postal-->
              </h2>
            </div>
          </ion-item>
          <div class="arrow-container">
            <i class="codere-icon arrow icon-arrow-right arrow2"></i>
          </div>
          <ion-label class="lblRequest noBorder">{{'GetCardordertx022' | translate}}</ion-label>
        </ng-container>
      </ng-template>
    </ion-list>
  </div>
  <codere-footer></codere-footer>
</ion-content>
