export const CONSTANTS = {
  // END POINTS
  lobbiesDataUrl: '/Slotgames/GetLobbiesLayout?mobile=',
  lobbyJackpotUrl: 'GetLobbyJackpots',
  rpgUrl: '/slotgames/GetGamesRPG?mobile=',
  dyUrl: '/slotgames/GetGamesDY?mobile=',
  lobbyParam: '&lobby=',
  dyParam: '&dysel=',
  dyMaxnumber: '&numMaxGames=',

  // SPECIAL CATEGORIES
  rpg: 'Recently Played Games',
  dy: 'Dynamic Yield',

  // CATEGORIES & FILTERS
  category: 'category',
  filter: 'filter',
  all: 'Todos',
  allEng: 'All',
  jackpot: 'jackpot',
  Jackpot: 'Jackpot',
  royalDegree: 'royalDegree',
  omnichannel: 'Omnichannel',
  liveGames: 'liveGames',
  aviator: 'Aviator',
  aviatorImgPc: 'assets/global/img/slots/ImageAviatorDesktop.jpg',
  aviatorImgMobile: 'assets/global/img/slots/ImageAviatorMobile.jpg',
  bannerUrlBase: 'https://www.codere.es/IconsCasino/',

  // GRID
  detail: 'detail',

  // ICON
  slots: 'slots',

  // LOBBIES
  slotsLobby: 'Nacional',
  casinoLobby: 'Playtech',
  playtech: 'playtech',
  playtechCO: 'playtechco',

  // LOBBIES NAMES
  slotsLobbyName: 'Slots',
  casinoLobbyName: 'Casino',

  // BANNERS
  slotsBanners: 'slot',
  casinoBanners: 'casino',

  // PAGES
  slotPage: 'SlotsPage',
  casinoPage: 'CasinoPage',
  jackpotPage: 'JackpotPage',

  // GAME DEMO PARAMS
  demoTarget: 'Game',
  demoFeatures: 'fullscreen=1, location=0, menubar=0, resizable=0, scrollbars=0, status=0, toolbar=0, titlebar=0',

  // GAME TYPES
  roulettePc: 'RoulettePC',
  rouletteMobile: 'RouletteMobile',
  blackJackPc: 'BlackJackPC',
  blackJackMobile: 'BlackJackMobile',
  tablePc: 'TablePC',
  tableMobile: 'TableMobile',
  slot: 'slot',

  minGames: 9,

  // ALERT SESSION
  slotsSessionMessage:
    '\u003cstrong\u003eJuego con moderaci&oacute;n.\u003c/strong\u003e El uso indebido del juego y las apuestas puede causar adicci&oacute;n.',
  slotsSessionMessageExtended:
    '  \u003ca external-link href="http://condiciones.apuestas.codere.es/nacional/juego_responsable.html?_ga=1.48326114.512593250.1464854868"  target="_blank"\u003eM&aacute;s info\u003c/a\u003e',
  slotsSessionUrl:
    'http://condiciones.apuestas.codere.es/nacional/juego_responsable.html?_ga=1.48326114.512593250.1464854868',
  casinoReasonPlayCasino: 'Jugar Casino',
  casinoReasonPlayCasinoLc: 'jugarcasino',
  casinoReasonPlaySlotsLc: 'jugarslots',

  // SIGNALR
  rouletteRound: 'RouletteRound',
  roulettePlayerCount: 'PlayerCount',
  jackpotTicker: 'JackpotTicker',
  roundField: 'LastNumbers',
  countField: 'PlayerCount',
  signalRCasinoJackpots: '/JackpotServices.AppService/hub',
  signalRCasinoLiveGames: '/LiveGameData/hub',
  royalDegreeNotification: 'playerNotificationWinnings',

  // MISC
  square: 'Smallsquare',
  searchFilterName: 'Búsqueda',
  ok: 'OK',
  demo: 'demo',
  cordova: 'cordova',
  moreInfo: 'Más info',
  notice: 'Atención',
  timePlaying: 'Tiempo de juego: ',
  moneyPlayed: ' minutos. <br>Importe jugado: ',
  tenMinutesRemaining: 'Quedan 10 segundos para que finalice la sesión de slots',
  miniGame: 'Mini Juego',
  sessionCloseWarning: 'Para poder jugar a este juego, se cerrará la sesión de Mini casino',
  cancel: 'Cancelar',
  closeSession: 'Cerrar Sesión',
  exit: 'Salir',

  //cards sizes
  smallsquare: 'Smallsquare',
  rectangle: 'Rectangle',
  squareTile: 'Square',
  // Categories
  banner: 'Banner',
  standard: 'Standard',
  vertical: 'Vertical',
  bigSquare: 'BigSquare',
  bigSquareVertical: 'BigSquareVertical',
  ranking: 'Ranking',
  verticalBig: 'VerticalBig',
  rounded: 'Rounded',
  QACo: 'https://q.mx.bet.cdrsbg.com',

  rouletteNumber: [
    { number: '0', class: 'is-green-number' },
    { number: '01', class: 'is-red-number' },
    { number: '02', class: 'is-white-number' },
    { number: '03', class: 'is-red-number' },
    { number: '04', class: 'is-white-number' },
    { number: '05', class: 'is-red-number' },
    { number: '06', class: 'is-white-number' },
    { number: '07', class: 'is-red-number' },
    { number: '08', class: 'is-white-number' },
    { number: '09', class: 'is-red-number' },
    { number: '10', class: 'is-white-number' },
    { number: '11', class: 'is-white-number' },
    { number: '12', class: 'is-red-number' },
    { number: '13', class: 'is-white-number' },
    { number: '14', class: 'is-red-number' },
    { number: '15', class: 'is-white-number' },
    { number: '16', class: 'is-red-number' },
    { number: '17', class: 'is-white-number' },
    { number: '18', class: 'is-red-number' },
    { number: '19', class: 'is-red-number' },
    { number: '20', class: 'is-white-number' },
    { number: '21', class: 'is-red-number' },
    { number: '22', class: 'is-white-number' },
    { number: '23', class: 'is-red-number' },
    { number: '24', class: 'is-white-number' },
    { number: '25', class: 'is-red-number' },
    { number: '26', class: 'is-white-number' },
    { number: '27', class: 'is-red-number' },
    { number: '28', class: 'is-white-number' },
    { number: '29', class: 'is-white-number' },
    { number: '30', class: 'is-red-number' },
    { number: '31', class: 'is-white-number' },
    { number: '32', class: 'is-red-number' },
    { number: '33', class: 'is-white-number' },
    { number: '34', class: 'is-red-number' },
    { number: '35', class: 'is-white-number' },
    { number: '36', class: 'is-red-number' }
  ]
};
