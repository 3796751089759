<div
  #videoPlayerContainer
  (mouseleave)="streamingEvents = []"
  class="video-player-container"
  (window:resize)="onWindowResize()"
></div>

<div class="video-top-bar" #videoPlayerTopBar>
  <div class="event-name" (click)="loadLiveEvents()">
    <div class="event-name-select"><i [ngClass]="'icon-'+event?.SportHandle"></i> {{event?.Name}}</div>
    <ion-icon name="chevron-down-outline" size="normal"></ion-icon>
  </div>

  <div class="streaming-events-list" *ngIf="streamingEvents.length !== 0" [ngClass]="eventListClass">
    <div class="streaming-events-item" *ngFor="let stream of streamingEvents" (click)="loadEvent(stream)">
      <i [ngClass]="'icon-'+stream?.SportHandle"></i> {{stream.Name}}
    </div>
  </div>

  <ion-icon (click)="close()" name="close" role="img" class="icon icon-md ion-md-close" aria-label="close"></ion-icon>
  <ion-icon
    *ngIf="isDesktop && showMaximize()"
    (click)="handleZoomDesktop()"
    name="expand"
    role="img"
    class="icon icon-md ion-md-expand"
    aria-label="expand"
  ></ion-icon>
  <i (click)="goToEvent()" name="md-direct" role="img" class="icon icon-direct" aria-label="go to event"></i>
</div>
