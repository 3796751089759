<ng-container *ngIf="!isMobile; else mobileView">
  <ion-header class="has-titleBar">
    <user-section-title [sectionTitle]="'uPLeng'"></user-section-title>
  </ion-header>

  <ion-content class="background-color-muted">
    <div class="is-narrow-content">
      <!-- <ion-list class="user-list" radio-group (ionChange)="changeLanguage()" [(ngModel)]="selectedLanguage"> -->
      <ion-list class="user-list" radio-group (ionChange)="changeLanguage()">
        <ion-radio-group [(ngModel)]="selectedLanguage">
          <div class="user-input-container" *ngIf="globalVars.licenseType == LicenseTypes.Mexico">
            <ion-item>
              <ion-label>Español (México)</ion-label>
              <ion-radio value="es-mx" name="language-es-mx">Español (México)</ion-radio>
            </ion-item>
          </div>
          <div class="user-input-container" *ngIf="globalVars.licenseType == LicenseTypes.Colombia">
            <ion-item>
              <ion-label>Español (Colombia)</ion-label>
              <ion-radio value="es-co" name="language-es-co">Español (Colombia)</ion-radio>
            </ion-item>
          </div>
          <div class="user-input-container" *ngIf="globalVars.licenseType == LicenseTypes.Panama">
            <ion-item>
              <ion-label>Español (Panamá)</ion-label>
              <ion-radio value="es-pa" name="language-es-pa">Español (Panamá)</ion-radio>
            </ion-item>
          </div>
          <div class="user-input-container" *ngIf="utils.isArgentina()">
            <ion-item>
              <ion-label>Español (Argentina)</ion-label>
              <ion-radio value="es-ar" name="language-es-ar">Español (Argentina)</ion-radio>
            </ion-item>
          </div>
          <div
            class="user-input-container"
            *ngIf="globalVars.licenseType === 0 || !globalVars.FEATURES?.MSO_DisabledLanguage"
          >
            <ion-item>
              <ion-label>Español</ion-label>
              <ion-radio value="es" name="language-es">Español</ion-radio>
            </ion-item>
          </div>
          <div class="user-input-container" *ngIf="!globalVars.FEATURES?.MSO_DisabledLanguage">
            <ion-item>
              <ion-label>English</ion-label>
              <ion-radio value="en" name="language-en">English</ion-radio>
            </ion-item>
          </div>
          <div
            class="user-input-container"
            *ngIf="!globalVars.FEATURES?.MSO_DisabledLanguage && globalVars.licenseType === 0"
          >
            <ion-item>
              <ion-label>Euskera</ion-label>
              <ion-radio value="eu" name="language-eu">Euskera</ion-radio>
            </ion-item>
          </div>
        </ion-radio-group>
      </ion-list>
    </div>

    <codere-footer></codere-footer>
  </ion-content>
</ng-container>

<!-- Mobile -->
<ng-template #mobileView>
  <ion-header class="has-titleBar">
    <user-section-title [sectionTitle]="'uPLeng'"></user-section-title>
  </ion-header>

  <ion-content class="background-color-muted">
    <ion-list class="user-list m-bottom-0" radio-group (ionChange)="changeLanguage()">
      <ion-radio-group [(ngModel)]="selectedLanguage">
        <div class="user-input-container" *ngIf="globalVars.licenseType == LicenseTypes.Mexico">
          <ion-item>
            <ion-label>Español (México)</ion-label>
            <ion-radio value="es-mx" name="language-es-mx">Español (México)</ion-radio>
          </ion-item>
        </div>
        <div class="user-input-container" *ngIf="globalVars.licenseType == LicenseTypes.Colombia">
          <ion-item>
            <ion-label>Español (Colombia)</ion-label>
            <ion-radio value="es-co" name="language-es-co">Español (Colombia)</ion-radio>
          </ion-item>
        </div>
        <div class="user-input-container" *ngIf="globalVars.licenseType == LicenseTypes.Panama">
          <ion-item>
            <ion-label>Español (Panamá)</ion-label>
            <ion-radio value="es-pa" name="language-es-pa">Español (Panamá)</ion-radio>
          </ion-item>
        </div>
        <div
          class="user-input-container"
          *ngIf="globalVars.licenseType == LicenseTypes.ArgentinaCaba || globalVars.licenseType == LicenseTypes.ArgentinaPBA"
        >
          <ion-item>
            <ion-label>Español (Argentina)</ion-label>
            <ion-radio value="es-ar" name="language-es-ar">Español (Argentina)</ion-radio>
          </ion-item>
        </div>
        <div
          class="user-input-container"
          *ngIf="globalVars.licenseType === 0 || !globalVars.FEATURES?.MSO_DisabledLanguage"
        >
          <ion-item>
            <ion-label
              >Español
              <span
                *ngIf="
                  globalVars.licenseType !== 4 &&
                  globalVars.licenseType !== 21 &&
                  globalVars.licenseType !== 22 &&
                  globalVars.licenseType !== 33 &&
                  globalVars.licenseType !== 34
                "
                >(España)</span
              ></ion-label
            >
            <ion-radio value="es" name="language-es">Español</ion-radio>
          </ion-item>
        </div>
        <div class="user-input-container" *ngIf="!globalVars.FEATURES?.MSO_DisabledLanguage">
          <ion-item>
            <ion-label>English</ion-label>
            <ion-radio value="en" name="language-en">English</ion-radio>
          </ion-item>
        </div>
        <div
          class="user-input-container"
          *ngIf="!globalVars.FEATURES?.MSO_DisabledLanguage && globalVars.licenseType === 0"
        >
          <ion-item>
            <ion-label>Euskera</ion-label>
            <ion-radio value="eu" name="language-eu">Euskera</ion-radio>
          </ion-item>
        </div>
      </ion-radio-group>
    </ion-list>

    <codere-footer></codere-footer>
  </ion-content>
</ng-template>
