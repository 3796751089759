export class LineItem {
  Amount: number;
  AmountLocal: number;
  BetType?: any;
  CanBeEachWay?: boolean;
  Date?: any;
  downOdd: boolean;
  EventDate: string;
  EventId: string;
  EventName: string;
  GameId: string;
  GameLocked?: boolean;
  GameName: string;
  GameSpecialOddsValue?: string;
  GreyhoundHorseBetType?: any;
  GreyhoundHorses?: boolean;
  Handicap?: any;
  Id?: string;
  Index?: number;
  IsBanked?: boolean;
  IsBet?: boolean;
  IsLive?: boolean;
  IsSelected?: boolean;
  ItemId?: number;
  LineErrorMessages: Array<string>;
  LineErrors?: any;
  LineItemStatus?: number; //enum
  LiveStatus?: boolean;
  Name?: string;
  NotAvailable?: boolean;
  Odd: number; //decimal
  Ordered?: any;
  Paddock?: any;
  Participants?: any;
  PendingCheck?: boolean;
  PipelineExecutionResult?: number; //enum_PipelineExecutionResult;
  Place?: number; //es un enum
  PotentialWin?: number; //decimal
  Price?: number; //decimal
  ResultName: string;
  ResultsNr: string;
  Selected: boolean = false;
  SpecialOddsValue: string;
  SportHandle: string;
  Status?: any;
  Tax?: any;
  TicketNumber?: string;
  TotalPotentialWin: number;
  Type?: number;
  upOdd: boolean;
  constructor() {
    this.LineErrorMessages = [];
    this.IsSelected = true;
    this.GreyhoundHorses = false;
    this.IsBet = false;
    this.Selected = false;
    this.AmountLocal = this.Amount;
  }

  static mapItem(item) {
    const lineItem = new LineItem();
    lineItem.Amount = 0;
    lineItem.AmountLocal = 0;
    lineItem.CanBeEachWay = item.CanBeEachWay || false;
    lineItem.downOdd = item.downOdd;
    lineItem.EventDate = item.EventDate;
    lineItem.EventId = item.EventId;
    lineItem.EventName = item.EventName;
    lineItem.GameId = item.GameId;
    lineItem.GameName = item.GameName;
    lineItem.IsLive = item.IsLive;
    lineItem.Odd = item.Odd;
    lineItem.Place = item.Place;
    lineItem.ResultName = item.Name;
    lineItem.ResultsNr = item.NodeId;
    lineItem.SpecialOddsValue = item.GameSpecialOddsValue;
    lineItem.SportHandle = item.SportHandle;
    lineItem.TotalPotentialWin = 0;
    lineItem.upOdd = item.upOdd;
    return lineItem;
  }
}
