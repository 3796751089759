<ion-header>
  <div>
    <h4 class="m-0 text-center">{{ title }}</h4>
  </div>
  <div>
    <input type="text" id="searchInput" placeholder="{{'phSearch'|translate}}" />
  </div>
</ion-header>
<ion-content>
  <ion-list (ionChange)="checkboxChange($event)">
    <ion-radio-group>
      <ion-item *ngFor="let item of filteredItems; let i = index">
        <ion-radio [value]="item.value"></ion-radio>
        <ion-label>{{ item.label }}</ion-label>
      </ion-item>
    </ion-radio-group>
  </ion-list>
</ion-content>
<ion-footer class="footer-content">
  <div>
    <button class="btn-cancel" (click)="cancelChanges()">{{ 'tCancel' | translate }}</button>
  </div>
  <div>
    <button class="btn-accept" (click)="confirmChanges()">{{ 'ifrFdbMinAmountAcceptBtn' | translate }}</button>
  </div>
</ion-footer>
