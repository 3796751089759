<div class="cs-punto-pago p-1">
  <div class="cs-wrapperText--title p-1">
    <span [innerHTML]="'PuntoPago_header' | translate"></span>
    <span> </span>
    <hr />
  </div>
  <div class="cs-wrapperImage text-center">
    <img src="assets/global/img/puntoPagoMachine.png" class="imagePuntoPago" />
  </div>
  <div>
    <span [innerHTML]="'PuntoPago_text' | translate"></span>
  </div>
</div>
