import { C_LiveData } from './C_LiveData';

export class C_LiveDataHockey extends C_LiveData {
  Periods: Array<number>;
  override MatchTime: number;

  constructor(
    actions: Array<any>,
    matchTime: number,
    ParticipantAway: string,
    participantHome: string,
    period: number,
    periodName: string,
    periods: Array<number>,
    resultAway: number,
    resultHome: number,
    time: string
  ) {
    super(actions, ParticipantAway, participantHome, period, periodName, resultAway, resultHome, time);
    this.Periods = periods;
    this.MatchTime = matchTime;
  }
}
