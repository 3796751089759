<ion-list clear class="ticketReceipt itemMyBet" *ngFor="let ticket of historyTicket.DepositPayments">
  <!--Muesca de ticket-->
  <div class="ticketTop"></div>

  <ion-list-header>
    <ion-label> {{ historyTicket.Date | extractDate: [1] }} </ion-label>
  </ion-list-header>

  <ion-item-group>
    <ion-item class="item-ticket">
      <ion-label>
        <p class="typeAp is-text-left">{{ 'tMet' | translate }}</p>
        <h2 class="eventAp is-text-right">{{ ticket.Metodo }}</h2>
      </ion-label>
    </ion-item>

    <ion-item class="item-ticket">
      <ion-label>
        <p class="typeAp is-text-left blackGreylbl">
          {{ 'CodereCardState' | translate }}:
          <!--Estado:-->
        </p>
        <h2 class="eventAp is-text-right color-accent">{{ ticket.Estado }}</h2>
      </ion-label>
    </ion-item>

    <ion-item class="item-ticket">
      <ion-label>
        <p class="typeAp is-text-left">{{ 'dCDate' | translate }}</p>
        <h2 class="eventAp is-text-right">{{ ticket.Fecha | extractDate: [2] }}</h2>
      </ion-label>
    </ion-item>

    <ion-item class="item-ticket">
      <ion-label>
        <p class="typeAp is-text-left">{{ 'tRef' | translate }}</p>
        <h2 class="eventAp is-text-right">{{ ticket.Referencia }}</h2>
      </ion-label>
    </ion-item>
    <!--Importe:-->
    <ion-item class="item-ticket">
      <ion-label>
        <p class="typeAp is-text-left blackGreylbl">{{ 'importSlots' | translate }}:</p>
        <h2
          *ngIf="
            (currentPage('DepositHistoryPage') ||
              (currentPage('ChargeHistoryPage') &&
                !globalVars.FEATURES?.WitdrawTaxRetentionInformationEnabled)) &&
            ticket.Cantidad !== ''
          "
          class="eventAp is-text-right"
        >
          {{ ticket | parseTransactionMoney }}
        </h2>
        <h2
          *ngIf="
            currentPage('ChargeHistoryPage') &&
            globalVars.FEATURES?.WitdrawTaxRetentionInformationEnabled &&
            ticket.Cantidad !== ''
          "
          class="eventAp is-text-right"
          (click)="viewRetention(ticket.DrawId)"
        >
          <span class="greentxta"> {{ ticket | parseTransactionMoney }} </span>
        </h2>
        <h2 *ngIf="ticket.Cantidad === ''" class="eventAp is-text-right" [ngSwitch]="globalVars.licenseType">
          <span *ngSwitchCase="4">$</span>
          <span *ngSwitchDefault>&euro;</span>
        </h2>
      </ion-label>
    </ion-item>
    <ion-item class="item-ticket" *ngIf="currentPage('DepositHistoryPage') && isDesktop">
      <ion-label>
        <p class="typeAp">&nbsp;</p>
      </ion-label>
    </ion-item>
    <ion-item
      class="item-ticket"
      *ngIf="
        currentPage('DepositHistoryPage') &&
        globalVars.licenseType === 4 &&
        ticket.DrawId !== null
      "
    >
      <ion-label>
        <p class="typeAp greentxta" (click)="viewTicketService(ticket.DrawId)">Ver nota de venta</p>
      </ion-label>
    </ion-item>
    <ion-item
      class="item-ticket"
      *ngIf="
        currentPage('DepositHistoryPage') &&
        globalVars.licenseType === 4 &&
        ticket.DrawId !== null
      "
    >
      <ion-label>
        <p class="typeAp greentxta" (click)="viewTicketDraw(ticket.DrawId)">Ver ticket sorteo</p>
      </ion-label>
    </ion-item>
    <ion-item class="item-ticket" *ngIf="currentPage('ChargeHistoryPage') && isDesktop">
      <ion-label>
        <p class="typeAp">&nbsp;</p>
      </ion-label>
    </ion-item>
    <ion-item
      class="item-ticket"
      *ngIf="
        currentPage('ChargeHistoryPage') &&
        globalVars.licenseType === 4 &&
        ticket.PartialWidthdrawDrawId !== null
      "
    >
      <ion-label>
        <p class="typeAp greentxta" (click)="viewTicketService(ticket.PartialWidthdrawDrawId)">Ver nota de venta</p>
      </ion-label>
    </ion-item>
    <ion-item
      class="item-ticket"
      *ngIf="
        currentPage('ChargeHistoryPage') &&
        globalVars.licenseType === 4 &&
        ticket.PartialWidthdrawDrawId !== null
      "
    >
      <ion-label>
        <p class="typeAp greentxta" (click)="viewTicketDraw(ticket.PartialWidthdrawDrawId)">Ver ticket sorteo</p>
      </ion-label>
    </ion-item>
  </ion-item-group>
</ion-list>
