import { Component, Input, OnInit, inject } from "@angular/core";
import { ModalController, NavController, NavParams } from "@ionic/angular";

import { CookiesService } from "@providers/CookiesService";
import { TrackingService } from "@providers/TrackingService";
import { UserService } from "@providers/UserService";
//Services
import { Utils } from "@utils/Utils";

import { CommonModule } from "@angular/common";
import { Capacitor } from "@capacitor/core";
import { BasicActionResultStatus, TransactionActionResult } from "@models/C_Transactions";
import { CookieNames } from "@models/CookieNames";
import { LicenseTypes } from "@models/MasterData";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { EventTypes } from "@models/TrackingEvents";
//Models
import { TranslateModule } from "@ngx-translate/core";
import { ParseMoney } from "@pipes/parseMoney";
import { AppsFlyerTrackingService } from "@providers/AppsFlyerTrackingService";
import { GTMDataManagerService, TrackStatus } from "@providers/GTMDataManager.service";
import { NativeService } from "@providers/NativeService";
import { NewBaseService } from "@providers/newBase.service";
import { CapacitorGetPlatform } from "@shared-utils/constants";
import * as MSO_PAYMENTCONST from "@utils/paymentsMethodsConstants";

@Component({
  selector: "cashier-request-info-ok",
  templateUrl: "./cashier-request-info-ok.html",
  styleUrls: ["./cashier-request-info-ok.scss"],
  host: {
    class: "sb-alert cs-request-info",
  },
  standalone: true,
  imports: [TranslateModule, ParseMoney, CommonModule],
})
export class CashierRequestInfoPageOk implements OnInit {
  idTransaction: string;
  mfaCode: string;
  amount: number;
  type: string = "withdrawal";
  paymentStatus: string;
  paymentMethod: number;
  paramsCollection: any;
  isPending: boolean;
  isDebinPending: boolean = false;
  isFirstDeposit: boolean;
  options: any;
  globalVars!: GlobalVarsModel;
  mfaActive: boolean = false;

  @Input() paymentArguments?: any;

  public nav = inject(NavController);
  public navParams = inject(NavParams);
  public viewCtrl = inject(ModalController);
  public newBaseService = inject(NewBaseService);
  public userService = inject(UserService);
  public utils = inject(Utils);
  private cookiesService = inject(CookiesService);
  private trackingService = inject(TrackingService);
  private appsflyerTrackingService = inject(AppsFlyerTrackingService);
  private nativeService = inject(NativeService);
  private GTMDataManagerService = inject(GTMDataManagerService);

  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
      this.mfaActive = this.globalVars.FEATURES.mfaActive;
    });
  }

  ngOnInit(): void {
    this.setOptions();
    this.setTrackings();
  }

  private setOptions() {
    this.paymentArguments
      ? (this.options = this.paymentArguments)
      : (this.options = this.navParams.get("paymentArguments"));
    this.isFirstDeposit = false;

    // Lo pasamos para reutilizar el componente
    //eslint-disable-next-line
    this.isDebinPending = this.paymentArguments.hasOwnProperty("isDebinPending") ? true : false;

    if (this.options.paymentStatus) {
      // if (this.options.paymentStatus === "OK" || this.options.paymentStatus === "APPROVED")
      if (this.options.paymentStatus === "OK") this.isPending = false;
      else this.isPending = true;
    } else this.isPending = false;
    if (this.options.isFirstDeposit) {
      this.isFirstDeposit = this.options.isFirstDeposit.toString().toLowerCase() === "true";
    }

    if (this.options.paymentMethod) {
      this.paymentMethod = parseInt(this.options.paymentMethod);
    }
  }

  private setTrackings() {
    if (this.options.type === "withdrawal") {
      this.trackingService.trackEvent([
        "CashierWithdrawalOk",
        this.options.idTransaction?.toString() ?? "",
        this.options.mfaCode?.toString() ?? "",
        "",
        this.options.amount?.toString() ?? "",
        "event",
      ]);
    } else {
      const sPaymentMethod = this.options.paymentMethod?.toString() ?? "";
      this.trackingService.trackEvent([
        "CashierDepositOk",
        this.options.idTransaction?.toString() ?? "",
        this.options.mfaCode?.toString() ?? "",
        sPaymentMethod,
        this.options.amount?.toString() ?? "",
        "event",
      ]);
      this.checkPartner(sPaymentMethod);
      if (this.trackingService.mustTrackEvent(this.options.paymentMethod)) {
        const transactionActionResult: TransactionActionResult = {
          status: BasicActionResultStatus.Ok,
          success: true,
          amount: this.options.amount,
          netAmount: this.options.amount,
          taxAmount: 0,
          errCode: 0,
          errMessage: "",
          errorParameters: [],
          isFirstDeposit: this.isFirstDeposit,
          transactionId: this.options.idTransaction,
          MFA_CODE: this.options.mfaCode,
          paymentMethod: this.options.paymentMethod,
        };

        this.trackingService.track({
          eventType: transactionActionResult.isFirstDeposit
            ? EventTypes.FirstDeposit
            : EventTypes.SubsequentDeposit,
          additionalData: {
            userId: this.globalVars.user.username,
            currencyIsoCode: this.globalVars.currencyISOCode,
            data: transactionActionResult,
            //MSO trackers GTM
            ...(this.utils.isNacional() || this.globalVars.licenseType === LicenseTypes.Colombia
              ? {
                  paymentMethod: MSO_PAYMENTCONST.PAYMENTMETHODSTRACK[transactionActionResult.paymentMethod],
                  signupDate:
                    this.utils.isValidDate(this.globalVars.user.registratedDate) ??
                    this.globalVars.user.registratedDate.toISOString(),
                  lastLoginDate:
                    this.utils.isValidDate(this.globalVars.user.lastLoginDate) ??
                    this.globalVars.user.lastLoginDate.toISOString(),
                  lastDepositDate: new Date().toISOString(),
                  contactId: this.globalVars.user.idBGT,
                  codereIdTrackers: this.globalVars.user.codereIdTracker,
                }
              : {}),
          },
        });

        if (
          this.globalVars.licenseType === LicenseTypes.Nacional ||
          this.globalVars.licenseType === LicenseTypes.Colombia
        ) {
          this.GTMDataManagerService.addItem(
            {
              status: TrackStatus.OK,
              userId: this.globalVars.user.idBGT,
              signupDate: this.globalVars.user.registratedDate.toISOString(),
              lastLoginDate: this.globalVars.user.lastLoginDate.toISOString(),
              paymentMethod: MSO_PAYMENTCONST.PAYMENTMETHODSTRACK[transactionActionResult.paymentMethod],
              lastDepositDate: new Date().toISOString(),
              amount: transactionActionResult.amount,
              advertiser: this.globalVars.user.codereIdTracker.Mexos_Advertiser || "",
              profile: this.globalVars.user.codereIdTracker.Mexos_ProfileID || "",
              acqSource: this.globalVars.user.codereIdTracker.Mexos_Aquisition_Source || "",
              ID2: this.globalVars.user.codereIdTracker.Mexos_ID2 || "",
              ID3: this.globalVars.user.codereIdTracker.Mexos_ID3 || "",
            },
            transactionActionResult.isFirstDeposit ? "FTD" : "SDeposits",
          );
        } //End MSO trackers GTM
        const payload = transactionActionResult.isFirstDeposit ? "af_purchase" : "SDeposit";

        if (Capacitor.getPlatform() !== CapacitorGetPlatform.WEB) {
          this.appsflyerTrackingService.trackEvent(payload, {
            af_content_id: this.globalVars.user.username,
            af_revenue: transactionActionResult.amount,
            af_currency: this.globalVars.currencyISOCode,
          });

          // Xtreme First deposit and Second deposit event
          this.nativeService.xtremeHitEvent(payload, `${payload} Event`);
        }

        if (this.globalVars.openSelectionChoose) {
          this.cookiesService.setCookie(
            CookieNames.DefaultDepositOption,
            this.globalVars.openSelectionChoose.toString(),
            365 * 24 * 60 * 60 * 1000,
          );
        }
      }
    }
  }

  public checkCodeValue(code: string): boolean {
    return !(code === "" || code === null || code === undefined);
  }

  get paymentType() {
    return this.type === "withdrawal" ? "cobro" : "dep&oacute;sito";
  }

  get showTitle() {
    return this.options.paymentMethod !== 754310016;
  }

  acceptClick() {
    this.viewCtrl.dismiss();
    this.globalVars.rootScope.tryToGoPage(this.globalVars.mainPageApp);
  }

  private checkPartner(sPaymentMethod) {
    if (this.globalVars.partner === "mvp" && this.isFirstDeposit) {
      this.trackingService.trackEvent([
        "MVPCashierDepositOk",
        this.options.idTransaction,
        sPaymentMethod,
        this.options.amount.toString(),
        "event",
      ]);
      this.globalVars.mvpData.deposit = "1";
    }
  }
}
