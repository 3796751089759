<section class="sb-betslip-stake--wrapper" [ngClass]="{ 'betslip-stake-disabled': disabled || mode != '' }">
  <span
    class="sb-betslip-stake--label color-dark"
    [ngStyle]="{ display: finalRow ? 'block' : 'none' }"
    [ngClass]="{ 'text-small': setSmallText(), 'final-row': finalRow }"
  >
    {{ stakeLabel }}
  </span>
  <input
    class="sb-betslip-stake--button"
    [(ngModel)]="stakeAmount"
    type="number"
    [disabled]="disabled || mode != ''"
    #stake
  />
  <span class="sb-betslip-stake--amount color-dark" [ngClass]="{ 'text-small': setSmallText(), 'final-row': finalRow }">
    <span *ngIf="!checkCurrency()">
      {{ currency }}
    </span>
    {{ stakeAmountAux | async | parseStakeMoney }}
    <span *ngIf="checkCurrency()">
      {{ currency }}
    </span>
  </span>
</section>
