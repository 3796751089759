export class C_ItemsOptionsSelect {
  value: string;
  text: string;
  selections: string;
  selected?: boolean;
  maxCombinations?: number;
  totalBets?: number;
  constructor(
    text: string,
    val: string,
    selections: string,
    selected?: boolean,
    maxCombinations?: number,
    totalBets?: number
  ) {
    this.text = text;
    this.value = val;
    this.selections = selections;
    this.selected = selected;
    this.maxCombinations = maxCombinations;
    this.totalBets = totalBets;
  }
}

//export =C_ItemsOptionsSelect
