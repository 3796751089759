import { Injectable, Pipe, PipeTransform } from '@angular/core';

/*
  Generated class for the CategoryFilter pipe.

  See https://angular.io/docs/ts/latest/guide/pipes.html for more info on
  Angular 2 Pipes.
*/
@Pipe({ name: 'categoryFilter' })
@Injectable({ providedIn: 'root' })
export class CategoryFilter implements PipeTransform {
  transform(value, args) {
    if (args) {
      return value?.filter((e: { Name: string }) => e.Name.toLowerCase().includes(args?.toLowerCase()));
    } else {
      return value;
    }
  }
}
