<ng-container *ngIf="isDesktop; else mobileTemplate;">
  <ion-content class="has-titleBar background-color-muted">
    <!-- user-section-title mobile & desktop -->
    <user-section-title [sectionTitle]="'timeout'"></user-section-title>
    <!-- Tiempo Fuera en Argentina -->
    <ion-list class="subDep" *ngIf="globalVars.FEATURES?.timeOutPageEnabled">
      <div class="masinfo text-center marginb23">
        <span>{{'timeoutArtxt01' | translate}}</span>
      </div>
      <div class="masinfo text-center marginb23">
        <span>{{'timeoutArtxt02' | translate}}</span>
      </div>
      <div class="buttonsLine" [class.has-error]="!validDate">
        <form>
          <!-- Selección del producto -->
          <div class="txttitle text-center marginb23">{{'timeoutArseleccprod' | translate}}</div>
          <div class="timeoutproduc--radio-wrapper">
            <button
              class="timeoutproduc--radio-button"
              [ngClass]="{'is-selected': argButtons.sports,
                            'is-selected-initial': argButtons.sportscrm}"
              (click)="checkArgButtons('sports')"
            >
              <p>{{'sports' | translate}}</p>
            </button>
            <button
              class="timeoutproduc--radio-button text-capitalized"
              [ngClass]="{'is-selected': argButtons.casino,
                        'is-selected-initial': argButtons.casinocrm}"
              (click)="checkArgButtons('casino')"
            >
              <p>{{'casino' | translate}}</p>
            </button>
            <button
              class="timeoutproduc--radio-button"
              [ngClass]="{'is-selected': argButtons.slots,
                            'is-selected-initial': argButtons.slotscrm}"
              (click)="checkArgButtons('slots')"
            >
              <p>{{'slots' | translate}}</p>
            </button>
            <button
              class="timeoutproduc--radio-button"
              [ngClass]="{'is-selected': argButtons.all}"
              (click)="checkArgButtons('all')"
            >
              <p>{{'timeoutArseleccall' | translate}}</p>
            </button>
          </div>
          <div [hidden]="!argErrors?.product" class="labelError">
            <span> Por favor selecciona un producto </span>
          </div>
          <!--Tiempo restante autoexlusión-->
          <div class="txttitle text-center marginb23 txtgreen" *ngIf="timeRest">
            Tiempo restante de autoexlusión: {{timeRest}} h.
          </div>
          <!-- Selección del tiempo -->
          <div class="txttitle text-center marginb23">
            {{'timeoutArselecc' | translate}} {{'timeoutArtitle01' | translate}}:
          </div>
          <div class="timeoutproduc--radio-wrapper">
            <button
              class="timeoutproduc--radio-button"
              *ngFor="let item of argTimes, let i = index"
              [ngClass]="{'is-selected': item.selected}"
              (click)="checkArgTimes(item)"
              [disabled]="!item.enabled"
            >
              <p>{{item.name | translate}}</p>
            </button>
          </div>
          <div class="personalInputs" *ngIf="argTimes[4].selected">
            <div class="labelInputs">{{ 'selfexclusionsArtitle01' | translate }} ({{ 'max42days' | translate }})</div>
            <ion-input
              type="tel"
              [(ngModel)]="argTimes[4].time"
              name="personal-input"
              placeholder="Indica los días"
              min="1"
              max="42"
              maxlength="2"
              (input)="managePersonalizada($event)"
              (blur)="managePersonalizada($event)"
              (focus)="managePersonalizada($event)"
            >
            </ion-input>
          </div>
          <div [hidden]="!argErrors?.time" class="labelError clearfix">
            <span>
              {{'time_valid' | translate}} ({{ 'max42days' | translate }})
              <!--Por favor selecciona un periodo de tiempo fuera.--></span
            >
          </div>
        </form>
      </div>
      <div class="sendForm">
        <ion-button id="selfExclusionBtn" expand="full" (click)="selfExclusionArgentina()">
          {{'tHelpSend'|translate}}
        </ion-button>
      </div>
    </ion-list>
    <codere-footer></codere-footer>
  </ion-content>
</ng-container>

<ng-template #mobileTemplate>
  <ion-header>
    <user-section-title [sectionTitle]="'timeout'"></user-section-title>
  </ion-header>

  <ion-content>
    <div class="myWrapInput" [class.has-error]="!validDate">
      <!-- Tiempo Fuera en Argentina -->
      <ion-list class="payment-sublist" *ngIf="globalVars.FEATURES?.timeOutPageEnabled">
        <ion-row>
          <!-- Selección del producto -->
          <ion-col size="12">
            <div class="txttitle text-center marginb23">{{'timeoutArseleccprod' | translate}}</div>
          </ion-col>
          <ion-col size="12">
            <ion-list class="user-list m-bottom-0">
              <div class="user-input-container">
                <ion-item lines="none">
                  <ion-checkbox
                    mode="ios"
                    name="sports"
                    [(ngModel)]="argButtons.sports"
                    [disabled]="argButtons.sportscrm"
                    (ionChange)="checkArgButtons('sports', true)"
                    labelPlacement="end"
                    justify="start"
                  >
                  </ion-checkbox>
                  <ion-label>{{'sports' | translate}}</ion-label>
                </ion-item>
              </div>
              <div class="user-input-container">
                <ion-item lines="none">
                  <ion-checkbox
                    mode="ios"
                    name="casino"
                    [(ngModel)]="argButtons.casino"
                    [disabled]="argButtons.casinocrm"
                    (ionChange)="checkArgButtons('casino', true)"
                    labelPlacement="end"
                    justify="start"
                  >
                  </ion-checkbox>
                  <ion-label>{{'casino' | translate | titlecase }}</ion-label>
                </ion-item>
              </div>
              <div class="user-input-container">
                <ion-item lines="none">
                  <ion-checkbox
                    mode="ios"
                    name="slots"
                    [(ngModel)]="argButtons.slots"
                    [disabled]="argButtons.slotscrm"
                    (ionChange)="checkArgButtons('slots', true)"
                    labelPlacement="end"
                    justify="start"
                  >
                  </ion-checkbox>
                  <ion-label>{{'slots' | translate}}</ion-label>
                </ion-item>
              </div>
              <div class="user-input-container">
                <ion-item lines="none">
                  <ion-checkbox
                    mode="ios"
                    name="all"
                    [(ngModel)]="argButtons.all"
                    (ionChange)="checkArgButtons('all', true)"
                    labelPlacement="end"
                    justify="start"
                  >
                  </ion-checkbox>
                  <ion-label>{{'timeoutArseleccall' | translate}}</ion-label>
                </ion-item>
              </div>
            </ion-list>
          </ion-col>
          <!-- Errores de validación en selección del producto -->
          <ion-col size="12">
            <div [hidden]="!argErrors?.product" class="labelError">
              <span>
                Por favor selecciona un producto
                <!-- {{'date_valid' | translate}}  Por favor introduce una fecha válida.-->
              </span>
            </div>
          </ion-col>
          <!--Tiempo restante autoexlusión-->
          <div class="txttitle txtgreen marginb23" *ngIf="timeRest">
            Tiempo restante de autoexlusión: {{timeRest}} h.
          </div>
          <!-- Selección del tiempo -->
          <ion-col size="12">
            <div class="txttitle text-center marginb23">
              {{'timeoutArselecc' | translate}} {{'timeoutArtitle01' | translate}}:
            </div>
          </ion-col>
          <ion-col size="12">
            <ion-radio-group class="user-list m-bottom-0" (ionChange)="changeTime()" [(ngModel)]="argSelelctTimeIndex">
              <div class="user-input-container" *ngFor="let item of argTimes; let i = index">
                <ion-item lines="none" [class.radioCheched]="argSelelctTimeIndex === i">
                  <ion-radio mode="md" [value]="i" [disabled]="!item.enabled">{{item.name| translate}}</ion-radio>
                </ion-item>
              </div>
            </ion-radio-group>
          </ion-col>

          <!-- Selección del tiempo personalizada -->
          <ion-col size="12">
            <div class="personalInputs" *ngIf="argTimes[4].selected">
              <div class="labelInputs">{{ 'selfexclusionsArtitle01' | translate }} ({{ 'max42days' | translate }})</div>
              <ion-input
                type="tel"
                [(ngModel)]="argTimes[4].time"
                name="personal-input"
                placeholder="Indica los días"
                min="1"
                max="42"
                maxlength="2"
                (input)="managePersonalizada($event)"
                (blur)="managePersonalizada($event)"
                (focus)="managePersonalizada($event)"
              >
              </ion-input>
            </div>
          </ion-col>
          <!-- Errores de validación en selección del tiempo -->
          <ion-col size="12">
            <div [hidden]="!argErrors?.time" class="labelError clearfix">
              <span>
                {{'time_valid' | translate}} ({{ 'max42days' | translate }})
                <!--Por favor selecciona un periodo de Tiempo Fuera--></span
              >
            </div>
          </ion-col>
        </ion-row>
        <div class="sendForm">
          <ion-button color="primary" expand="full" (click)="selfExclusionArgentina()"
            >{{'tHelpSend'|translate}}</ion-button
          >
        </div>
      </ion-list>
    </div>
    <div *ngIf="globalVars.licenseType === 33">
      <h3 class="listinfoh3">
        {{'informationabout' | translate | uppercase}} {{'timeout' | translate | uppercase}}<!--# INFORMACIÓN SOBRE-->
      </h3>
      <ul class="listInfo">
        <li><b>•</b>{{'timeoutArtxt01' | translate}}</li>
        <li><b>•</b>{{'timeoutArtxt02' | translate}}</li>
      </ul>
    </div>
    <codere-footer></codere-footer>
  </ion-content>
</ng-template>
