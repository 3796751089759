<div class="introReg">
  <div class="info-icon">
    <ion-icon class="iconinforeg" name="information-circle-outline"></ion-icon>
  </div>
  <div class="info-text">
    <span>{{ 'regtxtpaso1' | translate }}</span>
    <!--Introduce tus datos tal como aparecen en tu Documento de Identidad. Sólo podrán ser verificados si coinciden con los que
        figuran en el mismo.-->
  </div>
</div>
