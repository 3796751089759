import { C_LiveData } from './C_LiveData';

export class C_LiveDataAmericanFootball extends C_LiveData {
  Quarters: Array<number>;
  override MatchTime: number;
  HomeAttacking: boolean;
  override RemainingPeriodTime: string;
  Try: string;
  Yards: string;
  Position: string;

  constructor(
    actions: Array<any>,
    homeattacking: boolean,
    matchTime: number,
    paramtry: string,
    ParticipantAway: string,
    participantHome: string,
    period: number,
    periodName: string,
    position: string,
    quarters: Array<number>,
    remainingPeriodTime: string,
    resultAway: number,
    resultHome: number,
    time: string,
    yards: string
  ) {
    super(actions, ParticipantAway, participantHome, period, periodName, resultAway, resultHome, time);
    this.Quarters = quarters;
    this.MatchTime = matchTime;
    this.Try = paramtry;
    this.Yards = yards;
    this.RemainingPeriodTime = remainingPeriodTime;
    this.Position = position;
  }
}
