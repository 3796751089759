export class VerificationLiterals {
  header?: {
    title?: string;
    verified?: string;
    not_verified?: string;
    pending?: string;
    required?: string;
    account?: string;
    DNI?: string;
    justificante?: string;
  };
  buttons?: {
    DNI?: string;
    justificante?: string;
    otros?: string;
  };
  questions?: {
    FAQ?: {
      title?: string;
      QA?: [
        {
          question?: string;
          answer?: string;
        },
      ];
    };
    DNI?: {
      QA?: [
        {
          question?: string;
          answer?: string;
        },
      ];
    };
    justificante?: {
      QA?: [
        {
          question?: string;
          answer?: string;
        },
      ];
    };
    otros?: {
      QA?: [
        {
          question?: string;
          answer?: string;
        },
      ];
    };
  };

  constructor(header: any, buttons: any, questions: any) {
    this.header = header;
    this.buttons = buttons;
    this.questions = questions;
  }
}
