/* eslint-disable */
import { CommonModule } from "@angular/common";
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  inject,
} from "@angular/core";
import {
  LicenseTypes,
  VerificationStatus,
  VerificationStringStatus,
  VerificationTextColorClass,
} from "@models/MasterData";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { NewBaseService } from "@providers/newBase.service";
import { DeviceService } from "@services/device.service";

@Component({
  selector: "cs-verification-item",
  templateUrl: "./cs-verification-item.html",
  styleUrls: ["./cs-verification-item.scss"],
  standalone: true,
  imports: [CommonModule, TranslateModule],
  host: {
    class: "cs-verification-item color-dark",
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CsVerificationItemComponent {
  @Input() doc: any;
  @Output() navigate: EventEmitter<any> = new EventEmitter();
  globalVars!: GlobalVarsModel;
  private newBaseService = inject(NewBaseService);
  deviceService = inject(DeviceService);
  public translate = inject(TranslateService);
  isDesktop = false;
  isMobile = false;
  verStringStatus = VerificationStringStatus;

  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
    this.isDesktop = this.deviceService.isDesktop();
    this.isMobile = this.deviceService.isMobile();
  }

  @HostBinding("class")
  private get hostClass(): string {
    const defaultClass = "cs-verification-item color-dark";
    return defaultClass;
  }

  get iconName() {
    switch (this.doc.status) {
      //rejected
      case VerificationStatus.Rejected:
        return "icon-wrong color-alert";
      //approved
      case VerificationStatus.Verified:
        return "icon-right color-primary";
      //solicited
      case VerificationStatus.Requested:
        return "icon-alert color-secondary";
      //processing
      case VerificationStatus.Pending:
        return "icon-Clock color-progress";
      //inactive
      case VerificationStatus.Inactive:
        return null;
    }
  }

  get iconSource() {
    switch (this.doc.status) {
      //rejected
      case VerificationStatus.Rejected:
        return "icon-verification-fail.svg";
      //approved
      case VerificationStatus.Verified:
        return "icon-verification-completed.svg";
      //solicited
      case VerificationStatus.Requested:
        return "icon-verification-requested.svg";
      //processing
      case VerificationStatus.Pending:
        return "icon-verification-pending.svg";
      //inactive
      case VerificationStatus.Inactive:
        return null;
    }
  }

  get statusLabel() {
    const DEFAULT_STATUS_LABEL = "";
    const { status, type } = this.doc;

    if (!status) {
      return DEFAULT_STATUS_LABEL;
    }

    const commonLabels = {
      [VerificationStringStatus.Requested]: this.isMobile
        ? this.translate.instant("verification_requested_mobile")
        : this.translate.instant("verification_requested_desktop"),
      [VerificationStringStatus.Pending]: this.isMobile
        ? this.translate.instant("verification_pending_mobile")
        : this.translate.instant("verification_pending_desktop"),
      [VerificationStringStatus.Inactive]: this.translate.instant("verification_inactive"),
    };

    const documentLabels = {
      ...commonLabels,
      [VerificationStringStatus.Rejected]: this.isMobile
        ? this.translate.instant("verification_rejected_mobile")
        : this.translate.instant("verification_rejected_desktop"),
      [VerificationStringStatus.Verified]: this.translate.instant("verification_verified"),
    };

    const verificationLabels = {
      ...commonLabels,
      [VerificationStringStatus.Rejected]: this.translate.instant("verification_rejected_auto"),
      [VerificationStringStatus.Verified]: this.translate.instant("verification_approved"),
    };
    return type.toString() === VerificationStringStatus.Other
      ? verificationLabels[status.toString()]
      : documentLabels[status.toString()];
  }

  get statusLabelStyle() {
    const { status } = this.doc;
    let textColorClass: string;
    switch (status) {
      case VerificationStatus.Verified:
        textColorClass = VerificationTextColorClass.Verified;
        break;
      case VerificationStatus.Rejected:
        textColorClass = VerificationTextColorClass.Rejected;
        break;
      case VerificationStatus.Requested:
        textColorClass = VerificationTextColorClass.Requested;
        break;
      case VerificationStatus.Pending:
        textColorClass = VerificationTextColorClass.Pending;
        break;
    }
    return "cs-doc-type--title cs-doc-type--title--" + textColorClass;
  }

  public get isColombia() {
    return this.globalVars.licenseType === LicenseTypes.Colombia;
  }
}
