export class removeTicket {
  RetainItems: boolean;
  RetainSettings: boolean;
  RetainSingleStakes: boolean;
  RetainSystemStakes: boolean;

  constructor() {
    this.RetainItems = false;
    this.RetainSettings = true;
    this.RetainSingleStakes = false;
    this.RetainSystemStakes = false;
  }
}
