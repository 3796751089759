/* eslint-disable @typescript-eslint/no-explicit-any */
import { IonicModule, ModalController, NavParams } from '@ionic/angular';
import { Component, inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { DeviceService } from '../../../services/device.service';

/*
  Generated class for the HelpPage page.

  See http://ionicframework.com/docs/v2/components/#navigation for more info on
  Ionic pages and navigation.
*/
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'information-modal',
  templateUrl: './information-modal.html',
  styleUrls: ['./information-modal.scss'],
  standalone: true,
  imports: [CommonModule, IonicModule, TranslateModule]
})
export class InformationModal {
  srcUrl: any;
  ionViewLoaded = false;
  isMobile: boolean;

  navParams = inject(NavParams);
  sanitizer = inject(DomSanitizer);
  viewCtrl = inject(ModalController);
  device = inject(DeviceService);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {
    this.isMobile = this.device.isMobile();
  }

  ionViewDidLoad() {
    const params = this.navParams.get('url');
    this.srcUrl = this.sanitizer.bypassSecurityTrustResourceUrl(params);
  }

  ionViewDidEnter() {
    if (!this.ionViewLoaded) {
      this.ionViewLoaded = true;
      this.ionViewDidLoad();
    }
  }

  accept(val: boolean) {
    this.viewCtrl.dismiss(val);
  }
}
