<div [class.is-open]="!isCollapsed" class="codere-grid-filter background-color-regular border-color-regular">
  <div
    class="codere-grid-filter--item codere-icon"
    [ngClass]="isCollapsed ? 'arrow-down' : ''"
    (click)="toggle()"
    tappable
    [class.icon-arrow-bottom]="!blocked"
    #content
  >
    <div class="codere-grid-filter--icons">
      <span
        *ngIf="!!currentEvent?.StreamingEnabled && showIcon"
        class="codere-grid-filter--streaming has-streaming"
      ></span>
      <div class="codere-grid-filter--icon"></div>
    </div>
    <p class="codere-grid-filter--title color-dark" *ngIf="currentEvent?.Name">
      {{ currentEvent?.Name | translate }}
      <span *ngIf="currentEvent?.StartDate" class="codere-grid-filter--subtitle color-muted"
        >({{ currentEvent?.StartDate | extractHour }})</span
      >
    </p>
    <p class="codere-grid-filter--title color-dark" *ngIf="currentEvent?.value || currentEvent?.numberOfBetWays">
      {{ currentEvent?.text | translate }}
    </p>
  </div>
  <div class="codere-grid-filter--content background-color-base" #content>
    <ng-content></ng-content>
  </div>
</div>
