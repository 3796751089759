<ng-container *ngIf="!isMobile; else mobileView">
  <ion-content class="is-narrow-content background-color-muted">
    <!-- user-section-title mobile & desktop -->
    <user-section-title [sectionTitle]="'GetCardlostcard'"></user-section-title>

    <ion-list class="user-list" lines="none">
      <div class="recover-advice" [innerHTML]="'GetCardLosttxt' | translate">
        <!--No te preocupes, podrás reemplazarla por una nueva manteniendo tu saldo y tu historial de apuestas.-->
      </div>

      <ion-item class="card-item-recover" lines="inset">
        <ion-avatar slot="start">
          <i class="codere-icon icon-cardPin"></i>
        </ion-avatar>
        <ion-label>
          <h2 class="title">
            {{ 'GetCardChangePin' | translate }}
            <!--Cambiar Pin-->
          </h2>
          <p>
            {{ 'GetCardChangePin01txt' | translate }}<!-- En primer lugar, te recomendamos que cambies el PIN de la tarjeta para evitar su uso.-->
          </p>
          <a tappable="true" (click)="gotoPin()"
            >{{ 'GetCardChangePin' | translate }}<!--Cambiar pin-->
            <i class="codere-icon arrow icon-arrow-right arrow-link" item-right=""></i
          ></a>
        </ion-label>
      </ion-item>

      <ion-item class="card-item-recover" lines="full">
        <ion-avatar slot="start">
          <i class="codere-icon icon-cardPin"></i>
        </ion-avatar>
        <ion-label>
          <h2 class="title">
            {{ 'GetCardChangeGet' | translate }}<!--Consigue una nueva tarjeta en tu local Codere más cercano-->
          </h2>
          <p>{{ 'v2_reempTarj' | translate }}<!--# Podrás reemplazar la tarjeta perdida.--></p>
          <a tappable="true" (click)="gotoMap()"
            >{{ 'GetCardChangeGet02' | translate }}<!--Buscar el local Codere más cercano-->
            <i class="codere-icon arrow icon-arrow-right arrow-link" item-right=""></i
          ></a>
        </ion-label>
      </ion-item>

      <ion-item class="card-item-recover submit-info" lines="none">
        <ion-label>
          <h1>
            {{ 'GetCardChangeMake' | translate }}
            <!--Realiza el proceso para reemplazar la tarjeta perdida-->
          </h1>
          <h3>
            {{ 'v2_GetCardChangeMaketxt' | translate }}
            <!--Si tienes una tarjeta nueva, ya puedes realizar el proceso para reemplazar por la anterior.-->
          </h3>
          <div class="sendForm">
            <ion-button expand="full" (click)="gotoRecover2()">
              {{ 'GetCardChangeReplace' | translate }}<!--Remplazar tarjeta-->
            </ion-button>
          </div>
        </ion-label>
      </ion-item>
    </ion-list>

    <codere-footer></codere-footer>
  </ion-content>
</ng-container>

<ng-template #mobileView>
  <!-- eslint-disable @angular-eslint/template/eqeqeq -->
  <ion-header>
    <user-section-title [sectionTitle]="'GetCardlostcard'"></user-section-title>
  </ion-header>

  <ion-content class="background-color-muted">
    <ion-list class="user-list has-shadow" lines="none">
      <div class="recover-advice" [innerHTML]="'GetCardLosttxt' | translate">
        <!--No te preocupes, podrás reemplazarla por una nueva manteniendo tu saldo y tu historial de apuestas.-->
      </div>
      <ion-item class="card-item" lines="inset">
        <ion-avatar slot="start">
          <i class="codere-icon icon-cardPin"></i>
        </ion-avatar>
        <ion-label>
          <h2 class="my-card-recovery-title">
            {{ 'GetCardChangePin' | translate }}
            <!--Cambiar Pin-->
          </h2>
          <p>
            {{ 'GetCardChangePin01txt' | translate }}<!-- En primer lugar, te recomendamos que cambies el PIN de la tarjeta para evitar su uso.-->
          </p>
          <a tappable="true" (click)="gotoPin()"
            >{{ 'GetCardChangePin' | translate }}<!--Cambiar pin-->
            <i class="codere-icon arrow icon-arrow-right arrow-link" item-right=""></i
          ></a>
        </ion-label>
      </ion-item>

      <ion-item class="card-item" lines="full">
        <ion-avatar slot="start">
          <i class="codere-icon icon-cardPin"></i>
        </ion-avatar>
        <ion-label>
          <h2 class="my-card-recovery-title">
            {{ 'GetCardChangeGet' | translate }}<!--Consigue una nueva tarjeta en tu local Codere más cercano-->
          </h2>
          <p>{{ 'v2_reempTarj' | translate }}<!--# Podrás reemplazar la tarjeta perdida.--></p>
          <a tappable="true" (click)="gotoMap()"
            >{{ 'GetCardChangeGet02' | translate }}<!--Buscar el local Codere más cercano-->
            <i class="codere-icon arrow icon-arrow-right arrow-link" item-right=""></i
          ></a>
        </ion-label>
      </ion-item>

      <ion-item class="card-item submit-info" *ngIf="globalVars.licenseType != 4" lines="none">
        <ion-label>
          <h1>
            {{ 'GetCardChangeMake' | translate }}
            <!--Realiza el proceso para reemplazar la tarjeta perdida-->
          </h1>
          <h3>
            {{ 'v2_GetCardChangeMaketxt' | translate }}
            <!--Si tienes una tarjeta nueva, ya puedes realizar el proceso para reemplazar por la anterior.-->
          </h3>
          <div class="sendForm">
            <ion-button expand="full" (click)="gotoRecover2()">
              {{ 'GetCardChangeReplace' | translate }}<!--Remplazar tarjeta-->
            </ion-button>
          </div>
        </ion-label>
      </ion-item>
    </ion-list>
    <codere-footer></codere-footer>
  </ion-content>
</ng-template>
