import { Component, ViewChild, inject } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable eqeqeq */
// import { Keyboard } from '@ionic/angular';
import { IonContent, IonicModule, MenuController, NavController, NavParams, Platform } from "@ionic/angular";
import { LicenseTypes } from "@models/MasterData";
import { TranslateService } from "@ngx-translate/core";
import { FooterService } from "@providers/FooterService";
import { TrackingService } from "@providers/TrackingService";

import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { NewBaseService } from "@providers/newBase.service";
import { DeviceService } from "@services/device.service";

import { BackButton } from "@shared-common-components/back-button/back-button";
import { CodereFooterComponent } from "@shared-mso-components/footer/codere-footer/codere-footer";
import { UserSectionTitle } from "@shared-mso-components/user-section-title/user-section-title";

import { CommonModule } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { Utils } from "@utils/Utils";

export type InformationsTypes =
  | "milloncodere"
  | "howbet"
  | "infocookie"
  | "infodeposits"
  | "infowithdrawals"
  | "juegorespcol"
  | "juegoresparg"
  | "juegoresppa"
  | "juegorespnac"
  | "politicapriv"
  | "worldcupcenter"
  | "eventstrackingtesttool"
  | "reglas"
  | "tycweb"
  | "webdataprotection"
  | "worldCupCalendar"
  | "apostarmx"
  | "ifodeprmx"
  | "clubleatad"
  | "tarjetapremia"
  | "financialstatements";

@Component({
  standalone: true,
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "information-page",
  templateUrl: "./information-page.html",
  styleUrls: ["./information-page.scss"],
  imports: [CommonModule, IonicModule, TranslateModule, CodereFooterComponent, BackButton, UserSectionTitle],
  providers: [NavParams],
})
export class InformationPage {
  type: InformationsTypes;
  texto = "";
  scrHelp: any;
  appListener: any;
  globalVars!: GlobalVarsModel;
  isMobile: boolean;

  @ViewChild(IonContent, { static: false }) content: IonContent;

  private generalVars: { [key: string]: string } = null;
  private licenseVars: { [key: string]: string } = null;
  public trackEvent: TrackEvent;

  utils = inject(Utils);
  nav = inject(NavController);
  newBaseService = inject(NewBaseService);
  menu = inject(MenuController);
  navParams = inject(NavParams);
  sanitizer = inject(DomSanitizer);
  translate = inject(TranslateService);
  footerService = inject(FooterService);
  platform = inject(Platform);
  trackingService = inject(TrackingService);
  device = inject(DeviceService);
  router = inject(Router);
  activatedRouter = inject(ActivatedRoute);

  constructor() {
    this.isMobile = this.device.isMobile();

    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });

    this.activatedRouter.queryParams.subscribe((params) => {
      if (params["type"]) this.type = params["type"];
      if (params["animate"]) {
        if (params["animate"].type) this.type = params["animate"].type;
      }
      this.loadConstant();
    });

    this.appListener = this.listenEvents.bind(this);
  }

  async loadConstant() {
    const { generalVars, licenseVars } = await this.footerService.loadFooter();
    this.generalVars = generalVars;
    this.licenseVars = licenseVars;
    this.loadIframe();
  }

  loadIframe() {
    switch (this.type) {
      case "milloncodere":
        let position = "-1";
        let points = "0";
        if (this.navParams.get("position")) position = this.navParams.get("position");
        if (this.navParams.get("points")) points = this.navParams.get("points");
        this.scrHelp = this.sanitizer.bypassSecurityTrustResourceUrl(
          this.licenseVars["URL_CODERE_MILLION"] + position + "&points=" + points,
        );
        this.texto = "El mill\u00F3n de Codere";
        break;
      case "howbet":
        this.scrHelp = this.sanitizer.bypassSecurityTrustResourceUrl(this.licenseVars["URL_HOWBET"]);
        this.texto = "ftHelp";
        break;
      case "infocookie":
        this.scrHelp = this.sanitizer.bypassSecurityTrustResourceUrl(this.licenseVars["URL_POLITICA_COOKIES"]);
        this.texto = "ftHelp";
        break;
      case "infodeposits":
        this.scrHelp = this.sanitizer.bypassSecurityTrustResourceUrl(this.licenseVars["URL_INFO_DEPOSITS"]);
        this.texto = "ftHelp";
        break;
      case "infowithdrawals":
        this.scrHelp = this.sanitizer.bypassSecurityTrustResourceUrl(this.licenseVars["URL_INFO_WITHDRAWALS"]);
        this.texto = "ftHelp";
        break;
      case "juegorespcol":
      case "juegoresparg":
      case "juegoresppa":
        this.scrHelp = this.sanitizer.bypassSecurityTrustResourceUrl(this.licenseVars["URL_JUEGO_RESPONSABLE"]);
        this.texto = "ftHelp";
        break;
      case "juegorespnac":
        this.scrHelp = this.sanitizer.bypassSecurityTrustResourceUrl(this.licenseVars["URL_JUEGO_MAS_SEGURO"]);
        this.texto = "ftHelp";
        break;
      case "politicapriv":
        switch (this.globalVars.licenseType) {
          case LicenseTypes.Mexico:
            this.scrHelp = this.sanitizer.bypassSecurityTrustResourceUrl(
              "https://www.codere.mx/informaci%C3%B3n-legal/aviso-de-privacidad",
            );
            break;
          default:
            this.scrHelp = this.sanitizer.bypassSecurityTrustResourceUrl(
              this.licenseVars["URL_POLITICA_PRIVACIDAD"],
            );
            break;
        }
        this.texto = "ftHelp";
        break;
      case "worldcupcenter":
        switch (this.globalVars.licenseType) {
          case LicenseTypes.Mexico:
            this.scrHelp = this.sanitizer.bypassSecurityTrustResourceUrl("https://coderemx.statscore.com");
            break;
          default:
            this.scrHelp = this.sanitizer.bypassSecurityTrustResourceUrl(this.licenseVars["URL_WORD_CUP"]);
            break;
        }
        this.texto = "World Cup Center";
        break;
      case "eventstrackingtesttool":
        this.scrHelp = this.sanitizer.bypassSecurityTrustResourceUrl("./assets/eventstrackingtest/index.html");
        this.texto = "Events Tracking Test";
        this.appListener = this.listenEvents.bind(this);
        break;
      case "reglas":
        this.scrHelp = this.sanitizer.bypassSecurityTrustResourceUrl(this.licenseVars["URL_REGLAS"]);
        this.texto = "ftHelp";
        break;
      case "tycweb":
        switch (this.globalVars.licenseType) {
          case LicenseTypes.Nacional:
            this.scrHelp = this.sanitizer.bypassSecurityTrustResourceUrl(this.licenseVars["URL_TYC"]);
            this.texto = "ftHelp";
            break;
        }
        break;
      case "webdataprotection":
        switch (this.globalVars.licenseType) {
          case LicenseTypes.Nacional:
          case LicenseTypes.Colombia:
          case LicenseTypes.Panama:
            this.scrHelp = this.sanitizer.bypassSecurityTrustResourceUrl(this.licenseVars["URL_PROTECCION_DATOS"]);
            this.texto = "ftHelp";
            break;
        }
        break;
      case "worldCupCalendar":
        this.scrHelp = this.sanitizer.bypassSecurityTrustResourceUrl(this.licenseVars["URL_WORLDCUPCALENDAR"]);
        this.texto = "Grupos del Mundial";
        break;
      case "financialstatements":
        this.scrHelp = this.sanitizer.bypassSecurityTrustResourceUrl(this.licenseVars["URL_INSTRUCTIVO_COLOMBIA"]);
        this.texto = "financialstatements";
        break;
      default:
        this.backToHome();
        break;
    }
    this.goToTopPage();
  }

  helpageload(event) {
    event.target.classList.remove("nodisplay");
  }

  ionViewWillEnter(event) {
    window.addEventListener("message", this.appListener);
    this.utils.hideFormAccessoryBar(false);
  }

  ionViewDidLeave() {
    window.removeEventListener("message", this.appListener);
    this.utils.hideFormAccessoryBar(true);
  }

  listenEvents(e: any) {
    console.info("listenEvents");
    this.trackingService.customTrackEvent(e.data);
    if (e.data.action && e.data.action === "link") {
      if (this.globalVars.isNative) {
        console.info("isNative");
        window.open(e.data.link, "_system", "location=yes");
      } else {
        window.open(e.data.link, "_blank");
      }
    } else {
      this.trackingService.customTrackEvent(e.data);
    }
  }

  openModalUser() {
    this.menu.open("right");
  }

  backToHome() {
    this.router.navigate(["/"], { queryParams: { animate: false } });
  }
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  goBack() {}

  openLinkNative(link: string) {
    let target = "_blank";
    // TODO (no se usa)
    // const options: InAppBrowserOptions = {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const options = {
      location: "yes",
      hidden: "no",
      closebuttoncaption: "Cerrar",
      closebuttoncolor: "#ffffff",
      footer: "no",
      footercolor: "#000000",
      hardwareback: "yes",
      hidenavigationbuttons: "no",
      hideurlbar: "yes",
      navigationbuttoncolor: "#ffffff",
      toolbarcolor: "#000000",
      zoom: "yes",
      mediaPlaybackRequiresUserAction: "no",
      shouldPauseOnSuspend: "yes",
      useWideViewPort: "yes",

      toolbar: "yes",
      toolbartranslucent: "yes",
      enableViewportScale: "yes",
      allowInlineMediaPlayback: "yes",
      keyboardDisplayRequiresUserAction: "no",
      suppressesIncrementalRendering: "no",
      presentationstyle: "fullscreen", //pagesheet, formsheet, fullscreen
      transitionstyle: "coververtical", //fliphorizontal, crossdissolve, coververtical
      toolbarposition: "bottom", //top, bottom
      hidespinner: "no",
    };
    if (link.includes(".pdf")) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      target = "_system";
    }
    // TODO: Migrar iabService
    //this.iab.create(encodeURI(link), target, options);
  }

  goToTopPage() {
    if (!this.isMobile) {
      window.scrollY = window.innerHeight;
      const ionContentArray = Array.from(document.querySelectorAll("ion-content"));
      ionContentArray.forEach((ionContent) => {
        ionContent.scrollBy({
          top: -ionContent.scrollHeight,
          left: 0,
          behavior: "smooth",
        });
      });
    } else {
      this.content?.scrollToTop(1500);
    }
  }
}
