import { CommonModule } from "@angular/common";
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren,
  inject,
} from "@angular/core";
import { AlertController, NavController } from "@ionic/angular";
import { Swiper } from "swiper";

// Pipes
import { TruncateStringPipe } from "@pipes/truncateString.pipe";
import { SportsExtractDatePipe } from "@sports-pipes/sports.extract-date.pipe";

// Components
import { SbButtonComponent } from "@components/sports/sb-button/sb-button";
import { SbOverviewScoreSetsComponent } from "@components/sports/sb-overview-score-sets/sb-overview-score-sets";

// Models
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { I_MarqueeItem } from "@sports-models/index";

// Services
import { TrackingService } from "@providers/TrackingService";
import { DeviceService } from "@services/device.service";
import { SportService } from "@sports-services/index";

// Stores
import { NewTicketFacade } from "@sports-stores/ticket/index";

@Component({
  selector: "sb-marquee",
  templateUrl: "./sb-marquee.html",
  standalone: true,
  imports: [
    CommonModule,
    SbButtonComponent,
    TruncateStringPipe,
    SportsExtractDatePipe,
    SbOverviewScoreSetsComponent,
  ],
  styleUrls: ["./sb-marquee.scss"],
})
export class SbMarqueeComponent implements OnChanges, AfterViewInit {
  @ViewChildren("list") list: QueryList<any>;
  @ViewChild("swiper") swiperContainer: ElementRef;
  @Input() marqueeData: I_MarqueeItem[];
  @Input() switch: boolean;
  @Output() resize: EventEmitter<any> = new EventEmitter();
  marquees: I_MarqueeItem[] = [];
  public swiper;
  globalVars!: GlobalVarsModel;

  _element = inject(ElementRef);
  _renderer = inject(Renderer2);
  extractDate = inject(SportsExtractDatePipe);
  nav = inject(NavController);
  trackingService = inject(TrackingService);
  newTicketFacade = inject(NewTicketFacade);
  sportService = inject(SportService);
  alertCtrl = inject(AlertController);

  isMobile = false;
  isDesktop = true;
  deviceService = inject(DeviceService);

  constructor() {
    this.isDesktop = this.deviceService.isDesktop();
    this.isMobile = this.deviceService.isMobile();
  }

  ngOnChanges() {
    if (this.marqueeData.length > 0) {
      this.marquees = this.updateMarquee(this.marquees, this.marqueeData);
    }
    if (!this.swiper) return;
    this.swiper.update();
  }

  ngAfterViewInit() {
    this.display().then((_) => this.resize.emit());

    if (this.switch) return;
    this.swiper = new Swiper(this.swiperContainer.nativeElement, {
      slidesPerView: "auto",
      spaceBetween: 0,
      freeMode: true,
      direction: "horizontal",
      grabCursor: true,
      observer: false,
      loop: false,
      noSwiping: this.switch,
      followFinger: !this.switch,
      simulateTouch: !this.switch,
      allowSlidePrev: !this.switch,
      allowSlideNext: !this.switch,
    });
  }

  updateMarquee(previousMarqueeData: I_MarqueeItem[], nextMarqueeData: I_MarqueeItem[]): I_MarqueeItem[] {
    if (previousMarqueeData.length === 0) return nextMarqueeData;

    return nextMarqueeData.map((marqueeItem) => {
      if (!marqueeItem.Game) return marqueeItem;

      const previousMarqueeItem = previousMarqueeData.find(
        (prevItem) => prevItem.Game?.NodeId === marqueeItem.Game.NodeId,
      );

      if (!previousMarqueeItem) return marqueeItem;

      const updatedResults = marqueeItem.Game.Results.map((result) => {
        const previousResult = previousMarqueeItem.Game.Results.find(
          (prevResult) => prevResult.NodeId === result.NodeId,
        );

        return {
          ...result,
          upOdd: previousResult ? previousResult.Odd < result.Odd : false,
          downOdd: previousResult ? previousResult.Odd > result.Odd : false,
        };
      });

      return {
        ...marqueeItem,
        Game: {
          ...marqueeItem.Game,
          Results: updatedResults,
        },
      };
    });
  }

  private display() {
    return new Promise((res, rej) => {
      let display;
      if (Array.isArray(this.marquees)) {
        display = this.marquees.length < 1 ? "none" : "block";
      }

      this._renderer.setStyle(this._element.nativeElement, "display", display);
      this.resize.emit();

      if (this.swiper) {
        this.swiper.update();
      }
      res(null);
    });
  }

  navigate(NodeId: string, ParentNodeId: string) {
    this.sportService.deepLinkFromMarquee(ParentNodeId, NodeId);
  }

  async showDismiss(title: string, msg: string) {
    const alertOptions = {
      enableBackdropDismiss: false,
      title: title,
      message: msg,
      buttons: [
        {
          role: "cancel",
          text: "OK",
          handler: () => {
            //nothing, this is just a dismiss
          },
        },
      ],
    };
    const alert = await this.alertCtrl.create(alertOptions);
    alert.present();
  }

  getImgString(team) {
    return `assets/global/img/marquee-jerseys/${team}`;
  }

  /**
   *
   * @param game
   * @param event
   * @param gameName
   * @param type
   */
  bet(game, event, gameName: string, type: string) {
    console.log({ type });

    const data = {
      ...game,
      EventDate: this.extractDate.transform(event.StartDate),
      EventName: `${event.ParticipantHome} - ${event.ParticipantAway}`,
      GameName: gameName,
    };
    this.newTicketFacade.checkSelection(data, data.NodeId);
  }

  getPeriodAndMatchTime(liveData, sportHandle) {
    let periodAndMatchTime = "";

    switch (sportHandle) {
      case "basketball":
        periodAndMatchTime = `${liveData.PeriodName} ${liveData.RemainingPeriodTime}`;
        break;
      case "tennis":
        periodAndMatchTime = `${liveData.PeriodName}`;
        break;
      default:
        periodAndMatchTime = `${liveData.PeriodName} '${liveData.MatchTime}`;
        break;
    }

    return periodAndMatchTime;
  }

  isLiveTennis(sportHandle, isLive) {
    return isLive && sportHandle === "tennis";
  }
}
