import { Component, inject, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { CommonModule, LocationStrategy } from '@angular/common';
import { of, switchMap } from 'rxjs';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { GlobalVarsModel } from '@models/ModelGlobalsVar';

import { DeviceService } from '@services/device.service';
import { NewBaseService } from '@providers/newBase.service';

@Component({
  selector: 'sports-page-header',
  templateUrl: './sports-page-header.component.html',
  styleUrls: ['./sports-page-header.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, TranslateModule]
})
export class SportsPageHeaderComponent {
  @Input() hasBackButton: boolean = true;
  @Input() goHome: boolean = false;
  @Input() title: string;
  newBaseService = inject(NewBaseService);
  locationStrategy = inject(LocationStrategy);
  deviceService = inject(DeviceService);
  router = inject(Router);
  translate = inject(TranslateService);

  isDesktop = this.deviceService.isDesktop();
  isMobile = this.deviceService.isMobile();

  globalVars!: GlobalVarsModel;

  constructor() {
    this.newBaseService.getVars
      .pipe(
        switchMap((data: GlobalVarsModel) => {
          this.globalVars = data;
          return of(null);
        })
      )
      .subscribe();
  }

  navigate() {
    try {
      return this.locationStrategy.historyGo(-1);
    } catch (error) {
      return this.router.navigateByUrl('/');
    }
  }

  close() {
    return this.router.navigateByUrl('/');
  }
}
