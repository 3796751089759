<ion-accordion-group>
  <ion-accordion value="first" toggleIcon="''">
    <ion-item slot="header">
      <ion-label class="codpromo">{{ label }}</ion-label>
    </ion-item>
    <div slot="content" class="accordion-content">
      <cdr-input [length]="length" [errorsCol]="errorsCol" [modal]="modal" [fcName]="fcName" [fgrp]="fgrp"></cdr-input>
    </div>
  </ion-accordion>
</ion-accordion-group>
