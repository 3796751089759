/* eslint-disable eqeqeq */
import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, Output, inject } from "@angular/core";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { VerificationLiterals } from "@models/payments/VerificationLiterals";
import { VerificationOption } from "@models/payments/VerificationOption";
import { TranslateModule, TranslateService } from "@ngx-translate/core";

enum verificationStatuses {
  verified = "verified",
  notVerified = "not-verified",
  pending = "pending",
}
@Component({
  selector: "cs-document-header",
  templateUrl: "./cs-document-header.html",
  styleUrls: ["./cs-document-header.scss"],
  standalone: true,
  host: {
    class: "cs-document-header background-color-white color-dark",
  },
  imports: [TranslateModule, CommonModule],
})
export class CsDocumentHeaderComponent {
  @Input() isMobile: boolean;
  @Input() isVerificationCompleted: boolean;
  @Input() isVerificationPending: boolean;
  @Input() verificationLiterals: VerificationLiterals;
  @Input() verificationOptions: VerificationOption[];
  @Output() verificationOption: EventEmitter<any> = new EventEmitter();

  verificationStatus: verificationStatuses;
  verStatus = verificationStatuses;
  verificationWarningMessage: string;

  verificationOptionSelected: VerificationOption;

  private globalVars!: GlobalVarsModel;
  public translate = inject(TranslateService);

  getStatus(): verificationStatuses {
    return this.isVerificationCompleted
      ? verificationStatuses.verified
      : this.isVerificationPending
        ? verificationStatuses.pending
        : verificationStatuses.notVerified;
  }

  getWarningMessage(): string {
    let message = "";
    switch (this.getStatus()) {
      case verificationStatuses.verified:
        message = this.verificationLiterals.header.verified; //"Tu cuenta ha sido verificada"; //verification_text_verified
        break;
      case verificationStatuses.notVerified:
        message = this.verificationLiterals.header.not_verified; //"Tu cuenta está actualmente sin verificar."; //verification_text_not_verified
        break;
      case verificationStatuses.pending:
        message = this.verificationLiterals.header.pending; //"Tu cuenta está siendo revisada"; //verification_text_pending
        break;
      default:
        message = "";
        break;
    }
    return message;
  }

  getIcon(): string {
    const rootUrl = "assets/global/img/info/icono_info_";
    return rootUrl + this.getStatus() + ".svg";
  }

  verificationOptionClicked(verOpt: VerificationOption) {
    this.verificationOptions.forEach((verificationOption) => {
      verificationOption.type === verOpt.type
        ? ((verificationOption.selected = true), (this.verificationOptionSelected = verificationOption))
        : (verificationOption.selected = false);
    });
    this.verificationOption.emit(this.verificationOptionSelected);
  }
}
