/* eslint-disable eqeqeq */
import { Pipe, PipeTransform, inject } from "@angular/core";
import { LicenseTypes } from "@models/MasterData";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { NewBaseService } from "@providers/newBase.service";

/**
 * pipe para parsear la moneda dependiendo del pais,
 * se usa de una manera global en el proyecto
 */
@Pipe({
  name: "parseStakeMoney",
  standalone: true,
})
export class ParseStakeMoney implements PipeTransform {
  globalVars!: GlobalVarsModel;
  newBaseService = inject(NewBaseService);

  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
  }
  /**
   *
   * @param t : la cantidad en formato string
   * @param args : formato
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transform(t?: string, args: any[] = []): string {
    if (!t && args.length === 0) return;
    t = t + "";

    let format: number;
    if (args.length > 0) {
      format = parseInt(args[0]);
    } else {
      format = 0;
    }

    //format 0 -> devuelve N/A para el ticket cuando es 0 o negativo
    //format 1 -> devuelve el número formateado con la divisa, sea positivo o negativo
    //format 4 -> para slot de casino
    const auxT = t.toString().replace(",", ".");

    let intT: number = parseFloat(auxT);
    if (intT <= 0) return "N/A";
    let result: string;

    intT = Math.floor(parseFloat((intT * 100).toFixed(2))) / 100;
    if ((intT * 100) % 100 === 0) {
      //si es resultado redondo, quitamos la parte flotante
      result = intT.toFixed();
      if (this.globalVars.LICENSE_TYPE === LicenseTypes.Mexico) {
        result = this.setResult(",", intT);
      } else {
        result = this.setResult(".", intT);
      }
    } else {
      if (this.globalVars.LICENSE_TYPE === LicenseTypes.Mexico) {
        result = intT.toFixed(2).replace(",", ".");
        result = this.setResultWithDecimals(",", result);
      } else {
        result = intT.toFixed(2).replace(".", ",");
        result = this.setResultWithDecimals(".", result);
      }
    }

    //adds the currency symbol

    if (format === 1) {
      switch (this.globalVars.LICENSE_TYPE) {
        case LicenseTypes.Mexico:
        case LicenseTypes.Colombia:
        case LicenseTypes.ArgentinaCaba:
        case LicenseTypes.ArgentinaPBA:
        case LicenseTypes.ArgentinaMendoza:
          return "$" + result;
        case LicenseTypes.Panama:
          return result + "$";
        default:
          return result + "€";
      }
    }

    return result;
  }

  setResult(character, intT) {
    let num = Math.floor(intT).toString();
    const car = num.length;
    if (num.length > 9) {
      num =
        num.substring(0, car - 9) +
        character +
        num.substring(car - 9, car - 9 + 3) +
        character +
        num.substring(car - 9 + 3, car - 9 + 3 + 3) +
        character +
        num.substring(car - 9 + 3 + 3, car);
    } else if (num.length > 6) {
      num =
        num.substring(0, car - 6) +
        character +
        num.substring(car - 6, car - 6 + 3) +
        character +
        num.substring(car - 6 + 3, car);
    } else if (num.length > 3) {
      num = num.substring(0, car - 3) + character + num.substring(car - 3, car);
    }
    return num;
  }

  setResultWithDecimals(character, result) {
    let num = result.toString();
    const car = num.length;
    if (num.length > 15) {
      num =
        num.substring(0, car - 15) +
        character +
        num.substring(car - 15, car - 15 + 3) +
        character +
        num.substring(car - 15 + 3, car - 15 + 3 + 3 + 3) +
        character +
        num.substring(car - 15 + 3 + 3 + 3, car);
    } else if (num.length > 12) {
      num =
        num.substring(0, car - 12) +
        character +
        num.substring(car - 12, car - 12 + 3) +
        character +
        num.substring(car - 12 + 3, car - 12 + 3 + 3) +
        character +
        num.substring(car - 12 + 3 + 3, car);
    } else if (num.length > 9) {
      num =
        num.substring(0, car - 9) +
        character +
        num.substring(car - 9, car - 9 + 3) +
        character +
        num.substring(car - 9 + 3, car);
    } else if (num.length > 6) {
      num = num.substring(0, car - 6) + character + num.substring(car - 6, car);
    }
    return num;
  }
}
