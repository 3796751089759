/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { IonContent, IonicModule, NavController } from '@ionic/angular';
import { Component, ViewChild, inject } from '@angular/core';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, FormBuilder, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Observable, from } from 'rxjs';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { UserService } from '@providers/UserService';
import { UserServiceMSO } from '@providers/UserServiceMSO';
import { EventsService } from '@providers/EventsService';
import { ErrorService } from '@providers/ErrorService';
import { Utils } from '@utils/Utils';
import { GlobalVarsModel } from '@models/ModelGlobalsVar';
import { NewBaseService } from '@providers/newBase.service';
import { CodereFooterComponent } from '@shared-mso-components/footer/codere-footer/codere-footer';
import { DeviceService } from '@services/device.service';
import { MSO_PATHS } from '@shared-constants/routes';
import { UserSectionTitle } from '@shared-mso-components/user-section-title/user-section-title';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'card-request-form-page',
  templateUrl: './card-request-form.html',
  styleUrls: ['./card-request-form.scss'],
  standalone: true,
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    TranslateModule,
    ReactiveFormsModule,
    CodereFooterComponent,
    UserSectionTitle
  ]
})
export class CardRequestFormPage {
  @ViewChild(IonContent) content: IonContent;
  provincias: Array<{ code: string; label: string; ccaa: string }>;
  ccaa: Array<{ nameCode: string; label: string; code: string }>;
  allowedCCAA: Array<{ nameCode: string; label: string; code: string }>;
  allowedProvincias: Array<{ code: string; label: string; ccaa: string }>;
  myForm: FormGroup;
  comunidadUser: string;
  user: any;
  urlCondiciones: string;
  isMobile: boolean;
  globalVars!: GlobalVarsModel;

  nav = inject(NavController);
  newBaseService = inject(NewBaseService);
  userService = inject(UserService);
  userServiceMSO = inject(UserServiceMSO);
  fb = inject(FormBuilder);
  utils = inject(Utils);
  translate = inject(TranslateService);
  events = inject(EventsService);
  errorService = inject(ErrorService);
  router = inject(Router);
  device = inject(DeviceService);

  constructor() {
    this.isMobile = this.device.isMobile();
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });

    this.urlCondiciones = '';
    this.ccaa = [
      { nameCode: 'andalucia', label: 'Andalucía', code: '01' },
      { nameCode: 'aragon', label: 'Aragón', code: '02' },
      { nameCode: 'asturias', label: 'Asturias', code: '03' },
      { nameCode: 'baleares', label: 'Islas Baleares', code: '04' },
      { nameCode: 'canarias', label: 'Islas Canarias', code: '05' },
      { nameCode: 'cantabria', label: 'Cantabria', code: '06' },
      { nameCode: 'castillaleon', label: 'Castilla León', code: '07' },
      { nameCode: 'castillalamancha', label: 'Castilla la Mancha', code: '08' },
      { nameCode: 'catalunya', label: 'Cataluña', code: '09' },
      { nameCode: 'valencia', label: 'C. Valenciana', code: '10' },
      { nameCode: 'extremadura', label: 'Extremadura', code: '11' },
      { nameCode: 'galicia', label: 'Galicia', code: '12' },
      { nameCode: 'madrid', label: 'Madrid', code: '13' },
      { nameCode: 'murcia', label: 'Murcia', code: '14' },
      { nameCode: 'navarra', label: 'Navarra', code: '15' },
      { nameCode: 'paisvasco', label: 'País Vasco', code: '16' },
      { nameCode: 'larioja', label: 'La Rioja', code: '17' },
      { nameCode: 'ceuta', label: 'Ceuta', code: '18' },
      { nameCode: 'melilla', label: 'Melilla', code: '19' }
    ];

    this.provincias = [
      { code: '01', label: 'Álava', ccaa: '16' },
      { code: '02', label: 'Albacete', ccaa: '08' },
      { code: '03', label: 'Alicante', ccaa: '10' },
      { code: '04', label: 'Almería', ccaa: '01' },
      { code: '05', label: 'Ávila', ccaa: '07' },
      { code: '06', label: 'Badajoz', ccaa: '11' },
      { code: '07', label: 'Islas Baleares', ccaa: '04' },
      { code: '08', label: 'Barcelona', ccaa: '09' },
      { code: '09', label: 'Burgos', ccaa: '07' },
      { code: '10', label: 'Cáceres', ccaa: '11' },
      { code: '11', label: 'Cádiz', ccaa: '01' },
      { code: '12', label: 'Castellón', ccaa: '10' },
      { code: '13', label: 'Ciudad Real', ccaa: '08' },
      { code: '14', label: 'Córdoba', ccaa: '01' },
      { code: '15', label: 'La Coruña', ccaa: '12' },
      { code: '16', label: 'Cuenca', ccaa: '08' },
      { code: '17', label: 'Gerona', ccaa: '09' },
      { code: '18', label: 'Granada', ccaa: '01' },
      { code: '19', label: 'Guadalajara', ccaa: '08' },
      { code: '20', label: 'Guipúzcoa', ccaa: '16' },
      { code: '21', label: 'Huelva', ccaa: '01' },
      { code: '22', label: 'Huesca', ccaa: '02' },
      { code: '23', label: 'Jaén', ccaa: '01' },
      { code: '24', label: 'León', ccaa: '07' },
      { code: '25', label: 'Lérida', ccaa: '09' },
      { code: '26', label: 'La Rioja', ccaa: '17' },
      { code: '27', label: 'Lugo', ccaa: '12' },
      { code: '28', label: 'Madrid', ccaa: '13' },
      { code: '29', label: 'Málaga', ccaa: '01' },
      { code: '30', label: 'Murcia', ccaa: '14' },
      { code: '31', label: 'Navarra', ccaa: '15' },
      { code: '32', label: 'Orense', ccaa: '12' },
      { code: '33', label: 'Asturias', ccaa: '03' },
      { code: '34', label: 'Palencia', ccaa: '07' },
      { code: '35', label: 'Las Palmas', ccaa: '05' },
      { code: '36', label: 'Pontevedra', ccaa: '12' },
      { code: '37', label: 'Salamanca', ccaa: '07' },
      { code: '38', label: 'Santa Cruz de Tenerife', ccaa: '05' },
      { code: '39', label: 'Cantabria', ccaa: '06' },
      { code: '40', label: 'Segovia', ccaa: '07' },
      { code: '41', label: 'Sevilla', ccaa: '01' },
      { code: '42', label: 'Soria', ccaa: '07' },
      { code: '43', label: 'Tarragona', ccaa: '09' },
      { code: '44', label: 'Teruel', ccaa: '02' },
      { code: '45', label: 'Toledo', ccaa: '08' },
      { code: '46', label: 'Valencia', ccaa: '10' },
      { code: '47', label: 'Valladolid', ccaa: '07' },
      { code: '48', label: 'Vizcaya', ccaa: '16' },
      { code: '49', label: 'Zamora', ccaa: '07' },
      { code: '50', label: 'Zaragoza', ccaa: '02' },
      { code: '51', label: 'Ceuta', ccaa: '18' },
      { code: '52', label: 'Melilla', ccaa: '19' }
    ];

    this.allowedCCAA = [];

    this.user = {
      nombre: '',
      ciudad: '',
      cp: ''
    };

    this.resetForm();
  }

  resetForm() {
    this.myForm = this.fb.group({
      comunidadTarjeta: ['', Validators.required],
      nombre: [{ value: '', disabled: true }, Validators.required],
      calle: ['', Validators.required],
      piso: [''],
      ciudad: ['', Validators.required],
      cp: ['', this.zipValidator],
      provincia: ['', Validators.required],
      pin: [
        '',
        Validators.compose([
          Validators.required,
          Validators.maxLength(4),
          Validators.minLength(4),
          Validators.pattern('[0-9]*')
        ])
      ],
      repin: [
        '',
        Validators.compose([
          Validators.required,
          Validators.maxLength(4),
          Validators.minLength(4),
          Validators.pattern('[0-9]*')
        ])
      ],
      legal: [false, Validators.required]
    });

    this.myForm.controls['comunidadTarjeta'].valueChanges.subscribe((value: string) => {
      this.urlCondiciones = this.getConditions(value);
      this.myForm.controls['provincia'].reset();
      this.setProvincias(value);
    });
  }

  zipValidator = (control: FormControl) => {
    const zipRegEsp = /^[0-9]{5}$/;
    const zipRegNR = /^[0-9]*$/;
    if (zipRegNR.test(control.value)) {
      if (zipRegEsp.test(control.value) && parseInt(control.value) < 52999) {
        return null;
      } else {
        return { valid: 'notValid' };
      }
    } else {
      return { valid: 'notValid' };
    }
  };

  getAllowedCCAA(): Observable<any> {
    const q = new Promise((resolve, reject) => {
      this.allowedCCAA = [];
      this.userService.getAllowedRegions().subscribe(
        (data) => {
          const arrData: Array<string> = data.Regions.split(';');
          for (const x in arrData) {
            for (let i = 0; i < this.ccaa.length; i++) {
              if (this.ccaa[i].nameCode == arrData[x]) {
                this.allowedCCAA.push(this.ccaa[i]);
              }
            }
          }
          this.allowedCCAA = this.allowedCCAA.sort((n1, n2) => {
            if (n1.label > n2.label) {
              return 1;
            }

            if (n1.label < n2.label) {
              return -1;
            }
            return 0;
          });
          resolve({ resultado: 1 });
        },
        (err) => {
          this.utils.showError(this.translate.instant('m_menu_serverFail'));
          reject({ resultado: 0 });
        }
      );
    });
    return from(q);
  }

  ionViewWillEnter() {
    this.events.publish('footer:refresh', this.content);
  }

  ionViewDidEnter() {
    this.resetForm();
    this.getUserData();
  }

  matchCodeCommunity(prov: string, cp: string): boolean {
    if (prov != '' && cp != '') {
      const province = this.provincias.find((p) => p.code === prov);
      const ccaacode = cp.substring(0, 2);
      return province && province.code === ccaacode;
    } else {
      return false;
    }
  }

  setProvincias(comunityCode: string) {
    this.allowedProvincias = this.provincias.filter((p) => p.ccaa === comunityCode);
  }

  sendForm() {
    this.myForm.controls['repin'].setErrors(null);
    this.myForm.controls['cp'].setErrors(null);
    this.myForm.controls['legal'].setErrors(null);
    if (!this.myForm.controls['legal'].value) {
      this.myForm.controls['legal'].setErrors({ required: true });
    }
    if (this.myForm.controls['pin'].value != this.myForm.controls['repin'].value) {
      this.myForm.controls['repin'].setErrors({ same: 'notValid' });
    }
    if (!this.matchCodeCommunity(this.myForm.controls['provincia'].value, `${this.myForm.controls['cp'].value}`)) {
      this.myForm.controls['cp'].setErrors({ same: 'notValid' });
    }
    if (this.myForm.valid) {
      this.doSendForm();
    } else {
      for (const x in this.myForm.controls) {
        this.myForm.controls[x].markAsTouched();
      }
    }
  }

  getConditions(region: string): string {
    const url = 'https://www.codere.es/TyC_Tarjeta_Codere/' + region + '_TyC_Tarjeta.html?native=false';
    return url;
  }

  doSendForm() {
    const creds: any = {
      city: this.myForm.controls['ciudad'].value,
      state: this.provincias.find((p) => p.code === this.myForm.controls['provincia'].value)?.label,
      ad1: this.myForm.controls['calle'].value,
      ad2: this.myForm.controls['piso'].value,
      postalCode: this.myForm.controls['cp'].value,
      cardregion: this.myForm.controls['comunidadTarjeta'].value,
      pin: this.myForm.controls['pin'].value
    };

    this.userService.createCardRequest(creds).subscribe(
      (data) => {
        if (data.success) {
          this.globalVars.user.codereCard.active = true;
          this.globalVars.user.codereCard.cardNumber = data.cardNumber;
          this.globalVars.user.codereCard.region = this.myForm.controls['comunidadTarjeta'].value;
          this.resetForm();
          this.events.publish('card:refresh');
          this.utils.alert(
            false,
            this.translate.instant('getcardpostaltit'),
            this.translate.instant('GetCardcodpostal'),
            'OK',
            () => {
              setTimeout(() => {
                this.router.navigate([MSO_PATHS.BASE + '/' + MSO_PATHS.CardRequestOkPage]);
              }, 800);
            }
          );
        } else {
          if (data.message != undefined) {
            if (isNaN(data.message)) {
              this.utils.showError(data.message);
            } else {
              if (data.message == '175') {
                //not verified user
                const title: string = this.translate.instant('tFail');
                const msg = this.translate.instant('cardlinkeddvprocess');
                this.utils.confirm(
                  false,
                  title,
                  msg,
                  this.translate.instant('tCancel'),
                  null,
                  this.translate.instant('check')
                );
              } else {
                this.errorService.searchErrorCode(data.message).subscribe(
                  (data3) => {
                    this.utils.showError(data3);
                  },
                  (err) => {
                    this.utils.showError(data.message);
                  }
                );
              }
            }
          } else {
            this.utils.showError(this.translate.instant('GetCardrequestingError'));
          }
        }
      },
      (err) => {
        this.utils.showError(this.translate.instant('m_menu_serverFail'));
      }
    );
  }
  getComunidad(cp: string): string {
    //gets zip code and return ccaa code
    const provCode: string = cp.substr(0, 2);

    for (let i = 0; i < this.provincias.length; i++) {
      if (this.provincias[i].code == provCode) {
        return this.provincias[i].ccaa;
      }
    }
    return '';
  }
  getNameCode(code: string): string {
    //gets code and return ccaa nameCode

    for (let i = 0; i < this.ccaa.length; i++) {
      if (this.ccaa[i].code == code) {
        return this.ccaa[i].nameCode;
      }
    }
    return '';
  }

  getUserData() {
    this.userServiceMSO.getUserData().subscribe({
      next: (data) => {
        const nom: string = data.Nombre != null ? data.Nombre + ' ' : '';
        const ape1: string = data.PrimerApellido != null ? data.PrimerApellido + ' ' : '';
        const ape2: string = data.SegundoApellido != null ? data.SegundoApellido : '';
        this.user.nombre = nom + ape1 + ape2;
        this.myForm.patchValue({
          nombre: this.user.nombre
        });
        this.user.cp = data.CodigoPostal;
        this.getAllowedCCAA().subscribe(() => {
          this.setConditions();
        });
      },
      error: (err) => {
        this.utils.showError(this.translate.instant('m_menu_serverFail'));
      }
    });
  }

  setConditions() {
    if (this.user.cp) {
      this.comunidadUser = this.getComunidad(this.user.cp);
      if (this.comunidadUser != '') {
        const nc: string = this.getNameCode(this.comunidadUser);
        this.myForm.controls['provincia'].setValue(nc);
        if (this.isAllowedComunidad(this.comunidadUser)) {
          this.myForm.controls['comunidadTarjeta'].setValue(this.comunidadUser);
          this.urlCondiciones = this.getConditions(this.comunidadUser);
        } else {
          this.comunidadUser = '0';
          this.urlCondiciones = '';
        }
      } else {
        this.comunidadUser = '0';
        this.urlCondiciones = '';
      }
    } else {
      this.comunidadUser = '0';
      this.urlCondiciones = '';
    }
  }

  getFormControl(controlName: string): FormControl {
    return this.myForm.get(controlName) as FormControl;
  }

  isAllowedComunidad(codeComunidad: string): boolean {
    const arrComunidades = this.allowedCCAA;
    let encontrado = false;
    for (let i = 0; i < arrComunidades.length; i++) {
      if (arrComunidades[i].code == codeComunidad) {
        encontrado = true;
      }
    }
    return encontrado;
  }
}
