/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-empty-function */
import { IonContent, IonicModule, NavController } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { Component, ViewChild, inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { UserServiceMSO } from '../../../providers/UserServiceMSO';
import { UserService } from '../../../providers/UserService';
import { TrackingService } from '../../../providers/TrackingService';
import { EventsService } from '../../../providers/EventsService';
import { ErrorService } from '../../../providers/ErrorService';
import { GlobalVarsModel } from '../../../models/ModelGlobalsVar';
import { NewBaseService } from '../../../providers/newBase.service';
import { MyCardPage } from '../my-card/my-card';
import { CodereNavbar } from '../../../components/common/codere-navbar/codere-navbar';
import { CodereFooterComponent } from '../../../components/mso/footer/codere-footer/codere-footer';
import { UserSectionTitle } from '../../../components/mso/user-section-title/user-section-title';
import { Utils } from '../../../utils/Utils';
import { DeviceService } from '../../../services/device.service';
import { MSO_PATHS } from '../../../lib/constants/routes';

import { LicenseTypes } from '../../../models/MasterData';

import { Router } from '@angular/router';

@Component({
  standalone: true,
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'card-link-page',
  templateUrl: './card-link.html',
  styleUrls: ['./card-link.scss'],
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    ReactiveFormsModule,
    CodereFooterComponent,
    CodereNavbar,
    MyCardPage,
    UserSectionTitle
  ]
})
export class CardLinkPage {
  @ViewChild(IonContent) content: IonContent;

  steps: string;

  cardNumber: string;
  cardRegion: string;
  urlCondiciones: string;

  myForm1: FormGroup;
  myForm2: FormGroup;
  myForm3: FormGroup;
  myForm4: FormGroup;

  globalVars!: GlobalVarsModel;

  isMobile: boolean = false;

  nav = inject(NavController);
  trackingService = inject(TrackingService);
  events = inject(EventsService);
  fb = inject(FormBuilder);
  newBaseService = inject(NewBaseService);
  userService = inject(UserService);
  userServiceMSO = inject(UserServiceMSO);
  utils = inject(Utils);
  translate = inject(TranslateService);
  errorService = inject(ErrorService);
  device = inject(DeviceService);
  router = inject(Router);

  constructor() {
    this.isMobile = this.device.isMobile();
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
    this.steps = 'step1';
    this.resetForm();
  }

  resetForm() {
    this.myForm1 = this.fb.group({
      numTarjeta: ['', [Validators.required, this.cardValidator]]
    });

    this.myForm2 = this.fb.group({
      pin1: [
        '',
        Validators.compose([
          Validators.required,
          Validators.maxLength(4),
          Validators.minLength(4),
          Validators.pattern('[0-9]*')
        ])
      ],
      repin1: [
        '',
        Validators.compose([
          Validators.required,
          Validators.maxLength(4),
          Validators.minLength(4),
          Validators.pattern('[0-9]*')
        ])
      ],
      legal1: [false, Validators.required]
    });
    this.myForm3 = this.fb.group({
      pin2: [
        '',
        Validators.compose([
          Validators.required,
          Validators.maxLength(4),
          Validators.minLength(4),
          Validators.pattern('[0-9]*')
        ])
      ],
      legal2: [false, Validators.required]
    });

    this.myForm4 = this.fb.group({
      mexicoNumTarjeta: ['', [Validators.required, this.mexicoCardValidator]]
    });
  }

  ionViewWillEnter() {
    this.reloadCodereCard();
    this.router.navigate([MSO_PATHS.BASE + '/' + MSO_PATHS.CardLinkPage]);
    this.trackingService.trackEvent(['VinculateCodereCard', '', '', 'Vincular Tarjeta Codere', 'event']);
    this.events.publish('footer:refresh', this.content);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  ionViewDidEnter() {}

  reloadCodereCard() {
    this.userServiceMSO.loadCodereCard().subscribe(
      (data) => {
        if (data.success) {
          this.globalVars.user.codereCard.active = true;
          this.globalVars.user.codereCard.cardNumber = data.cardnumber;
          this.globalVars.user.codereCard.region = data.cardnumber.substr(2, 2);
          this.events.publish('card:refresh');
          setTimeout(() => {
            this.router.navigate([MSO_PATHS.BASE + '/' + MSO_PATHS.MyCardPage]);
          }, 800);
        } else {
          this.globalVars.user.codereCard.active = false;
        }
      },
      (err) => {
        this.globalVars.user.codereCard.active = false;
      },
      () => {}
    );
  }

  getConditions(region: string): string {
    const url = 'https://www.codere.es/TyC_Tarjeta_Codere/' + region + '_TyC_Tarjeta.html?native=false';
    return url;
  }

  sendPin1() {
    //pin and repin - new card
    if (!this.myForm2.controls['legal1'].value) {
      this.myForm2.controls['legal1'].setErrors({ valid: 'notValid' });
    }

    if (this.myForm2.valid) {
      if (this.myForm2.controls['pin1'].value == this.myForm2.controls['repin1'].value) {
        this.doSendPin1();
      } else {
        this.myForm2.controls['repin1'].setErrors({ same: 'notValid' });
      }
    } else {
      for (const x in this.myForm2.controls) {
        this.myForm2.controls[x].markAsTouched();
      }
    }
  }

  sendPin2() {
    if (!this.myForm3.controls['legal2'].value) {
      this.myForm3.controls['legal2'].setErrors({ valid: 'notValid' });
    }
    //pin only - card exists
    if (this.myForm3.valid) {
      this.doSendPin2();
    } else {
      for (const x in this.myForm3.controls) {
        this.myForm3.controls[x].markAsTouched();
      }
    }
  }

  doSendPin1() {
    const creds = {
      cardNumber: this.cardNumber,
      pin: this.myForm2.controls['pin1'].value
    };
    this.userService.addNewCardToClient(creds).subscribe(
      (data) => {
        if (data.success) {
          this.globalVars.user.codereCard.cardNumber = this.cardNumber;
          this.globalVars.user.codereCard.active = true;
          const title: string = '¡' + this.translate.instant('GetCardLink') + '!';
          const msg: string =
            this.translate.instant('CodereLinkedCard01') +
            ' ' +
            this.cardNumber +
            ' ' +
            this.translate.instant('CodereLinkedCard02');
          this.utils.alert(false, title, msg, 'OK', () => {
            //update rigth menu
            this.events.publish('card:refresh');
            setTimeout(() => {
              this.router.navigate([MSO_PATHS.BASE + '/' + MSO_PATHS.MyCardPage]);
            }, 800);
          });
        } else {
          if (data.message != undefined) {
            if (isNaN(data.message)) {
              this.utils.showError(this.translate.instant('GetCardActivateTarKO'));
            } else {
              if (data.message == '175') {
                //not verified user
                this.utils.confirm(
                  false,
                  this.translate.instant('tFail'),
                  'Solo se podrá vincular la tarjeta Codere en el caso de que hayas pasado el proceso de verificación documental',
                  this.translate.instant('tCancel'),
                  () => {},
                  this.translate.instant('check'),
                  () => {
                    setTimeout(() => {
                      this.router.navigate(['/DocumentVerificationPage']);
                    }, 800);
                  }
                );
              } else {
                this.errorService.searchErrorCode(data.message).subscribe(
                  (data3) => {
                    this.utils.showError(data3);
                  },
                  (err) => {
                    this.utils.showError(this.translate.instant('GetCardActivateTarKO'));
                  }
                );
              }
            }
          } else {
            this.utils.showError(this.translate.instant('GetCardActivateTarKO'));
          }
        }
      },
      (err) => {
        this.utils.showError(this.translate.instant('m_menu_serverFail'));
      }
    );
  }
  doSendPin2() {
    const creds = {
      cardNumber: this.cardNumber,
      pin: this.myForm3.controls['pin2'].value
    };
    this.userService.addClientCard(creds).subscribe(
      (data) => {
        if (data.success) {
          this.globalVars.user.codereCard.cardNumber = this.cardNumber;
          this.globalVars.user.codereCard.active = true;
          const title = '¡' + this.translate.instant('GetCardLink') + '!';
          const msg =
            this.translate.instant('CodereLinkedCard01') +
            ' ' +
            this.cardNumber +
            ' ' +
            this.translate.instant('CodereLinkedCard02');
          this.utils.alert(false, title, msg, 'OK', () => {
            //update rigth menu
            this.events.publish('card:refresh');
            setTimeout(() => {
              this.router.navigate([MSO_PATHS.BASE + '/' + MSO_PATHS.MyCardPage]);
            }, 800);
          });
          this.trackingService.trackEvent(['ActivateTar', '', '', 'Activar Tarjeta Codere', 'event']);
        } else {
          if (data.message != undefined && data.message.length > 0) {
            if (isNaN(data.message)) {
              this.utils.showError(data.message);
            } else {
              if (data.message == '175') {
                //not verified user
                this.utils.confirm(
                  false,
                  this.translate.instant('tFail'),
                  'Solo se podrá vincular la tarjeta Codere en el caso de que hayas pasado el proceso de verificación documental',
                  this.translate.instant('tCancel'),
                  () => {},
                  this.translate.instant('check'),
                  () => {
                    setTimeout(() => {
                      /* this.globalVars.gNav.setRoot(DocumentVerificationPage, {}); */
                      this.router.navigate(['/DocumentVerificationPage']);
                    }, 800);
                  }
                );
              } else {
                this.errorService.searchErrorCode(data.message).subscribe(
                  (data3) => {
                    this.utils.showError(data3);
                  },
                  (err) => {
                    this.utils.showError(data.message);
                  }
                );
              }
            }
          } else {
            this.utils.showError(this.translate.instant('GetCardActivateTarKO'));
          }
        }
      },
      (err) => {
        this.utils.showError(this.translate.instant('m_menu_serverFail'));
      }
    );
  }

  sendTarjeta() {
    if (this.myForm1.valid) {
      this.doSendTarjeta();
    } else {
      for (const x in this.myForm1.controls) {
        this.myForm1.controls[x].markAsTouched();
      }
    }
  }
  sendTarjetaMexico() {
    if (this.myForm4.valid) {
      this.doSendTarjetaMexico();
    } else {
      for (const x in this.myForm4.controls) {
        this.myForm4.controls[x].markAsTouched();
      }
    }
  }
  doSendTarjetaMexico() {
    //send the card
    const miData = {
      cardNumber: this.myForm4.controls['mexicoNumTarjeta'].value
    };
    this.userService.addMexicoCard(miData).subscribe(
      (data) => {
        if (data.success) {
          this.cardNumber = this.myForm4.controls['mexicoNumTarjeta'].value;
          this.globalVars.user.codereCard.cardNumber = this.cardNumber;
          this.globalVars.user.codereCard.active = true;
          const title: string = '¡' + this.translate.instant('GetCardLink') + '!';
          const msg: string =
            this.translate.instant('CodereLinkedCard01') +
            ' ' +
            this.cardNumber +
            ' ' +
            this.translate.instant('CodereLinkedCard02');
          this.utils.alert(false, title, msg, 'OK', () => {
            //update rigth menu
            this.events.publish('card:refresh');
            setTimeout(() => {
              this.router.navigate([MSO_PATHS.BASE + '/' + MSO_PATHS.MyCardPage]);
            }, 800);
          });
        } else {
          if (data.message != undefined && data.message.length > 0) {
            if (isNaN(data.message)) {
              this.utils.showError(data.message);
            } else {
              this.errorService.searchErrorCode(data.message).subscribe(
                (data3) => {
                  this.utils.showError(data3);
                },
                (err) => {
                  this.utils.showError(data.message);
                }
              );
            }
          } else {
            this.utils.showError(this.translate.instant('GetCardActivateTarKO'));
          }
        }
      },
      (err) => {
        this.utils.showError(this.translate.instant('m_menu_serverFail'));
      }
    );
  }
  doSendTarjeta() {
    //send the card
    const miData = { cardNumber: this.myForm1.controls['numTarjeta'].value };
    this.userService.cardStatus(miData).subscribe(
      (data) => {
        this.cardNumber = this.myForm1.controls['numTarjeta'].value;
        let carNumberAux = this.cardNumber;
        if (this.cardNumber.length === 19) {
          carNumberAux = carNumberAux.replace('31069000', '');
        }
        switch (true) {
          case carNumberAux.startsWith('34'): //Nacional
            this.cardRegion = carNumberAux.substring(2, 4);
            break;
          case carNumberAux.startsWith('5700'): // Colombia
            this.cardRegion = '5700';
            break;
          case carNumberAux.startsWith('5070'): // Panamá
            this.cardRegion = '5070';
        }
        this.urlCondiciones = this.getConditions(this.cardRegion);
        if (data.status == 'Exists' || data.status == 'HasData') {
          //goto step2
          this.steps = 'step3';
        } else if (data.status == 'Notexists') {
          //gets the user DNI
          this.getUserDNI(this.myForm1.controls['numTarjeta'].value);
        } else {
          this.utils.showError(this.translate.instant('GetCardChecknumKO'));
        }
      },
      (err) => {
        this.utils.showError(this.translate.instant('m_menu_serverFail'));
      }
    );
  }

  getUserDNI(cardNumber: string) {
    this.userService.getUserData().subscribe(
      (data) => {
        if (!data.sessionOut) {
          this.checkHasDNI(cardNumber, data.NIF);
        } else {
          //disconnect the user
          this.events.publish('user:sessionTimeout');
        }
      },
      (err) => {
        this.utils.showError(this.translate.instant('m_menu_serverFail'));
      }
    );
  }

  checkHasDNI(card: string, nif: string) {
    this.userService.hasCardNIF(card, nif).subscribe(
      (data) => {
        if (data.success) {
          this.utils.showError(this.translate.instant('checkhasdnierror'));
        } else {
          this.steps = 'step2';
        }
      },
      (err) => {
        this.utils.showError(this.translate.instant('m_menu_serverFail'));
      }
    );
  }

  cardValidator = (control: FormControl) => {
    //only 16 numbers
    let cardRegEsp = /^[0-9]{16}$/;
    if (this.globalVars.licenseType === LicenseTypes.Nacional) {
      cardRegEsp = /^[0-9]{16,19}$/;
    }
    const ccNum: string = control.value;
    const charCount: number = ccNum.length;
    if (control.value) {
      if (cardRegEsp.test(control.value)) {
        if (charCount === 19) {
          const nCodere = '31069000';
          const codePhone = '34';
          if (!ccNum.startsWith(nCodere)) {
            return { valid: 'notValid' };
          }
          if (ccNum.substring(8, 10) !== codePhone) {
            return { valid: 'notValid' };
          }
          const INE = parseInt(ccNum.substring(10, 12));
          const maxINE = 19;
          if (INE < 1 || INE > maxINE) {
            return { valid: 'notValid' };
          }

          if (this.isValidNumber(ccNum)) {
            return null;
          }
          return { valid: 'notValid' };
        } else {
          const numArr: Array<number> = [];
          let sumTotal = 0;
          for (let i = 0; i < charCount; i++) {
            let digit: number = parseInt(ccNum.charAt(i));
            if (i % 2 == 0) {
              //odd values
              const digitTemp: number = digit * 2;
              if (digitTemp > 9) {
                digit = parseInt(digitTemp.toString().charAt(0)) + parseInt(digitTemp.toString().charAt(1));
              } else {
                digit = digitTemp;
              }
            }
            numArr.push(digit);
          }
          for (let i = 0; i < numArr.length; i++) {
            sumTotal += numArr[i];
          }
          const diff: number = sumTotal % 10;

          if (diff == 0) {
            //card valid
            return null;
          } else {
            //not valid
            return { valid: 'notValid' };
          }
        }
      } else {
        //not valid
        return { valid: 'notValid' };
      }
    } else {
      return null;
    }
  };

  isValidNumber(number: string) {
    const DELTAS = [0, 1, 2, 3, 4, -4, -3, -2, -1, 0];
    let checksum = 0;
    const chars = number.split('');
    if (chars[0] === '0' && chars[1] === '0') {
      return false;
    }
    for (let i = chars.length - 1; i > -1; i--) {
      const j = parseInt(chars[i]);
      checksum += j;
      if ((i - chars.length) % 2 === 0) {
        console.info(DELTAS[j]);
        checksum += DELTAS[j];
      }
    }
    return checksum % 10 === 0;
  }

  mexicoCardValidator = (control: FormControl) => {
    //only 16 numbers
    const cardRegEsp = /^[0-9]{16,20}$/;
    const ccNum: string = control.value;
    const charCount: number = ccNum.length;
    if (control.value) {
      if (cardRegEsp.test(control.value)) {
        const numArr: Array<number> = [];
        let sumTotal = 0;
        for (let i = 0; i < charCount; i++) {
          let digit: number = parseInt(ccNum.charAt(i));
          if (i % 2 == 0) {
            //odd values
            const digitTemp: number = digit * 2;
            if (digitTemp > 9) {
              digit = parseInt(digitTemp.toString().charAt(0)) + parseInt(digitTemp.toString().charAt(1));
            } else {
              digit = digitTemp;
            }
          }
          numArr.push(digit);
        }
        for (let i = 0; i < numArr.length; i++) {
          sumTotal += numArr[i];
        }
        const diff: number = sumTotal % 10;

        if (diff == 0) {
          //card valid
          return null;
        } else {
          //not valid
          return { valid: 'notValid' };
        }
      } else {
        //not valid
        return { valid: 'notValid' };
      }
    } else {
      return null;
    }
  };
}
