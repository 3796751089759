export class C_Banners {
  Imagen: string;
  Url: string;
  Cid: string;
  Target: string;

  constructor(imagen: string, url: string, cid: string, target: string) {
    this.Imagen = imagen;
    this.Url = url;
    this.Cid = cid;
    this.Target = target;
  }
}
