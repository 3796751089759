import { CommonModule } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { IonicModule, ModalController, NavParams } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { GlobalVarsModel } from '../../../models/ModelGlobalsVar';

export interface Item {
  label: string;
  value: string;
  type: string;
  checked: boolean;
}

@Component({
  selector: 'cs-item-selector-modal',
  templateUrl: './cs-item-selector-modal.html',
  styleUrls: ['./cs-item-selector-modal.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, TranslateModule]
})
export class CsItemSelectorModal implements OnInit {
  items: Item[] = [];
  selectedItem: Item;
  title = 'Select Items';
  filteredItems: Item[] = [];
  globalVars!: GlobalVarsModel;

  params = inject(NavParams);
  modalCtrl = inject(ModalController);

  constructor() {}
  ngOnInit() {
    document.getElementById('searchInput').addEventListener('keyup', (event) => {
      this.searchbarInput(event);
    });
    this.items = this.params.get('items');
    this.title = this.params.get('title');
    this.filteredItems = [...this.items];
  }

  cancelChanges() {
    this.selectedItem = null;
    this.dismiss();
  }

  confirmChanges() {
    this.dismiss();
  }

  searchbarInput(ev) {
    this.filterList(ev.target.value);
  }

  /**
   * Update the rendered view with
   * the provided search query. If no
   * query is provided, all data
   * will be rendered.
   */
  filterList(searchQuery: string | undefined) {
    /**
     * If no search query is defined,
     * return all options.
     */
    if (searchQuery === undefined) {
      this.filteredItems = [...this.items];
    } else {
      /**
       * Otherwise, normalize the search
       * query and check to see which items
       * contain the search query as a substring.
       */
      const normalizedQuery = searchQuery.toLowerCase();
      this.filteredItems = this.items.filter((item) => item.label.toLowerCase().includes(normalizedQuery));
    }
  }

  checkboxChange(ev) {
    this.selectedItem = this.filteredItems.find((item) => item.value === ev.detail.value);
  }

  dismiss() {
    this.modalCtrl.dismiss(this.selectedItem);
  }

  public get isArgentina() {
    return this.globalVars.licenseType === 33;
  }
}
