<div
  class="footericondeposit"
  [ngClass]="{
  'footerDepositEs': globalVars.LICENSE_TYPE == 0,
  'footerDepositCo': globalVars.LICENSE_TYPE == 21,
  'footerDepositAr': globalVars.LICENSE_TYPE == 33 || globalVars.LICENSE_TYPE == 34,
  'footerDepositPa': globalVars.LICENSE_TYPE == 22,
}"
>
  <ng-container *ngFor="let img of imagesDeposits">
    <div *ngIf="canShowDeposit(img)" [ngClass]="img.divContainerCss">
      <img [src]="img.src" [alt]="img.alt" [ngClass]="isDesktop ? 'img-desktop' : ''" [style]="img.styImg" />
    </div>
  </ng-container>
</div>
