<!-- eslint-disable @angular-eslint/template/eqeqeq -->
<ng-container *ngIf="isMobile; then mobileTemplate; else pcTemplate"></ng-container>
<ng-template #pcTemplate>
  <ion-list class="subDep">
    <div class="masinfo text-center marginb23">
      {{'selfexclusionstxt01' | translate}}
      <!--Debes saber que todas las autoexclusiones son irreversibles durante el tiempo especificado.-->
      <span *ngIf="globalVars.licenseType != 21"
        >{{'selfexclusionstxt02' | translate}}
        <!--Además, los periodos de autoexclusión incluyen todos los juegos ...-->
      </span>
    </div>

    <div class="buttonsLine" [class.has-error]="!validDate">
      <ion-row class="" [class.error]="!validDate">
        <ion-col size="4">
          <ion-item>
            <ion-select
              cancelText="{{'tCancel'|translate}}"
              class="selectInput"
              [(ngModel)]="selfEx.day"
              [formControl]="$any(myForm).controls['day']"
              [label]="'rDay' | translate"
              label-placement="fixed"
            >
              <ion-select-option *ngFor="let day of dayMonth" value="{{day}}">{{day}}</ion-select-option>
            </ion-select>
          </ion-item>
        </ion-col>
        <ion-col size="4">
          <ion-item>
            <ion-select
              cancelText="{{'tCancel'|translate}}"
              class="selectInput"
              [(ngModel)]="selfEx.month"
              [formControl]="$any(myForm).controls['month']"
              [label]="'rMonth' | translate"
              label-placement="fixed"
            >
              <ion-select-option *ngFor="let month of months" value="{{month.data}}">{{month.label}}</ion-select-option>
            </ion-select>
          </ion-item>
        </ion-col>
        <ion-col size="4">
          <ion-item>
            <ion-select
              cancelText="{{'tCancel'|translate}}"
              class="selectInput"
              [(ngModel)]="selfEx.year"
              [formControl]="$any(myForm).controls['year']"
              [label]="'rYear' | translate"
              label-placement="fixed"
            >
              <ion-select-option *ngFor="let year of years" value="{{year}}">{{year}}</ion-select-option>
            </ion-select>
          </ion-item>
        </ion-col>
        <ion-col class="colpadding" size="12">
          <div
            [hidden]="!checkedDate || checkedDate && (validDate || !$any(myForm).controls['day'].touched || !$any(myForm).controls['month'].touched || !$any(myForm).controls['year'].touched)"
            class="labelError"
          >
            <span>
              {{'date_valid' | translate}}
              <!--Por favor introduce una fecha válida.--></span
            >
          </div>
        </ion-col>
      </ion-row>
    </div>

    <div class="sendForm">
      <ion-button expand="full" (click)="selfexclusion_click()">{{'tHelpSend'|translate}}</ion-button>
    </div>
  </ion-list>
  <div *ngIf="globalVars.licenseType == 21" class="newtxtco">{{'selfExclusiontxtCO' | translate}}</div>
</ng-template>
<ng-template #mobileTemplate>
  <div [class.has-error]="!validDate">
    <ion-list class="payment-sublist">
      <div class="masinfo text-center marginb10">
        {{'selfexclusionstxt01' | translate}}<!--Debes saber que todas las autoexclusiones son irreversibles durante el tiempo especificado.-->
        <span *ngIf="globalVars.licenseType != 21"
          >{{'selfexclusionstxt02' | translate}}<!--Además, los periodos de autoexclusión incluyen todos los juegos ...-->
        </span>
      </div>
      <ion-row class="" [class.error]="!validDate">
        <ion-col>
          <ion-item>
            <ion-select
              cancelText="{{'tCancel'|translate}}"
              class="selectInput"
              [(ngModel)]="selfEx.day"
              [formControl]="$any(myForm).controls['day']"
              [label]="'rDay' | translate"
              label-placement="fixed"
            >
              <ion-select-option *ngFor="let day of dayMonth" value="{{day}}">{{day}}</ion-select-option>
            </ion-select>
          </ion-item>
        </ion-col>
        <ion-col>
          <ion-item>
            <ion-select
              cancelText="{{'tCancel'|translate}}"
              class="selectInput"
              [(ngModel)]="selfEx.month"
              [formControl]="$any(myForm).controls['month']"
              [label]="'rMonth' | translate"
              label-placement="fixed"
            >
              <ion-select-option *ngFor="let month of months" value="{{month.data}}">{{month.label}}</ion-select-option>
            </ion-select>
          </ion-item>
        </ion-col>
        <ion-col>
          <ion-item>
            <ion-select
              cancelText="{{'tCancel'|translate}}"
              class="selectInput"
              [(ngModel)]="selfEx.year"
              [formControl]="$any(myForm).controls['year']"
              [label]="'rYear' | translate"
              label-placement="fixed"
            >
              <ion-select-option *ngFor="let year of years" value="{{year}}">{{year}}</ion-select-option>
            </ion-select>
          </ion-item>
        </ion-col>
      </ion-row>

      <div
        [hidden]="!checkedDate || checkedDate && (validDate || !$any(myForm).controls['day'].touched || !$any(myForm).controls['month'].touched || !$any(myForm).controls['year'].touched)"
        class="labelError"
      >
        <span>
          {{'date_valid' | translate}}
          <!--Por favor introduce una fecha válida.--></span
        >
      </div>

      <div style="height: 50px"></div>
      <div class="sendForm">
        <ion-button color="primary" expand="full" (click)="selfexclusion_click()">{{'tHelpSend'|translate}}</ion-button>
      </div>
    </ion-list>
    <div *ngIf="globalVars.licenseType == 21" class="newtxtco">{{'selfExclusiontxtCO' | translate}}</div>
  </div>
</ng-template>
