<ion-header>
  <codere-navbar titulo="Preferencias" *ngIf="this.isMobile"></codere-navbar>
  <user-section-title [sectionTitle]="'Resumen de movimientos'"></user-section-title>
  <div class="btnlist">
    <ion-item class="ion-item-summary" lines="none">
      <ion-select
        class="custom-icon-select"
        justify="start"
        [formControl]="selectedDate"
        (ionChange)="onSelectChange($event)"
        cancelText="{{'tCancel'|translate}}"
        placeholder="{{'histapu2d'|translate}}"
      >
        <div slot="label" class="ion-select-label">{{'histapfecha'|translate}}</div>
        <ion-select-option *ngFor="let item of selectList" [value]="item.value">{{item.name}}</ion-select-option>
      </ion-select>
      <ion-icon name="chevron-down-outline" class="custom-chevron-icon"></ion-icon>
    </ion-item>
  </div>
</ion-header>

<ion-content class="background-color-muted">
  <div class="scroll-content">
    <div class="divTable">
      <div class="divTableHeading">
        <div class="divTableRow">
          <div class="divTableHead" *ngFor="let item of labelsTable">{{item}}</div>
        </div>
      </div>
      <div class="divTableBody" *ngIf="rowsOfTable">
        <div class="divTableRow divTableColor" *ngFor="let item of rowsOfTable">
          <div class="divTableCell">{{item?.dateTime | date:'dd/MM/yyyy hh:mm:ss'}}</div>
          <div class="divTableCell">{{item?.credit}}</div>
          <div class="divTableCell">{{item?.debit}}</div>
          <div class="divTableCell">{{item?.balance}}</div>
          <div class="divTableCell">{{item?.transactionType}}</div>
          <div class="divTableCell">{{item?.idTransaction}}</div>
          <div class="divTableCell">{{item?.idBet}}</div>
          <div class="divTableCell">{{item?.casinoSessionId}}</div>
        </div>
      </div>
    </div>
    <codere-footer></codere-footer>
  </div>
</ion-content>
