<!-- eslint-disable -->
<div class="casino-card--preloader heartbeat-animation background-color-dark" *ngIf="!loaded"></div>
<div
  class="casino-card--wrapper"
  (click)="playGame(false)"
  [ngClass]="{
    'calendar-card': theme === 'calendar-card',
    rebranding: globalVars.FEATURES?.MSO_NewReBranding
  }"
>
  <div deferLoad (deferLoad)="isLoaded()">
    <ion-img
      class="casino-card--image"
      *ngIf="!setStreaming"
      [src]="game?.Image"
      [alt]="game?.Name"
      #cardNewImg
      (ionError)="errorImageHandler(cardNewImg)"
    />
  </div>
  <div
    deferLoad
    (deferLoad)="isLoaded2()"
    class="casino-card--stream"
    *ngIf="setStreaming"
    [ngClass]="classStreaming ? 'streaming' : 'returnH'"
  >
    <video
      #videoPlayer
      width="100%"
      height="100%"
      autoplay="autoplay"
      loop="true"
      muted="muted"
      playsinline
      [poster]="game?.Image"
    >
      Your browser doesn't support HTML5 video
    </video>
  </div>

  <ng-container *ngIf="numberRank !== 0">
    <ion-icon
      *ngIf="globalVars.FEATURES?.MSO_NewReBranding"
      src="assets/global/img/Top10Label.svg"
      class="casino-card--icon"
      [ngClass]="{ 'ranking1-icon': numberRank === 1 }"
    ></ion-icon>
    <span
      class="casino-card--ranking"
      [ngClass]="{ 'ranking1-text': numberRank === 1, 'ranking10-text': numberRank > 9 }"
      >{{ numberRank }}</span
    >
  </ng-container>

  <ng-container *ngIf="badges?.length && !isRounded">
    <div class="casino-card--icons">
      <img class="icon" *ngFor="let icon of badges" [src]="baseImage + 'icono_' + icon + '.svg'" />
    </div>
  </ng-container>
  <div class="casino-live--content" *ngIf="isJackpotCounter && !isJpLobby">
    <div class="casino-card--jackpot">
      <div class="casino-card--counter font-weight-bold">
        {{ jackpots$ | async | parseMoney: [4] }}
      </div>
    </div>
  </div>
  <div class="casino-pragmatic--players" *ngIf="pragmaticInfo">
    <div class="casino-card--pragmatic-players">
      <div class="casino-card--counter font-weight-bold">
        {{ pragmaticInfo.totalSeatedPlayers }}
        <ion-icon name="person-circle-outline" class="casino-card--pragmatic-icon"></ion-icon>
      </div>
    </div>
    <div class="casino-card--pragmatic-results">
      <div
        class="casino-card--pragmatic-result"
        *ngFor="let result of pragmaticInfo.lastGameResults; let first = first"
        [class.no-first]="!first"
        [class.orange]="result >= 5 && result < 10"
        [class.green]="result >= 10"
      >
        {{ result }}
      </div>
    </div>
  </div>
</div>

<div
  class="casino-card--hover"
  [ngClass]="{ 'casino-card--hover-75': game?.label && active }"
  *ngIf="isDesktop && active"
  (click)="playGame(false)"
>
  <h6 class="casino-card--hover-title color-light">{{ game?.Name | slotName | uppercase }}</h6>
  <div class="casino-card--hover-play-container">
    <img class="casino-card--hover-play-container-image" [src]="'assets/global/img/slots/icono_Play.svg'" />
  </div>
</div>

<ng-container *ngIf="!globalVars.FEATURES.CAS_NewCalendar && isCalendar">
  <div *ngIf="game?.label && active" class="casino-card--gameActive" (click)="playGame(false)">
    <div class="casino-card--gameActive--label">
      <div>
        <span class="casino-card--gameActive--label--txt">{{ game.label }}</span>
      </div>
    </div>
  </div>

  <div *ngIf="game?.label && !active" class="casino-card--gameInactive">
    <div class="casino-card--gameInactive--label" [ngClass]="{ rebranding: globalVars.FEATURES?.MSO_NewReBranding }">
      <span *ngIf="game.textLabel === '' || !game.textLabel" class="casino-card--gameInactive--label--date"
        >Fecha de lanzamiento</span
      >
      <span *ngIf="game.textLabel !== '' && game.textLabel !== null" class="casino-card--gameInactive--label--date">{{
        game.textLabel
      }}</span>
      <br />
      <span class="casino-card--gameInactive--label--des">{{ game.label }}</span>
    </div>
  </div>
</ng-container>
