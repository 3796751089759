// models/C_Result.ts
export class C_Result {
  Odd: number;
  SortOrder: number;
  IsLive: boolean;
  upOdd: boolean;
  downOdd: boolean;
  Name: string;
  NodeId: string;
  ParentNodeId: string;
  EventNodeTypeId: number;
  Priority: number;
  SportHandle: string;
  ChildrenCount: number;
  Spov: string;
  Locked: boolean;
  IsNonRunner: boolean;
  SportId: string;
  LocationId: string;
  LeagueId: string;
  EventId: string;
  EventsHasHandicap: boolean;
  GameId: string;
  GameTypeId: number;
  GameSpecialOddsValue: string;
  GameBetTypeAvailability: number;
  GameNumberOfStarters: number;

  constructor(
    odd: any,
    sortOrder: number,
    isLive: boolean,
    upodd: boolean,
    downodd: boolean,
    name: string,
    nodeId: string,
    parentNodeId: string,
    eventNodeTypeId: number,
    priority: number,
    sporthandle: string,
    childrenCount: number,
    Locked?: boolean
  ) {
    this.Odd = odd;
    this.SortOrder = sortOrder;
    this.IsLive = isLive;
    this.upOdd = upodd;
    this.downOdd = downodd;
    this.Name = name;
    this.NodeId = nodeId;
    this.ParentNodeId = parentNodeId;
    this.EventNodeTypeId = eventNodeTypeId;
    this.Priority = priority;
    this.SportHandle = sporthandle;
    this.ChildrenCount = childrenCount;
    this.Locked = Locked;
  }
}
