// models/C_Slot.ts
export class C_Slot {
  Name: string;
  GameId: string;
  ProviderId: string;
  DemoUrl: string;
  DemoMobileUrl: string;
  PlayUrl: string;
  GameType: string;
  ProviderGameID: string;
  Jackpot: boolean;
  LicenseType: Array<number>;
  LicensesOrder: Array<CLicenseOrder>;
  Order: number;
  JackpotAmount: number;
  starred: boolean = false;
  HasDemo: boolean = false;
  JackpotPotId: string = null;
  GameCategoryName: string;
  JackpotId: string = null;
  JackpotProvider: string = null;

  constructor() {
    this.JackpotAmount = 0.0;
  }
}

export class CLicenseOrder {
  LicenseType: string;
  LicenseOrder: number;
}

//export = C_Slot;
