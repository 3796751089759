<ng-container *ngIf="isMobile; then mobileTemplate; else deskTemplate"></ng-container>

<ng-template #deskTemplate>
  <ion-content class="iframe-container">
    <button class="closeModal registerModal" (click)="closeModal()">
      <ion-icon name="close-outline"></ion-icon>
    </button>
    <iframe #iframe class="iframeFullScreen" [src]="scrReg"></iframe>
  </ion-content>
</ng-template>

<ng-template #mobileTemplate>
  <ion-content class="iframe-container">
    <iframe #iframe [src]="scrReg" class="iframeFullScreen" frameborder="0"></iframe>
  </ion-content>
</ng-template>
