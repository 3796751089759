<ion-toolbar
  [color]="globalVars.FEATURES?.MSO_NewReBranding ? 'codereg' : 'codere'"
  [ngClass]="{'backgroundheader': globalVars.FEATURES?.MSO_NewReBranding,
  'colornewlogo': globalVars.FEATURES?.MSO_NewReBranding && !isCasinoUrl, 'colornewlogocas' : globalVars.FEATURES?.MSO_NewReBranding && isCasinoUrl}"
>
  <ion-row>
    <ion-buttons class="navbar-left">
      <button
        class="burgger-top bar-buttons"
        (click)="toggleSidebarMenu('left-menu')"
        *ngIf="currentPage !== 'ActivateUserPage'"
      >
        <i
          class="codere-icon icon-burger toggle-button"
          [class.license-co]="globalVars?.LICENSE_TYPE == LicenseTypes.Colombia"
          [class.license-ar]="utils.isArgentina()"
          [class.license-pa]="globalVars.licenseType == LicenseTypes.Panama"
        ></i>
      </button>
      <button
        class="logo-button"
        light
        (click)="backToHome()"
        [class.license-co]="globalVars.licenseType == LicenseTypes.Colombia"
      >
        <img
          alt=""
          [class]="globalVars.FEATURES?.MSO_NewReBranding ? isCasinoUrl ? headerImg.imgClass.casino : headerImg.imgClass.sport : headerImg.imgClass.common"
          [src]="globalVars.FEATURES?.MSO_NewReBranding ? isCasinoUrl ? headerImg.imgSrcCas : headerImg.imgSrcSport : headerImg.imgSrc"
        />
      </button>
      <div *ngIf="globalVars?.FEATURES?.MSO_LocalDateTimer && !globalVars?.user?.logged" class="localTimeContainer">
        <span *ngIf="globalVars?.FEATURES?.MSO_LocalDateTimer">{{localTime}}</span>
      </div>
    </ion-buttons>
    <ion-buttons
      class="loginOps navbar-right"
      [class.license-co]="globalVars.licenseType == LicenseTypes.Colombia"
      [class.license-ar]="utils.isArgentina()"
      [class.buttons-right-padding]="!globalVars.user.logged"
      *ngIf="currentPage !== 'ActivateUserPage'"
    >
      <ion-button *ngIf="isRegistro && globalVars.FEATURES?.callmebackEnabled" class="btCallUs" (click)="openCallMe()">
        Llámame
        <i class="icon-callUs"></i>
      </ion-button>
      <ion-button
        *ngIf="!isRegistro && globalVars.user.logged"
        (click)="refreshBalance()"
        class="reload disable-hover ion-button-navbar btnReload"
        [class.license-mx]="globalVars?.LICENSE_TYPE === 4"
        [class.font14]="globalVars?.LICENSE_TYPE == LicenseTypes.Colombia"
        [ngClass]="{'btnReload': globalVars.licenseType != 0}"
      >
        <i
          class="codere-icon icon-reload"
          [class.license-co]="globalVars?.LICENSE_TYPE == LicenseTypes.Colombia"
          [class.license-ar]="utils.isArgentina()"
        ></i>
      </ion-button>
      <ion-button
        class="reload disable-hover ion-button-navbar"
        (click)="showBalanceDetails($event)"
        *ngIf="globalVars?.user?.logged"
        [class.license-co]="globalVars?.LICENSE_TYPE == LicenseTypes.Colombia || globalVars?.LICENSE_TYPE === 22"
        [class.license-ar]="utils.isArgentina()"
      >
        <span
          >{{globalVars?.user?.balance | parseMoney:[4]}}
          <ion-icon class="icon-arrow" *ngIf="balancePopoverAvailable()" [name]="popoverName"></ion-icon
        ></span>
      </ion-button>
      <div
        *ngIf="(globalVars?.FEATURES?.MSO_SesionTimer || globalVars?.FEATURES?.MSO_LocalDateTimer) && globalVars?.user?.logged"
        class="timeheader"
        [class.license-pa]="globalVars.licenseType == LicenseTypes.Panama"
        [class.pading10]="!globalVars?.FEATURES?.MSO_LocalDateTimer"
      >
        <span *ngIf="globalVars?.FEATURES?.MSO_SesionTimer">{{timeColombia}}</span>
        <span *ngIf="globalVars?.FEATURES?.MSO_LocalDateTimer">{{localTime}}</span>
      </div>

      <ion-button
        *ngIf="isRegistro || !globalVars.user.logged"
        tappable="true"
        class="btAccess"
        (click)="openModalLogin()"
        [class.license-co]="globalVars?.LICENSE_TYPE == LicenseTypes.Colombia"
        [class.license-ar]="utils.isArgentina()"
      >
        {{'access' | translate}}
      </ion-button>
      <ion-button
        *ngIf="!isRegistro && !globalVars.user.logged"
        tappable="true"
        class="btSignUp"
        (click)="openRegistro()"
        [class.license-co]="globalVars?.LICENSE_TYPE == LicenseTypes.Colombia"
        [class.license-ar]="utils.isArgentina()"
      >
        {{'signUp' | translate}}
      </ion-button>
      <ion-button
        (click)="toggleSidebarMenu('right-menu')"
        class="btAvatar ion-button-navbar"
        *ngIf="globalVars?.user?.logged"
      >
        <i
          class="icon-icono_avatar codere-icon"
          [class.license-co]="globalVars?.LICENSE_TYPE == LicenseTypes.Colombia"
          [class.license-ar]="utils.isArgentina()"
        >
          <ion-badge *ngIf="messageCount > 0 && globalVars.FEATURES?.messageServiceEnabled" class="notifications-badge">
            <ion-icon class="avatar-bell" name="notifications-outline"></ion-icon>
          </ion-badge>
        </i>
      </ion-button>
    </ion-buttons>
  </ion-row>
</ion-toolbar>
