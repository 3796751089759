<div class="cs-calculator--content p-bottom-1 w-100">
  <span class="cs-calculator--title my-1 w-100 color-score text-center"
    >{{ options?.counterTitle }}
    <span *ngIf="options?.isDesktop">({{ options?.currency }})</span>
  </span>
  <div class="cs-calculator--counter">
    <button
      class="cs-calculator--add background-color-secondary"
      [disabled]="disabledSubstract || isActiveKeyboard"
      [ngClass]="{'cs-calculator--disabled-form': disabledSubstract || isActiveKeyboard}"
      #amountSubtractButton
    >
      <ion-icon
        name="remove"
        role="img"
        class="icon icon-ios ion-ios-remove color-light font-weight-bold"
        aria-label="remove"
      >
      </ion-icon>
    </button>

    <div
      *ngIf="!options?.isDesktop"
      class="cs-calculator--amount mx-1 background-color-white"
      [ngClass]="{'cs-calculator--disabled-form': blockCalculator}"
      tappable
      (click)="clickInputKeyboard()"
    >
      <h2 class="w-100 text-center m-unset color-dark">{{ amount | parseMoney:[1] }}</h2>
    </div>
    <div class="mx-1" *ngIf="options?.isDesktop">
      <form
        class="cs-calculator--amount background-color-white w-100"
        [formGroup]="amountForm"
        [ngClass]="{'cs-calculator--disabled-form': blockCalculator || isActiveKeyboard}"
      >
        <input
          class="cs-calculator--input w-100 text-center m-unset color-dark"
          type="number"
          formControlName="manualAmount"
          (input)="handleAmountChange($any($event))"
          #input
        />
      </form>
    </div>

    <button
      class="cs-calculator--add background-color-secondary"
      [disabled]="disabledAdd || isActiveKeyboard"
      [ngClass]="{'cs-calculator--disabled-form': disabledAdd || isActiveKeyboard}"
      #amountAddButton
    >
      <ion-icon name="add" role="img" class="icon icon-ios ion-ios-add color-light font-weight-bold" aria-label="add">
      </ion-icon>
    </button>
  </div>
</div>
<ng-container *ngIf="amountButtons">
  <div class="cs-calculator--content p-bottom-1 w-100">
    <span class="cs-calculator--title my-1 w-100 color-score text-center">{{ options?.buttonsTitle }}</span>
    <div class="cs-calculator--buttons w-100" [ngClass]="{'mobile': !options?.isDesktop }">
      <button
        [disabled]="isActiveKeyboard || blockCalculator"
        class="cs-calculator--button color-dark background-color-light"
        [ngStyle]="setFont"
        *ngFor="let amountOption of paymentSelected?.amountButtons; let i = index"
        tappable
        (click)="amountSelected.emit(amountOption)"
      >
        {{ amountOption | parseMoney:[1] }}
      </button>
    </div>
  </div>
</ng-container>
