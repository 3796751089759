<ion-content class="codere-sidebar-menu">
  <ion-list class="menuLatDestacados">
    <ion-list-header>
      {{ 'SHigh' | translate | uppercase }}
      <!--# DESTACADOS-->
    </ion-list-header>

    <!-- *ngIf="globalVars?.FEATURES?.SPO_HideHeaderSearchFunctionality" -->
    <ion-item class="destacados--item no-hover" lines="none">
      <div class="destacados--item-content full-width-input">
        <i class="codere-icon icon-search"></i>
        <ion-input
          #searchInput
          class="destacados--item-content-input codere-custom-input-style light"
          color="light"
          placeholder="{{ 'v2_buscar' | translate }}"
          [clearInput]="true"
          clearInputIcon="close-outline"
          (ionInput)="searchIonInput($event)"
        >
        </ion-input>
        <!-- (ionBlur)="searchOutOfFocus($event)" -->
      </div>
    </ion-item>
    <ion-accordion-group class="search-accordion-group" *ngIf="searchTermItems.length > 0" value="0">
      <ion-accordion class="search-accordion" value="0" toggleIcon="chevron-up">
        <ion-item slot="header" class="search-accordion--item" lines="none">
          <ion-label class="search-accordion--item-label">Resultados de búsqueda</ion-label>
          <ion-icon class="ion-accordion-toggle-icon" name="chevron-up" slot="end"> </ion-icon>
        </ion-item>
        <div slot="content">
          <ion-item
            class="search-accordion--item-content"
            *ngFor="let item of searchTermItems"
            (click)="btItemSearch_Click($event,item)"
          >
            <i class="codere-icon icon-color-light icon-{{ item.SportHandle }}"></i>
            <div class="search-accordion--item-content-name">{{ item.Name }}</div>
          </ion-item>
        </div>
      </ion-accordion></ion-accordion-group
    >

    <ion-item
      *ngIf="globalVars?.FEATURES?.MVPCodere && !globalVars?.isNative"
      class="destacados--item"
      [class.active]="router.url.includes('MvpCoderePage')"
      (click)="openMvpCodere()"
      tappable
      lines="none"
    >
      <div class="destacados--item-content">
        <img class="img-thumb" src="assets/global/img/mvpcodere.png" />
        <p>MVP de Codere</p>
      </div>
    </ion-item>

    <ion-item
      class="destacados--item"
      [class.active]="router.url.includes('TodayPage')"
      (click)="openToday()"
      tappable
      lines="none"
    >
      <div class="destacados--item-content">
        <i class="codere-icon icon-calendar"></i>
        <p>{{ 'textToday' | translate }}</p>
      </div>
    </ion-item>

    <ion-item
      class="destacados--item"
      (click)="globalVars.rootScope.openLigaAguila()"
      *ngIf="globalVars?.FEATURES?.LigaAguilaEnabled"
      tappable
      lines="none"
    >
      <div class="destacados--item-content">
        <img style="height: 23px; width: 23px; margin: 6px 12px 0 0px" src="assets/global/img/liga-aguila.svg" />
        <p>Liga Águila</p>
      </div>
    </ion-item>

    <ion-item
      *ngIf="!globalVars?.FEATURES?.SPO_BetbuilderEnabled"
      [class.active]="router.url.includes('Crea-Tu-Apuesta')"
      tappable
      class="destacados--item"
      lines="none"
      (click)="globalVars.rootScope.openBetbuilder()"
    >
      <div class="destacados--item-content">
        <img class="img-thumb special-icon" src="assets/global/img/betbuilder/betbuilder-icon.svg" />
        <p>Crea tu apuesta</p>
      </div>
    </ion-item>

    <ion-item
      *ngIf="globalVars.FEATURES.SPO_CodereFantasyEnabled"
      [class.active]="router.url.includes('CodereFantasy')"
      tappable
      class="destacados--item"
      lines="none"
      (click)="globalVars.rootScope.openCodereFantasy()"
    >
      <div class="destacados--item-content">
        <img class="img-thumb special-icon" src="assets/global/img/icon-FantasyLeague.svg" />
        <p>Codere Fantasy</p>
      </div>
    </ion-item>

    <ion-item
      *ngFor="let specialS of highlightsConfig; let i = index"
      class="destacados--item"
      [class.active]="router.url.includes('SpecialPaisLigaPage')"
      (click)="globalVars.rootScope.openSpecialSport(specialS, '')"
      tappable
      lines="none"
    >
      <div class="destacados--item-content">
        <img class="img-thumb special-icon" src="{{ specialS.icon }}" />
        <p>{{ specialS.sport }}</p>
      </div>
    </ion-item>

    <ion-item
      *ngFor="let specialG of specialGames; let i = index"
      class="destacados--item"
      (click)="globalVars.rootScope.openSpecialGame(specialG)"
      [class.active]="router.url.includes('SpecialPaisLigaPage')"
      tappable
      lines="none"
    >
      <div class="destacados--item-content">
        <img class="img-thumb special-icon" src="{{ specialG.icon }}" />
        <p>{{ specialG.gameName }}</p>
      </div>
    </ion-item>

    <ion-item
      class="destacados--item"
      [class.active]="router.url.includes('RealMadridPage')"
      (click)="globalVars?.rootScope?.openSpecialTeam('Real Madrid')"
      *ngIf="globalVars?.FEATURES?.RealMadridEnabled"
      tappable
      lines="none"
    >
      <div class="destacados--item-content special-team-content">
        <img class="img-thumb special-team" src="assets/global/img/real_madrid.svg" />
        <p>Real Madrid</p>
      </div>
    </ion-item>

    <ion-item
      class="destacados--item"
      [class.active]="router.url.includes('RiverPlatePage')"
      (click)="globalVars?.rootScope?.openSpecialTeam('River Plate')"
      *ngIf="globalVars?.FEATURES?.RiverPlateEnabled"
      tappable
      lines="none"
    >
      <div class="destacados--item-content special-team-content">
        <img class="img-thumb special-team" src="assets/global/img/river_plate.png" />
        <p>River Plate</p>
      </div>
    </ion-item>

    <ion-item
      *ngIf="globalVars?.FEATURES?.CodereMillion"
      class="destacados--item"
      [class.active]="router.url.includes('MisterunderdogPage')"
      (click)="globalVars.rootScope.openCodereMillion()"
      tappable
      lines="none"
    >
      <div class="destacados--item-content">
        <img class="menu-left-thumbnail" src="assets/global/img/icon-codere-million.svg" />
        <p>El Millón de Codere</p>
      </div>
    </ion-item>

    <ion-item
      class="destacados--item"
      *ngFor="let hs of highLightSports"
      (click)="navigateToNoDropdownSports(hs)"
      tappable
      lines="none"
    >
      <div class="destacados--item-content">
        <i class="codere-icon icon-{{ hs.SportsHandle }}"></i>
        <p>{{ hs.Name }}</p>
      </div>
    </ion-item>
  </ion-list>

  <!--EVENTOS A-Z-->
  <ion-list class="menuLatSections">
    <ion-list-header> {{ 'azSport' | translate }} </ion-list-header>

    <ion-accordion-group class="codere-sb-level-one--accordion-group" #SportsSidebar>
      <ion-accordion
        *ngFor="let sport of sportsItems; let i = index"
        id="{{ sport?.SportHandle }}"
        [value]="sport?.SportHandle"
        [toggleIcon]="sport?.isCollapsible ? 'chevron-down' : ''"
        class="codere-sb-level-one--accordion"
      >
        <ion-item
          slot="header"
          class="event-item"
          [ngClass]="{ active: sport?.SportHandle === sidebarFacade?.selectedSport?.SportHandle }"
          (click)="toggleSport(sport)"
          class="codere-sb-level-one--accordion-item"
          lines="none"
        >
          <ion-label
            [ngClass]="{ active: sport?.SportHandle === sidebarFacade?.selectedSport?.SportHandle }"
            class="event--item-label"
          >
            <i class="codere-icon icon-{{ sport.SportHandle }}"></i>
            <p>{{ sport.Name }}</p>
          </ion-label>
          <ion-icon
            class="ion-accordion-toggle-icon"
            name="chevron-down"
            slot="end"
            *ngIf="sport.isCollapsible"
          ></ion-icon>
        </ion-item>
        <div class="event--item-content" slot="content" *ngIf="showSportHandleContent(sport?.SportHandle)">
          <ion-list class="event--item-content-list" [attr.data-index]="i">
            <!-- SPORT HIGHLIGHT LEAGUES -->
            <ion-item *ngIf="sport?.highlight?.length > 0 && league" lines="none">
              <ion-list class="event--item-content-submenu" [attr.data-index]="i">
                <ion-item
                  class="level-two"
                  *ngFor="let highlight of sport?.highlight"
                  (click)="itemLeagueTapped($event, highlight, sport, true)"
                  lines="none"
                  [ngClass]="{ active: highlight.NodeId === league.NodeId }"
                >
                  <ion-label class="level-container" [ngClass]="{ active: highlight.NodeId === league.NodeId }">
                    <ion-avatar item-left class="sidebar-flag">
                      <img src="assets/global/img/banderas/ICO_{{ highlight.CountryCode }}.png" />
                    </ion-avatar>

                    <p>{{ highlight.Name }}</p>
                    <i item-right class="cs-arrow codere-icon icon-arrow-right"></i>
                  </ion-label>
                </ion-item>
              </ion-list>
            </ion-item>
            <!-- SHOW MORE -->
            <ion-item
              class="more-leagues"
              (click)="toggleSection(sport)"
              *ngIf="sport?.highlight?.length > 0"
              lines="none"
            >
              <p [hidden]="!sport?.countries?.collapsed">+ Ver más</p>
              <p [hidden]="sport?.countries?.collapsed">- Ver menos</p>
            </ion-item>
            <!-- COUNTRIES -->
            <ion-item
              class="first-list-item"
              lines="none"
              [hidden]="sport?.highlight?.length > 0 && sport?.countries?.collapsed"
            >
              <ion-accordion-group [value]="getLeagueValue(sport)" *ngIf="sport?.countries?.data.length > 0 && league">
                <ion-accordion
                  *ngFor="let country of sport?.countries?.data; let countryIndex = index;"
                  id="{{ sport?.SportHandle }}"
                  [value]="country"
                  class="second-level-content"
                >
                  <ion-item slot="header" class="first-list-item-level-two" lines="none">
                    <ion-label class="first-list-item-level-two-label">
                      <ion-avatar item-left class="sidebar-flag">
                        <img src="assets/global/img/banderas/ICO_{{country.CountryCode}}.png" />
                      </ion-avatar>
                      <p>{{ country.Name }}</p>
                    </ion-label>
                  </ion-item>
                  <div slot="content">
                    <ion-list class="first-list-item-list">
                      <ion-item
                        *ngFor="let itemLeague of country.Leagues"
                        class="first-list-item-level-three"
                        [ngClass]="{ active: itemLeague.NodeId === league.NodeId }"
                        tappable
                        (click)="itemLeagueTapped($event, itemLeague, sport)"
                        lines="none"
                      >
                        <ion-label
                          class="first-list-item-level-three-label"
                          [ngClass]="{ active: itemLeague.NodeId === league.NodeId }"
                        >
                          <p>{{ itemLeague.Name }}</p>
                          <i item-right class="cs-arrow codere-icon icon-arrow-right"></i>
                        </ion-label>
                      </ion-item>
                    </ion-list>
                  </div>
                </ion-accordion>
              </ion-accordion-group>
            </ion-item>
          </ion-list>
        </div>
      </ion-accordion>
    </ion-accordion-group>
  </ion-list>
</ion-content>
