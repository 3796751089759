/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable, inject } from '@angular/core';
import { AlertController } from '@ionic/angular';

import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';

import { GlobalVarsModel } from '@models/ModelGlobalsVar';
import { C_TicketApuestasNew, TicketMethods } from '@models/C_TicketApuestas';
import { C_FreeBetsActive } from '@models/C_FreeBetTicket';
import { LicenseTypes } from '@models/MasterData';
import { BaseService } from '@providers/BaseService';
import { C_OveraskDataBetPlacement } from '../models/sports/C_Overask-Data';

@Injectable({ providedIn: 'root' })
export class TicketService extends BaseService {
  override globalVars: GlobalVarsModel;
  ticket$: Subject<C_TicketApuestasNew> = new Subject();
  betType$: BehaviorSubject<number> = new BehaviorSubject(0);
  ticket: C_TicketApuestasNew;
  amount = 1;

  //#region Inject Services
  alertController = inject(AlertController);
  //#endregion

  constructor() {
    super();
    this.ticket = this.ticket ? TicketMethods.parse(this.ticket) : TicketMethods.newC_TicketApuestas();
  }

  getOrder(): Observable<C_TicketApuestasNew> {
    const url = this.globalVars.URLBASE + 'NoSessionTimeout/getOrder?_=' + this.utils.getValueParamNoCache();
    return this.myGet(url, true);
  }

  update(): Observable<C_TicketApuestasNew> {
    const url = this.globalVars.URLBASE + 'orderform/update?_=' + this.utils.getValueParamNoCache();
    return this.myGet(url, true).pipe(map((data) => data));
  }

  getConfigAlertOddsChange() {
    const auxCurrentOddAcceptance = this.globalVars.cuotaAccept;
    let dataResponse: any;
    const alertCoutaAccept = this.alertController.create({
      cssClass: 'sb-alert--quota-change',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          handler: (value) => {
            dataResponse = { accepted: false, value: value };
            this.alertController.dismiss(dataResponse);
            return false;
          }
        },
        {
          text: 'Actualizar',
          role: 'accept',
          handler: (value) => {
            dataResponse = { accepted: true, value: value };
            this.alertController.dismiss(dataResponse);
            return false;
          }
        }
      ],
      inputs: [
        {
          type: 'radio',
          label: 'Aceptar cualquier cambio de cuota',
          value: '0',
          checked: auxCurrentOddAcceptance === '0'
        },
        {
          type: 'radio',
          label: 'Aceptar cuotas más altas',
          value: '1',
          checked: auxCurrentOddAcceptance === '1'
        },
        {
          type: 'radio',
          label: 'No aceptar cambio de cuotas',
          value: '2',
          checked: auxCurrentOddAcceptance === '2'
        }
      ]
    });
    return alertCoutaAccept;
  }

  closeBet(hideLoader?: boolean): Observable<C_TicketApuestasNew> {
    const { AutoAcceptOddUpChanges, AutoAcceptOddDownChanges } = this.globalVars.user.customization; // var aux: string = this.utils.getValueParamNoCache();;
    const url =
      this.globalVars.URLBASE +
      'orderform/closebet' +
      '?_=' +
      this.utils.getValueParamNoCache() +
      `&autoAcceptOddDownChanges=${AutoAcceptOddDownChanges}&autoAcceptOddUpChanges=${AutoAcceptOddUpChanges}`;

    return this.myGet(url, hideLoader);
  }

  clearOrder2(): Observable<any> {
    const url = this.globalVars.URLBASE + 'orderform/Clearorder?_=' + this.utils.getValueParamNoCache();
    return this.myGet(url, false);
  }

  clearOrder(): Observable<any> {
    const url = this.globalVars.URLBASE + 'orderform/Clearorder?_=' + this.utils.getValueParamNoCache();
    return this.myGet(url, false);
  }

  changeBetType(betType: number): Observable<C_TicketApuestasNew> {
    const url =
      this.globalVars.URLBASE + 'orderform/ChangeType?type=' + betType + '&_=' + this.utils.getValueParamNoCache();
    return this.myGet(url);
  }

  ChangeBankBet(indexBet: number): Observable<C_TicketApuestasNew> {
    const url =
      this.globalVars.URLBASE +
      'orderform/ChangeBank?index=' +
      indexBet.toString() +
      '&_=' +
      this.utils.getValueParamNoCache();
    return this.myGet(url);
  }

  ChangeMultipleCombinations(numCombination: number): Observable<C_TicketApuestasNew> {
    const url =
      this.globalVars.URLBASE +
      'orderform/Combinations?c=' +
      numCombination.toString() +
      '&_=' +
      this.utils.getValueParamNoCache();
    return this.myGet(url);
  }

  GetUserActiveVouchers(checkDepositsFB = true): Observable<any> {
    const url =
      this.globalVars.UrlFreebetBonus +
      'GetUserActiveVouchers?checkDepositsFB=' +
      checkDepositsFB.toString() +
      '&_=' +
      this.utils.getValueParamNoCache();
    return this.myGet(url);
  }

  GetVoucherInformation(voucherCode: string): Observable<C_FreeBetsActive> {
    const url = `${this.globalVars.URLBASE}FreebetBonusEs/GetVoucherInfo?voucherCode=${voucherCode}`;
    return this.myGet(url);
  }

  KeepSelections(): Observable<C_TicketApuestasNew> {
    const url = this.globalVars.URLBASE + 'orderform/RecoverLastOrder';
    return this.myGet(url);
  }

  showErrorInTicketButton(errores: Array<string>): string {
    let errorTicket;
    if (errores.length > 0) {
      errorTicket = errores[0];
    }

    // Temporary workaround
    if (
      errorTicket === 'Según regulación, tras realizar un depósito no se permiten apuestas a eventos ya comenzados.'
    ) {
      errorTicket = 'Debido a tareas de mantenimiento tu apuesta no puede ser realizada en este momento';
    }
    if (errorTicket === 'Error: The bet amount exceeds allowable limit for this event.') {
      errorTicket = 'Due to maintenance tasks your bet cannot be made at this time';
    }
    return errorTicket;
  }

  get defaultAmounts(): number[] {
    switch (this.globalVars.licenseType) {
      case LicenseTypes.Mexico:
        return [100, 200];
      case LicenseTypes.Colombia:
        return [10000, 20000];
      case LicenseTypes.ArgentinaCaba:
      case LicenseTypes.ArgentinaPBA:
        return [1000, 5000];
      case LicenseTypes.Panama:
        return [10, 20];
      case LicenseTypes.Madrid:
      case LicenseTypes.Nacional:
      default:
        return [5, 10];
    }
  }

  setAmountSelection({ index, Amount }) {
    const url = this.globalVars.URLBASE + 'NoSessionOrderForm/SetAmount?' + `index=${index}&Amount=${Amount}`;
    return this.myGet(url);
  }

  setGreyhoundHorseAmountSelection({ index, Amount }) {
    const url =
      this.globalVars.URLBASE + 'NoSessionOrderForm/SetGreyhoundHorseAmount?' + `index=${index}&Amount=${Amount}`;

    return this.myGet(url);
  }

  openTicket() {
    console.info('openTicket');
  }

  overaskCheckState(OveraskRejectId: number): Observable<C_OveraskDataBetPlacement> {
    const url = `${this.globalVars.DOMAIN}/BetPlacement/api/Overask?rejectId=${OveraskRejectId}`;
    return this.myGet(url, true);
  }

  getLocalStoregeTicket() {
    let xTicketState = localStorage.getItem('X-Ticket-State');
    if (xTicketState) xTicketState = JSON.parse(xTicketState);
    return xTicketState;
  }
  saveLocalStoregeTicket(ticketState: any) {
    const xTicketState = JSON.stringify(ticketState);
    localStorage.setItem('X-Ticket-State', xTicketState);
  }

  removeLocalStoregeTicket() {
    localStorage.removeItem('X-Ticket-State');
  }

  public getEventByNode(nodeEvent: number) {
    const url = 'https://coderesbgonlinesbsbanners.azurewebsites.net/api/feeds/markets/';
    const headers = new HttpHeaders();
    headers.append('CodereAffiliateApiKey', 'CodereAuth');
    headers.append('CodereAffiliateApiSecret', 'f9e7c6cde1cccd32abf7722225761a3c');
    return this.httpClient.get(url + nodeEvent, { headers: headers }).pipe(map(this.extractData));
  }

  public getEventByNodeV2(nodeEvent: number) {
    const url: string = this.globalVars.NAVIGATIONSERVICEBASEURL + 'Home/GetResultNode?nodeId=' + nodeEvent;
    const headers = new HttpHeaders();
    headers.append('CodereAffiliateApiKey', 'CodereAuth');
    headers.append('CodereAffiliateApiSecret', 'f9e7c6cde1cccd32abf7722225761a3c');
    return this.httpClient.get(url, { headers: headers });
  }

  public getEventMarketsByNodeAndGameType(nodeEvent: number, gameType?: number) {
    let url = `https://coderesbgonlinesbsbanners.azurewebsites.net/api/feeds/events/${nodeEvent}`;
    if (gameType) {
      url += `/${gameType}`;
    }
    const headers = new HttpHeaders();
    headers.append('CodereAffiliateApiKey', 'CodereAuth');
    headers.append('CodereAffiliateApiSecret', 'f9e7c6cde1cccd32abf7722225761a3c');
    return this.httpClient.get(url, { headers: headers }).pipe(map(this.extractData));
  }
}
