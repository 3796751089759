<div class="sb-grid-header background-color-light" #header>
  <ng-container *ngIf="market?.StartDate">
    <p class="sb-grid-header--date color-muted">{{ market?.StartDate | extractDate }}</p>
  </ng-container>

  <ng-container *ngIf="market?.titleTeams">
    <p class="sb-grid-header--winner color-dark">{{ market?.titleTeams }}</p>
  </ng-container>

  <ng-container *ngIf="!headerTitles">
    <div class="sb-grid-header--item">
      <p class="sb-grid-header--title color-dark">{{ titleLeft }}</p>
    </div>
    <div class="sb-grid-header--item" *ngIf="hasCenterTitle">
      <p class="sb-grid-header--title color-dark">{{ titleCenter }}</p>
    </div>
    <div class="sb-grid-header--item">
      <p class="sb-grid-header--title color-dark">{{ titleRight }}</p>
    </div>
  </ng-container>

  <ng-container *ngIf="headerTitles">
    <div class="sb-grid-header--item" *ngFor="let title of headerTitles">
      <p class="sb-grid-header--title color-dark">{{ title }}</p>
    </div>
  </ng-container>
</div>
