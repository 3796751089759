import { C_CategoryInfo } from '@models/C_CategoryInfo';
import { C_GameGroupByName } from '@models/C_GameGroupByName';
import { C_Horses } from '@models/C_Horses';
import { C_TodayLeagues } from '@models/sports/C_TodayLeagues';
import { SportNavItem } from 'libs/sports/src/lib/models';

export interface homeDataModel {
  marquee: any[];
  homeLiveEvents: {
    LiveEventsCount: number;
    LiveSport: any[];
  };
  highlightsEvents: homeHighlightsEvents;
  betBuilder: any[];
}

export interface homeHighlightsEvents {
  events: any[];
  sports: any[];
  selectedSport: null;
}

export interface sportCountriesDataModel {
  parentId: string;
  highlights: any[];
  countries: any[];
}

export interface eventDataModel {
  eventMainCategories: any[];
  eventMarkesCategories: any[];
  games: any[];
  specialMarkets: C_GameGroupByName;
  leagueName: string;
  event: any;
  parentId: string;
  selectedEventMainCategory: any;
  selectedMarketsCategory: any;
}

export interface marketDataModel {
  categories: any[];
  marketBets: any[];
  leagueName: string;
  market: any;
  parentId: string;
  selectedCategory: any;
  betsenseData: any;
}

export interface todayDataModel {
  fiveDays: any[];
  selectedDay: any;
  selectedSport: any;
  sportsFilters: any[];
  sportsEvents: C_TodayLeagues[];
}

export class M_SportCountriesData {
  parentId: string;
  highlights: any[];
  countries: any[];
  constructor(parentId, highlights, countries) {
    this.parentId = parentId;
    this.highlights = highlights;
    this.countries = countries;
  }
}

export interface liveDataModel {
  sportsNav: SportNavItem[];
  selectedSportsNav: SportNavItem;
  categories: C_CategoryInfo[];
  selectedCategory: C_CategoryInfo;
  sport: any[];
  liveState: string;
}

export interface liveMarketDataModel {
  CategoryInfos: C_CategoryInfo[];
  Events: any[];
}

export interface raceDataModel {
  filters: any[];
  selectedFilter: any;
  next: C_Horses[];
  today: C_Horses[];
  tomorrow: C_Horses[];
}

export interface raceMarketDataModel {
  filters: any[];
  selectedFilter: any;
  dropListEvents: any;
  dropListEventSelected: any;
  participants: any;
}
