import { CommonModule } from "@angular/common";
import { Component, ElementRef, EventEmitter, Output, ViewChild } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { IonToggle, IonicModule } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";
import { Subject } from "rxjs";

// Pipes
import { ParseStakeMoney } from "@pipes/parseStakeMoney";

// Models
import { Ticket } from "@sports-components/ticket/ticket-base.component";

import { BetslipTicketConfirmedComponent, TicketBetbuilderComponent } from "@sports-components/index";
import { ticketState } from "@sports-stores/ticket/ticket.reducers";
// Components
import { TicketMethods } from "@sports-utils/index";
import { TicketSelectionComponent } from "../ticket-selection/ticket-selection";

@Component({
  selector: "ticket-collapsed",
  templateUrl: "./ticket-collapsed.html",
  styleUrls: ["./ticket-collapsed.scss"],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TranslateModule,
    TicketSelectionComponent,
    ParseStakeMoney,
    TicketBetbuilderComponent,
    BetslipTicketConfirmedComponent,
  ],
})
export class TicketCollapsedComponent extends Ticket {
  ticketState$: Subject<ticketState> = new Subject();

  @ViewChild("betTypeToggle") betTypeToggle: IonToggle;
  @ViewChild("selections") selections: ElementRef;
  @ViewChild("ticketFooter") ticketFooter: ElementRef;
  @ViewChild("ticketFooterBig") ticketFooterBig: ElementRef;

  @Output() toggleTicketCollapsed: EventEmitter<any> = new EventEmitter();

  confirmationExpanded: boolean = false;
  betType: boolean;

  isActive: boolean = true;

  constructor() {
    super();
    this.isMobile = this.deviceService.isMobile();
    if (this.ticket$ !== undefined && this.ticket$ !== null)
      this.ticket$.subscribe((ticket) => {
        this.updateBetTypeToggle();
      });
    this.store.select("ticket").subscribe((ts: ticketState) => {
      this.ticket = ts.ticket;
      this.ticketState = ts;
      this.updateBetTypeToggle();
    });
  }

  betTypeToggleHidden(): boolean {
    return TicketMethods.getNumberOfSelections(this.ticket) === 1 || !this.ticket.StakeGroups.Accumulator.systemId;
  }

  closeTicket() {
    this.mobileBehavior.closeTicket();
  }

  updateBetType() {
    if (this.isMobile && this.keyboard$.getValue()) return;
    const value = this.betTypeToggle.checked ? 0 : 1;
    this.newTicketFacade.changeFilterSelected(value);
  }

  public updateBetTypeToggle() {
    if (this.betTypeToggle) {
      this.betTypeToggle.checked = this.ticketState.ticket.BetType === 0;
    }
  }

  openBonus() {
    // this.dispatch.emit({ type: 'TICKET.OPEN_BONUS', payload: null });
  }

  swipeBarScroll(e) {}

  getTicketIsConfirmed(ticket: any): boolean {
    return TicketMethods.getIsConfirmed(ticket);
  }

  callGetNumberOfSelections(ticket: any) {
    return TicketMethods.getNumberOfSelections(ticket);
  }

  callBetTicketHasErrors(ticket: any) {
    return TicketMethods.getHasErrors(ticket);
  }
}
