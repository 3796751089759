<div class="sb-grid-item--score">
  <div class="sb-grid-item--score-column" [hidden]="scoreboard?.PeriodName === 'Descanso'">
    <i
      class="sb-scoreboard-dot"
      [ngClass]="{ 
      'is-active': scoreboard?.HomeService,
      'sb-button--animation': scoreboard?.HomeService
    }"
    ></i>
    <i
      class="sb-scoreboard-dot"
      [ngClass]="{
      'is-active': !scoreboard?.HomeService,
      'sb-button--animation': !scoreboard?.HomeService
    }"
    ></i>
  </div>
  <div class="sb-grid-item--score-column" *ngIf="!shortSet">
    <p class="sb-grid-item--number color-dark">{{ scoreboard?.ResultHome }}</p>
    <p class="sb-grid-item--number color-dark">{{ scoreboard?.ResultAway }}</p>
  </div>
  <div class="sb-grid-item--score-column" *ngFor="let set of scoreboard?.Sets">
    <p class="sb-grid-item--number color-dark" *ngFor="let game of set">{{ game }}</p>
  </div>
  <div class="sb-grid-item--score-column" *ngIf="!shortSet">
    <p class="sb-grid-item--number color-accent" [ngClass]="{'marquee-score-result':getScoreboardInMarquee()}">
      {{ scoreboard?.SetsHome }}
    </p>
    <p class="sb-grid-item--number color-accent" [ngClass]="{'marquee-score-result':getScoreboardInMarquee()}">
      {{ scoreboard?.SetsAway }}
    </p>
  </div>
  <div class="sb-grid-item--score-column" *ngIf="shortSet">
    <p class="sb-grid-item--number color-accent">{{ scoreboard?.ResultHome }}</p>
    <p class="sb-grid-item--number color-accent">{{ scoreboard?.ResultAway }}</p>
  </div>
</div>
