import { CommonModule } from "@angular/common";
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Input, OnDestroy, Output, inject } from "@angular/core";
import { Router } from "@angular/router";
import { IonicModule } from "@ionic/angular";
import { LicenseTypes } from "@models/MasterData";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { EventsService } from "@providers/EventsService";
import { TrackingService } from "@providers/TrackingService";
import { UserServiceMSO } from "@providers/UserServiceMSO";
import { NewBaseService } from "@providers/newBase.service";
import { DeviceService } from "@services/device.service";
import { Utils } from "@utils/Utils";
import { Subscription } from "rxjs";
import { StepFormSeComponent } from "../step-form-se/step-form-se";
import { Step1SeComponent } from "../step1-se/step1-se";
import { Step2SeComponent } from "../step2-se/step2-se";
import { Step3SeComponent } from "../step3-se/step3-se";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "cdr-pages-se",
  templateUrl: "./pages-se.html",
  styleUrls: ["./pages-se.scss"],
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    Step1SeComponent,
    Step2SeComponent,
    Step3SeComponent,
    StepFormSeComponent,
  ],
})
export class PagesSeComponent implements OnDestroy {
  @Input() step: number;
  @Output() selfExEvent: EventEmitter<{ selfExclusionDate: string; canRevocate: boolean }>;
  dates: Date[] | string = [];
  showTopWarningMessage = true;
  dateOptions: any;
  iconEvent$: Subscription;
  city = "";
  globalVars!: GlobalVarsModel;
  isDesktop: boolean = false;

  userServiceMSO = inject(UserServiceMSO);
  trackingService = inject(TrackingService);
  utils = inject(Utils);
  translate = inject(TranslateService);
  events = inject(EventsService);
  newBaseService = inject(NewBaseService);
  router = inject(Router);
  deviceService = inject(DeviceService);

  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
    this.isDesktop = this.deviceService.isDesktop();
    this.selfExEvent = new EventEmitter();
  }

  ngOnDestroy(): void {
    if (this.iconEvent$) {
      this.iconEvent$.unsubscribe();
    }
  }

  ngOnInit() {
    if (this.globalVars.licenseType === 0) {
      this.showTopWarningMessage = !!(this.isDesktop && this.step === 1);
      this.dateOptions = [
        {
          literal: "selfExclusion1d",
          value: "24h",
          size: "auto",
        },
        {
          literal: "selfExclusion2d",
          value: "48h",
          size: "auto",
        },
        {
          literal: "selfExclusion1s",
          value: "1s",
          size: "auto",
        },
      ];
    } else if (this.globalVars.licenseType === LicenseTypes.Panama) {
      this.dateOptions = [
        {
          literal: "twoyears",
          value: "2y",
          size: "auto",
        },
        {
          literal: "indefinitetime",
          value: "indefinido",
          size: "auto",
        },
      ];
      this.iconEvent$ = this.events.subscribe("iconBack", () => {
        if (this.step === 1) {
          this.router.navigate(["/"]);
        } else {
          if (this.step === 4) {
            this.onStepChange(1);
          } else if (this.step === 2) {
            this.onStepChange(4);
          } else if (this.step === 3) {
            this.onStepChange(2);
          }
        }
      });
    } else {
      this.dateOptions = [];
      this.iconEvent$ = this.events.subscribe("iconBack", () => {
        if (this.step === 1) {
          this.router.navigate(["/"]);
        } else {
          if (this.step === 4) {
            this.onStepChange(1);
          } else if (this.step === 2) {
            this.onStepChange(4);
          } else if (this.step === 3) {
            this.onStepChange(2);
          }
        }
      });
    }
  }

  onStepChange(n) {
    if (
      this.globalVars.licenseType !== LicenseTypes.Colombia &&
      this.globalVars.licenseType !== LicenseTypes.Panama
    ) {
      this.showTopWarningMessage = n === 2;
    } else {
      this.showTopWarningMessage = n === 2 || n === 4;
    }

    this.step = n;
    this.events.publish("changeStep");
  }

  cityChange(c) {
    this.city = c;
  }

  onDatesEvent(d) {
    this.dates = d;
  }

  onSelfExclusion(event: { selfExclusionDate: string; canRevocate: boolean }) {
    this.selfExEvent.emit(event);
  }
}
