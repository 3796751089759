/* eslint-disable */
import { Pipe, PipeTransform, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GlobalVarsModel } from '../models/ModelGlobalsVar';
import { NewBaseService } from '../providers/newBase.service';
@Pipe({
  name: 'formatOdd',
  standalone: true
})
export class FormatOdd implements PipeTransform {
  globalVars!: GlobalVarsModel;
  translate = inject(TranslateService);
  newBaseService = inject(NewBaseService);

  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
  }

  transform(t?: string): string {
    if (t == undefined || t == null || isNaN(parseFloat(t + ''))) {
      return '-';
    }

    if (t == 'N/A' || t == this.translate.instant('cabgalcf')) {
      return t;
    }

    t = t + '';
    const intT: number = parseFloat(t);

    if (intT <= 0 || intT == -1) return this.translate.instant('cabgalcf');

    const typeOdd: string = this.globalVars.oddType;
    let cuota: string;

    switch (typeOdd) {
      case 'fra':
        //hack to avoid js round problem
        const intT2: number = parseFloat((intT - 1).toFixed(2));
        cuota = this.getFractionString(intT2);
        break;

      case 'dec':
        if ((intT * 100) % 100 == 0) {
          cuota = intT.toFixed();
        } else {
          if (this.globalVars.licenseType == 4) {
            cuota = intT.toFixed(2).replace(',', '.');
          } else {
            cuota = intT.toFixed(2).replace('.', ',');
          }
        }

        break;
      case 'ame':
      default:
        if (intT >= 2) {
          cuota = '+' + (100 * (intT - 1)).toFixed();
        } else {
          cuota = (-100 / (intT - 1)).toFixed();
        }
        break;
    }
    if (isNaN(parseFloat(cuota))) {
      return '-';
    }
    return cuota;
  }

  private getFractionString(numerator: number): string {
    //code adapted from fraction.js -> https://github.com/ekg/fraction.js/blob/master/index.js

    const isFloat = function (n: any): boolean {
      return typeof n === 'number' && ((n > 0 && n % 1 > 0 && n % 1 < 1) || (n < 0 && n % -1 < 0 && n % -1 > -1));
    };

    const roundToPlaces = function (n: number, places: number): number {
      if (!places) {
        return Math.round(n);
      } else {
        const scalar = Math.pow(10, places);
        return Math.round(n * scalar) / scalar;
      }
    };

    const gcf = function (a: number, b: number): number {
      if (arguments.length < 2) {
        return a;
      }
      let c: any;
      a = Math.abs(a);
      b = Math.abs(b);

      while (b) {
        c = a % b;
        a = b;
        b = c;
      }
      return a;
    };

    /* double argument invocation */
    let denominator = 1;

    if (isFloat(denominator)) {
      const rounded = roundToPlaces(denominator, 9);
      const scaleup = Math.pow(10, rounded.toString().split('.')[1].length);
      denominator = Math.round(denominator * scaleup); // this !!! should be a whole number

      numerator *= scaleup;
    }
    if (isFloat(numerator)) {
      const rounded = roundToPlaces(numerator, 9);
      const scaleup = Math.pow(10, rounded.toString().split('.')[1].length);
      numerator = Math.round(numerator * scaleup); // this !!! should be a whole number

      denominator *= scaleup;
    }
    const miGfc: number = gcf(numerator, denominator);

    numerator /= miGfc;
    denominator /= miGfc;

    if (denominator < 0) {
      numerator *= -1;
      denominator *= -1;
    }

    return numerator.toFixed() + '/' + denominator.toFixed();
  }
}
