/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { NavController, ModalController, NavParams, IonicModule, IonContent, IonToggle } from '@ionic/angular';
import { Component, ViewChild, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CookiesService } from '@providers/CookiesService';
import { EventsService } from '@providers/EventsService';
import { UserService } from '@providers/UserService';
import { TrackingService } from '@providers/TrackingService';
import { Utils } from '@utils/Utils';
import { Router } from '@angular/router';
import { MSO_PATHS } from '@shared-constants/routes';
import { DeviceService } from '@services/device.service';
import { NewBaseService } from '@providers/newBase.service';
import { GlobalVarsModel } from '@models/ModelGlobalsVar';
import { AppsFlyerTrackingService } from '@providers/AppsFlyerTrackingService';

@Component({
  standalone: true,
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'cookies-consent-page',
  templateUrl: './cookies-consent.html',
  styleUrls: ['./cookies-consent.scss'],
  imports: [IonicModule, CommonModule, TranslateModule, FormsModule]
})
export class CookiesConsentPage {
  @ViewChild(IonContent) content: IonContent;
  @ViewChild('select_all') allSelect: IonToggle;
  consentOptions: any;
  public trackEvent!: TrackEvent;
  fromButton: boolean;
  isDesktop: boolean;
  globalVars!: GlobalVarsModel;

  viewCtrl = inject(ModalController);
  navCtrl = inject(NavController);
  navParams = inject(NavParams);
  utils = inject(Utils);
  trackingService = inject(TrackingService);
  userService = inject(UserService);
  translate = inject(TranslateService);
  cookiesService = inject(CookiesService);
  events = inject(EventsService);
  router = inject(Router);
  device = inject(DeviceService);
  newBaseService = inject(NewBaseService);
  appsFlyerTrackingService = inject(AppsFlyerTrackingService);

  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });

    this.isDesktop = this.device.isDesktop();

    this.consentOptions = {
      customOK: false,
      analyticsOK: false,
      advertismentOK: false
    };
    this.fromButton = true;
  }

  ionViewDidLoad() {
    console.info('ionViewDidLoad CookiesConsentPage');
  }

  ionViewWillEnter() {
    if (this.globalVars.rootScope.alertCookies) {
      this.globalVars.rootScope.alertCookies?.dismiss();
    }
    this.trackingService.trackEvent(['AccessToOddType', '', '', 'Acceder cookies consent', 'event']);
    this.events.publish('footer:refresh', this.content);
  }

  closeModal(hasAccepted = false) {
    if (this.viewCtrl) {
      this.viewCtrl.dismiss();
    }
    if (!hasAccepted) {
      this.events.publish('cookies:showAlert');
    }
  }

  openPolitCookies(event) {
    this.router.navigate([MSO_PATHS.BASE + '/' + MSO_PATHS.InformationPage], {
      queryParams: { type: 'infocookie', animate: !this.isDesktop },
      queryParamsHandling: 'merge'
    });
    event.preventDefault();
    if (this.viewCtrl) {
      this.viewCtrl.dismiss();
    }
  }

  acceptCookies() {
    this.cookiesService.setCookieConsent(this.consentOptions);
    this.appsFlyerTrackingService.setAppsFlyerConsent(this.consentOptions);
    this.closeModal(true);
  }

  acceptAllCookies() {
    for (const key in this.consentOptions) {
      if (!this.consentOptions[key]) {
        this.consentOptions[key] = true;
      }
    }
    this.acceptCookies();
  }

  /**
   * Manejador del evento change de cada uno de los checkbox
   * de los distintos tipos de cookies
   */
  selectOneChange() {
    let selectAll = true;
    for (const key in this.consentOptions) {
      if (!this.consentOptions[key] == true) {
        selectAll = false;
        break;
      }
    }
    this.fromButton = false;

    this.allSelect.checked = selectAll;
  }

  slectAllChangeMobile() {
    this.selectAllFocus();
    this.selectAllChange();
  }

  selectOneFocus() {
    this.fromButton = false;
  }

  selectAllFocus() {
    this.fromButton = true;
  }
  /**
   * Manejador de eventos del togle
   * que selecciona/desselecciona todas las cookies
   */
  selectAllChange() {
    if (!this.fromButton) {
      return;
    }

    if (this.allSelect.checked) {
      for (const key in this.consentOptions) {
        if (!this.consentOptions[key]) {
          this.consentOptions[key] = true;
        }
      }
    } else {
      for (const key in this.consentOptions) {
        if (this.consentOptions[key]) {
          this.consentOptions[key] = false;
        }
      }
    }
  }
}
