<ion-list [formGroup]="fgrp" class="ion-list-radio">
  <ion-radio-group
    mode="md"
    [formControlName]="fcName"
    (ionChange)="radioSelected($event)"
    [allowEmptySelection]="false"
  >
    <ion-radio *ngFor="let item of options; index as i" [value]="item" slot="start" labelPlacement="end">
      <ion-text class="radio-text">{{ item.name }}</ion-text></ion-radio
    >
  </ion-radio-group>
</ion-list>
<cdr-control-error
  *ngIf="errorsCol"
  [fControl]="fControl"
  class="cdr-control-error"
  [errorsCol]="errorsCol"
></cdr-control-error>
