<ng-container *ngIf="!isMobile; else mobileView">
  <ion-header class="has-titleBar">
    <user-section-title [sectionTitle]="'CodereLinkedCard'"></user-section-title>
  </ion-header>

  <!-- eslint-disable @angular-eslint/template/eqeqeq -->
  <ion-content class="background-color-muted">
    <div class="is-narrow-content p-top-1">
      <!--Mi tarjeta-->
      <ion-card class="codere-card">
        <ion-card-header>
          <span>{{ 'CodereLinkedCard' | translate | uppercase }}<!--TARJETA--></span>
          <img *ngIf="globalVars.licenseType != 4" src="assets/global/img/logoGreen.svg" alt="" />
          <img *ngIf="globalVars.licenseType == 4" src="assets/global/img/logo_cirpremia.png" alt="" />
        </ion-card-header>
        <ion-item class="card-item" lines="none">
          <p class="poperties">
            <span>{{ 'CodereCardToChecknumber' | translate | uppercase }}</span>
            <span class="result">{{ cardData?.cardnumber }}</span>
          </p>
        </ion-item>
        <!--NUMERO DE TARJETA-->
        <ion-item class="card-item" lines="none">
          <p class="poperties">
            <span>{{ 'CodereCardBalance' | translate | uppercase }}</span>
            <span class="result"><b>{{ globalVars.user.codereCard?.balance | parseMoney: [1] }}</b></span>
          </p>
        </ion-item>
        <!--FECHA DE VINCULACIÓN-->
        <ion-item class="card-item" lines="none">
          <p class="poperties">
            <span> {{ 'CodereCardDate' | translate | uppercase }}</span>
            <span class="result">{{ cardData?.date | extractDate: [1] }}</span>
          </p>
        </ion-item>
        <ion-item class="card-item" lines="none">
          <p class="poperties">
            <span>{{ 'hStade' | translate | uppercase }}</span>
            <span class="result greenlbl">{{ cardData?.state }}</span>
            <!--ESTADO-->
          </p>
        </ion-item>
      </ion-card>
      <div class="showCode" (click)="showBarcode()" tappable [hidden]="!cardData.cardnumber">
        <div class="squareArr"></div>
        <div *ngIf="isMobile" class="barcode-container">
          <i class="codere-icon icon-scan2"></i
          ><span>
            {{ 'GetCardCode' | translate | uppercase }}
            <!--MOSTRAR CÓDIGO--></span
          >
        </div>
      </div>
      <ion-list class="card-list card-options mx-1">
        <ion-list-header class="card-list-header background-color-transparent">
          {{ 'GetCardManage' | translate | uppercase }}
          <!--GESTIONAR TARJETA-->
        </ion-list-header>
        <ion-item class="card-item card-option" (click)="navToPin()" tappable>
          <ion-avatar slot="start">
            <i class="codere-icon icon-cardPin"></i>
          </ion-avatar>
          <!--Cambiar Pin-->
          <ion-label>{{ 'GetCardChangePin' | translate }}</ion-label>
          <i class="codere-icon arrow icon-arrow-right" item-right=""></i>
        </ion-item>
        <ion-item class="card-item card-option" (click)="navToUnlock()" tappable>
          <ion-avatar slot="start">
            <i class="codere-icon icon-cardPin"></i>
          </ion-avatar>
          <!--Desbloquear tarjeta-->
          <ion-label>{{ 'GetCardUnlock' | translate }}</ion-label>
          <i class="codere-icon arrow icon-arrow-right" item-right=""></i>
        </ion-item>
        <ion-item class="card-item card-option" *ngIf="globalVars.licenseType != 4" (click)="navToRecover()" tappable>
          <ion-avatar slot="start">
            <i class="codere-icon icon-cardRec"></i>
          </ion-avatar>
          <!--Recuperar tarjeta Codere-->
          <ion-label>{{ 'GetCardlost' | translate }}</ion-label>
          <i class="codere-icon arrow icon-arrow-right" item-right=""></i>
        </ion-item>
      </ion-list>
    </div>
    <codere-footer></codere-footer>
  </ion-content>
</ng-container>

<!-- Mobile -->
<ng-template #mobileView>
  <ion-header class="has-titleBar">
    <user-section-title [sectionTitle]="'CodereLinkedCard'"></user-section-title>
  </ion-header>

  <!-- eslint-disable @angular-eslint/template/eqeqeq -->
  <ion-content class="background-color-muted">
    <div class="is-narrow-content p-top-1">
      <!--Mi tarjeta-->
      <div class="pad">
        <ion-card class="codere-card">
          <ion-card-header>
            <span>{{ 'CodereLinkedCard' | translate | uppercase }}<!--TARJETA--></span>
            <img *ngIf="globalVars.licenseType != 4" src="assets/global/img/logoGreen.svg" alt="" />
            <img *ngIf="globalVars.licenseType == 4" src="assets/global/img/logo_cirpremia.png" alt="" />
          </ion-card-header>
          <ion-item class="card-item">
            <p class="poperties">
              <span>{{ 'CodereCardToChecknumber' | translate | uppercase }}</span>
              <span class="result">{{ cardData?.cardnumber }}</span>
            </p>
          </ion-item>
          <!--NUMERO DE TARJETA-->
          <ion-item class="card-item">
            <p class="poperties">
              <span>{{ 'CodereCardBalance' | translate | uppercase }}</span>
              <span class="result"><b>{{ globalVars.user.codereCard?.balance | parseMoney: [1] }}</b></span>
            </p>
          </ion-item>
          <!--FECHA DE VINCULACIÓN-->
          <ion-item class="card-item">
            <p class="poperties">
              <span> {{ 'CodereCardDate' | translate | uppercase }}</span>
              <span class="result">{{ cardData?.date | extractDate: [1] }}</span>
            </p>
          </ion-item>
          <ion-item class="card-item">
            <p class="poperties">
              <span>{{ 'hStade' | translate | uppercase }}</span>
              <span class="result greenlbl">{{ cardData?.state }}</span>
              <!--ESTADO-->
            </p>
          </ion-item>
        </ion-card>
      </div>
      <div class="showCode" (click)="showBarcode()" tappable [hidden]="!cardData.cardnumber">
        <div class="squareArr"></div>
        <div *ngIf="isMobile" class="barcode-container">
          <i class="codere-icon icon-scan2"></i
          ><span>
            {{ 'GetCardCode' | translate | uppercase }}
            <!--MOSTRAR CÓDIGO--></span
          >
        </div>
      </div>
      <ion-list class="card-list card-options mx-1">
        <ion-list-header class="card-list-header background-color-transparent">
          {{ 'GetCardManage' | translate | uppercase }}
          <!--GESTIONAR TARJETA-->
        </ion-list-header>
        <ion-item class="card-item card-option" (click)="navToPin()" tappable>
          <ion-avatar slot="start">
            <i class="codere-icon icon-cardPin"></i>
          </ion-avatar>
          <!--Cambiar Pin-->
          <ion-label>{{ 'GetCardChangePin' | translate }}</ion-label>
          <i class="codere-icon arrow icon-arrow-right" item-right=""></i>
        </ion-item>
        <ion-item class="card-item card-option" (click)="navToUnlock()" tappable>
          <ion-avatar slot="start">
            <i class="codere-icon icon-cardPin"></i>
          </ion-avatar>
          <!--Desbloquear tarjeta-->
          <ion-label>{{ 'GetCardUnlock' | translate }}</ion-label>
          <i class="codere-icon arrow icon-arrow-right" item-right=""></i>
        </ion-item>
        <ion-item class="card-item card-option" *ngIf="globalVars.licenseType != 4" (click)="navToRecover()" tappable>
          <ion-avatar slot="start">
            <i class="codere-icon icon-cardRec"></i>
          </ion-avatar>
          <!--Recuperar tarjeta Codere-->
          <ion-label>{{ 'GetCardlost' | translate }}</ion-label>
          <i class="codere-icon arrow icon-arrow-right"></i>
        </ion-item>
      </ion-list>
    </div>
    <codere-footer></codere-footer>
  </ion-content>
</ng-template>
