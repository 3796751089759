import { CommonModule } from '@angular/common';
import { Component, Input, inject } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { GlobalVarsModel } from '../../../models/ModelGlobalsVar';
import { NewBaseService } from '../../../providers/newBase.service';
import { LicenseTypes } from '@models/MasterData';

@Component({
  selector: 'cs-transfer-deposit',
  templateUrl: './cs-transfer-deposit.html',
  styleUrls: ['./cs-transfer-deposit.scss'],
  standalone: true,
  imports: [TranslateModule, CommonModule]
})
export class CsTransferDepositComponent {
  @Input() transferConfig: any;
  licenseType: number;
  transferConcept: string;
  globalVars!: GlobalVarsModel;

  public newBaseService = inject(NewBaseService);
  public readonly licenseMDZ = LicenseTypes.ArgentinaMendoza;
  public readonly licensePA = LicenseTypes.Panama;
  public readonly licenseCABA = LicenseTypes.ArgentinaCaba;

  constructor() {
    this.newBaseService.getVars.subscribe((vars) => {
      this.globalVars = vars;
    });
    this.licenseType = this.globalVars.licenseType;
    this.transferConcept = 'Concepto: Cédula de Identidad + Nombre de usuario.';
  }

  copyButton(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    navigator.clipboard.writeText(val);
    document.body.removeChild(selBox);
  }
}
