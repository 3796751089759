/* eslint-disable @typescript-eslint/no-explicit-any */
import { SelectorsMasterData } from '@models/SelectorsMasterData';
import { IonicModule, ModalController } from '@ionic/angular';
import { CUSTOM_ELEMENTS_SCHEMA, Component, inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormsModule, ReactiveFormsModule, FormControl } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DocumentNumberModalOutput } from '@models/DocumentNumberModalOutput';
import { CodereNavbar } from '@shared-common-components/codere-navbar/codere-navbar';
import { UserSectionTitle } from '@shared-mso-components/user-section-title/user-section-title';
import { CommonModule } from '@angular/common';
import { DeviceService } from '@services/device.service';

@Component({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'document-number-modal',
  templateUrl: './document-number-modal.html',
  styleUrls: ['./document-number-modal.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    UserSectionTitle,
    CodereNavbar
  ]
})
export class DocumentNumberModal {
  validationForm: FormGroup;
  documentTypes!: any[];
  viewModel: DocumentNumberModalOutput;
  isMobile: boolean;
  viewCtrl = inject(ModalController);
  translate = inject(TranslateService);
  selectorsMasterData = inject(SelectorsMasterData);
  fb = inject(FormBuilder);
  device = inject(DeviceService);

  constructor() {
    this.isMobile = this.device.isMobile();
    this.viewModel = new DocumentNumberModalOutput();

    this.validationForm = this.fb.group({
      documentType: ['', Validators.required],
      documentNumber: ['', Validators.compose([Validators.required, Validators.minLength(6)])]
    });
  }

  getFormControl(controlName: string): FormControl {
    return this.validationForm.get(controlName) as FormControl;
  }

  accept() {
    if (this.validationForm.valid) {
      this.viewCtrl.dismiss(this.viewModel);
    } else {
      for (const control in this.validationForm.controls) {
        this.validationForm.get(control)?.markAsTouched();
        this.validationForm.get(control)?.updateValueAndValidity();
      }
    }
  }
  closeModal() {
    if (this.viewCtrl) {
      this.viewCtrl.dismiss();
    }
  }
}
