/* eslint-disable eqeqeq */
import { Pipe, PipeTransform } from '@angular/core';
import { GlobalVarsModel } from '@models/ModelGlobalsVar';

@Pipe({ name: 'bonus' })
export class Bonus implements PipeTransform {
  globalVars!: GlobalVarsModel;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transform(t?: number, args: any[] = []): string {
    let format: number;
    if (args.length > 0) {
      format = parseInt(args[0]);
    } else {
      format = 0;
    }

    if (t == undefined || t == null) return '-';

    if (format == 1) return (t * 100).toFixed(2) + '%';
    else return ((t - 1) * 100).toFixed(0) + '%';
  }
}
