<div class="myWrapInput" [class.has-error]="t_error!=='' && item.fcontrol.touched">
  <ion-row>
    <ion-col class="col" col-2="">
      <button
        class="fab buttonadd"
        float-left
        mini=""
        style="background: #333f48 !important; text-align: center"
        (click)="lessValue()"
        name="buttonRemove"
      >
        <span class="button-inner" name="spanButtonRemove"
          ><ion-icon
            name="remove-outline"
            role="img"
            class="icon icon-ios ion-ios-remove iconButtonRemove color-light"
            aria-label="add"
          ></ion-icon
        ></span>
        <div class="button-effect"></div>
      </button>
    </ion-col>
    <ion-col class="col" col-8="">
      <ion-item style="border: 0 !important; margin: 5px 0 !important" [class.error]="t_error">
        <ion-input
          class="txtinputbig"
          type="number"
          [(ngModel)]="item.model.depositAmount"
          [formControl]="item.fcontrol"
          center
          placeholder="{{'enterAmount' | translate}}"
          name="inputAmount"
        ></ion-input>
      </ion-item>
      <div class="centertxt" [class.txtred]="t_error!=='' && item.fcontrol.touched">
        <span>{{item.text | uppercase}}</span><span> ({{globalVars.currencyLiteral}})</span>
      </div>
      <div [hidden]="t_error==='' || !item.fcontrol.touched" class="labelError">
        <span>
          <span [innerHTML]="t_error"></span>
        </span>
      </div>
    </ion-col>
    <ion-col class="col" col-2="">
      <button
        class="fab buttonadd"
        float-right
        (click)="moreValue()"
        mini=""
        style="background: #333f48 !important; text-align: center"
        name="buttonAdd"
      >
        <span class="button-inner" name="spanButtonAdd"
          ><ion-icon
            name="add-outline"
            role="img"
            class="icon icon-ios ion-ios-add iconButtonAdd color-light"
            aria-label="add"
          ></ion-icon
        ></span>
        <div class="button-effect"></div>
      </button>
    </ion-col>
  </ion-row>
</div>
