<!-- eslint-disable @angular-eslint/template/eqeqeq -->
<ng-container *ngIf="!isMobile; else mobileView">
  <ion-content class="is-narrow-content background-color-muted" [ngSwitch]="steps">
    <user-section-title [sectionTitle]="'GetCardlost'"></user-section-title>
    <ion-toolbar class="subHeaderSteps">
      <ion-segment class="my-card-recover-segment" [(ngModel)]="steps">
        <ion-segment-button
          [ngClass]="{enabledButton: steps=='step1', disabledButton: steps!='step1'}"
          disabled
          value="step1"
        >
          <ion-avatar>
            <span>1</span>
          </ion-avatar>
          <div class="squareArr"><i></i></div>
        </ion-segment-button>
        <div class="sepsegment" tappable="false"></div>
        <ion-segment-button
          [ngClass]="{enabledButton: steps=='step2', disabledButton: steps!='step2'}"
          disabled
          value="step2"
        >
          <ion-avatar>
            <span>2</span>
          </ion-avatar>
          <div class="squareArr"><i></i></div>
        </ion-segment-button>
        <div class="sepsegment" tappable="false"></div>
        <ion-segment-button
          [ngClass]="{enabledButton: steps=='step3', disabledButton: steps!='step3'}"
          disabled
          value="step3"
        >
          <ion-avatar>
            <span>3</span>
          </ion-avatar>
          <div class="squareArr"><i></i></div>
        </ion-segment-button>
      </ion-segment>
    </ion-toolbar>

    <form [formGroup]="myForm1">
      <ion-list class="card-list" *ngSwitchCase="'step1'">
        <ion-list-header class="card-list-header background-transparent">
          <ion-avatar item-left><i class="codere-icon icon-cardRec"></i></ion-avatar>
          <ion-label>
            <span [innerHTML]="'GetCardUnlockSecurity' | translate"
              ><!--Por motivos de seguridad, para poder desbloquear tu tarjeta indica la contrase�a de tu cuenta online.--></span
            >
          </ion-label>
        </ion-list-header>

        <div class="myWrapInput">
          <ion-item class="cdr-item-input" lines="none">
            <ion-input
              labelPlacement="floating"
              label="{{'GetCardpassonline' | translate}}"
              type="password"
              maxlength="50"
              [formControl]="$any(myForm1.controls['password'])"
            ></ion-input>
          </ion-item>
          <div
            class="labelError"
            [hidden]="myForm1.controls['password'].valid || !myForm1.controls['password'].touched"
          >
            <span>{{'error_form_requiered' | translate}}<!--Este campo es obligatorio--></span>
          </div>
        </div>

        <div class="sendForm">
          <ion-button expand="full" (click)="checkPassword()"
            >{{'tCont' | translate | uppercase}}<!--CONTINUAR--></ion-button
          >
        </div>
      </ion-list>

      <ion-list class="card-list" *ngSwitchCase="'step2'">
        <ion-list-header class="card-list-header background-transparent">
          <ion-avatar class="card-recover-avatar" item-left><i class="codere-icon icon-cardRec"></i></ion-avatar>
          <ion-label>
            <span>{{'GetCarstep2txt' | translate}}<!--Inserta los 16 d�gitos de tu nueva tarjeta.--></span>
          </ion-label>
        </ion-list-header>

        <div class="myWrapInput">
          <ion-item class="cdr-item-input" lines="none">
            <ion-input
              labelPlacement="floating"
              label="{{'CodereCardToChecknumber' | translate}}"
              type="text"
              [formControl]="$any(myForm2.controls['cardNumber'])"
            ></ion-input>
          </ion-item>
          <div
            class="labelError"
            [hidden]="myForm2.controls['cardNumber'].valid || !myForm2.controls['cardNumber'].touched"
          >
            <span>{{'v2_errorTarj' | translate}}<!--# N�mero de tarjeta no v�lido--></span>
          </div>
        </div>

        <div class="sendForm">
          <ion-button expand="full" (click)="sendCard()"
            >{{'tCont' | translate | uppercase}}<!--CONTINUAR--></ion-button
          >
        </div>
      </ion-list>

      <ion-list class="card-list" *ngSwitchCase="'step3'">
        <ion-list-header class="card-list-header background-transparent">
          <ion-avatar item-left><i class="codere-icon icon-cardRec"></i></ion-avatar>
          <ion-label>
            <span [innerHTML]="'GetCarstep3txt' | translate"><br /><!--Establece un Pin para tu tarjeta--></span>
          </ion-label>
        </ion-list-header>

        <div class="myWrapInput">
          <ion-item class="cdr-item-input" lines="none">
            <ion-input
              labelPlacement="floating"
              label="PIN"
              type="password"
              [formControl]="$any(myForm3.controls['newPin'])"
            ></ion-input>
          </ion-item>
          <div class="labelError" [hidden]="myForm3.controls['newPin'].valid || !myForm3.controls['newPin'].touched">
            <span *ngIf="myForm3.controls['newPin'].hasError('pinNotConsecutiveNumbers')">
              {{'v2_pinRegExNotEqNotConsec' | translate}}<br
            /></span>
            <span *ngIf="myForm3.controls['newPin'].hasError('required')"
              >{{'v2_pinObl' | translate}}<!--# El pin es obligatorio--></span
            >
            <span *ngIf="myForm3.controls['newPin'].hasError('pattern')">
              {{'v2_pinRegExNotEqNotConsec' | translate}}<!--# El pin debe contener s�lo n�meros--></span
            >
            <span *ngIf="myForm3.controls['newPin'].hasError('maxlength')">
              {{'v2_pinNum1' | translate}}<!--# El pin debe contener 4 n�meros--></span
            >
            <span *ngIf="myForm3.controls['newPin'].hasError('minlength')">
              {{'v2_pinNum1' | translate}}<!--# El pin debe contener 4 n�meros--></span
            >
          </div>
        </div>
        <div class="myWrapInput">
          <ion-item class="cdr-item-input" lines="none">
            <ion-input
              labelPlacement="floating"
              label="{{'GetCardRepeatPin' | translate | uppercase}}"
              type="password"
              [formControl]="$any(myForm3.controls['reNewPin'])"
            ></ion-input>
          </ion-item>
          <div
            class="labelError"
            [hidden]="myForm3.controls['reNewPin'].valid || !myForm3.controls['reNewPin'].touched"
          >
            <span *ngIf="myForm3.controls['reNewPin'].hasError('pinNotConsecutiveNumbers')">
              {{'v2_pinRegExNotEqNotConsec' | translate}}<br
            /></span>
            <span *ngIf="myForm3.controls['reNewPin'].hasError('pattern')">
              {{'v2_pinRegExNotEqNotConsec' | translate}}<!--# El pin debe contener s�lo n�meros--></span
            >
            <span *ngIf="myForm3.controls['reNewPin'].hasError('required')">
              {{'v2_pinObl' | translate}}<!--# El pin es obligatorio--></span
            >
            <span *ngIf="myForm3.controls['reNewPin'].hasError('maxlength')">
              {{'v2_pinNum1' | translate}}<!--# El pin debe contener 4 n�meros--></span
            >
            <span *ngIf="myForm3.controls['reNewPin'].hasError('minlength')">
              {{'v2_pinNum1' | translate}}<!--# El pin debe contener 4 n�meros--></span
            >
            <span *ngIf="myForm3.controls['reNewPin'].hasError('same')">
              {{'v2_errorPin' | translate}}<!--# El pin no coincide--></span
            >
          </div>
        </div>

        <div class="sendForm">
          <ion-button expand="full" (click)="setPin()">{{'tCont' | translate | uppercase}}<!--CONTINUAR--></ion-button>
        </div>
      </ion-list>
    </form>
    <codere-footer></codere-footer>
  </ion-content>
</ng-container>

<ng-template #mobileView>
  <ion-header>
    <user-section-title [sectionTitle]="'GetCardlost'"></user-section-title>
  </ion-header>

  <ion-content class="background-color-muted" [ngSwitch]="steps">
    <ion-toolbar class="subHeaderSteps">
      <ion-segment class="my-card-recover-segment" [(ngModel)]="steps">
        <ion-segment-button
          [ngClass]="{enabledButton: steps=='step1', disabledButton: steps!='step1'}"
          disabled
          value="step1"
          mode="md"
        >
          <ion-avatar>
            <span>1</span>
          </ion-avatar>
          <div class="squareArr"><i></i></div>
        </ion-segment-button>
        <div class="sepsegment" tappable="false"></div>
        <ion-segment-button
          [ngClass]="{enabledButton: steps=='step2', disabledButton: steps!='step2'}"
          disabled
          value="step2"
          mode="md"
        >
          <ion-avatar>
            <span>2</span>
          </ion-avatar>
          <div class="squareArr"><i></i></div>
        </ion-segment-button>
        <div class="sepsegment" tappable="false"></div>
        <ion-segment-button
          [ngClass]="{enabledButton: steps=='step3', disabledButton: steps!='step3'}"
          disabled
          value="step3"
          mode="md"
        >
          <ion-avatar>
            <span>3</span>
          </ion-avatar>
          <div class="squareArr"><i></i></div>
        </ion-segment-button>
      </ion-segment>
    </ion-toolbar>

    <form [formGroup]="myForm1">
      <ion-list class="card-list" *ngSwitchCase="'step1'">
        <ion-list-header class="card-list-header background-transparent">
          <ion-avatar item-left><i class="codere-icon icon-cardRec"></i></ion-avatar>
          <ion-label>
            <span [innerHTML]="'GetCardUnlockSecurity' | translate"
              ><!--Por motivos de seguridad, para poder desbloquear tu tarjeta indica la contrase�a de tu cuenta online.--></span
            >
          </ion-label>
        </ion-list-header>

        <div class="myWrapInput">
          <ion-item class="cdr-item-input" lines="none">
            <ion-input
              labelPlacement="floating"
              label="{{'GetCardpassonline' | translate}}"
              type="password"
              maxlength="50"
              [formControl]="$any(myForm1.controls['password'])"
            ></ion-input>
          </ion-item>
          <div
            class="labelError"
            [hidden]="myForm1.controls['password'].valid || !myForm1.controls['password'].touched"
          >
            <span>{{'error_form_requiered' | translate}}<!--Este campo es obligatorio--></span>
          </div>
        </div>

        <div class="sendForm">
          <ion-button expand="full" (click)="checkPassword()"
            >{{'tCont' | translate | uppercase}}<!--CONTINUAR--></ion-button
          >
        </div>
      </ion-list>

      <ion-list class="card-list" *ngSwitchCase="'step2'">
        <ion-list-header class="card-list-header background-transparent">
          <ion-avatar class="card-recover-avatar" item-left><i class="codere-icon icon-cardRec"></i></ion-avatar>
          <ion-label>
            <span>{{'GetCarstep2txt' | translate}}<!--Inserta los 16 d�gitos de tu nueva tarjeta.--></span>
          </ion-label>
        </ion-list-header>

        <div class="myWrapInput">
          <ion-item class="cdr-item-input" lines="none">
            <ion-input
              labelPlacement="floating"
              label="{{'CodereCardToChecknumber' | translate}}"
              type="text"
              [formControl]="$any(myForm2.controls['cardNumber'])"
            ></ion-input>
          </ion-item>
          <div
            class="labelError"
            [hidden]="myForm2.controls['cardNumber'].valid || !myForm2.controls['cardNumber'].touched"
          >
            <span>{{'v2_errorTarj' | translate}}<!--# N�mero de tarjeta no v�lido--></span>
          </div>
        </div>

        <div class="sendForm">
          <ion-button expand="full" (click)="sendCard()"
            >{{'tCont' | translate | uppercase}}<!--CONTINUAR--></ion-button
          >
        </div>
      </ion-list>

      <ion-list class="card-list" *ngSwitchCase="'step3'">
        <ion-list-header class="card-list-header background-transparent">
          <ion-avatar item-left><i class="codere-icon icon-cardRec"></i></ion-avatar>
          <ion-label>
            <span [innerHTML]="'GetCarstep3txt' | translate"><br /><!--Establece un Pin para tu tarjeta--></span>
          </ion-label>
        </ion-list-header>

        <div class="myWrapInput">
          <ion-item class="cdr-item-input" lines="none">
            <ion-input
              labelPlacement="floating"
              label="PIN"
              type="password"
              [formControl]="$any(myForm3.controls['newPin'])"
            ></ion-input>
          </ion-item>
          <div class="labelError" [hidden]="myForm3.controls['newPin'].valid || !myForm3.controls['newPin'].touched">
            <span *ngIf="myForm3.controls['newPin'].hasError('pinNotConsecutiveNumbers')">
              {{'v2_pinRegExNotEqNotConsec' | translate}}<br
            /></span>
            <span *ngIf="myForm3.controls['newPin'].hasError('required')"
              >{{'v2_pinObl' | translate}}<!--# El pin es obligatorio--></span
            >
            <span *ngIf="myForm3.controls['newPin'].hasError('pattern')">
              {{'v2_pinRegExNotEqNotConsec' | translate}}<!--# El pin debe contener s�lo n�meros--></span
            >
            <span *ngIf="myForm3.controls['newPin'].hasError('maxlength')">
              {{'v2_pinNum1' | translate}}<!--# El pin debe contener 4 n�meros--></span
            >
            <span *ngIf="myForm3.controls['newPin'].hasError('minlength')">
              {{'v2_pinNum1' | translate}}<!--# El pin debe contener 4 n�meros--></span
            >
          </div>
        </div>
        <div class="myWrapInput">
          <ion-item class="cdr-item-input" lines="none">
            <ion-input
              labelPlacement="floating"
              label="{{'GetCardRepeatPin' | translate | uppercase}}"
              type="password"
              [formControl]="$any(myForm3.controls['reNewPin'])"
            ></ion-input>
          </ion-item>
          <div
            class="labelError"
            [hidden]="myForm3.controls['reNewPin'].valid || !myForm3.controls['reNewPin'].touched"
          >
            <span *ngIf="myForm3.controls['reNewPin'].hasError('pinNotConsecutiveNumbers')">
              {{'v2_pinRegExNotEqNotConsec' | translate}}<br
            /></span>
            <span *ngIf="myForm3.controls['reNewPin'].hasError('pattern')">
              {{'v2_pinRegExNotEqNotConsec' | translate}}<!--# El pin debe contener s�lo n�meros--></span
            >
            <span *ngIf="myForm3.controls['reNewPin'].hasError('required')">
              {{'v2_pinObl' | translate}}<!--# El pin es obligatorio--></span
            >
            <span *ngIf="myForm3.controls['reNewPin'].hasError('maxlength')">
              {{'v2_pinNum1' | translate}}<!--# El pin debe contener 4 n�meros--></span
            >
            <span *ngIf="myForm3.controls['reNewPin'].hasError('minlength')">
              {{'v2_pinNum1' | translate}}<!--# El pin debe contener 4 n�meros--></span
            >
            <span *ngIf="myForm3.controls['reNewPin'].hasError('same')">
              {{'v2_errorPin' | translate}}<!--# El pin no coincide--></span
            >
          </div>
        </div>

        <div class="sendForm">
          <ion-button expand="full" (click)="setPin()">{{'tCont' | translate | uppercase}}<!--CONTINUAR--></ion-button>
        </div>
      </ion-list>
    </form>
    <codere-footer></codere-footer>
  </ion-content>
</ng-template>
