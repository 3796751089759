<div class="sb-filter-swiper swiper-container" [class.is-switch]="switch" #swiper [elementDisabled]="keyboard">
  <div class="swiper-wrapper" [class.is-switch]="switch" #list>
    <div
      class="swiper-slide sb-filter-item"
      *ngFor="let filter of filters; let index = index"
      [class.is-selected]="filter?.selected"
      [class.is-disabled]="filter?.disabled"
      (click)="select(filter, index)"
      [elementDisabled]="keyboard"
    >
      <div class="sb-filter-item--image" *ngIf="filter?.image">x</div>

      <span
        *ngIf="filter?.CategoryId !== betbuilderCatId"
        class="sb-filter--title color-muted"
        [ngClass]="{
          'line-through': (filter?.disabled && mode === lineThroughMode),
          'color-muted': filter?.CategoryId !== betbuilderCatId,
          'iconBetbuilder': filter?.CategoryId === betbuilderCatId
        }"
      >
        {{ filter?.name | translate | titleCase }}
      </span>
      <span
        *ngIf="filter?.CategoryId === betbuilderCatId"
        class="sb-filter--title color-muted"
        [ngClass]="{
          'line-through': (filter?.disabled && mode === lineThroughMode),
          'color-muted': filter?.CategoryId !== betbuilderCatId,
          'iconBetbuilder': filter?.CategoryId === betbuilderCatId
        }"
      >
        {{ 'SPO_BetbuilderCategoryName' | translate | uppercase }}
      </span>

      <img class="sb-filter-item--icon" *ngIf="filter?.icon && filter?.name !== null" [src]="filter.icon" />

      <ion-icon name="lock-closed" color="dark" *ngIf="filter?.blocked"> </ion-icon>
    </div>

    <div class="sb-filter-line" #line></div>
  </div>
</div>
