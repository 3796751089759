<div
  class="mini-ql-mobile"
  panHorizontal
  #container
  (onOpened)="init($event)"
  (onClosed)="close()"
  [class.shadowClose]="closed"
>
  <div class="ql-mobile-button" [ngClass]="{ 'bottom30': adjust === true }">
    <a (click)="click($event)"
      ><img
        *ngIf="!globalVars.FEATURES?.MSO_NewReBranding else rebranding"
        src="assets/global/img/slots/OpenIcon.svg"
        [src]="isActive ?  'assets/global/img/slots/Close.svg': 'assets/global/img/slots/OpenIcon.svg' "
        alt="ql icon"
      />
      <ng-template #rebranding>
        <img
          class="rebrandingImg"
          src="assets/global/img/slots/OpenIconRebranding.svg"
          [src]="isActive ?  'assets/global/img/slots/CloseRebranding.svg': 'assets/global/img/slots/OpenIconRebranding.svg' "
          alt="ql icon"
        />
      </ng-template>
    </a>
  </div>
  <div class="qL-card" [class.invisible]="!isActive">
    <div class="qL-card--txt" #textContainer [ngClass]="{'display': data?.title !== '' && data?.title }">
      {{data?.title}}
    </div>
    <div *ngFor="let game of currentGames$ | async" class="qL-card--container">
      <casino-card-new
        tappable
        class="qL-card--img swiper-slide"
        [game]="game"
        [grid]="true"
        [showGameTicker]="false"
        (turnPlay)="playGame($event)"
      >
      </casino-card-new>
    </div>
  </div>
</div>
