/* eslint-disable */
import { Injectable, inject } from "@angular/core";
import { Store } from "@ngrx/store";
import { BehaviorSubject, Subject } from "rxjs";

import { LicenseTypes } from "@models/index";
import { GlobalVarsModel } from "@models/index";
import { TicketMethods } from "@sports-utils/index";

import { NewBaseService } from "@shared-providers/newBase.service";
import { ticketState } from "../stores/ticket/ticket.reducers";

@Injectable({ providedIn: "root" })
export class MobileBehaviorUtils {
  ticketState: ticketState;
  smallFooter$ = new BehaviorSubject<boolean>(false);
  ticketCollapsed$ = new BehaviorSubject<boolean>(true);
  ticketFull$ = new BehaviorSubject<boolean>(false);
  scrolling$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  keyboard$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  mode = "";
  ticketMode: string;
  numberOfSelections = 0;
  disabledDot$: Subject<boolean> = new Subject();
  disableElement$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  oneSingleSelectionHeight = 81;
  twoBetbuilderHeight = 89;
  threeBetbuilderHeight = 114;
  footerHeight = 146;
  globalVars!: GlobalVarsModel;

  store = inject(Store<ticketState>);
  newBaseService = inject(NewBaseService);

  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
    this.store.select("ticket").subscribe((currentTicketState: any) => {
      this.ticketState = currentTicketState;
      this.numberOfSelections = TicketMethods.getNumberOfSelections(currentTicketState?.ticket);

      if (this.keyboard$.getValue() || this.ticketState?.ticket?.processing) {
        this.disableElement$.next(true);
      } else {
        this.disableElement$.next(false);
      }
    });
    switch (this.globalVars.licenseType) {
      case LicenseTypes.Mexico:
      case LicenseTypes.Colombia:
        this.disabledDot$.next(true); /* Betslip */
        break;
      case LicenseTypes.Panama:
      default: /* Betslip */
        this.disabledDot$.next(false);
        break;
    }
  }

  getMode(): string {
    this.mode = "";
    if (this.numberOfSelections > 0) {
      if (this.numberOfSelections === 1) {
        if (this.ticketState?.ticket?.SmartLineItems.length === 0) this.mode = "one-single-selection";
        if (
          this.ticketState?.ticket?.SmartLineItems.length === 1 &&
          this.ticketState?.ticket?.SmartLineItems[0].SmartLineItems.length < 3
        )
          this.mode = "two-betbuilder";
        if (
          this.ticketState?.ticket?.SmartLineItems.length === 1 &&
          this.ticketState?.ticket?.SmartLineItems[0].SmartLineItems.length === 3
        )
          this.mode = "three-betbuilder";
        if (
          this.ticketState?.ticket?.SmartLineItems.length === 1 &&
          this.ticketState?.ticket?.SmartLineItems[0].SmartLineItems.length > 3
        )
          this.mode = "open-full-ticket";
      }
      if (this.numberOfSelections === 2) {
        if (this.ticketState?.ticket?.SmartLineItems.length === 0) this.mode = "two-simple-selection";
        if (
          this.ticketState?.ticket?.SmartLineItems.length === 1 &&
          this.ticketState?.ticket?.SmartLineItems[0].SmartLineItems.length < 3
        )
          this.mode = "open-full-ticket";
        if (
          this.ticketState?.ticket?.SmartLineItems.length === 1 &&
          this.ticketState?.ticket?.SmartLineItems[0].SmartLineItems.length === 3
        )
          this.mode = "open-full-ticket";
        if (
          this.ticketState?.ticket?.SmartLineItems.length === 1 &&
          this.ticketState?.ticket?.SmartLineItems[0].SmartLineItems.length > 3
        )
          this.mode = "open-full-ticket";
        if (this.ticketState?.ticket?.SmartLineItems.length > 1) this.mode = "open-full-ticket";
      }
      if (this.numberOfSelections > 2) this.mode = "open-full-ticket";
    }
    return this.mode;
  }

  getModeHeight(): number {
    let modeHeight = this.footerHeight;
    switch (this.mode) {
      case "one-single-selection":
        modeHeight += this.oneSingleSelectionHeight;
        break;
      case "two-simple-selection":
        modeHeight += this.oneSingleSelectionHeight * 2;
        break;
      case "one-simple-two-betbuilder":
        modeHeight += this.oneSingleSelectionHeight + this.twoBetbuilderHeight;
        break;
      case "one-simple-three-betbuilder":
        modeHeight += this.oneSingleSelectionHeight + this.threeBetbuilderHeight;
        break;
      case "two-betbuilder":
        modeHeight += this.twoBetbuilderHeight;
        break;
      case "three-betbuilder":
        modeHeight += this.threeBetbuilderHeight;
        break;
      case "open-full-ticket":
        return 9999;
      default:
        break;
    }
    return modeHeight;
  }

  openTicket() {
    const mode = this.getMode();
    switch (mode) {
      case "open-full-ticket":
        this.openTicketFull();
        break;
      default:
        this.openSmallFooter();
        break;
    }
  }

  initialTicket() {
    this.openSmallFooter();
  }

  openTicketFull() {
    if (!this.ticketFull$.value) {
      this.ticketFull$.next(true);
      this.closeSmallFooter();
      this.closeTicketCollapsed();
    }
  }

  closeTicketFull() {
    if (this.ticketFull$.value) {
      this.ticketFull$.next(false);
      this.openSmallFooter();
      this.closeTicketCollapsed();
    }
  }

  openSmallFooter() {
    if (!this.smallFooter$.value) this.smallFooter$.next(true);
  }

  closeSmallFooter() {
    if (this.smallFooter$.value) this.smallFooter$.next(false);
  }

  openTicketCollapsed() {
    if (!this.ticketCollapsed$.value) this.ticketCollapsed$.next(true);
  }

  closeTicketCollapsed() {
    if (this.ticketCollapsed$.value) this.ticketCollapsed$.next(false);
  }

  closeTicket() {
    this.closeTicketFull();
  }

  clearTicket() {
    this.closeTicketFull();
    this.closeTicketCollapsed();
    this.closeSmallFooter();
  }

  toggleKeyboard() {
    const keyboard = this.keyboard$.getValue();
    this.keyboard$.next(!keyboard);
  }

  closeKeyboard(close: boolean) {
    this.keyboard$.next(close);
  }
}
