import { CommonModule } from "@angular/common";
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, OnInit, inject } from "@angular/core";
import { IonicModule, NavParams, PopoverController } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";
import { firstValueFrom } from "rxjs";

import { Router } from "@angular/router";
import { CookieNames } from "@models/CookieNames";
import { TargetOptInModel, TrackerValue } from "@models/I_TargetOptIn";
import { I_UserInfo } from "@models/I_UserInfo";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { TrackersCodereId } from "@models/TrackingEvents";
import { TRAKERSTYPECODEREID } from "@models/TrackingEvents";
import { DecouplingUserServiceMSO } from "@providers/DecouplingUserServiceMSO.service";
import { UserService } from "@providers/UserService";
import { UserServiceMSO } from "@providers/UserServiceMSO";
import { NewBaseService } from "@providers/newBase.service";
import { MSO_PATHS } from "@shared-constants/routes";
import { Utils } from "@utils/Utils";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "targeted-optin-popup-page",
  templateUrl: "./targeted-opt-in-popup.html",
  styleUrls: ["./targeted-opt-in-popup.scss"],
  standalone: true,
  imports: [CommonModule, IonicModule, TranslateModule],
})
export class TargetedOptInPopupPage implements OnInit {
  targetPromo: TargetOptInModel = null;
  headerTitle = "DISFRUTA DE NUESTRAS PROMOCIONES";
  isOptIn = false;
  dateNow: Date = null;
  dateUntilRedeem: Date = null;
  isDecline = false;

  globalVars!: GlobalVarsModel;

  router = inject(Router);
  utils = inject(Utils);
  userService = inject(UserService);
  decouplingUserServiceMSO = inject(DecouplingUserServiceMSO);
  userServiceMSO = inject(UserServiceMSO);
  navParams = inject(NavParams);
  viewCtrl = inject(PopoverController);
  newBaseService = inject(NewBaseService);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {
    this.newBaseService.getVars.subscribe((global) => {
      this.globalVars = global;
    });
  }

  ngOnInit(): void {
    //Tomar el objeto que le llega por parametros al modal
    if (this.navParams.get("targetPromo")) {
      this.targetPromo = this.navParams.get("targetPromo");
      this.dateNow = new Date();
      this.dateUntilRedeem = new Date();
      this.dateUntilRedeem.setDate(this.dateNow.getDate() + this.targetPromo.daysToRedeem);
    }
  }

  async dismissModal() {
    try {
      const isCreated = await this.isTrackerCreate(TRAKERSTYPECODEREID.croTag1);
      if (isCreated) {
        this.updateTracker(this.formatTracker(this.targetPromo, TrackerValue.WINDOWCLOSED));
      } else {
        this.createTracker(this.formatTracker(this.targetPromo, TrackerValue.WINDOWCLOSED));
      }
    } catch (error) {
      console.info(error);
    }
    this.closeModal();
  }

  closeModal() {
    if (this.viewCtrl) {
      this.viewCtrl.dismiss();
    }
  }

  dateStart() {
    let todayHour = "" + this.dateNow.getHours();
    if (this.dateNow.getHours() < 10) {
      todayHour = "0" + todayHour;
    }
    let todayMinutes = "" + this.dateNow.getMinutes();
    if (this.dateNow.getMinutes() < 10) {
      todayMinutes = "0" + todayMinutes;
    }
    const todayHourMinute = todayHour + ":" + todayMinutes;
    return (
      this.dateNow.getFullYear() +
      "-" +
      (this.dateNow.getMonth() + 1) +
      "-" +
      this.dateNow.getDate() +
      "T" +
      todayHourMinute +
      ":00"
    );
  }

  dateEnd() {
    let hour = "" + this.dateUntilRedeem.getHours();
    if (this.dateUntilRedeem.getHours() < 10) {
      hour = "0" + hour;
    }
    return (
      this.dateUntilRedeem.getFullYear() +
      "-" +
      (this.dateUntilRedeem.getMonth() + 1) +
      "-" +
      this.dateUntilRedeem.getDate() +
      "T23:59:00"
    );
  }

  optIn() {
    this.utils.loader();
    const dataOptIn = {
      promoId: this.targetPromo.bonusID,
      playerId: this.globalVars.user.username,
      promoProduct: this.targetPromo.optinCategory,
      promoType: "multi",
      tyc: "1",
      optInDate: "",
      optOutDate: "",
      promoStart: this.dateStart(),
      promoEnd: this.dateEnd(),
    };

    this.userServiceMSO.setPromotionsOptIn(dataOptIn).subscribe({
      next: async (data) => {
        this.isOptIn = true;
        this.headerTitle = "FELICIDADES";

        try {
          const isCreated = await this.isTrackerCreate(TRAKERSTYPECODEREID.croTag1);

          if (isCreated) {
            this.updateTracker(this.formatTracker(this.targetPromo, TrackerValue.ACCEPTED));
          } else {
            this.createTracker(this.formatTracker(this.targetPromo, TrackerValue.ACCEPTED));
          }
        } catch (error) {
          console.info(error);
        }
        this.utils.closeLoader();
      },
      error: (error) => {
        this.utils.closeLoader();
      },
    });
  }

  setdecline() {
    this.isDecline = true;
  }

  async decline() {
    try {
      const isCreated = await this.isTrackerCreate(TRAKERSTYPECODEREID.croTag1);
      if (isCreated) {
        this.updateTracker(this.formatTracker(this.targetPromo, TrackerValue.DECLINED));
      } else {
        this.createTracker(this.formatTracker(this.targetPromo, TrackerValue.DECLINED));
      }
    } catch (error) {
      console.info(error);
    }

    this.closeModal();
  }

  async recallLater() {
    try {
      const isCreated = await this.isTrackerCreate(TRAKERSTYPECODEREID.croTag1);
      if (isCreated) {
        this.updateTracker(this.formatTracker(this.targetPromo, TrackerValue.ASKMELATER));
      } else {
        this.createTracker(this.formatTracker(this.targetPromo, TrackerValue.ASKMELATER));
      }
    } catch (error) {
      console.info(error);
    }

    this.closeModal();
  }

  formatTracker(target: TargetOptInModel, action: string): TrackersCodereId {
    return {
      value: target.bonusID + action,
      trackType: TRAKERSTYPECODEREID.croTag1,
    };
  }

  async updateTracker(tracker: TrackersCodereId) {
    try {
      const observer = this.globalVars.FEATURES.MSO_DecouplingTrackersCodereId
        ? this.decouplingUserServiceMSO.updateTrackersCodereID(
            [tracker].map(({ value, trackType }) => ({ code: value, trackerType: trackType }))[0],
          )
        : this.userService.updateTrackersCurrentUser([tracker]);
      const response = await firstValueFrom(observer);
    } catch (error) {
      console.info(error);
    }
  }

  async createTracker(tracker: TrackersCodereId) {
    try {
      const observer = this.globalVars.FEATURES.MSO_DecouplingTrackersCodereId
        ? this.decouplingUserServiceMSO.createTrackersCodereID(
            [tracker].map(({ value, trackType }) => ({ code: value, trackerType: trackType }))[0],
          )
        : this.userService.createTrackersCodereId([tracker]);
      const response = await firstValueFrom(observer);
    } catch (error) {
      console.info(error);
    }
  }

  async isTrackerCreate(trackType: number) {
    try {
      const observer = this.globalVars.FEATURES.MSO_DecouplingTrackersCodereId
        ? this.decouplingUserServiceMSO.getTrackersCodereID()
        : this.userService.getTrackersCurrentUser();
      const activeTrackers: TrackersCodereId[] = await firstValueFrom(observer);
      const tracker = activeTrackers.find((t) => t.trackType === trackType);
      return !!tracker;
    } catch (error) {
      console.info(error);
    }
  }

  async closeandgoPromotions() {
    this.utils.loader();
    const url = this.targetPromo.termsLink.split("?url=");
    let page = `${url[1]}`;
    const userInfo: I_UserInfo = JSON.parse(
      this.globalVars.rootScope.cookiesService.getCookie(CookieNames.UserInfo),
    );
    let ispromoVip: boolean;
    if (userInfo) {
      ispromoVip = userInfo.ispromotablevip;
    } else {
      const dataresponse = await firstValueFrom(this.userService.getIsPromotionable(true));
      ispromoVip = dataresponse.isPromotable;
    }
    page += `?isPromotableVIP=${ispromoVip}`;
    this.userService.getPromotionsList().subscribe((resp) => {
      const promoList = resp.promotions.map((item) => ({ promoId: item.promoId }));
      page += "&promoList=" + encodeURI(JSON.stringify(promoList));

      this.userService.getIsPromotionable().subscribe((resp2) => {
        this.utils.closeLoader();
        page += "&promoData=" + encodeURI(JSON.stringify(resp2));
        // this.globalVars.rootScope.tryToGoPage(page);
        this.router.navigate([`${MSO_PATHS.BASE}/${MSO_PATHS.PromotionsPage}`], { queryParams: { url: page } });
        this.closeModal();
      });
    });
  }

  onTyC() {
    localStorage.setItem("targetOpInOpen", JSON.stringify({ open: true }));
  }
}
