export class StoreConstants {
  static ST_SBS_CalculationBaseReferenceEventNodeNotActive = "Mercado no disponible";
  static SingleStakeLimits = 2000000000;
  static SystemStakeLimits = 2000000000;

  static findErrorCode(existItemId) {
    const errorCodes = ["ST_SBS_CalculationBaseReferenceEventNodeNotActive"];
    const errorCodeFound = errorCodes.find((i) => i === existItemId.ErrorCode);
    return errorCodeFound;
  }
}
