import { IonicModule, NavController } from '@ionic/angular';
import { Component, inject } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TrackingService } from '../../../providers/TrackingService';

import { Utils } from '../../../utils/Utils';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DeviceService } from '../../../services/device.service';
import { GlobalVarsModel } from '../../../models/ModelGlobalsVar';
import { MSO_PATHS } from '../../../lib/constants/routes';
import { NewBaseService } from '../../../providers/newBase.service';
import { UserSectionTitle } from '../../../components/mso/user-section-title/user-section-title';
import { CodereFooterComponent } from '../../../components/mso/footer/codere-footer/codere-footer';
import { Router } from '@angular/router';

@Component({
  standalone: true,
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'my-card-recover-page',
  templateUrl: './my-card-recover.html',
  styleUrls: ['./my-card-recover.scss'],
  imports: [
    IonicModule,
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    UserSectionTitle,
    CodereFooterComponent
  ]
})
export class MyCardRecoverPage {
  globalVars!: GlobalVarsModel;
  isMobile: boolean = false;

  nav = inject(NavController);
  trackingService = inject(TrackingService);
  utils = inject(Utils);
  translate = inject(TranslateService);
  newBaseService = inject(NewBaseService);
  router = inject(Router);
  device = inject(DeviceService);

  constructor() {
    this.isMobile = this.device.isMobile();

    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
  }

  ionViewWillEnter() {
    this.router.navigate([MSO_PATHS.BASE + '/' + MSO_PATHS.MyCardRecoverPage]);
    this.trackingService.trackEvent(['accessToRecoverCard', '', '', 'Acceder Recuperar Tarjeta', 'event']);
  }

  gotoPin() {
    this.router.navigate([MSO_PATHS.BASE + '/' + MSO_PATHS.MyCardPinPage]), { animate: !this.device.isDesktop() };
  }

  gotoMap() {
    this.router.navigate([MSO_PATHS.BASE + '/' + MSO_PATHS.NearestLocalPage]), { animate: !this.device.isDesktop() };
  }

  gotoRecover2() {
    this.router.navigate([MSO_PATHS.BASE + '/' + MSO_PATHS.MyCardRecover2Page]), { animate: !this.device.isDesktop() };
  }
}
