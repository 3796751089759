import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { ErrorMsgs } from '@interfaces/interfaces';
import { ControlErrorComponent } from '../control-error/control-error.component';
import { InfoComponent } from '../info/info.component';

@Component({
  standalone: true,
  imports: [CommonModule, IonicModule, ReactiveFormsModule, ControlErrorComponent, InfoComponent, TranslateModule],
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'cdr-select-info',
  templateUrl: './select-info.component.html',
  styleUrls: ['./select-info.component.scss']
})
export class SelectInfoComponent implements OnInit {
  @Input() label: string;
  @Input() errorsCol: ErrorMsgs;
  @Input() options: Array<any>;
  @Input() fcName: string;
  @Input() fgrp: FormGroup;
  @Input() modal: string;
  @Input() placeHolder: string;
  fControl: FormControl;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}

  ngOnInit() {
    this.fControl = this.fgrp?.get(this.fcName) as FormControl;
  }
}
