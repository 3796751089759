<div class="custom-keyboard">
  <div class="custom-keyboard--actions" data-tap-disabled="true">
    <ng-container data-tap-disabled="true">
      <button class="custom-keyboard--button" tappable (click)="btKeyBoard_Click('1')">1</button>
      <button class="custom-keyboard--button" tappable (click)="btKeyBoard_Click('4')">4</button>
      <button class="custom-keyboard--button" tappable (click)="btKeyBoard_Click('7')">7</button>
      <button [disabled]="disabledDot" class="custom-keyboard--button" tappable (click)="btKeyBoard_Click('.')">
        .
      </button>
    </ng-container>
  </div>
  <div class="custom-keyboard--actions" data-tap-disabled="true">
    <ng-container data-tap-disabled="true">
      <button class="custom-keyboard--button" tappable (click)="btKeyBoard_Click('2')">2</button>
      <button class="custom-keyboard--button" tappable (click)="btKeyBoard_Click('5')">5</button>
      <button class="custom-keyboard--button" tappable (click)="btKeyBoard_Click('8')">8</button>
      <button class="custom-keyboard--button" tappable (click)="btKeyBoard_Click('0')">0</button>
    </ng-container>
  </div>
  <div class="custom-keyboard--actions" data-tap-disabled="true">
    <ng-container data-tap-disabled="true">
      <button class="custom-keyboard--button" tappable (click)="btKeyBoard_Click('3')">3</button>
      <button class="custom-keyboard--button" tappable (click)="btKeyBoard_Click('6')">6</button>
      <button class="custom-keyboard--button" tappable (click)="btKeyBoard_Click('9')">9</button>
      <button class="custom-keyboard--button" tappable (click)="btKeyBoard_Click('del')">
        <i class="codere-icon icon-arrow-left"></i>
      </button>
    </ng-container>
  </div>

  <div class="custom-keyboard--actions" data-tap-disabled="true">
    <ng-container *ngIf="buttons?.length" data-tap-disabled="true">
      <button
        class="custom-keyboard--button"
        *ngFor="let button of buttons | slice:0:2"
        tappable
        (click)="btKeyBoard_Click(button,true)"
      >
        {{button | parseStakeMoney:[1]}}
      </button>
    </ng-container>
    <ng-container data-tap-disabled="true">
      <button class="custom-keyboard--button-close" *ngIf="!buttons?.length" tappable (click)="btCancel_Click()">
        Cerrar
      </button>
      <button
        class="custom-keyboard--button-ok"
        [class.has-custom-buttons]="buttons?.length"
        tappable
        (click)="btOk_Click()"
      >
        Ok
      </button>
    </ng-container>
  </div>
</div>
