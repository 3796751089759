export class SmartLineItem {
  Amount: number;
  AmountLocal: number;
  BetType?: any;
  CountryId: string;
  Date?: any;
  Description: string;
  downOdd: boolean;
  EventDate: string;
  EventId: string;
  EventName: string;
  FreebetInstance: any;
  FreeBetVoucherId: string;
  GameId: string;
  GameLocked?: boolean;
  GameName: string;
  GameSpecialOddsValue: string;
  GreyhoundHorseBetType?: any;
  GreyhoundHorses: boolean;
  Handicap?: any;
  HasPrice: boolean;
  Id: string;
  Index: number;
  IsBanked: boolean;
  IsBet: boolean;
  IsLive: boolean;
  IsSelected: boolean;
  ItemId?: number;
  LineErrorMessages: Array<string>;
  LineErrors: any;
  LineItem: any;
  LineItemStatus: number; //enum
  LiveStatus: boolean;
  Name: string;
  NodeId: any;
  NotAvailable?: boolean;
  Odd: number; //decimal
  Ordered?: any;
  Paddock?: any;
  Participants?: any;
  PendingCheck: boolean;
  PersonalizedFreeBet: boolean;
  PipelineExecutionResult: number; //enum_PipelineExecutionResult;
  Place: number; //es un enum
  PotentialWin?: number; //decimal
  Price: number; //decimal
  ResultsNr: string;
  ResultName: string;
  Selected: boolean = false;
  SmartLineItems: {
    Name: string;
    GameName: string;
  }[];
  SpecialOddsValue: string;
  SportHandle: string;
  Status?: any;
  Tax?: any;
  TicketNumber?: string;
  TicketType: number;
  TotalBets: number;
  TotalPotentialWin: number;
  TotalPotentialWinWithoutBoost: number;
  Type: number;
  upOdd: boolean;
  BetBuilderId: string;

  constructor() {
    this.AmountLocal = this.Amount;
    this.GreyhoundHorses = false;
    this.IsBet = false;
    this.IsLive = false;
    this.IsSelected = true;
    this.LineErrorMessages = [];
    this.Selected = false;
  }

  static createSmartLineItem(items, market, odds) {
    const smartLineItem = new SmartLineItem();
    smartLineItem.EventName = market.Name;
    smartLineItem.IsLive = market.isLive;
    smartLineItem.Odd = parseFloat(odds);
    smartLineItem.SmartLineItems = [...items];
    smartLineItem.Amount = 1;
    smartLineItem.AmountLocal = 1;
    smartLineItem.TotalPotentialWin = 0;
    return smartLineItem;
  }

  static mapSmartLineItem(item) {
    const smartLineItem = new SmartLineItem();

    smartLineItem.EventId = item[0].EventId;
    smartLineItem.GameId = item[0].GameId;
    smartLineItem.SpecialOddsValue = item[0].GameSpecialOddsValue;
    smartLineItem.Odd = item[0].Odd;
    smartLineItem.upOdd = item[0].upOdd;
    smartLineItem.downOdd = item[0].downOdd;
    smartLineItem.Amount = 1;
    smartLineItem.AmountLocal = 1;
    smartLineItem.TotalPotentialWin = 0;
    smartLineItem.ResultsNr = item[0].NodeId;
    smartLineItem.ResultName = '';
    smartLineItem.SportHandle = item[0].SportHandle;
    smartLineItem.EventDate = item[0].EventDate;
    smartLineItem.EventName = item[0].EventName;
    smartLineItem.Place = item[0].Place;
    smartLineItem.SmartLineItems = [...item];
    return smartLineItem;
  }
}
