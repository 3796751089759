/* eslint-disable eqeqeq */
import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'genericObjectFilter'
})
@Injectable({ providedIn: 'root' })
export class genericObjectFilter implements PipeTransform {
  transform(arr, args) {
    const key: string = args[0];
    const val: string = args[1];

    return arr.filter((elem) => elem[key] == val);
  }
}
