<ng-container *ngIf="!isMobile; else mobileView">
  <ion-content class="has-titleBar background-color-muted">
    <user-section-title
      [sectionTitle]="'uPJRLDep'"
      *ngIf="globalVars.licenseType === 0 || globalVars.licenseType === 21"
    ></user-section-title>
    <user-section-title
      [sectionTitle]="'uPPLimits'"
      *ngIf="globalVars.licenseType === 22 || utils.isArgentina()"
    ></user-section-title>
    <div class="is-narrow-content">
      <ion-list padding class="listLimits">
        <!-- Límites de deposito -->
        <ion-grid class="myWrapInput is-row-grid">
          <div class="limitsInputs" *ngIf="utils.isArgentina() || globalVars.licenseType === 22">
            <div>
              <strong>{{ 'uPJRLDep' | translate | uppercase}}</strong
              ><!--Límites de depósito-->
            </div>
            <div *ngIf="utils.isArgentina()">({{ 'limitsDownward' | translate }})</div>
          </div>
          <ion-row>
            <ion-col col4
              ><!--LÍMITE DIARIO-->
              <div class="limitsInputs">
                <div class="labelInputs">{{ 'uPPLimitsDay' | translate | uppercase}}<!--LÍMITE DIARIO--></div>

                <ion-item class="cdr-item-input ion-item-limits" lines="none">
                  <ion-input
                    *ngIf="!utils.isArgentina()"
                    labelPlacement="floating"
                    type="tel"
                    [(ngModel)]="limits.day"
                    [maxlength]="maxlength"
                    [minlength]="minlength"
                    autocomplete="off"
                    autoCorrect="off"
                    spellcheck="off"
                  >
                  </ion-input>
                  <ion-input
                    *ngIf="utils.isArgentina()"
                    labelPlacement="floating"
                    type="text"
                    inputmode="numeric"
                    pattern="[0-9]*"
                    [(ngModel)]="limitsModel.day"
                    (input)="onInputLimits('day')"
                    [maxlength]="maxlength"
                    [minlength]="minlength"
                    autocomplete="off"
                    autoCorrect="off"
                    spellcheck="off"
                  >
                  </ion-input>
                </ion-item>
              </div>
            </ion-col>
            <ion-col col4
              ><!--LÍMITE SEMANAL-->
              <div class="limitsInputs">
                <div class="labelInputs">{{ 'uPPLimitsWeek' | translate | uppercase}}<!--LÍMITE SEMANAL--></div>

                <ion-item class="cdr-item-input ion-item-limits" lines="none">
                  <ion-input
                    *ngIf="!utils.isArgentina()"
                    labelPlacement="floating"
                    type="tel"
                    [(ngModel)]="limits.week"
                    [maxlength]="maxlength"
                    [minlength]="minlength"
                    autocomplete="off"
                    autoCorrect="off"
                    spellcheck="off"
                  >
                  </ion-input>

                  <ion-input
                    *ngIf="utils.isArgentina()"
                    labelPlacement="floating"
                    type="text"
                    inputmode="numeric"
                    pattern="[0-9]*"
                    [(ngModel)]="limitsModel.week"
                    (input)="onInputLimits('week')"
                    [maxlength]="maxlength"
                    [minlength]="minlength"
                    autocomplete="off"
                    autoCorrect="off"
                    spellcheck="off"
                  >
                  </ion-input>
                </ion-item>
              </div>
            </ion-col>
            <ion-col col4
              ><!--LÍMITE MENSUAL-->
              <div class="limitsInputs">
                <div class="labelInputs">{{ 'uPPLimitsMounth' | translate | uppercase}}<!--LÍMITE MENSUAL--></div>
                <ion-item class="cdr-item-input ion-item-limits" lines="none">
                  <ion-input
                    *ngIf="!utils.isArgentina()"
                    labelPlacement="floating"
                    type="tel"
                    [(ngModel)]="limits.month"
                    [maxlength]="maxlength"
                    [minlength]="minlength"
                    autocomplete="off"
                    autoCorrect="off"
                    spellcheck="off"
                  >
                  </ion-input>

                  <ion-input
                    *ngIf="utils.isArgentina()"
                    labelPlacement="floating"
                    type="text"
                    inputmode="numeric"
                    pattern="[0-9]*"
                    [(ngModel)]="limitsModel.month"
                    (input)="onInputLimits('month')"
                    [maxlength]="maxlength"
                    [minlength]="minlength"
                    autocomplete="off"
                    autoCorrect="off"
                    spellcheck="off"
                  >
                  </ion-input>
                </ion-item>
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>
        <!-- Actualizar -->
        <div class="sendForm">
          <ion-button fill="solid" mode="md" (click)="sendLimits()">
            {{ 'tInPUpd' | translate }}
            <!-- Actualizar -->
          </ion-button>
        </div>
        <!-- Límites de sesión (SOLO ARGENTINA y PÁNAMA y PBA) -->
        <ion-grid class="myWrapInput is-row-grid" *ngIf="globalVars.licenseType === 22 || utils.isArgentina()">
          <div class="limitsInputs">
            <strong>{{ 'uPJRLSes' | translate | uppercase}}</strong> ({{ 'Casino' | translate | uppercase}})
            <!--Límites de sesión-->
          </div>
          <ion-row>
            <ion-col col4>
              <!-- Gasto -->
              <div class="limitsInputs">
                <div class="labelInputs">
                  {{ 'uPJRLGast' | translate | uppercase}}
                  <!--LÍMITE DE GASTO-->
                </div>

                <ion-item class="cdr-item-input ion-item-limits" lines="none">
                  <ion-input
                    labelPlacement="floating"
                    type="text"
                    inputmode="numeric"
                    pattern="[0-9]*"
                    [(ngModel)]="sesionModel.gasto"
                    (input)="onInputSesion('gasto')"
                    [maxlength]="maxlength"
                    [minlength]="minlength"
                    autocomplete="off"
                    autoCorrect="off"
                    spellcheck="off"
                  >
                  </ion-input>
                </ion-item>
              </div>
            </ion-col>
            <ion-col col4>
              <!-- Perdida -->
              <div class="limitsInputs">
                <div class="labelInputs">
                  {{ 'uPJRLPer' | translate | uppercase}}
                  <!--LÍMITE DE PERDIDA-->
                </div>

                <ion-item class="cdr-item-input ion-item-limits" lines="none">
                  <ion-input
                    labelPlacement="floating"
                    type="text"
                    inputmode="numeric"
                    pattern="[0-9]*"
                    [(ngModel)]="sesionModel.perdida"
                    (input)="onInputSesion('perdida')"
                    [maxlength]="maxlength"
                    [minlength]="minlength"
                    autocomplete="off"
                    autoCorrect="off"
                    spellcheck="off"
                  >
                  </ion-input>
                </ion-item>
              </div>
            </ion-col>
            <ion-col col4>
              <!-- Tiempo de sesión -->
              <div class="limitsInputs limits-imputs-div">
                <div class="labelInputs">{{ 'sessiontime' | translate | uppercase}}<!--TIEMPO DE SESION--></div>
                <ion-item class="ion-item-limits" lines="none">
                  <ion-select
                    [(ngModel)]="sesion.tiempo"
                    class="custom-icon-select"
                    toggleIcon="chevron-down-outline"
                    justify="space-between"
                  >
                    <ion-select-option *ngFor="let item of sessionTimes" [value]="item.value">
                      {{item.desc}}
                    </ion-select-option>
                  </ion-select>
                  <!-- <ion-icon name="chevron-down-outline" class="custom-chevron-icon"></ion-icon> -->
                </ion-item>
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>
        <!-- Actualizar -->
        <div class="sendForm" *ngIf="globalVars.licenseType === 22 || utils.isArgentina()">
          <ion-button mode="md" fill="solid" (click)="sendSessionLimits()">
            {{ 'tInPUpd' | translate }}
            <!-- Actualizar -->
          </ion-button>
        </div>
      </ion-list>
      <p class="listinfoh3" *ngIf="!utils.isArgentina()">
        {{ 'v2_infLimit' | translate }}<!--# INFORMACIÓN SOBRE LÍMITES-->
      </p>
      <ul class="listInfo" *ngIf="!utils.isArgentina()">
        <li>
          <b>•</b>{{ 'v2_errorLimite' | translate }}<!--# No se puede establecer un límite mayor al indicado en cada caso.-->
        </li>
        <li *ngIf="globalVars.licenseType != 0 && globalVars.licenseType != 1 && !utils.isArgentina()">
          <b>•</b>{{ 'v2_cambioLimite' | translate }}
        </li>
        <!--# El doble cambio de límite no es inmediato, se hará efectivo en 48 horas.-->
      </ul>
    </div>
    <codere-footer></codere-footer>
  </ion-content>
</ng-container>

<ng-template #mobileView>
  <ion-header>
    <user-section-title
      [sectionTitle]="'uPJRLDep'"
      *ngIf="globalVars.licenseType == 0 || globalVars.licenseType == 21"
    ></user-section-title>
    <user-section-title
      [sectionTitle]="'uPPLimits'"
      *ngIf="globalVars.licenseType == 22 || utils.isArgentina()"
    ></user-section-title>
  </ion-header>
  <ion-content class="background-color-muted">
    <ion-list padding class="user-list">
      <div class="myWrapInput">
        <!-- Límites de deposito -->
        <div>
          <div class="user-input-container" *ngIf="utils.isArgentina()|| globalVars.licenseType == 22">
            <div class="bold">{{ 'uPJRLDep' | translate | uppercase}}<!--Límites de depósito--></div>
            <div *ngIf="utils.isArgentina()">({{ 'limitsDownward' | translate }})</div>
          </div>
          <!--LÍMITE DIARIO-->
          <div class="user-input-container">
            <h2 class="limits-input-label">{{ 'uPPLimitsDay' | translate }}<!--LÍMITE DIARIO--></h2>
            <ion-item class="cdr-item-input ion-item-limits" lines="none">
              <ion-input
                *ngIf="!utils.isArgentina()"
                labelPlacement="floating"
                type="tel"
                [(ngModel)]="limits.day"
                [maxlength]="maxlength"
                [minlength]="minlength"
                autocomplete="off"
                autoCorrect="off"
                spellcheck="off"
              >
              </ion-input>
              <ion-input
                *ngIf="utils.isArgentina()"
                type="text"
                inputmode="numeric"
                pattern="[0-9]*"
                [(ngModel)]="limitsModel.day"
                (input)="onInputLimits('day')"
                [maxlength]="maxlength"
                [minlength]="minlength"
                labelPlacement="floating"
                autocomplete="off"
                autoCorrect="off"
                spellcheck="off"
              >
              </ion-input>
            </ion-item>
          </div>
          <!--LÍMITE SEMANAL-->
          <div class="user-input-container">
            <h2 class="limits-input-label">{{ 'uPPLimitsWeek' | translate }}<!--LÍMITE SEMANAL--></h2>
            <ion-item class="cdr-item-input ion-item-limits" lines="none">
              <ion-input
                *ngIf="!utils.isArgentina()"
                type="tel"
                [(ngModel)]="limits.week"
                [maxlength]="maxlength"
                [minlength]="minlength"
                labelPlacement="floating"
                autocomplete="off"
                autoCorrect="off"
                spellcheck="off"
              ></ion-input>
              <ion-input
                *ngIf="utils.isArgentina()"
                type="text"
                inputmode="numeric"
                pattern="[0-9]*"
                [(ngModel)]="limitsModel.week"
                (input)="onInputLimits('week')"
                [maxlength]="maxlength"
                [minlength]="minlength"
                labelPlacement="floating"
                autocomplete="off"
                autoCorrect="off"
                spellcheck="off"
              >
              </ion-input>
            </ion-item>
          </div>
          <!--LÍMITE MENSUAL-->
          <div class="user-input-container">
            <h2 class="limits-input-label">{{ 'uPPLimitsMounth' | translate }}<!--LÍMITE MENSUAL--></h2>
            <ion-item class="cdr-item-input ion-item-limits" lines="none">
              <ion-input
                *ngIf="!utils.isArgentina()"
                type="tel"
                [(ngModel)]="limits.month"
                [maxlength]="maxlength"
                [minlength]="minlength"
                labelPlacement="floating"
                autocomplete="off"
                autoCorrect="off"
                spellcheck="off"
              >
              </ion-input>

              <ion-input
                *ngIf="utils.isArgentina()"
                type="text"
                inputmode="numeric"
                pattern="[0-9]*"
                [(ngModel)]="limitsModel.month"
                (input)="onInputLimits('month')"
                [maxlength]="maxlength"
                [minlength]="minlength"
                labelPlacement="floating"
                autocomplete="off"
                autoCorrect="off"
                spellcheck="off"
              >
              </ion-input>
            </ion-item>
          </div>
        </div>
        <!-- Actualizar -->
        <div class="sendForm">
          <ion-button mode="md" fill="solid" (click)="sendLimits()">
            {{ 'tInPUpd' | translate }}
            <!-- Actualizar -->
          </ion-button>
        </div>
        <!-- Límites de sesión (SOLO PANAMÁ Y ARGENTINA y PBA) -->
        <div *ngIf="globalVars.licenseType === 22 || utils.isArgentina()">
          <div class="user-input-container bold">
            {{ 'uPJRLSes' | translate | uppercase}}
            <!--Límites de sesión-->
          </div>
          <!-- Gasto -->
          <div class="user-input-container">
            <h2 class="limits-input-label">
              {{ 'uPJRLGast' | translate }}
              <!--LÍMITE DE GASTO-->
            </h2>
            <ion-item class="cdr-item-input ion-item-limits" lines="none">
              <ion-input
                type="text"
                inputmode="numeric"
                pattern="[0-9]*"
                [(ngModel)]="sesionModel.gasto"
                (input)="onInputSesion('gasto')"
                [maxlength]="maxlength"
                [minlength]="minlength"
                labelPlacement="floating"
                autocomplete="off"
                autoCorrect="off"
                spellcheck="off"
              >
              </ion-input>
            </ion-item>
          </div>
          <!-- Perdida -->
          <div class="user-input-container">
            <h2 class="limits-input-label">
              {{ 'uPJRLPer' | translate }}
              <!--LÍMITE DE PERDIDA-->
            </h2>
            <ion-item class="cdr-item-input ion-item-limits" lines="none">
              <ion-input
                type="text"
                inputmode="numeric"
                pattern="[0-9]*"
                [(ngModel)]="sesionModel.perdida"
                (input)="onInputSesion('perdida')"
                [maxlength]="maxlength"
                [minlength]="minlength"
                labelPlacement="floating"
                autocomplete="off"
                autoCorrect="off"
                spellcheck="off"
              >
              </ion-input>
            </ion-item>
          </div>
          <!-- Tiempo de sesión -->
          <div class="user-input-container">
            <h2 class="limits-input-label">{{ 'sessiontime' | translate }}<!--TIEMPO DE SESION--></h2>
            <ion-item class="cdr-item-input ion-item-limits" lines="none">
              <ion-select
                [(ngModel)]="sesion.tiempo"
                class="custom-icon-select"
                toggleIcon="chevron-down-outline"
                justify="space-between"
              >
                <ion-select-option *ngFor="let item of sessionTimes" [value]="item.value">
                  {{item.desc}}
                </ion-select-option>
                <!-- <ion-icon name="chevron-down-outline" class="custom-chevron-icon"></ion-icon> -->
              </ion-select>
            </ion-item>
          </div>
        </div>
        <!-- Actualizar -->
        <div class="sendForm" *ngIf="globalVars.licenseType === 22 || utils.isArgentina()">
          <ion-button mode="md" fill="solid" (click)="sendSessionLimits()">
            {{ 'tInPUpd' | translate }}
            <!-- Actualizar -->
          </ion-button>
        </div>
      </div>
    </ion-list>

    <h3 class="listinfoh3" *ngIf="!utils.isArgentina()">
      {{ 'v2_infLimit' | translate }}<!--# INFORMACIÓN SOBRE LÍMITES-->
    </h3>
    <ul class="listInfo" *ngIf="!utils.isArgentina()">
      <li><b>•</b>{{ 'v2_errorLimite' | translate }}</li>
      <li *ngIf="globalVars.licenseType != 0 && globalVars.licenseType != 1 && !utils.isArgentina()">
        <b>•</b>{{ 'v2_cambioLimite' | translate }}
      </li>
    </ul>
    <codere-footer></codere-footer>
  </ion-content>
</ng-template>
