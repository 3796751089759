/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable, inject } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, delay, finalize, tap } from 'rxjs/operators';

import { LoaderService } from '@services/loader-feature/loader.service';
import { NewBaseService } from '@providers/newBase.service';
import { GlobalVarsModel } from '@models/ModelGlobalsVar';
import { Utils } from '@utils/Utils';

const LoaderInterceptorShowUrl: string[] = [
  'RemoveItem',
  'additembyid',
  'logOff',
  'login/web',
  'GetCountries',
  'getticketsbydate',
  'singleResult',
  'betslip/clear',
  'bonus/voucher',
  'clearOrder',
  'GetCategoriesByLeague',
  'closebet',
  'TicketsAndCloseBet',
  'ReactivateAccount',
  'Signup',
  'SummaryPlayerServicesApi'
];

@Injectable({ providedIn: 'root' })
export class LoaderInterceptor implements HttpInterceptor {
  private counter = 0;
  globalVars!: GlobalVarsModel;

  //#region Inject Services
  loaderService = inject(LoaderService);
  utils = inject(Utils);
  newBaseService = inject(NewBaseService);
  //#endregion
  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const showLoader = this.checkUrl(req.url);

    if (showLoader) {
      // todo
      // this.utils.loader();
      this.counter++;
    }

    return next.handle(req).pipe(
      delay(0),
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.onEnd(event);
        }
      }),
      catchError((error) => {
        this.onEnd(error, true);
        return this.handleError(error);
      }),
      finalize(() => {
        if (this.counter <= 0) {
          this.utils.closeLoader();
          this.counter = 0;
        }
      })
    );
  }

  private onEnd(event: any, isError: boolean = false): void {
    if (isError) {
      this.counter = 0;
    }

    if (event.url) {
      const hide = this.checkUrl(event.url);

      if (
        hide &&
        !event.url.toLowerCase().includes('orderform/closebet') &&
        !event.url.toLowerCase().includes('/betplacement/closebet')
      ) {
        this.counter--;
        this.hideLoader();
      }
    } else {
      this.counter--;
      this.hideLoader();
    }
  }

  private hideLoader(): void {
    if (this.counter > 0) {
      this.utils.closeLoader();
    }
  }

  private checkUrl(url: string): boolean {
    return LoaderInterceptorShowUrl.some((element) => url.toLowerCase().includes(element.toLowerCase()));
  }

  private handleError(error: any): Observable<never> {
    return throwError(() => error);
  }
}
