<section
  *ngIf="item"
  [ngClass]="{
  'lobby': theme === 'lobby',
  'sportbook': theme === 'sportbook',
  }"
  [attr.accessibilityLabel]="item?.label"
>
  <div
    class="content"
    [class.color-light]="!item?.selected"
    [class.primary]="item?.selected"
    [class.color-primary]="item?.selected && !globalVars.FEATURES?.MSO_NewReBranding && theme === 'lobby'"
    tappable
    (click)="selectItem?.emit(item?.navigate)"
    [attr.accessibilityLabel]="item?.label"
    [ngClass]="{'rebranding': globalVars.FEATURES?.MSO_NewReBranding}"
  >
    <div class="image" [attr.accessibilityLabel]="item?.label">
      <img class="img" *ngIf="imageSrc" [src]="imageSrc" [alt]="item?.label" [attr.accessibilityLabel]="item?.label" />
      <ion-icon class="icon codere-icon" *ngIf="icon" [ngClass]="icon" role="img" name="options-outline"></ion-icon>
    </div>
    <p class="subtitle">{{ item?.label }}</p>
  </div>
</section>
