import { Component, inject } from "@angular/core";
import { CodereFooterComponent } from "@components/mso/footer/codere-footer/codere-footer";
import { UserSectionTitle } from "@components/mso/user-section-title/user-section-title";
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { IonicModule, NavController } from "@ionic/angular";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ParseMoney } from "@pipes/parseMoney";
import { BalancesService } from "@providers/BalancesService";
import { TrackingService } from "@providers/TrackingService";
import { NewBaseService } from "@providers/newBase.service";
import { DeviceService } from "@services/device.service";
import { MSO_PATHS } from "@shared-constants/routes";

import { CommonModule } from "@angular/common";
import { Router } from "@angular/router";
import { ExtractDatePipe } from "@pipes/extractDate.pipe";
import { Utils } from "@utils/Utils";

@Component({
  standalone: true,
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "my-card-page",
  templateUrl: "./my-card.html",
  styleUrls: ["./my-card.scss"],
  imports: [
    IonicModule,
    CommonModule,
    TranslateModule,
    UserSectionTitle,
    ParseMoney,
    ExtractDatePipe,
    CodereFooterComponent,
  ],
})
export class MyCardPage {
  cardData: any;
  globalVars!: GlobalVarsModel;
  isMobile: boolean = false;

  nav = inject(NavController);
  trackingService = inject(TrackingService);
  utils = inject(Utils);
  translate = inject(TranslateService);
  newBaseService = inject(NewBaseService);
  balancesService = inject(BalancesService);
  router = inject(Router);
  device = inject(DeviceService);

  constructor() {
    this.isMobile = this.device.isMobile();
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });

    this.cardData = {
      cardnumber: null,
      cardBalance: null,
      date: null,
      state: null,
      cardRegion: null,
    };
  }

  showBarcode() {
    this.utils.showCodereCardBarCode(this.cardData.cardnumber);
  }

  updateCard() {
    this.balancesService.getClientCardBalance().subscribe(
      (data) => {
        if (data.success) {
          this.cardData = data;
          this.cardData.cardRegion = data.cardnumber.substr(2, 2);
          this.globalVars.user.codereCard.balance = data.cardBalance;
          this.globalVars.user.codereCard.date = data.date;
          this.globalVars.user.codereCard.cardNumber = data.cardnumber;
          this.globalVars.user.codereCard.region = this.cardData.cardRegion;
          this.globalVars.user.codereCard.active = this.cardData.state === "Activa";
        } else {
          this.utils.showError(this.translate.instant("GetCardAccessActiveKO"));
        }
      },
      () => {
        //error
        this.utils.showError(this.translate.instant("GetCardAccessActiveKO"));
      },
    );
  }

  ionViewWillEnter() {
    this.router.navigate([MSO_PATHS.BASE + "/" + MSO_PATHS.MyCardPage]);
    this.trackingService.trackEvent(["accessToCheckCodereCard", "", "", "Acceder Tarjeta Codere", "event"]);
    this.updateCard();
  }

  navToPin() {
    this.router.navigate([MSO_PATHS.BASE + "/" + MSO_PATHS.MyCardPinPage]), { animate: !this.device.isDesktop() };
  }

  navToRecover() {
    this.router.navigate([MSO_PATHS.BASE + "/" + MSO_PATHS.MyCardRecoverPage]),
      { animate: !this.device.isDesktop() };
  }

  navToUnlock() {
    this.router.navigate([MSO_PATHS.BASE + "/" + MSO_PATHS.MyCardUnlockPage]),
      { animate: !this.device.isDesktop() };
  }
}
