import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [CommonModule, IonicModule, TranslateModule],
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'cdr-info-top',
  templateUrl: './info-top.component.html',
  styleUrls: ['./info-top.component.scss']
})
export class InfoTopComponent {
  @Input() isMobile = true;
  target: string;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}
}
