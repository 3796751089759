import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

interface SubsidiaryInterface {
  name: string;
  address: string;
  map: string;
}

@Component({
  selector: 'cs-crown-casino',
  templateUrl: './cs-crown-casino.html',
  styleUrls: ['./cs-crown-casino.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule]
})
export class CsCrownCasinoComponent implements OnInit {
  @Input() isDeposit?: boolean = true;
  subsidiaries: SubsidiaryInterface[];
  transactionTypeLabel = 'depósito';

  constructor() {
    this.subsidiaries = [
      {
        name: 'Sheraton Grand Panamá',
        address: 'Vía Israel y Calle 77',
        map: 'https://goo.gl/maps/ce3h6s4S3h4TmNxZ9'
      },
      {
        name: 'Hotel El Panamá',
        address: 'Calle Eusebio A. Morales &, Av. Central España Edificio 111',
        map: 'https://goo.gl/maps/yBCh5U4rAEAR9EZ46'
      },
      {
        name: 'Hotel Faranda Express Soloy & Casino',
        address: 'Avenida Perú, 30',
        map: 'https://goo.gl/maps/PnvdoFwqr6Xrcjas9'
      },
      {
        name: 'Hotel Riande Aeropuerto',
        address: 'Tocumen, entre la carretera Pan Americana y Av. Domingo Diaz',
        map: 'https://goo.gl/maps/YzBVh3UAxY13baCf6'
      },
      {
        name: 'Hipódromo Presidente Remón',
        address: 'Vía José Agustín Arango, Juan Díaz',
        map: 'https://goo.gl/maps/UYdQNo6drQeGj8kBA'
      },
      {
        name: 'Radisson Colon 2000 Hotel & Casino',
        address: 'Calle 13 &, P.º Gorgas, Colón',
        map: 'https://goo.gl/maps/i8QEbKGuKeWRnghC6'
      },
      {
        name: 'Hotel Faranda Guayacanes Chitré',
        address: 'Vía Circunvalación, Chitré',
        map: 'https://goo.gl/maps/QvYXCv5UrkzmxYfz8'
      },
      {
        name: 'Hotel Plaza Mirage',
        address: 'C. F Sur, David',
        map: 'https://goo.gl/maps/o5WUa1APkrBRSdHR9'
      },
      {
        name: 'Hotel Nacional',
        address: 'Calle 1era, David, Chiriquí',
        map: 'https://goo.gl/maps/nzKVehXaPUBTT7By9'
      },
      {
        name: 'Crown Casino Chorrera',
        address: 'Av. Libertador, La Chorrera, Panamá',
        map: 'https://goo.gl/maps/ZrTjB6hXzoZjDBUq5'
      }
    ];
  }
  ngOnInit(): void {
    if (!this.isDeposit) {
      this.transactionTypeLabel = 'retiro';
    }
  }
}
