export interface SingleItemInput {
  Sport: {
    Id: number;
    SportHandle: string;
  };
  Location: {
    Id: number;
  };
  League: {
    Id: number;
  };
  Event: {
    Id: number;
    HasHandicap: boolean;
  };
  PlacementSource: string;
  ClientData: string;
  TaxSettings: string;
  Game: {
    Id: number;
    GameTypeId: number;
    SpecialOddsValue: string;
    IsInPlay: boolean;
    BetTypeAvailability: number;
    NumberOfStarters: number;
    PlaceOddsDivisor: number;
    IncludedPlaces: number;
  };
  Odds: number;
  BaseOdds?: number;
  ResultId: number;
  BetType: string;
  CanBeEachWay?: boolean;
}

// eslint-disable-next-line no-shadow
export enum BetTypeEnum {
  DEFAULT = 'Default',
  WINNER = 'Winner',
  PLACE = 'Place',
  SHOW = 'Show'
}

export const mapSingleResult = (game): SingleItemInput => ({
  Sport: {
    Id: game.SportId,
    SportHandle: game.SportHandle
  },
  Location: {
    Id: game.LocationId
  },
  League: {
    Id: game.LeagueId
  },
  Event: {
    Id: game.EventId,
    HasHandicap: game.EventHasHandicap
  },
  PlacementSource: '',
  ClientData: '',
  TaxSettings: '',
  Game: {
    Id: game.GameId,
    GameTypeId: game.GameTypeId,
    SpecialOddsValue: game.GameSpecialOddsValue,
    IsInPlay: game.IsLive,
    BetTypeAvailability: game.GameBetTypeAvailability,
    NumberOfStarters: game.NumberOfStarters,
    PlaceOddsDivisor: 0,
    IncludedPlaces: 0
  },
  Odds: game.Odd,
  ResultId: game.NodeId,
  BetType: game.BetType ? game.BetType : 'Default', // Sports: "Default", GHH: "Winner" or ""
  CanBeEachWay: game.CanBeEachWay ? game.CanBeEachWay : false // Sports: false, GHH: true
});
