import { Component, Input, Output, EventEmitter, ElementRef, Renderer2, inject, OnInit } from '@angular/core';
import { HistoryItemFields } from '../../../models/payments/history-item.model';
import { CommonModule } from '@angular/common';
import { ExtractDatePipe } from '@pipes/extractDate.pipe';
import { ParseMoney } from '../../../pipes/parseMoney';
import { TranslateModule } from '@ngx-translate/core';
import { TRANSACTION_STATES } from '../../../models/payments/States';
import { TRANSACTION_HISTORY_TYPES } from '../../../models/payments/Types';
import { DeviceService } from '@services/device.service';

@Component({
  selector: 'cs-history-item',
  templateUrl: './cs-history-item.html',
  styleUrls: ['./cs-history-item.scss'],
  standalone: true,
  imports: [CommonModule, ExtractDatePipe, ParseMoney, TranslateModule]
})
export class CsHistoryItemComponent {
  @Input() history: any;
  @Input() options: HistoryItemFields;
  @Input() mode: any;
  @Output() navigate: EventEmitter<any> = new EventEmitter();
  @Output() cancelPayment: EventEmitter<any> = new EventEmitter();
  isDesktop = false;
  isMobile = false;

  private _host = inject(ElementRef);
  private _renderer = inject(Renderer2);
  deviceService = inject(DeviceService);
  extractDate = inject(ExtractDatePipe);

  constructor() {
    this.isMobile = this.deviceService.isMobile();
    this.isDesktop = this.deviceService.isDesktop();
  }

  ngAfterViewInit() {
    if (this.mode) {
      const setBackground = `background-color-${this.mode}`;
      this._renderer.addClass(this._host.nativeElement, setBackground);
    }
  }

  cancelPendingPayment() {
    this.cancelPayment.emit(this.history);
  }

  get setCancelWithdrawal() {
    return this.history.Estado === TRANSACTION_STATES.pending && this.isWithdrawal;
  }

  get isWithdrawal() {
    return this.history.Tipo === TRANSACTION_HISTORY_TYPES.withdrawal;
  }

  get isDeposit() {
    return this.history.Tipo === TRANSACTION_HISTORY_TYPES.deposit;
  }

  setIconColor(stateCode: string) {
    let colorClass = 'active';
    switch (stateCode) {
      case 'cancel':
        colorClass = 'cancel';
        break;
      case 'pending':
        colorClass = 'pending';
        break;
      default:
        colorClass = 'active';
        break;
    }
    return colorClass;
  }

  getDisplayDate(history) {
    let date = '';
    date = this.extractDate.transform(history?.Fecha, ['4']);
    this.isDesktop ? (date += ` ${this.extractDate.transform(history?.Fecha, ['5'])}`) : '';
    return date;
  }
}
