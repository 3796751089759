export interface BetbuilderItem {
  BetBuilderId: string;
  EventId: string;
  EventName: string;
  MarketInfoList: {
    GameName: string;
    ResultId: string;
    ResultName: string;
  }[];
  Odd: number;
  SportHandle: string;
  StartDate: string;
}

export interface BetbuilderLineItem {
  Amount: number;
  AmountLocal: number;
  BetBuilderId: string; // bb origin
  BetType?: any;
  CountryId: string;
  Date?: any;
  Description: string;
  downOdd: boolean;
  EventDate: string;
  EventId: string; // bb origin
  EventName: string; // bb origin
  FreebetInstance: any;
  FreeBetVoucherId: string;
  GameId: string;
  GameLocked?: boolean;
  GameSpecialOddsValue: string;
  GreyhoundHorseBetType?: any;
  GreyhoundHorses: boolean;
  Handicap?: any;
  HasPrice: boolean;
  Id: string;
  Index: number;
  IsBanked: boolean;
  IsBet: boolean;
  IsSelected: boolean;
  ItemId?: number;
  LineErrorMessages: Array<string>;
  LineErrors: any;
  LineItemStatus: number; //enum
  LiveStatus: boolean;
  Name: string;
  NodeId: any;
  NotAvailable?: boolean;
  Odd: number; // bb origin
  Ordered?: any;
  Paddock?: any;
  Participants?: any;
  PendingCheck: boolean;
  PersonalizedFreeBet: boolean;
  PipelineExecutionResult: number; //enum_PipelineExecutionResult;
  Place: number; //es un enum
  PotentialWin?: number; //decimal
  Price: number; //decimal
  ResultsNr: string;
  Selected: boolean;
  SmartLineItems: {
    GameName: string; // GameName
    Name: string; // ResultName
    ResultId: string; // bb origin
  }[];
  SpecialOddsValue: string;
  SportHandle: string;
  Status?: any;
  Tax?: any;
  TicketNumber?: string;
  TicketType: number;
  TotalBets: number;
  TotalPotentialWin: number;
  TotalPotentialWinWithoutBoost: number;
  Type: number;
  upOdd: boolean;
}

export class BetbuilderItemMethods {
  static createBetbuilderLineItem(betbuilderItem: BetbuilderItem) {
    const betbuilderLineItem: BetbuilderLineItem = {
      Amount: 1,
      AmountLocal: 1,
      BetBuilderId: betbuilderItem.BetBuilderId, // bb origi,
      BetType: null,
      CountryId: '',
      Date: null,
      Description: '',
      downOdd: false,
      EventDate: '',
      EventId: betbuilderItem.EventId, // bb origi,
      EventName: betbuilderItem.EventName, // bb origi,
      FreebetInstance: {},
      FreeBetVoucherId: '',
      GameId: '',
      GameLocked: false,
      GameSpecialOddsValue: '',
      GreyhoundHorseBetType: null,
      GreyhoundHorses: false,
      Handicap: null,
      HasPrice: false,
      Id: '',
      Index: 0,
      IsBanked: false,
      IsBet: false,
      IsSelected: false,
      ItemId: 0,
      LineErrorMessages: [],
      LineErrors: [],
      LineItemStatus: 0, //enu,
      LiveStatus: false,
      Name: '',
      NodeId: null,
      NotAvailable: false,
      Odd: betbuilderItem.Odd, // bb origi,
      Ordered: null,
      Paddock: null,
      Participants: null,
      PendingCheck: false,
      PersonalizedFreeBet: false,
      PipelineExecutionResult: 0,
      Place: 0,
      PotentialWin: 0,
      Price: 0,
      ResultsNr: '',
      Selected: false,
      SmartLineItems: this.setSmartLineItems(betbuilderItem.MarketInfoList),
      SpecialOddsValue: '',
      SportHandle: '',
      Status: '',
      Tax: '',
      TicketNumber: '',
      TicketType: 0,
      TotalBets: 0,
      TotalPotentialWin: 0,
      TotalPotentialWinWithoutBoost: 0,
      Type: 0,
      upOdd: false
    };
    return betbuilderLineItem;
  }
  static setSmartLineItems(marketInfoList) {
    const smartLineItems: any[] = [];
    marketInfoList.forEach((i) => {
      const smartLineItem = {
        GameName: i.GameName,
        Name: i.ResultName,
        ResultId: ''
      };
      smartLineItems.push(smartLineItem);
    });
    return smartLineItems;
  }
}
