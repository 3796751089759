import { IonContent, IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ViewChild, Component, inject, OnDestroy } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NewBaseService } from '@providers/newBase.service';
import { TrackingService } from '@providers/TrackingService';
import { CodereCardService, I_CodereCardQuestion } from '@providers/CodereCardService';
import { Utils } from '@utils/Utils';
import { DeviceService } from '@services/device.service';
import { CodereFooterComponent } from '@shared-mso-components/footer/codere-footer/codere-footer';
import { UserSectionTitle } from '@shared-mso-components/user-section-title/user-section-title';
import { EventsService } from '@providers/EventsService';
import { Router } from '@angular/router';
import { GlobalVarsModel } from '@models/ModelGlobalsVar';
import { MSO_PATHS, PAYMENTS_PATHS } from '@shared-constants/routes';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { MaxElementsPipe } from '@pipes/maxElements.pipe';
import { UserService } from '@providers/UserService';
import { Subscription } from 'rxjs';
import { LicenseTypes } from '@models/MasterData';

const URLASSETS = 'assets/global/img/coderecard/';
@Component({
  standalone: true,
  selector: 'codere-card-page',
  templateUrl: './codere-card.html',
  styleUrls: ['./codere-card.scss'],
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    CodereFooterComponent,
    TranslateModule,
    UserSectionTitle,
    MaxElementsPipe
  ],
  animations: [
    trigger('expandCollapse', [
      state(
        'expanded',
        style({
          height: '*'
        })
      ),
      state(
        'collapsed',
        style({
          height: 0,
          overflow: 'hidden'
        })
      ),
      transition('expanded <=> collapsed', animate('300ms ease-in-out'))
    ])
  ]
})
export class CodereCardPage {
  @ViewChild(IonContent) content: IonContent;
  isDesktop = false;
  globalVars!: GlobalVarsModel;
  isMobile: boolean;
  isOpen: boolean[] = [];
  questions: I_CodereCardQuestion[] = [];
  maxQuestions: number = 5;
  $loginSuscription: Subscription;
  bannerimg: string = URLASSETS;
  iconimg = { card: URLASSETS, benefits: URLASSETS, payments: URLASSETS };
  bannerassociate: string = URLASSETS;
  bannersolicity: string = URLASSETS;

  trackingService = inject(TrackingService);
  utils = inject(Utils);
  translate = inject(TranslateService);
  events = inject(EventsService);
  router = inject(Router);
  device = inject(DeviceService);
  newBaseService = inject(NewBaseService);
  codereCardService = inject(CodereCardService);
  userService = inject(UserService);
  licenseTypes = LicenseTypes;

  constructor() {
    this.isMobile = this.device.isMobile();
    this.isDesktop = this.device.isDesktop();
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
  }

  toggleQuestion(index: number) {
    this.isOpen[index] = !this.isOpen[index];
    this.isOpen = this.isOpen.map((value, i) => (i === index ? value : false));
  }

  setQuestions() {
    this.codereCardService.getCodereCardQuestion().subscribe((response) => {
      this.questions = response;
    });
  }

  ionViewWillEnter() {
    if (this.globalVars.user.logged) {
      this.setMenuUser();
    }
    this.buildPage();
    this.content.scrollToTop();
    this.setQuestions();
    this.isOpen = [];
    this.maxQuestions = 5;
    this.$loginSuscription = this.events.subscribe('user:login', () => {
      this.setMenuUser();
    });
  }

  addQuestions(add: number = 5) {
    if (add + this.maxQuestions >= this.questions.length) {
      this.maxQuestions = this.questions.length;
    } else {
      this.maxQuestions += add;
    }
  }

  openCardCodereCP() {
    if (this.globalVars.user.logged) {
      if (this.globalVars.user.codereCard && this.globalVars.user.codereCard.active) {
        this.router.navigate([MSO_PATHS.BASE + '/' + MSO_PATHS.MyCardPage]);
        return;
      }
      if (this.globalVars.FEATURES.MSO_cardCodereCP) {
        this.utils.alert(false, this.translate.instant('warning'), this.translate.instant('coderecardcp'), 'OK');
        return;
      }
      // checks if the user can request a card or not
      this.userService.checkCanSendCodere().subscribe({
        next: (data) => {
          if (!data.success) {
            this.userService.cardRequestExists().subscribe({
              next: (data3) => {
                if (!data3.success) {
                  // comprueba que el usuario haya realizado algún depósito en la amplicación
                  this.userService.hasDeposit().subscribe({
                    next: (data2) => {
                      if (data2.success) {
                        if (this.globalVars.user.pendingSMSEmail) {
                          this.utils.confirm(
                            true,
                            this.translate.instant('Attention'),
                            this.translate.instant('sendcommunicatdisabled'),
                            this.translate.instant('notWantpromotions'),
                            () => {
                              setTimeout(() => {
                                this.router.navigateByUrl(MSO_PATHS.BASE + '/' + MSO_PATHS.CardRequestFormPage);
                              }, 800);
                            },
                            this.translate.instant('acceptContinue'),
                            () => {
                              this.userService.setReceive().subscribe(() => {
                                this.globalVars.user.pendingSMSEmail = false;
                                this.events.publish('badges:refresh');
                                setTimeout(() => {
                                  this.router.navigateByUrl(MSO_PATHS.BASE + '/' + MSO_PATHS.CardRequestFormPage);
                                }, 800);
                              });
                            }
                          );
                        } else {
                          this.router.navigateByUrl(MSO_PATHS.BASE + '/' + MSO_PATHS.CardRequestFormPage);
                        }
                      } else {
                        //GetCardnodeposit
                        this.utils.confirm(
                          false,
                          'INFO',
                          this.translate.instant('GetCardnodeposit'),
                          this.translate.instant('tCancel'),
                          null,
                          this.translate.instant('IcoDeposit'),
                          () => {
                            // setTimeout(() => {
                            //   this.globalVars.rootScope.openModalOrPage(
                            //     `/${PAYMENTS_PATHS.CashierPage}`,
                            //     {},
                            //     true,
                            //     'modalCashier is-modal'
                            //   );
                            // }, 500);
                            this.router.navigateByUrl(`${PAYMENTS_PATHS.BASE}/${PAYMENTS_PATHS.CashierPage}`);
                          }
                        );
                      }
                      this.events.publish('footer:refresh', this.content);
                    },
                    error: (_err) => {
                      this.utils.showError(this.translate.instant('m_menu_serverFail'));
                    }
                  });
                } else {
                  this.utils.showError(this.translate.instant('coderecardrequested'));
                }
              },
              error: (_err) => {
                this.utils.showError(this.translate.instant('coderecardrequested'));
              }
            });
          } else {
            this.utils.confirm(
              false,
              this.translate.instant('tFail'),
              this.translate.instant('coderecardverifdocum'),
              this.translate.instant('tCancel'),
              null,
              this.translate.instant('check')
            );
          }
        },
        error: (_err) => {
          this.utils.confirm(
            false,
            this.translate.instant('tFail'),
            this.translate.instant('coderecardverifdocum'),
            this.translate.instant('tCancel'),
            null,
            this.translate.instant('check')
          );
        }
      });
    } else {
      this.globalVars.rootScope.openLogin(() => {
        if (this.globalVars.user.logged) {
          this.openCardCodereCP();
        }
      });
    }
  }
  goTo(path: string) {
    if (this.globalVars.user.logged) {
      if (this.globalVars.user.codereCard && this.globalVars.user.codereCard.active) {
        this.router.navigate([MSO_PATHS.BASE + '/' + MSO_PATHS.MyCardPage]);
      } else {
        this.router.navigate([`/${MSO_PATHS.BASE}/${path}`], {
          queryParams: { animate: !this.isDesktop }
        });
      }
    } else {
      this.globalVars.rootScope.openLogin(() => {
        if (this.globalVars.user.logged) {
          this.goTo(path);
        }
      });
    }
  }
  openMapLocals() {
    this.goTo(MSO_PATHS.NearestLocalPage);
  }

  associatePage() {
    this.goTo(MSO_PATHS.CardLinkPage);
  }

  bannerCard() {
    this.goTo(MSO_PATHS.CardRequestPage);
  }

  setMenuUser() {
    this.globalVars.rootScope.sectionName = 'user';
  }

  ionViewDidLeave() {
    this.$loginSuscription.unsubscribe();
  }

  buildPage() {
    switch (this.globalVars.licenseType) {
      case this.licenseTypes.Nacional:
        this.bannerimg += 'banner-codere-card.jpg';
        this.iconimg.card += 'icon-card.svg';
        this.iconimg.benefits += 'icon-card-benefits.svg';
        this.iconimg.payments += 'icon-card-payments.svg';
        break;
      case this.licenseTypes.Colombia:
        this.iconimg.card += 'icon_card_co.png';
        this.iconimg.benefits += 'icon_card_benefits_co.png';
        this.iconimg.payments += 'icon_card_associate_co.png';
        if (this.isDesktop) {
          this.bannerimg += 'coderecardbannercopc.png';
          this.bannerassociate += 'coderecardbannersrcopc.png';
          this.bannersolicity += 'coderecardbannersolicitycopc.jpg';
        } else {
          this.bannerimg += 'coderecardbannercomobile.png';
          this.bannerassociate += 'coderecardbannerassociatecomobile.jpg';
          this.bannersolicity += 'coderecardbannersolicitycomobile.jpg';
        }
        break;
    }
  }
}
