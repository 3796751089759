/* eslint-disable */
export enum BonusTypes {
  FreeSpin = 1,
  PreWager = 2,
  AfterWager = 3,
  Cash = 4,
  GoldenChips = 6
}

export enum StateKeys {
  Issue = 1,
  Active = 3,
  Accepted = 2,
  Declined = 7,
  Waiting = 4
}

export enum TriggerTypes {
  SignUp = 1,
  Deposit = 2,
  PromotionCode = 3,
  CustomEvent = 4,
  BuyBonus = 5,
  Campaign = 7,
  Gameplay = 8
}
