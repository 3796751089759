/* eslint-disable eqeqeq */
export function findBy(array: any[], property: string, comparison: any): any {
  return array.find((el) => el[property] === comparison);
}

export function groupBy(array: any[], key: string): any {
  return array.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}

export function intersection(previous: any[], current: any, property: string) {
  if (!previous) {
    return [null, current, property];
  }
  const prev = previous.find((el) => el[property] === current[property]);
  return [prev, current, property];
}

export function orderBy(array: any[], property: string): any[] {
  if (!array) {
    return [];
  }
  return array.sort((a, b) => {
    if (a[property] < b[property]) return -1;
    if (a[property] > b[property]) return 1;
    return 0;
  });
}

export function unique(array: any[], property: string) {
  return array.reduce((unique_, o) => {
    if (!unique_.some((obj: any) => obj[property] === o[property])) {
      unique_.push(o);
    }
    return unique_;
  }, []);
}
