export class C_loadTicketCaptcha {
  success: boolean;
  status: string;
  message: string;
  TicketID: string;

  constructor(_success: boolean, _status: string, _message: string, _ticketID: string) {
    this.success = _success;
    this.status = _status;
    this.message = _message;
    this.TicketID = _ticketID;
  }
}
