<ion-list class="list list-md margin10" *ngIf="msg">
  <div *ngIf="oxxo" class="alertMessage">
    <ion-icon
      aria-label="alert outline"
      class="ion-ios-alert-outline icon icon-md"
      ios="ios-alert-outline"
      md="ios-alert-outline"
      role="img"
    ></ion-icon>
    <b>{{ 'Important2' | translate }}</b> {{ 'deponlineoxxo' | translate }}
  </div>
  <div *ngIf="!oxxo" class="alertMessage">
    <ion-icon
      aria-label="alert outline"
      class="ion-ios-alert-outline icon icon-md"
      ios="ios-alert-outline"
      md="ios-alert-outline"
      role="img"
    ></ion-icon>
    <span [innerHTML]="'v2_SpeiInfoTx0' | translate"></span>
  </div>
</ion-list>
<ion-list class="list list-md margin10" *ngIf="autoEPG">
  <div class="alertMessage">
    <ion-icon
      aria-label="alert outline"
      class="ion-ios-alert-outline icon icon-md"
      ios="ios-alert-outline"
      md="ios-alert-outline"
      role="img"
    ></ion-icon>
    <span
      ><b>No ha podido finalizar su dep&oacute;sito</b> Int&eacute;ntelo de nuevo mediante este m&eacute;todo o contacte
      con nosotros en apuestas@codere.com</span
    >
  </div>
</ion-list>
<iframe class="cs-iframe" scrolling="yes" *ngIf="url" [src]="url" frameborder="0" #urlRef></iframe>
