/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { IonicModule, NavController } from '@ionic/angular';
import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { Utils } from '@utils/Utils';
import { ErrorService } from '@providers/ErrorService';
import { UserService } from '@providers/UserService';
import { NewBaseService } from '@providers/newBase.service';
import { DeviceService } from '@services/device.service';

import { CodereFooterComponent } from '@shared-mso-components/footer/codere-footer/codere-footer';
import { UserServiceMSO } from '@providers/UserServiceMSO';
import { BackButton } from '@components/common/back-button/back-button';

import { GlobalVarsModel } from '@models/ModelGlobalsVar';
import { MSO_PATHS } from '@shared-constants/routes';
import { LicenseTypes } from '@models/MasterData';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'change-password-page',
  standalone: true,
  templateUrl: './change-password.html',
  styleUrls: ['./change-password.scss'],
  imports: [CommonModule, ReactiveFormsModule, IonicModule, TranslateModule, CodereFooterComponent, BackButton]
})
export class ChangePasswordPage {
  formData: any;
  myForm!: FormGroup;

  nameUser!: string;
  surnameUser!: string;
  surnameUser2!: string;
  birthDayUser!: string;
  globalVars!: GlobalVarsModel;
  showPassword: boolean[] = [];
  fb = inject(FormBuilder);
  errorService = inject(ErrorService);
  newBaseService = inject(NewBaseService);
  userService = inject(UserService);
  userServiceMSO = inject(UserServiceMSO);
  utils = inject(Utils);
  translate = inject(TranslateService);
  deviceService = inject(DeviceService);
  router = inject(Router);
  isDesktop = this.deviceService.isDesktop();
  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });

    this.resetForm();
    this.inicializarDatosUsuario();
  }

  resetForm() {
    this.myForm = this.fb.group({
      pass: ['', Validators.required],
      newPass: [''],
      reNewPass: ['']
    });

    this.myForm.controls['newPass'].valueChanges.subscribe((value: string) => {
      //checks the password and repassword
      this.myForm.controls['newPass'].setErrors(this.passwordValidator(value));
      this.myForm.controls['reNewPass'].setErrors(this.repasswordValidator(this.myForm.controls['reNewPass'].value));
    });
    this.myForm.controls['reNewPass'].valueChanges.subscribe((value: string) => {
      //checks the repassword
      this.myForm.controls['reNewPass'].setErrors(this.repasswordValidator(value));
    });
  }

  passwordValidator = (value: string) => {
    if (this.globalVars.licenseType == LicenseTypes.Mexico) {
      return this.validadorPasswordMX(value);
    } else {
      return this.validadorPasswordES(value);
    }
  };

  validadorPasswordES(value: string) {
    const control: any = { value: value };
    const minLength = 8;
    const maxLength = 20;
    const mayReg = /[A-Z?]/;
    const minReg = /[a-z?]/;
    const numReg = /\d/;
    if (control.value) {
      if (minReg.test(control.value)) {
        if (mayReg.test(control.value)) {
          if (numReg.test(control.value)) {
            // if (espReg.test(control.value)) {
            if (control.value.length >= minLength) {
              if (control.value.length < maxLength) {
                if (control.value.toLowerCase().indexOf(this.nameUser.toLowerCase().replace(' ', '')) == -1) {
                  if (control.value.toLowerCase().indexOf(this.surnameUser.toLowerCase().replace(' ', '')) == -1) {
                    if (
                      !this.surnameUser2 ||
                      control.value.toLowerCase().indexOf(this.surnameUser2.toLowerCase().replace(' ', '')) == -1
                    ) {
                      let find = false;

                      if (this.globalVars.user.birthDay) {
                        const day = this.globalVars.user.birthDay.split('/')[0];
                        const month = this.globalVars.user.birthDay.split('/')[1];
                        const year = this.globalVars.user.birthDay.split('/')[2];

                        const regex = new RegExp(
                          `(${day}|0?${day})[^\\d]*(${month}|0?${month})[^\\d]*${year}` +
                            `|(${day}|0?${day})[^\\d]*${year}[^\\d]*(${month}|0?${month})` +
                            `|(${month}|0?${month})[^\\d]*(${day}|0?${day})[^\\d]*${year}` +
                            `|(${month}|0?${month})[^\\d]*${year}[^\\d]*(${day}|0?${day})` +
                            `|${year}[^\\d]*(${month}|0?${month})[^\\d]*(${day}|0?${day})` +
                            `|${year}[^\\d]*(${day}|0?${day})[^\\d]*(${month}|0?${month})`,
                          'g'
                        );
                        find = regex.test(control.value);
                      }
                      if (!find) {
                        if (this.globalVars.user.username) {
                          if (
                            control.value
                              .toLowerCase()
                              .indexOf(this.globalVars.user.username.toLowerCase().replace(' ', '')) == -1
                          ) {
                            return null;
                          } else {
                            //user
                            return { cError: 'incUser' };
                          }
                        } else {
                          return null;
                        }
                      } else if (this.birthDayUser) {
                        // solo valida si existe valor de fecha de nacimento
                        // en los datos de usuario guardados en globalVars
                        //birthDay
                        return { cError: 'incBirthday' };
                      }
                    } else {
                      //surname2
                      return { cError: 'incSurname2' };
                    }
                  } else {
                    //surname
                    return { cError: 'incSurname' };
                  }
                } else {
                  //name
                  return { cError: 'incName' };
                }
              } else {
                return { cError: 'tooLong' };
              }
            } else {
              return { cError: 'tooShort' };
            }
          } else {
            return { cError: 'noNum' };
          }
        } else {
          return { cError: 'noMay' };
        }
      } else {
        return { cError: 'noMin' };
      }
    } else {
      return { cError: 'required' };
    }
  }

  validadorPasswordMX(value: string) {
    const control: any = { value: value };
    const minLength = 5;
    const maxLength = 10;
    const letReg = /[a-zñA-ZÑ]/;
    const numReg = /\d/;
    if (control.value) {
      if (numReg.test(control.value)) {
        if (control.value.length >= minLength) {
          if (control.value.length <= maxLength) {
            if (letReg.test(control.value)) {
              return null;
            } else {
              return { cError: 'needChar' };
            }
          } else {
            return { cError: 'tooLongMX' };
          }
        } else {
          return { cError: 'tooShortMX' };
        }
      } else {
        return { cError: 'noNum' };
      }
    } else {
      return { cError: 'required' };
    }
  }

  repasswordValidator = (value: string) => {
    const control: any = { value: value };
    //repasswordValidator
    if (!!control.value && !!this.myForm.controls['newPass'].value) {
      if (control.value == this.myForm.controls['newPass'].value) {
        return null;
      } else {
        return { cError: 'same' };
      }
    } else {
      return { cError: 'required' };
    }
  };

  async doChangePass(form) {
    let validForm = false;
    if (
      this.myForm.controls['pass'].valid &&
      this.myForm.controls['newPass'].valid &&
      this.myForm.controls['reNewPass'].valid
    ) {
      validForm = true;
    }
    if (validForm) {
      const data = {
        oldP: this.myForm.controls['pass'].value,
        newP: this.myForm.controls['newPass'].value,
        userName: this.globalVars.user.username
      };
      this.userServiceMSO
        .resetPassword(this.utils.encryptWithPublicKey(JSON.stringify(data), 'MSO_rsa_publickey'))
        .subscribe({
          // eslint-disable-next-line no-shadow
          next: (data) => {
            if (data.status == 'ok') {
              this.resetForm();
              this.utils.showMsg(this.translate.instant('m_reg_updOk'), () => {
                setTimeout(() => {
                  this.router.navigate([MSO_PATHS.BASE + '/' + MSO_PATHS.PersonalInfoPage]);
                }, 300);
              });
            } else {
              if (data.errCode != undefined) {
                this.errorService.searchErrorCode(data.errCode).subscribe({
                  next: (data2) => {
                    this.utils.showError(data2);
                  },
                  error: () => {
                    this.utils.showError(data.errCode);
                  }
                });
              } else {
                this.utils.showError(this.translate.instant('m_menu_serverFail'));
              }
            }
          },
          error: () => {
            this.utils.showError(this.translate.instant('m_menu_serverFail'));
          }
        });
    } else {
      for (const x in this.myForm.controls) {
        this.myForm.controls[x].markAsTouched();
        this.myForm.controls[x].updateValueAndValidity();
      }
    }
  }

  // Comprueba si existen el nombre, apellidos y fecha de nacimiento del usuario
  inicializarDatosUsuario() {
    if (this.globalVars.user.name) {
      this.nameUser = this.globalVars.user.name;
    } else {
      this.nameUser = '###';
    }
    if (this.globalVars.user.surname) {
      this.surnameUser = this.globalVars.user.surname;
    } else {
      this.surnameUser = '###';
    }
    if (this.globalVars.user.surname2) {
      this.surnameUser2 = this.globalVars.user.surname2;
    } else {
      this.surnameUser2 = '###';
    }
    if (this.globalVars.user.birthDay) {
      this.birthDayUser =
        this.globalVars.user.birthDay.split('/')[0] +
        this.globalVars.user.birthDay.split('/')[1] +
        this.globalVars.user.birthDay.split('/')[2];
    } else {
      this.birthDayUser = '';
    }
  }
  onClickShowPassword(index: number) {
    this.showPassword[index] = !this.showPassword[index];
  }
}
