<!-- eslint-disable @angular-eslint/template/eqeqeq -->
<ng-container *ngIf="!isMobile; else mobileView">
  <div class="cs-documents background-color-white w-100">
    <div class="cs-documents--container background-color-white w-100"
      [style.height.px]="utils.getModalHeight('ion-modal--height', 8)">
      <cs-document-header [isMobile]="isMobile" [isVerificationCompleted]="isVerificationCompleted"
        [isVerificationPending]="isVerificationPending()" [verificationLiterals]="verificationLiterals"
        [verificationOptions]="verificationOptions" (verificationOption)="getVerificationOptionSelected($event)">
      </cs-document-header>
      <div *ngIf="utils.isSpain() || utils.isColombia()">
        <ng-container *ngIf="documentVerificationAvailable">
          <div [hidden]="isVerificationCompleted"
            *ngIf="(isSpain && showAutoVerification) || (globalVars?.licenseType === licenseType.Colombia && isVeridasCOActive)">
            <div [hidden]="isManualUploadEnabled()" class="color-secondary p-1 verification-info-link"></div>
            <div class="cs-documents--info red-txt p-1"
              *ngIf="globalVars?.licenseType == licenseType.Panama && globalVars?.openFromRegister">
              <a class="red-txt"><span>{{ 'registropacashierdocuments' | translate }}</span></a>
            </div>
            <div class="cs-document-item--container p-1 justify-center" *ngIf="!isVerificationCompleted">
              <button [hidden]="isManualUploadEnabled()" class="cs-document-item--btn verification-button"
                [disabled]="isVerificationPending()" (click)="initVerification($event)">
                <span class="color-light">{{ 'verification_start' | translate }}</span>
              </button>
            </div>
          </div>

          <div class="cs-documents--list" *ngIf="isManualUploadEnabled()">
            <ng-container *ngIf="!isMaxFailuresNumberReached()">
              <ng-container *ngIf="verificationOptionSelected.type == verificationType.DNI">
                <cs-document-item [document]="titleIDCard(this.verDocType.titleCardFront)"
                  (navigate)="triggerClick(inputOne)" [doc]="getLastDocumentUploadRequests(this.verDocType.idFront)">
                  <input id="input1" #inputOne type="file" accept="image/*" />
                </cs-document-item>

                <cs-document-item [document]="titleIDCard(this.verDocType.titleCardReverse)"
                  (navigate)="triggerClick(inputTwo)" [doc]="getLastDocumentUploadRequests(this.verDocType.idReverse)">
                  <input id="input2" #inputTwo type="file" accept="image/*" />
                </cs-document-item>

                <cs-document-item [document]="setSelfieLiteral()" (navigate)="triggerClick(inputFive)"
                  [doc]="getLastDocumentUploadRequests(this.verDocType.idSelfie)" iconName="verification_type_selfie">
                  <input id="input5" #inputFive type="file" accept="image/*" />
                </cs-document-item>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="verificationOptionSelected.type == verificationType.JustificanteBancario">
              <cs-document-item [document]="bankDataInfo()" (navigate)="triggerClick(inputThree)"
                [doc]="getLastDocumentUploadRequests(this.verDocType.idBank)">
                <input id="input3" #inputThree type="file" accept="image/*" />
              </cs-document-item>
            </ng-container>
            <ng-container *ngIf="verificationOptionSelected.type == verificationType.Otros">
              <cs-document-item [document]="otherDocument()" (navigate)="triggerClick(inputSeven)"
                [doc]="getLastDocumentUploadRequests(this.verDocType.idOther)">
                <input id="input7" #inputSeven type="file" accept="image/*" />
              </cs-document-item>
              <cs-document-item *ngIf="showOriginFunds()" [document]="originFundsCheck()" [isOriginFunds]="true"
                [originFunds]="originFunds" (navigate)="openModalSworm()">
              </cs-document-item>
            </ng-container>
          </div>
        </ng-container>
      </div>

      <!-- Other Regions -->
      <div *ngIf="!utils.isSpain() && !utils.isColombia()">
        <div class="cs-documents--info red-txt p-1"
          *ngIf="globalVars?.licenseType == licenseType.Panama && globalVars?.openFromRegister">
          <a class="red-txt"><span>{{ 'registropacashierdocuments' | translate }}</span></a>
        </div>
        <div class="cs-documents--list">
          <ng-container *ngIf="verificationOptionSelected.type == verificationType.DNI">
            <ng-container *ngIf="(veridas_NotES_NotCO_Active && canShowVeridas_NotES_NotCO)">
              <cs-document-item [document]="titleIDCard()" [verificationType]="true" tappable (click)="showInfoAlert()">
              </cs-document-item>
              <cs-verification-item *ngFor="let doc of veridasData$ | async" [doc]="doc"></cs-verification-item>
            </ng-container>

            <cs-document-item [hidden]="(veridas_NotES_NotCO_Active && canShowVeridas_NotES_NotCO)"
              [document]="titleIDCard(this.verDocType.titleCardFront)"
              [doc]="getLastDocumentUploadRequests(this.verDocType.idFront)" (navigate)="triggerClick(inputOne)">
              <input id="input1" #inputOne type="file" accept="image/*" />
            </cs-document-item>

            <cs-document-item [hidden]="(veridas_NotES_NotCO_Active && canShowVeridas_NotES_NotCO)"
              [document]="titleIDCard(this.verDocType.titleCardReverse)"
              [doc]="getLastDocumentUploadRequests(this.verDocType.idReverse)" (navigate)="triggerClick(inputTwo)">
              <input id="input2" #inputTwo type="file" accept="image/*" />
            </cs-document-item>

            <cs-document-item [hidden]="!globalVars?.FEATURES?.selfieDocEnabled" [document]="setSelfieLiteral()"
              [doc]="getLastDocumentUploadRequests(this.verDocType.idSelfie)" iconName="verification_type_selfie"
              (navigate)="triggerClick(inputFive)">
              <input id="input5" #inputFive type="file" accept="image/*" />
            </cs-document-item>
          </ng-container>

          <ng-container *ngIf="verificationOptionSelected.type == verificationType.JustificanteBancario">
            <cs-document-item [document]="bankDataInfo()" (navigate)="triggerClick(inputThree)"
              [doc]="getLastDocumentUploadRequests(this.verDocType.idBank)">
              <input id="input3" #inputThree type="file" accept="image/*" />
            </cs-document-item>
          </ng-container>

          <ng-container *ngIf="verificationOptionSelected.type == verificationType.Otros">
            <cs-document-item [document]="otherDocument()" (navigate)="triggerClick(inputSeven)"
              [doc]="getLastDocumentUploadRequests(this.verDocType.idOther)">
              <input id="input7" #inputSeven type="file" accept="image/*" />
            </cs-document-item>
            <cs-document-item *ngIf="showOriginFunds()" [document]="originFundsCheck()" [isOriginFunds]="true"
              [originFunds]="originFunds" (navigate)="openModalSworm()">
            </cs-document-item>
          </ng-container>
        </div>
      </div>
      <div>
        <cs-documents-accordion [verificationOptionSelected]="verificationOptionSelected"
          [verificationLiterals]="verificationLiterals"></cs-documents-accordion>
        <cs-documents-accordion [verificationLiterals]="verificationLiterals" [isFAQ]="true"></cs-documents-accordion>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #mobileView>
  <div class="cs-documents background-color-white w-100">
    <div class="cs-documents--container background-color-white w-100">
      <cs-document-header [isMobile]="isMobile" [isVerificationCompleted]="isVerificationCompleted"
        [verificationOptions]="verificationOptions" [isVerificationPending]="isVerificationPending()"
        [verificationLiterals]="verificationLiterals"
        (verificationOption)="getVerificationOptionSelected($event)"></cs-document-header>

      <div *ngIf="utils.isSpain() || utils.isColombia()">
        <ng-container *ngIf="documentVerificationAvailable">
          <div [hidden]="isVerificationCompleted"
            *ngIf="(isSpain && showAutoVerification) || (globalVars?.licenseType === licenseType.Colombia && isVeridasCOActive)">
            <div *ngIf="!isVerificationCompleted" [hidden]="isManualUploadEnabled()"
              class="color-secondary p-1 verification-info-link"></div>
            <div class="cs-documents--info red-txt p-1"
              *ngIf="globalVars?.licenseType == licenseType.Panama && globalVars?.openFromRegister">
              <a class="red-txt"><span>{{ 'registropacashierdocuments' | translate }}</span></a>
            </div>
            <div class="cs-document-item--container p-1 justify-center" *ngIf="!isVerificationCompleted">
              <button [hidden]="isManualUploadEnabled()" class="cs-document-item--btn verification-button"
                [disabled]="isVerificationPending()" (click)="initVerification($event)">
                <span class="color-light">{{ 'verification_start' | translate }}</span>
              </button>
            </div>
          </div>
          <!-- TO-DO: definir la clase para spain -->
          <div class="cs-document-item--container p-1 justify-center manual-upload-title"
            *ngIf="isManualUploadEnabled() && !utils.isColombia()">
            <span>{{ 'verification_manual_upload' | translate }}</span>
          </div>

          <!-- TO-DO: definir la clase para spain -->
          <div class="cs-documents--list" *ngIf="isManualUploadEnabled()">
            <ng-container *ngIf="!isMaxFailuresNumberReached()">
              <ng-container *ngIf="verificationOptionSelected.type == verificationType.DNI">
                <cs-document-item [document]="titleIDCard(this.verDocType.titleCardFront)"
                  (navigate)="triggerClick(inputOne)" [doc]="getLastDocumentUploadRequests(this.verDocType.idFront)">
                  <input id="input1" #inputOne type="file" accept="image/*" />
                </cs-document-item>

                <cs-document-item [document]="titleIDCard(this.verDocType.titleCardReverse)"
                  (navigate)="triggerClick(inputTwo)" [doc]="getLastDocumentUploadRequests(this.verDocType.idReverse)">
                  <input id="input2" #inputTwo type="file" accept="image/*" />
                </cs-document-item>

                <cs-document-item [document]="setSelfieLiteral()" (navigate)="triggerClick(inputFive)"
                  [doc]="getLastDocumentUploadRequests(this.verDocType.idSelfie)" iconName="verification_type_selfie">
                  <input id="input5" #inputFive type="file" accept="image/*" />
                </cs-document-item>
              </ng-container>
            </ng-container>

            <ng-container *ngIf="verificationOptionSelected.type == verificationType.JustificanteBancario">
              <cs-document-item [document]="bankDataInfo()" (navigate)="triggerClick(inputThree)"
                [doc]="getLastDocumentUploadRequests(this.verDocType.idBank)">
                <input id="input3" #inputThree type="file" accept="image/*" />
              </cs-document-item>
            </ng-container>

            <ng-container *ngIf="verificationOptionSelected.type == verificationType.Otros">
              <cs-document-item [document]="otherDocument()" (navigate)="triggerClick(inputSeven)"
                [doc]="getLastDocumentUploadRequests(this.verDocType.idOther)">
                <input id="input7" #inputSeven type="file" accept="image/*" />
              </cs-document-item>
              <cs-document-item *ngIf="showOriginFunds()" [document]="originFundsCheck()" [isOriginFunds]="true"
                [originFunds]="originFunds" (navigate)="openModalSworm()">
              </cs-document-item>
            </ng-container>
          </div>
        </ng-container>
      </div>

      <!-- Other Regions -->
      <div *ngIf="!utils.isSpain() && !utils.isColombia()">
        <div class="cs-documents--info red-txt p-1"
          *ngIf="globalVars?.licenseType == licenseType.Panama && globalVars?.openFromRegister">
          <a class="red-txt"><span>{{ 'registropacashierdocuments' | translate }}</span></a>
        </div>
        <div class="cs-documents--list">
          <ng-container *ngIf="verificationOptionSelected.type == verificationType.DNI">
            <ng-container *ngIf="(veridas_NotES_NotCO_Active && canShowVeridas_NotES_NotCO)">
              <cs-document-item [document]="titleIDCard()" [verificationType]="true" tappable (click)="showInfoAlert()">
              </cs-document-item>
              <cs-verification-item *ngFor="let doc of veridasData$ | async" [doc]="doc"></cs-verification-item>
            </ng-container>

            <cs-document-item [hidden]="(veridas_NotES_NotCO_Active && canShowVeridas_NotES_NotCO)"
              [document]="titleIDCard(this.verDocType.titleCardFront)"
              [doc]="getLastDocumentUploadRequests(this.verDocType.idFront)" (navigate)="triggerClick(inputOne)">
              <input id="input1" #inputOne type="file" accept="image/*" />
            </cs-document-item>

            <cs-document-item [hidden]="(veridas_NotES_NotCO_Active && canShowVeridas_NotES_NotCO)"
              [document]="titleIDCard(this.verDocType.titleCardReverse)"
              [doc]="getLastDocumentUploadRequests(this.verDocType.idReverse)" (navigate)="triggerClick(inputTwo)">
              <input id="input2" #inputTwo type="file" accept="image/*" />
            </cs-document-item>
          </ng-container>

          <ng-container *ngIf="verificationOptionSelected.type == verificationType.JustificanteBancario">
            <cs-document-item [document]="bankDataInfo()" (navigate)="triggerClick(inputThree)"
              [doc]="getLastDocumentUploadRequests(this.verDocType.idBank)">
              <input id="input3" #inputThree type="file" accept="image/*" />
            </cs-document-item>
          </ng-container>

          <ng-container *ngIf="verificationOptionSelected.type == verificationType.Otros">
            <cs-document-item [document]="otherDocument()" (navigate)="triggerClick(inputSeven)"
              [doc]="getLastDocumentUploadRequests(this.verDocType.idOther)">
              <input id="input7" #inputSeven type="file" accept="image/*" />
            </cs-document-item>
            <cs-document-item *ngIf="showOriginFunds()" [document]="originFundsCheck()" [isOriginFunds]="true"
              [originFunds]="originFunds" (navigate)="openModalSworm()">
            </cs-document-item>
          </ng-container>
        </div>
      </div>
      <div>
        <cs-documents-accordion [verificationOptionSelected]="verificationOptionSelected"
          [verificationLiterals]="verificationLiterals"></cs-documents-accordion>
        <cs-documents-accordion [verificationLiterals]="verificationLiterals" [isFAQ]="true"></cs-documents-accordion>
      </div>
    </div>
  </div>
</ng-template>