/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, ElementRef, HostListener, inject } from '@angular/core';
import { IonicModule, MenuController, ModalController } from '@ionic/angular';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';

import { Utils } from '../../../utils/Utils';
import { UserService } from '../../../providers/UserService';
import { TranslateModule } from '@ngx-translate/core';
import { TicketService } from '../../../providers/TicketService';
import { SearchService } from '../../../providers/SearchService';

import { NavService } from '../../../providers/NavService';
import { C_SearchResult } from '../../../models/C_SearchResult';
import { C_Horses } from '../../../models/C_Horses';
import { C_EventLive } from '../../../models/C_EventLive';

import { CommonModule } from '@angular/common';
import { DeviceService } from '../../../services/device.service';
import { GlobalVarsModel } from '../../../models/ModelGlobalsVar';
import { SportService } from '../../../providers/SportService';
import { C_Event } from '../../../models/C_Event';

import { Router } from '@angular/router';
import { NewBaseService } from '../../../providers/newBase.service';

@Component({
  selector: 'codere-navbar-pc-search',
  templateUrl: './codere-navbar-pc-search.html',
  styleUrls: ['./codere-navbar-pc-search.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, TranslateModule]
})
export class CodereNavbarPcSearch {
  userSubMenuClosed = true;
  searchSubMenuClosed = true;
  searchFocus = false;
  searchFocusActive = false;
  searchInputModel = '';
  searchInput: Subject<any> = new Subject();
  searchResults: any[];
  searchResultsLoading = false;
  resultsPositionLeft: string;
  globalVars!: GlobalVarsModel;
  isDesktop: boolean = false;
  isMobile: boolean = false;

  menu = inject(MenuController);
  sportService = inject(SportService);
  utils = inject(Utils);
  userService = inject(UserService);
  ticketService = inject(TicketService);
  modalCtrl = inject(ModalController);
  navService = inject(NavService);
  searchService = inject(SearchService);
  deviceService = inject(DeviceService);
  newBaseService = inject(NewBaseService);
  router = inject(Router);

  constructor(private elementRef: ElementRef) {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
    this.searchService.searchTerm
      .pipe(debounceTime(800), distinctUntilChanged())
      .subscribe((search) => this.search(search));
    this.isDesktop = this.deviceService.isDesktop();
    this.isMobile = this.deviceService.isMobile();
  }

  @HostListener('document:mousedown', ['$event'])
  public onClick(event: MouseEvent) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.searchSubMenuClosed = true;
    }
  }

  viewList(): boolean {
    return this.searchResults?.length === 0 && !this.searchResultsLoading;
  }

  viewResults(): boolean {
    return this.searchResults?.length > 0;
  }

  navigate(component: string, params?: any, left = false) {
    if (left) {
      this.navService.updateNavLeft(component);
    }
    if (component === 'SlotsPage' || component === 'VideoBingoPage') {
      params = { gameType: component === 'SlotsPage' ? 'slot' : 'bingo' };
    }

    this.router.navigate([component], { state: { params } });
  }

  search(term: string) {
    if (term) {
      this.searchSubMenuClosed = false;
      this.searchResultsLoading = true;
      this.searchResults = [];
      this.searchService.getResultSearch(term).subscribe((results: any) => {
        this.searchResults = results;
        this.searchResultsLoading = false;
      });
    }
  }

  btItemSearch_Click($DOMevent, item: C_SearchResult) {
    this.searchSubMenuClosed = true;
    this.globalVars.SportSelected = '';
    if (item.SportHandle === 'greyhound_racing' || item.SportHandle === 'horse_racing') {
      const objHorsesAux: C_Horses = new C_Horses(
        item.Name,
        item.NodeId,
        item.ParentNodeId,
        item.EventNodeTypeId,
        item.Priority,
        item.SportHandle,
        item.ChildrenCount,
        item.Country,
        false,
        0,
        item.PaddockId,
        item.StartDate
      );
      this.router.navigate(['HorsesMarketPage'], { state: { params: objHorsesAux } });
    } else {
      if (item.isLive) {
        const event = C_EventLive.parse(item);
        const eventGameTypes = this.globalVars.gameTypes.getOne(event.SportHandle, event.CountryCode, event.isLive);
        event.setGamesByPlatform(eventGameTypes, this.isMobile);
        this.router.navigate(['NowLiveDetailPage'], { state: { params: event } });
      } else {
        const event = C_Event.parse(item);
        const eventGameTypes = this.globalVars.gameTypes.getOne(event.SportHandle, event.CountryCode, event.isLive);
        event.hasSpecialGame(this.globalVars.gameTypes.apuestas_especiales);
        event.setGamesByPlatform(eventGameTypes, this.isMobile);
        this.router.navigate(['MercadosPage'], { state: { params: event, isLeftMenuAZ: true } });
      }
    }
  }
}
