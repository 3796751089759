<ion-content>
  <div class="contModal contModalCash">
    <h1 *ngIf="swormTitle" class="nav-sworm-title">{{ swormTitle }}</h1>
    <button class="closeModal" (click)="btClose_Click()">
      <ion-icon name="close"></ion-icon>
    </button>
    <h6 class="nav-sworm-bold">{{ 'origenDeFondos' | translate }}</h6>
    <form [formGroup]="fundsOriginForm">
      <div class="funds-origin-wrapper">
        <ion-select
          placeholder="Selecciona el origen"
          [interfaceOptions]="selectOptions"
          [formControl]="originControl"
          class="funds-origin-select"
          [class.error]="displayFieldError('origin')"
          interface="popover"
          [cancelText]="'tCancel' | translate"
          [okText]="'tAccept' | translate"
        >
          <ion-select-option *ngFor="let origin of fundsOrigin" [value]="origin">{{origin.name}}</ion-select-option>
        </ion-select>
      </div>

      <div>
        <h6 class="nav-sworm-bold">{{ 'origenDeFondosConfirmacion' | translate }}</h6>
        <table>
          <tr>
            <td>
              <input class="input" type="checkbox" (change)="handleSwornClick($event)" [formControl]="legalControl" />
            </td>
            <td>
              <span>{{ 'origenDeFondosDeclaracion' | translate }}</span>
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              <p>
                <span>{{ 'pulsa' | translate }} </span>
                <span class="nav-sworm-bold nav-sworm-underlined" (click)="goToProfile()"
                  >{{ 'here' | translate }}</span
                >
                <span> {{'origenDeFondosConsulta' | translate }}</span>
              </p>
            </td>
          </tr>
        </table>
        <div class="button-aceptar">
          <button [disabled]="!fundsOriginForm.valid" class="sb-alert--button" tappable (click)="acceptClick()">
            <span class="aceptarText"> {{'tAccept' | translate}} </span>
          </button>
        </div>
      </div>
    </form>
  </div>
</ion-content>
