<ion-card>
  <ion-card-content>
    <div class="my-bets-tile--bets" [ngClass]="{ collapsed: !expanded }">
      <ion-grid class="my-bets-tile--bets-grid">
        <ion-row>
          <ion-col>
            <span class="my-bets-tile--bets-top">
              Apuesta {{ ticket?.BetType }}
              <span *ngIf="showTicketStatus(ticket?.BetTypeNumber)">({{ ticket?.X }}/{{ ticket?.Y }})</span>
            </span>
          </ion-col>
          <ion-col size="3" class="my-bets-tile--bets-top-fixed-col">
            <span
              class="my-bets-tile--bets-top text-right status-text bold"
              [ngClass]="getTicketStatusColor(+ticket?.TicketStatusNumber)"
            >
              {{ ticket?.TicketStatus }}
            </span>
          </ion-col>
        </ion-row>

        <ion-list class="my-bets-tile--bets-rows">
          <ion-item
            *ngFor="let betevent of ticket?.BetEvent"
            lines="none"
            [ngClass]="{ collapsed: !expanded }"
            class="sportsbook"
          >
            <!-- line SIMPLE AND ACCUMULATOR-->
            <div
              class="my-bets-tile--bets-rows-block"
              *ngIf="!betevent?.IsBetbuilder"
              (click)="onClickGoToEvent(betevent)"
            >
              <p class="my-bets-tile--bets-rows-line">
                <span class="first-line text-right">
                  <b> {{ betevent?.ResultName }} </b>
                  {{ betevent?.Odd | parseMoney }}
                </span>
                <span
                  class="status-text text-right"
                  [ngClass]="getTicketStatusColor(+betevent?.ClearingStatusNumber)"
                  *ngIf="showTicketStatus(ticket?.BetTypeNumber)"
                  >{{ betevent?.ClearingStatus }}</span
                >
              </p>
              <p class="my-bets-tile--bets-rows-line">
                <span class="my-bets-tile--bets-rows-text">
                  {{ betevent?.MarketName }}
                </span>
              </p>
              <p class="my-bets-tile--bets-rows-line">
                <span class="my-bets-tile--bets-rows-text">
                  {{ betevent?.EventName }}
                </span>
              </p>
            </div>

            <!-- Betbuilder line -->
            <div
              class="my-bets-tile--bets-rows-block"
              *ngIf="betevent?.IsBetbuilder"
              (click)="onClickGoToEvent(betevent)"
            >
              <span
                *ngFor="let betBuilderItem of betevent?.BetBuilderItems; first as isFirst; last as isLast"
                class="my-bets-tile--betbuilder--content-left-ball-container"
              >
                <div
                  class="my-bets-tile--betbuilder--content-left-ball-wrapper"
                  [ngClass]="{ 'first-element': isFirst, 'last-element': isLast }"
                >
                  <img
                    class="my-bets-tile--betbuilder--content-left-ball"
                    src="assets/global/img/sb-betbuilderItemDark.svg"
                  />
                </div>
                <div class="my-bets-tile--betbuilder--content-left-ball-item">
                  <span
                    class="my-bets-tile--betbuilder--content-bets-rows-line-title color-muted"
                    [ngClass]="{ 'first-row': isFirst }"
                  >
                    {{ betBuilderItem?.ResultName }}&nbsp;
                  </span>
                  <span
                    class="my-bets-tile--betbuilder--content-bets-rows-line-text color-muted"
                    [ngClass]="{ 'first-row': isFirst }"
                  >
                    - {{ betBuilderItem?.MarketName }}
                  </span>
                  <span
                    class="my-bets-tile--betbuilder--content-bets-rows-line-odds"
                    *ngIf="isFirst"
                    [ngClass]="{ 'first-row': isFirst }"
                  >
                    &nbsp;{{ getQuota(ticket) + '' | formatOdd }}</span
                  >
                </div>
              </span>
              <p class="my-bets-tile--betbuilder--content-bets-rows-line">{{ betevent?.EventName }}</p>
            </div>
          </ion-item>
        </ion-list>
        <!-- end lines -->
      </ion-grid>
    </div>

    <div class="my-bets-tile--footer">
      <div class="button-space" *ngIf="!showExpandButton(ticket)"></div>
      <ion-button
        mode="md"
        class="my-bets-tile--footer-expand sportsbook btn-wide"
        *ngIf="showExpandButton(ticket)"
        (click)="expandTicket()"
      >
        <span class="my-bets-tile--footer-expand-text" *ngIf="!expanded"
          >Ver todo <ion-icon name="chevron-down"></ion-icon
        ></span>
        <span class="my-bets-tile--footer-expand-text" *ngIf="expanded"
          >Ocultar <ion-icon name="chevron-up"></ion-icon
        ></span>
      </ion-button>

      <ion-grid class="my-bets-tile--footer-grid">
        <ion-row>
          <ion-col>
            <span class="my-bets-tile--footer-stake"> Total apuestas </span>
          </ion-col>
          <ion-col>
            <span class="my-bets-tile--footer-stake text-right">
              {{ ticket?.Stake | parseMoney }}
            </span>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col>
            <div [ngSwitch]="+ticket?.TicketStatusNumber">
              <span *ngSwitchCase="0" class="my-bets-tile--footer-stake"> {{ ticket?.TicketStatus }} </span>
              <span *ngSwitchCase="5" class="my-bets-tile--footer-stake sportsbook-color-progress">
                {{ ticket?.TicketStatus }}
              </span>
              <span *ngSwitchDefault class="my-bets-tile--footer-stake">Ganancia</span>
            </div>
          </ion-col>
          <ion-col>
            <span
              class="my-bets-tile--footer-stake text-right"
              [ngClass]="{ 'sportsbook-color-progress': +ticket?.TicketStatusNumber == 5 }"
            >
              {{ ticket?.PotentialWinnings | parseMoney }}
            </span>
          </ion-col>
        </ion-row>

        <ion-row class="my-bets-tile--footer-payment-row">
          <ion-col>
            <div *ngIf="ticket?.TicketStatusNumber == 4" class="my-bets-tile--footer-payment">
              <ion-button
                mode="md"
                *ngIf="ticket?.StatusCode === 0 && !ticket?.HasBetbuilder && ticket?.CashOutAmount != 0"
                class="my-bets-tile--footer-button sportsbook"
                (click)="payOutTicket(ticket)"
              >
                Cobrar: {{ ticket?.CashOutAmount / 100 | parseMoney }}
              </ion-button>
              <ion-button
                mode="md"
                *ngIf="
                  ticket?.StatusCode === 0 &&
                  !ticket?.HasBetbuilder &&
                  ticket?.CashOutStatus >= 0 &&
                  ticket?.CashOutAmount == 0
                "
                class="my-bets-tile--footer-button btn-button sportsbook"
                (click)="getCashout(ticket)"
              >
                Cobrar
              </ion-button>
              <ion-button
                mode="md"
                *ngIf="ticket?.StatusCode === 0 && ticket?.CashOutStatus < 0"
                class="my-bets-tile--footer-button btn-danger sportsbook disabled text-small"
                (click)="getCashout(ticket)"
                disabled
              >
                <ion-label class="sportsbook text-small"> Cobrar: No Disponible </ion-label>
              </ion-button>
            </div>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col>
            <span class="my-bets-tile--footer-date-time">
              {{ ticket?.TicketDatetime | CodereFormatDate: [0] }}
            </span>
          </ion-col>
          <ion-col>
            <span class="my-bets-tile--footer-date-time text-right">
              Ticket:{{ ticket?.Pin }}{{ ticket?.TicketNumber }}
            </span>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </ion-card-content>
</ion-card>
