<ion-content class="is-modal modal-user-message">
  <div class="contModal">
    <button class="closeModal" (click)="closeModal()">
      <ion-icon ios="ios-close" md="ios-close"></ion-icon>
    </button>
    <div class="modal-message-date">
      <small>{{message.creationDate | date : 'd/M/yyyy' }} {{message.creationDate | date : 'h:mm' }}</small>
    </div>
    <div class="modal-message-subject">
      <span class="modal-user-headlines"
        ><ion-icon class="marginr5" ios="ios-chatboxes" md="md-chatboxes"></ion-icon>{{ 'tSubject' | translate }}</span
      >
      <div class="modal-user-content">{{ message.subject }}</div>
    </div>
    <div class="modal-message-question">
      <span class="modal-user-headlines"
        ><ion-icon class="marginr5" ios="ios-help" md="md-help"></ion-icon> {{ 'tQuestion' | translate }}</span
      >
    </div>
    <div class="modal-message-answer">
      <span class="modal-user-headlines">
        <ion-icon class="marginr5" ios="ios-information-circle" md="md-information-circle"></ion-icon> {{ 'tAnswer' |
        translate }}</span
      >
      <div [innerHtml]="message.description" class="modal-user-content"></div>
    </div>
  </div>
</ion-content>
