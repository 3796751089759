<div class="alert-wrapper background-color-white">
  <div *ngIf="intensivePlayer; else noIntensivePlayer">
    <div class="message-icon">
      <img src="assets/global/img/icon-circle-info.svg" />
    </div>
    <div class="text-center margin-top-10">
      <span class="sb-alert--title color-alert" [ngClass]="{'intensive-player' : intensivePlayer}"
        >{{ options?.paymentArguments?.title | translate}}</span
      >
    </div>
    <p *ngIf="showVer">
      <span>{{'chargepaymentKo' | translate}}<b (click)="goVerDoc()"> {{'verifdocument02' | translate}}</b>.</span>
    </p>
    <p>
      <span>{{'noCreditCardDepositAllowed' | translate}}</span>
    </p>
    <p *ngIf="globalVars?.licenseType == 4">{{'depositPaymentKo' | translate}}</p>
  </div>

  <ng-template #noIntensivePlayer>
    <div class="text-center margin-top-10">
      <span class="sb-alert--title color-alert" [ngClass]="{'intensive-player' : intensivePlayer}"
        >{{ options?.paymentArguments?.title | translate}}</span
      >
    </div>
    <div class="cs-pending-payments--row">
      <p *ngIf="options?.paymentArguments?.error ==''">{{ options?.paymentArguments?.subtitle | translate}}</p>
      <p *ngIf="options?.paymentArguments?.error !=''">{{ decoder }}</p>
    </div>
    <p *ngIf="showVer">
      <span>{{'chargepaymentKo' | translate}}<b (click)="goVerDoc()"> {{'verifdocument02' | translate}}</b>.</span>
    </p>
    <p *ngIf="globalVars?.licenseType == 4">{{'depositPaymentKo' | translate}}</p>
    <div class="cs-pending-payments--row2">
      <span>{{'tContac' | translate}}:</span>
      <a href="mailto:apuestas@codere.com">apuestas@codere.com</a>
    </div>
  </ng-template>

  <div class="alert-button--column-group payments text-center">
    <button
      [ngClass]="{'btn-verify' : intensivePlayer,'sb-alert--button' : !intensivePlayer}"
      tappable
      (click)="acceptClick()"
    >
      {{'tAccept' | translate}}
    </button>
  </div>
</div>
