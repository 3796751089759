<div class="image-filter-group">
  <ion-card
    *ngFor="let filter of filterGroup.filters"
    class="image-filter-item"
    (click)="selectFilter(filter)"
    [class.selected]="filter.selected"
  >
    <ion-card-content class="image-filter-content">
      <img [src]="filter.image" class="image-filter-image" [alt]="filter.text" />
      <div class="image-filter-text">({{ filter.count || 0 }})</div>
    </ion-card-content>
  </ion-card>
</div>
