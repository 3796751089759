<div *ngIf="fControl?.invalid && fControl?.dirty" class="control-error">
  <ng-container *ngFor="let eKey of listErrors">
    <ion-label color="danger" class="form-error" *ngIf="fControl.hasError(eKey)">
      <span [innerHTML]="errorsCol[eKey]"></span>
      <span *ngIf="eKey === 'validPassword'">
        {{ passwErrorsTexts[fControl.errors['validPassword']] }}
      </span>
    </ion-label>
  </ng-container>
</div>
