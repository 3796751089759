import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'docTypeFilter' })
export class DocTypeFilter implements PipeTransform {
  transform(value: string): string {
    // DNINIE = 754310000
    // Justificantebancario = 754310001
    // Otros = 754310002
    ////---
    // Anverso = 754310000
    // Reverso = 754310001

    let auxName: string;
    if (value === null) {
      auxName = '';
    }
    switch (value.toString()) {
      case '754310000':
        auxName = 'DNI/NIE';
        break;

      case '754310001':
        auxName = 'Justificante Bancario';
        break;

      case '754310002':
        auxName = 'Otros';
        break;
    }
    return auxName;
  }
}
