<p><b>Si lo prefieres haznos llegar tu consulta a través de un mensaje</b></p>
<textarea class="contact-textarea" [placeholder]="placeholder" [(ngModel)]="textarea"></textarea>
<div class="contact-credentials" *ngIf="askCredentials">
  <div class="contact-credentials__input">
    <label> {{'tContactUsername' | translate}} </label>
    <div>
      <input [(ngModel)]="username" />
      <small> </small>
    </div>
  </div>
  <div class="contact-credentials__input">
    <label> {{'tContactEmail' | translate}} </label>
    <div>
      <input [(ngModel)]="email" />
      <small *ngIf="emailError">{{ 'tEmailFormatError' | translate }} </small>
    </div>
  </div>
</div>
<button class="btn-contact-send" (click)="submitTextarea()" [ngClass]="{'disabled': textarea.length<=0}">
  {{'tContactSend' | translate }}
</button>
