import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, Component, ViewChild, inject } from "@angular/core";
import {
  FormBuilder,
  type FormControl,
  type FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { IonContent, IonicModule, type ModalController, Platform } from "@ionic/angular";
import { LicenseTypes } from "@models/MasterData";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { EventsService } from "@providers/EventsService";
import { NativeService } from "@providers/NativeService";
import { TrackingService } from "@providers/TrackingService";
import { UserService } from "@providers/UserService";
import { NewBaseService } from "@providers/newBase.service";
import { DeviceService } from "@services/device.service";
import { CodereNavbar } from "@shared-common-components/codere-navbar/codere-navbar";
import { PAYMENTS_PATHS } from "@shared-constants/routes";
import { CodereFooterComponent } from "@shared-mso-components/footer/codere-footer/codere-footer";
import { UserSectionTitle } from "@shared-mso-components/user-section-title/user-section-title";
import { Utils } from "@utils/Utils";

interface Model {
  numTicket: string;
  captcha: string;
  ccaa: string;
}

interface DeveloperMode {
  offsetClicks: number;
  offsetTime: number;
  numClicks: number;
  lastClick: Date;
}

@Component({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  selector: "check-ticket-page",
  templateUrl: "./check-ticket.html",
  styleUrls: ["./check-ticket.scss"],
  standalone: true,
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    CodereFooterComponent,
    CodereNavbar,
    UserSectionTitle,
  ],
})
export class CheckTicketPage {
  @ViewChild(IonContent) content: IonContent;
  viewCtrl: ModalController;
  captchaUrl: string;

  formDepTicket: FormGroup;
  model: Model;

  numticketvalido = true;
  validCaptcha = true;
  messageErrorCaptcha = "";
  activateDeveloperMode: DeveloperMode;
  isDesktop: boolean;
  isMobile: boolean;
  globalVars: GlobalVarsModel;

  router = inject(Router);
  platform = inject(Platform);
  trackingService = inject(TrackingService);
  userService = inject(UserService);
  nativeService = inject(NativeService);
  translate = inject(TranslateService);
  utils = inject(Utils);
  fb = inject(FormBuilder);
  events = inject(EventsService);
  deviceService = inject(DeviceService);
  newBaseService = inject(NewBaseService);

  constructor() {
    this.isMobile = this.deviceService.isMobile();
    this.isDesktop = this.deviceService.isDesktop();

    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });

    this.activateDeveloperMode = {
      offsetClicks: 5,
      offsetTime: 500,
      numClicks: 0,
      lastClick: new Date(),
    };
    this.newCaptcha();

    if (
      this.globalVars.LICENSE_TYPE === LicenseTypes.Mexico ||
      this.globalVars.LICENSE_TYPE === LicenseTypes.Colombia ||
      this.globalVars.LICENSE_TYPE === LicenseTypes.Panama
    ) {
      this.formDepTicket = this.fb.group({
        numTicket: [
          "",
          {
            validators: [Validators.required, Validators.minLength(8)],
          },
        ],
        captcha: [
          "",
          {
            validators: [Validators.required],
          },
        ],
        ccaa: [""],
      });
    } else {
      this.formDepTicket = this.fb.group({
        numTicket: [
          "",
          {
            validators: [Validators.required, Validators.minLength(8)],
          },
        ],
        captcha: [
          "",
          {
            validators: [Validators.required],
          },
        ],
        ccaa: ["", { validators: [Validators.required] }],
      });
    }
  }

  ionViewWillEnter() {
    this.trackingService.trackEvent(["AccessToViewTicket", "", "", "Consulta ticket en menu usuario", "event"]);
    this.events.publish("footer:refresh", this.content);
  }

  newCaptcha() {
    this.captchaUrl = this.globalVars.URLBASE + "/ViewTicket/CaptchaImage?_=" + Math.floor(Math.random() * 99999);
  }

  activateChanger() {
    //calls to native function
    this.nativeService.activateUrlChangerClick();
  }

  async scanTicket() {
    if (!this.platform.is("capacitor")) {
      this.nativeService.scanTicket((code) => {
        //returns the code here
        this.model.numTicket = code;
        this.formDepTicket.patchValue({
          numTicket: code,
        });
      });
    } else {
      const options = {
        preferFrontCamera: false,
        showTorchButton: true,
        torchOn: false,
        saveHistory: false,
        prompt: "Escanear código",
        resultDisplayDuration: 0,
        orientation: "portrait",
        disableAnimations: false,
        disableSuccessBeep: true,
      };
      const code = await this.utils.startBarcodescanner();
      this.model.numTicket = code;
      this.formDepTicket.patchValue({
        numTicket: code,
      });
    }
  }

  scanTicketExt(code: string) {
    this.model.numTicket = code;
    this.formDepTicket.patchValue({
      numTicket: code,
    });
  }

  sendTicket() {
    this.numticketvalido = true;
    this.globalVars.licenseTicketType = "";
    this.model = this.formDepTicket.value;
    if (this.formDepTicket.valid) {
      const systemid: string = this.model.ccaa.split("_")[1];

      this.userService.loadTicketCaptcha(this.model.numTicket, this.model.captcha).subscribe({
        next: (captchaTicket) => {
          //Error en el captcha siempre devuelve status ko
          //if (captchaTicket.success) {
          this.validCaptcha = true;
          this.messageErrorCaptcha = "";
          this.userService.getticket(systemid, this.model.numTicket, this.model.captcha).subscribe({
            next: (ticket) => {
              if (ticket) {
                this.router.navigate([`${PAYMENTS_PATHS.BASE}/${PAYMENTS_PATHS.CheckTicketStep2Page}`], {
                  state: {
                    ticket: this.model.numTicket,
                    betHistory: ticket,
                  },
                });
              } else {
                this.numticketvalido = false;
              }
            },
            error: (_err) => {
              this.numticketvalido = false;
            },
          });
          //Tambien se comenta el else
          // } else {
          //   this.validCaptcha = false;
          //   this.messageErrorCaptcha = captchaTicket.message;
          // }
        },
        error: (_err) => {
          this.validCaptcha = false;
          this.messageErrorCaptcha = this.translate.instant("msgErrorCaptcha");
        },
      });
    } else {
      // this.utils.alert(true, "Deposit Ticket", "Revisa que todos los campos esten bien rellenos", textBtOk, null);
      for (const x in this.formDepTicket.controls) {
        this.formDepTicket.controls[x].markAsTouched();
      }
    }
  }

  openHelpNumTicket() {
    this.utils.showHelpNumTicket();
  }

  validFormatNumTicket = (control: FormControl) => {
    const regExpPhone = /^[0-9]{8}$/;
    if (regExpPhone.test(control.value)) {
      return null;
    } else {
      return { validFormatNumTicket: "notValid" };
    }
  };

  tryActivateAdmin() {
    this.activateDeveloperMode.numClicks++;
    if (this.activateDeveloperMode.numClicks === 1) {
      this.activateDeveloperMode.lastClick = new Date();
    } else {
      if (
        new Date().getTime() - this.activateDeveloperMode.lastClick.getTime() <
        this.activateDeveloperMode.offsetTime
      ) {
        //valid click
        this.activateDeveloperMode.numClicks++;
      } else {
        this.activateDeveloperMode.numClicks = 0;
      }
    }

    if (this.activateDeveloperMode.numClicks > this.activateDeveloperMode.offsetClicks) {
      this.activateChanger();
    }
  }
}
