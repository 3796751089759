export class C_DepositPayment {
  Fecha: string;
  Operator: any;
  Referencia: string;
  Cantidad: string;
  CantidadNeta: string;
  CantidadBruta: string;
  CantidadImpuestos: string;
  Estado: string;
  Priority: number;
  Metodo: string;
  Cuenta: string;
  DrawId: string;
  PartialWidthdrawDrawId: string;

  constructor() {}
}

export class C_DepositPaymentDateList {
  DepositPayments: Array<C_DepositPayment>;
  Date: any;

  constructor() {}
}

//export = C_DepositPaymentDateList
