<ng-container *ngIf="!isMobile; else mobileView">
  <ion-content class="has-titleBar background-color-muted">
    <user-section-title [sectionTitle]="'InviteFriend'"></user-section-title>

    <h1 class="single-title-bar is-text-uppercase"></h1>
    <div class="is-narrow-content">
      <ion-list class="user-list boxpcaddfriend">
        <div class="imgaddfriend">
          <img src="assets/global/img/addfriendIcon.png" class="marginb2" />
        </div>
        <div class="subtitleaddfriend">
          <span
            >{{ 'InviteFriendtxt01' | translate }}
            <b>{{ 'InviteFriendtxt011' | translate }}</b>
            <!--¡Trae a un colega y llevaos cada uno una <b>FREEBET de 10€ GRATIS!--></span
          >
        </div>
        <ion-row class="padding010por">
          <ion-col width-30="">
            <div class="boxaddfriend">
              <div class="greenball">1</div>
              <div class="padddingt5">
                {{ 'InviteFriendtxt02' | translate }}
                <!--Comparte tu código personal con tus amigos-->
              </div>
              <div class="fontblue">
                {{ 'InviteFriendtxt03' | translate | uppercase }}:
                <!--TU CÓDIGO:-->
              </div>
              <div class="font27rem">{{ friendsID }}</div>
            </div>
          </ion-col>
          <hr class="h" />
          <ion-col width-30="">
            <div class="boxaddfriend">
              <div class="greenball">2</div>
              <div class="padddingt5">
                {{ 'InviteFriendtxt04' | translate }}
                <!--Sin límite de amigos. Ellos recibirán 1 FREEBET de 10€ después de registrarse con tu código y realizar una apuesta de 10€-->
              </div>
            </div>
          </ion-col>
          <hr class="h" />
          <ion-col width-30="">
            <div class="boxaddfriend">
              <div class="greenball">3</div>
              <div class="padddingt5">
                {{ 'InviteFriendtxt05' | translate }}
                <!--Cuando cada amigo tuyo realice la apuesta, ¡una FREEBET de 10€ será tuya!-->
              </div>
            </div>
          </ion-col>
        </ion-row>
        <div class="textcondi">
          <a href="https://www.codere.es/PromocionesTYC/Invita_a_un_amigo.html" target="_blank" class="textlink"
            >{{ 'InviteFriendtxt06' | translate }}
            <!--Términos y condiciones-->
          </a>
        </div>
        <div class="goReg goregbtn">
          <button ion-button full (click)="openShareFriend()" class="font19rem btn-addFriend">
            {{ 'InviteFriendtxt07' | translate }}
            <!--¡INVITAR AHORA!-->
          </button>
        </div>
      </ion-list>
    </div>
    <codere-footer></codere-footer>
  </ion-content>
</ng-container>

<!-- Mobile -->
<ng-template #mobileView>
  <ion-header>
    <!-- <codere-navbar titulo="Preferencias"></codere-navbar> -->
    <user-section-title [sectionTitle]="'Invita a un amigo'"></user-section-title>
  </ion-header>

  <ion-content class="background-color-muted" [ngClass]="{'no-scroll': globalVars?.iosScrollHide}">
    <div class="wAddFriend">
      <ion-list class="user-list padding10por">
        <div class="imgaddfriend">
          <img src="assets/global/img/addfriendIcon.png" class="marginb2" />
        </div>
        <div class="subtitleaddfriend">
          <span
            >{{ 'InviteFriendtxt01' | translate }} <b>{{ 'InviteFriendtxt011' | translate }}</b
            ><!--¡Trae a un colega y llevaos cada uno una <b>FREEBET de 10€ GRATIS!</b>-->
          </span>
        </div>
        <div class="boxaddfriend">
          <div class="greenball">1</div>
          <div class="padddingt5">
            {{ 'InviteFriendtxt02' | translate }}<!--Comparte tu código personal con tus amigos.-->
          </div>
          <div class="fontblue">{{ 'InviteFriendtxt03' | translate | uppercase }}:<!--TU CÓDIGO:--></div>
          <div class="font27rem">{{ friendsID }}</div>
        </div>
        <hr class="v" />
        <div class="boxaddfriend">
          <div class="greenball">2</div>
          <div class="padddingt5">
            {{ 'InviteFriendtxt04' | translate }}<!--Sin límite de amigos. Ellos recibirán 1 FREEBET de 10€ después de registrarse con tu código y realizar una apuesta de 10€-->
          </div>
        </div>
        <hr class="v" />
        <div class="boxaddfriend">
          <div class="greenball">3</div>
          <div class="padddingt5">
            {{ 'InviteFriendtxt05' | translate }}<!--Cuando cada amigo tuyo realice la apuesta, ¡una FREEBET de 10€ será tuya!-->
          </div>
        </div>
        <div class="textcondi">
          <a href="https://www.codere.es/PromocionesTYC/Invita_a_un_amigo.html" target="_blank" class="textlink"
            >{{ 'InviteFriendtxt06' | translate }}<!--Términos y condiciones--></a
          >
        </div>
        <div class="goReg marginb20">
          <button ion-button full class="btn-addFriend" (click)="openShareFriend()">
            {{ 'InviteFriendtxt07' | translate }}<!--¡INVITAR AHORA!-->
          </button>
        </div>
      </ion-list>
    </div>

    <codere-footer></codere-footer>
  </ion-content>
</ng-template>
