/* eslint-disable eqeqeq */
import { CommonModule } from "@angular/common";
import { Component, HostBinding, inject } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { TranslateModule } from "@ngx-translate/core";
import { FilterItem } from "@sports-models/index";
import { ApplicableAccaBonus } from "@sports-models/index";
import { NewTicketFacade } from "@sports-stores/ticket/index";
import { ticketState } from "@sports-stores/ticket/ticket.reducers";
import * as ticketSelector from "@sports-stores/ticket/ticket.selectors";
import { Subscription } from "rxjs";

@Component({
  selector: "ticket-bonus",
  templateUrl: "./ticket-bonus.html",
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule, TranslateModule],
  styleUrls: ["./ticket-bonus.scss"],
})
export class TicketBonusComponent {
  bonusSub: Subscription;
  filterSub: Subscription;

  selectedFilter: FilterItem;
  currentPercentage: number;

  newTicketFacade = inject(NewTicketFacade);
  store = inject(Store<ticketState>);

  constructor() {
    this.bonusSub = this.store
      .select(ticketSelector.getApplicableAccaBonus)
      .subscribe((applicableAccaBonus: ApplicableAccaBonus) => {
        this.currentPercentage =
          applicableAccaBonus.maxBonusRate > 0 ? Math.round(applicableAccaBonus.maxBonusRate * 100 - 100) : 0;
      });

    this.filterSub = this.store.select(ticketSelector.getFilters).subscribe((filters) => {
      this.selectedFilter = filters;
    });
  }

  @HostBinding("style.display")
  get display(): string {
    const mustShow = this.currentPercentage > 0 && this.selectedFilter.value === 1;
    return mustShow ? "block" : "none";
  }

  openBonus() {
    this.newTicketFacade.openBonus();
  }

  ngOnDestroy() {
    this.bonusSub.unsubscribe();
    this.filterSub.unsubscribe();
  }
}
