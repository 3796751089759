import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'lb-counter-item',
  imports: [CommonModule],
  templateUrl: './jackpot-counter-item.html',
  styleUrls: ['./jackpot-counter-item.scss'],
  standalone: true
  // host: {
  //   class: 'lb-counter--item',
  // },
})
export class JackpotCounterItemComponent {
  @Input() currentItem: any;
  @Input() position: any;
  @Input() minSize: boolean = false;

  customItem: any;

  constructor() {}
}
