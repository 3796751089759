import { ApplicableAccaBonus } from "@sports-models/index";

export const newApplicableAccaBonus = (): ApplicableAccaBonus => ({
  BonusId: 0,
  maxBonusRate: 0,
  BonusAmount: 0,
});

export const mapBonus = (bonusData): ApplicableAccaBonus => ({
  BonusId: bonusData.bonusOfferId || 0,
  maxBonusRate: bonusData.maxBonusRate || 0,
  BonusAmount: bonusData.maxBonusReturnNet || 0,
});
