<section class="background-color-dark" *ngIf="ticket?.OveraskInfo?.Status !== 'OK'">
  <!-- Overask Status 0 // Aprobación Requerida HEADER START -->
  <ion-grid *ngIf="getTicketIsConfirmed(ticket) || +ticket?.OveraskInfo?.Status === 0">
    <ion-row>
      <ion-col> 1</ion-col>
      <ion-col> 2</ion-col>
    </ion-row>
    <ion-row>
      <ion-col> 3</ion-col>
      <ion-col> 4</ion-col>
    </ion-row>
  </ion-grid>
</section>
