/* eslint-disable */
import { Platform, NavController, ModalController, IonicModule, IonContent } from '@ionic/angular';
import { Component, ViewChild, inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { UserService } from '@providers/UserService';
import { NativeService } from '@providers/NativeService';
import { CodereNavbar } from '@shared-common-components/codere-navbar/codere-navbar';
import { EventsService } from '@providers/EventsService';
import { GlobalVarsModel } from '@models/ModelGlobalsVar';
import { NewBaseService } from '@providers/newBase.service';
//import relativo por dependecias ciclicas
import { CodereFooterComponent } from '@shared-mso-components/footer/codere-footer/codere-footer';

import { TrackingService } from '@providers/TrackingService';
import { Utils } from '@utils/Utils';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'playsst-page',
  templateUrl: './playsst.html',
  styleUrls: ['./playsst.scss'],
  standalone: true,
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    TranslateModule,
    ReactiveFormsModule,
    CodereNavbar,
    CodereFooterComponent
  ]
})
export class PlaySstPage {
  @ViewChild('content', { static: false }) content: IonContent;
  viewCtrl!: ModalController;
  captchaUrl!: string;
  isPlaying: boolean;
  formNumber: FormGroup;
  model: any = { number: '' };
  globalVars!: GlobalVarsModel;

  private trackingService = inject(TrackingService);
  public nativeService = inject(NativeService);
  public translate = inject(TranslateService);
  public nav = inject(NavController);
  public utils = inject(Utils);
  public userService = inject(UserService);
  public fb = inject(FormBuilder);
  public platform = inject(Platform);
  public events = inject(EventsService);
  public newBaseService = inject(NewBaseService);

  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });

    this.isPlaying = false;
    this.formNumber = this.fb.group({
      number: ['', Validators.required]
    });
  }

  ionViewWillEnter() {
    this.trackingService.trackEvent(['AccessToPlaySstPage', '', '', 'PlaySstPage en menu usuario', 'event']);
    //refresh footer
    this.events.publish('footer:refresh', this.content);
  }

  async scanTicket() {
    if (!this.platform.is('cordova')) {
      //eslint-disable-next-line
      let vm = this;
      this.nativeService.scanTicketPlaySST(function (code: any) {
        //returns the code here

        vm.model.number = code;
        vm.sendCode();
      });
    } else {
      //Cordova BarcodeScanner
      const options = {
        preferFrontCamera: false,
        showTorchButton: true,
        torchOn: false,
        saveHistory: false,
        prompt: 'Escanear código',
        resultDisplayDuration: 0,
        orientation: 'portrait',
        disableAnimations: false,
        disableSuccessBeep: true
      };
      const code = await this.utils.startBarcodescanner();
      this.model.number = code;
      this.sendCode();
    }
  }

  sendCode() {
    if (this.formNumber.valid) {
      //cambio a nueva funcion llamada a
      this.userService.loadUrlSST(this.model.number).subscribe(
        (data) => {
          if (!!data && data == 'OK') {
            this.utils.alert(
              false,
              'Conectado',
              'Conexi&oacute;n Correcta, en unos segundos podr&aacute; disfrutar en la pantalla de su local',
              'OK'
            );
            this.isPlaying = true;
          } else {
            this.utils.alert(true, 'Error', 'No hemos podido conectarle a su local', 'OK');
          }
        },
        (err) => {
          this.utils.alert(true, 'Error', 'No hemos podido conectarle a su local', 'OK');
        }
      );
    } else {
      // this.utils.alert(true, "Deposit Ticket", "Revisa que todos los campos esten bien rellenos", textBtOk, null);
      for (const x in this.formNumber.controls) {
        this.formNumber.controls[x].markAsTouched();
      }
    }
  }
}
