<div class="containerP">
  <div class="texto">
    <div class="marginb15"><span [innerHTML]="'revocation_se_txt' | translate" class="txtspan"></span></div>

    <div class="btncenter">
      <ion-button (click)="doRevocation()" class="btnwhite" [class.padding2070]="isDesktop" shape="round">
        {{'revocation_se_btn' | translate }} <span class="codere-icon icon-arrow-right"></span>
      </ion-button>
    </div>
  </div>
</div>
