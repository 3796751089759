export class C_EventPageLeague {
  CountryCode: string;
  hasBackButton: boolean;
  Name: string;
  NodeId: string;
  SportHandle: string;
  SportName: string;
  constructor({ CountryCode, hasBackButton, Name, NodeId, SportHandle, SportName }) {
    this.CountryCode = CountryCode;
    this.hasBackButton = hasBackButton;
    this.Name = Name;
    this.NodeId = NodeId;
    this.SportHandle = SportHandle;
    this.SportName = SportName;
  }
}
