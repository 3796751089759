/* eslint-disable eqeqeq */
import { IonContent, IonicModule, NavController } from '@ionic/angular';
import { ViewChild, Component, inject } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { Utils } from '../../../utils/Utils';
import { CookiesService } from '../../../providers/CookiesService';
import { TrackingService } from '../../../providers/TrackingService';
import { CookieNames } from '../../../models/CookieNames';
import { DeviceService } from '../../../services/device.service';
import { GlobalVarsModel } from '../../../models/ModelGlobalsVar';
import { MSO_PATHS, PAGES_PATHS } from '../../../lib/constants/routes';
import { NewBaseService } from '../../../providers/newBase.service';
import { LanguageService } from '../../../providers/LanguageService';
import { EventsService } from '../../../providers/EventsService';
import { CodereFooterComponent } from '../../../components/mso/footer/codere-footer/codere-footer';
import { UserSectionTitle } from '../../../components/mso/user-section-title/user-section-title';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LicenseTypes } from '../../../models/MasterData';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'lenguage-page',
  templateUrl: './language.html',
  styleUrls: ['./language.scss'],
  standalone: true,
  imports: [IonicModule, FormsModule, ReactiveFormsModule, CodereFooterComponent, CommonModule, UserSectionTitle]
})
export class LanguagePage {
  @ViewChild(IonContent) content: IonContent;
  selectedLanguage: string;
  globalVars!: GlobalVarsModel;
  LicenseTypes = LicenseTypes;
  isMobile: boolean;

  utils = inject(Utils);
  nav = inject(NavController);
  languageService = inject(LanguageService);
  trackingService = inject(TrackingService);
  newBaseService = inject(NewBaseService);
  events = inject(EventsService);
  cookiesService = inject(CookiesService);
  router = inject(Router);
  device = inject(DeviceService);
  translate = inject(TranslateService);

  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
      this.selectedLanguage = this.globalVars.Idioma;
    });
    this.isMobile = this.device.isMobile();
  }

  ionViewWillEnter() {
    this.router.navigate([MSO_PATHS.BASE + '/' + MSO_PATHS.LanguagePage]);
    this.trackingService.trackEvent(['AccessToLanguage', '', '', 'Acceder a idioma', 'event']);
    this.events.publish('footer:refresh', this.content);
  }

  changeLanguage() {
    if (this.selectedLanguage != this.globalVars.Idioma) {
      this.globalVars.Idioma = this.selectedLanguage;

      this.cookiesService.setCookie(CookieNames.Idioma, this.globalVars.Idioma, 365 * 24 * 60 * 60 * 1000);

      this.languageService.getTranslation(this.globalVars.Idioma).subscribe(() => {
        // After the translations have been loaded, change the language
        this.translate.use(this.globalVars.Idioma).subscribe(() => {
          // Language change successful. Navigate to home page and update menu
          this.router.navigate([PAGES_PATHS.BASE + '/' + PAGES_PATHS.HomePage]);
        });
      });
    }
  }
}
