import { StakeGroup } from './stake.model';

export interface filterItem {
  name: string;
  value: number;
  betType: string;
  selected: boolean;
  hidden: boolean;
  disabled: boolean;
}

export const _getFilters = () => {
  const filters = [
    {
      name: 'tSen',
      value: 0,
      betType: StakeGroup.SINGLES,
      systemId: 1,
      selected: true,
      hidden: isBetTypeHidden('0'),
      disabled: false
    },
    {
      name: 'tAcu',
      value: 1,
      betType: StakeGroup.ACCUMULATOR,
      systemId: null,
      selected: false,
      hidden: isBetTypeHidden('1'),
      disabled: true
    },
    {
      name: 'tMul',
      value: 2,
      betType: StakeGroup.SYSTEM,
      systemId: null,
      selected: false,
      hidden: isBetTypeHidden('2'),
      disabled: true
    }
  ];
  return filters;
};

function isBetTypeHidden(betType: string): boolean {
  switch (parseInt(betType)) {
    case 0:
      return false;
    case 1:
      return false; //ticket.MultipleChoice; // ??
    case 2:
      return false;
    // return this._globalVars.licenseType === 0;
    // case 3: // **+** Comentado por errores
    //   return !this.ticketBetSlip.possibleSystems;
  }
}
