<ng-container *ngIf="!isMobile; else mobileView">
  <!-- eslint-disable @angular-eslint/template/eqeqeq -->
  <ion-content *ngIf="globalVars.FEATURES?.messageServiceEnabled">
    <ion-grid *ngIf="!loading" class="user-message-grid">
      <ion-row class="toolbar-content message-help">
        <ion-col size="1">
          <i class="sb-back-button color-light codere-icon icon-arrow-left" tappable="" (click)="openContact()"></i>
        </ion-col>
        <ion-col>
          <div class="titlemsg">
            <span *ngIf="messageCount > 0"
              >{{ 'tMessages' | translate }} ({{ 'tUnreadMessages' | translate }} : {{ messageCount }})</span
            >
            <span *ngIf="messageCount == 0 || !messageCount">{{ 'tMessages' | translate }}</span>
          </div>
        </ion-col>
      </ion-row>
      <ng-container *ngIf="messages.length; else noMessagesTemplate">
        <ion-row
          *ngFor="let message of messages; let i = index"
          [ngClass]="{'user-message-row': true, 'message-read': message.read, 'expanded' : (message.read && message.expanded)  }"
          (click)="expandItem($event,message,i); $event.stopPropagation();"
        >
          <ion-col size="1" col-sm-2 [ngClass]="!message.read ? 'bold' : null">
            <ion-row class="message-icon-date">
              <ion-col>
                <ion-row>{{message.creationDate | date : 'd/M/yyyy' }}</ion-row>
                <ion-row>{{message.creationDate | date : 'h:mm' }}</ion-row>
              </ion-col>
            </ion-row>
          </ion-col>
          <ion-col size="10" col-sm-10>
            <ion-row>
              <span [ngClass]="{'pleft5': true, 'bold' : message.expanded || !message.read}">
                {{message.subject}}
              </span>
            </ion-row>

            <ion-row>
              <accordion-list [expanded]="message.expanded"
                ><span [innerHtml]="message.description | sanitizerHtml"></span
              ></accordion-list>
            </ion-row>
          </ion-col>
          <ion-col size="1" class="text-center">
            <ion-icon *ngIf="message.expanded" name="chevron-up-outline"></ion-icon>
            <ion-icon *ngIf="!message.expanded" name="chevron-down-outline"></ion-icon>
          </ion-col>
        </ion-row>
      </ng-container>

      <ng-template #noMessagesTemplate>
        <div class="fondomessages">
          <div class="messageno">No existen mensajes</div>
          <div class="txtcenter">
            <ion-icon
              name="notifications-outline"
              role="img"
              class="icon icon-md ion-notifications-outline"
              aria-label="notifications outline"
            >
            </ion-icon>
          </div>
        </div>
      </ng-template>
    </ion-grid>
    <codere-footer></codere-footer>
  </ion-content>
</ng-container>

<!-- Mobile -->
<ng-template #mobileView>
  <!-- eslint-disable @angular-eslint/template/eqeqeq -->
  <ion-content *ngIf="globalVars.FEATURES?.messageServiceEnabled" class="message-page__content">
    <ion-grid *ngIf="!loading" class="user-message-grid">
      <ion-row class="toolbar-content message-help">
        <ion-col size="1">
          <i class="sb-back-button color-light codere-icon icon-arrow-left" tappable="" (click)="openContact()"></i>
        </ion-col>
        <ion-col size="11">
          <div class="titlemsg">
            <span *ngIf="messageCount > 0"
              >{{ 'tMessages' | translate }} ({{ 'tUnreadMessages' | translate }} : {{ messageCount }})</span
            >
            <span *ngIf="messageCount == 0 || !messageCount">{{ 'tMessages' | translate }}</span>
          </div>
        </ion-col>
      </ion-row>
      <ng-container *ngIf="messages.length; else noMessagesTemplate">
        <ion-row
          *ngFor="let message of messages; let i = index"
          [ngClass]="{'user-message-row-mobile': true, 'message-read': message.read, 'expanded' : (message.read && message.expanded)  }"
          (click)="expandItem($event,message,i); $event.stopPropagation();"
        >
          <ion-grid>
            <ion-row>
              <ion-col size="11">
                <span [ngClass]="{'bold': !message.read}">{{message.creationDate | date : 'd/M/yyyy' }} </span>
                {{message.creationDate | date : 'h:mm' }}
              </ion-col>
              <ion-col size="1">
                <span class="mobile-expand-arrow">
                  <ion-icon *ngIf="message.expanded" name="chevron-up-outline"></ion-icon>
                  <ion-icon *ngIf="!message.expanded" name="chevron-down-outline"></ion-icon>
                </span>
              </ion-col>
            </ion-row>
            <ion-row class="paddingl5">
              <ion-col
                size="12"
                [ngClass]="{'bold' : message.expanded || !message.read, 'bleft-read': message.read, 'bleft-unread': !message.read}"
              >
                <span> {{message.subject}} </span>
              </ion-col>
            </ion-row>
            <accordion-list [expanded]="message.expanded">
              <ion-row>
                <ion-col size="12">
                  <div
                    class="text-left"
                    [ngClass]="{'bleft-read': message.read, 'bleft-unread': !message.read}"
                    [innerHtml]="message.description | sanitizerHtml"
                  ></div>
                </ion-col>
              </ion-row>
            </accordion-list>
          </ion-grid>
        </ion-row>
      </ng-container>
      <ng-template #noMessagesTemplate>
        <div class="fondomessages">
          <div class="messageno">No existen mensajes</div>
          <div class="txtcenter">
            <ion-icon
              name="notifications-outline"
              role="img"
              class="icon icon-md ion-notifications-outline"
              aria-label="notifications outline"
            >
            </ion-icon>
          </div>
        </div>
      </ng-template>
    </ion-grid>
    <codere-footer></codere-footer>
  </ion-content>
</ng-template>
