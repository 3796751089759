import { Injectable } from '@angular/core';
import { Category, Filter, Game } from '../models/lobby/casinoDataModels';
import { Params } from '../services/casino/lobbytracking.service';
import { Observable } from 'rxjs';
import { CasinoFilter } from '@interfaces/casinoMoreFiltersInterfaces';

@Injectable({ providedIn: 'root' })
export abstract class AbstractLoobyFacadeService {
  public lobbyState: Observable<Category | Category[] | Filter>;
  public slotsBanners: any[];
  public filtersList: any[];
  public moreFiltersApplied: Observable<CasinoFilter[]>;
  public filterName$: Observable<string>;

  public abstract setLobbyData(lobby: string): Promise<void>;
  public abstract setFilter(name: string, component: any): Promise<void>;
  public abstract setCategory(name: string): void;
  public abstract search(word: string): void;
  // public abstract getlobbyName(lobby: any): any;
  public abstract getLobbyFromUrl(): any;
  public abstract getIsSingleLobby(lobby: any): boolean;
  public abstract getIsOmnichannel(lobby: any): boolean;
  public abstract loadGameByURL(name: string): Promise<any>;
  public abstract playGame(game: any): Promise<void>;
  public abstract track(event: Game | string, params?: Params): void;
  public abstract checkBonusCasino(): void;
  public abstract getGames(): Promise<any>;
  public abstract getInactiveGames(): Promise<any>;
  public abstract getCurrentLobby(): string;
  // public abstract getGamesFromOutCasino(): any;
  public abstract playGameOutCasino(game: any): Promise<void>;
  // public abstract subscribeEvents(): void;
  public abstract forceRPG(lobby: any): void;
  public abstract unapplyMoreFilters(): void;
  public abstract unapplyMoreFilter(casinoFilter: CasinoFilter): void;
  public abstract getPlatform(): string;
}
