<ng-container *ngIf="!isMobile; else mobileView">
  <ion-content>
    <user-section-title [sectionTitle]="'uPGames'"></user-section-title>
    <ion-row class="subHeaderSelects">
      <ion-col>
        <ion-list>
          <ion-item *ngIf="globalVars.browser !== 'internet'">
            <ion-label>{{'histapfecha'|translate}}</ion-label>
            <ion-select
              cancelText="{{'tCancel'|translate}}"
              [(ngModel)]="form.date"
              (ionChange)="updateHistory()"
              placeholder="{{'histapu2d'|translate}}"
              [interfaceOptions]="{cssClass: 'historySlotsSelect'}"
            >
              <!--<ion-option value="365" >{{'histaptodas'|translate}}</ion-option>-->
              <ion-select-option value="2" checked>{{'histapu2d' | translate}}</ion-select-option>
              <ion-select-option value="7">{{ 'histapu7d' | translate }}</ion-select-option>
              <ion-select-option value="15">{{ 'histapu15d' | translate }}</ion-select-option>
              <ion-select-option *ngIf="globalVars?.FEATURES?.myactivity" value="30"
                >{{ 'last30days' | translate }}</ion-select-option
              >
              <ion-select-option *ngIf="globalVars?.FEATURES?.myactivity" value="lastmonth"
                >{{ 'lastmonth' | translate }}</ion-select-option
              >
              <ion-select-option value="90">{{ 'histapu90d' | translate }}</ion-select-option>
            </ion-select>
          </ion-item>

          <div class="select-explorer-container" *ngIf="globalVars.browser === 'internet'">
            <label class="label-explorer">{{'histapfecha' | uppercase}}</label>
            <select class="subheader-select-explorer" [(ngModel)]="form.date" (change)="updateHistory()">
              <option value="90">{{'histapu90d'|translate}}</option>
              <option value="15">{{'histapu15d'|translate}}</option>
              <option value="7">{{'histapu7d'|translate}}</option>
              <option value="2" checked="true">{{'histapu2d'|translate}}</option>
            </select>
          </div>
        </ion-list>
      </ion-col>
    </ion-row>
    <div class="is-narrow-content">
      <!--Empty State-->
      <empty-state [message]="'v2_noSlots'" [imageSource]="imageSource" *ngIf="arrHistory?.length<=0"> </empty-state>

      <ion-list *ngFor="let session of arrHistory" clear class="ticketReceipt ticketOpened">
        <div class="ticketTop"></div>
        <ion-header>
          <ion-label> <b>{{'inSession' | translate }}:</b>{{session.InitDate | extractDate:[2]}} </ion-label>
          <ion-label> <b>{{'endSession' | translate }}:</b> {{session.EndDate| extractDate:[2]}} </ion-label>
          <ion-label> <b>{{'initialBalance' | translate}}:</b>{{session.InitBalance | parseMoney:[1]}} </ion-label>
          <ion-label> <b>{{'uPPExtractBfi' | translate}}:</b>{{session.EndBalance | parseMoney:[1]}} </ion-label>
          <ion-label>
            <b>{{'balanceSession' | translate }}:</b>
            {{session.SessionBalance | parseMoney:[1]}}
          </ion-label>
        </ion-header>

        <ion-item class="slots-item" *ngFor="let game of session.GamesPlayed">
          <!--<h2>{{game.GameName}}</h2>-->
          <ion-row>
            <ion-col size="6" class="histSlotsResume">
              {{'hPay'|translate}}:
              <ion-note item-right="">{{game.Bets | parseMoney:[1]}}</ion-note>
            </ion-col>
            <ion-col size="6" class="histSlotsResume">
              {{'hwin'|translate}}:
              <ion-note item-right=""><span>{{game.Wins | parseMoney:[1]}}</span></ion-note>
            </ion-col>
          </ion-row>
        </ion-item>
      </ion-list>
    </div>
    <codere-footer></codere-footer>
  </ion-content>
</ng-container>

<ng-template #mobileView>
  <ion-header>
    <!-- <codere-navbar titulo="{{ 'uPGames' | translate }}"></codere-navbar> -->
    <user-section-title [sectionTitle]="'uPGames'"></user-section-title>
    <ion-row class="subHeaderSelects">
      <ion-col>
        <ion-list>
          <ion-item>
            <ion-label>{{ 'histapfecha' | translate }}</ion-label>
            <ion-select
              toggleIcon="chevron-down-outline"
              cancelText="{{ 'tCancel' | translate }}"
              [(ngModel)]="form.date"
              (ionChange)="updateHistory()"
              placeholder="{{ 'histapu2d' | translate }}"
            >
              <!--<ion-option value="365" >{{'histaptodas'|translate}}</ion-option>-->
              <ion-select-option value="2" checked>{{'histapu2d' | translate}}</ion-select-option>
              <ion-select-option value="7">{{ 'histapu7d' | translate }}</ion-select-option>
              <ion-select-option value="15">{{ 'histapu15d' | translate }}</ion-select-option>
              <ion-select-option *ngIf="globalVars?.FEATURES?.myactivity" value="30"
                >{{ 'last30days' | translate }}</ion-select-option
              >
              <ion-select-option *ngIf="globalVars?.FEATURES?.myactivity" value="lastmonth"
                >{{ 'lastmonth' | translate }}</ion-select-option
              >
              <ion-select-option value="90">{{ 'histapu90d' | translate }}</ion-select-option>
            </ion-select>
          </ion-item>
        </ion-list>
      </ion-col>
    </ion-row>
  </ion-header>

  <ion-content class="tipoApuesta background-color-muted">
    <!--Empty State-->
    <empty-state
      class="test-1"
      [class.is-mobile]="isMobile"
      [message]="'v2_noSlots'"
      [imageSource]="imageSource"
      *ngIf="arrHistory?.length <= 0"
    >
    </empty-state>

    <ion-list *ngFor="let session of arrHistory" clear class="ticketReceipt ticketOpened">
      <ion-header class="arrHistory">
        <ion-label><b>{{'inSession' | translate }}:</b>{{session.InitDate | extractDate:[2]}}</ion-label>
        <ion-label><b>{{'endSession' | translate }}:</b> {{session.EndDate| extractDate:[2]}}</ion-label>
        <ion-label><b>{{'initialBalance' | translate}}:</b>{{session.InitBalance | parseMoney:[1]}}</ion-label>
        <ion-label><b>{{'uPPExtractBfi' | translate}}:</b>{{session.EndBalance | parseMoney:[1]}}</ion-label>
        <ion-label><b>{{'balanceSession' | translate }}:</b> {{session.SessionBalance | parseMoney:[1]}}</ion-label>
      </ion-header>
      <span *ngFor="let game of session.GamesPlayed">
        <ion-item lines="none" class="histSlotsResume">
          {{'hPay'|translate}}:
          <ion-note>{{game.Bets | parseMoney:[1]}}</ion-note>
        </ion-item>

        <ion-item lines="none" class="histSlotsResume">
          {{'hwin'|translate}}:
          <ion-note><span>{{game.Wins | parseMoney:[1]}}</span></ion-note>
        </ion-item>
      </span>
    </ion-list>

    <codere-footer></codere-footer>
  </ion-content>
</ng-template>
