/* eslint-disable */
import { IFramePaymentMethodBehaviourResult, PaymentMethodBehaviour, SkipAmountResult } from './PaymentMethodBase';
import { PaySafeCardDepositActionResult } from '@models/C_Transactions';
import { UserService } from '@providers/UserService';
import { ErrorService } from '@providers/ErrorService';
import { Observable, map } from 'rxjs';

export class AstropayEPG extends PaymentMethodBehaviour {
  name = 'AstropayEPG';
  image = 'logo_astropayepg';
  track = 'AccessToAstropayEPG';
  isBank = false;
  amountButtons: number[] = [300, 400, 800, 1000];
  minDepositAmount = 100;
  maxDepositAmount = 999999999;
  override amount = 100;

  constructor(userService: UserService, errorService: ErrorService) {
    super(false, userService, errorService);
  }

  skipAmountInput(): Observable<SkipAmountResult> {
    return new Observable<SkipAmountResult>();
  }

  paymentMetodExecution(): Observable<IFramePaymentMethodBehaviourResult> {
    return this.userService.validateDepositEPGAstropay(this.amount).pipe(
      map((obj) => {
        return this.map(obj);
      })
    );
  }

  private map(input: PaySafeCardDepositActionResult): IFramePaymentMethodBehaviourResult {
    const result = new IFramePaymentMethodBehaviourResult();
    if (input.success) {
      result.success = true;
      result.iframeType = 'astropayepg';
      result.iframeArgs = { type: 'astropayepg', url: encodeURI(input.url) };
    } else {
      result.success = false;
      result.erroMessage = this.errorService.searchErrorCodeWithParams('error' + input.errCode, input.errorParameters);
      result.errorcode = input.errCode;
      result.sendToDocumentVerificationPage = input.errCode == 174 || input.errCode == 2212;
    }
    return result;
  }
}
