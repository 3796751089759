/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ErrorMsgs } from '@interfaces/interfaces';
import { ControlErrorComponent } from '../control-error/control-error.component';

@Component({
  standalone: true,
  imports: [CommonModule, IonicModule, ReactiveFormsModule, ControlErrorComponent],
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'cdr-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit {
  @Input() label: string;
  @Input() errorsCol: ErrorMsgs;
  @Input() options: Array<any>;
  @Input() fcName: string;
  @Input() fgrp: FormGroup;
  fControl: FormControl;
  @ViewChild('labelSpanRef') elemSpan: ElementRef;

  constructor() {}

  ngOnInit() {
    this.fControl = this.fgrp?.get(this.fcName) as FormControl;
  }

  handleFocus(evt) {
    this.elemSpan.nativeElement.classList.add('has-focus');
  }
  handleChange(evt) {
    this.elemSpan.nativeElement.classList.remove('has-focus');
    evt.target.blur();
  }

  handleBlur(evt) {
    this.elemSpan.nativeElement.classList.remove('has-focus');
  }
}
