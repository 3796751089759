import { LineItem } from './B_LineItem.model';

//eslint-disable-next-line
export enum StakeGroup {
  SINGLES = 'Singles',
  DOUBLES = 'Doubles',
  TRIPLES = 'Triples',
  CUADRUPLES = 'Cuadruples',
  SYSTEM = 'System',
  MULTI_WAY_SYSTEM = 'MultiWaySystem',
  ACCUMULATOR = 'Accumulator'
}

export interface StakeModel {
  ItemId?: number;
  ItemIds?: number[];
  SystemId?: number;
  OverallStake?: number;
  StakePerBetWay?: number;
  VoucherCode?: string;
  IsEachWay: boolean;
  Group: string;
  MultiSingleAmount?: number;
  LineItems?: LineItem[];
}

export const mapMultiSingleStake = (multiSingleStake): StakeModel => {
  const tempStake: StakeModel = {
    ItemId: multiSingleStake.LineItems[0].ItemId,
    OverallStake: multiSingleStake.OverallStake,
    IsEachWay: false,
    Group: StakeGroup.SINGLES
  };
  tempStake.LineItems = multiSingleStake.LineItems.slice(1);
  tempStake.MultiSingleAmount = multiSingleStake.MultiSingleAmount - 1;
  return tempStake;
};

export const mapSystemStake = (systemSetStake): any => {
  const tempStake: StakeModel = {
    SystemId: systemSetStake.systemId,
    OverallStake: systemSetStake.stakeAmount,
    IsEachWay: false,
    Group: StakeGroup.SYSTEM
  };
  return tempStake;
};
