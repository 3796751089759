/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  AfterViewInit,
  CUSTOM_ELEMENTS_SCHEMA,
  inject
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { DeviceService } from '../../../../services/device.service';
import { GlobalVarsModel } from '../../../../models/ModelGlobalsVar';
import { NewBaseService } from '../../../../providers/newBase.service';
import { UserService } from '../../../../providers/UserService';
import { interval, Subscription } from 'rxjs';
import { PAGES_PATHS } from '../../../../lib/constants/routes';
import { C_Banners } from '../../../../models/C_banners';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { Swiper, SwiperOptions } from 'swiper';
@Component({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'codere-slider',
  templateUrl: './codere-slider.html',
  imports: [CommonModule, IonicModule, TranslateModule],
  standalone: true,
  styleUrls: ['./codere-slider.scss']
})
export class CodereSliderComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() slides;
  @Input() controls: boolean;
  @Output() clickedSlide: EventEmitter<any> = new EventEmitter();

  timersubscription: Subscription;
  globalVars!: GlobalVarsModel;
  hoursbanner = '00';
  minutesbanner = '00';
  daysbanner = '00';
  secondsbanner = '00';
  isDesktop = false;
  isSportSlider = false;
  swiperSliders: string[] = [];

  config: SwiperOptions = {
    loop: true,
    slidesPerView: 'auto',
    autoplay: {
      delay: 5000
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true
    },
    observer: true
  };
  @ViewChild('swiper') swiperRef:
    | ElementRef<HTMLElement & { swiper?: Swiper } & { initialize: () => void }>
    | undefined;

  swiper?: Swiper;

  newBaseService = inject(NewBaseService);
  userService = inject(UserService);
  route = inject(ActivatedRoute);
  deviceService = inject(DeviceService);

  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
    this.isSportSlider = this.route?.component.name === PAGES_PATHS.HomePage || false;
    this.isDesktop = this.deviceService.isDesktop();
  }
  ngOnInit(): void {
    this.slides?.map((item: C_Banners) => {
      this.swiperSliders.push(item.Imagen);
    });

    if (this.isSportSlider && this.globalVars?.FEATURES?.bannerCountdownMundial2022 && this.canShowCountdown) {
      if (this.userService?.urlQA()) {
        const img = `assets/global/img/${this.isDesktop ? 'countdown-desktop.jpg' : 'countdown-mobile.jpg'}`;
        this.slides.splice(1, 0, new C_Banners(img, '', '', ''));
      }
      this.countdown(this.globalVars.FEATURES.bannerCountdownMundial2022_dateTime);
    }

    this.config.allowTouchMove = !this.controls;
  }
  ngAfterViewInit(): void {
    const swiperEl = Object.assign(this.swiperRef?.nativeElement, this.config);
    swiperEl.initialize();
    this.swiper = this.swiperRef?.nativeElement.swiper;
  }

  ngOnDestroy(): void {
    if (this.globalVars.FEATURES.bannerCountdownMundial2022 && this.timersubscription && this.isSportSlider) {
      this.timersubscription.unsubscribe();
    }
  }
  countdown(dateTo) {
    const timer = interval(1000);
    this.timersubscription = timer.subscribe(() => {
      const currenTime = this.getTime(dateTo);
      this.daysbanner = currenTime.days;
      this.hoursbanner = currenTime.hours;
      this.minutesbanner = currenTime.minutes;
      this.secondsbanner = currenTime.seconds;
      if (currenTime.time <= 1) {
        this.timersubscription.unsubscribe();
      }
    });
  }

  getTime(dateTo) {
    const now = new Date(),
      time = (new Date(dateTo).getTime() - now.getTime() + 1000) / 1000,
      seconds = ('0' + Math.floor(time % 60)).slice(-2),
      minutes = ('0' + Math.floor((time / 60) % 60)).slice(-2),
      hours = ('0' + Math.floor((time / 3600) % 24)).slice(-2),
      days = ('0' + Math.floor(time / (3600 * 24))).slice(-2);

    return {
      seconds,
      minutes,
      hours,
      days,
      time
    };
  }

  canShowCountdown() {
    const timeNow = new Date();
    const timeEnd = new Date(this.globalVars.FEATURES.bannerCountdownMundial2022_dateTime);
    return timeNow.getTime() <= timeEnd.getTime();
  }
}
