import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, Component, EventEmitter, Input, Output, inject } from "@angular/core";

// Models
import { GlobalVarsModel } from "@models/ModelGlobalsVar";

// Other
import { DeviceService } from "@services/device.service";

// Components

@Component({
  selector: "sb-icon-bar",
  templateUrl: "./sb-icon-bar.component.html",
  standalone: true,
  imports: [CommonModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  styleUrls: ["./sb-icon-bar.component.scss"],
})
export class SbIconBarComponent {
  @Input() items: any[];
  @Input() selectedItem: any;
  @Input() isProcessing: boolean;
  @Output() selectItem: EventEmitter<any> = new EventEmitter();

  globalVars!: GlobalVarsModel;

  isMobile: boolean;
  isDesktop: boolean;

  deviceService = inject(DeviceService);

  constructor() {
    this.isDesktop = this.deviceService.isDesktop();
    this.isMobile = this.deviceService.isMobile();
  }

  identify(index, item) {
    return item.SportHandle;
  }

  getIconClass(iconName) {
    return iconName ? `icon-${iconName.toLowerCase()}` : null;
  }

  onClick(item) {
    this.selectItem.emit(item);
  }
}
