/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable eqeqeq */
import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { NavParams, ModalController, IonicModule } from '@ionic/angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MSO_PATHS, SPORTS_PATHS } from '../../../lib/constants/routes';
import { GlobalVarsModel } from '../../../models/ModelGlobalsVar';
import { NewBaseService } from '../../../providers/newBase.service';

@Component({
  standalone: true,
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'info-autoexclusion',
  templateUrl: './info-autoexclusion.html',
  styleUrls: ['./info-autoexclusion.scss'],
  imports: [CommonModule, IonicModule, TranslateModule]
})
export class InfoAutoexclusion {
  type: number;
  parent: any;
  fiveLogins = false;

  title: string;
  URL_MISAPUESTAS_ARGCABA = '';

  globalVars!: GlobalVarsModel;
  newBaseService = inject(NewBaseService);
  newBaseSertranslatevice = inject(TranslateService);
  navParams = inject(NavParams);
  translate = inject(TranslateService);
  modalController = inject(ModalController);
  router = inject(Router);

  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });

    if (this.navParams.get('fiveLogins')) {
      this.fiveLogins = this.navParams.get('fiveLogins');
    }
    if (!this.fiveLogins) {
      this.type = this.navParams.get('type');
      this.title =
        this.type == 1
          ? this.translate.instant('selfexcludedpreviouscheme')
          : this.translate.instant('selfexcludedcurrentregime');
    } else {
      this.title = this.translate.instant('modalfivelogins');
    }
  }

  goToSelfExclusion() {
    this.modalController?.dismiss();

    if (this.navParams.get('parent')) {
      this.navParams.get('parent')?.dismiss();
    }

    this.router.navigate([`/${MSO_PATHS.BASE}/${MSO_PATHS.SelfExclusionPage}`]);
  }

  gotoMisApuestas() {
    this.closeModal();
    this.router.navigate([`/${SPORTS_PATHS.BASE}/${SPORTS_PATHS.MisApuestasPage}`]);
  }

  closeModal() {
    this.modalController.dismiss();
  }
}
