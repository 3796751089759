import { Component, OnChanges, Input, OnInit, Renderer2, inject } from '@angular/core';
import { AbstractLoobyFacadeService } from '../../../../abstracts/abstract-looby-facade-service';
import { EventTypes } from '../../../../models/TrackingEvents';
import { DeviceService } from '../../../../services/device.service';
import { GlobalVarsModel } from '../../../../models/ModelGlobalsVar';
import { NewBaseService } from '../../../../providers/newBase.service';
import { EventsService } from '../../../../providers/EventsService';
import { CommonModule } from '@angular/common';
import { CasinoCardSlotsComponent } from '../casino-card-slots/casino-card-slots.component';
import { CasinoCardNewComponent } from '../../../../components/casino/casino-card-new/casino-card-new.component';
import { CasinoLiveCardNewComponent } from '../casino-live-card-new/casino-live-card-new';
import { Observable } from 'rxjs';
import { LobbyFacadeService } from '../../../../services/casino/loobyFacade.service';
import { CodereSliderComponent } from '@components/mso/slider/codere-slider/codere-slider';
import { CodereSliderCsvComponent } from '@components/mso/slider/codere-slider-csv/codere-slider-csv';

@Component({
  selector: 'lb-omnichanel',
  templateUrl: './omnichanel-lobby.html',
  styleUrls: ['./omnichanel-lobby.scss'],
  standalone: true,
  imports: [
    CommonModule,
    CasinoCardSlotsComponent,
    CasinoCardNewComponent,
    CodereSliderComponent,
    CodereSliderCsvComponent,
    CasinoLiveCardNewComponent
  ],
  providers: [
    {
      provide: AbstractLoobyFacadeService,
      useClass: LobbyFacadeService
    }
  ]
})
export class omnichanelLobbyComponent implements OnInit, OnChanges {
  @Input() omnichannellobby;
  category;
  searchResult;
  activeFilterIndex = 0;
  globalVars!: GlobalVarsModel;
  isDesktop: boolean = false;
  lobbyName: string;
  lobby$: Observable<any>;

  newBaseService = inject(NewBaseService);
  deviceService = inject(DeviceService);
  facade = inject(AbstractLoobyFacadeService);

  constructor(
    private events: EventsService,
    private renderer: Renderer2
  ) {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });

    this.isDesktop = this.deviceService.isDesktop();
  }

  ngOnInit() {
    this.category = this.omnichannellobby[0];
    this.lobbyName = this.category;
  }

  ngOnChanges() {}

  selectSearch(word: string) {
    if (word !== '') {
      this.facade.search(word);
      this.activeFilterIndex = -1;
      this.facade.track(word, { event: EventTypes.CasinoLobbySearch });
    } else {
      setTimeout(() => {
        this.activeFilterIndex = 0;
        this.omnichannellobby = this.category;
      }, 50);
    }
  }

  clear() {
    this.selectSearch('');
  }

  playGame(game) {
    game.game.lobby = this.facade.getCurrentLobby();
    game.game.product = 'Casino';
    this.facade.playGame(game);
  }

  ngOnDestroy() {}
}
