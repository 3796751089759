import { Injectable, inject } from "@angular/core";
import { Router } from "@angular/router";
import { AlertController, ModalController } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, Observable, Subscription, firstValueFrom, of, timer } from "rxjs";
import { catchError, filter, map, retry, switchMap, take, takeWhile } from "rxjs/operators";

import { LicenseTypes } from "@models/index";

import {
  BetbuilderLineItem,
  C_FreeBetTicket,
  C_FreeBetVoucher,
  C_OveraskData,
  C_OveraskDataBetPlacement,
  C_TicketApuestasNew,
  CombinationMulticastInput,
  FilterItem,
  LineItem,
  OddItem,
  SmartLineItem,
  SmartMarketInput,
  StakeGroup,
  StakeModel,
  StraightMulticastInput,
  oddsUpdateItem,
  removeTicket,
  singleResultItem,
  systemData,
  ticketBetSlip,
} from "@sports-models/index";

import { EventTypes, TRAKERSTYPECODEREID, TrackersCodereId } from "@models/index";

import {
  ErrorAlertSportsCasinoGeolocation,
  FilterFactory,
  ILicenseFilter,
} from "@shared-providers/licenseFilter/index";

import { TrackingService } from "@shared-providers/TrackingService";
import { UserService } from "@shared-providers/UserService";
import { UserServiceMSO } from "@shared-providers/UserServiceMSO";
import { NewBaseService } from "@shared-providers/newBase.service";
import { DeviceService } from "@shared-services/device.service";
import { MobileBehaviorService, SportService, TicketService } from "@sports-services/index";

import { GlobalVarsModel } from "@models/index";
import { LoaderService } from "@shared-services/loader-feature/loader.service";
import { Utils } from "@shared-utils/Utils";

import { EventsService } from "@shared-providers/EventsService";

import * as TicketActions from "./ticket.actions";
import { ticketState } from "./ticket.reducers";

import { NativeService } from "@providers/NativeService";
import { MSO_PATHS, PAYMENTS_PATHS } from "@shared-constants/routes";

import { FooterService } from "@providers/FooterService";
import { TicketFreebetsPage } from "@shared-sports-pages/ticket-freebets/ticket-freebets";

// PIPES
import { ParseMoney } from "@pipes/parseMoney";
// Utils
import { VerificationAlerts } from "@providers/VerificationAlert";

import { DecouplingUserServiceMSO } from "@providers/DecouplingUserServiceMSO.service";
//MSO Geolocation Mendoza
import { GeolocationService } from "@providers/GeolocationService";
import { TicketMethods } from "@sports-utils/index";

@Injectable({ providedIn: "root" })
export class NewTicketFacade {
  DEV = true;

  ticket$: Observable<C_TicketApuestasNew>;
  ticketState$: Observable<ticketState>;
  ticketState: ticketState;
  ticketBetSlip: ticketBetSlip;
  ticketView: C_TicketApuestasNew;
  freeBets: C_FreeBetTicket[];
  currentSingleResults: singleResultItem[] = [];
  filters$: Observable<FilterItem[]>;
  selectedFilter$: Observable<FilterItem>;
  filters: FilterItem[];
  filterSelected: FilterItem;
  defaultAmounts;
  minBetValue: string;

  changingStake$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  keyboard$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  betsenseSelections = [];

  license: LicenseTypes;
  currencySymbol: string = "€";

  private update$: Subscription;

  private stopOveraskPolling: boolean = false;
  overaskPolling: boolean = false;
  overaskInfo: C_OveraskData;
  overask$: Observable<C_OveraskDataBetPlacement>;
  overaskSub$: Subscription;
  timerCountdown = null;
  overaskCountdown$ = new BehaviorSubject<number>(30);
  overaskCountdownStopped: boolean = true;
  auxCloseBet;

  _globalVars!: GlobalVarsModel;

  newBaseService = inject(NewBaseService);

  store = inject(Store<ticketState>);
  _sportService = inject(SportService);
  _ticketService = inject(TicketService);
  _userService = inject(UserService);
  _userServiceMSO = inject(UserServiceMSO);
  _decouplingUserServiceMSO = inject(DecouplingUserServiceMSO);
  _utils = inject(Utils);
  _parseMoney = inject(ParseMoney);
  _trackingService = inject(TrackingService);
  _translate = inject(TranslateService);
  _alertController = inject(AlertController);
  events = inject(EventsService);
  loaderService = inject(LoaderService);
  deviceService = inject(DeviceService);
  router = inject(Router);
  modalController = inject(ModalController);
  nativeService = inject(NativeService);
  utils = inject(Utils);
  mobileBehavior = inject(MobileBehaviorService);
  verificationAlertService = inject(VerificationAlerts);
  //MSO Geolocation Mendoza
  geolocationService = inject(GeolocationService);
  //End MSO Geolocation Mendoza
  footerServices = inject(FooterService);
  registerPages = [
    MSO_PATHS.RegistroNewPage,
    MSO_PATHS.RegistroCONewPage,
    MSO_PATHS.RegistroPAPage,
    MSO_PATHS.RegistroARPage,
    MSO_PATHS.RegistroPBAPage,
  ];
  alertCookies: AlertController;
  alertCookiesOptions;
  isMobile = false;
  isDesktop = false;

  constructor() {
    this.isDesktop = this.deviceService.isDesktop();
    this.isMobile = this.deviceService.isMobile();
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this._globalVars = data;
    });
    this._filters();
    this.updateOdds();
    this.minBetValue = this._globalVars.FEATURES.BetSlipLegMinValue;

    this.ticket$ = this.store.select((state) => state.ticket);
    this.ticketState$ = this.store.select((state) => state.ticket);

    this.mobileBehavior.keyboard$.subscribe((value) => this.keyboard$.next(value));

    this.ticketState$.subscribe((ticketSt: ticketState) => {
      if (this.minBetValue === "") this.minBetValue = this._globalVars.FEATURES.BetSlipLegMinValue;

      this.ticketState = ticketSt;
      this.filters$ = of(ticketSt?.filters);
      this.selectedFilter$ = of(ticketSt?.filterSelected);
      this.ticketBetSlip = ticketSt?.betSlip;
      this.ticketView = ticketSt?.ticket;
      this.freeBets = ticketSt?.freeBets;
      this.filters = ticketSt?.filters;
      this.filterSelected = ticketSt?.filterSelected;
    });

    this.defaultAmounts = this._ticketService.defaultAmounts.map((amount) => parseFloat(amount.toFixed(2)));
    this.license = this._globalVars.licenseType;
    this.currencySymbol = this._globalVars.currencySymbol;
  }

  addBetByLink(id: number) {
    this.navigateToTicket();
    this.store.dispatch(new TicketActions.AddSingleResultById(id));
  }

  checkSelection(data, nodeId) {
    if (this.ticketState.ticket.processing) return;

    if (!TicketMethods.getIsConfirmed(this.ticketView)) {
      if (TicketMethods.getHasFreebet(this.ticketView)) {
        this.showRemoveFreebetOnTicketChange();
        return;
      }
      const id = this.checkIfBetExistsInTicket(nodeId);
      if (id !== -1) {
        if (TicketMethods.getNumberOfSelections(this.ticketView) > 1) {
          this.store.dispatch(new TicketActions.RemoveItem(this.ticketBetSlip.items.singleResultItems[id].itemId));
        } else {
          this.removeTicket();
        }
      } else {
        this.navigateToTicket();
        this.store.dispatch(new TicketActions.AddSingleResult({ ...data }));
      }
    }
  }

  checkIfBetExistsInTicket(nodeId) {
    let id: number = -1;
    for (let index = 0; index < this.ticketView.LineItems.length; index++) {
      const item = this.ticketView.LineItems[index];
      if (item.ResultsNr === nodeId) {
        id = index;
        break;
      }
    }
    return id;
  }

  removeMulticast(id: number) {
    if (TicketMethods.getHasFreebet(this.ticketView)) {
      this.showRemoveFreebetOnTicketChange();
      return;
    }
    this.store.dispatch(new TicketActions.RemoveMulticastItem(id));
  }

  removeCombinationItem(id: number) {
    if (TicketMethods.getHasFreebet(this.ticketView)) {
      this.showRemoveFreebetOnTicketChange();
      return;
    }
    this.store.dispatch(new TicketActions.RemoveCombinationItem(id));
  }

  /* BETBUILDER / SMARTMARKET */
  /**
   * Add LineItem to Ticket State Betbuilder Selection
   * @param smartMarketSelection
   */
  sendAddSmartMarketSelection(smartMarketSelection) {
    if (!TicketMethods.getIsConfirmed(this.ticketView)) {
      this.navigateToTicket();
      this.store.dispatch(new TicketActions.AddSmartMarketSelection(smartMarketSelection));
    }
  }

  /**
   * Place Betbuilder Item to State Ticket
   * @param data
   */
  sendAddSmartMarketItem(
    smartMarketRequest: SmartMarketInput,
    smartMarketBet: SmartLineItem | BetbuilderLineItem,
  ) {
    if (!TicketMethods.getIsConfirmed(this.ticketView)) {
      if (TicketMethods.getHasFreebet(this.ticketView)) {
        this.showRemoveFreebetOnTicketChange();
        return;
      }
      this.navigateToTicket();
      this.store.dispatch(new TicketActions.AddSmartMarketItem(smartMarketRequest, smartMarketBet));
    }
  }

  /**
   * Remove Smart Market / Betbuilder selection from State Ticket
   * @param smartMarketRequest
   * @param smartMarketBet
   */
  removeSmartMarketItem(smartMarketItemId) {
    if (TicketMethods.getHasFreebet(this.ticketView)) {
      this.showRemoveFreebetOnTicketChange();
      return;
    }
    this.navigateToTicket();
    this.store.dispatch(new TicketActions.RemoveSmartMarketItem(smartMarketItemId));
  }

  /**
   * Remove Selection from State BetbuilderSelection
   * @param id Selection Id
   */
  removeSmartMarketSelection(id: number) {
    this.navigateToTicket();
    this.store.dispatch(new TicketActions.RemoveSmartMarketSelection(id));
  }

  /**
   * Remove All Betbuilder / SmartMarket selections
   */
  clearBetbuilderSelections() {
    this.store.dispatch(new TicketActions.RemoveSmartMarketAllSelections());
  }

  /* Grayhounds and Hourses Races */
  /**
   * Place straightMulticast Item to State Ticket
   * @param straightMulticastInput
   */
  addStraighMulticastItem(straightMulticastInput: StraightMulticastInput, straightMultiacastItem: LineItem) {
    if (this.ticketState.ticket.processing) return;
    if (!TicketMethods.getIsConfirmed(this.ticketView)) {
      if (TicketMethods.getHasFreebet(this.ticketView)) {
        this.showRemoveFreebetOnTicketChange();
        return;
      }
      this.navigateToTicket();
      this.store.dispatch(new TicketActions.AddStraightMulticast(straightMulticastInput, straightMultiacastItem));
    }
  }

  /**
   * Place combinationMulticast Item to State Ticket
   * @param combinationMulticastInput
   */
  addCombinationMulticastItem(
    combinationMulticastInput: CombinationMulticastInput,
    combinationMulticastItem: LineItem,
  ) {
    if (this.ticketState.ticket.processing) return;
    if (!TicketMethods.getIsConfirmed(this.ticketView)) {
      if (TicketMethods.getHasFreebet(this.ticketView)) {
        this.showRemoveFreebetOnTicketChange();
        return;
      }
      this.navigateToTicket();
      this.store.dispatch(
        new TicketActions.AddCombinationMulticast(combinationMulticastInput, combinationMulticastItem),
      );
    }
  }

  /**
   * Check and update Stake
   * @param event
   */
  checkAndUpdateStake(event) {
    const systemId = this.systemId();
    const eventData = {
      betType: this.ticketView.BetType,
      amount: event.amount,
      systemId: systemId,
      lineItem: this.getSelectedLines(),
      multiSingleAmount: TicketMethods.getNumberOfSelections(this.ticketView),
      lineItems:
        this.getSelectedLines().length > 0 ? this.getSelectedLines() : TicketMethods.getAllItems(this.ticketView),
    };

    if (this.ticketView.BetType === 0) {
      this.multiStakeUpdate(eventData);
    } else {
      if (!this.getSelectedLines()) eventData.lineItem = TicketMethods.getAllItems(this.ticketView);
      this.updateStake(eventData);
    }
  }

  /**
   * Update the stake Amounts
   * @param event
   */
  updateStake(event) {
    const stake: StakeModel = {
      IsEachWay: false,
      Group: "",
      OverallStake: parseFloat(event.amount) * 100,
      VoucherCode: "",
    };
    switch (event.betType) {
      case 0:
        stake.Group = StakeGroup.SINGLES;
        stake.OverallStake = parseFloat(event.amount) * 100;
        stake.IsEachWay = event.lineItem.CanBeEachWay;
        stake.ItemId = event.lineItem.ItemId;
        break;
      case 1:
        stake.SystemId = event.systemId;
        stake.OverallStake = parseFloat(event.amount) * 100;
        stake.Group = StakeGroup.ACCUMULATOR;
        break;
      case 2: {
        const possibleSystemSelection = this.ticketBetSlip.possibleSystems.find(
          (i) => +i.systemId === +event.systemId,
        );
        const systemId = possibleSystemSelection ? possibleSystemSelection.systemId : 2;
        stake.SystemId = systemId;
        switch (systemId) {
          case 2:
            stake.Group = StakeGroup.DOUBLES;
            break;
          case 3:
            stake.Group = StakeGroup.TRIPLES;
            break;
          case 4:
            stake.Group = StakeGroup.CUADRUPLES;
            break;
          default:
            stake.Group = `${StakeGroup.SYSTEM}${systemId}`;
            break;
        }
        break;
      }
    }
    this.changingStake$.next(false);
    this.store.dispatch(new TicketActions.UpdateStake(stake));
  }

  /**
   * Update multiple single Amounts
   * @param event
   */
  multiStakeUpdate(event) {
    const itemIds = [];

    event.lineItems.forEach((i) => itemIds.push(i.ItemId));

    const multiStake: StakeModel = {
      IsEachWay: false,
      Group: StakeGroup.SINGLES,
      OverallStake: parseFloat(event.amount) * 100,
      VoucherCode: "",
      SystemId: 1,
      ItemIds: itemIds,
    };
    this.changingStake$.next(false);
    this.store.dispatch(new TicketActions.SetMultiStake(multiStake));
  }

  /**
   * Update the Amount Local (the amount shown on top of the input)
   * @param value
   */
  updateAmountLocal(value) {
    const selectedLine = TicketMethods.getAllItems(this.ticketView).find((i) => i.Selected); // LineItem

    if (selectedLine) {
      this.store.dispatch(
        new TicketActions.AmountLocalLineItemUpdate({ selectedLine: selectedLine, value: value }),
      );
    } else {
      this.store.dispatch(new TicketActions.AmountLocalTotalUpdate(value));
    }
  }

  // ***
  /**
   * Set the system dropdown selection
   * @param systemDataStake stake data
   */
  setNewMultipleSelection(systemDataStake: systemData) {
    if (this.minBetValue === "") this.minBetValue = this._globalVars.FEATURES.BetSlipLegMinValue;
    const stake: StakeModel = this.getSystemDataStake(systemDataStake);
    this.store.dispatch(new TicketActions.SetSystemBetType(this.ticketState, stake));
  }

  getSystemDataStake(data) {
    const total: number = this.ticketView.Total;
    const minValue = data.numberOfBetWays * +this.minBetValue;
    const OverallStake = total > minValue ? total : minValue;

    const tempStake: StakeModel = {
      SystemId: data.systemId,
      OverallStake: OverallStake * 100,
      IsEachWay: data.canBeEachWay,
      Group: "",
    };

    switch (data.systemId) {
      case 2:
        tempStake.Group = StakeGroup.DOUBLES;
        break;
      case 3:
        tempStake.Group = StakeGroup.TRIPLES;
        break;
      case 4:
        tempStake.Group = StakeGroup.CUADRUPLES;
        break;
      default:
        tempStake.Group = `${StakeGroup.SYSTEM}${data.systemId}`;
        break;
    }
    return tempStake;
  }

  /**
   * Bet placing
   * @returns if not changing Stake and is not Desktop
   */
  async placeBet(): Promise<Observable<TicketActions.PlaceBet>> {
    if (this.isProcesing) return null;
    if (this._globalVars.user.logged) {
      if (this._globalVars?.LICENSE_TYPE === LicenseTypes.Colombia) {
        this.verificationAlertService.checkDNIExpired();
      }
      if (!this._globalVars.extraUserData.blockBets) {
        if (this.changingStake$.getValue() /* && this._globalVars.isDesktop */) {
          return null;
        }
        if (
          this._globalVars.user.balance < this.ticketView.Total &&
          !TicketMethods.getHasFreebet(this.ticketView)
        ) {
          this.openDeposits();
        }
        const filters: ILicenseFilter = FilterFactory.getFilter(
          this._globalVars.licenseType,
          this._translate,
          this.newBaseService,
        );

        if (filters) {
          if (!filters.canBetSports()) {
            this.alertSportsGeolocation(filters.geolocationAlertCabaError());
            return null;
          }

          if (filters.isOrdenCongelamientoFondos()) {
            this._utils.alert(false, "Error", this._translate.instant("accountanalysisplay"), "OK", undefined);
            return null;
          }
        }

        if (!this.overaskCountdownStopped) this.stopTimer();

        let groupKey: string = "";
        this.events.publish("loading:start");
        switch (this.ticketView.BetType) {
          case 0:
            groupKey = StakeGroup.SINGLES;
            break;
          case 1:
            groupKey = StakeGroup.ACCUMULATOR;
            break;
          case 2:
            groupKey = this.ticketView.SystemSelected.groupName;
            break;
          default:
            break;
        }

        this.trackPromoPreBet(TicketMethods.getAllItems(this.ticketView), this.ticketView.BetType);
        //MSO Geolocation Mendoza
        if (this._globalVars.FEATURES.MSO_EnableGeolocation && this._globalVars.FEATURES.MSO_TimerGeolocation) {
          this.geolocationService.initTimerGeolocation();
        }
        //End MSO Geolocation Mendoza
        this.store.dispatch(new TicketActions.PlaceBet(groupKey));
      }
    } else {
      this._globalVars.rootScope.openLogin();
    }
  }

  placingBet(placeStatus: boolean) {
    this.store.dispatch(new TicketActions.PlacingBet(placeStatus));
  }

  removeSelection(id: number) {
    this.store.dispatch(new TicketActions.RemoveItem(id));
  }

  removeSelections(ids: number[]) {
    this.store.dispatch(new TicketActions.RemoveItems(ids));
  }

  keepSelections() {
    const cuotaAccept: string = this._globalVars.cuotaAccept;
    const userLogged: boolean = this._globalVars.user.logged;
    this.store.dispatch(new TicketActions.KeepSelection({ cuotaAccept, userLogged }));
  }

  //FILTERS

  private _filters() {
    const filters = [
      {
        name: this._translate.instant("tSen"),
        value: 0,
        betType: StakeGroup.SINGLES,
        systemId: 1,
        selected: true,
        hidden: this.isBetTypeHidden("0"),
        disabled: false,
      },
      {
        name: this._translate.instant("tAcu"),
        value: 1,
        betType: StakeGroup.ACCUMULATOR,
        systemId: null,
        selected: false,
        hidden: this.isBetTypeHidden("1"),
        disabled: true,
      },
      {
        name: this._translate.instant("tMul"),
        value: 2,
        betType: StakeGroup.SYSTEM,
        systemId: null,
        selected: false,
        hidden: this.isBetTypeHidden("2"),
        disabled: true,
      },
    ];

    this.store.dispatch(new TicketActions.InitFilters(filters.filter((filt) => !filt.hidden)));
  }

  private isBetTypeHidden(betType: string) {
    switch (parseInt(betType)) {
      case 0:
        return false;
      case 1:
        return false;
      case 2:
        return false;
      case 3:
        return !this.ticketBetSlip.possibleSystems;
    }
  }

  public changeFilterSelected(value) {
    if (TicketMethods.getHasFreebet(this.ticketView)) {
      this.showRemoveFreebetOnTicketChange();
      return;
    }
    this.store.dispatch(new TicketActions.ChangeFilter(value));
  }

  public removeStake(system: systemData) {
    if (system.systemId === this.ticketView.SystemSelected.systemId) return;

    const systemStake: StakeModel = {
      SystemId: system.systemId,
      IsEachWay: false,
      Group: this.setSystemGroup(system),
      OverallStake: this.setSystemOverallStake(system),
      VoucherCode: "",
    };

    this.store.dispatch(new TicketActions.RemoveStake(StakeGroup.SYSTEM, systemStake));
  }

  private setSystemOverallStake(system) {
    return this.ticketView.StakeGroups.System.totalAmount >= system.numberOfBetWays
      ? this.ticketView.StakeGroups.System.totalAmount * 100
      : system.numberOfBetWays * 100;
  }

  private setSystemGroup(system) {
    let systemGroup = "";
    switch (system.systemId) {
      case 2:
        systemGroup = StakeGroup.DOUBLES;
        break;
      case 3:
        systemGroup = StakeGroup.TRIPLES;
        break;
      case 4:
        systemGroup = StakeGroup.CUADRUPLES;
        break;
      default:
        systemGroup = `${StakeGroup.SYSTEM}${system.systemId}`;
        break;
    }
    return systemGroup;
  }

  public removeTicket() {
    this.stopOveraskPolling = true;
    this.store.dispatch(new TicketActions.RemoveTicket(new removeTicket()));
  }

  public SetOddErrors() {
    const { OrderErrors } = this.ticketState.ticket;
    const NotAvailableItems = this.getNotAvailableItems();
    if (NotAvailableItems.length > 0) return this.showRemoveNotAvailableSelections(NotAvailableItems); // 'El mercado no está disponible';
    if (OrderErrors["NationalBetTypeNotAllowed"]) return this.showCombinationForbidden(); // 'No es posible combinar pronósticos del mismo mercado';
    if (TicketMethods.getHasErrors(this.ticketState.ticket)) return;

    if (this.ticketState.filterSelected.betType === StakeGroup.ACCUMULATOR && OrderErrors["51931"]) {
      this.showSameMarketCombinationForbidden();
    } else {
      this.store.dispatch(new TicketActions.SetOddErrors());
    }
  }

  //CHECK BEHAVIOUR TICKET FUNCTIONALLITY REQUERIMENTS

  updateOdds() {
    const _timer = this.isDesktop ? this._globalVars.TK_DESK_RT : this._globalVars.TK_RT;
    this.update$ = timer(0, _timer)
      .pipe(
        switchMap(() => this.ticket$.pipe(take(1))),
        filter((state: any) => state && TicketMethods.getIsUpdatableTicket(state.ticket)),
        switchMap((ticketS: ticketState) => {
          const odds: string[] = [];
          TicketMethods.getAllItems(ticketS.ticket).map((lineItem: any) => {
            if (!lineItem.SmartLineItems) {
              if (
                lineItem.Odd > 1 ||
                (lineItem.SportHandle !== "greyhound_racing" && lineItem.SportHandle !== "horse_racing")
              ) {
                odds.push(lineItem.ResultsNr);
              }
            }
          });
          if (odds.length >= 1) {
            return this._sportService
              .getUpdateOdds(odds)
              .pipe(map((responseData: oddsUpdateItem) => responseData));
          } else {
            return of(null);
          }
        }),
        catchError(() => of()),
      )
      .subscribe(
        (responseData: any) => {
          if (!responseData) return;
          const oddsAr: OddItem[] = [];
          TicketMethods.getAllItems(this.ticketView).map((lineItem) => {
            responseData.map((oddsUpdateI: oddsUpdateItem) => {
              if (oddsUpdateI.NodeId === lineItem.ResultsNr && this.shouldUpdateItem(oddsUpdateI, lineItem)) {
                oddsAr.push({
                  ItemId: lineItem.ItemId,
                  Odds: oddsUpdateI.Odd,
                });
              }
            });
          });
          this.store.dispatch(new TicketActions.MarketLocked(responseData, oddsAr));
        },
        (err) => {
          console.error("err ", err);
        },
      );
  }

  shouldUpdateItem(oddsUpdateElement: oddsUpdateItem, lineItem): boolean {
    const shouldUpdate: boolean = false;
    if (oddsUpdateElement.Odd !== lineItem.Odd && !oddsUpdateElement.NotAvailable) return true; // Item with odd changes
    if (lineItem.IsLive && oddsUpdateElement.Locked && !oddsUpdateElement.NotAvailable) return true; // Live and Locked item
    return shouldUpdate;
  }

  public hasResult(
    idResult: string,
    place?: number,
    withOdd: boolean = false,
    isRaceSport: boolean = false,
  ): boolean {
    if (!this.ticketView) return false;
    const result = this.ticketView.LineItems.findIndex((selection) => {
      if (isRaceSport) {
        if (place !== 2) {
          if (!withOdd) {
            return selection.ResultsNr === idResult && selection.Place === place && selection.Odd === -1;
          } else {
            return selection.ResultsNr === idResult && selection.Place === place && selection.Odd !== -1;
          }
        } else {
          return selection.ResultsNr === idResult && selection.Place === place;
        }
      } else {
        if (place) {
          if (!withOdd) {
            return selection.ResultsNr === idResult && selection.Place === place && selection.Odd === -1;
          } else {
            return selection.ResultsNr === idResult && selection.Place === place && selection.Odd !== -1;
          }
        } else {
          return selection.ResultsNr === idResult;
        }
      }
    });
    return result !== -1;
  }

  public async presentOddAcceptance() {
    const auxCurrentOddAcceptance = this._globalVars.cuotaAccept;
    const alert = await this._ticketService.getConfigAlertOddsChange();
    alert.present();

    alert.onDidDismiss().then((alertResp) => {
      if (alertResp.data.accepted) {
        this._globalVars.setCuotaAccept(alertResp.data.value);
        this.store.dispatch(new TicketActions.SetBetOddAcceptance());
      } else {
        this._globalVars.cuotaAccept = auxCurrentOddAcceptance;
      }
    });
  }

  // MOBILE
  // Item Selection
  setLineItemSelected(lineItem) {
    this.store.dispatch(new TicketActions.SetLineItemSelected(lineItem));
    if (this.isMobile) this.openKeyboard();
  }

  setLineItemUnselected() {
    this.store.dispatch(new TicketActions.SetLineItemUnselected());
  }

  // Keyboard
  toggleKeyboard() {
    const isOpen = this.keyboard$.getValue();
    this.mobileBehavior.closeKeyboard(!isOpen);
  }

  openKeyboard() {
    this.mobileBehavior.closeKeyboard(true);
  }

  closeKeyboard() {
    this.mobileBehavior.closeKeyboard(false);
  }

  okPressKeyboard(amount) {
    const eventData = {
      amount,
      betType: this.ticketView.BetType,
      systemId: this.systemId(),
      multiSingleAmount: TicketMethods.getNumberOfSelections(this.ticketView),
      lineItems:
        this.getSelectedLines().length > 0 ? this.getSelectedLines() : TicketMethods.getAllItems(this.ticketView),
    };

    if (this.ticketView.BetType === 0) {
      this.multiStakeUpdate(eventData);
    } else {
      this.updateStake(eventData);
    }
    this.closeKeyboard();
  }

  getSelectedLines() {
    const items = TicketMethods.getAllItems(this.ticketView);
    return items.filter((i) => i.Selected);
  }

  /**
   * Get all ticket items
   * @returns []
   */
  getAllItems() {
    return [
      ...this.ticketView.LineItems,
      ...this.ticketView.StraightMulticast,
      ...this.ticketView.CombinationMulticast,
      ...this.ticketView.SmartLineItems,
    ];
  }

  systemId() {
    let systemId: number;
    switch (this.ticketView.BetType) {
      case 0:
        systemId = 1;
        break;
      case 1:
        systemId = this.ticketView.StakeGroups.Accumulator.systemId;
        break;
      case 2:
        systemId = this.ticketView.StakeGroups.System.systemId;
        break;
      default:
        break;
    }
    return systemId;
  }

  /**
   * Get the total selections on the ticket
   * @param ticketState
   * @returns number of ticket LineItems + SmartLineItems + StraightMulticast + CombinationMulticast
   */
  getTicketSelections(ticketsState) {
    return (
      ticketsState.ticket.LineItems.length +
      ticketsState.ticket.SmartLineItems.length +
      ticketsState.ticket.StraightMulticast.length +
      ticketsState.ticket.CombinationMulticast.length
    );
  }

  // BetSense
  /**
   * Event to add a selection by betsense iframe
   * Should be binded to a BetSenseData object, which can be accessed through the 'this' object
   * @param eventId Event data
   */
  addBetsenseSelection(eventId): void {
    const isSelected = this.betsenseSelections.find((selection) => selection === eventId);
    if (isSelected) {
      this.betsenseSelections.map((selection, index) => {
        if (selection === eventId) {
          this.betsenseSelections.splice(index, 1);
        }
      });
    } else {
      this.betsenseSelections.push(eventId);
    }
  }

  // FREEBET

  /**
   * Set FreeBetsVouchers on TicketState
   * @param vouchers
   */
  setFreeBetsVouchers(vouchers: C_FreeBetTicket): void {
    this.store.dispatch(new TicketActions.FreeBetsSetVouchers(vouchers));
  }

  /**
   * Update FreeBetsVouchers on TicketState
   */
  updateFreeBets(): void {
    this.store.dispatch(new TicketActions.FreeBetsUpdateVouchers());
  }

  /**
   * Toggle FreeBet switch
   */
  async toggleFreebet() {
    this._trackingService.track({
      eventType: EventTypes.FreeBetToogle,
      additionalData: {
        freeBetActivated: true,
      },
    });

    if (this._globalVars.user.logged) {
      this.promotableUser();
    } else {
      const callBackLogin = () => {
        if (!this._globalVars.user.logged) return;
        this.toggleFreebet();
      };
      this._globalVars.rootScope.openLogin(callBackLogin);
    }
  }

  private promotableUser() {
    if (this._globalVars.licenseType === LicenseTypes.Nacional) {
      this._userService.getIsPromotionable(true).subscribe((resp) => {
        if (resp.isPromotable) {
          this.createFreeBetModal();
        } else {
          this.showNotPromotionableAlert(resp.promotableErrors);
        }
      });
    } else {
      this.createFreeBetModal();
    }
  }

  private async createFreeBetModal() {
    const modal = await this.modalController.create({ component: TicketFreebetsPage, cssClass: "is-modal" });
    modal.onDidDismiss();
    modal.present();
    this._trackEventToggleFreeBet(true);
  }

  private async showNotPromotionableAlert(errors) {
    const mostCommonError = this.sortNotPromotableErrors(errors);
    const errorMsgString = `fb${mostCommonError.charAt(0).toUpperCase()}${mostCommonError.slice(1)}`;
    const promotionableAlert = {
      enableBackdropDismiss: false,
      title: "Lo sentimos",
      message: this._translate.instant(errorMsgString),
      buttons: [
        {
          text: "Ok",
          handler: () => {
            alert.dismiss();
          },
        },
      ],
    };

    if (errorMsgString === "fbDocuments") {
      promotionableAlert.buttons = [
        ...promotionableAlert.buttons,
        {
          text: "Verificar cuenta",
          handler: async () => {
            const navTransition = alert.dismiss();
            navTransition.then(() => {
              this._globalVars.rootScope.openModalOrPage(
                `${PAYMENTS_PATHS.BASE}/${PAYMENTS_PATHS.CashierPage}`,
                { section: "documents" },
                true,
                "modalCashier",
              );
            });
          },
        },
      ];
    }
    const alert = await this._alertController.create(promotionableAlert);
    alert.present();
  }

  private showRemoveNotAvailableSelections(notAvailableItems) {
    const notAvailableItemIds = [];
    notAvailableItems.forEach((i) => notAvailableItemIds.push(i.ItemId));
    this.removeSelections(notAvailableItemIds);
  }

  private async showSameMarketCombinationForbidden() {
    const sameMarketCombination = {
      enableBackdropDismiss: false,
      title: this._translate.instant("Attention"),
      message: this._translate.instant("SPO_SameMarketCombinationForbidden"),
      buttons: [
        {
          text: this._translate.instant("SPO_ChangeBettype"),
          handler: () => {
            this.store.dispatch(new TicketActions.ChangeFilterAndSetOddErrors(0));
          },
        },
        {
          text: this._translate.instant("SPO_ContinueInAccumulator"),
          handler: async () => {
            (await alert).dismiss();
          },
        },
      ],
    };
    const alert = this._alertController.create(sameMarketCombination);
    (await alert).present();
  }

  private async showCombinationForbidden() {
    const combinationForbidden = {
      enableBackdropDismiss: false,
      title: this._translate.instant("Attention"),
      message: this._translate.instant("SPO_CombinationForbidden"),
      buttons: [
        {
          text: this._translate.instant("SPO_ChangeBettype"),
          handler: () => {
            this.store.dispatch(new TicketActions.ChangeFilterAndSetOddErrors(0));
          },
        },
        {
          text: this._translate.instant("SPO_ContinueInAccumulator"),
          handler: async () => this.store.dispatch(new TicketActions.SetOddErrors()),
        },
      ],
    };
    const alert = this._alertController.create(combinationForbidden);
    (await alert).present();
  }

  private async showRemoveFreebetOnTicketChange() {
    const removeFreebetOnTicketChange = {
      enableBackdropDismiss: false,
      title: this._translate.instant("Attention"),
      message: this._translate.instant("SPO_RemoveFreebetOnTicketChange"),
      buttons: [
        {
          text: this._translate.instant("SPO_RemoveFreebet"),
          handler: () => {
            this.cancelFreebet();
          },
        },
        {
          text: this._translate.instant("tCancel"),
          handler: async () => {
            (await alert).dismiss();
          },
        },
      ],
    };
    const alert = this._alertController.create(removeFreebetOnTicketChange);
    (await alert).present();
  }

  private sortNotPromotableErrors(errors) {
    const errorsOrder = ["30", "documents", "risk", "autoforbidden", "autoexcluded"];
    errors.sort((a, b) => {
      const x = errorsOrder.indexOf(a);
      const y = errorsOrder.indexOf(b);
      return x === y ? 0 : x > y ? 1 : -1;
    });
    return errors[0];
  }

  private getNotAvailableItems() {
    const notAvailableLI = this.ticketState.ticket.LineItems.filter((i) => i.NotAvailable);
    const notAvailableSLI = this.ticketState.ticket.SmartLineItems.filter((i) => i.NotAvailable);
    const notAvailableSM = this.ticketState.ticket.StraightMulticast.filter((i) => i.NotAvailable);
    const notAvailableCM = this.ticketState.ticket.CombinationMulticast.filter((i) => i.NotAvailable);

    return [...notAvailableLI, ...notAvailableSLI, ...notAvailableSM, ...notAvailableCM];
  }

  async openBonus() {
    let msg: string = this._translate.instant("ticketapuestastxt02");
    if (this._globalVars.licenseType !== LicenseTypes.Nacional)
      msg = this._translate.instant("ticketapuestastxt03");
    const alert = await this._alertController.create({
      header: this._translate.instant("v2_mejTuPrem"),
      message: msg,
      buttons: [
        {
          text: "OK",
          role: "cancel",
        },
      ],
    });
    alert.present();
  }

  freeBetsAdd(freebet: C_FreeBetTicket, personalized: boolean): void {
    const voucherInfo = this.ticketState.freeBets.find((i) => i.VoucherCode === freebet.VoucherCode);
    if (voucherInfo) {
      const mappedFreeBet = C_FreeBetVoucher.mapFBVoucher(voucherInfo.PBSVoucherInfo);
      this.store.dispatch(new TicketActions.FreeBetsAdd(mappedFreeBet, personalized, this.filterSelected));
    }
  }

  cancelFreebet(): void {
    try {
      const voucherCode = this.ticketBetSlip.bonus.freeBetVouchers[0].voucherCode;
      this.store.dispatch(new TicketActions.FreeBetsCancel(voucherCode));
    } catch (error) {
      console.error("Error canceling free bet:", error);
    }
  }

  private openDeposits(): void {
    this._utils.confirm(
      false,
      "",
      this._translate.instant("ticketapuestastxt01") +
        this._parseMoney.transform(this._globalVars.user.balance.toString(), [1]) +
        "</boo></p>",
      this._translate.instant("tCancel"),
      undefined,
      this._translate.instant("depositBalance"),
      () => {
        setTimeout(() => {
          this._globalVars.rootScope.openModalOrPage(
            `${PAYMENTS_PATHS.BASE}/${PAYMENTS_PATHS.CashierPage}`,
            {},
            true,
            "modalCashier is-modal",
          );
        }, 300);
      },
    );
  }

  // OVERASK
  overaskPollingSvc(OveraskRejectId: number): Observable<C_OveraskDataBetPlacement> {
    return (this.overask$ = timer(1, 3000).pipe(
      switchMap(() => this._ticketService.overaskCheckState(OveraskRejectId)),
      retry(),
      takeWhile(() => this.overaskPolling),
    ));
  }

  overaskStart() {
    this.stopOveraskPolling = false;
    this.overaskPolling = true;
  }

  overaskStop() {
    this.stopOveraskPolling = true;
    this.overaskPolling = false;
  }

  overaskMaxStake(overaskData, status, stake) {
    this.overaskStop();
    this.store.dispatch(new TicketActions.OveraskMaxStake(overaskData, status, stake));
  }

  initOveraskTimeOut(overaskData, status) {
    let overaskCountdown = 30;
    this.overaskCountdownStopped = false;
    this.timerCountdown = setInterval(() => {
      overaskCountdown--;
      this.overaskCountdown$.next(overaskCountdown);
      if (overaskCountdown === 0 || this.overaskCountdownStopped) this.clearOveraskCountdown(overaskData, status);
    }, 1000);
  }

  clearOveraskCountdown(overaskData, status) {
    this.stopTimer();
    this.overaskCountdown$.next(30);
    this.overaskTimeOut(overaskData, status);
  }

  stopTimer() {
    this.overaskCountdownStopped = true;
    if (this.timerCountdown) clearInterval(this.timerCountdown);
  }

  overaskTimeOut(overaskData, status) {
    this.overaskStop();
    this.store.dispatch(new TicketActions.OveraskTimeOut(overaskData, status));
  }

  overaskRejected(overaskData, status) {
    this.overaskStop();
    this.store.dispatch(new TicketActions.OveraskRejected(overaskData, status));
  }

  overaskCloseBet() {
    this.overaskStop();
    this.store.dispatch(new TicketActions.OveraskCloseBet());
  }

  public getEventByNode(nodeEvent: number) {
    return this._ticketService.getEventByNode(nodeEvent).pipe(
      map<any[], any>((response) => {
        if (response.length > 0) {
          return response[0];
        } else {
          return null;
        }
      }),
    );
  }

  private async alertSportsGeolocation(configAlert: ErrorAlertSportsCasinoGeolocation) {
    let msg: string = "";
    msg += `<div class="subtitlealert">${configAlert.subtitle}</div>`;
    configAlert.txt.forEach((value) => {
      msg += `<div>${value}</div>`;
    });

    const errorAlert = await this._utils.alert(false, configAlert.title, msg, configAlert.btn, undefined);
    this.footerServices.loadFooter().then((footerVar) => {
      this._utils.formatAlertSportsGeolocalization(
        errorAlert,
        footerVar.licenseVars[
          this._globalVars.licenseType === LicenseTypes.ArgentinaCaba
            ? "URL_ARGCABA_WHATSAPP"
            : "URL_ARGMZA_WHATSAPP"
        ],
        configAlert.linkwhatsapp,
      );
    });
  }

  navigateToTicket() {
    return this._globalVars.rootScope.navigateToTicket();
  }

  private _trackEventToggleFreeBet(isToggle: boolean): void {
    this._trackingService.track({
      eventType: EventTypes.FreeBetToogle,
      additionalData: {
        freeBetActivated: isToggle,
      },
    });
  }

  get isProcesing() {
    return this.ticketView.processing;
  }

  private trackPromoPreBet(bets, typeBet: number) {
    if (this._globalVars.FEATURES.ObjectPromoPreBet && this._globalVars.FEATURES.ObjectPromoPreBet !== "") {
      const objPromoBet = JSON.parse(this._globalVars.FEATURES.ObjectPromoPreBet);
      objPromoBet.dateStart = new Date(objPromoBet.dateStart);
      objPromoBet.dateEnd = new Date(objPromoBet.dateEnd);
      bets.forEach(async (b) => {
        if (await this.cantrackPromoPreBet(objPromoBet, b, typeBet)) {
          const isCreated = await this.isTrackerCreate(TRAKERSTYPECODEREID.aux2);
          const tracker: TrackersCodereId = {
            value: b.EventName,
            trackType: TRAKERSTYPECODEREID.aux2,
            cantidadapuesta: b.Amount,
            validUntil: objPromoBet.dateEnd,
            evento: b.EventName,
            cuota: b.Odd,
            fechaapuesta: new Date(),
            inplay: b.IsLive.toString(),
            deporte: b.SportHandle,
            mercado: b.GameName,
          };
          if (isCreated) {
            //Update tracker
            const hoursDiff: number = this.getDifferenceInHours(new Date(isCreated.fechaapuesta), new Date());
            if (hoursDiff >= 24) {
              const observer = this._globalVars.FEATURES.MSO_DecouplingTrackersCodereId
                ? this._decouplingUserServiceMSO.updateTrackersCodereID(
                    [tracker].map((t) => ({
                      betAmount: t.cantidadapuesta,
                      betDate: t.fechaapuesta,
                      betId: t.idapuesta,
                      category: t.categoriaevento,
                      event: t.evento,
                      inPlay: t.inplay,
                      market: t.mercado,
                      quota: t.cuota,
                      sport: t.deporte,
                      trackerType: t.trackType,
                      validUntil: t.validUntil,
                      code: t.value,
                    }))[0],
                  )
                : this._userService.updateTrackersCurrentUser([tracker]);
              observer.subscribe({
                next: (response) => {
                  console.log(response);
                },
                error: (error) => {
                  console.log(error);
                },
              });
            }
          } else {
            //Create tracker
            const observer = this._globalVars.FEATURES.MSO_DecouplingTrackersCodereId
              ? this._decouplingUserServiceMSO.createTrackersCodereID(
                  [tracker].map((t) => ({
                    betAmount: t.cantidadapuesta,
                    betDate: t.fechaapuesta,
                    betId: t.idapuesta,
                    category: t.categoriaevento,
                    event: t.evento,
                    inPlay: t.inplay,
                    market: t.mercado,
                    quota: t.cuota,
                    sport: t.deporte,
                    trackerType: t.trackType,
                    validUntil: t.validUntil,
                    code: t.value,
                  }))[0],
                )
              : this._userService.createTrackersCodereId([tracker]);
            observer.subscribe({
              next: (response) => {
                console.log(response);
              },
              error: (error) => {
                console.log(error);
              },
            });
          }
        }
      });
    }
  }

  private async cantrackPromoPreBet(objPromoBet, bet: LineItem, typeBet: number) {
    let canPreBet: boolean = false;

    if (
      objPromoBet.dateStart.getTime() <= new Date().getTime() &&
      objPromoBet.dateEnd.getTime() >= new Date().getTime()
    ) {
      if (
        bet.EventId === objPromoBet.node &&
        bet.Amount >= objPromoBet.minBet &&
        typeBet === objPromoBet.typeBet &&
        bet.Odd >= objPromoBet.cuota &&
        bet.IsLive === objPromoBet.inplay
      ) {
        try {
          canPreBet = await this.isPromoAccept(objPromoBet.bonusId);
        } catch (error) {
          console.error(error);
          canPreBet = false;
        }
      }
    }

    return canPreBet;
  }

  private getDifferenceInHours(date1, date2) {
    const diffInMs = Math.abs(date2 - date1);
    return diffInMs / (1000 * 60 * 60);
  }

  private isPromoAccept(promoId: string) {
    return new Promise<boolean>((resolve, reject) => {
      (async () => {
        try {
          let havePromo: boolean = false;
          if (promoId.length > 0) {
            const promoList = await firstValueFrom(this._userServiceMSO.getPromotionsList());
            if (promoList.count > 0) {
              promoList.promotions.forEach((promo) => {
                if (promo.promoId === promoId) {
                  havePromo = true;
                }
              });
            }
          } else {
            havePromo = true;
          }
          resolve(havePromo);
        } catch (error) {
          reject(error);
        }
      })();
    });
  }

  private async isTrackerCreate(trackType: number) {
    try {
      const observer = this._globalVars.FEATURES.MSO_DecouplingTrackersCodereId
        ? this._decouplingUserServiceMSO.getTrackersCodereID()
        : this._userService.getTrackersCurrentUser();
      const activeTrackers: TrackersCodereId[] = await firstValueFrom(observer);
      const tracker = activeTrackers.find((t) => t.trackType === trackType);
      return tracker;
    } catch (error) {
      console.log(error);
    }
  }

  restartBetslip() {
    this.store.dispatch(new TicketActions.RestartBetslip());
  }
}
