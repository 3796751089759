import { CommonModule } from "@angular/common";
import {
  AfterViewInit,
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { IonicModule } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";
import { SportsConstants } from "@sports-utils/index";
import { Swiper, SwiperOptions } from "swiper";

@Component({
  selector: "sb-tabs-categories",
  templateUrl: "./sb-tabs-categories.component.html",
  styleUrls: ["./sb-tabs-categories.component.scss"],
  standalone: true,
  imports: [CommonModule, IonicModule, TranslateModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SbTabsCategoriesComponent implements OnChanges, AfterViewInit {
  /**
   * trigger scroll to left when input change
   * @input triggerScrollLeft
   * @type number || string
   * */
  @Input() triggerScrollLeft: any;
  /**
   * scrollable segment
   * @input categories[]
   * @type Array
   * */
  @Input() scrollable: any = false;
  /**
   * categories Array
   * @input categories[]
   * @type Array
   * */
  @Input() categories: any;
  /**
   * Selected filter
   * @input selected
   * @type {category}
   */
  @Input() selectedTab: any;
  /**
   * Event emmited when the user selects a tab
   * @output category
   * @type {category}
   */
  @Output() selectTab: EventEmitter<any> = new EventEmitter();
  /**
   * color mode: 'regular', 'dark'
   * @input color
   * @type string
   */
  @Input() color: string = "regular";
  /**
   * Small font-size for the buttons
   * @input smallButton
   * @type boolean
   */
  @Input() smallButton: boolean = false;
  /**
   * ion-segment-button layout for mobile
   * @input isMobile
   * @type boolean
   */
  @Input() isMobile: boolean = false;
  @ViewChild("tabsCategoriesSwiper") tabsCategoriesSwiper:
    | ElementRef<HTMLElement & { swiper?: Swiper } & { initialize: () => void }>
    | undefined;

  public swiper?: Swiper;
  config: SwiperOptions = {
    centeredSlides: false,
    loop: false,
    slidesPerView: "auto",
    observer: true,
  };
  SpConstants = SportsConstants;
  mode = "regular";
  indexSelected = 0;
  swiperElements = [];

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (
      (changes?.triggerScrollLeft?.currentValue &&
        changes?.triggerScrollLeft?.previousValue &&
        changes.triggerScrollLeft.currentValue !== changes.triggerScrollLeft.previousValue) ||
      (!changes?.triggerScrollLeft?.previousValue && changes?.triggerScrollLeft?.currentValue)
    ) {
      this.tabsCategoriesSwiper.nativeElement.swiper?.slideTo(0);
      this.indexSelected = 0;
    } else {
      const selectedTabIndex = this.getCategoriesIndexSelected(this.categories, this.selectedTab?.CategoryId);
      if (selectedTabIndex !== -1 && this.indexSelected !== selectedTabIndex) {
        this.tabsCategoriesSwiper.nativeElement.swiper?.slideTo(selectedTabIndex);
        this.indexSelected = selectedTabIndex;
      }
    }
    if (this.tabsCategoriesSwiper?.nativeElement && !this.existsSwiper()) {
      this.setSwiperConfig();
    }
    if (this.existsSwiper() && this.categories?.length > 0) {
      this.swiperElements = this.categories;
      this.tabsCategoriesSwiper.nativeElement.swiper.update();
    }
  }

  getCategoriesIndexSelected(categories, categoryId) {
    if (!categories || !categoryId) return -1;
    return categories.findIndex((category) => category.CategoryId === categoryId);
  }

  ngAfterViewInit() {
    if (this.tabsCategoriesSwiper?.nativeElement && !this.existsSwiper()) {
      this.setSwiperConfig();
      this.ngOnChanges(undefined);
    }
  }

  setSwiperConfig() {
    const swiperEl = Object.assign(this.tabsCategoriesSwiper?.nativeElement, this.config);
    swiperEl.initialize();
    this.swiper = this.tabsCategoriesSwiper?.nativeElement?.swiper;
  }

  existsSwiper = () => this.swiper && !this.swiper.destroyed;

  /**
   * Selects an emmit the category selection
   * @param cat
   * @param index
   */
  onClick(cat, index) {
    this.selectTab.emit({ ...cat, index });
  }
}
