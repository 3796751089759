/* eslint-disable eqeqeq */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method*/
import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";

@Component({
  selector: "cs-oxxobank-form",
  templateUrl: "./cs-oxxobank-form.html",
  styleUrls: ["./cs-oxxobank-form.scss"],
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
})
export class CsOxxobankFormComponent {
  @Input() paymentForm: FormGroup;
  @Input() license: any;
  @Output() checkDeposit: EventEmitter<any> = new EventEmitter();

  emitWithdrawal() {
    this.checkDeposit.emit();
  }

  ngAfterViewInit() {}

  get placeholderDoc() {
    return this.license === 22 ? "Número de cédula/pasaporte" : "Número del documento IFE";
  }
}
