/* eslint-disable @typescript-eslint/no-explicit-any */
import { IonContent, IonicModule, NavParams } from '@ionic/angular';
import { CUSTOM_ELEMENTS_SCHEMA, Component, OnInit, ViewChild, inject } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CodereNavbar } from '@shared-common-components/codere-navbar/codere-navbar';
import { GlobalVarsModel } from '@models/ModelGlobalsVar';
import { NewBaseService } from '@providers/newBase.service';
import { UserService } from '@providers/UserService';
import { CodereFooterComponent } from '@shared-mso-components/footer/codere-footer/codere-footer';
import { UserSectionTitle } from '@shared-mso-components/user-section-title/user-section-title';
import { Utils } from '@utils/Utils';
import { I_RowExtractTable } from '@models/I_ExtractTable';
import { EventsService } from '@providers/EventsService';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { TrackingService } from '@providers/TrackingService';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'summary-of-movements-page',
  templateUrl: './summary-of-movements.html',
  styleUrls: ['./summary-of-movements.scss'],
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    IonicModule,
    CommonModule,
    CodereFooterComponent,
    CodereNavbar,
    TranslateModule,
    UserSectionTitle,
    ReactiveFormsModule
  ]
})
export class SummaryOfMovementsPage implements OnInit {
  @ViewChild(IonContent) content: IonContent;
  selectList: Array<{ value: string; name: string }>;
  labelsTable: Array<string>;
  selectedDate = new FormControl('30');
  startDate: Date;
  endDate: Date;
  rowsOfTable: I_RowExtractTable[];
  globalVars!: GlobalVarsModel;
  isMobile: boolean;

  events = inject(EventsService);
  utils = inject(Utils);
  userService = inject(UserService);
  translate = inject(TranslateService);
  activatedRoute = inject(ActivatedRoute);
  newBaseService = inject(NewBaseService);
  userservice = inject(UserService);
  route = inject(Router);
  trackingService = inject(TrackingService);
  navparams = inject(NavParams);

  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });

    this.selectList = [
      {
        value: '2',
        name: this.translate.instant('histapu2d')
      },
      {
        value: '7',
        name: this.translate.instant('histapu7d')
      },
      {
        value: '15',
        name: this.translate.instant('histapu15d')
      },
      {
        value: '30',
        name: this.translate.instant('last30days')
      },
      {
        value: 'lastmonth',
        name: this.translate.instant('lastmonth')
      },
      {
        value: '90',
        name: this.translate.instant('histapu90d')
      }
    ];

    this.setTableLabels();
  }
  ngOnInit(): void {
    this.selectedDate.valueChanges.subscribe((data) => {
      if (data) {
        this.selectDates(data);
      }
    });

    const navigation = this.route.getCurrentNavigation();
    if (navigation && navigation.extras && navigation.extras.state) {
      const state = navigation.extras.state;
      const type = state['type'];
      const start = state['start'];
      const end = state['end'];
      this.selectedDate.setValue(type);
      this.startDate = new Date(start);
      this.endDate = new Date(end);
    } else {
      this.selectDates('30');
    }
  }

  ionViewWillEnter() {
    this.trackingService.trackEvent([
      'AccessToSummaryOfMovementsPage',
      '',
      '',
      'Acceder a resumen de movimiento',
      'event'
    ]);
    this.events.publish('footer:refresh', this.content);
  }

  selectDates(value: string) {
    switch (value) {
      case '2':
        this.startDate = new Date();
        this.startDate.setDate(this.startDate.getDate() - 2);
        this.endDate = new Date();
        break;
      case '7':
        this.startDate = new Date();
        this.startDate.setDate(this.startDate.getDate() - 7);
        this.endDate = new Date();
        break;
      case '15':
        this.startDate = new Date();
        this.startDate.setDate(this.startDate.getDate() - 15);
        this.endDate = new Date();
        break;
      case '30':
        this.startDate = new Date();
        this.startDate.setDate(this.startDate.getDate() - 30);
        this.endDate = new Date();
        break;
      case 'lastmonth':
        this.startDate = new Date();
        this.startDate.setDate(1);
        this.startDate.setMonth(this.startDate.getMonth() - 1);
        this.endDate = new Date(this.startDate.getFullYear(), this.startDate.getMonth() + 1, 0);
        break;
      case '90':
        this.startDate = new Date();
        this.startDate.setDate(this.startDate.getDate() - 90);
        this.endDate = new Date();
        break;
    }
    this.getMovements();
  }

  onSelectChange(event: any) {
    this.selectDates(event.detail.value);
  }
  getMovements() {
    if (this.endDate && this.startDate) {
      this.utils.loader();
      this.userservice.getUserExtractTable(this.formatDate(this.startDate), this.formatDate(this.endDate)).subscribe({
        next: (data) => {
          this.rowsOfTable = data;
          this.utils.closeLoader();
        },
        error: () => {
          this.utils.closeLoader();
        }
      });
    }
  }

  setTableLabels() {
    this.labelsTable = [
      'Fecha/Hora',
      'Crédito',
      'Débito',
      'Saldo',
      'Tipo transacción',
      'ID transacción',
      'ID apuesta',
      'Casino Sesión ID'
    ];
  }
  formatDate(datetoFormat: Date) {
    let day = '';
    if (datetoFormat.getDate() < 10) {
      day = '0' + datetoFormat.getDate().toString();
    } else {
      day = datetoFormat.getDate().toString();
    }
    let month = '';
    if (datetoFormat.getMonth() + 1 < 10) {
      month = '0' + (datetoFormat.getMonth() + 1).toString();
    } else {
      month = (datetoFormat.getMonth() + 1).toString();
    }
    return `${day}-${month}-${datetoFormat.getFullYear()}`;
  }
}
