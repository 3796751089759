export class C_UserDataBGT_MX {
  hasbgt: boolean;
  userdata: C_UserDataMX;
  success: boolean;
  Message: any;
  constructor() {
    this.success = true;
    this.Message = '';
    this.hasbgt = false;
    this.userdata = new C_UserDataMX();
  }
}

export class C_UserDataMX {
  ActiveCardNumber: string;
  CellPhone: string;
  City: string;
  Colony: string;
  County: string;
  DateofBirth: string;
  DateofBirthSpecified: boolean;
  Email: string;
  FirstName: string;
  IdMember: string;
  LastName: string;
  MaidenName: string;
  MemberNumber: number;
  NumTarjetaSocio: string;
  Points: number;
  PointsSpecified: boolean;
  PostalCode: string;
  RegistrationDate: string;
  RegistrationDateSpecified: boolean;
  RegistrationHall: string;
  State: string;
  Street: string;
  TarjetaBGT: string;

  constructor() {
    // ¿Necesita constructor??
    this.ActiveCardNumber = '';
    this.CellPhone = '';
    this.City = '';
    this.Colony = '';
    this.County = '';
    this.DateofBirth = '';
    this.DateofBirthSpecified = false;
    this.Email = '';
    this.FirstName = '';
    this.IdMember = '';
    this.LastName = '';
    this.MaidenName = '';
    this.MemberNumber = 0;
    this.NumTarjetaSocio = '';
    this.Points = 0;
    this.PointsSpecified = false;
    this.PostalCode = '';
    this.RegistrationDate = '';
    this.RegistrationDateSpecified = false;
    this.RegistrationHall = '';
    this.State = '';
    this.Street = '';
    this.TarjetaBGT = '';
  }
}
