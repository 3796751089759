/* eslint-disable @typescript-eslint/no-explicit-any */
import { CommonModule } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { IonicModule, NavParams } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [CommonModule, IonicModule],
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'cdr-info-over',
  templateUrl: './info-over.component.html',
  styleUrls: ['./info-over.component.scss']
})
export class InfoOverComponent implements OnInit {
  target: string;
  modals: Array<any>;

  params = inject(NavParams);
  translate = inject(TranslateService);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}

  ngOnInit(): void {
    this.target = this.params.get('target');
    this.modals = [
      { id: 'modalName', title: this.translate.instant('rNom'), description: [this.translate.instant('help_rNom')] },
      {
        id: 'modalName2',
        title: this.translate.instant('rPreN1'),
        description: [this.translate.instant('help_rPreN1')]
      },
      {
        id: 'modalName3',
        title: this.translate.instant('rPreN2'),
        description: [this.translate.instant('help_rPreN2')]
      },
      {
        id: 'modalBirthdate',
        title: this.translate.instant('rBDate'),
        description: [this.translate.instant('help_rBDate')]
      },
      {
        id: 'modalDNI',
        title: 'DNI',
        description:
          this.translate.instant('help_rDni') +
          '<div class="imgdni"><img src="assets/global/img/imgdni.png" alt="Img DNI"></div>'
      },
      { id: 'modalNIE', title: 'NIE', description: this.translate.instant('help_rNie') },
      {
        id: 'modalDocNumnber',
        title: this.translate.instant('NotResidDocumentNumber'),
        description: 'Introduce el número del documento que has selecciondo, sin espacios ni guiones'
      },
      {
        id: 'modalAddress',
        title: this.translate.instant('pIAddress'),
        description: [this.translate.instant('help_pIAddress')]
      },
      {
        id: 'modalPostalCode',
        title: this.translate.instant('rZip'),
        description: [this.translate.instant('help_rZip')]
      },
      {
        id: 'modalMobile',
        title: this.translate.instant('pIMvl'),
        description: [this.translate.instant('help_pIMvl')]
      },
      {
        id: 'modalEmail',
        title: this.translate.instant('pIMail'),
        description: [this.translate.instant('help_pIMail')]
      },
      {
        id: 'modalUser',
        title: this.translate.instant('loginUser'),
        description: [this.translate.instant('help_loginUser')]
      },
      {
        id: 'modalPassword',
        title: this.translate.instant('loginPass'),
        description: [this.translate.instant('tChangPassIns')]
      },
      {
        id: 'modalPasswordConfirm',
        title: this.translate.instant('rConfP'),
        description: [this.translate.instant('help_rConfP')]
      },
      {
        id: 'modalPromoCode',
        title: this.translate.instant('rCodB'),
        description: [this.translate.instant('help_rCodB')]
      },
      { id: 'modalFriendsID', title: 'Código amigo', description: 'Si tienes un código amigo, introdúcelo aquí.' },
      {
        id: 'modalProfesion',
        title: this.translate.instant('v2_profesion'),
        description: [this.translate.instant('selectprofesinlist')]
      },
      { id: 'modalPRP', title: this.translate.instant('prp'), description: [this.translate.instant('prpInfo')] },
      { id: 'modalSupportNumber', title: 'Número de Soporte', description: [] }
    ];
  }

  get message() {
    return this.modals.find((item) => item.id === this.target);
  }
}
