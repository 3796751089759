<!-- Datos de Contacto -->
<fieldset id="contactData" class="col s12">
  <legend class="flow-text title-section">{{ titleSection }}</legend>
  <!-- Direccion -->
  <cdr-input
    length="70"
    [label]="labels.address"
    [errorsCol]="errors['address']"
    modal="modalAddress"
    fcName="address"
    [fgrp]="formGrp"
  ></cdr-input>
  <!-- Código Postal -->
  <cdr-input
    length="10"
    [type]="zipType"
    [label]="labels.postalCode"
    [errorsCol]="errors['postalCode']"
    modal="modalPostalCode"
    fcName="postalCode"
    [fgrp]="formGrp"
  ></cdr-input>
  <!-- Teléfono móvil -->
  <cdr-input
    length="12"
    placeholder="600-123-456"
    [preImg]="mbPreImg"
    [label]="labels.mobile"
    [errorsCol]="errors['mobile']"
    type="tel"
    modal="modalMobile"
    fcName="mobile"
    [fgrp]="formGrp"
    class="mobileflag"
  >
  </cdr-input>
  <!-- Correo electrónico -->
  <cdr-input
    length="100"
    type="email"
    [label]="labels.email"
    [errorsCol]="errors['email']"
    modal="modalEmail"
    fcName="email"
    [fgrp]="formGrp"
  ></cdr-input>
</fieldset>
