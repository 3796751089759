export interface PragmaticInfo {
  totalSeatedPlayers: number;
  lastGameResults: number[];
}

export interface GameResult {
  gameId: string;
  result: string;
  time: string;
}
