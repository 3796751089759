<div class="card-filter-group">
  <ion-card
    *ngFor="let filter of filterGroup.filters"
    class="card-filter-item"
    (click)="selectFilter(filter)"
    [class.selected]="filter.selected"
  >
    <ion-card-content class="card-filter-content">
      <ion-icon *ngIf="filter.icon" [name]="filter.icon" class="card-icon"></ion-icon>
      <ion-icon *ngIf="filter.iconName" src="assets/global/img/{{filter.iconName}}.svg" class="card-icon"></ion-icon>
      <div class="card-text">{{ filter.text }} ({{ filter.count || 0 }})</div>
    </ion-card-content>
  </ion-card>
</div>
