<i class="lb-jackpot--line-animated" role="decorative"></i>
<!-- <i class="lb-jackpot--line-top" role="decorative"></i> -->

<div
  class="lb-jackpot--content"
  [ngClass]="{
  'license-co': globalVars.licenseType == 21
}"
>
  <div class="lb-jackpot--billboards" [ngStyle]="{ 'background-image': 'url('+ jackpot?.uiInfo?.imageUrl +')' }">
    <button
      *ngIf="jackpot?.jackpotInfo?.userJoined"
      class="lb-jackpot--button-close cd-button--sm background-color-transparent border-color-white"
      tappable
      (click)="join?.emit(jackpot)"
    >
      <span class="lb-jackpot--subtitle color-light">No Participar</span>
    </button>
  </div>

  <ng-container *ngIf="jackpot?.uiInfo?.jackpotsTitles?.length > 1">
    <div class="lb-jackpot--actions">
      <lb-counter
        [currentAmount$]="jackpot?.jackpotInfo?.currentAmounts[0]"
        [title]="jackpot?.uiInfo?.jackpotsTitles[0]"
        [titleSize]="false"
      >
      </lb-counter>
      <lb-counter
        [currentAmount$]="jackpot?.jackpotInfo?.currentAmounts[1]"
        [title]="jackpot?.uiInfo?.jackpotsTitles[1]"
        [titleSize]="true"
      >
      </lb-counter>

      <div class="lb-jackpot--toggle lb-jackpot--toggle--small">
        <ng-container *ngIf="jackpot?.jackpotInfo?.potId && !jackpot?.jackpotInfo?.userJoined">
          <i class="lb-jackpot--icon"></i>
          <button class="lb-jackpot--button">
            <span class="lb-jackpot--title color-light" tappable (click)="join?.emit(jackpot)">Participar</span>
          </button>
        </ng-container>

        <ng-container *ngIf="jackpot?.jackpotInfo?.potId && jackpot?.jackpotInfo?.userJoined">
          <img
            class="lb-jackpot--activated"
            src="https://www.codere.mx/jackpot/img/jackpot_on.png"
            alt="Jackpot activo"
          />
        </ng-container>

        <button
          class="lb-jackpot--button-info cd-button--sm background-color-transparent border-color-white"
          tappable
          (click)="info?.emit(jackpot)"
        >
          <span class="lb-jackpot--subtitle color-light">+ Más info</span>
        </button>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="jackpot?.uiInfo?.jackpotsTitles === undefined || jackpot?.uiInfo?.jackpotsTitles?.length <= 1">
    <div class="lb-jackpot--actions">
      <lb-counter [currentAmount$]="jackpot?.jackpotInfo?.currentAmounts[0]"> </lb-counter>

      <div class="lb-jackpot--toggle">
        <ng-container *ngIf="jackpot?.jackpotInfo?.potId && !jackpot?.jackpotInfo?.userJoined">
          <i class="lb-jackpot--icon"></i>

          <button class="lb-jackpot--button">
            <span class="lb-jackpot--title color-light" tappable (click)="join?.emit(jackpot)">Participar</span>
          </button>
        </ng-container>

        <ng-container *ngIf="jackpot?.jackpotInfo?.potId && jackpot?.jackpotInfo?.userJoined">
          <img
            class="lb-jackpot--activated"
            src="https://www.codere.mx/jackpot/img/jackpot_on.png"
            alt="Jackpot activo"
          />
        </ng-container>

        <button
          class="lb-jackpot--button-info cd-button--sm background-color-transparent border-color-white"
          tappable
          (click)="info?.emit(jackpot)"
        >
          <span class="lb-jackpot--subtitle color-light">+ Más info</span>
        </button>
      </div>
    </div>
  </ng-container>
</div>

<div class="lb-jackpot--slider">
  <ng-content></ng-content>
</div>

<i class="lb-jackpot--line-bottom" role="decorative"></i>
