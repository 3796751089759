import { Injectable, inject } from "@angular/core";
import { App, AppState } from "@capacitor/app";
import { Capacitor } from "@capacitor/core";
import { BundleInfo, CapacitorUpdater } from "@capgo/capacitor-updater";
import { CapacitorGetPlatform } from "@constants";
import { AlertController, AlertOptions } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { BaseService } from "@providers/BaseService";
import { firstValueFrom } from "rxjs";
import { NetworkService } from "./NetworkService";

interface CheckForNewVersion {
  isNewerVersionAvailable: boolean;
  releaseUrl: string;
  latestVersion: string;
}
//eslint-disable-next-line
enum ConnectionTypesValues {
  WIFI = "wifi",
  CELULAR = "cellular",
  NONE = "none",
  UNKNOWN = "unknown",
}

/**
 * Updates service
 * Contain methods related with the application updates
 */
@Injectable({ providedIn: "root" })
export class UpdatesService extends BaseService {
  bindEventsInitialized = false;

  private isUpdateDownloaded: boolean = false;

  private alertController = inject(AlertController);

  private translate = inject(TranslateService);

  private networkService = inject(NetworkService);

  private updater: { bundle: BundleInfo } = null;

  constructor() {
    super();
    CapacitorUpdater.notifyAppReady().then((updaterInfo) => (this.updater = updaterInfo));

    App.addListener("appStateChange", async (state: AppState) => {
      // fall back when the app is on the background
      if (state.isActive && this.isUpdateDownloaded && localStorage.getItem("updateAvailable") === "true") {
        localStorage.setItem("updateAvailable", "false");
        await CapacitorUpdater.reload(); // app reloads, process restart
      }
    });
  }

  public async checkForNewVersion(currentVersion?: string): Promise<CheckForNewVersion> {
    try {
      const { latestVersion, releaseUrl } = await firstValueFrom(
        this.httpClient.get<any>(this.globalVars.HCP_URL),
      );
      return {
        isNewerVersionAvailable: !!(currentVersion === "builtin" || currentVersion < latestVersion),
        latestVersion,
        releaseUrl,
      };
    } catch (error) {
      // console.error('HCP: Error al verificar la versión', error);
      return {
        isNewerVersionAvailable: false,
        latestVersion: null,
        releaseUrl: null,
      };
    }
  }

  /**
   * Manages how many times the app has started
   * @returns number of times the app has started
   */
  launchNumber(): number {
    const savedLaunches = localStorage.getItem("launches");
    if (!savedLaunches) {
      localStorage.setItem("launches", "0");
    } else {
      let count = parseInt(savedLaunches);
      if (count === 0) {
        count++;
        localStorage.setItem("launches", count.toString());
      }
    }

    return parseInt(localStorage.getItem("launches"));
  }

  /**
   * Setup Updates Listernes
   * we monitor the progress, when finished and any failures
   */
  async startUpdatesListeners() {
    await CapacitorUpdater.addListener("download", (e) => {
      console.log("CapacitorUpdater Update progress::", JSON.stringify(e));
    });

    await CapacitorUpdater.addListener("downloadComplete", async (e) => {
      console.log("CapacitorUpdater Download Complete", JSON.stringify(e.bundle));
      await CapacitorUpdater.next(e.bundle);
      // only time the var is set true is when the download is completed.
      this.isUpdateDownloaded = true;
      localStorage.setItem("updateAvailable", "true");
      await CapacitorUpdater.removeAllListeners();
    });

    await CapacitorUpdater.addListener("updateFailed", (e) => {
      console.error("CapacitorUpdater Update Failed", JSON.stringify(e));
    });
  }

  /**
   * Cancel download
   */
  async cancelUpdateDownload() {
    await CapacitorUpdater.setMultiDelay({
      delayConditions: [
        {
          kind: "background",
        },
      ],
    });
  }

  /**
   * Update If Required
   * Called on app.component on start up
   */
  async updateIfRequired(): Promise<void> {
    if (!this.updater) {
      this.updater = await CapacitorUpdater.notifyAppReady();
    }

    const LAUNCH_TIMES = 1; // we can change when to open the update modal

    const savedLaunches = this.launchNumber();
    // Check how many launches the device has had
    if (savedLaunches >= LAUNCH_TIMES) {
      this.globalVars.strVersion = this.updater.bundle.version;

      if (Capacitor.getPlatform() !== CapacitorGetPlatform.WEB) {
        this.networkService.initializeNetworkEvents();

        const response: CheckForNewVersion = await this.checkForNewVersion(this.updater.bundle.version);

        this.initUpdater(response);
      }
    }
  }

  initUpdater(response: CheckForNewVersion) {
    // fall back when the app is closed
    CapacitorUpdater.list().then(async (res: any) => {
      // has bundle
      if (res.bundles.length > 0 && localStorage.getItem("updateAvailable") === "true") {
        for (let index = 0; index < res.bundles.length; index++) {
          const bundle = res.bundles[index];
          if (bundle.version === response.latestVersion) {
            localStorage.setItem("updateAvailable", "false");
            await CapacitorUpdater.set(bundle); // app reloads, process restart
          }
        }
        // bundle empty, proceed download
      } else if (response.isNewerVersionAvailable && !this.isUpdateDownloaded) {
        const status = this.networkService.statusSignal();
        const isConnected = status?.connected;
        // If we dont have connection, we stop the proccess
        if (!isConnected) {
          return;
        }
        // Checks if the app is on connectionType
        const connectionType = status?.connectionType;
        switch (connectionType) {
          case ConnectionTypesValues.WIFI:
            await this.downloadUpdate(response);
            break;
          case ConnectionTypesValues.CELULAR:
            await this.downloadUpdateAlert(response);
            break;
          case ConnectionTypesValues.NONE:
            await this.cancelUpdateDownload();
            break;
          case ConnectionTypesValues.UNKNOWN:
            await this.cancelUpdateDownload();
            break;
        }
      }
    });
  }
  /**
   * Update Download
   * @param version CheckForNewVersion
   */
  async downloadUpdate(version: CheckForNewVersion) {
    let newVersion: BundleInfo;
    try {
      // starts listeners if connected
      await this.startUpdatesListeners();

      newVersion = await CapacitorUpdater.download({
        url: version.releaseUrl,
        version: version.latestVersion,
      });
    } catch (error) {
      console.error("Falló la actualización", error);
    } finally {
      if (newVersion) {
        localStorage.setItem("updateAvailable", "true");
        await this.installUpdateAlert(newVersion);
      }
    }
  }

  /**
   * Download Update Alert
   * @param version CheckForNewVersion
   */
  async downloadUpdateAlert(version: CheckForNewVersion) {
    const downloadAlertOpts: AlertOptions = {
      header: this.translate.instant("updatealerttitle"),
    };
    downloadAlertOpts.buttons = [
      {
        text: this.translate.instant("btndecline"),
        role: "cancel",
      },
      {
        text: this.translate.instant("btnyescont"),
        role: "accept",
        handler: async () => {
          await alert.dismiss();
          await this.downloadUpdate(version);
        },
      },
    ];

    const alert = await this.alertController.create(downloadAlertOpts);

    await alert.present();
  }

  /**
   * Install Update Alert
   * After the download zip is finished, we call this alert
   * Cancel = cancelUpdateDownload()
   * Action = CapacitorUpdater.set(version)
   * @param version BundleInfo
   */
  async installUpdateAlert(version: BundleInfo): Promise<void> {
    const installAlertOpts: AlertOptions = {
      header: this.translate.instant("updatealertmessage"),
      subHeader: this.translate.instant("updatealertsubtitle"),
    };
    installAlertOpts.buttons = [
      {
        text: this.translate.instant("btndecline"),
        role: "cancel",
      },
      {
        text: this.translate.instant("btnyescont"),
        role: "accept",
        handler: async () => {
          try {
            await alert.dismiss();
            localStorage.setItem("updateAvailable", "false");
            await CapacitorUpdater.set(version);
          } catch (error) {
            console.error("Falló la actualización", error);
          }
        },
      },
    ];

    const alert = await this.alertController.create(installAlertOpts);

    await alert.present();
  }
}
