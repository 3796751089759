import { C_League } from './C_League';

export class C_Country {
  Leagues: Array<C_League>;
  CountryCode: string;
  Name: string;
  NodeId: string;
  ParentNodeId: string;
  Priority: number;
  SportHandle: string;
  Selected: boolean;
  Collapsed: boolean = true;
  Locked: boolean;

  constructor(
    leagues: Array<C_League>,
    countryCode: string,
    name: string,
    nodeId: string,
    parentNodeId: string,
    priority: number,
    sporthandle: string,
    childrenCount?: number,
    locked?: boolean,
    Collapsed?: boolean
  ) {
    this.Leagues = leagues;
    this.CountryCode = countryCode;
    this.Name = name;
    this.NodeId = nodeId;
    this.ParentNodeId = parentNodeId;
    this.Priority = priority;
    this.SportHandle = sporthandle;
    this.Locked = locked;
    this.Selected = false;
    this.Collapsed = true;
  }
}
