<div class="casino-card--preloader heartbeat-animation background-color-dark" *ngIf="!loaded"></div>
<div class="casino-card--wrapper" (click)="playGame(false)">
  <ion-img
    class="casino-card--image"
    *ngIf="!setStreaming"
    [src]="game?.Image"
    [alt]="game?.Name"
    #cardNewImg
    (ionError)="errorImageHandler(cardNewImg)"
  />
  <div
    deferLoad
    (deferLoad)="playVideo()"
    class="casino-card--stream"
    *ngIf="setStreaming"
    [ngClass]="classStreaming ? 'streaming' : 'returnH'"
  >
    <video
      #videoPlayer
      width="100%"
      height="100%"
      autoplay="autoplay"
      loop="true"
      muted="muted"
      playsinline
      [poster]="game?.Image"
    >
      Your browser doesn't support HTML5 video
    </video>
  </div>

  <ng-container *ngIf="numberRank !== 0">
    <p class="casino-card--ranking">{{numberRank}}</p>
  </ng-container>
  <ng-container *ngIf="badges?.length">
    <div class="casino-card--icons">
      <ion-img class="icon" *ngFor="let icon of badges" [src]="baseImage + 'icono_' + icon + '.svg'" />
    </div>
  </ng-container>

  <!-- todo: no existe [style.display]="game?.Display" en casinoDataModels -->
  <div class="casino-live--content">
    <div class="casino-live--info">
      <div class="casino-live--players">{{playerCount}}</div>
      <div *ngIf="game?.gameTileSize === 'Bigrectangle'" class="casino-live--limit">
        {{min}}&euro;
        <span>-</span>
        {{max}}&euro;
      </div>
      <div *ngIf="game?.gameTileSize === 'Smallsquare'" class="casino-live--limit">{{max}}&euro;</div>
    </div>

    <div class="casino-live--roulette" *ngIf="isDesktop || game?.gameTileSize === 'Bigrectangle' && !grid">
      <ul class="casino-live--list last-list {{ animation }}">
        <li class="casino-live--number {{ selNumbers[0]?.class }}">{{ selNumbers[0]?.number }}</li>
        <li class="casino-live--number {{ selNumbers[1]?.class }}">{{ selNumbers[1]?.number }}</li>
        <li class="casino-live--number {{ selNumbers[2]?.class }}">{{ selNumbers[2]?.number }}</li>
        <li class="casino-live--number {{ selNumbers[3]?.class }}">{{ selNumbers[3]?.number }}</li>
        <li class="casino-live--number {{ selNumbers[4]?.class }}">{{ selNumbers[4]?.number }}</li>
        <li class="casino-live--number {{ selNumbers[5]?.class }}">{{ selNumbers[5]?.number }}</li>
        <li class="casino-live--number {{ selNumbers[6]?.class }}">{{ selNumbers[6]?.number }}</li>
        <li class="casino-live--number {{ selNumbers[7]?.class }}">{{ selNumbers[7]?.number }}</li>
        <li class="casino-live--number {{ selNumbers[8]?.class }}">{{ selNumbers[8]?.number }}</li>
        <li class="casino-live--number {{ selNumbers[9]?.class }}">{{ selNumbers[9]?.number }}</li>
      </ul>

      <ul class="casino-live--list new-list {{ animation }}">
        <li class="casino-live--number {{ selNumbers[0]?.class }}">{{ selNumbers[0]?.number }}</li>
        <li class="casino-live--number {{ selNumbers[1]?.class }}">{{ selNumbers[1]?.number }}</li>
        <li class="casino-live--number {{ selNumbers[2]?.class }}">{{ selNumbers[2]?.number }}</li>
        <li class="casino-live--number {{ selNumbers[3]?.class }}">{{ selNumbers[3]?.number }}</li>
        <li class="casino-live--number {{ selNumbers[4]?.class }}">{{ selNumbers[4]?.number }}</li>
        <li class="casino-live--number {{ selNumbers[5]?.class }}">{{ selNumbers[5]?.number }}</li>
        <li class="casino-live--number {{ selNumbers[6]?.class }}">{{ selNumbers[6]?.number }}</li>
        <li class="casino-live--number {{ selNumbers[7]?.class }}">{{ selNumbers[7]?.number }}</li>
        <li class="casino-live--number {{ selNumbers[8]?.class }}">{{ selNumbers[8]?.number }}</li>
        <li class="casino-live--number {{ selNumbers[9]?.class }}">{{ selNumbers[9]?.number }}</li>
        <li class="casino-live--number {{ selNumbers[10]?.class }}">{{ selNumbers[10]?.number }}</li>
      </ul>
    </div>

    <div class="casino-live--roulette" *ngIf="!isDesktop && game.gameTileSize === 'Smallsquare' || !isDesktop && grid">
      <ul class="casino-live--list last-list {{ animation }}">
        <li class="casino-live--number {{ selNumbers[0]?.class }}">{{ selNumbers[0]?.number }}</li>
        <li class="casino-live--number {{ selNumbers[1]?.class }}">{{ selNumbers[1]?.number }}</li>
        <li class="casino-live--number {{ selNumbers[2]?.class }}">{{ selNumbers[2]?.number }}</li>
        <li class="casino-live--number {{ selNumbers[3]?.class }}">{{ selNumbers[3]?.number }}</li>
        <li class="casino-live--number {{ selNumbers[4]?.class }}">{{ selNumbers[4]?.number }}</li>
        <li class="casino-live--number {{ selNumbers[5]?.class }}">{{ selNumbers[5]?.number }}</li>
      </ul>

      <ul class="casino-live--list new-list {{ animation }}">
        <li class="casino-live--number {{ selNumbers[0]?.class }}">{{ selNumbers[0]?.number }}</li>
        <li class="casino-live--number {{ selNumbers[1]?.class }}">{{ selNumbers[1]?.number }}</li>
        <li class="casino-live--number {{ selNumbers[2]?.class }}">{{ selNumbers[2]?.number }}</li>
        <li class="casino-live--number {{ selNumbers[3]?.class }}">{{ selNumbers[3]?.number }}</li>
        <li class="casino-live--number {{ selNumbers[4]?.class }}">{{ selNumbers[4]?.number }}</li>
        <li class="casino-live--number {{ selNumbers[5]?.class }}">{{ selNumbers[5]?.number }}</li>
        <li class="casino-live--number {{ selNumbers[6]?.class }}">{{ selNumbers[6]?.number }}</li>
      </ul>
    </div>
  </div>
</div>

<div class="casino-card--hover" *ngIf="isDesktop" (click)="playGame(false)">
  <h6 class="casino-card--hover-title color-light">{{ game?.Name | slotName | uppercase }}</h6>
  <div class="casino-card--hover-play-container">
    <div class="btn-play">
      <ion-img [src]="'assets/global/img/slots/icono_Play.svg'" />
    </div>
  </div>
</div>
