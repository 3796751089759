import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { PAGES_PATHS } from '../lib/constants/routes';
import { GlobalVarsModel } from '../models/ModelGlobalsVar';
import { NewBaseService } from '../providers/newBase.service';
import { EventsService } from '../providers/EventsService';

export const AuthGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  try {
    const router = inject(Router);
    let globalVars!: GlobalVarsModel;
    const newBaseService = inject(NewBaseService);
    newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      globalVars = data;
    });

    if (!globalVars.user.logged) {
      const events = inject(EventsService);
      router.navigateByUrl(PAGES_PATHS.HomePage);
      globalVars.rootScope.openLogin(() => {
        if (globalVars.user.logged) {
          router.navigateByUrl(state.url);
        }
      });
      return false;
    }
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
};
