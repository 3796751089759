<ng-container *ngIf="!hide">
  <ng-container *ngIf="page === 'Sports'; else gridview">
    <div class="lb-out-container" *ngIf="title$ | async">
      <h6 class="lb-out-title">{{titleShow}}</h6>
      <div class="lb-out-card">
        <ng-container *ngFor="let game of current$| async">
          <casino-card-new
            tappable
            class="lb-out-card--img swiper-slide"
            [game]="game"
            [grid]="true"
            [showGameTicker]="false"
            (turnPlay)="playGame($event)"
          >
          </casino-card-new>
        </ng-container>
      </div>
    </div>
  </ng-container>

  <ng-template #gridview>
    <div class="lb-out-containerPro" *ngIf="title$ | async">
      <h6 class="lb-out-titlePro">Promocionados</h6>
      <div class="lb-out-cardPro">
        <ng-container *ngFor="let game of current$| async">
          <casino-card-new
            tappable
            class="lb-out-cardPro--imgPro swiper-slide"
            [game]="game"
            [grid]="true"
            [showGameTicker]="false"
            (turnPlay)="playGame($event)"
          >
          </casino-card-new>
        </ng-container>
      </div>
    </div>
  </ng-template>
</ng-container>
