import { C_Game } from './C_Game';
import { C_CategoryInfo } from './C_CategoryInfo';
import { C_Result } from './C_Result';

export class C_GameGroupByName {
  GroupResults: Array<C_Result>;
  Results: Array<C_Result>;
  DisplayTypeName: string;
  Name: string;
  NodeId: string;
  ParentNodeId: string;
  GameType: number;
  Priority: number;
  SportHandle: string;
  ChildrenCount: number;
  Collapsed: boolean;
  CategoryInfo: C_CategoryInfo;
  CategoryInfos: Array<C_CategoryInfo>;
  SmartMarketAvailable: boolean;
  teamHome: string;
  teamAway: string;
  Spov: any;
  AllSpov: any;
  nameLeague: string;
  combinedResults: any;
  Locked: boolean;
  position: number;
  LTMEnabled?: boolean;

  constructor() {
    this.DisplayTypeName = '';
    this.Name = '';
    this.NodeId = '';
    this.ParentNodeId = '';
    this.GameType = null;
    this.Priority = null;
    this.SportHandle = '';
    this.ChildrenCount = null;
    this.Collapsed = false;
    this.GroupResults = [];
    this.CategoryInfo = null;
    this.CategoryInfos = [];
    this.teamHome = '';
    this.teamAway = '';
    this.Spov = [];
    this.AllSpov = [];
    this.LTMEnabled = false;
    this.SmartMarketAvailable = false;
  }
  public copyGame(gameSingle: C_Game) {
    this.DisplayTypeName = gameSingle.DisplayTypeName;
    this.Name = gameSingle.Name;
    this.NodeId = gameSingle.NodeId;
    this.ParentNodeId = gameSingle.ParentNodeId;
    this.GameType = gameSingle.GameType;
    this.Priority = gameSingle.Priority;
    this.SportHandle = gameSingle.SportHandle;
    this.ChildrenCount = gameSingle.ChildrenCount;
    this.Collapsed = gameSingle.Collapsed;
    this.CategoryInfo = gameSingle.CategoryInfo;
    this.CategoryInfos = gameSingle.CategoryInfos;
    this.GroupResults = gameSingle.Results;
    this.teamHome = gameSingle.teamHome;
    this.teamAway = gameSingle.teamAway;
    this.Spov = gameSingle.Spov;
    this.AllSpov = gameSingle.AllSpov;
    this.LTMEnabled = gameSingle.LTMEnabled;
    this.SmartMarketAvailable = gameSingle.SmartMarketAvailable;
  }

  static parse(obj: any): C_GameGroupByName {
    const game = Object.assign(new C_GameGroupByName(), obj);
    game.GroupResults = obj.Results || obj.GroupResults || [];
    return game;
  }

  static deserialize(obj: any): C_GameGroupByName {
    const game = Object.assign(new C_GameGroupByName(), obj);
    game.sort();
    return game;
  }

  public sort() {
    if (!this.Spov) {
      return;
    }
    this.GroupResults.sort((a, b) => this.getSpovValue(a.Spov) - this.getSpovValue(b.Spov));
  }

  private getSpovValue(spovString: string): number {
    const spovValues: string[] = spovString.split('|');
    const spovSection: string = spovValues.find((x) => x.startsWith('<Spov>'));
    return spovSection ? +spovSection.substring(6) : 0;
  }
}

//export = C_GameGroupByName
