/* eslint-disable eqeqeq */
import { Component, Input, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { c_FreeBetMapped } from '../../../models/C_FreeBet';
import { TranslateModule } from '@ngx-translate/core';
import { ParseMoney } from '../../../pipes/parseMoney';
import { FormatOdd } from '../../../pipes/formatOdd.pipe';
import { ExtractDatePipe } from '../../../pipes/extractDate.pipe';
import { GlobalVarsModel } from '../../../models/ModelGlobalsVar';
import { NewBaseService } from '../../../providers/newBase.service';

@Component({
  standalone: true,
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'tipo-free-bet',
  templateUrl: 'tipo-free-bet.html',
  styleUrls: ['tipo-free-bet.scss'],
  imports: [IonicModule, CommonModule, ParseMoney, TranslateModule, FormatOdd, ExtractDatePipe]
})
export class TipoFreeBet {
  @Input() freeBet: c_FreeBetMapped;
  @Input() Index: string;

  toolTippWidth: string;
  progressBarWidth: string;
  globalVars!: GlobalVarsModel;

  newBaseService = inject(NewBaseService);

  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
  }

  ngAfterViewInit() {
    const porcentajeFB = Math.ceil((this.freeBet.AmountBets * 100) / this.freeBet.AmountFb);
    this.toolTippWidth = `calc(${porcentajeFB}% - 60px)`;
    this.progressBarWidth = `${porcentajeFB}%`;
  }

  calcFreebetEur(GrossWin): string {
    if (GrossWin == 0) return '0';
    else {
      const fbEu = GrossWin / 2;
      if (fbEu / 10 < 1) return '0';
      else {
        return ((fbEu / 10) * 10).toString();
      }
    }
  }
}
