<div class="modalbono">
  <ion-list>
    <div class="myWrapLegal">
      <div class="titbono margint10 padding15 txtcenter">¡Bienvenido a Codere.es!</div>
      <div class="padding015 txtcenter paddingb20">
        Hoy es tu día de suerte. ¡Elige el bono de bienvenida que más te guste!
      </div>
      <ion-radio-group [(ngModel)]="bonos" mode="md">
        <ion-item class="margint10" lines="none">
          <div class="ion-radio-container">
            <ion-radio slot="start" labelPlacement="end" (click)="promotion_click(true)" value="200"></ion-radio>
          </div>
          <ion-img slot="end" src="assets/global/img/bonos/bono200_200.png"></ion-img>
        </ion-item>
      </ion-radio-group>
    </div>
    <div class="myWrapLegal">
      <ion-radio-group [(ngModel)]="bonos" mode="md">
        <ion-item class="margint10" lines="none">
          <div class="ion-radio-container">
            <ion-radio slot="start" labelPlacement="end" (click)="promotion_click(false)" value="10_30"></ion-radio>
          </div>
          <ion-img slot="end" src="assets/global/img/bonos/bono10_30.png"></ion-img>
        </ion-item>
      </ion-radio-group>
    </div>
  </ion-list>

  <div class="alert-message txtcenter txtalertmes">{{'v2_RegPromoReg03' | translate}}</div>
  <div class="alert-button-group margint10 paddingb20">
    <ion-button size="small" class="ion-button-promotion" fill="clear" (click)="goToHome()" color="primary">
      {{'tAccept' | translate}}
    </ion-button>
    <ion-button size="small" class="ion-button-promotion" (click)="goToDeposits()">
      {{'v2_RegPromoReg04' | translate}}
    </ion-button>
  </div>
</div>
