// ! Modelo que se encontraba dentro de las GlobalVars

export class CApplicationState {
  MiniRuletaOn: boolean;
  StreamingOn: boolean;

  constructor() {
    this.MiniRuletaOn = false;
    this.StreamingOn = false;
  }
}
