export class C_PlayerPropsLeagues {
  nameLeague: string;
  country: string;
  nodeId: number;
  constructor(league: C_PlayerPropsLeagues) {
    this.nameLeague = league.nameLeague;
    this.country = league.country;
    this.nodeId = league.nodeId;
  }
}
