<div
  id="spacerFooter"
  class="spacerFooter background-color-dark"
  [ngClass]="{
  'footerEs': globalVars.LICENSE_TYPE == 0,
  'footerCo': globalVars.LICENSE_TYPE == 21,
  'footeArCaba': globalVars.LICENSE_TYPE == 33 || globalVars.LICENSE_TYPE == 34,
  'footerPa': globalVars.LICENSE_TYPE == 22,
}"
  *ngIf="showFooterSpacer"
></div>
<ion-footer class="center" [hidden]="isHidden">
  <ion-toolbar position="bottom" [class]="toolbarCss" color="dark">
    <codere-footer-header
      [socialsLinks]="socialsLinks"
      [headerImages]="imagesHeader"
      [divSocilasCss]="divSocilasCss"
      [addViewMobile]="addViewMobile"
      [downloadLinks]="headerDownloadLinks"
      [downloadLinksDivCss]="downloadLinksDivCss"
      [downloadLinksMobileColombia]="downloadLinksMobileColombia"
      [colombiaImagesHeader]="colombiaImagesHeader"
      [colombiaImagesHeaderMobile]="colombiaImagesHeaderMobile"
      [socialsLinksMobileColombia]="socialsLinksMobileColombia"
      [socialMobile]="socialMobile"
      [headersImgMobile]="headersImgMobile"
    ></codere-footer-header>
    <div class="container">
      <codere-footer-body
        *ngIf="isDesktop"
        [class]="bodyClass"
        [bodyInformation]="bodyInformation"
        text-left
      ></codere-footer-body>
    </div>
    <div *ngIf="!isDesktop">
      <codere-footer-body [bodyInformation]="bodyInformation"></codere-footer-body>
    </div>

    <codere-footer-extraImg
      *ngIf="globalVars.licenseType == LicenseTypes.ArgentinaCaba"
      [extraImgs]="extraImgsAftDeposit"
    ></codere-footer-extraImg>

    <codere-footer-deposit *ngIf="imagesDeposit.length > 0" [imagesDeposits]="imagesDeposit"></codere-footer-deposit>
    <codere-footer-new-imgs *ngIf="newFooterImgs.length > 0" [items]="newFooterImgs"></codere-footer-new-imgs>
    <codere-footer-new-imgs
      class="newSocialImgs"
      *ngIf="newSocialsLinks.length > 0"
      [items]="newSocialsLinks"
      [additionalClass]="'socialContainer socialPPa'"
    ></codere-footer-new-imgs>
    <codere-footer-legal [timer]="timer" [time]="time" [rowsTexts]="rowsLegal" [aenor]="aenor"></codere-footer-legal>
  </ion-toolbar>
</ion-footer>
