import { inject, Injectable } from '@angular/core';
import { GlobalVarsModel } from '@models/ModelGlobalsVar';
import { NewBaseService } from './newBase.service';

type GTMCategory = 'Login' | 'Signup' | 'FTD' | 'SDeposits';
interface GTMStorage {
  [key: string]: {
    [key in GTMCategory]?: GTMData[];
  };
}

//eslint-disable-next-line
export enum TrackStatus {
  OK = 1,
  KO = 0
}

interface GTMData {
  status: TrackStatus;
  userId?: number;
  paymentMethod?: string;
  amount?: number;
  signupDate?: string;
  lastLoginDate?: string;
  lastDepositDate?: string;
  advertiser?: string;
  profile?: string;
  acqSource?: string;
  ID2?: string;
  ID3?: string;
  balance_amt?: number;
  ftd?: number;
}

@Injectable({
  providedIn: 'root'
})
export class GTMDataManagerService {
  private globalVars!: GlobalVarsModel;
  private GTMKeyStorage = 'GTMStorage';
  private maxItems = 90;
  private newBaseService = inject(NewBaseService);

  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
  }

  private getStoreData(): GTMStorage {
    const data = localStorage.getItem(this.getStoreName);
    return data ? JSON.parse(data) : {};
  }

  private get getStoreName(): string {
    return `${this.GTMKeyStorage}_${this.globalVars.user.username}`;
  }

  private saveDataStore(newData: GTMStorage) {
    localStorage.setItem(this.getStoreName, JSON.stringify(newData));
  }

  private trimToMaxEntries(data: GTMStorage) {
    const entries = Object.entries(data).sort(
      ([dateA], [dateB]) => new Date(dateA).getTime() - new Date(dateB).getTime()
    );
    while (entries.length > this.maxItems) {
      entries.shift();
    }
    const newData = Object.fromEntries(entries);
    Object.assign(data, newData);
  }

  addItem(newItem: GTMData, category: GTMCategory) {
    const data = this.getStoreData();
    const dateKey = new Date().toISOString().split('T')[0];
    if (!data[dateKey]) {
      data[dateKey] = {};
    }
    if (!data[dateKey][category]) {
      data[dateKey][category] = [];
    }
    data[dateKey][category].push(newItem);
    this.trimToMaxEntries(data);
    this.saveDataStore(data);
  }
}
