<!-- eslint-disable @angular-eslint/template/eqeqeq -->

<ng-container *ngIf="licenseType != licenseMDZ">
  <h6 class="cs-trans--title color-dark">{{ 'depTransf' | translate }}</h6>
  <div class="cs-transfer--line"></div>
  <p class="cs-trans--text color-dark">1. {{ 'depTransf01' | translate }}</p>
  <p class="cs-trans--text color-dark">
    2. {{ 'fb_txt_active02' | translate }}
    <br />
    <span class="text-uppercase color-alert">{{ 'Important2' | translate }}</span>
    <br />
    {{ 'fb_txt_active0201' | translate }}
    <br />
    <span *ngIf="licenseType == licensePA">{{ transferConcept }}</span>
    <span *ngIf="licenseType != licensePA">{{ 'transferBank01' | translate }}</span>
  </p>
</ng-container>
<ng-container *ngIf="licenseType == licenseMDZ">
  <!-- Mendoza -->
  <h6 class="cs-trans--title color-dark">{{ 'depTransfMDZ' | translate }}</h6>
  <div class="cs-transfer--line"></div>
  <p class="cs-trans--text color-dark">1. {{ 'depTransf01MDZ' | translate }}</p>
  <p class="cs-trans--text color-dark">
    2. {{ 'fb_txt_active02' | translate }}
    <br />
    <span class="text-uppercase color-alert">{{ 'Important2' | translate }}</span>
    <br />
    {{ 'fb_txt_active0201MDZ' | translate }}
    <br />
    <span>{{ 'transferBank0101MDZ' | translate }} </span>
    <span [innerHTML]="'transferBank0102MDZ' | translate"></span>
  </p>
</ng-container>

<div class="cs-transfer--slice border-color-secondary">
  <p class="cs-trans--text color-dark">
    {{ 'fb_txt_active0203' | translate }}<span class="cs-trans--title color-secondary">{{ transferConfig?.iban }}</span>
  </p>
  <button
    class="cs-transfer--slice-button cs-trans--text color-secondary background-color-light border-color-secondary"
    tappable
    (click)="copyButton(transferConfig?.iban)"
  >
    Copiar
  </button>
</div>
<div *ngIf="licenseType == 33" class="cs-transfer--slice border-color-secondary">
  <p class="cs-trans--text color-dark">
    {{ 'alias' | translate | uppercase }}<span class="cs-trans--title color-secondary"
      >{{ transferConfig?.alias }}</span
    >
  </p>
  <button
    class="cs-transfer--slice-button cs-trans--text color-secondary background-color-light border-color-secondary"
    (tap)="copyButton(transferConfig?.alias)"
  >
    Copiar
  </button>
</div>
<div class="cs-transfer--slice border-color-secondary">
  <p class="cs-trans--text color-dark">
    {{ 'swift_bic' | translate }} <span class="cs-trans--title color-secondary">{{ transferConfig?.swift }}</span>
  </p>
  <button
    class="cs-transfer--slice-button cs-trans--text color-secondary background-color-light border-color-secondary"
    tappable
    (click)="copyButton(transferConfig?.swift)"
  >
    Copiar
  </button>
</div>
<div class="cs-transfer--slice border-color-secondary">
  <p class="cs-trans--text color-dark">
    {{ 'Beneficiario' | translate }} :
    <span class="cs-trans--title color-secondary">{{ transferConfig?.beneficiary }}</span>
  </p>
  <button
    class="cs-transfer--slice-button cs-trans--text color-secondary background-color-light border-color-secondary"
    tappable
    (click)="copyButton(transferConfig?.beneficiary)"
  >
    Copiar
  </button>
</div>
<div *ngIf="licenseType == licensePA" class="cs-transfer--slice border-color-secondary">
  <p class="cs-trans--text color-dark">
    Tipo de Cuenta: <span class="cs-trans--title color-secondary">{{ transferConfig?.tipoCuenta }}</span>
  </p>
  <button
    class="cs-transfer--slice-button cs-trans--text color-secondary background-color-light border-color-secondary"
    tappable
    (click)="copyButton(transferConfig?.tipoCuenta)"
  >
    Copiar
  </button>
</div>
<div [ngSwitch]="licenseType">
  <ng-container *ngSwitchCase="licensePA">
    <p class="cs-trans--text color-dark"><b>3. {{ 'ftCondiciones' | translate }}</b></p>
    <div style="text-align: left" [innerHTML]="'depTransf03PA' | translate"></div>
  </ng-container>
  <ng-container *ngSwitchCase="licenseMDZ">
    <!-- Mendoza -->
    <p class="cs-trans--text color-dark">3. {{ 'depTransf03MDZ' | translate }}</p>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <p class="cs-trans--text color-dark">3. {{ 'depTransf03' | translate }}</p>
  </ng-container>
</div>
<div *ngIf="licenseType == licenseCABA">
  <p class="cs-trans--text color-dark">4. {{ 'depTransf04' | translate }}</p>
</div>
