import { Injectable, inject } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { EventTypes } from '@models/TrackingEvents';
import { CdrInsightsEvent } from '@providers/tracking/cdr-core/cdr-insights/model/cdr-insights-event';
import { TrackingService } from '@providers/TrackingService';
import { EventsService } from '@providers/EventsService';

@Injectable({ providedIn: 'root' })
export class CdrInsightsService {
  logoutEvent$: Subject<CdrInsightsEvent> = new Subject<CdrInsightsEvent>();
  events = inject(EventsService);
  constructor() {
    this.events.subscribe('user:logout', () => {
      this.logoutEvent$.next({
        eventType: EventTypes.Logout
      });
    });
  }

  get event$(): Observable<CdrInsightsEvent> {
    return TrackingService.event$.asObservable();
  }
}
