export class C_BetsData {
  nameLeague: string;
  nameLeagueNodeId: string;
  earlyBetsParentId: string;
  specialBetsParentId: string;
  earlyBetsNodeId: string;
  specialBetsNodeId: string;
  groupsBetsParentId: string;
  groupsBetsNodeId: string;
  constructor() {}
}

export class C_BetsDataFilter {
  index: number;
  name: string;
  selected: boolean;
  parentId: string;
  nodeId: string;

  constructor(index: number, name: string, selected: boolean, parentId: string, nodeId: string) {
    this.index = index;
    this.name = name;
    this.selected = selected;
    this.parentId = parentId;
    this.nodeId = nodeId;
  }
}
