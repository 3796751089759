import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'extractHour',
  standalone: true
})
export class ExtractHourPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return '';
    }
    const miDate = new Date(parseInt(value.slice(6, value.length - 2)));
    return this.formatoNumber(miDate.getHours(), 2) + ':' + this.formatoNumber(miDate.getMinutes(), 2);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formatoNumber(numero: any, digitos: number): string {
    //devuelve el string del número con n dígitos
    let numberStr: string = numero.toString();
    while (numberStr.length < digitos) {
      numberStr = '0' + numberStr;
    }
    return numberStr;
  }
}
