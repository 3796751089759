<div class="containerP">
  <div class="texto page1">
    <div class="txtcenter">
      <span>{{'selfExclusionF1tx01' | translate}}</span>
    </div>
    <div class="margint20 txtcenter is-bold">{{'selfExclusionF1tx02' | translate}}</div>
    <div class="formuser margint20">
      <form [formGroup]="myForm">
        <ion-row>
          <ion-col [size]="isDesktop ? 6 : 12">
            <div>
              <ion-input
                type="text"
                [formControl]="$any(myForm).controls['name']"
                [attr.disabled]="true"
                [label]="'rNom' | translate"
                labelPlacement="floating"
              >
              </ion-input>
            </div>
          </ion-col>
          <ion-col [size]="isDesktop ? 6 : 12">
            <div>
              <ion-input
                type="text"
                [formControl]="$any(myForm).controls['surname']"
                [attr.disabled]="true"
                [label]="'pIApe' | translate"
                labelPlacement="floating"
              ></ion-input>
            </div>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col [size]="isDesktop ? 6 : 12">
            <div>
              <ion-input
                type="text"
                [formControl]="$any(myForm).controls['documentTypeCO']"
                [attr.disabled]="true"
                [label]="'rCoDocumentType' | translate"
                labelPlacement="floating"
              >
              </ion-input>
            </div>
          </ion-col>
          <ion-col [size]="isDesktop ? 6 : 12">
            <!--Número de documento-->
            <div>
              <ion-input
                type="text"
                [formControl]="$any(myForm).controls['NumberDocument']"
                [attr.disabled]="true"
                [label]="'rCoNumberDocument' | translate"
                labelPlacement="floating"
              ></ion-input>
            </div>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col [size]="isDesktop ? 6 : 12">
            <div>
              <ion-input
                type="text"
                [formControl]="$any(myForm).controls['address']"
                [attr.disabled]="true"
                [label]="'pIAddress' | translate"
                labelPlacement="floating"
              ></ion-input>
            </div>
          </ion-col>
          <ion-col [size]="isDesktop ? 6 : 12">
            <div *ngIf="globalVars.licenseType === 21">
              <ion-input
                type="text"
                [formControl]="$any(myForm).controls['Municipality']"
                [attr.disabled]="true"
                [label]="'GetCardcity' | translate"
                labelPlacement="floating"
              >
              </ion-input>
            </div>
            <div *ngIf="globalVars.licenseType === 22">
              <ion-input
                type="text"
                [formControl]="$any(myForm).controls['Province']"
                [attr.disabled]="true"
                [label]="'pIState' | translate"
                labelPlacement="floating"
              >
              </ion-input>
            </div>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col [size]="isDesktop ? 6 : 12">
            <div>
              <ion-input
                type="tel"
                [formControl]="$any(myForm).controls['phone']"
                [attr.disabled]="true"
                [label]="'pIMvl2' | translate"
                labelPlacement="floating"
              ></ion-input>
            </div>
          </ion-col>
          <ion-col [size]="isDesktop ? 6 : 12">
            <div>
              <ion-input
                type="email"
                [formControl]="$any(myForm).controls['email']"
                [attr.disabled]="true"
                [label]="'pIMail' | translate"
                labelPlacement="floating"
              ></ion-input>
            </div>
          </ion-col>
        </ion-row>
      </form>
    </div>
    <div class="formtoogle borderdotted">
      <div class="is-bold"><span>{{'selfExclusionF1tx03' | translate}}</span></div>
      <div class="margint20">
        {{'selfExclusionF1tx0401' | translate}} {{city}}, {{'selfExclusionF1tx0402' | translate}}
        {{datetime|date:'HH:mm:ss'}} {{'selfExclusionF1tx0403' | translate}}, {{'selfExclusionF1tx0404' | translate}}
        {{datetime|date:'dd/MM/yyyy'}}, {{'selfExclusionF1tx0405' | translate}}
      </div>
      <div>
        <form [formGroup]="myFormbtn" class="toggle formbtn">
          <ion-toggle
            class="button"
            [formControl]="$any(myFormbtn).controls['toggleGamesInternet']"
            labelPlacement="end"
            justify="start"
          >
            <span class="toggle-text">{{'selfExclusionF1tx05' | translate}}</span></ion-toggle
          >
        </form>
      </div>
    </div>
    <div class="btnContainer">
      <ion-button (click)="goToStep(2)" class="btnorange" [disabled]="myForm.invalid || !myFormbtn.valid" shape="round">
        {{'selfExclusionP1btn' | translate}} <ion-icon slot="end" name="chevron-forward-outline"></ion-icon>
      </ion-button>
      <ion-button
        (click)="backToHome()"
        class="btnwhite paddingStartEnd77"
        [class.padding2070]="isDesktop"
        shape="round"
      >
        {{'tCancel' | translate }}
      </ion-button>
    </div>
  </div>
</div>
