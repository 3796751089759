import { Component } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { BaseFilter } from '../base-filter/base-filter';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'min-bet-filter',
  imports: [IonicModule, CommonModule, TranslateModule],
  standalone: true,
  providers: [],
  templateUrl: './min-bet-filters.component.html',
  styleUrls: ['./min-bet-filters.component.scss'],
  host: {
    class: 'min-bet-filters'
  }
})
export class MinBetFiltersComponent extends BaseFilter {
  constructor() {
    super();
  }
}
