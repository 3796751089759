/* eslint-disable eqeqeq */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GlobalVars } from '../../../globalVars';
import { CdrInsightsBaseModule } from '../../cdr-core/cdr-insights/model/cdr-insights-base.module';
import { CdrInsightsService } from '../../cdr-core/cdr-insights/shared/cdr-insights.service';

import soliticsconfig from '@config/soliticsconfig';
import { SoliticsService } from '../../tracking/solitics/shared/solitics.service';
/**
 * @author a.e.m
 * @version 1.0.0
 * @description modulo del tracker solitics
 *
 */
@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: []
})
export class SoliticsModule extends CdrInsightsBaseModule {
  constructor(
    cdrInsightService: CdrInsightsService,
    private globalVars: GlobalVars,
    private soliticsService: SoliticsService
  ) {
    super(soliticsconfig[globalVars.licenseType].insights.solitics, cdrInsightService);
  }

  build() {
    this.event$.subscribe((p) => {
      if (p.key == 'Login') {
        this.soliticsService.login(p);
      } else if (p.key == 'LogOut') {
        this.soliticsService.logout();
      }

      if (this.globalVars.user.logged) {
        if (p.key == 'betCompleted') {
          this.soliticsService.betCompleted(p);
        } else if (p.key == 'AccessToGameSlots') {
          this.soliticsService.gameLaunched(p, 'slot');
        } else if (p.key == 'AccessToGameCasino') {
          this.soliticsService.gameLaunched(p, 'casino');
        }
      }
    });
  }
}
