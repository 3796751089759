<ng-container>
  <div
    class="contact-question"
    [class.co]="globalVars.LICENSE_TYPE === licenseTypes.Colombia"
    *ngIf="hasBeenUseful===null"
  >
    <p>{{'tContactUseful' | translate}}</p>
    <button class="btn-checkbox" (click)="setHasBeenUseful(true)">{{'tContactYes' | translate}}</button>
    <button class="btn-checkbox" (click)="setHasBeenUseful(false)">{{'tContactNo' | translate}}</button>
  </div>
</ng-container>
