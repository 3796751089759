import { CommonModule } from "@angular/common";
import { Component, OnInit, inject } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { IonicModule, ModalController } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { Subscription, of, switchMap } from "rxjs";

import { TrackingService } from "@providers/TrackingService";
// services
import { TicketService } from "@sports-services/index";

import { LicenseTypes } from "@models/MasterData";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
// models
import { C_FreeBetTicket } from "@sports-models/index";

import { ticketState } from "@sports-stores/ticket/ticket.reducers";

import { FormatOdd } from "@pipes/formatOdd.pipe";
// pipes
import { ParseMoney } from "@pipes/parseMoney";

// components
import { CodereStyleMoneyComponet } from "@components/sports/codere-style-money/codere-style-money";

import { NewBaseService } from "@providers/newBase.service";
import { NewTicketFacade } from "@sports-stores/ticket/index";
import { TicketMethods } from "@sports-utils/index";
// other
import { Utils } from "@utils/Utils";

@Component({
  selector: "ticket-freebets-page",
  templateUrl: "./ticket-freebets.html",
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TranslateModule,
    CodereStyleMoneyComponet,
    ParseMoney,
    FormatOdd,
  ],
  styleUrls: ["./ticket-freebets.scss"],
})
export class TicketFreebetsPage implements OnInit {
  lstFreebets: C_FreeBetTicket[];
  inputFB: string;
  ticketFreebetSubscription: Subscription;
  ticketFreebet$: Subscription;

  globalvars: GlobalVarsModel;
  LicenseTypes = LicenseTypes;
  trackingService = inject(TrackingService);
  ticketService = inject(TicketService);
  translate = inject(TranslateService);
  newTicketFacade = inject(NewTicketFacade);
  store = inject(Store<ticketState>);
  modalCtrl = inject(ModalController);
  utils = inject(Utils);
  newBaseService = inject(NewBaseService);

  constructor() {
    this.newBaseService.getVars
      .pipe(
        switchMap((data: GlobalVarsModel) => {
          this.globalvars = data;
          return of(null);
        }),
      )
      .subscribe();
    // this.newTicketFacade = new NewTicketFacade();
  }

  ngOnInit(): void {
    this.bindFreebetsActive();
  }

  sendInputFB() {
    if (this.inputFB) {
      this.freeBetsAdd(new C_FreeBetTicket(null, null, this.inputFB, 0, "", true, "", false), true);
    }
  }

  close() {
    this.ticketFreebetSubscription.unsubscribe();
    this.modalCtrl.dismiss();
  }

  bindFreebetsActive() {
    this.newTicketFacade.updateFreeBets();
    this.ticketFreebetSubscription = this.store.select("ticket").subscribe((ticketSta: ticketState) => {
      this.lstFreebets = ticketSta.freeBets;
      if (TicketMethods.getHasFreebet(ticketSta.ticket)) {
        this.trackingService.trackEvent(["freeBetsAddsTicket", "", "", "Ticket Freebet a\u00F1adido", "event"]);
        this.close();
      }
    });
  }

  freeBetsAdd(freebet: C_FreeBetTicket, personalized: boolean) {
    this.newTicketFacade.freeBetsAdd(freebet, personalized);
    this.close();
  }

  isAR() {
    return (
      LicenseTypes.ArgentinaCaba ||
      LicenseTypes.ArgentinaCordoba ||
      LicenseTypes.ArgentinaMendoza ||
      LicenseTypes.ArgentinaPBA
    );
  }
}
