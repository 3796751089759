import { CommonModule } from '@angular/common';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  CUSTOM_ELEMENTS_SCHEMA,
  inject
} from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { CasinoCardNewComponent } from '../../../../components/casino/casino-card-new/casino-card-new.component';
import { CasinoLiveCardNewComponent } from '../casino-live-card-new/casino-live-card-new';
import { DeviceService } from '../../../../services/device.service';
import { GlobalVarsModel } from '../../../../models/ModelGlobalsVar';
import { NewBaseService } from '../../../../providers/newBase.service';
import { Swiper, SwiperOptions } from 'swiper';
import { Game } from '@models/lobby/casinoDataModels';
import { CONSTANTS } from '../../../../constants/lobbyConstants';

interface Size {
  width: string;
  height: string;
}

@Component({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  selector: 'casino-category-slider-new',
  templateUrl: './casino-category-slider-new.component.html',
  imports: [CommonModule, IonicModule, CasinoCardNewComponent, CasinoLiveCardNewComponent],
  styleUrls: ['./casino-category-slider-new.component.scss'],
  standalone: true
})
export class CasinoCategorySliderNewComponent implements OnInit {
  globalVars!: GlobalVarsModel;
  lobbyConstants = CONSTANTS;
  backgroundImg = '';

  @Input() id: any;

  /**
   * categoria con el conjunto de los juegos
   */
  @Input() category: any;

  /**
   * controles del swipe
   */
  @Input() controls = true;

  /**
   * actalización de Jackpot BR
   */
  @Input() updatedBrJackpot: any = null;

  /**
   * evento al pinchar en jugar
   */
  @Output() turnPlay: EventEmitter<any> = new EventEmitter();
  /**
   * el swiper del slider y su contenedor
   */

  @ViewChild('swiper') swiperRef:
    | ElementRef<HTMLElement & { swiper?: Swiper } & { initialize: () => void }>
    | undefined;

  swiper?: Swiper;
  totalStyles = 5;
  /**
   * juegos a visualizar
   */
  public games = [];

  public type;
  /**
   * los grandientes de izquierda y direcha, solo visibles en mobile donde no hay controles
   */
  protected isGradientLeftAvailable = false;
  protected isGradientRightAvailable = false;
  /**
   * indice de la vista a renderizar
   */
  private viewIndex = 0;
  /**
   * Flag si es version mobile o no
   */
  isMobile = false;
  /*
  + if there are live games
  */
  protected liveGames = false;

  /*
   * Control list of live games
   */
  protected liveGamesList: string[] = [];

  protected signalRSubscription: Subscription;

  /**
   *
   * @param globalVars : globarl variables de entorno
   * @param cd :change detection angular
   */

  cd = inject(ChangeDetectorRef);
  newBaseService = inject(NewBaseService);
  devicesService = inject(DeviceService);

  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });

    this.isMobile = this.devicesService.isMobile();
    if (this.isMobile) {
      this.isGradientRightAvailable = true;
    }
  }

  ngOnInit(): void {
    this.games = this.category.Games;
    this.type = this.category.typeCategory;
    this.setBg();
  }

  ngAfterViewInit(): void {
    //inicializar el swiper
    const config: SwiperOptions = {
      slidesPerView: 'auto',
      freeMode: true,
      allowTouchMove: !this.controls,
      spaceBetween: this.controls ? 18 : 0,
      navigation: {
        nextEl: `.swiper-button-next-${this.id}`,
        prevEl: `.swiper-button-prev-${this.id}`
      },
      observer: true
    };
    const swiperEl = Object.assign(this.swiperRef?.nativeElement, config);
    swiperEl.initialize();
    this.swiper = this.swiperRef?.nativeElement.swiper;
    this.swiper.on('slideChange', () => {
      this.viewIndex = this.swiper.activeIndex;
      this.cd.detectChanges();
    });
  }

  /**
   * indice de renderizaje usado por la vista
   */
  get vi() {
    return this.viewIndex + 8;
  }

  /**
   * controles de dislizar izquierda o derecha
   * @param option : prev |next
   */
  changeSlide(option: 'prev' | 'next') {
    switch (option) {
      case 'prev':
        if (this.swiper.isBeginning) return;
        this.swiper.slideTo(this.swiper.activeIndex - 1);
        break;
      case 'next':
        if (this.swiper.isEnd) return;
        this.swiper.slideTo(this.swiper.activeIndex + 1);
        break;
    }
  }

  /**
   * Returns a class. This class depending of gameType
   * @param gameType game with type of size: Smallsquare, Bigsquare, Bigrectangle
   * @returns {string} An string with the class
   */
  getCustomClass(game: Game): string {
    if (game.gameTileSize === 'Bigrectangle') {
      return 'bigrectangle';
    } else {
      return 'swiper-slide-custom';
    }
  }

  /**
   * al hacer click en play
   * @param $event
   */
  btPlayDetail($event) {
    this.turnPlay.emit($event);
  }

  setBg() {
    if (this.category.BackgroundImageUrl !== '') {
      this.backgroundImg = 'url(' + this.category.BackgroundImageUrl + ')';
    }
  }
}
