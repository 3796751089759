<ion-content>
  <div>
    <!-- Datos de usuario -->
    <cdr-fs-usuario [formGrp]="formUsr"></cdr-fs-usuario>
    <!-- Datos de confirmación -->
    <cdr-fs-confirmar [formGrp]="formConf"></cdr-fs-confirmar>
    <!-- Boton de envío -->
    <ion-button mode="md" class="btn-register" type="submit" id="endRegister" expand="block" (click)="onClick()">
      {{buttons.endRegister}}
    </ion-button>
  </div>
</ion-content>
