<ion-header>
  <div>
    <button class="btn-corner-close" (click)="cancel()">
      <ion-icon name="close-outline"></ion-icon>
    </button>
    <div class="message-icon">
      <img src="assets/global/img/icon-circle-info.svg" />
    </div>
    <h4 class="message-title">{{ title }}</h4>
  </div>
</ion-header>
<section>
  <div class="message-container">
    <div class="message-text">Necesitamos verificar tu cuenta para realizar esta operación.</div>
    <div class="message-text">Puedes verificar ahora con tu documento de identidad, DNI o NIE y un selfie.</div>
  </div>
</section>
<footer class="footer-content">
  <div>
    <button class="btn-cancel" (click)="cancel()">Cancelar</button>
  </div>
  <div>
    <button class="btn-verify" (click)="verifyNow()">Verificar ahora</button>
  </div>
</footer>
