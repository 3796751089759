import { IonContent, IonicModule } from '@ionic/angular';
import { ViewChild, Component, inject, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormGroup, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { TranslateService, TranslateModule } from '@ngx-translate/core';

import { DeviceService } from '../../../services/device.service';
import { ParseMoney } from '../../../pipes/parseMoney';
import { CodereNavbar } from '../../../components/common/codere-navbar/codere-navbar';
import { TipoFreeBet } from '../../../components/mso/tipo-free-bet/tipo-free-bet';
import { Utils } from '@utils/Utils';
import { UserService } from '@providers/UserService';
import { TrackingService } from '@providers/TrackingService';
import { c_FreeBet } from '@models/C_FreeBet';
import { GlobalVarsModel } from '@models/ModelGlobalsVar';
import { NewBaseService } from '@providers/newBase.service';
import { CodereFooterComponent } from '@shared-mso-components/footer/codere-footer/codere-footer';
import { UserSectionTitle } from '@shared-mso-components/user-section-title/user-section-title';
import { EmptyState } from '@shared-payments-components/empty-state/empty-state';
import { FormatOdd } from '@pipes/formatOdd.pipe';
import { CodereStyleMoneyComponet } from '@shared-sports-components/codere-style-money/codere-style-money';
import { EventsService } from '@providers/EventsService';
import { MSO_PATHS } from '../../../lib/constants/routes';

@Component({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  selector: 'free-bets-page',
  templateUrl: 'free-bets.html',
  standalone: true,
  imports: [
    IonicModule,
    CommonModule,
    UserSectionTitle,
    TranslateModule,
    CodereStyleMoneyComponet,
    FormatOdd,
    EmptyState,
    CodereFooterComponent,
    CodereNavbar,
    ParseMoney,
    TipoFreeBet,
    ReactiveFormsModule,
    FormsModule
  ],
  styleUrls: ['./free-bets.scss']
})
export class FreeBetsPage {
  @ViewChild(IonContent) content: IonContent;
  imageSource: string;
  fbType = 'online';
  codereCardActive = true;

  activeFBOnline: Array<any>;
  activeFBLocal: Array<any>;

  aPendingFBOnline: Array<any>;
  aPendingFBLocal: Array<any>;

  indexSeeMorePendLocal: number;
  indexSeeMorePendOnline: number;

  phoneNumber: string;

  firstLoad = true;
  forbiddenFreebets = false;

  form: FormGroup;
  modelForm: any = { smsCode: '' };
  errorSmsCode = '';

  globalVars: GlobalVarsModel;

  trackingService = inject(TrackingService);
  userService = inject(UserService);
  translate = inject(TranslateService);
  utils = inject(Utils);
  router = inject(Router);
  fb = inject(FormBuilder);
  deviceService = inject(DeviceService);
  newBaseService = inject(NewBaseService);
  eventService = inject(EventsService);

  isMobile = false;
  isDesktop = false;

  constructor() {
    this.isDesktop = this.deviceService.isDesktop();
    this.isMobile = this.deviceService.isMobile();

    this.activeFBOnline = [];
    this.activeFBLocal = [];

    this.aPendingFBOnline = [];
    this.aPendingFBLocal = [];
    this.imageSource = 'assets/global/img/freebet-ticket.png';

    this.indexSeeMorePendLocal = 0;
    this.indexSeeMorePendOnline = 0;
    this.newBaseService.getVars.subscribe((data) => {
      this.globalVars = data;
      this.codereCardActive = this.globalVars.user.codereCard.active;
    });

    this.form = this.fb.group({
      smsCode: ['']
    });

    this.updateData();
  }

  ionViewWillEnter() {
    this.trackingService.trackEvent(['AccesToFreeBets', '', '', 'Acceder FreeBets', 'event']);
    this.eventService.publish('footer:refresh', this.content);
  }

  ionViewDidLoad() {
    this.eventService.publish('footer:refresh', this.content);
  }

  hiddenFreebets() {
    if (this.activeFBOnline.length != 0 || this.aPendingFBOnline.length != 0 || this.firstLoad) {
      return 'hidden-freebets';
    } else {
      return '';
    }
  }

  toDate(str: string): Date {
    return new Date(parseInt(str.slice(6, str.length - 2)));
  }

  formatFb = (value: c_FreeBet): any => {
    //parse  the value properties
    let totalBets = value.DepositAmount * value.ConversionRate;
    totalBets =
      totalBets > value.MaxBets * value.MaxBonusMultiplier ? value.MaxBets * value.MaxBonusMultiplier : totalBets;
    const dateGW = this.toDate(value.LastGrossWinUpdate) > this.toDate(value.StartDate) ? value.LastGrossWinUpdate : 0;
    const now: Date = new Date();
    const strActive: string =
      this.toDate(value.DateLimit) <= now && now >= this.toDate(value.StartDate) ? 'Activo' : 'Inactivo';

    const pendingFb = {
      Name: value.PromoName,
      Type: value.PromoType,
      GrossWin: value.GrossWin > 0 ? value.GrossWin : 0,
      AmountFb: value.FreebetsAmount,
      TotalBets: totalBets,
      minOdd: value.MinOdds,
      AmountBets: value.ValidBets,
      pendingAmount: totalBets - value.ValidBets,
      date: new Date(value.DateLimit),
      dateStart: value.StartDate,
      dateGW: dateGW,
      active: strActive,
      PercentDev: value.VoucherMultiplier * 100,
      Url: value.ConditionsUrl != null ? value.ConditionsUrl : '#'
    };

    return pendingFb;
  };

  updateData() {
    this.utils.loader();
    this.userService.loadFreeBets().subscribe(
      async (data) => {
        if (data.success == false) {
          if (data.pendingSms) {
            this.userService.getUserData().subscribe(
              (userData) => {
                this.eventService.publish('loader:dismiss', null);
                if (!userData.sessionOut) {
                  this.phoneNumber = userData.NumMovil;
                } else {
                  //disconnect the user
                  this.eventService.publish('user:sessionTimeout');
                }
              },
              (err) => {
                this.eventService.publish('loader:dismiss', null);
                this.utils.showError(this.translate.instant('m_menu_serverFail'));
              }
            );
          } else {
            this.eventService.publish('loader:dismiss', null);
          }
          this.forbiddenFreebets = true;
        } else {
          this.eventService.publish('loader:dismiss', null);
          this.forbiddenFreebets = false;
          this.firstLoad = false;
          const preFb: Array<c_FreeBet> = data.availableDepositFbs;
          const activeFb: Array<any> = data.data;

          if (activeFb != null) {
            this.activeFBOnline = activeFb.filter((value) => !value.IsTerminalVoucher);
            this.activeFBLocal = activeFb.filter((value) => value.IsTerminalVoucher);
          }
          if (preFb != null) {
            this.aPendingFBOnline = preFb
              .filter((value) => {
                if (value.PromoType.indexOf('online') != -1) {
                  return true;
                } else {
                  return false;
                }
              })
              .map(this.formatFb);

            this.aPendingFBLocal = preFb
              .filter((value) => {
                if (value.PromoType.indexOf('online') == -1) {
                  return true;
                } else {
                  return false;
                }
              })
              .map(this.formatFb);
          }
        }
        this.eventService.publish('footer:refresh', this.content);
        await this.utils.closeLoader();
      },
      async (err) => {
        this.utils.showError(this.translate.instant('m_menu_serverFail'));
        await this.utils.closeLoader();
      }
    );
  }

  showMoreFreeBet(type: string) {
    switch (type) {
      case 'presencialesFb':
        this.indexSeeMorePendLocal = this.indexSeeMorePendLocal + 1;
        break;
      case 'onlineFb':
        this.indexSeeMorePendOnline = this.indexSeeMorePendOnline + 1;
        break;
    }
  }
  gotoNearLocal() {
    this.router.navigate(['NearestLocalPage', {}], {
      queryParams: { animate: false }
    });
  }

  checkCanSendCodereCard() {
    this.userService.checkCanSendCodere().subscribe(
      (data) => {
        if (!data.success) {
          this.userService.hasDeposit().subscribe(
            (depositData) => {
              if (depositData.success) {
                this.router.navigate(['CardRequestFormPage', {}], {
                  queryParams: { animate: false }
                });
              } else {
                this.eventService.publish('user:serverfail');
              }
            },
            (err) => {
              this.eventService.publish('user:serverfail:soft');
            }
          );
        } else {
          this.utils.confirm(
            false,
            this.translate.instant('tFail'),
            this.translate.instant('coderecardverifdocum'),
            this.translate.instant('tCancel'),
            () => {
              //
            },
            this.translate.instant('check'),
            () => {
              setTimeout(() => {
                this.router.navigate(['DocumentVerificationPage', {}]);
              }, 800);
            }
          );
        }
      },
      (err) => {
        this.utils.showError(this.translate.instant('GetCardOnlylink_send'), null);
      }
    );
  }

  accessToCheckCodereCard() {
    this.userService.checkCanSendCodere().subscribe(
      (data) => {
        if (!data.success) {
          this.router.navigate([`/${MSO_PATHS.BASE}/${MSO_PATHS.CardLinkPage}`]);
        } else {
          this.utils.confirm(
            false,
            this.translate.instant('tFail'),
            this.translate.instant('coderecardverifdocum'),
            this.translate.instant('tCancel'),
            () => {
              //
            },
            this.translate.instant('check'),
            () => {
              setTimeout(() => {
                this.router.navigate(['DocumentVerificationPage', {}]);
              }, 800);
            }
          );
        }
      },
      () => {
        this.utils.showError(this.translate.instant('GetCardOnlylink'), null);
      }
    );
  }

  checkFBCode() {
    const codFB = this.modelForm.smsCode;
    if (codFB == '') {
      this.utils.showError('Campo incorrecto');
    } else {
      this.userService.checkPhoneFreeBets(codFB).subscribe(
        (data) => {
          if (data.success) {
            this.errorSmsCode = '';
            this.utils.alert(false, '', this.translate.instant('correctcode'), 'OK');
            this.updateData();
          } else {
            this.utils.showError(this.translate.instant('incorrectcode'));
            this.updateData();
          }
        },
        (err) => {
          this.utils.showError(this.translate.instant('m_menu_serverFail'));
        }
      );
    }
  }

  reSendFBCode() {
    this.userService.resendFBCode().subscribe(
      (data) => {
        if (data.success) {
          this.utils.alert(false, '', this.translate.instant('regsmsrecib'), 'OK');
        } else {
          this.utils.alert(false, '', this.translate.instant('regsmsmore'), 'OK');
        }
      },
      (err) => {
        this.utils.showError(this.translate.instant('m_menu_serverFail'));
      }
    );
  }

  goToVer() {
    this.router.navigate(['DocumentVerificationPage', {}]);
  }
}
