/* eslint-disable */
import { Injectable } from '@angular/core';
import { Observable, catchError, finalize, map, throwError } from 'rxjs';
import { BaseService } from '@providers/BaseService';
import { HttpParams } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class MapsService extends BaseService {
  errorList: Array<{ code: string; label: string }>;

  constructor() {
    super();
  }

  getPois(): Observable<any> {
    const url: string = 'https://m.apuestas.codere.es/cacheservices/home/GetAllShops';

    // Parámetros opcionales (si los necesitas)
    const params = new HttpParams().set('callback', 'JSONP_CALLBACK');

    this.events.publish('loading:start');

    return this.httpClient.jsonp(url, 'callback').pipe(
      map((res: any) => {
        // Convierte los datos XML en JSON utilizando xml2js
        const data = this.utils.xml2json(res);
        return data;
      }),
      catchError((error) => {
        console.error('error maps:', error);
        return throwError(error);
      }),
      finalize(() => {
        this.events.publish('loading:finish');
      })
    );
  }
}
