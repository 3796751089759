import { C_Event } from './C_Event';
// models/C_Evento.ts
export class C_EventosRM {
  Eventos: Array<C_Event>;
  LeagueName: string;

  constructor(evento: Array<C_Event>, leagueName: string) {
    this.Eventos = evento;
    this.LeagueName = leagueName;
  }
}
