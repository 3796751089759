<ng-container *ngIf="!isMobile; else mobileView">
  <ion-content class="page-content background-color-muted">
    <user-section-title [sectionTitle]="'uPCob'"></user-section-title>
    <ion-row class="select-filters-wrapper">
      <ion-col>
        <ion-list>
          <ion-item *ngIf="globalVars.browser !== 'internet'">
            <ion-label>{{ 'hStade' | translate | uppercase }} </ion-label>
            <ion-select
              justify="start"
              label="Estado: "
              name="state"
              [(ngModel)]="form.state"
              cancelText="{{ 'tCancel' | translate }}"
              (ionChange)="update()"
              placeholder="{{ 'complet' | translate }}"
            >
              <ion-select-option value="all" checked="true">{{ 'histaptodas' | translate }} </ion-select-option>
              <ion-select-option value="Completada">{{ 'complet' | translate }} </ion-select-option>
              <ion-select-option value="Aprobada">{{ 'approved' | translate }} </ion-select-option>
              <ion-select-option value="Pendiente">{{ 'pending' | translate }} </ion-select-option>
              <ion-select-option value="Cancelada">{{ 'cancel' | translate }} </ion-select-option>
            </ion-select>
            <ion-icon ios="ios-arrow-dropdown" md="md-arrow-dropdown"></ion-icon>
          </ion-item>
          <div class="select-explorer-container" *ngIf="globalVars.browser === 'internet'">
            <label class="label-explorer">{{ 'hStade' | translate | uppercase }}</label>
            <select
              aria-label="Estado"
              name="state"
              title="state"
              class="subheader-select-explorer"
              [(ngModel)]="form.state"
              (change)="update()"
            >
              <option value="all" checked="true">{{ 'histaptodas' | translate }}</option>
              <option value="Completada">{{ 'complet' | translate }}</option>
              <option value="Aprobada">{{ 'approved' | translate }}</option>
              <option value="Pendiente">{{ 'pending' | translate }}</option>
              <option value="Cancelada">{{ 'cancel' | translate }}</option>
            </select>
          </div>
        </ion-list>
      </ion-col>
      <ion-col>
        <ion-list>
          <ion-item *ngIf="globalVars.browser !== 'internet'">
            <ion-label>{{ 'histapfecha' | translate | uppercase }} </ion-label>
            <ion-select
              justify="start"
              label="Fecha: "
              name="days"
              [(ngModel)]="form.days"
              cancelText="{{ 'tCancel' | translate }}"
              (ionChange)="update()"
              placeholder="{{ 'histapu15d' | translate }}"
            >
              <ion-select-option value="90" checked="true">{{ 'histapu90d' | translate }} </ion-select-option>
              <ion-select-option value="15">{{ 'histapu15d' | translate }} </ion-select-option>
              <ion-select-option value="7">{{ 'histapu7d' | translate }} </ion-select-option>
              <ion-select-option value="2">{{ 'histapu2d' | translate }} </ion-select-option>
            </ion-select>
          </ion-item>
          <div class="select-explorer-container" *ngIf="globalVars.browser === 'internet'">
            <label class="label-explorer">{{ 'hStade' | translate | uppercase }}</label>
            <select
              name="days"
              title="days"
              class="subheader-select-explorer"
              [(ngModel)]="form.days"
              (change)="update()"
            >
              <option value="90" checked="true">{{ 'histapu90d' | translate }}</option>
              <option value="15">{{ 'histapu15d' | translate }}</option>
              <option value="7">{{ 'histapu7d' | translate }}</option>
              <option value="2">{{ 'histapu2d' | translate }}</option>
            </select>
          </div>
        </ion-list>
      </ion-col>
    </ion-row>
    <div class="is-narrow-content">
      <empty-state
        [message]="'m_menu_noCobHistory' | translate "
        [imageSource]="imageSource"
        *ngIf="arrCharges?.length === 0"
      ></empty-state>
      <ion-grid class="history-activity is-row-grid">
        <span
          class="history-activity-item"
          *ngFor="let itemDatePayment of arrCharges"
          [hidden]="!arrCharges || arrCharges?.length === 0"
        >
          <history-ticket [historyTicket]="itemDatePayment"></history-ticket>
        </span>
      </ion-grid>
    </div>
    <codere-footer></codere-footer>
  </ion-content>
</ng-container>

<ng-template #mobileView>
  <ion-header>
    <!-- <codere-navbar titulo="{{ 'uPCob' | translate }}"></codere-navbar> -->
    <user-section-title [sectionTitle]="'uPCob'"></user-section-title>
    <ion-row class="subHeaderSelects">
      <ion-col size="6">
        <ion-list>
          <ion-item>
            <ion-label>{{ 'hStade' | translate | uppercase }} </ion-label>
            <ion-select
              cancelText="{{ 'tCancel' | translate }}"
              [(ngModel)]="form.state"
              (ionChange)="update()"
              placeholder="{{ 'complet' | translate }}"
            >
              <ion-select-option value="all">{{ 'histaptodas' | translate }}</ion-select-option>
              <ion-select-option value="Completada" checked="true">{{ 'complet' | translate }}</ion-select-option>
              <ion-select-option value="Aprobada">{{ 'approved' | translate }}</ion-select-option>
              <ion-select-option value="Pendiente">{{ 'pending' | translate }}</ion-select-option>
              <ion-select-option value="Cancelada">{{ 'cancel' | translate }}</ion-select-option>
            </ion-select>
            <ion-icon ios="ios-arrow-dropdown" md="md-arrow-dropdown"></ion-icon>
          </ion-item>
        </ion-list>
      </ion-col>
      <ion-col size="6">
        <ion-list>
          <ion-item>
            <ion-label>{{ 'histapfecha' | translate | uppercase }} </ion-label>
            <ion-select
              cancelText="{{ 'tCancel' | translate }}"
              [(ngModel)]="form.days"
              (ionChange)="update()"
              placeholder="{{ 'histapu15d' | translate }}"
            >
              <ion-select-option value="90" checked="true">{{ 'histapu90d' | translate }} </ion-select-option>
              <ion-select-option value="15">{{ 'histapu15d' | translate }} </ion-select-option>
              <ion-select-option value="7">{{ 'histapu7d' | translate }} </ion-select-option>
              <ion-select-option value="2">{{ 'histapu2d' | translate }} </ion-select-option>
            </ion-select>
          </ion-item>
        </ion-list>
      </ion-col>
    </ion-row>
  </ion-header>

  <ion-content class="background-color-muted">
    <div class="padding">
      <!-- <div>Empty State</div> -->
      <empty-state
        [class.is-mobile]="isMobile"
        [message]="'m_menu_noCobHistory'"
        [imageSource]="imageSource"
        *ngIf="arrCharges?.length === 0"
      ></empty-state>
      <span
        class="history-activity-item"
        *ngFor="let itemDatePayment of arrCharges"
        [hidden]="!arrCharges || arrCharges?.length === 0"
      >
        <history-ticket [historyTicket]="itemDatePayment"></history-ticket>
      </span>
    </div>
    <codere-footer></codere-footer>
  </ion-content>
</ng-template>
