/* eslint-disable eqeqeq */
import { Component, OnInit, inject } from '@angular/core';
import { IonicModule, ModalController, NavParams } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { TranslateModule } from '@ngx-translate/core';
import { GlobalVarsModel } from '@models/ModelGlobalsVar';
import { FormsModule } from '@angular/forms';
import { Promotion } from '@models/I_Promotion';
import { NewBaseService } from '@providers/newBase.service';
import { DeviceService } from '@services/device.service';

@Component({
  standalone: true,
  templateUrl: './alert-cta-promo.html',
  selector: 'alert-cta-promo-page',
  styleUrls: ['./alert-cta-promo.scss'],
  imports: [IonicModule, CommonModule, FormsModule, TranslateModule],
  animations: [
    trigger('expandCollapse', [
      state(
        'expanded',
        style({
          height: '250px'
        })
      ),
      state(
        'collapsed',
        style({
          height: 0
        })
      ),
      transition('expanded <=> collapsed', animate('300ms ease-in-out'))
    ])
  ]
})
export class AlertCtaPromoComponent implements OnInit {
  globalVars!: GlobalVarsModel;

  modalController = inject(ModalController);
  navParams = inject(NavParams);
  baseService = inject(NewBaseService);
  deviceService = inject(DeviceService);
  accepted: boolean = false;
  promoData: Promotion;
  tycLink: string = '';
  isOpen: boolean = false;

  constructor() {}

  ngOnInit(): void {
    this.promoData = this.navParams.get('promoData');
    this.baseService.getVars.subscribe((data) => {
      this.globalVars = data;
    });
    this.tycLink = this.globalVars.URLBASE + 'condicionesgenerales/terminosycondicionespricepromotions.pdf';
  }

  acceptBtn() {
    if (this.accepted) {
      this.closeModal({}, 'Accepted');
    }
  }

  closeModal(data: any, role: string) {
    this.modalController.dismiss(data, role);
  }

  changeRadio() {
    this.accepted = !this.accepted;
  }

  toggleOpenTyC(event: MouseEvent) {
    event.preventDefault();
    this.isOpen = !this.isOpen;
  }
}
