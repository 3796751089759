/* eslint-disable */
export enum MigrationActions {
  None,
  AcceptTandC,
  VerifyDocumentation,
  AddDocIdAndType,
  UpdateUI
}

export class MadridMigrationResult {
  success: boolean;
  action: MigrationActions;
}
