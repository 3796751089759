import { CommonModule } from "@angular/common";
import {
  AfterViewInit,
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren,
  inject,
} from "@angular/core";
import { AlertController, NavController } from "@ionic/angular";
import { Swiper, SwiperOptions } from "swiper";

// Models
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { I_BetSenseModel, I_MarqueeItem } from "@sports-models/index";

// Services
import { TrackingService } from "@providers/TrackingService";
import { DeviceService } from "@services/device.service";

// Components
import { SbButtonComponent } from "@components/sports/sb-button/sb-button";

// Stores
import { NewTicketFacade } from "@sports-stores/ticket/index";

// Pipes
import { SportsExtractDatePipe } from "@sports-pipes/sports.extract-date.pipe";

@Component({
  selector: "sb-marquee-betsense",
  templateUrl: "./sb-marquee-betsense.html",
  standalone: true,
  imports: [CommonModule, SbButtonComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  styleUrls: ["./sb-marquee-betsense.scss"],
})
export class SbMarqueeBetSenseComponent implements OnChanges, AfterViewInit {
  @ViewChildren("list") list: QueryList<any>;
  @Input() betSenseData: any[];
  @Input() switch: boolean;
  @Output() resizeEl: EventEmitter<any> = new EventEmitter();
  @Output() bet: EventEmitter<any> = new EventEmitter();
  betsenseMarquees: any[] = [];

  @ViewChild("betSenseSwiper") swiperRef:
    | ElementRef<HTMLElement & { swiper?: Swiper } & { initialize: () => void }>
    | undefined;
  public swiper?: Swiper;
  config: SwiperOptions = {
    loop: false,
    slidesPerView: "auto",
    navigation: {
      nextEl: ".swiper-button-next-betsense",
      prevEl: ".swiper-button-prev-betsense",
    },
    observer: true,
  };
  globalVars!: GlobalVarsModel;

  _element = inject(ElementRef);
  _renderer = inject(Renderer2);
  extractDate = inject(SportsExtractDatePipe);
  nav = inject(NavController);
  alertCtrl = inject(AlertController);

  isMobile: boolean;
  isDesktop: boolean;

  trackingService = inject(TrackingService);
  deviceService = inject(DeviceService);

  newTicketFacade = inject(NewTicketFacade);

  constructor() {
    this.isDesktop = this.deviceService.isDesktop();
    this.isMobile = this.deviceService.isMobile();
  }

  ngOnChanges() {
    if (this.betSenseData.length > 0) {
      this.betsenseMarquees = this.betSenseData;
    }
    if (!this.swiper) return;
    this.swiper.update();
  }

  ngAfterViewInit() {
    if (this.swiperRef) {
      const swiperEl = Object.assign(this.swiperRef?.nativeElement, this.config);
      swiperEl.initialize();
      this.swiper = this.swiperRef?.nativeElement.swiper;
    }
  }

  updateMarquee(previousMarqueeData: I_MarqueeItem[], nextMarqueeData: I_MarqueeItem[]): I_MarqueeItem[] {
    if (previousMarqueeData.length === 0) return nextMarqueeData;

    return nextMarqueeData.map((marqueeItem) => {
      if (!marqueeItem.Game) return marqueeItem;

      const previousMarqueeItem = previousMarqueeData.find(
        (prevItem) => prevItem.Game?.NodeId === marqueeItem.Game.NodeId,
      );

      if (!previousMarqueeItem) return marqueeItem;

      const updatedResults = marqueeItem.Game.Results.map((result) => {
        const previousResult = previousMarqueeItem.Game.Results.find(
          (prevResult) => prevResult.NodeId === result.NodeId,
        );

        return {
          ...result,
          upOdd: previousResult ? previousResult.Odd < result.Odd : false,
          downOdd: previousResult ? previousResult.Odd > result.Odd : false,
        };
      });

      return {
        ...marqueeItem,
        Game: {
          ...marqueeItem.Game,
          Results: updatedResults,
        },
      };
    });
  }

  setBetsenseMarqueeItems(betSenseData) {
    const betsenseItems: I_BetSenseModel[] = [];
    betSenseData.forEach((betSenseDataItem) => {
      const NodeId = betSenseDataItem.outcomes.find(
        (item) => item.label === betSenseDataItem.delivery.es.log.cta_target,
      ).id;
      const odd = betSenseDataItem.data.offer.outcomes.find((i) => i.id === NodeId).oddsDecimal;
      betsenseItems.push({
        title: betSenseDataItem.delivery?.es?.log?.body,
        Name: betSenseDataItem.delivery?.es?.log?.cta,
        Odd: odd,
        marketName: betSenseDataItem.data?.offer?.name,
        NodeId,
      });
    });
    return betsenseItems.filter((item, index, self) => index === self.findIndex((t) => t.NodeId === item.NodeId));
  }

  onClick(NodeId) {
    this.bet.emit(NodeId);
  }
}
