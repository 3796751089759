export interface StakeLimits {
  SystemStakeLimits: SystemStakeLimits[];
  SingleStakeLimits: SingleStakeLimits[];
}

export interface StakeLimit {
  MinimumStake: number;
  MaximumStake: number;
  MinimumEachWayStake: number;
  MaximumEachWayStake: number;
  Increment: number;
  EachWayIncrement: number;
  MaximumEachWayStakePerBetWay: number;
  MaximumStakePerBetWay: number;
  MinimumEachWayStakePerBetWay: number;
  MinimumStakePerBetWay: number;
}
export interface SystemStakeLimits extends StakeLimit {
  SystemId: number;
}

export interface SingleStakeLimits extends StakeLimit {
  ItemId: number;
}
