import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

// Services
import { BaseServiceExt } from '@providers/BaseServiceExt';
import { DeviceService } from '@services/device.service';
import { SportService } from '@providers/SportService';
import { SportCommonService } from './sports.common.service';

@Injectable({ providedIn: 'root' })
export class SportNavigationService extends BaseServiceExt {
  deviceService = inject(DeviceService);
  sportService = inject(SportService);
  sportCommonService = inject(SportCommonService);
  router = inject(Router);

  isDesktop: boolean;
  isMobile: boolean;

  constructor() {
    super();
    this.isDesktop = this.deviceService.isDesktop();
    this.isMobile = this.deviceService.isMobile();
  }

  tryToGoToEvent(selection) {
    this.sportService.getGameByNodeId(selection.ResultsNr).subscribe((data) => {
      if (!data) {
        this.sportCommonService.showDismiss('', 'Evento no disponible.');
        return;
      }
      this.sportService.deepLinkToEvent(data);
    });
  }

  navigateToRoute(route: string, params?) {
    this.router.navigate([route], { state: { params } });
  }

  placeBetFromHome(betIds: string[]) {
    console.log({ betIds });
  }
}
