/* eslint-disable */
import { EpgCardDepositActionResult } from "@models/C_Transactions";
import { ErrorService } from "@providers/ErrorService";
import { UserService } from "@providers/UserService";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { PaymentMethodBehaviour, PaymentMethodBehaviourResult, type SkipAmountResult } from "./PaymentMethodBase";

export class PayValidaCashMethodBehaviourResult extends PaymentMethodBehaviourResult {
  transactionCode: string;
  reference: string;
  amount: number;
  date: string;
}
export class EfectyMethodtBehaviourResul extends PaymentMethodBehaviourResult {
  transactionCode: string;
  reference: string;
  date: string;
  amount: number;
  MFA_CODE: string;
  netAmount: number;
  errCode: number;
  transactionId: string;
  isFirstDeposit: boolean;
  status: string;
  taxAmount: number;
  errMessage: string;
  errorParameters: string;
  declare success: boolean;
}

export abstract class PayValidaCashPaymentMethodBehaviour extends PaymentMethodBehaviour {
  isBank = false;
  minDepositAmount = 30000;
  maxDepositAmount = 99999999;
  amountButtons: number[] = [30000, 100000, 500000, 1000000];
  amount = 30000;
  type: string;
  convenioNumber: string;

  constructor(
    userService: UserService,
    type: string,
    acceptColombiaConditions = false,
    errorService: ErrorService,
  ) {
    super(acceptColombiaConditions, userService, errorService);
    this.type = type;
  }

  skipAmountInput(otherParams: any): Observable<SkipAmountResult> {
    return new Observable<SkipAmountResult>();
  }

  paymentMetodExecution(): Observable<PayValidaCashMethodBehaviourResult> {
    return this.userService
      .validateDepositPayValidaCash(this.amount, this.type, this.acceptColombiaConditions)
      .pipe(
        map((obj) => {
          return this.map(obj);
        }),
      );
  }

  private map(input: EpgCardDepositActionResult): PayValidaCashMethodBehaviourResult {
    const result = new PayValidaCashMethodBehaviourResult();
    if (input.success) {
      const date = new Date();
      const dayOfMonth = date.getDate();
      date.setDate(dayOfMonth + 30);
      const nextMonth = date.getMonth() + 1;

      result.success = true;
      result.amount = input.amount;
      result.transactionCode = input.transactionId;
      result.reference = input.reference;
      result.date =
        date.getDate() +
        " / " +
        (nextMonth.toString().length === 1 ? "0" + nextMonth : nextMonth) +
        " / " +
        date.getFullYear();
    } else {
      result.success = false;
      result.erroMessage = this.errorService.searchErrorCodeWithParams(
        "error" + input.errCode,
        input.errorParameters,
      );
      result.errorcode = input.errCode;
      result.sendToDocumentVerificationPage = input.errCode === 174 || input.errCode === 2212;
      result.acceptColombiaConditionsRequired = input.errCode === 4577;
    }
    return result;
  }
}

export class BalotoPayValidaCashPaymentMethodBehaviour extends PayValidaCashPaymentMethodBehaviour {
  name = "BALOTO";
  image = "logo_payvalidacash";
  track = "AccessToPayValidaCash-Baloto";
  constructor(
    public userService: UserService,
    acceptColombiaConditions = false,
    errorService: ErrorService,
  ) {
    super(userService, "baloto", acceptColombiaConditions, errorService);
  }
}

export class DimonexPayValidaCashPaymentMethodBehaviour extends PayValidaCashPaymentMethodBehaviour {
  name = "Dimonex";
  image = "logo_payvalidacash";
  track = "AccessToPayValidaCash-Dimonex";
  transactionCode: string;
  reference: string;
  declare amount: number;
  date: string;
  constructor(
    public userService: UserService,
    acceptColombiaConditions = false,
    errorService: ErrorService,
  ) {
    super(userService, "efecty", acceptColombiaConditions, errorService);
  }
}

export class EfectyPaymentMethodBehaviour extends PayValidaCashPaymentMethodBehaviour {
  name = "EEFECTY";
  image = "logo_payvalidacash";
  track = "AccessToPayValidaCash-Efecty";
  isBank = false;
  minDepositAmount = 30000;
  maxDepositAmount = 2000000;
  amountButtons: number[] = [30000, 100000, 500000, 1000000, 1500000, 2000000];
  amount = 30000;
  declare type: string;
  MFA_CODE: string;
  netAmount: number;
  errCode: number;
  transactionId: string;
  isFirstDeposit: boolean;
  status: string;
  taxAmount: number;
  errMessage: string;
  errorParameters: string;
  success: boolean;

  constructor(
    public userService: UserService,
    acceptColombiaConditions = false,
    errorService: ErrorService,
  ) {
    super(userService, "efecty", false, errorService);
  }
}

export class PuntoRedPayValidaCashPaymentMethodBehaviour extends PayValidaCashPaymentMethodBehaviour {
  name = "PUNTORED";
  image = "logo_payvalidacash";
  track = "AccessToPayValidaCash-Puntored";

  constructor(
    public userService: UserService,
    acceptColombiaConditions = false,
    errorService: ErrorService,
  ) {
    super(userService, "puntored", acceptColombiaConditions, errorService);
  }
}
