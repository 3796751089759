import { CdkAccordionModule } from "@angular/cdk/accordion";
import { CommonModule } from "@angular/common";
import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { VerificationTypes } from "@models/MasterData";
import { VerificationLiterals } from "@models/payments/VerificationLiterals";
import { VerificationOption } from "@models/payments/VerificationOption";

@Component({
  selector: "cs-documents-accordion",
  templateUrl: "./cs-documents-accordion.html",
  styleUrls: ["./cs-documents-accordion.scss"],
  standalone: true,
  imports: [CdkAccordionModule, CommonModule],
})
export class CsDocumentsAccordionComponent implements OnInit {
  @Input() isFAQ: boolean;
  @Input() verificationOptionSelected: VerificationOption;
  @Input() verificationLiterals: VerificationLiterals;
  accordionQuestions: any[];
  expandedIndex = 0;

  ngOnChanges(changes: SimpleChanges) {
    if (changes["verificationOptionSelected"])
      this.setAccordionQuestions(changes["verificationOptionSelected"].currentValue);
  }

  ngOnInit(): void {
    this.isFAQ
      ? (this.accordionQuestions = this.verificationLiterals.questions.FAQ.QA)
      : this.setAccordionQuestions(this.verificationOptionSelected);
  }

  setAccordionQuestions(verOpt: VerificationOption) {
    switch (verOpt.type) {
      case VerificationTypes.DNI:
        this.accordionQuestions = this.verificationLiterals.questions.DNI.QA;
        break;
      case VerificationTypes.JustificanteBancario:
        this.accordionQuestions = this.verificationLiterals.questions.justificante.QA;
        break;
      case VerificationTypes.Otros:
        this.accordionQuestions = this.verificationLiterals.questions.otros.QA;
        break;
    }
  }
}
