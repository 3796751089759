import { RedsysCardsActionResult } from "@models/C_Transactions";
import { ErrorService } from "@providers/ErrorService";
/* eslint-disable */
import { UserService } from "@providers/UserService";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { PaymentMethodBehaviour, RedsysCardsMethodBehaviourResult, SkipAmountResult } from "./PaymentMethodBase";

export class RedsysCardsMethodBehaviour extends PaymentMethodBehaviour {
  name = "RedsysCards";
  image = "Visa_epg";
  track = "AccessToRedsysCards";
  isBank = false;
  minDepositAmount = 10;
  maxDepositAmount = 100000;
  amountButtons: number[] = [10, 20, 40, 50];
  amount = 20;

  constructor(userService: UserService, errorService: ErrorService) {
    super(false, userService, errorService);
  }

  skipAmountInput(): Observable<SkipAmountResult> {
    return new Observable<SkipAmountResult>();
  }

  paymentMetodExecution(): Observable<RedsysCardsMethodBehaviourResult> {
    return this.userService.getListOfCards().pipe(
      map((obj) => {
        return this.map(obj);
      }),
    );
  }

  private map(input: RedsysCardsActionResult): RedsysCardsMethodBehaviourResult {
    const result = new RedsysCardsMethodBehaviourResult();
    if (input.success) {
      result.success = true;

      const list = input.ListOfCards;
      if (list && Object.keys(list).length === 0 && Object.getPrototypeOf(list)) {
        result.hasCards = false;
        result.ListOfCards = null;
      } else {
        result.hasCards = true;
        result.ListOfCards = input.ListOfCards;
        result.ListOfCards.push({ label: "Agregar nueva tarjeta", value: "0" });
      }
    } else {
      result.success = false;
      result.erroMessage = this.errorService.searchErrorCodeWithParams(
        "error" + input.errCode,
        input.errorParameters,
      );
      result.errorcode = input.errCode;
      result.sendToDocumentVerificationPage = input.errCode === 174 || input.errCode === 2212;
    }
    return result;
  }
}
