import { createReducer, on } from "@ngrx/store";
import { C_SportSidebar } from "@sports-models/index";
import * as SidebarActions from "./sidebar.actions";

export interface sidebarState {
  sports: C_SportSidebar[];
  highlights: any[];
  highlightsConfig: any[];
}

export const initialState: sidebarState = {
  sports: [],
  highlights: [],
  highlightsConfig: [],
};

export const sidebarReducer = createReducer(
  initialState,
  on(SidebarActions.updateSports, (state, payload) => ({
    ...state,
    sports: payload.sports,
    highlightsConfig: payload.highlightsConfig,
    highlights: payload.highlights,
  })),
  on(SidebarActions.updateCountries, (state, payload) => ({
    ...state,
    sports: state.sports.map((sport) =>
      sport.SportHandle === payload.sport.SportHandle
        ? {
            ...sport,
            countries: {
              ...sport.countries,
              data: [...payload.countries.countries],
            },
            highlight: [...payload.countries.highlights],
          }
        : sport,
    ),
  })),
  on(SidebarActions.toggleSection, (state, payload) => ({
    ...state,
    sports: state.sports.map((sport) =>
      sport.SportHandle === payload.sport.SportHandle
        ? {
            ...sport,
            countries: {
              ...sport.countries,
              collapsed: !payload.sport.countries.collapsed,
            },
          }
        : sport,
    ),
  })),
);
