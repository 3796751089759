import { C_Result } from './C_Result';
import { C_CategoryInfo } from './C_CategoryInfo';

export class C_Game {
  Results: Array<C_Result>;
  DisplayTypeName: string;
  Name: string;
  NodeId: string;
  ParentNodeId: string;
  Priority: number;
  SportHandle: string;
  ChildrenCount: number;
  Collapsed: boolean;
  CategoryInfo: C_CategoryInfo;
  CategoryInfos: Array<C_CategoryInfo>;
  SmartMarketAvailable: boolean;
  GameType: number;
  teamHome: string;
  teamAway: string;
  Spov: string;
  AllSpov: Array<any>;
  Locked: boolean;
  LTMEnabled?: boolean;
  constructor(
    results?: Array<C_Result>,
    displayTypeName?: string,
    nombre?: string,
    nId?: string,
    parentNodeId?: string,
    priority?: number,
    sporthandle?: string,
    childrenCount?: number,
    categoryInfo?: C_CategoryInfo,
    categoryInfos?: Array<C_CategoryInfo>,
    gameType?: number,
    teamHome?: string,
    teamAway?: string,
    Spov?: string,
    AllSpov?: Array<any>,
    Locked?: boolean,
    LTMEnabled?: boolean,
    SmartMarketAvailable?: boolean
  ) {
    this.Results = results;
    this.DisplayTypeName = displayTypeName;
    this.Name = nombre;
    this.NodeId = nId;
    this.ParentNodeId = parentNodeId;
    this.Priority = priority;
    this.SportHandle = sporthandle;
    this.ChildrenCount = childrenCount;
    this.CategoryInfo = categoryInfo;
    this.CategoryInfos = categoryInfos;
    this.GameType = gameType;
    this.teamHome = teamHome;
    this.teamAway = teamAway;
    this.Spov = Spov;
    this.AllSpov = AllSpov;
    this.Locked = Locked;
    this.LTMEnabled = LTMEnabled;
    this.SmartMarketAvailable = SmartMarketAvailable;
  }

  public setNameOfTeams(event, scoreboard?) {
    if (scoreboard) {
      event.teamHome = scoreboard.home;
      event.teamAway = scoreboard.away;
      return event;
    }
    event.teamHome = event.Name.slice(0, event.Name.indexOf(' - '));
    event.teamAway = event.Name.slice(event.Name.indexOf(' - ') + 3, event.Name.length);
    return event;
  }

  static parse(obj: any): C_Game {
    const game = Object.assign(new C_Game(), obj);
    return game;
  }

  public updateOddChanges(prev: any, current: any, field = 'Results') {
    for (const i in current[field]) {
      if (!prev[field][i] || !current[field][i]) {
        return;
      }
      current[field][i].upOdd = current[field][i].Odd > prev[field][i].Odd;
      current[field][i].downOdd = current[field][i].Odd < prev[field][i].Odd;
    }
  }
}
