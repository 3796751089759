<div class="sb-betbuilder--block background-color-dark" *ngIf="!errorMessage">
  <div class="sb-betbuilder--item-left">
    <p class="sb-betbuilder--title color-light">{{ messageText }}</p>
  </div>
  <div class="sb-betbuilder--item-right">
    <ion-button *ngIf="cuotaText" class="sportsbook btn-primary sb-betbuilder--item-right-button" (click)="placeBet()">
      <ion-label class="sb-betbuilder--item-right-label">
        <span class="color-light text-small"> Cuota: {{ cuotaText | formatOdd}} </span>
        <b class="color-light text-16"> Añadir al Ticket </b>
      </ion-label>
    </ion-button>
  </div>
</div>

<div class="sb-betbuilder--block background-color-dark" *ngIf="errorMessage">
  <div class="sb-betbuilder--item-left">
    <p class="sb-betbuilder--title color-light">{{ errorMessage }}</p>
  </div>
</div>
