import { createAction, props } from '@ngrx/store';

export const fetchSports = createAction('[Sidebar Component] Fetch Sports', props<{ sports: any }>());
export const updateSports = createAction(
  '[Sidebar Component] Update Sports',
  props<{ sports: any; highlights: any; highlightsConfig: any }>()
);
export const fetchCountries = createAction('[Sidebar Component] Fetch Countries', props<{ NodeId: string }>());
export const updateCountries = createAction(
  '[Sidebar Component] Update Countries',
  props<{ sport: any; countries: any }>()
);
export const toggleSection = createAction('[Sidebar Component] Toggle Section', props<{ sport: any }>());
