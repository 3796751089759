import { CommonModule } from '@angular/common';
import { Component, Input, Output, EventEmitter, inject } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ParseMoney } from '../../../pipes/parseMoney';
import { LicenseTypes } from '@models/MasterData';
import { UserService } from '@providers/UserService';
import { GlobalVarsModel } from '@models/ModelGlobalsVar';
import { NewBaseService } from '@providers/newBase.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'cs-local-codere',
  templateUrl: './cs-local-codere.html',
  styleUrls: ['./cs-local-codere.scss'],
  standalone: true,
  imports: [TranslateModule, CommonModule, ParseMoney]
})
export class CsLocalCodereComponent {
  @Input() localConfig: any;
  @Input() license: any;
  @Output() cancelLocalCode: EventEmitter<any> = new EventEmitter();
  @Output() openCodereLocals: EventEmitter<any> = new EventEmitter();
  licenseTypes = LicenseTypes;
  userFullName: string;
  globalVars!: GlobalVarsModel;
  showLocalCodereInfoDelete: boolean;
  private destroy$ = new Subject<void>();
  userService = inject(UserService);
  public newBaseService = inject(NewBaseService);

  ngOnInit() {
    this.userFullName = this.localConfig?.userData.Nombre + ' ' + this.localConfig?.userData.PrimerApellido;
    this.newBaseService.getVars.pipe(takeUntil(this.destroy$)).subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
      this.globalVars.FEATURES.showLocalCodereInfoDelete !== undefined
        ? (this.showLocalCodereInfoDelete = this.globalVars.FEATURES?.showLocalCodereInfoDelete)
        : (this.showLocalCodereInfoDelete = true);
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setOpenCodereLocals() {
    this.openCodereLocals.emit(this.localConfig);
  }

  setCancelWithdrawal() {
    this.cancelLocalCode.emit(this.localConfig);
  }

  showDeleteCodeButton() {
    return (
      !this.localConfig?.typeDeposit && this.license !== this.licenseTypes.Mexico && this.showLocalCodereInfoDelete
    );
  }

  showFullName() {
    return (
      !this.localConfig?.typeDeposit && this.license === this.licenseTypes.Nacional && !this.showLocalCodereInfoDelete
    );
  }
}
