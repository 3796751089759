/* eslint-disable eqeqeq */
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'docStatusFilter' })
export class DocStatusFilter implements PipeTransform {
  transform(value: string): string {
    /*
        Aprobada = 754310001,
        Inactivo = 2,
        Pendiente = 1,
        Rechazada = 754310000
    */

    let auxName: string;
    if (value == null) {
      auxName = '';
    }
    switch (value.toString()) {
      case '754310000':
        auxName = 'Verificación rechazada:';
        break;

      case '754310001':
        auxName = 'Aprobado';
        break;

      case '754310002':
        auxName = 'Documentación solicitada';
        break;

      case '1':
        auxName = 'Se está revisando la documentación recibida.';
        break;

      case '2':
        auxName = 'Inactivo';
        break;

      default:
        auxName = value.toString();
        break;
    }
    return auxName;
  }
}
