import { InjectionToken, inject } from '@angular/core';
import { Observable, map, distinctUntilChanged } from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';

export const SMALL_VIEWPORT = new InjectionToken<Observable<boolean>>('SMALL_VIEWPORT', {
  providedIn: 'root',
  factory: () =>
    inject(BreakpointObserver)
      .observe(['(max-width: 768px)'])
      .pipe(
        map(({ matches }) => matches),
        distinctUntilChanged()
      )
});
