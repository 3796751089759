/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable, Pipe, PipeTransform, inject } from "@angular/core";
import { LicenseTypes } from "@models/MasterData";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { ParseMoney } from "@pipes/parseMoney";
import { NewBaseService } from "@providers/newBase.service";

@Pipe({
  name: "parseTransactionMoney",
  standalone: true,
})
@Injectable({ providedIn: "root" })
export class ParseTransactionMoney implements PipeTransform {
  globalVars!: GlobalVarsModel;
  newBaseService = inject(NewBaseService);
  parseMoney = inject(ParseMoney);
  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
  }

  transform(t: any, args: any[] = []): string {
    const totalamount: number = parseFloat(t.Cantidad);
    if (!!t.CantidadNeta && !!t.CantidadImpuestos) {
      const netAmount: number = parseFloat(t.CantidadNeta);
      //eslint-disable-next-line
      if (netAmount !== totalamount) {
        if (t.CantidadImpuestos > 0) {
          return (
            this.parseMoney.transform(t.CantidadNeta, [1]) +
            //eslint-disable-next-line
            (this.globalVars.licenseType !== LicenseTypes.Mexico ? " (Retencion de " : " (Deduccion de ") +
            this.parseMoney.transform(t.CantidadImpuestos, [1]) +
            ")"
          );
        } else {
          return this.parseMoney.transform(t.CantidadNeta, [1]);
        }
      }
    }
    return this.parseMoney.transform(t.Cantidad, [1]);
  }
}
