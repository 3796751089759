/* eslint-disable */
import { TransactionActionResult } from '@models/C_Transactions';
import { PaymentMethodBehaviour, PaymentMethodBehaviourResult, SkipAmountResult } from './PaymentMethodBase';
import { ErrorService } from '@providers/ErrorService';
import { UserService } from '@providers/UserService';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class TeleingresoMethodBehaviourResult extends PaymentMethodBehaviourResult {
  transactionCode: string;
  amount: number;
  date: string;
}

export class TeleingresoSkipAmountResult extends SkipAmountResult {
  transactionCode: string;
  amount: number;
  date: string;
  constructor() {
    super(true);
  }
}

export abstract class TeleingresoPaymentMethodBehaviour extends PaymentMethodBehaviour {
  isBank = false;
  isPaynet = false;

  constructor(userService: UserService, isPaynet = false, errorService: ErrorService) {
    super(false, userService, errorService);
    this.isPaynet = isPaynet;
  }
  skipAmountInput(): Observable<TeleingresoSkipAmountResult> {
    if (this.isPaynet) {
      return this.userService.checkPaynet('').pipe(map(this.mapSkipAmount));
    } else {
      return this.userService.checkTeleingreso('').pipe(map(this.mapSkipAmount));
    }
  }

  paymentMetodExecution(): Observable<TeleingresoMethodBehaviourResult> {
    if (this.isPaynet) {
      return this.userService.createDepositByPaynet('', this.amount).pipe(
        map((obj) => {
          return this.map(obj);
        })
      );
    } else {
      return this.userService.createDepositByTeleingreso('', this.amount).pipe(
        map((obj) => {
          return this.map(obj);
        })
      );
    }
  }

  private mapSkipAmount(input: any): TeleingresoSkipAmountResult {
    const result = new TeleingresoSkipAmountResult();
    if (input.teleingresoreference != '') {
      result.skip = true;
      result.amount = input.amount;
      result.transactionCode = input.teleingresoreference;

      const time = input.createdon.slice(6, input.createdon.length - 2);
      const date = new Date(parseInt(time));
      const dayOfMonth = date.getDate();
      date.setDate(dayOfMonth + 15);
      const m = date.getMonth() + 1;
      // /calculo de fecha de caducidad teleingreso
      result.date = date.getDate() + ' / ' + (m.toString().length == 1 ? '0' + m : m) + ' / ' + date.getFullYear();
    } else {
      result.skip = false;
    }
    return result;
  }

  private map(input: TransactionActionResult): TeleingresoMethodBehaviourResult {
    const result = new TeleingresoMethodBehaviourResult();
    if (input.success) {
      const date = new Date();
      const dayOfMonth = date.getDate();
      date.setDate(dayOfMonth + 15);
      const nextMonth = date.getMonth() + 1;

      result.success = true;
      result.amount = input.amount;
      result.transactionCode = input.transactionId;
      result.date =
        date.getDate() +
        ' / ' +
        (nextMonth.toString().length == 1 ? '0' + nextMonth : nextMonth) +
        ' / ' +
        date.getFullYear();
    } else {
      result.success = false;
      result.erroMessage = this.errorService.searchErrorCodeWithParams('error' + input.errCode, input.errorParameters);
      result.errorcode = input.errCode;
      result.sendToDocumentVerificationPage = input.errCode == 174 || input.errCode == 2212;
    }
    return result;
  }
}

export class PaynetMxPaymentMethodBehaviour extends TeleingresoPaymentMethodBehaviour {
  name = 'PAYNET';
  image = 'paynet_logo';
  track = 'AccessToPaynet';
  minDepositAmount: number;
  maxDepositAmount: number;
  amountButtons: number[] = [300, 400, 800, 1000];
  override amount = 400;

  constructor(
    public override userService: UserService,
    public override errorService: ErrorService
  ) {
    super(userService, true, errorService);
  }
}

export class TeleingresoMxPaymentMethodBehaviour extends TeleingresoPaymentMethodBehaviour {
  name = 'TELEINGRESO';
  track = 'AccessToTeleingreso';
  image = 'teleingreso_logo';
  override amount = 400;

  minDepositAmount: number;
  maxDepositAmount: number;
  amountButtons: number[] = [300, 400, 800, 1000];

  constructor(
    public override userService: UserService,
    public override errorService: ErrorService
  ) {
    super(userService, true, errorService);
  }
}

export class TeleingresoESPaymentMethodBehaviour extends TeleingresoPaymentMethodBehaviour {
  name = 'TELEINGRESO';
  track = 'AccessToTeleingreso';
  image = 'teleingreso_logo';
  override amount = 20;

  minDepositAmount: number;
  maxDepositAmount: number;
  amountButtons: number[] = [10, 20, 40, 50];

  constructor(
    public override userService: UserService,
    public override errorService: ErrorService
  ) {
    super(userService, true, errorService);
  }
}
