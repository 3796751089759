import { CommonModule } from "@angular/common";
/* eslint-disable eqeqeq */
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  type OnDestroy,
  type OnInit,
  Output,
  inject,
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { CONSTANTS } from "@constants";
import { IonicModule } from "@ionic/angular";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { Game } from "@models/lobby/casinoDataModels";
import { TranslateService } from "@ngx-translate/core";
import { ParseMoney } from "@pipes/parseMoney";
import { NewBaseService } from "@providers/newBase.service";
import { JackpotService } from "@services/casino/jackpot.service";
import { DeviceService } from "@services/device.service";
import { Utils } from "@utils/Utils";

@Component({
  selector: "casino-banner-card",
  templateUrl: "./casino-banner-card.component.html",
  styleUrls: ["./casino-banner-card.scss"],
  imports: [CommonModule, ParseMoney, IonicModule],
  standalone: true,
})

//eslint-disable-next-line
export class CasinoBannerCard implements OnInit, OnDestroy {
  image: string;
  baseImage: string;
  loaded;
  isJackpotCounter = false;
  jackpots = 0;
  jackpots2 = 0;
  inited;
  globalVars!: GlobalVarsModel;
  @Input() game: Game;
  @Output() turnPlay = new EventEmitter<any>();
  subscription: any;
  setAmount: any[] = [];
  isDesktop: boolean;

  // #region injects
  element = inject(ElementRef);
  cd = inject(ChangeDetectorRef);
  jackpotService = inject(JackpotService);
  sanitizer = inject(DomSanitizer);
  utils = inject(Utils);
  translate = inject(TranslateService);
  newBaseService = inject(NewBaseService);
  deviceService = inject(DeviceService);
  // #endregion

  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
    this.isDesktop = this.deviceService.isDesktop();
    this.baseImage = CONSTANTS.bannerUrlBase;
  }

  ngOnInit() {
    if (this.game && !this.inited) {
      this.setImage();
      this.subscription = this.game.additionalData.subscribe((data) => this.changed(data));
      this.inited = true;
    }
  }

  @HostBinding("attr.priority")
  get priority() {
    return;
  }

  changed(data) {
    if (!this.game) return;
    if (data && data.jp && data.timer) {
      this.isJackpotCounter = true;
      if (data.currents) {
        // TODO: comprobar funcionalidad ya que ahora no se reciben datos con currents[] para probar con jackpots2
        this.jackpots = this.jackpotService.getTicker(data.currents[0], data.timer, this.game.GameId + "1");
        this.jackpots2 = this.jackpotService.getTicker(data.currents[1], data.timer, this.game.GameId + "2");
      } else {
        this.jackpotService
          .getTicker(data.jp, data.timer, this.game.GameId)
          .subscribe((jackpotData) => (this.jackpots = jackpotData));
      }
      this.setAmountFunction(data.jp);
    }
  }

  setImage() {
    const imageType = this.game.imageType || "jpg";
    const shape = this.isDesktop ? "BannerPC" : "BannerMobile";
    const image = this.baseImage + this.game.Name.replace(/ /g, "") + "_" + shape + `.${imageType}`;
    this.image = image;
  }

  playGame(demo?: boolean) {
    this.turnPlay.emit({ demo: demo, game: this.game });
  }

  isLoaded(loaded: boolean) {
    this.loaded = true;
    this.cd.detectChanges();
  }

  infoGame(event, game) {
    event.preventDefault();
    const msg = game.Description;
    this.utils.alert(false, "+ Info", msg, this.translate.instant("tAccept"), () => {});
  }

  setAmountFunction(currentNumber) {
    this.setAmount = [];
    const toLocaleStringMx: string = currentNumber.toLocaleStringMx("es-MX", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    const stringAmount: string[] = toLocaleStringMx.split("");
    if (toLocaleStringMx.length === 7) stringAmount.splice(1, 0, ",");

    stringAmount.map((el) => {
      if (el.indexOf(".") !== -1 || el.indexOf(",") !== -1) {
        this.setAmount.push({ item: el, isNumber: false });
      } else {
        this.setAmount.push({ item: el, isNumber: true });
      }
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
