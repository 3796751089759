import { C_Horses } from './C_Horses';
import { C_Racer } from './C_Racer';

export class C_Race {
  PreviousRace: string;
  NextRace: C_Horses;
  Participants: Array<C_Racer>;
  PaddockName: string;
  StartDate: string;
  MeetingId: string;
  GameId: string;
  HasHandicap: boolean;
  TricastDisabled: boolean;
  WinDisabled: boolean;
  ForecastDisabled: boolean;
  PlaceDisabled: boolean;
  CountryCode: string;
  PaddockId: string;
  IsActive: boolean;
  OddsAvailability: number;
  Name: string;
  NodeId: string;
  ParentNodeId: string;
  EventNodeTypeId: number;
  Priority: number;
  SportHandle: string;
  ChildrenCount: number;
  StreamingEnabled: string;
  StatisticsId: string;
  StreamingId: string;
  EventHasHandicap?: boolean;
  GameBetTypeAvailability?: number;
  GameNumberOfStarters?: number;
  GameSpecialOddsValue?: string;
  GameTypeId?: number;
  LocationId?: number;
  Locked?: boolean;
  ShowDisabled?: boolean;
  SportId?: string;
  SuperFectaDisabled?: boolean;
}
