/* eslint-disable eqeqeq */
import { TrackingService } from '@providers/TrackingService';
import { Component, inject, OnInit } from '@angular/core';
export abstract class TrackingInfo {
  uri!: string;
  description!: string;
  additionalData: any;
}
@Component({
  template: '' // **+** Agregando un template vacío para evitar los errores...
})
export abstract class BasePage implements OnInit {
  trackingService = inject(TrackingService);

  ngOnInit() {
    const trackingInfo: TrackingInfo = this.getTrackingInfo();
    if (trackingInfo != null) {
      this.trackPageView(trackingInfo);
    }
  }

  trackPageView = (trackingInfo: TrackingInfo) => {
    if (trackingInfo.uri.charAt(0) == '/') trackingInfo.uri = trackingInfo.uri.substring(1, trackingInfo.uri.length);
    this.trackingService.trackPageView(
      `${this.getBaseUri()}${trackingInfo.uri}`,
      trackingInfo.description,
      trackingInfo.additionalData
    );
  };

  private getBaseUri() {
    let baseUri: string = document.location.href;
    if (baseUri.indexOf('#') > 0) baseUri = baseUri.substring(0, baseUri.indexOf('#'));
    if (baseUri.indexOf('index.html') > 0) baseUri = baseUri.substring(0, baseUri.indexOf('index.html'));
    if (baseUri.indexOf('index.htm') > 0) baseUri = baseUri.substring(0, baseUri.indexOf('index.htm'));
    if (!baseUri.endsWith('/')) baseUri += '/';
    return baseUri;
  }

  abstract getTrackingInfo(): TrackingInfo;
}
