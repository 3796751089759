<ng-container [formGroup]="fgrp">
  <div class="col s12 select-element">
    <select [id]="'select-' + fcName" item-content [formControlName]="fcName" class="browser-default">
      <option [ngValue]="null" disabled selected>{{ placeHolder | translate }}</option>
      <option *ngFor="let option of options" [ngValue]="option">
        {{ option.name }}
      </option>
    </select>

    <label
      [for]="'select-' + fcName"
      [id]="'label-' + fcName"
      class="labelselect floating"
      [ngClass]="{
        green: fgrp.get(fcName).valid
      }"
    >
      <span>{{ label }}</span>
      <cdr-info
        tabindex="-1"
        class="select-img"
        [target]="modal"
        [fcName]="fcName"
        [fgrp]="fgrp"
        *ngIf="!fgrp.get(fcName).dirty"
      ></cdr-info>
      <ion-icon *ngIf="fgrp.get(fcName).valid" size="large" name="checkmark-circle-outline" class="green-text" />
      <ion-icon *ngIf="fgrp.get(fcName).invalid" size="large" name="checkmark-circle-outline" class="invisible" />
    </label>
  </div>
</ng-container>

<!-- Mensajes de error -->
<cdr-control-error *ngIf="errorsCol" [fControl]="fControl" [errorsCol]="errorsCol"> </cdr-control-error>
