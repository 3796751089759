import { c_FreeBet as C_FreeBet } from './C_FreeBet';
import { C_CodereCard } from './C_CodereCard';
import { C_Customization } from './C_Customization';

export class C_User {
  username: string;
  logged: boolean;
  balance: number;
  userRegion: number;
  id: string;

  name: string;
  surname: string;
  surname2: string;
  birthDay: string;
  email: string;

  //last password change
  lastPassword: Date;
  //pending SMS confirmation
  pendingSMSEmail: boolean;

  // number of pendind freebets
  pendingCountFreebets: number;

  //number of cob pending
  cobPend: number;

  //
  activeSmsEmail: boolean;

  //when the user is playing slots
  playingSlots: boolean;

  //when the user is playing casino games
  playingCasino: boolean;

  //codere card
  codereCard: C_CodereCard;

  /*tarCodereActive: boolean;
  numberCodereCard: string;
  cardRegion: string;
  cardBalance: number;
  cardDate: string;
  cardState: string;*/

  pendingFreeBets: C_FreeBet;
  customization: C_Customization;
  idBGT: number;

  sessionLimitsGasto: number;
  sessionLimitsPerdida: number;
  sessionLimitsTiempo: number;

  registratedDate: Date;
  lastLoginDate: Date;
  firstDeposit: boolean;
  verificacionDocumental: boolean;
  codereIdTracker: {
    Mexos_Advertiser: string;
    Mexos_ProfileID: string;
    Mexos_ID2: string;
    Mexos_ID3: string;
    Mexos_Aquisition_Source: string;
  };

  constructor(logged: boolean) {
    this.logged = logged;
    this.playingSlots = false;
    this.playingCasino = false;
    this.codereCard = new C_CodereCard();
    this.pendingCountFreebets = 0;
    this.customization = new C_Customization(false, false);
    this.verificacionDocumental = false;
    this.userRegion = 0;
    this.firstDeposit = false;
    this.codereIdTracker = {
      Mexos_Advertiser: '',
      Mexos_Aquisition_Source: '',
      Mexos_ID2: '',
      Mexos_ID3: '',
      Mexos_ProfileID: ''
    };
  }
}
