/* eslint-disable eqeqeq */
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, Component, inject } from '@angular/core';
import { Store } from '@ngrx/store';

import { TicketMethods } from '../../../models/C_TicketApuestas';
import { ticketState } from '../../../models/sports/store/reducers/ticket.reducers';
import { TicketMobileSmallFooterComponent } from '../ticket-mobile-small-footer/ticket-mobile-small-footer';
import { ParseStakeMoney } from '@pipes/parseStakeMoney';

@Component({
  selector: 'ticket-mobile',
  templateUrl: './ticket-mobile.html',
  styleUrls: ['./ticket-mobile.scss'],
  standalone: true,
  imports: [ParseStakeMoney, TicketMobileSmallFooterComponent, CommonModule],
  providers: [ParseStakeMoney],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class TicketMobileComponent {
  ticketState: ticketState;
  numberOfSelections: number = 0;
  store = inject(Store<ticketState>);

  constructor() {
    this.store.select('ticket').subscribe((ticketState: ticketState) => {
      this.ticketState = ticketState;
      this.numberOfSelections = TicketMethods.getNumberOfSelections(ticketState.ticket);
    });
  }

  showTMSmallFooter() {
    return this.numberOfSelections < 1;
  }
}
