<ion-content>
  <div class="veridas-container">
    <div id="veridasTarget" *ngIf="showIframe">
      <div *ngIf="processOpen" class="cs-veridas-close">
        <i class="color-light codere-icon icon-close" (click)="createAlert()"></i>
      </div>
      <iframe
        id="xpressid-iframe"
        class="cs-veridas-iframe"
        allow="camera; microphone;"
        frameborder="0"
        scrolling="no"
        [src]="sourceIframe"
        #veridasIframe
      >
      </iframe>
    </div>
  </div>
</ion-content>
