import { C_TicketApuestasNew, C_TicketEnumMode, OveraskDataMethods } from "@sports-models/index";
import { AABonusMethods } from "@sports-utils/index";

export const newC_TicketApuestas = (): C_TicketApuestasNew => ({
  ApplicableAccaBonus: AABonusMethods.newApplicableAccaBonus(),
  BetType: 0,
  BonusChecked: new Date().getDate(),
  CombinationMulticast: [],
  Combinations: 2,
  Created: new Date().getDate(),
  Default: true,
  hasChangesInOdds: false,
  HasPrice: true,
  isPlacingbet: false,
  keepSelection: false,
  LanguageCode: "es",
  LineItems: [],
  mode: C_TicketEnumMode.TICKET_CONFIRMATION_EXPANDED,
  Odd: 0,
  Opened: false,
  OrderBonusMessages: [],
  OrderErrorMessages: [],
  OrderErrors: {},
  OveraskInfo: OveraskDataMethods.newOveraskData(),
  OveraskCloseBet: null,
  processing: false,
  SmartLineItems: [],
  StakeGroups: { Singles: {}, Accumulator: {}, System: {} },
  StraightMulticast: [],
  SystemSelected: null,
  SystemType: 1,
  Tax: 0,
  TicketNumber: "",
  TotalLocal: "",
  Total: 1,
  TotalBets: 0,
  TotalPotentialWin: 0,
});

export const parse = (obj: Partial<C_TicketApuestasNew>): C_TicketApuestasNew => {
  return Object.assign(newC_TicketApuestas(), obj);
};

export const getAllItems = (ticket: C_TicketApuestasNew) => {
  return [
    ...ticket?.LineItems,
    ...ticket?.StraightMulticast,
    ...ticket?.CombinationMulticast,
    ...ticket?.SmartLineItems,
  ];
};

export const getItemByPBSId = (ticket, id: number) => {
  const arrItems = getAllItems(ticket);
  const removedItem = arrItems.find((i) => i.ItemId === id);
  return removedItem;
};

export const getItemByPBSIds = (ticket, ids: number[]) => {
  const arrItems = getAllItems(ticket);
  const removedItem = arrItems.find((i) => ids.includes(i.ItemId));
  return removedItem;
};

export const deleteError = (ticket: C_TicketApuestasNew, key: string): void => {
  const error = ticket?.OrderErrors[key];
  if (!error) {
    return;
  }
  ticket.OrderErrorMessages = ticket?.OrderErrorMessages.filter((e) => e.toLowerCase() !== error.toLowerCase());
  delete ticket?.OrderErrors[key];
};

export const getSelectedLine = (ticket: C_TicketApuestasNew) => {
  return [
    ...ticket?.LineItems,
    ...ticket?.StraightMulticast,
    ...ticket?.CombinationMulticast,
    ...ticket?.SmartLineItems,
  ].find((lineItem) => lineItem.Selected);
};

export const getIsPending = (ticket: C_TicketApuestasNew): boolean => {
  return !!(ticket?.OveraskInfo.Status !== "OK" && ticket?.OveraskInfo.Status !== 3);
};

export const getIsConfirmed = (ticket: C_TicketApuestasNew): boolean => {
  if (ticket !== null) {
    const lineItems = ticket?.LineItems.some((line) => Boolean(line.TicketNumber));
    const straightMulticast = ticket?.StraightMulticast.some((line) => Boolean(line.TicketNumber));
    const combinationMulticast = ticket?.CombinationMulticast.some((line) => Boolean(line.TicketNumber));
    const smartLineItems = ticket?.SmartLineItems.some((line) => Boolean(line.TicketNumber));
    return (
      lineItems || straightMulticast || combinationMulticast || smartLineItems || Boolean(ticket?.TicketNumber)
    );
  } else {
    return false;
  }
};

export const getIsUpdatableTicket = (ticket: C_TicketApuestasNew) => {
  const hasItems = getAllItems(ticket).length > 0;
  const hasChangesInOds = ticket?.hasChangesInOdds;
  const isConfirmed = getIsConfirmed(ticket);
  const isProcessing = ticket?.processing;
  let hasErrors = false;

  if (ticket?.OrderErrors["51931"]) hasErrors = false; // Combinación no permitida
  if (ticket?.OrderErrors["9999999"]) hasErrors = true; // Player Errors
  if (ticket?.OrderErrors["51937"]) hasErrors = false; // Cuota ha cambiado
  if (ticket?.OrderErrors["51941"]) hasErrors = false; // Selección Bloqueada
  if (ticket?.OrderErrors["51927"]) hasErrors = false; // Mercado no disponible

  return hasItems && !hasChangesInOds && !isConfirmed && !isProcessing && !hasErrors;
};

export const getHasErrors = (ticket: C_TicketApuestasNew) => {
  return ticket?.OrderErrorMessages.length > 0;
};

export const getHasFBErrors = (ticket: C_TicketApuestasNew) => {
  let hasErrors = false;
  if (ticket?.FreeBetVoucherId) {
    if (ticket?.BetType === 0 && !ticket?.FreebetInstance.Validation.singles) {
      hasErrors = true;
    }

    if (ticket?.BetType === 1 && !ticket?.FreebetInstance.Validation.systems) {
      hasErrors = true;
    }
  }
  return hasErrors;
};

export const getAmountBet = (ticket?: C_TicketApuestasNew) => {
  let amount: string;
  if (ticket) {
    if (ticket.TotalPotentialWin === 0) {
      const lineItemsAux: number = ticket.LineItems.findIndex(
        (item) =>
          item.SportHandle !== "horse_racing" && item.SportHandle !== "greyhound_racing" && item.IsSelected,
      );
      amount = lineItemsAux > -1 ? "N/A" : "CF";
    } else {
      if (getHasFreebet(ticket)) {
        amount = ((ticket.TotalPotentialWin + ticket.Total) / ticket.Total).toFixed(2).toString();
      } else {
        amount = (ticket.TotalPotentialWin / ticket.Total).toFixed(2).toString();
      }
    }
  } else {
    amount = "0";
  }
  return amount;
};

export const getHasFreebet = (ticket): boolean => {
  return Boolean(ticket?.FreebetInstance);
};

export const getNumberOfSelections = (data): number => {
  let selections = 0;

  if (data.LineItems.length > 0) {
    selections += data.LineItems.length;
  }
  if (data.SmartLineItems.length > 0) {
    selections += data.SmartLineItems.length;
  }
  if (data.StraightMulticast.length > 0) {
    selections += data.StraightMulticast.length;
  }
  if (data.CombinationMulticast.length > 0) {
    selections += data.CombinationMulticast.length;
  }
  return selections;
};
