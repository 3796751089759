import { CommonModule } from "@angular/common";
import { Component, OnInit, inject } from "@angular/core";
import { ModalController, NavController, NavParams } from "@ionic/angular";
import { LicenseTypes } from "@models/MasterData";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { TRANSACTION_STATES } from "@models/payments/States";
import { TRANSACTION_HISTORY_TYPES } from "@models/payments/Types";
import { TranslateModule } from "@ngx-translate/core";
import { ExtractDatePipe } from "@pipes/extractDate.pipe";
import { ParseMoney } from "@pipes/parseMoney";
import { NewBaseService } from "@providers/newBase.service";
import { DeviceService } from "@services/device.service";

@Component({
  selector: "page-cashier-history-detail",
  templateUrl: "./cashier-history-detail.html",
  styleUrls: ["./cashier-history-detail.scss"],
  standalone: true,
  imports: [TranslateModule, ParseMoney, ExtractDatePipe, CommonModule],
})
export class CashierHistoryDetailPage implements OnInit {
  history: any;
  globalVars!: GlobalVarsModel;

  public navCtrl = inject(NavController);
  public navParams = inject(NavParams);
  public modalCtrl = inject(ModalController);
  public newBaseService = inject(NewBaseService);
  public deviceService = inject(DeviceService);
  public isDesktop = false;
  public isMobile = false;

  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });

    this.isDesktop = this.deviceService.isDesktop();
    this.isMobile = this.deviceService.isMobile();
  }

  ngOnInit(): void {
    this.history = this.navParams.get("historyItem");
  }

  close() {
    this.modalCtrl.dismiss();
  }

  cancelPayment() {
    this.modalCtrl.dismiss(this.history);
  }

  get setCancelWithdrawal() {
    return (
      this.history.Estado === TRANSACTION_STATES.pending &&
      this.history.Tipo === TRANSACTION_HISTORY_TYPES.withdrawal
    );
  }

  get displayRetainedAmount() {
    const { AmountIsGreaterThanTaxWithdrawThreshold } = this.history;
    const hasRetainedAmount = "RetainedAmount" in this.history;
    const allowedLicenses = this.globalVars.licenseType === LicenseTypes.Colombia;

    return AmountIsGreaterThanTaxWithdrawThreshold && hasRetainedAmount && allowedLicenses;
  }
}
