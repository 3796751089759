import { AlertController, Platform, ModalController, IonContent, IonicModule } from '@ionic/angular';
import { ElementRef, ViewChild, Component, forwardRef, Inject, inject } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { ScreenOrientation } from '@capacitor/screen-orientation';
import { StatusBar } from '@capacitor/status-bar';

//Services
import { GlobalVars } from '../../../providers/globalVars';
import { Utils } from '../../../utils/Utils';
import { UserService } from '../../../providers/UserService';
import { GlobalVarsModel } from '../../../models/ModelGlobalsVar';
import { NewBaseService } from '../../../providers/newBase.service';
import { NativeService } from '../../../providers/NativeService';
import { DeviceService } from '../../../services/device.service';
import { UrlsService } from '../../../providers/UrlsService';
import { BufferFacade } from '../../../providers/facades/buffer.facade';
import { TrackingService } from '../../../providers/TrackingService';

//Models
import { CsVeridasIframe } from '../../../models/payments/cs-veridas-iframe';
import { EventTypes } from '../../../models/TrackingEvents';

//Pipes
import { ExtractDatePipe } from '../../../pipes/extractDate.pipe';
import { EventsService } from '../../../providers/EventsService';
import { CommonModule } from '@angular/common';
import { Capacitor } from '@capacitor/core';

//eslint-disable-next-line
enum PostMessageActions {
  Iframe_Loaded = 'Iframe_Loaded',
  ProcessStarted = 'ProcessStarted',
  ProcessCompleted = 'ProcessCompleted',
  FlowNotAllowed = 'FlowNotAllowed',
  VideocallNotAllowed = 'VideocallNotAllowed',
  InvalidInputParameter = 'InvalidInputParameter',
  BrowserNotSupported = 'BrowserNotSupported',
  ProcessError = 'ProcessError'
}

@Component({
  selector: 'cashier-veridas-iframe',
  templateUrl: './cashier-veridas-iframe.html',
  styleUrls: ['./cashier-veridas-iframe.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule]
})
export class CashierVeridasIframe {
  @ViewChild('content') content: IonContent;
  @ViewChild('veridasIframe') veridasIframe: ElementRef;

  previusPage: any;
  showIframe: boolean;
  sourceIframe: SafeResourceUrl;
  configIframe: any;
  setConfig: boolean;
  processOpen: boolean;

  iframePostedMessagesListener: (event: any) => void;

  public alertController = inject(AlertController);
  public viewCtrl = inject(ModalController); //**ViewController deprecado */
  public extractDate = inject(ExtractDatePipe);
  public utils = inject(Utils);
  public globalVars: GlobalVarsModel;
  private newBaseService = inject(NewBaseService);
  public events = inject(EventsService);
  public userService = inject(UserService);
  public translate = inject(TranslateService);
  public platform = inject(Platform);
  public urlsService = inject(UrlsService);
  public deviceService = inject(DeviceService);
  public bufferFacade = inject(BufferFacade);
  public nativeService = inject(NativeService);
  private trackingService = inject(TrackingService);

  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
    this.showIframe = false;
    this.setConfig = false;
    this.processOpen = true;
    this.iframePostedMessagesListener = this.listenEvents.bind(this);
  }

  ionViewWillEnter() {
    StatusBar.hide();

    window.addEventListener('message', this.iframePostedMessagesListener, false);
  }

  ionViewWillLeave() {
    window.removeEventListener('message', this.iframePostedMessagesListener, false);
  }

  ionViewDidEnter() {
    this.utils.loader();
    this.userService.getConfigVeridas().subscribe((data) => {
      this.configIframe = data;
      this.startVeridas();
    });
  }

  ionViewDidLoad() {
    this.events.publish('footer:refresh', this.content);
  }

  ionViewDidLeave() {
    if (Capacitor.getPlatform() !== 'web') {
      try {
        ScreenOrientation.lock({ orientation: 'portrait' });
        StatusBar.show();
      } catch (err) {
        console.error(err);
      }
    }

    if (!this.platform.is('cordova') && this.platform.is('android')) {
      setTimeout(() => {
        document.exitFullscreen();
      }, 0);
    }
    this.trackingService.track({
      eventType: EventTypes.VeridasXpressId,
      secondParameter: 'Exit_veridas',
      description: ''
    });
  }

  startVeridas() {
    const device =
      (this.platform.is('cordova') && this.platform.is('android')) || this.platform.is('ios') ? 'android' : 'web';

    this.trackingService.track({
      eventType: EventTypes.PaymentsVeridasGetToken,
      secondParameter: 'veridas_request_token',
      description: 'Requesting a token for the veridas iframe from the backend'
    });

    this.userService.getTokenVeridas(this.configIframe, device).subscribe((data: CsVeridasIframe) => {
      const { Success } = data;
      if (Success) {
        const { xpressid_url, access_token } = data;
        this.sourceIframe = this.urlsService.getVeridasIframeUrl(xpressid_url, access_token);
        this.showIframe = true;
        setTimeout(() => {
          if (this.showIframe) {
            this.utils.closeLoader();
          }
        }, 10000);
      } else {
        this.trackingService.track({
          eventType: EventTypes.PaymentsVeridasGetTokenFailure,
          secondParameter: 'veridas_request_token_failure',
          description: 'Failure in requesting token from the backdn for the veridas iframe',
          additionalData: data
        });
        const defaultErrorMessage = 'Error accediendo al servicio de verificación';
        const { ErrMessage } = data;
        this.utils.closeLoader();
        this.viewCtrl.dismiss();
        this.utils.showError(ErrMessage || defaultErrorMessage);
      }
    });
  }

  private parseVeridasEventName(event: any) {
    const second_param_name = event.data.code
      .replace(/([A-Z])/g, '_$1')
      .toLowerCase()
      .replace(/^_/, '');
    return second_param_name;
  }

  /**
   * Event listener method for Veridas Iframe page post messages
   * Should be binded to a enum PostMessageActions object
   * 07/24/2024 - BAUPAY-3783: Refactor to better listen events
   * VERIDAS REFERENCE: https://docs.veridas.com/xpressid/web/v3.4/integration/
   */
  private parseEventToTrackingLog(event: any): any {
    const returnData = <any>{};
    returnData.additionalData = <any>{};
    returnData.id = '';
    returnData.eventType = EventTypes.VeridasXpressId;
    returnData.description = event?.data?.message;
    switch (event.data.type) {
      case 'info':
      case 'warning':
        returnData.secondParameter = 'iframe_' + this.parseVeridasEventName(event);
        returnData.additionalData.code = event?.data?.infoEvent?.code;
        returnData.additionalData.detail = event?.data?.infoEvent?.detail;
        returnData.additionalData.validationId = event?.data?.additionalData?.validationId ?? ''; // WE ALWAYS LOG FOR BETTER SEARCHING
        returnData.id = event?.data?.additionalData?.validationId ?? '';
        break;

      case 'error':
        returnData.secondParameter = 'iframe_error_' + this.parseVeridasEventName(event);
        returnData.additionalData.validationId = event?.data?.additionalData?.validationId ?? ''; // WE ALWAYS LOG FOR BETTER SEARCHING
        returnData.id = event?.data?.additionalData?.validationId ?? '';
        returnData.additionalData.errorCode =
          event?.data?.errorData?.code ||
          event?.data?.errorCode ||
          event?.data?.infoEvent?.errorCode ||
          event?.data?.code;
        returnData.additionalData.errorMessage = event?.data?.errorData?.errorDetail || event?.data?.message;
        returnData.additionalData.flow =
          event?.data?.stage || event?.data?.errorData?.stage || event?.data?.infoEvent?.stage;
        returnData.additionalData.infoEvent = event?.data?.errorData; // IN CASE THERE IS NOTHING ELSE WE WILL ALWAYS LOG THIS
        break;
    }

    // Global scores will be logged if they are somewhere
    if (event.data.code === PostMessageActions.ProcessCompleted) {
      returnData.additionalData.globalScores =
        event?.additionalData?.globalScores ||
        event?.data?.additionalData?.globalScores ||
        event?.data?.globalScores ||
        event?.data?.infoEvent?.globalScores;
    }

    return returnData;
  }

  listenEvents(event: any) {
    console.log(event);
    const trackingLogData = this.parseEventToTrackingLog(event);
    console.log(trackingLogData);
    this.trackingService.track(trackingLogData);

    switch (event.data.code) {
      case PostMessageActions.ProcessStarted:
        this.utils.closeLoader();
        break;
      case PostMessageActions.ProcessCompleted:
        this.closeModal(event.data);
        this.processOpen = false;
        break;

      case PostMessageActions.FlowNotAllowed:
        this.utils.showError('Ha habido un error de verificación en el proceso. Inténtalo más tarde', () => {
          this.closeModal();
        });
        this.processOpen = false;
        break;

      case PostMessageActions.VideocallNotAllowed:
        this.utils.showError('Ha habido un error de verificación en el proceso. Inténtalo de nuevo', () => {
          this.closeModal();
        });
        this.processOpen = false;
        break;

      case PostMessageActions.InvalidInputParameter:
        this.utils.showError('Ha habido un error de verificación en el proceso. Inténtalo más tarde', () => {
          this.closeModal();
        });
        this.processOpen = false;
        break;

      case PostMessageActions.BrowserNotSupported:
        this.utils.showError(
          'Tu navegador no permite la captura de documentos, por favor utiliza otro distinto',
          () => {
            this.closeModal();
          }
        );
        this.processOpen = false;
        break;

      case PostMessageActions.ProcessError:
        this.utils.showError('Ha habido un error de verificación en el proceso. Inténtalo de nuevo', () => {
          this.closeModal();
        });
        this.processOpen = false;
        break;
    }
  }

  finishVeridas(id) {
    this.userService.setFinishVeridasVerification(id).subscribe((data) => {
      if (data.Success) {
        this.trackingService.track({
          eventType: EventTypes.VeridasXpressId,
          secondParameter: 'iframe_process_verified',
          id
        });
        this.utils.showMsg('Proceso completado y verificado correctamente', () => {
          this.closeModal();
        });
        this.closeModal();
      } else {
        this.trackingService.track({
          eventType: EventTypes.VeridasXpressId,
          secondParameter: 'iframe_error_process_verified',
          description: id
        });
        // TODO control de errores
        this.utils.showError('Ha habido un error de verificación en el proceso. Inténtalo de nuevo', () => {
          this.closeModal();
        });
      }
    });
  }

  fullScreen() {
    document.body.requestFullscreen();
  }

  async createAlert() {
    const confirmAlert = await this.alertController.create({
      header: '¿Está seguro?',
      message: 'Si sales se cancelará el proceso de verificación',
      cssClass: 'sb-alert',
      backdropDismiss: true,
      buttons: [
        {
          text: this.translate.instant('tCancel'),
          role: 'cancel'
        },
        {
          text: this.translate.instant('leave'),
          handler: (_) => {
            this.closeModal();
          },
          role: 'acept'
        }
      ]
    });
    confirmAlert.present();
  }

  closeModal(data?: any) {
    if (this.viewCtrl) {
      this.viewCtrl.dismiss(data);
    }
  }
}
