<div class="banner-container">
  <ion-img [src]="image" alt="banner" (click)="playGame(false)" />
  <div
    class="container-InfoCont"
    (click)="infoGame($event, game)"
    *ngIf="game.Description !== '' && game.Description !== null"
  >
    <img [src]="'assets/global/img/slots/icono_info.svg'" />
  </div>
  <div class="container-Jp" *ngIf="isJackpotCounter && jackpots2 === 0">
    <div class="container-Jp--jackpot">
      <div class="container-Jp-counter font-weight-bold">
        {{ jackpots | parseMoney: [4] }}
      </div>
    </div>
  </div>
  <div class="container-Jp2" *ngIf="isJackpotCounter && jackpots2 !== 0">
    <div class="container-Jp2--jackpot jp1">
      <div class="container-Jp2-counter font-weight-bold">
        {{ jackpots | parseMoney: [4] }}
      </div>
    </div>
    <div class="container-Jp2--jackpot jp2">
      <div class="container-Jp2-counter font-weight-bold">
        {{ jackpots2 | parseMoney: [4] }}
      </div>
    </div>
  </div>
</div>
