<ng-container *ngIf="isDesktop">
  <ng-content></ng-content>
  <ng-content></ng-content>
</ng-container>

<span
  *ngIf="getNumberOfSelections(ticket) === 0 && ticket?.OveraskInfo?.Status == 'OK'"
  class="sb-ticket--empty-state color-grey-300 background-color-dark"
>
  {{ 'selectionsadded' | translate }}
</span>

<!-- Ticket resume header -->
<div
  class="sb-ticket--confirmation-header sb-ticket--header border-color-light background-color-dark"
  *ngIf="getTicketIsConfirmed(ticket)"
  [ngClass]="{ 'box-shadow': !accordionHidden }"
  tappable
  (click)="toggleConfirmation()"
>
  <div class="sb-ticket--confirmation-headings">
    <span *ngIf="!ticket?.MultisingleError" class="sb-ticket-selection--title color-light">
      {{ 'compapuestaT1' | translate }} {{ 'compapuestaT2' | translate }}
    </span>
    <span *ngIf="ticket?.MultisingleError" class="color-light"> {{ ticket?.MultisingleError }} </span>
    <span
      *ngIf="getTicketIsConfirmed(ticket) && ticket?.TicketNumber"
      class="sb-ticket-selection--subtitle color-grey-500"
    >
      {{'compapuestaP3' | translate}} #{{ ticket?.TicketNumber }}
    </span>
  </div>
  <i class="codere-icon color-light icon-arrow-bottom" [ngClass]="{ 'rotate-icon-180' : !accordionHidden }"></i>
</div>
<!-- End Ticket resume header -->

<!-- Overask START -->
<!-- toggleConfirmation does not recieve any arguments -->
<div
  *ngIf="+ticket?.OveraskInfo?.Status == 0"
  class="sb-ticket--confirmation-header sb-ticket--header border-color-light background-color-dark"
  tappable
  (click)="toggleConfirmation()"
>
  <div class="sb-ticket--confirmation-headings">
    <span class="sb-ticket-selection--title color-light"> Aprobación Requerida </span>
    <span class="sb-ticket-selection--subtitle color-grey-500"> Por favor espera </span>
  </div>
  <i
    *ngIf="isDesktop"
    class="codere-icon color-light icon-arrow-bottom"
    [ngClass]="{ 'rotate-icon-180' : !accordionHidden }"
  ></i>
</div>

<!-- Overask Status 1 START -->
<div
  *ngIf="+ticket?.OveraskInfo?.Status == 1"
  class="sb-ticket--confirmation-header sb-ticket--header background-color-dark"
>
  <div class="sb-ticket--confirmation-headings">
    <span class="sb-ticket-selection--overask color-light">
      LO SENTIMOS, TU APUESTA HA ALCANZADO EL LÝMITE PERMITIDO. APUESTA MAXIMA POSIBLE:
      <!-- added +"" to convert the value to string -->
      <span class="color-primary">{{ ticket?.OveraskInfo?.MaxStake+"" | parseStakeMoney:[1]}}</span>
    </span>
  </div>
</div>
<!-- Overask Status 1 END -->

<!-- Overask Status 3 START -->
<div
  *ngIf="+ticket?.OveraskInfo?.Status == 3"
  class="sb-ticket--confirmation-header sb-ticket--header background-color-dark"
>
  <div class="sb-ticket--confirmation-headings">
    <span class="sb-ticket-selection--overask color-light">
      LO SENTIMOS, TU APUESTA HA ALCANZADO EL LÍMITE PERMITIDO Y NO HA SE PODIDO REALIZAR
    </span>
  </div>
</div>
<!-- Overask Status 3 END -->

<!-- Overask TIMEOUT START  -->
<div
  *ngIf="+ticket?.OveraskInfo?.Status == 2"
  class="sb-ticket--confirmation-header sb-ticket--header background-color-dark"
>
  <div class="sb-ticket--confirmation-headings">
    <span class="sb-ticket-selection--overask color-light">
      LO SENTIMOS, EL TIEMPO DE RESPUESTA SE HA AGOTADO. REALIZA DE NUEVO TU APUESTA
    </span>
  </div>
</div>
<!-- Overask TIMEOUT END -->

<!-- Overask END -->
<div
  class="sb-ticket--filter background-color-regular"
  [elementDisabled]="getElementDisabled()"
  *ngIf="!getTicketIsConfirmed(ticket) && getNumberOfSelections(ticket) > 0 && ticket?.OveraskInfo?.Status == 'OK'"
>
  <ticket-filter
    (filter)="changeBetType($event)"
    [ticket]="ticket"
    [filters]="filters"
    [selected]="filterSelected"
    [switch]="true"
    [elementDisabled]="getElementDisabled()"
    [keyboard]="keyboard$ | async"
  >
  </ticket-filter>
</div>

<div
  #selections
  class="sb-ticket--selections"
  [ngClass]="{ 'background-color-dark': getTicketIsConfirmed(ticket) || isDesktop, 'background-color-regular': !getTicketIsConfirmed(ticket) && !isDesktop, 'selection-max-height': getTicketIsConfirmed(ticket) && !isDesktop }"
  [elementDisabled]="getElementDisabled()"
  [hidden]="accordionHidden"
>
  <!-- Promo -->
  <ticket-bonus *ngIf="!getTicketIsConfirmed(ticket)"> </ticket-bonus>
  <!-- Promo Fin -->
  <p hidden>{{ ticket?.TotalLocal}}</p>

  <div id="ticket-selections" class="background-color-dark">
    <!-- SingleResult -->
    <div *ngFor="let lineItem of ticket?.LineItems; trackBy: trackBy" class="sb-ticket-selection-group">
      <ticket-selection
        [lineItem]="lineItem"
        [mode]="selectionBetType"
        [keyboard]="keyboard$ | async"
        [ticket]="ticket"
        [isTicketClosed]="getTicketIsConfirmed(ticket)"
        [isDesktop]="isDesktop"
        [license]="license"
        [minBetValue]="minBetValue"
        [currency]="currencySign"
        [disabled]="disabled$ | async"
        (scrollTo)="scrollTo($event)"
        (delete)="removeSelection(lineItem)"
        (updateAmount)="updateSingleStake($event)"
        (select)="selectLineItem($event)"
        (unselect)="unselectLineItem()"
      >
        <!-- unselectLineItem does not take arguments -->
      </ticket-selection>
    </div>

    <!-- StraightMulticast -->
    <div *ngFor="let lineItem of ticket?.StraightMulticast; trackBy: trackBy" class="sb-ticket-selection-group">
      <ticket-selection
        [lineItem]="lineItem"
        [mode]="selectionBetType"
        [keyboard]="keyboard$ | async"
        [ticket]="ticket"
        [isTicketClosed]="!!getTicketIsConfirmed(ticket)"
        [isDesktop]="isDesktop"
        [license]="license"
        [minBetValue]="minBetValue"
        [currency]="currencySign"
        (scrollTo)="scrollTo($event)"
        (delete)="removeStraightMulticast(lineItem)"
        (updateAmount)="updateSingleStake($event)"
        (select)="selectLineItem($event)"
        (unselect)="unselectLineItem()"
      >
      </ticket-selection>
    </div>

    <!-- CombinationMulticast -->
    <div *ngFor="let lineItem of ticket?.CombinationMulticast; trackBy: trackBy" class="sb-ticket-selection-group">
      <ticket-selection
        [lineItem]="lineItem"
        [mode]="selectionBetType"
        [keyboard]="keyboard$ | async"
        [ticket]="ticket"
        [isTicketClosed]="!!getTicketIsConfirmed(ticket)"
        [isDesktop]="isDesktop"
        [license]="license"
        [minBetValue]="minBetValue"
        [currency]="currencySign"
        (scrollTo)="scrollTo($event)"
        (delete)="removeCombinationItem(lineItem)"
        (updateAmount)="updateSingleStake($event)"
        (select)="selectLineItem($event)"
        (unselect)="unselectLineItem()"
      >
      </ticket-selection>
    </div>

    <div *ngFor="let smartLineItem of ticket?.SmartLineItems; trackBy: trackBy" class="sb-ticket-selection-group">
      <ticket-betbuilder
        [smartLineItem]="smartLineItem"
        [mode]="selectionBetType"
        [keyboard]="keyboard$ | async"
        [ticket]="ticket"
        [isTicketClosed]="!!getTicketIsConfirmed(ticket)"
        [isDesktop]="isDesktop"
        [license]="license"
        [minBetValue]="minBetValue"
        [currency]="currencySign"
        [disabled]="disabled$ | async"
        (scrollTo)="scrollTo($event)"
        (deleteSmartMarket)="removeSmartMarketSelection(smartLineItem)"
        (updateAmount)="updateSingleStake($event)"
        (select)="selectLineItem($event)"
        (unselect)="unselectLineItem()"
      >
      </ticket-betbuilder>
    </div>
  </div>
  <!-- End selections -->

  <!-- End Ticket resume content -->
  <div *ngIf="!getTicketIsConfirmed(ticket)" class="sb-ticket--delete">
    <ion-button class="sportsbook btn-transparent color-dark" (click)="clearTicket()" [disabled]="disabled$ | async">
      <ion-label class="sb-ticket--button-title text-uppercase"> {{'v2_eliminarTodo' | translate}} </ion-label>
      <i class="codere-icon icon-close color-dark icon-left"></i>
    </ion-button>
  </div>
</div>

<div
  *ngIf="!getTicketIsConfirmed(ticket) && getNumberOfSelections(ticket) > 0 && ticket?.OveraskInfo?.Status == 'OK'"
  class="sb-ticket--footer"
>
  <div *ngIf="ticket?.BetType === 2" class="sb-ticket--system">
    <codere-grid-filter
      [collapsed]="multiBetCollapsed"
      [bgColor]="'background-color-light'"
      [currentEvent]="systemSelected"
      [isTicket]="true"
      [isDesktop]="isDesktop"
      [blocked]="possibleSystems?.length < 1"
      (select)="toggleMultipleBetOptions($event)"
    >
      <ul class="sb-ticket-multiselection--list">
        <li
          class="sb-grid-filter--item sb-ticket-multiselection--title color-dark"
          *ngFor="let option of possibleSystems"
          tappable
          (click)="changeInMultipleBetOptions(option)"
        >
          {{ option?.text }}
        </li>
      </ul>
    </codere-grid-filter>
  </div>
  <div class="sb-ticket--actions background-color-dark border-color-light box-shadow">
    <div class="sb-ticket--row-toggle">
      <div *ngIf="!isDesktop" class="sb-ticket--toggle"></div>

      <div class="sb-ticket--toggle" [hidden]="hideFreebetToggle()">
        <span *ngIf="isDesktop" class="sb-ticket--toggle-title color-light"> {{'fb' | translate}} </span>
        <ion-toggle
          [ngClass]="{'sb-ticket--toggle-icon-opacity': disabled$ | async}"
          class="sb-ticket--toggle-icon"
          #freebetToggle
          (ionChange)="toggleFreebet()"
        >
        </ion-toggle>
        <span *ngIf="!isDesktop" class="sb-ticket--toggle-title color-light"> {{'fb' | translate}} </span>
      </div>

      <div *ngIf="!getTicketIsConfirmed(ticket) && isDesktop" class="sb-ticket--delete">
        <ion-button
          class="sportsbook btn-transparent btn-auto-height"
          (click)="clearTicket()"
          [disabled]="disabled$ | async"
        >
          <ion-label class="sb-ticket--button-title with-right-icon"> {{'v2_eliminarTodo' | translate}} </ion-label>
          <i class="codere-icon icon-close color-light"></i>
        </ion-button>
      </div>
    </div>
    <div class="sb-ticket--row-toggle" *ngIf="totalOddsEnabled && ticket.BetType === 1">
      <div *ngIf="!isDesktop" class="sb-ticket--toggle"></div>

      <ion-chip class="sportsbook btn-primary">{{this.numberOfSelections}}</ion-chip>
      <span style="color: white">Selecciones</span>
      <div style="color: white; font-size: 14px; margin-right: 10px" class="sb-ticket--delete">
        {{ 'hOdd' | translate }} {{ cuota | formatOdd }}
      </div>
    </div>

    <div class="sb-ticket--row-final">
      <!-- STAKE PLACEMENT AND BET BUTTON -->
      <section class="ticket-button--wrapper" *ngIf="!callHasErrors(ticket) && !ticket?.hasChangesInOdds">
        <ion-grid class="ticket-button--grid" [ngClass]="{'desktop-grid': !isMobile}">
          <ion-row>
            <ion-col
              sizeXl="6"
              [size]="isMobile ? '4' : '6'"
              class="sportsbook ticket-button--col left"
              [ngClass]="{ 'is-mobile' : isMobile }"
            >
              <sb-betslip-stake-input
                *ngIf="isDesktop"
                [minBetValue]="minBetValue"
                [ticket]="ticket"
                [currency]="currencySign"
                [finalRow]="ticket?.Total"
                [disabled]="disabled$ | async"
                (update)="checkAndUpdateStake($event)"
                [mode]="''"
              >
              </sb-betslip-stake-input>
              <ion-button
                class="sports-btn btn-light sportsbook stake-button"
                (click)="toggleTotalLocal()"
                [disabled]="disabled$ | async"
                *ngIf="!isDesktop"
              >
                <ion-label>
                  <span *ngIf="false" class="sb-ticket--button-title text-small">
                    {{ 'total' | translate }}: {{ ticket?.TotalPotentialWin.toString() | parseStakeMoney:[1] }}
                  </span>
                  <span
                    class="sb-ticket--button-title text-small"
                    [ngClass]="{ 'text-small' : ticket?.TotalLocal.length > 8 }"
                  >
                    {{ ticket?.TotalLocal | parseStakeMoney:[1] }}
                  </span>
                </ion-label>
              </ion-button>
            </ion-col>
            <ion-col sizeXl="6" [size]="isMobile ? '8' : '6'" class="sportsbook ticket-button--col right">
              <ion-button
                class="sports-btn btn-primary sportsbook"
                [ngClass]="{ 'stake-button': isMobile }"
                (click)="placeBet()"
                [disabled]="disabled$ | async"
              >
                <ion-label>
                  <span class="sb-ticket--button-title text-uppercase"> {{ btnBetText }} </span>
                  <span class="sb-ticket--button-title text-small">
                    {{'hDetailsGan' | translate}}: {{ ticket?.TotalPotentialWin?.toString() | parseStakeMoney:[1]}}
                  </span>
                </ion-label>
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>
      </section>

      <!-- HAS ERRORS -->
      <section class="ticket-button--wrapper" *ngIf="callHasErrors(ticket)">
        <ion-grid class="ticket-button--grid" [ngClass]="{'desktop-grid': !isMobile}">
          <ion-row>
            <ion-col
              sizeXl="6"
              [size]="'6'"
              class="sportsbook ticket-button--col left"
              [ngClass]="{ 'is-mobile' : isMobile }"
            >
              <ion-button class="sports-btn btn-light sportsbook" [disabled]="disabled$ | async">
                <ion-label class="font-weight-bolder">
                  <span class="sb-ticket--button-title text-uppercase"> &#45; </span>
                </ion-label>
              </ion-button>
            </ion-col>
            <ion-col sizeXl="6" [size]="'6'" class="sportsbook ticket-button--col right">
              <ion-button
                class="sports-btn btn-primary sportsbook"
                (click)="acceptErrorAndContinue(errorTicket)"
                [disabled]="disabled$ | async"
              >
                <ion-label class="font-weight-bolder">
                  <span class="sb-ticket--button-title text-small"> {{ errorTicket | translate }} </span>
                </ion-label>
                <ion-icon name="alert-circle-outline"></ion-icon>
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>
      </section>

      <!-- FREEBETS ERRORS -->
      <section class="ticket-button--wrapper" *ngIf="hasFBError()">
        <ion-grid class="ticket-button--grid" [ngClass]="{'desktop-grid': !isMobile}">
          <ion-row>
            <ion-col
              sizeXl="6"
              [size]="'6'"
              class="sportsbook ticket-button--col left"
              [ngClass]="{ 'is-mobile' : isMobile }"
            >
              <ion-button class="sports-btn btn-light sportsbook">
                <ion-label class="font-weight-bolder">
                  <span class="sb-ticket--button-title text-uppercase"> &#45; </span>
                </ion-label>
              </ion-button>
            </ion-col>
            <ion-col sizeXl="6" [size]="'6'" class="sportsbook ticket-button--col right">
              <ion-button class="sports-btn btn-secondary sportsbook">
                <ion-label>
                  <span class="sb-ticket--button-title text-small"> {{ errorFB | translate }} </span>
                </ion-label>
                <ion-icon name="alert-circle-outline"></ion-icon>
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>
      </section>

      <!-- ODD ACCEPTANCE -->
      <section class="ticket-button--wrapper" *ngIf="!callHasErrors(ticket) && ticket?.hasChangesInOdds">
        <ion-grid class="ticket-button--grid" [ngClass]="{'desktop-grid': !isMobile}">
          <ion-row>
            <ion-col
              sizeXl="6"
              [size]="'6'"
              class="sportsbook ticket-button--col left"
              [ngClass]="{ 'is-mobile' : isMobile }"
            >
              <ion-button class="sports-btn btn-transparent with-border sportsbook" (click)="presentOddAcceptance()">
                <i class="codere-icon icon-preferencias color-light icon-right"></i>
                <ion-label class="font-weight-bolder">
                  <span class="sb-ticket--button-title text-uppercase">{{ 'bettingOptions' | translate }}</span>
                </ion-label>
              </ion-button>
            </ion-col>
            <ion-col sizeXl="6" [size]="'6'" class="sportsbook ticket-button--col right">
              <ion-button class="sports-btn btn-primary sportsbook" (click)="acceptErrorAndContinue(null)">
                <ion-label class="font-weight-bolder">
                  <span class="sb-ticket--button-title"> {{ 'tAcepOdd' | translate }} </span>
                </ion-label>
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>
      </section>
    </div>
  </div>
</div>

<!-- CONFIRMATION AND OVERASK -->
<div class="betslip-ticket-confirmed--wrapper">
  <betslip-ticket-confirmed
    [ticket]="ticket"
    [btnBetText]="btnBetText"
    [disabled]="disabled$ | async"
    [status]="ticket?.OveraskInfo?.Status+''"
    (keepSelections)="keepSelections()"
    (closeResume)="closeResume()"
    (clearTicket)="clearTicket()"
    (placeBet)="placeBet()"
  >
  </betslip-ticket-confirmed>
</div>
<!-- Ticket resume footer -->

<lb-play-out [page]="'Sports'" [hide]="hideGames"></lb-play-out>
