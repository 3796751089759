export interface I_MyBetsModel {
  DayOrderNr: number;
  Tickets: I_MyBetsModelTicket[];
  TicketsDatetime: string;
}

export interface I_MyBetsModelTicket {
  BetEvent: I_MyBetsModelTicketBetEvent[];
  BetsNumber: number;
  BetType: string;
  BetTypeNumber: string;
  CancelationReason: string | null;
  CashOutAmount: number;
  CashOutStatus: number;
  IsPushWinning: false;
  HasBetbuilder?: boolean;
  Pin: string;
  PotentialWinnings: number;
  PotentialWinningsOriginal: number;
  Stake: number;
  StatusCode: number;
  TicketDatetime: string;
  TicketNumber: number;
  TicketOrderNr: number;
  TicketStatus: string;
  TicketStatusNumber: number;
  VoucherCode: string | null;
  X: number;
  Y: number;
}

export interface I_MyBetsModelTicketBetEvent {
  ClearingStatus: string;
  ClearingStatusNumber: string;
  CountryName: string;
  EventDatetime: string;
  EventId: number;
  EventName: string;
  IsBank: boolean;
  LeagueName: string;
  MarketName: string;
  Odd: number;
  ResultName: string;
  ResultsNr: number;
  SportName: string;
  IsBetbuilder?: boolean;
  BetBuilderItems?: I_MyBetsModelTicketBetEventWBetBuilder[];
}

export interface I_MyBetsModelTicketBetEventWBetBuilder {
  ClearingStatus: string;
  ClearingStatusNumber: string;
  CountryName: string;
  EventDatetime: string;
  EventId: number;
  EventName: string;
  IsBank: boolean;
  LeagueName: string;
  MarketName: string;
  Odd: number;
  ResultName: string;
  ResultsNr: number;
  SportName: string;
}

export interface I_BetEvent {
  EventId: number;
  [key: string]: any;
}

export interface I_MyBetsFilters {
  value: string;
  text: string;
  selected: boolean;
}

export interface I_FetchMyBetsParams {
  betType: string;
  date: string;
  endDate: string;
}

export interface I_CashoutParams {
  DayOrderNr: number;
  Ticket: any;
  TicketNumber: string;
  Pin: string;
}

export interface I_CashoutData {
  CashOutAmount: number;
  CashOutStatu: number;
}
