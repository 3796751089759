interface LeftMenuSport {
  Name: string;
  NodeId: string;
  ParentNodeId: string;
  Priority: number;
  SportHandle: string;
  Locked: boolean;
}

interface LeftMenuHighlight {
  CountryCode: string | null;
  SportsHandle: string;
  Name: string;
  NodeId: string;
  ParentNodeId: string;
  Priority: number;
  SportHandle: string;
  Locked: boolean;
}

interface LeftMenuHighlightConfig {
  LeagueName: string;
  IconUrl: string;
  IdLeagues: string;
  SportName: string;
  SportHandle: string;
  CountryCode: string;
}

export interface I_LeftMenu {
  sports: LeftMenuSport[];
  highlights: LeftMenuHighlight[];
  highlightsConfig: LeftMenuHighlightConfig[];
}
