import { NavController, ModalController, IonicModule, IonContent } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ViewChild, Component, inject } from '@angular/core';
import { DeviceService } from '../../../services/device.service';
import { GlobalVarsModel } from '../../../models/ModelGlobalsVar';
import { NewBaseService } from '../../../providers/newBase.service';
import { Utils } from '../../../utils/Utils';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { UserService } from '../../../providers/UserService';
import { EventsService } from '../../../providers/EventsService';
import { C_DepositPaymentDateList } from '../../../models/C_Deposits';
import { TrackingService } from '../../../providers/TrackingService';
import { HistoryTicket } from '../../../components/common/history-ticket/history-ticket';
import { EmptyState } from '../../../components/payments/empty-state/empty-state';
import { UserSectionTitle } from '../../../components/mso/user-section-title/user-section-title';
import { CodereFooterComponent } from '../../../components/mso/footer/codere-footer/codere-footer';
import { CodereNavbar } from '../../../components/common/codere-navbar/codere-navbar';

@Component({
  standalone: true,
  selector: 'charge-history-page',
  templateUrl: './charge-history.html',
  styleUrls: ['./charge-history.scss'],
  imports: [
    IonicModule,
    CommonModule,
    TranslateModule,
    HistoryTicket,
    EmptyState,
    UserSectionTitle,
    CodereFooterComponent,
    FormsModule,
    CodereNavbar
  ]
})
export class ChargeHistoryPage {
  @ViewChild(IonContent) content: IonContent;
  imageSource: string;
  form: { days: number; state: string };
  arrCharges: Array<C_DepositPaymentDateList>;
  globalVars!: GlobalVarsModel;
  isMobile = false;

  nav = inject(NavController);
  translate = inject(TranslateService);
  utils = inject(Utils);
  trackingService = inject(TrackingService);
  events = inject(EventsService);
  userService = inject(UserService);
  modalCtrl = inject(ModalController);
  newBaseService = inject(NewBaseService);
  deviceService = inject(DeviceService);

  constructor() {
    this.isMobile = this.deviceService.isMobile();
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });

    this.imageSource = 'assets/global/img/cobros.svg';
    this.form = {
      days: 15,
      state: 'Completada'
    };
    this.getMyCharges();
  }

  ionViewWillEnter() {
    this.trackingService.trackEvent(['AccessToCobHistroy', '', '', 'Acceder historial cobros', 'event']);
    this.events.publish('footer:refresh', this.content);
  }

  ionViewDidEnter() {
    this.events.publish('footer:refresh', this.content);
  }

  update() {
    this.getMyCharges();
    this.events.publish('footer:refresh', this.content);
  }

  getMyCharges() {
    this.userService.getPayments(this.form.state, this.form.days).subscribe({
      next: (data) => {
        this.content.scrollToTop();
        this.arrCharges = data;
      },
      error: (err) => {
        this.utils.showError(err);
      }
    });
  }
}
