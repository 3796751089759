<!-- eslint-disable @angular-eslint/template/eqeqeq -->
<ng-container *ngIf="items">
  <div class="itemContainer" [ngClass]="[(additionalClass ?? ''), (globalVars.licenseType === 22 ? 'newImgPa' : '')]">
    <ng-container *ngFor="let item of items">
      <div [ngClass]="item.containerClass">
        <span *ngIf="item.type === 'innerText'" [innerHTML]="item.text" [ngClass]="item.textClass"></span>
        <span *ngIf="item.type === 'text'" [ngClass]="item.textClass">{{item.text}}</span>
        <a
          *ngIf="item.type === 'text-link'"
          [href]="sanitize(item.href)"
          [target]="item.target"
          [ngClass]="item.linkClass"
          (click)="openLink($event,item)"
          ><span [ngClass]="item.textClass">{{item.text}}</span></a
        >
        <a
          *ngIf="item.type === 'innerText-link'"
          [href]="sanitize(item.href)"
          [target]="item.target"
          [ngClass]="item.linkClass"
          (click)="openLink($event,item)"
          ><span [ngClass]="item.textClass" [innerHTML]="item.text"></span
        ></a>
        <img *ngIf="item.type === 'img'" [src]="item.img" [alt]="item.altImg" [ngClass]="item.imgClass" class="img-pa" />
        <a
          *ngIf="item.type === 'img-link'"
          [href]="sanitize(item.href)"
          [target]="item.target"
          [ngClass]="item.linkClass"
          (click)="openLink($event,item)"
          ><img [src]="item.img" [alt]="item.altImg" [ngClass]="item.imgClass"
        /></a>
        <a
          *ngIf="item.type === 'img-text-link'"
          [href]="sanitize(item.href)"
          [target]="item.target"
          [ngClass]="item.linkClass"
          (click)="openLink($event,item)"
        >
          <img [src]="item.img" [alt]="item.altImg" [ngClass]="item.imgClass" />
          <span [ngClass]="item.textClass">{{item.text}}</span></a
        >
        <ng-container *ngIf="item.type =='img-text'">
          <img [src]="item.img" [alt]="item.altImg" [ngClass]="item.imgClass" />
          <span [ngClass]="item.textClass">{{item.text}}</span>
        </ng-container>
      </div>
    </ng-container>
  </div>
</ng-container>
