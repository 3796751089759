/* eslint-disable eqeqeq*/
import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  ElementRef,
  AfterViewInit,
  Renderer2,
  HostListener,
  ChangeDetectorRef,
  OnDestroy,
  ViewChild,
  inject
} from '@angular/core';
import { IonImg, IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import Hls from 'hls.js';
import { Observable } from 'rxjs';
import { CONSTANTS } from '@constants';
import { Game } from '../../../../models/lobby/casinoDataModels';
import { GlobalVarsModel } from '../../../../models/ModelGlobalsVar';
import { DeviceService } from '../../../../services/device.service';
import { NewBaseService } from '../../../../providers/newBase.service';
import { DeferLoadDirective } from '../../../../directives/defer-load/defer-load.directive';
import { SlotName } from '../../../../pipes/casino/lobby/slot-name.pipe';

export interface rouletteNumber {
  number: string;
  class: string;
}

@Component({
  selector: 'casino-live-card-new',
  templateUrl: './casino-live-card-new.html',
  standalone: true,
  styleUrls: ['./casino-live-card-new.scss'],
  imports: [CommonModule, SlotName, IonicModule, DeferLoadDirective]
})
export class CasinoLiveCardNewComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input()
  game: Game; //to change to angular naming convention
  @Input()
  updatedBrJackpot: any = null;
  @Input()
  outstanding = false;
  @Input()
  grid: boolean;
  @Input() setStreaming = true;
  @Output()
  turnPlay: EventEmitter<any> = new EventEmitter();
  @Output()
  height: EventEmitter<number> = new EventEmitter();
  classStreaming = true;
  public animation: string;
  lastTimestamp = 0;
  image: string;
  defaultImg: string;
  baseImage: string;
  isDesktop = false;
  counter$: Observable<any>;
  public playerCount = 0;
  badges;
  numberRank = 0;
  hls: Hls;

  public numbers: rouletteNumber[] = CONSTANTS.rouletteNumber;
  public last;
  public selNumbers: rouletteNumber[] = [];

  public sel0Class: string;
  public sel0Number: string;
  public min = 0; //minimun bet for this table
  public max = 0; //maximun bet for this table
  private maxnumbers: number;
  private ref: HTMLElement;
  get refElement() {
    return this.ref;
  }
  loaded = false;
  inited = false;
  subscription: any;

  @ViewChild('videoPlayer') videoPlayerElRef: ElementRef<HTMLVideoElement>;

  globalVars!: GlobalVarsModel;

  element = inject(ElementRef);
  renderer = inject(Renderer2);
  cd = inject(ChangeDetectorRef);
  newBaseService = inject(NewBaseService);
  deviceService = inject(DeviceService);

  constructor() {
    // * GlovalVars
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });

    this.isDesktop = this.deviceService.isDesktop();

    if (this.globalVars) {
      this.baseImage = this.globalVars.LOBBY_IMAGES;
    }
  }

  changed(liveData) {
    if (!this.game) return;

    this.playerCount = liveData.PlayerCount ? liveData.PlayerCount : 0;

    this.min = liveData.minLimit ? liveData.minLimit : 0;
    this.max = liveData.maxLimit ? liveData.maxLimit : 0;

    if (liveData.replaceNumbers || !this.lastTimestamp) {
      this.updateNumbers(liveData.LastNumbers, liveData.timeStamp);
    }
  }

  ngOnInit() {
    if (this.game && !this.inited) {
      this.setImage();
      if (this.game.lobby !== 'QuickLaunch') {
        this.setBadges();
      }
      this.subscription = this.game.additionalData?.subscribe((liveData) => this.changed(liveData));
      if (this.game.numberRanking) {
        this.numberRank = this.game.numberRanking;
      }
      this.inited = true;
    }
  }

  errorImageHandler(element: IonImg) {
    element.src = this.defaultImg;
  }

  //eslint-disable-next-line
  updateNumbers(numbers, timeStamp) {
    let c: number = 0,
      newnr: string,
      exist: any;
    //first time fill 10 places
    if (!this.lastTimestamp && numbers) {
      this.lastTimestamp = timeStamp;
      const total: number = numbers.length;
      this.selNumbers = [];
      this.maxnumbers = this.game.gameTileSize == 'Bigrectangle' ? 10 : 7;
      if (this.isDesktop) this.maxnumbers = 10; //siempre 10 en desktop
      //fill backwards
      for (let i: number = 0; i <= this.maxnumbers; i++) {
        if (!numbers[i]) break;
        newnr = numbers[i];
        if (this.numbers[newnr]) {
          this.selNumbers.push(this.numbers[newnr]);
          c++;
        }
      }
    }
    //push last if it´s newer
    if (timeStamp > this.lastTimestamp) {
      if (!numbers) return;
      const first: string = numbers[0];
      const nr = this.numbers[first];
      if (!nr) return; //exist and is not repeated
      this.selNumbers.unshift(nr);
      if (this.selNumbers.length > this.maxnumbers) this.selNumbers.pop(); //keep only max, no need to keep the complete list
      this.animation = 'is-animated';
      setTimeout(() => (this.animation = ''), 1500);
    }
  }

  async ngAfterViewInit() {
    if (!this.game) return;
    this.ref = this.element.nativeElement;
    this.setSize();
  }

  @HostListener('mouseover')
  onMouseOver() {
    if (!this.ref) return;
    this.renderer.addClass(this.ref, 'hovered');
  }

  @HostListener('mouseleave')
  onMouseLeave() {
    if (!this.ref) return;
    this.renderer.removeClass(this.ref, 'hovered');
  }

  setSize() {
    if (this.outstanding) return;
    const sizeClass = this.grid ? 'in-grid' : this.game.gameTileSize.toLowerCase();
    this.renderer.addClass(this.ref, sizeClass);
  }

  setImage() {
    let shape = this.game.gameTileSize.toLowerCase().includes('square') ? 'Square' : 'Rectangle';

    if (this.grid) {
      shape = 'Square';
    }

    const imageType = this.game.imageType || 'jpg';
    const image = this.game.Name.replace(/ /g, '') + '_' + shape + `.${imageType}`;

    this.game.Image = this.baseImage + image;
    this.defaultImg = this.baseImage + 'default_' + shape + `.${imageType}`;
  }

  playGame(demo?: boolean) {
    this.turnPlay.emit({ demo: demo, game: this.game });
  }

  isLoaded(loaded: boolean) {
    this.loaded = true;
    this.cd.detectChanges();
  }

  isLoaded2(loaded: boolean) {
    // console.warn('HOLAAAAAAAAAAAAAAAAAAAAAAAA');
    // console.warn(this.game.streamUrl);

    this.loaded = true;
    this.cd.detectChanges();
    setTimeout(() => {
      this.playVideo();
    }, 100);
  }

  setBadges() {
    if (!this.game.Badges) return;
    this.badges = this.game.Badges.map((badge) => badge.toLowerCase());
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.hls) {
      this.hls.stopLoad();
      this.hls.destroy();
    }
  }

  //eslint-disable-next-line
  playVideo() {
    const videoElement = this.videoPlayerElRef.nativeElement;
    if (this.game.gameTileSize == 'Smallsquare') {
      this.classStreaming = true;
    }
    if (Hls.isSupported() && this.game.streamUrl) {
      this.hls = new Hls();
      this.hls.loadSource(this.game.streamUrl);
      this.hls.attachMedia(videoElement);
      this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
        videoElement.muted = true;
        videoElement.volume = 0;
        videoElement.play();
      });
      //error hls
      this.hls.on(Hls.Events.ERROR, (_event, data) => {
        if (data.fatal) {
          switch (data.type) {
            case Hls.ErrorTypes.NETWORK_ERROR:
              // console.warn('fatal network error encountered, try to recover');
              this.hls.startLoad();
              break;
            case Hls.ErrorTypes.MEDIA_ERROR:
              // console.warn('fatal media error encountered, try to recover');
              this.hls.recoverMediaError();
              break;
            default:
              this.setStreaming = false;
              this.classStreaming = false;
              this.hls.stopLoad();
              this.hls.destroy();
              break;
          }
        } else if (data.type === 'mediaError') {
          // console.warn('media error');
          this.hls.recoverMediaError();
        } else if (
          data.details === 'levelLoadTimeOut' ||
          data.details === 'fragLoadError' ||
          data.details === 'levelLoadError'
        ) {
          // console.warn('error grande parar streaming');
          this.hls.stopLoad();
          this.hls.destroy();
          this.setStreaming = false;
          this.classStreaming = false;
        }
      });
    } else if (videoElement.canPlayType('application/vnd.apple.mpegurl') && this.game.streamUrl) {
      videoElement.src = this.game.streamUrl;
      videoElement.muted = true;
      videoElement.volume = 0;
      videoElement.play();
      //error native elemet
      videoElement.addEventListener('error', (_event) => {
        this.setStreaming = false;
        this.classStreaming = false;
        // console.warn(event, 'error video');
      });
      videoElement.addEventListener('pause', (_event) => {
        videoElement.play();
        // console.warn(event, 'video pausado');
      });
    } else {
      this.setStreaming = false;
      this.classStreaming = false;
    }
  }
}
