import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { TransactionActionResult } from '../../models/C_Transactions';
import { UserService } from '../UserService';
import { PuntoPagoMethodBehaviour } from './PuntoPago';
import { RedsysCardsMethodBehaviour } from './RedsysCards';
import { GlobalVarsModel } from '../../models/ModelGlobalsVar';
import { NewBaseService } from '../newBase.service';
import { inject } from '@angular/core';
import { ErrorService } from '../ErrorService';
import {
  AlmacenesExitoOxxoBankPaymentMethodBehaviour,
  AztecaBankOxxoBankPaymentMethodBehaviour,
  BalotoOxxoBankPaymentMethodBehaviour,
  BanamexOxxoBankPaymentMethodBehaviour,
  BancolombiaBankOxxoBankPaymentMethodBehaviour,
  BancomerOxxoBankPaymentMethodBehaviour,
  CarullaOxxoBankPaymentMethodBehaviour,
  CucutaOxxoBankPaymentMethodBehaviour,
  GanaBankOxxoBankPaymentMethodBehaviour,
  GanaCercaBankOxxoBankPaymentMethodBehaviour,
  MasterCardOxxoBankPaymentMethodBehaviour,
  PagoTodoOxxoBankPaymentMethodBehaviour,
  PaynetOxxoBankPaymentMethodBehaviour,
  SantanderOxxoBankPaymentMethodBehaviour,
  ToditoOxxoBankPaymentMethodBehaviour,
  VisaOxxoBankPaymentMethodBehaviour,
  AmericanExpressOxxoBankPaymentMethodBehaviour,
  BanorteBankPaymentMethodBehaviour,
  SpeiBankPaymentMethodBehaviour
} from './OxxoBank';
import { OxxoCashPaymentMethodBehaviour } from './OxxoCash';
import { PSEOxxoBankPaymentMethodBehaviour } from './PSEOxxoBankPaymentMethodBehaviour';
import { AstropayEPG } from './AstropayEPG';
import { BalotoPayValidaCashPaymentMethodBehaviour, DimonexPayValidaCashPaymentMethodBehaviour } from './PayValida';
import { BankAccountMethodBehaviour } from './BankTransfer';
import { BankcvuMethodBehaviour } from './BankcvuMethodBehaviour';
import { BizumMethodBehaviour } from './Bizum';
import { CoinspaidDepositBehaviour } from './CoinspaidDeposit';
import { CrowCasinoDepositMethodBehaviour } from './CrowCasinoDeposit';
import { EPGMethodBehaviour } from './EPG';
import { EPGMuchBetter } from './EPGMuchBetter';
import { LocalCodereMethodBehaviour } from './LocalCodere';
import { LotbaPaymentMethodBehaviour } from './Lotba';
import { MercadoPagoMethodBehaviour } from './MercadoPago';
import { MercadoPagoRedirectMethodBehaviour } from './MercadoPagoRedirect';
import { NetellerMethodBehaviour } from './Netteler';
import { Pagos360MethodBehaviour } from './Pagos360';
import { Pagos360TransBankMethodBehaviour } from './Pagos360TransBank';
import { PagueloFacilPaymentMethodBehaviour } from './PagueloFacil';
import { PayUPSEPaymentMethodBehaviour, PayUPaymentMethodBehaviour } from './PayU';
import { PaymentMethodBehaviour } from './PaymentMethodBase';
import { PaynetPaymentMethodBehaviour } from './Paynet';
import { PaypalPaymentMethodBehaviour, PaypalPaymentMethodBehaviourResult } from './Paypal';
import { PaysafecardMethodBehaviour } from './PaysafeCard';
import { PuntoRedPayValidaCashPaymentMethodBehaviour } from './PayValida';
import { RapidTransferMethodBehaviour } from './RapidTransfer';
import { RedsysMethodBehaviour } from './BizumRedsys';
import { SKRILLMethodBehaviour } from './Skrill';
import { TelePayPaymentMethodBehaviourCO } from './TelePay';
import { TeleingresoESPaymentMethodBehaviour } from './Teleingreso';
import { TpagaDepositBehaviour } from './TpagaDeposit';
import { TrueLayerMethodBehaviour } from './TrueLayer';
import { VisaBBVAMexPaymentMethodBehaviour } from './VisaBBVAMex';
import { CyberSourceMethodBehaviour } from './CyberSource';

@Injectable({ providedIn: 'root' })
export class PaymentMethodFactory {
  globalVars!: GlobalVarsModel;
  paypalShowErrorCallback: (paymentMethodBehaviourResult: PaypalPaymentMethodBehaviourResult) => any;
  paypalExecuteOkCallback: (transaction: TransactionActionResult) => any;

  newBaseService = inject(NewBaseService);
  errorService = inject(ErrorService);
  userService = inject(UserService);

  public constructor() {
    this.newBaseService.getVars.subscribe((data) => {
      this.globalVars = data;
    });
  }

  initialize(
    paypalShowErrorCallback: (paymentMethodBehaviourResult: PaypalPaymentMethodBehaviourResult) => any,
    paypalExecuteOkCallback: (transaction: TransactionActionResult) => any
  ) {
    this.paypalShowErrorCallback = paypalShowErrorCallback;
    this.paypalExecuteOkCallback = paypalExecuteOkCallback;
  }

  payments(astropayBankMethods: Array<any>): Observable<Array<PaymentMethodBehaviour>> {
    let result: Observable<Array<PaymentMethodBehaviour>> = of([]);
    result = this.userService.getPaymentMethods().pipe(map((x) => x.map((y) => this.map(y, astropayBankMethods))));
    return result;
  }

  private map(input: any, astropayBankMethods: Array<any>): PaymentMethodBehaviour {
    let result: PaymentMethodBehaviour = null;

    if (input.PaymentMethodName == 'EPG') {
      result = new EPGMethodBehaviour(this.userService, this.errorService);
    }
    if (input.PaymentMethodName == 'OxxoBank-Paynet') {
      const oxxobank = new PaynetOxxoBankPaymentMethodBehaviour(this.userService, this.errorService);
      result = oxxobank;
    }
    if (input.PaymentMethodName == 'PAYSAFE') {
      result = new PaysafecardMethodBehaviour(this.userService, this.errorService);
    }
    if (input.PaymentMethodName == 'Bizum') {
      result = new BizumMethodBehaviour(this.userService, this.errorService);
    }
    // Redsys implementation
    if (input.PaymentMethodName == 'RedsysCards') {
      result = new RedsysCardsMethodBehaviour(this.userService, this.errorService);
    }
    if (input.PaymentMethodName == 'RedsysBizum') {
      result = new RedsysMethodBehaviour(this.userService, this.errorService);
    }
    if (input.PaymentMethodName == 'PAYPAL') {
      result = new PaypalPaymentMethodBehaviour(
        this.userService,
        this.paypalShowErrorCallback,
        this.paypalExecuteOkCallback,
        this.errorService
      );
    }
    if (input.PaymentMethodName == 'TRANSF') {
      result = new BankAccountMethodBehaviour(this.userService, this.errorService);
    }
    if (input.PaymentMethodName == 'TELEINGRESOES') {
      result = new TeleingresoESPaymentMethodBehaviour(this.userService, this.errorService);
    }
    if (input.PaymentMethodName == 'TELEPAYCO') {
      result = new TelePayPaymentMethodBehaviourCO(this.userService, false, this.errorService);
    }
    if (input.PaymentMethodName == 'BALOTO') {
      // merge in master is comented, please check
      const resultBaloto = new BalotoPayValidaCashPaymentMethodBehaviour(this.userService, false, this.errorService);
      result = resultBaloto;
    }
    if (input.PaymentMethodName == 'EFECTY') {
      const resultDimonex = new DimonexPayValidaCashPaymentMethodBehaviour(this.userService, false, this.errorService);
      result = resultDimonex;
    }
    if (input.PaymentMethodName == 'PUNTORED') {
      const resultPuntoRed = new PuntoRedPayValidaCashPaymentMethodBehaviour(
        this.userService,
        false,
        this.errorService
      );
      result = resultPuntoRed;
    }
    if (input.PaymentMethodName == 'OXXOCash') {
      const oxxoCash = new OxxoCashPaymentMethodBehaviour(this.userService, this.errorService);
      result = oxxoCash;
    }
    if (input.PaymentMethodName == 'OxxoBank-AmericanExpress') {
      const oxxobank = new AmericanExpressOxxoBankPaymentMethodBehaviour(this.userService, this.errorService);
      result = oxxobank;
    }
    if (input.PaymentMethodName == 'OxxoBank-Baloto') {
      const oxxobank = new BalotoOxxoBankPaymentMethodBehaviour(this.userService, this.errorService);
      result = oxxobank;
    }
    if (input.PaymentMethodName == 'OxxoBank-PSE') {
      const oxxobankResult = new PSEOxxoBankPaymentMethodBehaviour(this.userService, 'PC', this.errorService);
      result = oxxobankResult;
    }
    if (input.PaymentMethodName == 'DaviplataPSE') {
      const oxxobankResult = new PSEOxxoBankPaymentMethodBehaviour(this.userService, 'PC', this.errorService);
      result = oxxobankResult;
    }
    if (input.PaymentMethodName == 'NequiPSE') {
      const oxxobankResult = new PSEOxxoBankPaymentMethodBehaviour(this.userService, 'PC', this.errorService);
      result = oxxobankResult;
    }
    if (input.PaymentMethodName == 'OxxoBank-Spei') {
      const spei = new SpeiBankPaymentMethodBehaviour(this.userService, this.errorService);
      result = spei;
    }
    if (input.PaymentMethodName == 'VisaBancomer') {
      const visaBancomer = new VisaBBVAMexPaymentMethodBehaviour(this.userService, this.errorService);
      result = visaBancomer;
    }
    if (input.PaymentMethodName == 'OxxoBank-Bancomer') {
      const oxxobank = new BancomerOxxoBankPaymentMethodBehaviour(this.userService, this.errorService);
      result = oxxobank;
    }
    if (input.PaymentMethodName == 'OxxoBank-Banamex') {
      const oxxobank = new BanamexOxxoBankPaymentMethodBehaviour(this.userService, this.errorService);
      result = oxxobank;
    }
    if (input.PaymentMethodName == 'OxxoBank-Santander') {
      const oxxobank = new SantanderOxxoBankPaymentMethodBehaviour(this.userService, this.errorService);
      result = oxxobank;
    }
    if (input.PaymentMethodName == 'OxxoBank-Banorte') {
      const oxxobank = new BanorteBankPaymentMethodBehaviour(this.userService, this.errorService);
      result = oxxobank;
    }
    if (input.PaymentMethodName == 'OxxoBank-Todito') {
      const oxxobank = new ToditoOxxoBankPaymentMethodBehaviour(this.userService, this.errorService);
      result = oxxobank;
    }
    if (input.PaymentMethodName == 'OxxoBank-GanaCercaBank') {
      const oxxobank = new GanaCercaBankOxxoBankPaymentMethodBehaviour(this.userService, this.errorService);
      result = oxxobank;
    }
    if (input.PaymentMethodName == 'OxxoBank-AztecaBank') {
      const oxxobank = new AztecaBankOxxoBankPaymentMethodBehaviour(this.userService, this.errorService);
      result = oxxobank;
    }
    if (input.PaymentMethodName == 'carulla') {
      const oxxobank = new CarullaOxxoBankPaymentMethodBehaviour(this.userService, this.errorService);
      result = oxxobank;
    }
    if (input.PaymentMethodName == 'OxxoBank-GanaBank') {
      const oxxobank = new GanaBankOxxoBankPaymentMethodBehaviour(this.userService, this.errorService);
      result = oxxobank;
    }
    if (input.PaymentMethodName == 'OxxoBank-AlamacenesExitoBank') {
      const oxxobank = new AlmacenesExitoOxxoBankPaymentMethodBehaviour(this.userService, this.errorService);
      result = oxxobank;
    }
    if (input.PaymentMethodName == 'OxxoBank-Pagotodo') {
      const oxxobank = new PagoTodoOxxoBankPaymentMethodBehaviour(this.userService, this.errorService);
      result = oxxobank;
    }
    if (input.PaymentMethodName == 'OxxoBank-Cucuta') {
      const oxxobank = new CucutaOxxoBankPaymentMethodBehaviour(this.userService, this.errorService);
      result = oxxobank;
    }
    if (input.PaymentMethodName == 'PAYU') {
      const payU = new PayUPaymentMethodBehaviour(this.userService, this.errorService);
      result = payU;
    }
    if (input.PaymentMethodName == 'PAYUPSE') {
      const payUpsE = new PayUPSEPaymentMethodBehaviour(this.userService, this.errorService);
      result = payUpsE;
    }
    if (input.PaymentMethodName == 'Paynet') {
      const paynet = new PaynetPaymentMethodBehaviour(this.userService, this.errorService);
      result = paynet;
    }
    if (input.PaymentMethodName == 'SKRILL') {
      result = new SKRILLMethodBehaviour(this.userService, this.errorService, this.newBaseService);
    }
    if (input.PaymentMethodName == 'CYBERSOURCE') {
      result = new CyberSourceMethodBehaviour(this.userService, this.errorService, this.newBaseService);
    }
    if (input.PaymentMethodName == 'RAPIDTRANSFER') {
      result = new RapidTransferMethodBehaviour(this.userService, this.errorService);
    }
    if (input.PaymentMethodName == 'NETELLER') {
      result = new NetellerMethodBehaviour(this.userService, this.errorService, this.newBaseService);
    }
    if (input.PaymentMethodName == 'LOCALCODERE') {
      result = new LocalCodereMethodBehaviour(this.userService, this.errorService);
    }
    if (input.PaymentMethodName == 'PUNTOPAGO') {
      result = new PuntoPagoMethodBehaviour(this.userService, this.errorService);
    }
    if (input.PaymentMethodName == 'OxxoBank-Bancolombia') {
      const oxxobank = new BancolombiaBankOxxoBankPaymentMethodBehaviour(this.userService, this.errorService);
      result = oxxobank;
    }
    if (input.PaymentMethodName == 'AstropayEPG') {
      result = new AstropayEPG(this.userService, this.errorService);
    }
    if (input.PaymentMethodName == 'OxxoBank-Visa') {
      const oxxobank = new VisaOxxoBankPaymentMethodBehaviour(this.userService, this.errorService);
      result = oxxobank;
    }
    if (input.PaymentMethodName == 'OxxoBank-MasterCard') {
      const oxxobank = new MasterCardOxxoBankPaymentMethodBehaviour(this.userService, this.errorService);
      result = oxxobank;
    }
    if (input.PaymentMethodName == 'MercadoPago') {
      result = new MercadoPagoMethodBehaviour(this.userService, this.errorService);
    }
    if (input.PaymentMethodName == 'MercadoPagoRedirect') {
      result = new MercadoPagoRedirectMethodBehaviour(this.userService, this.errorService);
    }
    if (input.PaymentMethodName == 'MercadoPagoRedirectCards') {
      result = new MercadoPagoRedirectMethodBehaviour(this.userService, this.errorService, true);
    }
    if (input.PaymentMethodName == 'Pagos360') {
      result = new Pagos360MethodBehaviour(this.userService, this.errorService);
    }
    if (input.PaymentMethodName == 'PagosTrans360') {
      result = new Pagos360TransBankMethodBehaviour(this.userService, this.errorService);
    }
    if (input.PaymentMethodName == 'TPAGA-DEPOSIT') {
      const tpagadeposit = new TpagaDepositBehaviour(this.userService, this.errorService);
      result = tpagadeposit;
    }
    if (input.PaymentMethodName == 'COINSPAID') {
      const coinspaideposit = new CoinspaidDepositBehaviour(this.userService, this.errorService);
      result = coinspaideposit;
    }
    if (input.PaymentMethodName == 'MuchBetter') {
      result = new EPGMuchBetter(this.userService, this.errorService);
    }
    if (input.PaymentMethodName == 'cvuBank') {
      result = new BankcvuMethodBehaviour(this.userService, this.errorService);
    }
    if (input.PaymentMethodName == 'PAGUELOFACIL') {
      result = new PagueloFacilPaymentMethodBehaviour(this.userService, this.errorService);
    }
    if (input.PaymentMethodName == 'TrueLayer') {
      result = new TrueLayerMethodBehaviour(this.userService, this.errorService);
    }
    if (input.PaymentMethodName == 'CrownCasinoDeposit') {
      result = new CrowCasinoDepositMethodBehaviour(this.userService, this.errorService);
    }
    if (input.PaymentMethodName == 'Lotba') {
      result = new LotbaPaymentMethodBehaviour(this.userService, this.errorService);
    }
    if (result != null) {
      result.name = input.Name;
      result.image = input.Image;
      result.track = input.Track;
      result.minDepositAmount = input.minDepositAmount;
      result.maxDepositAmount = input.maxDepositAmount;
      result.amountButtons = input.amountButtons;
      result.amount = input.amount;
      result.isRecommended = input.isRecommended || false;
    }
    return result;
  }
}
