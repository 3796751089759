<ion-segment
  [scrollable]="true"
  [value]="selectedItem?.SportHandle"
  [ngClass]="{ 'no-selection': selectedItem?.SportHandle == '' }"
  mode="md"
>
  <ion-segment-button
    [value]="item?.SportHandle"
    *ngFor="let item of items; let index = index"
    (click)="onClick(item)"
    [disabled]="isProcessing"
  >
    <ion-icon *ngIf="item?.SportHandle" class="icon codere-icon" [ngClass]="getIconClass(item?.SportHandle)"></ion-icon>
    <img class="sb-icon-bar--img" *ngIf="item?.img" [src]="item?.img" [alt]="item?.Name" />
    <ion-label class="sb-icon-bar--item-title">{{ item?.Name }}</ion-label>
  </ion-segment-button>
</ion-segment>
