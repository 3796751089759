import { Action, ActionReducer, ActionReducerMap } from "@ngrx/store";

// import * as fromBetslip from "@sports-stores/betslip/index";
import * as fromMyBets from "@sports-stores/my-bets/index";
import * as fromSidebar from "@sports-stores/sidebar/index";
import * as fromSportsbook from "@sports-stores/sportsbook/index";
import * as fromTicket from "@sports-stores/ticket/index";

export interface AppState {
  // betslip: fromBetslip.BetslipState;
  sidebar: fromSidebar.sidebarState;
  ticket: fromTicket.ticketState;
  sportsbook: fromSportsbook.SportsbookState;
  myBets: fromMyBets.MyBetsState;
}

export const appReducer: ActionReducerMap<AppState> = {
  // betslip: fromBetslip.BetslipReducer as ActionReducer<fromBetslip.BetslipState, Action>,
  ticket: fromTicket.ticketReducer as ActionReducer<fromTicket.ticketState, Action>,
  sidebar: fromSidebar.sidebarReducer as ActionReducer<fromSidebar.sidebarState, Action>,
  sportsbook: fromSportsbook.SportsbookReducer as ActionReducer<fromSportsbook.SportsbookState, Action>,
  myBets: fromMyBets.myBetsReducer as ActionReducer<fromMyBets.MyBetsState, Action>,
};
