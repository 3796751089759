<!-- VOLVER -->
<button (click)="goToStep(1)" class="cs-action-button pos-absolute" *ngIf="step === 2" tappable>
  <i class="color-light codere-icon icon-arrow-left"></i>
  <span class="cs-action-button--text color-light"> Volver </span>
</button>

<!-- STEP 1 -->
<div class="step1-truelayer p-1 background-color-black" *ngIf="step === 1" #containerHeight>
  <div class="w-100">
    <p class="instant-alert-text" *ngIf="isSpain">{{ 'paymentProcessTime' | translate }}</p>
    <div *ngIf="verifiedAccounts > 0">
      <span class="step1-truelayer--accounts--text" *ngIf="!showVerificarButton">
        Deposita fondos directamente desde tu cuenta bancaria de forma inmediata, fácil y sin comisión.
      </span>
      <ion-list class="bg-list" *ngIf="accountList?.length">
        <br />
        <span class="step1-truelayer--accounts--text"> Selecciona una cuenta bancaria: </span>
        <ion-item>
          <ion-label class="color-score">Selecciona una cuenta:</ion-label>
          <ion-select [(ngModel)]="selectedAccount" (ionChange)="selectAccount($event)">
            <ion-select-option value="newAccount"> Verificar nueva cuenta </ion-select-option>
            <ion-select-option *ngFor="let account of verifiedAccountList, index as i" value="{{account.iban}}">
              {{ account.display_name }} - {{ account.ofuscateIban | obfuscatebanks}}
            </ion-select-option>
          </ion-select>
        </ion-item>
      </ion-list>
    </div>

    <div class="step1-truelayer--calculator" *ngIf="verifiedAccounts > 0 && !showVerificarButton" #calculatorCont>
      <cs-calculator
        (setInputKeyboardTrueLayer)="setInputKeyboardTrueLayer()"
        [options]="calculatorConfig"
        [paymentSelected]="paymentSelected"
        [isActiveKeyboard]="isActiveKeyboard"
        [amount]="paymentForm?.get('amount')?.value"
        (amountSelected)="amountSelected($event)"
        (setCustomAmount)="setCustomAmount($event)"
        (inputAmount)="inputAmount($event)"
      >
        [blockCalculator]="blockCalculator">
      </cs-calculator>
    </div>

    <span class="step1-truelayer--accounts--text" *ngIf="verifiedAccounts === 0">
      Conecta y verifica tu cuenta bancaria de forma fácil y segura.
    </span>
    <button
      *ngIf="(verifiedAccounts >= 0 && verifiedAccounts < 5) && showVerificarButton "
      (click)="verifyAccount()"
      class="cs-action-button background-color-secondary"
      tappable
    >
      <span class="cs-action-button--text color-light"> {{ 'check' | translate }} </span>
    </button>
  </div>

  <button
    [disabled]="isActiveKeyboard || !paymentForm?.valid || verifiedAccounts === 0 || selectedAccount === ''"
    (click)="deposit()"
    class="cs-action-button background-color-secondary step1-truelayer--action-button"
    *ngIf="verifiedAccounts > 0 && !showVerificarButton"
    tappable
  >
    <span class="cs-action-button--text color-light"> {{ 'uPCDep' | translate }} </span>
  </button>
  <br />
  <span class="step1-truelayer--accounts--text" *ngIf="!showVerificarButton">
    Al dar clic en "Depositar" está permitiendo que TrueLayer inicie un pago desde su cuenta bancaria. También acepta
    los términos de usuario final y la política de privacidad de TrueLayer Ireland.
  </span>
</div>

<!-- STEP 2 -->
<div class="step2-truelayer p-1 background-color-black w-100" *ngIf="step === 2">
  <span class="step2-truelayer--banks--text"> Selecciona dónde tengas cuenta para verificar: </span>

  <ion-grid>
    <ion-row>
      <ion-col
        class="step2-truelayer--ion-col"
        col-4
        *ngFor="let bank of bankList$ | async"
        (click)="selectBank(bank.provider_id)"
      >
        <div class="step2-truelayer--ion-col-img-wrap" [ngClass]="{ 'selected': selectedBank === bank.provider_id }">
          <ion-avatar>
            <img class="step2-truelayer--ion-col-img-wrap-img" src="{{bank.logo_url}}" alt="{{bank.display_name}}" />
          </ion-avatar>
        </div>
      </ion-col>
      <!-- Mock bank -->
      <ion-col class="step2-truelayer--ion-col" col-4 (click)="selectBank('uk-ob-all%20uk-oauth-all%20uk-cs-mock')">
        <div
          class="step2-truelayer--ion-col-img-wrap"
          [ngClass]="{ 'selected': selectedBank === 'uk-ob-all%20uk-oauth-all%20uk-cs-mock' }"
        >
          <ion-avatar>
            <img
              class="step2-truelayer--ion-col-img-wrap-img"
              src="{{bankList$[0]?.logo_url}}"
              alt="{{bankList$[0]?.display_name}}"
            />
          </ion-avatar>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>

  <button
    [disabled]="selectedBank === ''"
    (click)="verifyAccount()"
    class="cs-action-button background-color-secondary"
    tappable
  >
    <span class="cs-action-button--text color-light"> {{ 'check' | translate }} </span>
  </button>
</div>
