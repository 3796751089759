<ion-content>
  <div *ngIf="isPlaying" style="text-align: center; margin-top: 10%; font-size: 1.3em">
    <b>Disfrute de la experiencia</b><br />
    <span class="margin15"
      >Juegue en su local como en su m&oacute;vil y no olvide cerrar sesi&oacute;n tras terminar el juego</span
    ><br />
    <img style="width: 50%; margin-top: 10%" src="assets/global/img/screens.png" alt="" />
  </div>
  <form [formGroup]="formNumber" *ngIf="!isPlaying">
    <div *ngIf="globalVars.DEVICE_TYPE!=='web'" class="introReg" [innerHTML]="'playsst01' | translate">
      <!--Para poder jugar a <b>Slots</b> o <b>Casino</b> escanea el c&oacute;digo de barras o introduce el n&uacute;mero de forma manual.-->
    </div>

    <ion-list *ngIf="globalVars.DEVICE_TYPE!=='web'" class="ticket-list">
      <div class="boxDeposit">
        <p>{{'scancode' | translate | uppercase}}</p>
        <ion-button expand="full" (click)="scanTicket()">
          <i class="icon-scan2"></i>
          {{'scan' | translate}}
        </ion-button>
      </div>
    </ion-list>

    <ion-list class="ticket-list">
      <ion-list-header class="ticket-list-header has-no-background">
        {{'playsst02' | translate}}
        <!--INTRODUCE EL NÚMERO DE TICKET-->
      </ion-list-header>
      <div
        class="myWrapInput class depTicket-number"
        [class.has-error]="(!formNumber.controls['number'].valid && formNumber.controls['number'].touched)"
      >
        <ion-item
          class="cdr-item"
          [ngClass]="{'error': !formNumber.controls['number'].valid && formNumber.controls['number'].touched}"
        >
          <ion-input
            label="{{'playsst04' | translate}}"
            label-placement="floating"
            type="number"
            [(ngModel)]="model.number"
            [formControl]="$any(formNumber.controls['number'])"
            class="cdr-input-floating"
          ></ion-input>
        </ion-item>
        <div
          [hidden]="formNumber.controls['number'].valid || !formNumber.controls['number'].touched"
          class="labelError"
        >
          <span> {{'playsst03' | translate}}<br /> </span>
        </div>
      </div>

      <div class="sendForm">
        <ion-button expand="full" (click)="sendCode()">{{'tAccept' | translate}}</ion-button>
      </div>
    </ion-list>
  </form>

  <codere-footer></codere-footer>
</ion-content>
