<ng-container *ngIf="!isMobile; else mobileView">
  <ion-header class="has-titleBar">
    <user-section-title
      [sectionTitle]="'promotions'"
      (click)="openPromotions()"
      class="titlecursor"
    ></user-section-title>
  </ion-header>

  <ion-content>
    <ng-container *ngIf="scrHelp">
      <iframe #iframe class="iframeFullScreen" [src]="scrHelp"></iframe>
    </ng-container>
    <codere-footer></codere-footer>
  </ion-content>
</ng-container>

<ng-template #mobileView>
  <ion-header>
    <user-section-title
      [sectionTitle]="'promotions'"
      (click)="openPromotions()"
      class="titlecursor"
    ></user-section-title>
  </ion-header>

  <ion-content *ngIf="scrHelp">
    <iframe #iframe data-tap-disabled="true" class="iframeFullScreen" [src]="scrHelp"></iframe>
    <codere-footer></codere-footer>
  </ion-content>
</ng-template>
