import { Injectable } from "@angular/core";

import { SportHandleList } from "@sports-utils/sport-handle-list";

import * as models from "@sports-models/index";

@Injectable({ providedIn: "root" })
export class livePageUtils {
  getSportNav = (list) => {
    const mapOrder = {
      videolive: 1,
      soccer: 2,
      tennis: 3,
      basketball: 4,
      efootball: 5,
      ebasket: 6,
      table_tennis: 7,
      ice_hockey: 8,
      esports: 9,
      handball: 10,
      volleyball: 11,
      futsal: 12,
      snooker: 13,
      badminton: 14,
    };
    return list
      .map((sporthandle, i) => ({
        Name: SportHandleList[`${sporthandle}Literal`],
        NodeId: "",
        Selected: false,
        SportHandle: sporthandle,
        SportId: "",
        Order: mapOrder[sporthandle] ? mapOrder[sporthandle] : i + Object.keys(mapOrder).length,
      }))
      .sort((a, b) => a.Order - b.Order);
  };

  getOrderedEvents = (sportsNav, currentEvents, sportsNavSelected) => {
    const orderedEventsBySportHandle = {};
    sportsNav.map((sp) => {
      currentEvents.map((ev) => {
        if (ev.SportHandle === sp.SportHandle) {
          if (orderedEventsBySportHandle[ev.SportHandle]) {
            orderedEventsBySportHandle[ev.SportHandle].push(ev);
          } else {
            orderedEventsBySportHandle[ev.SportHandle] = [ev];
          }
        }
      });
    });
    let orderedArrayEventsBySportHandle = [];
    sportsNav.map((sp) => {
      if (orderedEventsBySportHandle[sp.SportHandle])
        orderedArrayEventsBySportHandle = orderedArrayEventsBySportHandle.concat(
          orderedEventsBySportHandle[sp.SportHandle],
        );
    });
    if (sportsNavSelected.SportHandle === SportHandleList.videolive) {
      orderedArrayEventsBySportHandle = orderedArrayEventsBySportHandle.filter(
        (events) => events.StreamingEnabled,
      );
    }
    return orderedArrayEventsBySportHandle;
  };

  getCategories = (events, currentSportHandle) => {
    const categories = [];
    events.forEach((event) => {
      if (!event.LeagueName) return;
      const index = categories.findIndex((category) => category.term === event.CountryCode);
      if (index === -1) {
        const newCategory: models.C_CategoryInfo = new models.C_CategoryInfo(
          event.NodeId,
          false,
          event.CountryName,
          null,
          event.CountryCode,
          event.SportHandle,
          null,
          event.CountryName,
        );
        newCategory.numGames = 1;
        categories.push(newCategory);
        return;
      }
      categories[index].numGames++;
    });
    const all: models.C_CategoryInfo = new models.C_CategoryInfo(
      "99",
      false,
      "Todos",
      null,
      "",
      currentSportHandle,
    );
    categories.unshift(all);
    return categories;
  };

  getSport = (sportTarget, events) => {
    const sport = {
      Name: sportTarget.Name,
      NodeId: sportTarget.NodeId,
      SportHandle: sportTarget.SportHandle,
      SportId: sportTarget.NodeId,
      Leagues: [],
    };
    events.map((eventItem) => {
      const index = sport.Leagues.findIndex((item) => item.LeagueName === eventItem.LeagueName);
      if (index === -1) {
        sport.Leagues.push({
          CountryCode: eventItem.CountryCode,
          Events: [eventItem],
          LeagueName: eventItem.LeagueName,
          ParentNodeId: eventItem.ParentNodeId,
          NodeId: eventItem.NodeId,
        });
      } else {
        sport.Leagues[index].Events.push(eventItem);
      }
    });
    return sport;
  };

  getOddsUpdated = (currentEvents, prevEvents) => {
    if (!prevEvents) return currentEvents;
    const getPrevResult = (events, nodeId) => {
      let result = null;
      for (let i = 0; i < events.length && !result; i++) {
        for (let j = 0; j < events[i].Games.length && !result; j++) {
          for (let k = 0; k < events[i].Games[j].Results.length && !result; k++) {
            if (events[i].Games[j].Results[k].NodeId === nodeId) {
              result = events[i].Games[j].Results[k];
            }
          }
        }
      }
      return result;
    };
    return currentEvents.map((event) => {
      return {
        ...event,
        Games: event.Games.map((game) => {
          return {
            ...game,
            Results: game.Results.map((result) => {
              const prevResult = getPrevResult(prevEvents, result.NodeId);
              return {
                ...result,
                downOdd: prevResult ? result.Odd < prevResult.Odd : false,
                upOdd: prevResult ? result.Odd > prevResult.Odd : false,
              };
            }),
          };
        }),
      };
    });
  };
}
