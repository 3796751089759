import { CommonModule } from "@angular/common";
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  inject,
} from "@angular/core";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { TranslateModule } from "@ngx-translate/core";
import { SlotName } from "@pipes/casino/lobby/slot-name.pipe";
import { ParseMoney } from "@pipes/parseMoney";
import { NewBaseService } from "@providers/newBase.service";
import { JackpotService } from "@services/casino/jackpot.service";
import { DeviceService } from "@services/device.service";
import { BehaviorSubject, Subject, takeUntil } from "rxjs";

@Component({
  selector: "lb-header",
  templateUrl: "./lobby-header.html",
  standalone: true,
  imports: [CommonModule, SlotName, ParseMoney, TranslateModule],
  providers: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ["./lobby-header.scss"],
})
export class LobbyHeaderComponent implements OnInit, OnChanges {
  deviceService = inject(DeviceService);
  @Input() title: string;
  @Input() cta: string;
  @Input() category: any;
  @Input() mobileDetail = false;
  @Output() toggle: EventEmitter<any> = new EventEmitter();
  isJackpotCounter = false;
  jp: "";
  jackpots$: BehaviorSubject<number> = new BehaviorSubject(0);
  subscription: any;
  isDesktop = false;
  globalVars!: GlobalVarsModel;
  private destroy$ = new Subject<void>();

  jackpotService = inject(JackpotService);
  newBaseService = inject(NewBaseService);

  constructor() {
    this.isDesktop = this.deviceService.isDesktop();
    this.newBaseService.getVars.pipe(takeUntil(this.destroy$)).subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
  }

  //eslint-disable-next-line
  ngOnInit() {
    this.jp = this.category.JackpotId;
    if (this.jp) {
      this.subscription = this.category.additionalData.subscribe((data) => this.changed(data));
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.jp = changes["category"].currentValue.JackpotId;
  }

  select(event: any) {
    const seeAll = this.cta === "seeall";
    this.toggle.emit({ name: this.title, seeAll });
    event.preventDefault();
  }

  changed(data) {
    if (data && data.jp && data.timer) {
      this.isJackpotCounter = true;
      this.jackpots$ = this.jackpotService.getTicker(data.jp, data.timer, this.category.Name);
    }
  }
}
