/* eslint-disable */
import { PaymentMethodBehaviour, SkipAmountResult, LotbaPaymentMethodBehaviourResult } from './PaymentMethodBase';
import { Observable } from 'rxjs';
import { UserService } from '@providers/UserService';
import { LotbaDepositActionResult } from '@models/C_Transactions';
import { map } from 'rxjs/operators';
import { ErrorService } from '@providers/ErrorService';

export class LotbaPaymentMethodBehaviour extends PaymentMethodBehaviour {
  minDepositAmount: number;
  maxDepositAmount: number;
  amountButtons: number[];
  name = 'Lotba';
  image = 'LOGOS LOTBA';
  track = 'AccessToLotba';
  isBank = false;
  override amount: number;
  code: string;

  constructor(userService: UserService, errorService: ErrorService) {
    super(false, userService, errorService);
  }
  skipAmountInput(): Observable<SkipAmountResult> {
    throw new Error('Method not implemented.');
  }

  paymentMetodExecution(): Observable<LotbaPaymentMethodBehaviourResult> {
    return this.userService.validateDepositLotba(this.code, this.amount).pipe(
      map((obj) => {
        return this.map(obj);
      })
    );
  }

  private map(input: LotbaDepositActionResult): LotbaPaymentMethodBehaviourResult {
    const result = new LotbaPaymentMethodBehaviourResult();
    if (input.success) {
      result.success = true;
      result.id = input.transactionId;
      result.amount = input.amount;
    } else {
      result.success = false;
      result.erroMessage = this.errorService.searchErrorCodeWithParams('error' + input.errCode, input.errorParameters);
      result.errorcode = input.errCode;
      result.sendToDocumentVerificationPage = input.errCode == 174 || input.errCode == 2212;
    }
    return result;
  }
}
