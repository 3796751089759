<ion-content>
  <div id="taboola"></div>
  <div class="info-section" slot="fixed">
    <cdr-info-top></cdr-info-top>
  </div>
  <div class="content-section">
    <!--  Datos personales -->
    <cdr-fs-personal [formGrp]="formPers" [canShowSuportNumber]="canShowSuportNumber"></cdr-fs-personal>
    <!--  Datos de contacto -->
    <cdr-fs-contacto [formGrp]="formCont" [isResident]="isResident$"> </cdr-fs-contacto>
    <!-- Botón siguiente -->
    <ion-button mode="md" class="btn-register" type="button" expand="block" (click)="onClickNext()">
      {{buttons.next}}
    </ion-button>
  </div>
</ion-content>
