import { VerificationTypes } from "@models/MasterData";

export class VerificationOption {
  type: VerificationTypes;
  text: string;
  status?: string;
  selected: boolean;

  constructor(type: VerificationTypes, text: string, selected: boolean, status?: string) {
    this.type = type;
    this.text = text;
    this.status = status;
    this.selected = selected;
  }
}
