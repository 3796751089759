/* eslint-disable */
import { CommonModule } from '@angular/common';
import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'empty-state',
  templateUrl: './empty-state.html',
  imports: [IonicModule, CommonModule, TranslateModule],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./empty-state.scss']
  /* host: {
    class: 'fade-in'
  } */
})
export class EmptyState {
  @Input() message: string;
  @Input() imageSource: string;
  @Input() subMessage: string;
  @Input() theme: string;
  text: string;

  constructor() {}

  get colorTheme() {
    return this.theme === 'lobby' ? 'color-light' : 'color-dark';
  }
}
