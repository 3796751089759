import { IonicModule, ModalController } from '@ionic/angular';
import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'cashier-halcash-info',
  templateUrl: './cashier-halcash-info.html',
  styleUrls: ['./cashier-halcash-info.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule, TranslateModule]
})
export class CashierHalcashInfoPage {
  modalCtrl = inject(ModalController);

  constructor() {}

  btClose_Click() {
    this.modalCtrl.dismiss();
  }
}
