import { Component, Input } from '@angular/core';

@Component({
  selector: 'sb-grid-content-expanded',
  templateUrl: './sb-grid-content-expanded.html',
  styleUrls: ['./sb-grid-content-expanded.scss'],
  standalone: true
})
export class SbGridContentExpandedComponent {
  @Input() title: string;
  @Input() date: string;
  constructor() {}
}
