/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, ElementRef, Input, type OnInit, Renderer2, inject } from "@angular/core";
import { Aenor } from "@models/footer/aenor";
import { AnchorLink } from "@models/footer/anchor-link";
import { Description } from "@models/footer/description";
import { FooterImage } from "@models/footer/imagen";
import { Item } from "@models/footer/item";
import { TypeRowTextLegal } from "@models/footer/typetextlegal";
import { FooterService } from "@providers/FooterService";
import { UserService } from "@providers/UserService";
import { Utils } from "@utils/Utils";

import { CodereFooterBody } from "../codere-footer-body/codere-footer-body";
import { CodereFooterDeposit } from "../codere-footer-deposit/codere-footer-deposit";
import { CodereFooterDownloadLinks } from "../codere-footer-downloadlinks/codere-footer-downloadlinks";
import { CodereFooterExtraImg } from "../codere-footer-extraImg/codere-footer-extraImg";
import { CodereFooterHeader } from "../codere-footer-header/codere-footer-header";
import { CodereFooterInformation } from "../codere-footer-information/codere-footer-information";
import { CodereFooterLegal } from "../codere-footer-legal/codere-footer-legal";
import { CodereFooterNewImgs } from "../codere-footer-new-imgs/codere-footer-new-imgs";
import { CodereFooterSocials } from "../codere-footer-socials/codere-footer-socials";

import { CommonModule } from "@angular/common";
import { Router } from "@angular/router";
import { IonicModule } from "@ionic/angular";
import { LicenseTypes } from "@models/MasterData";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { PipesModule } from "@pipes/pipes.module";
import { EventsService } from "@providers/EventsService";
import { NewBaseService } from "@providers/newBase.service";
import { DeviceService } from "@services/device.service";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "codere-footer",
  templateUrl: "./codere-footer.html",
  styleUrls: ["./codere-footer.scss"],
  standalone: true,
  imports: [
    CodereFooterBody,
    CodereFooterSocials,
    CodereFooterInformation,
    CodereFooterDownloadLinks,
    CodereFooterHeader,
    CodereFooterLegal,
    CodereFooterDeposit,
    CodereFooterExtraImg,
    CodereFooterNewImgs,
    IonicModule,
    CommonModule,
    PipesModule,
    TranslateModule,
  ],
})
export class CodereFooterComponent implements OnInit {
  isDesktop = false;
  time!: string;
  idInterval: number;
  @Input() space = true;
  @Input() currentPage: string;
  @Input() showFooterSpacer: boolean;

  oldAltoContent = 0;
  globalVars: GlobalVarsModel;
  LicenseTypes = LicenseTypes;
  minFooterHeight = 350;

  isHidden = true;
  urlJRes!: string;

  toolbarCss = "";

  divSocilasCss = "";

  addViewMobile = false;

  downloadLinksDivCss = "";

  bodyClass = "";

  timer = true;

  //**+** socialsLinks: AnchorLink[] = [];
  socialsLinks: any[] = [];

  descriptionHelp!: Description;

  descriptionNavSport!: Description;

  descripcionLinks!: Description;

  description!: Description;

  bodyInformation: Description[] = [];

  headerDownloadLinks: any[] = [];

  imagesHeader: FooterImage[] = [];

  imagesDeposit: FooterImage[] = [];

  rowsLegal: TypeRowTextLegal[] = [];

  colombiaImagesHeader: FooterImage[] = [];

  colombiaImagesHeaderMobile: FooterImage[] = [];

  downloadLinksMobileColombia: AnchorLink[] = [];

  socialsLinksMobileColombia: AnchorLink[] = [];

  newFooterImgs: Item[] = [];

  newSocialsLinks: Item[] = [];

  extraImgsAftDeposit: any[] = [];

  aenor!: Aenor;
  socialMobile = true;
  headersImgMobile = false;
  generalVars: { [key: string]: string } = {};
  licenseVars: { [key: string]: string } = {};

  //#region
  events = inject(EventsService);
  element = inject(ElementRef);
  userService = inject(UserService);
  footerService = inject(FooterService);
  utils = inject(Utils);
  deviceService = inject(DeviceService);
  newBaseService = inject(NewBaseService);
  translate = inject(TranslateService);
  router = inject(Router);
  private _element = inject(ElementRef);
  private _renderer = inject(Renderer2);
  //#endregion
  constructor() {
    this.isDesktop = this.deviceService.isDesktop();
    this.isHidden = true;
    this.newBaseService.getVars.subscribe((data) => {
      this.globalVars = data;
    });
    this.setTime();
    this.idInterval = window.setInterval(() => {
      this.isHidden = false;
      this.updatePosFooter();
      this.setTime();
    }, 300);

    this.events.subscribe("user:login", () => {
      this.startData();
    });

    this.events.subscribe("navbarpc:refresh", () => {
      this.startData();
    });
  }

  async ngOnInit() {
    this.startData();
  }

  ngAfterViewInit() {
    if (this.currentPage) {
      this._renderer.addClass(this._element.nativeElement, this.currentPage);
    }
  }

  private async startData() {
    const { getFooterComplete } = await this.footerService.loadFooter();
    Object.keys(getFooterComplete).forEach((key) => {
      this[key] = getFooterComplete[key];
    });
  }

  updatePosFooter() {
    try {
      let altoContent = 0;
      for (let i = 0; i < this.element.nativeElement.parentNode.children.length - 1; i++) {
        altoContent += this.element.nativeElement.parentNode.children[i].getBoundingClientRect().height;
      }

      if (altoContent !== this.oldAltoContent) {
        //update footer
        const hSpacer = document.getElementById("spacerFooter").getBoundingClientRect().height;
        const hFooter = this.element.nativeElement.getBoundingClientRect().height;
        if (hFooter - hSpacer > this.minFooterHeight) {
          this.minFooterHeight = hFooter - hSpacer;
        }

        const altoContainer = this.element.nativeElement.parentNode.getBoundingClientRect().height;
        let newAltoFooter = altoContainer - altoContent;

        if (newAltoFooter < 0) {
          newAltoFooter = 0;
        }
        const arrSpacers = this.element.nativeElement.getElementsByClassName("spacerFooter");

        arrSpacers[0].style.height = newAltoFooter + "px";
        this.oldAltoContent = altoContent;
      }
      // eslint-disable-next-line no-empty
    } catch (err) {}
  }

  setTime() {
    const now: Date = new Date();
    const hora = now.getHours().toString();
    const minutos = now.getMinutes() < 10 ? "0" + now.getMinutes().toString() : now.getMinutes().toString();
    const segundos = now.getSeconds() < 10 ? "0" + now.getSeconds().toString() : now.getSeconds().toString();
    //
    this.time = hora + ":" + minutos + ":" + segundos;
  }

  // setTime() {
  //   const now: Date = new Date();
  //   let hora: string = '';
  //   let minutos: string = '';
  //   let segundos: string = '';

  //   if (this.globalVars.licenseType === LicenseTypes.ArgentinaCaba) {
  //     const formatter = new Intl.DateTimeFormat('es-AR', {
  //       timeZone: 'America/Argentina/Buenos_Aires',
  //       hour: 'numeric',
  //       minute: 'numeric',
  //       second: 'numeric',
  //       hour12: false
  //     });

  //     const numbers = formatter.formatToParts(now);
  //     hora = numbers[0].value;
  //     minutos = numbers[2].value;
  //     segundos = numbers[4].value;
  //     this.time = this.translate.instant('timerlocal').toUpperCase() + ': ';
  //   } else {
  //     hora = now.getHours().toString();
  //     minutos = now.getMinutes() < 10 ? '0' + now.getMinutes().toString() : now.getMinutes().toString();
  //     segundos = now.getSeconds() < 10 ? '0' + now.getSeconds().toString() : now.getSeconds().toString();
  //     this.time = '';
  //   }
  //   this.time += hora + ':' + minutos + ':' + segundos;
  // }

  ionViewWillLeave(event: any) {
    clearInterval(this.idInterval);
    this.utils.unloadContactChat();
  }
}
