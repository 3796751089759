import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SportsbookState } from '../reducers/sportsbook.reducers';

export const getSportsbookState = createFeatureSelector<SportsbookState>('sportsbook');

export const getHomeDataState = createSelector(getSportsbookState, (state: SportsbookState) => state.homeData);
export const getSportCountriesDataState = createSelector(
  getSportsbookState,
  (state: SportsbookState) => state.sportCountriesData
);
export const getEventDataState = createSelector(getSportsbookState, (state: SportsbookState) => state.eventData);
export const getMarketDataState = createSelector(getSportsbookState, (state: SportsbookState) => state.marketData);
export const getMarketBetsDataState = createSelector(
  getSportsbookState,
  (state: SportsbookState) => state.marketData.marketBets
);
export const getLiveDataState = createSelector(getSportsbookState, (state: SportsbookState) => state.liveData);
export const getLiveMarketDataState = createSelector(
  getSportsbookState,
  (state: SportsbookState) => state.liveMarketData
);
export const getRaceDataState = createSelector(getSportsbookState, (state: SportsbookState) => state.raceData);
export const getRaceMarketDataState = createSelector(
  getSportsbookState,
  (state: SportsbookState) => state.raceMarketData
);
export const getTodayDataState = createSelector(getSportsbookState, (state: SportsbookState) => state.todayData);
export const getSpecialTeamDataState = createSelector(
  getSportsbookState,
  (state: SportsbookState) => state.specialTeamData
);
export const getSportEventsDataState = createSelector(
  getSportsbookState,
  (state: SportsbookState) => state.sportEventsData
);
