<section
  class="lb-header"
  [ngClass]="{'header-fix' : category?.BackgroundImageUrl !== '' && !isDesktop && !mobileDetail, 
    'rebranding': globalVars.FEATURES?.MSO_NewReBranding}"
>
  <h2 class="lb-header--title color-light">
    {{ title | slotName}}
    <span class="lb-header--counter" *ngIf="!!jp && isJackpotCounter"> {{ jackpots$ | async | parseMoney: [4] }} </span>
  </h2>
  <button class="cd-button--sm background-color-transparent border-color-white" (click)="select($event)">
    <span class="cd-button--subtitle color-light">{{ cta | translate }}</span>
  </button>
</section>
