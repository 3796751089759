<ng-container *ngIf="!isMobile; else mobileView">
  <ion-content class="free-bets-page background-color-muted">
    <ion-row no-padding no-margin>
      <ion-col [class.leftMenuOpen]="globalVars.leftMenuOpen" class="no-padding">
        <!-- user-section-title mobile & desktop -->
        <user-section-title [sectionTitle]="'Freebets'"></user-section-title>

        <ion-toolbar
          class="subHeaderTabs tablet-content"
          [hidden]="forbiddenFreebets"
          *ngIf="globalVars.LICENSE_TYPE !== 21 && globalVars.LICENSE_TYPE !== 33"
        >
          <ion-segment [(ngModel)]="fbType">
            <ion-segment-button role="button" tappable="" value="online">
              {{'fbOnline' | translate}}
              <!--FREEBET ONLINE-->
            </ion-segment-button>

            <ion-segment-button role="button" tappable="" value="presencial" *ngIf="globalVars.LICENSE_TYPE !== 4">
              {{'fbPresen' | translate}}
              <!--FREEBET PRESENCIALES-->
            </ion-segment-button>
          </ion-segment>
        </ion-toolbar>

        <div class="is-narrow-content background-color-">
          <ion-list [hidden]="!forbiddenFreebets" class="background-color-muted">
            <form [formGroup]="form" *ngIf="globalVars.FEATURES.FreeBetSMSVerification && phoneNumber">
              <div class="introReg">
                {{'fbverificamob01' | translate}}
                <!--Por favor, completa la verificación móvil para continuar-->
              </div>

              <div class="myWrapInput">
                <ion-input
                  class="background-color-light"
                  [label]="'fbverificamob02' | translate"
                  labelPlacement="floating"
                  [placeholder]="'aCSms' | translate"
                  fill="outline"
                  [(ngModel)]="modelForm.smsCode"
                  [formControl]="$any(form).controls['smsCode']"
                ></ion-input>
              </div>

              <div class="sendForm">
                <button ion-button full (click)="checkFBCode()">OK</button>
              </div>

              <hr />

              <div class="introReg">
                <strong
                  >{{'fbverificamob03' | translate}}
                  <!--¿No has recibido el SMS?--></strong
                >
                <br />
                {{'fbverificamob04' | translate}}
                <!--Tu número de teléfono es el-->
                {{phoneNumber}}
              </div>

              <div class="sendForm">
                <button ion-button full (click)="reSendFBCode()">
                  {{'fbverificamob05' | translate}}
                  <!--Reenviar SMS de verificación-->
                </button>
              </div>
            </form>

            <div *ngIf="globalVars.FEATURES.FreeBetAccountVerification">
              <div class="introReg">
                {{'fbfreebets' | translate}}
                <!--Para poder utilizar tus freebet debes verificar tu cuenta.-->
              </div>
              <div class="sendForm">
                <button ion-button full (click)="goToVer()">
                  {{'check' | translate}}
                  <!--Verificar-->
                </button>
              </div>
            </div>
          </ion-list>

          <!--begin freebets online-->
          <ion-list class="back-transp" [hidden]="fbType=='presencial'">
            <!--Begin No hay Freebets-->
            <div class="alertMessage" [hidden]="activeFBOnline.length!=0 || aPendingFBOnline.length!=0 || firstLoad ">
              <ion-icon name="alert-circle-outline"></ion-icon>
              {{'fb_txt_num1' | translate}}
            </div>
            <div class="empty-state" [ngClass]="hiddenFreebets()">
              <img class="m-top-1" src="assets/global/img/freebet-ticket.png" alt="" />
            </div>
            <!--End Inicio No hay Freebets-->
            <ion-list-header [hidden]="activeFBOnline.length==0 && aPendingFBOnline.length==0" class="freebet-black">
              <img src="assets/global/img/freebet-black.png" />
            </ion-list-header>

            <div class="total-free-bet" [hidden]="activeFBOnline.length==0 && aPendingFBOnline.length==0">
              <ion-item class="item">
                {{'fbtotal' | translate }}&nbsp;<b>{{'fbpendient' | translate}}</b>:&nbsp;<span class="colorPending"
                  >{{aPendingFBOnline.length}}</span
                >
              </ion-item>
              <hr />
              <ion-item class="item">
                {{'fbtotal' | translate }}&nbsp;<b>{{'fbdisponib' | translate}}</b>:&nbsp;<span class="colorAvalaible"
                  >{{activeFBOnline.length}}</span
                >
              </ion-item>
            </div>

            <ion-list-header [hidden]="aPendingFBOnline.length==0">
              <h1 class="pendientes no-upper-case">
                {{'fbpen' | translate}}
                <!--FREEBET PENDIENTES-->
              </h1>
            </ion-list-header>

            <!-- Begin Freebets pendientes-->
            <div [hidden]="aPendingFBOnline.length==0">
              <div *ngFor="let itemFb of aPendingFBOnline; let i = index">
                <!--HACER UNA DIRECTIVA/COMPONENTE PARA PASARLE COMO PARAMETRO LA FREEBET ENTERA Y QUE ESA DIRECTIVA PONGA EL C�DIGO HTML CORRESPONDIENTE AL TIPO DE FREEBET-->
                <tipo-free-bet
                  [hidden]="i>indexSeeMorePendOnline"
                  [freeBet]="itemFb"
                  [Index]="i.toString()"
                ></tipo-free-bet>
              </div>
              <button
                ion-button
                (click)="showMoreFreeBet('onlineFb');"
                [hidden]="aPendingFBOnline.length<=indexSeeMorePendOnline+1"
                class="more"
              >
                + {{'seeMore' | translate}}
                <!--Ver m�s-->
              </button>
            </div>
            <!-- End Freebets pendientes-->
            <!-- Begin Freebets disponibles -->

            <ion-list-header [hidden]="activeFBOnline.length==0">
              <ion-item>
                <h1>
                  {{'fbdis' | translate}}
                  <!--FREEBET DISPONIBLES-->
                </h1>
              </ion-item>
            </ion-list-header>

            <div class="free-bets-avalaible" [hidden]="activeFBOnline.length==0">
              <ion-item class="main-box" *ngFor="let itemFbDisponible of activeFBOnline">
                <ion-avatar item-left class="freebetBullet">
                  <codere-style-money [amount]="itemFbDisponible.Amount" [type]="0"></codere-style-money>
                  <p>
                    {{'fb' | translate}}
                    <!--freebet-->
                  </p>
                </ion-avatar>
                <div>
                  <h2>
                    {{itemFbDisponible.Name}}<span *ngIf="globalVars.LICENSE_TYPE === 21"
                      >&nbsp;-&nbsp;COD:&nbsp;{{itemFbDisponible.VoucherCode}}</span
                    >
                  </h2>
                  <h3>
                    {{'importSlots' | translate}}
                    <!--Importe-->
                    <b>{{itemFbDisponible.Amount}}</b>
                  </h3>
                  <div *ngFor="let itemConditions of itemFbDisponible?.Conditions">
                    <h3 *ngIf="itemConditions.ConditionValueDecimal>0">
                      {{'fb_txt_active6' | translate}}
                      <b>{{itemConditions.ConditionValueDecimal | formatOdd}}</b>
                    </h3>
                    <h3 *ngIf="itemConditions.ConditionValueInt>0">
                      {{'fb_txt_active8' | translate}}
                      <b>{{itemConditions.ConditionValueInt}}</b>
                    </h3>
                  </div>
                  <h3 *ngIf="!itemFbDisponible.IsRedeemable">
                    {{'v2_ganaNetas' | translate}}
                    <!--# Solo ganancias netas-->
                  </h3>
                </div>
              </ion-item>
            </div>
            <!-- End Freebets disponibles -->
            <ion-list
              class="legal-list"
              [hidden]="activeFBOnline.length==0 && aPendingFBOnline.length==0"
              *ngIf="globalVars.LICENSE_TYPE === 21"
            >
              <!--continuar literales-->

              <ion-list-header class="text-uppercase">{{'fb_sst_txt' | translate}}</ion-list-header>
              <ion-item class="legal-item has-bullet">
                <ion-label>
                  <div class="bullet">
                    <p>1</p>
                  </div>
                  <p class="legal-message" [innerHTML]="'fb_sst_txt01' | translate"></p>
                </ion-label>
              </ion-item>

              <ion-item class="legal-item has-bullet">
                <ion-label>
                  <div class="bullet">
                    <p>2</p>
                  </div>
                  <p class="legal-message" [innerHTML]="'fb_sst_txt02' | translate"></p>
                </ion-label>
              </ion-item>
              <ion-item class="legal-item has-bullet">
                <ion-label>
                  <div class="bullet">
                    <p>3</p>
                  </div>
                  <p class="legal-message" [innerHTML]="'fb_sst_txt03' | translate"></p>
                </ion-label>
              </ion-item>
              <ion-item class="legal-item has-bullet">
                <ion-label>
                  <div class="bullet">
                    <p>4</p>
                  </div>
                  <p class="legal-message" [innerHTML]="'fb_sst_txt04' | translate"></p>
                </ion-label>
              </ion-item>
              <ion-item class="legal-item has-bullet">
                <ion-label>
                  <div class="bullet">
                    <p>5</p>
                  </div>
                  <p class="legal-message" [innerHTML]="'fb_sst_txt05' | translate"></p>
                </ion-label>
              </ion-item>
            </ion-list>

            <!--LEGAL-->
            <ion-list class="legal-list" [hidden]="activeFBOnline.length==0 && aPendingFBOnline.length==0">
              <!--continuar literales online-->
              <ion-list-header class="text-uppercase"
                >{{'fb_txt_active1' | translate}}<span *ngIf="globalVars.LICENSE_TYPE === 21"
                  >&nbsp;ONLINE</span
                ></ion-list-header
              >

              <ion-item class="legal-item has-bullet">
                <ion-label>
                  <div class="bullet">
                    <p>1</p>
                  </div>
                  <p class="legal-message" [innerHTML]="'fb_txt_active2' | translate"></p>
                </ion-label>
              </ion-item>

              <ion-item class="legal-item has-bullet">
                <ion-label>
                  <div class="bullet">
                    <p>2</p>
                  </div>
                  <p class="legal-message" [innerHTML]="'fb_txt_active3' | translate"></p>
                </ion-label>
              </ion-item>
              <ion-item class="legal-item has-bullet">
                <ion-label>
                  <div class="bullet">
                    <p>3</p>
                  </div>
                  <p class="legal-message" [innerHTML]="'fb_txt_active4' | translate"></p>
                </ion-label>
              </ion-item>
              <ion-item class="legal-item has-bullet">
                <ion-label>
                  <div class="bullet">
                    <p>4</p>
                  </div>
                  <p class="legal-message" [innerHTML]="'fb_txt_active5' | translate"></p>
                </ion-label>
              </ion-item>
            </ion-list>
          </ion-list>
          <!--end freebets online-->
          <!--begin freebets presencial-->
          <ion-list [hidden]="fbType=='online'" class="freebets-presenciales">
            <span *ngIf="!codereCardActive" class="card-request-free-bets">
              <div class="alertMessage">
                <ion-icon name="alert-circle-outline"></ion-icon>
                {{'fbneedCard' | translate}}
                <!--Necesitas una tarjeta Codere para poder disfrutar de nuestras promociones--><br />
              </div>

              <p class="singleTitle singleTitleCentered">
                {{'fbCardNo' | translate}}
                <!--�TODAV�A NO TIENES UNA?-->
              </p>

              <div class="introReg" [innerHTML]="'GetCardordertx00' | translate">
                <!--Puedes <b>obtener tu Tarjeta Codere</b> de las siguientes formas:-->
              </div>
              <ion-list class="list-forma">
                <ion-item tappable="true" (click)="gotoNearLocal()">
                  <div class="forma1">
                    <ion-avatar item-left>
                      <span>1</span>
                    </ion-avatar>
                    <h2>
                      {{'GetCardordertx01' | translate}}
                      <!--Consíguela en cualquier local Codere-->
                    </h2>
                  </div>
                </ion-item>
                <i class="codere-icon arrow icon-arrow-right"></i>
                <ion-label class="lblRequest">
                  {{'GetCardordertx011' | translate}}
                  <!--Puedes consultar tu local más cercano-->
                </ion-label>
                <ion-item
                  tappable="true"
                  (click)="checkCanSendCodereCard()"
                  *ngIf="globalVars.LICENSE_TYPE === 0 || globalVars.LICENSE_TYPE === 1"
                >
                  <div class="forma1">
                    <ion-avatar item-left>
                      <span>2</span>
                    </ion-avatar>
                    <h2>
                      {{'GetCardordertx02' | translate}}
                      <!--Solicitando que te la enviemos por correo postal-->
                    </h2>
                  </div>
                </ion-item>
                <i
                  class="arrow arrowReqq2 codere-icon icon-arrow-right"
                  *ngIf="globalVars.LICENSE_TYPE === 0 || globalVars.LICENSE_TYPE === 1"
                ></i>
                <ion-label *ngIf="globalVars.LICENSE_TYPE === 0 || globalVars.LICENSE_TYPE === 1" class="lblRequest">
                  <ion-icon name="alert-circle-outline"></ion-icon>
                  {{'GetCardordertx022' | translate}}
                  <!--Comprueba que los datos de envío son correctos -->
                </ion-label>
              </ion-list>

              <ion-list class="subDept">
                <p>
                  {{'fbCardpromotions' | translate}}<br />
                  <!--Si ya tienes una tarjeta Codere, as�ciala a tu cuenta para disfrutar de nuestras promociones-->
                </p>
                <div class="sendForm">
                  <button
                    tappable="true"
                    (click)="accessToCheckCodereCard();"
                    class="disable-hover button button-default button-full"
                  >
                    {{'CodereCardToCheck' | translate}}<br />
                    <!--Asociar tarjeta-->
                  </button>
                </div>
              </ion-list>
            </span>

            <div [hidden]="!codereCardActive">
              <!--Inicio No hay Freebets-->
              <div class="alertMessage" [hidden]="aPendingFBLocal.length!=0 ||  activeFBLocal.length!=0">
                <ion-icon name="alert-circle-outline"></ion-icon>
                {{'fbnoPresencial' | translate}}
              </div>

              <div class="total-free-bet" [hidden]="aPendingFBLocal.length==0 &&  activeFBLocal.length==0">
                <ion-item class="item">
                  {{'fbtotal' | translate }}&nbsp;<b>{{'fbpendient' | translate}}</b>:&nbsp;<span class="colorPending"
                    >{{aPendingFBLocal.length}}</span
                  >
                </ion-item>
                <hr />
                <ion-item class="item">
                  {{'fbtotal' | translate }}&nbsp;<b>{{'fbdisponib' | translate}}</b>:&nbsp;<span class="colorAvalaible"
                    >{{activeFBLocal.length}}</span
                  >
                </ion-item>
              </div>

              <div id="Div2" class="tab-content">
                <!--Empty State-->
                <empty-state [imageSource]="imageSource" [hidden]="activeFBLocal.length!=0"></empty-state>

                <!--Inicio Freebets pendientes-->

                <ion-list-header [hidden]="aPendingFBLocal.length==0" class="freebets-title">
                  <ion-item>
                    <h1 class="pendientes">
                      {{'fbpen' | translate}}
                      <!--FREEBET PENDIENTES-->
                    </h1>
                  </ion-item>
                </ion-list-header>

                <div [hidden]="aPendingFBLocal.length==0">
                  <!--<div data-bind="template: { name: freeBetPendingTemplate, foreach: pendingFreeBetsLocal }"></div>-->
                  <div *ngFor="let itemPendFb of aPendingFBLocal; let i = index">
                    <!--HACER UNA DIRECTIVA/COMPONENTE PARA PASARLE COMO PARAMETRO LA FREEBET ENTERA Y QUE ESA DIRECTIVA PONGA EL C�DIGO HTML CORRESPONDIENTE AL TIPO DE FREEBET-->

                    <tipo-free-bet
                      [hidden]="i>indexSeeMorePendLocal"
                      Name="{{itemPendFb.Name}}"
                      TypeFB="{{itemPendFb.Type}}"
                      GrossWin="{{itemPendFb.GrossWin}}"
                      AmountFb="{{itemPendFb.AmountFb}}"
                      TotalBets="{{itemPendFb.TotalBets}}"
                      minOdd="{{itemPendFb.minOdd}}"
                      AmountBets="{{itemPendFb.AmountBets}}"
                      pendingAmount="{{itemPendFb.pendingAmount}}"
                      date="{{itemPendFb.date}}"
                      dateStart="{{itemPendFb.dateStart}}"
                      dateGW="{{itemPendFb.dateGW}}"
                      active="{{itemPendFb.active}}"
                      PercentDev="{{itemPendFb.PercentDev}}"
                      Url="{{itemPendFb.Url}}"
                    ></tipo-free-bet>
                  </div>
                  <button
                    (click)="showMoreFreeBet('presencialesFb');"
                    [hidden]="aPendingFBLocal.length<=indexSeeMorePendLocal+1"
                  >
                    + {{'seeMore' | translate}}
                    <!--Ver m�s-->
                  </button>
                </div>
                <!--Fin Freebets pendientes-->
                <!--Inicio Freebets disponibles-->

                <ion-list-header [hidden]="activeFBLocal.length==0">
                  <ion-item>
                    <h1>
                      {{'fbdis' | translate }}
                      <!--FREEBET DISPONIBLES-->
                    </h1>
                  </ion-item>
                </ion-list-header>

                <div class="free-bets-avalaible" [hidden]="activeFBLocal.length==0">
                  <ion-item class="main-box" *ngFor="let itemFbDisponible of activeFBLocal">
                    <ion-avatar item-left class="freebetBullet">
                      <codere-style-money [amount]="itemFbDisponible.Amount" [type]="0"> </codere-style-money>
                      <p>
                        {{'fb' | translate}}
                        <!--freebet-->
                      </p>
                    </ion-avatar>
                    <h2>
                      {{itemFbDisponible.VoucherCode}}
                      <!--# Bono el clásico-->
                    </h2>
                    <h3>
                      {{'importSlots' | translate}}
                      <!--Importe-->
                      <b>{{itemFbDisponible.Amount}}</b>
                    </h3>
                    <div *ngFor="let itemConditions of itemFbDisponible?.Conditions">
                      <h3 *ngIf="itemConditions.ConditionValueDecimal>0">
                        {{'fb_txt_active6' | translate}}
                        <b>{{itemConditions.ConditionValueDecimal}}</b>
                      </h3>
                      <h3 *ngIf="itemConditions.ConditionValueInt>0">
                        {{'fb_txt_active8' | translate}}
                        <b>{{itemConditions.ConditionValueInt}}</b>
                      </h3>
                    </div>
                    <h3 *ngIf="!itemFbDisponible.IsRedeemable">
                      {{'v2_ganaNetas' | translate}}
                      <!--# Solo ganancias netas-->
                    </h3>
                  </ion-item>
                </div>

                <!--END Freebets disponibles-->
              </div>
            </div>
          </ion-list>
          <!--end freebets presencial-->
        </div>

        <codere-footer [space]="false"></codere-footer>
      </ion-col>
    </ion-row>
  </ion-content>
</ng-container>

<ng-template #mobileView>
  <ion-header>
    <user-section-title [sectionTitle]="'Freebets'"></user-section-title>
    <ion-toolbar
      mode="md"
      class="subheader-tabs"
      [hidden]="forbiddenFreebets"
      *ngIf="globalVars.LICENSE_TYPE !== 21 && globalVars.LICENSE_TYPE !== 33"
    >
      <ion-segment [(ngModel)]="fbType">
        <ion-segment-button role="button" tappable="" value="online">
          <!--{{'fbOnline' | translate}}-->ONLINE
        </ion-segment-button>

        <ion-segment-button role="button" tappable="" value="presencial" *ngIf="globalVars.LICENSE_TYPE !== 4">
          <!--{{'fbPresen' | translate}}-->PRESENCIAL
        </ion-segment-button>
      </ion-segment>
    </ion-toolbar>
  </ion-header>

  <ion-content padding class="free-bets-page background-color-muted">
    <ion-list [hidden]="!forbiddenFreebets" class="background-color-muted">
      <form
        class="freebets-page--form background-color-muted"
        [formGroup]="form"
        *ngIf="globalVars.FEATURES.FreeBetSMSVerification && phoneNumber"
      >
        <div class="introReg">
          {{'fbverificamob01' | translate}}
          <!--Por favor, completa la verificación móvil para continuar-->
        </div>

        <div class="myWrapInput">
          <ion-input
            class="background-color-light"
            [label]="'fbverificamob02' | translate"
            labelPlacement="floating"
            [placeholder]="'aCSms' | translate"
            fill="outline"
            [(ngModel)]="modelForm.smsCode"
            [formControl]="$any(form).controls['smsCode']"
          ></ion-input>
        </div>

        <div class="sendForm">
          <button ion-button full (click)="checkFBCode()">OK</button>
        </div>

        <hr />

        <div class="introReg">
          <strong>{{'fbverificamob03' | translate}}</strong>
          <br />
          {{'fbverificamob04' | translate}} {{phoneNumber}}
        </div>

        <div class="sendForm">
          <button ion-button full (click)="reSendFBCode()">{{'fbverificamob05' | translate}}</button>
        </div>
      </form>

      <div *ngIf="globalVars.FEATURES.FreeBetAccountVerification" class="freebet-account-verification">
        <div class="introReg">{{'fbfreebets' | translate}}</div>
        <div class="sendForm">
          <button ion-button full (click)="goToVer()">{{'check' | translate}}</button>
        </div>
      </div>
    </ion-list>

    <!--begin freebets online-->
    <ion-list class="legal-list" [hidden]="forbiddenFreebets || fbType=='presencial'">
      <!--Empty State-->
      <empty-state
        class="empty-state-mobile"
        [class.is-mobile]="isMobile"
        [message]="'fb_txt_num1'"
        [imageSource]="imageSource"
        [hidden]="activeFBOnline.length!=0 || aPendingFBOnline.length!=0 || firstLoad "
      ></empty-state>
      <!--Freebets Online-->
      <ion-list-header class="freebet-header" [hidden]="activeFBOnline.length==0 && aPendingFBOnline.length==0">
        <ion-item class="background-color-transparent">
          <h1><img src="assets/global/img/freebet-black.png" /></h1>
        </ion-item>
      </ion-list-header>

      <div class="total-free-bet" [hidden]="activeFBOnline.length==0 && aPendingFBOnline.length==0">
        <ion-item class="item">
          {{'fbtotal' | translate }}&nbsp;<b>{{'fbpendient' | translate}}</b>:&nbsp;<span class="colorPending"
            >{{aPendingFBOnline.length}}</span
          >
        </ion-item>
        <hr />
        <ion-item class="item">
          {{'fbtotal' | translate }}&nbsp;<b>{{'fbdisponib' | translate}}</b>:&nbsp;<span class="colorAvalaible"
            >{{activeFBOnline.length}}</span
          >
        </ion-item>
      </div>

      <ion-list-header [hidden]="aPendingFBOnline.length==0">
        <ion-item>
          <h1>
            {{'fbpen' | translate}}
            <!--FREEBET PENDIENTES-->
          </h1>
        </ion-item>
      </ion-list-header>

      <!-- Begin Freebets pendientes-->
      <div [hidden]="aPendingFBOnline.length==0">
        <div *ngFor="let itemFb of aPendingFBOnline; let i = index">
          <!--HACER UNA DIRECTIVA/COMPONENTE PARA PASARLE COMO PARAMETRO LA FREEBET ENTERA Y QUE ESA DIRECTIVA PONGA EL C?DIGO HTML CORRESPONDIENTE AL TIPO DE FREEBET-->
          <!--<span [hidden]="i>indexSeeMorePendOnline">freebet {{i.toString()}} de tipo {{itemFb.Type}}</span>-->
          <tipo-free-bet [hidden]="i>indexSeeMorePendOnline" [freeBet]="itemFb" [Index]="i.toString()"> </tipo-free-bet>
        </div>
        <button
          ion-button
          (click)="showMoreFreeBet('onlineFb');"
          [hidden]="aPendingFBOnline.length<=indexSeeMorePendOnline+1"
          class="more"
        >
          + {{'seeMore' | translate}}
          <!--Ver m?s-->
        </button>
      </div>
      <!-- End Freebets pendientes-->
      <!-- Begin Freebets disponibles -->

      <ion-list-header [hidden]="activeFBOnline.length==0">
        <ion-item>
          <h1>
            {{'fbdis' | translate}}
            <!--FREEBET DISPONIBLES-->
          </h1>
        </ion-item>
      </ion-list-header>

      <!--<div -->
      <div class="free-bets-avalaible" [hidden]="activeFBOnline.length==0">
        <ion-item class="main-box" *ngFor="let itemFbDisponible of activeFBOnline">
          <ion-avatar item-left class="freebetBullet">
            <codere-style-money [amount]="itemFbDisponible?.Amount" [type]="0"></codere-style-money>
            <p>
              {{'fb' | translate}}
              <!--freebet-->
            </p>
          </ion-avatar>
          <div>
            <h2>
              {{itemFbDisponible.Name}}<span *ngIf="globalVars.LICENSE_TYPE === 21"
                >&nbsp;-&nbsp;COD:&nbsp;{{itemFbDisponible.VoucherCode}}</span
              >
            </h2>
            <h3>
              {{'importSlots' | translate}}
              <!--Importe-->
              <b>{{itemFbDisponible.Amount | parseMoney}}</b>
            </h3>
            <div *ngFor="let itemConditions of itemFbDisponible?.Conditions">
              <h3 *ngIf="itemConditions.ConditionValueDecimal>0">
                {{'fb_txt_active6' | translate}}
                <b>{{itemConditions.ConditionValueDecimal | formatOdd}}</b>
              </h3>
              <h3 *ngIf="itemConditions.ConditionValueInt>0">
                {{'fb_txt_active8' | translate}}
                <b>{{itemConditions.ConditionValueInt}}</b>
              </h3>
            </div>
            <h3 *ngIf="!itemFbDisponible.IsRedeemable">
              {{'v2_ganaNetas' | translate}}
              <!--# Solo ganancias netas-->
            </h3>
          </div>
        </ion-item>
      </div>
      <!-- End Freebets disponibles -->
      <ion-list
        class="legal-list"
        [hidden]="activeFBOnline.length==0 && aPendingFBOnline.length==0"
        *ngIf="globalVars.LICENSE_TYPE === 21"
      >
        <!--continuar literales-->

        <ion-list-header class="text-uppercase">{{'fb_sst_txt' | translate}}</ion-list-header>
        <ion-item class="legal-item has-bullet">
          <ion-label>
            <div class="bullet">
              <p>1</p>
            </div>
            <p class="legal-message" [innerHTML]="'fb_sst_txt01' | translate"></p>
          </ion-label>
        </ion-item>

        <ion-item class="legal-item has-bullet">
          <ion-label>
            <div class="bullet">
              <p>2</p>
            </div>
            <p class="legal-message" [innerHTML]="'fb_sst_txt02' | translate"></p>
          </ion-label>
        </ion-item>
        <ion-item class="legal-item has-bullet">
          <ion-label>
            <div class="bullet">
              <p>3</p>
            </div>
            <p class="legal-message" [innerHTML]="'fb_sst_txt03' | translate"></p>
          </ion-label>
        </ion-item>
        <ion-item class="legal-item has-bullet">
          <ion-label>
            <div class="bullet">
              <p>4</p>
            </div>
            <p class="legal-message" [innerHTML]="'fb_sst_txt04' | translate"></p>
          </ion-label>
        </ion-item>
        <ion-item class="legal-item has-bullet">
          <ion-label>
            <div class="bullet">
              <p>5</p>
            </div>
            <p class="legal-message" [innerHTML]="'fb_sst_txt05' | translate"></p>
          </ion-label>
        </ion-item>
      </ion-list>

      <ion-list class="legal-list" [hidden]="activeFBOnline.length==0 && aPendingFBOnline.length==0">
        <!--continuar literales-->
        <ion-list-header class="text-uppercase"
          >{{'fb_txt_active1' | translate}}<span *ngIf="globalVars.LICENSE_TYPE === 21"
            >&nbsp;ONLINE</span
          ></ion-list-header
        >

        <ion-item class="legal-item has-bullet">
          <ion-label>
            <div class="bullet">
              <p>1</p>
            </div>
            <p class="legal-message" [innerHTML]="'fb_txt_active2' | translate"></p>
          </ion-label>
        </ion-item>

        <ion-item class="legal-item has-bullet">
          <ion-label>
            <div class="bullet">
              <p>2</p>
            </div>
            <p class="legal-message" [innerHTML]="'fb_txt_active3' | translate"></p>
          </ion-label>
        </ion-item>
        <ion-item class="legal-item has-bullet">
          <ion-label>
            <div class="bullet">
              <p>3</p>
            </div>
            <p class="legal-message" [innerHTML]="'fb_txt_active4' | translate"></p>
          </ion-label>
        </ion-item>
        <ion-item class="legal-item has-bullet">
          <ion-label>
            <div class="bullet">
              <p>4</p>
            </div>
            <p class="legal-message" [innerHTML]="'fb_txt_active5' | translate"></p>
          </ion-label>
        </ion-item>
      </ion-list>
    </ion-list>
    <!--end freebets online-->
    <!--begin freebets presencial-->
    <ion-list [hidden]="forbiddenFreebets || fbType=='online'" class="freebets-presencial">
      <span *ngIf="!codereCardActive" class="card-request-free-bets">
        <div class="alertMessage">
          <ion-icon name="alert-circle-outline"></ion-icon>
          {{'fbneedCard' | translate}}
          <!--Necesitas una tarjeta Codere para poder disfrutar de nuestras promociones--><br />
        </div>

        <p class="singleTitle">
          {{'fbCardNo' | translate}}
          <!--?TODAV?A NO TIENES UNA?-->
        </p>
        <div class="introReg" [innerHTML]="'GetCardordertx00' | translate">
          <!--Puedes <b>obtener tu Tarjeta Codere</b> de las siguientes formas:-->
        </div>
        <ion-list>
          <ion-item tappable="true" (click)="gotoNearLocal()">
            <div class="forma1">
              <ion-avatar item-left>
                <span>1</span>
              </ion-avatar>
              <h2>
                {{'GetCardordertx01' | translate}}
                <!--Cons�guela en cualquier local Codere-->
              </h2>
            </div>
          </ion-item>
          <i class="arrow icon-arr-right"></i>
          <ion-label class="lblRequest">
            {{'GetCardordertx011' | translate}}
            <!--Puedes consultar tu local m�s cercano-->
          </ion-label>
          <ion-item
            tappable="true"
            (click)="checkCanSendCodereCard()"
            *ngIf="globalVars.LICENSE_TYPE === 0 || globalVars.LICENSE_TYPE === 1"
          >
            <div class="forma1">
              <ion-avatar item-left>
                <span>2</span>
              </ion-avatar>
              <h2>
                {{'GetCardordertx02' | translate}}
                <!--Solicitando que te la enviemos por correo postal-->
              </h2>
            </div>
          </ion-item>
          <i
            class="arrow arrowReqq2 icon-arr-right"
            *ngIf="globalVars.LICENSE_TYPE === 0 || globalVars.LICENSE_TYPE === 1"
          ></i>
          <ion-label *ngIf="globalVars.LICENSE_TYPE === 0 || globalVars.LICENSE_TYPE === 1" class="lblRequest">
            <ion-icon name="alert-circle-outline"></ion-icon>
            {{'GetCardordertx022' | translate}}
            <!--Comprueba que los datos de env�o son correctos -->
          </ion-label>
        </ion-list>

        <ion-list class="subDept">
          <p>
            {{'fbCardpromotions' | translate}}<br />
            <!--Si ya tienes una tarjeta Codere, as?ciala a tu cuenta para disfrutar de nuestras promociones-->
          </p>
          <div class="sendForm">
            <button
              ion-button
              tappable="true"
              (click)="accessToCheckCodereCard();"
              class="disable-hover button button-default button-full"
            >
              {{'CodereCardToCheck' | translate}}<br />
              <!--Asociar tarjeta-->
            </button>
          </div>
        </ion-list>
      </span>

      <div [hidden]="!codereCardActive">
        <!--Empty State-->
        <empty-state
          [class.is-mobile]="isMobile"
          [message]="'fbnoPresencial'"
          [imageSource]="imageSource"
          [hidden]="aPendingFBLocal.length!=0 ||  activeFBLocal.length!=0"
        >
        </empty-state>
        <!--Freebet Presencial-->
        <ion-list-header [hidden]="aPendingFBLocal.length==0 &&  activeFBLocal.length==0">
          <ion-item>
            <h1><img src="assets/global/img/freebet-black.png" /></h1>
          </ion-item>
        </ion-list-header>

        <div class="total-free-bet" [hidden]="aPendingFBLocal.length==0 &&  activeFBLocal.length==0">
          <ion-item class="item">
            {{'fbtotal' | translate }}&nbsp;<b>{{'fbpendient' | translate}}</b>:&nbsp;<span class="colorPending"
              >{{aPendingFBLocal.length}}</span
            >
          </ion-item>
          <hr />
          <ion-item class="item">
            {{'fbtotal' | translate }}&nbsp;<b>{{'fbdisponib' | translate}}</b>:&nbsp;<span class="colorAvalaible"
              >{{activeFBLocal.length}}</span
            >
          </ion-item>
        </div>

        <div id="Div2" class="tab-content">
          <!--Inicio Freebets pendientes-->

          <ion-list-header [hidden]="aPendingFBLocal.length==0">
            <ion-item>
              <h1>
                {{'fbpen' | translate}}
                <!--FREEBET PENDIENTES-->
              </h1>
            </ion-item>
          </ion-list-header>

          <div [hidden]="aPendingFBLocal.length==0">
            <!--<div data-bind="template: { name: freeBetPendingTemplate, foreach: pendingFreeBetsLocal }"></div>-->
            <div *ngFor="let itemPendFb of aPendingFBLocal; let i = index">
              <!--HACER UNA DIRECTIVA/COMPONENTE PARA PASARLE COMO PARAMETRO LA FREEBET ENTERA Y QUE ESA DIRECTIVA PONGA EL C?DIGO HTML CORRESPONDIENTE AL TIPO DE FREEBET-->
              <!--<span [hidden]="i>indexSeeMorePendLocal">freebet {{i.toString()}} de tipo {{itemPendFb.Type}}</span>-->
              <tipo-free-bet
                [hidden]="i>indexSeeMorePendLocal"
                Name="{{itemPendFb.Name}}"
                TypeFB="{{itemPendFb.Type}}"
                GrossWin="{{itemPendFb.GrossWin}}"
                AmountFb="{{itemPendFb.AmountFb}}"
                TotalBets="{{itemPendFb.TotalBets}}"
                minOdd="{{itemPendFb.minOdd}}"
                AmountBets="{{itemPendFb.AmountBets}}"
                pendingAmount="{{itemPendFb.pendingAmount}}"
                date="{{itemPendFb.date}}"
                dateStart="{{itemPendFb.dateStart}}"
                dateGW="{{itemPendFb.dateGW}}"
                active="{{itemPendFb.active}}"
                PercentDev="{{itemPendFb.PercentDev}}"
                Url="{{itemPendFb.Url}}"
                Index="{{i}}"
              ></tipo-free-bet>
            </div>
            <button
              ion-button
              (click)="showMoreFreeBet('presencialesFb');"
              [hidden]="aPendingFBLocal.length<=indexSeeMorePendLocal+1"
              class="more"
            >
              + {{'seeMore' | translate}}
              <!--Ver m?s-->
            </button>
          </div>
          <!--Fin Freebets pendientes-->
          <!--Inicio Freebets disponibles-->

          <ion-list-header [hidden]="activeFBLocal.length==0">
            <ion-item>
              <h1>
                {{'fbdis' | translate }}
                <!--FREEBET DISPONIBLES-->
              </h1>
            </ion-item>
          </ion-list-header>

          <div class="free-bets-avalaible" [hidden]="activeFBLocal.length==0">
            <ion-item class="main-box" *ngFor="let itemFbDisponible of activeFBLocal">
              <ion-avatar item-left class="freebetBullet">
                <codere-style-money [amount]="itemFbDisponible.Amount" [type]="0"></codere-style-money>
                <p>
                  {{'fb' | translate}}
                  <!--freebet-->
                </p>
              </ion-avatar>
              <h2>
                {{itemFbDisponible.VoucherCode}}
                <!--# Bono el cl�sico-->
              </h2>
              <h3>
                {{'importSlots' | translate}}
                <!--Importe-->
                <b>{{itemFbDisponible.Amount | parseMoney}}</b>
              </h3>
              <div *ngFor="let itemConditions of itemFbDisponible?.Conditions">
                <h3 *ngIf="itemConditions.ConditionValueDecimal>0">
                  {{'fb_txt_active6' | translate}}
                  <b>{{itemConditions.ConditionValueDecimal | parseMoney}}</b>
                </h3>
                <h3 *ngIf="itemConditions.ConditionValueInt>0">
                  {{'fb_txt_active8' | translate}}
                  <b>{{itemConditions.ConditionValueInt}}</b>
                </h3>
              </div>
              <h3 *ngIf="!itemFbDisponible.IsRedeemable">
                {{'v2_ganaNetas' | translate}}
                <!--# Solo ganancias netas-->
              </h3>
            </ion-item>
          </div>

          <!--END Freebets disponibles-->
        </div>
      </div>
    </ion-list>
    <!--end freebets presencial-->

    <codere-footer></codere-footer>
  </ion-content>
</ng-template>
