/* eslint-disable */
import {
  Component,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  Renderer2,
  ElementRef,
  OnInit,
  OnDestroy,
  OnChanges,
  inject
} from '@angular/core';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { NavController, NavParams, ModalController, AlertController, IonicModule } from '@ionic/angular';
import { FormBuilder, FormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Observable, Subject, forkJoin, lastValueFrom, of } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';

//Services
import { GlobalVarsModel } from '../../../models/ModelGlobalsVar';
import { TrackingInfo } from '../../base/BasePage';
import { ObfuscatePipe } from '../../../pipes/obfuscate.pipe';
import { WithDrawalFactory } from '../../../providers/PaymentMethodService/withdrawals';
import { BalancesService } from '../../../providers/BalancesService';
import { NewBaseService } from '../../../providers/newBase.service';
import { DeviceService } from '../../../services/device.service';
import { MSO_PATHS } from '../../../lib/constants/routes';
import { PaymentsCashierService } from '../../../providers/payments/PaymentsCashierService';
import { CashierWithdrawalsSworm } from '../cashier-withdrawals-sworm/cashier-withdrawals-sworm';
import { CashierMovement } from '../../../models/payments/cs-step-movement';

import { UserService } from '../../../providers/UserService';
import { EventsService } from '../../../providers/EventsService';
import { UtilsCashierService } from '../../../providers/UtilsCashierService';
import { Utils } from '../../../utils/Utils';

//Models
import { TransactionActionResult } from '../../../models/C_Transactions';
import { LicenseTypes } from '../../../models/MasterData';

//Pipes
import { ParseMoney } from '../../../pipes/parseMoney';

//Others
import { ErrorService } from '../../../providers/ErrorService';
import { PartnerConfigurationsService } from '../../../providers/PartnerConfigurationsService';

import { CsTransactionItemComponent } from '../../../components/payments/cs-transaction-item/cs-transaction-item';
import { CsCalculatorComponent } from '../../../components/payments/cs-calculator/cs-calculator';
import { CsTPagaInfoComponent } from '../../../components/payments/cs-tpaga-info/cs-tpaga-info';
import { CsHalcashFormComponent } from '../../../components/payments/cs-halcash-form/cs-halcash-form';
import { CsBankTransferFormComponent } from '../../../components/payments/cs-bank-transfer-form/cs-bank-transfer-form';
import { CsLocalCodereComponent } from '../../../components/payments/cs-local-codere/cs-local-codere';
import { CashierHalcashInfoPage } from '../cashier-halcash-info/cashier-halcash-info';
import { DOCUMENT_VERIFICATION_STATES } from '../../../models/payments/DocumentVerificationStates';
import { FundsOrigin, BankService } from '../../../providers/payments/BankService';
import { CsVerificationAlertModal } from '../../../components/payments/cs-verification-alert-modal/cs-verification-alert-modal';
import { CsCrownCasinoComponent } from '../../../components/payments/cs-crown-casino/cs-crown-casino';
import { CashierRequestInfoPageOk } from '../cashier-request-info-ok/cashier-request-info-ok';
import { CashierRequestInfoPageKo } from '../cashier-request-info-ko/cashier-request-info-ko';
import { VerificationAlerts } from '@providers/VerificationAlert';
import { KeyBoardComponent } from '../../../../../shared/src/components/common/keyboard/keyboard';
import { WithdrawalMethodsClassName } from '@models/payments/WithdrawalMethodsClassName';
import { TrackingService } from '@providers/TrackingService';
import { EventTypes } from '@models/TrackingEvents';
import { CsConfirmModal } from '@components/payments/cs-confirm-modal/cs-confirm-modal';

interface account {
  iban: string;
  verified: boolean;
}

interface VerifiedAccount {
  iban: string;
  display_name: string;
  ofuscateIban: string;
  verified: boolean;
}

interface ModalResult {
  originSelected: { name: string };
  swornAccepted: boolean;
}

@Component({
  selector: 'page-cashier-withdrawals',
  templateUrl: './cashier-withdrawals.html',
  styleUrls: ['./cashier-withdrawals.scss'],
  standalone: true,
  imports: [
    IonicModule,
    CommonModule,
    TranslateModule,
    CsTransactionItemComponent,
    FormsModule,
    ObfuscatePipe,
    CsCalculatorComponent,
    CsTPagaInfoComponent,
    CsHalcashFormComponent,
    CsBankTransferFormComponent,
    CsLocalCodereComponent,
    CsCrownCasinoComponent,
    KeyBoardComponent
  ]
})
export class CashierWithdrawalsPage extends CashierMovement implements OnInit, OnDestroy, OnChanges {
  @ViewChild('content', { static: false }) content: ElementRef;
  @ViewChild('redys') redys;

  @Input() sectionStep: number;
  @Output() isLocalTicket: EventEmitter<boolean> = new EventEmitter();
  @Output() keyboardOpen: EventEmitter<boolean> = new EventEmitter();
  @Output() paymentSelection: EventEmitter<any> = new EventEmitter();
  @Output() sendToDocuments: EventEmitter<number> = new EventEmitter();
  @Output() setSectionStep: EventEmitter<number> = new EventEmitter();
  @Output() trackingEvent: EventEmitter<any[]> = new EventEmitter();
  @Output() cardSelectionWithdrawal: EventEmitter<any> = new EventEmitter<any>();

  verifiedAccountList: VerifiedAccount[] = [];
  accountList: account[] = [];

  errorAmount: string = '';
  stateDepositButton: boolean = false;

  msgCheckBank: string;
  paypalChargeAvailable: boolean;

  showVerificarButton: boolean = false;

  fundOriginSelected: string;

  calculatorConfig: any = {
    counterTitle: 'Importe de cobro',
    buttonsTitle: 'Importes',
    isDesktop: null,
    license: null,
    currency: ''
  };

  localConfig: any = {
    amountTaxes: '',
    amountCharge: '',
    code: '',
    barcode: '',
    userName: '',
    userData: '',
    typeDeposit: false
  };

  outConditions: string;

  withdrawals$: Observable<any[]>;
  currentWithdrawal: any = null;
  currentAmount: any;
  public currentAddress: string; //for Coinspaid
  userData$: Observable<any>;

  optionsRequestKo: any = {
    error: '',
    type: 'withdrawal',
    title: 'chargeKOtx01',
    subtitle: 'chargeKO'
  };

  optionsRequestOk: any = {
    idTransaction: '',
    mfaCode: '',
    type: 'withdrawal',
    title: 'chargeOKtxt01',
    subtitle: 'chargeOKtxt02',
    amount: ''
  };

  isActiveKeyboard: boolean = false;
  validAmount: boolean = true;
  trueLayerAccountList = [];
  trueLayerVerifiedAccountList: any = [];
  selectedAccount: string = '';

  currentModal: any;

  estCiviles: Array<{ id: number; name: string }>;

  swormRequired: boolean = false;
  swormAcepted: boolean = false;

  verifiedAccounts: number = 0;
  bankList$: Observable<any>;
  globalVars!: GlobalVarsModel;
  userData: any;
  isDesktop = false;
  isMobile = false;
  isUserVerified: boolean;
  withdrawalLimitsDossier = 0;

  isOperationBlocked = false;

  public errorService = inject(ErrorService);
  public fb = inject(FormBuilder);
  public navCtrl = inject(NavController);
  public modalCtrl = inject(ModalController);
  public alertCtrl = inject(AlertController);
  public navParams = inject(NavParams);
  public events = inject(EventsService);
  public translate = inject(TranslateService);
  public parseMoney = inject(ParseMoney);
  public paymentsCashierService = inject(PaymentsCashierService);
  private utilsCsService = inject(UtilsCashierService);
  private partnerConfigurationsService = inject(PartnerConfigurationsService);
  public balancesService = inject(BalancesService);
  public utils = inject(Utils);
  public userService = inject(UserService);
  public router = inject(Router);
  public newBaseService = inject(NewBaseService);
  public deviceService = inject(DeviceService);
  public bankService = inject(BankService);
  public trackingService = inject(TrackingService);
  private verificationAlertService = inject(VerificationAlerts);
  public readonly licenseMDZ = LicenseTypes.ArgentinaMendoza;
  public readonly licenseCOL = LicenseTypes.Colombia;
  private destroy$ = new Subject<void>();
  public COLRegCashierTxtActive = false;

  constructor(public override _renderer: Renderer2) {
    super(_renderer);

    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });

    this.userService
      .getUserData()
      .pipe(takeUntil(this.destroy$))
      .subscribe((userData) => {
        this.userData = userData;
      });

    this.isDesktop = this.deviceService.isDesktop();
    this.isMobile = this.deviceService.isMobile();

    switch (this.globalVars.licenseType) {
      case LicenseTypes.Mexico: {
        this.outConditions = 'https://www.codere.mx/Documents/AMH-Pol%C3%ADticas%20de%20Apuesta.pdf';
        break;
      }
      case LicenseTypes.Colombia: {
        this.outConditions = 'https://m.codere.com.co/csbgonline/condicionesgenerales/ContratoColombia.pdf';
        break;
      }
      case LicenseTypes.Panama: {
        this.outConditions = 'https://condiciones.apuestas.codere.es/Panama/condiciones_panama.html';
        break;
      }
      case LicenseTypes.ArgentinaCaba: {
        this.outConditions = 'https://m.codere.com.ar/csbgonline/condicionesgenerales/condicionespoliticaargentina.pdf';
        break;
      }
      case LicenseTypes.ArgentinaPBA: {
        this.outConditions = 'https://m.codere.com.ar/csbgonline/condicionesgenerales/condicionespoliticaargentina.pdf';
        break;
      }
      default: {
        this.outConditions =
          'https://m.apuestas.codere.es/csbgonline/condicionesgenerales/condicionesgeneralesOnlineNacional.pdf';
        break;
      }
    }

    this.calculatorConfig.license = this.globalVars.licenseType;
    this.calculatorConfig.isDesktop = this.isDesktop;
    this.calculatorConfig.currency = this.globalVars.currencyLiteral;

    this.trackingEvent.emit(['AccessToCashierWithdrawal', '', 'Icon', 'Acceder cobros online', 'event']);
  }

  ngOnInit() {
    const { FEATURES } = this.globalVars;
    const { Payments_COLRegCashierTxt_Active } = FEATURES;
    if (Payments_COLRegCashierTxt_Active) {
      if (this.utils.isMobile && this.utils.isColombia()) {
        const messageToShow = `<p>${this.translate.instant('colombiaCashiertxt')}</p><p>${this.translate.instant('colombiaCashiertxt1')}</p><p>${this.translate.instant('colombiaCashiertxt2')}</p>`;
        this.utilsCsService.showInfoAlert('', messageToShow);
      }
    }
    (async () => {
      if (this.globalVars.licenseType !== LicenseTypes.ArgentinaMendoza) {
        this.accountList = await lastValueFrom(this.userService.getUserAccounts());
      } else {
        this.accountList = [];
      }
      this.setWithDrawals();
      this.checkVerifiedAccounts();
    })();

    if (this.isArgentinaCaba) {
      this.partnerConfigurationsService
        .GetWithdrawalLimitsDossier()
        .pipe(take(1))
        .subscribe((result) => {
          this.withdrawalLimitsDossier = result;
        });
    }

    if (this.utils.isColombia()) {
      this.COLRegCashierTxtActive = this.globalVars.FEATURES.Payments_COLRegCashierTxt_Active;
    }

    this.bankList$ = this.userService.getBankList();
    this.events.publish('loading:finish');
    if (this.utils.isSpain()) {
      this.userService.getUserData().subscribe((data) => {
        const { VerifiDocumental } = data;
        this.isUserVerified = VerifiDocumental === DOCUMENT_VERIFICATION_STATES.COMPLETED;
      });
    }
  }

  async getAccuntList() {
    this.utils.loader();
    this.accountList = await this.userService.getUserAccounts().toPromise();
    this.events.publish('loading:finish');
    this.utils.closeLoader();
  }

  ngOnChanges() {
    if (this.globalVars.extraUserData.blockWithdrawals && this.step === 2) {
      this.step = 1;
    }
    if (this.sectionStep === 1 || this.sectionStep === 3) this.isActiveKeyboard = false;
    if (
      (this.sectionStep === 3 && this.currentWithdrawal.className !== 'LocalWithDrawal' && !this.userData$) ||
      (this.sectionStep === 2 && this.currentWithdrawal.className == 'DaviPlataWithdrawal' && !this.userData$) ||
      (this.sectionStep === 2 && this.currentWithdrawal.className == 'NequiWithdrawal' && !this.userData$)
    ) {
      this.userData$ = this.userService.getUserData();
    }
    this.step = this.sectionStep;
    this.move();
  }

  ngAfterViewInit() {
    this.setContainer(this.content.nativeElement);
    if (this.globalVars.licenseType == LicenseTypes.ArgentinaCaba) {
      this.showRequestMateInfoAlert();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.paymentsCashierService.cavaSwormCancel();
    this.setSectionStep.emit(1);
  }

  showCashier(trueLayerCheck: boolean = false) {
    let classUsed = this.currentWithdrawal?.className;

    let isNotTrueLayerClass = trueLayerCheck && classUsed !== 'TrueLayerWithdrawal';
    let isNotCardSelectionClass = classUsed !== 'CardSelectionWithdrawal';
    let isNotCrownCasinoClass = classUsed !== 'CrownCasinoWithdrawal';

    return (
      isNotCardSelectionClass && isNotCrownCasinoClass && isNotTrueLayerClass && !this.utils.isMendoza() // Avoid displaying the cashier when Mendoza as this license has an email process for Withdrawals.
    );
  }

  getTrackingInfo(): TrackingInfo {
    return {
      uri: encodeURI(`/CashierWithdrawals`),
      description: `CashierWithdrawals`,
      additionalData: {
        sportHandle: null
      }
    };
  }

  amountSelected(amount: any) {
    this.currentAmount = amount;
    this.validAmount = true;
  }

  setCustomAmount(customOption: any) {
    const newAmount = this.utilsCsService.setCustomAmount(customOption, this.currentAmount, this.currentWithdrawal);
    this.currentAmount = newAmount;
  }

  inputAmount(event: any) {
    this.validateInputAmount(event, this.isMobile);
  }

  setInputKeyboard() {
    if (!this.isDesktop) {
      this.isActiveKeyboard = !this.isActiveKeyboard;
      this.isActiveKeyboard ? this.keyboardOpen.emit(true) : this.keyboardOpen.emit(false);
    }
  }

  pressInputKeyBoard(event) {
    if (
      this.currentWithdrawal.className == 'CardSelectionWithdrawal' &&
      this.globalVars.licenseType == LicenseTypes.Nacional
    ) {
      this.redys.pressInputKeyBoard(event);
    } else {
      this.currentAmount = event;
    }
  }

  closeKeyBoard(event: string) {
    if (!!event) {
      this.validateInputAmount(event, true);
    } else {
      this.validateInputAmount(this.currentAmount, false);
    }
    this.isActiveKeyboard = false;
    this.keyboardOpen.emit(false);
  }

  handleInvalidAmount(realAmount: number) {
    this.validAmount = false;
    this.currentAmount =
      realAmount > this.currentWithdrawal.maxDepositAmount
        ? this.currentWithdrawal.maxDepositAmount
        : this.currentWithdrawal.minDepositAmount;
    this.utilsCsService.showInfoAlert(null, this.errorAmount);
  }

  validateInputAmount(inputAmount: string, nextStep: boolean) {
    const realAmount = parseInt(inputAmount);
    switch (this.currentWithdrawal.className) {
      case WithdrawalMethodsClassName.BANK_TRANSFER:
      case WithdrawalMethodsClassName.ITAU:
      case WithdrawalMethodsClassName.TRUE_LAYER:
      case WithdrawalMethodsClassName.CLABE:
      case WithdrawalMethodsClassName.EPG_MUCH_BETTER:
      case WithdrawalMethodsClassName.NEQUI:
      case WithdrawalMethodsClassName.DAVIPLATA:
      case WithdrawalMethodsClassName.PSE:
      case WithdrawalMethodsClassName.CARD_SELECTION:
      case WithdrawalMethodsClassName.LOTBA:
        //ValidateAmountTranfer
        if (this.validateAmount(inputAmount, this.currentWithdrawal.className)) {
          this.currentAmount = parseFloat(parseFloat(inputAmount).toFixed(2));
          this.validAmount = true;
          if (nextStep) this.checkStepWithdrawal();
        } else {
          this.handleInvalidAmount(realAmount);
        }
        break;
      case WithdrawalMethodsClassName.HAL_CASH:
        //ValidateAmountHalcash
        if (this.validateAmount(inputAmount, this.currentWithdrawal.className)) {
          this.currentAmount = parseFloat(parseFloat(inputAmount).toFixed(2));
          this.validAmount = true;
          if (nextStep) this.checkStepWithdrawal();
        } else {
          this.handleInvalidAmount(realAmount);
        }
        break;
      case WithdrawalMethodsClassName.T_PAGA:
        //ValidateAmountTpaga
        if (this.validateAmount(inputAmount, this.currentWithdrawal.className)) {
          this.currentAmount = parseFloat(parseFloat(inputAmount).toFixed(2));
          this.validAmount = true;
          if (nextStep) this.checkStepWithdrawal();
        } else {
          this.handleInvalidAmount(realAmount);
        }
        break;
      case WithdrawalMethodsClassName.PAY_PAL:
        //ValidateAmountPaypal
        if (this.validateAmount(inputAmount, this.currentWithdrawal.className)) {
          this.currentAmount = parseFloat(parseFloat(inputAmount).toFixed(2));
          this.validAmount = true;
          if (nextStep) this.checkStepWithdrawal();
        } else {
          this.handleInvalidAmount(realAmount);
        }
        break;
      case WithdrawalMethodsClassName.LOCAL:
        //ValidateAmountLocal
        if (this.validateAmount(inputAmount, this.currentWithdrawal.className)) {
          this.currentAmount = parseFloat(parseFloat(inputAmount).toFixed(2));
          this.validAmount = true;
          if (nextStep) this.checkStepWithdrawal();
        } else {
          this.handleInvalidAmount(realAmount);
        }
        break;
      case WithdrawalMethodsClassName.ASTROPAY:
        //ValidateAmountAstropay
        if (this.validateAmount(inputAmount, this.currentWithdrawal.className)) {
          this.currentAmount = parseFloat(parseFloat(inputAmount).toFixed(2));
          this.validAmount = true;
          if (nextStep) this.checkStepWithdrawal();
        } else {
          this.handleInvalidAmount(realAmount);
        }
        break;
      case WithdrawalMethodsClassName.EFECTY:
        //ValidateAmountEfecty
        if (this.validateAmount(inputAmount, this.currentWithdrawal.className)) {
          this.currentAmount = parseFloat(parseFloat(inputAmount).toFixed(2));
          this.validAmount = true;
          if (nextStep) this.checkStepWithdrawal();
        } else {
          this.handleInvalidAmount(realAmount);
        }
        break;
    }
  }

  async openMoreInfoHalcash() {
    const modal = await this.modalCtrl.create({
      component: CashierHalcashInfoPage,
      cssClass: 'is-modal'
    });
    await modal.present();
  }

  async openModalSworm() {
    const fundsOrigin: Array<FundsOrigin> = [];

    this.utils.loader();

    try {
      const origin = await this.bankService.getFundsOrigin().toPromise();
      fundsOrigin.push(...origin);

      const modalMoreInfo = await this.modalCtrl.create({
        component: CashierWithdrawalsSworm,
        componentProps: { fundsOrigin: fundsOrigin, swormTitle: this.translate.instant('origenDeFondosCobro') }
      });

      await modalMoreInfo.present();

      const { data } = await modalMoreInfo.onDidDismiss<ModalResult>();

      if (data) {
        this.fundOriginSelected = data.originSelected.name;
        this.userService
          .updateOriginOfFunds(this.fundOriginSelected)
          .pipe(takeUntil(this.destroy$))
          .subscribe({
            next: async () => {},
            error: (err) => {
              console.error(err);
            },
            complete: () => {
              if (data.swornAccepted) {
                this.setSectionStep.emit(3);
              }
            }
          });
      }
    } catch (err) {
      console.error(err);
    } finally {
      this.utils.closeLoader();
    }
  }

  showInfo(option) {
    switch (option) {
      case 'halCash':
        this.openMoreInfoHalcash();
        break;
      case 'swift':
        this.utilsCsService.showInfoAlert(this.translate.instant('swift_bic'), this.translate.instant('swift_bichelp'));
        break;
      case 'iban':
        this.utilsCsService.showInfoAlert(this.translate.instant('TCobIban'), this.translate.instant('TCobEjIban'));
        break;
      case 'claveSecreta':
        this.utilsCsService.showInfoAlert(this.translate.instant('tKey'), this.translate.instant('tKeyClaveCajero'));
        break;
    }
  }

  setInputKeyboardtl(state: boolean) {
    if (this.isDesktop) {
      this.isActiveKeyboard = !this.isActiveKeyboard;
      state ? this.keyboardOpen.emit(true) : this.keyboardOpen.emit(false);
    }
  }
  selectOption(withdrawal) {
    this.verificationAlertService.checkDNIExpired();
    if (!this.globalVars.extraUserData.blockWithdrawals) {
      this.setInputKeyboardtl(false);
      this.isActiveKeyboard = false;
      this.stateDepositButton = false;
      this.utilsCsService.setInitTruelayer();
      this.currentWithdrawal = withdrawal;
      this.currentAmount = withdrawal.amount;
      this.paymentSelection.emit(withdrawal);

      if (this.utils.isSpain()) {
        if (withdrawal.number !== 7) {
          this.checkUserVerification();
        }
      }

      if (withdrawal.number === 7) {
        this.globalVars.FEATURES.MexCobLocalEnabled && this.utils.isMexico()
          ? this.bindBarcode()
          : this.checkChargePend();
      } else if (withdrawal.number !== 5 && !this.utils.isMexico()) {
        if (withdrawal.enabled) {
          this.trackingEvent.emit([
            this.currentWithdrawal.track,
            this.currentWithdrawal.className,
            this.currentWithdrawal.amount.toString(),
            '',
            'event'
          ]);
          console.log('222');
          this.setSectionStep.emit(2);
        } else {
          this.utilsCsService.showInfoAlert(this.translate.instant('Attention'), this.msgCheckBank);
        }
      } else {
        switch (withdrawal.number) {
          case 2:
            this.globalVars.clabeBanksWithDrawalEnabled = false;
            break;
          case 3:
            this.globalVars.clabeBanksWithDrawalEnabled = true;
            break;
          default:
            this.globalVars.clabeBanksWithDrawalEnabled = false;
            break;
        }
        if (withdrawal.enabled) {
          this.trackingEvent.emit([
            this.currentWithdrawal.track,
            this.currentWithdrawal.className,
            this.currentWithdrawal.amount.toString(),
            '',
            'event'
          ]);
          this.setSectionStep.emit(3);
        } else {
          this.utilsCsService.showInfoAlert(this.translate.instant('Attention'), this.msgCheckBank);
        }
      }
      this.currentWithdrawal = withdrawal;
      this.globalVars.paymentType = 'withdrawal';
    }
  }

  bindBarcode() {
    this.userService.createLocalChargeMex().subscribe(
      (data) => {
        var transactionResult = <TransactionActionResult>data;
        if (transactionResult.success) {
          this.setGenerateCode(transactionResult);
        } else {
          this.setSectionStep.emit(1);
          this.errorService
            .searchErrorCodeWithParams(transactionResult.errCode.toString(), transactionResult.errorParameters)
            .subscribe(
              (data2) => {
                if (transactionResult.errCode === 174) {
                  this.showError(transactionResult.errCode, data2);
                } else {
                  this.utils.showError(data2, () => {});
                }
              },
              () => {
                this.utils.showError(transactionResult.errMessage, () => {});
              }
            );
        }
      },
      (err) => {
        this.setSectionStep.emit(1);
        this.utils.showError(err, () => {});
      }
    );
  }

  closeModal() {
    if (this.currentModal) {
      this.currentModal.dismiss();
    }
  }

  async hydrateRetenctionFeature() {
    const nameFeature = `PayRW_${this.currentWithdrawal.className}`;
    let retVal = null;
    try {
      retVal = JSON.parse(this.globalVars.FEATURES[nameFeature]);
    } catch (e) {
      retVal = null;
    }
    return retVal;
  }

  async checkIsRetention(retVal: any) {
    if (retVal === null) {
      return false;
    } else {
      if (this.currentAmount >= retVal?.amount) {
        return true;
      } else {
        return false;
      }
    }
  }

  async checkStepWithdrawal() {
    const retentionValue = await this.hydrateRetenctionFeature();
    let uvtConversion = { year: 0, rate: 0 };
    try {
      uvtConversion = JSON.parse(this.globalVars.FEATURES.PayRW_UVT);
    } catch (e) {}
    const isRetention = await this.checkIsRetention(retentionValue);
    let isAcceptedRetention = !isRetention ? true : false;

    console.log(this.translate.instant('co_retention_message'));

    if (isRetention) {
      const titlePopUp = `Si el monto a cobrar es igual o superior a ${(retentionValue?.amount / uvtConversion?.rate).toFixed(2)} UVT ($${this.parseMoney.transform(retentionValue?.amount).replace('$', '')} para ${uvtConversion?.year}), debemos aplicar la retención del ${retentionValue.retention}%, como retención en la fuente.`;
      const modalConfirm = await this.modalCtrl.create({
        component: CsConfirmModal,
        componentProps: {
          title: 'Retención',
          subtitle: titlePopUp
        },
        cssClass: 'cs-request-info'
      });
      await modalConfirm.present();
      const { data } = await modalConfirm.onDidDismiss();
      isAcceptedRetention = data;
    }

    if (isAcceptedRetention) {
      const isArgentinaCaba = this.globalVars.licenseType == LicenseTypes.ArgentinaCaba;
      const isArgentinaMendoza = this.globalVars.licenseType == LicenseTypes.ArgentinaMendoza;
      const showOriginOfFunds = this.globalVars.FEATURES.showOriginOfFunds;
      if ((isArgentinaCaba || isArgentinaMendoza) && !this.globalVars.originFunds && showOriginOfFunds) {
        this.openModalSworm();
        if (!this.swormAcepted) {
          return;
        }
      }
      if (!this.validAmount) {
        this.validAmount = true;
        return;
      }
      if (isArgentinaCaba && this.globalVars.isOrdenCongelamientoFondos) {
        this.utils.alert(false, 'Error', this.translate.instant('accountanalysischarge'), 'OK', () => {});
        return;
      }
      switch (this.currentWithdrawal.className) {
        case WithdrawalMethodsClassName.HAL_CASH:
          //ValidateAmountHalcash
          if (this.validateAmount(this.currentAmount, this.currentWithdrawal.className)) {
            this.setSectionStep.emit(3);
          } else {
            this.utilsCsService.showInfoAlert(null, this.errorAmount);
          }
          break;
        case WithdrawalMethodsClassName.TRUE_LAYER:
          //ValidateAmountTranfer
          if (this.validateAmount(this.currentAmount, this.currentWithdrawal.className)) {
            this.emitTrueLayerWidthrawal();
          } else {
            this.utilsCsService.showInfoAlert(null, this.errorAmount);
          }
          break;
        case WithdrawalMethodsClassName.COINS_PAID:
          //ValidateAmountTranfer
          if (this.validateAmount(this.currentAmount, this.currentWithdrawal.className)) {
            this.emitCoinspaidWithdrawal(this.currentAddress);
          } else {
            this.utilsCsService.showInfoAlert(null, this.errorAmount);
          }
          break;
        case WithdrawalMethodsClassName.BANK_TRANSFER:
          //ValidateAmountTranfer
          if (this.validateAmount(this.currentAmount, this.currentWithdrawal.className)) {
            this.setSectionStep.emit(3);
          } else {
            this.utilsCsService.showInfoAlert(null, this.errorAmount);
          }
          break;
        case WithdrawalMethodsClassName.ITAU:
          //ValidateAmountTranfer
          if (this.validateAmount(this.currentAmount, this.currentWithdrawal.className)) {
            this.setSectionStep.emit(3);
          } else {
            this.utilsCsService.showInfoAlert(null, this.errorAmount);
          }
          break;
        case WithdrawalMethodsClassName.CLABE:
          //ValidateAmountTranfer
          if (this.validateAmount(this.currentAmount, this.currentWithdrawal.className)) {
            this.setSectionStep.emit(3);
          } else {
            this.utilsCsService.showInfoAlert(null, this.errorAmount);
          }
          break;
        case WithdrawalMethodsClassName.T_PAGA:
          //ValidateAmountTpaga
          if (this.validateAmount(this.currentAmount, this.currentWithdrawal.className)) {
            this.emitTpagaWithdrawal();
          } else {
            this.utilsCsService.showInfoAlert(null, this.errorAmount);
          }
          break;
        case WithdrawalMethodsClassName.PAY_PAL:
          //ValidateAmountPaypal
          if (this.validateAmount(this.currentAmount, this.currentWithdrawal.className)) {
            this.emitPaypalWithdrawal();
          } else {
            this.utilsCsService.showInfoAlert(null, this.errorAmount);
          }
          break;
        case WithdrawalMethodsClassName.LOCAL:
          //ValidateAmountLocal
          if (this.validateAmount(this.currentAmount, this.currentWithdrawal.className)) {
            this.emitLocalWithdrawal();
          } else {
            this.utilsCsService.showInfoAlert(null, this.errorAmount);
          }
          break;
        case WithdrawalMethodsClassName.EPG_MUCH_BETTER:
          //ValidateAmountTranfer
          if (this.validateAmount(this.currentAmount, this.currentWithdrawal.className)) {
            this.emitMuchBetterWidthrawal();
          } else {
            this.utilsCsService.showInfoAlert(null, this.errorAmount);
          }
          break;
        case WithdrawalMethodsClassName.NEQUI:
          //ValidateCustomAmount
          if (this.validateAmount(this.currentAmount, this.currentWithdrawal.className)) {
            this.emitNequiWidthrawal();
          } else {
            this.utilsCsService.showInfoAlert(null, this.errorAmount);
          }
          break;
        case WithdrawalMethodsClassName.DAVIPLATA:
          //ValidateAmountTranfer
          if (this.validateAmount(this.currentAmount, this.currentWithdrawal.className)) {
            this.emitDaviplataWidthrawal();
          } else {
            this.utilsCsService.showInfoAlert(null, this.errorAmount);
          }
          break;
        case WithdrawalMethodsClassName.PSE:
          //ValidateAmountTranfer
          if (this.validateAmount(this.currentAmount, this.currentWithdrawal.className)) {
            this.emitPSEWidthrawal();
          } else {
            this.utilsCsService.showInfoAlert(null, this.errorAmount);
          }
          break;

        // Redsys implementation Withdrawal
        case WithdrawalMethodsClassName.CARD_SELECTION:
          //ValidateAmountTranfer
          if (this.validateAmount(this.currentAmount, this.currentWithdrawal.className)) {
            this.setSectionStep.emit(3);
          } else {
            this.utilsCsService.showInfoAlert(null, this.errorAmount);
          }
          break;
        case WithdrawalMethodsClassName.ASTROPAY:
          //ValidateAmountTranfer
          if (this.validateAmount(this.currentAmount, this.currentWithdrawal.className)) {
            this.emitAstropayWidthrawal();
          } else {
            this.utilsCsService.showInfoAlert(null, this.errorAmount);
          }
          break;
        case WithdrawalMethodsClassName.LOTBA:
          //ValidateAmountTranfer
          if (this.validateAmount(this.currentAmount, this.currentWithdrawal.className)) {
            this.emitLotBaWithdrawal();
          } else {
            this.utilsCsService.showInfoAlert(null, this.errorAmount);
          }
          break;
        case WithdrawalMethodsClassName.EFECTY:
          //ValidateAmountEfecty
          if (this.validateAmount(this.currentAmount, this.currentWithdrawal.className)) {
            this.emitEfectyWithdrawal();
          } else {
            this.utilsCsService.showInfoAlert(null, this.errorAmount);
          }
          break;
      }
    }
  }

  checkChargePend() {
    this.userService.checkPayment(this.globalVars.user.username).subscribe(
      (data) => {
        let logError: string = '';
        if (data.sessionOut) {
          this.events.publish('user:serverfail');
        } else {
          if (!data.success) {
            logError = this.translate.instant('m_menu_noLater');
            if (data.errMessage != undefined || data.errMessage != '') {
              if (!isNaN(data.errMessage)) {
                this.errorService.searchErrorCode(data.errMessage).subscribe(
                  (data2) => {
                    this.utils.showError(data2, () => this.checkUserVerification());
                  },
                  (err) => {
                    this.utils.showError(logError, null);
                  }
                );
              } else {
                this.utils.showError(data.errMessage, () => this.checkUserVerification());
              }
            } else {
              this.utils.showError(logError, null);
            }
          } else {
            const transactionResult = <TransactionActionResult>data;
            if (transactionResult.success && !!transactionResult.transactionId) {
              this.setGenerateCode(transactionResult);
            } else if (!this.globalVars.extraUserData.blockWithdrawals) {
              this.setSectionStep.emit(2);
            }
          }
        }
      },
      (err) => {
        this.events.publish('loader:dismiss', null);
        this.events.publish('user:serverfail');
      }
    );
  }

  setGenerateCode(transactionResult: TransactionActionResult) {
    if (transactionResult.amount > 0 && transactionResult.amount != transactionResult.netAmount) {
      this.localConfig.amountTaxes =
        ' (Retención de ' + this.parseMoney.transform(transactionResult.taxAmount.toString(), [1]) + ')';
      this.localConfig.amountCharge = transactionResult.netAmount.toString();
    } else {
      this.localConfig.amountCharge = transactionResult.amount.toString();
      this.localConfig.amountTaxes = '';
    }
    this.localConfig.code = transactionResult.transactionId;
    this.localConfig.userName = this.globalVars.user.username;
    this.localConfig.userData = this.userData;
    this.localConfig.barcode =
      "<ul id='barcode'>" + this.utils.generateBarcode(transactionResult.transactionId) + '</ul>';
    this.events.publish('badges:refresh');
    this.balancesService.refreshBalance(true);
    this.isLocalTicket.emit(true);
    this.setSectionStep.emit(3);
    this.trackingEvent.emit([
      this.currentWithdrawal.track,
      this.currentWithdrawal.className,
      transactionResult.amount.toString(),
      'Acceder a cobros en local',
      'event'
    ]);
  }

  private showError(transactionErrorCode: number, message: string, closeErrorCallback?: any) {
    if (transactionErrorCode == 174 || transactionErrorCode == 2212) {
      this.utils.confirm(
        false,
        this.translate.instant('tFail'),
        message,
        this.translate.instant('tCancel'),
        !closeErrorCallback ? () => {} : closeErrorCallback,
        this.translate.instant('check'),
        () => {
          setTimeout(() => {
            this.trackingEvent.emit(['NotDocumentationVerifiedOnCharge', message, '', '', 'event']);
            this.sendToDocuments.emit();
          }, 800);
        }
      );
    } else if (transactionErrorCode == 2412) {
      this.utils.confirm(
        false,
        this.translate.instant('Attention'),
        this.translate.instant('V2_profesionRequest'),
        this.translate.instant('tCancel'),
        null,
        this.translate.instant('tCont'),
        () => {
          this.GotoPersonaInfoPage();
        },
        true
      );
    } else {
      this.utilsCsService.showInfoAlert('Error', message);
    }
  }

  GotoPersonaInfoPage(params?: any) {
    if (this.isDesktop) {
      this.modalCtrl.dismiss({ animate: false }).then(() => {
        this.router.navigate([`/${MSO_PATHS.BASE}/${MSO_PATHS.PersonalInfoPage}`], {
          queryParams: { ...params, animate: !this.isDesktop }
        });
      });
    } else {
      this.router.navigate([`/${MSO_PATHS.BASE}/${MSO_PATHS.PersonalInfoPage}`], {
        queryParams: { animate: !this.isDesktop }
      });
    }
  }
  cancelLocalCode(localConfig) {
    this.userService.cancelTransaction(localConfig.code, localConfig.amountCharge).subscribe(
      (data) => {
        if (data.success) {
          this.localConfig.code = '';
          this.localConfig.amountCharge = '';
          this.localConfig.barcode = '';
          this.balancesService.refreshBalance(true);
          this.utilsCsService.showInfoAlert(this.translate.instant('tInf'), this.translate.instant('chargeCodere02'));
          this.events.publish('badges:refresh');
          this.setSectionStep.emit(2);
          this.trackingEvent.emit([
            'CashierDeleteLocalWithdrawal',
            this.localConfig.code,
            '',
            'Borrar cobro en local cantidad',
            'event'
          ]);
        } else {
          this.utils.showError(this.translate.instant('m_menu_noLater'));
        }
      },
      (_) => {
        this.events.publish('user:serverfail');
      }
    );
  }

  async showRequestOkInfoModal(paymentArguments: any) {
    const modal = await this.modalCtrl.create({
      component: CashierRequestInfoPageOk,
      componentProps: {
        paymentArguments
      },
      cssClass: 'cs-request-info'
    });
    await modal.present();
    modal.onDidDismiss().then(() => {
      this.modalCtrl.dismiss();
      this.globalVars.rootScope.tryToGoPage(this.globalVars.mainPageApp);
    });
  }

  async showRequestKoInfoModal(paymentArguments: any) {
    const modal = await this.modalCtrl.create({
      component: CashierRequestInfoPageKo,
      componentProps: {
        paymentArguments
      },
      cssClass: 'cs-request-info'
    });
    modal.present();
    modal.onDidDismiss().then((docSection) => {
      if (docSection.data !== undefined) {
        setTimeout(() => {
          this.trackingEvent.emit(['NotDocumentationVerifiedOnCharge', paymentArguments.error, '', '', 'event']);
          this.sendToDocuments.emit();
        }, 800);
        this.modalCtrl.dismiss();
      }
    });
  }

  linkOutOfModalPage(link, paymentArguments: any) {
    if (this.isMobile) {
      this.router.navigate([link, paymentArguments], {
        queryParams: { animate: !this.isDesktop }
      });
    } else {
      this.modalCtrl.dismiss(['null', 'null', { animate: false }]).then(() => {
        this.router.navigate([link, paymentArguments], {
          queryParams: { animate: !this.isDesktop }
        });
      });
    }
  }

  linkOutConditions() {
    window.open(this.outConditions, '_blank');
  }

  openCodereLocals() {
    this.modalCtrl.dismiss();
    this.router.navigate([`/${MSO_PATHS.BASE}/${MSO_PATHS.NearestLocalPage}`]);
  }

  /**
   * SOLO EN ARGENTINA
   * Solicita Info de la pareja antes de permitir un cobro
   */
  showRequestMateInfoAlert(): void {
    this.estCiviles = this.utils.getCivilStatesAR();
    this.userService.getUserData().subscribe((data) => {
      this.utils.isArgentina() ? (this.globalVars.originFunds = data.OrigenFondos) : null;
      if (
        (data.Estado == 'Casado/a' || data.Estado == 'Unión Civil' || data.Estado == 'Unión de Hecho') &&
        (!data.PersoncloselyrelatedwithplayerName ||
          !data.PersoncloselyrelatedwithplayerLastname ||
          !data.PersoncloselyrelatedwithplayerNationality ||
          !data.PersoncloselyrelatedwithplayerTipodocumento ||
          !data.PersoncloselyrelatedwithplayerNif ||
          !data.PersoncloselyrelatedwithplayerTipoidentificaciontributaria ||
          !data.PersoncloselyrelatedwithplayerIdentificadortatributario)
      ) {
        const message = this.translate.instant('conyugecashier'); //'Para comenzar el proceso de cobro te solicitamos que completes unos datos adicionales. ¡Muchas gracias!'
        this.utils.confirm(
          false,
          '',
          message,
          'Cancelar',
          () => {
            if (this.deviceService.isDesktop()) {
              this.modalCtrl.dismiss().then(() => {
                this.router.navigate(['/HomePage']);
              });
            } else {
              this.router.navigate(['/HomePage']);
            }
          },
          'Actualizar',
          () => {
            if (this.deviceService.isDesktop()) {
              this.modalCtrl.dismiss().then(() => {
                this.router.navigate([`/${MSO_PATHS.BASE}/${MSO_PATHS.PersonalInfoPage}`]);
              });
            } else {
              this.router.navigate([`/${MSO_PATHS.BASE}/${MSO_PATHS.PersonalInfoPage}`]);
            }
          }
        );
      }
    });
  }

  fillAmountButtons(withdrawal) {
    const amountButtons = this.globalVars.FEATURES.amountChargeValues?.split(';').map((button) => parseInt(button));
    if (amountButtons && withdrawal.className === WithdrawalMethodsClassName.PAY_PAL && amountButtons[0] == 10) {
      amountButtons[0] = 15;
    }
    return amountButtons;
  }

  private setWithDrawals() {
    const withDrawalFactory = new WithDrawalFactory();
    const JSONWithdrawals$ = this.userService.getWithdrawalsMethods();
    const HalCashEnabled$ = this.globalVars.FEATURES.CobHalCashEnabled
      ? this.userService.getHalcashAvaliable()
      : of({ success: false });
    const TpagaEnabled = this.globalVars.FEATURES.TpagaPaymentEnabled;
    const PaypalEnabled = this.globalVars.FEATURES.CobPayPalEnabled;
    const CheckBankTransfers$ =
      this.globalVars.licenseType !== LicenseTypes.ArgentinaMendoza
        ? this.userService.CheckBankTransfers()
        : of({ success: true });
    const EPGMuchBetterWithDrawalEnabled = this.globalVars.FEATURES.EPGMuchBetterWithDrawalEnabled;
    const NequiWithDrawalEnabled = this.globalVars.FEATURES.NequiWithDrawalEnabled;
    const DaviPlataWithDrawalEnabled = this.globalVars.FEATURES.DaviPlataWithDrawalEnabled;
    const PSEWithDrawalEnabled = this.globalVars.FEATURES.PSEWithDrawalEnabled;
    const TrueLayerWithdrawalEnabled = this.checkTrueLayer();
    const CardSelectionWithdrawal$ = this.globalVars.FEATURES.CardSelectionWithdrawal
      ? this.userService.getCardsAvaliability()
      : of({ success: false });
    const AstropayWithdrawalEnabled = this.globalVars.FEATURES.AstropayWithdrawalEnabled;
    const CrownCasinoWithdrawalEnabled = this.globalVars.FEATURES.CrowCasinoWDEnabled;
    const LotbaWithdrawalEnabled = this.globalVars.FEATURES.LotbaWithdrawalEnabled;
    this.withdrawals$ = forkJoin({
      withdrawals: JSONWithdrawals$,
      HalCashEnabled: HalCashEnabled$,
      CheckBankTransfers: CheckBankTransfers$,
      CardSelectionWithdrawal: CardSelectionWithdrawal$
    }).pipe(
      map(({ withdrawals, HalCashEnabled, CheckBankTransfers, CardSelectionWithdrawal }) => {
        if (!CheckBankTransfers.success && CheckBankTransfers.errCode !== -1) {
          // -1 means new user, this logic does not apply
          CheckBankTransfers.errCode === 0
            ? (this.msgCheckBank = this.translate.instant('errorTransBank'))
            : (this.msgCheckBank = CheckBankTransfers.errMessage);
          if (this.msgCheckBank) {
            this.utilsCsService.showInfoAlert(this.translate.instant('Attention'), this.msgCheckBank);
          }
        }
        const setWithdrawals = withdrawals
          .map((withdrawal) => {
            switch (withdrawal.className) {
              case WithdrawalMethodsClassName.CLABE:
                withdrawal.enabled = CheckBankTransfers.success;
                break;
              case WithdrawalMethodsClassName.BANK_TRANSFER:
                withdrawal.enabled =
                  CheckBankTransfers.success || (!CheckBankTransfers.success && CheckBankTransfers.errCode === -1);
                break;
              case WithdrawalMethodsClassName.ITAU:
                withdrawal.enabled = CheckBankTransfers.success;
                break;
              case WithdrawalMethodsClassName.T_PAGA:
                withdrawal.enabled = CheckBankTransfers.success;
                break;
            }
            if (withdrawal.amountButtons?.length > 0) {
              this.fillAmountButtons(withdrawal);
            } else {
              withdrawal.amountButtons = [];
            }
            return withdrawal;
          })
          .filter(
            (withdrawal) =>
              withdrawal.className === WithdrawalMethodsClassName.BANK_TRANSFER ||
              withdrawal.className === WithdrawalMethodsClassName.ITAU ||
              withdrawal.className === WithdrawalMethodsClassName.LOCAL ||
              withdrawal.className === WithdrawalMethodsClassName.CLABE ||
              withdrawal.className === WithdrawalMethodsClassName.EFECTY ||
              (withdrawal.className === WithdrawalMethodsClassName.EPG_MUCH_BETTER && EPGMuchBetterWithDrawalEnabled) ||
              (withdrawal.className === WithdrawalMethodsClassName.PAY_PAL && PaypalEnabled) ||
              (withdrawal.className === WithdrawalMethodsClassName.HAL_CASH && HalCashEnabled.success) ||
              (withdrawal.className === WithdrawalMethodsClassName.T_PAGA && TpagaEnabled) ||
              withdrawal.className === WithdrawalMethodsClassName.COINS_PAID ||
              (withdrawal.className === WithdrawalMethodsClassName.NEQUI && NequiWithDrawalEnabled) ||
              (withdrawal.className === WithdrawalMethodsClassName.DAVIPLATA && DaviPlataWithDrawalEnabled) ||
              (withdrawal.className === WithdrawalMethodsClassName.PSE && PSEWithDrawalEnabled) ||
              withdrawal.className === WithdrawalMethodsClassName.TRUE_LAYER ||
              (withdrawal.className === WithdrawalMethodsClassName.CARD_SELECTION && CardSelectionWithdrawal.success) ||
              (withdrawal.className === WithdrawalMethodsClassName.ASTROPAY && AstropayWithdrawalEnabled) ||
              (withdrawal.className === WithdrawalMethodsClassName.CROWN_CASINO && CrownCasinoWithdrawalEnabled) ||
              (withdrawal.className === WithdrawalMethodsClassName.LOTBA && LotbaWithdrawalEnabled)
          );

        const wd = setWithdrawals.map((wd) => withDrawalFactory.create(wd.className, wd));
        return wd;
      })
    );
  }

  private checkTrueLayer() {
    if (this.globalVars.licenseType == LicenseTypes.Nacional) {
      this.trueLayerAccountList = this.accountList;
      this.trueLayerAccountList.forEach((account, index) => {
        if (account.verified) {
          if (index === 0) {
            this.selectedAccount = account.iban;
          }
          this.trueLayerVerifiedAccountList.push(account);
        }
      });
      let verifiedAccount = false;
      this.trueLayerAccountList.map((account) => {
        if (account.verified) {
          verifiedAccount = true;
        }
      });
      return verifiedAccount && this.globalVars.FEATURES.TrueLayerWithdrawalEnabled;
    } else {
      return false;
    }
  }

  emitPaypalWithdrawal() {
    this.currentWithdrawal.amount = this.currentAmount;
    this.currentWithdrawal.executeRequest = () =>
      this.userService.checkCreateChargeByPaypal(this.currentWithdrawal.amount);
    this.executeWithdrawal();
  }

  emitCoinspaidWithdrawal(address) {
    this.currentWithdrawal.amount = this.currentAmount;
    this.currentWithdrawal.executeRequest = () =>
      this.userService.createChargeByCoinspaid(this.currentWithdrawal.amount, address);
    this.executeWithdrawal();
  }

  emitTpagaWithdrawal() {
    this.currentWithdrawal.amount = this.currentAmount;
    this.utils.loader();
    this.currentWithdrawal.checkPayment = () =>
      this.userService.checkCreateChargeByTpaga(this.currentWithdrawal.amount);
    this.currentWithdrawal.executeRequest = () => this.userService.createChargeByTpaga(this.currentWithdrawal.amount);
    this.currentWithdrawal.checkPayment().subscribe(
      (transactionResult: TransactionActionResult) => {
        this.events.publish('loader:dismiss', null);
        if (transactionResult.success) {
          this.optionsRequestOk.title = 'Tpaga';
          this.optionsRequestOk.subtitle = `${this.translate.instant('withdrawalrequest')} ${this.translate.instant(
            'enterapp'
          )} ${this.translate.instant('writeus')}`;
          this.optionsRequestOk.paymentStatus = 'OK';
          if (transactionResult.amount != transactionResult.netAmount) {
            this.utils.showAlertChargeWithTaxes(
              transactionResult.netAmount,
              transactionResult.taxAmount,
              () => {
                this.executeWithdrawal();
              },
              this.translate.instant('impCobro'),
              this.translate.instant('tAccept'),
              this.translate.instant('tCancel')
            );
          } else {
            this.executeWithdrawal();
          }
        } else {
          this.errorService.searchErrorCode(transactionResult.errCode.toString()).subscribe(
            (data) => {
              this.optionsRequestKo.error = data;
              this.showRequestKoInfoModal(this.optionsRequestKo);
            },
            (err) => {
              this.optionsRequestKo.error = transactionResult.errMessage;
              this.showRequestKoInfoModal(this.optionsRequestKo);
            }
          );
        }
      },
      (error) => {
        console.log(error);
        this.events.publish('user:serverfail:soft');
      }
    );
  }

  emitLocalWithdrawal() {
    this.currentWithdrawal.amount = this.currentAmount;
    this.currentWithdrawal.userName = this.globalVars.user.username;
    this.currentWithdrawal.checkPayment = () =>
      this.userService.checkCreateChargeByLocalCodere(
        this.currentWithdrawal.userName,
        this.currentWithdrawal.amount.toString()
      );
    this.currentWithdrawal.executeRequest = () =>
      this.userService.createChargeByLocalCodere(
        this.currentWithdrawal.userName,
        this.currentWithdrawal.amount.toString()
      );
    if (this.globalVars.licenseType == LicenseTypes.Colombia || this.globalVars.licenseType == LicenseTypes.Mexico) {
      this.currentWithdrawal.checkPayment().subscribe(
        (transactionResult: TransactionActionResult) => {
          if (transactionResult.success) {
            if (transactionResult.amount != transactionResult.netAmount) {
              this.utils.showAlertChargeWithTaxes(
                transactionResult.netAmount,
                transactionResult.taxAmount,
                () => {
                  this.executeWithdrawal();
                },
                this.translate.instant('impCobro'),
                this.translate.instant('tAccept'),
                this.translate.instant('tCancel')
              );
            } else {
              this.executeWithdrawal();
            }
          } else {
            this.errorService
              .searchErrorCodeWithParams(transactionResult.errCode.toString(), transactionResult.errorParameters)
              .subscribe(
                (data) => {
                  this.showError(transactionResult.errCode, data);
                },
                () => {
                  this.utils.showError(transactionResult.errMessage, null);
                }
              );
          }
        },
        (error) => {
          console.log(error);
          this.events.publish('user:serverfail:soft');
        },
        () => {}
      );
    } else {
      this.executeWithdrawal();
    }
  }

  emitHalcashWithdrawal(formHalcash) {
    this.currentWithdrawal.tlf = formHalcash.tlf;
    this.currentWithdrawal.pincode = formHalcash.pincode;
    this.currentWithdrawal.amount = this.currentAmount;
    this.currentWithdrawal.executeRequest = () =>
      this.userService.createChargeByHalcash(
        this.currentWithdrawal.amount,
        this.currentWithdrawal.tlf,
        this.currentWithdrawal.pincode
      );
    this.executeWithdrawal();
  }

  emitTransferWithdrawal(formTransfer) {
    let account = formTransfer.account.replace(/\s+/g, '');
    this.currentWithdrawal.userName = this.globalVars.user.username;
    this.currentWithdrawal.incumbent = formTransfer.name;
    this.currentWithdrawal.account = account;
    this.currentWithdrawal.amount = this.currentAmount;
    this.currentWithdrawal.details = formTransfer.swiftbic;
    this.currentWithdrawal.typeaccount = formTransfer.type;
    this.currentWithdrawal.checkPayment = () =>
      this.userService.checkChargeByTransfer(
        this.currentWithdrawal.userName,
        this.currentWithdrawal.incumbent,
        this.currentWithdrawal.amount,
        this.currentWithdrawal.account,
        this.currentWithdrawal.details,
        this.currentWithdrawal.typeaccount
      );
    if (this.currentWithdrawal.className == WithdrawalMethodsClassName.ITAU) {
      this.currentWithdrawal.executeRequest = () =>
        this.userService.chargeByTransferITAU(
          this.currentWithdrawal.userName,
          this.currentWithdrawal.incumbent,
          this.currentWithdrawal.amount,
          this.currentWithdrawal.account,
          this.currentWithdrawal.details,
          this.currentWithdrawal.typeaccount,
          this.fundOriginSelected
        );
    } else {
      this.currentWithdrawal.executeRequest = () =>
        this.userService.chargeByTransfer(
          this.currentWithdrawal.userName,
          this.currentWithdrawal.incumbent,
          this.currentWithdrawal.amount,
          this.currentWithdrawal.account,
          this.currentWithdrawal.details,
          this.currentWithdrawal.typeaccount
        );
    }
    if (this.globalVars.licenseType == LicenseTypes.Colombia || this.globalVars.licenseType == LicenseTypes.Mexico) {
      this.utils.loader();
      this.currentWithdrawal.checkPayment().subscribe(
        (transactionResult: TransactionActionResult) => {
          this.events.publish('loader:dismiss', null);
          if (transactionResult.success) {
            if (transactionResult.amount != transactionResult.netAmount) {
              this.utils.showAlertChargeWithTaxes(
                transactionResult.netAmount,
                transactionResult.taxAmount,
                () => {
                  this.executeWithdrawal();
                },
                this.translate.instant('impCobro'),
                this.translate.instant('tAccept'),
                this.translate.instant('tCancel')
              );
            } else {
              this.executeWithdrawal();
            }
          } else {
            this.errorService.searchErrorCode(transactionResult.errCode.toString()).subscribe(
              (data) => {
                this.optionsRequestKo.error = data;
                this.showRequestKoInfoModal(this.optionsRequestKo);
              },
              (err) => {
                this.optionsRequestKo.error = transactionResult.errMessage;
                this.showRequestKoInfoModal(this.optionsRequestKo);
              }
            );
          }
        },
        (error) => {
          console.log(error);
          this.events.publish('user:serverfail:soft');
        },
        () => {}
      );
    } else {
      this.executeWithdrawal();
    }
  }

  emitAstropayTransferWithdrawal(formTransfer) {
    let account = formTransfer.account.replace(/\s+/g, '');
    this.currentWithdrawal.userName = this.globalVars.user.username;
    this.currentWithdrawal.incumbent = formTransfer.name;
    this.currentWithdrawal.account = account;
    this.currentWithdrawal.amount = this.currentAmount;
    this.currentWithdrawal.details = formTransfer.swiftbic;
    this.currentWithdrawal.typeaccount = formTransfer.type;
    this.currentWithdrawal.checkPayment = () =>
      this.userService.checkChargeByTransfer(
        this.currentWithdrawal.userName,
        this.currentWithdrawal.incumbent,
        this.currentWithdrawal.amount,
        this.currentWithdrawal.account,
        this.currentWithdrawal.details,
        this.currentWithdrawal.typeaccount
      );
    this.currentWithdrawal.executeRequest = () =>
      this.userService.BankTransferDirect24(
        this.currentWithdrawal.amount,
        formTransfer.swiftbic,
        this.currentWithdrawal.account,
        this.currentWithdrawal.incumbent
      );
    this.utils.loader();
    this.currentWithdrawal.checkPayment().subscribe(
      (transactionResult: TransactionActionResult) => {
        this.events.publish('loader:dismiss', null);
        if (transactionResult.success) {
          if (transactionResult.amount != transactionResult.netAmount) {
            this.utils.showAlertChargeWithTaxes(
              transactionResult.netAmount,
              transactionResult.taxAmount,
              () => {
                this.executeWithdrawal();
              },
              this.translate.instant('impCobro'),
              this.translate.instant('tAccept'),
              this.translate.instant('tCancel')
            );
          } else {
            this.executeWithdrawal();
          }
        } else {
          this.errorService.searchErrorCode(transactionResult.errCode.toString()).subscribe(
            (data) => {
              this.optionsRequestKo.error = data;
              this.showRequestKoInfoModal(this.optionsRequestKo);
            },
            (err) => {
              this.optionsRequestKo.error = transactionResult.errMessage;
              this.showRequestKoInfoModal(this.optionsRequestKo);
            }
          );
        }
      },
      (error) => {
        console.log(error);
        this.events.publish('user:serverfail:soft');
      },
      () => {}
    );
  }

  emitMuchBetterWidthrawal() {
    this.currentWithdrawal.executeRequest = () => this.userService.validateWidthrawalEPGMuchBetter(this.currentAmount);
    this.utils.loader();
    this.executeWithdrawal();
  }

  emitNequiWidthrawal() {
    this.currentWithdrawal.executeRequest = () => this.userService.ValidateNequi(this.currentAmount);
    this.utils.loader();
    this.executeWithdrawal();
  }

  emitDaviplataWidthrawal() {
    this.currentWithdrawal.executeRequest = () => this.userService.ValidateDaviplata(this.currentAmount);
    this.utils.loader();
    this.executeWithdrawal();
  }

  emitPSEWidthrawal() {
    this.currentWithdrawal.executeRequest = () => this.userService.ValidatePSE(this.currentAmount);
    this.utils.loader();
    this.executeWithdrawal();
  }

  emitTrueLayerWidthrawal() {
    try {
      this.currentWithdrawal.executeRequest = () =>
        this.userService.validateTrueLayer(this.currentAmount, this.selectedAccount);
      this.utils.loader();
      this.executeWithdrawal();
    } catch (e) {
      this.trackingService.track({
        eventType: EventTypes.AccessPaymentMethod,
        secondParameter: 'ERROR_TRUELAYER_WITHDRAWAL',
        additionalData: e
      });
      console.log(e);
    }
  }

  emitedCardSelectionWithdrawal(data: any) {
    this.currentWithdrawal.executeRequest = () => this.userService.ValidateCardSelection(data.card, data.amount);
    this.executeWithdrawal();
  }

  emitAstropayWidthrawal() {
    this.currentWithdrawal.executeRequest = () => this.userService.ValidateAstropay(this.currentAmount);
    this.utils.loader();
    this.executeWithdrawal();
  }

  emitLotBaWithdrawal() {
    this.currentWithdrawal.executeRequest = () => this.userService.ValidateLotbaDepost(this.currentAmount);
    this.executeWithdrawal();
  }

  emitEfectyWithdrawal() {
    this.currentWithdrawal.executeRequest = () => this.userService.validateEfectyWithdrawal(this.currentAmount);
    this.executeWithdrawal();
  }

  executeWithdrawal() {
    this.utils.loader();
    this.currentWithdrawal.executeRequest().subscribe((transactionResult: TransactionActionResult) => {
      if (transactionResult.success) {
        if (this.currentWithdrawal.className === WithdrawalMethodsClassName.LOCAL) {
          this.setGenerateCode(transactionResult);
        } else {
          this.balancesService.refreshBalance(true).subscribe(() => {});

          if (this.currentWithdrawal.className === WithdrawalMethodsClassName.EFECTY) {
            this.optionsRequestOk.mfaCode = transactionResult.MFA_CODE;
            this.optionsRequestOk.idTransaction = transactionResult.transactionId;
          } else {
            this.optionsRequestOk.idTransaction = transactionResult.transactionId;
          }
          this.optionsRequestOk.amount = transactionResult.amount;
          this.showRequestOkInfoModal(this.optionsRequestOk);
        }
        this.trackingEvent.emit([
          'CashierWithdrawalOk',
          this.currentWithdrawal.className,
          transactionResult.amount.toString(),
          '',
          'event'
        ]);
        this.events.publish('loader:dismiss', null);
      } else {
        this.utils.closeLoader();
        this.errorService
          .searchErrorCodeWithParams(transactionResult.errCode.toString(), transactionResult.errorParameters)
          .subscribe(
            (data) => {
              if (transactionResult.errCode == 174) {
                setTimeout(() => {
                  this.trackingEvent.emit(['NotDocumentationVerifiedOnCharge', data, '', '', 'event']);
                  this.sendToDocuments.emit();
                }, 800);
              } else if (transactionResult.errCode == 2212) {
                this.showError(transactionResult.errCode, data, () => {});
              } else if (transactionResult.errCode == 2405) {
                this.utils.confirm(
                  false,
                  this.translate.instant('tFail'),
                  data,
                  this.translate.instant('tCancel'),
                  () => {},
                  'Términos y Condiciones',
                  () => {
                    setTimeout(() => {
                      this.linkOutConditions();
                    }, 800);
                  }
                );
              } else if (transactionResult.errCode == 2412) {
                this.utils.confirm(
                  false,
                  this.translate.instant('Attention'),
                  this.translate.instant('V2_profesionRequest'),
                  this.translate.instant('tCancel'),
                  null,
                  this.translate.instant('tCont'),
                  () => this.GotoPersonaInfoPage(),
                  true
                );
              } else {
                this.optionsRequestKo.error = data;
                this.showRequestKoInfoModal(this.optionsRequestKo);
              }
              this.trackingEvent.emit([
                'TransactionActionResultError',
                data,
                transactionResult.errCode.toString(),
                '',
                'event'
              ]);
            },
            () => {
              this.trackingEvent.emit([
                'TransactionActionResultError',
                transactionResult.errMessage,
                transactionResult.errCode.toString(),
                '',
                'event'
              ]);
              this.optionsRequestKo.error = transactionResult.errMessage;
              this.showRequestKoInfoModal(this.optionsRequestKo);
            }
          );
      }
    });
  }

  public selectAccount(account) {
    this.currentWithdrawal.account = account?.detail?.value;
    this.selectedAccount = account?.detail?.value;
    this.showVerificarButton = account?.detail?.value == 'newAccount' ? true : false;
  }

  async verifyAccount() {
    this.utils.loader();
    const response = await this.userService.verifyAccount().toPromise();
    if (response.status.toLowerCase() === 'ok') {
      window.location.href = response.url;
    } else {
      this.utilsCsService.showInfoAlert('Error', response.errMessage);
    }
    setTimeout(() => {
      this.events.publish('loading:finish');
      this.utils.closeLoader();
    }, 1000);
  }

  private checkVerifiedAccounts() {
    this.verifiedAccountList = [];
    this.accountList.forEach((account, index) => {
      if (account.verified) {
        let verifiedAccount: VerifiedAccount;
        const splitAccount = account.iban.split('-');
        verifiedAccount = {
          display_name: splitAccount[0],
          iban: account.iban,
          ofuscateIban: splitAccount[1],
          verified: account.verified
        };
        this.verifiedAccountList.push(verifiedAccount);
        this.verifiedAccounts++;
      }
    });

    if (this.verifiedAccountList.length > 0) {
      let firstAccount = this.verifiedAccountList[0].iban;
      this.selectedAccount = firstAccount;
      if (this.currentWithdrawal) {
        this.currentWithdrawal.account = firstAccount;
      }
    }
  }

  async checkUserVerification() {
    if (this.utils.isSpain() && !this.isUserVerified) {
      const verificationModal = await this.modalCtrl.create({
        component: CsVerificationAlertModal,
        cssClass: 'cs-verification-alert-modal',
        showBackdrop: true
      });
      await verificationModal.present();

      const { data } = await verificationModal.onDidDismiss();
      if (data && data.verificationAccepted) {
        this.events.publish('cashier:open', { section: 'documents', cashierEmpty: false });
      }
    }
  }

  handleCalculatorOperationBlocked(ev: boolean) {
    this.isOperationBlocked = ev;
  }

  public get isArgentinaCaba() {
    return this.globalVars.licenseType == LicenseTypes.ArgentinaCaba;
  }

  private get isDossierLimitExceeded() {
    return this.currentAmount > this.withdrawalLimitsDossier;
  }

  public get isSpain() {
    return this.globalVars.licenseType == LicenseTypes.Nacional || this.globalVars.licenseType == LicenseTypes.Madrid;
  }

  private validateAmount(currentAmount: any, paymentType?: WithdrawalMethodsClassName): boolean {
    const minAmount = this.currentWithdrawal.minDepositAmount;
    const maxAmount = this.currentWithdrawal.maxDepositAmount;
    const errors = this.getErrorMessages();

    // if current value is zero or empty also display min amount error message
    if (currentAmount == 0 || isNaN(currentAmount) || currentAmount < minAmount) {
      this.errorAmount = errors.errorMin;
      return false;
    }
    if (currentAmount > maxAmount) {
      this.errorAmount = errors.errorMax;
      return false;
    }
    if (paymentType == WithdrawalMethodsClassName.HAL_CASH) {
      if (currentAmount % 10 != 0 || currentAmount == 30) {
        this.errorAmount = this.translate.instant('error_dep_cant_rule2050');
        return false;
      }
    }
    if (currentAmount > this.globalVars.user.balance) {
      this.errorAmount = this.translate.instant('chargeCodere01');
      return false;
    }
    if (paymentType === WithdrawalMethodsClassName.COINS_PAID && this.currentAddress.length < 34) {
      return false;
    }
    this.errorAmount = '';
    return true;
  }

  private getErrorMessages(): { errorMin: string; errorMax: string } {
    let errorMin, errorMax, licenseDetails;
    switch (this.globalVars.licenseType) {
      case LicenseTypes.Nacional: {
        licenseDetails = {
          locale: {
            active: true,
            code: 'es-ES'
          },
          details: {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 0
          },
          currencyText: ''
        };
        break;
      }
      case LicenseTypes.Mexico: {
        licenseDetails = {
          locale: {
            active: true,
            code: 'es-MX'
          },
          details: {
            style: 'currency',
            currency: 'MXN',
            minimumFractionDigits: 0
          },
          currencyText: 'MXN'
        };
        break;
      }
      case LicenseTypes.Colombia: {
        licenseDetails = {
          locale: {
            active: true,
            code: 'es-CO'
          },
          details: {
            style: 'currency',
            currency: 'COP',
            minimumFractionDigits: 0
          },
          currencyText: 'COL'
        };
        break;
      }
      case LicenseTypes.Panama: {
        //Panama currency symbol for PAB is 'B/.'.We should display '$' symbol that's why we avoid locale options for Panama.
        licenseDetails = {
          locale: {
            active: false,
            code: 'es-PA'
          },
          currencyText: '$'
        };
        break;
      }
      case LicenseTypes.ArgentinaCaba ||
        LicenseTypes.ArgentinaMendoza ||
        LicenseTypes.ArgentinaCordoba ||
        LicenseTypes.ArgentinaPBA: {
        licenseDetails = {
          locale: {
            active: true,
            code: 'es-AR'
          },
          details: {
            style: 'currency',
            currency: 'ARS',
            minimumFractionDigits: 0
          },
          currencyText: ''
        };
        break;
      }
      default: {
        licenseDetails = {
          locale: {
            active: true,
            code: 'es-ES'
          },
          details: {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 0
          },
          currencyText: ''
        };
        break;
      }
    }

    errorMin = this.translate.instant('minimumAmount');
    errorMax = this.translate.instant('maximumAmount');

    if (licenseDetails.locale.active) {
      errorMin += ` ${this.currentWithdrawal.minDepositAmount.toLocaleString(licenseDetails.locale.code, licenseDetails.details)}${licenseDetails.currencyText}`;
      errorMax += ` ${this.currentWithdrawal.maxDepositAmount.toLocaleString(licenseDetails.locale.code, licenseDetails.details)}${licenseDetails.currencyText}`;
    } else {
      errorMin += ` ${this.currentWithdrawal.minDepositAmount}${licenseDetails.currencyText}`;
      errorMax += ` ${this.currentWithdrawal.maxDepositAmount}${licenseDetails.currencyText}`;
    }
    return { errorMin, errorMax };
  }
}
