import { Injectable, inject } from '@angular/core';
import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { LoadingController } from '@ionic/angular';
import { finalize, map, Observable, throwError, from, of, switchMap } from 'rxjs';

import { BaseService } from './BaseService';
import { I_SignupPaForm, I_SignupPaResponse } from '@models/I_SignupPADecoupling';
import { TypeLogout } from '@models/I_DecouplingLogout';
import { LicenseTypes } from '@models/MasterData';
import {
  I_PreValidateSignupArg,
  I_PreValidateSignupResponseArg,
  I_SignupFormArg,
  I_SignupArgResponse
} from '@models/I_SignupArgentinaDecoupling';
import {
  CasinoSessionsLimits,
  DecoplingLimits,
  DepositsSessionsLimits,
  LimitsChangeResponse
} from '@models/I_DecouplingLimits';
import { DecouplingInfoPlayer } from '@models/I_DecouplingInfoPlayer';

const URL_SIGNUP = 'services.accounts.signup';
const URL_LOGIN = 'services.accounts.login';
const URL_PROFILE = 'services.players.profile';
declare const codereSharedJS;
export interface DecouplingResponse<T> {
  value: T | null;
  isSuccess: boolean;
  isFailure: boolean;
  error: ErrorDecoupling;
}

export interface ErrorDecoupling {
  code: string;
  description: string;
  type: number;
}
//eslint-disable-next-line
export enum ErrorCodes {
  FORBIDDEN = 403,
  UNAUTHORIZED = 401,
  MAINTENACE = 410,
  CONFLICT = 409
}

@Injectable({
  providedIn: 'root'
})
export class DecouplingUserServiceMSO extends BaseService {
  loadingCtrl = inject(LoadingController);
  loading: HTMLIonLoadingElement;
  loadingCounter: number = 0;

  constructor() {
    super();
  }

  public signupPa(data: I_SignupPaForm): Observable<I_SignupPaResponse> {
    return this.customPost<I_SignupPaResponse>(
      `${this.globalVars.DOMAIN}${URL_SIGNUP}${codereSharedJS.debug.isQA() ? '.pa' : ''}/signup`,
      data,
      {},
      true
    );
  }

  public signupArg(data: I_SignupFormArg): Observable<I_SignupPaResponse> {
    return this.customPost<I_SignupArgResponse>(`${this.globalVars.DOMAIN}/${URL_SIGNUP}/signup`, data, {}, true);
  }

  public preValidateSignupArg(data: I_PreValidateSignupArg): Observable<I_PreValidateSignupResponseArg> {
    return this.customPost<I_PreValidateSignupResponseArg>(
      `${this.globalVars.DOMAIN}/${URL_SIGNUP}/prevalidatesignup`,
      data,
      {},
      true
    );
  }

  public logoutDecoupling(userID: string, type: TypeLogout = TypeLogout.AUTOMATICO) {
    let finalpath = URL_LOGIN;
    if (codereSharedJS.debug.isQA()) {
      if (this.globalVars.licenseType === LicenseTypes.Nacional) {
        finalpath += '.es';
      }
      if (this.globalVars.licenseType === LicenseTypes.Panama) {
        finalpath += '.pa';
      }
    }
    const url = `${this.globalVars.DOMAIN}/${finalpath}/logout`;
    const headers = new HttpHeaders().append('X-Codere-Key', 'a9bf68d9-7042-40af-aa28-83a0ea7a9c4f');
    return this.customPost<null>(url, { typeLogout: type, playerIdEncrypted: userID }, { headers }, true, [
      ErrorCodes.UNAUTHORIZED,
      ErrorCodes.CONFLICT
    ]);
  }

  public refreshCookie() {
    let finalpath = URL_LOGIN;
    if (codereSharedJS.debug.isQA()) {
      if (this.globalVars.licenseType === LicenseTypes.Nacional) {
        finalpath += '.es';
      }
      if (this.globalVars.licenseType === LicenseTypes.Panama) {
        finalpath += '.pa';
      }
    }
    const url = `${this.globalVars.DOMAIN}/${finalpath}/login/refreshcookie`;
    return this.customPost<null>(url, {}, {}, false, [ErrorCodes.UNAUTHORIZED]);
  }

  public decouplingApplyRequestChangeLimits(accepted: boolean) {
    let finalpath = URL_PROFILE;
    if (codereSharedJS.debug.isQA()) {
      if (this.globalVars.licenseType === LicenseTypes.Nacional) {
        finalpath += '.es';
      }
      if (this.globalVars.licenseType === LicenseTypes.Panama) {
        finalpath += '.pa';
      }
    }
    const url = `${this.globalVars.DOMAIN}/${finalpath}/limits/applyrequestchange`;
    return this.customPost<null>(url, { accepted }, {}, true);
  }

  public decouplingCasinoRequestChangeLimits(limits: CasinoSessionsLimits) {
    let finalpath = URL_PROFILE;
    if (codereSharedJS.debug.isQA()) {
      if (this.globalVars.licenseType === LicenseTypes.Nacional) {
        finalpath += '.es';
      }
      if (this.globalVars.licenseType === LicenseTypes.Panama) {
        finalpath += '.pa';
      }
    }
    const url = `${this.globalVars.DOMAIN}/${finalpath}/limits/casinosessions/requestchange`;
    return this.customPost<LimitsChangeResponse>(url, limits, {}, true);
  }

  public decouplingDepositsRequestChangeLimits(limits: DepositsSessionsLimits) {
    let finalpath = URL_PROFILE;
    if (codereSharedJS.debug.isQA()) {
      if (this.globalVars.licenseType === LicenseTypes.Nacional) {
        finalpath += '.es';
      }
      if (this.globalVars.licenseType === LicenseTypes.Panama) {
        finalpath += '.pa';
      }
    }
    const url = `${this.globalVars.DOMAIN}/${finalpath}/limits/deposits/requestchange`;
    return this.customPost<LimitsChangeResponse>(url, limits, {}, true);
  }

  public decouplingGetLimits() {
    let finalpath = URL_PROFILE;
    if (codereSharedJS.debug.isQA()) {
      if (this.globalVars.licenseType === LicenseTypes.Nacional) {
        finalpath += '.es';
      }
      if (this.globalVars.licenseType === LicenseTypes.Panama) {
        finalpath += '.pa';
      }
    }
    const url = `${this.globalVars.DOMAIN}/${finalpath}/profile/limits`;
    return this.customGet<DecoplingLimits>(url, true);
  }

  public decouplingDepositsRequestChangeLimitsWithForm(limits: DepositsSessionsLimits, approbed: boolean) {
    let finalpath = URL_PROFILE;
    if (codereSharedJS.debug.isQA()) {
      if (this.globalVars.licenseType === LicenseTypes.Nacional) {
        finalpath += '.es';
      }
      if (this.globalVars.licenseType === LicenseTypes.Panama) {
        finalpath += '.pa';
      }
    }
    const url = `${this.globalVars.DOMAIN}/${finalpath}/limits/applysurvey`;
    return this.customPost<LimitsChangeResponse>(url, { ...limits, approbed }, {}, true);
  }
  public getInfoPlayer() {
    let finalpath = URL_PROFILE;
    if (codereSharedJS.debug.isQA()) {
      if (this.globalVars.licenseType === LicenseTypes.Nacional) {
        finalpath += '.es';
      }
      if (this.globalVars.licenseType === LicenseTypes.Panama) {
        finalpath += '.pa';
      }
    }
    const url = `${this.globalVars.DOMAIN}/${finalpath}/profile/infoplayer`;
    return this.customGet<DecouplingInfoPlayer>(url, true);
  }

  private customHandleError(skiptDefaultError: ErrorCodes[] = []) {
    return (errorResponse: HttpErrorResponse): Observable<ErrorDecoupling | undefined> =>
      throwError(() => {
        const { status, error } = errorResponse;

        if (!skiptDefaultError.includes(status)) {
          switch (status) {
            case ErrorCodes.UNAUTHORIZED:
            case ErrorCodes.FORBIDDEN:
              this.events.publish('user:logout:soft');
              return undefined;
            case ErrorCodes.MAINTENACE:
              this.events.publish('user:serverfailmaintenace', error);
              return undefined;
          }
        }

        const newErr: ErrorDecoupling = {
          code: error.error?.code,
          type: error.error?.type || status,
          description: error.error?.description
        };
        return newErr;
      });
  }

  private customPipe<T>(observable: Observable<T>, loading = false): Observable<T> {
    const loaderObservable = loading ? from(this.showLoader()) : of(null);

    return loaderObservable.pipe(
      switchMap(() =>
        observable.pipe(
          map((response) => {
            const data = response as DecouplingResponse<T>;

            if (data.isSuccess === false) {
              throwError(() => data.error);
            } else {
              return data.value || null;
            }
          }),
          finalize(() => {
            if (loading) {
              this.hideLoader();
            }
          })
        )
      )
    );
  }

  private customGet<T>(url: string, loading = false, skiptDefaultError: ErrorCodes[] = []): Observable<T> {
    return this.customPipe<T>(this.myGet(url, false, this.customHandleError(skiptDefaultError)), loading);
  }
  private customPost<T>(
    url: string,
    data: any,
    options: any,
    loading = false,
    skiptDefaultError: ErrorCodes[] = []
  ): Observable<T> {
    return this.customPipe<T>(
      this.myPost(url, data, options, false, this.customHandleError(skiptDefaultError)),
      loading
    );
  }
  private customPut<T>(
    url: string,
    data: any,
    options: any,
    loading = false,
    skiptDefaultError: ErrorCodes[] = []
  ): Observable<T> {
    return this.customPipe<T>(
      this.myPut(url, data, options, false, this.customHandleError(skiptDefaultError)),
      loading
    );
  }
  private customDelete<T>(url: string, loading = false, skiptDefaultError: ErrorCodes[] = []): Observable<T> {
    return this.customPipe<T>(this.myDelete(url, false, this.customHandleError(skiptDefaultError)), loading);
  }

  private async showLoader() {
    if (this.loadingCounter === 0) {
      this.loading = await this.loadingCtrl.create({
        spinner: 'crescent',
        backdropDismiss: false,
        cssClass: 'is-loader',
        translucent: true
      });
      await this.loading.present();
    }
    this.loadingCounter++;
  }

  private async hideLoader() {
    this.loadingCounter--;
    if (this.loadingCounter <= 0) {
      this.loadingCounter = 0;
      await this.loading.dismiss();
    }
  }
}
