<ion-card>
  <ion-card-header class="card-header"> {{headLabel}} </ion-card-header>
  <ion-card-content>
    <form [formGroup]="formGroup" (ngSubmit)="submit()">
      <ion-row>
        <ion-col class="check-container" size="2">
          <ion-checkbox formControlName="tycCheck"></ion-checkbox>
        </ion-col>
        <ion-col size="10">
          <div class="message-text">
            {{contentLabelInit}}
            <a external-link [href]="url" target="_blank" class="color-secondary">{{policyPdfLink}}</a>
            {{contentLabelEnd}}
          </div>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <button class="form-submit" type="submit" [disabled]="!formGroup.valid">{{ 'tCont' | translate }}</button>
        </ion-col>
      </ion-row>
    </form>
  </ion-card-content>
</ion-card>
