import { IonContent, IonicModule, NavController } from '@ionic/angular';
import { ViewChild, Component, Inject, forwardRef, inject } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { UserService } from '@providers/UserService';
import { TrackingService } from '@providers/TrackingService';
import { Utils } from '@utils/Utils';
import { EventsService } from '@providers/EventsService';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { BackButton } from '@shared-common-components/back-button/back-button';
import { UserSectionTitle } from '@shared-mso-components/user-section-title/user-section-title';
import { CodereFooterComponent } from '@shared-mso-components/footer/codere-footer/codere-footer';
import { DeviceService } from '@services/device.service';
import { GlobalVarsModel } from '@models/ModelGlobalsVar';
import { NewBaseService } from '@providers/newBase.service';
import { ExtractDatePipe } from '@pipes/extractDate.pipe';
import { strNoSpacesPipe } from '../../pipes/strNoSpaces.pipe';
import { SlotsService } from '@providers/SlotsService';
import { LobbyCasinoPage } from '@shared-casino-pages/lobby-casino/lobby-casino';

@Component({
  // ! Modificado selector: slots-bonus-page -> 'slot-bonus-detail'
  selector: 'slots-bonus-detail-page',
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    CodereFooterComponent,
    ExtractDatePipe,
    strNoSpacesPipe,
    BackButton,
    TranslateModule,
    UserSectionTitle
  ],
  styleUrls: ['./slots-bonus-detail.scss'],
  templateUrl: './slots-bonus-detail.html'
})
export class SlotsBonusDetailPage {
  @ViewChild(IonContent) content: IonContent;
  bono: Array<any>;
  globalVars!: GlobalVarsModel;
  isDesktop: boolean;

  nav = inject(NavController);
  trackingService = inject(TrackingService);
  newBaseService = inject(NewBaseService);
  serService = inject(UserService);
  slotsService = inject(SlotsService);
  utils = inject(Utils);
  events = inject(EventsService);
  router = inject(Router);
  activatedRoute = inject(ActivatedRoute);
  deviceService = inject(DeviceService);

  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });

    this.isDesktop = this.deviceService.isDesktop();

    this.activatedRoute.params.subscribe((data: any) => {
      this.bono = data;
    });
  }

  ionViewWillEnter() {
    this.router.navigate(['/' + SlotsBonusDetailPage]);
    this.trackingService.trackEvent(['AccesToSlotsBonusDetail', '', '', '', 'event']);
    this.events.publish('footer:refresh', this.content);
  }

  ionViewDidLoad() {
    this.events.publish('footer:refresh', this.content);
  }

  goToSlots(SectionType: string) {
    this.trackingService.trackEvent(['GoToSlotsfromBonusPage', '', '', '', 'event']);
    let lobby = this.globalVars.FEATURES.Lobbies.find((lb: any) => lb.LobbyName.toLowerCase().indexOf('playtech') >= 0);
    lobby === undefined ? (lobby = this.globalVars.FEATURES.Lobbies[0].LobbyName) : (lobby = lobby.LobbyName);

    //eslint-disable-next-line
    if (SectionType == 'Slots') {
      this.router.navigate(['/' + LobbyCasinoPage], { queryParams: lobby });
    } else {
      this.router.navigate(['/' + LobbyCasinoPage], { queryParams: lobby });
    }
  }
}
