/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable eqeqeq */
import { NavParams, ModalController, AlertController, IonicModule, IonContent } from '@ionic/angular';
import { ViewChild, Component, ElementRef, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';
import { map, tap, zip } from 'rxjs';

//Services
import { Utils } from '@utils/Utils';
import { EventTypes } from '@models/TrackingEvents';
import { UserService } from '@providers/UserService';
import { UserServiceMSO } from '@providers/UserServiceMSO';
import { RedirectService } from '@providers/RedirectService';
import { CookiesService } from '@providers/CookiesService';
import { TrackingService } from '@providers/TrackingService';
import { GeolocationService } from '@providers/GeolocationService';
import { SlotsBonusesService } from '@providers/SlotsBonusesService';

//Models
import { I_UserArgentina } from '@models/I_UserArgentina';
import { CookieNames } from '@models/CookieNames';

//Others
import { DeviceService } from '@services/device.service';
import { HomePage } from '@shared-pages/home/home';
import { GlobalVarsModel } from '@models/ModelGlobalsVar';
import { NewBaseService } from '@providers/newBase.service';
import { EventsService } from '@providers/EventsService';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Browser } from '@capacitor/browser';
import { Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { LicenseTypes } from '@models/MasterData';

const jobsAR = '';
@Component({
  templateUrl: './registroPBA.html',
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'registroAR-page',
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule]
})
export class RegistroPBAPage {
  @ViewChild(IonContent) content: IonContent;
  @ViewChild('iframe') iframeNode: ElementRef;
  viewCtrl: ModalController;
  codepromotion: string;
  scrReg: any;
  regListener: any;
  cardNumber: any;
  parameters: any;
  renderIFrame: boolean;
  globalVars: GlobalVarsModel;

  //Dependencias
  trackingService = inject(TrackingService);
  events = inject(EventsService);
  userService = inject(UserService);
  userServiceMSO = inject(UserServiceMSO);
  translate = inject(TranslateService);
  slotsBonusesService = inject(SlotsBonusesService);
  geolocationService = inject(GeolocationService);
  redirectService = inject(RedirectService);
  cookiesService = inject(CookiesService);
  newBaseService = inject(NewBaseService);
  utils = inject(Utils);
  modalCtrl = inject(ModalController);
  alertCtrl = inject(AlertController);
  navParams = inject(NavParams);
  sanitizer = inject(DomSanitizer);
  deviceService = inject(DeviceService);
  router = inject(Router);

  isMobile = this.deviceService.isMobile();
  isDesktop = this.deviceService.isDesktop();

  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });

    this.renderIFrame = false;
    this.setCardNumber();
    this.parameters = this.utils.getParamStore();
    console.info('Parámetros en registroAR', this.parameters);

    this.defineIFrame();

    this.codepromotion = '';
    window['jobsAR'] = this.globalVars.FEATURES.ListOfProfesions;
    const tempCode: string = this.cookiesService.getCookie(CookieNames.CoderePromotionCode);
    if (tempCode != '') {
      this.codepromotion = tempCode;
    }
    if (this.globalVars.codePromotion) {
      this.codepromotion = this.globalVars.codePromotion;
      if (this.codepromotion != tempCode) {
        this.cookiesService.setCookie(
          CookieNames.CoderePromotionCode,
          this.codepromotion,
          3 * 24 * 60 * 60 * 1000,
          true
        );
      }
    }
    if (this.navParams.get('item')) {
      if (this.navParams.get('item').codepromotion) {
        this.codepromotion = this.navParams.get('item').codepromotion;
        if (this.codepromotion != tempCode) {
          this.cookiesService.setCookie(
            CookieNames.CoderePromotionCode,
            this.codepromotion,
            3 * 24 * 60 * 60 * 1000,
            true
          );
        }
      }
    }
    if (this.navParams.get('codepromotion')) {
      this.codepromotion = this.navParams.get('codepromotion');
      if (this.codepromotion != tempCode) {
        this.cookiesService.setCookie(
          CookieNames.CoderePromotionCode,
          this.codepromotion,
          3 * 24 * 60 * 60 * 1000,
          true
        );
      }
    }
    this.regListener = this.listenEvents.bind(this);
  } // Fin del constructor

  defineIFrame() {
    let url = this.setUrl();
    //añadir parámetros renaper
    url = this.renaperParams(url);
    //añadir parámetros promociones
    url = this.promoParams(url);
    //añadir parámetros de cardNumber
    url = this.cardNumberParams(url);
    //añadir parámetros no login
    url += '&nologin=false';
    this.renderIFrame = true;
    this.scrReg = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    if (this.globalVars.licenseType == 33) {
      this.slotsBonusesService.taboolaTrack('lead');
    }
  }

  /**
   * Procesamiento de la respuesta del API con los datos corespondientes
   * al usuario preregistrado (si existían parámetros)
   * En la respuesta a getPreregisterUser
   * comprueba si hay datos repetidos de usuarios para los campos
   * TELEFONO y CORREO
   * @param res
   */
  processUserData(data) {
    const consultas = [];
    console.info('-------------------------------------');
    console.info('Respuesta del servicio Angular');
    console.info(data);

    if (data.email) {
      consultas.push(this.userServiceMSO.checkDataAvailability('CORREO', data.email));
    }
    if (data.mobile) {
      consultas.push(this.userServiceMSO.checkDataAvailability('TELEFONO', data.mobile));
    }

    if (!consultas.length) {
      return;
    }

    zip(...consultas)
      .pipe(
        tap((results) => console.info(results)),
        map((results) => {
          const result = results.some((item: any) => item);
          console.info(result);
          if (!result) {
            // no repetido
            this.defineIFrame();
            localStorage.setItem('Preregistro', JSON.stringify(data));
          } else {
            // Si hay datos repetidos, no cargar nada en el iframe y cerrarlo
            // Hacer una llamada a Acceder
            this.utils.openAccder$.next(true);
            this.closeModal();
          }
        })
      )
      .subscribe();
  }

  ionViewWillLeave(event) {
    this.utils.unloadContactChat();
    window.removeEventListener('message', this.regListener);
    this.utils.hideFormAccessoryBar(true);
  }

  backToHome() {
    this.router.navigate(['/']);
  }

  ionViewWillEnter() {
    this.utils.hideFormAccessoryBar(false);
    window.addEventListener('message', this.regListener);
    this.trackingService.trackEvent(['AccessToRegisterFromButton', '', '', 'Registro desde boton', 'event']);

    // New tracking services
    this.trackingService.track({ eventType: EventTypes.Lead });

    if (this.isMobile) {
      this.utils.changePage('', RegistroPBAPage);
    }
  }

  listenEvents(e: any) {
    if (e.data['action'] == 'RegOK') {
      this.geolocationService.showGeolocation();
      if (e.data['dest'] == 'Home') {
        this.utils.loginAndGoto(this.globalVars.mainPageComponent, null, false).then((isOk) => {
          if (isOk) {
            this.partialSelfExclusion();
            this.closeModal();
          } else {
            this.linkOutOfModalPage(this.router.url || '/', {});
          }
        });
      } else if (e.data['dest'] == 'Cashier') {
        this.utils.loginAndGoto(this.globalVars.mainPageComponent, { name: 'VisaBancomer' }, false).then((isOk) => {
          if (isOk) {
            this.partialSelfExclusion();
            this.closeModal();
          } else {
            this.linkOutOfModalPage(this.router.url || '/', {});
          }
        });
      } else {
        // 'Documents'
        this.utils.loginAndGoto(this.globalVars.mainPageComponent, { section: 'documents' }, false).then((isOk) => {
          if (isOk) {
            this.partialSelfExclusion();
            this.closeModal();
          } else {
            this.linkOutOfModalPage(this.router.url || '/', {});
          }
        });
      }
      this.closeModal();

      window.removeEventListener('message', this.regListener);
    }
    if (e.data['action'] == 'closeModal') {
      this.closeModal();
      window.removeEventListener('message', this.regListener);
    }
    if (e.data['action'] == 'trackEvent') {
      this.trackingService.trackEvent(e.data['event']);
    }
    if (e.data['action'] == 'refresh') {
      const iframe: any = document.getElementsByClassName('iframeFullScreen').item(0);
      iframe.style = 'display:none';
      iframe.style = 'display:block';
    }

    if (e.data.action == 'startIFrame') {
      if (this.navParams.get('id')) {
        // Pre-Registro desde Retail
        const iframeElem = this.iframeNode.nativeElement;
        const preRegistroID = this.navParams.get('id');
        console.info('Pre-Registro', preRegistroID);
        this.userServiceMSO.getPreRegisterUserAr(preRegistroID).subscribe((res) => {
          const obj = {
            preRegistroAR: true,
            userData: res
          };
          iframeElem.contentWindow.postMessage(obj);
        });
      } else if (this.navParams.get('JWTRegistro')) {
        this.fillRegFromJWT(this.navParams.get('JWTRegistro'));
      } else if (this.navParams.data && this.navParams.data['item'] && this.navParams.data['item'].JWTRegistro) {
        this.fillRegFromJWT(this.navParams.data['item'].JWTRegistro);
      }
    }

    if (e.data.action === 'doRegister') {
      this.doRegister(e.data.user);
    }
    if (e.data.action === 'redirect') {
      this.redirectFromRegister(e.data);
    }
    if (e.data.action === 'openExternalLink') {
      this.openExternalLink(e.data.dest);
    }
  }

  redirectFromRegister(data) {
    if (!Capacitor.isNativePlatform()) {
      this.redirectService.generateJWTFromData(data.param).subscribe({
        next: (rsp) => {
          window.location.replace(`${this.getURLToRedirect(data.dest)}?JWTRegistro=${rsp}`);
        },
        error: (err) => {
          console.error(err);
        }
      });
    }
  }

  async openExternalLink(url: string) {
    if (Capacitor.isNativePlatform()) {
      const target = '_system';
      const options: any = {
        location: 'yes',
        hidden: 'no',
        closebuttoncaption: 'Cerrar',
        closebuttoncolor: '#ffffff',
        footer: 'no',
        footercolor: '#000000',
        hardwareback: 'yes',
        hidenavigationbuttons: 'no',
        hideurlbar: 'yes',
        navigationbuttoncolor: '#ffffff',
        toolbarcolor: '#000000',
        zoom: 'yes',
        mediaPlaybackRequiresUserAction: 'no',
        shouldPauseOnSuspend: 'yes',
        useWideViewPort: 'yes',
        toolbar: 'yes',
        toolbartranslucent: 'yes',
        enableViewportScale: 'yes',
        allowInlineMediaPlayback: 'yes',
        keyboardDisplayRequiresUserAction: 'no',
        suppressesIncrementalRendering: 'no',
        presentationstyle: 'fullscreen', //pagesheet, formsheet, fullscreen
        transitionstyle: 'coververtical', //fliphorizontal, crossdissolve, coververtical
        toolbarposition: 'bottom', //top, bottom
        hidespinner: 'no'
      };

      // this.iab.create(encodeURI(url), target, options);
      await Browser.open({ url: encodeURI(url), windowName: target, ...options });
    } else {
      window.open(url, '_blank');
    }
  }

  fillRegFromJWT(jwt: string) {
    this.redirectService.loadDataFromJWT(jwt).subscribe(
      (resp) => {
        const dataReg = JSON.parse(resp);
        const iframeElem = this.iframeNode.nativeElement;
        const obj = {
          regFromRedirect: true,
          userData: { ...dataReg }
        };
        iframeElem.contentWindow.postMessage(obj);
      },
      (err) => {
        console.error(err);
      }
    );
  }

  getURLToRedirect(license: number) {
    const urls = JSON.parse(this.globalVars.FEATURES.registroRedirect) || [];
    return urls.find((u) => u[license])[license];
  }

  doRegister(user: I_UserArgentina) {
    const iframeElem = this.iframeNode.nativeElement;
    this.utils.loader();

    try {
      this.userServiceMSO.registerPBA(user).subscribe({
        next: (data) => {
          this.utils.closeLoader();
          if ((typeof data.status === 'string' && data.status.toUpperCase() === 'OK') || data.status === 0) {
            //RegistroOK
            this.trackingService.trackEvent(['FinishRegister', 'OK', user.login, user.email, 'event']);
            if (this.globalVars.licenseType == 33) {
              this.slotsBonusesService.taboolaTrack('complete_registration');
            }
            iframeElem.contentWindow.postMessage({
              action: 'RegOK',
              user,
              data
            });
          } else {
            iframeElem.contentWindow.postMessage({
              action: 'RegKO',
              user,
              errorCode: data.status,
              errorMess: data.Message,
              errorDess: data.errorDescription,
              errorAr: data.errorCode
            });
          }
        },
        error: (err) => {
          this.utils.closeLoader();
          this.trackingService.trackEvent(['FinishRegister', 'KO', 'ERR', 'Fin Registro KO', 'event']);
          iframeElem.contentWindow.postMessage({
            action: 'RegKO',
            user,
            errorCode: -1
          });
        }
      });
    } catch (error) {
      this.utils.closeLoader();
      this.trackingService.trackEvent(['FinishRegister', 'KO', 'ERR', 'Fin Registro KO', 'event']);
      iframeElem.contentWindow.postMessage({
        action: 'RegKO',
        user,
        errorCode: -1
      });
    }
  }

  async linkOutOfModalPage(link: any, paymentArguments: any) {
    if (this.isMobile) {
      this.navigateToLink(link, paymentArguments);
    } else {
      if (this.viewCtrl) {
        this.viewCtrl.dismiss(null, null, { animate: false } as any).then(() => {
          this.navigateToLink(link, paymentArguments);
        });
        this.viewCtrl = null;
      } else {
        this.navigateToLink(link, paymentArguments);
      }
    }
  }

  private navigateToLink(link: any, paymentArguments: any) {
    this.router.navigate([link], {
      queryParams: paymentArguments,
      queryParamsHandling: 'merge',
      skipLocationChange: false,
      replaceUrl: false,
      state: { animate: !this.isDesktop }
    });
  }

  partialSelfExclusion() {
    if (this.globalVars.licenseType === LicenseTypes.ArgentinaCaba && this.globalVars.user.username) {
      const autoExclusion = JSON.parse(localStorage.getItem('alertSelfExclusionLotba'));
      if (autoExclusion) {
        console.info('Usuario autoexcluido en otra plataforma ');
        localStorage.removeItem('alertSelfExclusionLotba');
        this.utils.selfExclusionModal(1, this.viewCtrl);
      }
    }
  }

  async nologinAndGoto(dest: any, params = {}) {
    this.linkOutOfModalPage(HomePage, params);
    // Se cierra el modal si exista -> desktop
    this.closeModal();
    //this.events.publish('user:logout');
    this.globalVars.rootScope.doLogout();
    this.utils.setCookie('.CodereApuestas', '', 0);
    await this.presentAlert();
  }
  async presentAlert() {
    const footer = await this.utils.createARFooter();
    const alert = await this.alertCtrl.create({
      header: 'BIENVENIDO A CODERE!',
      message: `Te registraste correctamente.<br>
        Próximamente vas a poder disfrutar de nuestra oferta de juegos.`,
      cssClass: 'alertnologin',
      buttons: [
        {
          text: 'Aceptar',
          role: 'cancel'
        }
      ]
    });
    await alert.present();

    if (this.globalVars.licenseType === LicenseTypes.ArgentinaCaba) {
      // Agregar el pie de página después de que la alerta se haya presentado
      const ionAlert = await this.alertCtrl.getTop();
      if (ionAlert) {
        const alertElement = ionAlert.querySelector('.alert-wrapper');
        if (alertElement) {
          alertElement.appendChild(footer);
        }
      }
    }
    return alert;
  }

  closeModal() {
    if (this.viewCtrl) {
      this.viewCtrl.dismiss();
      this.viewCtrl = null;
    }
  }

  openChat() {
    this.utils.loadContactChat();
    setTimeout(function () {
      window['SnapEngage'].startLink();
    }, 800);
  }

  private setCardNumber() {
    const item = this.navParams.get('item') || this.navParams.get('data');
    const cardNumber = this.navParams.get('cardNumber');
    this.cardNumber = item && item.cardNumber ? item.cardNumber : cardNumber ? cardNumber : '';
  }

  private renaperParams(url: string): string {
    return !this.globalVars.registry || this.globalVars.registry == 'noRenaper' ? url : url + `&renaper=true`;
  }

  private promoParams(url: string): string {
    return this.codepromotion ? url + `&codepromotion=${this.codepromotion}` : url;
  }

  private cardNumberParams(url: string): string {
    return this.cardNumber ? url + `&numbercard=${this.cardNumber}` : url;
  }

  private setUrl(): string {
    // TODO REVISAR RUTA. No se puede probar hasta que codere termine el desarrollo.
    return this.globalVars.URLBASE_REGPBA + '/reg.html?iswebapppc=' + this.isDesktop;
  }
}
