<ng-container *ngIf="!isMobile; else mobileView">
  <!-- eslint-disable @angular-eslint/template/eqeqeq -->
  <ion-content class="background-color-muted">
    <!-- only show when the user is not subscribed -->
    <user-section-title [sectionTitle]="'uPPPInfo'"></user-section-title>

    <div class="is-narrow-content">
      <ion-list class="form-list">
        <ion-item class="promoPanel" *ngIf="globalVars.user.pendingSMSEmail" lines="none">
          <div class="item-inner">
            <div class="input-wrapper">
              <ion-label>
                <h1 class="text-center">{{'smsEmail_title' | translate}}</h1>
                <img src="assets/global/img/superPromos.png" alt="" />
                <div class="promo-message-container">
                  <p>
                    {{'smsEmail_t1' | translate}}
                    <!--Recibe toda la información sobre-->
                    <b
                      >{{'smsEmail_t2' | translate}}
                      <!--Bonos, Promociones exclusivas, Apuestas gratis...--></b
                    >
                    {{'smsEmail_t3'| translate}}
                    <!--y mucho más-->
                  </p>
                  <ion-button (click)="doSubscription()">
                    {{'smsEmail_ok' | translate | uppercase}}
                    <!--SÍ, INFÓRMAME-->
                  </ion-button>
                </div>
              </ion-label>
            </div>
          </div>
        </ion-item>
        <div class="textcenter" *ngIf="utils.isArgentina()">{{'infopersonaltxt'| translate}}</div>
        <form [formGroup]="myForm">
          <!-- novalidate -->
          <!--Usuario-->
          <div class="myWrapInput">
            <ion-item class="cdr-item-input-disabled" lines="none">
              <!--Usuario-->
              <ion-input
                class="ion-input-no-transform-label"
                labelPlacement="floating"
                label="{{'loginUser' | translate}}"
                formControlName="user"
                type="text"
              >
              </ion-input>
            </ion-item>
          </div>
          <!-- Mail -->
          <ng-container>
            <div
              class="myWrapInput"
              [class.has-error]="!myForm.get('email').valid
              && myForm.get('email').enabled && myForm.get('email').touched"
              (click)="showDisabledMailInfo = true"
            >
              <ion-item
                [class.error]="!myForm.get('email').valid
                && myForm.get('email').enabled && myForm.get('email').touched"
                [class.cdr-item-input]="myForm.get('email').enabled"
                [class.cdr-item-input-disabled]="!myForm.get('email').enabled"
                lines="none"
              >
                <ion-input
                  class="ion-input-no-transform-label"
                  labelPlacement="floating"
                  label="{{'pIMail' | translate}}"
                  type="email"
                  formControlName="email"
                ></ion-input>
              </ion-item>
              <div
                [hidden]="myForm.get('email').disabled || myForm.get('email').valid
                || myForm.get('email').untouched"
                class="labelError"
              >
                <span *ngIf="myForm.get('email').hasError('required')">
                  {{'v2_emailObl' | translate}}
                  <!--# El email es obligatorio--></span
                >
                <span *ngIf="myForm.get('email').hasError('validFormat')">
                  {{'v2_emailError' | translate}}
                  <!--# El formato del email no es válido--></span
                >
                <span *ngIf="myForm.get('email').hasError('validUnique')">
                  {{'error_checkMail' | translate}}
                  <!--El email ya está registrado anteriormente--></span
                >
              </div>
            </div>
            <!-- Aviso modificación Mail -->
            <div class="myWrapInput" *ngIf="showDisabledMailInfo">
              <ion-item style="background-color: #f5f5f5; color: #d62929" lines="none">
                <span> Por su seguridad, si requiere modificar este dato póngase en contacto con nosotros </span>
              </ion-item>
            </div>
          </ng-container>
          <!-- Mobile -->
          <ng-container>
            <div
              class="myWrapInput"
              [class.has-error]="!myForm.get('phone').valid
              && myForm.get('phone').enabled && myForm.get('phone').touched"
              (click)="showDisabledMobileInfo = true"
            >
              <ion-item
                [class.error]="!myForm.get('phone').valid
              && myForm.get('phone').enabled && myForm.get('phone').touched"
                [class.cdr-item-input]="myForm.get('phone').enabled"
                [class.cdr-item-input-disabled]="!myForm.get('phone').enabled"
                lines="none"
              >
                <!--Móvil-->
                <ion-input
                  class="ion-input-no-transform-label"
                  labelPlacement="floating"
                  label="{{'pIMvl' | translate}}"
                  type="tel"
                  formControlName="phone"
                ></ion-input>
              </ion-item>
              <div
                [hidden]="myForm.get('phone').disabled || myForm.get('phone').valid
                || myForm.get('phone').untouched"
                class="labelError"
              >
                <span *ngIf="myForm.get('phone').hasError('required')">
                  {{'v2_movilObl' | translate}}
                  <!--# El móvil es obligatorio--></span
                >
                <span *ngIf="myForm.get('phone').hasError('validFormat')">
                  {{'error_mvlRule' | translate}}
                  <!--El formato del móvil no es válido--></span
                >
                <span *ngIf="myForm.get('phone').hasError('validUnique')">
                  {{'error_checkTel' | translate}}
                  <!--El teléfono ya está registrado anteriormente--></span
                >
                <span *ngIf="myForm.get('phone').hasError('minlength')"> Debe tener mínimo 10 caracteres<br /></span>
                <span *ngIf="myForm.get('phone').hasError('maxlength')">Debe tener máximo 10 caracteres<br /></span>
              </div>
            </div>
            <!-- Aviso modificación Mobile -->
            <div class="myWrapInput" *ngIf="showDisabledMobileInfo">
              <ion-item style="background-color: #f5f5f5; color: #d62929" lines="none">
                <span> Por su seguridad, si requiere modificar este dato póngase en contacto con nosotros </span>
              </ion-item>
            </div>
          </ng-container>
          <!-- Address -->
          <ng-container>
            <div
              class="myWrapInput"
              [class.has-error]="!myForm.get('address').valid
              && myForm.get('address').enabled && myForm.get('address').touched"
              (click)="setfalseshowDisabledAddressInfo()"
            >
              <ion-item
                [class.error]="!myForm.get('address').valid
                && myForm.get('address').enabled && myForm.get('address').touched"
                [class.cdr-item-input]="myForm.get('address').enabled"
                [class.cdr-item-input-disabled]="!myForm.get('address').enabled"
                lines="none"
              >
                <!--Dirección-->
                <ion-input
                  class="ion-input-no-transform-label"
                  autocomplete="off"
                  autoCorrect="off"
                  spellcheck="off"
                  labelPlacement="floating"
                  label="{{'pIAddress' | translate}}"
                  type="text"
                  formControlName="address"
                  [class.ion-input-error]="!myForm.get('address').valid
                  && myForm.get('address').enabled && myForm.get('address').touched && myForm.get('address').dirty"
                ></ion-input>
              </ion-item>
              <div
                [hidden]="myForm.get('address').disabled || myForm.get('address').valid ||
                myForm.get('address').untouched"
                class="labelError"
              >
                {{'v2_errorDirec' | translate}}
                <!--# Debes introducir correctamente la dirección-->
              </div>

              <!-- Extra address fields in Argentina -->
              <ion-row *ngIf="utils.isArgentina()">
                <ion-col>
                  <ion-item class="cdr-item-input-disabled" lines="none">
                    <ion-input
                      class="ion-input-no-transform-label"
                      labelPlacement="floating"
                      label="Número"
                      type="text"
                      formControlName="num"
                    ></ion-input>
                  </ion-item>
                </ion-col>
                <ion-col>
                  <ion-item class="cdr-item-input-disabled" lines="none">
                    <ion-input
                      class="ion-input-no-transform-label"
                      labelPlacement="floating"
                      label="Piso"
                      type="text"
                      formControlName="piso"
                    ></ion-input>
                  </ion-item>
                </ion-col>
                <ion-col>
                  <ion-item class="cdr-item-input-disabled" lines="none">
                    <ion-input
                      class="ion-input-no-transform-label"
                      labelPlacement="floating"
                      label="Departamento"
                      type="text"
                      formControlName="dpto"
                    ></ion-input>
                  </ion-item>
                </ion-col>
              </ion-row>
            </div>
            <!-- Aviso modificación Adress -->
            <div class="myWrapInput" *ngIf="showDisabledAddressInfo">
              <ion-item style="background-color: #f5f5f5; color: #d62929" lines="none">
                <span>
                  Para modificar los datos de contacto, debe ponerse en contacto con nuestro Servicio de Atención al
                  Cliente.
                </span>
              </ion-item>
            </div>
          </ng-container>
          <!-- Código Postal -->
          <div
            class="myWrapInput"
            [class.has-error]="!myForm.controls['zipCode'].valid
            && myForm.get('zipCode').enabled && myForm.controls['zipCode'].touched"
          >
            <ion-item
              class="cdr-item-input"
              [class.error]="!myForm.controls['zipCode'].valid
              && myForm.get('zipCode').enabled && myForm.controls['zipCode'].touched"
              [class.cdr-item-input]="myForm.get('zipCode').enabled"
              [class.cdr-item-input-disabled]="!myForm.get('zipCode').enabled"
              lines="none"
            >
              <!--Código postal-->
              <ion-input
                class="ion-input-no-transform-label"
                labelPlacement="floating"
                label="{{'rZip' | translate}}"
                type="tel"
                formControlName="zipCode"
              >
              </ion-input>
            </ion-item>
          </div>

          <!-- estado civil-->
          <div class="myWrapInput" *ngIf="utils.isArgentina()">
            <ion-item class="firstInput fixed-select cdr-item-input" id="select-estado" lines="none">
              <!--Estado civil-->
              <ion-select
                placeholder="{{'civilstatus' | translate}}"
                class="selectInput"
                cancelText="{{'tCancel'|translate}}"
                formControlName="estCivil"
                toggleIcon="chevron-down-outline"
                mode="md"
                [interfaceOptions]="{size: 'auto', header: 'selectoption' | translate | uppercase, mode: undefined }"
              >
                <ion-select-option *ngFor="let estado  of estCiviles" [value]="estado.name">
                  {{estado.name}}
                </ion-select-option>
              </ion-select>
            </ion-item>
            <div class="labelError" *ngIf="myForm.get('estCivil').invalid && myForm.get('estCivil').touched">
              <ion-label>Es necesario seleccionar un estado civil</ion-label>
            </div>
          </div>
          <!--datos del cónyuge-->
          <div
            *ngIf="(utils.isArgentina()) &&
                (myForm.get('estCivil').value == 'Casado/a' ||
                myForm.get('estCivil').value == 'Unión Civil' ||
                myForm.get('estCivil').value == 'Unión de Hecho')"
            class="conytit"
          >
            <div>{{'spousedata' | translate}}</div>
            <div class="conydiv">
              <!--Nombre del cónyuge-->
              <ion-item
                [class.error]="!myForm.controls['cygNombre'].valid && myForm.controls['cygNombre'].touched"
                [class.cdr-item-input]="myForm.get('cygNombre').enabled"
                [class.cdr-item-input-disabled]="!myForm.get('cygNombre').enabled"
                lines="none"
              >
                <ion-input
                  class="ion-input-no-transform-label"
                  labelPlacement="floating"
                  label="{{'spousename' | translate}}"
                  type="text"
                  maxlength="50"
                  formControlName="cygNombre"
                ></ion-input>
              </ion-item>
              <div class="labelError" *ngIf="myForm.get('cygNombre').invalid && myForm.get('cygNombre').touched">
                <ion-label>Es necesario indicar el nombre del cónyuge</ion-label>
              </div>
              <!--Apellidos del cónyuge-->
              <ion-item
                [class.error]="!myForm.controls['cygApellidos'].valid && myForm.controls['cygApellidos'].touched"
                [class.cdr-item-input]="myForm.get('cygApellidos').enabled"
                [class.cdr-item-input-disabled]="!myForm.get('cygApellidos').enabled"
                lines="none"
              >
                <ion-input
                  class="ion-input-no-transform-label"
                  labelPlacement="floating"
                  label="{{'spousename2' | translate}}"
                  class="cdr-input-floating"
                  type="text"
                  maxlength="50"
                  formControlName="cygApellidos"
                ></ion-input>
              </ion-item>
              <div class="labelError" *ngIf="myForm.get('cygApellidos').invalid && myForm.get('cygApellidos').touched">
                <ion-label>Es necesario indicar los apellidos del cónyuge</ion-label>
              </div>
              <!--Nacionalidad del cónyuge-->
              <div class="myWrapInput">
                <ion-item
                  [class.error]="!myForm.controls['cygNacionalidad'].valid && myForm.controls['cygNacionalidad'].touched"
                  class="fixed-select cdr-item-input"
                  lines="none"
                >
                  <ion-select
                    placeholder="{{'spousenationality' | translate}}"
                    class="selectInput"
                    cancelText="{{'tCancel'|translate}}"
                    formControlName="cygNacionalidad"
                    toggleIcon="chevron-down-outline"
                    mode="md"
                    [interfaceOptions]="{size: 'auto', header: 'selectoption' | translate | uppercase, mode: undefined}"
                  >
                    <ion-select-option *ngFor="let item of nationalities" [value]="item"> {{item}} </ion-select-option>
                  </ion-select>
                </ion-item>
                <div
                  class="labelError"
                  *ngIf="myForm.get('cygNacionalidad').invalid && myForm.get('cygNacionalidad').touched"
                >
                  <ion-label>Es necesario indicar la nacionalidad del cónyuge</ion-label>
                </div>
              </div>
              <!--Tipo de documento-->
              <div class="myWrapInput">
                <ion-item *ngIf="globalVars.browser!='internet'" class="fixed-select cdr-item-input" lines="none">
                  <ion-select
                    placeholder="{{'NotResidDocumentType' | translate}}"
                    class="selectInput"
                    cancelText="{{'tCancel'|translate}}"
                    formControlName="cygTipoDoc"
                    toggleIcon="chevron-down-outline"
                    mode="md"
                    [interfaceOptions]="{size: 'auto', header: 'selectoption' | translate | uppercase, mode: undefined }"
                  >
                    <ion-select-option *ngFor="let item of typesDocument" [value]="item.id">
                      {{item.label}}
                    </ion-select-option>
                  </ion-select>
                </ion-item>
                <div class="labelError" *ngIf="myForm.get('cygTipoDoc').invalid && myForm.get('cygTipoDoc').touched">
                  <ion-label>Es necesario indicar el documento del cónyuge</ion-label>
                </div>
              </div>
              <!--Número de documento-->
              <div class="myWrapInput">
                <ion-item
                  [class.error]="!myForm.controls['cygNumDoc'].valid && myForm.controls['cygNumDoc'].touched"
                  [class.cdr-item-input]="myForm.get('cygNumDoc').enabled"
                  [class.cdr-item-input-disabled]="!myForm.get('cygNumDoc').enabled"
                  lines="none"
                >
                  <ion-input
                    class="ion-input-no-transform-label"
                    labelPlacement="floating"
                    label="{{'NotResidDocumentTypeNum' | translate}}"
                    type="text"
                    maxlength="12"
                    formControlName="cygNumDoc"
                  ></ion-input>
                </ion-item>
                <div class="labelError" *ngIf="myForm.get('cygNumDoc').invalid && myForm.get('cygNumDoc').touched">
                  <!-- <ion-label>{{'spousennumdoc' | translate}}</ion-label> -->
                  <ion-label>{{errorCodeSpouse('cygNumDoc')}}</ion-label>
                </div>
              </div>
            </div>
            <!--Tipo de identificación tributaria del cónyuge-->
            <div class="myWrapInput conydiv">
              <ion-item
                [class.error]="!myForm.controls['typeTaxIdentification'].valid && myForm.controls['typeTaxIdentification'].touched"
                class="fixed-select cdr-item-input"
                lines="none"
              >
                <ion-select
                  placeholder="{{'typetaxidentification' | translate}}"
                  class="selectInput"
                  cancelText="{{'tCancel'|translate}}"
                  formControlName="typeTaxIdentification"
                  toggleIcon="chevron-down-outline"
                  mode="md"
                  [interfaceOptions]="{size: 'auto', header: 'selectoption' | translate | uppercase, mode: undefined }"
                >
                  <ion-select-option *ngFor="let item of listTypeTaxIdentification" [value]="item">
                    {{item}}
                  </ion-select-option>
                </ion-select>
              </ion-item>
              <div
                class="labelError"
                *ngIf="myForm.get('typeTaxIdentification').invalid && myForm.get('typeTaxIdentification').touched"
              >
                <ion-label> {{'spousetypeidentification' | translate}} </ion-label>
              </div>
            </div>
            <!--Identificador tributario del cónyuge-->
            <div class="myWrapInput conydiv" *ngIf="myForm.get('typeTaxIdentification').value !== '' ">
              <ion-item
                [class.error]="!myForm.controls['taxIdentifier'].valid && myForm.controls['taxIdentifier'].touched"
                [class.cdr-item-input]="myForm.get('taxIdentifier').enabled"
                [class.cdr-item-input-disabled]="!myForm.get('taxIdentifier').enabled"
                lines="none"
              >
                <ion-input
                  class="ion-input-no-transform-label"
                  labelPlacement="floating"
                  label="{{'taxidentifier' | translate}}"
                  type="text"
                  formControlName="taxIdentifier"
                ></ion-input>
              </ion-item>
              <div
                class="labelError"
                *ngIf="myForm.get('taxIdentifier').invalid && myForm.get('taxIdentifier').touched"
              >
                <!-- <ion-label>{{'spousennumdoc' | translate}}</ion-label> -->
                <ion-label>{{errorCodeSpouse('taxIdentifier')}}</ion-label>
              </div>
            </div>
          </div>
          <!-- Profesión Excepto Argentina -->
          <ng-container *ngIf="globalVars.licenseType == 0">
            <div class="myWrapInput conytit">
              <ion-item class="whitebackg" lines="none">
                <!--As part of the verification  process we require the following information-->
                <ion-label> {{'v2_profesion' | translate}} </ion-label>
              </ion-item>
              <!-- Lista de profesiones (Select) -->
              <ion-item class="firstInput fixed-select cdr-item-input" id="select-profesion" lines="none">
                <!--profesion-->
                <ion-select
                  [interfaceOptions]="profesionAlertOptions"
                  placeholder="{{'selectoption' | translate}}"
                  class="selectInput"
                  cancelText="{{'tCancel'|translate}}"
                  [(ngModel)]="register.Profesion"
                  [formControl]="$any(myForm).controls['Profesion']"
                  toggleIcon="chevron-down-outline"
                  mode="md"
                  [interfaceOptions]="{size: 'auto', header: 'selectoption' | translate | uppercase, cssClass: 'alertbig', mode: undefined }"
                  justify="space-between"
                >
                  <ion-select-option *ngFor="let ProfesionData  of profesionInfoSelect" [value]="ProfesionData.label">
                    {{ProfesionData.label}}
                  </ion-select-option>
                </ion-select>
              </ion-item>

              <!-- Otra Profesión -->
              <div class="labelError" *ngIf="!register.Profesion || register.Profesion === ''">
                <ion-label>{{'requiredfield' | translate}}</ion-label>
              </div>
              <ion-item id="Input-otra" class="cdr-item-input" *ngIf="this.showother" lines="none">
                <!--Otra-->
                <ion-input
                  class="ion-input-no-transform-label"
                  labelPlacement="floating"
                  label="{{'v2_otra' | translate}}"
                  type="text"
                  [(ngModel)]="register.Otra"
                  [formControl]="$any(myForm).controls['Otra']"
                  (click)="changeFocus()"
                ></ion-input>
              </ion-item>
            </div>
          </ng-container>

          <!-- Ocupación (Argentina) -->
          <ng-container *ngIf="utils.isArgentina()">
            <div class="myWrapInput conytit license-ar">
              <ion-item class="whitebackg" lines="none">
                <!--As part of the verification  process we require the following information-->
                <ion-label> {{'occupation' | translate}} </ion-label>
              </ion-item>
              <!-- Ocupación (en Argentina)-->
              <ion-item class="firstInput fixed-select" id="select-profesion" class="cdr-item-input" lines="none">
                <!--profesion-->
                <ion-select
                  placeholder="{{'selectoption' | translate}}"
                  class="selectInput"
                  cancelText="{{'tCancel'|translate}}"
                  [(ngModel)]="register.Profesion"
                  [formControl]="$any(myForm).controls['Profesion']"
                  toggleIcon="chevron-down-outline"
                  mode="md"
                  [interfaceOptions]="{size: 'auto', header: 'selectoption' | translate | uppercase, mode: undefined }"
                >
                  <ion-select-option *ngFor="let ProfesionData  of profesionInfoSelect" [value]="ProfesionData.label">
                    {{ProfesionData.label}}</ion-select-option
                  >
                </ion-select>
              </ion-item>
            </div>
          </ng-container>

          <!-- PRP (No Argentina) -->
          <div class="myWrapInput conytit" *ngIf="globalVars.licenseType == 0">
            <ion-row class="contRepass row">
              <ion-col class="marginr4">
                <ion-item class="whitebackg" lines="none">
                  <ion-label *ngIf="globalVars.licenseType == 0">
                    <!--Seleccionar si corresponde-->
                    {{'V2_profesionLabel' | translate}}
                  </ion-label>
                </ion-item>
                <ion-item class="whitebackg" lines="none">
                  <!-- Persona con responsabilidad publica // A-->
                  <ion-checkbox
                    labelPlacement="end"
                    justify="start"
                    class="prpcheckbox"
                    [(ngModel)]="register.PersonPrp"
                    [formControl]="$any(myForm).controls['PersonPrp']"
                    ><span *ngIf="globalVars.licenseType == 0">{{'V2_profesionPRP'|translate}} </span>
                  </ion-checkbox>
                </ion-item>
              </ion-col>
            </ion-row>
          </div>
          <!-- /PRP (No Argentina) -->
          <!-- PEP (Argentina) -->
          <div class="myWrapInput conytit" [class.license-ar]="utils.isArgentina()" *ngIf="utils.isArgentina()">
            <div>
              <span>{{'userpep_01' | translate}} </span>
              <span>
                <a external-link [href]="globalVars.FEATURES?.declaracionJuradaPep" target="_blank"
                  >{{'userpep_02' | translate}}</a
                >
              </span>
              <span> {{'userpep_03' | translate}}</span>
            </div>
            <!-- Opciones: SI/NO -->
            <div class="displayflex">
              <!-- SI -->
              <ion-item class="whitebackg" lines="none">
                <ion-checkbox
                  labelPlacement="end"
                  justify="start"
                  class="prpcheckbox"
                  (ionChange)="resetValidatorsInfoPersonPepOK()"
                  [formControl]="trueFormControl"
                >
                  <span>{{'yes' | translate}} </span>
                </ion-checkbox>
              </ion-item>
              <!-- NO -->
              <ion-item class="whitebackg" lines="none">
                <ion-checkbox
                  labelPlacement="end"
                  justify="start"
                  class="prpcheckbox"
                  (ionChange)="resetValidatorsInfoPersonPep()"
                  [formControl]="falseFormControl"
                >
                  <span>No </span>
                </ion-checkbox>
              </ion-item>
            </div>
            <ng-container *ngIf="trueFormControl.value">
              <!--select titular / afinidad-->
              <div class="myWrapInput">
                <ion-item class="whitebackg" lines="none">
                  <!--As part of the verification  process we require the following information-->
                  <ion-label>
                    {{'peptitular' | translate}} {{'or' | translate}} {{'pepAffinity' | translate}}
                  </ion-label>
                </ion-item>
                <ion-item class="cdr-item-input firstInput fixed-select" id="select-titular" lines="none">
                  <!--Seleccione una opción-->
                  <ion-select
                    placeholder="{{'selectoption' | translate}}"
                    class="selectInput"
                    cancelText="{{'tCancel'|translate}}"
                    [formControl]="$any(myForm).controls['PepTipo']"
                    toggleIcon="chevron-down-outline"
                    mode="md"
                    [interfaceOptions]="{size: 'auto', header: 'selectoption' | translate | uppercase, mode: undefined }"
                  >
                    <ion-select-option *ngFor="let titular  of pepSelectTitular" [value]="titular.value">
                      {{titular.label}}</ion-select-option
                    >
                  </ion-select>
                </ion-item>
              </div>
              <ng-container *ngIf="myForm.controls['PepTipo'].value && myForm.controls['PepTipo'].value == '1'">
                <!-- Vínculo-->
                <div
                  class="myWrapInput"
                  [class.has-error]="!myForm.get('PepVinculo').valid
              && myForm.get('PepVinculo').enabled && myForm.get('PepVinculo').touched"
                  (click)="showDisabledMailInfo = true"
                >
                  <ion-item
                    [class.error]="!myForm.get('PepVinculo').valid
                  && myForm.get('PepVinculo').enabled && myForm.get('PepVinculo').touched"
                    [class.cdr-item-input]="myForm.get('PepVinculo').enabled"
                    [class.cdr-item-input-disabled]="!myForm.get('PepVinculo').enabled"
                    lines="none"
                  >
                    <!--Email-->
                    <ion-input
                      class="ion-input-no-transform-label"
                      labelPlacement="floating"
                      label="{{'pepvinculo' | translate}}"
                      type="text"
                      [formControl]="$any(myForm).controls['PepVinculo']"
                    ></ion-input>
                  </ion-item>
                  <div
                    [hidden]="myForm.get('PepVinculo').disabled || myForm.get('PepVinculo').valid
                  || myForm.get('PepVinculo').untouched"
                    class="labelError"
                  >
                    <span *ngIf="myForm.get('PepVinculo').hasError('required')">
                      {{'error_form_requiered' | translate}}
                      <!--# Este campo  es obligatorio--></span
                    >
                    <span *ngIf="myForm.get('PepVinculo').hasError('minlength')">
                      {{'error_format' | translate}}
                      <!--# El formato no es válido--></span
                    >
                  </div>
                </div>
                <!-- Nombre y apellidos -->
                <div
                  class="myWrapInput"
                  [class.has-error]="!myForm.get('PepTitular').valid
              && myForm.get('PepTitular').enabled && myForm.get('PepTitular').touched"
                  (click)="showDisabledMailInfo = true"
                >
                  <ion-item
                    [class.error]="!myForm.get('PepTitular').valid
                  && myForm.get('PepTitular').enabled && myForm.get('PepTitular').touched"
                    [class.cdr-item-input]="myForm.get('PepTitular').enabled"
                    [class.cdr-item-input-disabled]="!myForm.get('PepTitular').enabled"
                    lines="none"
                  >
                    <!--Email-->
                    <ion-input
                      class="ion-input-no-transform-label"
                      labelPlacement="floating"
                      label="{{'v2_NomyAp' | translate}}"
                      type="text"
                      [formControl]="$any(myForm).controls['PepTitular']"
                    ></ion-input>
                  </ion-item>
                  <div
                    [hidden]="myForm.get('PepTitular').disabled || myForm.get('PepTitular').valid
                  || myForm.get('PepTitular').untouched"
                    class="labelError"
                  >
                    <span *ngIf="myForm.get('PepTitular').hasError('required')">
                      {{'error_form_requiered' | translate}}
                      <!--# Este campo  es obligatorio--></span
                    >
                    <span *ngIf="myForm.get('PepTitular').hasError('minlength')">
                      {{'error_format' | translate}}
                      <!--# El formato no es válido--></span
                    >
                  </div>
                </div>
              </ng-container>

              <!--cargo-->
              <div
                *ngIf="myForm.controls['PepTipo'].value"
                class="myWrapInput"
                [class.has-error]="!myForm.get('PepCargo').valid
              && myForm.get('PepCargo').enabled && myForm.get('PepCargo').touched"
                (click)="showDisabledMailInfo = true"
              >
                <ion-item
                  [class.error]="!myForm.get('PepCargo').valid
                && myForm.get('PepCargo').enabled && myForm.get('PepCargo').touched"
                  [class.cdr-item-input]="myForm.get('PepCargo').enabled"
                  [class.cdr-item-input-disabled]="!myForm.get('PepCargo').enabled"
                  lines="none"
                >
                  <!--Email-->
                  <ion-input
                    class="ion-input-no-transform-label"
                    labelPlacement="floating"
                    label="{{'cargo' | translate}}"
                    type="text"
                    [formControl]="$any(myForm).controls['PepCargo']"
                  ></ion-input>
                </ion-item>
                <div
                  [hidden]="myForm.get('PepCargo').disabled || myForm.get('PepCargo').valid
                || myForm.get('PepCargo').untouched"
                  class="labelError"
                >
                  <span *ngIf="myForm.get('PepCargo').hasError('required')">
                    {{'error_form_requiered' | translate}}
                    <!--# Este campo  es obligatorio--></span
                  >
                  <span *ngIf="myForm.get('PepCargo').hasError('minlength')">
                    {{'error_format' | translate}}
                    <!--# El formato no es válido--></span
                  >
                </div>
              </div>
            </ng-container>
            <div></div>
          </div>

          <!-- /PEP (Argentina) -->

          <ion-row class="contRepass">
            <ion-col>
              <div class="myWrapInput">
                <ion-item class="cdr-item-input-disabled" lines="none">
                  <!--Contraseña-->
                  <ion-input
                    class="ion-input-no-transform-label"
                    labelPlacement="floating"
                    label="{{'loginPass' | translate}}"
                    type="password"
                    disabled="true"
                    maxlength="10"
                    value="*****"
                  >
                  </ion-input>
                </ion-item>
              </div>
            </ion-col>
            <ion-col>
              <button class="btLight" (click)="changePass()">
                {{'tInPChange' | translate | uppercase}}
                <!--CAMBIAR-->
              </button>
            </ion-col>
          </ion-row>
          <!--Boton Actualizar-->
          <div class="sendForm">
            <ion-button expand="full" type="button" (click)="updateData()">
              {{'tInPUpd' | translate}}
              <!--Actualizar-->
            </ion-button>
          </div>
          <!-- # Descargar extracto -->
          <div
            class="downPdf clear"
            [attr.track-event]='["AccessToNationalUserReports", "", "", "Acceder extractos usuario", "event"]'
            *ngIf="globalVars.licenseType != 4 && globalVars.licenseType != 22"
            tappable="true"
          >
            <ion-row class="firstInput">
              <ion-col class="labelmargin">
                <ion-item class="whitebackg cursornone" lines="none">
                  <img src="assets/global/img/pdf.svg" alt="" />
                  <span class="down-pdf-span"
                    >{{'yearextract' | translate}}
                    <!--Selecciona año del extracto:-->
                  </span>
                </ion-item>
              </ion-col>
              <ion-col width-15 class="margint_5">
                <ion-item class="static-select cdr-item-input ion-item-year" lines="none">
                  <ion-select
                    class="selectInput ion-select-year"
                    [formControl]="$any(yearForm).controls['year']"
                    mode="md"
                    [interfaceOptions]="{mode: undefined}"
                  >
                    <ion-select-option *ngFor="let year of years" [value]="year">{{year}}</ion-select-option>
                    <ion-select-option value="">Todos</ion-select-option>
                  </ion-select>
                </ion-item>
              </ion-col>
              <ion-col class="contRepass">
                <!--<span [innerHTML]="'v2_descExtr' | translate"></span>-->
                <!--# Descargar <b>Extracto</b>-->
                <button
                  (click)="download()"
                  type="button"
                  class="btLight btnextracto"
                  [innerHTML]="'v2_descExtr' | translate"
                ></button>
                <!--Extracto-->
              </ion-col>
              <ion-col></ion-col>
            </ion-row>
          </div>

          <form (ngSubmit)="getExtractoAR()" [formGroup]="fromExtractAr" *ngIf="utils.isArgentina()">
            <div
              class="downPdf"
              [attr.track-event]='["AccessToNationalUserReports", "", "", "Acceder extractos usuario", "event"]'
            >
              <ion-row class="contRepass gap5">
                <ion-col class="labelmargin">
                  <ion-item class="whitebackg cursornone" lines="none">
                    <img src="assets/global/img/pdf.svg" alt="" />
                    <span class="down-pdf-span">Selecciona extracto: </span>
                  </ion-item>
                </ion-col>
                <ion-col>
                  <div class="positionrel">
                    <input
                      type="date"
                      id="start"
                      name="trip-start"
                      formControlName="fromDate"
                      [min]="fromDateMin"
                      [max]="fromDateMax"
                      placeholder="Desde:&nbsp;"
                      class="datetimeinput"
                    />
                    <ion-icon name="calendar" class="iconcalendar"></ion-icon>
                  </div>
                </ion-col>
                <ion-col>
                  <div class="positionrel">
                    <input
                      type="date"
                      id="start"
                      name="trip-start"
                      formControlName="toDate"
                      [min]="toDateMin"
                      [max]="toDateMax"
                      placeholder="Hasta:&nbsp;"
                      class="datetimeinput"
                    />
                    <ion-icon name="calendar" class="iconcalendar"></ion-icon>
                  </div>
                </ion-col>
                <ion-col class="contRepass">
                  <button
                    class="btLight btnextracto btnarg"
                    [innerHTML]="'v2_descExtrAr' | translate"
                    type="submit"
                    [disabled]="this.fromExtractAr.invalid || this.fromExtractAr.untouched"
                    [style.opacity]="(fromExtractAr.invalid || fromExtractAr.untouched) ? '0.4' : '1'"
                  ></button
                  ><!--Extracto-->
                </ion-col>
              </ion-row>
            </div>
          </form>
          <!--# Descargar contrato: (Solo Colombia) -->
          <div
            class="downPdf clear"
            [attr.track-event]='["DownloadTermsColombia", "pc", "", "", "event"]'
            *ngIf="globalVars.licenseType == 21"
            (click)="downloadTerms()"
          >
            <img src="assets/global/img/pdf.svg" alt="Contrato" />
            <span [innerHTML]="'v2_descCond' | translate"></span>
            <span>{{legalTerms.date}}</span>
          </div>
          <!--# Descargar política de privacidad (Solo Colombia) -->
          <div
            class="downPdf clear"
            [attr.track-event]='["DownloadPoliticsColombia", "pc", "", "", "event"]'
            *ngIf="globalVars.licenseType == 21 "
            tappable="true"
            (click)="downloadPolitic()"
          >
            <img src="assets/global/img/pdf.svg" alt="Política de privacidad" />
            <span [innerHTML]="'v2_descPol' | translate"></span>
            <span>{{policyTerms.date}}</span>
          </div>
        </form>
      </ion-list>
    </div>
    <codere-footer></codere-footer>
  </ion-content>
</ng-container>

<ng-template #mobileView>
  <!-- eslint-disable @angular-eslint/template/eqeqeq -->
  <ion-header>
    <user-section-title [sectionTitle]="'uPPPInfo'"></user-section-title>
  </ion-header>
  <!-- eslint-disable @angular-eslint/template/eqeqeq -->
  <ion-content class="background-color-muted">
    <!-- only show when the user is not subscribed -->
    <ion-item class="promoPanel" *ngIf="globalVars.user.pendingSMSEmail" lines="none">
      <div class="item-inner">
        <div class="input-wrapper">
          <ion-label>
            <h1 class="text-center">{{'smsEmail_title' | translate}}</h1>
            <img src="assets/global/img/superPromos.png" alt="" />
            <div class="promo-message-container">
              <p>
                {{'smsEmail_t1' | translate}}
                <!--Recibe toda la información sobre-->
                <b
                  >{{'smsEmail_t2' | translate}}
                  <!--Bonos, Promociones exclusivas, Apuestas gratis...--></b
                >
                {{'smsEmail_t3'| translate}}
                <!--y mucho más-->
              </p>
              <ion-button (click)="doSubscription()">
                {{'smsEmail_ok' | translate | uppercase}}
                <!--SÍ, INFÓRMAME-->
              </ion-button>
            </div>
          </ion-label>
        </div>
      </div>
    </ion-item>
    <div class="textcenter" *ngIf="utils.isArgentina()">{{'infopersonaltxt'| translate}}</div>
    <ion-list class="form-list">
      <form [formGroup]="myForm">
        <!-- novalidate -->
        <!--Usuario-->
        <div class="myWrapInput">
          <ion-item class="cdr-item-input-disabled" lines="none">
            <!--Usuario-->
            <ion-input
              class="ion-input-no-transform-label"
              labelPlacement="floating"
              label="{{'loginUser' | translate}}"
              formControlName="user"
              type="text"
            >
            </ion-input>
          </ion-item>
        </div>
        <!-- Mail -->
        <ng-container>
          <div
            class="myWrapInput"
            [class.has-error]="!myForm.get('email').valid
                && myForm.get('email').enabled && myForm.get('email').touched"
            (click)="showDisabledMailInfo = true"
          >
            <ion-item
              [class.error]="!myForm.get('email').valid
                  && myForm.get('email').enabled && myForm.get('email').touched"
              [class.cdr-item-input]="myForm.get('email').enabled"
              [class.cdr-item-input-disabled]="!myForm.get('email').enabled"
              lines="none"
            >
              <ion-input
                class="ion-input-no-transform-label"
                labelPlacement="floating"
                label="{{'pIMail' | translate}}"
                type="email"
                formControlName="email"
              ></ion-input>
            </ion-item>
            <div
              [hidden]="myForm.get('email').disabled || myForm.get('email').valid
                  || myForm.get('email').untouched"
              class="labelError"
            >
              <span *ngIf="myForm.get('email').hasError('required')">
                {{'v2_emailObl' | translate}}
                <!--# El email es obligatorio--></span
              >
              <span *ngIf="myForm.get('email').hasError('validFormat')">
                {{'v2_emailError' | translate}}
                <!--# El formato del email no es válido--></span
              >
              <span *ngIf="myForm.get('email').hasError('validUnique')">
                {{'error_checkMail' | translate}}
                <!--El email ya está registrado anteriormente--></span
              >
            </div>
          </div>
          <!-- Aviso modificación Mail -->
          <div class="myWrapInput" *ngIf="showDisabledMailInfo">
            <ion-item style="background-color: #f5f5f5; color: #d62929" lines="none">
              <span> Por su seguridad, si requiere modificar este dato póngase en contacto con nosotros </span>
            </ion-item>
          </div>
        </ng-container>
        <!-- Mobile -->
        <ng-container>
          <div
            class="myWrapInput"
            [class.has-error]="!myForm.get('phone').valid
                && myForm.get('phone').enabled && myForm.get('phone').touched"
            (click)="showDisabledMobileInfo = true"
          >
            <ion-item
              [class.error]="!myForm.get('phone').valid
                && myForm.get('phone').enabled && myForm.get('phone').touched"
              [class.cdr-item-input]="myForm.get('phone').enabled"
              [class.cdr-item-input-disabled]="!myForm.get('phone').enabled"
              lines="none"
            >
              <!--Móvil-->
              <ion-input
                class="ion-input-no-transform-label"
                labelPlacement="floating"
                label="{{'pIMvl' | translate}}"
                type="tel"
                formControlName="phone"
              ></ion-input>
            </ion-item>
            <div
              [hidden]="myForm.get('phone').disabled || myForm.get('phone').valid
                  || myForm.get('phone').untouched"
              class="labelError"
            >
              <span *ngIf="myForm.get('phone').hasError('required')">
                {{'v2_movilObl' | translate}}
                <!--# El móvil es obligatorio--></span
              >
              <span *ngIf="myForm.get('phone').hasError('validFormat')">
                {{'error_mvlRule' | translate}}
                <!--El formato del móvil no es válido--></span
              >
              <span *ngIf="myForm.get('phone').hasError('validUnique')">
                {{'error_checkTel' | translate}}
                <!--El teléfono ya está registrado anteriormente--></span
              >
              <span *ngIf="myForm.get('phone').hasError('minlength')"> Debe tener mínimo 10 caracteres<br /></span>
              <span *ngIf="myForm.get('phone').hasError('maxlength')">Debe tener máximo 10 caracteres<br /></span>
            </div>
          </div>
          <!-- Aviso modificación Mobile -->
          <div class="myWrapInput" *ngIf="showDisabledMobileInfo">
            <ion-item style="background-color: #f5f5f5; color: #d62929" lines="none">
              <span> Por su seguridad, si requiere modificar este dato póngase en contacto con nosotros </span>
            </ion-item>
          </div>
        </ng-container>
        <!-- Address -->
        <ng-container>
          <div
            class="myWrapInput"
            [class.has-error]="!myForm.get('address').valid
                && myForm.get('address').enabled && myForm.get('address').touched"
            (click)="setfalseshowDisabledAddressInfo()"
          >
            <ion-item
              [class.error]="!myForm.get('address').valid
                  && myForm.get('address').enabled && myForm.get('address').touched"
              [class.cdr-item-input]="myForm.get('address').enabled"
              [class.cdr-item-input-disabled]="!myForm.get('address').enabled"
              lines="none"
            >
              <!--Dirección-->
              <ion-input
                class="ion-input-no-transform-label"
                autocomplete="off"
                autoCorrect="off"
                spellcheck="off"
                labelPlacement="floating"
                label="{{'pIAddress' | translate}}"
                type="text"
                formControlName="address"
                [class.ion-input-error]="!myForm.get('address').valid
                  && myForm.get('address').enabled && myForm.get('address').touched && myForm.get('address').dirty"
              ></ion-input>
            </ion-item>
            <div
              [hidden]="myForm.get('address').disabled || myForm.get('address').valid ||
                  myForm.get('address').untouched"
              class="labelError"
            >
              {{'v2_errorDirec' | translate}}
              <!--# Debes introducir correctamente la dirección-->
            </div>

            <!-- Extra address fields in Argentina -->
            <ion-row *ngIf="utils.isArgentina()">
              <ion-col>
                <ion-item class="cdr-item-input-disabled" lines="none">
                  <ion-input
                    class="ion-input-no-transform-label"
                    labelPlacement="floating"
                    label="Número"
                    type="text"
                    formControlName="num"
                    class="address-fields-ar"
                  ></ion-input>
                </ion-item>
              </ion-col>
              <ion-col>
                <ion-item class="cdr-item-input-disabled" lines="none">
                  <ion-input
                    class="ion-input-no-transform-label"
                    labelPlacement="floating"
                    label="Piso"
                    type="text"
                    class="address-fields-ar"
                    formControlName="piso"
                  ></ion-input>
                </ion-item>
              </ion-col>
              <ion-col>
                <ion-item class="cdr-item-input-disabled" lines="none">
                  <ion-input
                    class="ion-input-no-transform-label"
                    labelPlacement="floating"
                    label="Departamento"
                    type="text"
                    class="address-fields-ar"
                    formControlName="dpto"
                  ></ion-input>
                </ion-item>
              </ion-col>
            </ion-row>
          </div>
          <!-- Aviso modificación Adress -->
          <div class="myWrapInput" *ngIf="showDisabledAddressInfo">
            <ion-item style="background-color: #f5f5f5; color: #d62929" lines="none">
              <span>
                Para modificar los datos de contacto, debe ponerse en contacto con nuestro Servicio de Atención al
                Cliente.
              </span>
            </ion-item>
          </div>
        </ng-container>
        <!-- Código Postal -->
        <div
          class="myWrapInput"
          [class.has-error]="!myForm.controls['zipCode'].valid
              && myForm.get('zipCode').enabled && myForm.controls['zipCode'].touched"
        >
          <ion-item
            [class.error]="!myForm.controls['zipCode'].valid
                && myForm.get('zipCode').enabled && myForm.controls['zipCode'].touched"
            [class.cdr-item-input]="myForm.get('zipCode').enabled"
            [class.cdr-item-input-disabled]="!myForm.get('zipCode').enabled"
            lines="none"
          >
            <!--Código postal-->
            <ion-input
              class="ion-input-no-transform-label"
              labelPlacement="floating"
              label="{{'rZip' | translate}}"
              type="tel"
              formControlName="zipCode"
            >
            </ion-input>
          </ion-item>
        </div>

        <!-- estado civil-->
        <div class="myWrapInput" *ngIf="utils.isArgentina()">
          <ion-item class="firstInput fixed-select cdr-item-input" id="select-estado" lines="none">
            <!--Estado civil-->
            <ion-select
              placeholder="{{'civilstatus' | translate}}"
              class="selectInput"
              cancelText="{{'tCancel'|translate}}"
              formControlName="estCivil"
              toggleIcon="chevron-down-outline"
              mode="md"
              [interfaceOptions]="{size: 'auto', header: 'selectoption' | translate | uppercase, mode: undefined }"
            >
              <ion-select-option *ngFor="let estado  of estCiviles" [value]="estado.name">
                {{estado.name}}
              </ion-select-option>
            </ion-select>
          </ion-item>
          <div class="labelError" *ngIf="myForm.get('estCivil').invalid && myForm.get('estCivil').touched">
            <ion-label>Es necesario seleccionar un estado civil</ion-label>
          </div>
        </div>
        <!--datos del cónyuge-->
        <div
          *ngIf="(utils.isArgentina()) &&
                  (myForm.get('estCivil').value == 'Casado/a' ||
                  myForm.get('estCivil').value == 'Unión Civil' ||
                  myForm.get('estCivil').value == 'Unión de Hecho')"
          class="conytit"
        >
          <div>{{'spousedata' | translate}}</div>
          <div class="conydiv">
            <!--Nombre del cónyuge-->
            <ion-item
              [class.error]="!myForm.controls['cygNombre'].valid && myForm.controls['cygNombre'].touched"
              [class.cdr-item-input]="myForm.get('cygNombre').enabled"
              [class.cdr-item-input-disabled]="!myForm.get('cygNombre').enabled"
              lines="none"
            >
              <ion-input
                class="ion-input-no-transform-label"
                labelPlacement="floating"
                label="{{'spousename' | translate}}"
                type="text"
                maxlength="50"
                formControlName="cygNombre"
              ></ion-input>
            </ion-item>
            <div class="labelError" *ngIf="myForm.get('cygNombre').invalid && myForm.get('cygNombre').touched">
              <ion-label>Es necesario indicar el nombre del cónyuge</ion-label>
            </div>
            <!--Apellidos del cónyuge-->
            <ion-item
              [class.error]="!myForm.controls['cygApellidos'].valid && myForm.controls['cygApellidos'].touched"
              [class.cdr-item-input]="myForm.get('cygApellidos').enabled"
              [class.cdr-item-input-disabled]="!myForm.get('cygApellidos').enabled"
              lines="none"
            >
              <ion-input
                class="ion-input-no-transform-label"
                labelPlacement="floating"
                label="{{'spousename2' | translate}}"
                type="text"
                maxlength="50"
                formControlName="cygApellidos"
              ></ion-input>
            </ion-item>
            <div class="labelError" *ngIf="myForm.get('cygApellidos').invalid && myForm.get('cygApellidos').touched">
              <ion-label>Es necesario indicar los apellidos del cónyuge</ion-label>
            </div>
            <!--Nacionalidad del cónyuge-->
            <div class="myWrapInput">
              <ion-item
                [class.error]="!myForm.controls['cygNacionalidad'].valid && myForm.controls['cygNacionalidad'].touched"
                class="fixed-select cdr-item-input"
                lines="none"
              >
                <ion-select
                  placeholder="{{'spousenationality' | translate}}"
                  class="selectInput"
                  cancelText="{{'tCancel'|translate}}"
                  formControlName="cygNacionalidad"
                  toggleIcon="chevron-down-outline"
                  mode="md"
                  [interfaceOptions]="{size: 'auto', header: 'selectoption' | translate | uppercase, mode: undefined }"
                >
                  <ion-select-option *ngFor="let item of nationalities" [value]="item"> {{item}} </ion-select-option>
                </ion-select>
              </ion-item>
              <div
                class="labelError"
                *ngIf="myForm.get('cygNacionalidad').invalid && myForm.get('cygNacionalidad').touched"
              >
                <ion-label>Es necesario indicar la nacionalidad del cónyuge</ion-label>
              </div>
            </div>
            <!--Tipo de documento-->
            <div class="myWrapInput">
              <ion-item *ngIf="globalVars.browser!='internet'" class="fixed-select cdr-item-input" lines="none">
                <ion-select
                  placeholder="{{'NotResidDocumentType' | translate}}"
                  class="selectInput"
                  cancelText="{{'tCancel'|translate}}"
                  formControlName="cygTipoDoc"
                  toggleIcon="chevron-down-outline"
                  mode="md"
                  [interfaceOptions]="{size: 'auto', header: 'selectoption' | translate | uppercase, mode: undefined }"
                >
                  <ion-select-option *ngFor="let item of typesDocument" [value]="item.id">
                    {{item.label}}
                  </ion-select-option>
                </ion-select>
              </ion-item>
              <div class="labelError" *ngIf="myForm.get('cygTipoDoc').invalid && myForm.get('cygTipoDoc').touched">
                <ion-label>Es necesario indicar el documento del cónyuge</ion-label>
              </div>
            </div>
            <!--Número de documento-->
            <div class="myWrapInput">
              <ion-item
                [class.error]="!myForm.controls['cygNumDoc'].valid && myForm.controls['cygNumDoc'].touched"
                [class.cdr-item-input]="myForm.get('cygNumDoc').enabled"
                [class.cdr-item-input-disabled]="!myForm.get('cygNumDoc').enabled"
                lines="none"
              >
                <ion-input
                  class="ion-input-no-transform-label"
                  labelPlacement="floating"
                  label="{{'NotResidDocumentTypeNum' | translate}}"
                  type="text"
                  maxlength="12"
                  formControlName="cygNumDoc"
                ></ion-input>
              </ion-item>
              <div class="labelError" *ngIf="myForm.get('cygNumDoc').invalid && myForm.get('cygNumDoc').touched">
                <!-- <ion-label>{{'spousennumdoc' | translate}}</ion-label> -->
                <ion-label>{{errorCodeSpouse('cygNumDoc')}}</ion-label>
              </div>
            </div>
          </div>
          <!--Tipo de identificación tributaria del cónyuge-->
          <div class="myWrapInput conydiv">
            <ion-item
              [class.error]="!myForm.controls['typeTaxIdentification'].valid && myForm.controls['typeTaxIdentification'].touched"
              class="fixed-select cdr-item-input"
              lines="none"
            >
              <ion-select
                placeholder="{{'typetaxidentification' | translate}}"
                class="selectInput"
                cancelText="{{'tCancel'|translate}}"
                formControlName="typeTaxIdentification"
                toggleIcon="chevron-down-outline"
                mode="md"
                [interfaceOptions]="{mode: undefined}"
                [interfaceOptions]="{size: 'auto', header: 'selectoption' | translate | uppercase, mode: undefined }"
              >
                <ion-select-option *ngFor="let item of listTypeTaxIdentification" [value]="item">
                  {{item}}
                </ion-select-option>
              </ion-select>
            </ion-item>
            <div
              class="labelError"
              *ngIf="myForm.get('typeTaxIdentification').invalid && myForm.get('typeTaxIdentification').touched"
            >
              <ion-label> {{'spousetypeidentification' | translate}} </ion-label>
            </div>
          </div>
          <!--Identificador tributario del cónyuge-->
          <div class="myWrapInput conydiv" *ngIf="myForm.get('typeTaxIdentification').value !== '' ">
            <ion-item
              [class.error]="!myForm.controls['taxIdentifier'].valid && myForm.controls['taxIdentifier'].touched"
              [class.cdr-item-input]="myForm.get('taxIdentifier').enabled"
              [class.cdr-item-input-disabled]="!myForm.get('taxIdentifier').enabled"
              lines="none"
            >
              <ion-input
                class="ion-input-no-transform-label"
                labelPlacement="floating"
                label="{{'taxidentifier' | translate}}"
                type="text"
                formControlName="taxIdentifier"
              ></ion-input>
            </ion-item>
            <div class="labelError" *ngIf="myForm.get('taxIdentifier').invalid && myForm.get('taxIdentifier').touched">
              <!-- <ion-label>{{'spousennumdoc' | translate}}</ion-label> -->
              <ion-label>{{errorCodeSpouse('taxIdentifier')}}</ion-label>
            </div>
          </div>
        </div>
        <!-- Profesión Excepto Argentina -->
        <ng-container *ngIf="globalVars.licenseType == 0">
          <div class="myWrapInput conytit">
            <ion-item class="whitebackg" lines="none">
              <!--As part of the verification  process we require the following information-->
              <ion-label> {{'v2_profesion' | translate}} </ion-label>
            </ion-item>
            <!-- Lista de profesiones (Select) -->
            <ion-item class="firstInput fixed-select cdr-item-input" id="select-profesion" lines="none">
              <!--profesion-->
              <ion-select
                [interfaceOptions]="profesionAlertOptions"
                placeholder="{{'selectoption' | translate}}"
                class="selectInput"
                cancelText="{{'tCancel'|translate}}"
                [(ngModel)]="register.Profesion"
                [formControl]="$any(myForm).controls['Profesion']"
                toggleIcon="chevron-down-outline"
                mode="md"
                [interfaceOptions]="{size: 'auto', header: 'selectoption' | translate | uppercase, cssClass: 'alertbig', mode: undefined }"
              >
                <ion-select-option *ngFor="let ProfesionData  of profesionInfoSelect" [value]="ProfesionData.label">
                  {{ProfesionData.label}}
                </ion-select-option>
              </ion-select>
            </ion-item>

            <!-- Otra Profesión -->
            <div class="labelError" *ngIf="!register.Profesion || register.Profesion === ''">
              <ion-label>{{'requiredfield' | translate}}</ion-label>
            </div>
            <ion-item id="Input-otra" class="cdr-item-input" *ngIf="this.showother" lines="none">
              <!--Otra-->
              <ion-input
                class="ion-input-no-transform-label"
                labelPlacement="floating"
                label="{{'v2_otra' | translate}}"
                type="text"
                [(ngModel)]="register.Otra"
                [formControl]="$any(myForm).controls['Otra']"
                (click)="changeFocus()"
              ></ion-input>
            </ion-item>
          </div>
        </ng-container>

        <!-- Ocupación (Argentina) -->
        <ng-container *ngIf="utils.isArgentina()">
          <div class="myWrapInput conytit license-ar">
            <ion-item class="whitebackg" lines="none">
              <!--As part of the verification  process we require the following information-->
              <ion-label> {{'occupation' | translate}} </ion-label>
            </ion-item>
            <!-- Ocupación (en Argentina)-->
            <ion-item class="firstInput fixed-select" id="select-profesion" class="cdr-item-input" lines="none">
              <!--profesion-->
              <ion-select
                placeholder="{{'selectoption' | translate}}"
                class="selectInput"
                cancelText="{{'tCancel'|translate}}"
                [(ngModel)]="register.Profesion"
                [formControl]="$any(myForm).controls['Profesion']"
                toggleIcon="chevron-down-outline"
                mode="md"
                [interfaceOptions]="{size: 'auto', header: 'selectoption' | translate | uppercase, mode: undefined }"
              >
                <ion-select-option *ngFor="let ProfesionData  of profesionInfoSelect" [value]="ProfesionData.label">
                  {{ProfesionData.label}}</ion-select-option
                >
              </ion-select>
            </ion-item>
          </div>
        </ng-container>

        <!-- PRP (No Argentina) -->
        <div class="myWrapInput conytit paddingb0" *ngIf="globalVars.licenseType == 0">
          <ion-row class="contRepass row">
            <ion-col class="marginr4">
              <ion-item class="whitebackg" lines="none">
                <ion-label *ngIf="globalVars.licenseType == 0">
                  <!--Seleccionar si corresponde-->
                  {{'V2_profesionLabel' | translate}}
                </ion-label>
              </ion-item>
              <ion-item class="whitebackg" lines="none">
                <!-- Persona con responsabilidad publica // A-->
                <ion-checkbox
                  labelPlacement="end"
                  justify="start"
                  class="prpcheckbox"
                  [(ngModel)]="register.PersonPrp"
                  [formControl]="$any(myForm).controls['PersonPrp']"
                >
                  <span class="mtext" *ngIf="globalVars.licenseType == 0">{{'V2_profesionPRP'|translate}} </span>
                </ion-checkbox>
              </ion-item>
            </ion-col>
          </ion-row>
        </div>
        <!-- /PRP (No Argentina) -->
        <!-- PEP (Argentina) -->
        <div class="myWrapInput conytit" [class.license-ar]="utils.isArgentina()" *ngIf="utils.isArgentina()">
          <div>
            <span>{{'userpep_01' | translate}}</span>
            <span
              ><a external-link [href]="globalVars.FEATURES?.declaracionJuradaPep" target="_blank">
                {{'userpep_02' | translate}}</a
              ></span
            >
            <span>{{'userpep_03' | translate}}</span>
          </div>
          <!-- Opciones: SI/NO -->
          <div class="displayflex">
            <!-- SI -->
            <ion-item class="whitebackg" lines="none">
              <ion-checkbox
                labelPlacement="end"
                justify="start"
                class="prpcheckbox"
                (ionChange)="resetValidatorsInfoPersonPepOK()"
                [formControl]="trueFormControl"
              >
                <span>{{'yes' | translate}} </span>
              </ion-checkbox>
            </ion-item>
            <!-- NO -->
            <ion-item class="whitebackg" lines="none">
              <ion-checkbox
                labelPlacement="end"
                justify="start"
                class="prpcheckbox"
                (ionChange)="resetValidatorsInfoPersonPep()"
                [formControl]="falseFormControl"
              >
                <span>No </span>
              </ion-checkbox>
            </ion-item>
          </div>
          <ng-container *ngIf="trueFormControl.value">
            <!--select titular / afinidad-->
            <div class="myWrapInput">
              <ion-item class="whitebackg" lines="none">
                <!--As part of the verification  process we require the following information-->
                <ion-label> {{'peptitular' | translate}} {{'or' | translate}} {{'pepAffinity' | translate}} </ion-label>
              </ion-item>
              <ion-item class="firstInput fixed-select" id="select-titular" lines="none">
                <!--Seleccione una opción-->
                <ion-select
                  placeholder="{{'selectoption' | translate}}"
                  class="selectInput"
                  cancelText="{{'tCancel'|translate}}"
                  [formControl]="$any(myForm).controls['PepTipo']"
                  toggleIcon="chevron-down-outline"
                  mode="md"
                  [interfaceOptions]="{size: 'auto', header: 'selectoption' | translate | uppercase, mode: undefined }"
                >
                  <ion-select-option *ngFor="let titular  of pepSelectTitular" [value]="titular.value">
                    {{titular.label}}</ion-select-option
                  >
                </ion-select>
              </ion-item>
            </div>
            <ng-container *ngIf="myForm.controls['PepTipo'].value && myForm.controls['PepTipo'].value == '1'">
              <!-- Vínculo-->
              <div
                class="myWrapInput"
                [class.has-error]="!myForm.get('PepVinculo').valid
                && myForm.get('PepVinculo').enabled && myForm.get('PepVinculo').touched"
                (click)="showDisabledMailInfo = true"
              >
                <ion-item
                  [class.error]="!myForm.get('PepVinculo').valid
                    && myForm.get('PepVinculo').enabled && myForm.get('PepVinculo').touched"
                  [class.cdr-item-input]="myForm.get('PepVinculo').enabled"
                  [class.cdr-item-input-disabled]="!myForm.get('PepVinculo').enabled"
                  lines="none"
                >
                  <!--Email-->
                  <ion-input
                    class="ion-input-no-transform-label"
                    labelPlacement="floating"
                    label="{{'pepvinculo' | translate}}"
                    type="text"
                    [formControl]="$any(myForm).controls['PepVinculo']"
                  ></ion-input>
                </ion-item>
                <div
                  [hidden]="myForm.get('PepVinculo').disabled || myForm.get('PepVinculo').valid
                    || myForm.get('PepVinculo').untouched"
                  class="labelError"
                >
                  <span *ngIf="myForm.get('PepVinculo').hasError('required')">
                    {{'error_form_requiered' | translate}}
                    <!--# Este campo  es obligatorio--></span
                  >
                  <span *ngIf="myForm.get('PepVinculo').hasError('minlength')">
                    {{'error_format' | translate}}
                    <!--# El formato no es válido--></span
                  >
                </div>
              </div>
              <!-- Nombre y apellidos -->
              <div
                class="myWrapInput"
                [class.has-error]="!myForm.get('PepTitular').valid
                && myForm.get('PepTitular').enabled && myForm.get('PepTitular').touched"
                (click)="showDisabledMailInfo = true"
              >
                <ion-item
                  [class.error]="!myForm.get('PepTitular').valid
                    && myForm.get('PepTitular').enabled && myForm.get('PepTitular').touched"
                  [class.cdr-item-input]="myForm.get('PepTitular').enabled"
                  [class.cdr-item-input-disabled]="!myForm.get('PepTitular').enabled"
                  lines="none"
                >
                  <!--Email-->
                  <ion-input
                    class="ion-input-no-transform-label"
                    labelPlacement="floating"
                    label="{{'v2_NomyAp' | translate}}"
                    type="text"
                    [formControl]="$any(myForm).controls['PepTitular']"
                  ></ion-input>
                </ion-item>
                <div
                  [hidden]="myForm.get('PepTitular').disabled || myForm.get('PepTitular').valid
                    || myForm.get('PepTitular').untouched"
                  class="labelError"
                >
                  <span *ngIf="myForm.get('PepTitular').hasError('required')">
                    {{'error_form_requiered' | translate}}
                    <!--# Este campo  es obligatorio--></span
                  >
                  <span *ngIf="myForm.get('PepTitular').hasError('minlength')">
                    {{'error_format' | translate}}
                    <!--# El formato no es válido--></span
                  >
                </div>
              </div>
            </ng-container>

            <!--cargo-->
            <div
              *ngIf="myForm.controls['PepTipo'].value"
              class="myWrapInput"
              [class.has-error]="!myForm.get('PepCargo').valid
                && myForm.get('PepCargo').enabled && myForm.get('PepCargo').touched"
              (click)="showDisabledMailInfo = true"
            >
              <ion-item
                [class.error]="!myForm.get('PepCargo').valid
                  && myForm.get('PepCargo').enabled && myForm.get('PepCargo').touched"
                [class.cdr-item-input]="myForm.get('PepCargo').enabled"
                [class.cdr-item-input-disabled]="!myForm.get('PepCargo').enabled"
                lines="none"
              >
                <!--Email-->
                <ion-input
                  class="ion-input-no-transform-label"
                  labelPlacement="floating"
                  label="{{'cargo' | translate}}"
                  type="text"
                  [formControl]="$any(myForm).controls['PepCargo']"
                ></ion-input>
              </ion-item>
              <div
                [hidden]="myForm.get('PepCargo').disabled || myForm.get('PepCargo').valid
                  || myForm.get('PepCargo').untouched"
                class="labelError"
              >
                <span *ngIf="myForm.get('PepCargo').hasError('required')">
                  {{'error_form_requiered' | translate}}
                  <!--# Este campo  es obligatorio--></span
                >
                <span *ngIf="myForm.get('PepCargo').hasError('minlength')">
                  {{'error_format' | translate}}
                  <!--# El formato no es válido--></span
                >
              </div>
            </div>
          </ng-container>
          <div></div>
        </div>

        <!-- /PEP (Argentina) -->

        <ion-row class="contRepass margint23">
          <ion-col>
            <div class="myWrapInput">
              <ion-item class="cdr-item-input-disabled input-password" lines="none">
                <!--Contraseña-->
                <ion-input
                  class="ion-input-no-transform-label"
                  labelPlacement="floating"
                  label="{{'loginPass' | translate}}"
                  type="password"
                  disabled="true"
                  maxlength="10"
                  value="*****"
                >
                </ion-input>
              </ion-item>
            </div>
          </ion-col>
          <ion-col>
            <button class="btLight" (click)="changePass()">
              {{'tInPChange' | translate | uppercase}}
              <!--CAMBIAR-->
            </button>
          </ion-col>
        </ion-row>
        <!--Boton Actualizar-->
        <div class="sendForm">
          <ion-button expand="full" type="button" (click)="updateData()">
            {{'tInPUpd' | translate}}
            <!--Actualizar-->
          </ion-button>
        </div>
        <!-- # Descargar extracto -->
        <div
          class="downPdf clear"
          [attr.track-event]='["AccessToNationalUserReports", "", "", "Acceder extractos usuario", "event"]'
          *ngIf="globalVars.licenseType != 4 && globalVars.licenseType != 22"
          tappable="true"
        >
          <ion-row class="firstInput">
            <ion-col class="labelmargin">
              <ion-item class="whitebackg cdr-item-input cursornone" lines="none">
                <img src="assets/global/img/pdf.svg" alt="" />
                <span>Selecciona año: </span>
              </ion-item>
            </ion-col>
            <ion-col style="padding: 0">
              <ion-item class="static-select cdr-item-input" lines="none">
                <ion-select
                  class="selectInput ion-select-year"
                  [formControl]="$any(yearForm).controls['year']"
                  toggleIcon="chevron-down-outline"
                  mode="md"
                  [interfaceOptions]="{size: 'auto', header: 'selectoption' | translate | uppercase, mode: undefined }"
                >
                  <ion-select-option *ngFor="let year of years" [value]="year">{{year}}</ion-select-option>
                  <ion-select-option value="">Todos</ion-select-option>
                </ion-select>
              </ion-item>
            </ion-col>
          </ion-row>
          <div class="contRepass">
            <ion-col class="contRepass">
              <!--<span [innerHTML]="'v2_descExtr' | translate"></span>-->
              <!--# Descargar <b>Extracto</b>-->
              <button
                (click)="download()"
                type="button"
                class="btLight btnextracto btnextractoESMobile"
                [innerHTML]="'v2_descExtr' | translate"
              ></button>
              <!--Extracto-->
            </ion-col>
          </div>
          <ion-col></ion-col>
        </div>

        <form (ngSubmit)="getExtractoAR()" [formGroup]="fromExtractAr" *ngIf="utils.isArgentina()">
          <div
            class="downPdf download-extract-container"
            [attr.track-event]='["AccessToNationalUserReports", "", "", "Acceder extractos usuario", "event"]'
          >
            <ion-row class="contRepass gap5">
              <ion-col class="labelmargin" size="12">
                <ion-item class="cdr-item-input whitebackg cursornone" lines="none">
                  <img src="assets/global/img/pdf.svg" alt="" />
                  <span>Selecciona extracto: </span>
                </ion-item>
              </ion-col>
              <ion-col id="fromDate" #fromDate size="12" class="marginl05">
                <div class="positionrel from-date-option">
                  <input
                    type="date"
                    id="start"
                    name="trip-start"
                    formControlName="fromDate"
                    [min]="fromDateMin"
                    [max]="fromDateMax"
                    placeholder="Desde:&nbsp;"
                    class="datetimeinput"
                  />
                  <ion-icon name="calendar-outline" class="iconcalendar"></ion-icon>
                </div>
              </ion-col>
              <ion-col size="12" class="marginl05">
                <div class="positionrel to-date-option">
                  <input
                    type="date"
                    id="start"
                    name="trip-start"
                    formControlName="toDate"
                    [min]="toDateMin"
                    [max]="toDateMax"
                    placeholder="Hasta:&nbsp;"
                    class="datetimeinput"
                  />
                  <ion-icon name="calendar-outline" class="iconcalendar"></ion-icon>
                </div>
              </ion-col>
              <ion-col class="contRepass btn-extracto-container" size="12">
                <button
                  class="btLight btnextracto btnextracAR"
                  [innerHTML]="'v2_descExtrAr' | translate"
                  type="submit"
                  [disabled]="this.fromExtractAr.invalid || this.fromExtractAr.untouched"
                  [style.opacity]="(fromExtractAr.invalid || fromExtractAr.untouched) ? '0.4' : '1'"
                ></button
                ><!--Extracto-->
              </ion-col>
            </ion-row>
          </div>
        </form>
        <!--# Descargar contrato: (Solo Colombia) -->
        <div
          class="downPdf clear"
          [attr.track-event]='["DownloadTermsColombia", "pc", "", "", "event"]'
          *ngIf="globalVars.licenseType == 21"
          (click)="downloadTerms()"
        >
          <img src="assets/global/img/pdf.svg" alt="Contrato" />
          <span [innerHTML]="'v2_descCond' | translate"></span>
          <span>{{legalTerms.date}}</span>
        </div>
        <!--# Descargar política de privacidad (Solo Colombia) -->
        <div
          class="downPdf clear"
          [attr.track-event]='["DownloadPoliticsColombia", "pc", "", "", "event"]'
          *ngIf="globalVars.licenseType == 21 "
          tappable="true"
          (click)="downloadPolitic()"
        >
          <img src="assets/global/img/pdf.svg" alt="Política de privacidad" />
          <span [innerHTML]="'v2_descPol' | translate"></span>
          <span>{{policyTerms.date}}</span>
        </div>
      </form>
    </ion-list>
    <codere-footer></codere-footer>
  </ion-content>
</ng-template>
