import {
  C_FreeBetTicket,
  C_TicketApuestasNew,
  CombinationMulticastInput,
  FilterItem,
  GreyhoundHorseBet,
  LineItem,
  OddItem,
  OveraskDataMethods,
  SmartLineItem,
  StakeGroup,
  StakeModel,
  _getFilters,
  closeTicketResult,
  oddsUpdateItem,
  systemData,
  ticketBetSlip,
} from "@sports-models/index";

import { AABonusMethods, FreebetInstanceMethods, TicketMethods } from "@sports-utils/index";

import { StoreConstants } from "../../utils/store.constants";

import * as TicketActions from "./ticket.actions";

export interface ticketState {
  betSlip;
  filters: FilterItem[];
  filterSelected: FilterItem;
  freeBets: C_FreeBetTicket[];
  betbuilderSelection;
  processingFrom;
  processingItem;
  ticket: C_TicketApuestasNew;
}

const initialState: ticketState = {
  betSlip: null,
  filters: _getFilters(),
  filterSelected: null,
  freeBets: [],
  betbuilderSelection: [],
  processingFrom: "",
  processingItem: null,
  ticket: TicketMethods.newC_TicketApuestas(),
};

const aux = {
  keepSelection: false,
  modification: false,
  setToAccumulator: false,
  valueCombinationMulticastItems: undefined,
  valueLineItems: undefined,
  valueSmartLineItems: undefined,
  valueStraightMulticastItems: undefined,
};

export function ticketReducer(state = initialState, action: TicketActions.Types) {
  switch (action.type) {
    case TicketActions.TICKET_ERROR:
      return handleError(state, action.error);

    case TicketActions.INIT_FILTERS:
      return initFilters(state, action.filters);

    case TicketActions.CHANGE_FILTER:
      return changeFilter(state, action.filter);

    case TicketActions.SET_FILTERS:
      return setFilters(state);

    case TicketActions.SET_FILTER_SELECTED:
      return setFilterSelected(state);

    case TicketActions.ADD_SINGLE_RESULT:
      return addSingleResult(state, action.game);

    case TicketActions.ADD_SINGLE_RESULT_BY_ID:
      return addSingleResultById(state, action.betId);

    case TicketActions.ADD_SMARTMARKET_SELECTION:
      return addSmartMarketSelection(state, action.selection);

    case TicketActions.ADD_SMARTMARKET_ITEM:
      return addSmartMarketBet(state, action.smartMarketRequest, action.smartMarketBet);

    case TicketActions.ADD_STRAIGHT_MULTICAST:
      return addStraightMulticastBet(state, action.straightMulticastInput, action.straightMulticastItem);

    case TicketActions.ADD_COMBINATION_MULTICAST:
      return addCombinationMulticastBet(state, action.combinationMulticastInput, action.combinationMulticastItem);

    case TicketActions.KEEP_SELECTION:
      return keepSelection(state, action.globalsData);

    case TicketActions.SET_SYSTEM_BET_TYPE:
      return setSystemBetType(state, action.newTicketState, action.systemStake);

    case TicketActions.UPDATE_AND_CHANGE_TO_SINGLES:
      return updateAndChangeToSingles(state, action.globalsData);

    case TicketActions.SAVE_AND_CLOSE_BET:
      return saveBetSlip(state, action.betSlip);

    case TicketActions.BETBUILDER_ODDS_CHANGE:
      return betbuilderOddsChange(state);

    case TicketActions.REMOVE_ITEM:
      return removeItem(state, action.id);

    case TicketActions.REMOVE_ITEMS:
      return removeItems(state, action.ids);

    case TicketActions.REMOVE_MULTICAST_ITEM:
      return removeMulticastItem(state, action.id);

    case TicketActions.REMOVE_COMBINATION_ITEM:
      return removeCombination(state, action.id);

    case TicketActions.REMOVE_SMARTMARKET_SELECTION:
      return removeSmartMarketSelection(state, action.id);

    case TicketActions.REMOVE_SMARTMARKET_ALL_SELECTIONS:
      return removeSmartMarketAllSelections(state);

    case TicketActions.REMOVE_SMARTMARKET_ITEM:
      return removeSmartMarketItem(state, action.id);

    case TicketActions.RECOVER_TICKET:
      return recoverTicket(state, action.localStorageBetSlip, action.ticketItemsAndAmount);

    case TicketActions.SET_STAKE:
      return setStake(state, action.stake);

    case TicketActions.SET_MULTI_STAKE:
      return setMultiStake(state, action.multiStake);

    case TicketActions.REMOVE_STAKE:
      return removeStake(state, action.stakeGroup);

    case TicketActions.PLACE_BET:
      return placeBet(state, action.groupKey);

    case TicketActions.CLOSE_BET:
      return closeBet(state, action.placeBet);

    case TicketActions.OVERASK_START:
      return overaskStart(state, action.overaskData, action.closeBetStake);

    case TicketActions.OVERASK_START_POLLING:
      return overaskStartPolling(state, action.overaskData, action.status);

    case TicketActions.OVERASK_MAX_STAKE:
      return overaskMaxStake(state, action.overaskData, action.status, action.stake);

    case TicketActions.OVERASK_TIMEOUT:
      return overaskTimeOut(state, action.overaskData, action.status);

    case TicketActions.OVERASK_REJECTED:
      return overaskRejected(state, action.overaskData, action.status);

    case TicketActions.CLOSED_TICKET_RESULT:
      return closedTicketResult(state, action.closeTicketResult, action.newOdds);

    case TicketActions.CLOSED_TICKET_RESULT_AND_ODDS_UPDATE:
      return closedTicketResult(state, action.closeTicketResult, action.newOdds);

    case TicketActions.SAVE_BETSLIP:
      return saveBetSlip(state, action.betSlip);

    case TicketActions.SAVE_BETSLIP_AND_EVALUATE_FLOW:
      return saveBetSlipAndEvaluateFlow(state, action.betSlip, action.checkFilterChange); //? SaveBetSlipAndEvaluateFlow

    case TicketActions.MARKET_LOCKED:
      return marketLocked(state, action.odds);

    case TicketActions.UPDATE_TICKET:
      return updateTicket(state, action.globalsData);

    case TicketActions.UPDATE_AND_CHECK_FOR_ERRORS:
      return updateAndCheckForErrors(state, action.globalsData);

    case TicketActions.PLACING_BET:
      return placingBet(state, action.placeStatus);

    case TicketActions.SET_ODD_ERRORS:
      return setOddErrors(state);

    case TicketActions.CHECK_FOR_ERRORS:
      return checkForErrors(state);

    case TicketActions.CHANGE_FILTERS_AND_SET_ODD_ERRORS:
      return changeFiltersAndSetOddErrors(state, action.filter);

    case TicketActions.SET_LINE_ITEM_SELECTED:
      return setLineItemSelected(state, action.lineItemSelection);

    case TicketActions.SET_LINE_ITEM_UNSELECTED:
      return setLineItemUnselected(state);

    case TicketActions.AMOUNT_LOCAL_LINE_ITEM_UPDATE:
      return amountLocalLineItemUpdate(state, action.payload);

    case TicketActions.AMOUNT_LOCAL_TOTAL_UPDATE:
      return amountLocalTotalUpdate(state, action.value);

    case TicketActions.REMOVE_TICKET:
      return removeTicket(state);

    case TicketActions.SET_BET_ODDS_ACCEPTANCE:
      return setOddErrors(state);

    // FREEBETS
    case TicketActions.FREEBETS_ADD:
      return freeBetsAdd(state, action.voucher, action.personalized, action.filterSelected);

    case TicketActions.FREEBETS_SET_VOUCHERS:
      return setFreeBetsVouchers(state, action.vouchers);

    case TicketActions.FREEBETS_UPDATE_VALIDATIONS:
      return freeBetsUpdateValidations(state, action.freebetStake, action.betSlip);

    case TicketActions.FREEBETS_UPDATE_VOUCHERS:
      return updateFreeBetsVouchers(state);

    case TicketActions.FREEBETS_CANCEL:
      return freeBetsCancel(state, action.voucherCode);

    default:
      return state;
  }
}

function handleError(state, error) {
  const auxState = JSON.parse(JSON.stringify(state));
  auxState.ticket.isPlacingbet = false;
  auxState.ticket.processing = false;
  auxState.processingFrom = "";
  console.error(error);
  aux.valueLineItems = undefined;
  aux.valueCombinationMulticastItems = undefined;
  aux.valueStraightMulticastItems = undefined;
  aux.valueSmartLineItems = undefined;
  aux.modification = false;
  const newState = Object.assign({}, state, {
    ticket: auxState.ticket,
  });
  return newState;
}

function recoverTicket(state: ticketState, localStorageBetSlip: ticketState, ticketItemsAndAmount: any) {
  const auxState = JSON.parse(JSON.stringify(state));
  let auxStateStorage = JSON.parse(JSON.stringify(localStorageBetSlip));
  auxStateStorage = setFilters(auxStateStorage);
  auxStateStorage = setFilterSelected(auxStateStorage);
  auxStateStorage.ticket = TicketMethods.parse(localStorageBetSlip.ticket);
  const newState = Object.assign({}, state, {
    ticket: auxStateStorage.ticket,
    filters: auxStateStorage.filters,
    filterSelected: auxStateStorage.filterSelected,
  });
  return newState;
}

function saveBetSlip(state: ticketState, betSlip: ticketBetSlip) {
  const auxState = JSON.parse(JSON.stringify(state));
  const auxStateTicket = JSON.parse(JSON.stringify(state.ticket));
  auxState.betSlip = betSlip;

  const arrLines: LineItem[] = [];

  if (aux.modification) {
    if (aux.valueLineItems) {
      auxStateTicket.LineItems = [...aux.valueLineItems];
      auxStateTicket.LineItems.forEach((line) => {
        betSlip.items.singleResultItems.map((single) => {
          if (single.resultId === Number(line.ResultsNr)) {
            const nLine = Object.assign({}, line, { ItemId: single.itemId });
            arrLines.push(nLine);
          }
        });
      });
    }

    if (arrLines.length > 0) auxStateTicket.LineItems = arrLines;

    if (aux.valueSmartLineItems) {
      auxStateTicket.SmartLineItems = [...aux.valueSmartLineItems];
      if (betSlip.items.smartMarketItems.length > 0) {
        const itemIdFromBetslip = betSlip.items.smartMarketItems[betSlip.items.smartMarketItems.length - 1].itemId;
        auxStateTicket.SmartLineItems = auxStateTicket.SmartLineItems.map((item) =>
          !item.ItemId ? Object.assign({}, item, { ItemId: itemIdFromBetslip }) : item,
        );
      }
    }

    if (aux.valueStraightMulticastItems) {
      auxStateTicket.StraightMulticast = [...aux.valueStraightMulticastItems];
      if (betSlip.items.straightMulticastItems.length > 0) {
        const itemIdFromBetslip =
          betSlip.items.straightMulticastItems[betSlip.items.straightMulticastItems.length - 1].itemId;

        auxStateTicket.StraightMulticast = auxStateTicket.StraightMulticast.map((item) =>
          !item.ItemId ? Object.assign({}, item, { ItemId: itemIdFromBetslip }) : item,
        );
      }
    }

    if (aux.valueCombinationMulticastItems) {
      auxStateTicket.CombinationMulticast = [...aux.valueCombinationMulticastItems];
      if (betSlip.items.combinationMulticastItems.length > 0) {
        const itemIdFromBetslip =
          betSlip.items.combinationMulticastItems[betSlip.items.combinationMulticastItems.length - 1].itemId;
        auxStateTicket.CombinationMulticast = auxStateTicket.CombinationMulticast.map((item) =>
          !item.ItemId ? Object.assign({}, item, { ItemId: itemIdFromBetslip }) : item,
        );
      }
    }

    aux.valueCombinationMulticastItems = undefined;
    aux.valueStraightMulticastItems = undefined;
    aux.valueSmartLineItems = undefined;
    aux.valueLineItems = undefined;
    aux.modification = false;
  }

  auxStateTicket.StakeGroups.Accumulator.systemId = auxState.betSlip.possibleSystems.find(
    (system) => system.systemType === StakeGroup.ACCUMULATOR,
  )
    ? auxState.betSlip.possibleSystems.find((system) => system.systemType === StakeGroup.ACCUMULATOR).systemId
    : null;
  const possibleSystems: systemData[] = _setPossibleSystems(auxState.betSlip);
  auxStateTicket.StakeGroups.System.possibleSystems = possibleSystems.length > 0 ? possibleSystems : [];

  auxStateTicket.SingleStakeLimits = StoreConstants.SingleStakeLimits;
  auxStateTicket.SystemStakeLimits = StoreConstants.SystemStakeLimits;

  // FreeBets
  if (betSlip.bonus.freeBetVouchers.length > 0) {
    if (auxStateTicket.numberOfSelections < 1) {
      auxStateTicket.FreeBetVoucherId = "";
      auxStateTicket.FreebetInstance = FreebetInstanceMethods.newFreebetInstance();
    } else {
      const FBVoucher = auxState.freeBets.find(
        (i) => i.VoucherCode === betSlip.bonus.freeBetVouchers[0].voucherCode,
      );
      auxStateTicket.FreeBetVoucherId = FBVoucher.VoucherCode;
      auxStateTicket.FreebetInstance = FreebetInstanceMethods.mapFreebetInstance(FBVoucher, betSlip.bonus);
    }
  }
  const newAuxState: ticketState = Object.assign({}, state, {
    betSlip: auxState.betSlip,
    ticket: auxStateTicket as C_TicketApuestasNew,
  });

  const newAuxStateTemp = setLineItemUnselected(newAuxState);

  const newState: ticketState = Object.assign({}, state, {
    betSlip: newAuxStateTemp.betSlip,
    ticket: newAuxStateTemp.ticket as C_TicketApuestasNew,
  });
  return newState;
}

function saveBetSlipAndEvaluateFlow(state: ticketState, betSlip: ticketBetSlip, checkFilterChange: boolean) {
  const auxState = JSON.parse(JSON.stringify(state));
  const newState = saveBetSlip(auxState, betSlip);

  auxState.ticket.processing = true;
  const returnState: ticketState = Object.assign({}, state, {
    processingFrom: "",
    betSlip: newState.betSlip,
    ticket: newState.ticket as C_TicketApuestasNew,
  });

  return returnState;
}

function addSingleResultById(state: ticketState, betId: LineItem) {
  const auxStateTicket = JSON.parse(JSON.stringify(state)).ticket as C_TicketApuestasNew;

  auxStateTicket.processing = true;
  const newState = Object.assign({}, state, {
    processingFrom: "addSingleResultById",
    ticket: auxStateTicket,
  });
  return newState;
}

function addSingleResult(state: ticketState, selection: LineItem) {
  aux.modification = true;
  aux.valueLineItems = [...state.ticket.LineItems, LineItem.mapItem(selection)];
  return {
    ...state,
    processingFrom: "addSingleResult",
    ticket: {
      ...state.ticket,
      LineItems: [...state.ticket.LineItems, LineItem.mapItem(selection)],
    },
  };
}

function setStake(state: ticketState, stake: StakeModel) {
  const auxStateTicket = JSON.parse(JSON.stringify(state)).ticket as C_TicketApuestasNew;

  auxStateTicket.processing = true;

  if (stake.ItemId) {
    auxStateTicket.LineItems.map((lineItem, index) => {
      if (lineItem.ItemId === stake.ItemId) {
        lineItem.Amount = stake.OverallStake / 100;
        lineItem.AmountLocal = stake.OverallStake / 100;
      }
    });
    auxStateTicket.SmartLineItems.map((smartLineItem, index) => {
      if (smartLineItem.ItemId === stake.ItemId) {
        smartLineItem.Amount = stake.OverallStake / 100;
        smartLineItem.AmountLocal = stake.OverallStake / 100;
      }
    });
    auxStateTicket.StraightMulticast.map((racesLineItem, index) => {
      if (racesLineItem.ItemId === stake.ItemId) {
        racesLineItem.Amount = stake.OverallStake / 100;
        racesLineItem.AmountLocal = stake.OverallStake / 100;
      }
    });
    auxStateTicket.CombinationMulticast.map((racesLineItem, index) => {
      if (racesLineItem.ItemId === stake.ItemId) {
        racesLineItem.Amount = stake.OverallStake / 100;
        racesLineItem.AmountLocal = stake.OverallStake / 100;
      }
    });
  } else if (stake.SystemId) {
    auxStateTicket.Total = stake.OverallStake ? stake.OverallStake / 100 : stake.OverallStake / 100;
  }

  auxStateTicket.processing = true;
  const newState: ticketState = Object.assign({}, state, {
    processingFrom: "setStake",
    ticket: auxStateTicket as C_TicketApuestasNew,
  });
  return newState;
}

function addSmartMarketSelection(state: ticketState, smartMarketSelection: LineItem) {
  const auxState = JSON.parse(JSON.stringify(state));
  const auxStateTicket = JSON.parse(JSON.stringify(state.ticket));
  auxState.betbuilderSelection = [...auxState.betbuilderSelection, smartMarketSelection];
  const newState = Object.assign({}, state, {
    betbuilderSelection: auxState.betbuilderSelection,
    ticket: auxStateTicket,
  });
  return newState;
}

function addSmartMarketBet(state: ticketState, smartMarketRequest: SmartLineItem, smartMarketBet) {
  const auxState = JSON.parse(JSON.stringify(state));
  const auxStateTicket = JSON.parse(JSON.stringify(state.ticket));
  auxState.ticket.processing = true;
  const smartLineItems: SmartLineItem[] = [...auxStateTicket.SmartLineItems];

  smartLineItems.push(smartMarketBet);
  aux.modification = true;
  aux.valueSmartLineItems = smartLineItems;
  auxState.betbuilderSelection = [];
  const newState = Object.assign({}, state, {
    betbuilderSelection: auxState.betbuilderSelection,
    processingFrom: "addSmartMarketBet",
    ticket: auxStateTicket,
  });
  return newState;
}

function addStraightMulticastBet(state: ticketState, straightMulticastInput: any, straightMulticastItem) {
  const auxState = JSON.parse(JSON.stringify(state));
  const auxStateTicket = JSON.parse(JSON.stringify(state)).ticket as C_TicketApuestasNew;
  auxStateTicket.processing = true;
  const newState = Object.assign({}, state, {
    processingFrom: "addStraightMulticastBet",
    ticket: auxStateTicket,
  });

  const straightMulticastItems = [...auxState.ticket.StraightMulticast];
  straightMulticastItems.push(straightMulticastItem);

  aux.modification = true;
  aux.valueStraightMulticastItems = straightMulticastItems;

  return newState;
}

function addCombinationMulticastBet(
  state: ticketState,
  combinationMulticastInput: CombinationMulticastInput,
  combinationMulticastItem: LineItem,
) {
  const auxStateTicket = JSON.parse(JSON.stringify(state)).ticket as C_TicketApuestasNew;
  const combinationMulticastItems: GreyhoundHorseBet[] = [...auxStateTicket.CombinationMulticast];

  auxStateTicket.processing = true;

  combinationMulticastItems.push(combinationMulticastItem);
  aux.modification = true;
  aux.valueCombinationMulticastItems = combinationMulticastItems;
  const newState = Object.assign({}, state, {
    processingFrom: "addCombinationMulticastBet",
    ticket: auxStateTicket,
  });
  return newState;
}

function keepSelection(state: ticketState, globalsData: { cuotaAccept: string; userLogged: boolean }) {
  const auxStateTicket = JSON.parse(JSON.stringify(state)).ticket as C_TicketApuestasNew;
  auxStateTicket.TicketNumber = null;
  auxStateTicket.OveraskInfo = OveraskDataMethods.newOveraskData();
  auxStateTicket.keepSelection = true;
  const newState = Object.assign({}, state, {
    ticket: auxStateTicket,
  });
  return newState;
}

function removeItems(state: ticketState, ids: any) {
  const auxStateTicket = JSON.parse(JSON.stringify(state)).ticket as C_TicketApuestasNew;
  auxStateTicket.processing = true;

  const lineItems = [...auxStateTicket.LineItems];
  const straightMulticast = [...auxStateTicket.StraightMulticast];
  const combinationMulticast = [...auxStateTicket.CombinationMulticast];
  const smartLineItems = [...auxStateTicket.SmartLineItems];

  aux.modification = true;
  aux.valueCombinationMulticastItems = combinationMulticast.filter((i) => !ids.includes(i.ItemId));
  aux.valueStraightMulticastItems = straightMulticast.filter((i) => !ids.includes(i.ItemId));
  aux.valueSmartLineItems = smartLineItems.filter((i) => !ids.includes(i.ItemId));
  aux.valueLineItems = lineItems.filter((i) => !ids.includes(i.ItemId));
  let newState = Object.assign({}, state, {
    processingFrom: "removeItems",
    ticket: auxStateTicket,
  });
  newState = setOddErrors(newState);

  return newState;
}

function removeItem(state: ticketState, id: number) {
  const auxStateTicket = JSON.parse(JSON.stringify(state)).ticket as C_TicketApuestasNew;
  const auxState = JSON.parse(JSON.stringify(state));
  auxStateTicket.processing = true;
  const lineItems = [...auxStateTicket.LineItems];
  const item = auxState.betSlip.items.singleResultItems.find((e) => e.itemId === id);

  aux.modification = true;
  aux.valueLineItems = lineItems.filter((i) => parseInt(i.ResultsNr) !== item.resultId);
  const newState = Object.assign({}, state, {
    processingFrom: "removeItem",
    ticket: auxStateTicket,
  });
  return newState;
}

function removeMulticastItem(state: ticketState, id: number) {
  const auxStateTicket = JSON.parse(JSON.stringify(state)).ticket as C_TicketApuestasNew;
  const auxState = JSON.parse(JSON.stringify(state));
  auxStateTicket.processing = true;
  const straightMulticast = [...auxStateTicket.StraightMulticast];
  const item = auxState.betSlip.items.straightMulticastItems.find((e) => e.itemId === id);

  aux.modification = true;
  aux.valueStraightMulticastItems = straightMulticast.filter((i) => i.ItemId !== item.itemId);
  const newState = Object.assign({}, state, {
    processingFrom: "removeMulticastItem",
    ticket: auxStateTicket,
  });
  return newState;
}

function removeCombination(state: ticketState, id: number) {
  const auxStateTicket = JSON.parse(JSON.stringify(state)).ticket as C_TicketApuestasNew;
  const auxState = JSON.parse(JSON.stringify(state));
  auxStateTicket.processing = true;
  const combinationMulticast = [...auxStateTicket.CombinationMulticast];
  const item = auxState.betSlip.items.combinationMulticastItems.find((e) => e.itemId === id);

  aux.modification = true;
  aux.valueCombinationMulticastItems = combinationMulticast.filter((i) => i.ItemId !== item.itemId);
  const newState = Object.assign({}, state, {
    processingFrom: "removeCombination",
    ticket: auxStateTicket,
  });
  return newState;
}

function removeSmartMarketSelection(state: ticketState, id: any) {
  const auxState = JSON.parse(JSON.stringify(state));
  auxState.betbuilderSelection = auxState.betbuilderSelection.filter((i) => i.NodeId !== id);
  const newState = Object.assign({}, state, {
    betbuilderSelection: auxState.betbuilderSelection,
  });
  return newState;
}

function removeSmartMarketAllSelections(state: ticketState) {
  const auxState = JSON.parse(JSON.stringify(state));
  auxState.betbuilderSelection = [];
  const newState = Object.assign({}, state, {
    betbuilderSelection: auxState.betbuilderSelection,
  });
  return newState;
}

function removeSmartMarketItem(state: ticketState, id: number) {
  const auxState = JSON.parse(JSON.stringify(state));
  const auxStateTicket = JSON.parse(JSON.stringify(state)).ticket as C_TicketApuestasNew;
  auxStateTicket.processing = true;
  const smartLineItems = [...auxStateTicket.SmartLineItems];
  const item = auxState.betSlip.items.smartMarketItems.find((e) => e.itemId === id);

  aux.modification = true;
  aux.valueSmartLineItems = smartLineItems.filter((i) => i.ItemId !== item.itemId);
  const newState = Object.assign({}, state, {
    processingFrom: "removeSmartMarketItem",
    ticket: auxStateTicket,
  });
  return newState;
}

function setSystemBetType(state: ticketState, newTicketState: ticketState, systemStake: StakeModel) {
  const auxState = JSON.parse(JSON.stringify(state));
  const auxStateTicket = JSON.parse(JSON.stringify(state)).ticket as C_TicketApuestasNew;
  auxState.betSlip = newTicketState.betSlip;

  if (systemStake.Group !== StakeGroup.ACCUMULATOR && state.betSlip.possibleSystems.length > 2) {
    const possibleSystems: systemData[] = _setPossibleSystems(auxState.betSlip);
    auxStateTicket.StakeGroups.System.possibleSystems = possibleSystems ? possibleSystems : [];

    let SystemSelected = auxStateTicket.StakeGroups.System.possibleSystems.find(
      (system) => system.systemId === systemStake.SystemId,
    );
    auxStateTicket.SystemSelected = auxStateTicket.StakeGroups.System.possibleSystems.find(
      (system) => system.systemId === systemStake.SystemId,
    );
    if (!SystemSelected) {
      SystemSelected = auxStateTicket.StakeGroups.System.possibleSystems.find(
        (system) => system.systemType === StakeGroup.SYSTEM || system.systemType === StakeGroup.MULTI_WAY_SYSTEM,
      );
      auxStateTicket.SystemSelected = SystemSelected;
      auxStateTicket.StakeGroups.System.systemId = SystemSelected.systemId;
      auxStateTicket.StakeGroups.System.selectedGroup = SystemSelected.groupName.toLocaleLowerCase();
      auxStateTicket.StakeGroups.System.totalAmount = systemStake.OverallStake / 100;
    } else {
      auxStateTicket.StakeGroups.System.systemId = SystemSelected.systemId;
      auxStateTicket.StakeGroups.System.selectedGroup = SystemSelected.groupName.toLocaleLowerCase();
      auxStateTicket.StakeGroups.System.totalAmount = systemStake.OverallStake / 100;
    }
  } else if (state.betSlip.possibleSystems.length < 3) {
    auxStateTicket.SystemSelected = null;
    auxStateTicket.StakeGroups.System.systemId = null;
    auxStateTicket.StakeGroups.System.selectedGroup = null;
    auxStateTicket.StakeGroups.System.totalAmount = null;
  }
  const newState = Object.assign({}, state, {
    betSlip: auxState.betSlip,
    ticket: auxStateTicket,
  });
  return newState;
}

function setMultiStake(state: ticketState, multiStake: StakeModel) {
  const auxStateTicket = JSON.parse(JSON.stringify(state)).ticket as C_TicketApuestasNew;
  auxStateTicket.processing = true;
  multiStake.ItemIds.forEach((id) => {
    auxStateTicket.LineItems.map((lineItem, index) => {
      if (lineItem?.ItemId === id) {
        lineItem.Amount = multiStake.OverallStake / 100;
        lineItem.AmountLocal = multiStake.OverallStake / 100;
      }
    });
    auxStateTicket.SmartLineItems.map((smartLineItem, index) => {
      if (smartLineItem.ItemId === id) {
        smartLineItem.Amount = multiStake.OverallStake / 100;
        smartLineItem.AmountLocal = multiStake.OverallStake / 100;
      }
    });
    auxStateTicket.StraightMulticast.map((racesLineItem, index) => {
      if (racesLineItem.ItemId === id) {
        racesLineItem.Amount = multiStake.OverallStake / 100;
        racesLineItem.AmountLocal = multiStake.OverallStake / 100;
      }
    });
    auxStateTicket.CombinationMulticast.map((racesLineItem, index) => {
      if (racesLineItem.ItemId === id) {
        racesLineItem.Amount = multiStake.OverallStake / 100;
        racesLineItem.AmountLocal = multiStake.OverallStake / 100;
      }
    });
  });

  auxStateTicket.processing = true;
  const newState = Object.assign({}, state, {
    processingFrom: "setMultiStake",
    ticket: auxStateTicket,
  });
  return newState;
}

function removeStake(state: ticketState, stakeGroup: string) {
  const auxStateTicket = JSON.parse(JSON.stringify(state)).ticket as C_TicketApuestasNew;
  auxStateTicket.processing = true;
  auxStateTicket.StakeGroups.System.systemId = null;
  auxStateTicket.StakeGroups.System.totalPotentialWin = null;
  const newState = Object.assign({}, state, {
    processingFrom: "removeStake",
    ticket: auxStateTicket,
  });
  return newState;
}

function placeBet(state: ticketState, placeB) {
  const auxStateTicket = JSON.parse(JSON.stringify(state)).ticket as C_TicketApuestasNew;
  auxStateTicket.processing = true;
  const newState = Object.assign({}, state, {
    processingFrom: "placeBet",
    ticket: auxStateTicket,
  });
  return newState;
}

function closeBet(state: ticketState, groupKey) {
  const auxStateTicket = JSON.parse(JSON.stringify(state)).ticket as C_TicketApuestasNew;
  auxStateTicket.processing = true;
  auxStateTicket.OveraskInfo = OveraskDataMethods.newOveraskData();
  const newState = Object.assign({}, state, {
    processingFrom: "closeBet",
    ticket: auxStateTicket,
  });
  return newState;
}

function overaskStart(state: ticketState, singleTicketItem, closeBetStake) {
  const auxStateTicket = JSON.parse(JSON.stringify(state)).ticket as C_TicketApuestasNew;

  auxStateTicket.processing = true;

  auxStateTicket.OveraskInfo = {
    ExpiryDate: "",
    MaxStake: singleTicketItem.PotentialWin,
    RejectId: singleTicketItem.OveraskRejectId,
    Status: 0,
    StatusString: "Pending",
  };
  auxStateTicket.OveraskCloseBet = closeBetStake;
  auxStateTicket.processing = true;

  const newState = Object.assign({}, state, {
    processingFrom: "addSingleResultById",
    ticket: auxStateTicket,
  });
  return newState;
}

function overaskStartPolling(state: ticketState, overaskInfo, status) {
  const auxStateTicket = JSON.parse(JSON.stringify(state)).ticket as C_TicketApuestasNew;

  auxStateTicket.OveraskInfo = {
    ExpiryDate: "",
    MaxStake: overaskInfo.MaxStake / 100,
    RejectId: overaskInfo.RejectId,
    Status: status,
    StatusString: "Pending",
  };

  auxStateTicket.processing = true;
  const newState = Object.assign({}, state, {
    processingFrom: "overaskStartPolling",
    ticket: auxStateTicket,
  });
  return newState;
}

function overaskMaxStake(state: ticketState, overaskData, status = 1, stake) {
  const auxStateTicket = JSON.parse(JSON.stringify(state)).ticket as C_TicketApuestasNew;
  auxStateTicket.OveraskInfo = {
    ExpiryDate: "",
    MaxStake: overaskData.MaxStake / 100,
    RejectId: overaskData.OveraskRejectId,
    Status: status,
    StatusString: "Max Stake",
  };

  auxStateTicket.processing = true;
  const newState = Object.assign({}, state, {
    processingFrom: "overaskMaxStake",
    ticket: auxStateTicket,
  });
  return newState;
}

function overaskTimeOut(state: ticketState, overaskData, status = 2) {
  const auxStateTicket = JSON.parse(JSON.stringify(state)).ticket as C_TicketApuestasNew;

  auxStateTicket.OveraskInfo = {
    ExpiryDate: "",
    MaxStake: 0,
    RejectId: overaskData.OveraskRejectId,
    Status: 2,
    StatusString: "TimeOut",
  };
  auxStateTicket.processing = false;
  const newState = Object.assign({}, state, {
    processingFrom: "",
    ticket: auxStateTicket,
  });
  return newState;
}

function overaskRejected(state: ticketState, overaskData, status = 3) {
  const auxStateTicket = JSON.parse(JSON.stringify(state)).ticket as C_TicketApuestasNew;
  auxStateTicket.OveraskInfo = {
    ExpiryDate: "",
    MaxStake: 0,
    RejectId: overaskData.OveraskRejectId,
    Status: 3,
    StatusString: "Rejected",
  };
  auxStateTicket.processing = false;
  const newState = Object.assign({}, state, {
    processingFrom: "",
    ticket: auxStateTicket,
  });
  return newState;
}

function setLineItemSelected(state: ticketState, lineItemSelection: any) {
  const auxStateTicket = JSON.parse(JSON.stringify(state)).ticket as C_TicketApuestasNew;
  const selected = (line) => ({
    ...line,
    Selected: line.ItemId === lineItemSelection.ItemId && line.ResultsNr === lineItemSelection.ResultsNr,
  });
  const smartSelected = (line) => ({
    ...line,
    Selected: line.ItemId === lineItemSelection.ItemId,
  });

  auxStateTicket.LineItems = [...auxStateTicket.LineItems.map(selected)];
  auxStateTicket.StraightMulticast = [...auxStateTicket.StraightMulticast.map(selected)];
  auxStateTicket.CombinationMulticast = [...auxStateTicket.CombinationMulticast.map(selected)];

  auxStateTicket.SmartLineItems = [...auxStateTicket.SmartLineItems.map(smartSelected)];
  const newState = Object.assign({}, state, {
    ticket: auxStateTicket,
  });
  return newState;
}

function setLineItemUnselected(state: ticketState) {
  const auxStateTicket = JSON.parse(JSON.stringify(state)).ticket as C_TicketApuestasNew;
  const unselected = (line) => ({ ...line, Selected: false });

  auxStateTicket.LineItems = [...auxStateTicket.LineItems.map(unselected)];
  auxStateTicket.SmartLineItems = [...auxStateTicket.SmartLineItems.map(unselected)];
  auxStateTicket.StraightMulticast = [...auxStateTicket.StraightMulticast.map(unselected)];
  auxStateTicket.CombinationMulticast = [...auxStateTicket.CombinationMulticast.map(unselected)];

  const newState: ticketState = Object.assign({}, state, {
    ticket: auxStateTicket as C_TicketApuestasNew,
  });
  return newState;
}

function freeBetsAdd(state: ticketState, voucher, personalized, filterSelected) {
  const auxStateTicket = JSON.parse(JSON.stringify(state)).ticket as C_TicketApuestasNew;
  const newState = Object.assign({}, state, {
    ticket: auxStateTicket,
  });
  return newState;
}

function freeBetsCancel(state: ticketState, voucherCode: string) {
  const auxStateTicket = JSON.parse(JSON.stringify(state)).ticket as C_TicketApuestasNew;
  auxStateTicket.FreeBetVoucherId = null;
  auxStateTicket.FreebetInstance = null;

  const newState = Object.assign({}, state, {
    ticket: auxStateTicket,
  });
  return newState;
}

/**
 * Sets FreeBet Vouchers to TicketState - freeBets
 * @param state
 * @param vouchers
 * @returns New ticketState
 */
function setFreeBetsVouchers(state: ticketState, vouchers: C_FreeBetTicket[]): ticketState {
  let auxStateFreeBets = JSON.parse(JSON.stringify(state)).freeBets;

  auxStateFreeBets = vouchers;
  const newState = Object.assign({}, state, {
    freeBets: auxStateFreeBets,
  });
  return newState;
}
/**
 * Update FreeBet Vouchers Validation to TicketState - freeBets
 * @param state
 * @param freebetStake
 * @returns New ticketState
 */
function freeBetsUpdateValidations(
  state: ticketState,
  freebetStake: StakeModel,
  betSlip: ticketBetSlip,
): ticketState {
  const auxStateTicket = JSON.parse(JSON.stringify(state)).ticket as C_TicketApuestasNew;
  const auxStateFreeBets = JSON.parse(JSON.stringify(state)).freeBets;
  if (betSlip.bonus.freeBetVouchers.length > 0) {
    if (TicketMethods.getNumberOfSelections(auxStateTicket) < 1) {
      auxStateTicket.FreeBetVoucherId = "";
      auxStateTicket.FreebetInstance = FreebetInstanceMethods.newFreebetInstance();
    } else {
      const FBVoucher = auxStateFreeBets.find(
        (i) => i.VoucherCode === betSlip.bonus.freeBetVouchers[0].voucherCode,
      );
      auxStateTicket.FreeBetVoucherId = FBVoucher.VoucherCode;
      auxStateTicket.FreebetInstance = FreebetInstanceMethods.mapFreebetInstance(FBVoucher, betSlip.bonus);
    }
  }
  const newState = Object.assign({}, state, {
    ticket: auxStateTicket,
  });
  return newState;
}

/**
 * Updates FreeBet Vouchers to TicketState - freeBets
 * @param state
 * @param vouchers
 * @returns New ticketState
 */
function updateFreeBetsVouchers(state: ticketState): ticketState {
  const auxStateTicket = JSON.parse(JSON.stringify(state)).ticket;
  const newState = Object.assign({}, state, {
    ticket: auxStateTicket,
  });
  return newState;
}

function amountLocalLineItemUpdate(state: ticketState, payload: any) {
  const auxStateTicket = JSON.parse(JSON.stringify(state)).ticket as C_TicketApuestasNew;

  const update = (line) => ({
    ...line,
    AmountLocal:
      line.ItemId === payload.selectedLine.ItemId && line.ResultsNr === payload.selectedLine.ResultsNr
        ? payload.value
        : line.Amount,
  });

  auxStateTicket.LineItems = [...auxStateTicket.LineItems.map(update)];
  auxStateTicket.StraightMulticast = [...auxStateTicket.StraightMulticast.map(update)];
  auxStateTicket.CombinationMulticast = [...auxStateTicket.CombinationMulticast.map(update)];

  const smartUpdate = (line) => ({
    ...line,
    AmountLocal: line.ItemId === payload.selectedLine.ItemId ? payload.value : line.Amount,
  });
  auxStateTicket.SmartLineItems = [...auxStateTicket.SmartLineItems.map(smartUpdate)];

  let TotalLocalAux = 0;
  auxStateTicket.LineItems.map((i) => {
    TotalLocalAux += +i.AmountLocal;
  });

  auxStateTicket.SmartLineItems.map((i) => {
    TotalLocalAux += +i.AmountLocal;
  });

  auxStateTicket.TotalLocal = TotalLocalAux.toString();
  const newState = Object.assign({}, state, {
    processingFrom: "",
    ticket: auxStateTicket,
  });
  return newState;
}

function amountLocalTotalUpdate(state: ticketState, value: number) {
  const auxStateTicket = JSON.parse(JSON.stringify(state)).ticket as C_TicketApuestasNew;

  const update = (line) => ({
    ...line,
    AmountLocal: value,
  });

  auxStateTicket.LineItems = [...auxStateTicket.LineItems.map(update)];
  auxStateTicket.SmartLineItems = [...auxStateTicket.SmartLineItems.map(update)];
  auxStateTicket.StraightMulticast = [...auxStateTicket.StraightMulticast.map(update)];
  auxStateTicket.CombinationMulticast = [...auxStateTicket.CombinationMulticast.map(update)];

  auxStateTicket.TotalLocal = value.toString();
  const newState = Object.assign({}, state, {
    ticket: auxStateTicket,
  });
  return newState;
}

function closedTicketResult(state: ticketState, closeTicketR: closeTicketResult, newOdd: OddItem[]) {
  let auxStateTicket = JSON.parse(JSON.stringify(state)).ticket as C_TicketApuestasNew;

  if (closeTicketR.TicketItems) {
    auxStateTicket.LineItems.map((lineItem, index) => {
      const existItemId = closeTicketR.TicketItems.find((item) => item.ItemId === lineItem.ItemId);

      if (existItemId) {
        lineItem.TicketNumber = existItemId.TicketId;
        lineItem.LineErrors = existItemId.ErrorDescription;
        if (existItemId.ErrorCode) lineItem.LineErrorMessages.push(existItemId.ErrorDescription);
        lineItem.TotalPotentialWin = existItemId.PotentialWin ? existItemId.PotentialWin : 0;
        lineItem.Amount = existItemId.Stake / 100;
      }
      lineItem.upOdd = false;
      lineItem.downOdd = false;
    });

    auxStateTicket.SmartLineItems.map((smartLineItem, index) => {
      const existItemId = closeTicketR.TicketItems.find((item) => item.ItemId === smartLineItem.ItemId);

      if (existItemId) {
        smartLineItem.TicketNumber = existItemId.TicketId;
        if (existItemId.ErrorCode)
          StoreConstants.findErrorCode(existItemId)
            ? smartLineItem.LineErrorMessages.push(StoreConstants[existItemId.ErrorCode])
            : smartLineItem.LineErrorMessages.push(existItemId.ErrorDescription);
        smartLineItem.LineErrors = existItemId.ErrorDescription;
        smartLineItem.TotalPotentialWin = existItemId.PotentialWin ? existItemId.PotentialWin : 0;
        smartLineItem.Amount = existItemId.Stake / 100;
      }
      smartLineItem.upOdd = false;
      smartLineItem.downOdd = false;
    });

    auxStateTicket.StraightMulticast.map((straightMulticastItem, index) => {
      const existItemId = closeTicketR.TicketItems.find((item) => item.ItemId === straightMulticastItem.ItemId); // CORREGIR RESULT NR TODO

      if (existItemId) {
        straightMulticastItem.TicketNumber = existItemId.TicketId;
        straightMulticastItem.LineErrors = existItemId.ErrorDescription; // CORREGIR LINE ERRORS TODO
        if (existItemId.ErrorCode) straightMulticastItem.LineErrorMessages.push(existItemId.ErrorDescription);
        straightMulticastItem.TotalPotentialWin = existItemId.PotentialWin ? existItemId.PotentialWin : 0;
        straightMulticastItem.Amount = existItemId.Stake / 100;
      }
      straightMulticastItem.upOdd = false;
      straightMulticastItem.downOdd = false;
    });

    auxStateTicket.CombinationMulticast.map((combinationMulticastItem, index) => {
      const existItemId = closeTicketR.TicketItems.find((item) => item.ItemId === combinationMulticastItem.ItemId); // CORREGIR RESULT NR TODO

      if (existItemId) {
        combinationMulticastItem.TicketNumber = existItemId.TicketId;
        combinationMulticastItem.LineErrors = existItemId.ErrorDescription; // CORREGIR LINE ERRORS TODO
        if (existItemId.ErrorCode) combinationMulticastItem.LineErrorMessages.push(existItemId.ErrorDescription);
        combinationMulticastItem.TotalPotentialWin = existItemId.PotentialWin ? existItemId.PotentialWin : 0;
        combinationMulticastItem.Amount = existItemId.Stake / 100;
      }
      combinationMulticastItem.upOdd = false;
      combinationMulticastItem.downOdd = false;
    });
  } else if (closeTicketR.SingleTicketItem) {
    auxStateTicket.TicketNumber = closeTicketR.SingleTicketItem.TicketId;
    if (closeTicketR.SingleTicketItem.ErrorCode) {
      auxStateTicket.OrderErrors[closeTicketR.SingleTicketItem.ErrorCode] =
        closeTicketR.SingleTicketItem.ErrorDescription;
      auxStateTicket.OrderErrorMessages.push(closeTicketR.SingleTicketItem.ErrorDescription);
      auxStateTicket = _checkOddsChangeErrors(auxStateTicket, closeTicketR.SingleTicketItem.ErrorCode);
      auxStateTicket = _checkSmartLineItemMarketErrors(auxStateTicket, closeTicketR.SingleTicketItem);
    }
    if (closeTicketR.SingleTicketItem.NewOdds) {
      auxStateTicket.LineItems = closeBetNewOds(auxStateTicket.LineItems, closeTicketR.SingleTicketItem.NewOdds);
      auxStateTicket.SmartLineItems = closeBetNewOds(
        auxStateTicket.SmartLineItems,
        closeTicketR.SingleTicketItem.NewOdds,
      );
      auxStateTicket.StraightMulticast = closeBetNewOds(
        auxStateTicket.StraightMulticast,
        closeTicketR.SingleTicketItem.NewOdds,
      );
      auxStateTicket.CombinationMulticast = closeBetNewOds(
        auxStateTicket.CombinationMulticast,
        closeTicketR.SingleTicketItem.NewOdds,
      );
    }
  } else if (closeTicketR.PlayerError) {
    auxStateTicket.OrderErrors["9999999"] = closeTicketR.PlayerErrorMessage;
    if (closeTicketR.PlayerErrorMessage) auxStateTicket.OrderErrorMessages.push(closeTicketR.PlayerErrorMessage);
  }

  auxStateTicket.TotalLocal = (closeTicketR.TotalStake / 100).toString();
  auxStateTicket.TotalPotentialWin = closeTicketR.TotalPotentialWin / 100;
  auxStateTicket.processing = false;
  auxStateTicket.keepSelection = false;

  const newState = Object.assign({}, state, {
    processingFrom: "",
    ticket: auxStateTicket,
  });
  return newState;
}

function initFilters(state: ticketState, filters: FilterItem[]) {
  // Process filters
  const licenseType: number = Number.parseInt(sessionStorage.getItem("licenseType"));
  sessionStorage.removeItem("licenseType");
  let auxFilters = filters;
  let auxFilterSelected = filters.find((i) => i.selected) || filters[0];
  if (
    state.filters !== undefined &&
    state.filters !== null &&
    state.filterSelected !== undefined &&
    state.filterSelected !== null
  ) {
    auxFilterSelected = state.filters.find((i) => i.selected) || filters[0];
    auxFilters = JSON.parse(JSON.stringify(state.filters));
  }

  const auxStateTicket = JSON.parse(JSON.stringify(state)).ticket as C_TicketApuestasNew;
  auxStateTicket.BetType = state.filterSelected ? state.filterSelected.value : 0;
  auxStateTicket.processing = false;

  const newState: ticketState = Object.assign({}, state, {
    filters: auxFilters,
    filterSelected: auxFilterSelected,
    ticket: auxStateTicket as C_TicketApuestasNew,
  });
  return newState;
}

function setFilters(state: ticketState) {
  const auxState = JSON.parse(JSON.stringify(state));

  const auxStateForFilters = _setFilterStatus(auxState);
  const auxNewState = checkForErrors(auxStateForFilters);
  const newState: ticketState = Object.assign({}, state, {
    filters: auxNewState.filters,
    filterSelected: auxNewState.filterSelected,
    ticket: auxNewState.ticket as C_TicketApuestasNew,
  });
  return newState;
}

function setFilterSelected(state: ticketState) {
  const auxStateTicket = JSON.parse(JSON.stringify(state)).ticket as C_TicketApuestasNew;
  const auxStateFilterSelected = JSON.parse(JSON.stringify(state)).filterSelected;
  if (auxStateFilterSelected !== undefined && auxStateFilterSelected !== null) {
    if (auxStateTicket.StakeGroups[auxStateFilterSelected.betType].totalPotentialWin)
      auxStateTicket.TotalPotentialWin =
        auxStateTicket.StakeGroups[auxStateFilterSelected.betType].totalPotentialWin;
    if (auxStateTicket.StakeGroups[auxStateFilterSelected.betType].totalAmount)
      auxStateTicket.Total = auxStateTicket.StakeGroups[auxStateFilterSelected.betType].totalAmount;
  }

  auxStateTicket.TotalLocal = auxStateTicket.Total.toString();
  auxStateTicket.processing = false;
  const newState: ticketState = Object.assign({}, state, {
    filterSelected: auxStateFilterSelected,
    processingFrom: "",
    ticket: auxStateTicket as C_TicketApuestasNew,
  });
  return newState;
}

function changeFilter(state: ticketState, value: number) {
  let auxStateTicket = JSON.parse(JSON.stringify(state)).ticket as C_TicketApuestasNew;
  let auxStateFilterSelected = JSON.parse(JSON.stringify(state)).filterSelected;
  const auxStateFilters = JSON.parse(JSON.stringify(state)).filters;
  const auxState = JSON.parse(JSON.stringify(state));

  auxStateFilters.map((filter, index) => (value === index ? (filter.selected = true) : (filter.selected = false)));

  auxStateFilterSelected = auxStateFilters[value];
  auxStateTicket.BetType = value;
  if (auxStateTicket.StakeGroups[auxStateFilterSelected.betType].totalPotentialWin)
    auxStateTicket.TotalPotentialWin =
      auxStateTicket.StakeGroups[auxStateFilterSelected.betType].totalPotentialWin;
  if (auxStateTicket.StakeGroups[auxStateFilterSelected.betType].totalAmount)
    auxStateTicket.Total = auxStateTicket.StakeGroups[auxStateFilterSelected.betType].totalAmount;

  // UPDATE TOTAL AMOUNT to show in mobile TotalAmount Input
  auxStateTicket.TotalLocal = auxStateTicket.Total.toString();

  // Check For Errors On Selected Filter
  if (aux.setToAccumulator) {
    auxStateTicket = _ticketCheckErrors(auxState);
    aux.setToAccumulator = false;
  }

  const newState = Object.assign({}, state, {
    filterSelected: auxStateFilterSelected,
    filters: auxStateFilters,
    ticket: auxStateTicket,
  });
  return newState;
}

function changeFiltersAndSetOddErrors(state: ticketState, value: number) {
  const auxStateTicket = JSON.parse(JSON.stringify(state)).ticket as C_TicketApuestasNew;
  let auxStateFilterSelected = JSON.parse(JSON.stringify(state)).filterSelected;
  const auxStateFilters = JSON.parse(JSON.stringify(state)).filters;
  const auxState = JSON.parse(JSON.stringify(state));

  auxStateFilters.map((filter, index) => (value === index ? (filter.selected = true) : (filter.selected = false)));

  auxStateFilterSelected = auxStateFilters[value];
  auxStateTicket.BetType = value;
  if (auxStateTicket.StakeGroups[auxStateFilterSelected.betType].totalPotentialWin)
    auxStateTicket.TotalPotentialWin =
      auxStateTicket.StakeGroups[auxStateFilterSelected.betType].totalPotentialWin;
  if (auxStateTicket.StakeGroups[auxStateFilterSelected.betType].totalAmount)
    auxStateTicket.Total = auxStateTicket.StakeGroups[auxStateFilterSelected.betType].totalAmount;

  // UPDATE TOTAL AMOUNT to show in mobile TotalAmount Input
  auxStateTicket.TotalLocal = auxStateTicket.Total.toString();

  auxState.filters = auxStateFilters;
  auxState.filterSelected = auxStateFilterSelected;
  auxState.ticket = auxStateTicket;

  const newState = setOddErrors(auxState);

  return newState;
}

function marketLocked(state: ticketState, odd: oddsUpdateItem[]) {
  const auxStateTicket = JSON.parse(JSON.stringify(state)).ticket as C_TicketApuestasNew;
  const auxState = JSON.parse(JSON.stringify(state));

  auxStateTicket.LineItems.map((lineItem, index) => {
    const existItemId = odd.find((item) => item.NodeId === lineItem.ResultsNr);
    if (existItemId) {
      auxStateTicket.LineItems[index].GameLocked = existItemId.Locked;
      auxStateTicket.LineItems[index].NotAvailable = existItemId.NotAvailable;
    }
    if (existItemId && !existItemId.Locked) {
      auxStateTicket.LineItems[index].upOdd = lineItem.Odd < existItemId.Odd;
      auxStateTicket.LineItems[index].downOdd = lineItem.Odd > existItemId.Odd;
    } else {
      auxStateTicket.LineItems[index].upOdd = false;
      auxStateTicket.LineItems[index].downOdd = false;
    }
  });

  auxStateTicket.SmartLineItems.map((lineItem, index) => {
    const existItemId = odd.find((item) => item.NodeId === lineItem.ResultsNr); // SMARTITEMS Don't have ResultsNr
    if (existItemId) {
      auxStateTicket.SmartLineItems[index].GameLocked = existItemId.Locked;
      auxStateTicket.SmartLineItems[index].NotAvailable = existItemId.NotAvailable;
    }
    if (existItemId && !existItemId.Locked) {
      auxStateTicket.SmartLineItems[index].upOdd = lineItem.Odd < existItemId.Odd;
      auxStateTicket.SmartLineItems[index].downOdd = lineItem.Odd > existItemId.Odd;
    } else {
      auxStateTicket.SmartLineItems[index].upOdd = false;
      auxStateTicket.SmartLineItems[index].downOdd = false;
    }
  });

  auxStateTicket.StraightMulticast.map((lineItem, index) => {
    const existItemId = odd.find((item) => item.NodeId === lineItem.ResultsNr);
    if (existItemId) {
      auxStateTicket.StraightMulticast[index].GameLocked = existItemId.Locked;
      auxStateTicket.StraightMulticast[index].NotAvailable = existItemId.NotAvailable;
    }
    if (existItemId && !existItemId.Locked) {
      auxStateTicket.StraightMulticast[index].upOdd = lineItem.Odd < existItemId.Odd;
      auxStateTicket.StraightMulticast[index].downOdd = lineItem.Odd > existItemId.Odd;
    } else {
      auxStateTicket.StraightMulticast[index].upOdd = false;
      auxStateTicket.StraightMulticast[index].downOdd = false;
    }
  });

  auxStateTicket.CombinationMulticast.map((lineItem, index) => {
    const existItemId = odd.find((item) => item.NodeId === lineItem.ResultsNr);
    if (existItemId) {
      auxStateTicket.CombinationMulticast[index].GameLocked = existItemId.Locked;
      auxStateTicket.CombinationMulticast[index].NotAvailable = existItemId.NotAvailable;
    }
    if (existItemId && !existItemId.Locked) {
      auxStateTicket.CombinationMulticast[index].upOdd = lineItem.Odd < existItemId.Odd;
      auxStateTicket.CombinationMulticast[index].downOdd = lineItem.Odd > existItemId.Odd;
    } else {
      auxStateTicket.CombinationMulticast[index].upOdd = false;
      auxStateTicket.CombinationMulticast[index].downOdd = false;
    }
  });
  auxState.ticket = auxStateTicket;

  const newAuxStateTicket = _ticketCheckErrors(auxState);

  const newState: ticketState = Object.assign({}, state, {
    ticket: newAuxStateTicket as C_TicketApuestasNew,
  });
  return newState;
}

function checkForErrors(state: ticketState) {
  const auxState = JSON.parse(JSON.stringify(state));
  let auxStateTicket = JSON.parse(JSON.stringify(state)).ticket as C_TicketApuestasNew;
  auxStateTicket = _ticketCheckErrors(auxState);
  const newState: ticketState = Object.assign({}, state, {
    ticket: auxStateTicket as C_TicketApuestasNew,
  });
  return newState;
}

function updateAndChangeToSingles(state: ticketState, globalsData: { cuotaAccept: string; userLogged: boolean }) {
  const auxNewState = updateTicket(state, globalsData);
  const auxNewStateFilters = _setFilterStatus(auxNewState);
  const newState: ticketState = Object.assign({}, state, {
    filters: auxNewStateFilters.filters,
    filterSelected: auxNewStateFilters.filterSelected,
    ticket: auxNewStateFilters.ticket as C_TicketApuestasNew,
  });
  return newState;
}

function updateAndCheckForErrors(state: ticketState, globalsData: { cuotaAccept: string; userLogged: boolean }) {
  const auxState = updateTicket(state, globalsData);
  const auxStateFilters = setFilters(auxState); // Set Filters ==>> Check For Errors
  const auxStateFilterSelected = setFilterSelected(auxStateFilters);
  const newState: ticketState = Object.assign({}, state, {
    filters: auxStateFilterSelected.filters,
    ticket: auxStateFilterSelected.ticket as C_TicketApuestasNew,
  });
  return newState;
}

function updateTicket(state: ticketState, globalsData: { cuotaAccept: string; userLogged: boolean }) {
  const auxState = JSON.parse(JSON.stringify(state));
  if (auxState.betSlip === undefined || !auxState.betSlip) return state;
  const betSlipSingles = { ...auxState.betSlip.stakeGroups.singles };
  const betSlipAccumulator = { ...auxState.betSlip.stakeGroups.accumulator };
  const betSlipGroups = { ...auxState.betSlip.stakeGroups };
  const keepSelect = auxState.ticket.keepSelection;

  //set LineItems amounts
  auxState.ticket.LineItems.map((lineItem, index) => {
    if (
      auxState.betSlip.items.singleResultItems.find(
        (singleResultItem) => singleResultItem.resultId.toString() === lineItem.ResultsNr,
      )
    ) {
      auxState.ticket.LineItems[index].Odd = auxState.betSlip.items.singleResultItems.find(
        (singleResultItem) => singleResultItem.resultId.toString() === lineItem.ResultsNr,
      ).odds;
    }

    const existItemId = betSlipSingles?.staking?.singles.find((item) => item.itemId === lineItem.ItemId);

    if (!existItemId) {
      auxState.ticket.LineItems[index].Amount = 0;
      auxState.ticket.LineItems[index].AmountLocal = 0;
      auxState.ticket.LineItems[index].TotalPotentialWin = 0;
      auxState.ticket.StakeGroups.Singles.totalAmount = undefined;
      auxState.ticket.StakeGroups.Accumulator.totalAmount = undefined;
      auxState.ticket.StakeGroups.System.totalAmount = undefined;
    } else {
      betSlipSingles?.staking?.singles.map((item) => {
        if (lineItem.ItemId === item.itemId) {
          auxState.ticket.LineItems[index].Amount = item.overallStake / 100;
          auxState.ticket.LineItems[index].AmountLocal = item.overallStake / 100;
        }
      });
      betSlipSingles.calculation.singles.map((item) => {
        if (lineItem.ItemId === item.itemId) {
          auxState.ticket.LineItems[index].TotalPotentialWin = item.maxReturn / 100;
        }
      });
    }

    if (keepSelect) auxState.ticket.LineItems[index].TicketNumber = "";
  });
  //set SmartLineItems Amounts
  auxState.ticket.SmartLineItems.map((smartLineItem, index) => {
    if (
      auxState.betSlip.items.smartMarketItems.find(
        (smartMarketItem) => smartMarketItem.itemId === smartLineItem.ItemId,
      )
    ) {
      auxState.ticket.SmartLineItems[index].Odd = auxState.betSlip.items.smartMarketItems.find(
        (smartMarketItem) => smartMarketItem.itemId === smartLineItem.ItemId,
      ).odds;
    }

    const existItemId = betSlipSingles?.staking?.singles.find((item) => item.itemId === smartLineItem.ItemId);

    if (!existItemId) {
      auxState.ticket.SmartLineItems[index].Amount = 0;
      auxState.ticket.SmartLineItems[index].AmountLocal = 0;
      auxState.ticket.SmartLineItems[index].TotalPotentialWin = 0;
      auxState.ticket.StakeGroups.Singles.totalAmount = undefined;
      auxState.ticket.StakeGroups.Accumulator.totalAmount = undefined;
      auxState.ticket.StakeGroups.System.totalAmount = undefined;
    } else {
      betSlipSingles?.staking?.singles.map((item) => {
        if (smartLineItem.ItemId === item.itemId) {
          auxState.ticket.SmartLineItems[index].Amount = item.overallStake / 100;
          auxState.ticket.SmartLineItems[index].AmountLocal = item.overallStake / 100;
        }
      });
      betSlipSingles.calculation.singles.map((item) => {
        if (smartLineItem.ItemId === item.itemId) {
          auxState.ticket.SmartLineItems[index].TotalPotentialWin = item.maxReturn / 100;
        }
      });
    }
    if (keepSelect) auxState.ticket.SmartLineItems[index].TicketNumber = "";
  });
  //set StraightMulticast Amounts
  auxState.ticket.StraightMulticast.map((straightMulticastBet, index) => {
    const existItemId = betSlipSingles?.staking?.singles.find(
      (item) => item.itemId === straightMulticastBet.ItemId,
    );

    if (!existItemId) {
      auxState.ticket.StraightMulticast[index].Amount = 0;
      auxState.ticket.StraightMulticast[index].AmountLocal = 0;
      auxState.ticket.StraightMulticast[index].TotalPotentialWin = 0;
      auxState.ticket.StakeGroups.Singles.totalAmount = undefined;
      auxState.ticket.StakeGroups.Accumulator.totalAmount = undefined;
      auxState.ticket.StakeGroups.System.totalAmount = undefined;
    } else {
      betSlipSingles?.staking?.singles.map((item) => {
        if (straightMulticastBet.ItemId === item.itemId) {
          auxState.ticket.StraightMulticast[index].Amount = item.overallStake / 100;
          auxState.ticket.StraightMulticast[index].AmountLocal = item.overallStake / 100;
        }
      });
      betSlipSingles.calculation.singles.map((item) => {
        if (straightMulticastBet.ItemId === item.itemId) {
          auxState.ticket.StraightMulticast[index].TotalPotentialWin = item.maxReturn / 100;
        }
      });
    }
    if (keepSelect) auxState.ticket.StraightMulticast[index].TicketNumber = "";
  });
  //set CombinationMulticast Amounts
  auxState.ticket.CombinationMulticast.map((combinationMulticastBet, index) => {
    const existItemId = betSlipSingles?.staking?.singles.find(
      (item) => item.itemId === combinationMulticastBet.ItemId,
    );

    if (!existItemId) {
      auxState.ticket.CombinationMulticast[index].Amount = 0;
      auxState.ticket.CombinationMulticast[index].AmountLocal = 0;
      auxState.ticket.CombinationMulticast[index].TotalPotentialWin = 0;
      auxState.ticket.StakeGroups.Singles.totalAmount = undefined;
      auxState.ticket.StakeGroups.Accumulator.totalAmount = undefined;
      auxState.ticket.StakeGroups.System.totalAmount = undefined;
    } else {
      betSlipSingles?.staking?.singles.map((item) => {
        if (combinationMulticastBet.ItemId === item.itemId) {
          auxState.ticket.CombinationMulticast[index].Amount = item.overallStake / 100;
          auxState.ticket.CombinationMulticast[index].AmountLocal = item.overallStake / 100;
        }
      });
      betSlipSingles.calculation.singles.map((item) => {
        if (combinationMulticastBet.ItemId === item.itemId) {
          auxState.ticket.CombinationMulticast[index].TotalPotentialWin = item.maxReturn / 100;
        }
      });
    }
    if (keepSelect) auxState.ticket.CombinationMulticast[index].TicketNumber = "";
  });

  auxState.ticket.TotalLocal = (betSlipSingles?.staking?.overallStake / 100).toString();

  //set StakeGroups and SystemSelected
  // Singles
  if (betSlipSingles.staking && betSlipSingles.calculation) {
    auxState.ticket.StakeGroups.Singles.totalAmount = betSlipSingles?.staking?.overallStake / 100;
    auxState.ticket.StakeGroups.Singles.totalPotentialWin =
      betSlipSingles.calculation.betslip.maxReturnNetIncludingBonus / 100;
  }

  // Accumulator
  if (betSlipAccumulator.staking && betSlipAccumulator.calculation) {
    if (betSlipAccumulator.staking.overallStake) {
      auxState.ticket.StakeGroups.Accumulator.totalAmount = betSlipAccumulator.staking.overallStake / 100;
    }
    if (betSlipAccumulator.calculation.betslip) {
      auxState.ticket.StakeGroups.Accumulator.totalPotentialWin =
        betSlipAccumulator.calculation.betslip.maxReturnNetIncludingBonus / 100;
    }
    if (
      betSlipAccumulator.calculation.systems.length > 0 &&
      betSlipAccumulator.calculation.systems[0].bonus.length > 0
    ) {
      auxState.ticket.ApplicableAccaBonus = AABonusMethods.mapBonus(
        betSlipAccumulator.calculation.systems[0].bonus[0],
      );
    } else {
      auxState.ticket.ApplicableAccaBonus = AABonusMethods.newApplicableAccaBonus();
    }
  }

  // System
  const filterSelected = auxState.filterSelected;
  const possibleSystems = auxState.betSlip.possibleSystems.filter(
    (i) => i.systemType !== StakeGroup.SINGLES && i.systemType !== StakeGroup.ACCUMULATOR,
  );
  if (
    betSlipGroups &&
    filterSelected !== undefined &&
    filterSelected !== null &&
    filterSelected.betType === StakeGroup.SYSTEM &&
    possibleSystems.length > 0
  ) {
    if (!auxState.ticket.SystemSelected)
      auxState.ticket.SystemSelected = auxState.ticket.StakeGroups.System.possibleSystems[0];
    const groupName = auxState.ticket.SystemSelected.groupName;
    switch (groupName) {
      case "Doubles":
        if (betSlipGroups.doubles) {
          if (betSlipGroups.doubles.staking && betSlipGroups.doubles.calculation) {
            if (betSlipGroups.doubles.staking.overallStake) {
              auxState.ticket.StakeGroups.System.totalAmount = betSlipGroups.doubles.staking.overallStake / 100;
            }
            if (betSlipGroups.doubles.calculation.betslip) {
              auxState.ticket.StakeGroups.System.totalPotentialWin =
                betSlipGroups.doubles.calculation.betslip.maxReturnNetIncludingBonus / 100;
            }
            auxState.ticket.StakeGroups.System.selectedGroup = "doubles";
            auxState.ticket.StakeGroups.System.systemId = 2;
          }
        }
        break;
      case "Triples":
        if (betSlipGroups.triples) {
          if (betSlipGroups.triples.staking && betSlipGroups.triples.calculation) {
            if (betSlipGroups.triples.staking.overallStake) {
              auxState.ticket.StakeGroups.System.totalAmount = betSlipGroups.triples.staking.overallStake / 100;
            }
            if (betSlipGroups.triples.calculation.betslip) {
              auxState.ticket.StakeGroups.System.totalPotentialWin =
                betSlipGroups.triples.calculation.betslip.maxReturnNetIncludingBonus / 100;
            }
            auxState.ticket.StakeGroups.System.selectedGroup = "triples";
            auxState.ticket.StakeGroups.System.systemId = 3;
          }
        }
        break;
      case "Cuadruples":
        if (betSlipGroups.cuadruples) {
          if (betSlipGroups.cuadruples.staking && betSlipGroups.cuadruples.calculation) {
            if (betSlipGroups.cuadruples.staking.overallStake) {
              auxState.ticket.StakeGroups.System.totalAmount = betSlipGroups.cuadruples.staking.overallStake / 100;
            }
            if (betSlipGroups.cuadruples.calculation.betslip) {
              auxState.ticket.StakeGroups.System.totalPotentialWin =
                betSlipGroups.cuadruples.calculation.betslip.maxReturnNetIncludingBonus / 100;
            }
            auxState.ticket.StakeGroups.System.selectedGroup = "cuadruples";
            auxState.ticket.StakeGroups.System.systemId = 4;
          }
        }
        break;
      default: {
        const systemX = `system${auxState.ticket.SystemSelected.systemId}`;
        if (betSlipGroups[systemX]) {
          if (betSlipGroups[systemX].staking && betSlipGroups[systemX].calculation) {
            if (betSlipGroups[systemX].staking.overallStake) {
              auxState.ticket.StakeGroups.System.totalAmount = betSlipGroups[systemX].staking.overallStake / 100;
            }
            if (betSlipGroups[systemX].calculation.betslip) {
              auxState.ticket.StakeGroups.System.totalPotentialWin =
                betSlipGroups[systemX].calculation.betslip.maxReturnNetIncludingBonus / 100;
            }
            auxState.ticket.StakeGroups.System.selectedGroup = systemX;
            auxState.ticket.StakeGroups.System.systemId = auxState.ticket.SystemSelected.systemId;
          }
        }
        break;
      }
    }
  }
  auxState.ticket.processing = false;
  const newStateTicket = _ticketHasOddsChanges(auxState.ticket, globalsData);
  const newState: ticketState = Object.assign({}, state, {
    ticket: newStateTicket as C_TicketApuestasNew,
  });
  return newState;
}

const placingBet = (state: ticketState, placeStatus: boolean) =>
  (state = {
    ...state,
    ticket: {
      ...state.ticket,
      isPlacingbet: placeStatus,
    },
  });

const setOddErrors = (state: ticketState) =>
  (state = {
    ...state,
    ticket: {
      ...state.ticket,
      OrderErrorMessages: [],
      OrderErrors: {},
      hasChangesInOdds: false,
      processing: false,
    },
  });

const removeTicket = (state: ticketState) =>
  (state = {
    ...state,
    processingFrom: "",
    ticket: TicketMethods.newC_TicketApuestas(),
  });

const betbuilderOddsChange = (state: ticketState) =>
  (state = {
    ...state,
    ticket: { ...state.ticket, hasChangesInOdds: true },
  });

function isBetTypeHidden(betType: string): boolean {
  switch (parseInt(betType)) {
    case 0:
      return false;
    case 1:
      return false; //ticket.MultipleChoice; // ??
    case 2:
      return false;
    // return this._globalVars.licenseType === 0;
    case 3:
      // todo revisar
      // return !this.ticketBetSlip.possibleSystems!=undefined;
      return false;
    default:
      return false;
  }
}

const _ticketHasOddsChanges = (
  ticket: C_TicketApuestasNew,
  globalsData: { cuotaAccept: string; userLogged: boolean },
): C_TicketApuestasNew => {
  ticket = gameChangedQuote(ticket);

  const oddAcceptance = globalsData.cuotaAccept;
  const keys = Object.keys(ticket.OrderErrors);
  const isOddChangeError = Boolean(keys.find((key) => key.toString() === "51937"));

  if (!globalsData.userLogged || oddAcceptance === "0" || !isOddChangeError) {
    ticket.hasChangesInOdds = false;
    if (Object.keys(ticket.OrderErrors).length !== 0) TicketMethods.deleteError(ticket, "51937");
    return ticket;
  }

  let cond;
  switch (oddAcceptance) {
    case "1":
      cond = (item) => item.downOdd;
      break;
    case "2":
      cond = (item) => item.downOdd || item.upOdd;
      break;
  }

  ticket.hasChangesInOdds = ticket.LineItems.some(cond);

  if (oddAcceptance === "1" && isOddChangeError) {
    if (Object.keys(ticket.OrderErrors).length !== 0) TicketMethods.deleteError(ticket, "51937");
    return ticket;
  }

  if (globalsData.cuotaAccept === "2" && ticket.hasChangesInOdds && isOddChangeError) {
    if (Object.keys(ticket.OrderErrors).length !== 0) TicketMethods.deleteError(ticket, "51937");
    return ticket;
  }
  return ticket;
};

const _ticketCheckErrors = (newState: ticketState): C_TicketApuestasNew => {
  let existAccumulator;
  let existMultiples;

  if (newState.betSlip !== undefined && newState.betSlip !== null) {
    existAccumulator = newState.betSlip.possibleSystems.find(
      (system) => system.systemType === StakeGroup.ACCUMULATOR,
    );
    existMultiples = newState.betSlip.possibleSystems.find(
      (system) => system.systemType === StakeGroup.SYSTEM || system.systemType === StakeGroup.MULTI_WAY_SYSTEM,
    );
  }

  /** MULTIPLES **/
  // multiError: Selections of the same market
  if (
    newState.filterSelected !== undefined &&
    newState.filterSelected !== null &&
    !existMultiples &&
    newState.filterSelected.value === 2
  ) {
    newState.ticket.OrderErrors["multiError"] = "No está permitido este tipo de apuesta.";
    const errorMultiError = newState.ticket.OrderErrorMessages.find((i) =>
      i.includes("No está permitido este tipo de apuesta."),
    );
    if (!errorMultiError) newState.ticket.OrderErrorMessages.push("No está permitido este tipo de apuesta.");
  } else {
    if (Object.keys(newState.ticket.OrderErrors).length !== 0)
      TicketMethods.deleteError(newState.ticket, "multiError");
  }

  /** ACCUMULATOR **/
  // 51931: Selections of the same market
  if (
    newState.filterSelected !== undefined &&
    newState.filterSelected !== null &&
    !existAccumulator &&
    newState.filterSelected.value === 1
  ) {
    newState.ticket.OrderErrors["51931"] = "No está permitido combinar pronósticos del mismo mercado.";
    const error51931 = newState.ticket.OrderErrorMessages.find((i) =>
      i.includes("No está permitido combinar pronósticos del mismo mercado."),
    );
    if (!error51931)
      newState.ticket.OrderErrorMessages.push("No está permitido combinar pronósticos del mismo mercado.");
  } else {
    if (Object.keys(newState.ticket.OrderErrors).length !== 0) TicketMethods.deleteError(newState.ticket, "51931");
  }

  // Check blocked lineitems and add error message
  // 51941: "Alguna de las selecciones está bloqueada",
  const gameLocked: LineItem = getAllItems(newState.ticket).find((item) => item.GameLocked);
  if (gameLocked) {
    newState.ticket.OrderErrors["51941"] = "Alguna de las selecciones está bloqueada.";
    const error51941 = newState.ticket.OrderErrorMessages.find((i) =>
      i.includes("Alguna de las selecciones está bloqueada."),
    );
    if (!error51941) newState.ticket.OrderErrorMessages.push("Alguna de las selecciones está bloqueada.");
  } else {
    if (Object.keys(newState.ticket.OrderErrors).length !== 0) TicketMethods.deleteError(newState.ticket, "51941");
  }

  // 51927: "El mercado no está disponible"
  const gameEnded: LineItem = getAllItems(newState.ticket).find((item) => item.NotAvailable);
  if (gameEnded) {
    newState.ticket.OrderErrors["51927"] = "El mercado no está disponible.";
    const error51927 = newState.ticket.OrderErrorMessages.find((i) =>
      i.includes("El mercado no está disponible."),
    );
    if (!error51927) newState.ticket.OrderErrorMessages.push("El mercado no está disponible.");
  } else {
    if (Object.keys(newState.ticket.OrderErrors).length !== 0) TicketMethods.deleteError(newState.ticket, "51927");
  }

  // ST_BS_ToLowMinAmountPerWay: "El mercado no está disponible"
  const toLowMinAmountPerWay: LineItem = getAllItems(newState.ticket).find((item) => item.NotAvailable);
  if (toLowMinAmountPerWay) {
    newState.ticket.OrderErrors["ST_BS_ToLowMinAmountPerWay"] = "ST_BS_ToLowMinAmountPerWay";
    const error51927 = newState.ticket.OrderErrorMessages.find((i) => i.includes("ST_BS_ToLowMinAmountPerWay"));
    if (!error51927) newState.ticket.OrderErrorMessages.push("ST_BS_ToLowMinAmountPerWay");
  } else {
    if (Object.keys(newState.ticket.OrderErrors).length !== 0)
      TicketMethods.deleteError(newState.ticket, "ST_BS_ToLowMinAmountPerWay");
  }

  if (newState?.filterSelected?.value !== 1) {
    if (Object.keys(newState.ticket.OrderErrors).length !== 0)
      TicketMethods.deleteError(newState.ticket, "NationalBetTypeNotAllowed");
  }

  return newState.ticket;
};

const _checkOddsChangeErrors = (ticket: C_TicketApuestasNew, errors: any): C_TicketApuestasNew => {
  const keys = Object.keys(ticket.OrderErrors);
  const isOddChangeError = Boolean(keys.find((key) => key.toString().includes("ST_EMS_ErrorOdds")));

  switch (errors) {
    case "ST_EMS_ErrorOddsChangeUp":
    case "ST_EMS_ErrorOddsChangeDown":
    case "ST_SBS_CalculationBaseInvalidOdd":
      ticket.hasChangesInOdds = true;
      break;
    default:
      ticket.hasChangesInOdds = false;
      break;
  }

  if (ticket.hasChangesInOdds && isOddChangeError) {
    if (Object.keys(ticket.OrderErrors).length !== 0) TicketMethods.deleteError(ticket, errors);
  }

  return ticket;
};

const _checkSmartLineItemMarketErrors = (ticket: C_TicketApuestasNew, SingleTicketItem: any) => {
  const errors = SingleTicketItem.ErrorCode;
  if (errors.includes("ReferenceEventNodeNotActive")) {
    ticket.SmartLineItems.map((smartLineItem, index) => {
      const existItemId = SingleTicketItem.ItemId === smartLineItem.ItemId;
      smartLineItem.NotAvailable = !!existItemId;
    });
  }

  const isSmartMarketLiveError = ticket.OrderErrors["ST_SBS_CalculationBaseReferenceEventNodeNotActive"];
  if (isSmartMarketLiveError) {
    ticket.OrderErrorMessages = [StoreConstants.ST_SBS_CalculationBaseReferenceEventNodeNotActive];
  }

  return ticket;
};

const _setFilterStatus = (state: ticketState) => {
  const auxState = JSON.parse(JSON.stringify(state));
  const filters = JSON.parse(JSON.stringify(auxState)).filters;
  let existMultiples;
  if (auxState.betSlip !== undefined && auxState.betSlip !== null) {
    existMultiples = auxState.betSlip.possibleSystems.find(
      (system) => system.systemType === StakeGroup.SYSTEM || system.systemType === StakeGroup.MULTI_WAY_SYSTEM,
    );
  }
  const numberOfSelections = TicketMethods.getNumberOfSelections(auxState.ticket);
  let auxFilterSelected = JSON.parse(JSON.stringify(auxState.filterSelected));

  if (numberOfSelections < 1) {
    filters[0].selected = true;
    filters[1].disabled = true;
    filters[1].selected = false;
    filters[2].disabled = true;
    filters[2].selected = false;
  } else if (numberOfSelections === 1) {
    filters[0].selected = true;
    filters[1].disabled = true;
    filters[1].selected = false;
    filters[2].disabled = true;
    filters[2].selected = false;
    if (auxState.filterSelected !== null && auxState.filterSelected.value === 1) {
      auxFilterSelected = filters[0];
    }
  } else {
    filters[1].disabled = false;
    filters[2].disabled = !existMultiples;
    if (!existMultiples && numberOfSelections < 2 && filters[2].selected) {
      filters[0].selected = true;
      filters[2].selected = false;
    }
  }

  const returnNewState = Object.assign({}, state, {
    filters: filters,
    filterSelected: auxFilterSelected,
  });
  return returnNewState;
};

const _setPossibleSystems = (betSlip: ticketBetSlip) => {
  const possibleSystems: systemData[] = [];
  betSlip.possibleSystems.map((system) => {
    if (system.systemType === StakeGroup.SYSTEM || system.systemType === StakeGroup.MULTI_WAY_SYSTEM) {
      let text = "";
      let groupName = "";
      switch (system.systemId) {
        case 2:
          text = `Dobles: ${system.numberOfBetWays} apuestas`;
          groupName = StakeGroup.DOUBLES;
          break;
        case 3:
          text = `Triple: ${system.numberOfBetWays} apuestas`;
          groupName = StakeGroup.TRIPLES;
          break;
        case 4:
          text = `Cuadruple: ${system.numberOfBetWays} apuestas`;
          groupName = StakeGroup.CUADRUPLES;
          break;
        default:
          text = `Multiple (${system.systemId}): ${system.numberOfBetWays} apuestas`;
          groupName = `${StakeGroup.SYSTEM}${system.systemId}`;
          break;
      }
      const possibleSystem: systemData = {
        canBeEachWay: false,
        numberOfBetWays: system.numberOfBetWays,
        numberOfEachWayBetWays: system.numberOfEachWayBetWays,
        systemId: system.systemId,
        systemType: system.systemType,
        text: text,
        groupName: groupName,
      };
      possibleSystems.push(possibleSystem);
    }
  });
  return possibleSystems;
};

const gameChangedQuote = (ticket) => {
  // Check coute change lineitems and add error message
  // 51937: "La cuota ha cambiado",
  const gameChanged: LineItem = getAllItems(ticket).find((item) => item.downOdd || item.upOdd);
  if (gameChanged) {
    ticket.OrderErrors["51937"] = "La cuota ha cambiado.";
    const error51937 = ticket.OrderErrorMessages.find((i) => i.includes("La cuota ha cambiado."));
    if (!error51937) ticket.OrderErrorMessages.push("La cuota ha cambiado.");
  } else {
    if (Object.keys(ticket.OrderErrors).length !== 0) TicketMethods.deleteError(ticket, "51937");
  }
  return ticket;
};

const closeBetNewOds = (lineItems, newOdd) => {
  const newLineItems = lineItems.map((lineItem, index) => {
    const newOddItem = newOdd.find((item) => item.ResultId === lineItem.ResultsNr);
    if (newOddItem) {
      lineItem.upOdd = newOddItem.Odd > lineItem.Odd;
      lineItem.downOdd = newOddItem.Odd < lineItem.Odd;
      lineItem.Odd = newOddItem.Odd;
    }
    return lineItem;
  });
  return newLineItems;
};

const getAllItems = (ticket) => [
  ...ticket.LineItems,
  ...ticket.StraightMulticast,
  ...ticket.CombinationMulticast,
  ...ticket.SmartLineItems,
];

// * Comentado en repo
// export const TicketReducer:
// ActionReducer<any> = ticketReducer;
