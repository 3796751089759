import { Injectable, inject } from '@angular/core';

import { EMPTY, catchError, map, of, switchMap, withLatestFrom } from 'rxjs';
import { Actions, ROOT_EFFECTS_INIT, createEffect, ofType } from '@ngrx/effects';

import * as SidebarActions from '../actions/sidebar.actions';

import { NewBaseService } from '../../../../providers/newBase.service';
import { SportService } from '../../../../providers/SportService';

import { GlobalVarsModel } from '../../../../models/ModelGlobalsVar';
import * as models from '../../../../models/index';

// Models
import { C_Sport } from '../../../../models/C_Sport';
import { SidebarUtils } from '../storeUtils/sidebar.utils';

@Injectable({ providedIn: 'root' })
export class SidebarEffects {
  newBaseService = inject(NewBaseService);
  sportService = inject(SportService);
  globalVars!: GlobalVarsModel;

  /**
   * initial Effect
   * triggers on Effects initialization
   */

  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      switchMap(() =>
        this.sportService.getLeftMenu().pipe(
          map((leftMenuData) => {
            const sports = this.setSports(leftMenuData);
            const highlightsConfig = this.setHighlightsConfig(leftMenuData);
            const highlight = this.setHighlights(leftMenuData);
            return SidebarActions.updateSports({
              sports: sports,
              highlights: highlight,
              highlightsConfig: highlightsConfig
            });
          })
        )
      )
    )
  );

  fetchCountries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SidebarActions.fetchCountries),
      switchMap((sport) =>
        this.sportService.getSportItems(sport.NodeId).pipe(
          map((countriesData) =>
            SidebarActions.updateCountries({
              sport: sport,
              countries: countriesData
            })
          ),
          catchError(() => EMPTY)
        )
      )
    )
  );

  constructor(private actions$: Actions) {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
  }

  setSports(sportsData) {
    const auxsports = sportsData['sports'].map(
      (i) =>
        (i = new C_Sport(
          i.isnew,
          i.Locked,
          i.Name,
          i.NodeId,
          i.ParentNodeId,
          i.Priority,
          i.SportHandle,
          SidebarUtils.showDropdownIcon(i.SportHandle)
        ))
    );
    const auxSportsInfo = {};
    auxsports?.forEach((item) => {
      auxSportsInfo[item.SportHandle] = { id: item.NodeId, name: item.Name };
    });
    this.globalVars.sportItemsId$.next(auxSportsInfo);
    if (auxsports?.length == 0) {
      // this.utils.showError(this.translate.instant('m_menu_noSport'));
    } else {
      let newFeatures: string[] = [];

      if (this.globalVars.FEATURES.NewSports) {
        newFeatures = this.globalVars.FEATURES.NewSports.split(',');
      }

      for (let i: number = auxsports?.length - 1; i >= 0; i--) {
        auxsports[i].isnew = newFeatures.some((x) => x == auxsports[i].NodeId);
        if (auxsports[i].SportHandle == '') {
          console.warn('sport without SportHandle');
          console.warn(auxsports[i]);
          auxsports[i].SportHandle = auxsports[i].SportHandle == '' && 'Special Bets' ? 'special_bets' : '';
        } else if (auxsports[i].SportHandle === 'anticipadas') {
          auxsports[i].SportHandle = 'padel';
        }
        if (this.globalVars.FEATURES.ForbiddenSports && auxsports[i].SportHandle != '') {
          if (this.globalVars.FEATURES.ForbiddenSports.indexOf(auxsports[i].SportHandle) != -1) {
            auxsports.splice(i, 1);
          }
        }
        if (auxsports[i].Name === 'Cuotas Especiales' && this.globalVars.licenseType == 4) {
          auxsports[i].Name = 'Momios Especiales';
        }
      }
    }
    return auxsports;
  }

  setHighlights(sportsData) {
    const highlights = sportsData['highlights'];
    //remove event 'mejora_la_linea'
    const index = highlights?.findIndex((myItem) => myItem.SportsHandle == 'mejora_la_linea', 0);
    if (index > -1) {
      highlights.splice(index, 1);
    }
    for (let i: number = highlights?.length - 1; i >= 0; i--) {
      if (this.globalVars.FEATURES.ForbiddenSports) {
        if (this.globalVars.FEATURES.ForbiddenSports.indexOf(highlights[i].SportsHandle) != -1) {
          highlights.splice(i, 1);
        }
      }
    }
    return highlights;
  }

  setHighlightsConfig(sportsData) {
    return sportsData['highlightsConfig'].map(
      (specialSport) =>
        new models.C_SpecialSports(
          specialSport.CountryCode,
          specialSport.IconUrl,
          specialSport.IdLeagues,
          specialSport.LeagueName,
          specialSport.SportHandle,
          specialSport.SportName
        )
    );
  }
}
