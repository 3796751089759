/* eslint-disable @angular-eslint/no-output-on-prefix */
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { GlobalVarsModel } from '@models/ModelGlobalsVar';
import { NewBaseService } from '@providers/newBase.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'contact-form',
  templateUrl: './contact-form-pc.html',
  styleUrls: ['./contact-form.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule, TranslateModule]
})
export class contactForm {
  @Output() onSubmit = new EventEmitter();
  @Output() onSubmitWithCredentials = new EventEmitter();
  @Input() placeholder = '';

  textarea = '';
  username = '';
  email = '';
  askCredentials = false;
  emailError = false;
  globalVars!: GlobalVarsModel;

  newBaseService = inject(NewBaseService);
  translate = inject(TranslateService);

  emailValidator = (email: string) => {
    const regExpEmail =
      // eslint-disable-next-line no-useless-escape
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (regExpEmail.test(email)) {
      this.emailError = false;
      return true;
    } else {
      this.emailError = true;
      return false;
    }
  };
  submitTextarea() {
    if (this.askCredentials) {
      if (this.username.trim().length > 0 && this.email.trim().length > 0 && this.emailValidator(this.email)) {
        this.onSubmitWithCredentials.emit({
          textarea: this.textarea,
          username: this.username,
          email: this.email
        });
      }
    } else if (this.globalVars.user.logged) {
      if (this.textarea.trim().length > 0) {
        this.onSubmit.emit(this.textarea);
      }
    } else {
      this.askCredentials = true;
    }
  }

  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });

    this.askCredentials = !this.globalVars.user.logged;
  }
}
