import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class JackpotService {
  intervalTime = 0.2;
  gamesIntervals = {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}

  getTicker(value, timer, game) {
    let ticker = this.gamesIntervals[game];
    if (!ticker || ticker.currentValue.value > value) {
      this.gamesIntervals[game] = {
        startValue: (99 * value) / 100,
        endValue: value,
        currentValue: new BehaviorSubject<number>(0)
      };
      ticker = this.gamesIntervals[game];
      ticker.currentValue.next(ticker.startValue);
    } else {
      ticker.startValue = ticker.currentValue.value;
      ticker.endValue = value;
      clearInterval(ticker.interval);
    }
    const steps = timer / this.intervalTime;
    const stepValue = (ticker.endValue - ticker.startValue) / steps;
    let time = -this.intervalTime;
    ticker.interval = setInterval(() => {
      if (time > timer) {
        clearInterval(ticker.interval);
      }
      time = time + this.intervalTime;
      ticker.currentValue.next(ticker.currentValue.value + stepValue);
    }, this.intervalTime * 1000);
    return ticker.currentValue.asObservable();
  }
}
