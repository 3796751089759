import { Component, OnDestroy, ViewChild, inject } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable eqeqeq */
import {
  AlertButton,
  AlertController,
  AlertOptions,
  IonicModule,
  MenuController,
  ModalController,
  NavParams,
} from "@ionic/angular";

import { LicenseTypes } from "@models/MasterData";
import { GlobalVarsModel } from "@models/ModelGlobalsVar";
import { TranslateService } from "@ngx-translate/core";
import { SlotsBonusesService } from "@providers/SlotsBonusesService";
import { NewBaseService } from "@providers/newBase.service";
import { DeviceService } from "@services/device.service";
import { BackButton } from "@shared-common-components/back-button/back-button";
import { MSO_PATHS, PAGES_PATHS } from "@shared-constants/routes";
import { CodereFooterComponent } from "@shared-mso-components/footer/codere-footer/codere-footer";
import { UserSectionTitle } from "@shared-mso-components/user-section-title/user-section-title";
import { BasePage, TrackingInfo } from "@shared-pages/base/BasePage";
import { Utils } from "@utils/Utils";

import { CookieNames } from "@models/CookieNames";
import { Promotion } from "@models/I_Promotion";
import { I_UserInfo } from "@models/I_UserInfo";

import { CommonModule } from "@angular/common";
import { ActivatedRoute, NavigationEnd, NavigationSkipped, Router } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { DecouplingUserServiceMSO } from "@providers/DecouplingUserServiceMSO.service";
import { EventsService } from "@providers/EventsService";
import { UserServiceMSO } from "@providers/UserServiceMSO";
import * as Constants from "@shared-utils/constants";
import { Subscription, firstValueFrom, map } from "rxjs";
import { AlertCtaPromoComponent } from "../../components/alert-cta-promo/alert-cta-promo";

//eslint-disable-next-line
enum PostMessageActions {
  AvailableBonusRequest = "AvailableBonusRequest",
  AvailableBonusResponse = "AvailableBonusResponse",
  PromotionsOptIn = "PromotionsOptIn",
  PromotionsPageOptIn = "PromotionsPageOptIn",
  PromotionsOptOut = "PromotionsOptOut",
  GoToPage = "GoToPage",
  OptinErrorOtherPromoSing = "OptinErrorOtherPromoSing",
}

@Component({
  selector: "promociones-page",
  templateUrl: "./promociones.html",
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    CodereFooterComponent,
    BackButton,
    UserSectionTitle,
    AlertCtaPromoComponent,
  ],
})
export class PromotionsPage extends BasePage implements OnDestroy {
  public trackEvent: TrackEvent;
  @ViewChild("iframe") iframe;

  urlFromQuery: string;
  scrHelp: any;
  promoListener: any;
  $pageDataSuscription: Subscription;

  promotionsUrlConfig: {
    [key: number]: {
      urlBase: string;
      allPromotionsPath: string;
      activeUrl: string;
    };
  };

  globalVars!: GlobalVarsModel;
  isMobile: boolean;

  newBaseService = inject(NewBaseService);
  utils = inject(Utils);
  routerSubscription: Subscription;
  loginSubscription: Subscription;
  events = inject(EventsService);
  menu = inject(MenuController);
  navParams = inject(NavParams);
  sanitizer = inject(DomSanitizer);
  slotsBonusesService = inject(SlotsBonusesService);
  router = inject(Router);
  routerActive = inject(ActivatedRoute);
  device = inject(DeviceService);
  userServiceMSO = inject(UserServiceMSO);
  modalCtr = inject(ModalController);
  alertCtr = inject(AlertController);
  translate = inject(TranslateService);
  decouplingUserServiceMSO = inject(DecouplingUserServiceMSO);

  constructor() {
    super();
    this.isMobile = this.device.isMobile();
    this.promoListener = this.listenEvents.bind(this);
    this.$pageDataSuscription = this.routerActive.data.subscribe((pageData) => {
      this.setURLConfig(pageData && Boolean(pageData["promotionsTest"]));
    });

    window["promoPage"] = this;
  }

  async initPromotionsPage() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });

    const params = await firstValueFrom(this.routerActive.queryParams);

    const configurl = this.promotionsUrlConfig[this.globalVars.licenseType];

    if (params) {
      if (params["url"]) {
        this.urlFromQuery = params["url"];

        if (params["promoList"]) {
          this.urlFromQuery += "?promoList=" + params["promoList"];
        }

        if (params["promoData"]) {
          this.urlFromQuery += this.urlFromQuery.includes("?") ? "&" : "?";
          this.urlFromQuery += "?promoData=" + params["promoData"];
        }

        this.urlFromQuery += this.urlFromQuery.includes("?") ? "&" : "?";

        if (params["optinedFromLoggedOut"]) {
          this.urlFromQuery += "optinedFromLoggedOut=true";
        }

        this.urlFromQuery += this.urlFromQuery.includes("=") ? "&" : "";
        this.formatUrlPromotionsPage(this.urlFromQuery + "authenticated=" + this.globalVars.user.logged);
      } else {
        this.formatUrlPromotionsPage(`${configurl.urlBase}${configurl.allPromotionsPath}?authenticated=true`);
      }
    } else {
      this.formatUrlPromotionsPage(`${configurl.urlBase}${configurl.allPromotionsPath}?authenticated=true`);
    }
  }

  async formatUrlPromotionsPage(urlToFormat: string) {
    if (this.globalVars.user.logged) {
      let userInfo: I_UserInfo = null;
      try {
        userInfo = this.globalVars.rootScope.cookiesService.getCookie(CookieNames.UserInfo)
          ? JSON.parse(this.globalVars.rootScope.cookiesService.getCookie(CookieNames.UserInfo))
          : await firstValueFrom(
              this.globalVars.FEATURES.MSO_DecouplingInfoPlayer
                ? this.decouplingUserServiceMSO
                    .getInfoPlayer()
                    .pipe(map((response) => this.utils.mapUserInfo(response)))
                : this.userServiceMSO.getUserInfo(),
            );
      } catch (error) {
        console.error(error);
      }

      const promoList = await firstValueFrom(
        this.userServiceMSO
          .getPromotionsList()
          .pipe(
            map((response) =>
              response.promotions.map((item) => ({ promoId: item.promoId, promoType: item.promoType })),
            ),
          ),
      );

      const promoData =
        this.globalVars.licenseType === LicenseTypes.Nacional
          ? await firstValueFrom(this.userServiceMSO.getIsPromotionable())
          : null;

      let newUrl: string = urlToFormat + (urlToFormat.includes("?") ? "" : "?");

      newUrl += `${newUrl.includes("=") ? "&" : ""}promoData=${
        promoData ? encodeURI(JSON.stringify(promoData)) : encodeURI(JSON.stringify(promoList))
      }`;

      if (promoData) newUrl += `&promoList=${encodeURI(JSON.stringify(promoList))}`;

      if (userInfo) {
        newUrl += `&vipstats=${userInfo.vipEstado === Constants.VipEstado.YES}${
          !userInfo.vipLevel ? "" : "," + userInfo.vipLevel
        }`;
        newUrl += `&verifiDocumental=${userInfo.verifiDocumental}`;
        newUrl += `&isUserMadeFTD=${userInfo.isUserMadeFTD}`;
        newUrl += `&segmentedClosed=${userInfo.segmentedClosed}`;

        if (this.globalVars.licenseType === LicenseTypes.Nacional) {
          newUrl += `&vipEstado=${userInfo.vipEstado}`;
          newUrl += `&birthdate=${userInfo.birthdate}`;
          newUrl += `&commercialCommunications=${userInfo.commercialCommunications}`;
          newUrl += `&notverified30days=${userInfo.notverified30days}`;
          newUrl += `&ispromotablevip=${userInfo.ispromotablevip}`;
          newUrl += `&userOptinPossibility=${userInfo.userOptinPossibility}`;
        }
      }

      this.scrHelp = this.sanitizer.bypassSecurityTrustResourceUrl(newUrl);
    } else {
      this.scrHelp = this.sanitizer.bypassSecurityTrustResourceUrl(urlToFormat);
    }
  }

  ionViewWillEnter() {
    window.addEventListener("message", this.promoListener);
    this.trackingService.trackEvent(["AccessToPromotionPage", "", "", "Acceder a pagina de promociones", "event"]);
    this.utils.hideFormAccessoryBar(false);
    this.resetPromotions();
    this.initPromotionsPage();
    this.setLoginListener();
    this.setRouerSubscriptions();
  }

  ionViewDidLeave() {
    this.scrHelp = undefined;
    window.removeEventListener("message", this.promoListener);
    this.unSubscribeLoginListener();
    this.utils.hideFormAccessoryBar(false);
    this.slotsBonusesService.storeIforiumPromoCookie(); // #67436
  }

  listenEvents(e: any) {
    if (!e?.data || e?.data?.messageName) return;

    if ((e?.data?.search && e.data.search("PROMOTIONPAGE@") !== -1) || !this.globalVars.user.logged) {
      if (this.urlFromQuery && !this.globalVars.user.logged) {
        this.router.navigateByUrl(
          `HomePage?openlogin=true&afterloginurl=/${MSO_PATHS.BASE}/${MSO_PATHS.PromotionsPage}?url=${this.urlFromQuery}&optinedFromLoggedOut=true`,
        );
      } else if (e?.data?.search && e?.data?.search("PROMOTIONPAGE@") !== -1) {
        const urlToOptIn = (e.data as string).includes("afterloginurl=")
          ? (e?.data as string).split("afterloginurl=")[1]
          : null;
        if (urlToOptIn && urlToOptIn.startsWith("http")) {
          this.router.navigateByUrl(
            `HomePage?openlogin=true&afterloginurl=/${MSO_PATHS.BASE}/${MSO_PATHS.PromotionsPage}?url=${urlToOptIn}&optinedFromLoggedOut=true`,
          );
        } else {
          this.router.navigateByUrl(
            e?.data
              .replace("PROMOTIONPAGE@", "")
              .replace("PromotionsPage", `/${MSO_PATHS.BASE}/${MSO_PATHS.PromotionsPage}`),
          );
        }
      } else if (e?.data && e?.data?.data && e?.data?.data?.includes("promoId")) {
        const config = this.promotionsUrlConfig[this.globalVars.licenseType];
        const urlPromoId = `${config.urlBase}${config.activeUrl}${JSON.parse(e.data.data).promoId}.aspx`;
        this.router.navigateByUrl(
          `HomePage?openlogin=true&afterloginurl=/${MSO_PATHS.BASE}/${MSO_PATHS.PromotionsPage}?url=${urlPromoId}&optinedFromLoggedOut=true`,
        );
      }
    } else {
      switch (e?.data?.action) {
        case PostMessageActions.AvailableBonusRequest:
          this.slotsBonusesService.getPlayerAvailableBonuses().subscribe((bonuses) => {
            e?.source?.postMessage(
              { action: PostMessageActions.AvailableBonusResponse, data: bonuses },
              e?.origin,
            );
          });
          break;
        case PostMessageActions.PromotionsOptIn:
          console.info("Messge from iframe", e.data);
          const promo: Promotion = JSON.parse(e?.data?.data);
          if (this.globalVars.FEATURES.MSO_NewCtaPromoPopup && promo.promoPrize && promo.promoPrize === "yes") {
            this.newLogicCtaPopup(promo);
          } else {
            this.doOptIn(promo);
          }
          break;
        case PostMessageActions.PromotionsOptOut:
          console.info("Messge from iframe", e?.data);
          this.userServiceMSO
            .setPromotionsOptOut(JSON.parse(e?.data?.data))
            .subscribe((data) => this.updateAfterOpt(data, PostMessageActions.PromotionsOptOut));
          break;
        case PostMessageActions.GoToPage:
          const page = e?.data?.data;
          console.info("goToPage", page);
          this.globalVars.rootScope.extTryToGoPage(page);
          break;
        case PostMessageActions.PromotionsPageOptIn:
          console.info("Messge from iframe - PromotionsPageOptIn", e?.data);
          this.userServiceMSO.setPromotionsOptIn(JSON.parse(e?.data?.data)).subscribe({
            next: (data) => {
              this.updateAfterOpt(data, PostMessageActions.PromotionsOptIn);
              this.updateAfterOpt(data.promotableErrors ? 0 : 1, PostMessageActions.PromotionsPageOptIn);
              this.initPromotionsPage();
            },
            error: (err) => {
              this.updateAfterOpt(0, PostMessageActions.PromotionsPageOptIn);
              this.initPromotionsPage();
            },
          });
          break;
        case PostMessageActions.OptinErrorOtherPromoSing:
          if (this.globalVars.FEATURES.MSO_NewAlertsPromotionsPage) {
            this.showNewAlertsErrorsOptIn(
              this.canShowErrorAlert([PostMessageActions.OptinErrorOtherPromoSing]),
              null,
              e.data.urlPromoSined,
            );
          }
          break;
        default:
          break;
      }
    }
  }

  getTrackingInfo(): TrackingInfo {
    return {
      uri: "/promotions",
      description: "Promotions page",
      additionalData: null,
    };
  }

  openModalUser() {
    this.menu.open("right");
  }

  backToHome() {
    this.router.navigate([PAGES_PATHS.BASE + "/" + PAGES_PATHS.HomePage]);
  }

  openPromotions() {
    this.resetPromotions();
    this.initPromotionsPage();
  }
  doOptIn(promoData: Promotion) {
    this.userServiceMSO.setPromotionsOptIn(promoData).subscribe((data) => {
      this.updateAfterOpt(data, PostMessageActions.PromotionsOptIn);
    });
  }
  updateAfterOpt(data: any, action: PostMessageActions) {
    console.info("updateAfterOpt", data);
    if (this.globalVars.FEATURES.MSO_NewAlertsPromotionsPage && action === PostMessageActions.PromotionsOptIn) {
      if (data.promotableErrors && data.promotableErrors.length > 0) {
        this.showNewAlertsErrorsOptIn(this.canShowErrorAlert(data.promotableErrors), data.registratedDate);
      } else {
        this.showAlertOptInOK();
      }
    }
    const iframe = this.iframe.nativeElement;
    iframe.contentWindow.postMessage(
      {
        action: action,
        data: data,
      },
      "*",
    );
    console.info("Send to iframe", {
      action: action,
      data: data,
    });
  }

  checkOptIn() {
    const testData = {
      promoId: "testwithoutdates100",
      promoProduct: "Casino",
      promoType: "multi",
      tyc: "1",
      promoStart: "2021-05-01",
      promoEnd: "2022-12-01",
    };
    this.userServiceMSO
      .setPromotionsOptIn(testData)
      .subscribe((data) => this.updateAfterOpt(data, PostMessageActions.PromotionsOptIn));
  }

  resetPromotions() {
    this.urlFromQuery = undefined;
    this.scrHelp = undefined;
  }

  ngOnDestroy(): void {
    if (this.routerSubscription) this.routerSubscription.unsubscribe();
    if (this.$pageDataSuscription) this.$pageDataSuscription.unsubscribe();
  }

  setLoginListener() {
    this.loginSubscription = this.events.subscribe("userInfoDone", () => {
      this.initPromotionsPage();
    });
  }

  setRouerSubscriptions() {
    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd || event instanceof NavigationSkipped) {
        this.router.navigated = false;
        this.utils.hideFormAccessoryBar(false);
        if (event.url.includes(MSO_PATHS.PromotionsPage) && location.hash.includes(MSO_PATHS.PromotionsPage)) {
          this.resetPromotions();
          this.initPromotionsPage();
        }
      }
    });
  }

  unSubscribeLoginListener() {
    if (this.loginSubscription) this.loginSubscription.unsubscribe();
  }

  newLogicCtaPopup(dataPromo: Promotion) {
    this.userServiceMSO.checkOptIn(dataPromo).subscribe(async (response) => {
      if (response.promotableErrors && response.promotableErrors.length > 0) {
        this.updateAfterOpt(response, PostMessageActions.PromotionsOptIn);
      } else {
        const alertResponse = await this.showNewAlertCtaPromo(dataPromo);
        if (alertResponse === "Accepted") {
          this.doOptIn(dataPromo);
        } else {
          this.openPromotions();
        }
      }
    });
  }

  showNewAlertCtaPromo(promoData: Promotion) {
    return new Promise<string>((resolve) => {
      const alertCta = this.modalCtr.create({
        component: AlertCtaPromoComponent,
        backdropDismiss: false,
        cssClass: "alertCtaPromo",
        componentProps: {
          promoData,
        },
      });

      alertCta.then((alert) => {
        alert.present();
        alert.onDidDismiss().then((responseAlert) => {
          resolve(responseAlert.role);
        });
      });
    });
  }
  async showNewAlertsErrorsOptIn(data: string[], registratedDate?: string, promoSigned?: string) {
    const alertOptions: AlertOptions = {
      message: `<img src="assets/global/img/icon-info-contact-blue.svg" alt="Warning"> <span class="closeIcon"><i class="color-dark codere-icon icon-close" item-right=""></i></span> <h1>${this.translate.instant(
        "requesterror",
      )}</h1><br>`,
      cssClass: "alertPromotionsKo" + (this.globalVars.FEATURES.MSO_NewReBranding ? " newRebrandingCss" : ""),
      backdropDismiss: false,
      buttons: [
        {
          cssClass: "btnAlertPromotionsKo",
          text: this.translate.instant("close"),
          role: "cancel",
        },
      ],
      mode: "md",
    };

    let showAlert = true;
    let linkHandler: () => void;

    switch (true) {
      case data.indexOf("autoforbidden") > -1 || data.indexOf("autoexcluded") > -1 || data.indexOf("risk") > -1:
        alertOptions.message += this.translate.instant("alertOptionsMessageErrReqLegal");
        break;

      case data.indexOf("ended") > -1:
        alertOptions.message += this.translate.instant("alertOptionsMessageErrended");
        if (typeof alertOptions.buttons[0] !== "string") {
          (alertOptions.buttons[0] as AlertButton).handler = () => {
            this.openPromotions();
          };
        }
        break;

      case data.indexOf("30") > -1:
        const daysRemaining = this.utils.differenceInDays(new Date(registratedDate), new Date());
        const msg = this.translate.instant("alertOptionsMessageErr30").replace("XX", daysRemaining);
        alertOptions.message += msg;
        break;

      case data.indexOf("+30") > -1:
        alertOptions.message += this.translate.instant("alertOptionsMessageErrmas30");
        if (typeof alertOptions.buttons[0] !== "string") {
          (alertOptions.buttons[0] as AlertButton).handler = () => {
            this.openPromotions();
          };
        }
        break;

      case data.indexOf("documents") > -1:
        alertOptions.message += this.translate.instant("alertOptionsMessageErrVdocum");

        if (typeof alertOptions.buttons[0] !== "string") {
          (alertOptions.buttons[0] as AlertButton).handler = () => {
            this.events.publish("documentverification:open");
          };
        }
        linkHandler = () => {
          this.events.publish("documentverification:open");
        };
        break;

      case data.indexOf("El usuario no puede optar a esta promoción") > -1:
        alertOptions.message += this.translate.instant("alertOptionsMessageErrTyC");
        if (typeof alertOptions.buttons[0] !== "string") {
          (alertOptions.buttons[0] as AlertButton).handler = () => {
            this.openPromotions();
          };
        }
        break;

      case data.indexOf(PostMessageActions.OptinErrorOtherPromoSing) > -1:
        const urlSplit = promoSigned.split("/");
        const idPromoSigned: string = urlSplit[urlSplit.length - 1].split(".")[0];
        const msg2 = this.translate.instant("alertOptionsMessageErrIdPromoSigned").replace("XX", idPromoSigned);
        alertOptions.message += msg2;
        linkHandler = () => {
          this.resetPromotions();
          this.formatUrlPromotionsPage(promoSigned + "?authenticated=" + this.globalVars.user.logged);
        };
        break;
      case data.indexOf("under26") > -1:
        alertOptions.message += this.translate.instant("alertOptionsMessageErrTyC");
        if (typeof alertOptions.buttons[0] !== "string") {
          (alertOptions.buttons[0] as AlertButton).handler = () => {
            const isTest =
              this.promotionsUrlConfig[this.globalVars.licenseType].allPromotionsPath.includes("Test");
            this.router.navigateByUrl(
              `/${MSO_PATHS.BASE}/${isTest ? MSO_PATHS.PromotionsTestPage : MSO_PATHS.PromotionsPage}`,
            );
          };
        }
        break;
      default:
        showAlert = false;
    }

    if (showAlert) {
      const alertOptinKo = await this.alertCtr.create(alertOptions);
      alertOptinKo.present().then(() => {
        const nodeIonAlert = alertOptinKo;
        const iconW = nodeIonAlert.querySelector(".closeIcon");
        const linkDoc = nodeIonAlert.querySelector("a");
        if (linkDoc) {
          linkDoc.addEventListener("click", ($event) => {
            $event.preventDefault();
            linkHandler();
            alertOptinKo.dismiss();
          });
        }
        iconW.addEventListener("click", () => {
          alertOptinKo.dismiss();
        });
      });
    }
  }

  async showAlertOptInOK() {
    const alertOptions: AlertOptions = {
      message:
        `<span class="closeIcon"><i class="color-dark codere-icon icon-close" item-right=""></i></span>
      <img src="assets/global/img/icon-checkgreen.svg" alt="OK" class="checkgreen">` +
        this.translate.instant("alertOptionsMessageOK"),
      cssClass: "alertPromotionsOk" + (this.globalVars.FEATURES.MSO_NewReBranding ? " newRebrandingCss" : ""),
      buttons: [
        {
          role: "confirm",
          text: "Cerrar",
          cssClass: "btnPromoOK",
        },
      ],
      mode: "md",
    };

    const alertOptinOk = await this.alertCtr.create(alertOptions);
    alertOptinOk.present().then(() => {
      const nodeIonAlert = alertOptinOk;
      const iconW = nodeIonAlert.querySelector(".closeIcon");
      iconW.addEventListener("click", () => {
        alertOptinOk.dismiss();
      });
    });
  }

  canShowErrorAlert(promotableErrors: string[]): string[] {
    const showError: string[] = [];
    if (this.globalVars.FEATURES.MSO_SkipErrorMessagePromotions) {
      const skipErrors = this.globalVars.FEATURES.MSO_SkipErrorMessagePromotions.split(";");
      promotableErrors.forEach((promotableError) => {
        if (!skipErrors.includes(promotableError)) {
          showError.push(promotableError);
        }
      });
      return showError;
    } else {
      return promotableErrors;
    }
  }

  setURLConfig(isTest = false) {
    this.promotionsUrlConfig = {
      [LicenseTypes.Nacional]: {
        urlBase: "https://www.codere.es/PromosCalidad/",
        allPromotionsPath: `PromosCalidad${isTest ? "Test" : ""}.aspx`,
        activeUrl: "actives/",
      },
      [LicenseTypes.Panama]: {
        urlBase: "https://www.codere.pa/PromocionesPA/",
        allPromotionsPath: `PromosCalidad${isTest ? "Test" : ""}.aspx`,
        activeUrl: "actives/",
      },
      [LicenseTypes.Colombia]: {
        urlBase: "https://www.codere.com.co/PromosCO/",
        allPromotionsPath: `PromosCalidad${isTest ? "Test" : ""}.aspx`,
        activeUrl: "actives/",
      },
      [LicenseTypes.ArgentinaCaba]: {
        urlBase: "https://www.codere.bet.ar/PromosCalidad/",
        allPromotionsPath: `PromosCalidad${isTest ? "Test" : ""}.aspx`,
        activeUrl: "actives/",
      },
      [LicenseTypes.ArgentinaPBA]: {
        urlBase: "https://www.codere.bet.ar/PromosCalidad/",
        allPromotionsPath: `PromosCalidad${isTest ? "Test" : ""}.aspx`,
        activeUrl: "actives/",
      },
      [LicenseTypes.ArgentinaMendoza]: {
        urlBase: "https://www.mdz.codere.bet.ar/PromosCalidad/",
        allPromotionsPath: `PromosCalidad${isTest ? "Test" : ""}.aspx`,
        activeUrl: "actives/",
      },
    };
  }
}
