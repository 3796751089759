<!-- eslint-disable @angular-eslint/template/eqeqeq -->
<div class="containerP containerPst1">
  <div class="texto page1">
    <div *ngIf="globalVars.licenseType != 22"><span>{{'selfExclusionP1tx01' | translate}}</span></div>
    <div *ngIf="globalVars.licenseType == 22">
      <span>{{'selfExclusionP1tx01' | translate}}</span>
      <span class="txtred"> ({{'selfExclusionP1tx01pa' | translate}})</span>,
      <span>{{'selfExclusionP1tx02pa' | translate}}</span>
    </div>
    <div>
      <span>{{'selfExclusionP1tx02' | translate}}</span>
      <span *ngIf="globalVars.licenseType == 0">{{'selfExclusionP1tx03' | translate}}</span>
    </div>
    <div *ngIf="globalVars.licenseType == 0"><span>{{'selfExclusionP1tx04' | translate}}</span></div>
    <div><span>{{'selfExclusionP1tx05' | translate}}</span></div>
    <div class="btnContainer">
      <ion-button
        (click)="goToStep((globalVars.licenseType == 21  || globalVars.licenseType == 22) ? 4 : 2)"
        class="btnorange"
        shape="round"
      >
        {{'selfExclusionP1btn' | translate}} <ion-icon slot="end" name="chevron-forward-outline"></ion-icon>
      </ion-button>
      <ion-button
        (click)="backToHome()"
        expand="block"
        class="btnwhite"
        [class.paddingStartEnd77]="isDesktop"
        shape="round"
      >
        {{'tCancel' | translate }}
      </ion-button>
    </div>
    <ng-container *ngIf="globalVars.licenseType == 0">
      <div><span>{{'selfExclusionP1tx06' | translate}}</span> <span>&nbsp;Gamblock</span></div>
      <div>
        <span>{{'selfExclusionP1tx07' | translate}}</span>
        <span>Cybersitter&nbsp;</span>
        <span>{{'and' | translate}}</span>
        <span>&nbsp;NetNanny</span>
        <span>{{'selfExclusionP1tx08' | translate}}</span>
      </div>
    </ng-container>
  </div>
</div>
