<form class="cs-oxxobank-form p-1 w-100" [formGroup]="paymentForm">
  <div
    class="cs-oxxobank-form--field background-color-white m-bottom-1"
    [class.valid]="paymentForm?.get('documentNumber')?.valid"
    [class.error]="paymentForm?.get('documentNumber')?.invalid && paymentForm?.get('documentNumber')?.touched"
  >
    <input
      class="cs-oxxobank-form--input w-100 p-left-1"
      type="text"
      [placeholder]="placeholderDoc"
      formControlName="documentNumber"
    />
  </div>
</form>
