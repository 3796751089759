import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  ViewChild,
  Input,
  Output,
  OnChanges,
  AfterViewInit,
  EventEmitter,
  NgZone,
  inject,
  ChangeDetectorRef,
  CUSTOM_ELEMENTS_SCHEMA
} from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { Observable, Subscription } from 'rxjs';
import { Swiper, SwiperOptions } from 'swiper';
import { CasinoCardNewComponent } from '../../../../components/casino/casino-card-new/casino-card-new.component';
import { DeviceService } from '../../../../services/device.service';

@Component({
  selector: 'lb-slider',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [CommonModule, IonicModule, CasinoCardNewComponent],
  standalone: true,
  templateUrl: './lobby-slider.html',
  styleUrls: ['./lobby-slider.scss']
})
export class LobbySliderComponent implements OnChanges, AfterViewInit {
  cd = inject(ChangeDetectorRef);
  devicesService = inject(DeviceService);

  @Input() id: any;
  @Input() jackpot: any;
  @Input() category: string;
  @Input() controls: boolean;
  @Output() toggleView: EventEmitter<any> = new EventEmitter();
  @Output() turnPlay: EventEmitter<any> = new EventEmitter();

  @ViewChild('swiper') swiperRef:
    | ElementRef<HTMLElement & { swiper?: Swiper } & { initialize: () => void }>
    | undefined;

  swiper?: Swiper;
  resizeObservable$: Observable<Event>;
  resizeSubscription$: Subscription;
  customMoveIndex: number;
  isGradientLeftAvailable = true;
  isGradientRightAvailable = true;
  isMobile = false;

  public title: string;

  constructor(public zone: NgZone) {
    this.isMobile = this.devicesService.isMobile();
  }

  ngOnChanges() {
    this.title = this.category ? this.category : '';
  }

  //eslint-disable-next-line
  ngAfterViewInit() {
    const config: SwiperOptions = {
      slidesPerView: 'auto',
      freeMode: true,
      allowTouchMove: !this.controls,
      spaceBetween: 10,
      navigation: {
        nextEl: `.swiper-button-next-${this.id}`,
        prevEl: `.swiper-button-prev-${this.id}`
      },
      observer: true
    };
    const swiperEl = Object.assign(this.swiperRef?.nativeElement, config);
    swiperEl.initialize();
    this.swiper = this.swiperRef?.nativeElement.swiper;
    this.swiper.on('slideChange', () => {
      this.cd.detectChanges();
    });
  }

  ngOnDestroy() {
    if (this.resizeSubscription$) {
      this.resizeSubscription$.unsubscribe();
    }
  }

  getSlideSize(gameType: string): string {
    if (gameType === 'Smallsquare' && !this.isMobile) {
      if (window.innerWidth >= 992) {
        return 'calc(100% / 7)';
      } else {
        return 'calc(100% / 5)';
      }
    } else if (gameType === 'Smallsquare' && this.isMobile) {
      return 'calc(100% / 3)';
    }
  }

  setGridOptions(innerWidth: number) {
    switch (true) {
      case innerWidth >= 1720:
        this.customMoveIndex = 5;
        break;
      case innerWidth <= 1720 && innerWidth >= 1240:
        this.customMoveIndex = 4;
        break;
      case innerWidth <= 1240:
        this.customMoveIndex = 3;
        break;
    }
  }

  changeSlide(option: string) {
    switch (option) {
      case 'prev':
        if (this.swiper.isBeginning) return;
        this.swiper.slideTo(this.swiper.activeIndex - 1);
        break;
      case 'next':
        if (this.swiper.isEnd) return;
        this.swiper.slideTo(this.swiper.activeIndex + 1);
        break;
    }
  }

  toggle(event, category) {
    this.toggleView.emit(category);
    event.preventDefault();
  }

  playGame(game) {
    this.turnPlay.emit({ game });
  }
}
