<!-- eslint-disable @angular-eslint/template/eqeqeq -->
<section *ngIf="swiperSliders?.length">
  <swiper-container #swiper init="false" class="swiper-container">
    <swiper-slide
      *ngFor="let slide of swiperSliders; let i=index"
      class="slider-banner"
      [ngClass]="{'countdownMundial2022': this.globalVars?.FEATURES?.bannerCountdownMundial2022 && i==1 && isSportSlider && canShowCountdown}"
    >
      <img [src]="slide" />
      <div
        class="countdown"
        *ngIf="this.globalVars?.FEATURES?.bannerCountdownMundial2022 && i==1 && isSportSlider && canShowCountdown"
      >
        <div class="contenedorReloj">
          <div class="contenido">
            <div class="digitos">
              <div id="dia1" class="contenedorDigito">{{daysbanner[0]}}</div>
              <div id="dia2" class="contenedorDigito">{{daysbanner[1]}}</div>
            </div>
            <div class="atextCenter">{{'days' | translate | uppercase}}</div>
          </div>
          <div class="puntos">:</div>
          <div class="contenido">
            <div class="digitos">
              <div id="hora1" class="contenedorDigito">{{hoursbanner[0]}}</div>
              <div id="hora2" class="contenedorDigito">{{hoursbanner[1]}}</div>
            </div>
            <div class="atextCenter">{{'hours' | translate | uppercase}}</div>
          </div>
          <div class="puntos">:</div>
          <div class="contenido">
            <div class="digitos">
              <div id="minutos1" class="contenedorDigito">{{minutesbanner[0]}}</div>
              <div id="minutos2" class="contenedorDigito">{{minutesbanner[1]}}</div>
            </div>
            <div class="atextCenter">{{'minutes' | translate | uppercase}}</div>
          </div>
          <div class="puntos">:</div>
          <div class="contenido">
            <div class="digitos">
              <div id="segundos1" class="contenedorDigito">{{secondsbanner[0]}}</div>
              <div id="segundos2" class="contenedorDigito">{{secondsbanner[1]}}</div>
            </div>
            <div class="atextCenter">{{'Segundos' | uppercase}}</div>
          </div>
        </div>
      </div>
    </swiper-slide>
  </swiper-container>
  <div class="swiper-pagination"></div>
  <button *ngIf="controls" class="swiper-button-prev slide-control prev" ion-button>
    <ion-icon name="chevron-back-outline"></ion-icon>
  </button>
  <button *ngIf="controls" class="swiper-button-next slide-control next" ion-button>
    <ion-icon name="chevron-forward-outline"></ion-icon>
  </button>
</section>
