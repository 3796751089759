import {
  ChangeDetectorRef,
  Directive,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  inject,
} from "@angular/core";
import { IonToggle } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { BehaviorSubject, Observable, Subscription } from "rxjs";

import { GlobalVarsModel, LicenseTypes } from "@models/index";
import { NewBaseService } from "@shared-providers/index";
import {
  C_OveraskData,
  C_OveraskDataBetPlacement,
  C_TicketApuestasNew,
  FilterItem,
  GreyhoundHorseBet,
  LineItem,
  PossibleSystemsItem,
  SmartLineItem,
  StakeGroup,
  StakeModel,
  TicketSelectionMode,
} from "@sports-models/index";

import { DeviceService } from "@shared-services/index";
import { BetSlipService, MobileBehaviorService } from "@sports-services/index";
import { ticketState } from "@sports-stores/ticket/index";
import { NewTicketFacade } from "@sports-stores/ticket/index";
import * as ticketSelector from "@sports-stores/ticket/ticket.selectors";
import { TicketMethods } from "@sports-utils/index";

@Directive()
export class Ticket implements OnInit {
  @Input() ticketState: ticketState;

  @Input() isIOSNative: boolean = false;
  @Input() isDYGames: boolean = false;
  @Output() dispatch: EventEmitter<any> = new EventEmitter();
  @Output() ticketOddAcceptance: EventEmitter<boolean> = new EventEmitter();
  @Output() iosScrollDisabled: EventEmitter<boolean> = new EventEmitter();

  @ViewChild("freebetToggle") freebetToggle: IonToggle;

  accordionHidden: boolean = false;
  betButton: string[] = ["background-color-primary", "border-color-primary"];
  btnBetText: string = "Realizar apuesta";
  currencyleft = false;
  currencySign: string;
  errorFB: string = "FreeBet no compatible";
  errorTicket: string;
  filters: FilterItem[];
  filterSelected: FilterItem;
  freebet: boolean = false;
  isDesktop: boolean = false;
  isMobile: boolean = false;
  keepedTicket: boolean = false;
  keyboard$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public disabled$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  disableElement: boolean = false;
  public disableCloseBet$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  license: LicenseTypes;
  lineItems: LineItem[];
  minBetValue: string;
  multiBetCollapsed: boolean = true;

  /* OVERASK */
  public overaskCountdown: number;
  overaskPolling$: Subscription;
  overaskInfo: C_OveraskData;

  overask$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  overaskTimeOut$: Observable<boolean>;
  timerCountdown = null;
  auxCloseBet: any;

  selectionBetType: TicketSelectionMode;
  smartLineItems: SmartLineItem[];
  stakeInput: number = 1;
  stakeLabel: string = "Apuesta";
  ticket: C_TicketApuestasNew;
  ticket$: BehaviorSubject<C_TicketApuestasNew> = new BehaviorSubject(null);
  timeOutSub$: Subscription;
  totalLocal: string = "";
  possibleSystems: PossibleSystemsItem[];
  systemSelected: PossibleSystemsItem;

  globalVars: GlobalVarsModel;
  newTicketFacade = inject(NewTicketFacade);
  store = inject(Store<ticketState>);
  mobileBehavior = inject(MobileBehaviorService);
  betSlipService = inject(BetSlipService);

  newBaseService = inject(NewBaseService);
  deviceService = inject(DeviceService);
  public cuota: string = "-";

  changeDetectorRef = inject(ChangeDetectorRef);

  constructor() {
    this.isDesktop = this.deviceService.isDesktop();
    this.isMobile = this.deviceService.isMobile();
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });
    this.minBetValue = this.globalVars.FEATURES.BetSlipLegMinValue;
    this.license = this.globalVars.licenseType;

    this.mobileBehavior.keyboard$.subscribe((value) => this.keyboard$.next(value));
    this.mobileBehavior.disableElement$.subscribe((value) => this.disabled$.next(value));
    this.mobileBehavior.disableElement$.subscribe((value) => (this.disableElement = value));
  }
  ngOnInit(): void {
    this.store.select("ticket").subscribe((ticketS: any) => {
      if (this.ticket$ !== undefined) {
        this.ticket$.next(ticketS.ticket);
      }

      this.ticketState = ticketS;
      this.ticket = ticketS.ticket;
      this.filters = ticketS.filters;
      this.filterSelected = ticketS.filterSelected;

      this.setPossibleSystems(ticketS);
      this.checkTicket();
      this.changeDetectorRef.detectChanges();
    });

    this.store.select(ticketSelector.getOverask).subscribe((overaskData: C_OveraskData) => {
      this.overaskInfo = overaskData;
      if (this.overaskInfo.Status === 0 && !this.newTicketFacade.overaskPolling) {
        this.auxCloseBet = this.ticket.OveraskCloseBet;
        this.overaskStart();
      }
      this.changeDetectorRef.detectChanges();
    });
    this.newTicketFacade.overaskCountdown$.subscribe((value) => (this.overaskCountdown = value));
  }

  checkTicket() {
    this.hasErrors();
    this.hasActiveFreebet();
    this.setTicketSelectionMode();
    this.setCurrencySign();
    this.setBetButtonStyle();
    this.accordionHidden =
      TicketMethods.getIsConfirmed(this.ticket) === undefined ? false : TicketMethods.getIsConfirmed(this.ticket);
    this.cuota = `${TicketMethods.getAmountBet(this?.ticket)}`;
  }

  clearTicket(): void {
    this.accordionHidden = false;
    this.stopOverask();
    this.newTicketFacade.removeTicket();
  }

  closeResume(): void {
    this.newTicketFacade.stopTimer();
    this.accordionHidden = false;
    this.newTicketFacade.removeTicket();
  }

  selectionsHidden() {
    return (
      !TicketMethods.getNumberOfSelections(this.ticket) ||
      this.ticket.OveraskInfo.Status === 2 ||
      (this.ticket.OveraskInfo.Status !== "OK" && !this.isDesktop) ||
      this.accordionHidden
    );
  }

  keepSelections() {
    if (!this.isDesktop) this.keepedTicket = true;
    this.accordionHidden = false;
    this.newTicketFacade.stopTimer();
    this.mobileBehavior.closeTicket();
    setTimeout(() => this.newTicketFacade.keepSelections(), 100);
  }

  removeSelection(item: LineItem): void {
    this.newTicketFacade.checkSelection(item, item.ResultsNr);
  }

  removeStraightMulticast(item: any) {
    this.newTicketFacade.removeMulticast(item.ItemId);
  }

  removeCombinationItem(item: any) {
    this.newTicketFacade.removeCombinationItem(item.ItemId);
  }

  removeSmartMarketSelection(item: SmartLineItem): void {
    this.newTicketFacade.removeSmartMarketItem(item.ItemId);
  }

  //updateLineItemAmount
  selectLineItem(lineItem: LineItem | SmartLineItem | GreyhoundHorseBet | Event) {
    if (lineItem instanceof Event) {
      // todo to fix compilation issue
    } else {
      this.newTicketFacade.setLineItemSelected(lineItem);
    }
  }

  unselectLineItem() {
    this.newTicketFacade.setLineItemUnselected();
  }

  updateSingleStake(event) {
    this.newTicketFacade.checkAndUpdateStake(event);
  }

  updateStake(event) {
    this.newTicketFacade.updateStake(event);
  }

  checkAndUpdateStake(event) {
    this.newTicketFacade.checkAndUpdateStake(event);
  }

  acceptErrorAndContinue(): void {
    this.newTicketFacade.SetOddErrors();
  }

  hasActiveFreebet() {
    this.freebet =
      !!this.ticketState && this.ticketState.ticket && TicketMethods.getHasFreebet(this.ticketState.ticket);

    if (this.freebetToggle) {
      this.freebetToggle.checked = this.freebet;
    }
    return this.freebet;
  }

  placeBet(): void {
    this.newTicketFacade.placeBet();
  }

  presentOddAcceptance(): void {
    this.newTicketFacade.presentOddAcceptance();
  }

  closeKeyboard(e: any) {
    if (!e) {
      this.newTicketFacade.closeKeyboard();
      this.mobileBehavior.closeKeyboard(true);
      const betAmount = this.checkMinBetAmount(e);
      this.newTicketFacade.okPressKeyboard(betAmount);
      this.mobileBehavior.closeKeyboard(true);
    }
  }

  public checkMinBetAmount(e: string) {
    let amount;
    if (this.ticket) {
      switch (this.ticket.BetType) {
        case 2: // Multi
          amount = this.setSystemAmountController(e);
          break;
        case 1: // Acumulator
        default: // Singles
          amount = this.setSingleAmountController(e);
          break;
      }
      return amount;
    }
  }

  setSingleAmountController(inputAmount: string) {
    const amount =
      +inputAmount > +this.globalVars.FEATURES.BetSlipLegMinValue
        ? inputAmount.toString()
        : this.globalVars.FEATURES.BetSlipLegMinValue;
    return amount;
  }

  setSystemAmountController(inputAmount: string) {
    const minValue = this.ticket.SystemSelected.numberOfBetWays * +this.globalVars.FEATURES.BetSlipLegMinValue;
    const amount = +inputAmount > minValue ? inputAmount.toString() : minValue.toString();
    return amount;
  }

  toggleTotalLocal() {
    if (TicketMethods.getHasFreebet(this.ticket)) {
      return;
    }
    this.mobileBehavior.toggleKeyboard();
    this.unselectLineItem();
  }

  isNumber(val: any): boolean {
    return typeof val === "number";
  }

  trackBy(i, item) {
    return item.Id;
  }

  getElementDisabled() {
    if (this.ticket !== undefined) {
      if (this.isDesktop) {
        return TicketMethods.getHasFreebet(this.ticket);
      }
      return this.keyboard$.getValue() || TicketMethods.getHasFreebet(this.ticket);
    } else {
      return false;
    }
  }

  systemId() {
    let systemId: number;

    switch (this.ticketState.ticket.BetType) {
      case 0:
        systemId = 1;
        break;
      case 1:
        systemId = this.ticketState.ticket.StakeGroups.Accumulator.systemId;
        break;
      case 2:
        systemId = this.ticketState.ticket.StakeGroups.System.systemId;
        break;
      default:
        break;
    }
    return systemId;
  }

  getAllLineItems() {
    return this.ticket.LineItems.length + this.ticket.SmartLineItems.length;
  }

  toggleConfirmation() {
    this.accordionHidden = !this.accordionHidden;
  }

  /* From app.component */
  public updateAmount(value: string) {
    this.newTicketFacade.updateAmountLocal(value);
  }

  public changeBetType({ value }): void {
    this.newTicketFacade.changeFilterSelected(value);
  }

  public changeInMultipleBetOptions(optionSelection): void {
    this.newTicketFacade.setNewMultipleSelection(optionSelection);
    this.toggleMultipleBetOptions(false);
  }

  public toggleMultipleBetOptions(isCollapsed: boolean) {
    this.multiBetCollapsed = !isCollapsed;
  }

  toggleFreebet() {
    if (this.keyboard$.value) return;
    this.freebet ? this.newTicketFacade.cancelFreebet() : this.newTicketFacade.toggleFreebet();
  }

  // CHECK TICKET

  hasErrors() {
    if (!this.ticketState || !this.ticketState.ticket) return;

    const { OrderErrors } = this.ticketState.ticket;
    const { OrderErrorMessages } = this.ticketState.ticket;

    if (Object.keys(OrderErrors).length > 0) {
      const objCode = Object.keys(OrderErrors);
      switch (objCode[0]) {
        case "51934":
          this.errorTicket = "Ninguna de las apuestas ha sido confirmada";
          break;
        case "NotYetValidated":
          this.errorTicket = "Freebet no compatible";
          break;
        default:
          this.errorTicket = OrderErrorMessages[0];
          break;
      }
    } else {
      this.errorTicket = "";
    }
    return Boolean(this.errorTicket);
  }

  hasFBError() {
    return TicketMethods.getHasFBErrors(this.ticketState.ticket);
  }

  setTicketSelectionMode() {
    if (!this.ticketState || !this.ticketState.ticket) {
      this.selectionBetType = 0;
      return;
    }
    this.selectionBetType =
      this.ticketState.ticket.BetType === 0
        ? TicketMethods.getNumberOfSelections(this.ticketState.ticket) > 1
          ? -1
          : this.ticketState.ticket.BetType
        : this.ticketState.ticket.BetType;
  }

  hideFreebetToggle() {
    if (!this.ticketState || !this.ticketState.ticket) return;

    let hideFBToggle = true;

    switch (this.ticketState.ticket.BetType) {
      case 0:
        hideFBToggle = TicketMethods.getNumberOfSelections(this.ticketState.ticket) > 1;
        break;
      case 1:
        hideFBToggle = false;
        break;
      case 2:
        hideFBToggle = true;
        break;
    }

    if (this.hasErrors()) hideFBToggle = false;

    return hideFBToggle;
  }

  setCurrencySign() {
    switch (this.license) {
      case LicenseTypes.PaisVasco:
      case LicenseTypes.Nacional:
      case LicenseTypes.Madrid:
        this.currencySign = "€";
        break;
      case LicenseTypes.Mexico:
      case LicenseTypes.Colombia:
      case LicenseTypes.Panama:
      case LicenseTypes.ArgentinaCaba:
      case LicenseTypes.ArgentinaPBA:
      case LicenseTypes.ArgentinaMendoza:
        this.currencyleft = true;
        this.currencySign = "$";
        break;
      default:
        this.currencySign = "€";
        break;
    }
  }

  setBetButtonStyle() {
    if (!!this.ticketState && this.ticketState.ticket) {
      if (TicketMethods.getHasFreebet(this.ticketState.ticket)) {
        this.betButton = ["background-color-secondary", "border-color-secondary"];
        this.btnBetText = "Realizar Freebet";
      } else {
        this.betButton = ["background-color-primary", "border-color-primary"];
        this.btnBetText = "Realizar apuesta";
      }
    }
  }

  setPossibleSystems(ticketSt) {
    this.systemSelected = ticketSt.ticket.SystemSelected;
    this.possibleSystems = [];
    if (ticketSt.ticket.StakeGroups.System.possibleSystems && this.systemSelected)
      this.possibleSystems = ticketSt.ticket.StakeGroups.System.possibleSystems.filter(
        (i) => i.systemId !== this.systemSelected.systemId,
      );
  }

  // OVERASK
  overaskStart() {
    this.newTicketFacade.overaskStart();

    this.overaskPolling$ = this.newTicketFacade
      .overaskPollingSvc(this.overaskInfo.RejectId)
      .subscribe((overaskData: C_OveraskDataBetPlacement) => {
        const status = this.getOveraskStatus(overaskData);
        switch (status) {
          case 0: // Aprobación Requerida
            return;
          case 1: // Apuesta Máxima Posible
            const stake: StakeModel = {
              Group: "",
              IsEachWay: false,
              OverallStake: overaskData.MaxStake,
            };
            switch (this.ticket.BetType) {
              case 0:
                stake.Group = StakeGroup.SINGLES;
                stake.ItemId = 1;
                break;
              case 1:
                stake.Group = StakeGroup.ACCUMULATOR;
                stake.SystemId = this.ticket.StakeGroups.Accumulator.systemId;
                break;
              case 2:
                stake.Group = this.ticket.SystemSelected.groupName;
                stake.SystemId = this.ticket.StakeGroups.System.systemId;
                break;
              default:
                break;
            }

            this.newTicketFacade.initOveraskTimeOut(overaskData, status);
            this.overaskPolling$.unsubscribe();
            this.newTicketFacade.overaskMaxStake(overaskData, status, stake);
            break;
          case 3: // No se ha podido realizar
            this.overaskPolling$.unsubscribe();
            this.newTicketFacade.overaskRejected(overaskData, status);
            break;
          default:
            this.overaskPolling$.unsubscribe();
            this.newTicketFacade.overaskCloseBet();
            break;
        }
      });
  }

  getOveraskStatus(overaskData: C_OveraskDataBetPlacement) {
    let status: number | string = 0;
    const StakeCt = this.auxCloseBet;
    if (overaskData.Finished) {
      if (overaskData.Rejected) {
        status = 3;
      } else {
        if (overaskData.Approved) status = overaskData.MaxStake > 0 && overaskData.MaxStake < StakeCt ? 1 : "OK";
      }
    }
    return status;
  }

  stopOverask() {
    this.newTicketFacade.stopTimer();
    this.newTicketFacade.overaskStop();
    if (this.overaskPolling$) this.overaskPolling$.unsubscribe();
  }

  getTicketNumberOfSelections() {
    return TicketMethods.getNumberOfSelections(this.ticket);
  }

  /**
   * Ticket Methods
   */
  isConfirmed = (): boolean => TicketMethods.getIsConfirmed(this.ticket);

  hasFreebet = (): boolean => TicketMethods.getHasFreebet(this.ticket);
}
