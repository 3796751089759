<!-- eslint-disable @angular-eslint/template/eqeqeq -->
<ng-container *ngIf="!isMobile; else mobileView">
  <ion-content [ngSwitch]="steps" class="background-color-muted">
    <div class="is-narrow-content">
      <user-section-title [sectionTitle]="'GetCardUnlock'"></user-section-title>

      <ion-toolbar class="subHeaderSteps">
        <ion-segment [(ngModel)]="steps">
          <ion-segment-button
            [ngClass]="{enabledButton: steps=='step1', disabledButton: steps!='step1'}"
            disabled
            tappable="false"
            value="step1"
          >
            <ion-avatar>
              <span>1</span>
            </ion-avatar>
            <div class="squareArr"><i></i></div>
          </ion-segment-button>
          <div class="sepsegment" tappable="false"></div>
          <ion-segment-button
            [ngClass]="{enabledButton: steps=='step2', disabledButton: steps!='step2'}"
            class=""
            disabled
            tappable="true"
            value="step2"
          >
            <ion-avatar>
              <span>2</span>
            </ion-avatar>
            <div class="squareArr"><i></i></div>
          </ion-segment-button>
        </ion-segment>
      </ion-toolbar>

      <form scroll="true">
        <ion-list class="card-list" *ngSwitchCase="'step1'">
          <ion-list-header>
            <ion-avatar><i class="codere-icon icon-cardPin"></i></ion-avatar>
            <ion-label>
              <span [innerHTML]="'GetCardUnlockSecurity' | translate"
                >Por motivos de seguridad, para poder desbloquear tu tarjeta indica la contraseña de tu cuenta
                online.</span
              >
            </ion-label>
          </ion-list-header>

          <div class="myWrapInput">
            <ion-item lines="none" class="cdr-item-input">
              <ion-input
                label="{{'GetCardpassonline' | translate}}"
                labelPlacement="floating"
                [type]="showPassword['password'] ? 'text' : 'password'"
                autocomplete="off"
                autoCorrect="off"
                spellcheck="off"
                maxlength="50"
                [formControl]="$any(myForm1.controls['password'])"
              ></ion-input>
              <ion-icon
                [name]="showPassword['password'] ? 'eye-off' : 'eye'"
                (click)="toggleTypeInput('password')"
              ></ion-icon>
            </ion-item>
            <!-- todo: revisar  (click)="toggleInput(pwd)" -->
            <div
              class="labelError"
              [hidden]="myForm1.controls['password'].valid || !myForm1.controls['password'].touched"
            >
              <span> {{'error_form_requiered' | translate}}</span>
            </div>
          </div>

          <div class="sendForm">
            <ion-button expand="full" (click)="checkPassword()"
              >{{'tCont' | translate | uppercase}}<!--CONTINUAR--></ion-button
            >
          </div>
        </ion-list>

        <ion-list class="card-list" *ngSwitchCase="'step2'">
          <ion-list-header>
            <ion-avatar><i class="codere-icon icon-cardPin"></i></ion-avatar>
            <ion-label>
              <span [innerHTML]="'GetCardNewPin' | translate">Indica un nuevo Pin para tu tarjeta</span>
            </ion-label>
          </ion-list-header>
          <div class="myWrapInput">
            <ion-item lines="none" class="cdr-item-input">
              <ion-input
                label="PIN"
                labelPlacement="floating"
                [type]="showPassword['newPin'] ? 'text' : 'password'"
                [formControl]="$any(myForm2.controls['newPin'])"
                autocomplete="off"
                autoCorrect="off"
                spellcheck="off"
              ></ion-input>
              <ion-icon
                [name]="showPassword['newPin'] ? 'eye-off' : 'eye'"
                (click)="toggleTypeInput('newPin')"
              ></ion-icon>
            </ion-item>
            <!-- todo: revisar  (click)="toggleInput(pin1)" -->
            <div class="labelError" [hidden]="myForm2.controls['newPin'].valid || !myForm2.controls['newPin'].touched">
              <span *ngIf="myForm2.controls['newPin'].hasError('required')">
                {{'v2_pinObl' | translate}}<!--# El pin es obligatorio--><br
              /></span>
              <span *ngIf="myForm2.controls['newPin'].hasError('pattern')">
                {{'v2_pinNum' | translate}}<!--# El pin debe contener sólo números--><br
              /></span>
              <span *ngIf="myForm2.controls['newPin'].hasError('maxlength')">
                {{'v2_pinNum1' | translate}}<!--# El pin debe contener 4 números--><br
              /></span>
              <span *ngIf="myForm2.controls['newPin'].hasError('minlength')">
                {{'v2_pinNum1' | translate}}<!--# El pin debe contener 4 números--><br
              /></span>
            </div>
          </div>
          <div class="myWrapInput">
            <ion-item lines="none" class="cdr-item-input">
              <!--REPETIR PIN-->
              <ion-input
                label="{{'GetCardRepeatPin' | translate}}"
                labelPlacement="floating"
                autocomplete="off"
                autoCorrect="off"
                spellcheck="off"
                [type]="showPassword['reNewPin'] ? 'text' : 'password'"
                [formControl]="$any(myForm2.controls['reNewPin'])"
              ></ion-input>
              <ion-icon
                [name]="showPassword['reNewPin'] ? 'eye-off' : 'eye'"
                (click)="toggleTypeInput('reNewPin')"
              ></ion-icon>
            </ion-item>
            <div
              class="labelError"
              [hidden]="myForm2.controls['reNewPin'].valid || !myForm2.controls['reNewPin'].touched"
            >
              <span *ngIf="myForm2.controls['newPin'].hasError('required')">
                {{'v2_pinObl' | translate}}<!--# El pin es obligatorio--><br
              /></span>
              <span *ngIf="myForm2.controls['newPin'].hasError('pattern')">
                {{'v2_pinNum' | translate}}<!--# El pin debe contener sólo números--><br
              /></span>
              <span *ngIf="myForm2.controls['newPin'].hasError('maxlength')">
                {{'v2_pinNum1' | translate}}<!--# El pin debe contener 4 números--><br
              /></span>
              <span *ngIf="myForm2.controls['newPin'].hasError('minlength')">
                {{'v2_pinNum1' | translate}}<!--# El pin debe contener 4 números--><br
              /></span>
              <span *ngIf="myForm2.controls['reNewPin'].hasError('same')">
                {{'v2_errorPin' | translate}}<!--# El pin no coincide--><br
              /></span>
            </div>
          </div>

          <div class="sendForm">
            <ion-button expand="full" (click)="setPin()"
              >{{'tCont' | translate | uppercase}}<!--CONTINUAR--></ion-button
            >
          </div>
        </ion-list>
      </form>
    </div>

    <codere-footer></codere-footer>
  </ion-content>
</ng-container>

<ng-template #mobileView>
  <ion-header>
    <user-section-title [sectionTitle]="'GetCardUnlock'"></user-section-title>
  </ion-header>

  <ion-content [ngSwitch]="steps" class="background-color-muted">
    <ion-toolbar class="subHeaderSteps">
      <ion-segment [(ngModel)]="steps">
        <ion-segment-button
          [ngClass]="{enabledButton: steps=='step1', disabledButton: steps!='step1'}"
          disabled
          tappable="false"
          value="step1"
          mode="md"
        >
          <ion-avatar>
            <span>1</span>
          </ion-avatar>
          <div class="squareArr"><i></i></div>
        </ion-segment-button>
        <div class="sepsegment" tappable="false"></div>
        <ion-segment-button
          [ngClass]="{enabledButton: steps=='step2', disabledButton: steps!='step2'}"
          class=""
          disabled
          tappable="true"
          value="step2"
          mode="md"
        >
          <ion-avatar>
            <span>2</span>
          </ion-avatar>
          <div class="squareArr"><i></i></div>
        </ion-segment-button>
      </ion-segment>
    </ion-toolbar>

    <form scroll="true">
      <ion-list class="card-list" *ngSwitchCase="'step1'">
        <ion-list-header>
          <ion-avatar><i class="codere-icon icon-cardPin"></i></ion-avatar>
          <ion-label>
            <span [innerHTML]="'GetCardUnlockSecurity' | translate"
              >Por motivos de seguridad, para poder desbloquear tu tarjeta indica la contraseña de tu cuenta
              online.</span
            >
          </ion-label>
        </ion-list-header>

        <div class="myWrapInput">
          <ion-item lines="none" class="cdr-item-input">
            <ion-input
              label="{{'GetCardpassonline' | translate}}"
              labelPlacement="floating"
              [type]="showPassword['password'] ? 'text' : 'password'"
              autocomplete="off"
              autoCorrect="off"
              spellcheck="off"
              maxlength="50"
              [formControl]="$any(myForm1.controls['password'])"
            ></ion-input>
            <ion-icon
              [name]="showPassword['password'] ? 'eye-off' : 'eye'"
              (click)="toggleTypeInput('password')"
            ></ion-icon>
          </ion-item>
          <!-- todo: revisar  (click)="toggleInput(pwd)" -->
          <div
            class="labelError"
            [hidden]="myForm1.controls['password'].valid || !myForm1.controls['password'].touched"
          >
            <span> {{'error_form_requiered' | translate}}</span>
          </div>
        </div>

        <div class="sendForm">
          <ion-button expand="full" (click)="checkPassword()"
            >{{'tCont' | translate | uppercase}}<!--CONTINUAR--></ion-button
          >
        </div>
      </ion-list>

      <ion-list class="card-list" *ngSwitchCase="'step2'">
        <ion-list-header>
          <ion-avatar><i class="codere-icon icon-cardPin"></i></ion-avatar>
          <ion-label>
            <span [innerHTML]="'GetCardNewPin' | translate">Indica un nuevo Pin para tu tarjeta</span>
          </ion-label>
        </ion-list-header>
        <div class="myWrapInput">
          <ion-item lines="none" class="cdr-item-input">
            <ion-input
              label="PIN"
              labelPlacement="floating"
              [type]="showPassword['newPin'] ? 'text' : 'password'"
              [formControl]="$any(myForm2.controls['newPin'])"
              autocomplete="off"
              autoCorrect="off"
              spellcheck="off"
            ></ion-input>
            <ion-icon
              [name]="showPassword['newPin'] ? 'eye-off' : 'eye'"
              (click)="toggleTypeInput('newPin')"
            ></ion-icon>
          </ion-item>
          <!-- todo: revisar  (click)="toggleInput(pin1)" -->
          <div class="labelError" [hidden]="myForm2.controls['newPin'].valid || !myForm2.controls['newPin'].touched">
            <span *ngIf="myForm2.controls['newPin'].hasError('required')">
              {{'v2_pinObl' | translate}}<!--# El pin es obligatorio--><br
            /></span>
            <span *ngIf="myForm2.controls['newPin'].hasError('pattern')">
              {{'v2_pinNum' | translate}}<!--# El pin debe contener sólo números--><br
            /></span>
            <span *ngIf="myForm2.controls['newPin'].hasError('maxlength')">
              {{'v2_pinNum1' | translate}}<!--# El pin debe contener 4 números--><br
            /></span>
            <span *ngIf="myForm2.controls['newPin'].hasError('minlength')">
              {{'v2_pinNum1' | translate}}<!--# El pin debe contener 4 números--><br
            /></span>
          </div>
        </div>
        <div class="myWrapInput">
          <ion-item lines="none" class="cdr-item-input">
            <!--REPETIR PIN-->
            <ion-input
              label="{{'GetCardRepeatPin' | translate}}"
              labelPlacement="floating"
              autocomplete="off"
              autoCorrect="off"
              spellcheck="off"
              [type]="showPassword['reNewPin'] ? 'text' : 'password'"
              [formControl]="$any(myForm2.controls['reNewPin'])"
            ></ion-input>
            <ion-icon
              [name]="showPassword['reNewPin'] ? 'eye-off' : 'eye'"
              (click)="toggleTypeInput('reNewPin')"
            ></ion-icon>
          </ion-item>
          <div
            class="labelError"
            [hidden]="myForm2.controls['reNewPin'].valid || !myForm2.controls['reNewPin'].touched"
          >
            <span *ngIf="myForm2.controls['newPin'].hasError('required')">
              {{'v2_pinObl' | translate}}<!--# El pin es obligatorio--><br
            /></span>
            <span *ngIf="myForm2.controls['newPin'].hasError('pattern')">
              {{'v2_pinNum' | translate}}<!--# El pin debe contener sólo números--><br
            /></span>
            <span *ngIf="myForm2.controls['newPin'].hasError('maxlength')">
              {{'v2_pinNum1' | translate}}<!--# El pin debe contener 4 números--><br
            /></span>
            <span *ngIf="myForm2.controls['newPin'].hasError('minlength')">
              {{'v2_pinNum1' | translate}}<!--# El pin debe contener 4 números--><br
            /></span>
            <span *ngIf="myForm2.controls['reNewPin'].hasError('same')">
              {{'v2_errorPin' | translate}}<!--# El pin no coincide--><br
            /></span>
          </div>
        </div>

        <div class="sendForm">
          <ion-button expand="full" (click)="setPin()">{{'tCont' | translate | uppercase}}<!--CONTINUAR--></ion-button>
        </div>
      </ion-list>
    </form>

    <codere-footer></codere-footer>
  </ion-content>
</ng-template>
