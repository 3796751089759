import { Component, ElementRef, EventEmitter, HostBinding, Input, Output, SimpleChanges } from "@angular/core";

import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";
import { FormatOdd } from "@pipes/formatOdd.pipe";
import { ParseStakeMoney } from "@pipes/parseStakeMoney";
import { SBBetslipStakeComponent } from "@sports-components/index";
import { TicketSelectionMode } from "@sports-models/index";
import { SmartLineItem } from "@sports-models/index";
import { C_TicketApuestasNew } from "@sports-models/index";
import { TicketMethods } from "@sports-utils/index";

@Component({
  selector: "ticket-betbuilder",
  templateUrl: "./ticket-betbuilder.html",
  styleUrls: ["./ticket-betbuilder.scss"],
  standalone: true,
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    FormatOdd,
    TranslateModule,
    ParseStakeMoney,
    SBBetslipStakeComponent,
  ],
})
export class TicketBetbuilderComponent {
  @Input() smartLineItem: SmartLineItem;
  @Input() mode: TicketSelectionMode;
  @Input() isTicketClosed: boolean = false;
  @Input() isDesktop: boolean;
  @Input() license: number;
  @Input() minBetValue: string;
  @Input() currency: string;
  @Input() keyboard: boolean;
  @Input() ticket: C_TicketApuestasNew;
  @Input() disabled: boolean;
  @Output() updateAmount: EventEmitter<any> = new EventEmitter();
  @Output() selectElement: EventEmitter<any> = new EventEmitter();
  @Output() unselect: EventEmitter<any> = new EventEmitter();
  @Output() deleteSmartMarket: EventEmitter<SmartLineItem> = new EventEmitter();
  @Output() scrollTo: EventEmitter<any> = new EventEmitter();
  keyEvent: EventEmitter<any> = new EventEmitter();
  potentialWin: any;
  currencyLeft: boolean = false;

  constructor(private _host: ElementRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (!this.smartLineItem) return;
    this.potentialWin = (this.smartLineItem.AmountLocal * this.smartLineItem.Odd).toFixed(2);
  }

  ngAfterViewInit() {
    this.currencyLeft = this.currency === "$";
  }

  public remove() {
    this.deleteSmartMarket.emit(this.smartLineItem);
  }

  public selectAmount() {
    if (!this.smartLineItem) return;
    if (this.smartLineItem.Selected && this.keyboard) return;
    this.selectElement.emit(this.smartLineItem);
    this.scrollTo.emit(this._host.nativeElement.offsetTop);
  }

  public unselectLineItem() {
    if (!this.smartLineItem) return;
    this.unselect.emit(this.smartLineItem);
  }

  public updateStake(event: any) {
    this.selectAmount();
    this.updateAmount.emit(event);
  }

  get multiSingleActivated() {
    return (
      this.ticket.BetType === 0 && TicketMethods.getNumberOfSelections(this.ticket) >= 2 && !this.isTicketClosed
    );
  }

  @HostBinding("attr.mode")
  get selectionState() {
    if (!this.smartLineItem) return;
    let state = null;
    const lineErrors = this.smartLineItem.LineErrorMessages || [];
    if (lineErrors.length) {
      state = "selection-error";
    }

    if (this.smartLineItem.GameLocked) {
      state = "selection-locked";
    }

    return state;
  }

  public getIsProcessing(): boolean {
    return this.ticket.processing;
  }

  getTicketSelectionMode() {
    let state = "";
    const lineErrors = this.smartLineItem.LineErrorMessages || [];
    if (lineErrors.length) {
      state = "selection-error";
    }

    if (this.smartLineItem.GameLocked) {
      state = "selection-locked";
    }

    if (this.smartLineItem["NotAvailable"]) {
      state = "selection-not-available";
    }

    return state;
  }
}
