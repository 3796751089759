<!-- eslint-disable @angular-eslint/template/eqeqeq -->
<div class="containerP padding20 texto">
  <div *ngIf="globalVars.LICENSE_TYPE ===21  || globalVars.LICENSE_TYPE ===22">
    <div class="is-bold title">{{'selfExclusionP2COtitle01' | translate | uppercase}}</div>
    <div class="margin15TB selfExclusiontx">{{'selfExclusionP2COtxt01' | translate}}</div>
    <div class="margin15TBCO" *ngIf="globalVars.LICENSE_TYPE ===21">{{'selfExclusionP2COtxt02' | translate}}</div>
    <div
      [ngClass]="{
      'margin15TBCO': globalVars.LICENSE_TYPE ===21,
      'margin15TBPA': globalVars.LICENSE_TYPE ===22
    }"
      class="is-bold title"
    >
      {{'selfExclusionP2COtitle02' | translate | uppercase}}
    </div>
  </div>
  <div class="txtcenter is-bold title" *ngIf="globalVars.LICENSE_TYPE !==21 && this.globalVars.LICENSE_TYPE !==22">
    {{'selfExclusionP2times' | translate}}
  </div>
  <ion-radio-group [(ngModel)]="timeExclusion" *ngIf="this.globalVars.LICENSE_TYPE !==21" ngDefaultControl>
    <ion-row class="ion-justify-content-center margin15TB">
      <ion-col class="ion-no-padding" [size]="item.size" *ngFor="let item of dateOptions">
        <ion-item class="optItem" lines="none">
          <ion-radio [value]="item.value" labelPlacement="end" mode="md">
            <span
              >{{item.literal | translate}}<span *ngIf="this.globalVars.LICENSE_TYPE ===0">**</span></span
            ></ion-radio
          >
        </ion-item>
      </ion-col>
    </ion-row>
    <ion-row class="ion-justify-content-center margin15TB">
      <ion-col size="auto" class="txtcenter">
        <ion-item class="optItem" lines="none">
          <ion-radio value="intervalodate" class="txtcenter" labelPlacement="end" mode="md">
            <span>{{'selfExclusionP2tx01' | translate}}</span></ion-radio
          >
        </ion-item>
      </ion-col>
    </ion-row>
  </ion-radio-group>
  <div class="marginTopM20" *ngIf="timeExclusion === 'intervalodate'">
    <ion-list class="listselect">
      <div class="" [class.has-error]="!validDate">
        <div class="title txtcenter is-bold margint10">{{'selfExclusionP2tx02' | translate}}</div>
        <ion-row class="ion-justify-content-center intervalodate">
          <ion-col [size]="isMobile ? 3 : 3">
            <ion-item lines="none">
              <ion-select
                class="daySelect"
                cancelText="{{'tCancel'|translate}}"
                [(ngModel)]="selfEx.day"
                [formControl]="$any(myForm).controls['day']"
                interface="alert"
                [placeholder]="'rDay' | translate"
                mode="md"
                [interfaceOptions]="{ header: 'rDay' | translate, size: 'auto', mode: undefined }"
                toggleIcon="chevron-down-outline"
              >
                <ion-select-option *ngFor="let day of dayMonth" [value]="day">{{day}}</ion-select-option>
              </ion-select>
            </ion-item>
          </ion-col>
          <ion-col [size]="isMobile ? 6 : 6">
            <ion-item lines="none">
              <ion-select
                cancelText="{{'tCancel'|translate}}"
                [(ngModel)]="selfEx.month"
                [formControl]="$any(myForm).controls['month']"
                interface="alert"
                [placeholder]="'rMonth' | translate"
                mode="md"
                [interfaceOptions]="{ header: 'rMonth' | translate, size: 'auto', mode: undefined }"
                toggleIcon="chevron-down-outline"
              >
                <ion-select-option *ngFor="let month of months" value="{{month.data}}"
                  >{{month.label}}</ion-select-option
                >
              </ion-select>
            </ion-item>
          </ion-col>
          <ion-col size="3">
            <ion-item lines="none">
              <ion-input
                class="step2-se-input"
                type="number"
                class=""
                [(ngModel)]="selfEx.year"
                [formControl]="$any(myForm).controls['year']"
                placeholder="Año"
              ></ion-input>
            </ion-item>
          </ion-col>
          <ion-col class="" size="12">
            <div
              [hidden]="!checkedDate || checkedDate && (validDate || !myForm.controls['day'].touched || !myForm.controls['month'].touched || !myForm.controls['year'].touched)"
              class="labelError"
            >
              <span>
                {{textError | translate}}
                <!--Por favor introduce una fecha válida.-->
              </span>
            </div>
          </ion-col>
          <ion-col size="12">
            <div class="txtcenter txtdura" *ngIf="isMobile">{{'selfExclusionP2tx03' | translate}}</div>
          </ion-col>
        </ion-row>
      </div>
    </ion-list>
  </div>
  <div *ngIf="timeExclusion === 'COSelfexclusion'">
    <ion-row class="selfexcco contRepass">
      <ion-col class="containerTogInpt" [size]="isMobile ? 12 : 4">
        <ion-row class="alignCenter">
          <ion-col class="toggleContainerCo" size="3">
            <ion-toggle class="button" [formControl]="$any(myFormCo).controls['toggleDay']"></ion-toggle>
          </ion-col>
          <ion-col [size]="isMobile ? 6 : 7">
            <ion-input
              class="step2-se-input"
              type="number"
              [(ngModel)]="selfEx.day"
              [formControl]="$any(myFormCo).controls['day']"
              [placeholder]="'days' | translate"
              [attr.disabled]="!myFormCo.controls['toggleDay'].value"
              [disabled]="myFormCo.controls['day'].disabled"
              [label]="'days' | translate"
              labelPlacement="floating"
              fill="solid"
              color="primary"
              [ngClass]="{ 'disabled': myFormCo.controls['day'].disabled }"
            >
            </ion-input>
          </ion-col>
        </ion-row>
      </ion-col>
      <ion-col
        class="containerTogInpt"
        [size]="isMobile ? 12 : 4"
        *ngIf="globalVars.FEATURES.MSO_NewLogicSelfexclusionCo"
      >
        <ion-row class="alignCenter">
          <ion-col class="toggleContainerCo" size="3">
            <ion-toggle class="button" [formControl]="$any(myFormCo).controls['toggleWeek']"></ion-toggle>
          </ion-col>
          <ion-col [size]="isMobile ? 6 : 7">
            <ion-input
              class="step2-se-input"
              type="number"
              [(ngModel)]="selfEx.week"
              [formControl]="$any(myFormCo).controls['week']"
              [placeholder]="'weeks' | translate"
              [attr.disabled]="!myFormCo.controls['toggleWeek'].value"
              [disabled]="myFormCo.controls['week'].disabled"
              [label]="'weeks' | translate"
              labelPlacement="floating"
              fill="solid"
              color="primary"
              [ngClass]="{ 'disabled': myFormCo.controls['week'].disabled }"
            >
            </ion-input>
          </ion-col>
        </ion-row>
      </ion-col>
      <ion-col class="containerTogInpt" [size]="isMobile ? 12 : 4">
        <ion-row class="alignCenter">
          <ion-col class="toggleContainerCo" size="3">
            <ion-toggle class="button" [formControl]="$any(myFormCo).controls['toggleMonth']"></ion-toggle>
          </ion-col>
          <ion-col [size]="isMobile ? 6 : 7">
            <ion-input
              class="step2-se-input"
              type="number"
              [(ngModel)]="selfEx.month"
              [formControl]="$any(myFormCo).controls['month']"
              [placeholder]="'months' | translate"
              [attr.disabled]="!myFormCo.controls['toggleMonth'].value"
              [disabled]="myFormCo.controls['month'].disabled"
              [label]="'months' | translate"
              labelPlacement="floating"
              fill="solid"
              color="primary"
              [ngClass]="{ 'disabled': myFormCo.controls['month'].disabled }"
            >
            </ion-input>
          </ion-col>
        </ion-row>
      </ion-col>
      <ion-col
        class="containerTogInpt"
        [size]="isMobile ? 12 : 4"
        *ngIf="!globalVars.FEATURES.MSO_NewLogicSelfexclusionCo"
      >
        <ion-row class="alignCenter">
          <ion-col class="toggleContainerCo" size="3">
            <ion-toggle class="button" [formControl]="$any(myFormCo).controls['toggleYear']"></ion-toggle>
          </ion-col>
          <ion-col [size]="isMobile ? 6 : 7">
            <ion-input
              class="step2-se-input"
              type="number"
              [(ngModel)]="selfEx.year"
              [formControl]="$any(myFormCo).controls['year']"
              [placeholder]="'years' | translate"
              [attr.disabled]="!myFormCo.controls['toggleYear'].value"
              [disabled]="myFormCo.controls['year'].disabled"
              [label]="'years' | translate"
              labelPlacement="floating"
              fill="solid"
              color="primary"
              [ngClass]="{ 'disabled': myFormCo.controls['year'].disabled }"
            >
            </ion-input>
          </ion-col>
        </ion-row>
      </ion-col>
    </ion-row>
  </div>
  <div *ngIf="this.globalVars.LICENSE_TYPE ===21">{{'selfExclusionP2COtxt03' | translate}}</div>
  <div class="txtcenter btnContainer">
    <!-- Spain and Pánama -->
    <ion-button [disabled]="isValid" (click)="goToStep(3)" class="btnorange" [class.marginr15]="isMobile" shape="round">
      {{'tCont' | translate}}
      <span class="codere-icon icon-arrow-right"></span>
    </ion-button>

    <!-- Exit -->
    <ion-button (click)="backToHome()" class="btnwhite" [class.paddinglr30]="isMobile" shape="round">
      {{'tCancel' | translate }}
    </ion-button>
  </div>
  <!-- Conditions -->
  <ng-container *ngIf="globalVars.LICENSE_TYPE !==21">
    <div class="txtcenter txtdura" *ngIf="isDesktop">{{selfExclusionP2tx03}}</div>
    <div class="txtcenter txtdura padding4" *ngIf="showSelfExclusionP2tx04">{{'selfExclusionP2tx04' | translate}}</div>
    <div class="txtcenter txtdura padding4" *ngIf="showSelfExclusionP2tx05">{{'selfExclusionP2tx05' | translate}}</div>
  </ng-container>
</div>
