<div class="casino-card-slots--preloader heartbeat-animation background-color-dark" *ngIf="!loaded"></div>
<div class="casino-card-slots--wrapper" (click)="playGame(false)">
  <ion-img
    class="casino-card-slots--image"
    deferLoad
    [src]="game?.Image"
    (deferLoad)="isLoaded(true)"
    [alt]="game?.Name"
    #gameImage
    (ionError)="errorImageHandler(gameImage)"
  />

  <ion-img
    class="casino-card-slots--imageSlots"
    deferLoad
    [src]="ImageSlots"
    (deferLoad)="isLoaded(true)"
    #defaultImgSlots
    (ionError)="errorImageHandler(defaultImgSlots)"
  />
</div>

<div class="casino-card-slots--hover" *ngIf="isDesktop" (click)="playGame(false)">
  <h6 class="casino-card-slots--hover-title color-light">{{ game?.Name | slotName | uppercase }}</h6>
  <div class="casino-card-slots--hover-play-container">
    <div class="btn-play">
      <img [src]="'assets/global/img/slots/icono_Play.svg'" />
    </div>
  </div>
</div>
