import { ActivatedRoute, NavigationEnd, NavigationSkipped, Router } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ViewChild, Component, inject } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { IonContent, IonicModule, NavParams, Platform } from '@ionic/angular';
// Pipes
import { ParseMoney } from '@pipes/parseMoney';
import { FormatOdd } from '@pipes/formatOdd.pipe';
import { ExtractDatePipe } from '@pipes/extractDate.pipe';
import { FormatTitleEventPipe } from '@pipes/formatTitleEvent.pipe';
// Providers
import { EventsService } from '@providers/EventsService';
import { NewUserService } from '@providers/NewUserService';
import { BalancesService } from '@providers/BalancesService';
// Services
import { DeviceService } from '@services/device.service';
// Shared
import { BackButton } from '@shared-common-components/back-button/back-button';
import { CodereNavbar } from '@shared-common-components/codere-navbar/codere-navbar';
import { CodereFooterComponent } from '@shared-mso-components/footer/codere-footer/codere-footer';
import { DetailTicket } from '../../../../../shared/src/components/payments/detail-ticket/detail-ticket';
// Others
import { Utils } from '@utils/Utils';
import { GlobalVarsModel } from '@models/ModelGlobalsVar';
import { C_DetailTicket } from '@models/C_ResponseTickets';
import { NewBaseService } from '../../../../../shared/src/providers/newBase.service';
import { Subscription } from 'rxjs';
import { PAYMENTS_PATHS } from '../../../../../shared/src/lib/constants/routes';

@Component({
  selector: 'check-ticket-step-2-page',
  templateUrl: './check-ticket-step-2.html',
  styleUrls: ['./check-ticket-step-2.scss'],
  standalone: true,
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    TranslateModule,
    BackButton,
    CodereFooterComponent,
    ParseMoney,
    FormatTitleEventPipe,
    ExtractDatePipe,
    FormatOdd,
    DetailTicket
  ]
})
export class CheckTicketStep2Page {
  @ViewChild(IonContent) content: IonContent;
  @ViewChild(CodereNavbar) miNavBar: CodereNavbar;

  betDetails = new C_DetailTicket();
  selectedTicket: string;
  isDesktop: boolean;
  isMobile: boolean;
  globalVars: GlobalVarsModel;
  mySubscription: Subscription;

  translate = inject(TranslateService);
  newUserService = inject(NewUserService);
  balancesService = inject(BalancesService);
  utils = inject(Utils);
  myParseMoney = inject(ParseMoney);
  platform = inject(Platform);
  router = inject(Router);
  events = inject(EventsService);
  navParams = inject(NavParams);
  deviceService = inject(DeviceService);
  newBaseService = inject(NewBaseService);
  route = inject(ActivatedRoute);

  constructor() {
    this.newBaseService.getVars.subscribe((vars) => {
      this.globalVars = vars;
    });
    this.isDesktop = this.deviceService.isDesktop();
    this.isMobile = this.deviceService.isMobile();
  }

  ionViewWillEnter() {
    if (history.state.ticket) {
      this.selectedTicket = history.state.ticket;
      this.betDetails.BetHistoryInfo = history.state.betHistory;
      //TODO revisar metodo viene null la peticion, se comenta hasta validar en mirror
      // this.loadDetails()
    }
    this.events.publish('footer:refresh', this.content);
  }

  //TODO revisar metodo viene null la peticion, se comenta hasta validar en mirror
  // loadDetails() {
  //   if (this.globalVars.user.logged && this.betDetails.BetHistoryInfo.TicketStatusNumber == '4') {
  //     this.newUserService.getTicketInfo(this.selectedTicket).subscribe((data) => {
  //       if (data) {
  //         this.betDetails = data;
  //         this.events.publish('footer:refresh', this.content);
  //       }
  //     });
  //   }
  // }

  payOutTicket(ticketNr: string, amount: number) {
    const msg =
      this.translate.instant('CloseBet1') +
      ' ' +
      this.myParseMoney.transform((amount / 100).toString()) +
      ' ' +
      this.translate.instant('CloseBet2') +
      '<br />' +
      this.translate.instant('compapuestaP3') +
      ':' +
      ticketNr;
    this.utils.confirm(
      true,
      this.translate.instant('CloseBet'),
      msg,
      this.translate.instant('tCancel'),
      null,
      this.translate.instant('tAccept'),
      () => {
        this.newUserService.payoutBetTicket(ticketNr, amount).subscribe({
          next: (data) => {
            //La apuesta ha sido cerrada correctamente y se han agregado 1,24 a tu balance.
            if (data.success) {
              const msg2 =
                this.translate.instant('BetClose1') +
                ' ' +
                this.myParseMoney.transform((amount / 100).toString()) +
                ' ' +
                this.translate.instant('BetClose2');
              const title = this.translate.instant('CloseBet');
              this.utils.showMsg(
                msg2,
                () => {
                  //go to previous page
                  //TODO revisar metodo viene null la peticion, se comenta hasta validar en mirror
                  // this.loadDetails();
                },
                title
              );
              this.balancesService.refreshBalance();
            } else {
              //error al cobrar
              const texto = data.status + ' (' + data.statuscode + ')';
              this.utils.showError(texto, () => {
                //TODO revisar metodo viene null la peticion, se comenta hasta validar en mirror
                // this.loadDetails();
              });
            }
          },
          error: (_error) => {
            this.utils.showError('', () => {
              //TODO revisar metodo viene null la peticion, se comenta hasta validar en mirror
              // this.loadDetails();
            });
          }
        });
      }
    );
  }

  btBackCheckTicket() {
    this.router.navigate([`/${PAYMENTS_PATHS.BASE}/${PAYMENTS_PATHS.CheckTicketPage}`]);
  }

  btDepositTicket() {
    //comprobar login
    if (this.globalVars.user.logged) {
      const title: string = this.translate.instant('Tickprem');
      const mensaje: string =
        this.translate.instant('Tickpremmoney') +
        "<b class='greenlbl'>" +
        this.myParseMoney.transform(this.betDetails.BetHistoryInfo.PotentialWinnings.toString()) +
        '</b>.<br>' +
        this.translate.instant('tickpremtx');
      this.utils.confirm(false, title, mensaje, this.translate.instant('tCancel'), null, 'OK', () => {
        this.balancesService.addWonTicketToBalance(this.betDetails.BetHistoryInfo.TicketNumber).subscribe({
          next: (data) => {
            if (data.success && data.status == 'ok') {
              this.utils.showMsg(this.translate.instant('Deprealizado'));
            } else if (data.status == 'error') {
              this.utils.showError(this.translate.instant('tfailcompticket'));
            } else {
              this.utils.showError(data.message);
            }
          },
          error: (_error) => {
            this.utils.showError(this.translate.instant('m_menu_serverFail'));
          }
        });
      });
    } else {
      this.miNavBar.openModalLogin();
    }
  }

  //TODO revisar metodo viene null la peticion, se comenta hasta validar en mirror
  // reloadDetails() {
  //   return () => this.loadDetails();
  // }
}
