<div class="cs-document-item--container">
  <i class="cs-document-item--icon codere-icon icon-burger color-dark"></i>
  <span class="cs-document-item--text">{{ document }}</span>
</div>

<div
  *ngIf="!isSpain && !isColombia && verificationType || (isColombia && isVeridasCOActive)"
  class="cs-document-item--container m-right-1"
>
  <span class="cs-document-item--btn color-light background-color-secondary">Iniciar Verificación</span>
</div>

<div
  *ngIf="!isArgentina && !verificationType || (isColombia && !isVeridasCOActive)"
  class="cs-document-item--container"
>
  <span class="cs-document-item--text">{{'uploadFile' | translate}}</span>
  <i class="cs-document-item--icon codere-icon icon-photo color-dark"></i>
</div>

<ng-container *ngIf="isArgentina">
  <ng-container *ngIf="!isOriginFunds; else originFundsTemplate">
    <div class="cs-document-item--container icon-upload-document" *ngIf="!verificationType">
      <span class="cs-document-item--text color-secondary">Subir</span>
      <img alt="upload" class="upload-caba-img" src="./assets/global/img/upload-blue-icon.png" />
    </div>
  </ng-container>
  <ng-template #originFundsTemplate>
    <div class="cs-document-item--container upload-caba-img">
      <span class="cs-document-item--text color-secondary"
        >{{ originFunds ? originFunds : translate.instant('origenDeFondosSeleccion') }}</span
      >
    </div>
  </ng-template>
</ng-container>
