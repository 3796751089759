<div class="sb-button--content" [ngClass]="{'is-selected': selected,'locked':isLocked(), 'disabled':disabled() }">
  <ion-icon
    *ngIf="!mode && game?.upOdd"
    name="caret-up-outline"
    class="caret-up-outline"
    color="success"
    [ngClass]="{ 'sb-button--animation': game?.downOdd }"
  >
  </ion-icon>
  <p class="sb-button--title color-muted">
    {{ game?.Name | getNameResult: [type, typeName, index, isCombined, home, away, sport] }}
  </p>
  <p
    class="sb-button--subtitle color-dark"
    [ngClass]="{
    'is-up': !!mode && game?.upOdd,
    'is-down': !!mode && game?.downOdd
  }"
  >
    {{ game?.Odd | formatOdd }}
    <i></i>
  </p>
  <ion-icon
    *ngIf="!mode && game?.downOdd"
    name="caret-down-outline"
    class="caret-down-outline"
    color="danger"
    [ngClass]="{ 'sb-button--animation': game?.downOdd }"
  >
  </ion-icon>
</div>
