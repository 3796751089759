<div
  [ngClass]="{'active': active,'disabled': disabled}"
  (click)="toggleSelect()"
  (focusout)="desactivateSelect()"
  class="contact-select"
>
  <p>{{selectedValue ? selectedValue.name:placeholder}} <img src="assets/global/img/chevron-down-outline.svg" /></p>
  <ul>
    <ng-container *ngFor="let item of values">
      <li (click)="changeSelectedValue(item)" *ngIf="item.id!==selected">{{item.name}}</li>
    </ng-container>
  </ul>
</div>
