<section>
  <swiper-container #betSenseSwiper init="false" class="swiper-container sb-marquee-betsense">
    <swiper-slide
      *ngFor="let betsenseMarquee of betsenseMarquees | slice:0:10; let index = index"
      class="sb-marquee-betsense--box swiper-slide sb-marquee-betsense-item background-color-light"
    >
      <div class="sb-marquee-betsense--title">
        <p>{{ betsenseMarquee.title }}</p>
      </div>

      <div class="sb-marquee-betsense--button-wrapper">
        <sb-button
          class="sb-button background-color-button sb-marquee-betsense--button"
          [game]="betsenseMarquee"
          [index]="index"
          (bet)="onClick(betsenseMarquee.NodeId)"
          [locked]="false"
          [home]="''"
          [away]="''"
          [selected]="newTicketFacade?.hasResult(betsenseMarquee.NodeId)"
          [sport]="'soccer'"
        >
        </sb-button>
      </div>
    </swiper-slide>
  </swiper-container>
  <ng-container>
    <button
      class="swiper-button-prev-betsense slide-control prev"
      [ngStyle]="{'display': isMobile ? 'none' : 'block'}"
      ion-button
    >
      <ion-icon name="chevron-back-outline"></ion-icon>
    </button>
    <button
      class="swiper-button-next-betsense slide-control next"
      [ngStyle]="{'display': isMobile ? 'none' : 'block'}"
      ion-button
    >
      <ion-icon name="chevron-forward-outline"></ion-icon>
    </button>
  </ng-container>
</section>
