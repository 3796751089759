import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, ElementRef, Renderer2, ViewChild, inject } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { IonButton, IonicModule } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";
import { Subject } from "rxjs";

import { Ticket } from "@sports-components/ticket/ticket-base.component";
// Models
import { C_TicketEnumMode } from "@sports-models/index";
import { TicketMethods } from "@sports-utils/index";

// Pipes
import { FormatOdd } from "@pipes/formatOdd.pipe";
import { ParseStakeMoney } from "@pipes/parseStakeMoney";

// Directives
import { DirectivesModule } from "@directives/directives.module";

import { CodereGridFilterComponent } from "@components/sports/codere-grid-filter/codere-grid-filter.component";
import {
  BetslipTicketConfirmedComponent,
  SBBetslipStakeComponent,
  TicketBetbuilderComponent,
} from "@sports-components/index";
import { LobbyOutComponent } from "../../casino/lobby/lobby-out/lobby-out";
// Components
import { TicketBonusComponent } from "../ticket-bonus/ticket-bonus";
import { TicketFilterComponent } from "../ticket-filter/ticket-filter";
import { TicketSelectionComponent } from "../ticket-selection/ticket-selection";

@Component({
  selector: "ticket",
  templateUrl: "./ticket.html",
  styleUrls: ["./ticket.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    id: "sb-ticket",
  },
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TranslateModule,
    ParseStakeMoney,
    FormatOdd,
    TicketBonusComponent,
    TicketSelectionComponent,
    LobbyOutComponent,
    TicketFilterComponent,
    TicketBetbuilderComponent,
    DirectivesModule,
    CodereGridFilterComponent,
    SBBetslipStakeComponent,
    BetslipTicketConfirmedComponent,
  ],
})
export class TicketComponent extends Ticket {
  @ViewChild("selections") selections: ElementRef;
  @ViewChild("header") header: ElementRef;
  @ViewChild("eliminar_todo") botonEliminarTodo: IonButton;
  confirmationExpanded: boolean = true;
  mode: string = C_TicketEnumMode.TICKET_EXPANDED;
  /// Stake's input
  declare stakeInput;
  stakeInputUpdate = new Subject<string>();
  swipeActive: boolean;
  hideGames: boolean = false;
  totalOdds: number = 0;
  totalOddsEnabled: boolean = false;
  numberOfSelections: number = 0;

  /// Stake's input
  public confirmationMessage: string;
  renderer = inject(Renderer2);

  constructor() {
    super();

    this.ticket$.subscribe((ticket$) => {
      this.isMultiSelection();
      this.isConfirmed();
      this.isPending();
      this.setCurrencySign();
      this.totalOdds = 0;
      this.totalOddsEnabled = this.globalVars.FEATURES.SPO_totalOddsFeatureFlagEnabled
        ? this.globalVars.FEATURES.SPO_totalOddsFeatureFlagEnabled
        : false;
      this.numberOfSelections = this.getNumberOfSelections(this.ticket);
    });
  }

  ngAfterViewInit() {
    this.setCurrencySign();
  }

  reveal() {
    if (this.isDesktop) return;
    this.newTicketFacade.closeKeyboard();
  }

  closeTicketFull() {
    this.mobileBehavior.closeTicketFull();
  }

  private isMultiSelection() {
    if (this.ticket !== undefined) {
      this.mode =
        this.ticket.BetType === 2
          ? C_TicketEnumMode.TICKET_EXPANDED_MULTISELECTION
          : C_TicketEnumMode.TICKET_EXPANDED;
    } else {
      this.mode = C_TicketEnumMode.TICKET_EXPANDED;
    }
  }

  getTicketIsConfirmed(ticket: any): boolean {
    if (ticket !== undefined && ticket !== null) return TicketMethods.getIsConfirmed(ticket);
    else return false;
  }

  private isPending() {
    if (
      !!this.ticket &&
      TicketMethods.getIsPending(this.ticket) &&
      (this.ticket.OveraskInfo.Status !== "OK" || !this.isDesktop)
    ) {
      this.confirmationExpanded = false;
      this.mode = C_TicketEnumMode.TICKET_CONFIRMATION_COLLAPSED;
      return;
    } else if (!!this.ticket && this.ticket.OveraskInfo.Status !== "OK" && this.ticket.OveraskInfo.Status === 3) {
      this.confirmationExpanded = false;
      this.mode = C_TicketEnumMode.TICKET_OVERASK_REJECTED;
    }
  }

  public getNumberOfSelections(data: any) {
    if (data !== undefined && data !== null) return TicketMethods.getNumberOfSelections(data);
    else return undefined;
  }

  public scrollTo(selectionPosition: any) {
    if (this.isDesktop) return;
    setTimeout(() => {
      this.selections.nativeElement.scroll({
        top: (TicketMethods.getSelectedLine(this.ticket).ItemId - 1) * 81,
        left: 0,
        behavior: "smooth",
      });
    }, 500);
  }

  inputChange(value) {
    const num = value.replaceAll(".", ",");
    return Number(num);
  }

  callHasErrors(ticket: any) {
    return TicketMethods.getHasErrors(ticket);
  }
}
