import { Pipe, PipeTransform } from '@angular/core';
@Pipe({ name: 'countDownStartDate' })
export class countDownStartDate implements PipeTransform {
  transform(value: string): number {
    const one_minute = 1000 * 60;
    const startDate = new Date(parseInt(value.slice(6, value.length - 2)));
    const date1_ms = new Date().getTime();
    const date2_ms = startDate.getTime();

    // Calculate the difference in milliseconds
    const difference_ms = date2_ms - date1_ms;

    // Convert back to days and return
    return Math.round(difference_ms / one_minute);
  }
}
