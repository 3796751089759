<!-- eslint-disable @angular-eslint/template/eqeqeq -->
<ion-content>
  <user-section-title [sectionTitle]="'GetCardorder'"></user-section-title>
  <div class="is-narrow-content">
    <form [formGroup]="myForm">
      <ion-list class="form-list">
        <div class="introReg">
          <ion-icon name="alert-circle-outline"></ion-icon>
          {{'GetCardverifydata' |translate}}
          <!--Verifica que tus datos son correctos para poder recibir la tarjeta Codere en tu domicilio-->
        </div>
        <div class="titleheader" [innerHTML]="'getcardccaa' | translate"></div>
        <!--Comunidad en la que vas a utilizar la tarjeta-->

        <div
          class="myWrapInput"
          [class.has-error]="!myForm.controls['comunidadTarjeta'].valid && myForm.controls['comunidadTarjeta'].touched"
        >
          <ion-item
            class="firstInput fixed-select cdr-item-input"
            lines="none"
            [class.error]="!myForm.controls['comunidadTarjeta'].valid && myForm.controls['comunidadTarjeta'].touched"
          >
            <ion-select
              cancelText="{{'tCancel'|translate}}"
              class="selectInput"
              formControlName="comunidadTarjeta"
              [tabindex]="1"
              [value]="comunidadUser"
              placeholder="{{'v2_CCAA' | translate}}"
              mode="md"
              [interfaceOptions]="{size: 'auto', header: 'selectoption' | translate | uppercase, mode: undefined }"
              justify="space-between"
            >
              <ion-select-option *ngFor="let comunidad of allowedCCAA" [value]="comunidad.code">
                {{comunidad.label}}
              </ion-select-option>
            </ion-select>
          </ion-item>
          <div
            class="labelError"
            [hidden]="myForm.controls['comunidadTarjeta'].valid || !myForm.controls['comunidadTarjeta'].touched"
          >
            <span
              >{{'GetCardUseCCAA' | translate}}
              <!--Selecciona la comunidad autónoma en la que utilizarás la tarjeta--></span
            >
          </div>
        </div>

        <div class="titleheader">
          {{'GetCardpostal' | translate | uppercase}}
          <!--DATOS DE ENVÍO -->
        </div>

        <div
          class="myWrapInput"
          [class.has-error]="!myForm.controls['nombre'].valid && myForm.controls['nombre'].touched"
        >
          <ion-item
            [class.error]="!myForm.controls['nombre'].valid && myForm.controls['nombre'].touched"
            [class.cdr-item-input]="myForm.get('nombre').enabled"
            [class.cdr-item-input-disabled]="!myForm.get('nombre').enabled"
            lines="none"
          >
            <ion-input
              class="ion-input-no-transform-label cdr-input-floating"
              type="text"
              max-length="255"
              label="{{'rNom' | translate}}"
              labelPlacement="floating"
              formControlName="nombre"
            ></ion-input>
          </ion-item>
          <div class="labelError" [hidden]="!myForm.controls['nombre'].invalid || !myForm.controls['nombre'].touched">
            <span
              >{{'error_form_requiered' | translate}}
              <!--Este campo es obligatorio--></span
            >
          </div>
        </div>
        <div
          class="myWrapInput"
          [class.has-error]="!myForm.controls['calle'].valid && myForm.controls['calle'].touched"
        >
          <ion-item
            [class.error]="!myForm.controls['calle'].valid && myForm.controls['calle'].touched"
            class="cdr-item-input"
            [class.cdr-item-input]="myForm.get('calle').enabled"
          >
            <ion-input
              class="ion-input-no-transform-label"
              type="text"
              formControlName="calle"
              label="{{'GetCardnumber' | translate}}"
              labelPlacement="floating"
            ></ion-input>
          </ion-item>
          <div class="labelError" [hidden]="myForm.controls['calle'].valid || !myForm.controls['calle'].touched">
            <span
              >{{'error_form_requiered' | translate}}
              <!--Este campo es obligatorio--></span
            >
          </div>
        </div>
        <div class="myWrapInput" [class.has-error]="!myForm.controls['piso'].valid && myForm.controls['piso'].touched">
          <ion-item
            [class.error]="!myForm.controls['piso'].valid && myForm.controls['piso'].touched"
            class="cdr-item-input"
            [class.cdr-item-input]="myForm.get('piso').enabled"
          >
            <ion-input
              class="ion-input-no-transform-label"
              type="text"
              formControlName="piso"
              [tabindex]="2"
              label="{{'GetCardletter' | translate}}"
              labelPlacement="floating"
            >
            </ion-input>
          </ion-item>
          <div class="labelError" [hidden]="myForm.controls['piso'].valid || !myForm.controls['piso'].touched">
            <span
              >{{'error_form_requiered' | translate}}
              <!--Este campo es obligatorio--></span
            >
          </div>
        </div>
        <div
          class="myWrapInput"
          [class.has-error]="!myForm.controls['ciudad'].valid && myForm.controls['ciudad'].touched"
        >
          <ion-item
            [class.error]="!myForm.controls['ciudad'].valid && myForm.controls['ciudad'].touched"
            class="cdr-item-input"
            [class.cdr-item-input]="myForm.get('ciudad').enabled"
          >
            <ion-input
              class="ion-input-no-transform-label"
              [(ngModel)]="user.ciudad"
              type="text"
              formControlName="ciudad"
              label="{{'GetCardcity' | translate}}"
              labelPlacement="floating"
            ></ion-input>
          </ion-item>
          <div class="labelError" [hidden]="myForm.controls['ciudad'].valid || !myForm.controls['ciudad'].touched">
            <span
              >{{'error_form_requiered' | translate}}
              <!--Este campo es obligatorio--></span
            >
          </div>
        </div>
        <div class="myWrapInput" [class.has-error]="!myForm.controls['cp'].valid && myForm.controls['cp'].touched">
          <ion-item
            [class.error]="!myForm.controls['cp'].valid && myForm.controls['cp'].touched"
            class="cdr-item-input"
            [class.cdr-item-input]="myForm.get('ciudad').enabled"
          >
            <ion-input
              class="ion-input-no-transform-label"
              [(ngModel)]="user.cp"
              type="number"
              formControlName="cp"
              label="{{'rZip' | translate}}"
              labelPlacement="floating"
            ></ion-input>
          </ion-item>
          <div class="labelError" [hidden]="myForm.controls['cp'].valid || !myForm.controls['cp'].touched">
            <span *ngIf="myForm.controls['cp'].hasError('required')"
              >{{'error_form_requiered' | translate}}
              <!--Este campo es obligatorio--></span
            >
            <span *ngIf="myForm.controls['cp'].hasError('valid')"
              >{{'tfailformat' | translate}}
              <!--Formato incorrecto--></span
            >
            <span *ngIf="myForm.controls['cp'].hasError('same')">
              {{'GetCardCPCCAA' | translate}}
              <!--Debe coincidir el código postal y la comunidad a la que pertenece--></span
            >
          </div>
        </div>
        <div
          class="myWrapInput"
          [class.has-error]="!myForm.controls['provincia'].valid && myForm.controls['provincia'].touched"
        >
          <ion-item
            class=""
            [class.error]="!myForm.controls['provincia'].valid && myForm.controls['provincia'].touched"
            class="firstInput fixed-select cdr-item-input"
            lines="none"
          >
            <ion-select
              cancelText="{{'tCancel'|translate}}"
              class="selectInput"
              formControlName="provincia"
              [value]="comunidadUser"
              placeholder="{{'pIState' | translate}}"
              mode="md"
              [interfaceOptions]="{size: 'auto', header: 'selectoption' | translate | uppercase, mode: undefined }"
              justify="space-between"
            >
              <ion-select-option *ngFor="let provincia of allowedProvincias" [value]="provincia.code">
                {{provincia.label}}
              </ion-select-option>
            </ion-select>
          </ion-item>
          <div
            class="labelError"
            [hidden]="myForm.controls['provincia'].valid || !myForm.controls['provincia'].touched"
          >
            <span
              >{{'error_form_requiered' | translate}}
              <!--Este campo es obligatorio--></span
            >
          </div>
        </div>
        <div class="titleheader">
          {{'GetCarddata' | translate | uppercase}}
          <!--DATOS DE TU TARJETA-->
        </div>
        <p>
          {{'CoderecardPin' | translate}}*
          <!--Inserta el Pin para tu nueva tarjeta*-->
        </p>
        <div class="myWrapInput" [class.has-error]="!myForm.controls['pin'].valid && myForm.controls['pin'].touched">
          <ion-item
            [class.error]="!myForm.controls['pin'].valid && myForm.controls['pin'].touched"
            class="cdr-item-input"
            [class.cdr-item-input]="myForm.get('pin').enabled"
          >
            <ion-input
              class="ion-input-no-transform-label"
              type="password"
              formControlName="pin"
              label="Pin"
              labelPlacement="floating"
            ></ion-input>
          </ion-item>
          <div class="labelError" [hidden]="myForm.controls['pin'].valid || !myForm.controls['pin'].touched">
            <span *ngIf="myForm.controls['pin'].hasError('required')">
              {{'v2_pinObl' | translate}}
              <!--# El pin es obligatorio--></span
            >
            <span *ngIf="myForm.controls['pin'].hasError('pattern')">
              {{'v2_pinNum' | translate}}
              <!--# El pin debe contener sólo números--><br
            /></span>
            <span *ngIf="myForm.controls['pin'].hasError('maxlength')">
              {{'v2_pinNum1' | translate}}
              <!--# El pin debe contener 4 números--></span
            >
            <span *ngIf="myForm.controls['pin'].hasError('minlength')">
              {{'v2_pinNum1' | translate}}
              <!--# El pin debe contener 4 números--></span
            >
          </div>
        </div>
        <div
          class="myWrapInput"
          [class.has-error]="!myForm.controls['repin'].valid && myForm.controls['repin'].touched"
        >
          <ion-item
            [class.error]="!myForm.controls['repin'].valid && myForm.controls['repin'].touched"
            class="cdr-item-input"
            [class.cdr-item-input]="myForm.get('repin').enabled"
          >
            <ion-input
              class="ion-input-no-transform-label"
              type="password"
              formControlName="repin"
              label="{{'GetCardRepeatPin' | translate}}"
              labelPlacement="floating"
            ></ion-input>
          </ion-item>
          <div class="labelError" [hidden]="myForm.controls['repin'].valid || !myForm.controls['repin'].touched">
            <span *ngIf="myForm.controls['repin'].hasError('required')">
              {{'v2_pinObl' | translate}}
              <!--# El pin es obligatorio--></span
            >
            <span *ngIf="myForm.controls['repin'].hasError('pattern')"
              >{{'v2_pinNum' | translate}}
              <!--# El pin debe contener sólo números--><br
            /></span>
            <span *ngIf="myForm.controls['repin'].hasError('maxlength')">
              {{'v2_pinNum1' | translate}}
              <!--# El pin debe contener 4 números--></span
            >
            <span *ngIf="myForm.controls['repin'].hasError('minlength')">
              {{'v2_pinNum1' | translate}}
              <!--# El pin debe contener 4 números--></span
            >
            <span *ngIf="myForm.controls['repin'].hasError('same')">
              {{'v2_errorPin' | translate}}
              <!--# El pin no coincide--></span
            >
          </div>
        </div>
        <div class="positionr">
          <div class="myWrapInput">
            <ion-item
              [class.error]="!myForm.controls['legal'].valid && myForm.controls['legal'].touched"
              lines="none"
              class="paddingl0"
            >
              <ion-checkbox formControlName="legal" labelPlacement="end" justify="start"> </ion-checkbox>
            </ion-item>
            <div class="labelError" [hidden]="myForm.controls['legal'].value || !myForm.controls['legal'].touched">
              {{'error_form_check_ct' | translate}}
            </div>
          </div>
          <div class="tyccoderecard">
            {{'GetCardtyc01' | translate}}
            <a [href]="urlCondiciones" target="_blank">{{'GetCardtyc02' | translate}}</a>
            {{'GetCardtyc03' | translate}}
          </div>
        </div>
        <div class="sendForm">
          <button ion-button full (click)="sendForm()">
            {{'GetCardorder' | translate}}
            <!--Solicitar tarjeta-->
          </button>
        </div>
      </ion-list>
    </form>
  </div>
  <codere-footer></codere-footer>
</ion-content>
