import { Component, Input, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { C_DepositPaymentDateList } from '../../../models/C_Deposits';
import { DeviceService } from '../../../services/device.service';
import { GlobalVarsModel } from '../../../models/ModelGlobalsVar';
import { NewBaseService } from '../../../providers/newBase.service';
import { UserService } from '../../../providers/UserService';
import { Utils } from '../../../utils/Utils';
import { Router } from '@angular/router';

import { ExtractDatePipe } from '../../../pipes/extractDate.pipe';
import { ParseTransactionMoney } from '../../../pipes/parseTransactionMoney.pipe';

@Component({
  standalone: true,
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'history-ticket',
  templateUrl: './history-ticket.html',
  styleUrls: ['./history-ticket.scss'],
  imports: [IonicModule, CommonModule, TranslateModule, ExtractDatePipe, ParseTransactionMoney]
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class HistoryTicket {
  @Input() historyTicket: C_DepositPaymentDateList;
  dataCortesia: any;
  globalVars!: GlobalVarsModel;
  isDesktop = false;

  utils = inject(Utils);
  newBaseService = inject(NewBaseService);
  userService = inject(UserService);
  translate = inject(TranslateService);
  extractDate = inject(ExtractDatePipe);
  parseTransactionMoney = inject(ParseTransactionMoney);
  router = inject(Router);
  deviceService = inject(DeviceService);

  constructor() {
    this.newBaseService.getVars.subscribe((data: GlobalVarsModel) => {
      this.globalVars = data;
    });

    this.isDesktop = this.deviceService.isDesktop();
  }

  viewTicketService(drawid: string) {
    this.userService.getDepositDrawInformation(drawid).subscribe(
      (data) => {
        const msg =
          '<div>' +
          '<div class="ticketservtxt">' +
          '<div>Libros For&aacute;neos SA de CV</div>' +
          '<div>Av.Concripto #311 Acceso 4 Caballeriza 6 D-102</div>' +
          '<div> Col. Lomas de Sotelo, Alcald&iacute;a Miguel Hidalgo</div>' +
          '<div> CDMX, CP. 11200, RFC LFO900430GN2 </div>' +
          '<div class="titsuc"> Sucursal </div>' +
          '<div>Boulevard A. Villas #1443 Local 63</div>' +
          '<div>Col. Ejido Irapuato, C.P. 36670</div>' +
          '<div>Irapuato, Guanajuato</div>' +
          '</div>' +
          '<div>' +
          '<div class="ticketservtxtt1">' +
          this.formatDate(data.CreationDateString) +
          '</div>' +
          '</div>' +
          '</div>' +
          '<div>Folio: 0000000004632</div>' +
          '<div class="ticketservtxtbor"><div class="ticketservtxtmt10">Nota de Venta</div></div>' +
          '<div>' +
          '<div>' +
          '<div> Referencia: ' +
          data.ExternalTransactionId +
          '</div>' +
          '<div class="marginb10"> Acceso y uso de instalaciones y Software: $' +
          data.ServiceAmount +
          '</div>' +
          '</div>' +
          '<div class="bordertopticket" >' +
          '<div class="txtticketsort txcenter" > Total: $' +
          data.ServiceAmount +
          '</div>' +
          '</div>' +
          '<div class="bordertopticket ticketservtxtmt10 txcenter">R&eacute;gimen General de ley Personas Morales</div>' +
          '</div>';
        this.utils.alertHistory('Nota de venta', msg, this.translate.instant('close'), 'alertbig');
      },
      (err) => {
        this.utils.showError(this.translate.instant('v2_errorTicket'));
      }
    );
  }

  viewTicketDraw(drawid: string) {
    this.userService.getDepositDrawInformation(drawid).subscribe(
      (data) => {
        const draw = data.Draw != null ? data.Draw : '0';
        const msg =
          '<div>' +
          '<div class="ticketservtxt">' +
          '<div>Libros For&aacute;neos SA de CV</div>' +
          '<div>Av.Concripto #311 Acceso 4 Caballeriza 6 D-102</div>' +
          '<div> Col. Lomas de Sotelo, Alcald&iacute;a Miguel Hidalgo</div>' +
          '<div> CDMX, CP. 11200, RFC LFO900430GN2 </div>' +
          '<div class="titsuc"> Sucursal </div>' +
          '<div>Boulevard A. Villas #1443 Local 63</div>' +
          '<div>Col. Ejido Irapuato, C.P. 36670</div>' +
          '<div>Irapuato, Guanajuato</div>' +
          '</div>' +
          '<div>' +
          '<div class="ticketservtxtt1">' +
          this.formatDate(data.CreationDateString) +
          '</div>' +
          '</div>' +
          '<div class="ticketservtxtbor"><div class="ticketservtxtmt10">Sorteo</div></div>' +
          '<div>' +
          '<div class="ticketsortmb10"> Reference: ' +
          data.ExternalTransactionId +
          '</div>' +
          '</div>' +
          '<div class="bordertopticket">' +
          '<div class="ticketservtxtpt10"> N&uacute;m de sorteo: ' +
          data.Id +
          '</div>' +
          '<div> Apuesta: $' +
          data.PlayedAmount +
          '</div>' +
          '<div class="ticketsortmb10"> Premio: $' +
          draw +
          '</div>' +
          '</div>' +
          '<div class="ticketsortbord" >' +
          '<div class="txtticketsort txcenter"> ' +
          this.formatDraw(data.OwnedCards, true) +
          '</div>' +
          '<div class="txtticketsort txcenter"> Extracto:<br/> ' +
          this.formatDraw(data.CasinoCards, false) +
          '</div>' +
          '</div>' +
          '<div class="bordertopticket ticketservtxtmt10 txcenter">R&eacute;gimen General de ley Personas Morales</div>' +
          '</div>';
        if (data.Draw == null) {
          this.dataCortesia = data;
          this.utils.confirm(
            false,
            'Ticket sorteo',
            msg,
            this.translate.instant('close'),
            null,
            'Ver ticket cortes\u00EDa',
            () => {
              this.viewCortesia();
            }
          );
        } else this.utils.alertHistory('Ticket sorteo', msg, this.translate.instant('close'), 'alertbig');
      },
      (err) => {
        this.utils.showError(this.translate.instant('v2_errorTicket'));
      }
    );
  }

  viewRetention(drawid: string) {
    this.userService.getChargeDrawInformation(drawid).subscribe(
      (data) => {
        const msg =
          '<div>' +
          '<div class="ticketservtxt">' +
          '<div>Libros For&aacute;neos SA de CV</div>' +
          '<div>Av.Concripto #311 Acceso 4 Caballeriza 6 D-102</div>' +
          '<div> Col. Lomas de Sotelo, Alcald&iacute;a Miguel Hidalgo</div>' +
          '<div> CDMX, CP. 11200, RFC LFO900430GN2 </div>' +
          '<div class="titsuc"> Sucursal </div>' +
          '<div>Boulevard A. Villas #1443 Local 63</div>' +
          '<div>Col. Ejido Irapuato, C.P. 36670</div>' +
          '<div>Irapuato, Guanajuato</div>' +
          '</div>' +
          '<div class="ticketservtxtmt10"> Periodo de deducciones desde: ' +
          this.extractDate.transform(data.TaxPeriodFrom, [2]) +
          '</div>' +
          '<div class="ticketsortmb10"> Periodo de deducciones hasta: ' +
          this.extractDate.transform(data.TaxPeriodTo, [2]) +
          '</div>' +
          '<div class="ticketservtxtbor"><div class="ticketservtxtmt10">Retirada</div></div>' +
          '<div class="ticketsortmb10"> Saldo Final al momento de la retirada: $' +
          data.Amount +
          '</div>' +
          '<div class="ticketsortmb10"> Beneficio en el momento de la retirada: $' +
          data.TaxedAmount +
          '</div>' +
          '<div class="ticketsortmb10"> Deducciones: $' +
          data.TaxesAmount +
          '</div>' +
          '<div class="bordertopticket ticketservtxtmt10 txcenter">R&eacute;gimen General de ley Personas Morales</div>' +
          '</div>';
        this.utils.alertHistory('Comprobante de pago', msg, this.translate.instant('close'), 'alertbig');
      },
      (err) => {
        this.utils.showError('No se pueden mostrar las deducciones');
      }
    );
  }

  viewCortesia() {
    const data = this.dataCortesia;
    const msg =
      '<div>' +
      '<div class="ticketservtxt">' +
      '<div>Libros For&aacute;neos SA de CV</div>' +
      '<div>Av.Concripto #311 Acceso 4 Caballeriza 6 D-102</div>' +
      '<div> Col. Lomas de Sotelo, Alcald&iacute;a Miguel Hidalgo</div>' +
      '<div> CDMX, CP. 11200, RFC LFO900430GN2 </div>' +
      '<div class="titsuc"> Sucursal </div>' +
      '<div>Boulevard A. Villas #1443 Local 63</div>' +
      '<div>Col. Ejido Irapuato, C.P. 36670</div>' +
      '<div>Irapuato, Guanajuato</div>' +
      '</div>' +
      '<div>' +
      '<div class="ticketservtxtt1">' +
      this.formatDate(data.CreationDateString) +
      '</div>' +
      '</div>' +
      '<div>' +
      '<div class="ticketsortmb10"> Referencia: ' +
      data.ExternalTransactionId +
      '</div>' +
      '</div>' +
      '<div class="bordertopticket ticketservtxtmt10">' +
      '<div class="ticketservtxtpt10"> Cortes&iacute;a: $' +
      data.Promotion +
      '</div>' +
      '</div>' +
      '</div>';
    this.utils.alertHistory('Ticket cortes&iacute;a', msg, this.translate.instant('close'), 'alertbig');
  }

  currentPage(name: string) {
    const path = this.router.routerState.snapshot.url;
    const page = path.substring(path.lastIndexOf('/') + 1);

    return page == name;
  }

  formatDraw(balls: string, isfirst: boolean) {
    const itemsballs = balls.split(',');
    let spanballs = '';
    for (let i = 0; i < itemsballs.length; i++) {
      if (isfirst) spanballs += "<span class='ballgreen'>" + itemsballs[i] + '</span>';
      else spanballs += "<span class='balltrans'>" + itemsballs[i] + '</span>';
    }
    return spanballs;
  }

  formatDate(stringDate: string) {
    const miDate = new Date(stringDate);

    return (
      miDate.getDate() +
      ' ' +
      this.getMonthName(miDate.getMonth()) +
      ' ' +
      miDate.getFullYear() +
      ' ' +
      this.formatoNumber(miDate.getHours(), 2) +
      ':' +
      this.formatoNumber(miDate.getMinutes(), 2) +
      'h.'
    );
  }

  private getMonthName(mes: number): string {
    //devuelve el nombre del mes en formato MMM
    const meses = new Array<string>(12);
    for (let i = 0; i < 12; i++) {
      meses[i] = this.translate.instant('month' + (i + 1));
    }
    return meses[mes];
  }

  private formatoNumber(numero: number, digitos: number): string {
    let numberStr = numero.toString();
    while (numberStr.length < digitos) {
      numberStr = '0' + numberStr;
    }
    return numberStr;
  }
}
