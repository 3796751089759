/* eslint-disable eqeqeq */
import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Renderer2,
  inject,
} from "@angular/core";
import { Observable, Observer, of } from "rxjs";

@Directive({
  selector: "[deferLoad]",
  standalone: true,
})
export class DeferLoadDirective implements AfterViewInit {
  @Input() transition: string;
  @Input() placeholder: string;
  @Output() public deferLoad: EventEmitter<any> = new EventEmitter();
  private _intersectionObserver?: IntersectionObserver;

  _element = inject(ElementRef);
  _renderer = inject(Renderer2);

  ngAfterViewInit() {
    if (!window["IntersectionObserver"]) {
      this.skipIntersection();
      return;
    }

    this._renderer.setStyle(this._element.nativeElement, "opacity", "0");
    this._intersectionObserver = new IntersectionObserver((entries) => {
      this.checkForIntersection(entries);
    }, {});
    this._intersectionObserver.observe(<Element>this._element.nativeElement);
  }

  private checkForIntersection(entries: Array<IntersectionObserverEntry>) {
    entries.forEach((entry: IntersectionObserverEntry) => {
      if (this.checkIfIntersecting(entry)) {
        this.loadImage().subscribe({
          next: (url) => {
            this._renderer.setAttribute(this._element.nativeElement, "src", url);
            this._renderer.addClass(this._element.nativeElement, this.transition || "fade-in");
            this._intersectionObserver.unobserve(<Element>this._element.nativeElement);
            this._intersectionObserver.disconnect();
            this.deferLoad.emit();
          },
          error: (err) => {
            this._renderer.setAttribute(this._element.nativeElement, "src", err);
            this._renderer.addClass(this._element.nativeElement, this.transition || "fade-in");
            this._intersectionObserver.unobserve(<Element>this._element.nativeElement);
            this._intersectionObserver.disconnect();
            this.deferLoad.emit();
          },
        });
      }
    });
  }

  private checkIfIntersecting(entry: IntersectionObserverEntry) {
    return (<any>entry).isIntersecting && entry.target === this._element.nativeElement;
  }

  private loadImage(): Observable<string> {
    const url = this._element.nativeElement.getAttribute("data-src");
    if (!url) return of("");
    return Observable.create((observer: Observer<any>) => {
      try {
        const img = new Image();
        img.src = url;
        if (img.complete) {
          observer.next(url);
          observer.complete();
        } else {
          img.onload = () => {
            observer.next(url);
            observer.complete();
          };
          img.onerror = () => {
            observer.error(this.placeholder);
          };
        }
      } catch (err) {
        observer.error(this.placeholder);
      }
    });
  }

  private skipIntersection() {
    const src = this._element.nativeElement.getAttribute("data-src");
    this._renderer.setAttribute(this._element.nativeElement, "src", src);
    this._renderer.setStyle(this._element.nativeElement, "opacity", "1");
    this.deferLoad.emit();
  }
}
